export class Location {
    private _locationId: string;
    private _locationName: string;

    constructor(locationId: string, locationName: string) {
        this._locationId = locationId;
        this._locationName = locationName;
    }

    /**
     * Getter locationId
     * @return {string}
     */
    public get locationId(): string {
        return this._locationId;
    }

    /**
     * Getter locationName
     * @return {string}
     */
    public get locationName(): string {
        return this._locationName;
    }

    /**
     * Setter locationId
     * @param {string} value
     */
    public set locationId(value: string) {
        this._locationId = value;
    }

    /**
     * Setter locationName
     * @param {string} value
     */
    public set locationName(value: string) {
        this._locationName = value;
    }
}
