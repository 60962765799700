<dashlet-table
    [columns]="columns"
    [dataSource]="dashlet.trunks"
    [pageLength]="scrollHeight - 1"
    [tableHeightRows]="scrollHeight"
    [menuItems]="trunkContextMenuItems"
    [loading]="dashlet.loading"
    [defaultSort]="sortField"
    [sortDirection]="sortDirection"
    (filteredData)="receiveFilterdTableData($event)"
></dashlet-table>
