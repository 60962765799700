import * as ProcessWatchedServiceDataActions from '@actions/process-watched-service-data/process-watched-service-data.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService } from '@services/index';
import { RealTimeNotify } from '@models/index';



@Injectable()
export class ProcessWatchedServiceDataEffects {

  constructor(private actions$: Actions, private realtimeGatewayService: RealtimeGatewayService) { }

  getNotifyProcessWatchedServiceData$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessWatchedServiceDataActions.GetNotifyProcessWatchedServiceData),
    switchMap((action) => this.realtimeGatewayService.observe(action.equipmentId, action.commandTypeId).pipe(
      catchError((err) => of(
        ProcessWatchedServiceDataActions.GetNotifyProcessWatchedServiceDataFailure({ error: err })
        )
      ),
      mergeMap((data: RealTimeNotify) => [
        ProcessWatchedServiceDataActions.GetNotifyProcessWatchedServiceDataSuccess({ data }),
        ProcessWatchedServiceDataActions.GetProcessWatchedServiceData({ ...data })
      ],
      ),
    ),
    )
  ));

  getProcessWatchedServiceData$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessWatchedServiceDataActions.GetProcessWatchedServiceData),
    mergeMap((action) => this.realtimeGatewayService.getDocument(action.equipmentId, action.commandTypeId).pipe(
      map((data) => ProcessWatchedServiceDataActions.GetProcessWatchedServiceDataSuccess({ data:{ data, equipmentId: action.equipmentId} }),
      ),
      catchError((err) => of(ProcessWatchedServiceDataActions.GetProcessWatchedServiceDataFailure({ error: err })))
    ))
  ));

}
