export class SIPEntityStatus {
	private _fqdNorIPaddr: string;
	private _entityName: string;
	private _status: string;
	private _lastResponse: string;
	private _mode: string;
	private _addressMap: string;
	private _reason: string;
	private _responseMs: string;

	constructor(
		entityName: string,
		fqdNorIPaddr: string,
		status: string,
		lastResponse: string,
		mode: string,
		addressMap: string,
		reason: string,
		responseMs: string
	) {
		this._fqdNorIPaddr = fqdNorIPaddr;
		this._entityName = entityName;
		this._status = status;
		this._lastResponse = lastResponse;
		this._mode = mode;
		this._addressMap = addressMap;
		this._reason = reason;
		this._responseMs = responseMs;
	}

	public update(
		status: string,
		lastResponse: string,
		mode: string,
		addressMap: string,
		reason: string,
		responseMs: string
	) {
		this._status = status;
		this._lastResponse = lastResponse;
		this._mode = mode;
		this._addressMap = addressMap;
		this._reason = reason;
		this._responseMs = responseMs;
	}

	public updateFromObject(sipEntityStatus: SIPEntityStatus) {
		this._status = sipEntityStatus.status;
		this._lastResponse = sipEntityStatus.lastResponse;
		this._mode = sipEntityStatus.mode;
		this._addressMap = sipEntityStatus.addressMap;
		this._reason = sipEntityStatus.reason;
		this._responseMs = sipEntityStatus.responseMs;
	}

	/**
	 * Getter fqdNorIPaddr
	 * @return {string}
	 */
	public get fqdNorIPaddr(): string {
		return this._fqdNorIPaddr;
	}

	/**
	 * Getter entityName
	 * @return {string}
	 */
	public get entityName(): string {
		return this._entityName;
	}

	/**
	 * Getter status
	 * @return {string}
	 */
	public get status(): string {
		return this._status;
	}

	/**
	 * Getter lastResponse
	 * @return {string}
	 */
	public get lastResponse(): string {
		return this._lastResponse;
	}

	/**
	 * Getter mode
	 * @return {string}
	 */
	public get mode(): string {
		return this._mode;
	}

	/**
	 * Getter addressMap
	 * @return {string}
	 */
	public get addressMap(): string {
		return this._addressMap;
	}

	/**
	 * Getter reason
	 * @return {string}
	 */
	public get reason(): string {
		return this._reason;
	}

	/**
	 * Getter responseMs
	 * @return {string}
	 */
	public get responseMs(): string {
		return this._responseMs;
	}

	/**
	 * Setter fqdNorIPaddr
	 * @param {string} value
	 */
	public set fqdNorIPaddr(value: string) {
		this._fqdNorIPaddr = value;
	}

	/**
	 * Setter entityName
	 * @param {string} value
	 */
	public set entityName(value: string) {
		this._entityName = value;
	}

	/**
	 * Setter status
	 * @param {string} value
	 */
	public set status(value: string) {
		this._status = value;
	}

	/**
	 * Setter lastResponse
	 * @param {string} value
	 */
	public set lastResponse(value: string) {
		this._lastResponse = value;
	}

	/**
	 * Setter mode
	 * @param {string} value
	 */
	public set mode(value: string) {
		this._mode = value;
	}

	/**
	 * Setter addressMap
	 * @param {string} value
	 */
	public set addressMap(value: string) {
		this._addressMap = value;
	}

	/**
	 * Setter reason
	 * @param {string} value
	 */
	public set reason(value: string) {
		this._reason = value;
	}

	/**
	 * Setter responseMs
	 * @param {string} value
	 */
	public set responseMs(value: string) {
		this._responseMs = value;
	}
}
