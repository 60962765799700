/* eslint-disable no-dupe-class-members */
/* eslint-disable no-undef */
import { Injectable, NgZone } from '@angular/core';
import { SubscriptionSubject } from '@models/index';
import {
    Observable,
    EMPTY,
    throwError,
    fromEventPattern,
    Subject,
    takeUntil,
    BehaviorSubject,
    timer,
    switchMap,
    filter,
    of
} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as signalR from '@microsoft/signalr';
import * as Actions from '@actions/index';
import { select, Store } from '@ngrx/store';
import { AppState } from '@reducers/index';
import { environment } from '@environments/environment';
import { TimeoutService } from '@services/index';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable()
export class RealtimeGatewayService {
    private proxy: signalR.HubConnection | undefined;
    private subjects: SubscriptionSubject[] = [];
    private open = false;
    private worker: Worker;
    private messageObservable: Observable<any>;
    public isConnectedSubject = new BehaviorSubject(false);
    public isReConnectedSubject = new BehaviorSubject(false);
    private authToken: any;
    private onDestroy$ = new Subject<void>();
    rootUrl: string = environment.realtimeApi;
    storedIds: any[];
    private rtgWorker: Worker;

    private messageSubject = new BehaviorSubject<{ equipmentId: string; commandTypeId: string }>(null);
    private bufferedRequests: Record<string, string[]> = {};
    private bufferTime = 1000; // Adjust as needed
    private maxGroupSize = 8;

    constructor(
        private http: HttpClient,
        private store$: Store<AppState>,
        private zone: NgZone,
        public timeoutService: TimeoutService,
        private router: Router
    ) {
        this.authToken = localStorage.getItem('access_token');
        this.store$
            .pipe(
                select(state => state.realTimeData.storeEquipIdBasedOnType),
                takeUntil(this.onDestroy$)
            )
            .subscribe(res => {
                this.storedIds = res;
            });
        this.worker = new Worker(new URL('../../api.worker', import.meta.url), {
            name: 'apiWorker',
            type: 'module'
        });
        this.setupMessageObservable();

        this.rtgWorker = new Worker(new URL('../../rtg.worker', import.meta.url), {
            name: 'rtgWorker',
            type: 'module'
        });
        this.rtgWorker.onmessage = ({ data }) => {
            if ('subject' in data) {
                this.isConnectedSubject.next(data.subject);
                this.open = data.subject;
                if (data.subject) {
                    this.isReConnectedSubject.next(data.subject);
                }
            }
            if ('notifyData' in data) {
                const { equipmentId, commandTypeId } = data.notifyData;
                const id = this.getDocumentIdentifier(equipmentId, commandTypeId);
                if (id) {
                    const subject = this.subjects.find(element => element.key === id.key);
                    const data = {
                        equipmentId,
                        commandTypeId
                    };
                    if (subject) {
                        if (this.isSummaryDocument(data.commandTypeId)) {
                            this.store$.dispatch(Actions.getSummaryDocument(data));
                        } else {
                            this.store$.dispatch(Actions.GetCommonEntitys({ ...data, idKey: id.key }));
                        }

                        subject.subject.next(data);
                    }
                }
            }
            if ('error' in data) {
                console.error(data.error);
            }
        };

        // Request Grouping Logic
        this.messageSubject
            .pipe(
                filter(data => !!data && !!data.equipmentId),
                switchMap(() => timer(this.bufferTime)),
                switchMap(() => {
                    for (const [equipmentId, commandTypeIds] of Object.entries(this.bufferedRequests)) {
                        this.processRequest(equipmentId, commandTypeIds);
                    }
                    this.bufferedRequests = {};
                    return of([]); // Return an Observable of any value (null here)
                })
            )
            .subscribe(); // Initialize
    }

    public initConnection() {
        this.authToken = localStorage.getItem('access_token');
        this.rtgWorker.postMessage({
            type: 'initConnection',
            rootUrl: this.rootUrl,
            access_token: this.authToken,
            production: environment.production,
            workerInstanse: null
        });
    }

    private processRequest(equipmentId: string, commandTypeIds: string[]): void {
        const isBulk = commandTypeIds.length > 1;
        let docUrl = isBulk
            ? `${this.rootUrl}document/equipment/${equipmentId}/bulk`
            : `${this.rootUrl}document/equipment/${equipmentId}/${commandTypeIds[0]}`;
        // Send to web worker
        this.zone.run(() => {
            const authKey = `Bearer ${localStorage.getItem('access_token')}`;
            this.worker.postMessage({
                url: docUrl,
                authKey,
                commandTypeIds, // send array of commandTypeIds in the request payload
                method: isBulk ? 'POST' : 'GET',
                equipmentId,
                commandTypeId: isBulk ? 'null' : commandTypeIds[0]
            });
        });
    }

    public observe(equipmentId: string, commandTypeId: string): Observable<any> {
        if (equipmentId && commandTypeId) {
            equipmentId = equipmentId.toLowerCase();
            commandTypeId = commandTypeId.toLowerCase();

            const id = this.getDocumentIdentifier(equipmentId, commandTypeId);
            let subject;
            if (id) {
                subject = this.subjects.find(element => element.key === id.key);
            }
            if (subject) {
                return subject.subject;
            }
        }
        return EMPTY;
    }

    public subscribe(equipmentId: string, commandTypeId: string, idType?: string) {
        const id = this.getDocumentIdentifier(equipmentId, commandTypeId);
        if (id) {
            this.addSubject(id.key);
            this.rtgWorker.postMessage({
                type: 'subscribe',
                equipmentId,
                commandTypeId
            });
            this.store$.dispatch(Actions.StoreEquipIdBasedOnType({ equipmentId: equipmentId, idType: idType }));
        }
    }

    public requestHistoricLatest(equipmentId: string, commandTypeId: string) {
        const id = this.getDocumentIdentifier(equipmentId, commandTypeId);
        if (id) {
            const subject = this.getSubject(id.key);
            if (!subject) {
                if (!environment.production) console.error('Requesting from an unsubcribed document. ' + id.key);
                return;
            }

            if (this.open) {
                this.proxy!.invoke('RequestHistoricLatest', id.equipmentId, id.commandTypeId);
            }
        }
    }

    public requestHistoricDate(equipmentId: string, commandTypeId: string, from: Date): any;
    public requestHistoricDate(
        equipmentId: string,
        commandTypeId: string,
        from: Date,
        to?: Date,
        max?: number | null
    ): any;
    public requestHistoricDate(
        equipmentId: string,
        commandTypeId: string,
        from: string,
        to?: string,
        max?: number | null
    ): any;
    public requestHistoricDate(
        equipmentId: string,
        commandTypeId: string,
        from: string | Date,
        to?: string | Date,
        max?: number | null
    ): any {}

    public unsubscribe(equipmentId: string, commandTypeId: string) {
        const id = this.getDocumentIdentifier(equipmentId, commandTypeId);
        if (id) {
            const subject = this.getSubject(id.key);
            if (subject) {
                subject.counter--;

                if (subject.counter === 0) {
                    this.subjects.splice(this.subjects.indexOf(subject), 1);
                }
            }
        }
        this.rtgWorker.postMessage({
            type: 'unsubscribe',
            equipmentId,
            commandTypeId
        });
    }

    public getDocument(equipmentId: string, commandTypeId: string): Observable<any> {
        const selectedId = this.storedIds.filter(i => i.id === equipmentId)[0];
        let docUrl;
        if (selectedId.idType && selectedId.idType === 'customerId') {
            docUrl = `${this.rootUrl}document/customer/${equipmentId}/${commandTypeId}`;
        } else if (selectedId.idType && selectedId.idType === 'locationId') {
            docUrl = `${this.rootUrl}document/location/${equipmentId}/${commandTypeId}`;
        } else {
            docUrl = `${this.rootUrl}document/equipment/${equipmentId}/${commandTypeId}`;
        }
        const authKey = `Bearer ${localStorage.getItem('access_token')}`;
        this.worker.postMessage({ url: docUrl, authKey });
        return this.messageObservable;
    }

    public getChildEquipmentDocument(
        childEquipmentId: string,
        parentEquipmentId: string,
        commandTypeId: string
    ): Observable<any> {
        const docUrl = `${this.rootUrl}document/equipment/${parentEquipmentId}/${childEquipmentId}/${commandTypeId}`;
        const authKey = `Bearer ${localStorage.getItem('access_token')}`;
        this.worker.postMessage({ url: docUrl, authKey, equipmentId: childEquipmentId, commandTypeId });
        return this.messageObservable;
    }

    public getDocApiCall(equipmentId: string, commandTypeId: string) {
        const selectedId = this.storedIds.find(i => i.id === equipmentId);
        if (selectedId?.idType === ('customerId' || 'locationId')) {
            let docUrl = `${this.rootUrl}document/customer/${equipmentId}/${commandTypeId}`;
            if (selectedId?.idType === 'locationId') {
                docUrl = `${this.rootUrl}document/location/${equipmentId}/${commandTypeId}`;
            }
            const authKey = `Bearer ${localStorage.getItem('access_token')}`;
            const commandTypeIds = [];
            this.worker.postMessage({
                url: docUrl,
                authKey,
                commandTypeIds,
                method: 'GET',
                equipmentId,
                commandTypeId: commandTypeId
            });
        } else {
            // Accumulate with group size check
            if (this.bufferedRequests[equipmentId]) {
                if (this.bufferedRequests[equipmentId].length >= this.maxGroupSize) {
                    // Group limit reached - emit existing group for processing
                    this.processRequest(equipmentId, this.bufferedRequests[equipmentId]);
                    this.bufferedRequests[equipmentId] = [commandTypeId]; // Start a new group
                } else {
                    this.bufferedRequests[equipmentId].push(commandTypeId);
                }
            } else {
                this.bufferedRequests[equipmentId] = [commandTypeId];
            }
            this.messageSubject.next({ equipmentId, commandTypeId });
        }
    }

    private saveCurrentLocation(): void {
        let redirectUrl: string = location.pathname;
        if (redirectUrl && redirectUrl.toLowerCase() !== '/Login' && redirectUrl.toLowerCase() !== '/') {
            sessionStorage.setItem('postLoginRedirectUrl', redirectUrl);
        }
    }

    private setupMessageObservable() {
        this.messageObservable = fromEventPattern(handler => {
            this.worker.onmessage = ({ data }) => {
                if (!data.error) {
                    const timeStamp =
                        data && data.data[0] && data.data[0].timestamp ? data.data[0].timestamp : new Date();

                    const idKey = data.equipmentId + ':' + data.commandTypeId;

                    if (this.timeoutService.timers.get(idKey.toLowerCase())) {
                        this.timeoutService.documentRecieved(
                            data.equipmentId + ':' + data.commandTypeId,
                            data.commandTypeId,
                            timeStamp,
                            data.equipmentId
                        );
                    }
                    if (data && data.data.status && data.data.status === 401) {
                        this.saveCurrentLocation();
                        this.router.navigate(['/Login']);
                        this.store$.dispatch(Actions.GetCommonEntitysFailure({ error: 'Error occured' }));
                    } else if (data && data.data.status && data.data.status === 404) {
                        Actions.GetCommonEntitysFailure({ error: 'Error occured' });
                    } else {
                        if (data.url.includes('summarydocument')) {
                            Actions.getSummaryDocumentSuccess({
                                data: {
                                    data: data.data,
                                    uniqueId: (data.equipmentId + data.commandTypeId).toLowerCase()
                                }
                            });
                        } else {
                            Actions.GetCommonEntitysSuccess({
                                data: {
                                    data: data.data,
                                    uniqueId: (data.equipmentId + data.commandTypeId).toLowerCase()
                                }
                            });
                        }
                    }
                    handler(data);
                }
            };
        }).pipe(
            catchError(error => {
                console.error('Error occurred in message observable:', error);
                return throwError(() => error);
            }),
            takeUntil(this.onDestroy$)
        );
    }

    getWorkerMessageObservable(): Observable<any> {
        return this.messageObservable;
    }

    public getSummaryDocument(equipmentId: string, commandTypeId: string) {
        const selectedId = this.storedIds.filter(i => i.id === equipmentId)[0];
        if (!selectedId?.idType || selectedId.idType === 'equipmentId') {
            const docUrl = `${this.rootUrl}summarydocument/equipment/${equipmentId}/${commandTypeId}`;
            const authKey = `Bearer ${localStorage.getItem('access_token')}`;
            this.zone.run(() => {
                this.worker.postMessage({
                    url: docUrl,
                    authKey,
                    equipmentId: equipmentId,
                    commandTypeId: commandTypeId
                });
            });
        } else {
            return throwError(() => {
                const error: any = new Error(`ID provided was not of type Equipment ID`);
                error.timestamp = Date.now();
                return error;
            });
        }
    }

    private getSubject(key: string) {
        return this.subjects.find(element => element.key === key);
    }

    private addSubject(key: string) {
        let subject = this.getSubject(key);
        if (subject) {
            subject.counter++;
        } else {
            subject = new SubscriptionSubject(key);
            subject.counter = 1;
            this.subjects.push(subject);
        }
    }

    private getDocumentIdentifier(equipmentId: string, commandTypeId: string) {
        if (equipmentId && commandTypeId) {
            equipmentId = equipmentId.toLowerCase();
            commandTypeId = commandTypeId.toLowerCase();
            return {
                equipmentId: equipmentId,
                commandTypeId: commandTypeId,
                key: equipmentId + ':' + commandTypeId
            };
        }
        return;
    }

    private isSummaryDocument(commandTypeId: string): boolean {
        return [
            '4EF0FF09-F8B5-4C33-83E2-C4D6F510D14C',
            'DD0E7FA6-F8A6-4F91-8075-00347D45A7AA',
            '08252A85-953D-402C-A9FF-CC87B264F76D'
        ].includes(commandTypeId.toUpperCase());
    }

    public requestHistoricDocument(
        equipmentId: string,
        commandTypeId: string,
        from?: Date | string,
        to?: Date | string,
        max?: number | null
    ): Observable<any> {
        const selectedId = this.storedIds.filter(i => i.id === equipmentId)[0];
        let docUrl;
        if (selectedId && selectedId.idType && selectedId.idType === 'customerId') {
            docUrl = `${this.rootUrl}historicaldocument/customer/${equipmentId}/${commandTypeId}/`;
        } else if (selectedId && selectedId.idType && selectedId.idType === 'locationId') {
            docUrl = `${this.rootUrl}historicaldocument/location/${equipmentId}/${commandTypeId}/`;
        } else {
            docUrl = `${this.rootUrl}historicaldocument/equipment/${equipmentId}/${commandTypeId}/`;
        }
        if (from && to && max) {
            docUrl = docUrl + `${new Date(from).toISOString().replace('Z', '')}/${max}`;
        } else if (from && !to && max) {
            docUrl = docUrl + `${new Date(from).toISOString().replace('Z', '')}/${max}`;
        } else if (from && !to && !max) {
            docUrl = docUrl + `${new Date(from).toISOString().replace('Z', '')}`;
        } else if (from && to && !max) {
            docUrl = docUrl + `${new Date(from).toISOString().replace('Z', '')}`;
        } else if (!from && !to && max) {
            docUrl = docUrl + `${max}`;
        }
        return this.http.get<any>(docUrl);
    }

    // call this method(to deallocate memory) from component, that use realtime gateway service
    public cleanup(): void {
        this.worker?.terminate();
        this.rtgWorker?.terminate();
        this.onDestroy$?.next();
        this.onDestroy$?.complete();
        this.messageSubject.complete();
    }
}
