import { Component, Injector, ElementRef } from '@angular/core';

import { DashletSettingDropdown } from '@models/index';

@Component({
    selector: 'dashlet-setting-dropdown',
    templateUrl: 'dashlet-setting-dropdown.component.html',
    styleUrls: ['./settings.component.scss']
})
export class DashletSettingDropdownComponent {
    data: DashletSettingDropdown | null = null;
    public dataArray: any[] = [];
    constructor(private injector: Injector) {
        this.data = this.injector.get('data');
    }

    public onModelChange(val: string): void {
        this.data.value = val;
    }

    public selectedValue(): void {
        this.data.change.next();
    }

    public onKey(event): void {
        this.dataArray = [];
        this.selectSearch(event.target.value);
    }

    private selectSearch(value: string): void {
        let filter = value.toLowerCase();
        for (let i = 0; i < this.data?.items.length; i++) {
            let option = this.data?.items[i];
            if (option.label.toLowerCase().indexOf(filter) >= 0) {
                this.dataArray.push(option);
            }
        }
    }
}
