<div class="flex-column gap-l">
    <div class="grow justify-space-around">
        <div class="flex gap-m">
            <div class="flex-column text-align-right equipment-detail-labels">
                <div
                    class="mat-body-2 "
                    [ngClass]="{'dashlet-link-underline': configurationEnabled}"
                    matTooltip="The endpoint data summary reports on IP endpoints that are SNMP enabled and excludes endpoints that are gateways and softphones."
                    (click)="configurationEnabled ? openPhoneDataReport() : null"
                >
                    Endpoints
                    <sup><mat-icon>question_mark</mat-icon></sup>
                </div>
                <div class="mat-body-2">Streams</div>
                <div class="mat-body-2">Subnets</div>
                <div class="mat-body-2">Total VLANS</div>
                <div class="mat-body-2">VLANs = 0</div>
            </div>
            <div class="flex-column equipment-detail-values">
                <div class="mat-body-1">{{ dashlet.PhoneDataSummaryData[0].TotalDevices || '---' }}</div>

                <div class="mat-body-1">{{ dashlet.PhoneDataSummaryData[0].TotalStreams || '---' }}</div>

                <div class="mat-body-1">{{ dashlet.PhoneDataSummaryData[0].NumberOfSubnets || '---' }}</div>

                <div class="mat-body-1">{{ dashlet.PhoneDataSummaryData[0].NumberOfVlan || '---' }}</div>

                <div class="mat-body-1">{{ dashlet.PhoneDataSummaryData[0].VlansEqualToZero || '---' }}</div>
            </div>
        </div>
        <div class="flex gap-m">
            <div class="flex-column text-align-right equipment-detail-labels">
                <div class="mat-body-2" >Ethernet Speed &lt; 100 MB</div>
                <div class="mat-body-2">Ethernet &ne; Full Duplex</div>
                <div class="mat-body-2">Audio Priority &ne; 6</div>
                <div class="mat-body-2">Signalling Priority &ne; 6</div>
                <div class="mat-body-2">DSCP &ne; 46</div>
            </div>
            <div class="flex-column equipment-detail-values">
                <div class="mat-body-1">{{ dashlet.PhoneDataSummaryData[0].EthernetSpeedLess100Mb || '---' }}</div>

                <div class="mat-body-1">{{ dashlet.PhoneDataSummaryData[0].EthernetDuplex || '---' }}</div>

                <div class="mat-body-1">{{ dashlet.PhoneDataSummaryData[0].AudioPriority || '---' }}</div>

                <div class="mat-body-1">{{ dashlet.PhoneDataSummaryData[0].SignallingPriority || '---' }}</div>

                <div class="mat-body-1">{{ dashlet.PhoneDataSummaryData[0].DSCPsNotEqualTo46 || '---' }}</div>
            </div>
        </div>
    </div>
    <mat-accordion multi>
        <mat-expansion-panel [expanded]="getTabStateByIndex(0)" (opened)="handleTabOpen(0)" (afterCollapse)="handleTabClose(0)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span
                        class="mat-body-1"
                        matTooltip="It is best practice to have separate voice and data VLANs. End points will default to VLAN 0 if they cannot pick up settings, and as there is no QOS in VLAN 0, this will result in voice degradation. All end points within a given subnet should be on the same VLAN."
                        >VLAN <sup><mat-icon>question_mark</mat-icon></sup></span
                    >
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="flex-column grow gap-m">
                <label class="mat-caption" id="radio-group-label">Group By</label>
                <mat-radio-group aria-labelledby="radio-group-label" class="flex gap-l" color="primary" [(ngModel)]="VlanSelectedTab">
                    <mat-radio-button [value]="0"> Subnet </mat-radio-button>
                    <mat-radio-button [value]="1"> IPNR </mat-radio-button>
                    <mat-radio-button [value]="2"> DHCP </mat-radio-button>
                </mat-radio-group>
                <div *ngIf="VlanSelectedTab === 0">
                    <ng-container *ngTemplateOutlet="VlanSubnetPanel"></ng-container>
                </div>
                <div *ngIf="VlanSelectedTab === 1">
                    <ng-container *ngTemplateOutlet="VlanIpnrPanel"></ng-container>
                </div>
                <div *ngIf="VlanSelectedTab === 2">
                    <ng-container *ngTemplateOutlet="VlanDhcpPanel"></ng-container>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="getTabStateByIndex(1)" (opened)="handleTabOpen(1)" (afterCollapse)="handleTabClose(1)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span
                        class="mat-body-1"
                        matTooltip="In many cases voice degradation is caused by incorrect Ethernet configuration or negotiation. For Ethernet settings, best practice is Ethernet status is set to auto-negotiation, Ethernet Speed is 100 Mbps or 1 Gbps , and Ethernet Duplex is full. Half duplex will most certainly cause issues, even when there is no contention with other applications for bandwidth."
                        >Network <sup><mat-icon>question_mark</mat-icon></sup></span
                    >
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="flex-column grow gap-m">
                <label class="mat-caption" id="radio-group-label">Group By</label>
                <mat-radio-group aria-labelledby="radio-group-label" class="flex gap-l" color="primary" [(ngModel)]="NetworkSelectedTab">
                    <mat-radio-button [value]="0"> Subnet </mat-radio-button>
                    <mat-radio-button [value]="1"> IPNR </mat-radio-button>
                </mat-radio-group>

                <div *ngIf="NetworkSelectedTab === 0">
                    <ng-container *ngTemplateOutlet="NetworkSubnetPanel"></ng-container>
                </div>
                <div *ngIf="NetworkSelectedTab === 1">

                        <ng-container *ngTemplateOutlet="NetworkIpnrPanel"></ng-container>

                </div>

            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="getTabStateByIndex(2)" (opened)="handleTabOpen(2)" (afterCollapse)="handleTabClose(2)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span
                        class="mat-body-1"
                        matTooltip="In some cases voice degradation is caused by incorrect 802.1Q configuration. Best practice is priority 6 for audio and priority 6 for signalling."
                        >Protocol <sup><mat-icon>question_mark</mat-icon></sup></span
                    >
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="flex-column grow gap-m">
                <label class="mat-caption" id="radio-group-label">Group By</label>
                <mat-radio-group aria-labelledby="radio-group-label" class="flex gap-l" color="primary" [(ngModel)]="ProtocolSelectedTab">
                    <mat-radio-button [value]="0"> Subnet </mat-radio-button>
                    <mat-radio-button [value]="1"> IPNR </mat-radio-button>
                </mat-radio-group>

                <div *ngIf="ProtocolSelectedTab === 0">


                        <ng-container *ngTemplateOutlet="ProtocolSubnetPanel"></ng-container>

                </div>
                <div *ngIf="ProtocolSelectedTab === 1">


                        <ng-container *ngTemplateOutlet="ProtocolIpnrPanel"></ng-container>

                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>

<ng-template #VlanSubnetPanel>
    <div class="flex-column gap-m">
        <vsm-smart-alex [type]="['SubnetVlan']" [typeData]="[dashlet.SubnetVlanMapped]"></vsm-smart-alex>

        <dashlet-table
            [loading]="subnetVlanLoading"
            [dataSource]="SubnetVlan.DataSource"
            [columns]="subnetColumns"
            [pagination]="true"
            [pageLength]="10"
            [tableHeightRows]="10"
            [rowExpansionColumns]="vlanExpansionColumns"
            rowExpansionField="VlanSubRowData"
        ></dashlet-table>
    </div>
</ng-template>

<ng-template #VlanIpnrPanel>
    <div class="gap-m flex-column">
        <vsm-smart-alex [type]="['IPNRVlan']" [typeData]="[dashlet.IPNRVlanMapped]"> </vsm-smart-alex>
        <dashlet-table
            [loading]="ipnrVlanLoading"
            [dataSource]="IpnrVlan.DataSource"
            [columns]="ipnrColumns"
            [pagination]="true"
            [pageLength]="10"
            [tableHeightRows]="10"
            [rowExpansionColumns]="vlanExpansionColumns"
            rowExpansionField="VlanSubRowData"
        ></dashlet-table>
    </div>
</ng-template>

<ng-template #VlanDhcpPanel>
    <div class="gap-m flex-column">
        <vsm-smart-alex [type]="['DHCPVlan']" [typeData]="[dashlet.DHCPVlanMapped]"></vsm-smart-alex>
        <dashlet-table
            [loading]="dhcpVlanLoading"
            [dataSource]="DhcpVlan.DataSource"
            [columns]="dhcpColumns"
            [pagination]="true"
            [pageLength]="10"
            [tableHeightRows]="10"
            [rowExpansionColumns]="vlanExpansionColumns"
            rowExpansionField="VlanSubRowData"
        ></dashlet-table>
    </div>
</ng-template>

<ng-template #NetworkSubnetPanel>
    <div class="gap-l flex-column">
        <vsm-smart-alex [type]="['SubnetNetwork']" [typeData]="[dashlet.SubnetNetworkMapped]"></vsm-smart-alex>
        <div class="flex gap-l grow">
            <div class="flex-column gap-m grow">
                <div class="mat-body-2">Ethernet Status</div>
                <div>
                    <dashlet-ratio-bar emptyTitle="No data available for Network Interface">
                        <dashlet-ratio-bar-item
                            [value]="dashlet.chartEthernetStatus.datasets.data[0]"
                            [color]="dashlet.chartEthernetStatus.datasets.backgroundColor[0]"
                            [matTooltip]="dashlet.chartEthernetStatus.labels[0]"
                        ></dashlet-ratio-bar-item>
                        <dashlet-ratio-bar-item
                            [value]="dashlet.chartEthernetStatus.datasets.data[1]"
                            [color]="dashlet.chartEthernetStatus.datasets.backgroundColor[1]"
                            [matTooltip]="dashlet.chartEthernetStatus.labels[1]"
                        ></dashlet-ratio-bar-item>
                    </dashlet-ratio-bar>
                </div>
                <div class="justify-space-between">
                    <div *ngIf="dashlet.chartEthernetStatus.datasets.data[0] > 0">
                        <span class="mat-h2">{{ dashlet.chartEthernetStatus.datasets.data[0] }}</span>
                        <span class="mat-h3"> OK</span>
                    </div>
                    <div *ngIf="dashlet.chartEthernetStatus.datasets.data[1] > 0">
                        <span class="mat-h2">{{ dashlet.chartEthernetStatus.datasets.data[1] }}</span>
                        <span class="mat-h3">{{ dashlet.chartEthernetStatus.labels[1] }}</span>
                    </div>
                </div>
            </div>
            <div class="flex-column gap-m grow">
                <div class="mat-body-2">Ethernet Duplex</div>
                <div>
                    <dashlet-ratio-bar emptyTitle="No data available for Network Interface">
                        <dashlet-ratio-bar-item
                            [value]="dashlet.chartEthernetDuplex.datasets.data[0]"
                            [color]="dashlet.chartEthernetDuplex.datasets.backgroundColor[0]"
                            [matTooltip]="dashlet.chartEthernetDuplex.labels[0]"
                        ></dashlet-ratio-bar-item>
                        <dashlet-ratio-bar-item
                            [value]="dashlet.chartEthernetDuplex.datasets.data[1]"
                            [color]="dashlet.chartEthernetDuplex.datasets.backgroundColor[1]"
                            [matTooltip]="dashlet.chartEthernetDuplex.labels[1]"
                        ></dashlet-ratio-bar-item>
                    </dashlet-ratio-bar>
                </div>
                <div class="justify-space-between">
                    <div *ngIf="dashlet.chartEthernetDuplex.datasets.data[0] > 0">
                        <span class="mat-h2">{{ dashlet.chartEthernetDuplex.datasets.data[0] }}</span>
                        <span class="mat-h3"> OK</span>
                    </div>
                    <div *ngIf="dashlet.chartEthernetDuplex.datasets.data[1] > 0">
                        <span class="mat-h2">{{ dashlet.chartEthernetDuplex.datasets.data[1] }}</span>
                        <span class="mat-h3">{{ dashlet.chartEthernetDuplex.labels[1] }}</span>
                    </div>
                </div>
            </div>
            <div class="gap-m flex-column grow">
                <div class="mat-body-2">Ethernet Speed</div>
                <div>
                    <dashlet-ratio-bar emptyTitle="No data available for Network Interface">
                        <dashlet-ratio-bar-item
                            [value]="dashlet.chartEthernetSpeed.datasets.data[0]"
                            [color]="dashlet.chartEthernetSpeed.datasets.backgroundColor[0]"
                            [matTooltip]="dashlet.chartEthernetSpeed.labels[0]"
                        ></dashlet-ratio-bar-item>
                        <dashlet-ratio-bar-item
                            [value]="dashlet.chartEthernetSpeed.datasets.data[1]"
                            [color]="dashlet.chartEthernetSpeed.datasets.backgroundColor[1]"
                            [matTooltip]="dashlet.chartEthernetSpeed.labels[1]"
                        ></dashlet-ratio-bar-item>
                    </dashlet-ratio-bar>
                </div>
                <div class="justify-space-between">
                    <div *ngIf="dashlet.chartEthernetSpeed.datasets.data[0] > 0">
                        <span class="mat-h2">{{ dashlet.chartEthernetSpeed.datasets.data[0] }}</span>
                        <span class="mat-h3"> OK</span>
                    </div>
                    <div *ngIf="dashlet.chartEthernetSpeed.datasets.data[1] > 0">
                        <span class="mat-h2">{{ dashlet.chartEthernetSpeed.datasets.data[1] }}</span>
                        <span class="mat-h3">{{ dashlet.chartEthernetSpeed.labels[1] }}</span>
                    </div>
                </div>
            </div>
        </div>
        <dashlet-table
            [loading]="!SubnetNetworkDataNoResult"
            [dataSource]="SubnetNetwork.DataSource"
            [columns]="subnetNetworkColumns"
            [pagination]="true"
            [pageLength]="10"
            [tableHeightRows]="10"
            [rowExpansionColumns]="networkExpansionColumns"
            rowExpansionField="NetworkSubRow"
        ></dashlet-table>
    </div>
</ng-template>

<ng-template #NetworkIpnrPanel>
    <div class="gap-l flex-column">
        <vsm-smart-alex [type]="['SubnetNetwork']" [typeData]="[dashlet.SubnetNetworkMapped]"></vsm-smart-alex>

        <div class="flex gap-l grow">
            <div class="flex-column gap-m grow">
                <div class="mat-body-2">Ethernet Status</div>
                <div>
                    <dashlet-ratio-bar emptyTitle="No data available for Network Interface">
                        <dashlet-ratio-bar-item
                            [value]="dashlet.chartEthernetStatus.datasets.data[0]"
                            [color]="dashlet.chartEthernetStatus.datasets.backgroundColor[0]"
                            [matTooltip]="dashlet.chartEthernetStatus.labels[0]"
                        ></dashlet-ratio-bar-item>
                        <dashlet-ratio-bar-item
                            [value]="dashlet.chartEthernetStatus.datasets.data[1]"
                            [color]="dashlet.chartEthernetStatus.datasets.backgroundColor[1]"
                            [matTooltip]="dashlet.chartEthernetStatus.labels[1]"
                        ></dashlet-ratio-bar-item>
                    </dashlet-ratio-bar>
                </div>
                <div class="justify-space-between">
                    <div *ngIf="dashlet.chartEthernetStatus.datasets.data[0] > 0">
                        <span class="mat-h2">{{ dashlet.chartEthernetStatus.datasets.data[0] }}</span>
                        <span class="mat-h3"> OK</span>
                    </div>
                    <div *ngIf="dashlet.chartEthernetStatus.datasets.data[1] > 0">
                        <span class="mat-h2">{{ dashlet.chartEthernetStatus.datasets.data[1] }}</span>
                        <span class="mat-h3">{{ dashlet.chartEthernetStatus.labels[1] }}</span>
                    </div>
                </div>
            </div>
            <div class="flex-column gap-m grow">
                <div class="mat-body-2">Ethernet Duplex</div>
                <div>
                    <dashlet-ratio-bar emptyTitle="No data available for Network Interface">
                        <dashlet-ratio-bar-item
                            [value]="dashlet.chartEthernetDuplex.datasets.data[0]"
                            [color]="dashlet.chartEthernetDuplex.datasets.backgroundColor[0]"
                            [matTooltip]="dashlet.chartEthernetDuplex.labels[0]"
                        ></dashlet-ratio-bar-item>
                        <dashlet-ratio-bar-item
                            [value]="dashlet.chartEthernetDuplex.datasets.data[1]"
                            [color]="dashlet.chartEthernetDuplex.datasets.backgroundColor[1]"
                            [matTooltip]="dashlet.chartEthernetDuplex.labels[1]"
                        ></dashlet-ratio-bar-item>
                    </dashlet-ratio-bar>
                </div>
                <div class="justify-space-between">
                    <div *ngIf="dashlet.chartEthernetDuplex.datasets.data[0] > 0">
                        <span class="mat-h2">{{ dashlet.chartEthernetDuplex.datasets.data[0] }}</span>
                        <span class="mat-h3"> OK</span>
                    </div>
                    <div *ngIf="dashlet.chartEthernetDuplex.datasets.data[1] > 0">
                        <span class="mat-h2">{{ dashlet.chartEthernetDuplex.datasets.data[1] }}</span>
                        <span class="mat-h3">{{ dashlet.chartEthernetDuplex.labels[1] }}</span>
                    </div>
                </div>
            </div>
            <div class="gap-m flex-column grow">
                <div class="mat-body-2">Ethernet Speed</div>
                <div>
                    <dashlet-ratio-bar emptyTitle="No data available for Network Interface">
                        <dashlet-ratio-bar-item
                            [value]="dashlet.chartEthernetSpeed.datasets.data[0]"
                            [color]="dashlet.chartEthernetSpeed.datasets.backgroundColor[0]"
                            [matTooltip]="dashlet.chartEthernetSpeed.labels[0]"
                        ></dashlet-ratio-bar-item>
                        <dashlet-ratio-bar-item
                            [value]="dashlet.chartEthernetSpeed.datasets.data[1]"
                            [color]="dashlet.chartEthernetSpeed.datasets.backgroundColor[1]"
                            [matTooltip]="dashlet.chartEthernetSpeed.labels[1]"
                        ></dashlet-ratio-bar-item>
                    </dashlet-ratio-bar>
                </div>
                <div class="justify-space-between">
                    <div *ngIf="dashlet.chartEthernetSpeed.datasets.data[0] > 0">
                        <span class="mat-h2">{{ dashlet.chartEthernetSpeed.datasets.data[0] }}</span>
                        <span class="mat-h3"> OK</span>
                    </div>
                    <div *ngIf="dashlet.chartEthernetSpeed.datasets.data[1] > 0">
                        <span class="mat-h2">{{ dashlet.chartEthernetSpeed.datasets.data[1] }}</span>
                        <span class="mat-h3">{{ dashlet.chartEthernetSpeed.labels[1] }}</span>
                    </div>
                </div>
            </div>
        </div>

        <dashlet-table
            [loading]="!SubnetNetworkDataNoResult"
            [dataSource]="IpnrNetwork.DataSource"
            [columns]="ipnrNetworkColumns"
            [pagination]="true"
            [pageLength]="10"
            [tableHeightRows]="10"
            [rowExpansionColumns]="networkExpansionColumns"
            rowExpansionField="NetworkSubRow"
        ></dashlet-table>
    </div>
</ng-template>

<ng-template #ProtocolSubnetPanel>
    <div class="gap-l flex-column">
        <vsm-smart-alex [type]="['SubnetProtocol']" [typeData]="[dashlet.SubnetProtocolMapped]"></vsm-smart-alex>
        <div class="flex gap-l grow">
            <div class="flex-column gap-m grow">
                <div class="mat-body-2">Audio Priority</div>
                <div>
                    <dashlet-ratio-bar emptyTitle="No data available for Network Interface">
                        <dashlet-ratio-bar-item
                            [value]="dashlet.chartAudioPriority.datasets.data[0]"
                            [color]="dashlet.chartAudioPriority.datasets.backgroundColor[0]"
                            [matTooltip]="dashlet.chartAudioPriority.labels[0]"
                        ></dashlet-ratio-bar-item>
                        <dashlet-ratio-bar-item
                            [value]="dashlet.chartAudioPriority.datasets.data[1]"
                            [color]="dashlet.chartAudioPriority.datasets.backgroundColor[1]"
                            [matTooltip]="dashlet.chartAudioPriority.labels[1]"
                        ></dashlet-ratio-bar-item>
                    </dashlet-ratio-bar>
                </div>
                <div class="justify-space-between">
                    <div *ngIf="dashlet.chartAudioPriority.datasets.data[0] > 0">
                        <span class="mat-h2">{{ dashlet.chartAudioPriority.datasets.data[0] }}</span>
                        <span class="mat-h3"> OK</span>
                    </div>
                    <div *ngIf="dashlet.chartAudioPriority.datasets.data[1] > 0">
                        <span class="mat-h2">{{ dashlet.chartAudioPriority.datasets.data[1] }}</span>
                        <span class="mat-h3">{{ dashlet.chartAudioPriority.labels[1] }}</span>
                    </div>
                </div>
            </div>
            <div class="flex-column gap-m grow">
                <div class="mat-body-2">Signalling Priority</div>
                <div>
                    <dashlet-ratio-bar emptyTitle="No data available for Network Interface">
                        <dashlet-ratio-bar-item
                            [value]="dashlet.chartSignallingPriority.datasets.data[0]"
                            [color]="dashlet.chartSignallingPriority.datasets.backgroundColor[0]"
                            [matTooltip]="dashlet.chartSignallingPriority.labels[0]"
                        ></dashlet-ratio-bar-item>
                        <dashlet-ratio-bar-item
                            [value]="dashlet.chartSignallingPriority.datasets.data[1]"
                            [color]="dashlet.chartSignallingPriority.datasets.backgroundColor[1]"
                            [matTooltip]="dashlet.chartSignallingPriority.labels[1]"
                        ></dashlet-ratio-bar-item>
                    </dashlet-ratio-bar>
                </div>
                <div class="justify-space-between">
                    <div *ngIf="dashlet.chartSignallingPriority.datasets.data[0] > 0">
                        <span class="mat-h2">{{ dashlet.chartSignallingPriority.datasets.data[0] }}</span>
                        <span class="mat-h3"> OK</span>
                    </div>
                    <div *ngIf="dashlet.chartSignallingPriority.datasets.data[1] > 0">
                        <span class="mat-h2">{{ dashlet.chartSignallingPriority.datasets.data[1] }}</span>
                        <span class="mat-h3">{{ dashlet.chartSignallingPriority.labels[1] }}</span>
                    </div>
                </div>
            </div>
        </div>
        <dashlet-table
            [loading]="!SubnetProtocolDataNoResult"
            [dataSource]="SubnetProtocol.DataSource"
            [columns]="subnetProtocolColumns"
            [pagination]="true"
            [pageLength]="10"
            [tableHeightRows]="10"
            [rowExpansionColumns]="networkExpansionColumns"
            rowExpansionField="ProtocolSubRow"
        ></dashlet-table>
    </div>
</ng-template>

<ng-template #ProtocolIpnrPanel>
    <div class="gap-l flex-column">
        <vsm-smart-alex [type]="['SubnetProtocol']" [typeData]="[dashlet.SubnetProtocolMapped]"></vsm-smart-alex>
        <div class="flex gap-l grow">
            <div class="flex-column gap-m grow">
                <div class="mat-body-2">Audio Priority</div>
                <div>
                    <dashlet-ratio-bar emptyTitle="No data available for Network Interface">
                        <dashlet-ratio-bar-item
                            [value]="dashlet.chartAudioPriority.datasets.data[0]"
                            [color]="dashlet.chartAudioPriority.datasets.backgroundColor[0]"
                            [matTooltip]="dashlet.chartAudioPriority.labels[0]"
                        ></dashlet-ratio-bar-item>
                        <dashlet-ratio-bar-item
                            [value]="dashlet.chartAudioPriority.datasets.data[1]"
                            [color]="dashlet.chartAudioPriority.datasets.backgroundColor[1]"
                            [matTooltip]="dashlet.chartAudioPriority.labels[1]"
                        ></dashlet-ratio-bar-item>
                    </dashlet-ratio-bar>
                </div>
                <div class="justify-space-between">
                    <div *ngIf="dashlet.chartAudioPriority.datasets.data[0] > 0">
                        <span class="mat-h2">{{ dashlet.chartAudioPriority.datasets.data[0] }}</span>
                        <span class="mat-h3"> OK</span>
                    </div>
                    <div *ngIf="dashlet.chartAudioPriority.datasets.data[1] > 0">
                        <span class="mat-h2">{{ dashlet.chartAudioPriority.datasets.data[1] }}</span>
                        <span class="mat-h3">{{ dashlet.chartAudioPriority.labels[1] }}</span>
                    </div>
                </div>
            </div>
            <div class="flex-column gap-m grow">
                <div class="mat-body-2">Signalling Priority</div>
                <div>
                    <dashlet-ratio-bar emptyTitle="No data available for Network Interface">
                        <dashlet-ratio-bar-item
                            [value]="dashlet.chartSignallingPriority.datasets.data[0]"
                            [color]="dashlet.chartSignallingPriority.datasets.backgroundColor[0]"
                            [matTooltip]="dashlet.chartSignallingPriority.labels[0]"
                        ></dashlet-ratio-bar-item>
                        <dashlet-ratio-bar-item
                            [value]="dashlet.chartSignallingPriority.datasets.data[1]"
                            [color]="dashlet.chartSignallingPriority.datasets.backgroundColor[1]"
                            [matTooltip]="dashlet.chartSignallingPriority.labels[1]"
                        ></dashlet-ratio-bar-item>
                    </dashlet-ratio-bar>
                </div>
                <div class="justify-space-between">
                    <div *ngIf="dashlet.chartSignallingPriority.datasets.data[0] > 0">
                        <span class="mat-h2">{{ dashlet.chartSignallingPriority.datasets.data[0] }}</span>
                        <span class="mat-h3"> OK</span>
                    </div>
                    <div *ngIf="dashlet.chartSignallingPriority.datasets.data[1] > 0">
                        <span class="mat-h2">{{ dashlet.chartSignallingPriority.datasets.data[1] }}</span>
                        <span class="mat-h3">{{ dashlet.chartSignallingPriority.labels[1] }}</span>
                    </div>
                </div>
            </div>
        </div>
        <dashlet-table
            [loading]="!IpnrProtocolDataNoResult"
            [dataSource]="IpnrProtocol.DataSource"
            [columns]="ipnrProtocolColumns"
            [pagination]="true"
            [pageLength]="10"
            [tableHeightRows]="10"
            [rowExpansionColumns]="networkExpansionColumns"
            rowExpansionField="ProtocolSubRow"
        ></dashlet-table>
    </div>

</ng-template>

<ng-template #vsmLoadingTemplate>
    <vsm-dashlet-loading></vsm-dashlet-loading>
</ng-template>
