import {
    ACMAESServer,
    CPUUsageData,
    Customer,
    CustomerLogo,
    Dashlet,
    DiskUsageData,
    DMCCData,
    Equipment,
    LicenceData,
    Link,
    Location,
    MemoryUsageData,
    NetworkConnectivityData,
    ServiceStatus,
    SwitchLink,
    Threshold,
    TSAPIData,
    TSAPITSDIBuffer
    } from '@models/index';

export enum AESServiceStatusServiceFilter {
	AsaiLinkManger,
	CvlanService,
	DlgService,
	DmccService,
	SessionService,
	TransportService,
	TsapiService
}

export class AESServiceStatus extends ServiceStatus {
	private static readonly serviceFilter: string[] = [
		'AsaiLinkManager',
		'CvlanService',
		'DlgService',
		'DmccService',
		'SessionService',
		'TransportService',
		'TsapiService'
	];

	private constructor(serviceName: string, status: string) {
		super(serviceName, '', '', status, '');
	}

	static createAESServiceStatus(serviceName: string, status: string) {
		const index = this.serviceFilter.indexOf(serviceName);
		if (index != -1) {
			switch (index) {
				case AESServiceStatusServiceFilter.AsaiLinkManger:
					serviceName = 'ASAI';
					break;
				case AESServiceStatusServiceFilter.CvlanService:
					serviceName = 'CVLAN';
					break;
				case AESServiceStatusServiceFilter.DlgService:
					serviceName = 'DLG';
					break;
				case AESServiceStatusServiceFilter.DmccService:
					serviceName = 'DMCC';
					break;
				case AESServiceStatusServiceFilter.SessionService:
					serviceName = 'DMCC';
					break;
				case AESServiceStatusServiceFilter.TransportService:
					serviceName = 'TRANSPORT';
					break;
				case AESServiceStatusServiceFilter.TsapiService:
					serviceName = 'TSAPI';
					break;
			}
			return new AESServiceStatus(serviceName, status);
		} else {
			return null;
		}
	}
}