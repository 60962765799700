import { DashletSetting } from '@models/index';

export class DashletSettingText extends DashletSetting {
    public value: string = '';
    public maxLength?: number;

    constructor(maxLength?: number) {
        super();
        this.maxLength = maxLength;
    }

    public loadState(obj: { [key: string]: any }): void {
        this.value = obj[this.stateKey] || null;
    }

    public saveState(obj: { [key: string]: any }): void {
        obj[this.stateKey] = this.value;
    }
}
