/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable no-undef */
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { DashletNavChangeEvent, DashletNavType, DashletSettingsForm } from '@models/index';
import { DashletSettingsService, DashboardService, TileGridService, NotificationService } from '@services/index';
import { Dashlet } from '@models/index';
import { environment } from '@environments/environment';

@Component({
    selector: 'settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {
    @Output() dashboardChange: EventEmitter<null> = new EventEmitter();
    @Output() dashletChange: EventEmitter<null> = new EventEmitter();

    form!: DashletSettingsForm | null;
    private pDashletNavSelected!: Dashlet | null;
    get dashletNavSelected(): Dashlet | null {
        return this.pDashletNavSelected;
    }
    @Input('dashletNavSelected') set dashletNavSelected(value: Dashlet | null) {
        this.pDashletNavSelected = value;
        if (!value) {
            this.openDashboardSettings();
        } else {
            this.openDashletSettings(value);
        }
    }
    @Output('dashletNavSelectedChange') dashletNavSelectedChange = new EventEmitter<Dashlet>();

    private dispose = new Subject<null>();

    constructor(
        private settingsService: DashletSettingsService,
        private dashboardService: DashboardService,
        public tileGridService: TileGridService,
        private notificationService: NotificationService
    ) {}

    public ngOnInit(): void {
        if (!this.dashletNavSelected) {
            this.openDashboardSettings();
        }

        this.dashboardService.dashlets.subscribe(dashlets => {
            //clear selection if no longer avail
            if (this.dashletNavSelected) {
                if (dashlets?.every(d => d.id === this.dashletNavSelected.id)) {
                    this.hideSettings();
                }
                this.openDashletSettings(this.dashletNavSelected);
            }
        });
    }

    public ngOnDestroy(): void {
        this.dispose.complete();
        this.form.destroySubscripions();
    }

    public onSettingChange(e): void {
        const settings = {};
        this.form!.saveState(settings);
        const dashlet = this.dashletNavSelected;
        if (dashlet) {
            try {
                dashlet.applySettings(settings);
            } catch (e) {
                this.notificationService.notify('Failed to apply settings to dashlet', 'error');
            }
            this.dashletChange.emit();
        } else {
            this.dashboardService.applyDashboardSettings(settings);
            this.dashboardChange.emit();
        }
    }

    public onNavChange(event: DashletNavChangeEvent): void {
        if (event.type === DashletNavType.Dashlet) {
            this.dashletNavSelected = event.dashlet;
        } else {
            this.dashletNavSelected = null;
        }
        this.dashletNavSelectedChange.emit(this.dashletNavSelected);
    }

    private openDashboardSettings(): void {
        try {
            this.form = this.settingsService.createDashboardSettingsForm();
        } catch (ex) {
            if (!environment.production) console.error(ex);
            this.hideSettings();
        }

        const settings = this.dashboardService.getDashboardSettings();
        if (settings) {
            this.form!.loadState(settings);
        }
    }

    private openDashletSettings(dashlet: Dashlet) {
        try {
            this.form = this.settingsService.createSettingsForm(dashlet);
        } catch (ex) {
            if (!environment.production) console.error(ex);
            this.hideSettings();
        }
        const settings = dashlet.getSettings();
        if (settings) {
            this.form!.loadState(settings);
        }
    }

    private hideSettings(): void {
        this.form = null;
    }
}
