import {
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingDropdownGrouped,
    DashletSettingList,
    DashletSettingText,
    SelectItem
} from '@models/index';
import { DashletSettingsService, NotificationService } from '@services/index';

export class DashletSettingsFormGenesysDetailsSummary extends DashletSettingsForm {
    private customer!: DashletSettingDropdown;
    private equipment!: DashletSettingDropdown;
    private location: DashletSettingDropdown;
    public period: DashletSettingDropdown;

    constructor(
        private readonly settingsService: DashletSettingsService,
        private readonly notificationService: NotificationService
    ) {
        super();
        this.init();

        this.loadCustomers();
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);

        if (this.customer.value) {
            this.loadLocations(this.customer.value);
        }
        if (this.location.value) {
            this.loadequipments(this.location.value);
        }
    }

    private init(): void {
        const group = new DashletSettingsGroup();
        const s: any = (group.settings = []);
        const period = (this.period = new DashletSettingDropdown());

        period.label = 'Summary Period';
        period.stateKey = 'summaryPeriod';
        period.items = [];

        const daily = new SelectItem();
        daily.label = '24 hours';
        daily.value = '24';
        period.items.push(daily);

        const twelveHourly = new SelectItem();
        twelveHourly.label = '12 hours';
        twelveHourly.value = '12';

        period.items.push(twelveHourly);

        const sixHourly = new SelectItem();
        sixHourly.label = '6 hours';
        sixHourly.value = '6';
        period.items.push(sixHourly);

        const hourly = new SelectItem();
        hourly.label = '1 hour';
        hourly.value = '1';
        period.items.push(hourly);
        s.push(period);

        const customer = (this.customer = new DashletSettingDropdown());
        customer.label = 'Customer';
        customer.stateKey = 'customer';
        customer.disabled = true;
        customer.change.subscribe(() => {
            this.clearAndDisable(this.equipment);
            this.clearAndDisable(this.location);
            this.loadLocations(customer.value);
        });
        s.push(customer);

        const location = (this.location = new DashletSettingDropdown());
        location.label = 'Location';
        location.stateKey = 'location';
        location.disabled = true;
        location.change.subscribe(() => {
            this.clearAndDisable(this.equipment);
            this.loadequipments(location.value);
        });
        s.push(location);

        const equipment = (this.equipment = new DashletSettingDropdown());
        equipment.label = 'Service';
        equipment.stateKey = 'equipment';
        equipment.disabled = true;
        s.push(equipment);

        const customName = new DashletSettingText(40);
        customName.label = 'Custom Dashlet Name';
        customName.stateKey = 'customName';
        s.push(customName);

        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);
        this.settingsService.getUserAssociatedCustomers().subscribe(
            customers => {
                this.customer.items = customers;
                this.customer.loadingComplete();
            },
            () => {
                this.customer.loadingComplete();
                this.customer.disabled = true;
            }
        );
    }

    private loadLocations(customer: string): void {
        this.enableAndLoading(this.location);

        this.settingsService.getVirtualLocations(customer).subscribe(
            locations => {
                this.location.items = locations;
                this.location.loadingComplete();
            },
            () => {
                this.location.loadingComplete();
                this.location.disabled = true;
            }
        );
    }

    private loadequipments(locationId: string): void {
        this.enableAndLoading(this.equipment);
        this.settingsService.getGenesysCloudServices(locationId).subscribe(
            receivers => {
                this.equipment.items = receivers;
                this.equipment.loadingComplete();
            },
            () => {
                this.equipment.loadingComplete();
                this.equipment.disabled = true;
            }
        );
    }

    private enableAndLoading(
        widget: DashletSettingDropdownGrouped | DashletSettingDropdown | DashletSettingList<string>
    ) {
        widget.disabled = false;
        widget.loadingBegin();
    }

    private clearAndDisable(
        widget: DashletSettingDropdownGrouped | DashletSettingDropdown | DashletSettingList<string>
    ) {
        widget.disabled = true;
        widget.items = [];
        if (widget instanceof DashletSettingList) widget.values = [];
        else widget.value = null;
    }
}
