/* eslint-disable no-undef */
import * as Actions from '@actions/index';
import { AccountService, DashletService, ThresholdService, TileGridService, TimeoutService } from '@services/index';
import { AppState, selectEntity } from '@reducers/index';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CustomerLogo, DashletSystemHealthIpOffice, Threshold } from '@models/index';
import { select, Store } from '@ngrx/store';
import { ThresholdItemComponent } from '../threshold-item/threshold-item.component';
import { DashletSectionProcessorMemoryComponent } from '../dashlet-section-processor-memory/dashlet-section-processor-memory.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-dashlet-system-health-ip-office',
    templateUrl: './dashlet-system-health-ip-office.component.html',
    styleUrls: ['./dashlet-system-health-ip-office.component.scss']
})
export class DashletSystemHealthIpOfficeComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletSystemHealthIpOffice;
    @ViewChild('thresholdItem') thresholdItem: ThresholdItemComponent;
    @ViewChild('processorMemory') processorMemory: DashletSectionProcessorMemoryComponent;

    private destroy$ = new Subject<void>();

    openSubcontainer = '';
    openSubcontainerSeverity = 0;

    selectedField: string;
    selectedThreshold: Threshold;

    constructor(
        private dashletService: DashletService,
        private accountService: AccountService,
        private thresholdService: ThresholdService,
        private tileGridService: TileGridService,
        public timeoutService: TimeoutService,
        private store$: Store<AppState>
    ) {}

    ngOnInit() {
        if (this.dashlet.configured) {
            this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
            this.store$
                .pipe(select(selectEntity(this.dashlet.customer.customerId)), takeUntil(this.destroy$))
                .subscribe(logo => {
                    if (logo) {
                        this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                    }
                });
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getSeverityColor(severity: number): string {
        return this.dashletService.getSeverityColor(severity);
    }

    closeDetails() {
        this.openSubcontainer = null;
        this.dashlet.displayHeader = true;
    }

    openThresholdAlerts(elementId: string, commandTypeId: string) {
        this.openSubcontainer = 'createThreshold';
        this.dashlet.displayHeader = false;
        this.selectedThreshold = new Threshold();
        this.selectedThreshold.commandTypeId = commandTypeId;
        this.selectedThreshold.field = elementId;
        this.selectedThreshold.whereCreated = 'Dashboard';
    }

    openAlarmIconThreshold() {
        this.thresholdService.openThresholdManagement(this.accountService.getUserDetails().EntityId);
    }

    onSubmit() {
        this.thresholdItem.onSubmit().subscribe(result => {
            if (result) {
                this.selectedThreshold.dapThresholdId = result['DapThresholdId'];
                this.dashlet.thresholds.push(this.selectedThreshold);
                switch (this.selectedThreshold.field) {
                    case 'processor':
                        ++this.processorMemory.cpuUsageData.thresholdsNumber;
                        break;
                    case 'memory':
                        ++this.processorMemory.memoryUsageData.thresholdsNumber;
                        break;
                    default:
                        break;
                }
                this.tileGridService.thresholdCreated(
                    this.selectedThreshold.name + ' threshold added via ' + this.dashlet.title
                );
            } else {
                this.tileGridService.thresholdCreated('Threshold add failed');
            }
        });
        this.closeDetails();
    }

    saveEdit() {
        this.thresholdItem.saveEdit().subscribe(result => {
            if (result) {
                this.tileGridService.thresholdCreated(
                    this.selectedThreshold.name + ' threshold edited via ' + this.dashlet.title
                );
            } else {
                this.tileGridService.thresholdCreated('Threshold edit failed');
            }
        });
        this.closeDetails();
    }
}
