import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

export const GetNotifyProcessUpCpuMemData = createAction(
  '[ProcessUpCpuMemData] Get ProcessUpCpuMemDatas',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetNotifyProcessUpCpuMemDataSuccess = createAction(
  '[ProcessUpCpuMemData] Get ProcessUpCpuMemDatas Success',
  props<{ data: RealTimeNotify }>()
);

export const GetNotifyProcessUpCpuMemDataFailure = createAction(
  '[ProcessUpCpuMemData] Get ProcessUpCpuMemDatas Failure',
  props<{ error: any }>()
);


export const GetProcessUpCpuMemData = createAction(
  '[ProcessUpCpuMemData] Get ProcessUpCpuMemDatas',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetProcessUpCpuMemDataSuccess = createAction(
  '[ProcessUpCpuMemData] Get ProcessUpCpuMemDatas Success',
  props<{ data: any}>()
);

export const GetProcessUpCpuMemDataFailure = createAction(
  '[ProcessUpCpuMemData] Get ProcessUpCpuMemDatas Failure',
  props<{ error: any }>()
);

// timer
export const GetProcessUpCpuMemDataTimer = createAction(
  '[ProcessUpCpuMemData] Get ProcessUpCpuMemDataTimer',
  props<{ equipmentId: string, command: string }>()
);

export const GetProcessUpCpuMemDataTimerSuccess = createAction(
  '[ProcessUpCpuMemData] Get ProcessUpCpuMemDataTimer Success',
  props<{ data: any }>() 
);

export const GetProcessUpCpuMemDataTimerFailure = createAction(
  '[ProcessUpCpuMemData] Get ProcessUpCpuMemDataTimer Failure',
  props<{ error: any }>()
);

// Historic data
export const GetHistoricCpuMemData = createAction(
  '[ProcessUpCpuMemData] Get HistoricCpuMemData',
  props<{ equipmentId: string, commandTypeId: string, from?: Date | string, to?: Date | string, max?: number | null }>()
);

export const GetHistoricCpuMemDataSuccess = createAction(
  '[ProcessUpCpuMemData] Get HistoricCpuMemData Success',
  props<{ data: any}>()
);

export const GetHistoricCpuMemDataFailure = createAction(
  '[ProcessUpCpuMemData] Get HistoricCpuMemData Failure',
  props<{ error: any }>()
);
