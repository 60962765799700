import { GridsterItem } from 'angular-gridster2';
import { DashletType } from '@models/dashboard/enums/DashletType';
import { Subject } from 'rxjs';
import { CustomerLogo } from '@models/dashboard/Customer';

interface DashletSize {
    id: number;
    label: string;
    rows: number;
    cols: number;
}

export abstract class Dashlet {
    static readonly PERSIST_ID_KEY = 'id';
    static readonly PERSIST_TYPE_KEY = 'type';
    static readonly PERSIST_SIZE_ID_KEY = 'sizeId';
    static readonly PERSIST_X_KEY = 'x';
    static readonly PERSIST_Y_KEY = 'y';
    static readonly PERSIST_SETTINGS_TOPIC_KEY = 'settings';
    static readonly PERSIST_DRAG_ENABLED_KEY = 'dragEnabled';

    static readonly SETTING_CUSTOM_NAME_TAG_KEY = 'customNameTag';

    private size!: DashletSize;
    private sizeExpansion = { w: 0, h: 0 };

    protected settings!: { [key: string]: any };
    serialized!: any;
    id!: string;
    type!: DashletType;
    title!: string;
    generatedNameTag!: string;
    customNameTag!: string;
    selected!: boolean;
    // [TODO]: Need to check this again
    tile!: GridsterItem;
    configured!: boolean;
    sizes!: DashletSize[];
    totalSizeChanged = new Subject<{ w: number; h: number }>();
    logo!: CustomerLogo | null;
    isDataOld!: boolean;
    showApiStatus!: boolean;

    displayHeader = true;

    protected constructor() {}

    abstract dispose(): void;
    abstract resetData(): void;

    getSettings() {
        return this.settings;
    }

    applySettings(value: { [key: string]: any }): void {
        this.settings = value;
        this.customNameTag = value[Dashlet.SETTING_CUSTOM_NAME_TAG_KEY];
    }

    getSize(): DashletSize {
        return this.size;
    }

    getTotalSize() {
        return {
            w: this.size.cols + this.sizeExpansion.w,
            h: this.size.rows + this.sizeExpansion.h
        };
    }

    applySize(id: number): void {
        const size = this.sizes.find(s => s.id === id);
        if (size) {
            this.size = size;
            this.triggerSizeEvent();
        }
    }

    applySizeExpansion(w: number, h: number): void {
        this.sizeExpansion.w = Math.ceil(w);
        this.sizeExpansion.h = Math.ceil(h);
        this.triggerSizeEvent();
    }

    saveState(obj: { [key: string]: any }) {
        obj[Dashlet.PERSIST_ID_KEY] = this.id;
        obj[Dashlet.PERSIST_TYPE_KEY] = DashletType[this.type];
        obj[Dashlet.PERSIST_X_KEY] = this.tile.x;
        obj[Dashlet.PERSIST_Y_KEY] = this.tile.y;
        obj[Dashlet.PERSIST_SIZE_ID_KEY] = this.size.id;
        obj[Dashlet.PERSIST_SETTINGS_TOPIC_KEY] = this.settings;
        obj[Dashlet.PERSIST_DRAG_ENABLED_KEY] = this.tile.dragEnabled;
    }

    loadState(obj: { [key: string]: any }) {
        // apply settings
        const settings = obj[Dashlet.PERSIST_SETTINGS_TOPIC_KEY];
        if (settings) {
            this.applySettings(settings);
        }

        // extract size
        const sizeId = obj[Dashlet.PERSIST_SIZE_ID_KEY];
        if (sizeId !== null) {
            this.applySize(sizeId);
        }
        // recreate tile
        const size = this.getTotalSize();
        this.tile = {
            id: this.id,
            cols: size.w,
            rows: size.h,
            x: obj[Dashlet.PERSIST_X_KEY] || 0,
            y: obj[Dashlet.PERSIST_Y_KEY] || 0,
            dragEnabled: obj[Dashlet.PERSIST_DRAG_ENABLED_KEY]
        };
        if (this.tile.dragEnabled === undefined) {
            this.tile.dragEnabled = true;
        }
    }
    private triggerSizeEvent(): void {
        this.totalSizeChanged.next(this.getTotalSize());
    }
}
