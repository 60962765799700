import * as GetChildEquipmentActions from '@actions/process-vmware-data/process-vmware-data.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService } from '@services/index';
import { RealtimeGatewayDocument } from '@models/index';

interface childEquipmentResponse {
    commandTypeId: string;
    equipmentId: string;
    data: RealtimeGatewayDocument;
    url: string;
}
@Injectable()
export class ProcessVmwareDataEffect {
    constructor(private actions$: Actions, private realtimeGatewayService: RealtimeGatewayService) {}

    GetChildEquipmentData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetChildEquipmentActions.GetChildEquipmentData),
            mergeMap(action =>
                this.realtimeGatewayService
                    .getChildEquipmentDocument(action.childEquipmentId, action.parentEquipmentId, action.commandTypeId)
                    .pipe(
                        concatMap(response => {
                            if (response.data.status === 404) {
                                return of(
                                    GetChildEquipmentActions.GetChildEquipmentDataFailure({ error: '404 Not Found' })
                                );
                            }
                            return of(response).pipe(
                                map((data: childEquipmentResponse) =>
                                    GetChildEquipmentActions.GetChildEquipmentDataSuccess({
                                        data: {
                                            data: data.data,
                                            uniqueId: (data.equipmentId + data.commandTypeId).toLowerCase()
                                        }
                                    })
                                )
                            );
                        }),
                        catchError(err => of(GetChildEquipmentActions.GetChildEquipmentDataFailure({ error: err })))
                    )
            )
        )
    );
}
