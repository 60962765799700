export class Customer {
    private _customerId: string;
    private _customerName: string;

    constructor(customerId: string, customerName: string) {
        this._customerId = customerId;
        this._customerName = customerName;
    }
    /**
     * Getter customerId
     * @return {string}
     */
    public get customerId(): string {
        return this._customerId;
    }

    /**
     * Getter customerName
     * @return {string}
     */
    public get customerName(): string {
        return this._customerName;
    }

    /**
     * Setter customerId
     * @param {string} value
     */
    public set customerId(value: string) {
        this._customerId = value;
    }

    /**
     * Setter customerName
     * @param {string} value
     */
    public set customerName(value: string) {
        this._customerName = value;
    }
}

export class CustomerLogo {
    private _logo!: string;
    private _imageType!: string;

    constructor(logo: string, imageType: string) {
        this.logo = logo;
        this.imageType = imageType;
    }

    /**
     * Getter logo
     * @return {string}
     */
    public get logo(): string {
        return this._logo;
    }

    /**
     * Getter imageType
     * @return {string}
     */
    public get imageType(): string {
        return this._imageType;
    }

    /**
     * Setter logo
     * @param {string} value
     */
    public set logo(value: string) {
        this._logo = value;
    }

    /**
     * Setter imageType
     * @param {string} value
     */
    public set imageType(value: string) {
        this._imageType = value;
    }
}

export interface LogoRespObj {
    entityId: string;
    image: string;
    imageType: string;
}

export interface RealTimeNotify {
    equipmentId: string;
    commandTypeId: string;
}

export interface CustomerModel {
    customerId: string;
    name: string;
    customerNumber: string | null;
    active: boolean;
    partnerId: string;
    businessTelephoneNumber: string | null;
    primaryContactUserId: string | null;
    billingAddressAddressId: string;
    shippingAddressAddressId: string;
    shippingAddressSameAsBilling: boolean;
    partnerAccountNumber: string | null;
    serviceDeskEmailAddress: string | null;
    businessPartnerServiceDeskEmailAddress: string | null;
    mobileNumber: string | null;
    customerLogo: string;
    isBilled: boolean;
    federationKeyNumber: number;
    vsmExpressCustomer: boolean;
    createdUtcDateTime: string;
    createdByUserId: string;
    passwordAgingEnabled: boolean;
    maxPasswordAgeDays: number;
    idleTimeoutEnabled: boolean;
    maxIdleTimeMins: number;
    disableAccessConcentrator: boolean;
    ftAvailability: boolean;
    ftCapacity: boolean;
    ftChange: boolean;
    ftConfiguration: boolean;
    ftContinuity: boolean;
    ftRelease: boolean;
    ftSecurity: boolean;
    ftService: boolean;
}
