import {
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingCheckbox,
    DashletSettingText
} from '@models/index';
import { DashletSettingsService } from '@services/index';

export class DashletSettingsFormSystemHealthCMS extends DashletSettingsForm {
    private customer!: DashletSettingDropdown;
    private location!: DashletSettingDropdown;
    private equipment!: DashletSettingDropdown;
    // private showSystemChart: DashletSettingCheckbox;

    constructor(private readonly settingsService: DashletSettingsService) {
        super();

        this.init();
        this.loadCustomers();
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);

        if (this.customer.value) {
            this.loadLocations(this.customer.value);
        }
        if (this.location.value) {
            this.loadEquipment(this.location.value);
        }
    }

    private init(): void {
        const equipmentGroup = new DashletSettingsGroup();
        const s: any = (equipmentGroup.settings = []);

        const customer = (this.customer = new DashletSettingDropdown());
        customer.label = 'Customer';
        customer.stateKey = 'customer';
        customer.disabled = true;
        customer.change.subscribe(() => {
            if (!this.location.value) this.clearAndDisable(this.location);
            if (!this.equipment.value) this.clearAndDisable(this.equipment);

            this.loadLocations(customer.value!);
        });
        s.push(customer);

        const location = (this.location = new DashletSettingDropdown());
        location.label = 'Location';
        location.stateKey = 'location';
        location.disabled = true;
        location.change.subscribe(() => {
            if (!this.equipment.value) this.clearAndDisable(this.equipment);

            this.loadEquipment(location.value!);
        });
        s.push(location);

        const equipment = (this.equipment = new DashletSettingDropdown());
        equipment.label = 'Equipment';
        equipment.stateKey = 'equipment';
        equipment.disabled = true;
        s.push(equipment);

        // layout section
        const layoutGroup = new DashletSettingsGroup();
        layoutGroup.title = 'Layout';
        const layoutSettings: any = (layoutGroup.settings = []);

        const showSystemChart = new DashletSettingCheckbox();
        showSystemChart.label = 'Show Occupancy Graph';
        showSystemChart.stateKey = 'showSystemChart';
        showSystemChart.disabled = false;
        layoutSettings.push(showSystemChart);

        const showNetworkGraph = new DashletSettingCheckbox();
        showNetworkGraph.label = 'Show Network Connectivity Graph';
        showNetworkGraph.stateKey = 'showNetworkGraph';
        showNetworkGraph.disabled = false;
        layoutSettings.push(showNetworkGraph);

        const showWFMStatus = new DashletSettingCheckbox();
        showWFMStatus.label = 'Show Work Force Management Connections';
        showWFMStatus.stateKey = 'showWFMStatus';
        showWFMStatus.disabled = false;
        layoutSettings.push(showWFMStatus);

        const showCustomScripts = new DashletSettingCheckbox();
        showCustomScripts.label = 'Show Custom Scripts';
        showCustomScripts.stateKey = 'showCustomScripts';
        showCustomScripts.disabled = false;
        layoutSettings.push(showCustomScripts);

        const customName = new DashletSettingText(40);
        customName.label = 'Custom Dashlet Name';
        customName.stateKey = 'customName';
        s.push(customName);

        const group = new DashletSettingsGroup();
        group.groups = [equipmentGroup, layoutGroup];
        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);
        this.settingsService.getUserAssociatedCustomers().subscribe(
            customers => {
                this.customer.items = customers;
                this.customer.loadingComplete();
            },
            () => {
                this.customer.loadingComplete();
                this.customer.disabled = true;
                // show error notification here
            }
        );
    }

    private loadLocations(customer: string): void {
        this.enableAndLoading(this.location);
        this.settingsService.getLocations(customer).subscribe(
            locations => {
                this.location.items = locations;
                this.location.loadingComplete();
            },
            () => {
                this.location.loadingComplete();
                this.location.disabled = true;
                // show error notification here
            }
        );
    }

    private loadEquipment(location: string): void {
        this.enableAndLoading(this.equipment);
        this.settingsService.getEquipment(location, 'CMS').subscribe(
            receivers => {
                this.equipment.items = receivers;
                this.equipment.loadingComplete();
            },
            () => {
                this.equipment.loadingComplete();
                this.equipment.disabled = true;
                // show error notification here
            }
        );
    }

    private clearAndDisable(dropdown: DashletSettingDropdown) {
        dropdown.disabled = true;
        dropdown.items = [];
        dropdown.value = null;
    }

    private enableAndLoading(dropdown: DashletSettingDropdown) {
        dropdown.disabled = false;
        dropdown.loadingBegin();
    }
}
