/* eslint-disable no-undef */
/* eslint-disable @angular-eslint/component-selector */
import * as Actions from '@actions/index';
import { AccountService, DashboardService, DashletService, ReportService, ThresholdService } from '@services/index';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CustomerLogo, DashletSystemHealthCiscoPresence, DataBaseSummary } from '@models/index';
import { select, Store } from '@ngrx/store';
import { AppState, selectEntity, selectDataFromCommonEntity } from '@reducers/index';
import { Subscription } from 'rxjs';

@Component({
    selector: 'dashlet-system-health-cisco-presence',
    templateUrl: 'dashlet-system-health-cisco-presence.component.html',
    styleUrls: ['dashlet-system-health-cisco-presence.component.scss']
})
export class DashletSystemHealthCiscoPresenceComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletSystemHealthCiscoPresence;

    constructor(
        private dashletService: DashletService,
        private accountService: AccountService,
        private reportService: ReportService,
        private dashboardService: DashboardService,
        private thresholdService: ThresholdService,
        private store$: Store<AppState>
    ) {}

    openSubcontainer = '';

    private subscription: Subscription = new Subscription();

    public ngOnDestroy(): void {
        if (this.dashlet) this.dashlet.dispose();
        this.subscription.unsubscribe();
    }
    public ngOnInit(): void {
        // Logo
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );
        // Datbase Summary Data (No Nodes)
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdDatabaseReplicateInfo
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdDatabaseReplicateInfo
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdDatabaseReplicateInfo
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length) {
                        this.dashlet.processDatabaseInfo(data);
                    }
                })
        );
    }

    public openDetails(container: string): void {
        this.openSubcontainer = container;
        this.dashlet.displayHeader = false;
    }

    public getSeverityColor(sev: number): string {
        return this.dashletService.getSeverityColor(sev);
    }

    public closeDetails(): void {
        this.openSubcontainer = null;
        this.dashlet.displayHeader = true;
    }

    public getFileSystemColor(data: string): string {
        let threshold = parseInt(data);
        if (threshold < 80) {
            return '#0A9B02';
        } else if (threshold >= 80 && threshold < 90) {
            return '#FFA500';
        } else if (threshold >= 90) {
            return '#DC3704';
        }
    }

    public getDatabaseColor(data: DataBaseSummary): string {
        if (data) {
            const info = data.Replicate_State;
            switch (info) {
                case 'Initializing':
                    return this.dashletService.getSeverityColor(1);
                case 'Count Displayed Incorrect':
                    return this.dashletService.getSeverityColor(2);
                case 'Good':
                    return this.dashletService.getSeverityColor(0);
                case 'Bad Replication':
                    return this.dashletService.getSeverityColor(2);
                case 'Setup Failed':
                    return this.dashletService.getSeverityColor(2);
                default:
                    return this.dashletService.getSeverityColor(2);
            }
        } else {
            return this.dashletService.getSeverityColor(2);
        }
    }
}
