import * as SystemHealthEquipmentActions from '@actions/get-system-health-equipment/get-system-health-equipment.actions';
import { createReducer, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const systemHealthEquipmentFeatureKey = 'systemHealthEquipment';
interface SystemHealthEquipmentEntityState extends EntityState<any> {}

export interface State {
    systemHealthEquipment: SystemHealthEquipmentEntityState;
}

function selectEntityId(a: any): string {
    return a.entityId;
}

const adapterSystemHealthEquipment = createEntityAdapter<any>({
    selectId: selectEntityId
});

export const initialState: State = {
    systemHealthEquipment: adapterSystemHealthEquipment.getInitialState()
};

export const reducer = createReducer(
    initialState,
    on(SystemHealthEquipmentActions.GetSystemHealthEquipmentsSuccess, (state, action) => ({
        ...state,
        systemHealthEquipment: adapterSystemHealthEquipment.addOne(
            { ...action.data, entityId: action.entityId },
            state.systemHealthEquipment
        )
    }))

);
