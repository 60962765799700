import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

//  Get Notify process usage 
export const GetNotifyProcessUsage = createAction(
  '[ProcessUsage] Get Notify ProcessUsage',
  props<{ equipmentId: string, command: string }>()
);

export const GetNotifyProcessUsageSuccess = createAction(
  '[ProcessUsage] Get Notify ProcessUsage Success',
  props<{ data: RealTimeNotify }>()
);

export const GetNotifyProcessUsagesFailure = createAction(
  '[ProcessUsage] Get Notify ProcessUsage Failure',
  props<{ error: any }>()
);


// Get process usage document
export const GetProcessUsage = createAction(
  '[ProcessUsage] Get ProcessUsage',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetProcessUsageSuccess = createAction(
  '[ProcessUsage] Get ProcessUsage Success',
  props<{ data: any, equipmentId: string, }>()
);

export const GetProcessUsagesFailure = createAction(
  '[ProcessUsage] Get ProcessUsage Failure',
  props<{ error: any }>()
);

// Get process usage timer
export const GetProcessUsageTimer = createAction(
  '[ProcessUsage] Get ProcessUsage Timer',
  props<{ equipmentId: string, command: string }>()
);

export const GetProcessUsageTimerSuccess = createAction(
  '[ProcessUsage] Get ProcessUsage Timer Success',
  props<{ data:any }>()
);

export const GetProcessUsageTimerFailure = createAction(
  '[ProcessUsage] Get ProcessUsage Timer Failure',
  props<{ error: any }>()
);
