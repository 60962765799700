import { Customer, Dashlet, Equipment, NetworkConnectivityData } from '@models/index';
import { DashletDataProcessService } from '@services/index';
import { Subject, Subscription } from 'rxjs';

export class DashletNetworkConnectivity extends Dashlet {
    public equipment!: Equipment;
    public customer!: Customer;

    readonly interval = 30;
    private networkConnectivityData!: NetworkConnectivityData;

    public chartDataUpdated: Subject<null> = new Subject();

    get onChartDataUpdated() {
        return this.chartDataUpdated;
    }

    constructor(private dashletDataService: DashletDataProcessService) {
        super();

        // sizing
        this.sizes = [
            {
                id: 0,
                label: 'Small',
                cols: 5,
                rows: 2
            },
            {
                id: 1,
                label: 'Medium',
                cols: 5,
                rows: 4
            },
            {
                id: 2,
                label: 'Large',
                cols: 10,
                rows: 4
            }
        ];
        this.applySize(1);

        // init data
        this.resetData();

        // produce data every interval
        this.networkConnectivityData.subscribeToIntervalUpdate(this.interval);
    }

    public applySettings(v: { [key: string]: any }): void {
        super.applySettings(v);

        this.configured = v.customer && v.location && v.equipment;
        if (v.equipment) {
            this.equipment = new Equipment(v.equipment.value, v.equipment.label);
        }
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
            // this.loadCustomerLogo();
        } else {
            this.customer = new Customer('', '');
        }
        this.generatedNameTag = this.configured ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;

        this.updateSize();
    }

    public applySize(id: number): void {
        super.applySize(id);
        this.updateSize();
    }

    private updateSize(): void {
        const h = 0;
        const w = 0;
        this.applySizeExpansion(w, h);
    }

    public processNetworkData(data: any[]): void {
        const row = data[0].data[0];
        if (row !== null) {
            const networkData = this.dashletDataService.processNetworkData(row);
            this.networkConnectivityData.dataExpired = false;
            this.networkConnectivityData.updateNetworkChartData(
                networkData.averagePing,
                networkData.packetLoss,
                networkData.maxPing,
                new Date(data[0].timestamp)
            );
        }
    }

    public resetData(): void {
        this.networkConnectivityData = new NetworkConnectivityData(this.equipment);
    }

    public dispose(): void {}
}
