<div class="flex-column gap-m" *ngIf="!openSubcontainer">
    <!-- properties -->
    <dashlet-section-processor-memory
        #processorMemory
        [showSystemChart]="dashlet.showSystemChart"
        [showDiskUsage]="false"
        [equipment]="dashlet.equipment"
        [customerId]="dashlet.customer.customerId"
        [locationId]="dashlet.location.locationId"
        [commandTypeIdCPUUsage]="dashlet.commandTypeIdOccupancy"
        [commandTypeIdMemoryUsage]="dashlet.commandTypeIdOccupancy"
        [commandTypeIdUpTime]="dashlet.commandTypeIdSystemUptime"
        [commandTypeIdSystemInfo]="dashlet.commandTypeIdDeviceVersion"
    >
    </dashlet-section-processor-memory>

    <!-- network connectivity -->
    <ng-container *ngTemplateOutlet="networkConnectivity"> </ng-container>

    <!-- Ethernet Port Status -->
    <div *ngIf="dashlet.showEthernet">
        <dashlet-section-header>Ethernet Port Status</dashlet-section-header>
        <ng-container *ngTemplateOutlet="ethernet"> </ng-container>
    </div>

    <!-- IP Interfaces -->
    <div *ngIf="dashlet.showIPInterface">
        <dashlet-section-header>IP Interface</dashlet-section-header>
        <ng-container *ngTemplateOutlet="ipInterface"> </ng-container>
    </div>
</div>

<ng-template #networkConnectivity>
    <dashlet-section-network-connectivity
        [showNetworkGraph]="dashlet.showNetworkGraph"
        [equipment]="dashlet.equipment"
        [customerId]="dashlet.customer.customerId"
    >
    </dashlet-section-network-connectivity>
</ng-template>

<ng-template #ethernet>
    <div class="flex-column gap-m">
        <dashlet-ratio-bar>
            <dashlet-ratio-bar-item [value]="getEthernetActive()" [color]="getSeverityColor(0)"></dashlet-ratio-bar-item>
            <dashlet-ratio-bar-item [value]="getEthernetInActive()" [color]="getSeverityColor(2)"></dashlet-ratio-bar-item>
        </dashlet-ratio-bar>
        <div class="justify-space-between">
            <h3 class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('ethernet-drilldown', 0)">
                <span class="mat-h2">{{ getEthernetActive() + ' ' }}</span>
                <span class="mat-h3">Active</span>
            </h3>
            <h3 class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('ethernet-drilldown', 2)">
                <span class="mat-h2">{{ getEthernetInActive() + ' ' }}</span>
                <span class="mat-h3">Not Active</span>
            </h3>
        </div>
    </div>
</ng-template>

<ng-template #ipInterface>
    <div class="flex-column gap-m">
        <dashlet-ratio-bar>
            <dashlet-ratio-bar-item [value]="getIpInterfaceActive()" [color]="getSeverityColor(0)"></dashlet-ratio-bar-item>
            <dashlet-ratio-bar-item [value]="getIpInterfaceNIS()" [color]="getSeverityColor(1)"></dashlet-ratio-bar-item>
            <dashlet-ratio-bar-item [value]="getIpInterfaceNotReady()" [color]="getSeverityColor(2)"></dashlet-ratio-bar-item>
        </dashlet-ratio-bar>
        <div class="justify-space-between">
            <h3 class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('ipinterface-drilldown', 0)">
                <span class="mat-h2">{{ getIpInterfaceActive() + ' ' }}</span>
                <span class="mat-h3">Active</span>
            </h3>
            <h3 class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('ipinterface-drilldown', 1)">
                <span class="mat-h2">{{ getIpInterfaceNIS() + ' ' }}</span>
                <span class="mat-h3">Not In Service</span>
            </h3>
            <h3 class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('ipinterface-drilldown', 2)">
                <span class="mat-h2">{{ getIpInterfaceNotReady() + ' ' }}</span>
                <span class="mat-h3">Not Ready</span>
            </h3>
        </div>
    </div>
</ng-template>

<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'ethernet-drilldown'"
    [title]="dashlet.location.locationName + ' | ' + dashlet.equipment.equipmentName"
    [navPrevious]="'AudioCodes SBC System Health - ' + dashlet.customer.customerName"
    [navCurrent]="'Ethernet Port'"
    [color]="getSeverityColor(this.openSubcontainerSeverity)"
    (close)="closeDetails()"
>
    <ng-container subtitle>
        {{ openSubcontainerSeverity === 0 ? 'Active Ethernet Port' : 'Inactive Ethernet Port' }}
    </ng-container>
    <ng-container body>
        <ng-container *ngIf="getEthernetDrillDownSelected(openSubcontainerSeverity).length === 0"> No Ports Found </ng-container>
        <mat-accordion multi>
            <mat-expansion-panel *ngFor="let port of getEthernetDrillDownSelected(openSubcontainerSeverity)">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{ port.Title }} </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="flex gap-m">
                    <div class="flex-column text-align-right equipment-detail-labels">
                        <div class="mat-body-2">Active</div>
                        <div class="mat-body-2">Speed</div>
                        <div class="mat-body-2">Duplex Mode</div>
                        <div class="mat-body-2">State</div>
                        <div class="mat-body-2">Group Member</div>
                    </div>
                    <div class="flex-column equipment-detail-values">
                        <div class="mat-body-1 ">{{ port.Active  || '---'  }}</div>
                        <div class="mat-body-1 ">{{ port.Speed  || '---'  }}</div>
                        <div class="mat-body-1 ">{{ port.Duplex  || '---'  }}</div>
                        <div class="mat-body-1 ">{{ port.State  || '---'  }}</div>
                        <div class="mat-body-1 ">{{ port.GroupMem   || '---' }}</div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-container>
    <div footer class="justify-end grow gap-m">
        <button mat-button [disabled]="openSubcontainerSeverity === 0" matTooltip="View Active Ports" (click)="openDetails('ethernet-drilldown', 0)">
            Active
        </button>
        <button mat-button [disabled]="openSubcontainerSeverity === 2" matTooltip="View Inctive Ports" (click)="openDetails('ethernet-drilldown', 2)">
            Inactive
        </button>
    </div>
</vsm-dashlet-subcontainer>

<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'ipinterface-drilldown'"
    [title]="dashlet.location.locationName + ' | ' + dashlet.equipment.equipmentName"
    [navPrevious]="'AudioCodes SBC System Health - ' + dashlet.customer.customerName"
    [navCurrent]="'IP Interface'"
    [color]="getSeverityColor(this.openSubcontainerSeverity)"
    (close)="closeDetails()"
>
    <ng-container subtitle>
        {{
            openSubcontainerSeverity === 0
                ? 'Active IP Interface'
                : openSubcontainerSeverity === 1
                ? 'Not in Service Ip Interface'
                : openSubcontainerSeverity === 2
                ? 'Not Ready IP Interface'
                : 'Error'
        }}
    </ng-container>
    <ng-container body>
    
            <ng-container *ngIf="getIPInterfaceDrillDownSelected(openSubcontainerSeverity).length === 0"> No IP Interface Found </ng-container>
            <mat-accordion multi>
                <mat-expansion-panel *ngFor="let interface of getIPInterfaceDrillDownSelected(openSubcontainerSeverity)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{ interface.Title }} </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="flex gap-m">
                        <div class="flex-column text-align-right equipment-detail-labels">
                            <div class="mat-body-2">Application Type</div>
                            <div class="mat-body-2">Ip Address</div>
                            <div class="mat-body-2">Interface Mode</div>
                            <div class="mat-body-2">Prefix Length</div>
                            <div class="mat-body-2">Default Gateway</div>
                            <div class="mat-body-2">Primary DNS</div>
                            <div class="mat-body-2">Secondary DNS</div>
                            <div class="mat-body-2">Underlying Device</div>
                            <div class="mat-body-2">Underlying Interface</div>
                        </div>
                        <div class="flex-column equipment-detail-values">
                            <div class="mat-body-1">{{ interface.Application  || '---' }}</div>
                            <div class="mat-body-1">{{ interface.IpAddress || '---' }}</div>
                            <div class="mat-body-1">{{ interface.InterfaceMode || '---' }}</div>
                            <div class="mat-body-1">{{ interface.PrefixLength || '---' }}</div>
                            <div class="mat-body-1">{{ interface.Gateway || '---' }}</div>
                            <div class="mat-body-1">{{ interface.PrimaryDNS || '---' }}</div>
                            <div class="mat-body-1">{{ interface.SecondaryDNS || '---' }}</div>
                            <div class="mat-body-1">{{ interface.UnderlyingDevice || '---' }}</div>
                            <div class="mat-body-1">{{ interface.UnderlyingInterface || '---' }}</div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
            
    </ng-container>
    <div footer class="justify-end grow gap-m">
        <button mat-button [disabled]="openSubcontainerSeverity === 0" matTooltip="View Active IP Interfaces" (click)="openDetails('ethernet-drilldown', 0)">
            Active
        </button>
        <button mat-button [disabled]="openSubcontainerSeverity === 1" matTooltip="View Not in Service IP Interfaces" (click)="openDetails('ethernet-drilldown', 1)">
            Not In Service
        </button>
        <button mat-button [disabled]="openSubcontainerSeverity === 2" matTooltip="View Not Ready IP Interfaces" (click)="openDetails('ethernet-drilldown', 2)">
            Not Ready
        </button>
    </div>
    
</vsm-dashlet-subcontainer>
