import * as Actions from '@actions/index';
import { AccountService, DashletService, TimeService } from '@services/index';
import { AppState, selectDataFromCommonEntity } from '@reducers/index';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ColumnTypes, CustomerMOSSummaryData, DashletBusinessPartnerVQMSummary, SeverityRatioBar } from '@models/index';
import { select, Store } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-dashlet-business-partner-vqmsummary',
    templateUrl: './dashlet-business-partner-vqmsummary.component.html',
    styleUrls: ['./dashlet-business-partner-vqmsummary.component.scss']
})
export class DashletBusinessPartnerVQMSummaryComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletBusinessPartnerVQMSummary;

    customerData = [];
    streams: number;
    traffic: number;
    quality: string;
    cipOptions: SelectItem[] = [
        { label: 'Mos > 4.0', value: 'cip40' },
        { label: 'Mos < 4.0', value: 'cipLess40' },
        { label: 'Mos 3.6 - 4.0', value: 'cipSatisfactory' },
        { label: 'Mos < 3.6', value: 'cipPoor' }
    ];
    cipSelectedMOS: string = 'cipLess40';
    mosOptions: SelectItem[] = [
        { label: 'Mos > 4.0', value: 'mos40' },
        { label: 'Mos < 4.0', value: 'mosLess40' },
        { label: 'Mos 3.6 - 4.0', value: 'mos36_40' },
        { label: 'Mos < 3.6', value: 'mos10_36' }
    ];
    selectedMOS: string = 'mosLess40';

    entityGroup: number;
    customerId: string;

    public loading = true;

    private subscription: Subscription = new Subscription();

    public columns: ColumnTypes[] = [
        {
            columnDef: 'name',
            header: 'Customer'
        },
        {
            columnDef: 'cipTotal',
            header: 'CIP Total',
            dataTooltip: (row: any) =>
                'MOS > 4.0: ' +
                (row['cipTotal'] - row['cipLess40']) +
                '\nMOS 3.6 - 4.0: ' +
                row['cipSatisfactory'] +
                '\nMOS &gt; 3.6: ' +
                row['cipPoor']
        },
        {
            columnDef: 'cipMos',
            header: 'CIP Mos < 4.0',
            cell: (row: CustomerMOSSummaryData) => row[this.cipSelectedMOS]
        },
        {
            columnDef: 'totalCalls',
            header: ' 24 Hr Total'
        },
        {
            columnDef: 'summaryMos',
            header: '24 Hr Mos < 4.0',
            cell: (row: CustomerMOSSummaryData) => row[this.selectedMOS]
        },
        {
            columnDef: 'distribution',
            header: 'Mos Distribution',
            cell: (row: any): SeverityRatioBar[] => {
                return [
                    {
                        value: row.mos40,
                        colour: this.dashletService.getSeverityColor(0),
                        tooltip:
                            this.getSeverityBarPercentage(
                                row['mos40'],
                                row['mos40'] + row['mos36_40'] + row['mos10_36']
                            ) + ' - MOS >= 4.0'
                    },
                    {
                        value: row.mos36_40,
                        colour: this.dashletService.getSeverityColor(1),
                        tooltip:
                            this.getSeverityBarPercentage(
                                row['mos36_40'],
                                row['mos40'] + row['mos36_40'] + row['mos10_36']
                            ) + ' - MOS 3.6 - 4.0'
                    },
                    {
                        value: row.mos10_36,
                        colour: this.dashletService.getSeverityColor(2),
                        tooltip:
                            this.getSeverityBarPercentage(
                                row['mos10_36'],
                                row['mos40'] + row['mos36_40'] + row['mos10_36']
                            ) + ' - MOS &lt; 3.6'
                    }
                ];
            },
            type: 'ratio'
        }
    ];

    constructor(
        private accountService: AccountService,
        private dashletService: DashletService,
        private cdr: ChangeDetectorRef,
        private store$: Store<AppState>,
        private timeService: TimeService
    ) {}

    public ngOnInit(): void {
        var userDetail = this.accountService.getUserDetails();
        this.entityGroup = userDetail.EntityGroup;

        this.sub();

        this.subscription.add(
            this.dashlet.settingsUpdated.subscribe(() => {
                this.unSub();
                this.sub();
                this.updateCustomerData();
            })
        );
    }

    public sub(): void {
        if (this.dashlet.configured) {
            this.dashlet.lastUpdated = this.timeService.convertToUserTimeZone(new Date());
            const minDate = new Date();
            minDate.setMinutes(0, 0, 0);
            minDate.setHours(minDate.getHours() - (parseInt(this.dashlet.summaryPeriod.value) - 1));
            for (const customer of this.dashlet.customers) {
                this.store$.dispatch(
                    Actions.SubscribeToRealTimeService({
                        equipmentId: customer.customerId,
                        command: this.dashlet.commandTypeIDBusinessPartnerVQMSummary,
                        idType: 'customerId'
                    })
                );

                this.store$.dispatch(
                    Actions.GetNotifyCommonEntitys({
                        equipmentId: customer.customerId,
                        commandTypeId: this.dashlet.commandTypeIDBusinessPartnerVQMSummary
                    })
                );

                this.subscription.add(
                    this.store$
                        .pipe(
                            select(
                                selectDataFromCommonEntity(
                                    customer.customerId + this.dashlet.commandTypeIDBusinessPartnerVQMSummary
                                )
                            )
                        )
                        .subscribe(data => {
                            if (data) {
                                this.loading = false;
                                this.dashlet.processData(data);
                                this.dashlet.updateNameTag(data[0].timestamp);
                                this.dashlet.updateTimeRange(data[0].timestamp);
                                this.updateCustomerData();
                            }
                        })
                );

                this.store$.dispatch(
                    Actions.GetCommonHistoric({
                        equipmentId: customer.customerId,
                        commandTypeId: this.dashlet.commandTypeIDBusinessPartnerVQMSummary,
                        from: minDate.toISOString(),
                        to: new Date().toISOString(),
                        max: parseInt(this.dashlet.summaryPeriod.value)
                    })
                );

                this.store$.dispatch(
                    Actions.SubscribeToRealTimeService({
                        equipmentId: customer.customerId,
                        command: this.dashlet.commandTypeIDCIP,
                        idType: 'customerId'
                    })
                );

                this.store$.dispatch(
                    Actions.GetNotifyCommonEntitys({
                        equipmentId: customer.customerId,
                        commandTypeId: this.dashlet.commandTypeIDCIP
                    })
                );

                this.subscription.add(
                    this.store$
                        .pipe(select(selectDataFromCommonEntity(customer.customerId + this.dashlet.commandTypeIDCIP)))
                        .subscribe(data => {
                            if (data) {
                                this.dashlet.processCIP(data, customer.customerId, customer.customerName);
                                const time: Date = new Date(
                                    this.timeService.convertToUserTimeZone(data[0].timestamp)
                                );
                                if (new Date(this.dashlet.lastUpdated) < time)
                                    this.dashlet.lastUpdated = this.timeService.convertToUserTimeZone(
                                        data[0].timestamp
                                    );
                                this.dashlet.updateNameTag(data[0].timestamp);
                                this.updateCustomerData();
                            }
                        })
                );
            }
        }
    }

    public unSub(): void {
        let customersToSlice: string[] = [];
        this.customerData.forEach(customer => {
            if (this.dashlet.customers.findIndex(item => item.customerId === customer.id) === -1) {
                customersToSlice.push(customer.id);
            }

            this.store$.dispatch(
                Actions.UnsubscribeFromRealTimeService({
                    equipmentId: customer.id,
                    command: this.dashlet.commandTypeIDBusinessPartnerVQMSummary,
                    idType: 'customerId'
                })
            );

            this.store$.dispatch(
                Actions.UnsubscribeFromRealTimeService({
                    equipmentId: customer.id,
                    command: this.dashlet.commandTypeIDCIP,
                    idType: 'customerId'
                })
            );
        });

        customersToSlice.forEach(id => {
            this.dashlet.customerMOSData.delete(id);
            this.customerData = this.customerData.filter(item => item.id !== id);
        });
    }

    public ngOnDestroy(): void {
        this.unSub();
        this.dashlet?.dispose();
        this.subscription.unsubscribe();
    }

    get smallLayout() {
        return this.dashlet.getSize().id === 0;
    }

    get mediumLayout() {
        return this.dashlet.getSize().id === 1;
    }

    get wideLayout() {
        return this.dashlet.getSize().id === 2;
    }

    updateCustomerData() {
        let rows = [];
        let totalCalls = 0;
        let goodCalls = 0;
        let traffic = 0;

        this.dashlet.customerMOSData.forEach(customer => {
            rows.push({
                id: customer.id,
                name: customer.name,
                totalCalls: customer.getMOSDataByHours(this.dashlet.summaryPeriod.value).totalCalls,
                traffic: customer.getMOSDataByHours(this.dashlet.summaryPeriod.value).traffic,
                mos10_36: customer.getPoorTotalByHours(this.dashlet.summaryPeriod.value),
                mos36_40: customer.getSatisfactoryTotalByHours(this.dashlet.summaryPeriod.value),
                mosLess40:
                    customer.getPoorTotalByHours(this.dashlet.summaryPeriod.value) +
                    customer.getSatisfactoryTotalByHours(this.dashlet.summaryPeriod.value),
                mos40: customer.getGoodTotalByHours(this.dashlet.summaryPeriod.value),
                percentage: customer['_mosPercentage'],
                cip40: customer['_cipTotal'] - customer['_cipPoor'] - customer['_cipSatisfactory'],
                cipTotal: customer['_cipTotal'],
                cipPoor: customer['_cipPoor'],
                cipSatisfactory: customer['_cipSatisfactory'],
                cipLess40: customer['_cipPoor'] + customer['_cipSatisfactory']
            });

            totalCalls += customer.getMOSDataByHours(this.dashlet.summaryPeriod.value).totalCalls;
            traffic += customer.getMOSDataByHours(this.dashlet.summaryPeriod.value).traffic;
            goodCalls += customer.getGoodTotalByHours(this.dashlet.summaryPeriod.value);
        });

        this.streams = totalCalls;
        this.traffic = traffic / Math.pow(2, 30);
        this.quality = ((goodCalls / totalCalls) * 100).toFixed(1) + '%';
        this.customerData = [...rows];
        this.cdr.detectChanges();
    }

    getSeverityBarPercentage(severity: number, total: number) {
        let percentage = (severity / total) * 100;
        return isNaN(percentage) ? '0%' : percentage.toFixed(1) + '%';
    }

    public onCipSelectChange($event: string) {
        const header = this.cipOptions[this.cipOptions.map(x => x.value).indexOf($event)].label;
        this.columns[2].header = 'CIP ' + header;
        this.updateCustomerData();
    }
    public onMosSelectChange($event: string) {
        const header = this.mosOptions[this.mosOptions.map(x => x.value).indexOf($event)].label;
        this.columns[4].header = '24 Hr ' + header;
        this.updateCustomerData();
    }
}
