<div class="flex-column gap-l">
    <div class="grow center text-center mat-body-2">From {{ dashlet.equipment?.equipmentName }} to {{ dashlet.remoteLocation?.locationName }}</div>
    <p-chart
        #chart
        type="line"
        [data]="sipBandwidthChartData"
        [options]="sipBandwidthChartOptions"
        [height]="dashlet.chartHeight"
        
    ></p-chart>
    <div class="flex grow justify-space-around" *ngIf="!smallLayout">
        <div class="metric gap-s" [ngStyle]="{ 'border-left-color': totalUsageColor }">
            <div class="mat-caption">Total Usage</div>
            <div class="mat-h2 dashlet-link-underline">{{ dashlet.sipBandwidth.getCurrentTotalUsage() }} kbps</div>
        </div>
        <div class="metric gap-s" [ngStyle]="{ 'border-left-color': audioUsageColor }">
            <div class="mat-caption">Audio</div>
            <div class="mat-h2 dashlet-link-underline">{{ dashlet.sipBandwidth.getCurrentAudioUsage() }} kbps</div>
        </div>
        <div class="metric gap-s" [ngStyle]="{ 'border-left-color': videoUsageColor }">
            <div class="mat-caption">Video</div>
            <div class="mat-h2 dashlet-link-underline">{{ dashlet.sipBandwidth.getCurrentVideoUsage() }} kbps</div>
        </div>
    </div>
</div>
