import * as Actions from '@actions/index';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { DashletNetworkConnectivity, CustomerLogo } from '@models/index';
import { select, Store } from '@ngrx/store';

import { AppState, selectEntity } from '@reducers/index';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-dashlet-network-connectivity',
    templateUrl: './dashlet-network-connectivity.component.html',
    styleUrls: ['./dashlet-network-connectivity.component.scss']
})
export class DashletNetworkConnectivityComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletNetworkConnectivity;

    private subscription: Subscription = new Subscription();

    constructor(private store$: Store<AppState>) {}

    public ngOnInit(): void {
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );
    }

    public ngOnDestroy(): void {
        if (this.dashlet) this.dashlet.dispose();
        this.subscription.unsubscribe();
    }

    get smallLayout() {
        return this.dashlet.getSize().id === 0;
    }

    get mediumLayout() {
        return this.dashlet.getSize().id === 1;
    }

    get wideLayout() {
        return this.dashlet.getSize().id === 2;
    }

    public getChartAspect(): number {
        return this.wideLayout ? 3.4 : 1.4;
    }
}
