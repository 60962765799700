import * as LocationActions from '@actions/get-locations/get-locations.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export const locationsFeatureKey = 'locations';

interface LocationEntityState extends EntityState<any> {}

export interface State {
    locations: LocationEntityState;
}

function selectEntityId(a: any): string {
    return a.customerId;
}

const adapterLocations = createEntityAdapter<any>({
    selectId: selectEntityId
});

const LocatonInitState: LocationEntityState = adapterLocations.getInitialState();

export const initialState: State = {
    locations: LocatonInitState
};

export const reducer = createReducer(
    initialState,
    on(LocationActions.GetLocationsSuccess, (state, action) => ({
        ...state,
        locations: adapterLocations.upsertOne(action.data, state.locations)
    }))
);
