export class NewUserDetails {
	username!: string;
	password!: string;
	securityQuestion!: string;
	securityQuestionAnswer!: string;
	firstName!: string;
	lastName!: string;
	workPhone!: string;
	mobilePhone!: string;
	timeZone!: string;
}
