import * as Actions from '@actions/index';
import { AccountService, DashletService, ReportService, TimeoutService } from '@services/index';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    Availability,
    AvailabilityData,
    ColumnTypes,
    CustomerLogo,
    DashletRibbonSystemHealthSummary,
    FeatureSubscriptions,
    MenuItem,
    RibbonSystemHealthServer,
    SelectItem,
    ServerEquipment,
    SummaryField
} from '@models/index';
import { select, Store } from '@ngrx/store';
import {
    AppState,
    selectEntity,
    selectDataFromCommonEntity,
    selectSummaryDocument,
    selectLocations,
    selectRibbonSystemHealthEquipment,
    selectReportSubscription
} from '@reducers/index';
import { combineLatest, map, Observable, of, Subject, Subscription, switchMap } from 'rxjs';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-dashlet-system-health-ribbon-system-health',
    templateUrl: './dashlet-system-health-ribbon-system-health-summary.component.html',
    styleUrls: ['./dashlet-system-health-ribbon-system-health-summary.component.scss']
})
export class DashletSystemHealthRibbonSystemHealthSummaryComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletRibbonSystemHealthSummary;
    public servers$: Observable<RibbonSystemHealthServer[]>;
    public availibilitySummary$: Observable<Availability>;
    private equipment$: Observable<ServerEquipment[]>;
    private locations$: Observable<string[]>;
    public loading = true;
    private customerId: string;
    private entityGroup: any;

    private reportSubscriptions: FeatureSubscriptions = {
        capacityEnabled: false,
        availabilityEnabled: false,
        releaseEnabled: false,
        apiEnabled: false,
        serviceDeskEnabled: false,
        configurationEnabled: false,
        continuityEnabled: false,
        changeEnabled: false,
        securityEnabled: false
    };

    pingCount: number = 0;
    totalPing: number = 0;
    totalAveragePing: number = 0;

    availabilityCount: number = 0;
    totalAvailability: number = 0;
    totalAvgAvailability: number = 0;

    longestOutageServer!: string;
    longestOutageSeconds: number = 0;
    longestOutageText: string = 'No outages';

    public menuItems: MenuItem[] = [
        {
            label: 'Open Processor Report',
            command: () => {
                this.openCpuReport();
            },
            disabled: !(this.reportSubscriptions.capacityEnabled || this.accountService.isVirsaeOwner()),
            title: !(this.reportSubscriptions.capacityEnabled || this.accountService.isVirsaeOwner())
                ? this.getCapacityUnsubscribedText()
                : ''
        },
        {
            label: 'Open Disk Report',
            command: () => {
                this.openDiskReport();
            },
            disabled: !(this.reportSubscriptions.capacityEnabled || this.accountService.isVirsaeOwner()),
            title: !(this.reportSubscriptions.capacityEnabled || this.accountService.isVirsaeOwner())
                ? this.getCapacityUnsubscribedText()
                : ''
        },
        {
            label: 'Open Memory Report',
            command: () => {
                this.openMemoryReport();
            },
            disabled: !(this.reportSubscriptions.capacityEnabled || this.accountService.isVirsaeOwner()),
            title: !(this.reportSubscriptions.capacityEnabled || this.accountService.isVirsaeOwner())
                ? this.getCapacityUnsubscribedText()
                : ''
        },
        {
            label: 'Open Network Connectivity Report',
            command: () => {
                this.openNetworkConnectivityReport();
            },
            disabled: !(this.reportSubscriptions.availabilityEnabled || this.accountService.isVirsaeOwner()),
            title: !(this.reportSubscriptions.availabilityEnabled || this.accountService.isVirsaeOwner())
                ? this.getAvailabilityUnsubscribedText()
                : ''
        }
    ];
    public columns: ColumnTypes[] = [
        {
            columnDef: 'severity',
            header: '',
            cell: (row: RibbonSystemHealthServer) => row.severity,
            type: 'severity',
            dataTooltip: (row: RibbonSystemHealthServer) => this.getSeverityReasons(row)
        },
        {
            columnDef: 'name',
            header: 'Session Border Controller',
            filterType: 'text',
            width: '13.5%'
        },
        {
            columnDef: 'cpuPercentage',
            header: 'CPU',
            cell: (row: RibbonSystemHealthServer) => `${row.cpuPercentage || '---'}% `,
            type: 'numeric',
            width: '5%'
        },
        {
            columnDef: 'memoryPercentage',
            header: 'Memory',
            cell: (row: RibbonSystemHealthServer) => `${row.memoryPercentage || '---'}% `,
            type: 'numeric',
            width: '8%'
        },
        {
            columnDef: 'spacerElement',
            header: ' ',
            width: '3.5%'
        },
        {
            columnDef: 'signalGroupsGreen',
            header: 'Signalling Groups',
            subHeadingInFilter: 'Up',
            width: '12%',
            cell: (row: RibbonSystemHealthServer) => {
                return row.signalGroupsGreen > 0
                    ? row.signalGroupsGreen.toString()
                    : row.signalGroupsGreen === 0
                    ? row.signalGroupsGreen
                    : 'N/A';
            },
            type: 'services'
        },
        {
            columnDef: 'signalGroupsRed',
            header: 'Signalling Groups',
            subHeadingInFilter: 'Down',
            width: '12%',
            cell: (row: RibbonSystemHealthServer) => {
                return row.signalGroupsRed > 0
                    ? row.signalGroupsRed.toString()
                    : row.signalGroupsRed === 0
                    ? row.signalGroupsRed
                    : 'N/A';
            },
            type: 'services'
        },
        {
            columnDef: 'sessionsCurrentlyUp',
            header: 'Sessions Currently Up',
            width: '15%',
            cell: (row: RibbonSystemHealthServer) => {
                return row.sessionsCurrentlyUp > 0
                    ? row.sessionsCurrentlyUp.toString()
                    : row.sessionsCurrentlyUp === 0
                    ? row.sessionsCurrentlyUp
                    : '---';
            }
        },
        {
            columnDef: 'dspStatus',
            header: 'DSP Status',
            width: '7%',
            cell: (row: RibbonSystemHealthServer) => (row.dspStatus.length ? row.dspStatus : '---')
        },
        {
            columnDef: 'maxPing',
            header: 'Ping',
            subHeadingInFilter: 'Max',
            type: 'numeric',
            headerTooltip: 'Maximum ping time from three tests at configured interval',
            cell: (row: RibbonSystemHealthServer) => (row.maxPing ? row.maxPing + ' ms' : '---'),
            width: '6%'
        },
        {
            columnDef: 'averagePing',
            header: 'Ping',
            subHeadingInFilter: 'Avg',
            type: 'numeric',
            headerTooltip: 'Average ping time from three tests at configured interval',
            cell: (row: RibbonSystemHealthServer) => (row.averagePing ? row.averagePing + ' ms' : '---'),
            width: '7%'
        },
        {
            columnDef: 'availability',
            header: 'Availability',
            type: 'numeric',
            headerTooltip:
                'Availability is calculated as: average ping packets received ÷ ping packets sent x 100 over last 30 days',
            cell: (row: RibbonSystemHealthServer) => (row.availability ? `${row.availability}%` : '---'),
            width: '9.5%'
        }
    ];
    selectedServer: RibbonSystemHealthServer;
    types: SelectItem[];
    private subscription: Subscription = new Subscription();

    constructor(
        public dashletService: DashletService,
        private accountService: AccountService,
        private reportService: ReportService,
        private timeoutService: TimeoutService,
        private store$: Store<AppState>
    ) {}

    public ngOnInit(): void {
        this.subscription.add(
            this.dashlet.settingsChanged.subscribe(() => {
                this.unSubFromRTG();
                this.servers$ = of([]);
                this.equipment$ = of([]);
                this.subscribeToData();
            })
        );
        this.subscribeToData();
    }

    private subscribeToData(): void {
        this.customerId = this.dashlet.customer.customerId;
        this.store$.dispatch(Actions.GetReportSubscriptions({ customerId: this.customerId }));
        this.subscription.add(
            this.store$.pipe(select(selectReportSubscription(this.customerId))).subscribe(subscriptions => {
                if (subscriptions?.reportSubscriptions) {
                    this.reportSubscriptions = subscriptions.reportSubscriptions;
                }
            })
        );
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );
        if (this.dashlet.customer.customerId && this.dashlet.locationId) {
            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: this.dashlet.customer.customerId,
                    command: this.dashlet.commandTypeIdAvailabilty,
                    idType: 'customerId'
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: this.dashlet.customer.customerId,
                    commandTypeId: this.dashlet.commandTypeIdAvailabilty
                })
            );
            const equipmentIds$: Subject<string[]> = new Subject();
            const availability$ = this.store$.pipe(
                select(
                    selectDataFromCommonEntity(this.dashlet.customer.customerId + this.dashlet.commandTypeIdAvailabilty)
                )
            );
            this.availibilitySummary$ = combineLatest([availability$, equipmentIds$]).pipe(
                map(([availability, equipmentIds]) => {
                    if (availability?.length && equipmentIds.length) {
                        let equipmentAvailibility: AvailabilityData[] = [];
                        availability[0].data.forEach(element => {
                            let equipmentId = element.EquipmentId || element.MaxLongestOutageEquipmentId;
                            let equipmentName = element.EquipmentName || element.MaxLongestOutageEquipmentName;
                            const index = equipmentAvailibility.map(x => x.EquipmentId).indexOf(equipmentId);
                            if (index === -1) {
                                equipmentAvailibility.push({
                                    EquipmentId: equipmentId,
                                    EquipmentName: equipmentName,
                                    AveragePacketLossPercentage: +element.AveragePacketLossPercentage,
                                    AverageResponseTime: +element.AverageResponseTime,
                                    LongestOutageSeconds: +element.LongestOutageSeconds,
                                    MaxLongestOutageSeconds: +element.MaxLongestOutageSeconds
                                });
                            } else {
                                equipmentAvailibility[index] = {
                                    EquipmentId: equipmentId,
                                    EquipmentName: equipmentName,
                                    AveragePacketLossPercentage: element.AveragePacketLossPercentage
                                        ? +element.AveragePacketLossPercentage
                                        : equipmentAvailibility[index].AveragePacketLossPercentage,
                                    AverageResponseTime: element.AverageResponseTime
                                        ? +element.AverageResponseTime
                                        : equipmentAvailibility[index].AverageResponseTime,
                                    LongestOutageSeconds: element.LongestOutageSeconds
                                        ? +element.LongestOutageSeconds
                                        : equipmentAvailibility[index].LongestOutageSeconds,
                                    MaxLongestOutageSeconds: element.MaxLongestOutageSeconds
                                        ? +element.MaxLongestOutageSeconds
                                        : equipmentAvailibility[index].MaxLongestOutageSeconds
                                };
                            }
                        });

                        const filteredAvailability = equipmentAvailibility.filter(x =>
                            equipmentIds.includes(x.EquipmentId.toLowerCase())
                        );
                        return this.processAvailability(filteredAvailability);
                    }
                })
            );
            if (this.dashlet.locationId !== '=all=') {
                this.locations$ = of([this.dashlet.locationId]);
            } else {
                this.store$.dispatch(Actions.GetLocations({ customerId: this.dashlet.customer.customerId }));

                this.locations$ = this.store$.pipe(
                    select(selectLocations(this.dashlet.customer.customerId)),
                    map(locations => {
                        if (locations) {
                            return locations.map(location => location.value);
                        }
                    })
                );
            }
            this.equipment$ = this.locations$.pipe(
                switchMap(locations => {
                    if (locations) {
                        const observable$ = locations.map(location => {
                            this.store$.dispatch(Actions.GetRibbonSystemHealthEquipments({ entityId: location }));
                            return this.store$.pipe(select(selectRibbonSystemHealthEquipment(location)));
                        });

                        return combineLatest(observable$).pipe(
                            map(data => {
                                const returnObject: ServerEquipment[] = [];
                                const equipmentIds: string[] = [];

                                if (!data || !data.length) {
                                    return returnObject;
                                }

                                data.forEach(group => {
                                    if (!group) {
                                        return;
                                    }

                                    Object.keys(group)
                                        .filter(key => key !== 'entityId')
                                        .forEach(key => {
                                            group[key].items.forEach(item => {
                                                equipmentIds.push(item.value.toLowerCase());
                                                returnObject.push({
                                                    equipmentGroup: group[key].label,
                                                    equipmentName: item.label,
                                                    equipmentId: item.value,
                                                    locationId: group.entityId
                                                });
                                            });
                                        });
                                });

                                equipmentIds$.next(equipmentIds);
                                return returnObject;
                            })
                        );
                    } else return of(undefined);
                })
            );
            this.servers$ = this.equipment$.pipe(
                switchMap(equipmentItems => {
                    if (equipmentItems) {
                        let summary$: Observable<{
                            summaryData: any;
                            equipmentName: string;
                            equipmentId: string;
                            equipmentGroup: string;
                            locationId: string;
                        }>[] = [];
                        this.store$.dispatch(
                            Actions.getSummaryDocumentWithRepeat({
                                equipmentId: equipmentItems.map(e => e.equipmentId),
                                commandTypeId: this.dashlet.commandTypeIdSystemSummaryData,
                                interval: 30
                            })
                        );
                        equipmentItems.forEach(equipment => {
                            const systemHealthSummary$ = this.store$.pipe(
                                select(
                                    selectSummaryDocument(
                                        equipment.equipmentId + this.dashlet.commandTypeIdSystemSummaryData
                                    )
                                )
                            );

                            summary$.push(
                                systemHealthSummary$.pipe(
                                    map(systemHealthSummary => {
                                        return {
                                            summaryData: systemHealthSummary,
                                            equipmentName: equipment.equipmentName,
                                            equipmentId: equipment.equipmentId,
                                            equipmentGroup: equipment.equipmentGroup,
                                            locationId: equipment.locationId
                                        };
                                    })
                                )
                            );
                        });
                        return combineLatest(summary$).pipe(
                            map(summaryData => {
                                let returnData: RibbonSystemHealthServer[] = [];
                                if (summaryData.length) {
                                    summaryData.forEach(row => {
                                        if (row.summaryData) {
                                            returnData.push(
                                                this.processSummaryRow(
                                                    row.summaryData,
                                                    row.equipmentName,
                                                    row.equipmentId,
                                                    row.equipmentGroup,
                                                    row.locationId
                                                )
                                            );
                                        }
                                    });
                                }
                                this.loading = false;

                                return returnData;
                            })
                        );
                    } else return of(undefined);
                })
            );
        }

        const userDetail = this.accountService.getUserDetails();
        this.entityGroup = userDetail.EntityGroup;

        const isBP = userDetail.EntityGroup === 0 || userDetail.EntityGroup === 1; //allow for BPs & VOs
        if (isBP) {
            this.menuItems = [
                ...this.menuItems,
                {
                    label: 'Open Access Concentrator',
                    command: () => {
                        this.accessConcentrator();
                    },
                    disabled: false,
                    title: ''
                }
            ];
        }
    }

    public ngOnDestroy(): void {
        if (this.dashlet) this.dashlet.dispose();
        this.subscription.unsubscribe();
        this.unSubFromRTG();
    }

    private processSummaryRow(
        data: any,
        equipmentName: string,
        equipmentId: string,
        equipmentType: string,
        locationId: string
    ): RibbonSystemHealthServer {
        const name = equipmentName;
        const id = equipmentId;
        const type = equipmentType;
        const location = locationId;
        const signallingGroupData = this.getSignalingGroupsData(data);
        const cpuPercentage = +(+this.processSummaryField(data.CPU)).toFixed(1) || undefined;
        const memoryPercentage = +(+this.processSummaryField(data.Memory)).toFixed(1) || undefined;
        const averagePing = this.processPing(data.AveragePing) || undefined;
        const maxPing = this.processPing(data.MaxPing) || undefined;
        let sessionsCurrentlyUp = undefined;
        if (data.CallsCurrentlyUP && data.CallsCurrentlyUP.FieldValue) {
            sessionsCurrentlyUp =
                data.CallsCurrentlyUP.FieldValue !== '0'
                    ? +data.CallsCurrentlyUP.FieldValue
                    : data.CallsCurrentlyUP.FieldValue === '0'
                    ? 0
                    : undefined;
        }
        const dspStatus = data.Dspstatus ? JSON.parse(this.processSummaryField(data.Dspstatus)) : [];
        const lastUpdateTimes = undefined;
        const availability = +(+this.processSummaryField(data.AverageAvailability)).toFixed(1) || undefined;
        const longestOutageSeconds = data.LongestOutageSeconds;
        let returnObject: RibbonSystemHealthServer = {
            severity: -1,
            name: name,
            equipmentId: id,
            type: type,
            location: location,
            cpuPercentage: cpuPercentage,
            memoryPercentage: memoryPercentage,
            averagePing: averagePing,
            maxPing: maxPing,
            sessionsCurrentlyUp: sessionsCurrentlyUp,
            signalGroupsGreen: +signallingGroupData.upCount,
            signalGroupsRed: +signallingGroupData.downCount,
            dspStatus: dspStatus,
            lastUpdateTimes: lastUpdateTimes,
            availability: availability,
            longestOutageSeconds: longestOutageSeconds
        };
        returnObject.severity = this.processSeverityState(returnObject);

        return returnObject;
    }

    private processAvailability(data: AvailabilityData[]): Availability {
        let longestOutageSeconds: number = 0;
        let longestOutageServer: string = '';
        let totalResponseTime: number = 0;
        let totalAvailability: number = 0;
        data.forEach(element => {
            if (element.LongestOutageSeconds > longestOutageSeconds) {
                longestOutageSeconds = element.LongestOutageSeconds;
                longestOutageServer = element.EquipmentName;
            }
            totalResponseTime += element.AverageResponseTime;
            totalAvailability += element.AveragePacketLossPercentage;
        });
        return {
            longestOutageText: this.getLongestOutageText(longestOutageSeconds, longestOutageServer),
            averageResponse: totalResponseTime / data.length,
            totalAvgAvailability: 100 * data.length - totalAvailability,
            totalServers: data.length
        };
    }

    private getLongestOutageText(longestOutageSeconds: number, longestOutageServer: string): string {
        let longestOutageText = 'No outages';
        if (longestOutageSeconds > 300) {
            longestOutageText = `${longestOutageSeconds} seconds from ${longestOutageServer}`;
        }
        return longestOutageText;
    }

    private processSummaryField(data: SummaryField): string {
        if (data?.FieldCommandUpdateTimeInfoList) {
            const dataExpired = this.timeoutService.getIsDataExpired(
                data.FieldCommandUpdateTimeInfoList[0]?.CommandTypeId,
                data.FieldCommandUpdateTimeInfoList[0]?.LastUpdateTime
            );
            if (!dataExpired) {
                return data.FieldValue ? data.FieldValue : undefined;
            }
        }
        return undefined;
    }

    private processSeverityState(data: RibbonSystemHealthServer): number {
        if (
            data.cpuPercentage === undefined &&
            data.memoryPercentage === undefined &&
            data.averagePing === undefined &&
            data.maxPing === undefined
        ) {
            return -1;
        }

        if (
            data.cpuPercentage! > 90 ||
            data.memoryPercentage! > 90 ||
            data.dspStatus.length === +this.getDspDown(data) ||
            data.signalGroupsRed > 0
        ) {
            return 2;
        }

        if (data.memoryPercentage! > 80 || data.cpuPercentage! > 80 || +this.getDspDown(data)! > 0) {
            return 1;
        }

        return 0; // all is okay
    }

    get smallLayout() {
        return this.dashlet.getSize().id === 0;
    }

    get mediumLayout() {
        return this.dashlet.getSize().id === 1;
    }

    get wideLayout() {
        return this.dashlet.getSize().id === 2;
    }

    private accessConcentrator(): void {
        const settings = this.dashlet.getSettings();
        const customerId = settings.customer.value;
        const equipmentId = this.selectedServer.equipmentId;
        //as a customer, go to access history
        if (this.entityGroup === 2) {
            // eslint-disable-next-line no-undef
            window.open(
                environment.webPortalUrl + '/AccessConcentrator/AccessConcentrator/List?entityId=' + customerId
            );
        } else {
            // eslint-disable-next-line no-undef
            window.open(
                environment.webPortalUrl +
                    '/AccessConcentrator/AccessConcentrator/EquipmentList?entityId=' +
                    customerId +
                    '&equipmentId=' +
                    equipmentId
            );
        }
    }

    public openNetworkConnectivityReport(): void {
        var settings = this.dashlet.getSettings();
        this.customerId = settings.customer.value;

        this.reportService.openReport(this.reportService.networkConnectivityReportId, this.customerId);
    }

    private openCpuReport(): void {
        var settings = this.dashlet.getSettings();
        this.customerId = settings.customer.value;
        this.reportService.openReport(this.reportService.osCpuReportId, this.customerId);
    }

    private openMemoryReport(): void {
        var settings = this.dashlet.getSettings();
        this.customerId = settings.customer.value;
        this.reportService.openReport(this.reportService.osMemoryReportId, this.customerId);
    }

    private openDiskReport(): void {
        var settings = this.dashlet.getSettings();
        this.customerId = settings.customer.value;
        this.reportService.openReport(this.reportService.osDiskReportId, this.customerId);
    }

    public getCapacityUnsubscribedText(): string {
        return this.dashletService.getCapacityUnsubscribedText();
    }
    public getAvailabilityUnsubscribedText(): string {
        return this.dashletService.getAvailabilityUnsubscribedText();
    }

    private getDspDown(item: RibbonSystemHealthServer): string {
        let count = 0;
        item.dspStatus.forEach(group => {
            if (group.RtServiceStatus === '0') {
                count++;
            }
        });
        return count !== 0 ? count.toString() : '-';
    }

    private getSeverityReasons(item: RibbonSystemHealthServer): string {
        if (item.severity === 0) {
            return 'All systems ok';
        } else if (item.severity === 1 || item.severity === 2) {
            let reasonsArray: string[] = [];

            if (item.cpuPercentage > 90) {
                reasonsArray.push('CPU > 90%');
            } else if (item.cpuPercentage > 80) {
                reasonsArray.push('CPU > 80%');
            }

            if (item.memoryPercentage > 90) {
                reasonsArray.push('Memory > 90%');
            } else if (item.memoryPercentage > 80) {
                reasonsArray.push('Memory > 80%');
            }

            if (item.dspStatus.length === +this.getDspDown(item)) {
                reasonsArray.push('All DSP Services Down');
            } else if (+this.getDspDown(item) > 0) {
                reasonsArray.push('Some DSP Services Down');
            }

            if (item.signalGroupsRed > 0) {
                reasonsArray.push('Signalling Group Down');
            }

            let reasonsBox: string = reasonsArray.join(' \n');
            return reasonsBox;
        } else if (item.severity === 3) {
            return 'No connectivity data received. Check network, configuration and system settings.';
        }
    }

    public getNoOutageText(): string {
        return '<5 mins';
    }

    private getSignalingGroupsData(apiResponse: RibbonSystemHealthServer): { upCount: number; downCount: number } {
        let upCount = 0;
        let downCount = 0;
        for (const key in apiResponse) {
            if (Object.prototype.hasOwnProperty.call(apiResponse, key)) {
                const value = apiResponse[key];
                const dataExpired = this.timeoutService.getIsDataExpired(
                    value.FieldCommandUpdateTimeInfoList[0]?.CommandTypeId,
                    value.FieldCommandUpdateTimeInfoList[0]?.LastUpdateTime
                );
                if (key.startsWith('SignalingGroupGreenArrow_')) {
                    if (!dataExpired) {
                        upCount += parseInt(value['FieldValue']);
                    }
                } else if (key.startsWith('SignalingGroupRedArrow_')) {
                    if (!dataExpired) {
                        downCount += parseInt(value['FieldValue']);
                    }
                }
            }
        }

        return { upCount, downCount };
    }

    private unSubFromRTG(): void {
        this.store$.dispatch(
            Actions.UnsubscribeFromRealTimeService({
                equipmentId: this.dashlet.customer.customerId,
                command: this.dashlet.commandTypeIdAvailabilty,
                idType: 'customerId'
            })
        );
        this.equipment$.forEach(item => {
            item.forEach(e => {
                Actions.UnsubscribeFromRealTimeService({
                    equipmentId: e.equipmentId,
                    command: this.dashlet.commandTypeIdAvailabilty,
                    idType: 'customerId'
                });
            });
        });
    }

    private processPing(data: SummaryField): string | number | null {
        if (data?.FieldCommandUpdateTimeInfoList) {
            const dataExpired = this.timeoutService.getIsDataExpired(
                data.FieldCommandUpdateTimeInfoList[0]?.CommandTypeId,
                data.FieldCommandUpdateTimeInfoList[0]?.LastUpdateTime
            );
            if (!dataExpired) {
                if (!data || !data.FieldValue) return null;
                if (!Number(data.FieldValue)) return '<1';
                return +data.FieldValue;
            }
        }
        return undefined;
    }

    public tableSize(): number {
        const s = this.dashlet.getSize();
        if (s.id === 0) return 3;
        else if (s.id === 1) return 10;
        else if (s.id === 2) return 16;
        else if (s.id === 3) return 28;
        return 4;
    }
}
