import * as Actions from '@actions/index';
import { AccountService } from '@services/index';
import { AppState, selectDataFromCommonEntity } from '@reducers/index';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DashletLineGraphComponent } from '../dashlet-line-graph/dashlet-line-graph.component';
import { DashletPortsPerCustomer } from '@models/index';
import { select, Store } from '@ngrx/store';
import { UIChart } from 'primeng/chart';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-dashlet-ports-per-customer',
    templateUrl: './dashlet-ports-per-customer.component.html',
    styleUrls: ['./dashlet-ports-per-customer.component.scss']
})
export class DashletPortsPerCustomerComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletPortsPerCustomer;
    @ViewChild('chart') chart!: UIChart;

    private destroy$ = new Subject<void>();

    entityGroup: number;
    customerId: string;

    private pportsChart: DashletLineGraphComponent;
    get portsChart() {
        return this.pportsChart;
    }
    @ViewChild('portsChart') set portsChart(value: DashletLineGraphComponent) {
        this.pportsChart = value;
        this.updateChart();
    }

    private pportsChartWide: DashletLineGraphComponent;
    get portsChartWide() {
        return this.pportsChartWide;
    }
    @ViewChild('portsChartWide') set portsChartWide(value: DashletLineGraphComponent) {
        this.pportsChartWide = value;
        this.updateChart();
    }

    lineData = {};

    private avgPortsEMEA = {
        label: 'Avg Ports EMEA',
        borderColor: '#36d3bc',
        backgroundColor: '#36d3bc',
        pointRadius: 0,
        pointHitRadius: 10,
        data: []
    };
    private avgPortsNA = {
        label: 'Avg Ports NA',
        borderColor: '#336699',
        backgroundColor: '#336699',
        pointRadius: 0,
        pointHitRadius: 10,
        data: []
    };
    private avgPortsAPAC = {
        label: 'Avg Ports APAC',
        borderColor: '#3399ff',
        backgroundColor: '#3399ff',
        pointRadius: 0,
        pointHitRadius: 10,
        data: []
    };
    private avgPortsAll = {
        label: 'Avg Ports All',
        borderColor: '#814e7c',
        backgroundColor: '#814e7c',
        pointRadius: 0,
        pointHitRadius: 10,
        data: []
    };

    portsLineData: any = {};

    portsChartOptions: any = {
        scales: {
            x: {
                type: 'time',
                grid: {
                    display: false
                },
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'd MMM'
                    },
                    stepSize: 3,
                    max: null,
                    min: null
                },
                ticks: {
                    fontSize: 10.2,
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0
                }
            },
            y: {
                grid: {
                    display: false
                },
                ticks: {
                    fontSize: 10.2,
                    beginAtZero: true,
                    suggestedMax: this.getMaxDataValue()
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'bottom'
            },
            tooltip: {
                callback: {
                    title: tipItem => {
                        return null;
                    },
                    label: (tipItem, data) => {
                        let label = data.datasets[tipItem.datasetIndex].label || '';
                        if (label) label += ': ';
                        return label + (+tipItem.yLabel).toFixed(1);
                    }
                }
            }
        },
        maintainAspectRatio: false,
        animation: {
            duration: 0
        }
    };
    portsChartOptionsWide: any = {
        responsive: true,
        scales: {
            x: {
                type: 'time',
                grid: {
                    display: false
                },
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'd MMM'
                    },
                    stepSize: 3,
                    max: null,
                    min: null
                },
                ticks: {
                    fontSize: 10.2,
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0
                }
            },
            y: {
                grid: {
                    display: false
                },
                ticks: {
                    fontSize: 10.2,
                    beginAtZero: true
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'bottom'
            },
            tooltip: {
                callback: {
                    title: tipItem => {
                        return null;
                    },
                    label: (tipItem, data) => {
                        let label = data.datasets[tipItem.datasetIndex].label || '';
                        if (label) label += ': ';
                        return label + (+tipItem.yLabel).toFixed(1);
                    }
                }
            }
        },
        maintainAspectRatio: false,
        animation: {
            duration: 0
        }
    };

    constructor(private accountService: AccountService, private store$: Store<AppState>) {
        this.portsLineData = {
            datasets: [this.avgPortsEMEA, this.avgPortsNA, this.avgPortsAPAC, this.avgPortsAll]
        };
    }

    ngOnInit() {
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet._equipmentId,
                command: this.dashlet._commandTypeIdPort,
                idType: 'customerId'
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet._equipmentId,
                commandTypeId: this.dashlet._commandTypeIdPort
            })
        );

        this.store$
            .pipe(
                select(selectDataFromCommonEntity(this.dashlet._equipmentId + this.dashlet._commandTypeIdPort)),
                takeUntil(this.destroy$)
            )
            .subscribe(data => {
                if (data) {
                    this.dashlet.processPortData(data);
                }
            });

        this.dashlet.onChartDataUpdated.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.updateChart();
        });
        var userDetail = this.accountService.getUserDetails();
        this.entityGroup = userDetail.EntityGroup;
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        if (this.dashlet) this.dashlet.dispose();
    }

    private updateChart() {
        this.avgPortsEMEA.data = this.dashlet.avgPortsPerCustomerArrayEMEA;
        this.avgPortsNA.data = this.dashlet.avgPortsPerCustomerArrayNA;
        this.avgPortsAPAC.data = this.dashlet.avgPortsPerCustomerArrayAPAC;
        this.avgPortsAll.data = this.dashlet.avgPortsPerCustomerArrayAll;

        //update axis range
        let max = new Date();
        max.setDate(max.getDate() - 1);
        let min = new Date(max);
        min.setDate(min.getDate() - 15); //then show up to 15 days before max
        this.portsChartOptions.scales.x.min = min;
        this.portsChartOptions.scales.x.max = max;

        min = new Date(max);
        min.setDate(min.getDate() - 35); // show up to 35 days before max
        this.portsChartOptionsWide.scales.x.min = min;
        this.portsChartOptionsWide.scales.x.max = max;
        if (this.portsChart) {
            this.portsChart.chart.options.scales.y.ticks.suggestedMax = this.getMaxDataValue();
            this.portsChart.updateChart();
        }
        if (this.portsChartWide) {
            this.portsChartWide.chart.options.scales.y.ticks.suggestedMax = this.getMaxDataValue();
            this.portsChartWide.updateChart();
        }
    }

    get smallLayout() {
        return this.dashlet.getSize().id === 0;
    }

    get mediumLayout() {
        return this.dashlet.getSize().id === 1;
    }

    get wideLayout() {
        return this.dashlet.getSize().id === 2;
    }

    getMaxDataValue() {
        if (this.dashlet != null) {
            let maxAll = Math.max.apply(
                Math,
                this.dashlet.avgPortsPerCustomerArrayAll.map(function (point) {
                    return point.y;
                })
            );
            let maxEMEA = Math.max.apply(
                Math,
                this.dashlet.avgPortsPerCustomerArrayEMEA.map(function (point) {
                    return point.y;
                })
            );
            let maxNA = Math.max.apply(
                Math,
                this.dashlet.avgPortsPerCustomerArrayNA.map(function (point) {
                    return point.y;
                })
            );
            let maxAPAC = Math.max.apply(
                Math,
                this.dashlet.avgPortsPerCustomerArrayAPAC.map(function (point) {
                    return point.y;
                })
            );

            return Math.max(maxAll, maxEMEA, maxNA, maxAPAC) + 100;
        } else {
            return null;
        }
    }
}
