/* eslint-disable no-undef */
import * as Actions from '@actions/index';
import { AccountService, DashletService, ThresholdService, TileGridService, TimeoutService } from '@services/index';
import { AppState, selectDataFromCommonEntity, selectEntity, selectEntityTimer } from '@reducers/index';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    ArchiveDestinationExpansionData,
    ColumnTypes,
    CustomerLogo,
    DashletSystemHealthAvayaContact,
    LinkData,
    MenuItem,
    TableChannelData,
    Threshold
} from '@models/index';
import { select, Store } from '@ngrx/store';
import { ThresholdItemComponent } from '../threshold-item/threshold-item.component';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { DashletSectionProcessorMemoryComponent } from '../dashlet-section-processor-memory/dashlet-section-processor-memory.component';

@Component({
    selector: 'app-dashlet-system-health-avaya-contact',
    templateUrl: './dashlet-system-health-avaya-contact.component.html',
    styleUrls: ['./dashlet-system-health-avaya-contact.component.scss']
})
export class DashletSystemHealthAvayaContactComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletSystemHealthAvayaContact;
    @ViewChild('thresholdItem') thresholdItem: ThresholdItemComponent;
    @ViewChild('processorMemory') processorMemory: DashletSectionProcessorMemoryComponent;

    entityGroup: number;
    customerId: string;
    occupancyContextMenuItems: MenuItem[];
    openSubcontainer = '';
    openSubcontainerSeverity = 0;
    selectedThreshold: Threshold;
    selectedField: string;

    returnCustomScriptOutputData: any;

    public statisticsColumns: ColumnTypes[] = [
        {
            columnDef: 'title',
            header: ''
        },
        {
            columnDef: 'today',
            header: 'Today',
            type: 'numeric'
        },
        {
            columnDef: 'reboot',
            header: 'Since Reboot',
            type: 'numeric'
        }
    ];

    public linkColumns: ColumnTypes[] = [
        {
            columnDef: 'witscscmLinkState',
            header: '',
            cell: (row: LinkData) => (row.witscscmLinkState === 0 ? 2 : 0),
            type: 'severity'
        },
        {
            columnDef: 'witscscmLinkIndex',
            header: 'Name'
        },
        {
            columnDef: 'witscscmLinkTypeNumber',
            header: 'Number'
        },
        {
            columnDef: 'witscscmLinkType',
            header: 'Type'
        },
        {
            columnDef: 'witscscmLinkPath',
            header: 'Path'
        }
    ];

    public channelColumns: ColumnTypes[] = [
        {
            columnDef: 'status',
            header: 'Status'
        },
        {
            columnDef: 'total',
            header: 'Channels',
            type: 'numeric'
        }
    ];

    public channelDetailColumns: ColumnTypes[] = [
        {
            columnDef: 'witscscmChannelOwner',
            header: 'Owner'
        },
        {
            columnDef: 'witscscmChannelRecording',
            header: 'Recording'
        },
        {
            columnDef: 'witscscmChannelPort',
            header: 'Port'
        }
    ];

    public archiveColumns: ColumnTypes[] = [
        {
            columnDef: 'expand',
            header: ''
        },
        {
            columnDef: 'path',
            header: 'Path'
        },
        {
            columnDef: 'state',
            header: 'State'
        }
    ];
    public rowExpansionArchiveColumns: ColumnTypes[] = [
        {
            columnDef: 'free',
            header: 'Free',
            cell: (row: ArchiveDestinationExpansionData) => `${row.free}`,
            type: 'numeric'
        },
        {
            columnDef: 'callsPending',
            header: 'Calls Pending',
            cell: (row: ArchiveDestinationExpansionData) => `${row.callsPending}`,
            type: 'numeric'
        },
        {
            columnDef: 'mbPending',
            header: 'Mbs Pending',
            cell: (row: ArchiveDestinationExpansionData) => `${row.mbPending}`,
            type: 'numeric'
        }
    ];

    public linkMenu: MenuItem[] = [
        {
            label: 'Details',
            title: '',
            disabled: false,
            command: (row: TableChannelData) => this.openChannelDrilldown(row)
        }
    ];

    private subscription: Subscription = new Subscription();
    private destroy$ = new Subject<void>();

    constructor(
        private dashletService: DashletService,
        private accountService: AccountService,
        private thresholdService: ThresholdService,
        private tileGridService: TileGridService,
        public timeoutService: TimeoutService,
        private store$: Store<AppState>
    ) {}

    ngOnInit() {
        const userDetail = this.accountService.getUserDetails();
        this.entityGroup = userDetail.EntityGroup;
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );

        if (this.dashlet.equipment.equipmentId) {
            // server data
            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    command: this.dashlet.commandTypeIdServer
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    commandTypeId: this.dashlet.commandTypeIdServer
                })
            );
            this.store$.dispatch(
                Actions.GetEntityTimer({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    commandTypeId: this.dashlet.commandTypeIdServer,
                    uniqueId: this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdServer
                })
            );

            this.subscription.add(
                this.store$
                    .pipe(
                        select(
                            selectDataFromCommonEntity(
                                this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdServer
                            )
                        )
                    )
                    .subscribe(data => {
                        if (data) {
                            this.dashlet.processServerData(data);
                        }
                    })
            );

            this.subscription.add(
                this.store$
                    .pipe(
                        select(selectEntityTimer(this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdServer))
                    )
                    .subscribe(dataTimeoutExpired => {
                        this.dashlet.uptimeMs = dataTimeoutExpired ? null : this.dashlet.uptimeMs;
                    })
            );

            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    command: this.dashlet.commandTypeIdHealth
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    commandTypeId: this.dashlet.commandTypeIdHealth
                })
            );
            this.subscription.add(
                this.store$
                    .pipe(
                        select(
                            selectDataFromCommonEntity(
                                this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdHealth
                            )
                        )
                    )
                    .subscribe(data => {
                        if (data) {
                            this.dashlet.processHealthData(data);
                        }
                    })
            );

            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    command: this.dashlet.commandTypeIdLinks
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    commandTypeId: this.dashlet.commandTypeIdLinks
                })
            );
            this.subscription.add(
                this.store$
                    .pipe(
                        select(
                            selectDataFromCommonEntity(
                                this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdLinks
                            )
                        )
                    )
                    .subscribe(data => {
                        if (data) {
                            this.dashlet.processLinksData(data);
                        }
                    })
            );

            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    command: this.dashlet.commandTypeIdChannel
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    commandTypeId: this.dashlet.commandTypeIdChannel
                })
            );
            this.subscription.add(
                this.store$
                    .pipe(
                        select(
                            selectDataFromCommonEntity(
                                this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdChannel
                            )
                        )
                    )
                    .subscribe(data => {
                        if (data) {
                            this.dashlet.processChannelData(data);
                        }
                    })
            );

            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    command: this.dashlet.commandTypeIdArchiveDestination
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    commandTypeId: this.dashlet.commandTypeIdArchiveDestination
                })
            );
            this.subscription.add(
                this.store$
                    .pipe(
                        select(
                            selectDataFromCommonEntity(
                                this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdArchiveDestination
                            )
                        ),
                        takeUntil(this.destroy$)
                    )
                    .subscribe(data => {
                        if (data) {
                            this.dashlet.processArchiveDestinationData(data);
                        }
                    })
            );

            combineLatest([
                this.store$.pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdHealth
                        )
                    )
                ),
                this.store$.pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdMemoryUsage
                        )
                    )
                )
            ]).pipe(
                tap(data => {
                    if (data) {
                        this.dashlet.processProcessMemory(data);
                    }
                }),
                takeUntil(this.destroy$)
            );

            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    command: this.dashlet.commandTypeIdStatistics
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    commandTypeId: this.dashlet.commandTypeIdStatistics
                })
            );
            this.subscription.add(
                this.store$
                    .pipe(
                        select(
                            selectDataFromCommonEntity(
                                this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdStatistics
                            )
                        )
                    )
                    .subscribe(data => {
                        if (data) {
                            this.dashlet.processStatisticsData(data);
                        }
                    })
            );
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        this.subscription.unsubscribe();
    }

    getSeverityColor(sev: number) {
        return this.dashletService.getSeverityColor(sev);
    }

    getLinksSeverityColor(elem: LinkData) {
        if (elem.witscscmLinkState === 0) {
            return this.getSeverityColor(2);
        } else {
            return this.getSeverityColor(0);
        }
    }

    openLinkDrilldown() {
        this.openSubcontainer = 'link';
        this.dashlet.displayHeader = false;
    }

    private openChannelDrilldown(event: TableChannelData): void {
        this.dashlet.processChannelDrilldownData(event.stateOriginal);
        this.openSubcontainer = 'channel';
        this.dashlet.displayHeader = false;
    }

    closeDetails() {
        this.openSubcontainer = null;
        this.dashlet.displayHeader = true;
    }

    public getTableHeight() {
        let height = 300;

        if (this.dashlet.showHealth) {
            height += 100;
        }
        if (this.dashlet.showLinkDetails) {
            height += 100;
        }
        if (this.dashlet.showChannelDetails) {
            height += 100;
        }
        if (this.dashlet.showArchiveDestinationDetails) {
            height += 100;
        }
        return height.toString() + 'px';
    }

    //Thresholds & Reports
    openThresholdAlerts(elementId: string, commandTypeId: string, diskName?: string) {
        this.openSubcontainer = 'createThreshold';
        this.dashlet.displayHeader = false;
        this.selectedThreshold = new Threshold();
        this.selectedThreshold.commandTypeId = commandTypeId;
        this.selectedThreshold.field = elementId;
        this.selectedThreshold.whereCreated = 'Dashboard';
        if (diskName) this.selectedThreshold.fieldInstance = diskName;
    }

    openAlarmIconThreshold() {
        this.thresholdService.openThresholdManagement(this.accountService.getUserDetails().EntityId);
    }

    onSubmit() {
        this.subscription.add(
            this.thresholdItem.onSubmit().subscribe(result => {
                if (result) {
                    this.selectedThreshold.dapThresholdId = result['DapThresholdId'];
                    this.dashlet.thresholds.push(this.selectedThreshold);
                    switch (this.selectedThreshold.field) {
                        case 'processor':
                            ++this.processorMemory.cpuUsageData.thresholdsNumber;
                            break;
                        case 'memory':
                            ++this.processorMemory.memoryUsageData.thresholdsNumber;
                            break;
                        case 'disk':
                            let diskUsage = this.processorMemory.diskUsageData.find(d => {
                                return d.diskName === this.selectedThreshold.fieldInstance;
                            });
                            if (diskUsage) {
                                ++diskUsage.thresholdsNumber;
                            }
                            break;
                        default:
                            break;
                    }
                    this.tileGridService.thresholdCreated(
                        this.selectedThreshold.name + ' threshold added via ' + this.dashlet.title
                    );
                } else {
                    this.tileGridService.thresholdCreated('Threshold add failed');
                }
            })
        );
        this.closeDetails();
    }

    saveEdit() {
        this.subscription.add(
            this.thresholdItem.saveEdit().subscribe(result => {
                if (result) {
                    this.tileGridService.thresholdCreated(
                        this.selectedThreshold.name + ' threshold edited via ' + this.dashlet.title
                    );
                } else {
                    this.tileGridService.thresholdCreated('Threshold edit failed');
                }
            })
        );
        this.closeDetails();
    }
}
