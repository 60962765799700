import * as ProcessUSageActions from '@actions/process-usage/process-usage.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService, TimeoutService } from '@services/index';
import { RealTimeNotify } from '@models/index';

@Injectable()
export class ProcessUsageEffects {
    constructor(
        private actions$: Actions,
        private realtimeGatewayService: RealtimeGatewayService,
        private timeoutService: TimeoutService
    ) {}

    getNotifyProcessUsage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessUSageActions.GetNotifyProcessUsage),
            switchMap(action =>
                this.realtimeGatewayService.observe(action.equipmentId, action.command).pipe(
                    catchError(err => of(ProcessUSageActions.GetNotifyProcessUsagesFailure({ error: err }))),
                    mergeMap((data: RealTimeNotify) => [
                        ProcessUSageActions.GetNotifyProcessUsageSuccess({ data }),
                        ProcessUSageActions.GetProcessUsage({ ...data })
                    ])
                )
            )
        )
    );

    getProcessUsage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessUSageActions.GetProcessUsage),
            mergeMap(action =>
                this.realtimeGatewayService.getDocument(action.equipmentId, action.commandTypeId).pipe(
                    map(data => ProcessUSageActions.GetProcessUsageSuccess({ data, equipmentId: action.equipmentId })),
                    catchError(err => of(ProcessUSageActions.GetProcessUsagesFailure({ error: err })))
                )
            )
        )
    );

    getProcessUsageTimer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessUSageActions.GetProcessUsageTimer),
            switchMap(action =>
                this.timeoutService.observeTimerNew(action.equipmentId, action.command).pipe(
                    catchError(err => of(ProcessUSageActions.GetProcessUsageTimerFailure({ error: err }))),
                    map((data: any) =>
                        ProcessUSageActions.GetProcessUsageTimerSuccess({
                            data: { data, equipmentId: action.equipmentId }
                        })
                    )
                )
            )
        )
    );
}
