export class BarDataSet {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
    borderWidth: number;
    borderRadius: number;
    borderSkipped: boolean;
    fill: boolean;
}
