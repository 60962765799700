import * as ProcessAcdActions from '@actions/process-acd/process-acd.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService } from '@services/index';
import { RealTimeNotify } from '@models/index';


@Injectable()
export class ProcessAcdEffects {

  constructor(private actions$: Actions, private realtimeGatewayService: RealtimeGatewayService) { }

  getNotifyProcessAcd$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessAcdActions.GetNotifyProcessAcd),
    switchMap((action) => this.realtimeGatewayService.observe(action.equipmentId, action.commandTypeId).pipe(
      catchError((err) => of(
        ProcessAcdActions.GetNotifyProcessAcdFailure({ error: err })
        )
      ),
      mergeMap((data: RealTimeNotify) => [
        ProcessAcdActions.GetNotifyProcessAcdSuccess({ data }),
        ProcessAcdActions.GetProcessAcd({ ...data })
      ],
      ),
    ),
    )
  ));

  getProcessAcd$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessAcdActions.GetProcessAcd),
    mergeMap((action) => this.realtimeGatewayService.getDocument(action.equipmentId, action.commandTypeId).pipe(
      map((data) => ProcessAcdActions.GetProcessAcdSuccess({ data:{ data, equipmentId: action.equipmentId} }),
      ),
      catchError((err) => of(ProcessAcdActions.GetProcessAcdFailure({ error: err })))
    ))
  ));

}
