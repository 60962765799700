import { Customer, Dashlet, Equipment, Location } from '@models/index';

export interface TeamsRoomDataRow {
    ragIndicator: number;
    roomDisplayName: string;
    deviceType: string;
    makeAndModel: string;
    health: string;
    activity: string;
}

enum DeviceTypeFriendlyName {
    TEAMS_ROOM = 'Teams Room',
    SURFACE_HUB = 'Surface Hub',
    COLLABORATION_BAR = 'Collaboration Bar',
    TEAMS_DISPLAY = 'Teams Display',
    TOUCH_CONSOLE = 'Touch Console',
    TEAMS_PANEL = 'Teams Panel',
    UNKNOWN = ''
}

export enum HealthStatusFriendlyName {
    CRITICAL = 'critical',
    NON_URGENT = 'non-urgent',
    HEALTHY = 'healthy',
    OFFLINE = 'offline',
    UNKNOWN = 'unknown'
}

export class DashletSystemHealthMSTeamsRoomsSummary extends Dashlet {
    public customer: Customer;
    public location: Location;
    public equipment: Equipment;
    public msTeamsRoomsRowData: TeamsRoomDataRow[] = [];
    public isDataOld: boolean = false;
    public showApiStatus: boolean = true; // To show API status in header

    public readonly commandTypeIdTeamsRoomsSummary = 'BF61A9FC-4430-415E-9368-E7907E1D6903';
    constructor() {
        super();
        this.sizes = [
            {
                id: 0,
                label: 'Small',
                rows: 7, // this will responsible for entire dashlet height
                cols: 12 // this will responsible for entire dashlet width
            }
        ];
        this.applySize(0);
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        this.configured = v.customer && v.location && v.equipment;

        this.customer = v.customer
            ? new Customer(v.customer.value, v.customer.label)
            : (this.customer = new Customer('', ''));

        this.location = v.location ? new Location(v.location.value, v.location.label) : new Location('', '');

        this.equipment = v.equipment ? new Equipment(v.equipment.value, v.equipment.label) : new Equipment('', '');
        this.generatedNameTag = this.configured ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
    }

    processMSTeamsRoomData(teamsRoomData) {
        this.msTeamsRoomsRowData = [];

        teamsRoomData.data.forEach(item => {
            const { CurrentUserDisplayName, DeviceType, Manufacturer, Model, HealthStatus, ActivityState } = item;

            const friendlyDeviceType = this.getFriendlyDeviceTypeName(DeviceType);
            const friendlyHealthStatus = this.getFriendlyHealthStatusName(HealthStatus);
            const ragValueGenerateBasedOnHealth = this.getRagValuebyHealth(friendlyHealthStatus);

            const tableRowData: TeamsRoomDataRow = {
                ragIndicator: ragValueGenerateBasedOnHealth,
                roomDisplayName: CurrentUserDisplayName,
                deviceType: friendlyDeviceType,
                makeAndModel: Manufacturer + ' | ' + Model,
                health: friendlyHealthStatus,
                activity: ActivityState
            };

            this.msTeamsRoomsRowData.push(tableRowData);
        });
    }

    private getFriendlyDeviceTypeName(deviceType: string): DeviceTypeFriendlyName {
        switch (deviceType) {
            case 'teamsRoom':
                return DeviceTypeFriendlyName.TEAMS_ROOM;
            case 'surfaceHub':
                return DeviceTypeFriendlyName.SURFACE_HUB;
            case 'collaborationBar':
                return DeviceTypeFriendlyName.COLLABORATION_BAR;
            case 'teamsDisplay':
                return DeviceTypeFriendlyName.TEAMS_DISPLAY;
            case 'touchConsole':
                return DeviceTypeFriendlyName.TOUCH_CONSOLE;
            case 'teamsPanel':
                return DeviceTypeFriendlyName.TEAMS_PANEL;
            default:
                return DeviceTypeFriendlyName.UNKNOWN;
        }
    }

    private getFriendlyHealthStatusName(healthStatus: string): HealthStatusFriendlyName {
        switch (healthStatus) {
            case 'healthy':
                return HealthStatusFriendlyName.HEALTHY;
            case 'critical':
                return HealthStatusFriendlyName.CRITICAL;
            case 'nonUrgent':
                return HealthStatusFriendlyName.NON_URGENT;
            case 'offline':
                return HealthStatusFriendlyName.OFFLINE;
            default:
                'unknown';
                return HealthStatusFriendlyName.UNKNOWN;
        }
    }

    private getRagValuebyHealth(healthStatusFriendlyName: HealthStatusFriendlyName): number {
        switch (healthStatusFriendlyName) {
            case HealthStatusFriendlyName.CRITICAL:
                return 1;
            case HealthStatusFriendlyName.NON_URGENT:
                return 2;
            case HealthStatusFriendlyName.HEALTHY:
                return 3;
            case HealthStatusFriendlyName.OFFLINE:
                return 4;
            default:
                HealthStatusFriendlyName.UNKNOWN;
                return 4;
        }
    }
    public resetData() {}
    public dispose() {}
}
