import { Customer, Dashlet, IPNREndpointsData } from '@models/index';
import { DashletDataProcessService, RealtimeGatewayService } from '@services/index';

export class DashletIPNREndpoints extends Dashlet {
    public commandTypeIdIPNREndpoints = '25fb7a14-dbf2-4944-a13b-de090d12531c';

    public equipmentId!: string;
    public customer!: Customer;
    private lastUpdateTime: Date;

    ipnrTotal: IPNREndpointsData[] = [];
    ipnrs: IPNREndpointsData[] = [];
    ipnrData: IPNREndpointsData[] = [];
    ipnrNow: any = null;
    sortColumn = 'BGColor';
    sortDesc = true;

    ipnrAverageTotal = 0;
    ipnrNowTotal = 0;
    loading = true;

    constructor(
        private realtimeService: RealtimeGatewayService,
        private dashletDataService: DashletDataProcessService
    ) {
        super();
        this.lastUpdateTime = new Date();
        this.sizes = [
            {
                id: 0,
                label: 'Small',
                cols: 5,
                rows: 4
            },
            {
                id: 1,
                label: 'Medium',
                cols: 5,
                rows: 7
            },
            {
                id: 2,
                label: 'Large',
                cols: 5,
                rows: 8
            }
        ];
        this.applySize(0);
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        // unsub realtime feed
        this.unsubData();

        // read settings
        this.configured = v.customer && v.location && v.equipment;
        if (this.configured) {
            if (v.customer.value !== this.customer.customerId || v.equipment.value !== this.equipmentId) {
                this.getIPNREndpoints(v.customer.value, v.equipment.value);
            }
        }
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }

        this.equipmentId = v.equipment && v.equipment.value ? v.equipment.value : null;
        this.generatedNameTag = this.configured ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;
    }
    private getIPNREndpoints(customerId: string, equipmentId: string) {
        this.dashletDataService.getIPNREndpoints(customerId, equipmentId).subscribe(e => {
            if (e.length > 0) {
                this.ipnrTotal = e.slice(0, 1);
                this.ipnrs = e.slice(1);
                if (this.ipnrNow !== null) {
                    this.populataData();
                }
            }
        });
    }
    private diff_minutes(dt2: Date, dt1: Date) {
        let diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= 60;
        return Math.abs(Math.round(diff));
    }

    dispose() {
        this.unsubData();
    }

    private unsubData() {
        if (this.equipmentId) {
            this.realtimeService.unsubscribe(this.equipmentId, this.commandTypeIdIPNREndpoints);
        }
    }

    resetData() {
        this.ipnrs = [];
        this.ipnrTotal = [];
        this.ipnrNow = null;
    }

    public processDataIpo(data: any) {
        this.ipnrNow = data[1].data;
        if (this.diff_minutes(new Date(), this.lastUpdateTime) > 30) {
            this.getIPNREndpoints(this.customer.customerId, this.equipmentId);
        }
        if (this.ipnrTotal.length > 0 && this.ipnrs.length > 0) {
            this.populataData();
        }
    }

    private populataData() {
        this.ipnrData = this.ipnrs.map(ipnr => {
            return {
                IPNRName: ipnr['ipnrName'],
                IPNRNumber: ipnr['ipnrNumber'],
                BGColor: ipnr['bgColor'],
                IPEndpointsAverage: ipnr['ipEndpointsAverage'],
                IPEndpointsNow:
                    this.ipnrNow.filter(x => +x['IPNR'] === +ipnr['ipnrNumber']) &&
                    this.ipnrNow.filter(x => +x['IPNR'] === +ipnr['ipnrNumber'])[0] &&
                    this.ipnrNow.filter(x => +x['IPNR'] === +ipnr['ipnrNumber'])[0]['StationCount']
                        ? +this.ipnrNow.filter(x => +x['IPNR'] === +ipnr['ipnrNumber'])[0]['StationCount']
                        : 0
            } as IPNREndpointsData;
        });

        this.ipnrData.forEach(ipnr => {
            const val = Math.abs(ipnr.IPEndpointsAverage - ipnr.IPEndpointsNow) / ipnr.IPEndpointsAverage;
            ipnr.BGColor = 0;
            if (val > 3 / 10) {
                ipnr.BGColor = 2;
            }
        });

        this.ipnrAverageTotal = this.ipnrData.reduce(function (acc, ipnr) {
            return acc + ipnr.IPEndpointsAverage;
        }, 0);

        this.ipnrNowTotal = this.ipnrData.reduce(function (acc, ipnr) {
            return acc + ipnr.IPEndpointsNow;
        }, 0);
    }
}
