import * as UserContextActions from '@actions/user-context/user-context.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OAuthService } from 'angular-oauth2-oidc';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { AuthenticationService } from '@services/index';

@Injectable()
export class GetUserContextEffects {
    constructor(
        private actions$: Actions,
        private oauthService: OAuthService,
        private authenticationService: AuthenticationService
    ) {}
    getUserContext$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserContextActions.GetUserContexts),
            mergeMap(() =>
                from(this.oauthService.loadUserProfile()).pipe(
                    map(data => UserContextActions.GetUserContextsSuccess({ data }))
                )
            ),
            catchError(err => {
                return of(UserContextActions.GetUserContextsFailure({ error: err }));
            })
        )
    );
}
