import { Injectable } from '@angular/core';
import { ChartColors } from '@models/index';

@Injectable()
export class DashletService {
    constructor() {}

    public getSeverityColor(severity: number): string {
        switch (severity) {
            case 3:
                return this.getColorFromCssVariable('--success-color');
            case 2:
                return this.getColorFromCssVariable('--failure-color');
            case 1:
                return this.getColorFromCssVariable('--warning-color');
            case 0:
                return this.getColorFromCssVariable('--success-color');
            case -1:
            default:
                return this.getColorFromCssVariable('--grey-color-light');
        }
    }

    public getAlarmSeverityColor(severity: number): string {
        if (severity > 5) {
            return this.getColorFromCssVariable('--infoblue-color');
        } else if (severity > 2) {
            return this.getColorFromCssVariable('--warning-color');
        } else if (severity >= 0) {
            return this.getColorFromCssVariable('--failure-color');
        } else {
            return this.getColorFromCssVariable('--grey-color-light');
        }
    }

    public getChartColors(): ChartColors {
        return {
            pink: this.getColorFromCssVariable('--infopink-color'),
            pinkDark: this.getColorFromCssVariable('--infopink-color-dark'),
            blue: this.getColorFromCssVariable('--infoblue-color'),
            blueLight: this.getColorFromCssVariable('--infoblue-color-light'),
            blueLighter: this.getColorFromCssVariable('--infoblue-color-lighter'),
            blueDark: this.getColorFromCssVariable('--infoblue-color-dark'),
            blueDarker: this.getColorFromCssVariable('--infoblue-color-darker'),
            green: this.getColorFromCssVariable('--success-color'),
            greenDark: this.getColorFromCssVariable('--success-color-dark'),
            amber: this.getColorFromCssVariable('--warning-color'),
            amberDark: this.getColorFromCssVariable('--warning-color-dark'),
            red: this.getColorFromCssVariable('--failure-color'),
            redDark: this.getColorFromCssVariable('--failure-color-dark'),
            grey: this.getColorFromCssVariable('--grey-color'),
            greyLight: this.getColorFromCssVariable('--grey-color-light'),
            greyLighter: this.getColorFromCssVariable('--grey-color-lighter'),
            greyDark: this.getColorFromCssVariable('--grey-color-dark'),
            greyDarker: this.getColorFromCssVariable('--grey-color-darker'),
            yellow: this.getColorFromCssVariable('--infoyellow-color'),
            purple: this.getColorFromCssVariable('--infopurple-color'),
            purpleDark: this.getColorFromCssVariable('--infopurple-color-dark'),
            cyan: this.getColorFromCssVariable('--infocyan-color'),
            cyanDark: this.getColorFromCssVariable('--infocyan-color-dark'),
            brown: this.getColorFromCssVariable('--infobrown-color'),
            brownLight: this.getColorFromCssVariable('--infobrown-color-light'),
            brownDark: this.getColorFromCssVariable('--infobrown-color-dark')
        };
    }

    public getMsTeamsRoomSeverityColor(severity: number): string {
        switch (severity) {
            case 1:
                return this.getColorFromCssVariable('--failure-color');
            case 2:
                return this.getColorFromCssVariable('--warning-color');
            case 3:
                return this.getColorFromCssVariable('--success-color');
            case 4:
                return this.getColorFromCssVariable('--grey-color-light');
            default:
                return this.getColorFromCssVariable('--grey-color-light');
        }
    }

    private getColorFromCssVariable(variableName: string): string {
        // eslint-disable-next-line no-undef
        return getComputedStyle(document.documentElement).getPropertyValue(variableName);
    }

    public getCapacityUnsubscribedText(): string {
        return 'You must be subscribed to Capacity Manager to run this report. Contact your administrator for more information.';
    }

    public getAvailabilityUnsubscribedText(): string {
        return 'You must be subscribed to Availability Manager to run this report. Contact your administrator for more information.';
    }
}
