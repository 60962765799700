import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ReportService } from '@services/index';
import * as ReportActions from '@actions/report/report.actions';
import { FeatureSubscriptions, FeatureSubscriptionsRespObj } from '@models/index';

@Injectable()
export class ReportEffects {
    constructor(private actions$: Actions, private reportService: ReportService) {}

    getReportSubscriptions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportActions.GetReportSubscriptions),
            mergeMap(action => {
                if (!action.customerId) {
                    return of(ReportActions.GetReportSubscriptionsFailure({ error: 'No Customer ID' }));
                }
                return this.reportService.getSubscriptions(action.customerId).pipe(
                    map((reportSubscriptions: FeatureSubscriptions) => {
                        return ReportActions.GetReportSubscriptionsSuccess({
                            data: {
                                customerId: action.customerId,
                                reportSubscriptions: reportSubscriptions
                            } as FeatureSubscriptionsRespObj
                        });
                    }),
                    catchError(err => of(ReportActions.GetReportSubscriptionsFailure({ error: err })))
                );
            })
        )
    );
}
