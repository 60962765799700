import * as Actions from '@actions/index';
import {
    AccountService,
    DashletDataProcessService,
    DashletService,
    ThresholdService,
    TileGridService,
    TimeService
} from '@services/index';
import {
    AppState,
    selectDataFromCommonEntity,
    selectEntity,
    selectEquipmentInfo,
    selectLocations,
    selectMosCapableReceivers,
    selectTypeEquipmentsAtLocation
} from '@reducers/index';
import { Subject, Subscription } from 'rxjs';
import {
    CMSMosData_Agent,
    CMSMosData_Skill,
    CMSMosData_VDN,
    CodecCategorized,
    DashletVQMDailySummary,
    DSCPCategorized,
    IPNRCategorized,
    Layer3Categorized,
    Point,
    Threshold,
    MOSSummaryData,
    MOSSummaryBreakdownData,
    MosQuality,
    Location,
    ColumnTypes,
    MenuItem,
    SeverityRatioBar,
    RealtimeGatewayDocument,
    ChartCell
} from '@models/index';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { map, retry, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { ThresholdItemComponent } from '../threshold-item/threshold-item.component';
import { UIChart } from 'primeng/chart';

import { CustomerLogo } from '@models/index';
import { EquipmentInfo } from '@models/dataObjects/interfaces/CentralApi.models';
import { SelectItem } from 'primeng/api';
import { environment } from '@environments/environment';
import { DashletLineGraphComponent } from '../dashlet-line-graph/dashlet-line-graph.component';

interface SortOptions {
    sortColumn: string[];
    sortDesc: boolean[];
    sortType: string[];
}
interface MosDataSource {
    severity: number;
    mos: string;
    noOfStreams: string;
    percentage: string;
}

@Component({
    selector: 'app-dashlet-vqmdaily-summary',
    templateUrl: './dashlet-vqmdaily-summary.component.html',
    styleUrls: ['./dashlet-vqmdaily-summary.component.scss']
})
export class DashletVQMDailySummaryComponent implements OnInit, OnDestroy {
    //#region Class Members

    @Input() dashlet: DashletVQMDailySummary;
    @ViewChild('totalPie') totalPie: UIChart;
    @ViewChild('partialPie') partialPie: UIChart;
    // @ViewChild('mosOverTimeLine') mosOverTimeLine: UIChart;
    @ViewChild('dscpBar') dscpBar: UIChart;
    @ViewChild('ipnrBar') ipnrBar: UIChart;

    readonly DSCP = DashletVQMDailySummary.DSCP;
    readonly IPNR = DashletVQMDailySummary.IPNR;
    @ViewChild('thresholdItem') thresholdItem: ThresholdItemComponent;

    private pmosOverTimeLineGraph: DashletLineGraphComponent;
    get mosOverTimeLine() {
        return this.pmosOverTimeLineGraph;
    }
    @ViewChild('mosOverTimeLine') set mosOverTimeLine(value: DashletLineGraphComponent) {
        this.pmosOverTimeLineGraph = value;
    }

    private uiSubscription: Subscription = new Subscription();
    private dataSubscription: Subscription = new Subscription();

    public mosTableDataSource: MosDataSource[] = [
        {
            severity: 0,
            mos: '>= 4.0',
            noOfStreams: '---',
            percentage: '---'
        },
        {
            severity: 1,
            mos: '3.6 - 4.0',
            noOfStreams: '---',
            percentage: '---'
        },
        {
            severity: 2,
            mos: '< 3.6',
            noOfStreams: '---',
            percentage: '---'
        }
    ];

    public mosTableColumns: ColumnTypes[] = [
        {
            columnDef: 'severity',
            header: '',
            cell: (element: MosDataSource): string => `${element.severity}`,
            type: 'severity'
        },
        {
            columnDef: 'mos',
            header: 'MOS',
            cell: (element: MosDataSource): string => `${element.mos}`
        },
        {
            columnDef: 'noOfStreams',
            header: 'Number of Streams',
            cell: (element: MosDataSource): string => `${element.noOfStreams}`,
            type: 'numeric'
        },
        {
            columnDef: 'percentage',
            header: 'Percentage',
            cell: (element: MosDataSource): string => `${element.percentage}`,
            type: 'numeric'
        }
    ];

    private expanderColumn: ColumnTypes = {
        columnDef: 'expand',
        header: ''
    };

    private commonColumns: ColumnTypes[] = [
        {
            columnDef: 'TotalStreams',
            header: 'Streams',
            cell: (element: any): string => `${element.TotalStreams}`,
            type: 'numeric'
        },
        {
            columnDef: 'MosGood',
            header: 'Mos >= 4.0',
            cell: (element: any): string => `${element.MosGood}`,
            type: 'numeric'
        },
        {
            columnDef: 'MosWarning',
            header: 'Mos 3.6 - 4.0',
            cell: (element: any): string => `${element.MosWarning}`,
            type: 'numeric'
        },
        {
            columnDef: 'MosDanger',
            header: 'Mos <3.6',
            cell: (element: any): string => `${element.MosDanger}`,
            type: 'numeric'
        },
        {
            columnDef: 'Percentage',
            header: 'Percentage',
            cell: (element: any): SeverityRatioBar[] => {
                return [
                    {
                        value: parseInt(element.MosGood),
                        colour: this.getSeverityColor(0),
                        tooltip: `${this.getSeverityBarPercentage(
                            element.MosGood,
                            element.MosDanger + element.MosWarning + element.MosGood
                        )} - MOS >= 4.0`
                    },
                    {
                        value: parseInt(element.MosWarning),
                        colour: this.getSeverityColor(1),
                        tooltip: `${this.getSeverityBarPercentage(
                            element.MosWarning,
                            element.MosDanger + element.MosWarning + element.MosGood
                        )} - MOS 3.6 - 4.0`
                    },
                    {
                        value: parseInt(element.MosDanger),
                        colour: this.getSeverityColor(2),
                        tooltip: `${this.getSeverityBarPercentage(
                            element.MosDanger,
                            element.MosDanger + element.MosWarning + element.MosGood
                        )} - MOS <3.6`
                    }
                ];
            },
            type: 'ratio'
        }
    ];

    public ipnrColumnExpansion: ColumnTypes[] = [
        {
            columnDef: 'IPNR',
            header: 'IPNR',
            cell: (element: any): string => `${element.IPNR}`,
            type: 'numeric',
            isIdColumn: true
        },
        ...this.commonColumns
    ];

    public ipnrColumn: ColumnTypes[] = [this.expanderColumn, ...this.ipnrColumnExpansion];

    public codecColumn: ColumnTypes[] = [
        this.expanderColumn,
        {
            columnDef: 'Codec',
            header: 'Codec',
            cell: (element: any): string => `${element.Codec}`
        },
        ...this.commonColumns
    ];

    public layer3Column: ColumnTypes[] = [
        this.expanderColumn,
        {
            columnDef: 'IpAddress',
            header: 'Device',
            cell: (element: any): string => `${element.IpAddress}`,
            filterType: 'text'
        },
        ...this.commonColumns
    ];

    public dscpColumnsExpansion: ColumnTypes[] = [
        {
            columnDef: 'DSCP',
            header: 'DSCP',
            cell: (element: any): string => `${element.DSCP}`,
            type: 'numeric',
            isIdColumn: true
        },
        ...this.commonColumns
    ];

    public callCenterColumns: ColumnTypes[] = [
        {
            columnDef: '',
            header: ''
        },
        ...this.commonColumns
    ];
    private summaryPeriod: string = '';
    public mosSummaryColumns: ColumnTypes[] = [
        {
            columnDef: 'name',
            header: 'IPNR',
            cell: (element: any): string => element.name,
            type: 'numeric',
            isIdColumn: true,
            width: '80px'
        },
        {
            columnDef: 'percentage',
            header: 'Percentage',
            cell: (element: any): string => `${element.percentage}%`,
            type: 'numeric',
            width: '80px'
        },
        {
            columnDef: 'streams',
            header: 'Streams',
            cell: (element: any): string => `${element.streams}`,
            type: 'numeric',
            width: '80px'
        },
        {
            columnDef: 'mosGoodPercentage',
            header: 'Mos >= 4.0',
            cell: (element: any): string => `${element.mosGoodPercentage}`,
            type: 'numeric',
            width: '80px'
        },
        {
            columnDef: 'SummaryChart',
            header: `MOS Summary Over ${this.summaryPeriod} Hours`,
            cell: (element: any): ChartCell => {
                return {
                    options: this.mosSummaryOverTimeMiniOptions,
                    data: element.chartData,
                    chartHeight: '150'
                };
            },
            type: 'chart'
        }
    ];

    public dscpColumns: ColumnTypes[] = [this.expanderColumn, ...this.dscpColumnsExpansion];

    entityGroup: number;

    totalMosSummaryData: any;
    partialMosSummaryData: any;
    mosSummaryOverTimeData: any;
    mosSummaryOverTimeIPNRData: any;
    DSCPData: any;
    IPNRData: any;
    public DSCPDataRaw: any[] = [];
    public IPNRDataRaw: any[] = [];
    public CodecDataRaw: any[] = [];
    public Layer3DataRaw: any[] = [];
    public MosTimeDataRaw: any[] = [];
    public IPNRMapList: any[] = [];
    public CMSAgentDataRaw: any[] = [];
    public CMSVDNDataRaw: any[] = [];
    public CMSSkillDataRaw: any[] = [];

    callCenterData: any[] = [];

    totalPieOptions: any;
    partialPieOptions: any;
    upperLineStart: number;
    lowerLineStart: number;
    mosSummaryOverTimeOptions: any;
    mosSummaryOverTimeMiniOptions: any;
    dscpOptions: any;
    dscpLegend: any;
    ipnrOptions: any;

    DSCPSortOptions: SortOptions;
    IPNRSortOptions: SortOptions;
    SummarySortOptions: SortOptions;

    showDSCP: boolean[]; // DSCP breakdown table accordion state
    showIPNR: boolean[]; // IPNR breakdown table accordion state
    showMoreIPNRs: boolean;

    vqmLinkEnabled: boolean; // whether or not new page will be opened
    selectedToggle: string;

    MosTimeMappedData: any;

    IPNRSpliceRecords: number;

    MosTimeTotalRecords: number;

    DSCPSpliceRecords: number;
    CodecSpliceRecords: number;
    Layer3SpliceRecords: number;
    MosTimeSpliceRecords: number;
    CMSAgentSpliceRecords: number;
    CMSVDNSpliceRecords: number;
    CMSSkillSpliceRecords: number;

    showMoreMosTimeRecords: boolean;

    DSCP_IPNRCombinedData: any[];
    IPNR_DSCPCombinedData: any[];
    Codec_IPNRCombinedData: any[];
    Layer3_DSCPCombinedData: any[];

    TimeIPNRData: any;
    manageThreshold: boolean;

    public DSCPMappedDataNoResult: boolean;
    public DSCPLoading: boolean = true;
    public IPNRMappedDataNoResult: boolean;
    public IPNRLoading: boolean = true;
    public CodecMappedDataNoResult: boolean;
    public CodecLoading: boolean = true;
    public Layer3MappedDataNoResult: boolean;
    public Layer3Loading: boolean = true;
    public CMSMappedDataNoResult: boolean;
    public CMSLoading: boolean = true;
    public mosSummaryLoading: boolean = true;
    public mosSummaryOverTimeNoResult: boolean = false;
    public MosOverTimeLoading: boolean = true;
    public showCMSDataTable: boolean = true;

    public MosSummaryByIPNRLoading: boolean = true;

    occupancyContextMenuItems: MenuItem[] = [];
    selectedField: string;
    selectedThreshold: Threshold;
    openSubcontainer = '';
    vqmMosMap: any = {
        Mos36_Percentage: 'MOS < 3.6',
        Mos36_40_Percentage: 'MOS 3.6 - 4.0',
        Mos40_Percentage: 'MOS > 4.0'
    };

    vsmDSCP: Subject<null> = new Subject();

    MosSummaryTableDisplay: any;

    layer3searchText: string;
    cmsAgentSearchText: string;
    cmsVDNSearchText: string;
    cmsSkillSearchText: string;

    isCiscoSystems = false;

    cmsOptionItems: SelectItem[] = [
        { label: 'Agent', value: 'Agent' },
        { label: 'VDN', value: 'VDN' },
        { label: 'Skill', value: 'Skill' }
    ];

    selectedCMSFilter: any = 'Agent';
    cmsAgentOptionList: SelectItem[];
    cmsVDNOptionList: any[];
    cmsSkillOptionList: any[];

    selectedCMSFilterAgent: any[];
    selectedCMSFilterVDN: any[];
    selectedCMSFilterSkill: any[];

    private processedDocumentIds: string[] = [];

    //#endregion
    //#region Constructor

    constructor(
        private accountService: AccountService,
        public dashletService: DashletService,
        private dashletDataProcessingService: DashletDataProcessService,
        private thresholdService: ThresholdService,
        private tileGridService: TileGridService,
        private cdr: ChangeDetectorRef,
        private store$: Store<AppState>,
        private timeService: TimeService
    ) {
        this.initData();

        this.totalPieOptions = {
            plugins: {
                legend: {
                    display: false
                }
            },
            showAllTooltips: false,
            animation: {
                duration: 0
            },
            rotation: -0.5 * Math.PI + (45 / 180) * Math.PI,
            tooltips: {
                callbacks: {
                    label: (tipItem, data) => {
                        return data.labels[tipItem.index];
                    }
                }
            }
        };

        this.partialPieOptions = {
            plugins: {
                legend: {
                    display: false
                }
            },
            showAllTooltips: false,
            animation: {
                duration: 0
            },
            tooltips: {
                callbacks: {
                    label: (tipItem, data) => {
                        return data.labels[tipItem.index];
                    }
                }
            }
        };

        this.mosSummaryOverTimeOptions = {
            scales: {
                x: {
                    type: 'time',
                    grid: {
                        display: false
                    },
                    time: {
                        unit: 'hour',
                        displayFormats: {
                            minute: 'HH:mm'
                        },
                        stepSize: 2
                    },
                    ticks: {
                        fontSize: 10.2,
                        autoSkip: true,
                        maxRotation: 0,
                        minRotation: 0
                    }
                },
                y: {
                    type: 'linear',
                    min: 0,
                    ticks: {
                        autoSkip: false,
                        fontSize: 10.2
                    },
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: 'Number of Streams'
                    }
                }
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        boxWidth: 15
                    }
                }
            },
            animation: {
                duration: 0
            }
        };

        this.mosSummaryOverTimeMiniOptions = {
            scales: {
                x: {
                    type: 'time',
                    time: {
                        unit: 'hour'
                    },
                    ticks: {
                        display: false
                    },
                    grid: {
                        display: false
                    }
                },
                y: {
                    ticks: {
                        display: false
                    },
                    grid: {
                        drawBorder: false,
                        display: false
                    }
                }
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    callback: {
                        title: tipItem => {
                            return null;
                        }
                    }
                }
            },
            animation: {
                duration: 0
            }
        };

        this.dscpOptions = {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    boxWidth: 15
                }
            },
            scales: {
                xAxis: [
                    {
                        stacked: true
                    }
                ],
                yAxis: [
                    {
                        stacked: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Number of Streams'
                        }
                    }
                ]
            },
            animation: {
                duration: 0
            },
            tooltips: {
                callbacks: {
                    title: tipItem => {
                        return null;
                    },
                    label: (tipItem, data) => {
                        let label = data.datasets[tipItem.datasetIndex].label || '';
                        if (label) label += ': ';
                        return label + (+tipItem.yLabel).toFixed(0);
                    }
                }
            }
        };

        this.ipnrOptions = {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    boxWidth: 15
                }
            },
            scales: {
                xAxis: [
                    {
                        stacked: true
                    }
                ],
                yAxis: [
                    {
                        stacked: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Number of Streams'
                        }
                    }
                ]
            },
            animation: {
                duration: 0
            },
            tooltips: {
                callbacks: {
                    title: tipItem => {
                        return null;
                    },
                    label: (tipItem, data) => {
                        let label = data.datasets[tipItem.datasetIndex].label || '';
                        if (label) label += ': ';
                        return label + (+tipItem.yLabel).toFixed(1);
                    }
                }
            }
        };

        this.showDSCP = [false, false, false];

        this.showIPNR = [false, false, false];

        this.showMoreIPNRs = false;

        // sort options for
        this.SummarySortOptions = {
            sortColumn: ['percentage'],
            sortDesc: [true],
            sortType: ['number']
        };
    }

    //#endregion

    //#region Angular Interface Methods
    public ngOnInit(): void {
        this.uiSubscriptions();
        this.subscribeToData();
    }

    public subscribeToData(): void {
        this.dashlet.lastUpdated = this.timeService.convertToUserTimeZoneForLastUpdated(new Date());
        this.dashlet.refreshNameTag();
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.dataSubscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );

        if (this.dashlet.customer!.customerId && this.dashlet.locationId && this.dashlet.equipmentId) {
            this.store$.dispatch(Actions.GetLocations({ customerId: this.dashlet.customer.customerId }));
            this.dataSubscription.add(
                this.store$
                    .pipe(select(selectLocations(this.dashlet.customer.customerId)))
                    .subscribe((locations: Location[]) => {
                        if (locations) {
                            if (this.dashlet.locationId !== '=all=') {
                                locations = [new Location(this.dashlet.locationId, this.dashlet.location)];
                            }

                            this.dashlet.MasterLocations = locations;
                            locations.forEach((location: any) => {
                                let locationMem = null;
                                if (location.value && location.label) {
                                    locationMem = new Location(location.value, location.label);
                                } else {
                                    locationMem = location;
                                }

                                if (!locationMem.locationId) {
                                    return;
                                }
                                this.store$.dispatch(
                                    Actions.GetMosCapableReceivers({ locationId: locationMem.locationId })
                                );
                                this.dataSubscription.add(
                                    this.dataSubscription.add(
                                        this.store$
                                            .pipe(select(selectMosCapableReceivers(locationMem.locationId)))
                                            .subscribe(receivers => {
                                                if (receivers) {
                                                    if (this.dashlet.equipmentId !== '=all=') {
                                                        this.store$.dispatch(
                                                            Actions.GetEquipmentInfo({
                                                                equipmentId: this.dashlet.equipmentId
                                                            })
                                                        );
                                                        this.dataSubscription.add(
                                                            this.store$
                                                                .pipe(
                                                                    select(
                                                                        selectEquipmentInfo(this.dashlet.equipmentId)
                                                                    )
                                                                )
                                                                .subscribe(data => {
                                                                    if (data) {
                                                                        if (data.Vendor === 'Cisco Systems') {
                                                                            this.dashlet.sizes.splice(2, 1);
                                                                            this.dashlet.isCiscoSystems = true;
                                                                            this.dashlet.checkIfEquipmentTypeSub.next(
                                                                                null
                                                                            );
                                                                        }
                                                                    }
                                                                })
                                                        );

                                                        receivers = [
                                                            {
                                                                equipmentName: this.dashlet.equipment,
                                                                equipmentId: this.dashlet.equipmentId,
                                                                label: this.dashlet.equipment,
                                                                value: this.dashlet.equipmentId
                                                            }
                                                        ];
                                                    }

                                                    this.dashlet.MasterReceivers = Object.values(receivers).filter(
                                                        (r: any) => r.value
                                                    );

                                                    for (const receiver of Object.values(receivers) as any[]) {
                                                        if (!receiver.value) {
                                                            continue;
                                                        }
                                                        // get minimum date time to request documents from
                                                        const minDate = new Date();
                                                        minDate.setMinutes(0, 0, 0);
                                                        minDate.setHours(
                                                            minDate.getHours() - (+this.dashlet.summaryPeriod.value - 1)
                                                        );

                                                        if (
                                                            this.dashlet.receivers.findIndex(
                                                                (e: any) => e.value === receiver.value
                                                            ) === -1
                                                        ) {
                                                            this.dashlet.receivers.push(receiver);
                                                        }

                                                        //Populate store with EquipIds before they are needed by GetCommonHistoric actions
                                                        this.store$.dispatch(
                                                            Actions.StoreEquipIdBasedOnType({
                                                                equipmentId: receiver.value,
                                                                idType: null
                                                            })
                                                        );

                                                        // Subscribe Mos Summary
                                                        this.store$.dispatch(
                                                            Actions.SubscribeToRealTimeService({
                                                                equipmentId: receiver.value,
                                                                command: this.dashlet.commandTypeIdMOSSummary
                                                            })
                                                        );

                                                        this.store$.dispatch(
                                                            Actions.GetNotifyCommonEntitys({
                                                                equipmentId: receiver.value,
                                                                commandTypeId: this.dashlet.commandTypeIdMOSSummary
                                                            })
                                                        );
                                                        this.dataSubscription.add(
                                                            this.store$
                                                                .pipe(
                                                                    select(
                                                                        selectDataFromCommonEntity(
                                                                            receiver.value +
                                                                                this.dashlet.commandTypeIdMOSSummary
                                                                        )
                                                                    )
                                                                )
                                                                .subscribe(
                                                                    (data: RealtimeGatewayDocument[]) => {
                                                                        if (data) {
                                                                            this.mosSummaryOverTimeNoResult = false;
                                                                            const filteredData = data.filter(
                                                                                x =>
                                                                                    this.withinSummaryRange(
                                                                                        x,
                                                                                        minDate
                                                                                    ) &&
                                                                                    !this.processedDocumentIds.includes(
                                                                                        x.id.toLowerCase()
                                                                                    )
                                                                            );

                                                                            // create map key for receiver if does not exist
                                                                            if (
                                                                                !this.dashlet.mosSummaryMap.has(
                                                                                    receiver.label
                                                                                )
                                                                            ) {
                                                                                this.dashlet.mosSummaryMap.set(
                                                                                    receiver.label,
                                                                                    new MOSSummaryData()
                                                                                );
                                                                            }

                                                                            filteredData.forEach(document => {
                                                                                this.dashlet.processMOSSummary(
                                                                                    [document],
                                                                                    receiver.label
                                                                                );
                                                                                this.processedDocumentIds.push(
                                                                                    document.id.toLowerCase()
                                                                                );
                                                                                this.mosTableDataSource = [
                                                                                    {
                                                                                        severity: 0,
                                                                                        mos: '>= 4.0',
                                                                                        noOfStreams: (
                                                                                            this.dashlet.combinedMosData
                                                                                                .mosData[5] +
                                                                                            this.dashlet.combinedMosData
                                                                                                .mosData[4]
                                                                                        ).toString(),
                                                                                        percentage:
                                                                                            this.getPercentageTotalCalls(
                                                                                                this.dashlet
                                                                                                    .combinedMosData
                                                                                                    .mosData[5] +
                                                                                                    this.dashlet
                                                                                                        .combinedMosData
                                                                                                        .mosData[4]
                                                                                            ).toString()
                                                                                    },
                                                                                    {
                                                                                        severity: 1,
                                                                                        mos: '3.6 - 4.0',
                                                                                        noOfStreams:
                                                                                            this.dashlet.combinedMosData.mosData[3].toString(),
                                                                                        percentage:
                                                                                            this.getPercentageTotalCalls(
                                                                                                this.dashlet
                                                                                                    .combinedMosData
                                                                                                    .mosData[3]
                                                                                            ).toString()
                                                                                    },
                                                                                    {
                                                                                        severity: 2,
                                                                                        mos: '< 3.6',
                                                                                        noOfStreams: (
                                                                                            this.dashlet.combinedMosData
                                                                                                .mosData[2] +
                                                                                            this.dashlet.combinedMosData
                                                                                                .mosData[1] +
                                                                                            this.dashlet.combinedMosData
                                                                                                .mosData[0]
                                                                                        ).toString(),
                                                                                        percentage:
                                                                                            this.getPercentageTotalCalls(
                                                                                                this.dashlet
                                                                                                    .combinedMosData
                                                                                                    .mosData[2] +
                                                                                                    this.dashlet
                                                                                                        .combinedMosData
                                                                                                        .mosData[1] +
                                                                                                    this.dashlet
                                                                                                        .combinedMosData
                                                                                                        .mosData[0]
                                                                                            ).toString()
                                                                                    }
                                                                                ];

                                                                                const time: Date = new Date(
                                                                                    this.timeService.convertToUserTimeZoneForLastUpdated(
                                                                                        document.timestamp
                                                                                    )
                                                                                );
                                                                                if (
                                                                                    new Date(this.dashlet.lastUpdated) <
                                                                                    time
                                                                                )
                                                                                    this.dashlet.lastUpdated =
                                                                                        this.timeService.convertToUserTimeZoneForLastUpdated(
                                                                                            document.timestamp
                                                                                        );

                                                                                this.dashlet.refreshNameTag();
                                                                                this.dashlet.postProcessMosDataAsync(
                                                                                    document.data,
                                                                                    'MosTime',
                                                                                    document.timestamp
                                                                                );
                                                                            });
                                                                        } else {
                                                                            this.mosSummaryOverTimeNoResult = true;
                                                                        }
                                                                        this.mosSummaryLoading = false;
                                                                        this.MosOverTimeLoading = false;
                                                                    },
                                                                    error => {
                                                                        this.mosSummaryOverTimeNoResult = true;
                                                                        this.mosSummaryLoading = false;
                                                                        this.MosOverTimeLoading = false;
                                                                    }
                                                                )
                                                        );

                                                        this.store$.dispatch(
                                                            Actions.GetCommonHistoric({
                                                                equipmentId: receiver.value,
                                                                commandTypeId: this.dashlet.commandTypeIdMOSSummary,
                                                                from: minDate.toISOString(),
                                                                to: new Date().toISOString(),
                                                                max: +this.dashlet.summaryPeriod.value
                                                            })
                                                        );

                                                        // Subscribe Mos Summary By IPNR
                                                        this.store$.dispatch(
                                                            Actions.SubscribeToRealTimeService({
                                                                equipmentId: receiver.value,
                                                                command: this.dashlet.commandTypeIdMOSSummaryByIPNR
                                                            })
                                                        );
                                                        this.store$.dispatch(
                                                            Actions.GetNotifyCommonEntitys({
                                                                equipmentId: receiver.value,
                                                                commandTypeId:
                                                                    this.dashlet.commandTypeIdMOSSummaryByIPNR
                                                            })
                                                        );
                                                        this.dataSubscription.add(
                                                            this.store$
                                                                .pipe(
                                                                    select(
                                                                        selectDataFromCommonEntity(
                                                                            receiver.value +
                                                                                this.dashlet
                                                                                    .commandTypeIdMOSSummaryByIPNR
                                                                        )
                                                                    )
                                                                )
                                                                .subscribe(
                                                                    data => {
                                                                        if (data) {
                                                                            this.mosSummaryColumns[4].header = `MOS Summary Over ${this.dashlet.summaryPeriod.value} Hours`;
                                                                            this.summaryPeriod =
                                                                                this.dashlet.summaryPeriod.value;
                                                                            this.IPNRLoading = false;
                                                                            const filteredData = data.filter(
                                                                                x =>
                                                                                    this.withinSummaryRange(
                                                                                        x,
                                                                                        minDate
                                                                                    ) &&
                                                                                    !this.processedDocumentIds.includes(
                                                                                        x.id.toLowerCase()
                                                                                    )
                                                                            );

                                                                            if (
                                                                                !this.dashlet.mosSummaryIPNRBreakdownMap.has(
                                                                                    receiver.label
                                                                                )
                                                                            ) {
                                                                                this.dashlet.mosSummaryIPNRBreakdownMap.set(
                                                                                    receiver.label,
                                                                                    new MOSSummaryBreakdownData()
                                                                                );
                                                                            }

                                                                            this.dashlet.IPNRMappedDataNoResult = true;
                                                                            this.dashlet.IPNRMappedDataNoResultSub.next(
                                                                                null
                                                                            );

                                                                            filteredData.forEach(document => {
                                                                                this.processedDocumentIds.push(
                                                                                    document.id.toLowerCase()
                                                                                );
                                                                                this.dashlet.processMOSSummaryBreakdown(
                                                                                    [document],
                                                                                    receiver.label,
                                                                                    DashletVQMDailySummary.IPNR
                                                                                );
                                                                                this.dashlet.processipnrSet(
                                                                                    document.data
                                                                                );
                                                                            });
                                                                        }
                                                                        this.MosSummaryByIPNRLoading = false;
                                                                    },
                                                                    error => {
                                                                        this.MosSummaryByIPNRLoading = false;
                                                                        // no data produced after time out
                                                                        this.dashlet.IPNRMappedDataNoResult = true;
                                                                        this.dashlet.IPNRMappedDataNoResultSub.next(
                                                                            null
                                                                        );
                                                                    }
                                                                )
                                                        );
                                                        this.store$.dispatch(
                                                            Actions.GetCommonHistoric({
                                                                equipmentId: receiver.value,
                                                                commandTypeId:
                                                                    this.dashlet.commandTypeIdMOSSummaryByIPNR,
                                                                from: minDate.toISOString(),
                                                                to: new Date().toISOString(),
                                                                max: +this.dashlet.summaryPeriod.value
                                                            })
                                                        );

                                                        // Subscribe Mos Summary DSCP by IPNR
                                                        this.store$.dispatch(
                                                            Actions.SubscribeToRealTimeService({
                                                                equipmentId: receiver.value,
                                                                command: this.dashlet.commandTypeIdMOSSummaryDSCPByIPNR
                                                            })
                                                        );
                                                        this.store$.dispatch(
                                                            Actions.GetNotifyCommonEntitys({
                                                                equipmentId: receiver.value,
                                                                commandTypeId:
                                                                    this.dashlet.commandTypeIdMOSSummaryDSCPByIPNR
                                                            })
                                                        );
                                                        this.dataSubscription.add(
                                                            this.store$
                                                                .pipe(
                                                                    select(
                                                                        selectDataFromCommonEntity(
                                                                            receiver.value +
                                                                                this.dashlet
                                                                                    .commandTypeIdMOSSummaryDSCPByIPNR
                                                                        )
                                                                    )
                                                                )
                                                                .subscribe(
                                                                    async data => {
                                                                        if (data) {
                                                                            const filteredData = data.filter(
                                                                                x =>
                                                                                    this.withinSummaryRange(
                                                                                        x,
                                                                                        minDate
                                                                                    ) &&
                                                                                    !this.processedDocumentIds.includes(
                                                                                        x.id.toLowerCase()
                                                                                    )
                                                                            );

                                                                            for (const document of filteredData) {
                                                                                if (
                                                                                    await this.dashlet.processDataRowsAsync(
                                                                                        [document],
                                                                                        DashletVQMDailySummary.DSCP
                                                                                    )
                                                                                ) {
                                                                                    this.dashlet.dscpDataUpdated.next(
                                                                                        null
                                                                                    );
                                                                                }
                                                                                this.dashlet.processSubDataRows(
                                                                                    [document],
                                                                                    'DSCP_IPNR'
                                                                                );
                                                                                this.processedDocumentIds.push(
                                                                                    document.id.toLowerCase()
                                                                                );
                                                                            }

                                                                            this.dashlet.DSCPMappedDataNoResult = true;
                                                                            this.dashlet.DSCPMappedDataNoResultSub.next(
                                                                                null
                                                                            );
                                                                        }
                                                                        this.DSCPLoading = false;
                                                                    },
                                                                    error => {
                                                                        // no data produced after time out
                                                                        this.DSCPLoading = false;
                                                                        this.dashlet.DSCPMappedDataNoResult = true;
                                                                        this.dashlet.DSCPMappedDataNoResultSub.next(
                                                                            null
                                                                        );
                                                                    }
                                                                )
                                                        );
                                                        this.store$.dispatch(
                                                            Actions.GetCommonHistoric({
                                                                equipmentId: receiver.value,
                                                                commandTypeId:
                                                                    this.dashlet.commandTypeIdMOSSummaryDSCPByIPNR,
                                                                from: minDate.toISOString(),
                                                                to: new Date().toISOString(),
                                                                max: +this.dashlet.summaryPeriod.value
                                                            })
                                                        );

                                                        // Subscribe Mos Summary IPNR by DSCP
                                                        this.store$.dispatch(
                                                            Actions.SubscribeToRealTimeService({
                                                                equipmentId: receiver.value,
                                                                command: this.dashlet.commandTypeIdMOSSummaryIPNRByDSCP
                                                            })
                                                        );
                                                        this.store$.dispatch(
                                                            Actions.GetNotifyCommonEntitys({
                                                                equipmentId: receiver.value,
                                                                commandTypeId:
                                                                    this.dashlet.commandTypeIdMOSSummaryIPNRByDSCP
                                                            })
                                                        );
                                                        this.dataSubscription.add(
                                                            this.store$
                                                                .pipe(
                                                                    select(
                                                                        selectDataFromCommonEntity(
                                                                            receiver.value +
                                                                                this.dashlet
                                                                                    .commandTypeIdMOSSummaryIPNRByDSCP
                                                                        )
                                                                    )
                                                                )
                                                                .subscribe(
                                                                    async data => {
                                                                        if (data) {
                                                                            const filteredData = data.filter(
                                                                                x =>
                                                                                    this.withinSummaryRange(
                                                                                        x,
                                                                                        minDate
                                                                                    ) &&
                                                                                    !this.processedDocumentIds.includes(
                                                                                        x.id.toLowerCase()
                                                                                    )
                                                                            );

                                                                            for (const document of filteredData) {
                                                                                if (
                                                                                    await this.dashlet.processDataRowsAsync(
                                                                                        [document],
                                                                                        DashletVQMDailySummary.IPNR
                                                                                    )
                                                                                ) {
                                                                                    this.dashlet.ipnrDataUpdated.next(
                                                                                        null
                                                                                    );
                                                                                }
                                                                                this.dashlet.processSubDataRows(
                                                                                    [document],
                                                                                    'IPNR_DSCP'
                                                                                );
                                                                                this.processedDocumentIds.push(
                                                                                    document.id.toLowerCase()
                                                                                );
                                                                            }
                                                                        }
                                                                        this.IPNRLoading = false;
                                                                    },
                                                                    error => {
                                                                        this.IPNRLoading = false;
                                                                    }
                                                                )
                                                        );
                                                        this.store$.dispatch(
                                                            Actions.GetCommonHistoric({
                                                                equipmentId: receiver.value,
                                                                commandTypeId:
                                                                    this.dashlet.commandTypeIdMOSSummaryIPNRByDSCP,
                                                                from: minDate.toISOString(),
                                                                to: new Date().toISOString(),
                                                                max: +this.dashlet.summaryPeriod.value
                                                            })
                                                        );

                                                        // Subscribe Mos Summary By Codec

                                                        this.store$.dispatch(
                                                            Actions.SubscribeToRealTimeService({
                                                                equipmentId: receiver.value,
                                                                command: this.dashlet.commandTypeIdMOSSummaryByCodec
                                                            })
                                                        );
                                                        this.store$.dispatch(
                                                            Actions.GetNotifyCommonEntitys({
                                                                equipmentId: receiver.value,
                                                                commandTypeId:
                                                                    this.dashlet.commandTypeIdMOSSummaryByCodec
                                                            })
                                                        );
                                                        this.dataSubscription.add(
                                                            this.store$
                                                                .pipe(
                                                                    select(
                                                                        selectDataFromCommonEntity(
                                                                            receiver.value +
                                                                                this.dashlet
                                                                                    .commandTypeIdMOSSummaryByCodec
                                                                        )
                                                                    )
                                                                )
                                                                .subscribe(
                                                                    data => {
                                                                        if (data) {
                                                                            const filteredData = data.filter(
                                                                                x =>
                                                                                    this.withinSummaryRange(
                                                                                        x,
                                                                                        minDate
                                                                                    ) &&
                                                                                    !this.processedDocumentIds.includes(
                                                                                        x.id.toLowerCase()
                                                                                    )
                                                                            );

                                                                            filteredData.forEach(document => {
                                                                                this.dashlet.processDataRows(
                                                                                    [document],
                                                                                    DashletVQMDailySummary.Codec
                                                                                );
                                                                                this.processedDocumentIds.push(
                                                                                    document.id.toLowerCase()
                                                                                );
                                                                            });

                                                                            this.dashlet.CodecMappedDataNoResult = true;
                                                                            this.dashlet.CodecMappedDataNoResultSub.next(
                                                                                null
                                                                            );
                                                                        }
                                                                        this.CodecLoading = false;
                                                                    },
                                                                    err => {
                                                                        // no data produced after time out
                                                                        this.CodecLoading = false;
                                                                        if (this.dashlet.CodecMapList.length === 0) {
                                                                            this.dashlet.CodecMappedDataNoResult = true;
                                                                            this.dashlet.CodecMappedDataNoResultSub.next(
                                                                                null
                                                                            );
                                                                        }
                                                                    }
                                                                )
                                                        );
                                                        this.store$.dispatch(
                                                            Actions.GetCommonHistoric({
                                                                equipmentId: receiver.value,
                                                                commandTypeId:
                                                                    this.dashlet.commandTypeIdMOSSummaryByCodec,
                                                                from: minDate.toISOString(),
                                                                to: new Date().toISOString(),
                                                                max: +this.dashlet.summaryPeriod.value
                                                            })
                                                        );

                                                        // Subscribe Mos Summary Codec by IPNR

                                                        this.store$.dispatch(
                                                            Actions.SubscribeToRealTimeService({
                                                                equipmentId: receiver.value,
                                                                command: this.dashlet.commandTypeIdMOSSummaryCodecByIPNR
                                                            })
                                                        );
                                                        this.store$.dispatch(
                                                            Actions.GetNotifyCommonEntitys({
                                                                equipmentId: receiver.value,
                                                                commandTypeId:
                                                                    this.dashlet.commandTypeIdMOSSummaryCodecByIPNR
                                                            })
                                                        );
                                                        this.dataSubscription.add(
                                                            this.store$
                                                                .pipe(
                                                                    select(
                                                                        selectDataFromCommonEntity(
                                                                            receiver.value +
                                                                                this.dashlet
                                                                                    .commandTypeIdMOSSummaryCodecByIPNR
                                                                        )
                                                                    )
                                                                )
                                                                .subscribe(data => {
                                                                    if (data) {
                                                                        const filteredData = data.filter(
                                                                            x =>
                                                                                this.withinSummaryRange(x, minDate) &&
                                                                                !this.processedDocumentIds.includes(
                                                                                    x.id.toLowerCase()
                                                                                )
                                                                        );
                                                                        filteredData.forEach(document => {
                                                                            this.dashlet.processSubDataRows(
                                                                                [document],
                                                                                'Codec_IPNR'
                                                                            );
                                                                            this.processedDocumentIds.push(
                                                                                document.id.toLowerCase()
                                                                            );
                                                                        });
                                                                    }
                                                                })
                                                        );
                                                        this.store$.dispatch(
                                                            Actions.GetCommonHistoric({
                                                                equipmentId: receiver.value,
                                                                commandTypeId:
                                                                    this.dashlet.commandTypeIdMOSSummaryCodecByIPNR,
                                                                from: minDate.toISOString(),
                                                                to: new Date().toISOString(),
                                                                max: +this.dashlet.summaryPeriod.value
                                                            })
                                                        );

                                                        // Subscribe Mos Summary By Layer 3
                                                        this.store$.dispatch(
                                                            Actions.SubscribeToRealTimeService({
                                                                equipmentId: receiver.value,
                                                                command: this.dashlet.commandTypeIdMOSSummaryByLayer3
                                                            })
                                                        );
                                                        this.store$.dispatch(
                                                            Actions.GetNotifyCommonEntitys({
                                                                equipmentId: receiver.value,
                                                                commandTypeId:
                                                                    this.dashlet.commandTypeIdMOSSummaryByLayer3
                                                            })
                                                        );
                                                        this.dataSubscription.add(
                                                            this.store$
                                                                .pipe(
                                                                    select(
                                                                        selectDataFromCommonEntity(
                                                                            receiver.value +
                                                                                this.dashlet
                                                                                    .commandTypeIdMOSSummaryByLayer3
                                                                        )
                                                                    )
                                                                )
                                                                .subscribe(
                                                                    data => {
                                                                        if (data) {
                                                                            const filteredData = data.filter(
                                                                                x =>
                                                                                    this.withinSummaryRange(
                                                                                        x,
                                                                                        minDate
                                                                                    ) &&
                                                                                    !this.processedDocumentIds.includes(
                                                                                        x.id.toLowerCase()
                                                                                    )
                                                                            );

                                                                            filteredData.forEach(document => {
                                                                                this.dashlet.processDataRowsForLayer3AsyncV3(
                                                                                    document.data
                                                                                );
                                                                                this.processedDocumentIds.push(
                                                                                    document.id.toLowerCase()
                                                                                );
                                                                            });

                                                                            this.dashlet.Layer3MappedDataNoResult =
                                                                                true;
                                                                            this.dashlet.Layer3MappedDataNoResultSub.next(
                                                                                null
                                                                            );
                                                                        }
                                                                        this.Layer3Loading = false;
                                                                    },
                                                                    err => {
                                                                        // no data produced after time out
                                                                        this.Layer3Loading = false;
                                                                        if (this.dashlet.Layer3MapList.length === 0) {
                                                                            this.dashlet.Layer3MappedDataNoResult =
                                                                                true;
                                                                            this.dashlet.Layer3MappedDataNoResultSub.next(
                                                                                null
                                                                            );
                                                                        }
                                                                    }
                                                                )
                                                        );
                                                        this.store$.dispatch(
                                                            Actions.GetCommonHistoric({
                                                                equipmentId: receiver.value,
                                                                commandTypeId:
                                                                    this.dashlet.commandTypeIdMOSSummaryByLayer3,
                                                                from: minDate.toISOString(),
                                                                to: new Date().toISOString(),
                                                                max: +this.dashlet.summaryPeriod.value
                                                            })
                                                        );

                                                        // Subscribe Mos Summary Layer 3 by DSCP
                                                        this.store$.dispatch(
                                                            Actions.SubscribeToRealTimeService({
                                                                equipmentId: receiver.value,
                                                                command:
                                                                    this.dashlet.commandTypeIdMOSSummaryLayer3ByDSCP
                                                            })
                                                        );
                                                        this.store$.dispatch(
                                                            Actions.GetNotifyCommonEntitys({
                                                                equipmentId: receiver.value,
                                                                commandTypeId:
                                                                    this.dashlet.commandTypeIdMOSSummaryLayer3ByDSCP
                                                            })
                                                        );
                                                        this.dataSubscription.add(
                                                            this.store$
                                                                .pipe(
                                                                    select(
                                                                        selectDataFromCommonEntity(
                                                                            receiver.value +
                                                                                this.dashlet
                                                                                    .commandTypeIdMOSSummaryLayer3ByDSCP
                                                                        )
                                                                    )
                                                                )
                                                                .subscribe(data => {
                                                                    if (data) {
                                                                        const filteredData = data.filter(
                                                                            x =>
                                                                                this.withinSummaryRange(x, minDate) &&
                                                                                !this.processedDocumentIds.includes(
                                                                                    x.id.toLowerCase()
                                                                                )
                                                                        );

                                                                        filteredData.forEach(document => {
                                                                            this.dashlet.processSubDataRowsForLayer3(
                                                                                [document],
                                                                                'Layer3_DSCP'
                                                                            );
                                                                            this.processedDocumentIds.push(
                                                                                document.id.toLowerCase()
                                                                            );
                                                                        });
                                                                    }
                                                                })
                                                        );
                                                    }
                                                }
                                            })
                                    )
                                );

                                // getTypeEquipmentsAtLocation
                                this.store$.dispatch(
                                    Actions.GetTypeEquipmentsAtLocations({
                                        locationId: locationMem.locationId,
                                        typeStr: 'CMS'
                                    })
                                );
                                this.dataSubscription.add(
                                    this.store$
                                        .pipe(select(selectTypeEquipmentsAtLocation(locationMem.locationId)))
                                        .subscribe(cmsReceivers => {
                                            if (cmsReceivers) {
                                                this.CMSLoading = false;
                                                const minDate = new Date();
                                                minDate.setMinutes(0, 0, 0);
                                                minDate.setHours(
                                                    minDate.getHours() - (+this.dashlet.summaryPeriod.value - 1)
                                                );

                                                this.dashlet.MasterCMSEquipment = cmsReceivers;
                                                this.dashlet.MasterCMSEquipmentWithData = [];

                                                for (const cmsReceiver of cmsReceivers.data as EquipmentInfo[]) {
                                                    const equipmentIdCMS = cmsReceiver.equipmentId;

                                                    //Populate store with EquipIds before they are needed by GetCommonHistoric actions
                                                    this.store$.dispatch(
                                                        Actions.StoreEquipIdBasedOnType({
                                                            equipmentId: equipmentIdCMS,
                                                            idType: null
                                                        })
                                                    );

                                                    this.store$.dispatch(
                                                        Actions.SubscribeToRealTimeService({
                                                            equipmentId: equipmentIdCMS,
                                                            command: this.dashlet.commandTypeIdMOSSummaryByCMS_Agent
                                                        })
                                                    );
                                                    this.store$.dispatch(
                                                        Actions.GetNotifyCommonEntitys({
                                                            equipmentId: equipmentIdCMS,
                                                            commandTypeId:
                                                                this.dashlet.commandTypeIdMOSSummaryByCMS_Agent
                                                        })
                                                    );
                                                    this.dataSubscription.add(
                                                        this.store$
                                                            .pipe(
                                                                select(
                                                                    selectDataFromCommonEntity(
                                                                        equipmentIdCMS +
                                                                            this.dashlet
                                                                                .commandTypeIdMOSSummaryByCMS_Agent
                                                                    )
                                                                )
                                                            )
                                                            .subscribe(data => {
                                                                if (data) {
                                                                    const filteredData = data.filter(
                                                                        x =>
                                                                            this.withinSummaryRange(x, minDate) &&
                                                                            !this.processedDocumentIds.includes(
                                                                                x.id.toLowerCase()
                                                                            )
                                                                    );
                                                                    filteredData.forEach(document => {
                                                                        if (document) {
                                                                            if (document.data.length > 0) {
                                                                                if (
                                                                                    !this.dashlet.MasterCMSEquipmentWithData.includes(
                                                                                        cmsReceiver.equipmentName
                                                                                    )
                                                                                ) {
                                                                                    this.dashlet.MasterCMSEquipmentWithData.push(
                                                                                        cmsReceiver.equipmentName
                                                                                    );
                                                                                }
                                                                            }
                                                                            this.dashlet.processDataRowsForCMS(
                                                                                [document],
                                                                                'Agent'
                                                                            );
                                                                            this.processedDocumentIds.push(
                                                                                document.id.toLowerCase()
                                                                            );
                                                                        }
                                                                    });
                                                                }
                                                            })
                                                    );
                                                    this.store$.dispatch(
                                                        Actions.GetCommonHistoric({
                                                            equipmentId: equipmentIdCMS,
                                                            commandTypeId:
                                                                this.dashlet.commandTypeIdMOSSummaryByCMS_Agent,
                                                            from: minDate.toISOString(),
                                                            to: new Date().toISOString(),
                                                            max: +this.dashlet.summaryPeriod.value
                                                        })
                                                    );

                                                    // Subscribe Mos Summary By CMS_VDN
                                                    this.store$.dispatch(
                                                        Actions.SubscribeToRealTimeService({
                                                            equipmentId: equipmentIdCMS,
                                                            command: this.dashlet.commandTypeIdMOSSummaryByCMS_VDN
                                                        })
                                                    );
                                                    this.store$.dispatch(
                                                        Actions.GetNotifyCommonEntitys({
                                                            equipmentId: equipmentIdCMS,
                                                            commandTypeId: this.dashlet.commandTypeIdMOSSummaryByCMS_VDN
                                                        })
                                                    );
                                                    this.dataSubscription.add(
                                                        this.store$
                                                            .pipe(
                                                                select(
                                                                    selectDataFromCommonEntity(
                                                                        equipmentIdCMS +
                                                                            this.dashlet
                                                                                .commandTypeIdMOSSummaryByCMS_VDN
                                                                    )
                                                                )
                                                            )
                                                            .subscribe(data => {
                                                                if (data) {
                                                                    const filteredData = data.filter(
                                                                        x =>
                                                                            this.withinSummaryRange(x, minDate) &&
                                                                            !this.processedDocumentIds.includes(
                                                                                x.id.toLowerCase()
                                                                            )
                                                                    );
                                                                    filteredData.forEach(document => {
                                                                        if (document) {
                                                                            this.dashlet.processDataRowsForCMS(
                                                                                [document],
                                                                                'VDN'
                                                                            );
                                                                            this.processedDocumentIds.push(
                                                                                document.id.toLowerCase()
                                                                            );
                                                                        }
                                                                    });
                                                                }
                                                            })
                                                    );
                                                    this.store$.dispatch(
                                                        Actions.GetCommonHistoric({
                                                            equipmentId: equipmentIdCMS,
                                                            commandTypeId:
                                                                this.dashlet.commandTypeIdMOSSummaryByCMS_VDN,
                                                            from: minDate.toISOString(),
                                                            to: new Date().toISOString(),
                                                            max: +this.dashlet.summaryPeriod.value
                                                        })
                                                    );

                                                    // Subscribe Mos Summary By CMS_Skill

                                                    this.store$.dispatch(
                                                        Actions.SubscribeToRealTimeService({
                                                            equipmentId: equipmentIdCMS,
                                                            command: this.dashlet.commandTypeIdMOSSummaryByCMS_Skill
                                                        })
                                                    );
                                                    this.store$.dispatch(
                                                        Actions.GetNotifyCommonEntitys({
                                                            equipmentId: equipmentIdCMS,
                                                            commandTypeId:
                                                                this.dashlet.commandTypeIdMOSSummaryByCMS_Skill
                                                        })
                                                    );
                                                    this.dataSubscription.add(
                                                        this.store$
                                                            .pipe(
                                                                select(
                                                                    selectDataFromCommonEntity(
                                                                        equipmentIdCMS +
                                                                            this.dashlet
                                                                                .commandTypeIdMOSSummaryByCMS_Skill
                                                                    )
                                                                )
                                                            )
                                                            .subscribe(data => {
                                                                if (data) {
                                                                    const filteredData = data.filter(
                                                                        x =>
                                                                            this.withinSummaryRange(x, minDate) &&
                                                                            !this.processedDocumentIds.includes(
                                                                                x.id.toLowerCase()
                                                                            )
                                                                    );
                                                                    filteredData.forEach(document => {
                                                                        if (document) {
                                                                            this.dashlet.processDataRowsForCMS(
                                                                                [document],
                                                                                'Skill'
                                                                            );

                                                                            this.processedDocumentIds.push(
                                                                                document.id.toLowerCase()
                                                                            );
                                                                        }
                                                                    });
                                                                }
                                                            })
                                                    );

                                                    this.store$.dispatch(
                                                        Actions.GetCommonHistoric({
                                                            equipmentId: equipmentIdCMS,
                                                            commandTypeId:
                                                                this.dashlet.commandTypeIdMOSSummaryByCMS_Skill,
                                                            from: minDate.toISOString(),
                                                            to: new Date().toISOString(),
                                                            max: +this.dashlet.summaryPeriod.value
                                                        })
                                                    );
                                                }
                                            }
                                        })
                                );
                            });
                        }
                    })
            );
        }

        var userDetail = this.accountService.getUserDetails();
        this.entityGroup = userDetail.EntityGroup;
        this.selectedToggle = 'toggledDSCP';
        this.selectedCMSFilter = 'Agent';
    }

    private uiSubscriptions(): void {
        // Update charts whenever new data is received from realtime gateway
        this.uiSubscription.add(
            this.dashlet.chartDataUpdated.subscribe(() => {
                this.updateCharts();
            })
        );

        // Update MosTime data whenever new data is received from realtime gateway
        this.uiSubscription.add(
            this.dashlet.mosTimeDataUpdated.subscribe(() => {
                this.MosOverTimeLoading = false;
                this.MosTimeMappedData = [];
                this.MosTimeDataRaw = [];
                this.MosTimeTotalRecords = 0;
                this.mosSummaryLoading = false;

                if (this.dashlet.MosTimeMappedData.length > 0) {
                    this.MosTimeDataRaw = this.dashlet.MosTimeMappedData;

                    this.MosTimeMappedData = this.MosTimeDataRaw.slice(0, this.MosTimeSpliceRecords);
                    this.MosTimeTotalRecords = this.MosTimeDataRaw.length;

                    if (this.MosTimeTotalRecords > 10) {
                        this.showMoreMosTimeRecords = true;
                    }
                }

                this.updateCharts();
            })
        );

        // Update DSCP data whenever new data is received from realtime gateway
        this.uiSubscription.add(
            this.dashlet.dscpDataUpdated.subscribe(() => {
                this.DSCPLoading = false;
                let recList = this.dashlet.DSCPMapList;

                let resultList = this.categorizeData(recList, 'DSCP');

                this.DSCPDataRaw = [];

                this.DSCPDataRaw = resultList.map(row => {
                    return {
                        ...row,
                        expansion: this.returnExpandableDataV5(row, 'DSCP_IPNR')
                    };
                });
            })
        );

        // Update IPNR data whenever new data is received from realtime gateway
        this.uiSubscription.add(
            this.dashlet.ipnrDataUpdated.subscribe(async () => {
                this.IPNRLoading = false;
                let recList = this.dashlet.IPNRMapList;
                let resultList = this.categorizeData(recList, 'IPNR');

                this.IPNRDataRaw = [];

                this.IPNRDataRaw = resultList.map(row => {
                    return {
                        ...row,
                        expansion: this.returnExpandableDataV5(row, 'IPNR_DSCP')
                    };
                });
                this.IPNRMapList = this.dashlet.IPNRMapList;
            })
        );

        // Update Codec data whenever new data is received from realtime gateway
        this.uiSubscription.add(
            this.dashlet.codecDataUpdated.subscribe(() => {
                this.CodecLoading = false;
                let recList = this.dashlet.CodecMapList;

                let resultList = this.categorizeData(recList, 'Codec');

                this.CodecDataRaw = [];

                this.CodecDataRaw = resultList.map(row => {
                    return {
                        ...row,
                        expansion: this.returnExpandableDataV5(row, 'Codec_IPNR')
                    };
                });
            })
        );

        // Update Layer3 data whenever new data is received from realtime gateway
        this.uiSubscription.add(
            this.dashlet.layer3DataUpdated.subscribe(() => {
                this.Layer3Loading = false;

                var myMap = this.dashlet.Layer3Mapped;

                var sampleList = [];
                myMap.forEach(async (value: Layer3Categorized, key: string) => {
                    sampleList.push(value);
                });

                let resultList = sampleList;

                this.Layer3DataRaw = [];

                this.Layer3DataRaw = resultList.map(row => {
                    return {
                        ...row,
                        expansion: this.returnExpandableDataV5ForLayer3(row, 'Layer3_DSCP')
                    };
                });
            })
        );

        // Update CMS Agent data whenever new data is received from realtime gateway
        this.uiSubscription.add(
            this.dashlet.cmsDataUpdated_Agent.subscribe(() => {
                this.CMSLoading = false;
                this.CMSMappedDataNoResult = false;

                var myMap = this.dashlet.CMSMosDataMapped_Agent;

                var sampleList = [];
                myMap.forEach(async (value: CMSMosData_Agent, key: string) => {
                    sampleList.push(value);
                });

                let resultList = sampleList;

                this.CMSAgentDataRaw = [];

                this.CMSAgentDataRaw = resultList;

                // generate unique item list from arrayset
                this.cmsAgentOptionList = Array.from(new Set(resultList.map(x => x.AgentId)))
                    .map(AgentId => {
                        return {
                            label: resultList.find(s => s.AgentId === AgentId).AgentName,
                            value: {
                                AgentId: AgentId,
                                AgentName: resultList.find(s => s.AgentId === AgentId).AgentName
                            }
                        };
                    })
                    .sort((x, y) => +x - +y);

                this.cmsAgentOptionList.sort((a, b) => (a.label > b.label ? 1 : -1));

                if (this.selectedCMSFilter === 'Agent') {
                    this.callCenterData = this.CMSAgentDataRaw;

                    this.callCenterColumns[0] = {
                        columnDef: 'AgentName',
                        header: 'Agent',
                        filterType: 'text',
                        cell: (element: any): string => `${element.AgentName}`
                    };
                }
            })
        );

        // Update CMS VDN data whenever new data is received from realtime gateway
        this.uiSubscription.add(
            this.dashlet.cmsDataUpdated_VDN.subscribe(() => {
                this.CMSLoading = false;
                this.CMSMappedDataNoResult = false;

                var myMap = this.dashlet.CMSMosDataMapped_VDN;

                var sampleList = [];
                myMap.forEach(async (value: CMSMosData_VDN, key: string) => {
                    sampleList.push(value);
                });

                let resultList = sampleList;

                this.CMSVDNDataRaw = [];

                this.CMSVDNDataRaw = resultList;
                // generate unique item list from arrayset
                this.cmsVDNOptionList = Array.from(new Set(resultList.map(x => x.Vdn))).map(Vdn => {
                    return {
                        label: Vdn,
                        value: Number(Vdn)
                    };
                });

                this.cmsVDNOptionList.sort((a, b) => (a.value > b.value ? 1 : -1));

                if (this.selectedCMSFilter === 'VDN') {
                    this.callCenterData = this.CMSVDNDataRaw;
                }
            })
        );

        // Update CMS Skill data whenever new data is received from realtime gateway
        this.uiSubscription.add(
            this.dashlet.cmsDataUpdated_Skill.subscribe(() => {
                this.CMSLoading = false;
                this.CMSMappedDataNoResult = false;

                var myMap = this.dashlet.CMSMosDataMapped_Skill;

                var sampleList = [];
                myMap.forEach(async (value: CMSMosData_Skill, key: string) => {
                    sampleList.push(value);
                });

                let resultList = sampleList;

                this.CMSSkillDataRaw = [];

                this.CMSSkillDataRaw = resultList;

                // generate unique item list from arrayset
                this.cmsSkillOptionList = Array.from(new Set(resultList.map(x => x.Skill))).map(Skill => {
                    return {
                        label: Skill,
                        value: Number(Skill)
                    };
                });

                this.cmsSkillOptionList.sort((a, b) => (a.value > b.value ? 1 : -1));

                if (this.selectedCMSFilter === 'Skill') {
                    this.callCenterData = this.CMSSkillDataRaw;
                }
            })
        );

        // Update IPNR/DSCP data whenever new data is received from realtime gateway
        this.uiSubscription.add(
            this.dashlet.layer3ByDscpDataUpdated.subscribe(() => {
                let tempData = this.dashlet.Layer3_DSCPCombinedData;
                this.Layer3_DSCPCombinedData = tempData;
            })
        );

        this.uiSubscription.add(
            this.dashlet.sizeChanged.subscribe(() => {
                // trigger update chart when view changed (due to lazy loading charts may not exist)
                setTimeout(() => {
                    this.updateCharts();
                }, 200);
            })
        );

        // Update DSCP/IPNR data whenever new data is received from realtime gateway
        this.uiSubscription.add(
            this.dashlet.DSCP_IPNRDataUpdated.subscribe(() => {
                let tempData = this.dashlet.DSCP_IPNRCombinedData;
                this.DSCP_IPNRCombinedData = tempData;
            })
        );

        // Update IPNR/DSCP data whenever new data is received from realtime gateway
        this.uiSubscription.add(
            this.dashlet.IPNR_DSCPDataUpdated.subscribe(() => {
                let tempData = this.dashlet.IPNR_DSCPCombinedData;
                this.IPNR_DSCPCombinedData = tempData;
            })
        );

        // Update Codec/IPNR data whenever new data is received from realtime gateway
        this.uiSubscription.add(
            this.dashlet.Codec_IPNRDataUpdated.subscribe(() => {
                let tempData = this.dashlet.Codec_IPNRCombinedData;
                this.Codec_IPNRCombinedData = tempData;
            })
        );

        // Update Layer3/DSCP data whenever new data is received from realtime gateway
        this.uiSubscription.add(
            this.dashlet.Layer3_DSCPDataUpdated.subscribe(() => {
                let tempData = this.dashlet.Layer3_DSCPCombinedData;
                this.Layer3_DSCPCombinedData = tempData;
            })
        );

        // will timeout after 20 seconds if there is no data received for DSCP
        this.uiSubscription.add(
            this.dashlet.DSCPMappedDataNoResultSub.subscribe(() => {
                this.DSCPMappedDataNoResult = this.dashlet.DSCPMappedDataNoResult;
            })
        );

        // will timeout after 20 seconds if there is no data received for IPNR
        this.uiSubscription.add(
            this.dashlet.IPNRMappedDataNoResultSub.subscribe(() => {
                this.IPNRMappedDataNoResult = this.dashlet.IPNRMappedDataNoResult;
            })
        );

        // will timeout after 20 seconds if there is no data received for Codec
        this.uiSubscription.add(
            this.dashlet.CodecMappedDataNoResultSub.subscribe(() => {
                this.CodecMappedDataNoResult = this.dashlet.CodecMappedDataNoResult;
            })
        );

        // will timeout after 20 seconds if there is no data received for Layer3
        this.uiSubscription.add(
            this.dashlet.Layer3MappedDataNoResultSub.subscribe(() => {
                this.Layer3MappedDataNoResult = this.dashlet.Layer3MappedDataNoResult;
            })
        );

        // checks an equipment if equipment type is Cisco
        this.uiSubscription.add(
            this.dashlet.checkIfEquipmentTypeSub.subscribe(() => {
                this.isCiscoSystems = this.dashlet.isCiscoSystems;

                if (this.isCiscoSystems) {
                    if (this.dashlet.tabsOpen.findIndex(e => e === 1) !== -1) {
                        this.handleTabClose(1);
                    }
                }
            })
        );

        // Reset chart data when dashlet settings are changed.
        this.uiSubscription.add(
            this.dashlet.settingsUpdated.subscribe(() => {
                this.unsubFromRTG();
                this.dataSubscription.unsubscribe();
                this.DSCPLoading = true;
                this.IPNRLoading = true;
                this.CodecLoading = true;
                this.Layer3Loading = true;
                this.CMSLoading = true;
                this.mosSummaryLoading = true;
                this.MosOverTimeLoading = true;
                this.selectedToggle = 'toggledDSCP';
                this.selectedCMSFilter = 'Agent';
                this.processedDocumentIds = [];
                this.cdr.detectChanges();
                this.subscribeToData();
            })
        );

        this.uiSubscription.add(
            this.accountService
                .hasCapacityFeature(this.dashlet.customer.customerId)
                .pipe(take(1))
                .subscribe(capacityEnabled => {
                    this.occupancyContextMenuItems.push(
                        {
                            label: 'Create Threshold',
                            command: () => {
                                this.thresholdService.createThreshold(this.accountService.getUserDetails().EntityId);
                            },
                            disabled: !capacityEnabled,
                            title: !capacityEnabled ? this.getCapacityUnsubscribedText() : ''
                        },
                        {
                            label: 'Manage Thresholds',
                            command: () => {
                                this.thresholdService.openThresholdManagement(
                                    this.accountService.getUserDetails().EntityId
                                );
                            },
                            disabled: !capacityEnabled,
                            title: !capacityEnabled ? this.getCapacityUnsubscribedText() : ''
                        },
                        {
                            label: 'View VQM Details',
                            command: (row: MosDataSource) => this.openVQM(row.mos),
                            disabled: false,
                            title: ''
                        }
                    );
                })
        );
    }

    public unsubFromRTG(): void {
        if (this.dashlet.customerUnsub && this.dashlet.locationIdUnsub) {
            this.dataSubscription.add(
                this.store$
                    .pipe(select(selectLocations(this.dashlet.customerUnsub.customerId)))
                    .subscribe((locations: Location[]) => {
                        if (locations) {
                            if (this.dashlet.locationIdUnsub !== '=all=') {
                                locations = [new Location(this.dashlet.locationIdUnsub, this.dashlet.locationIdUnsub)];
                            }

                            locations.forEach((location: any) => {
                                let locationMem = null;
                                if (location.value && location.label) {
                                    locationMem = new Location(location.value, location.label);
                                } else {
                                    locationMem = location;
                                }

                                if (!locationMem.locationId) {
                                    return;
                                }
                                this.store$.dispatch(
                                    Actions.GetMosCapableReceivers({ locationId: locationMem.locationId })
                                );
                                this.dataSubscription.add(
                                    this.dataSubscription.add(
                                        this.store$
                                            .pipe(select(selectMosCapableReceivers(locationMem.locationId)))
                                            .subscribe(receivers => {
                                                if (receivers) {
                                                    if (this.dashlet.equipmentIdUnsub !== '=all=') {
                                                        this.store$.dispatch(
                                                            Actions.GetEquipmentInfo({
                                                                equipmentId: this.dashlet.equipmentIdUnsub
                                                            })
                                                        );
                                                        this.dataSubscription.add(
                                                            this.store$
                                                                .pipe(
                                                                    select(
                                                                        selectEquipmentInfo(
                                                                            this.dashlet.equipmentIdUnsub
                                                                        )
                                                                    )
                                                                )
                                                                .subscribe(data => {
                                                                    if (data) {
                                                                        if (data.Vendor === 'Cisco Systems') {
                                                                            this.dashlet.sizes.splice(2, 1);
                                                                            this.dashlet.isCiscoSystems = true;
                                                                            this.dashlet.checkIfEquipmentTypeSub.next(
                                                                                null
                                                                            );
                                                                        }
                                                                    }
                                                                })
                                                        );

                                                        receivers = [
                                                            {
                                                                equipmentId: this.dashlet.equipmentIdUnsub,
                                                                value: this.dashlet.equipmentIdUnsub
                                                            }
                                                        ];
                                                    }

                                                    for (const receiver of Object.values(receivers) as any[]) {
                                                        if (!receiver.value) {
                                                            continue;
                                                        }

                                                        this.store$.dispatch(
                                                            Actions.UnsubscribeFromRealTimeService({
                                                                equipmentId: receiver.value,
                                                                command: this.dashlet.commandTypeIdMOSSummary
                                                            })
                                                        );

                                                        this.store$.dispatch(
                                                            Actions.UnsubscribeFromRealTimeService({
                                                                equipmentId: receiver.value,
                                                                command: this.dashlet.commandTypeIdMOSSummaryByIPNR
                                                            })
                                                        );

                                                        this.store$.dispatch(
                                                            Actions.UnsubscribeFromRealTimeService({
                                                                equipmentId: receiver.value,
                                                                command: this.dashlet.commandTypeIdMOSSummaryDSCPByIPNR
                                                            })
                                                        );

                                                        this.store$.dispatch(
                                                            Actions.UnsubscribeFromRealTimeService({
                                                                equipmentId: receiver.value,
                                                                command: this.dashlet.commandTypeIdMOSSummaryIPNRByDSCP
                                                            })
                                                        );

                                                        this.store$.dispatch(
                                                            Actions.UnsubscribeFromRealTimeService({
                                                                equipmentId: receiver.value,
                                                                command: this.dashlet.commandTypeIdMOSSummaryByCodec
                                                            })
                                                        );

                                                        this.store$.dispatch(
                                                            Actions.UnsubscribeFromRealTimeService({
                                                                equipmentId: receiver.value,
                                                                command: this.dashlet.commandTypeIdMOSSummaryCodecByIPNR
                                                            })
                                                        );

                                                        this.store$.dispatch(
                                                            Actions.UnsubscribeFromRealTimeService({
                                                                equipmentId: receiver.value,
                                                                command: this.dashlet.commandTypeIdMOSSummaryByLayer3
                                                            })
                                                        );

                                                        this.store$.dispatch(
                                                            Actions.UnsubscribeFromRealTimeService({
                                                                equipmentId: receiver.value,
                                                                command:
                                                                    this.dashlet.commandTypeIdMOSSummaryLayer3ByDSCP
                                                            })
                                                        );
                                                    }
                                                }
                                            })
                                    )
                                );

                                // getTypeEquipmentsAtLocation
                                this.store$.dispatch(
                                    Actions.GetTypeEquipmentsAtLocations({
                                        locationId: locationMem.locationId,
                                        typeStr: 'CMS'
                                    })
                                );
                                this.dataSubscription.add(
                                    this.store$
                                        .pipe(select(selectTypeEquipmentsAtLocation(locationMem.locationId)))
                                        .subscribe(cmsReceivers => {
                                            if (cmsReceivers) {
                                                for (const cmsReceiver of cmsReceivers.data as EquipmentInfo[]) {
                                                    const equipmentIdCMS = cmsReceiver.equipmentId;

                                                    this.store$.dispatch(
                                                        Actions.UnsubscribeFromRealTimeService({
                                                            equipmentId: equipmentIdCMS,
                                                            command: this.dashlet.commandTypeIdMOSSummaryByCMS_Agent
                                                        })
                                                    );

                                                    // Subscribe Mos Summary By CMS_VDN
                                                    this.store$.dispatch(
                                                        Actions.UnsubscribeFromRealTimeService({
                                                            equipmentId: equipmentIdCMS,
                                                            command: this.dashlet.commandTypeIdMOSSummaryByCMS_VDN
                                                        })
                                                    );

                                                    this.store$.dispatch(
                                                        Actions.UnsubscribeFromRealTimeService({
                                                            equipmentId: equipmentIdCMS,
                                                            command: this.dashlet.commandTypeIdMOSSummaryByCMS_Skill
                                                        })
                                                    );
                                                }
                                            }
                                        })
                                );
                            });
                        }
                    })
            );
        }
    }

    public initData(): void {
        this.vqmLinkEnabled = true;
        this.mosSummaryOverTimeIPNRData = [];

        this.MosTimeMappedData = [];
        this.totalMosSummaryData =
            this.partialMosSummaryData =
            this.mosSummaryOverTimeData =
            this.DSCPData =
            this.IPNRData =
                {};
        this.IPNRSpliceRecords =
            this.DSCPSpliceRecords =
            this.CodecSpliceRecords =
            this.Layer3SpliceRecords =
            this.CMSAgentSpliceRecords =
            this.CMSVDNSpliceRecords =
            this.CMSSkillSpliceRecords =
                10;
        this.DSCP_IPNRCombinedData = this.IPNR_DSCPCombinedData = this.Codec_IPNRCombinedData = [];
        this.IPNRMapList = [];
        this.TimeIPNRData = [];
        this.MosSummaryTableDisplay = [];

        this.CMSAgentDataRaw = this.CMSVDNDataRaw = this.CMSSkillDataRaw = [];
        this.CodecMappedDataNoResult = false;
        this.Layer3MappedDataNoResult = false;

        this.layer3searchText = '';

        this.manageThreshold = false;
    }

    ngOnDestroy() {
        this.uiSubscription.unsubscribe();
        this.dataSubscription.unsubscribe();
    }

    //#endregion

    private withinSummaryRange(data: RealtimeGatewayDocument, minDate: Date): boolean {
        return new Date(data.timestamp).getTime() >= minDate.getTime();
    }

    /* Updates charts*/
    updateCharts() {
        this.updateSummaryCharts();
        this.updateSummaryOverTimeChart();
        this.updateSummaryOverTimeIPNRCharts();
        this.updateBreakdownChartByType(DashletVQMDailySummary.IPNR);
        this.updateBreakdownChartByType(DashletVQMDailySummary.DSCP);

        this.updateSummaryMosSummaryDisplay();

        if (this.totalPie && this.partialPie) {
            if (this.totalPie.chart && this.partialPie.chart) {
                this.partialPie.chart.update();
                this.totalPie.chart.update();
            }
        }

        if (this.mosOverTimeLine) {
            if (this.mosOverTimeLine) {
                this.mosOverTimeLine.updateChart();
            }
        }

        if (this.dscpBar) {
            if (this.dscpBar.chart) {
                this.dscpBar.chart.update();
            }
        }

        if (this.ipnrBar) {
            if (this.ipnrBar.chart) {
                this.ipnrBar.chart.update();
            }
        }
    }

    /**
     * Processes the data that will be shown on the Mos Sumary table for Large and Huge Dashlet.
     * author: patrick
     */
    updateSummaryMosSummaryDisplay() {
        if (this.dashlet.combinedMosData) {
            let combinedMOSSummary = this.dashlet.combinedMosData;
            let dataDisplay = [];

            let goodRow = { MosBandLabel: '>= 4.0', MosBand: 'Good', TotalStreams: 0, Percentage: 0 };
            let warningRow = { MosBandLabel: '3.6 - 4.0', MosBand: 'Warning', TotalStreams: 0, Percentage: 0 };
            let badRow = { MosBandLabel: '< 3.6', MosBand: 'Danger', TotalStreams: 0, Percentage: 0 };

            // get all good streams
            goodRow.TotalStreams = combinedMOSSummary.mosData[4] + combinedMOSSummary.mosData[5];
            goodRow.Percentage = Number(((goodRow.TotalStreams / combinedMOSSummary.totalCalls) * 1 * 100).toFixed(1));
            dataDisplay.push(goodRow); // push as one row

            // get all warning streams
            warningRow.TotalStreams = combinedMOSSummary.mosData[3];
            warningRow.Percentage = Number(
                ((warningRow.TotalStreams / combinedMOSSummary.totalCalls) * 1 * 100).toFixed(1)
            );
            dataDisplay.push(warningRow); // push as one row

            // get all danger streams
            badRow.TotalStreams =
                combinedMOSSummary.mosData[0] + combinedMOSSummary.mosData[1] + combinedMOSSummary.mosData[2];
            badRow.Percentage = Number(((badRow.TotalStreams / combinedMOSSummary.totalCalls) * 1 * 100).toFixed(1));
            dataDisplay.push(badRow); // push as one row

            this.MosSummaryTableDisplay = dataDisplay;
        }
    }

    resetCharts() {
        this.totalMosSummaryData = {};
        this.partialMosSummaryData = {};
        this.mosSummaryOverTimeData = {};
        this.mosSummaryOverTimeIPNRData = [];
        this.DSCPData = {};
        this.IPNRData = {};

        this.lowerLineStart = null;
        this.upperLineStart = null;

        this.IPNRSpliceRecords = this.DSCPSpliceRecords = this.CodecSpliceRecords = 10;
        this.DSCP_IPNRCombinedData = this.IPNR_DSCPCombinedData = this.Codec_IPNRCombinedData = [];

        this.IPNRSpliceRecords =
            this.DSCPSpliceRecords =
            this.CodecSpliceRecords =
            this.Layer3SpliceRecords =
            this.CMSAgentSpliceRecords =
            this.CMSVDNSpliceRecords =
            this.CMSSkillSpliceRecords =
                10;
        this.DSCP_IPNRCombinedData = this.IPNR_DSCPCombinedData = this.Codec_IPNRCombinedData = [];
        this.IPNRMapList = [];
        this.TimeIPNRData = [];
        this.MosSummaryTableDisplay = [];
        this.DSCPDataRaw =
            this.IPNRDataRaw =
            this.CodecDataRaw =
            this.Layer3DataRaw =
            this.CMSAgentDataRaw =
            this.CMSVDNDataRaw =
            this.CMSSkillDataRaw =
            this.MosTimeDataRaw =
                [];
        this.MosTimeMappedData = [];

        this.CodecMappedDataNoResult = false;
        this.Layer3MappedDataNoResult = false;
        this.CMSMappedDataNoResult = true;
    }

    showMoreRecords(type: string) {
        let count = this[`${type}SpliceRecords`];
        if ((count += 10) > 20) {
            this[`${type}SpliceRecords`] -= 10;
        } else {
            this[`${type}SpliceRecords`] += 10;
        }

        this[`${type}MappedData`] = this[`${type}DataRaw`].slice(0, this[`${type}SpliceRecords`]);

        this[`showMore${type}Records`] = !this[`showMore${type}Records`];
    }

    showMoreRecordsV2(dataSource: string) {
        let count = this[`${dataSource}`].DataSlice;

        if ((count += 10) > 20) {
            this[`${dataSource}`].DataSlice -= 10;
        } else {
            this[`${dataSource}`].DataSlice += 10;
        }

        this[`${dataSource}`].DataDisplay = this[`${dataSource}`].DataSource.slice(0, this[`${dataSource}`].DataSlice);
    }

    /**
     * Latest Expandable method as of 22-05-2019
     * @param event
     * @param typeParam
     */
    returnExpandableDataV5(event, typeParam) {
        //IPNR_DSCPCombinedData
        //DSCP_IPNRCombinedData
        let parentRow = typeParam.split('_')[0];
        let childRow = typeParam.split('_')[1];
        let paramValue = event[parentRow];

        let typeText = childRow;

        let filteredList = this[`${typeParam}CombinedData`].filter(x => {
            if (x[`${parentRow}`] === paramValue) {
                return true;
            }
        });

        // returns a list of distict records of the given datatable i.e. IPNR table returns list of unique IPNR number
        let distinctList = Array.from(new Set(filteredList.map(s => s[`${childRow}`]))).map(childRow => {
            return {
                [`${typeText}`]: childRow
            };
        });

        //loops through the parameter filtered list using the distinct list to get a list based on the paramValue
        // i.e. DSCP_IPNRCombinedData. ParamValue(DSCP) = 1, FilteredList = contains a list of DSCP == 1, Distinct = contains a list of Unique IPNR ids.
        let returnList = distinctList.map(x => {
            let returnVal = this.getObjectType(childRow);
            returnVal[`${childRow}`] = x[`${childRow}`];

            filteredList.map(y => {
                if (y[`${childRow}`] === x[`${childRow}`]) {
                    returnVal.MosGood += y.Mos40_43 + y.Mos43;
                    returnVal.MosWarning += y.Mos36_40;
                    returnVal.MosDanger += y.Mos10_26 + y.Mos26_31 + y.Mos31_36;
                    returnVal.TimeStamp = y.TimeStamp;
                    returnVal.TotalStreams += y.TotalStreams;
                    returnVal.Percentage += (y.Mos40_43 + y.Mos43) / y.TotalStreams;
                }
            });

            return returnVal;
        });
        return returnList;
    }

    /**
     * Latest Expandable method as of 22-05-2019
     * @param event
     * @param typeParam
     */
    private returnExpandableDataV5ForLayer3(event, typeParam): any[] {
        //IPNR_DSCPCombinedData
        //DSCP_IPNRCombinedData
        let parentRow = typeParam.split('_')[0];

        parentRow = parentRow === 'Layer3' ? 'IpAddress' : typeParam;

        let childRow = typeParam.split('_')[1];
        let paramValue = event[parentRow];

        let typeText = childRow;

        if (!this[`${typeParam}CombinedData`]) {
            return;
        }

        let filteredList = this[`${typeParam}CombinedData`].filter(x => {
            if (x[`${parentRow}`] === paramValue) {
                return true;
            }
        });

        // returns a list of distict records of the given datatable i.e. IPNR table returns list of unique IPNR number
        let distinctList = Array.from(new Set(filteredList.map(s => s[`${childRow}`]))).map(childRow => {
            return {
                [`${typeText}`]: childRow
            };
        });

        //loops through the parameter filtered list using the distinct list to get a list based on the paramValue
        // i.e. DSCP_IPNRCombinedData. ParamValue(DSCP) = 1, FilteredList = contains a list of DSCP == 1, Distinct = contains a list of Unique IPNR ids.
        return distinctList.map(x => {
            let returnVal = this.getObjectType(childRow);
            returnVal[`${childRow}`] = x[`${childRow}`];

            filteredList.map(y => {
                if (y[`${childRow}`] === x[`${childRow}`]) {
                    returnVal.MosGood += y.MosGood;
                    returnVal.MosWarning += y.MosWarning;
                    returnVal.MosDanger += y.MosDanger;
                    returnVal.TimeStamp = y.TimeStamp;
                    returnVal.TotalStreams += y.TotalStreams;
                    returnVal.Percentage += y.MosGood / y.TotalStreams;
                }
            });

            return returnVal;
        });
    }

    private categorizeData(dataParam: any, typeParam: string) {
        let typeText = typeParam === 'Layer3' ? 'IpAddress' : typeParam;
        typeParam = typeParam === 'Layer3' ? 'IpAddress' : typeParam;

        // returns a list of distict records of the given datatable i.e. IPNR table returns list of unique IPNR number
        let distinctList = Array.from(new Set(dataParam.map(s => s[`${typeParam}`]))).map(typeParam => {
            return {
                [`${typeText}`]: typeParam
            };
        });

        let returnList = distinctList.map(x => {
            let returnVal = this.getObjectType(typeParam);
            returnVal[`${typeParam}`] = x[`${typeParam}`];

            dataParam.map(y => {
                if (y[`${typeParam}`] === x[`${typeParam}`]) {
                    returnVal.MosGood += y.Mos40_43 + y.Mos43;
                    returnVal.MosWarning += y.Mos36_40;
                    returnVal.MosDanger += y.Mos10_26 + y.Mos26_31 + y.Mos31_36;
                    returnVal.TimeStamp = y.TimeStamp;
                    returnVal.TotalStreams += y.TotalStreams;
                    returnVal.Percentage = parseInt(
                        (
                            (returnVal.MosGood / (returnVal.MosGood + returnVal.MosWarning + returnVal.MosDanger)) *
                            100
                        ).toFixed(0)
                    );
                    returnVal.Percentage =
                        returnVal.MosGood > returnVal.MosWarning + returnVal.MosDanger
                            ? returnVal.Percentage
                            : returnVal.MosWarning > returnVal.MosDanger
                            ? returnVal.Percentage
                            : returnVal.Percentage * -1;
                }
            });

            return returnVal;
        });
        return returnList;
    }

    getObjectType(typeParam: string) {
        switch (typeParam) {
            case 'Codec': {
                let data: CodecCategorized = {
                    MosGood: 0,
                    MosDanger: 0,
                    MosWarning: 0,
                    TotalStreams: 0,
                    Percentage: 0,
                    Priority: 0
                } as CodecCategorized;
                return data;
            }
            case 'IPNR': {
                let data: IPNRCategorized = {
                    MosGood: 0,
                    MosDanger: 0,
                    MosWarning: 0,
                    TotalStreams: 0,
                    Percentage: 0,
                    Priority: 0,
                    ChartData: []
                } as IPNRCategorized;
                return data;
            }
            case 'DSCP': {
                let data: DSCPCategorized = {
                    MosGood: 0,
                    MosDanger: 0,
                    MosWarning: 0,
                    TotalStreams: 0,
                    Percentage: 0,
                    Priority: 0
                } as DSCPCategorized;
                return data;
            }
            case 'IpAddress': {
                //this is actually Layer3 naming inconsistency
                let data: Layer3Categorized = {
                    MosGood: 0,
                    MosDanger: 0,
                    MosWarning: 0,
                    TotalStreams: 0,
                    Percentage: 0,
                    Priority: 0
                } as Layer3Categorized;
                return data;
            }
        }
    }

    /* Updates pie charts in MOS Summary section */
    updateSummaryCharts() {
        if (this.dashlet.combinedMosData) {
            let combinedMOSSummary = this.dashlet.combinedMosData;

            this.totalMosSummaryData = {
                labels: ['MOS < 3.6', 'MOS 3.6 - 4.0', 'MOS 4.0 - 4.3', 'MOS > 4.3'],
                datasets: [
                    {
                        data: [
                            combinedMOSSummary.mosData[2] +
                                combinedMOSSummary.mosData[1] +
                                combinedMOSSummary.mosData[0],
                            combinedMOSSummary.mosData[3],
                            combinedMOSSummary.mosData[4],
                            combinedMOSSummary.mosData[5]
                        ],
                        backgroundColor: [
                            this.dashletService.getSeverityColor(2),
                            this.dashletService.getSeverityColor(1),
                            this.dashletService.getSeverityColor(0),
                            this.dashletService.getSeverityColor(3)
                        ],
                        hoverBackgroundColor: [
                            this.dashletService.getSeverityColor(2),
                            this.dashletService.getSeverityColor(1),
                            this.dashletService.getSeverityColor(0),
                            this.dashletService.getSeverityColor(3)
                        ]
                    }
                ]
            };

            // calculates pie chart reorientation so that Mos bands lower than 4.0 are always facing horizontally to the right
            let partialTotal: number = 0;
            for (let i = 0; i < 4; i++) {
                partialTotal += combinedMOSSummary.mosData[i];
            }

            let rotationAngle = (180 - (360 * partialTotal) / combinedMOSSummary.totalCalls) / 2;

            this.totalPieOptions = { ...this.totalPieOptions, rotation: rotationAngle };

            // calculates line position between two pie charts
            let radius = 85;
            let adjustmentHeight = radius * Math.tan(((90 - rotationAngle) * Math.PI) / 180);
            this.upperLineStart = radius - adjustmentHeight;
            this.lowerLineStart = radius + adjustmentHeight;

            this.partialMosSummaryData = {
                labels: ['MOS 1.0 - 2.6', 'MOS 2.6 - 3.1', 'MOS 3.1 - 3.6', 'MOS 3.6 - 4.0'],
                datasets: [
                    {
                        data: [
                            combinedMOSSummary.mosData[0],
                            combinedMOSSummary.mosData[1],
                            combinedMOSSummary.mosData[2],
                            combinedMOSSummary.mosData[3]
                        ],
                        backgroundColor: [
                            this.dashletService.getSeverityColor(2),
                            '#eb5134',
                            '#fb7237',
                            this.dashletService.getSeverityColor(1)
                        ],
                        hoverBackgroundColor: [
                            this.dashletService.getSeverityColor(2),
                            '#eb5134',
                            '#fb7237',
                            this.dashletService.getSeverityColor(1)
                        ]
                    }
                ]
            };
        }
    }

    /* Updates stacked bar charts in respective breakdown sections.*/
    updateBreakdownChartByType(type: string) {
        let combinedMOSSummaryBreakdown: Map<string, number[]> = this.dashlet.getCombinedMOSSummaryBreakdown(type);
        let labels: string[] = this.dashlet.getTopGroupsByType(type, 30).map(e => e.name);

        let mosGood: number[] = [];
        let mosSatisfactory: number[] = [];
        let mosPoor: number[] = [];

        for (let label of labels) {
            mosGood.push(combinedMOSSummaryBreakdown.get(label)[5] + combinedMOSSummaryBreakdown.get(label)[4]);
            mosSatisfactory.push(combinedMOSSummaryBreakdown.get(label)[3]);
            mosPoor.push(
                combinedMOSSummaryBreakdown.get(label)[2] +
                    combinedMOSSummaryBreakdown.get(label)[1] +
                    combinedMOSSummaryBreakdown.get(label)[0]
            );
        }

        let data = {
            labels: labels,
            datasets: [
                {
                    label: 'MOS < 3.6',
                    data: mosPoor,
                    backgroundColor: this.dashletService.getSeverityColor(2)
                },
                {
                    label: 'MOS 3.6 - 4.0',
                    data: mosSatisfactory,
                    backgroundColor: this.dashletService.getSeverityColor(1)
                },
                {
                    label: 'MOS > 4.0',
                    data: mosGood,
                    backgroundColor: this.dashletService.getSeverityColor(0)
                }
            ]
        };
        type === DashletVQMDailySummary.IPNR ? (this.IPNRData = data) : (this.DSCPData = data);
    }

    /* Updates summary over time line chart (with ipnr) */
    updateSummaryOverTimeIPNRCharts() {
        let ipnrsToShow = this.showMoreIPNRs ? 50 : 10;

        this.mosSummaryOverTimeIPNRData = [];
        for (let ipnr of this.dashlet.getTopGroupsByType(DashletVQMDailySummary.IPNR, ipnrsToShow)) {
            this.mosSummaryOverTimeIPNRData.push({
                name: ipnr.name,
                percentage: this.getPercentageTotalCalls(
                    ipnr.data[0] + ipnr.data[1] + ipnr.data[2] + ipnr.data[3] + ipnr.data[4] + ipnr.data[5]
                ),
                streams: Number(
                    ipnr.data[0] + ipnr.data[1] + ipnr.data[2] + ipnr.data[3] + ipnr.data[4] + ipnr.data[5]
                ),
                mosGoodPercentage: this.getPercentage(
                    ipnr.data[4] + ipnr.data[5],
                    ipnr.data[0] + ipnr.data[1] + ipnr.data[2] + ipnr.data[3] + ipnr.data[4] + ipnr.data[5]
                ),
                chartData: {
                    datasets: []
                }
            });
        }

        // for all the top IPNRs, we need to produce data by the hour for the hours specified for each table row's graph
        for (let ipnr of this.mosSummaryOverTimeIPNRData) {
            // we split the data into 3 bands, good for green, orange for satisfactory,
            let currentIPNRGoodData: Point[] = this.getMosQualityDataForChart(
                this.dashlet.mosSummaryIPNRBreakdownMap,
                MosQuality.Good,
                ipnr.name
            );
            let currentIPNRSatisfactoryData: Point[] = this.getMosQualityDataForChart(
                this.dashlet.mosSummaryIPNRBreakdownMap,
                MosQuality.Satisfactory,
                ipnr.name
            );
            let currentIPNRPoorData: Point[] = this.getMosQualityDataForChart(
                this.dashlet.mosSummaryIPNRBreakdownMap,
                MosQuality.Poor,
                ipnr.name
            );

            let mosGoodChartData = {
                label: 'MOS > 4.0',
                borderColor: this.dashletService.getSeverityColor(0),
                backgroundColor: this.dashletService.getSeverityColor(0),
                borderWidth: 2,
                pointRadius: 0,
                pointHitRadius: 10,
                fill: false,
                lineTension: 0,
                data: currentIPNRGoodData.sort((a, b) => a.x.getTime() - b.x.getTime())
            };

            let mosSatisfactoryChartData = {
                label: 'MOS 3.6 - 4.0',
                borderColor: this.dashletService.getSeverityColor(1),
                backgroundColor: this.dashletService.getSeverityColor(1),
                borderWidth: 2,
                pointRadius: 0,
                pointHitRadius: 10,
                fill: false,
                lineTension: 0,
                data: currentIPNRSatisfactoryData.sort((a, b) => a.x.getTime() - b.x.getTime())
            };

            let mosPoorChartData = {
                label: 'MOS < 3.6',
                borderColor: this.dashletService.getSeverityColor(2),
                backgroundColor: this.dashletService.getSeverityColor(2),
                borderWidth: 2,
                pointRadius: 0,
                pointHitRadius: 10,
                fill: false,
                lineTension: 0,
                data: currentIPNRPoorData.sort((a, b) => a.x.getTime() - b.x.getTime())
            };

            ipnr.chartData = {
                datasets: [mosPoorChartData, mosSatisfactoryChartData, mosGoodChartData]
            };
            this.MosOverTimeLoading = false;
        }
    }

    /* Updates summary over time line chart */
    updateSummaryOverTimeChart() {
        let mosGoodTotal: Point[] = this.getMosQualityDataForChart(this.dashlet.mosSummaryMap, MosQuality.Good);
        let mosSatisfactoryTotal: Point[] = this.getMosQualityDataForChart(
            this.dashlet.mosSummaryMap,
            MosQuality.Satisfactory
        );
        let mosPoorTotal: Point[] = this.getMosQualityDataForChart(this.dashlet.mosSummaryMap, MosQuality.Poor);

        let mosGoodChartData = {
            label: 'MOS >= 4.0',
            borderColor: this.dashletService.getSeverityColor(0),
            backgroundColor: this.dashletService.getSeverityColor(0),
            pointRadius: 0,
            pointHitRadius: 10,
            fill: false,
            lineTension: 0,
            data: mosGoodTotal.sort((a, b) => a.x.getTime() - b.x.getTime())
        };

        let mosSatisfactoryChartData = {
            label: 'MOS 3.6 - 4.0',
            borderColor: this.dashletService.getSeverityColor(1),
            backgroundColor: this.dashletService.getSeverityColor(1),
            pointRadius: 0,
            pointHitRadius: 10,
            fill: false,
            lineTension: 0,
            data: mosSatisfactoryTotal.sort((a, b) => a.x.getTime() - b.x.getTime())
        };

        let mosPoorChartData = {
            label: 'MOS < 3.6',
            borderColor: this.dashletService.getSeverityColor(2),
            backgroundColor: this.dashletService.getSeverityColor(2),
            pointRadius: 0,
            pointHitRadius: 10,
            fill: false,
            lineTension: 0,
            data: mosPoorTotal.sort((a, b) => a.x.getTime() - b.x.getTime())
        };

        this.mosSummaryOverTimeData = {
            datasets: [mosPoorChartData, mosSatisfactoryChartData, mosGoodChartData]
        };
    }

    /**
     * Retrieves the requested (total or breakdown) MOS quality data (i.e. Good, Satisfactoty, or Poor) for a given time period.
     * @param mosSummaryMap
     * @param quality
     * @param ipnrName The name of the IP Network region to retrieve data for. This is needed to retrieve breakdown data.
     * @returns A Point array with X (date) and Y (number) co-ordinates for a given quality type.
     */
    private getMosQualityDataForChart(
        mosSummaryMap: Map<string, MOSSummaryData> | Map<string, MOSSummaryBreakdownData>,
        quality: string,
        ipnrName?: string
    ): Point[] {
        let mosQualityData: Point[] = [];
        for (let receiver of this.dashlet.receivers) {
            if (mosSummaryMap.get(receiver.label)) {
                const receiverMosQuality = mosSummaryMap.get(receiver.label);
                const receiverMosQualityDataInSummaryPeriod = this.getCurrentReceiverMosQualityPoints(
                    receiverMosQuality,
                    quality,
                    ipnrName
                );

                if (!receiverMosQualityDataInSummaryPeriod) {
                    continue;
                }

                for (let receiverMosQualityDataPoint of receiverMosQualityDataInSummaryPeriod) {
                    const updateIndex = mosQualityData.findIndex(
                        (point: Point) =>
                            point.x.getDate() === receiverMosQualityDataPoint.x.getDate() &&
                            point.x.getMonth() === receiverMosQualityDataPoint.x.getMonth() &&
                            point.x.getFullYear() === receiverMosQualityDataPoint.x.getFullYear() &&
                            point.x.getHours() === receiverMosQualityDataPoint.x.getHours()
                    );

                    //If the array holding the MOS quality data already contains the date co-ordinate (X variable) for a data point then add to its total.
                    if (updateIndex !== -1) {
                        mosQualityData[updateIndex].y += receiverMosQualityDataPoint.y;
                    } else {
                        //Otherwise, add the new date co-ordianate to the array.
                        mosQualityData.push(receiverMosQualityDataPoint);
                    }
                }
            }
        }
        return mosQualityData;
    }

    /**
     * For a given MOS Data object (MOSSummaryData or MOSSummaryBreakdownData), retrieve the "Good", "Satisfactory", or "Poor" data points within the summary period
     * defined in the settings for this dashlet.
     * @param mosSummaryData
     * @param quality
     * @returns A point array containing the quality data for a given MOSSyummaryData object.
     */
    private getCurrentReceiverMosQualityPoints(
        mosDataObj: MOSSummaryData | MOSSummaryBreakdownData,
        quality: string,
        ipnrName?: string
    ): Point[] {
        if (mosDataObj instanceof MOSSummaryData) {
            switch (quality) {
                case MosQuality.Good:
                    return mosDataObj.getGoodArrayByHours(this.dashlet.summaryPeriod.value);
                case MosQuality.Satisfactory:
                    return mosDataObj.getSatisfactoryArrayByHours(this.dashlet.summaryPeriod.value);
                case MosQuality.Poor:
                    return mosDataObj.getPoorArrayByHours(this.dashlet.summaryPeriod.value);
                default:
                    break;
            }
        }

        if (mosDataObj instanceof MOSSummaryBreakdownData) {
            switch (quality) {
                case MosQuality.Good:
                    return mosDataObj.getGoodMOSBreakdownDataByHours(this.dashlet.summaryPeriod.value).get(ipnrName);
                case MosQuality.Satisfactory:
                    return mosDataObj
                        .getSatisfactoryBreakdownDataByHours(this.dashlet.summaryPeriod.value)
                        .get(ipnrName);
                case MosQuality.Poor:
                    return mosDataObj.getPoorMOSBreakdownDataByHours(this.dashlet.summaryPeriod.value).get(ipnrName);
                default:
                    break;
            }
        }

        return []; //If input object is not of type MOSSummaryData or MOSSummaryBreakdownData return empty array.
    }

    /* ---------- ACCORIDON TOGGLE METHODS ---------- */

    /* Applies size expansion whenever an accordion tab is opened */
    handleTabClose(index: number) {
        const tabIndex = this.dashlet.tabsOpen.indexOf(index);
        if (tabIndex !== -1) {
            this.dashlet.tabsOpen.splice(tabIndex, 1);
        }

        if (this.largeLayout) {
            this.dashlet.applySizeExpansion(0, -6 * (4 - this.dashlet.tabsOpen.length) + 2);
        }
    }

    handleTabOpen(index: number) {
        if (this.dashlet.tabsOpen.indexOf(index) === -1) {
            this.dashlet.tabsOpen.push(index);
        }
        if (this.largeLayout) {
            this.dashlet.applySizeExpansion(0, -6 * (4 - this.dashlet.tabsOpen.length) + 2);
        }
    }

    handleLeftTabOpen(event: any) {
        this.dashlet.leftTabsOpen.push(event.index);
    }

    handleLeftTabClose(event: any) {
        this.dashlet.leftTabsOpen.splice(this.dashlet.leftTabsOpen.indexOf(event.index), 1);
    }

    handleRightTabOpen(event: any) {
        this.dashlet.rightTabsOpen.push(event.index);
    }

    handleRightTabClose(event: any) {
        this.dashlet.rightTabsOpen.splice(this.dashlet.rightTabsOpen.indexOf(event.index), 1);
    }

    /* Handles state change for breakdown tabs in 'Huge' size */
    handleBreakdownTabChanged() {
        this.dashlet.sizeChanged.next(null);
    }

    /* Toggles state for nested table in DSCP breakdown section*/
    toggleDSCP(index: number) {
        this.showDSCP[index] = this.showDSCP[index] ? false : true;
    }

    /* Toggles state for nested table in IPNR breakdown section*/
    toggleIPNR(index: number) {
        this.showIPNR[index] = this.showIPNR[index] ? false : true;
    }

    /* Gets open/closed state of specific accordion tab*/
    getTabStateByIndex(index: number) {
        return this.dashlet.tabsOpen.findIndex(e => e === 1) !== -1;
    }

    getLeftTabStateByIndex(index: number) {
        return this.dashlet.leftTabsOpen.findIndex(e => e === index) !== -1;
    }

    getRightTabStateByIndex(index: number) {
        return this.dashlet.rightTabsOpen.findIndex(e => e === index) !== -1;
    }

    handleIPNRExpansion() {
        this.showMoreIPNRs = !this.showMoreIPNRs;
        this.dashlet.chartDataUpdated.next(null);
    }

    /* Handle Toggle Tabs */
    toggledGroupClickEvent(toggle) {
        this.selectedToggle = toggle;
    }

    /* ---------- ALEX / TOOLTIP VALUE METHODS ---------- */

    getPercentageTotalCalls(streams: number) {
        let percentage = (streams / this.dashlet.combinedMosData?.totalCalls) * 100;
        let returnValue = isNaN(percentage) === true ? '---' : percentage.toFixed(1);
        return returnValue === '100.0' ? Number(percentage.toFixed(0)) : returnValue;
    }

    getPercentage(streams: number, totalStreams) {
        let percentage = (streams / totalStreams) * 100;
        let returnValue = isNaN(percentage) ? '---' : percentage.toFixed(1);
        return returnValue === '100.0' ? Number(percentage.toFixed(0)) : returnValue;
    }

    getPercentageForLayer3(lineRecord: number, rowData: any[]) {
        var sum = rowData.reduce((acc, num) => acc + num.Streams, 0);
        return ((lineRecord / sum) * 100).toFixed(0);
    }

    getSeverityBarPercentage(severity: number, total: number) {
        let percentage = (severity / total) * 100;
        return isNaN(percentage) ? '0%' : percentage.toFixed(1) + '%';
    }

    getSeverityColor(severity: number): string {
        return this.dashletService.getSeverityColor(severity);
    }

    /* 'For media streams with a MOS average below 4, we noticed that value% of them had either DSCP stripped, or a value not equal to 46*/
    getDSCPDetectValue() {
        let combinedMOSSummaryBreakdown: Map<string, number[]> = this.dashlet.getCombinedMOSSummaryBreakdown(
            DashletVQMDailySummary.DSCP
        );

        if (combinedMOSSummaryBreakdown.get('46')) {
            let sum = 0;
            for (let i = 0; i < 4; i++) {
                sum += combinedMOSSummaryBreakdown.get('46')[i];
            }

            let totalMosLessThanFour =
                this.dashlet.combinedMosData?.totalCalls -
                this.dashlet.combinedMosData?.mosData[5] -
                this.dashlet.combinedMosData?.mosData[4];

            return (((totalMosLessThanFour - sum) / totalMosLessThanFour) * 100).toFixed(1) + '%';
        }

        return '---';
    }

    getDSCPToolTip() {
        return (
            'DSCP (Differentiated Services Code Point) setting, retaining, and network honoring is critical to sustainable good call quality.\n' +
            'All Network Regions are set to use a DSCP value of 46 on all voice packets they send.'
        );
    }

    getMosSummaryToolTip() {
        return (
            'MOS scores are calculated using an industry standard formula from data contained in the RTCP stream and the Trace Route.\nThe data included in the formula are:\n' +
            '   - Packet Loss\n' +
            '   - Latency\n' +
            '   - Jitter\n' +
            '   - Codec\n\n' +
            'The MOS score provides a numeric indication of the perceived quality of received voice.\n' +
            '   Maximum for G.711 codec:    4.4\n' +
            '   Very satisfied:    4.3 - 5.0\n' +
            '   Satisfied:    4.0 - 4.3\n' +
            '   Some users satisfied:    3.6 - 4.0\n' +
            '   Many users dissatisfied:    3.1 - 3.6\n' +
            '   Nearly all users dissatisfied:    2.6 - 3.1\n' +
            '   Not recommended:    1.0 - 2.6\n'
        );
    }

    getMosSummaryPanelMouseoverToolTip() {
        return 'Click to:\n' + '- View details in VQM\n' + '- Add/Manage Thresholds';
    }

    getIPNRToolTip() {
        return `Showing 1 - ${
            this.dashlet.getCombinedMOSSummaryBreakdown(DashletVQMDailySummary.IPNR).size < 30
                ? this.dashlet.getCombinedMOSSummaryBreakdown(DashletVQMDailySummary.IPNR).size
                : 30
        } of ${this.dashlet.getCombinedMOSSummaryBreakdown(DashletVQMDailySummary.IPNR).size} IPNRs`;
    }

    /* ---------- VQM LINK METHODS ---------- */

    /* Opens a new tab and waits for ruleset with filters to be created before redirecting to VQM*/
    openVQM(mosCategory: string) {
        if (this.vqmLinkEnabled) {
            this.vqmLinkEnabled = false;
            let vqmTab = window.open();
            vqmTab.location.href = '#';
            this.dashletDataProcessingService
                .addVQMFilterRuleSet(
                    this.dashlet.customer.customerId,
                    `VQM_${this.dashlet.summaryPeriod.value}_HOUR_SUMMARY_${this.dashlet.location.replace(/ /g, '_')}`,
                    JSON.stringify(this.buildRulesArrayFormatWithMos(mosCategory))
                )
                .pipe(
                    retry(3),
                    take(1),
                    map(a => a || {})
                )
                .subscribe(a => {
                    vqmTab.location.href =
                        `${environment.webPortalUrl}/VoiceQualityManagement/VQM?entityId=` +
                        this.dashlet.customer.customerId;
                    this.vqmLinkEnabled = true;
                });
        }
    }

    /* Builds filter format required by rules builder API*/
    buildRulesArrayFormat() {
        let dateTo = new Date(this.dashlet.lastUpdated);
        dateTo.setMinutes(0, 0, 0);
        let dayTo = dateTo.getDate();
        let monthTo = dateTo.toLocaleString('en-us', { month: 'long' });
        let yearTo = dateTo.getFullYear();
        let suffixTo = dateTo.getHours() > 11 ? 'PM' : 'AM';
        let hoursTo = dateTo.getHours() > 12 ? dateTo.getHours() - 12 + '' : dateTo.getHours() + '';
        hoursTo = parseInt(hoursTo) < 10 ? '0' + hoursTo : hoursTo;
        let minutesTo = dateTo.getMinutes() < 10 ? '0' + dateTo.getMinutes() : dateTo.getMinutes();
        let secondsTo = dateTo.getSeconds() < 10 ? '0' + dateTo.getSeconds() : dateTo.getSeconds();

        let dateFrom = new Date(dateTo);
        dateFrom.setHours(dateTo.getHours() - this.dashlet.summaryPeriod.value);
        let dayFrom = dateFrom.getDate();
        let monthFrom = dateFrom.toLocaleString('en-us', { month: 'long' });
        let yearFrom = dateFrom.getFullYear();
        let suffixFrom = dateFrom.getHours() > 11 ? 'PM' : 'AM';
        let hoursFrom = dateFrom.getHours() > 12 ? dateFrom.getHours() - 12 + '' : dateFrom.getHours() + '';
        hoursFrom = parseInt(hoursFrom) < 10 ? '0' + hoursFrom : hoursFrom;
        let minutesFrom = dateFrom.getMinutes() < 10 ? '0' + dateFrom.getMinutes() : dateFrom.getMinutes();
        let secondsFrom = dateFrom.getSeconds() < 10 ? '0' + dateFrom.getSeconds() : dateFrom.getSeconds();

        let rulesArrayFormat = [
            {
                id: 1,
                parentid: null,
                isanyall: true,
                anyall: 'ALL',
                field: null,
                operatr: null,
                value: null,
                maskedvalue: null,
                expression: 'ALL',
                inputtype: null,
                validrule: true,
                mandatory: false,
                filterbyfield: null,
                visible: true,
                sqldatatype: null,
                sqlalias: null,
                sqlcolumnmap: null,
                sqlfromvalue: false,
                utcvalue: null
            },
            {
                id: 2,
                parentid: 1,
                isanyall: false,
                anyall: null,
                field: 'Location',
                operatr: this.dashlet.locationId === '=all=' ? 'ALL' : '==',
                value: this.dashlet.locationId === '=all=' ? 'ALL' : this.dashlet.locationId,
                maskedvalue: null,
                expression: `Location equal ${this.dashlet.locationId === '=all=' ? 'ALL' : this.dashlet.location}`,
                inputtype: this.dashlet.locationId === '=all=' ? null : 'DropDownList',
                validrule: this.dashlet.locationId === '=all=' ? true : true,
                mandatory: true,
                filterbyfield: null,
                visible: true,
                sqldatatype: this.dashlet.locationId === '=all=' ? 'Guid' : 'Guid',
                sqlalias: this.dashlet.locationId === '=all=' ? 'h' : 'h',
                sqlcolumnmap: this.dashlet.locationId === '=all=' ? 'LocationId' : 'LocationId',
                sqlfromvalue: false,
                utcvalue: null
            },
            {
                id: 3,
                parentid: 1,
                isanyall: false,
                anyall: null,
                field: 'DateFrom',
                operatr: '>=',
                value: `${dayFrom} ${monthFrom} ${yearFrom} ${hoursFrom}:${minutesFrom}:${secondsFrom} ${suffixFrom}`,
                maskedvalue: null,
                expression: `Date From greater than or equal ${dayFrom} ${monthFrom} ${yearFrom} ${hoursFrom}:${minutesFrom}:${secondsFrom} ${suffixFrom}`,
                inputtype: 'DateTime',
                validrule: true,
                mandatory: true,
                filterbyfield: null,
                visible: true,
                sqldatatype: 'DateTime',
                sqlalias: 'h',
                sqlcolumnmap: 'UTCDateTime',
                sqlfromvalue: false,
                utcvalue: null
            },
            {
                id: 4,
                parentid: 1,
                isanyall: false,
                anyall: null,
                field: 'DateTo',
                operatr: '<=',
                value: `${dayTo} ${monthTo} ${yearTo} ${hoursTo}:${minutesTo}:${secondsTo} ${suffixTo}`,
                maskedvalue: null,
                expression: `Date To less than or equal ${dayTo} ${monthTo} ${yearTo} ${hoursTo}:${minutesTo}:${secondsTo} ${suffixTo}`,
                inputtype: 'DateTime',
                validrule: true,
                mandatory: true,
                filterbyfield: null,
                visible: true,
                sqldatatype: 'DateTime',
                sqlalias: 'h',
                sqlcolumnmap: 'UTCDateTime',
                sqlfromvalue: false,
                utcvalue: null
            }
        ];

        return rulesArrayFormat;
    }

    buildRulesArrayFormatWithMos(mosCategory: string) {
        let rulesArrayFormat = this.buildRulesArrayFormat();
        let mosMin = 0.0;
        let mosMax = 9.9;
        let greaterThan = true;
        let lessThan = true;
        let lessThanNotInclusive = false;

        if (this.dashlet.equipmentId !== '=all=' && this.dashlet.locationId !== '=all=') {
            rulesArrayFormat.push({
                id: 7,
                parentid: 1,
                isanyall: false,
                anyall: null,
                field: 'Equipment',
                operatr: '==',
                value: this.dashlet.equipmentId,
                maskedvalue: null,
                expression: `RTCP Receiver Name equal ${this.dashlet.equipment}`,
                inputtype: 'DropDownList',
                validrule: true,
                mandatory: false,
                filterbyfield: null,
                visible: true,
                sqldatatype: 'Guid',
                sqlalias: 'h',
                sqlcolumnmap: 'EquipmentId',
                sqlfromvalue: false,
                utcvalue: null
            });
        }

        switch (mosCategory) {
            case 'none': // no Mos
                return rulesArrayFormat;
            case '< 3.6': // extra category (< 3.6)
                mosMin = 0.0;
                greaterThan = false;
                lessThan = false;
                lessThanNotInclusive = true;
                mosMax = 3.6;
                break;
            case '1.0 - 2.6': // Mos category 1.0-2.6
                mosMin = 1.0;
                mosMax = 2.6;
                break;
            case '2.6 - 3.1': // Mos category 2.6 - 3.1
                mosMin = 2.6;
                mosMax = 3.1;
                break;
            case '3.1 - 3.6': // Mos category 3.1 - 3.6
                mosMin = 3.1;
                mosMax = 3.6;
                break;
            case '3.6 - 4.0': // Mos category 3.6 - 4.0
                mosMin = 3.6;
                greaterThan = true;
                lessThan = false;
                lessThanNotInclusive = true;
                mosMax = 4.0;
                break;
            case '4.0 - 4.3': // Mos category 4.0 - 4.3
                mosMin = 4.0;
                mosMax = 4.3;
                break;
            case '> 4.3': // Mos category 4.3+
                mosMin = 4.3;
                lessThan = false;
                mosMax = 9.9;
                break;
            case '>= 4.0': // extra category (> 4.0)
                mosMin = 4.0;
                lessThan = false;
                mosMax = 9.9;
                break;
            default:
                return rulesArrayFormat;
        }

        if (lessThan) {
            rulesArrayFormat.push({
                id: 5,
                parentid: 1,
                isanyall: false,
                anyall: null,
                field: 'MosMin',
                operatr: '<=',
                value: mosMax,
                maskedvalue: null,
                expression: `Mos Minimum less than or equal ${mosMax}`,
                inputtype: 'NumberTextBox',
                validrule: true,
                mandatory: false,
                filterbyfield: null,
                visible: true,
                sqldatatype: 'Numeric',
                sqlalias: 'sm',
                sqlcolumnmap: 'MosMinAcrossAllStreams',
                sqlfromvalue: false,
                utcvalue: null
            });
        }

        if (lessThanNotInclusive) {
            rulesArrayFormat.push({
                id: 5,
                parentid: 1,
                isanyall: false,
                anyall: null,
                field: 'MosMin',
                operatr: '<',
                value: mosMax,
                maskedvalue: null,
                expression: `Mos Minimum less than ${mosMax}`,
                inputtype: 'NumberTextBox',
                validrule: true,
                mandatory: false,
                filterbyfield: null,
                visible: true,
                sqldatatype: 'Numeric',
                sqlalias: 'sm',
                sqlcolumnmap: 'MosMinAcrossAllStreams',
                sqlfromvalue: false,
                utcvalue: null
            });
        }

        if (greaterThan) {
            rulesArrayFormat.push({
                id: 6,
                parentid: 1,
                isanyall: false,
                anyall: null,
                field: 'MosMin',
                operatr: '>=',
                value: mosMin,
                maskedvalue: null,
                expression: `Mos Minimum greater than or equal ${mosMin}`,
                inputtype: 'NumberTextBox',
                validrule: true,
                mandatory: false,
                filterbyfield: null,
                visible: true,
                sqldatatype: 'Numeric',
                sqlalias: 'sm',
                sqlcolumnmap: 'MosMinAcrossAllStreams',
                sqlfromvalue: false,
                utcvalue: null
            });
        }

        return rulesArrayFormat;
    }

    buildRulesArrayByTypeCategory(typeCategory: string, typeCategoryValue: number) {
        let rulesArrayFormat = this.buildRulesArrayFormat();

        if (this.dashlet.equipmentId !== '=all=' && this.dashlet.locationId !== '=all=') {
            rulesArrayFormat.push({
                id: 7,
                parentid: 1,
                isanyall: false,
                anyall: null,
                field: 'Equipment',
                operatr: '==',
                value: this.dashlet.equipmentId,
                maskedvalue: null,
                expression: `RTCP Receiver Name equal ${this.dashlet.equipment}`,
                inputtype: 'DropDownList',
                validrule: true,
                mandatory: false,
                filterbyfield: null,
                visible: true,
                sqldatatype: 'Guid',
                sqlalias: 'h',
                sqlcolumnmap: 'EquipmentId',
                sqlfromvalue: false,
                utcvalue: null
            });
        }

        rulesArrayFormat.push({
            id: 5,
            parentid: 1,
            isanyall: false,
            anyall: null,
            field: typeCategory, //either dscp, ipnr, codec
            operatr: '==',
            value: typeCategoryValue,
            maskedvalue: null,
            expression: `${typeCategory} equal ${typeCategoryValue}`,
            inputtype: 'NumberTextBox',
            validrule: true,
            mandatory: false,
            filterbyfield: null,
            visible: true,
            sqldatatype: 'Numeric',
            sqlalias: 'sm',
            sqlcolumnmap: typeCategory,
            sqlfromvalue: false,
            utcvalue: null
        });

        return rulesArrayFormat;
    }

    //#region

    get smallLayout() {
        return this.dashlet.getSize().id === 0;
    }

    get largeLayout() {
        return this.dashlet.getSize().id === 2;
    }

    get hugeLayout() {
        return this.dashlet.getSize().id === 3;
    }

    getCapacityUnsubscribedText() {
        return this.dashletService.getCapacityUnsubscribedText();
    }

    openThresholdAlerts(elementId: string, commandTypeId: string) {
        if (this.largeLayout || this.hugeLayout) {
            this.manageThreshold = true;
        } else {
            this.dashlet.displayHeader = false;
            this.openSubcontainer = 'createThreshold';
        }

        this.selectedThreshold = new Threshold();
        this.selectedThreshold.commandTypeId = commandTypeId;
        this.selectedThreshold.field = 'MOS ' + elementId;
        this.selectedThreshold.whereCreated = 'Dashboard';

        let uniqueAndSortedIpnrs = Array.from(this.dashlet.ipnrSet).sort((x, y) => +x - +y);
        uniqueAndSortedIpnrs.unshift('All');
        this.selectedThreshold.validFields = new Map();
        this.selectedThreshold.validFields.set(elementId, elementId);
        this.selectedThreshold.validFields.set('ipnr', uniqueAndSortedIpnrs);
        this.selectedThreshold.additionalRuleGroup = {
            groupId: '2',
            rules: [{ fieldName: 'IPNR', ruleOperator: '==', ruleValue: 'All' }]
        };
    }

    closeDetails() {
        this.dashlet.displayHeader = true;
        this.openSubcontainer = null;

        if (this.largeLayout || this.hugeLayout) {
            this.manageThreshold = false;
        }
    }

    onSubmit() {
        this.thresholdItem
            .onSubmit()
            .pipe(take(1))
            .subscribe(result => {
                if (result) {
                    this.selectedThreshold.dapThresholdId = result['DapThresholdId'];
                    this.dashlet.thresholds.push(this.selectedThreshold);

                    const category = this.thresholdService
                        .generateVqmSummaryField(this.selectedThreshold.field)
                        .replace('_Percentage', '');
                    this.dashlet[`${category}Thresholds`].push(this.selectedThreshold);

                    this.tileGridService.thresholdCreated(
                        this.selectedThreshold.name + ' threshold added via ' + this.dashlet.title
                    );
                } else {
                    this.tileGridService.thresholdCreated('Threshold add failed');
                }
            });
        this.closeDetails();
    }

    saveEdit() {
        this.thresholdItem
            .saveEdit()
            .pipe(take(1))
            .subscribe(result => {
                if (result) {
                    this.tileGridService.thresholdCreated(
                        this.selectedThreshold.name + ' threshold edited via ' + this.dashlet.title
                    );
                } else {
                    this.tileGridService.thresholdCreated('Threshold edit failed');
                }
            });
        this.closeDetails();
    }

    openAlarmIconThreshold() {
        this.thresholdService.openThresholdManagement(this.accountService.getUserDetails().EntityId);
    }

    public cmsFilterOnChangeByType($event): void {
        this.showCMSDataTable = false;
        this.cdr.detectChanges();
        this.selectedCMSFilter = $event.value;
        if (this.selectedCMSFilter === 'Agent') {
            this.callCenterData = this.CMSAgentDataRaw;
            this.callCenterColumns[0] = {
                columnDef: 'AgentName',
                header: 'Agent',
                filterType: 'text',
                cell: (element: any): string => `${element.AgentName}`
            };
        } else if (this.selectedCMSFilter === 'VDN') {
            this.callCenterData = this.CMSVDNDataRaw;
            this.callCenterColumns[0] = {
                columnDef: 'Vdn',
                header: 'VDN',
                filterType: 'text',
                cell: (element: any): string => `${element.Vdn}`,
                type: 'numeric'
            };
        } else {
            this.callCenterData = this.CMSSkillDataRaw;
            this.callCenterColumns[0] = {
                columnDef: 'Skill',
                header: 'Skill',
                filterType: 'text',
                cell: (element: any): string => `${element.Skill}`,
                type: 'numeric'
            };
        }
        this.callCenterData = [...this.callCenterData];
        this.callCenterColumns = [...this.callCenterColumns];
        this.showCMSDataTable = true;
        this.cdr.detectChanges();
    }

    //#endregion
}
