import { environment } from '@environments/environment';
import { Dashlet } from '@models/index';
import { RealtimeGatewayService } from '@services/index';
import { Subject } from 'rxjs';

interface Point {
    x: Date;
    y: number;
}

export class DashletTotalPortsByType extends Dashlet {
    public _commandTypeIdPort = '205B3174-BB66-42C1-934D-1ABC4A6D44B2';
    public _equipmentId = '445bb159-5bae-42ce-b568-58d84617bdfc';

    groupName: string;

    private _portsPerTypeArrayBillable: Point[] = [];
    private _portsPerTypeArrayNonBillable: Point[] = [];

    private _portsLatestBillable = 0;
    private _portsLatestNonBillable = 0;

    private _chartDataUpdated: Subject<null> = new Subject();
    get onChartDataUpdated() {
        return this._chartDataUpdated;
    }

    constructor(private realtimeService: RealtimeGatewayService) {
        super();
        //sizing
        this.sizes = [
            {
                id: 0,
                label: 'Small',
                cols: 5,
                rows: 2
            },
            {
                id: 1,
                label: 'Medium',
                cols: 5,
                rows: 5
            },
            {
                id: 2,
                label: 'Large',
                cols: 10,
                rows: 5
            }
        ];
        this.applySize(1);

        //init data
        this.resetData();
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);
        this.configured = true;
    }

    applySize(id: number) {
        super.applySize(id);
        this.updateSize();
    }

    private updateSize() {
        let h = 0;
        let w = 0;
        this.applySizeExpansion(w, h);
    }

    public processPortData(data: any[]) {
        this.resetData();
        const results = data[0].data;
        if (results !== null) {
            let startDate = new Date(); //sets to current date

            startDate.setDate(startDate.getDate() - 40); //date we want to start at
            let portsPerTypeBillable = null;
            let portsPerTypeNonBillable = null;

            results.forEach(row => {
                //Convert date from RTG to Date object format. RTG date is in US format
                let periodStart = new Date();
                let dateSplit = row.PeriodStart.split('/', 3);
                periodStart.setDate(dateSplit[1]);
                periodStart.setMonth(dateSplit[0] - 1);
                dateSplit = dateSplit[2].split(' ', 1);
                periodStart.setFullYear(dateSplit[0]);

                if (startDate < periodStart) {
                    portsPerTypeBillable = row.ChargeablePorts;
                    portsPerTypeNonBillable = row.NonChargeablePorts;
                    this._portsPerTypeArrayBillable.push({ x: periodStart, y: portsPerTypeBillable });
                    this._portsPerTypeArrayNonBillable.push({ x: periodStart, y: portsPerTypeNonBillable });
                    //Get latest value for each region unless it's null
                    this._portsLatestBillable = portsPerTypeBillable ? portsPerTypeBillable : this._portsLatestBillable;
                    this._portsLatestNonBillable = portsPerTypeNonBillable
                        ? portsPerTypeNonBillable
                        : this._portsLatestNonBillable;
                    //reset values for new day
                    portsPerTypeBillable = null;
                    portsPerTypeNonBillable = null;
                }
            });
        } else {
            if (!environment.production) console.error('RTG CONNECTION ERROR');
        }

        this._chartDataUpdated.next(null);
    }

    resetData() {
        this._portsPerTypeArrayBillable = [];
        this._portsPerTypeArrayNonBillable = [];
        this._portsLatestNonBillable = 0;
        this._portsLatestBillable = 0;
        this._chartDataUpdated.next(null);
    }

    dispose() {
        this.unsubData();
    }
    private unsubData() {
        if (this._equipmentId) {
            //unsub data
            this.realtimeService.unsubscribe(this._equipmentId, this._commandTypeIdPort);
        }
    }

    get portsLatestNonBillable(): number {
        return this._portsLatestNonBillable;
    }
    get portsLatestBillable(): number {
        return this._portsLatestBillable;
    }
    get portsPerTypeArrayBillable(): Point[] {
        return this._portsPerTypeArrayBillable;
    }
    get portsPerTypeArrayNonBillable(): Point[] {
        return this._portsPerTypeArrayNonBillable;
    }
}
