import { createAction, props } from '@ngrx/store';

export const GetProcessThresholdData = createAction(
  '[ProcessThresholdData] Get ProcessThresholdData',
  props<{ equipmentId: string}>()
);

export const GetProcessThresholdDataSuccess = createAction(
  '[ProcessThresholdData] Get ProcessThresholdData Success',
  props<{ data:any }>()
);

export const GetProcessThresholdDataFailure = createAction(
  '[ProcessThresholdData] Get ProcessThresholdData Failure',
  props<{ error: any }>()
);
