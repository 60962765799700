<div class="flex-column gap-l">
    <div class="flex-column gap-m">
        <mat-radio-group aria-labelledby="radio-group-label" class="flex gap-l" color="primary" [(ngModel)]="showChart">
            <mat-radio-button *ngFor="let b of chartButtons; let i = index" [value]="i">
                {{ b.label }}<sup *ngIf="b.label === 'Created'"><mat-icon matTooltip="New alarms created in the past 24 hours by hour created">question_mark</mat-icon></sup>
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <ng-container *ngTemplateOutlet="chart"></ng-container>

    <div *ngIf="largeLayout">
        <ng-container *ngTemplateOutlet="table"></ng-container>
    </div>
</div>

<ng-template #chart>
    <div class="chart-section flex-column center">
        <ng-container *ngIf="showChart === 0">
        <ng-container *ngIf="alarmChartData; else vsmLoadingTemplate">

                <p-chart  type="bar" [data]="alarmChartData.data" [options]="alarmChartOptions" [width]="'37.25rem'" [height]="'290px'"> </p-chart>
                <div class="grow center">
                    <div class="grid-3-columns column-gap-xl">
                        <div class="flex-column" *ngFor="let l of equipmentLegend">
                            <div class="metric mat-caption" [ngStyle]="{ 'border-left-color': l.colour }">
                                {{ l.label }}
                            </div>
                        </div>
                        <div class="flex-column" *ngFor="let l of alarmLegend">
                            <div class="metric mat-caption" [ngStyle]="{ 'border-left-color': l.colour }" [matTooltip]="l.title">Resolved</div>
                            <div class="metric mat-h2">{{ l.label }} - {{ alarmChartData[l.label] }}</div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="showChart === 1">
            <ng-container *ngIf="equipmentChartData; else vsmLoadingTemplate">
                <p-chart  type="bar" [data]="equipmentChartData.data" [options]="alarmChartOptions" [width]="'37.25rem'" [height]="'290px'"> </p-chart>
                <div class="grow center">
                    <div class="grid-3-columns column-gap-xl">
                        <div class="flex-column" *ngFor="let l of equipmentLegend">
                            <div class="metric mat-caption" [ngStyle]="{ 'border-left-color': l.colour }">
                                {{ l.label }}
                            </div>
                        </div>
                        <div class="flex-column" *ngFor="let l of alarmLegend">
                            <div class="metric mat-caption" [ngStyle]="{ 'border-left-color': l.colour }">Resolved</div>
                            <div class="metric mat-h2" >
                                {{ l.label }} - {{ equipmentChartData[l.label] }}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="showChart === 2">
            <ng-container *ngIf="timeChartData; else vsmLoadingTemplate">
                <dashlet-line-graph [data]="timeChartData.data" [options]="timeChartOptions" [width]="'37.25rem'" [height]="'290px'"> </dashlet-line-graph>
                <div class="grow center">
                    <div class="grid-3-columns column-gap-xl">
                        <div class="flex-column" *ngFor="let l of equipmentLegend">
                            <div class="metric mat-caption" [ngStyle]="{ 'border-left-color': l.colour }">
                                {{ l.label }}
                            </div>
                        </div>
                        <div class="flex-column" *ngFor="let l of alarmLegend">
                            <div class="metric mat-caption" [ngStyle]="{ 'border-left-color': l.colour }">Resolved</div>
                            <div class="metric mat-h2" >
                                {{ l.label }} - {{ timeChartData[l.label] }}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>


    </div>
</ng-template>
<ng-template #table>
    <div *ngIf="largeLayout">
        <dashlet-section-header> 24 Hour Summary </dashlet-section-header>
        <div class="flex-column gap-m">
            <label class="mat-caption" id="radio-group-label">Group by</label>
            <mat-radio-group aria-labelledby="radio-group-label" class="flex gap-l" color="primary" [(ngModel)]="showEquipmentTable">
                <mat-radio-button [value]="false"> Alarm </mat-radio-button>
                <mat-radio-button [value]="true"> Equipment </mat-radio-button>
            </mat-radio-group>
        </div>

        <!--Didn't use structural directive due to performance issues.-->
        <dashlet-table
                [dataSource]="showEquipmentTable ? equipmentTableData : alarmTableData"
                [columns]="showEquipmentTable ? equipmentColumns : alarmColumns"
                [pagination]="true"
                [pageLength]="10"
                [tableHeightRows]="10"
                [loading]="alarmTableLoading"
                [rowExpansionColumns]="showEquipmentTable ? equipmentExpansionColumns : alarmExpansionColumns"
                rowExpansionSortField="count"
                rowExpansionSortDirection="desc"
                [rowExpansionHeight]="10"
                [rowExpansionField] ="showEquipmentTable ? 'alarms' : 'expansion'"
                sortField="count"
                sortDirection="desc"
            ></dashlet-table>
    </div>
</ng-template>

<!-- loading template -->
<ng-template #vsmLoadingTemplate>
        <vsm-dashlet-loading></vsm-dashlet-loading>
</ng-template>

<!-- no data template -->
<ng-template #noDataTemplate>
    <div class="dashlet-no-data-text">No Alarm Data Found</div>
</ng-template>
