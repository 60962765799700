import * as RtcpReceiversActions from '@actions/get-rtcp-receivers/get-rtcp-receivers.actions';
import { createReducer, on } from '@ngrx/store';

import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const rtcpReceiversFeatureKey = 'rtcpReceivers';

interface RtcpReceiversEntityState extends EntityState<any> {}

export interface State {
    rtcpReceivers: RtcpReceiversEntityState;
}

function selectEntityId(a: any): string {
    return a.locationId;
}

const adapterRtcpReceivers = createEntityAdapter<any>({
    selectId: selectEntityId
});

const RtcpReceiversInitState: RtcpReceiversEntityState = adapterRtcpReceivers.getInitialState();

export const initialState: State = {
    rtcpReceivers: RtcpReceiversInitState
};

export const reducer = createReducer(
    initialState,
    on(RtcpReceiversActions.GetRtcpReceiversSuccess, (state, action) => ({
        ...state,
        rtcpReceivers: adapterRtcpReceivers.addOne(
            { ...action.data, locationId: action.locationId },
            state.rtcpReceivers
        )
    }))
);
