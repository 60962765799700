import * as MosCapableReceiversActions from '@actions/get-mos-capable-receivers/get-mos-capable-receivers.actions';
import { createReducer, on } from '@ngrx/store';

import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const mosCapableReceiversFeatureKey = 'mosCapableReceivers';

interface MosCapableReceiversEntityState extends EntityState<any> {}

export interface State {
    mosCapableReceivers: MosCapableReceiversEntityState;
}

function selectEntityId(a: any): string {
    return a.locationId;
}

const adapterMosCapableReceivers = createEntityAdapter<any>({
    selectId: selectEntityId
});

const MosCapableReceiversInitState: MosCapableReceiversEntityState = adapterMosCapableReceivers.getInitialState();

export const initialState: State = {
    mosCapableReceivers: MosCapableReceiversInitState
};

export const reducer = createReducer(
    initialState,
    on(MosCapableReceiversActions.GetMosCapableReceiversSuccess, (state, action) => ({
        ...state,
        mosCapableReceivers: adapterMosCapableReceivers.addOne(
            { ...action.data, locationId: action.locationId },
            state.mosCapableReceivers
        )
    }))
);
