import * as HistoricCpuDataActions from '@actions/index';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService, TimeoutService } from '@services/index';
import { RealTimeNotify } from '@models/index';
import {
  catchError,
  map,
  switchMap,
} from 'rxjs/operators';



@Injectable()
export class HistoricCpuDataEffects {

  constructor(private actions$: Actions, private realtimeGatewayService: RealtimeGatewayService) { }

  getHistoricCpuData$ = createEffect(() => this.actions$.pipe(
    ofType(HistoricCpuDataActions.GetHistoricCpuData),
    switchMap((action) => this.realtimeGatewayService.requestHistoricDocument(action.equipmentId, action.commandTypeId, action.from, action.to, action.max).pipe(
      map((data) => HistoricCpuDataActions.GetHistoricCpuDataSuccess({ data:{ data, equipmentId: action.equipmentId} })),
      catchError((err) => of(HistoricCpuDataActions.GetHistoricCpuDataFailure({ error: err })))
    ))
  ));
}
