import * as ProcessAdminBackupDataActions from '@actions/process-admin-backup-data/process-admin-backup-data.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService, TimeoutService } from '@services/index';
import { RealTimeNotify } from '@models/index';

@Injectable()
export class ProcessAdminBackupDataEffects {
    constructor(private actions$: Actions, private realtimeGatewayService: RealtimeGatewayService) {}

    getNotifyProcessAdminBackupData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessAdminBackupDataActions.GetNotifyProcessAdminBackupData),
            switchMap(action =>
                this.realtimeGatewayService.observe(action.equipmentId, action.commandTypeId).pipe(
                    catchError(err =>
                        of(ProcessAdminBackupDataActions.GetNotifyProcessAdminBackupDataFailure({ error: err }))
                    ),
                    mergeMap((data: RealTimeNotify) => [
                        ProcessAdminBackupDataActions.GetNotifyProcessAdminBackupDataSuccess({ data }),
                        ProcessAdminBackupDataActions.GetProcessAdminBackupData({ ...data })
                    ])
                )
            )
        )
    );

    getProcessAdminBackupData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessAdminBackupDataActions.GetProcessAdminBackupData),
            mergeMap(action =>
                this.realtimeGatewayService.observe(action.equipmentId, action.commandTypeId).pipe(
                    map(data =>
                        ProcessAdminBackupDataActions.GetProcessAdminBackupDataSuccess({
                            data: { data, equipmentId: action.equipmentId }
                        })
                    ),
                    catchError(err =>
                        of(ProcessAdminBackupDataActions.GetProcessAdminBackupDataFailure({ error: err }))
                    )
                )
            )
        )
    );

    getHistoricProcessAdminBackupData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessAdminBackupDataActions.GetHistoricProcessAdminBackupData),
            switchMap(action =>
                this.realtimeGatewayService
                    .requestHistoricDocument(
                        action.equipmentId,
                        action.commandTypeId,
                        new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                        new Date().toISOString(),
                        20
                    )
                    .pipe(
                        map(data =>
                            ProcessAdminBackupDataActions.GetHistoricProcessAdminBackupDataSuccess({
                                data: { data, equipmentId: action.equipmentId }
                            })
                        ),
                        catchError(err =>
                            of(ProcessAdminBackupDataActions.GetHistoricProcessAdminBackupDataFailure({ error: err }))
                        )
                    )
            )
        )
    );
}
