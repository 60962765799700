import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { DashletSettingDropdownGroupedList, SelectItem, SelectItemGroup } from '@models/index';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import {
    CustomerModel,
    Dashlet,
    DashletType,
    SettingsSubject,
    DashletSetting,
    DashletSettingDropdown,
    DashletSettingDropdownGrouped,
    DashletSettingText,
    DashletSettingCheckbox,
    DashletSettingList,
    DashletSettingsForm,
    DashletSettingsFormDashboard,
    DashletSettingsFormExample1,
    DashletSettingsFormTrunkGroupUtilization,
    DashletSettingsFormVoiceQuality,
    DashletSettingsFormSystemHealthIpOffice,
    DashletSettingsFormSystemHealthOracle,
    DashletSettingsFormTrunkGroupList,
    DashletSettingsFormSystemHealthLinuxServer,
    DashletSettingsFormSystemHealthWindowsServer,
    DashletSettingsFormSystemHealthAcm,
    DashletSettingsFormSystemHealthASM,
    DashletSettingsFormSystemHealthAEP,
    DashletSettingsFormSystemHealthAES,
    DashletSettingsFormSystemHealthCMS,
    DashletSettingsFormSystemHealthSBC,
    DashletSettingsFormNetworkConnectivity,
    DashletSettingsFormNetworkConnectivitySummary,
    DashletSettingsFormIPNRStatus,
    DashletSettingsFormIPNREndpoints,
    DashletSettingsFormAlarmsSummary,
    DashletSettingsFormPortsPerCustomer,
    DashletSettingsFormPortsByRegion,
    DashletSettingsFormSIPBandwidth,
    DashletSettingsFormPortsByType,
    DashletSettingsFormCustomersByRegion,
    DashletSettingsFormDatabaseHealth,
    DashletSettingsFormVQMDailySummary,
    DashletSettingsFormSystemHealthCiscoCUBE,
    DashletSettingsFormSystemHealthAudioCodes,
    DashletSettingsFormCiscoDialPeerSummary,
    DashletSettingsFormSystemHealthSummary,
    DashletSettingsFormServiceGatewayInstancesInformation,
    DashletSettingsFormEndpointDataSummary,
    DashletSettingsFormBusinessPartnerVQMSummary,
    DashletSettingsFormIPNRBandwidth,
    DashletSettingsFormACMSystemHealth,
    DashletSettingsFormSIPResponseSummary,
    DashletSettingsFormAlarmOverview,
    DashletSettingsFormRibbonSBCEdge,
    DashletSettingsFormSystemHealthVmware,
    DashletSettingsFormSystemHealthCiscoPresence,
    DashletSettingsFormRibbonSystemHealth,
    DashletSettingsFormTrunkGroupSummary,
    DashletSettingsFormSystemHealthAvayaContact,
    DashletSettingsFormSystemHealthEngelbartSoftwareEsuites,
    DashletSettingsFormSystemHealthCiscoUCM,
    DashletSettingsFormSystemHealthCiscoUnityConnection,
    DashletSettingsFormMSTeamsCallSummary,
    DashletSettingsFormGenesysTrunksSummary,
    DashletSettingsFormGenesysDetailsSummary,
    DashletSettingsFormMSTeamsRoomsHealthSummary
} from '@models/index';

import { DashletSettingDropdownComponent } from '../../modules/dashboard/components/settings/dashlet-setting-dropdown.component';
import { DashletSettingTextComponent } from '../../modules/dashboard/components/settings/dashlet-setting-text.component';
import { DashletSettingCheckboxComponent } from '../../modules/dashboard/components/settings/dashlet-setting-checkbox.component';
import { DashletSettingDropdownGroupedComponent } from '../../modules/dashboard/components/settings/dashlet-setting-dropdown-grouped.component';
import { DashletSettingDropdownGroupedListComponent } from '@modules/dashboard/components/settings/dashlet-setting-dropdown-grouped-list.component';
import { DashletSettingListComponent } from '../../modules/dashboard/components/settings/dashlet-setting-list.component';

import { AccountService, NotificationService } from '@services/index';
import { SystemHealthInfo } from '@models/dataObjects/interfaces/CentralApi.models';
import { MicrosoftTeamsSiteNames } from '@models/dataObjects/interfaces/MSTeamsData.model';

@Injectable({
    providedIn: 'root'
})
export class DashletSettingsService {
    private subjects: SettingsSubject[] = [];

    constructor(
        protected http: HttpClient,
        private readonly accountService: AccountService,
        private readonly notificationService: NotificationService
    ) {}

    public initSettings(dashletId: string): void {
        let subject = this.subjects.find(element => element.dashletId === dashletId);

        if (!subject) {
            subject = new SettingsSubject(dashletId);
            this.subjects.push(subject);
        }
    }

    public getRtcpReceivers(locationId: string): Observable<SelectItem[]> {
        return this.http.get<any[]>(environment.centralApi + `Equipment/${locationId}/MosCapable`).pipe(
            map(recievers => {
                return recievers
                    .filter(reciever => reciever.equipmentType === 'RTCPReceiver')
                    .map(rtcpReceiver => ({ label: rtcpReceiver.equipmentName, value: rtcpReceiver.equipmentId }));
            })
        );
    }

    public getMosCapableReceivers(locationId: string): Observable<SelectItem[]> {
        return this.http.get<any[]>(environment.centralApi + `Equipment/${locationId}/MosCapable`).pipe(
            map(recievers => {
                return recievers.map(receiver => ({
                    label: receiver.equipmentName,
                    value: receiver.equipmentId
                }));
            })
        );
    }

    public getEquipment(equipmentId: string, type: string): Observable<SelectItem[]> {
        return this.http.get<SelectItem[]>(environment.centralApi + `equipment/${equipmentId}/${type}`).pipe(
            map((resp: any) => {
                let temp = [];
                resp.forEach((item: any) => {
                    temp.push({
                        label: item.equipmentName,
                        value: item.equipmentId
                    });
                });
                return temp;
            })
        );
    }

    public getServerName(customerId: string, locationId: string, equipmentId: string): Observable<string> {
        return this.http.get<string>(environment.centralApi + `Equipment/${customerId}/${equipmentId}/OSSystemInfo`);
    }

    public getTypeEquipmentsAtLocation(locationId: string, type: any): Observable<SelectItem[]> {
        return this.http.get<SelectItem[]>(environment.centralApi + `Equipment/${locationId}/${type}`);
    }
    public getLocations(customerId: string): Observable<SelectItem[]> {
        return this.http.get<SelectItem[]>(environment.centralApi + `Location/${customerId}`).pipe(
            map((resp: any) => {
                let temp = [];
                resp.forEach((item: any) => {
                    temp.push({
                        label: item.locationName,
                        value: item.locationId
                    });
                });
                return temp;
            })
        );
    }

    public getVirtualLocations(customerId: string): Observable<SelectItem[]> {
        return this.http.get<SelectItem[]>(environment.centralApi + `Location/${customerId}`).pipe(
            map((resp: any) => {
                const temp = resp
                    .filter(item => item.applianceType === 'Virtual')
                    .map((item: any) => {
                        return {
                            label: item.locationName,
                            value: item.locationId
                        };
                    });
                return temp;
            })
        );
    }

    public getBPAssociatedCustomers(businessPartnerId: string): Observable<SelectItem[]> {
        return this.http.get<SelectItem[]>(environment.centralApi + `Customer/${businessPartnerId}/Partners`);
    }

    async getMosCapableReceiversCount(locations: SelectItem[]): Promise<number> {
        let countAll = 0;
        let countPass = 0;

        return new Promise<number>(resolve => {
            locations.forEach(element => {
                let data = this.http.get<SelectItem[]>(
                    environment.centralApi + `Equipment/${element.value}/MosCapable`
                );

                data.subscribe(item => {
                    countPass++;
                    countAll += item.length;
                    if (countPass === locations.length) {
                        resolve(countAll);
                    }
                });
            });
        });
    }

    public getSipEquipment(locationId: string): Observable<SelectItemGroup[]> {
        return this.http.get<any[]>(environment.centralApi + `Equipment/${locationId}/SipEquipmentInfo`).pipe(
            map(equipment => {
                return equipment.reduce<SelectItemGroup[]>((acc, cur) => {
                    let g = acc.find(i => i.label === cur.productName);
                    if (!g) {
                        g = new SelectItemGroup();
                        g.label = cur.productName;
                        g.items = [];
                        acc.push(g);
                    }
                    const s = new SelectItem();
                    s.label = cur.equipmentName;
                    s.value = cur.equipmentId;
                    g.items.push(s);
                    return acc;
                }, []);
            })
        );
    }

    public getSystemHealthEquipment(locationId: string): Observable<SelectItemGroup[]> {
        return this.http.get<any[]>(environment.centralApi + `Equipment/${locationId}/SystemHealthInfo`).pipe(
            map(equipment => {
                return equipment.reduce<SelectItemGroup[]>((acc, cur) => {
                    let g = acc.find(i => i.label === cur.productName);
                    if (!g) {
                        g = new SelectItemGroup();
                        g.label = cur.productName;
                        g.items = [];
                        acc.push(g);
                    }
                    const s = new SelectItem();
                    s.label = cur.equipmentName;
                    s.value = cur.equipmentId;
                    g.items.push(s);
                    return acc;
                }, []);
            })
        );
    }

    public getAcmAsmEquipmentByLocation(locationId: string): Observable<SelectItem[]> {
        return this.http.get<SelectItem[]>(
            environment.centralApi + `Equipment/${locationId}/AcmAsmEquipmentByLocation`
        );
    }

    public getRibbonSystemHealthEquipment(locationId: string): Observable<SelectItemGroup[]> {
        return this.http.get<any[]>(environment.centralApi + `Equipment/${locationId}/RibbonSystemHealthInfo`).pipe(
            map(equipment => {
                return equipment.reduce<SelectItemGroup[]>((acc, cur) => {
                    let g = acc.find(i => i.label === cur.productName);
                    if (!g) {
                        g = new SelectItemGroup();
                        g.label = cur.productName;
                        g.items = [];
                        acc.push(g);
                    }
                    const s = new SelectItem();
                    s.label = cur.equipmentName;
                    s.value = cur.equipmentId;
                    g.items.push(s);
                    return acc;
                }, []);
            })
        );
    }

    public createDashboardSettingsForm(): DashletSettingsForm {
        return new DashletSettingsFormDashboard();
    }

    public getUserAssociatedCustomers(): Observable<SelectItem[]> {
        return this.http.get<SelectItem[]>(environment.centralApi + `Customer`).pipe(
            map(res => {
                const temp = [];
                res.forEach((item: any) => {
                    temp.push({
                        label: item.name,
                        value: item.customerId
                    });
                });
                return temp.sort((a, b) => a.label.localeCompare(b.label));
            })
        );
    }
    public getAllCustomers(): Observable<CustomerModel[]> {
        return this.http.get<CustomerModel[]>(environment.centralApi + `Customer`);
    }

    public getIPNRsByEquipment(customerId: string, equipmentId: string): Observable<SelectItem[]> {
        const params = { customerId: customerId, equipmentId: equipmentId };
        return this.http.get<SelectItem[]>('API/DashboardSettingsNg/getIPNRsByEquipment', {
            params: params
        });
    }
    public getTrunkGroups(equipmentId: string): Observable<SelectItem[]> {
        return this.http.get<SelectItem[]>(environment.centralApi + `Equipment/${equipmentId}/TrunkGroups`).pipe(
            map(res => {
                const temp = [];
                res.forEach((item: any) => {
                    temp.push({
                        label: item.name,
                        value: item.number
                    });
                });
                return temp.sort((a, b) => a.label.localeCompare(b.label));
            })
        );
    }

    public getGenesysTrunkBases(customerId: string, equipmentId: string): Observable<SelectItem[]> {
        return this.http
            .get<SelectItem[]>(environment.centralApi + `Equipment/${customerId}/${equipmentId}/GenesysTrunkBases`)
            .pipe(
                map((resp: any) => {
                    const temp = resp.map((item: any) => {
                        return {
                            label: item.baseName,
                            value: item.baseId
                        };
                    });
                    return temp;
                })
            );
    }

    public getCiscoCubeDialPeerGroups(equipmentId: string, locationId: string): Observable<SelectItem[]> {
        return this.http
            .get<SelectItem[]>(environment.centralApi + `Equipment/${equipmentId}/CiscoDialPeerGroups`)
            .pipe(
                map(res => {
                    const temp = [];
                    res.forEach((item: any) => {
                        temp.push({
                            label: item.name,
                            value: item.number
                        });
                    });
                    return temp.sort((a, b) => a.label.localeCompare(b.label));
                })
            );
    }

    public getTeamsTenants(locationId: string): Observable<SelectItem[]> {
        return this.http.get<SelectItem[]>(environment.centralApi + `Equipment/${locationId}/MicrosoftTeamsInfo`).pipe(
            map(res => {
                const temp = [];
                res.forEach((item: any) => {
                    temp.push({
                        label: item.equipmentName,
                        value: item.equipmentId
                    });
                });
                return temp.sort((a, b) => a.label.localeCompare(b.label));
            })
        );
    }

    public getTeamsSites(equipmentId: string): Observable<SelectItem[]> {
        return this.http
            .get<MicrosoftTeamsSiteNames[]>(environment.centralApi + `Teams/GetTeamsSites/${equipmentId}`)
            .pipe(
                map((res: MicrosoftTeamsSiteNames[]) =>
                    res
                        .map((subnet: MicrosoftTeamsSiteNames) => {
                            return { label: subnet.siteName, value: subnet.siteNameId };
                        })
                        .sort((a, b) => a.label.localeCompare(b.label))
                )
            );
    }

    public getGenesysCloudServices(locationId: string): Observable<SelectItem[]> {
        return this.http.get<SelectItem[]>(environment.centralApi + `Equipment/${locationId}/GenesysInfo`).pipe(
            map(res => {
                const temp = [];
                res.forEach((item: any) => {
                    temp.push({
                        label: item.equipmentName,
                        value: item.equipmentId
                    });
                });
                return temp.sort((a, b) => a.label.localeCompare(b.label));
            })
        );
    }

    public getIpNetworkRegionsByRTCP(
        customerId: string,
        locationId: string,
        sourceEquipmentId: string
    ): Observable<SelectItem[]> {
        const params = { customerId: customerId, locationId: locationId, sourceEquipmentId: sourceEquipmentId };
        return this.http.get<SelectItem[]>('API/DashboardSettingsNg/LocationIpNetworkRegions', {
            params: params
        });
    }

    public getEquipmentForCustomerList(customerId: string): Observable<SelectItem[]> {
        return this.http.get<SelectItem[]>(environment.centralApi + `Equipment/Customer/${customerId}`).pipe(
            map((res: any) => {
                const temp = [];
                for (let item of res) {
                    temp.push({
                        label: item.equipmentName ? item.equipmentName : '',
                        value: item.equipmentId ? item.equipmentId : ''
                    });
                }
                return temp;
            })
        );
    }

    public getBusinessPartners(): Observable<SelectItem[]> {
        return this.http.get<SelectItem[]>('API/DashboardSettingsNg/BusinessPartners');
    }

    public getAllNetworkConnectivityEquipmentAtLocationGrouped(locationId: string): Observable<SelectItemGroup[]> {
        return this.http
            .get<
                {
                    equipmentName: string;
                    equipmentId: string;
                    productName: string;
                    productId: string;
                }[]
            >(environment.centralApi + `Equipment/${locationId}/NetworkConnectivityInfo`)
            .pipe(
                map(equipment => {
                    return equipment.reduce<SelectItemGroup[]>((acc, cur) => {
                        let g = acc.find(i => i.label === cur.productName);
                        if (!g) {
                            //create new product group
                            g = new SelectItemGroup();
                            g.label = cur.productName;
                            g.items = [];
                            acc.push(g);
                        }
                        //add eqp to product group
                        const s = new SelectItem();
                        s.label = cur.equipmentName;
                        s.value = cur.equipmentId;
                        g.items.push(s);
                        return acc.sort((a, b) => a.label.localeCompare(b.label));
                    }, []);
                })
            );
    }

    public getAllNetworkConnectivityEquipmentAtLocation(locationId: string): Observable<
        {
            equipmentName: string;
            equipmentId: string;
            config: any;
        }[]
    > {
        return this.http
            .get<
                {
                    equipmentName: string;
                    equipmentId: string;
                    config: any;
                }[]
            >(environment.centralApi + `Equipment/${locationId}/NetworkConnectivityInfo`)
            .pipe(
                map(equipmentlist => {
                    let g = [];
                    equipmentlist.forEach(equipment => {
                        g.push({
                            label: equipment.equipmentName,
                            value: equipment.equipmentId,
                            config: equipment.config ? equipment.config : null
                        });
                    });

                    g = g
                        .filter(thing => !thing.label.includes('Appliance_'))
                        .sort((a, b) => a.label.localeCompare(b.label));
                    return g;
                })
            );
    }

    public getRemoteLocations(
        customerId: string,
        locationId: string,
        equipmentId: string
    ): Observable<
        {
            label: string;
            value: string;
            localLocationName: string;
            timeZone: string;
        }[]
    > {
        return this.http
            .get<
                {
                    remoteLocationId: string;
                    remoteLocationName: string;
                    localLocationName: string;
                    timeZone: string;
                }[]
            >(environment.centralApi + `Location/${customerId}/${locationId}/${equipmentId}/Remote`)
            .pipe(
                map(remotelocationlist => {
                    let g = [];
                    for (let equipment of remotelocationlist) {
                        g.push({
                            label: equipment.remoteLocationName + ` (${equipment.remoteLocationId})`,
                            value: equipment.remoteLocationId,
                            localLocationName: equipment.localLocationName,
                            timeZone: equipment.timeZone
                        });
                    }
                    return g;
                }, [])
            );
    }

    public getLinuxEquipment(equipmentId: string): Observable<SelectItemGroup[]> {
        return this.http
            .get<SystemHealthInfo[]>(environment.centralApi + `Equipment/${equipmentId}/LinuxSystemHealthInfo`)
            .pipe(
                map(equipment => {
                    return equipment.reduce<SelectItemGroup[]>((acc, cur) => {
                        let g = acc.find(i => i.label === cur.productName);
                        if (!g) {
                            //create new product group
                            g = new SelectItemGroup();
                            g.label = cur.productName;
                            g.items = [];
                            acc.push(g);
                        }
                        //add eqp to product group
                        const s = new SelectItem();
                        s.label = cur.equipmentName;
                        s.value = cur.equipmentId;
                        g.items.push(s);
                        return acc;
                    }, []);
                })
            );
    }

    public getEngelbartSoftwareEsuitesEquipment(equipmentId: string): Observable<SelectItemGroup[]> {
        let params = new HttpParams();
        params = params.append('entityId', equipmentId);

        return this.http
            .get<
                {
                    equipmentName: string;
                    equipmentId: string;
                    productName: string;
                    productId: string;
                }[]
            >(environment.centralApi + `Equipment/${equipmentId}/EngelbartSystemHealthInfo`)
            .pipe(
                map(equipment => {
                    return equipment.reduce<SelectItemGroup[]>((acc, cur) => {
                        let g = acc.find(i => i.label === cur.productName);
                        if (!g) {
                            //create new product group
                            g = new SelectItemGroup();
                            g.label = cur.productName;
                            g.items = [];
                            acc.push(g);
                        }
                        //add eqp to product group
                        const s = new SelectItem();
                        s.label = cur.equipmentName;
                        s.value = cur.equipmentId;
                        g.items.push(s);
                        return acc;
                    }, []);
                })
            );
    }

    public createSettingsForm(dashlet: Dashlet): DashletSettingsForm {
        switch (dashlet.type) {
            case DashletType.Sample1:
                return new DashletSettingsFormExample1();
            case DashletType.TrunkGroupUtilization:
            case DashletType.TrunkGroupTraffic:
                return new DashletSettingsFormTrunkGroupUtilization(this);
            case DashletType.TrunkGroupList:
                return new DashletSettingsFormTrunkGroupList(this, this.notificationService);
            case DashletType.VoiceQualityCallsInProgress:
            case DashletType.VoiceQualityActiveStreams:
            case DashletType.VoiceQualityMeanOpinionScoreScatter:
                return new DashletSettingsFormVoiceQuality(this);
            case DashletType.SystemHealthIpOffice:
                return new DashletSettingsFormSystemHealthIpOffice(this);
            case DashletType.SystemHealthLinuxServer:
                return new DashletSettingsFormSystemHealthLinuxServer(this);
            case DashletType.SystemHealthEngelbartSoftwareESuites:
                return new DashletSettingsFormSystemHealthEngelbartSoftwareEsuites(this);
            case DashletType.SystemHealthOracle:
                return new DashletSettingsFormSystemHealthOracle(this);
            case DashletType.SystemHealthWindowsServer:
                return new DashletSettingsFormSystemHealthWindowsServer(this);
            case DashletType.SystemHealthAcm:
                return new DashletSettingsFormSystemHealthAcm(this);
            case DashletType.SystemHealthCMS:
                return new DashletSettingsFormSystemHealthCMS(this);
            case DashletType.SystemHealthASM:
                return new DashletSettingsFormSystemHealthASM(this);
            case DashletType.SystemHealthAEP:
                return new DashletSettingsFormSystemHealthAEP(this);
            case DashletType.SystemHealthAES:
                return new DashletSettingsFormSystemHealthAES(this);
            case DashletType.SystemHealthSBC:
                return new DashletSettingsFormSystemHealthSBC(this);
            case DashletType.IPNRStatus:
                return new DashletSettingsFormIPNRStatus(this);
            case DashletType.IPNREndpoints:
                return new DashletSettingsFormIPNREndpoints(this);
            case DashletType.NetworkConnectivity:
                return new DashletSettingsFormNetworkConnectivity(this);
            case DashletType.NetworkConnectivitySummary:
                return new DashletSettingsFormNetworkConnectivitySummary(this);
            case DashletType.SIPBandwidth:
                return new DashletSettingsFormSIPBandwidth(this);
            case DashletType.AlarmsSummary:
                return new DashletSettingsFormAlarmsSummary(this, this.accountService);
            case DashletType.PortsPerCustomer:
                return new DashletSettingsFormPortsPerCustomer(this);
            case DashletType.PortsByRegion:
                return new DashletSettingsFormPortsByRegion(this);
            case DashletType.PortsByType:
                return new DashletSettingsFormPortsByType(this);
            case DashletType.CustomersByRegion:
                return new DashletSettingsFormCustomersByRegion(this);
            case DashletType.DatabaseHealth:
                return new DashletSettingsFormDatabaseHealth(this);
            case DashletType.VQMDailySummary:
                return new DashletSettingsFormVQMDailySummary(this, this.accountService);
            case DashletType.BusinessPartnerAlarmsSummary:
                return new DashletSettingsFormAlarmsSummary(this, this.accountService);
            case DashletType.TrunkGroupSummary:
                return new DashletSettingsFormTrunkGroupSummary(this);
            case DashletType.SystemHealthSummary:
                return new DashletSettingsFormSystemHealthSummary(this);
            case DashletType.ServiceGatewayInstancesInformation:
                return new DashletSettingsFormServiceGatewayInstancesInformation(this);
            case DashletType.EndpointDataSummary:
                return new DashletSettingsFormEndpointDataSummary(this, this.accountService);
            case DashletType.SystemHealthAvayaContact:
                return new DashletSettingsFormSystemHealthAvayaContact(this);
            case DashletType.BusinessPartnerVQMSummary:
                return new DashletSettingsFormBusinessPartnerVQMSummary(this, this.accountService);
            case DashletType.IPNRBandwidth:
                return new DashletSettingsFormIPNRBandwidth(this, this.accountService);
            case DashletType.ACMSystemHealthSummary:
                return new DashletSettingsFormACMSystemHealth(this);
            case DashletType.SystemHealthCiscoUCM:
                return new DashletSettingsFormSystemHealthCiscoUCM(this);
            case DashletType.SIPResponseSummary:
                return new DashletSettingsFormSIPResponseSummary(this, this.accountService);
            case DashletType.AlarmOverview:
                return new DashletSettingsFormAlarmOverview(this, this.accountService);
            case DashletType.SystemHealthCiscoCUBE:
                return new DashletSettingsFormSystemHealthCiscoCUBE(this, this.accountService);
            case DashletType.SystemHealthCiscoUnityConnection:
                return new DashletSettingsFormSystemHealthCiscoUnityConnection(this, this.accountService);
            case DashletType.SystemHEalthCiscoPresence:
                return new DashletSettingsFormSystemHealthCiscoPresence(this, this.accountService);
            case DashletType.RibbonSBCEdge:
                return new DashletSettingsFormRibbonSBCEdge(this);
            case DashletType.SystemHealthVmware:
                return new DashletSettingsFormSystemHealthVmware(this, this.accountService);
            case DashletType.RibbonSystemHealthSummary:
                return new DashletSettingsFormRibbonSystemHealth(this);
            case DashletType.SystemHealthCiscoDialPeerSummary:
                return new DashletSettingsFormCiscoDialPeerSummary(this);
            case DashletType.SystemHealthAudioCodesSBC:
                return new DashletSettingsFormSystemHealthAudioCodes(this, this.accountService);
            case DashletType.MSTeamsCallSummary:
                return new DashletSettingsFormMSTeamsCallSummary(this, this.accountService);
            case DashletType.GenesysTrunkGroupsSummary:
                return new DashletSettingsFormGenesysTrunksSummary(this, this.notificationService);
            case DashletType.GenesysConversationSummary:
                return new DashletSettingsFormGenesysDetailsSummary(this, this.notificationService);
            case DashletType.MicrosoftTeamsRoomHealthSummary:
                return new DashletSettingsFormMSTeamsRoomsHealthSummary(this);
            default:
                throw new Error(`No implementation for dashlet '${DashletType[dashlet.type]}'.`);
        }
    }

    public resolveComponent(setting: DashletSetting): Type<any> {
        if (setting === null) throw new Error(`Null argument.`);

        if (setting instanceof DashletSettingDropdown) return DashletSettingDropdownComponent;
        else if (setting instanceof DashletSettingDropdownGrouped) return DashletSettingDropdownGroupedComponent;
        else if (setting instanceof DashletSettingDropdownGroupedList)
            return DashletSettingDropdownGroupedListComponent;
        else if (setting instanceof DashletSettingText) return DashletSettingTextComponent;
        else if (setting instanceof DashletSettingCheckbox) return DashletSettingCheckboxComponent;
        else if (setting instanceof DashletSettingList) return DashletSettingListComponent;
        else
            throw new Error(
                `No implementation for dashlet setting type '${
                    setting.constructor ? setting.constructor.name : 'anonymous'
                }'.`
            );
    }

    public observe(dashletId: string): Observable<any> {
        let subject = this.subjects.find(element => element.dashletId === dashletId);
        if (subject) return subject.subject;
        return null;
    }

    public makeHealthSummaryApiCall(url: string, key: string, equipmentName?: string): Observable<any> {
        return this.http.get(environment.realtimeApi + url).pipe(
            map((response: any) => {
                if (equipmentName) {
                    response.equipmentName = equipmentName;
                }
                response.newKey = key;
                return response;
            })
        );
    }

    public makeHealthSummaryApiCallWithIds(equipmentIds: string[], commandTypeID: string): Observable<any> {
        const url = environment.realtimeApi + `summarydocument/command/${commandTypeID}/batch`;
        return this.http.post(url, equipmentIds);
    }
}
