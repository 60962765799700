<div class="grid full-height" [ngClass]="{ 'grid-custom-2-columns': expandedView && !dashlet.loadingDocuments }">
    <div class="center grow" *ngIf="dashlet.loadingDocuments; else showTable">
        <ng-container *ngTemplateOutlet="vsmLoadingTemplate"></ng-container>
    </div>
    <ng-template #showTable>
        <div class="flex-column non-expanded-width">
            <ng-container *ngTemplateOutlet="summaryCallStats"></ng-container>
            <ng-container *ngTemplateOutlet="streamQualityGraph"></ng-container>
            <ng-container *ngTemplateOutlet="doughnutCharts"></ng-container>
            <ng-container *ngTemplateOutlet="platforms"></ng-container>
            <ng-container *ngTemplateOutlet="feedbackRatings"></ng-container>
        </div>
        <div *ngIf="expandedView">
            <ng-container *ngTemplateOutlet="siteStreamQualityGraph"></ng-container>
        </div>
    </ng-template>
</div>

<!-- loading template -->
<ng-template #vsmLoadingTemplate>
    <vsm-dashlet-loading></vsm-dashlet-loading>
</ng-template>

<ng-template #summaryCallStats>
    <div class="justify-space-around">
        <div class="flex gap-m">
            <div class="flex-column equipment-detail-labels">
                <div
                    class="mat-body-2 dashlet-link-underline"
                    [matTooltip]="'Navigate to Microsoft Teams Call Records Page'"
                    (click)="OpenMsTeamsCallRecords()"
                >
                    Call Records
                    <mat-icon class="medium-icon clickable">open_in_new</mat-icon>
                </div>
                <div class="mat-body-2">Duration</div>
            </div>
            <div class="flex-column text-no-wrap">
                <div class="mat-body-1">{{ callRecordsCount || 'Unknown' }}</div>
                <div class="mat-body-1">{{ callDurationCount || 'Unknown' }}</div>
            </div>
        </div>
        <div class="flex gap-m">
            <div class="flex-column equipment-detail-labels">
                <div class="mat-body-2">Unique Participants</div>
                <div class="mat-body-2">Total Participants</div>
            </div>
            <div class="flex-column text-no-wrap">
                <div class="mat-body-1">{{ uniqueUsersList.length || 'Unknown' }}</div>
                <div class="mat-body-1">{{ participantCount || 'Unknown' }}</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #platforms>
    <div class="horizontal-line-graph-section">
        <dashlet-section-header>
            <span class="dashlet-link-underline" matTooltip="Open Microsoft Teams Device Usage report" (click)="openDeviceUsageReport()">
                Platforms&nbsp;
                <mat-icon class="medium-icon clickable">open_in_new</mat-icon>
            </span>
        </dashlet-section-header>
        <div class="flex-column gap-m">
            <dashlet-ratio-bar>
                <dashlet-ratio-bar-item
                    [clickable]="false"
                    [value]="platformUsed.Windows"
                    [color]="chartColorsArray[0]"
                    [title]="platformUsed.Windows + ' user(s) used a Windows based platform'"
                ></dashlet-ratio-bar-item>
                <dashlet-ratio-bar-item
                    [clickable]="false"
                    [value]="platformUsed.MacOs"
                    [color]="chartColorsArray[1]"
                    [title]="platformUsed.MacOs + ' user(s) used a MacOS based platform'"
                ></dashlet-ratio-bar-item>
                <dashlet-ratio-bar-item
                    [clickable]="false"
                    [value]="platformUsed.Android"
                    [color]="chartColorsArray[2]"
                    [title]="platformUsed.Android + ' user(s) used an Android based platform'"
                ></dashlet-ratio-bar-item>
                <dashlet-ratio-bar-item
                    [clickable]="false"
                    [value]="platformUsed.Ios"
                    [color]="chartColorsArray[3]"
                    [title]="platformUsed.Ios + ' user(s) used an iOS based platform'"
                ></dashlet-ratio-bar-item>
                <dashlet-ratio-bar-item
                    [clickable]="false"
                    [value]="platformUsed.Other"
                    [color]="this.chartColors.grey"
                    [title]="platformUsed.Other + ' used an \'Other\' based platform'"
                ></dashlet-ratio-bar-item>
            </dashlet-ratio-bar>
            <div class="justify-space-around">
                <div class="metric flex-column align-baseline" [ngStyle]="{ 'border-left-color': chartColorsArray[0] }">
                    <div class="mat-caption">Windows</div>
                    <div class="mat-h2">{{ platformUsed.Windows }}</div>
                </div>
                <div class="metric flex-column align-baseline" [ngStyle]="{ 'border-left-color': chartColorsArray[1] }">
                    <div class="mat-caption">MacOS</div>
                    <div class="mat-h2">{{ platformUsed.MacOs }}</div>
                </div>

                <div class="metric flex-column align-baseline" [ngStyle]="{ 'border-left-color': chartColorsArray[2] }">
                    <div class="mat-caption">Android</div>
                    <div class="mat-h2">{{ platformUsed.Android }}</div>
                </div>
                <div class="metric flex-column align-baseline" [ngStyle]="{ 'border-left-color': chartColorsArray[3] }">
                    <div class="mat-caption">iOS</div>
                    <div class="mat-h2">{{ platformUsed.Ios }}</div>
                </div>
                <div class="metric flex-column align-baseline" [ngStyle]="{ 'border-left-color': this.chartColors.grey }">
                    <div class="mat-caption">Other</div>
                    <div class="mat-h2">{{ platformUsed.Other }}</div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #feedbackRatings>
    <div class="horizontal-line-graph-section">
        <dashlet-section-header
            ><span class="dashlet-link-underline" matTooltip="Open Session Quality User Feedback Summary report" (click)="openUserFeedbackSummaryReport()"
                >Feedback Ratings&nbsp;<mat-icon class="medium-icon clickable">open_in_new</mat-icon></span
            ></dashlet-section-header
        >
        <div class="flex-column gap-m">
            <dashlet-ratio-bar class="dashlet-cell">
                <dashlet-ratio-bar-item
                    [clickable]="false"
                    [value]="feedbackRatingCount.Best"
                    [color]="getSeverityColor(0)"
                    [title]="feedbackRatingCount.Best + ' user(s) have given a rating of Excellent or Good'"
                ></dashlet-ratio-bar-item>
                <dashlet-ratio-bar-item
                    [clickable]="false"
                    [value]="feedbackRatingCount.Middle"
                    [color]="getSeverityColor(1)"
                    [title]="feedbackRatingCount.Middle + ' user(s) have given a rating of Fair'"
                ></dashlet-ratio-bar-item>
                <dashlet-ratio-bar-item
                    [clickable]="false"
                    [value]="feedbackRatingCount.Worst"
                    [color]="getSeverityColor(2)"
                    [title]="feedbackRatingCount.Worst + ' user(s) have given a rating of Poor or Bad'"
                ></dashlet-ratio-bar-item>
                <dashlet-ratio-bar-item
                    [clickable]="false"
                    [value]="feedbackRatingCount.NoRating"
                    [color]="getSeverityColor(-1)"
                    [title]="feedbackRatingCount.NoRating + ' user(s) have given Not Rated'"
                ></dashlet-ratio-bar-item>
            </dashlet-ratio-bar>
            <div class="justify-space-between">
                <h3>
                    {{ feedbackRatingCount.Best }}
                    <span class="mat-h3">Excellent/Good</span>
                </h3>
                <h3>
                    {{ feedbackRatingCount.Middle }}
                    <span class="mat-h3">Fair</span>
                </h3>
                <h3>
                    {{ feedbackRatingCount.Worst }}
                    <span class="mat-h3">Poor/Bad</span>
                </h3>
                <h3>
                    {{ feedbackRatingCount.NoRating }}
                    <span class="mat-h3">Not Rated</span>
                </h3>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #doughnutChartTemplate let-chartData="data">
    <!-- can't use *ngIf on the p-charts because they need to exist on page to be initialized properly, and *ngIf makes them not exist at first -->
    <p-chart
        #systemChart
        [ngStyle]="{ display: displayPiChart(chartData) ? 'unset' : 'none' }"
        [type]="'doughnut'"
        [data]="chartData"
        [options]="options"
    ></p-chart>
    <div *ngIf="!displayPiChart(chartData)" class="no-data-container">No Data Available</div>
</ng-template>

<ng-template #legendTemplate let-chartData="data">
    <div class="doughnut-legends">
        <div
            *ngFor="let entry of chartData?.labels; let i = index"
            class="metric align-baseline justify-space-between"
            [ngStyle]="getStyleForEntry(chartData.datasets[0].backgroundColor[i])"
        >
            <div class="doughnut-legend">
                <div class="mat-body-2 doughnut-legend-label">{{ entry }}</div>
                <div class="doughnut-count">{{ chartData.datasets[0].data[i] }}</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #doughnutCharts>
    <div class="grid grid-custom-4-columns grid-custom-3-rows">
        <!-- Doughnut chart titles -->
        <div class="mat-caption text-center">Call Type</div>
        <div class="mat-caption text-center">Network</div>
        <div class="mat-caption text-center">Audio Device</div>
        <div class="mat-caption text-center dashlet-link-underline" matTooltip="Open Microsoft Teams User Activity report" (click)="openUserActivityReport()">
            Modalities&nbsp;<mat-icon class="medium-icon clickable link-icon">open_in_new</mat-icon>
        </div>
        <!-- Doughnut chart charts -->
        <ng-container *ngTemplateOutlet="doughnutChartTemplate; context: { data: callTypeChartData }"></ng-container>
        <ng-container *ngTemplateOutlet="doughnutChartTemplate; context: { data: networksChartData }"></ng-container>
        <ng-container *ngTemplateOutlet="doughnutChartTemplate; context: { data: audioDevicesChartData }"></ng-container>
        <ng-container *ngTemplateOutlet="doughnutChartTemplate; context: { data: modalitiesChartData }"></ng-container>
        <!-- Doughnut chart legends -->
        <ng-container *ngTemplateOutlet="legendTemplate; context: { data: callTypeChartData }"></ng-container>
        <ng-container *ngTemplateOutlet="legendTemplate; context: { data: networksChartData }"></ng-container>
        <ng-container *ngTemplateOutlet="legendTemplate; context: { data: audioDevicesChartData }"></ng-container>
        <ng-container *ngTemplateOutlet="legendTemplate; context: { data: modalitiesChartData }"></ng-container>
    </div>
</ng-template>

<ng-template #streamQualityGraph>
    <div class="flex gap-l justify-space-between align-center">
        <div class="flex-column grow">
            <div class="mat-body-2 dashlet-link-underline" (click)="openMOSDistributionReport()" matTooltip="Open Microsoft Teams Stream Quality report">
                Stream Quality&nbsp;<mat-icon class="medium-icon clickable">open_in_new</mat-icon>
            </div>
            <div class="metric align-baseline success-border justify-space-between">
                <div class="mat-body-2">Good</div>
                <div>{{ streamSummary[selectedCustomSiteName].good }}</div>
            </div>
            <div class="metric align-baseline warning-border justify-space-between">
                <div class="mat-body-2">Satisfactory</div>
                <div>{{ streamSummary[selectedCustomSiteName].satisfactory }}</div>
            </div>
            <div class="metric align-baseline failure-border justify-space-between">
                <div class="mat-body-2">Poor</div>
                <div>{{ streamSummary[selectedCustomSiteName].poor }}</div>
            </div>
        </div>
        <dashlet-line-graph
            #mainGraph
            style="height: 200px"
            [data]="siteStreamData[selectedCustomSiteName]"
            [options]="mainStreamQualityChartOptions"
            [aspect]="3"
        ></dashlet-line-graph>
    </div>
</ng-template>

<ng-template #siteStreamQualityGraph>
    <div class="site-graphs-container">
        <div
            tabindex="0"
            (click)="changeSite(label)"
            (keydown)="handleKeyDown($event, label)"
            class="align-center site-graph-container"
            [ngClass]="{ 'site-graph-container-selected': label === selectedCustomSiteName }"
            *ngFor="let label of graphLabels"
            [ngStyle]="!siteStreamData[label] && { pointerEvents: 'none' }"
        >
            <ng-container *ngIf="siteStreamData[label]; else showPending">
                <div class="mat-body-1 text-center">
                    {{ startCase(label === 'All' ? dashlet.equipment.equipmentName || '' : label) }}
                </div>
                <dashlet-line-graph
                    #subGraph
                    [data]="siteStreamData[label]"
                    width="200"
                    height="75"
                    [options]="smallStreamQualityGraphOptions"
                    [aspect]="1.5"
                ></dashlet-line-graph>
            </ng-container>
            <ng-template #showPending>
                <div class="mat-body-1 text-center">
                    {{ startCase(label) }}
                </div>
                <div class="mat-body-1 text-center pending-data-container">Pending</div>
            </ng-template>
        </div>
        <div *ngIf="!graphLabels.length">
            <div class="align-center pending-data-container">No Custom Site Names Selected</div>
        </div>
    </div>
</ng-template>
