import * as DashboardActions from '@actions/dashboard/dashboard.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState, selectDashboardState, selectDashletMeta } from '@reducers/index';
import { catchError, concatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Dashboard, DashboardState, DashletMeta } from '@models/index';
import { DashboardService, DashletFactoryService } from '@services/index';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';

@Injectable()
export class DashboardEffects {
    constructor(
        private actions$: Actions,
        private dashboardService: DashboardService,
        private store$: Store<AppState>,
        private dashletFactoryService: DashletFactoryService
    ) {}
    getDashletsMeta$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.GetDashletsMeta),
            mergeMap(action =>
                this.dashboardService.getDashletsMeta().pipe(
                    map((data: DashletMeta[]) => DashboardActions.GetDashletsMetaSuccess({ data })),
                    catchError(err => of(DashboardActions.GetDashletsMetaFailure({ error: err })))
                )
            )
        )
    );

    loadDashboard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.LoadDashboard),
            switchMap(action =>
                this.dashboardService.loadDashboard(action.id).pipe(
                    withLatestFrom(this.store$.select(selectDashletMeta)),
                    concatMap(([data, state]) =>
                        of(
                            DashboardActions.LoadDashboardSuccess({
                                dashboard: data,
                                dashboardState: JSON.parse(data.state)
                            }),
                            DashboardActions.CreateDashletsFromState({
                                state: JSON.parse(data.state),
                                dashletsMeta: state
                            })
                        )
                    ),
                    catchError(error => of(DashboardActions.LoadDashboardFailure({ error })))
                )
            )
        )
    );

    getDashboards$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.GetDashboards),
            mergeMap(action =>
                this.dashboardService.getDashboards(action.entityId, action.userId).pipe(
                    map(data => DashboardActions.GetDashboardsSuccess({ dashboards: data })),
                    catchError(err => of(DashboardActions.GetDashboardsFailure({ error: err })))
                )
            )
        )
    );

    updateDashboard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.UpdateDashboard),
            mergeMap(action =>
                this.dashboardService.updateDashboard(action.dashboard).pipe(
                    map(data => DashboardActions.UpdateDashboardSuccess({ data })),
                    catchError(err => of(DashboardActions.UpdateDashboardFailure({ error: err })))
                )
            )
        )
    );

    deleteDashboard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.DeleteDashboard),
            mergeMap(action =>
                this.dashboardService.persistDashboardState().pipe(
                    map(data => DashboardActions.DeleteDashboardSuccess({ data: action.dashletId })),
                    catchError(err => of(DashboardActions.DeleteDashboardFailure({ error: err })))
                )
            )
        )
    );

    addDashboard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.AddDashboard),
            mergeMap(action =>
                this.dashboardService.addDashboard(action.dashboard).pipe(
                    map(data => DashboardActions.AddDashboardSuccess({ data })),
                    catchError(err => of(DashboardActions.AddDashboardFailure({ error: err })))
                )
            )
        )
    );

    updateDashboardState$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.UpdateDashboardState),
            switchMap(action =>
                this.dashboardService.persistDashboardState().pipe(
                    withLatestFrom(this.store$.select(selectDashboardState)),
                    switchMap(([state]) => of(DashboardActions.UpdateDashboardStateSuccess({ data: state }))),
                    catchError(err => of(DashboardActions.UpdateDashboardStateFailure({ error: err })))
                )
            )
        )
    );

    copyDashboard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.CopyDashboard),
            mergeMap(action =>
                this.dashboardService.copyDashboard(action.dashboardId, action.newDashboard).pipe(
                    map(data => DashboardActions.CopyDashboardSuccess({ data })),
                    catchError(err => of(DashboardActions.CopyDashboardFailure({ error: err })))
                )
            )
        )
    );

    createDashletFromState$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.CreateDashletsFromState),
            mergeMap(action =>
                this.dashletFactoryService.createDashletsFromStateNew(action.state, action.dashletsMeta).pipe(
                    map(data => {
                        return [...cloneDeep(data)];
                    }),
                    concatMap(data => of(DashboardActions.CreateDashletsFromStateSuccess({ data }))),
                    catchError(err => of(DashboardActions.CreateDashletsFromStateFailure({ error: err })))
                )
            )
        )
    );

    addDashlet$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.AddDashlet),
            mergeMap(action => {
                this.dashboardService.addDashlet(action.dashlet);
                return of(DashboardActions.CreateDashletsFromStateSuccess({ data: [action.dashType] }));
            }),
            catchError(err => of(DashboardActions.CreateDashletsFromStateFailure({ error: err })))
        )
    );

    updateDashlets$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.UpdateDashlets),
            mergeMap(action =>
                this.dashboardService.persistDashboardState().pipe(
                    map(state => DashboardActions.UpdateDashletsSuccess({ data: state })),
                    catchError(err => of(DashboardActions.UpdateDashletsFailure({ error: err })))
                )
            )
        )
    );
}
