<div class="flex-column gap-l">
    <ng-container *ngIf="!openSubcontainer">
        <!-- system -->
        <dashlet-section-processor-memory
            #processorMemory
            [showSystemChart]="dashlet.showSystemChart"
            [showDiskUsage]="false"
            [equipment]="dashlet.equipment"
            [locationId]="dashlet.location.locationId"
            [customerId]="dashlet.customer.customerId"
            [commandTypeIdCPUUsage]="dashlet.commandTypeIdCpu"
            [commandTypeIdMemoryUsage]="dashlet.commandTypeIdMemory"
            [commandTypeIdUpTime]="dashlet.commandTypeIdServer"
        >
        </dashlet-section-processor-memory>

        <!-- network connectivity -->
        <dashlet-section-network-connectivity
            [showNetworkGraph]="dashlet.showNetworkGraph"
            [equipment]="dashlet.equipment"
            [customerId]="dashlet.customer.customerId"
        >
        </dashlet-section-network-connectivity>
    </ng-container>
    <!-- Network Interface -->
    <dashlet-section-network-interface
        *ngIf="dashlet.showNetworkInterface"
        [equipment]="dashlet.equipment"
        [locationName]="dashlet.location.locationName"
        [customerName]="dashlet.customer.customerName"
        (openDetails)="$event ? openDetails($event) : closeDetails()"
    ></dashlet-section-network-interface>
</div>
