import { environment } from '@environments/environment';
import { Dashlet } from '@models/index';
import { RealtimeGatewayService } from '@services/index';
import { Subject } from 'rxjs';

interface Point {
    x: Date;
    y: number;
}

export class DashletTotalPortsByRegion extends Dashlet {
    public _commandTypeIdPort = 'A65D6322-7F6A-4A8A-8A44-159A4BF91B80';
    public _equipmentId = '445bb159-5bae-42ce-b568-58d84617bdfc';

    groupName!: string;

    private _totalPortsPerCustomerArrayEMEA: Point[] = [];
    private _totalPortsPerCustomerArrayNA: Point[] = [];
    private _totalPortsPerCustomerArrayAPAC: Point[] = [];

    private _totalPortsLatestAPAC = 0;
    private _totalPortsLatestEMEA = 0;
    private _totalPortsLatestNA = 0;

    private _chartDataUpdated: Subject<null> = new Subject();
    get onChartDataUpdated() {
        return this._chartDataUpdated;
    }

    constructor(private realtimeService: RealtimeGatewayService) {
        super();
        // sizing
        this.sizes = [
            {
                id: 0,
                label: 'Small',
                cols: 5,
                rows: 2
            },
            {
                id: 1,
                label: 'Medium',
                cols: 5,
                rows: 5
            },
            {
                id: 2,
                label: 'Large',
                cols: 10,
                rows: 5
            }
        ];
        this.applySize(1);

        // init data
        this.resetData();
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);
        this.configured = true;
    }

    applySize(id: number) {
        super.applySize(id);
        this.updateSize();
    }

    private updateSize() {
        const h = 0;
        const w = 0;
        this.applySizeExpansion(w, h);
    }

    public processPortData(data: any[]) {
        this.resetData();
        const results = data[0].data;
        if (results !== null) {
            const startDate = new Date(); // sets to current date

            startDate.setDate(startDate.getDate() - 40); // date we want to start at
            let currentPeriod: any = null;
            let totalPortsPerCustomerEMEA: any = null;
            let totalPortsPerCustomerNA: any = null;
            let totalPortsPerCustomerAPAC: any = null;
            const periodStart = new Date();

            results.forEach((row: any) => {
                // Convert date from RTG to Date object format. RTG date is in US format
                let dateSplit = row.PeriodStart.split('/', 3);
                periodStart.setDate(dateSplit[1]);
                periodStart.setMonth(dateSplit[0] - 1);
                dateSplit = dateSplit[2].split(' ', 1);
                periodStart.setFullYear(dateSplit[0]);

                if (startDate < periodStart) {
                    // New day and not the first day
                    if (currentPeriod < periodStart && currentPeriod !== null) {
                        this._totalPortsPerCustomerArrayNA.push({ x: currentPeriod, y: totalPortsPerCustomerNA });
                        this._totalPortsPerCustomerArrayAPAC.push({ x: currentPeriod, y: totalPortsPerCustomerAPAC });
                        this._totalPortsPerCustomerArrayEMEA.push({ x: currentPeriod, y: totalPortsPerCustomerEMEA });
                        // Get latest value for each region unless it's null
                        this._totalPortsLatestEMEA = totalPortsPerCustomerEMEA
                            ? totalPortsPerCustomerEMEA
                            : this._totalPortsLatestEMEA;
                        this._totalPortsLatestNA = totalPortsPerCustomerNA
                            ? totalPortsPerCustomerNA
                            : this._totalPortsLatestNA;
                        this._totalPortsLatestAPAC = totalPortsPerCustomerAPAC
                            ? totalPortsPerCustomerAPAC
                            : this._totalPortsLatestAPAC; // reset values for new day
                        totalPortsPerCustomerEMEA = null;
                        totalPortsPerCustomerNA = null;
                        totalPortsPerCustomerAPAC = null;
                    }

                    switch (row.RegionShortName) {
                        case 'NA':
                            totalPortsPerCustomerNA = row.Ports;
                            break;
                        case 'APAC':
                            totalPortsPerCustomerAPAC = row.Ports;
                            break;
                        case 'EMEA':
                            totalPortsPerCustomerEMEA = row.Ports;
                            break;
                        case 'null':
                            break;
                    }
                    currentPeriod = new Date(periodStart);
                }
            });
            // Get the last days values if we have them all
            if (
                totalPortsPerCustomerAPAC !== null &&
                totalPortsPerCustomerEMEA !== null &&
                totalPortsPerCustomerNA !== null
            ) {
                this._totalPortsPerCustomerArrayNA.push({ x: currentPeriod, y: totalPortsPerCustomerNA });
                this._totalPortsPerCustomerArrayAPAC.push({ x: currentPeriod, y: totalPortsPerCustomerAPAC });
                this._totalPortsPerCustomerArrayEMEA.push({ x: currentPeriod, y: totalPortsPerCustomerEMEA });
                // Get latest value for each region unless it's null
                this._totalPortsLatestEMEA = totalPortsPerCustomerEMEA
                    ? totalPortsPerCustomerEMEA
                    : this._totalPortsLatestEMEA;
                this._totalPortsLatestNA = totalPortsPerCustomerNA ? totalPortsPerCustomerNA : this._totalPortsLatestNA;
                this._totalPortsLatestAPAC = totalPortsPerCustomerAPAC
                    ? totalPortsPerCustomerAPAC
                    : this._totalPortsLatestAPAC; // reset values for new day
            }
        } else {
            if (!environment.production) console.error('RTG CONNECTION ERROR');
        }

        this._chartDataUpdated.next(null);
    }

    resetData() {
        this._totalPortsPerCustomerArrayEMEA = [];
        this._totalPortsPerCustomerArrayNA = [];
        this._totalPortsPerCustomerArrayAPAC = [];
        this._totalPortsLatestEMEA = 0;
        this._totalPortsLatestNA = 0;
        this._totalPortsLatestAPAC = 0;
        this._chartDataUpdated.next(null);
    }

    dispose() {
        this.unsubData();
    }
    private unsubData() {
        if (this._equipmentId) {
            // unsub data
            this.realtimeService.unsubscribe(this._equipmentId, this._commandTypeIdPort);
        }
    }
    get totalPortsLatestEMEA(): number {
        return this._totalPortsLatestEMEA;
    }
    get totalPortsLatestNA(): number {
        return this._totalPortsLatestNA;
    }
    get totalPortsLatestAPAC(): number {
        return this._totalPortsLatestAPAC;
    }
    get totalPortsPerCustomerArrayEMEA(): Point[] {
        return this._totalPortsPerCustomerArrayEMEA;
    }
    get totalPortsPerCustomerArrayNA(): Point[] {
        return this._totalPortsPerCustomerArrayNA;
    }
    get totalPortsPerCustomerArrayAPAC(): Point[] {
        return this._totalPortsPerCustomerArrayAPAC;
    }
}
