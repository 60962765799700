import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

export const GetNotifyProcessHyperVData = createAction(
  '[ProcessHyperVData] Get Notify ProcessHyperVDatas',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetNotifyProcessHyperVDataSuccess = createAction(
  '[ProcessHyperVData] Get Notify ProcessHyperVDatas Success',
  props<{ data: RealTimeNotify }>()
);

export const GetNotifyProcessHyperVDataFailure = createAction(
  '[ProcessHyperVData] Get Notify ProcessHyperVDatas Failure',
  props<{ error: any }>()
);

export const GetProcessHyperVData = createAction(
  '[ProcessHyperVData] Get ProcessHyperVDatas',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetProcessHyperVDataSuccess = createAction(
  '[ProcessHyperVData] Get ProcessHyperVDatas Success',
  props<{ data: any}>()
);

export const GetProcessHyperVDataFailure = createAction(
  '[ProcessHyperVData] Get ProcessHyperVDatas Failure',
  props<{ error: any }>()
);
