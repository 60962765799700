import * as Actions from '@actions/index';
import { Component, Input, OnInit } from '@angular/core';
import {
    ColumnTypes,
    CustomerLogo,
    DashletACMSystemHealthSummary,
    ServerEquipment,
    AcmSystemHealthSummary,
    TableIcon,
    SummaryField,
    Snmp,
    Duplication,
    CdrLink,
    Pn,
    StandBy,
    Lsp,
    MediaServer,
    MediaGateway,
    License
} from '@models/index';
import { DashletService, DashletSettingsService, TimeoutService } from '@services/index';
import { AppState, selectEntity } from '@reducers/index';

import { select, Store } from '@ngrx/store';
import {
    catchError,
    map,
    mergeMap,
    Observable,
    Subscription,
    switchMap,
    interval,
    of,
    Subject,
    forkJoin,
    tap,
    startWith,
    takeUntil,
    BehaviorSubject,
    from
} from 'rxjs';

import { SelectItem } from '@models/index';
@Component({
    selector: 'app-dashlet-acm-system-health-summary',
    templateUrl: './dashlet-acm-system-health-summary.component.html',
    styleUrls: ['./dashlet-acm-system-health-summary.component.scss']
})
export class DashletACMSystemHealthSummaryComponent implements OnInit {
    @Input() dashlet: DashletACMSystemHealthSummary;
    private subscription: Subscription = new Subscription();
    private dataServersSubject: BehaviorSubject<AcmSystemHealthSummary[]> = new BehaviorSubject<
        AcmSystemHealthSummary[]
    >([]);
    public servers$: Observable<AcmSystemHealthSummary[]> = this.dataServersSubject.asObservable();
    public loading = true;
    private destroy$ = new Subject<void>();
    public columns: ColumnTypes[] = [
        {
            columnDef: 'severity',
            header: '',
            cell: (element: AcmSystemHealthSummary) => element.severity,
            type: 'severity',
            dataTooltip: (element: AcmSystemHealthSummary) => this.getSeverityReasons(element)
        },
        {
            columnDef: 'name',
            header: 'Server',
            cell: (element: AcmSystemHealthSummary) => `${element.name}`,
            dataTooltip: (element: AcmSystemHealthSummary) => this.getSeverityReasons(element),
            filterType: 'text',
            width: '11.5%'
        },
        {
            columnDef: 'cpuPercentage',
            header: 'CPU',
            cell: (row: AcmSystemHealthSummary) =>
                row.cpuPercentage === null || row.cpuPercentage === undefined ? '---' : `${row.cpuPercentage}%`,
            type: 'numeric',
            width: '3.75%'
        },
        {
            columnDef: 'memoryPercentage',
            header: 'Memory',
            cell: (row: AcmSystemHealthSummary) =>
                row.memoryPercentage === null || row.memoryPercentage === undefined
                    ? '---'
                    : `${row.memoryPercentage}%`,
            type: 'numeric',
            width: '5.8%'
        },
        {
            columnDef: 'diskPercentage',
            header: 'Disk',
            cell: (row: AcmSystemHealthSummary) =>
                row.diskPercentage === null || row.diskPercentage === undefined ? '---' : `${row.diskPercentage}%`,
            type: 'numeric',
            width: '3.8%'
        },
        {
            columnDef: 'averagePing',
            header: 'Ping',
            subHeadingInFilter: 'Avg',
            cell: (element: AcmSystemHealthSummary) => {
                return isNaN(element.averagePing) || element.diskPercentage === undefined
                    ? '---'
                    : `${element.averagePing < 1 ? '<1' : element.averagePing} ms`;
            },
            type: 'numeric',
            headerTooltip: this.getAvgPingHeaderTooltip(),
            width: '4.4%'
        },
        //adding blank space for readability
        {
            columnDef: 'spacerElement',
            header: ' ',
            width: '1%'
        },
        {
            columnDef: 'servicesUp',
            header: 'Services',
            subHeadingInFilter: 'Up',
            cell: (element: AcmSystemHealthSummary) =>
                element.servicesUp.length > 0
                    ? element.servicesUp.length.toString()
                    : element.servicesUp.length === 0
                    ? '---'
                    : 'N/A',
            type: 'services',
            dataTooltip: (element: AcmSystemHealthSummary) => {
                return this.getServicesTooltip('up', element);
            },
            width: '5.9%'
        },
        {
            columnDef: 'servicesDown',
            header: 'Services',
            subHeadingInFilter: 'Down',
            cell: (element: AcmSystemHealthSummary) =>
                element.servicesDown.length > 0
                    ? element.servicesDown.length.toString()
                    : element.servicesDown.length === 0
                    ? '---'
                    : 'N/A',
            type: 'services',
            dataTooltip: (element: AcmSystemHealthSummary) => {
                return this.getServicesTooltip('down', element);
            },
            width: '5.9%'
        },
        {
            columnDef: 'linkStatus',
            header: 'CDR',
            headerTooltip: this.getCDRHeaderTooltip(),
            matTooltipClass: 'custom-tooltip',
            cell: (element: AcmSystemHealthSummary): TableIcon => {
                switch (element.linkStatus) {
                    case 0:
                        return {
                            text: '',
                            iconName: 'arrow_upward',
                            color: this.getSeverityColor(0)
                        };
                    case 1:
                        return {
                            text: '',
                            iconName: 'arrow_upward',
                            color: this.getSeverityColor(1)
                        };
                    case 2:
                        return {
                            text: '',
                            iconName: 'arrow_downward',
                            color: this.getSeverityColor(2)
                        };
                    case 3:
                        return {
                            text: '',
                            iconName: 'warning',
                            color: this.getSeverityColor(-1)
                        };
                    default:
                        return {
                            text: '---',
                            iconName: '',
                            color: this.getSeverityColor(-1)
                        };
                }
            },
            type: 'icon',
            dataTooltip: (element: AcmSystemHealthSummary) => this.getLinkTooltip(element),
            width: '4.2%'
        },
        {
            columnDef: 'licenseStatus',
            header: 'Licence',
            cell: (element: AcmSystemHealthSummary) => {
                switch (element.licenseStatus) {
                    case -1:
                        return {
                            text: '---',
                            iconName: '',
                            color: this.getSeverityColor(-1)
                        };
                    case 0:
                        return {
                            text: 'Ok',
                            iconName: '',
                            color: this.getSeverityColor(-1)
                        };
                    default:
                        return {
                            text: '',
                            iconName: 'warning',
                            color: this.getSeverityColor(element.licenseStatus)
                        };
                }
            },
            type: 'icon',
            dataTooltip: (element: AcmSystemHealthSummary) => this.getLicenceTooltip(element),
            width: '5.5%'
        },
        {
            columnDef: 'snmpUp',
            header: 'SNMP',
            subHeadingInFilter: 'Up',
            cell: (element: AcmSystemHealthSummary) =>
                element.snmpUp.length > 0
                    ? element.snmpUp.length.toString()
                    : element.snmpUp.length === 0
                    ? '---'
                    : 'N/A',
            type: 'services',
            dataTooltip: (element: AcmSystemHealthSummary) => {
                return this.getSNMPTooltip('up', element);
            },
            width: '4.45%'
        },
        {
            columnDef: 'snmpDown',
            header: 'SNMP',
            subHeadingInFilter: 'Down',
            cell: (element: AcmSystemHealthSummary) =>
                element.snmpDown.length > 0
                    ? element.snmpDown.length.toString()
                    : element.snmpDown.length === 0
                    ? '---'
                    : 'N/A',
            type: 'services',
            dataTooltip: (element: AcmSystemHealthSummary) => {
                return this.getSNMPTooltip('down', element);
            },
            width: '4.45%'
        },
        {
            columnDef: 'standByStatus',
            header: 'Active/',
            subHeadingInFilter: 'Standby',
            cell: (element: AcmSystemHealthSummary) => {
                switch (element.standByStatus) {
                    case -1:
                        return {
                            text: '---',
                            iconName: '',
                            color: this.getSeverityColor(-1)
                        };
                    case 0:
                        return {
                            text: 'Ok',
                            iconName: '',
                            color: this.getSeverityColor(-1)
                        };
                    default:
                        return {
                            text: '',
                            iconName: 'warning',
                            color: this.getSeverityColor(element.standByStatus)
                        };
                }
            },
            type: 'icon',
            headerTooltip: this.getStandByHeaderTooltip(),
            matTooltipClass: 'custom-tooltip',
            dataTooltip: (element: AcmSystemHealthSummary) => this.getStandByTooltip(element),
            width: '5.6%'
        },
        {
            columnDef: 'duplicationStatus',
            header: 'Duplication',
            cell: (element: AcmSystemHealthSummary) => {
                switch (element.duplicationStatus) {
                    case -1:
                        return {
                            text: '---',
                            iconName: '',
                            color: this.getSeverityColor(-1)
                        };
                    case 0:
                        return {
                            text: 'Ok',
                            iconName: '',
                            color: this.getSeverityColor(-1)
                        };
                    case 3:
                        return {
                            text: 'N/A',
                            iconName: ''
                        };
                    default:
                        return {
                            text: '',
                            iconName: 'warning',
                            color: this.getSeverityColor(element.duplicationStatus)
                        };
                }
            },
            type: 'icon',
            headerTooltip: this.getDuplicationHeaderTooltip(),
            matTooltipClass: 'custom-tooltip',
            dataTooltip: (element: AcmSystemHealthSummary) =>
                element.duplication ? this.getDuplicationTooltip(element) : '',
            width: '7.8%'
        },
        {
            columnDef: 'lspStatus',
            header: 'ESS/',
            subHeadingInFilter: 'LSP',
            cell: (element: AcmSystemHealthSummary): TableIcon => {
                switch (element.lspStatus) {
                    case -1:
                        return {
                            text: '',
                            iconName: 'remove',
                            color: this.getSeverityColor(-1)
                        };
                    default:
                        return {
                            text: '',
                            iconName: 'warning',
                            color: this.getSeverityColor(element.lspStatus)
                        };
                }
            },
            type: 'icon',
            headerTooltip: this.getLSPHeaderTooltip(),
            matTooltipClass: 'custom-tooltip',
            dataTooltip: (element: AcmSystemHealthSummary) => this.getLspTooltip(element),
            width: '4.2%'
        },
        {
            columnDef: 'mediaGatewaysUp',
            header: 'Gateways',
            subHeadingInFilter: 'Up',
            cell: (element: AcmSystemHealthSummary) =>
                element.mediaGatewaysUp.length > 0
                    ? element.mediaGatewaysUp.length.toString()
                    : element.mediaGatewaysUp.length === 0
                    ? '---'
                    : 'N/A',
            type: 'services',
            dataTooltip: (element: AcmSystemHealthSummary) => {
                return this.getMediaGatewaysTooltip('up', element);
            },
            width: '6.7%'
        },
        {
            columnDef: 'mediaGatewaysDown',
            header: 'Gateways',
            subHeadingInFilter: 'Down',
            cell: (element: AcmSystemHealthSummary) =>
                element.mediaGatewaysDown.length > 0
                    ? element.mediaGatewaysDown.length.toString()
                    : element.mediaGatewaysDown.length === 0
                    ? '---'
                    : 'N/A',
            type: 'services',
            dataTooltip: (element: AcmSystemHealthSummary) => {
                return this.getMediaGatewaysTooltip('down', element);
            },
            width: '6.7%'
        },
        {
            columnDef: 'mediaServerStatus',
            header: 'MS',
            cell: (element: AcmSystemHealthSummary) => {
                switch (element.mediaServerStatus) {
                    case -1:
                        return {
                            text: '---',
                            iconName: '',
                            color: this.getSeverityColor(-1)
                        };
                    case 0:
                        return {
                            text: 'Ok',
                            iconName: '',
                            color: this.getSeverityColor(-1)
                        };
                    default:
                        return {
                            iconName: 'warning',
                            color: this.getSeverityColor(element.mediaServerStatus)
                        };
                }
            },
            type: 'icon',
            headerTooltip: this.getMSHeaderTooltip(),
            matTooltipClass: 'custom-tooltip',
            dataTooltip: (element: AcmSystemHealthSummary) => this.getMediaServerTooltip(element),
            width: '3.5%'
        },
        {
            columnDef: 'pnStatus',
            header: 'PN',
            cell: (element: AcmSystemHealthSummary) => {
                switch (element.pnStatus) {
                    case -1:
                        return {
                            text: '---',
                            iconName: '',
                            color: this.getSeverityColor(-1)
                        };
                    case 0:
                        return {
                            text: 'Ok',
                            iconName: '',
                            color: this.getSeverityColor(-1)
                        };
                    default:
                        return {
                            iconName: 'warning',
                            color: this.getSeverityColor(element.pnStatus)
                        };
                }
            },
            type: 'icon',
            headerTooltip: this.getPNHeaderTooltip(),
            matTooltipClass: 'custom-tooltip',
            dataTooltip: (element: AcmSystemHealthSummary) => this.getPnTooltip(element),
            width: '3.5%'
        }
    ];

    constructor(
        private dashletService: DashletService,
        private store$: Store<AppState>,
        private settingsService: DashletSettingsService,
        private timeoutService: TimeoutService
    ) {}

    public ngOnInit(): void {
        this.onLoad();
        this.subscription.add(
            this.dashlet.settingsUpdated.subscribe(() => {
                this.destroy$.next();
                this.destroy$.complete();
                this.store$.dispatch(Actions.stopSummaryDocumentRepeat());
                this.dashlet.resetData();
                this.onLoad();
            })
        );
    }

    public onLoad(): void {
        this.destroy$ = new Subject<void>();
        this.loading = true;

        if (this.dashlet.configured) {
            this.getEntityLogo();
        }

        if (this.isValidCustomer()) {
            this.fetchLocationsAndEquipments();
        }
    }

    private isValidCustomer(): boolean {
        return (
            this.dashlet.customer.customerId !== '' && !!this.dashlet.customer.customerId && !!this.dashlet.locationId
        );
    }

    private getEntityLogo(): void {
        if (this.dashlet.configured) {
            this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
            this.subscription.add(
                this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                    if (logo) {
                        this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                    }
                })
            );
        }
    }

    private fetchLocationsAndEquipments(): void {
        const locations$: Observable<SelectItem[] | string[]> =
            this.dashlet.locationId !== '=all='
                ? of([this.dashlet.locationId])
                : this.settingsService.getLocations(this.dashlet.customer.customerId);

        locations$
            .pipe(
                tap(() => (this.loading = true)),
                mergeMap((locations: any) => this.getEquipment(locations)),
                switchMap((equipmentItems: any) => this.getSummaryData(equipmentItems))
            )
            .subscribe(summaryData => {
                this.dataServersSubject.next(summaryData);
                this.loading = false;
            });
    }

    private getEquipment(locations: string[]): Observable<ServerEquipment[]> {
        const equipmentRequests = locations.map((locationId: any) =>
            this.settingsService.getSystemHealthEquipment(locationId.value ? locationId.value : locationId)
        );

        return forkJoin(equipmentRequests).pipe(
            map(data => {
                const returnObject: ServerEquipment[] = [];
                data.forEach(group => {
                    if (group) {
                        Object.keys(group).forEach(key => {
                            if (key !== 'entityId') {
                                group[key].items.forEach(item => {
                                    returnObject.push({
                                        equipmentGroup: group[key].label,
                                        equipmentName: item.label,
                                        equipmentId: item.value
                                    });
                                });
                            }
                        });
                    }
                });
                return returnObject.filter(equip => equip.equipmentGroup === 'ACM');
            })
        );
    }

    private getSummaryData(equipmentItems: ServerEquipment[]): Observable<AcmSystemHealthSummary[]> {
        if (!equipmentItems || equipmentItems.length === 0) {
            this.loading = false;
            return of([]);
        }

        // Batch the equipment items into groups of 5
        const equipmentIdGroups = this.batch(equipmentItems, 10);
        let returnData: AcmSystemHealthSummary[] = [];
        return interval(60000).pipe(
            startWith(0),
            takeUntil(this.destroy$),
            switchMap(() => from(equipmentIdGroups)),
            mergeMap(group => {
                const summaryRequests = [];
                summaryRequests.push(
                    this.settingsService.makeHealthSummaryApiCallWithIds(
                        group.map(e => e.equipmentId),
                        this.dashlet.commandTypeIdACMSystemHealth
                    )
                );
                summaryRequests.push(
                    this.settingsService.makeHealthSummaryApiCallWithIds(
                        group.map(e => e.equipmentId),
                        this.dashlet.commandTypeIdSystemHealthSummary
                    )
                );

                return forkJoin(summaryRequests);
            }, 5), // Maximum concurrency set to 5
            map(summaryDataBatches => {
                for (let i = 0; i < summaryDataBatches.length; i += 2) {
                    const acmSummary = summaryDataBatches[i];
                    const systemHealthSummary = summaryDataBatches[i + 1];

                    Object.entries(acmSummary).forEach(([equipmentId, dataString]) => {
                        if (typeof dataString === 'string') {
                            const acmSumData = JSON.parse(dataString);
                            const equipName = this.findEquipmentNameById(equipmentItems, equipmentId);
                            const healthSumData = JSON.parse(systemHealthSummary[equipmentId]);
                            const processedRecord = this.processSummaryRow(
                                { ...acmSumData, ...healthSumData },
                                equipName,
                                equipmentId
                            );

                            const index = returnData.findIndex(item => item.equipmentId === equipmentId);

                            if (index > -1) {
                                returnData[index] = processedRecord;
                                returnData = [...returnData];
                            } else {
                                returnData = returnData.concat(processedRecord);
                            }
                        }
                    });
                }
                return returnData;
            }),
            catchError(err => {
                this.loading = false;
                return of([]);
            })
        );
    }

    private findEquipmentNameById(equipmentItems: ServerEquipment[], equipmentId: string): string | null {
        const equipment = equipmentItems.find(item => item.equipmentId === equipmentId);
        return equipment ? equipment.equipmentName : null;
    }

    private batch(arr: any[], size: number): any[][] {
        const batches = [];
        for (let i = 0; i < arr.length; i += size) {
            batches.push(arr.slice(i, i + size));
        }
        return batches;
    }

    public ngOnDestroy(): void {
        if (this.dashlet) this.dashlet.dispose();
        this.subscription.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
        this.store$.dispatch(Actions.stopSummaryDocumentRepeat());
    }

    private processSummaryRow(data: any, equipmentName: string, equipmentId: string): AcmSystemHealthSummary {
        const cpuPercentage: number = this.processSummaryField(data.CPU)
            ? +(+this.processSummaryField(data.CPU)).toFixed(1)
            : undefined;
        const memoryPercentage: number = this.processSummaryField(data.Memory)
            ? +(+this.processSummaryField(data.Memory)).toFixed(1)
            : undefined;
        const diskPercentage: number = this.processDiskPercentage(data.Disk)
            ? +(+this.processSummaryField(this.processDiskPercentage(data.Disk))).toFixed(1)
            : undefined;
        const averagePing: number = this.processSummaryField(data.AveragePing)
            ? +(+this.processSummaryField(data.AveragePing)).toFixed(1)
            : undefined;
        const servicesUp =
            this.processSummaryField(data.ServciesUp) && JSON.parse(this.processSummaryField(data.ServciesUp)).length
                ? JSON.parse(this.processSummaryField(data.ServciesUp))
                : [];
        const servicesDown =
            this.processSummaryField(data.ServciesDown) &&
            JSON.parse(this.processSummaryField(data.ServciesDown)).length
                ? JSON.parse(this.processSummaryField(data.ServciesDown))
                : [];
        const snmpUp =
            this.processSummaryField(data.Snmp) && JSON.parse(this.processSummaryField(data.Snmp)).length
                ? JSON.parse(this.processSummaryField(data.Snmp))?.filter(
                      (snmp: Snmp) => snmp.PeakToneReceivers.toLowerCase() === 'up'
                  )
                : [];
        const snmpDown =
            this.processSummaryField(data.Snmp) && JSON.parse(this.processSummaryField(data.Snmp)).length
                ? JSON.parse(this.processSummaryField(data.Snmp))?.filter(
                      (snmp: Snmp) => snmp.PeakToneReceivers.toLowerCase() === 'down'
                  )
                : [];
        const duplication: Duplication = this.processSummaryField(data.Duplication)
            ? JSON.parse(this.processSummaryField(data.Duplication))
            : undefined;
        const cdrLink: CdrLink[] =
            this.processSummaryField(data.CdrLink) && JSON.parse(this.processSummaryField(data.CdrLink)).length
                ? JSON.parse(this.processSummaryField(data.CdrLink))
                : [];
        const pn: Pn[] =
            this.processSummaryField(data.Pn) && JSON.parse(this.processSummaryField(data.Pn)).length
                ? JSON.parse(this.processSummaryField(data.Pn))
                : [];
        const standBy: StandBy[] =
            this.processSummaryField(data.StandBy) && JSON.parse(this.processSummaryField(data.StandBy)).length
                ? JSON.parse(this.processSummaryField(data.StandBy))
                : [];
        const lsp: Lsp =
            this.processSummaryField(data.Lsp) && JSON.parse(this.processSummaryField(data.Lsp)).length
                ? JSON.parse(this.processSummaryField(data.Lsp))[0]
                : undefined;
        const mediaServer: MediaServer[] =
            this.processSummaryField(data.MediaServer) && JSON.parse(this.processSummaryField(data.MediaServer)).length
                ? JSON.parse(this.processSummaryField(data.MediaServer))
                : [];
        const mediaGateway = this.processSummaryField(data.MediaGateway) || undefined;
        const mediaGatewaysUp: MediaGateway[] =
            mediaGateway && JSON.parse(mediaGateway).length
                ? JSON.parse(mediaGateway).filter((gateway: MediaGateway) => gateway.LinkStatus.toLowerCase() === 'up')
                : [];
        const mediaGatewaysDown: MediaGateway[] =
            mediaGateway && JSON.parse(mediaGateway).length
                ? JSON.parse(mediaGateway).filter((gateway: MediaGateway) => gateway.LinkStatus.toLowerCase() === 'dn')
                : [];
        const mediaGatewaysNoData: MediaGateway[] =
            mediaGateway && JSON.parse(mediaGateway).length
                ? JSON.parse(mediaGateway).filter((gateway: MediaGateway) => gateway.LinkStatus.toLowerCase() === '')
                : [];
        const license = data.License ? JSON.parse(this.processSummaryField(data.License)) : undefined;

        const returnData = {
            severity: -1,
            name: equipmentName || undefined,
            equipmentId: equipmentId || undefined,
            cpuPercentage: cpuPercentage,
            memoryPercentage: memoryPercentage,
            diskPercentage: diskPercentage,
            averagePing: averagePing,
            servicesUp: servicesUp,
            servicesDown: servicesDown,
            linkStatus: this.processLinkStatus(cdrLink),
            licenseStatus: this.processLicenseStatus(license),
            snmpUp: snmpUp,
            snmpDown: snmpDown,
            snmpStatus: this.getSnmpStatus(snmpUp.length, snmpDown.length),
            standByStatus: this.processStandByStatus(standBy),
            duplicationStatus: this.processDuplicationStatus(duplication),
            lspStatus: this.processLspStatus(lsp),
            lsp: lsp,
            mediaServer: mediaServer,
            mediaGatewaysUp: mediaGatewaysUp,
            mediaGatewaysDown: mediaGatewaysDown,
            mediaGatewaysNoData: mediaGatewaysNoData,
            standBy: standBy,
            duplication: duplication,
            mediaServerStatus: this.processMediaServerStatus(mediaServer),
            pn: pn,
            pnStatus: this.processPnStatus(pn),
            cdrLink: cdrLink,
            license: license
        };
        returnData.severity = this.getSeverity(returnData);
        return returnData;
    }

    private getSeverity(acmSystemHealthSummary: AcmSystemHealthSummary): number {
        if (
            acmSystemHealthSummary.cpuPercentage! > 90 ||
            acmSystemHealthSummary.memoryPercentage! > 90 ||
            acmSystemHealthSummary.diskPercentage! > 90 ||
            acmSystemHealthSummary.servicesDown.length > 0 ||
            acmSystemHealthSummary.mediaGatewaysDown.length > 0 ||
            (acmSystemHealthSummary.linkStatus > 1 && acmSystemHealthSummary.linkStatus !== 3) ||
            acmSystemHealthSummary.licenseStatus > 0 ||
            acmSystemHealthSummary.snmpStatus === 2 ||
            acmSystemHealthSummary.standByStatus === 2 ||
            acmSystemHealthSummary.duplicationStatus === 2 ||
            acmSystemHealthSummary.lspStatus === 2 ||
            acmSystemHealthSummary.pnStatus === 2 ||
            acmSystemHealthSummary.mediaServerStatus === 2
        ) {
            return 2;
        } else {
            if (
                acmSystemHealthSummary.cpuPercentage! > 80 ||
                acmSystemHealthSummary.memoryPercentage! > 80 ||
                acmSystemHealthSummary.diskPercentage! > 80 ||
                (acmSystemHealthSummary.linkStatus > 0 && acmSystemHealthSummary.linkStatus !== 3) ||
                acmSystemHealthSummary.standByStatus === 1 ||
                acmSystemHealthSummary.lspStatus === 1
            ) {
                return 1;
            } else if (
                !acmSystemHealthSummary.cpuPercentage &&
                !acmSystemHealthSummary.memoryPercentage &&
                !acmSystemHealthSummary.diskPercentage &&
                !acmSystemHealthSummary.averagePing
            ) {
                return -1;
            } else {
                return 0;
            }
        }
    }
    private getSnmpStatus(snmpUp: number, snmpDown: number) {
        if (snmpDown > 0) {
            return 2;
        } else if (snmpUp === 0 && snmpDown === 0) {
            return -1;
        } else {
            return 0;
        }
    }

    private processSummaryField(data: SummaryField): string {
        if (data?.FieldCommandUpdateTimeInfoList) {
            const dataExpired = this.timeoutService.getIsDataExpired(
                data.FieldCommandUpdateTimeInfoList[0]?.CommandTypeId,
                data.FieldCommandUpdateTimeInfoList[0]?.LastUpdateTime
            );
            if (!dataExpired) {
                return data.FieldValue === null || data.FieldValue === undefined || data.FieldValue === ''
                    ? null
                    : data.FieldValue;
            }
        }
        return null;
    }

    private processDiskPercentage(data: SummaryField): SummaryField {
        if (data?.FieldCommandUpdateTimeInfoList) {
            const dataExpired = this.timeoutService.getIsDataExpired(
                data.FieldCommandUpdateTimeInfoList[0]?.CommandTypeId,
                data.FieldCommandUpdateTimeInfoList[0]?.LastUpdateTime
            );
            if (!dataExpired) {
                const diskObject = data?.FieldValue
                    ? JSON.parse(data.FieldValue).filter(disk => (disk.DiskName = 'Total'))[0]
                    : null;
                return { ...data, FieldValue: diskObject?.DiskUsedPercentage };
            }
        }
        return null;
    }

    private processDuplicationStatus(duplication: Duplication): number {
        if (!duplication) {
            return -1;
        }
        if (
            duplication.ClusterId !== '' &&
            duplication.StandbyBusied === '' &&
            duplication.StandbyShadowing === '' &&
            duplication.StandbyRefreshed === '' &&
            duplication.DuplicationLink === ''
        ) {
            return 3; // special status for N/A duplication
        }

        const elapsedTime = this.parseElapsedTimeMinutes(duplication.ElapsedTimeSinceInterchange);

        if (
            duplication.StandbyShadowing !== 'on' ||
            duplication.StandbyRefreshed !== 'yes' ||
            duplication.DuplicationLink !== 'up' ||
            elapsedTime < 1440
        ) {
            return 2;
        } else if (duplication.StandbyBusied === 'yes') {
            return 1;
        } else {
            return 0;
        }
    }
    private parseElapsedTimeMinutes(timeStamp: string): number {
        const split = timeStamp.split(' ');
        const days = parseInt(split[0].replace('d', ''));
        const time = split[1].split(':');
        const hours = parseInt(time[0]);
        let minutes = parseInt(time[1]);

        minutes += (days * 24 + hours) * 60;
        return minutes;
    }

    private processMediaServerStatus(mediaServer: MediaServer[]): number {
        const mediaServerStatus =
            mediaServer.length === 0
                ? -1
                : mediaServer.some(item => {
                      return item.State.toLowerCase() !== 'ins';
                  })
                ? 2
                : 0;
        return mediaServerStatus;
    }

    private processLspStatus(lsp: Lsp): number {
        if (!lsp) {
            return -1;
        }
        const date = Date.parse(lsp.TranslationsUpdated);
        const now = Date.now();
        const hours = (now - date) / 3600000; // time difference in hours
        return lsp.Active === 'y' ? 2 : lsp.Registered === 'n' || hours > 24 ? 1 : 0;
    }

    private processPnStatus(pn: Pn[]): number {
        return pn.length === 0
            ? -1
            : pn.some(item => {
                  return item.ServerState.toLowerCase() !== 'in';
              }) ||
              pn.some(item => {
                  return item.ControlState.toLowerCase() !== 'actv-aa' && item.ControlState.toLowerCase() !== 'standby';
              })
            ? 2
            : 0;
    }

    private getSeverityColor(severity: number): string {
        return this.dashletService.getSeverityColor(severity);
    }

    private getSeverityReasons(item: AcmSystemHealthSummary): string {
        let snmpTotal: number = item.snmpDown.length + item.snmpUp.length;
        if (item.severity === -1) {
            return 'No data received';
        } else if (item.severity === 0) {
            return 'All systems ok';
        } else if (item.severity === 2) {
            let reasonsArray: string[] = [];

            if (item.cpuPercentage > 90) {
                reasonsArray.push('CPU > 90%');
            }
            if (item.memoryPercentage > 90) {
                reasonsArray.push('Memory > 90%');
            }
            if (item.diskPercentage > 90) {
                reasonsArray.push('Disk Space Used > 90%');
            }
            if (item.servicesDown.length > 0) {
                reasonsArray.push(`${item.servicesDown.length} services are down`);
            }
            if (item.mediaGatewaysDown.length > 0) {
                reasonsArray.push(`${item.mediaGatewaysDown.length} media gateways are down`);
            }
            if (item.linkStatus > 1 && item.linkStatus !== 3) {
                reasonsArray.push('Primary CDR Link down');
            }
            if (item.license?.CommuncationManagerLicenseMode?.toLowerCase() !== 'normal') {
                reasonsArray.push('License mode != normal');
            }
            if (item.snmpStatus === 2) {
                reasonsArray.push(`${item.snmpDown.length}/${snmpTotal} SNMP services down`);
            }
            if (item.standByStatus === 2) {
                reasonsArray.push(
                    'Either Stand By or Active servers have major alarms, or hardware or processor is not okay'
                );
            }
            if (item.duplicationStatus === 2) {
                reasonsArray.push('Duplication issues detected');
            }
            if (item.lspStatus === 2) {
                reasonsArray.push('ESS/LSP active=y');
            }
            if (item.mediaServerStatus === 2) {
                reasonsArray.push('At least one media server state != INS');
            }
            if (item.pnStatus === 2) {
                reasonsArray.push('PN active=y');
            }

            let reasonsBox: string = reasonsArray.join(' \n');
            return reasonsBox;
        } else if (item.severity === 1) {
            let reasonsArray: string[] = [];

            if (item.cpuPercentage > 80) {
                reasonsArray.push('CPU > 80%');
            }
            if (item.memoryPercentage > 80) {
                reasonsArray.push('Memory > 80%');
            }
            if (item.diskPercentage > 80) {
                reasonsArray.push('Disk Space Used > 80%');
            }
            if (item.linkStatus > 0 && item.linkStatus !== 3) {
                reasonsArray.push('Secondary CDR Link down');
            }
            if (item.standByStatus === 1) {
                reasonsArray.push('Stand By or Active servers have minor alarms');
            }
            if (item.lspStatus === 1) {
                reasonsArray.push('Translations update < 24, or reg=n');
            }

            let reasonsBox: string = reasonsArray.join(' \n');
            return reasonsBox;
        }
    }

    private getAvgPingHeaderTooltip(): string {
        return 'Average ping time from three tests at configured interval';
    }

    private getCDRHeaderTooltip(): string {
        return 'Shows status of the CDR Link \n Green: Both Primary and Secondary up\nAmber: Secondary is down\nRed: Primary is down';
    }

    private getStandByHeaderTooltip(): string {
        return 'Shows status of Active and Stand by servers\nOk: Both Active and Standby servers have no alarms,  hardware and processor is ok\nAmber: Either Active or Standby servers have minor alarms\nRed: Either active or Standby servers have major alarms, or hardware or processor is not ok';
    }

    private getDuplicationHeaderTooltip(): string {
        return 'N/A: No Duplication\nOk: Standby busied = no, Standby refreshed = yes, Standby shadowing= on, Duplication link = up\nAmber: Standby busied = yes\nRed: Standby refreshed != yes, or Standby shadowing != on, or Duplication link != up, or Elaspsed Time since Last interchange = 0d';
    }

    private getLSPHeaderTooltip(): string {
        return 'Green: reg=y, and act=n, and translations update =< 24hr\nAmber: Translations update > 24hr, or reg=n\nRed: active=y';
    }
    private getMSHeaderTooltip(): string {
        return 'Green: All Media servers state=INS\nRed: Any Media servers state !=INS';
    }

    private getPNHeaderTooltip(): string {
        return 'Green: Server State = IN\nRed: Server State != IN';
    }

    private processLinkStatus(cdrLink: CdrLink[]): number {
        if (cdrLink.length > 0) {
            if (
                (cdrLink[0].Status === 'up' && cdrLink[1].Status === 'up') ||
                (cdrLink[0].Status === 'up' && cdrLink[1].Status === 'CDR not administered')
            ) {
                return 0;
            } else if (cdrLink[0].Status === 'down') {
                return 2;
            } else if (cdrLink[1].Status === 'down') {
                return 1;
            } else {
                return 3;
            }
        }
    }

    private processLicenseStatus(license: License): number {
        const communcationManagerLicenseMode = license?.CommuncationManagerLicenseMode || '';
        return communcationManagerLicenseMode === ''
            ? -1
            : communcationManagerLicenseMode.toLowerCase() === 'normal'
            ? 0
            : 2;
    }

    private processStandByStatus(standBy: StandBy[]): number {
        return standBy.length === 0
            ? -1
            : standBy.every(value => {
                  return (
                      value.MinorAlarms === 'no' &&
                      value.MajorAlarms === 'no' &&
                      value.ServerHardware === 'okay' &&
                      value.Processes === 'okay'
                  );
              })
            ? 0
            : standBy.some(sb => sb.MinorAlarms === 'yes') &&
              standBy.every(value => {
                  return value.MajorAlarms === 'no' && value.ServerHardware === 'okay' && value.Processes === 'okay';
              })
            ? 1
            : 2;
    }

    private getLinkTooltip(item: AcmSystemHealthSummary): string {
        if (item.cdrLink === undefined || item.cdrLink.length === 0) return 'No data';
        let primaryLinkTooltip = `${item.cdrLink[0].Name} : ${item.cdrLink[0].Status}`;
        let secondaryLinkTooltip = `${item.cdrLink[1].Name}: ${item.cdrLink[1].Status}`;
        if (item.cdrLink[0].Status.toLowerCase() === 'up' && item.cdrLink[0].CDRPercentageBufferFull >= 0) {
            const percent = Math.round(item.cdrLink[0].CDRPercentageBufferFull);
            primaryLinkTooltip += ` - ${percent}% buffer utilization`;
        }
        if (item.cdrLink[1].Status.toLowerCase() === 'up' && item.cdrLink[1].CDRPercentageBufferFull) {
            const percent = Math.round(item.cdrLink[1].CDRPercentageBufferFull);
            secondaryLinkTooltip += ` - ${percent}% buffer utilization`;
        }
        return primaryLinkTooltip + ' \n' + secondaryLinkTooltip;
    }

    private getLicenceTooltip(item: AcmSystemHealthSummary): string {
        switch (item.licenseStatus) {
            case -1:
                return 'No data';

            case 0:
                return 'License Mode: Normal';

            case 2:
                return 'License Mode: NOT';
        }
    }

    private getServicesTooltip(direction: 'down' | 'up', item: AcmSystemHealthSummary): string {
        switch (direction) {
            case 'up':
                return this.getServicesUpTooltip(item);

            case 'down':
                return this.getServicesDownTooltip(item);
        }
    }

    private getMediaGatewaysTooltip(direction: 'down' | 'up', item: AcmSystemHealthSummary): string {
        switch (direction) {
            case 'up':
                return this.getMediaGatewaysUpTooltip(item);

            case 'down':
                return this.getMediaGatewaysDownTooltip(item);
        }
    }

    private getSNMPTooltip(direction: 'down' | 'up', item: AcmSystemHealthSummary): string {
        switch (direction) {
            case 'up':
                return this.getSnmpUpTooltip(item);

            case 'down':
                return this.getSnmpDownTooltip(item);
        }
    }

    private getServicesUpTooltip(item: AcmSystemHealthSummary): string {
        return `${item.servicesUp.length}/${item.servicesUp.length + item.servicesDown.length} services up`;
    }

    private getServicesDownTooltip(item: AcmSystemHealthSummary): string {
        if (item.servicesDown.length > 0) {
            let down: string = '';
            item.servicesDown.forEach(service => {
                down += service.serviceName + '\n';
            });
            return (
                `The following ${item.servicesDown.length}/${
                    item.servicesUp.length + item.servicesDown.length
                } services are down:\n` + down
            );
        }
    }

    private getMediaGatewaysUpTooltip(item: AcmSystemHealthSummary): string {
        return `${item.mediaGatewaysUp.length}/${
            item.mediaGatewaysUp.length + item.mediaGatewaysDown.length + item.mediaGatewaysNoData.length
        } media gateways up`;
    }

    private getMediaGatewaysDownTooltip(item: AcmSystemHealthSummary): string {
        if (item.mediaGatewaysDown.length > 0) {
            let down: string = '';
            item.mediaGatewaysDown.forEach(gateway => {
                down += gateway.Server + '\n';
            });
            return (
                `The following ${item.mediaGatewaysDown.length}/${
                    item.mediaGatewaysUp.length + item.mediaGatewaysDown.length + item.mediaGatewaysNoData.length
                } media gateways are down:\n` + down
            );
        }
    }

    private getSnmpUpTooltip(item: AcmSystemHealthSummary): string {
        return `${item.snmpUp.length}/${item.snmpUp.length + item.snmpDown.length} Up`;
    }
    private getSnmpDownTooltip(item: AcmSystemHealthSummary): string {
        return `${item.snmpDown.length}/${item.snmpUp.length + item.snmpDown.length} Down`;
    }

    private getStandByTooltip(item: AcmSystemHealthSummary): string {
        let tooltip: string = '';
        item.standBy.forEach(server => {
            tooltip += `${server.Mode} server ${server.ServerID}: ${server.Server}\nMajor Alarms: ${server.MajorAlarms}\nMinor Alarms: ${server.MinorAlarms}\nServer Hardware: ${server.ServerHardware}\nProcesses: ${server.Processes}\n`;
        });
        return tooltip;
    }

    private getDuplicationTooltip(item: AcmSystemHealthSummary): string {
        return item.duplicationStatus === 3
            ? 'Simplex'
            : `Standby Busied: ${item.duplication.StandbyBusied}\nStandby Refreshed: ${item.duplication.StandbyRefreshed}\nStandby Shadowing: ${item.duplication.StandbyShadowing}\nDuplication Link: ${item.duplication.DuplicationLink}\nElapsed Time since last Inst/Interchange: ${item.duplication.ElapsedTimeSinceInterchange}`;
    }

    private getLspTooltip(item: AcmSystemHealthSummary): string {
        if (!item.lsp) return '';
        return `Registered: ${item.lsp.Registered}\nActive: ${item.lsp.Active}\nTranslations update: ${item.lsp.TranslationsUpdated}`;
    }

    private getPnTooltip(item: AcmSystemHealthSummary): string {
        switch (item.pnStatus) {
            default:
                return '';
            case 0:
                return `${item.pn.length}/${item.pn.length} port networks ok`;
            case 2:
                // eslint-disable-next-line no-case-declarations
                let tooltip: string = '';
                // eslint-disable-next-line no-case-declarations
                let number = 0;
                item.pn.forEach(data => {
                    if (
                        data.ServerState.toLowerCase() !== 'in' ||
                        (data.ControlState.toLowerCase() !== 'actv-aa' && data.ControlState.toLowerCase() !== 'standby')
                    ) {
                        tooltip += `${data.Port}: Server state = ${data.ServerState}, Control state = ${data.ControlState}\n`;
                        number++;
                    }
                });
                return `${number}/${item.pn.length} port networks not ok:\n` + tooltip;
        }
    }

    private getMediaServerTooltip(item: AcmSystemHealthSummary): string {
        switch (item.mediaServerStatus) {
            default:
                return '';
            case 0:
                return `${item.mediaServer.length}/${item.mediaServer.length} Media servers ok`;
            case 2:
                // eslint-disable-next-line no-case-declarations
                let notOk = 0;
                // eslint-disable-next-line no-case-declarations
                let tooltip = '';
                item.mediaServer.forEach(item => {
                    if (item.State.toLowerCase() !== 'ins') {
                        tooltip += `${item.NodeName}: state=${item.State}`;
                        notOk++;
                    }
                });
                return `${notOk}/${item.mediaServer.length} Media servers not in INS state:\n` + tooltip;
        }
    }
}
