import * as Actions from '@actions/index';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ColumnTypes, DashletIPNREndpoints, IPNREndpointsData } from '@models/index';
import { DashletService } from '@services/index';
import { select, Store } from '@ngrx/store';

import { AppState, selectEntity, selectDataFromCommonEntity } from '@reducers/index';

import { CustomerLogo } from '@models/index';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-dashlet-ipnrendpoints',
    templateUrl: './dashlet-ipnrendpoints.component.html',
    styleUrls: ['./dashlet-ipnrendpoints.component.scss']
})
export class DashletIPNREndpointsComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletIPNREndpoints;
    public loading = true;
    scrollHeight = 5;

    public columns: ColumnTypes[] = [
        {
            columnDef: 'severity',
            header: '',
            cell: (row: IPNREndpointsData) => `${row.BGColor}`,
            type: 'severity',
            dataTooltip: (row: IPNREndpointsData) => this.getSeverityToolTip(row.BGColor)
        },
        {
            columnDef: 'IPNRNumber',
            header: 'No.',
            cell: (row: IPNREndpointsData) => row.IPNRNumber
        },
        {
            columnDef: 'IPNRName',
            header: 'Name',
            cell: (row: IPNREndpointsData) => row.IPNRName,
            filterType: 'text'
        },
        {
            columnDef: 'IPEndpointsAverage',
            header: 'Avg',
            cell: (row: IPNREndpointsData) => row.IPEndpointsAverage,
            type: 'numeric'
        },
        {
            columnDef: 'IPEndpointsNow',
            header: 'Now',
            cell: (row: IPNREndpointsData) => row.IPEndpointsNow,
            type: 'numeric'
        }
    ];

    private subscription: Subscription = new Subscription();

    constructor(private dashletService: DashletService, private store$: Store<AppState>) {}

    public ngOnInit(): void {
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );

        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipmentId,
                command: this.dashlet.commandTypeIdIPNREndpoints
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdIPNREndpoints
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(this.dashlet.equipmentId + this.dashlet.commandTypeIdIPNREndpoints)
                    )
                )
                .subscribe(data => {
                    if (data) {
                        this.loading = false;
                        this.dashlet.processDataIpo(data);
                    }
                })
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.dashlet.dispose();
    }

    getLayout() {
        const s = this.dashlet.getSize();
        if (s !== null) {
            return s.id;
        }
        return -1;
    }

    getSeverityToolTip(severity: number): string {
        if (this.dashletService.getSeverityColor(severity) === 'rgb(220, 55, 41)') {
            return 'The average of Registered IP Endpoints has deviated 30% from the average registered over the last 5 days.';
        } else {
            return '';
        }
    }

    getTableSize(): number {
        switch (this.dashlet.getSize().id) {
            case 0:
                return 4;

            case 1:
                return 14;

            case 2:
                return 18;
        }
        return 5;
    }
}
