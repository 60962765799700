import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as Actions from '@actions/index';
import { select, Store } from '@ngrx/store';
import { AppState, selectEntityTimer, selectEntity, selectDataFromCommonEntity } from '@reducers/index';
import { CustomerLogo, DashletGenesysDetailsSummary, ChartColors, GenesysServiceStatusData } from '@models/index';
import { filter, Subscription } from 'rxjs';
import { DashletService } from '@services/index';
import { DashletLineGraphComponent } from '../dashlet-line-graph/dashlet-line-graph.component';
import { startCase } from 'lodash';
import { environment } from '@environments/environment';

interface DonutChartData {
    labels: string[];
    datasets: DonutChartSet[];
}

interface DonutChartSet {
    data: number[];
    backgroundColor: string[];
}

@Component({
    selector: 'app-dashlet-genesys-details-summary',
    templateUrl: './dashlet-genesys-details-summary.component.html',
    styleUrls: ['./dashlet-genesys-details-summary.component.scss']
})
export class DashletGenesysDetailsSummaryComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletGenesysDetailsSummary;
    public chartColors: ChartColors = this.getChartColors();
    public loading: boolean;
    private subscription: Subscription = new Subscription();
    private pNetworkChart: DashletLineGraphComponent;
    get networkChart() {
        return this.pNetworkChart;
    }
    @ViewChild('networkChart') set networkChart(value: DashletLineGraphComponent) {
        this.pNetworkChart = value;
        this.updateChart();
    }

    public callRecordsCount: number = 0;
    public callDurationCount: number = 0;
    public uniqueUsersCount: number = 0;
    public participantCount: number = 0;

    public streamSummary = {
        good: 0,
        satisfactory: 0,
        poor: 0
    };

    public options = {
        cutout: '80%',
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        },
        layout: {
            padding: {
                left: 10,
                right: 10
            }
        }
    };

    private goodStreamData = {
        label: 'Good Stream',
        borderColor: this.chartColors.green,
        backgroundColor: this.chartColors.green,
        pointRadius: 0,
        pointHitRadius: 10,
        data: [],
        spanGap: false,
        tension: 0.1,
        fill: true
    };
    private satisfactoryData = {
        label: 'Satisfactory Stream',
        borderColor: this.chartColors.amber,
        backgroundColor: this.chartColors.amber,
        pointRadius: 0,
        pointHitRadius: 10,
        data: [],
        spanGap: false,
        tension: 0.1,
        fill: true
    };
    private poorStreamData = {
        label: 'Poor Stream',
        borderColor: this.chartColors.red,
        backgroundColor: this.chartColors.red,
        pointRadius: 0,
        pointHitRadius: 10,
        data: [],
        spanGap: false,
        tension: 0.1,
        fill: true
    };

    public streamData: any = {};
    public networkChartOptions: any = {
        scales: {
            x: {
                type: 'time',
                max: null,
                min: null,
                grid: {
                    display: false
                },
                time: {
                    unit: 'hour',
                    displayFormats: {
                        minute: 'HH:mm'
                    },
                    stepSize: 2
                },
                ticks: {
                    fontSize: 10.2,
                    autoSkip: true,
                    maxRotation: 0,
                    minRotation: 0
                }
            },
            y: {
                type: 'linear',
                min: 0,
                stacked: true,
                beginAtZero: true,
                grid: {
                    display: false
                },
                ticks: {
                    autoSkip: false,
                    fontSize: 10.2
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'bottom'
            },
            tooltip: {
                displayColors: true
            },
            filler: {
                propagate: true
            }
        },

        animation: {
            duration: 0
        },
        elements: {
            line: {
                borderJoinStyle: 'round'
            }
        }
    };

    public mediaTypeChartData: DonutChartData;
    public originatingDirectionChartData: DonutChartData;
    public purposeChartData: DonutChartData;
    public agentsChartData: DonutChartData;

    public mediaType: object = {
        callback: 0,
        chat: 0,
        cobrowse: 0,
        email: 0,
        message: 0,
        screenshare: 0,
        video: 0,
        voice: 0,
        unknown: 0
    };
    public originatingDirection = {
        outbound: 0,
        inbound: 0
    };
    public purpose: object = {
        acd: 0,
        agent: 0,
        api: 0,
        botflow: 0,
        campaign: 0,
        customer: 0,
        dialer: 0,
        external: 0,
        fax: 0,
        group: 0,
        inbound: 0,
        ivr: 0,
        manual: 0,
        outbound: 0,
        station: 0,
        user: 0,
        voicemail: 0,
        workflow: 0
    };

    public agents = {
        configured: null,
        'logged-In': null
    };

    public services: GenesysServiceStatusData[] = [];

    public isDataOld: boolean = false;

    private getChartColors(): ChartColors {
        return this.dashletService.getChartColors();
    }

    public chartColorsArray = [
        this.chartColors.blue,
        this.chartColors.pink,
        this.chartColors.cyan,
        this.chartColors.brownLight,
        this.chartColors.purpleDark,
        this.chartColors.blueLight,
        this.chartColors.blueDarker,
        this.chartColors.yellow,
        this.chartColors.cyanDark,
        this.chartColors.greyDarker
    ];

    private getColorsArray(labelArray: string[]): string[] {
        const colorsArray: string[] = [];

        //Loops though the chartColorsArray, in case labelArray is bigger
        for (let i = 0; i < labelArray.length; i++) {
            colorsArray.push(this.chartColorsArray[i % this.chartColorsArray.length]);
        }

        return colorsArray;
    }

    public headings = ['Session Media Type', 'Session Originating Direction', 'Session Purpose', 'Agents'];
    public showAgentsChart = true;

    constructor(private store$: Store<AppState>, private dashletService: DashletService) {
        this.streamData = {
            datasets: [this.goodStreamData, this.satisfactoryData, this.poorStreamData]
        };
    }
    ngOnInit() {
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );
        this.subscription.add(
            this.dashlet.settingsChanges.subscribe(() => {
                this.dashlet.resetData();
                if (this.networkChart) {
                    this.networkChart.updateChart();
                }
                this.loadDocument();
            })
        );
        this.loadDocument();
    }

    private updateChart() {
        if (this.networkChart) {
            this.goodStreamData.data = Array.from(this.dashlet.dataMap.entries())
                .map(([timeStamp, document]) => {
                    return {
                        x: new Date(timeStamp),
                        y: Number(document.data[0].StreamQualityGood)
                    };
                })
                .sort((a, b) => a.x.getTime() - b.x.getTime());

            this.satisfactoryData.data = Array.from(this.dashlet.dataMap.entries())
                .map(([timeStamp, document]) => {
                    return {
                        x: new Date(timeStamp),
                        y: Number(document.data[0].StreamQualitySatisfactory)
                    };
                })
                .sort((a, b) => a.x.getTime() - b.x.getTime());

            this.poorStreamData.data = Array.from(this.dashlet.dataMap.entries())
                .map(([timeStamp, document]) => {
                    return {
                        x: new Date(timeStamp),
                        y: Number(document.data[0].StreamQualityPoor)
                    };
                })
                .sort((a, b) => a.x.getTime() - b.x.getTime());

            this.streamData = {
                datasets: [this.goodStreamData, this.satisfactoryData, this.poorStreamData]
            };
            this.networkChart.updateChart();
        }
    }

    private loadDocument(): void {
        this.loading = true;

        // Genesys Call Summary
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipmentId,
                command: this.dashlet.commandTypeIdGenesysCallSummary
            })
        );

        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdGenesysCallSummary
            })
        );

        this.store$.dispatch(
            Actions.GetEntityTimer({
                equipmentId: this.dashlet.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdGenesysCallSummary,
                uniqueId: this.dashlet.equipmentId + this.dashlet.commandTypeIdGenesysCallSummary
            })
        );

        this.store$.dispatch(
            Actions.GetCommonHistoric({
                equipmentId: this.dashlet.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdGenesysCallSummary,
                from: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                to: new Date().toISOString(),
                max: this.dashlet.hourSetting * 4
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipmentId + this.dashlet.commandTypeIdGenesysCallSummary
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length) {
                        data.forEach(doc => {
                            this.dashlet.processIncomingConversationDocument(doc);
                        });
                        this.processData();
                        const targetSize = this.dashlet.hourSetting * 4;
                        const tolerancePercentage = 10; // 10% tolerance, adjust as we are not receiving 96 docs for 24 hours.

                        const lowerBound = targetSize - targetSize * (tolerancePercentage / 100);
                        const upperBound = targetSize + targetSize * (tolerancePercentage / 100);

                        if (this.dashlet.dataMap.size >= lowerBound && this.dashlet.dataMap.size <= upperBound) {
                            this.loading = false;
                        }
                    }
                })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(selectEntityTimer(this.dashlet.equipmentId + this.dashlet.commandTypeIdGenesysCallSummary)),
                    filter((dataTimeoutExpired: boolean | undefined) => typeof dataTimeoutExpired === 'boolean')
                )
                .subscribe((dataTimeoutExpired: boolean) => {
                    this.dashlet.isDataOld = dataTimeoutExpired;
                })
        );

        // Genesys Agent Summary
        // Don't request historical data since this dashlet item only shows the current state of logged in agents
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipmentId,
                command: this.dashlet.commandTypeIdGenesysAgentSummary
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipmentId + this.dashlet.commandTypeIdGenesysAgentSummary
                        )
                    )
                )
                .subscribe(data => {
                    if (!data || !data.length) {
                        this.agents = { configured: null, 'logged-In': null }; // Set values to null to trigger warning message if no data is available.
                        this.updateAgentsGraph(false);
                    } else {
                        const agentData = this.dashlet.processAgentDocument(data[0]);
                        this.agents = { configured: agentData.TotalAgents, 'logged-In': agentData.LoggedInAgents };
                        this.updateAgentsGraph(true);
                    }
                })
        );

        // Genesys Cloud Service Status By Customer Region
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipmentId,
                command: this.dashlet.commandTypeIdGenesysServiceStatus
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipmentId + this.dashlet.commandTypeIdGenesysServiceStatus
                        )
                    )
                )
                .subscribe(data => {
                    if (!data || !data.length) {
                        this.services = [];
                    } else {
                        this.services = this.dashlet.processGenesysServiceStatusDocument(data);
                    }
                })
        );
    }

    private updateAgentsGraph(showAgentChart: boolean): void {
        this.showAgentsChart = showAgentChart;
        this.agentsChartData = this.UpdateAgentType();
    }

    private processData(): void {
        const data = this.dashlet.getTotalSummary();
        this.callRecordsCount = data.callRecordsCount;
        this.callDurationCount = data.callDurationCount;
        this.participantCount = data.participantCount;
        this.uniqueUsersCount = data.uniqueUsersCount;
        this.streamSummary = data.streamSummary;
        this.mediaType = this.sortObjectByValuesDescending(data.mediaType);
        this.purpose = this.sortObjectByValuesDescending(data.purpose);
        this.originatingDirection = data.originatingDirection;

        this.originatingDirectionChartData = this.UpdateOriginatingDirectionType();
        this.purposeChartData = this.UpdatePurposeType();
        this.mediaTypeChartData = this.UpdateMediaType();

        this.updateChart();
    }

    private UpdateMediaType(): DonutChartData {
        const labels = Object.keys(this.mediaType).map(key => startCase(key));
        return {
            labels,
            datasets: [
                {
                    data: Object.values(this.mediaType),
                    backgroundColor: this.getColorsArray(labels)
                }
            ]
        };
    }

    private UpdateOriginatingDirectionType(): DonutChartData {
        const labels = Object.keys(this.originatingDirection).map(key => startCase(key));
        return {
            labels,
            datasets: [
                {
                    data: Object.values(this.originatingDirection),
                    backgroundColor: this.getColorsArray(labels)
                }
            ]
        };
    }

    private UpdatePurposeType(): DonutChartData {
        const labels = Object.keys(this.purpose).map(key => (key.length === 3 ? key.toUpperCase() : startCase(key)));

        return {
            labels,
            datasets: [
                {
                    data: Object.values(this.purpose),
                    backgroundColor: this.getColorsArray(labels)
                }
            ]
        };
    }

    private UpdateAgentType(): DonutChartData {
        const labels = Object.keys(this.agents).map(key => startCase(key));
        return {
            labels,
            datasets: [
                {
                    data: Object.values(this.agents),
                    backgroundColor: this.getColorsArray(labels)
                }
            ]
        };
    }

    public ngOnDestroy(): void {}

    public getStyleForEntry(color: string): object {
        return { 'border-left-color': color };
    }

    public getServiceStatusColour(colour: string): object {
        return { color: `${this.chartColors[colour]}` };
    }

    public isDataAvailable(data: DonutChartData | null): boolean {
        if (!data || !data.datasets || data.datasets.length === 0) return false;

        // If all data in the dataset is either null or zero, then the data is in a default state.
        // Hence, no data is available.
        return data.datasets.flatMap(d => d.data).filter(x => x !== null || x > 0).length > 0;
    }

    private sortObjectByValuesDescending(objectToSort: { [key: string]: number }): { [key: string]: number } {
        let entries = Object.entries(objectToSort);
        entries.sort((a, b) => b[1] - a[1]);
        return Object.fromEntries(entries);
    }

    public openCloudConversationGrid(): void {
        window
            .open(
                `${environment.webPortalUrl}Availability/GenesysCloudConversationRecords?customerId=${this.dashlet.customer.customerId}`,
                '_blank'
            )
            .focus();
    }

    public openMOSDistributionReport(): void {
        window
            .open(
                `${environment.reportingUrl}viewer?entityId=${this.dashlet.customer.customerId}&locationId=${this.dashlet.location.locationId}&equipmentId=${this.dashlet.equipment.equipmentId}&reportId=8e2b20e5-7ba9-42da-b40b-cf3bbe2da8db&reportName=Mean%20Opinion%20Score%20Distributions`,
                '_blank'
            )
            .focus();
    }

    public openLicenseUsageTrendReport(): void {
        window
            .open(
                `${environment.reportingUrl}viewer?entityId=${this.dashlet.customer.customerId}&locationId=${this.dashlet.location.locationId}&equipmentId=${this.dashlet.equipment.equipmentId}&reportId=d1260470-eb48-42b3-b55e-fe353308ac4f&reportName=License%20Usage%20Trend`,
                '_blank'
            )
            .focus();
    }

    public openLicenseCapacityReport(): void {
        window
            .open(
                `${environment.reportingUrl}viewer?entityId=${this.dashlet.customer.customerId}&locationId=${this.dashlet.location.locationId}&equipmentId=${this.dashlet.equipment.equipmentId}&reportId=ebac17db-862b-49fc-9472-cb01a9d8eb4d&reportName=License`,
                '_blank'
            )
            .focus();
    }

    public openVSMDevicesGrid(): void {
        window
            .open(
                `${environment.webPortalUrl}VSMEverywhereDevices?customerId=${this.dashlet.customer.customerId}`,
                '_blank'
            )
            .focus();
    }
}
