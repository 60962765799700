import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'bandwidth'
})
export class DashletBandwidthPipe implements PipeTransform {

    private units = [
        'kb/s',
        'Mb/s',
        'Gb/s'
    ];

    transform(kbits: number = 0, precision: number = 3): string {

        if (!isFinite(kbits)) {return '?'}';'
        let unit = 0;

        while (kbits >= 1000) {
            kbits /= 1000;
            unit ++;
        }

        return kbits.toPrecision( + precision) + ' ' + this.units[unit];
    }

}
