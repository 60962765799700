import { cloneDeep } from 'lodash';
import { Customer, Dashlet, IPNRBandwidthData } from '@models/index';
import { DashletService, RealtimeGatewayService } from '@services/index';
import { Subscription } from 'rxjs';

export class DashletIPNRBandwidth extends Dashlet {
    public readonly commandTypeIdIPNRBandwidth = 'C5718263-90DA-4D95-84FB-9B42ED281D8F';

    public equipmentId!: string;
    public locationId!: string;
    public customer!: Customer;
    public summaryPeriod: any;
    public filteredIpnrs: IPNRBandwidthData[] = [];
    public rawData!: any[];
    public showAllIpnrs: boolean;
    public schematicFileName!: string;
    public schematicBlobId!: string;
    public documentTimeStamps: any[] = [];

    private ipnrs: IPNRBandwidthData[] = [];

    constructor(private realtimeService: RealtimeGatewayService, private dashletService: DashletService) {
        super();
        this.sizes = [
            {
                id: 0,
                label: 'Standard',
                cols: 9,
                rows: 6
            }
        ];

        this.applySize(0);
        this.resetData();
        this.showAllIpnrs = false;
    }

    public get customerId(): any {
        return this.customer.customerId;
    }

    applySettings(v: { [key: string]: any }): void {
        super.applySettings(v);

        // unsub realtime feed
        this.unsubData();

        // read settings object
        this.configured = v.customer && v.summaryPeriod && v.location && v.equipment;
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        this.locationId = v.location && v.location.value ? v.location.value : null;

        this.equipmentId = v.equipment && v.equipment.value ? v.equipment.value : null;
        this.generatedNameTag = this.configured ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;

        this.summaryPeriod = v.summaryPeriod;
        // update size
        this.updateSize();
    }

    applySize(id: number): void {
        super.applySize(id);
        this.updateSize();
    }

    private updateSize(): void {
        const h = 0;
        const w = 0;
        this.applySizeExpansion(w, h);
    }

    public processData(dataArray: any[]) {
        if (dataArray) {
            dataArray.forEach(dataRow => {
                if (dataRow) {
                    if (!this.checkDataIfProcessed(dataRow.id, dataRow.timestamp)) {
                        this.processArrayData(dataRow.data);
                    }
                }
            });
        }
    }

    private checkDataIfProcessed(id: string, timestamp: string) {
        const index = this.documentTimeStamps.findIndex(document => {
            return document.id === id && document.timestamp === timestamp;
        });

        if (index === -1) {
            this.documentTimeStamps.push({ id: id, timestamp: timestamp });
            return false;
        }

        return true;
    }

    private processArrayData(rawData: any) {
        this.schematicFileName = rawData[0]?.SchematicFileName;
        this.schematicBlobId = rawData[0]?.SchematicBlobId;

        let parentIndex = 0;
        rawData.forEach(element => {
            const newIPNR: IPNRBandwidthData = new IPNRBandwidthData(this.dashletService);
            newIPNR.IPNRNumber = parseInt(element.IpnrNumber, 10);
            newIPNR.IPNRName = element.IpnrName;
            newIPNR.branches = parseInt(element.NumChildNodes, 10);
            newIPNR.streams = parseInt(element.NumStreams, 10);

            newIPNR.mosgood = parseInt(element.Mos40, 10);
            newIPNR.moswarning = parseInt(element.Mos36_40, 10);
            newIPNR.mosdanger = parseInt(element.Mos10_36, 10);

            if (element.IsChildNode === 'False') {
                const index = this.ipnrs.findIndex(ipnr => {
                    return ipnr.IPNRNumber === newIPNR.IPNRNumber;
                });
                if (index < 0) {
                    this.ipnrs.push(newIPNR);
                    parentIndex = this.ipnrs.length - 1;
                } else {
                    this.ipnrs[index].add(newIPNR);
                    parentIndex = index;
                }
            } else {
                newIPNR.capacity = parseInt(element.TrafficCapacity, 10);

                if (element.TxBandWidth !== null) {
                    newIPNR.transmitted = element.TxBandWidth.split('|').map((item: any) => {
                        return parseInt(item, 10);
                    });
                }
                if (element.RxBandWidth !== null) {
                    newIPNR.received = element.RxBandWidth.split('|').map((item: any) => {
                        return parseInt(item, 10);
                    });
                }

                const childIndex = this.ipnrs[parentIndex].childnodes.findIndex(childnode => {
                    return childnode.IPNRNumber === newIPNR.IPNRNumber;
                });
                if (childIndex < 0) {
                    this.ipnrs[parentIndex].childnodes.push(newIPNR);
                } else {
                    this.ipnrs[parentIndex].childnodes[childIndex].add(newIPNR);
                }
            }

            // generate graph data
            this.generateGraphData();
        });

        this.filterEmptyIpnrs();

        this.sortByMos(1, 0);
        this.sortByMos(1, 1);
    }

    generateGraphData() {
        this.ipnrs.forEach(ipnr => {
            ipnr.childnodes.forEach(childnode => {
                childnode.graphData = childnode.getTRGraphData();
            });
        });
    }

    resetData() {
        this.ipnrs = [];
        this.filteredIpnrs = [];
        this.rawData = [];
    }

    dispose() {
        this.unsubData();
    }
    private unsubData() {
        if (this.equipmentId) {
            // unsub data
            this.realtimeService.unsubscribe(this.equipmentId, this.commandTypeIdIPNRBandwidth);
        }
    }
    sortByMos(order: any, sortSwitch: number) {
        const compare = function (a: IPNRBandwidthData, b: IPNRBandwidthData) {
            if (a.streams === 0) {
                return 1;
            }
            if (b.streams === 0) {
                return -1;
            }
            return (
                (a.mosgood / a.streams - b.mosgood / b.streams) * order ||
                (a.moswarning / a.streams - b.moswarning / b.streams) * order ||
                (a.mosdanger / a.streams - b.mosdanger / b.streams) * order
            );
        };
        switch (sortSwitch) {
            case 0:
                this.filteredIpnrs.sort(compare);
                this.filteredIpnrs = this.filteredIpnrs.slice();
                break;
            case 1:
                this.filteredIpnrs.forEach(ipnr => {
                    ipnr.childnodes.sort(compare);
                    ipnr.childnodes = ipnr.childnodes.slice();
                });
                break;
        }
    }

    sortByMosFromDashlet(input: any, sortSwitch: number) {
        let order = input.order;

        const compare = function (a: IPNRBandwidthData, b: IPNRBandwidthData) {
            if (a.streams === 0) {
                return 1;
            }
            if (b.streams === 0) {
                return -1;
            }
            return (
                (a.mosgood / a.streams - b.mosgood / b.streams) * order ||
                (a.moswarning / a.streams - b.moswarning / b.streams) * order ||
                (a.mosdanger / a.streams - b.mosdanger / b.streams) * order
            );
        };
        switch (sortSwitch) {
            case 0:
                this.filteredIpnrs.sort(compare);
                this.filteredIpnrs = this.filteredIpnrs.slice();
                break;
            case 1:
                this.filteredIpnrs.forEach(ipnr => {
                    ipnr.childnodes.sort(compare);
                    ipnr.childnodes = ipnr.childnodes.slice();
                });
                break;
        }
    }

    filterEmptyIpnrs() {
        if (!this.showAllIpnrs) {
            this.filteredIpnrs = cloneDeep(
                this.ipnrs.filter(ipnr => {
                    for (const childnode of ipnr.childnodes) {
                        if (childnode.peakUtil > 0) {
                            return true;
                        }
                    }
                    return ipnr.streams > 0;
                })
            );
            this.filteredIpnrs.forEach(filteredIpnr => {
                const childnodes = filteredIpnr.childnodes.filter(childnode => {
                    return childnode.peakUtil > 0 || childnode.streams > 0;
                });
                filteredIpnr.childnodes = cloneDeep(childnodes);
            });
        } else {
            this.filteredIpnrs = cloneDeep(this.ipnrs);
        }

        this.sortByMos(1, 0);
        this.sortByMos(1, 1);
        this.filteredIpnrs = this.filteredIpnrs.slice();
    }
}
