/* eslint-disable no-undef */
import * as CommonEntityActions from '@actions/common-entity/common-entity.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, concatMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService, TimeoutService } from '@services/index';
import { RealTimeNotify } from '@models/index';
import * as SummaryDocumentActions from '@actions/summary-document/summary-document.actions';
import { Router } from '@angular/router';

@Injectable()
export class CommonEntityEffects {
    constructor(
        private actions$: Actions,
        private realtimeGatewayService: RealtimeGatewayService,
        private timeoutService: TimeoutService,
        private router: Router
    ) {}

    private saveCurrentLocation(): void {
        let redirectUrl: string = location.pathname;
        if (redirectUrl && redirectUrl.toLowerCase() !== '/Login' && redirectUrl.toLowerCase() !== '/') {
            sessionStorage.setItem('postLoginRedirectUrl', redirectUrl);
        }
    }

    getNotifyEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommonEntityActions.GetNotifyCommonEntitys),
            switchMap(action =>
                this.realtimeGatewayService.observe(action.equipmentId, action.commandTypeId).pipe(
                    catchError(err => of(CommonEntityActions.GetNotifyCommonEntitysFailure({ error: err }))),
                    mergeMap((data: RealTimeNotify) => {
                        return [CommonEntityActions.GetNotifyCommonEntitysSuccess({ data })];
                    })
                )
            )
        )
    );

    getEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommonEntityActions.GetCommonEntitys),
            mergeMap(action => {
                this.realtimeGatewayService.getDocApiCall(action.equipmentId, action.commandTypeId);
                return this.realtimeGatewayService.getWorkerMessageObservable().pipe(
                    map(data => {
                        const timeStamp =
                            data && data.data[0] && data.data[0].timestamp ? data.data[0].timestamp : new Date();

                        const idKey = data.equipmentId + ':' + data.commandTypeId;

                        if (this.timeoutService.timers.get(idKey.toLowerCase())) {
                            this.timeoutService.documentRecieved(
                                data.equipmentId + ':' + data.commandTypeId,
                                data.commandTypeId,
                                timeStamp,
                                data.equipmentId
                            );
                        }
                        if (data && data.data.status && data.data.status === 401) {
                            this.saveCurrentLocation();
                            this.router.navigate(['/Login']);
                            return CommonEntityActions.GetCommonEntitysFailure({ error: 'Error occured' });
                        } else if (data && data.data.status && data.data.status === 404) {
                            return CommonEntityActions.GetCommonEntitysFailure({ error: 'Error occured' });
                        } else {
                            if (data.url.includes('summarydocument')) {
                                return SummaryDocumentActions.getSummaryDocumentSuccess({
                                    data: {
                                        data: data.data,
                                        uniqueId: (data.equipmentId + data.commandTypeId).toLowerCase()
                                    }
                                });
                            } else {
                                return CommonEntityActions.GetCommonEntitysSuccess({
                                    data: {
                                        data: data.data,
                                        uniqueId: (data.equipmentId + data.commandTypeId).toLowerCase()
                                    }
                                });
                            }
                        }
                    })
                );
            }),
            catchError((err: any) => {
                return of(CommonEntityActions.GetCommonEntitysFailure({ error: err }));
            })
        )
    );

    getHistoricCommonData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommonEntityActions.GetCommonHistoric),
            concatMap(action => {
                return this.realtimeGatewayService
                    .requestHistoricDocument(
                        action.equipmentId,
                        action.commandTypeId,
                        action.from,
                        action.to,
                        action.max
                    )
                    .pipe(
                        map(data => {
                            const tempData = data.map(item => {
                                if (item && item[0]) {
                                    return item[0];
                                } else {
                                    return item;
                                }
                            });
                            if (
                                this.timeoutService.timers.get(
                                    action.equipmentId.toLowerCase() + ':' + action.commandTypeId.toLowerCase()
                                )
                            ) {
                                this.timeoutService.documentRecieved(
                                    action.equipmentId.toLowerCase() + ':' + action.commandTypeId.toLowerCase(),
                                    action.commandTypeId,
                                    tempData[0].timestamp,
                                    action.equipmentId
                                );
                            }
                            return CommonEntityActions.GetCommonHistoricSuccess({
                                data: {
                                    data: tempData,
                                    uniqueId: (action.equipmentId + action.commandTypeId).toLowerCase()
                                }
                            });
                        }),
                        catchError(err => {
                            return of(CommonEntityActions.GetCommonHistoricFailure({ error: err }));
                        })
                    );
            })
        )
    );
}
