import {
    SelectItemGroup,
    SelectItem,
    DashletSettingDropdownGrouped,
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingCheckbox
} from '@models/index';
import { DashletSettingsService } from '@services/index';

export class DashletSettingsFormSystemHealthAvayaContact extends DashletSettingsForm {
    showHealth!: DashletSettingCheckbox;
    showLinkDetails!: DashletSettingCheckbox;
    showChannelDetails!: DashletSettingCheckbox;
    showArchiveDestinationDetails!: DashletSettingCheckbox;
    private customer!: DashletSettingDropdown;
    private location!: DashletSettingDropdown;
    private equipment!: DashletSettingDropdownGrouped;

    constructor(private readonly settingsService: DashletSettingsService) {
        super();

        this.init();
        this.loadCustomers();
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);

        if (this.customer.value) {
            this.loadLocations(this.customer.value);
        }
        if (this.location.value) {
            this.loadEquipment(this.location.value);
        }
    }

    private init(): void {
        const equipmentGroup = new DashletSettingsGroup();
        const s: any = (equipmentGroup.settings = []);

        const customer = (this.customer = new DashletSettingDropdown());
        customer.label = 'Customer';
        customer.stateKey = 'customer';
        customer.disabled = true;
        customer.change.subscribe(() => {
            if (!this.location.value) this.clearAndDisable(this.location);
            if (!this.equipment.value) this.clearAndDisable(this.equipment);

            this.loadLocations(customer.value!);
        });
        s.push(customer);

        const location = (this.location = new DashletSettingDropdown());
        location.label = 'Location';
        location.stateKey = 'location';
        location.disabled = true;
        location.change.subscribe(() => {
            if (!this.equipment.value) this.clearAndDisable(this.equipment);

            this.loadEquipment(location.value!);
        });
        s.push(location);

        const equipment = (this.equipment = new DashletSettingDropdownGrouped());
        equipment.label = 'Equipment';
        equipment.stateKey = 'equipment';
        equipment.disabled = true;
        s.push(equipment);

        // layout section
        const layoutGroup = new DashletSettingsGroup();
        layoutGroup.title = 'Layout';
        const layoutSettings: any = (layoutGroup.settings = []);

        const showSystemChart = new DashletSettingCheckbox();
        showSystemChart.label = 'Show Occupancy Graph';
        showSystemChart.stateKey = 'showSystemChart';
        showSystemChart.disabled = false;
        layoutSettings.push(showSystemChart);

        const showNetworkGraph = new DashletSettingCheckbox();
        showNetworkGraph.label = 'Show Network Connectivity Graph';
        showNetworkGraph.stateKey = 'showNetworkGraph';
        showNetworkGraph.disabled = false;
        layoutSettings.push(showNetworkGraph);

        const showStatistics = (this.showHealth = new DashletSettingCheckbox());
        showStatistics.label = 'Show Call and Recording Statistics';
        showStatistics.stateKey = 'showStatistics';
        showStatistics.disabled = false;
        layoutSettings.push(showStatistics);

        const showHealth = (this.showHealth = new DashletSettingCheckbox());
        showHealth.label = 'Show Health';
        showHealth.stateKey = 'showHealth';
        showHealth.disabled = false;
        layoutSettings.push(showHealth);

        const showLinkDetails = (this.showLinkDetails = new DashletSettingCheckbox());
        showLinkDetails.label = 'Show Link Details';
        showLinkDetails.stateKey = 'showLinkDetails';
        showLinkDetails.disabled = false;
        layoutSettings.push(showLinkDetails);

        const showChannelDetails = (this.showChannelDetails = new DashletSettingCheckbox());
        showChannelDetails.label = 'Show Channel Details';
        showChannelDetails.stateKey = 'showChannelDetails';
        showChannelDetails.disabled = false;
        layoutSettings.push(showChannelDetails);

        const showArchiveDestinationDetails = (this.showArchiveDestinationDetails = new DashletSettingCheckbox());
        showArchiveDestinationDetails.label = 'Show Archive Destination Details';
        showArchiveDestinationDetails.stateKey = 'showArchiveDestinationDetails';
        showArchiveDestinationDetails.disabled = false;
        layoutSettings.push(showArchiveDestinationDetails);

        const showCustomScripts = new DashletSettingCheckbox();
        showCustomScripts.label = 'Show Custom Scripts';
        showCustomScripts.stateKey = 'showCustomScripts';
        showCustomScripts.disabled = false;
        layoutSettings.push(showCustomScripts);

        const group = new DashletSettingsGroup();
        group.groups = [equipmentGroup, layoutGroup];
        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);
        this.settingsService.getUserAssociatedCustomers().subscribe(
            customers => {
                this.customer.items = customers;
                this.customer.loadingComplete();
            },
            () => {
                this.customer.loadingComplete();
                this.customer.disabled = true;
                // show error notification here
            }
        );
    }

    private loadLocations(customer: string): void {
        this.enableAndLoading(this.location);
        this.settingsService.getLocations(customer).subscribe(
            locations => {
                this.location.items = locations;
                this.location.loadingComplete();
            },
            () => {
                this.location.loadingComplete();
                this.location.disabled = true;
                // show error notification here
            }
        );
    }

    private loadEquipment(location: string): void {
        this.enableAndLoading(this.equipment);
        this.settingsService.getEquipment(location, 'AVAYACONTACTRECORDER').subscribe(
            equipment => {
                const equipmentGroups: SelectItemGroup[] = [];
                const group: SelectItemGroup = new SelectItemGroup();
                group.items = [];
                equipment.forEach(item => {
                    group.items.push(item);
                });
                group.label = 'Contact Recorder';
                equipmentGroups.push(group);
                this.equipment.items = equipmentGroups;
                this.equipment.loadingComplete();
            },
            () => {
                this.equipment.loadingComplete();
                this.equipment.disabled = true;
                // show error notification here
            }
        );
    }

    private clearAndDisable(dropdown: DashletSettingDropdown | DashletSettingDropdownGrouped) {
        dropdown.disabled = true;
        dropdown.items = [];
        dropdown.value = null;
    }

    private enableAndLoading(dropdown: DashletSettingDropdown | DashletSettingDropdownGrouped) {
        dropdown.disabled = false;
        dropdown.loadingBegin();
    }
}
