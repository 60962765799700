export class WFMRealTimeData {
	private _wfoVendor: string;
	private _logMessage: string;
	private _logEntryDateTime!: string;
	private _status: string;
	private _severity: number;

	constructor(wfoVendor: string, logMessage: string, logEntryDateTime: string) {
		this._wfoVendor = wfoVendor;
		this._logMessage = logMessage;

		if (this._logMessage.toLowerCase().includes('error:')) {
			this._severity = 2; // red
			this._status = 'Error';
		} else if (this._logMessage.toLowerCase().includes('warning:')) {
			this._severity = 1; // orange
			this._status = 'Warning';
		} else if (
			this._logMessage.toLowerCase().endsWith('ran (done)') ||
			this._logMessage.toLowerCase().includes('run by') ||
			this._logMessage.toLowerCase().endsWith('ran')
		) {
			this._severity = 0; // green
			this._status = 'Ran';
		} else {
			this._severity = -1; // grey
			this._status = 'Unknown';
		}

		this.updateDateTime();
	}

	public update(wfoVendor: string, logMessage: string, logEntryDateTime: string) {
		this._wfoVendor = wfoVendor;
		this._logMessage = logMessage;

		if (this._logMessage.toLowerCase().includes('error:')) {
			this._severity = 2; // red
			this._status = 'Error';
		} else if (this._logMessage.toLowerCase().includes('warning:')) {
			this._severity = 1; // orange
			this._status = 'Warning';
		} else if (
			this._logMessage.toLowerCase().endsWith('ran (done)') ||
			this._logMessage.toLowerCase().includes('run by') ||
			this._logMessage.toLowerCase().endsWith('ran')
		) {
			this._severity = 0; // green
			this._status = 'Ran';
		}

		this.updateDateTime();
	}

	private updateDateTime() {
		const args = this._logMessage.split(' ');
		for (let i = 0; i < args.length; i++) {
			if (args[i].trim().split(':').length == 3) {
				this._logEntryDateTime = args[i];
			}
		}
	}

	/**
	 * Getter status
	 * @return {string}
	 */
	public get status(): string {
		return this._status;
	}

	/**
	 * Getter severity
	 * @return {number}
	 */
	public get severity(): number {
		return this._severity;
	}

	/**
	 * Getter wfoVendor
	 * @return {string}
	 */
	public get wfoVendor(): string {
		return this._wfoVendor;
	}

	/**
	 * Getter logMessage
	 * @return {string}
	 */
	public get logMessage(): string {
		return this._logMessage;
	}

	/**
	 * Getter logEntryDateTime
	 * @return {string}
	 */
	public get logEntryDateTime(): string {
		return this._logEntryDateTime;
	}

	/**
	 * Setter wfoVendor
	 * @param {string} value
	 */
	public set wfoVendor(value: string) {
		this._wfoVendor = value;
	}

	/**
	 * Setter logMessage
	 * @param {string} value
	 */
	public set logMessage(value: string) {
		this._logMessage = value;
	}

	/**
	 * Setter logEntryDateTime
	 * @param {string} value
	 */
	public set logEntryDateTime(value: string) {
		this._logEntryDateTime = value;
	}
}
