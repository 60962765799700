import * as DiskUsageActions from '@actions/index';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap
  } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService, TimeoutService } from '@services/index';
import { RealTimeNotify } from '@models/index';


@Injectable()
export class DiskUsageEffects {

  constructor(private actions$: Actions, private realtimeGatewayService: RealtimeGatewayService,
    private timeoutService: TimeoutService) { }


  getNotifyProcessUsage$ = createEffect(() => this.actions$.pipe(
    ofType(DiskUsageActions.GetNotifyDiskUsage),
    switchMap((action) => this.realtimeGatewayService.observe(action.equipmentId, action.commandTypeId).pipe(
      catchError((err) => of(
        DiskUsageActions.GetNotifyDiskUsageFailure({ error: err })
      )
      ),
      mergeMap((data: RealTimeNotify) => [
        DiskUsageActions.GetNotifyDiskUsageSuccess({ data }),
        DiskUsageActions.GetDiskUsage({ ...data })
      ],
      ),
    ),
    )
  ));

  getDiskUsage$ = createEffect(() => this.actions$.pipe(
    ofType(DiskUsageActions.GetDiskUsage),
    mergeMap((action) => this.realtimeGatewayService.getDocument(action.equipmentId, action.commandTypeId).pipe(
      map((data) => DiskUsageActions.GetDiskUsageSuccess({ data:{ data, equipmentId: action.equipmentId} }),
      ),
      catchError((err) => of(DiskUsageActions.GetDiskUsageFailure({ error: err })))
    ))
  ));

  getDiskUsageTimer$ = createEffect(() => this.actions$.pipe(
    ofType(DiskUsageActions.GetDiskUsageTimer),
    mergeMap((action) => this.timeoutService.observeTimer(action.equipmentId, action.commandTypeId).pipe(
      map((data) => DiskUsageActions.GetDiskUsageTimerSuccess({ data:{ data, equipmentId: action.equipmentId} }),
      ),
      catchError((err) => of(DiskUsageActions.GetDiskUsageTimerFailure({ error: err })))
    ))
  ));

}
