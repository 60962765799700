import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

export const GetNotifyProcessUpTime = createAction(
    '[ProcessUpTime] Get Notify ProcessUpTime',
    props<{ equipmentId: string; command: string }>()
);

export const GetNotifyProcessUpTimeSuccess = createAction(
    '[ProcessUpTime] Get Notify ProcessUpTime Success',
    props<{ data: RealTimeNotify }>()
);

export const GetNotifyProcessUpTimeFailure = createAction(
    '[ProcessUpTime] Get Notify ProcessUpTime Failure',
    props<{ error: any }>()
);

export const GetProcessUpTimeTimer = createAction(
    '[ProcessUpTime] Get ProcessUpTime Timer',
    props<{ equipmentId: string; command: string }>()
);

export const GetProcessUpTimeTimerSuccess = createAction(
    '[ProcessUpTime] Get ProcessUpTime Timer Success',
    props<{ data: any }>()
);

export const GetProcessUpTimeTimerFailure = createAction(
    '[ProcessUpTime] Get ProcessUpTime Timer Failure',
    props<{ error: any }>()
);

export const SubscribeToRealTimeService = createAction(
    '[ProcessUpTime] Subscribe To Real Time Service',
    props<{ equipmentId: string; command: string; idType?: string }>()
);

export const UnsubscribeFromRealTimeService = createAction(
    '[ProcessUpTime] Unsubscribe From Real Time Service',
    props<{ equipmentId: string; command: string; idType?: string }>()
);

export const StoreEquipIdBasedOnType = createAction(
    '[ProcessUpTime] Store Equip Id and Customer Id for lookup',
    props<{ equipmentId: string; idType: string }>()
);

export const RequestHistoricDate = createAction(
    '[RequestHistoricDate] Request Historic Date',
    props<{ equipmentId: string; command: string; from?: string | Date; to?: string | Date; max?: number | null }>()
);

/*
 * Get document from realtime api
 */
export const GetProcessUpTimeDocument = createAction(
    '[ProcessUpTime] Get ProcessUpTime Document',
    props<{ equipmentId: string; commandTypeId: string }>()
);

export const GetProcessUpTimeDocumentSuccess = createAction(
    '[ProcessUpTime] Get ProcessUpTime Document Success',
    props<{ data: any }>()
);

export const GetProcessUpTimeDocumentFailure = createAction(
    '[ProcessUpTime] Get ProcessUpTime Document Failure',
    props<{ error: any }>()
);

/*
 * Get Historic document from realtime api
 */
export const GetProcessUpTimeHistoryDocument = createAction(
    '[ProcessUpTime] Get ProcessUpTime History Document',
    props<{ equipmentId: string; command: string }>()
);

export const GetProcessUpTimeHistoryDocumentSuccess = createAction(
    '[ProcessUpTime] Get ProcessUpTime History Document Success',
    props<{ data: any }>()
);

export const GetProcessUpTimeDocumentHistoryFailure = createAction(
    '[ProcessUpTime] Get ProcessUpTime History Document Failure',
    props<{ error: any }>()
);

/*
 * Get http document from realtime api
 */

export const GetRequestHttp = createAction(
    '[ProcessUpTime] Get RequestHttp ',
    props<{ equipmentId: string; command: string }>()
);

export const GetRequestHttpSuccess = createAction('[ProcessUpTime] Get RequestHttp Success', props<{ data: any }>());

export const GetRequestHttpFailure = createAction('[ProcessUpTime] Get RequestHttp Failure', props<{ error: any }>());
