import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EquipmentConfigurationService {
    readonly unifiedCommunicationsServiceId = '1393DFBA-F63E-431E-8AAF-3C89811CCC77';

    constructor(protected http: HttpClient) {}

    openEdit(locationId: string, sectionId: string) {
        window.open(
            environment.webPortalUrl +
                '/EquipmentConfiguration/Connectivity/Edit?sectionId=' +
                sectionId +
                '&locationId=' +
                locationId +
                '&serviceId=' +
                this.unifiedCommunicationsServiceId
        );
    }

    getEquipmentCommandsRun(equipmentId: string, customerId: string) {
        // const url = `API/EquipmentNg/GetEquipmentCommandsRunWithCommandsOutput?equipmentId=${equipmentId}&customerId=${customerId}`;
        const url = environment.centralApi + `Command/${equipmentId}/${customerId}/Windows`;
        return this.http.get(url);
    }

    getEditMicrosoft(equipmentId: string, locationId: string, hostname: string) {
        return this.http.get(
            `/Microsoft/NgEdit?equipmentInfoId=${equipmentId}&locationId=${locationId}&hostname=${hostname}`
        );
    }

    getEquipmentCommandsRunLinux(equipmentId: string, customerId: string) {
        const url = environment.centralApi + `Command/${equipmentId}/${customerId}/Linux`;
        return this.http.get(url);
    }

    getServerStatusList(entityId: string, messageIds: string[]) {
        const url = `API/EquipmentNg/GetCommandsRunServerStatus`;

        return <Observable<any>>this.http.get<any>(url, {
            params: new HttpParams().append('entityId', entityId).append('messageIds', messageIds.toString())
        });
    }
}
