import {
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingDropdownGrouped,
    DashletSettingCheckbox
} from '@models/index';
import { DashletSettingsService } from '@services/index';

export class DashletSettingsFormSIPBandwidth extends DashletSettingsForm {
    private customer!: DashletSettingDropdown;
    private location!: DashletSettingDropdown;
    private equipment!: DashletSettingDropdown;
    private remoteLocations!: DashletSettingDropdown;

    constructor(private readonly settingsService: DashletSettingsService) {
        super();

        this.init();
        this.loadCustomers();
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);

        if (this.customer.value) {
            this.loadLocations(this.customer.value);
        }
        if (this.location.value) {
            this.loadEquipment(this.location.value);
            if (this.equipment.value) {
                this.loadRemoteLocations(this.customer.value!, this.location.value, this.equipment.value);
            }
        }
    }

    private init(): void {
        const group = new DashletSettingsGroup();
        const s: any = (group.settings = []);

        const customer = (this.customer = new DashletSettingDropdown());
        customer.label = 'Customer';
        customer.stateKey = 'customer';
        customer.disabled = true;
        customer.change.subscribe(() => {
            this.clearAndDisable(this.location);
            this.clearAndDisable(this.equipment);
            this.clearAndDisable(this.remoteLocations);

            this.loadLocations(customer.value!);
        });
        s.push(customer);

        const location = (this.location = new DashletSettingDropdown());
        location.label = 'Location';
        location.stateKey = 'location';
        location.disabled = true;
        location.change.subscribe(() => {
            this.clearAndDisable(this.equipment);
            this.clearAndDisable(this.remoteLocations);

            this.loadEquipment(location.value!);
        });
        s.push(location);

        const equipment = (this.equipment = new DashletSettingDropdown());
        equipment.label = 'Equipment';
        equipment.stateKey = 'equipment';
        equipment.disabled = true;
        equipment.change.subscribe(() => {
            this.clearAndDisable(this.remoteLocations);
            this.loadRemoteLocations(customer.value!, location.value!, equipment.value!);
        });
        s.push(equipment);

        const remoteLocations = (this.remoteLocations = new DashletSettingDropdown());
        remoteLocations.label = 'Remote Location';
        remoteLocations.stateKey = 'remoteLocation';
        remoteLocations.disabled = true;
        s.push(remoteLocations);

        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);
        this.settingsService.getUserAssociatedCustomers().subscribe(
            customers => {
                this.customer.items = customers;
                this.customer.loadingComplete();
            },
            () => {
                this.customer.loadingComplete();
                this.customer.disabled = true;
                // show error notification here
            }
        );
    }

    private loadLocations(customer: string): void {
        this.enableAndLoading(this.location);
        this.settingsService.getLocations(customer).subscribe(
            locations => {
                this.location.items = locations;
                this.location.loadingComplete();
            },
            () => {
                this.location.loadingComplete();
                this.location.disabled = true;
                // show error notification here
            }
        );
    }

    private loadEquipment(location: string): void {
        this.enableAndLoading(this.equipment);
        this.settingsService.getEquipment(location, 'ASM').subscribe(
            receivers => {
                this.equipment.items = receivers;
                this.equipment.loadingComplete();
            },
            () => {
                this.equipment.loadingComplete();
                this.equipment.disabled = true;
                // show error notification here
            }
        );
    }

    private loadRemoteLocations(customer: string, location: string, equipment: string): void {
        this.enableAndLoading(this.remoteLocations);
        this.settingsService.getRemoteLocations(customer, location, equipment).subscribe(
            remoteLocations => {
                this.remoteLocations.items = remoteLocations;
                this.remoteLocations.loadingComplete();
            },
            () => {
                this.remoteLocations.loadingComplete();
                this.remoteLocations.disabled = true;
                // show error notification here
            }
        );
    }

    private clearAndDisable(dropdown: DashletSettingDropdown | DashletSettingDropdownGrouped) {
        dropdown.disabled = true;
        dropdown.items = [];
        dropdown.value = null;
    }

    private enableAndLoading(dropdown: DashletSettingDropdown | DashletSettingDropdownGrouped) {
        dropdown.disabled = false;
        dropdown.loadingBegin();
    }
}
