import { SelectItemGroup, SelectItem, DashletSetting, DashletSettingLoadable } from '@models/index';

export class DashletSettingDropdownGroupedList extends DashletSetting implements DashletSettingLoadable {
    public items!: SelectItemGroup[];
    public multiple: boolean = true;
    public filter: boolean = false;
    public metaKeySelection: boolean = true;
    public disabled!: boolean;
    public removeSelectAll: boolean = false;
    public limitSelection: number | null = null;

    private pValues!: any[];
    get values(): any[] {
        return this.pValues;
    }
    set values(value: any[]) {
        this.pValues = value;
        // clear loading items if selection changes
        if (this.pLoadingItems) {
            if (value === null || value.length < 1 || !this.pLoadingItems.every(i => value.some(v => i.value === v))) {
                this.pLoadingItems = [];
            }
        }
    }

    private loadingSem: number = 0;

    private pLoadingItems!: any[];
    get loadingItems(): any[] {
        return this.pLoadingItems;
    }

    public getLoading(): boolean {
        return this.loadingSem > 0;
    }

    public loadingBegin(): void {
        this.loadingSem++;
    }

    public loadingComplete(): void {
        if (this.loadingSem > 0) {
            this.loadingSem--;
        }
    }

    public loadState(obj: { [key: string]: any }): void {
        const s = obj[this.stateKey];
        if (s?.length) {
            this.pLoadingItems = s;
            this.pValues = s.map(i => i.value);
        } else {
            this.pLoadingItems = [];
            this.pValues = [];
        }
    }

    public saveState(obj: { [key: string]: any }): void {
        obj[this.stateKey] = this.getSelectedItem();
    }

    private getSelectedItem(): SelectItem[] | null {
        // check for null selections
        if (!this.values) {
            return [];
        }

        let result: SelectItem[] = [];

        this.values.forEach(value => {
            this.items.forEach(selectGroup => {
                let index = selectGroup.items.findIndex(x => x.value === value);
                if (index > -1) result.push(selectGroup.items[index]);
            });
        });

        return result;
    }
}
