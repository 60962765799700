<mat-form-field *ngIf="!data!.getLoading(); else loading" appearance="outline">
    <mat-label>{{ data!.label }}</mat-label>

    <mat-select #input [typeaheadDebounceInterval]="800" [ngModel]="data!.value" (ngModelChange)="onModelChange($event)" [disabled]="data!.disabled">
        <ng-container *ngIf="data!.items && data!.items.length > 0; else noOptions">
            <mat-optgroup *ngFor="let group of data!.items" [label]="group.label">
                <mat-option *ngFor="let item of group.items" [value]="item.value">
                    {{ item.label }}
                </mat-option>
            </mat-optgroup>
        </ng-container>
        <ng-template #noOptions>
            <mat-option disabled>None</mat-option>
        </ng-template>
    </mat-select>
</mat-form-field>
<ng-template #loading>
    <div class="flex align-center gap-m dropdown-loading">
        <mat-spinner [diameter]="28"></mat-spinner>
        <span class="mat-body-2">{{ 'Loading ' + data!.label }}</span>
    </div>
</ng-template>
