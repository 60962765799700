import { AccountService } from '@services/index';
import { Customer, Dashlet } from '@models/index';
import { Subject } from 'rxjs';

enum alarmStatus {
    resolving = -1,
    resolveFailed,
    resolveSucceed
}

class Alarm {
    id!: string;
    displayName!: string;
    administeredId!: string;
    firstOccurred!: Date;
    lastOccurred!: Date;
    equipmentId!: string | null;
    equipment!: string | null;
    customerId!: string | null;
    customer!: string | null;
    severity!: number;
    resolveStatus!: alarmStatus;
}

export interface AlarmStatistic {
    customer: string;
    customerId: string | null;
    location: string | null;
    locationId: string | null;
    highSeverity: number;
    mediumSeverity: number;
    lowSeverity: number;
}

export class DashletBusinessPartnerAlarmsSummary extends Dashlet {
    public commandTypeIdAlarms = 'D58872AD-8669-4217-8E12-3FE364F8A9D0';
    public commandTypeIdAlarmStatistics = '16E3CA3B-1B5B-4893-9755-84D3B50C10F9';
    public commandTypeIdResolved = '20126C92-F4C0-4816-A779-8BDCC4CC4D06';
    public customerIds: string[] = [];
    public customers: string[] = [];
    public locationId!: string;
    public location!: string;
    public customer!: Customer | null;
    public customerGroup: {
        label: string;
        value: string;
    }[] = [];
    public equipmentIds: string[] = [];
    public severities: number[] = [];

    public highSeverity = 0;
    public mediumSeverity = 0;
    public lowSeverity = 0;

    public settingsChange$: Subject<null> = new Subject();

    private alarmsCustomer!: Map<string, Alarm[]>;
    private alarms!: Alarm[];
    public alarmStatistics!: AlarmStatistic[];
    private sortColumn = 'lastOccurred';
    private sortDesc = true;

    constructor(private accountService: AccountService) {
        super();

        this.sizes = [
            {
                id: 0,
                label: 'Small',
                cols: 5,
                rows: 4
            },
            {
                id: 1,
                label: 'Medium',
                cols: 5,
                rows: 8
            },
            {
                id: 2,
                label: 'Large',
                cols: 10,
                rows: 8
            },
            {
                id: 3,
                label: 'Huge',
                cols: 15,
                rows: 12
            }
        ];
        this.applySize(0);
    }

    public applySettings(v: { [key: string]: any }): void {
        super.applySettings(v);

        const user = this.accountService.getUserDetails();

        this.customerIds = v.customer ? v.customer.map((element: any) => element.value) : [user!.EntityId];
        this.customers = v.customer ? v.customer.map((element: any) => element.label) : [user!.UsersEntityName];
        this.locationId = v.location ? v.location.value : '';
        this.location = v.location ? v.location.label : '';
        if (this.customers.length === 1 && this.customerIds.length === 1) {
            if (this.customers[0] !== 'Virsae Owners') {
                this.customer = new Customer(this.customerIds[0], this.customers[0]);
            }
        } else {
            this.customer = null;
        }
        this.customerGroup = v.customer
            ? v.customer
            : [
                  {
                      label: user!.UsersEntityName,
                      value: user!.EntityId
                  }
              ];
        this.severities = v.severity ? v.severity.map((element: any) => element.value) : [];

        this.configured = v.severity && this.customers.length > 0 && this.severities.length > 0;

        this.generatedNameTag = this.configured ? '' : 'Unconfigured';
        this.customNameTag = v.nameTag;

        this.equipmentIds = [];

        this.alarmsCustomer = new Map<string, Alarm[]>();
        this.alarms = [];
        this.alarmStatistics = [];
        if (this.configured) {
            this.settingsChange$.next(null);
        }
    }

    public dispose(): void {}

    public resetData(): void {
        this.alarmsCustomer = new Map<string, Alarm[]>();
        this.alarms = [];
        this.alarmStatistics = [];
    }

    private sortData(): void {
        if (!this.sortColumn) {
            return;
        }

        // define comparers
        const cmpNumber = (a: number, b: number): number => {
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        };
        const cmpString = (a: string, b: string): number => {
            return a.localeCompare(b);
        };
        const cmpDate = (a: Date, b: Date): number => {
            if (a instanceof Date || b instanceof Date) {
                const aa = (a && a.getTime()) || 0;
                const bb = (b && b.getTime()) || 0;
                if (aa < bb) {
                    return -1;
                }
                if (aa > bb) {
                    return 1;
                }
            }
            return 0;
        };

        // sort current array
        const direction = this.sortDesc ? -1 : 1;
        this.alarms.sort((a, b) => {
            let orderer;
            switch (this.sortColumn) {
                case 'severity':
                    orderer = cmpNumber(a.severity, b.severity);
                    if (orderer === 0) {
                        // then by
                        orderer = cmpString(a.displayName, b.displayName);
                    }
                    break;
                case 'displayName':
                    orderer = cmpString(a.displayName, b.displayName);
                    if (orderer === 0) {
                        // then by
                        orderer = cmpString(a.equipment!, b.equipment!);
                    }
                    if (orderer === 0) {
                        // then by
                        orderer = cmpString(a.customer!, b.customer!);
                    }
                    break;
                case 'administeredId':
                    orderer = cmpString(a.administeredId, b.administeredId);
                    if (orderer === 0) {
                        // then by
                        orderer = cmpString(a.displayName, b.displayName);
                    }
                    break;
                case 'equipment':
                    orderer = cmpString(a.equipment!, b.equipment!);
                    if (orderer === 0) {
                        // then by
                        orderer = cmpString(a.displayName, b.displayName);
                    }
                    break;
                case 'firstOccurred':
                    orderer = cmpDate(a.firstOccurred, b.firstOccurred);
                    break;
                case 'lastOccurred':
                    orderer = cmpDate(a.lastOccurred, b.lastOccurred);
                    break;
                case 'customer':
                    orderer = cmpString(a.customer!, b.customer!);
                    break;
                default:
                    orderer = 0;
            }
            return orderer * direction;
        });
    }

    public processAlarmData(customerId: string, data: any) {
        const row = data && data.length && data.filter(item => !item.suspendedAlarmNotificationId);

        const customer = this.customerGroup.find(element => element.value.toLowerCase() === customerId.toLowerCase());
        const alarms: Alarm[] = [];
        if (row) {
            // shortcut out if filtering out all severities
            if (this.severities.length === 0) {
                return;
            }
            // read rows
            this.highSeverity = 0;
            this.mediumSeverity = 0;
            this.lowSeverity = 0;

            for (const rowData of row) {
                const alarm = {
                    id: rowData.alarmId,
                    displayName: rowData.alarmName,
                    administeredId: rowData.administeredId,
                    firstOccurred: (rowData.activatedTime && new Date(rowData.activatedTime + 'Z')) || null,
                    lastOccurred: null,
                    equipmentId: rowData.equipmentId,
                    equipment: rowData.equipmentName ?? 'Unknown',
                    customerId: rowData.customerId,
                    customer: '',
                    severity: +rowData.severity,
                    resolveStatus: null
                };

                // check alarm against settings filters
                const isSeverityMatch = this.severities.some(element => element === Number(alarm.severity));
                const isEquipmentMatch =
                    this.equipmentIds.length > 0
                        ? this.equipmentIds.some(element => element === alarm.equipmentId)
                        : true;
                if (isSeverityMatch && isEquipmentMatch) {
                    // fill customer name from settings map
                    const customerMap = this.customerGroup.find(
                        element => element.value.toLowerCase() === alarm.customerId.toLowerCase()
                    );
                    alarm.customer = customerMap ? customerMap.label : 'Unknown';

                    // add alarm and process severities
                    if (this.customerIds.length === 1 && this.locationId !== '=all=') {
                        this.equipmentIds.indexOf(alarm.equipmentId) >= 0 ? alarms.push(alarm as any) : '';
                        if (alarm.severity >= 0 && alarm.severity <= 2) {
                            this.highSeverity = this.highSeverity + 1;
                        } else if (alarm.severity > 2 && alarm.severity < 6) {
                            this.mediumSeverity = this.mediumSeverity + 1;
                        } else if (alarm.severity >= 6 && alarm.severity <= 10) {
                            this.lowSeverity = this.lowSeverity + 1;
                        }
                    } else {
                        alarms.push(alarm as any);
                    }
                }
            }
            
        }
        const index = this.alarmStatistics.findIndex(e => e.customer === customer!.label);
            if (index === -1) {
                this.alarmStatistics.push({
                    customer: customer ? customer.label : 'Unknown',
                    customerId: customer ? customer.value : null,
                    location: this.location ? this.location : null,
                    locationId: this.locationId ? this.locationId : null,
                    lowSeverity: this.lowSeverity,
                    mediumSeverity: this.mediumSeverity,
                    highSeverity: this.highSeverity
                });
            } else {
                this.alarmStatistics[index] = {
                    customer: customer ? customer.label : 'Unknown',
                    customerId: customer ? customer.value : null,
                    location: this.location ? this.location : null,
                    locationId: this.locationId ? this.locationId : null,
                    lowSeverity: this.lowSeverity,
                    mediumSeverity: this.mediumSeverity,
                    highSeverity: this.highSeverity
                };
            }
            this.alarmsCustomer.set(customerId, alarms);
            this.updateAlarms();
            this.sortData();
            return [];
    }

    public processResolve(customerId: string, data: any) {
        const rows = data[0].data;
        if (rows !== null) {
            const resolved: string[] = [];
            for (const row of rows) {
                resolved.push(row.AlarmID);
            }

            this.removeAlarms(customerId, resolved);
        }
    }

    public processStatistics(customerId?: any, data?: any): void {
        const statistics = data && data.length && data.filter(item => !item.suspendedAlarmNotificationId);

        const customer = this.customerGroup.find(element => element.value.toLowerCase() === customerId.toLowerCase());

        let highSeverity = 0;
        for (let i = 0; i <= 2; i++) {
            if (this.severities.indexOf(i) !== -1) {
                const severity = statistics && statistics.filter((e: any) => i === parseInt(e.severity));
                if (severity) {
                    highSeverity += severity.length;
                }
            }
        }

        let mediumSeverity = 0;
        for (let i = 3; i <= 5; i++) {
            if (this.severities.indexOf(i) !== -1) {
                const severity = statistics && statistics.filter((e: any) => i === parseInt(e.severity));
                if (severity) {
                    mediumSeverity += severity.length;
                }
            }
        }

        let lowSeverity = 0;
        for (let i = 6; i <= 10; i++) {
            if (this.severities.indexOf(i) !== -1) {
                const severity = statistics && statistics.filter((e: any) => i === parseInt(e.severity));
                if (severity) {
                    lowSeverity += severity.length;
                }
            }
        }

        const index = this.alarmStatistics.findIndex(e => e.customer === customer!.label);
        if (index === -1) {
            this.alarmStatistics.push({
                customer: customer ? customer.label : 'Unknown',
                customerId: customer ? customer.value : null,
                location: this.location ? this.location : null,
                locationId: this.locationId ? this.locationId : null,
                lowSeverity: lowSeverity,
                mediumSeverity: mediumSeverity,
                highSeverity: highSeverity
            });
        } else {
            this.alarmStatistics[index] = {
                customer: customer ? customer.label : 'Unknown',
                customerId: customer ? customer.value : null,
                location: this.location ? this.location : null,
                locationId: this.locationId ? this.locationId : null,
                lowSeverity: lowSeverity,
                mediumSeverity: mediumSeverity,
                highSeverity: highSeverity
            };
        }
        this.alarmStatistics = [...this.alarmStatistics];
    }

    private removeAlarms(customerId: string, removeIds: string[]): void {
        if (!this.alarmsCustomer.has(customerId)) {
            return;
        }
        let alarms = this.alarmsCustomer.get(customerId);
        alarms = alarms!.filter(a => removeIds.every(removeId => a.id !== removeId));
        this.alarmsCustomer.set(customerId, alarms);
        this.updateAlarms();
    }

    private updateAlarms(): void {
        this.alarms = [];
        this.alarmsCustomer.forEach(alarms => this.alarms.push.apply(this.alarms, alarms));
        (window as any).countRx = this.alarmsCustomer.size;
        (window as any).countTx = this.customers.length;
    }
}
