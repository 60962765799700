import * as EquipmentInfoActions from '@actions/equipment-info/equipment-info.actions';
import { createReducer, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const equipmentInfoFeatureKey = 'equipmentInfo';
interface MosCapableReceiversEntityState extends EntityState<any> {}

export interface State {
    equipmentInfo: MosCapableReceiversEntityState;
}

function selectEntityId(a: any): string {
    return a.equipmentId;
}

const adapterEquipmentInfo = createEntityAdapter<any>({
    selectId: selectEntityId
});

export const initialState: State = {
    equipmentInfo: adapterEquipmentInfo.getInitialState()
};

export const reducer = createReducer(
    initialState,
    on(EquipmentInfoActions.GetEquipmentInfoSuccess, (state, action) => ({
        ...state,
        equipmentInfo: adapterEquipmentInfo.addOne(
            { ...action.data, customerId: action.equipmentId },
            state.equipmentInfo
        )
    }))
);
