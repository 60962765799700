export const environment = {
    production: '#{IsProduction}#',
    issuer: '#{IdentityServerUrl}#',
    redirectUrl: '#{RedirectUrl}#',
    dashboardApi: '#{AllowedUrl}#',
    centralApi: '#{CentralApiUrl}#',
    realtimeApi: '#{RealtimeApiUrl}#',
    reportingUrl: '#{ReportingUrl}#',
    webPortalUrl: '#{WebPortalUrl}#',
    documentationServerUrl: '#{DocumentationServerUrl}#'
};
