import { FeatureSubscriptionsRespObj } from '@models/index';
import { createReducer, on } from '@ngrx/store';
import * as ReportActions from '@actions/report/report.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export const reportFeatureKey = 'report';

export interface State extends EntityState<FeatureSubscriptionsRespObj> {
    reportSubscriptions: FeatureSubscriptionsRespObj[];
    ids: any[];
    entities: any;
}

export function selectEntityId(a: FeatureSubscriptionsRespObj): string {
    //In this case this would be optional since primary key is id
    return a.customerId;
}

export const adapter: EntityAdapter<FeatureSubscriptionsRespObj> = createEntityAdapter<FeatureSubscriptionsRespObj>({
    selectId: selectEntityId
});
export const initialState: State = adapter.getInitialState({
    // additional entity state properties
    reportSubscriptions: [],
    ids: [],
    entities: {}
});

export const reducer = createReducer(
    initialState,
    on(ReportActions.GetReportSubscriptionsSuccess, (state, { data }) => {
        return adapter.addOne(data, state);
    })
);

const { selectEntities } = adapter.getSelectors();

export const selectSubscriptionEntities = selectEntities;
