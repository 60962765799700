/* eslint-disable no-undef */
import { Subject, Subscription } from 'rxjs';
import { AccountService } from '@services/index';
import { Customer, Dashlet } from '@models/index';

export enum alarmStatus {
    resolving = -1,
    resolveFailed,
    resolveSucceed
}

class Alarm {
    id!: string;
    displayName!: string;
    administeredId!: string;
    firstOccurred!: Date;
    lastOccurred!: Date | null;
    equipmentId!: string;
    equipment!: string;
    customerId!: string;
    customer!: string;
    severity!: number;
    resolveStatus!: alarmStatus | null;
}

export class DashletAlarmsSummary extends Dashlet {
    public commandTypeIdAlarms = 'D58872AD-8669-4217-8E12-3FE364F8A9D0';
    public commandTypeIdResolved = '20126C92-F4C0-4816-A779-8BDCC4CC4D06';

    public sizeChange = new Subject<number>();
    public settingsChanged: Subject<boolean> = new Subject<boolean>();

    customerIds: string[] = [];
    customers: string[] = [];
    customer!: Customer | null;
    logoSub!: Subscription;
    customerGroup: {
        label: string;
        value: string;
    }[] = [];
    equipmentIds: string[] = [];
    severities: number[] = [];
    alarmsCustomer!: Map<string, Alarm[]>;
    public alarms: Alarm[] = [];
    unresolved = 0;
    sortColumn = 'severity';
    sortColumnRules = 'matchIndex';
    sortDesc = false;
    sortDescRules = true;

    constructor(private accountService: AccountService) {
        super();

        this.sizes = [
            {
                id: 0,
                label: 'Small',
                cols: 5,
                rows: 4
            },
            {
                id: 1,
                label: 'Medium',
                cols: 5,
                rows: 8
            },
            {
                id: 2,
                label: 'Large',
                cols: 10,
                rows: 8
            },
            {
                id: 3,
                label: 'Huge',
                cols: 15,
                rows: 12
            }
        ];
        this.applySize(2);
    }

    public applySize(id: number): void {
        super.applySize(id);
        this.sizeChange.next(id);
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);
        const user = this.accountService.getUserDetails();

        // read settings - stop false settings updated observable triggering
        let settingsUpdated =
            (v.customer !== undefined &&
                JSON.stringify(this.customerIds) !== JSON.stringify(v.customer.map((element: any) => element.value))) ||
            (v.equipment !== undefined &&
                JSON.stringify(this.equipmentIds) !==
                    JSON.stringify(v.equipment.map((element: any) => element.value))) ||
            (v.severity !== undefined &&
                JSON.stringify(this.severities) !== JSON.stringify(v.severity.map((element: any) => element.value)));

        this.customerIds = v.customer ? v.customer.map((element: any) => element.value) : [user!.EntityId];
        this.customers = v.customer ? v.customer.map((element: any) => element.label) : [user!.UsersEntityName];

        // Instantiate the customer object if there is only one customer selected
        // Used for the customers logo
        if (this.customers.length === 1 && this.customerIds.length === 1) {
            if (this.customers[0] !== 'Virsae Owners') {
                this.customer = new Customer(this.customerIds[0], this.customers[0]);
            }
        } else {
            if (this.logoSub) {
                this.logoSub.unsubscribe();
            }
            this.customer = null;
        }
        this.customerGroup = v.customer
            ? v.customer
            : [
                  {
                      label: user!.UsersEntityName,
                      value: user!.EntityId
                  }
              ];
        this.equipmentIds = v.equipment ? v.equipment.map((element: any) => element.value) : [];
        this.severities = v.severity ? v.severity.map((element: any) => element.value) : [];

        this.configured = v.severity && this.customers.length > 0 && this.severities.length > 0;
        this.generatedNameTag = this.configured ? `${this.customers.join(', ')}` : 'Unconfigured';
        this.customNameTag = v.nameTag;

        if (this.configured && settingsUpdated) {
            this.alarmsCustomer = new Map<string, Alarm[]>();
            this.alarms = [];
            this.unresolved = 0;
            this.settingsChanged.next(true);
        }
    }

    dispose() {}

    resetData() {
        this.alarmsCustomer = new Map<string, Alarm[]>();
        this.alarms = [];
        this.unresolved = 0;
    }

    public processAlarmData(customerId: string, data: any) {
        const row = data && data.length && data.filter(item => !item.suspendedAlarmNotificationId);
        if (row && row.length) {
            // shortcut out if filtering out all severities
            if (this.severities.length === 0) {
                return;
            }
            // read rows
            const alarms: Alarm[] = [];
            for (const rowData of row) {
                const alarm = {
                    id: rowData.alarmId,
                    displayName: rowData.alarmName,
                    administeredId: rowData.administeredId,
                    firstOccurred: rowData.activatedTime || null,
                    lastOccurred: null,
                    equipmentId: rowData.equipmentId,
                    equipment: rowData.equipmentName ?? 'Unknown',
                    customerId: rowData.customerId,
                    customer: '',
                    severity: +rowData.severity,
                    resolveStatus: null
                };

                // check alarm against settings filters
                const isSeverityMatch = this.severities.some(element => element === Number(alarm.severity));
                const isEquipmentMatch =
                    this.equipmentIds.length > 0
                        ? this.equipmentIds.some(element => element === alarm.equipmentId)
                        : true;
                if (isSeverityMatch && isEquipmentMatch) {
                    // fill customer name from settings map
                    const customerMap = this.customerGroup.find(
                        element => element.value.toLowerCase() === alarm.customerId.toLowerCase()
                    );
                    alarm.customer = customerMap ? customerMap.label : 'Unknown';

                    // add alarm
                    alarms.push(alarm);
                }
            }

            // update alarms
            this.alarmsCustomer.set(customerId, alarms);
            this.updateAlarms();
            // sort data for grid
        }
    }

    public processResolve(customerId: string, data: any) {
        const rows = data[0].data;
        if (rows !== null) {
            // collect alarms to remove
            const resolved: string[] = [];
            for (const row of rows) {
                resolved.push(row.AlarmID);
            }

            // remove alarms
            this.removeAlarms(customerId, resolved);
        }
    }

    private removeAlarms(customerId: string, removeIds: string[]) {
        if (!this.alarmsCustomer.has(customerId)) {
            return;
        }
        // filter out alarms from customer
        let alarms = this.alarmsCustomer.get(customerId);
        alarms = alarms!.filter(a => removeIds.every(removeId => a.id !== removeId));
        this.alarmsCustomer.set(customerId, alarms);
        // update alarms structures
        this.updateAlarms();
    }

    public updateAlarms() {
        // flatten alarms map into a single array
        this.alarms = [];
        this.alarmsCustomer.forEach(alarms => this.alarms.push.apply(this.alarms, alarms));
        // populate some debug stats
        (window as any).countRx = this.alarmsCustomer.size;
        (window as any).countTx = this.customers.length;
        // update metadata
        this.unresolved = this.alarms.length;
        this.alarms.sort((a, b) => {
            if (a && b && a.firstOccurred && b.firstOccurred) {
                return new Date(b.firstOccurred).getTime() - new Date(a.firstOccurred).getTime();
            }
        });
    }
}
