import * as Actions from '@actions/index';
import {
    AccountService,
    DashletDataProcessService,
    DashletService,
    DashletSettingsService,
    RealtimeGatewayService,
    TimeoutService
} from '@services/index';
import {
    CipData,
    ColumnTypes,
    CustomerLogo,
    DashletVoiceQualityStreamsInProgress,
    MenuItem,
    UserDetails
} from '@models/index';
import { AppState, selectDataFromCommonEntity, selectEntity } from '@reducers/index';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CipStreamDetail } from '@models/dataObjects/cipStreamDetail.model';
import { Subject, Subscription, Observable } from 'rxjs';
import { retry, map } from 'rxjs/operators';
import { VqmData } from '@models/dataObjects/vqmData.model';

@Component({
    selector: 'app-dashlet-voice-quality-streams-in-progress',
    templateUrl: './dashlet-voice-quality-streams-in-progress.component.html',
    styleUrls: ['./dashlet-voice-quality-streams-in-progress.component.scss']
})
export class DashletVoiceQualityStreamsInProgressComponent implements OnInit, OnDestroy {
    @Input() dashlet!: DashletVoiceQualityStreamsInProgress;

    public columns: ColumnTypes[] = [
        {
            columnDef: 'sourceSeverity',
            header: '',
            cell: (row: CipData) => this.getSeverityColor(Number(row.sourceMos)),
            type: 'severity'
        },
        {
            columnDef: 'sourceIp',
            header: 'Source IP',
            filterType: 'text',
            dataTooltip: (row: CipData) => row.sourceIp?.toString() ?? ''
        },
        {
            columnDef: 'sourceName',
            header: 'Name',
            filterType: 'text',
            dataTooltip: (row: CipData) => row.sourceName?.toString() ?? ''
        },
        { columnDef: 'sourceEndPoint', header: 'End Point', display: () => this.isExpandedView(), filterType: 'text' },
        {
            columnDef: 'sourceMos',
            header: 'MOS',
            display: () => this.isExpandedView(),
            filterType: 'slider',
            sliderRange: [0, 5],
            type: 'numeric'
        },
        {
            columnDef: 'sourceIpnr',
            header: 'IPNR',
            specialHeaderType: 'ipnr',
            display: () => this.isExpandedView(),
            filterType: 'text',
            type: 'numeric',
            width: '50px'
        },
        {
            columnDef: 'remoteSeverity',
            header: '',
            cell: (row: CipData) => this.getSeverityColor(Number(row.remoteMos)),
            type: 'severity'
        },
        {
            columnDef: 'remoteIp',
            header: 'Remote IP',
            filterType: 'text',
            dataTooltip: (row: CipData) => row.remoteIp?.toString() ?? ''
        },
        {
            columnDef: 'remoteName',
            header: 'Name',
            filterType: 'text',
            dataTooltip: (row: CipData) => row.remoteName?.toString() ?? ''
        },
        { columnDef: 'remoteEndPoint', header: 'End Point', display: () => this.isExpandedView(), filterType: 'text' },
        {
            columnDef: 'remoteMos',
            header: 'MOS',
            display: () => this.isExpandedView(),
            filterType: 'slider',
            sliderRange: [0, 5],
            type: 'numeric'
        },
        {
            columnDef: 'remoteIpnr',
            header: 'IPNR',
            specialHeaderType: 'ipnr',
            display: () => this.isExpandedView(),
            filterType: 'text',
            type: 'numeric',
            width: '50px'
        }
    ];
    sourceMosRange: number[];
    remoteMosRange: number[];
    sortType: string;
    startTime: number;

    sourceIpSearch!: string | null;
    sourceNameSearch!: string | null;
    sourceEndPointSearch!: string | null;
    sourceIpnrSearch!: number | null;
    remoteIpSearch!: string | null;
    remoteNameSearch!: string | null;
    remoteEndPointSearch!: string | null;
    remoteIpnrSearch!: number | null;

    filterCount: number = 0;
    filteredCountSubject = new Subject<number>();
    filterCountsubscription!: Subscription;

    openSubcontainer!: string | null;
    detailsIndex!: number;
    detailsHasPrev!: boolean;
    detailsHasNext!: boolean;
    userDetails: UserDetails;
    public items: MenuItem[] = [
        {
            label: 'View details',
            command: (row: CipData) => {
                this.openDetails(row);
            },
            disabled: false,
            title: ''
        }
    ];
    selectedCip!: CipData;
    cipStreamDetail: Observable<CipStreamDetail> = new Subject();
    selectedRowSourceIp!: number;
    selectedRowRemoteIp!: number | null;
    rowIndex!: number;
    public displayTable: boolean = true;

    private subscription: Subscription = new Subscription();

    constructor(
        public dashletService: DashletService,
        private dashletDataProcessingService: DashletDataProcessService,
        private accountService: AccountService,
        private settingsService: DashletSettingsService,
        public timeoutService: TimeoutService,
        public realtimeService: RealtimeGatewayService,
        private cdr: ChangeDetectorRef,
        private store$: Store<AppState>
    ) {
        {
            this.sourceMosRange = [-10, 50];
            this.remoteMosRange = [-10, 50];
            this.sortType = 'multiple';
            this.startTime = Date.now();

            this.userDetails = this.accountService.getUserDetails();
        }
    }

    ngOnInit(): void {
        this.onLoad();

        this.subscription.add(
            this.dashlet.settingsUpdated.subscribe(() => {
                this.dashlet.resetData();
                this.onLoad();
            })
        );

        this.subscription.add(
            this.dashlet.sizeChange.subscribe(() => {
                this.displayTable = false;
                this.cdr.detectChanges();

                this.displayTable = true;
                this.cdr.detectChanges();
            })
        );
    }

    public onLoad(): void {
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );

        if (this.dashlet.equipmentIds.length === 1 && this.dashlet.equipmentIds[0] === '=all=') {
            this.settingsService.getRtcpReceivers(this.dashlet.locationId).subscribe(res => {
                this.dashlet.getPhoneNameList();
                const temp = res.map((d: any) => d.value);
                temp.forEach(equipmentId => {
                    this.store$.dispatch(
                        Actions.SubscribeToRealTimeService({
                            equipmentId: equipmentId,
                            command: this.dashlet.commandTypeIdRtcpNew
                        })
                    );
                    this.store$.dispatch(
                        Actions.GetNotifyCommonEntitys({
                            equipmentId: equipmentId,
                            commandTypeId: this.dashlet.commandTypeIdRtcpNew
                        })
                    );
                    this.subscription.add(
                        this.store$
                            .pipe(select(selectDataFromCommonEntity(equipmentId + this.dashlet.commandTypeIdRtcpNew)))
                            .subscribe(data => {
                                if (data && data[0]) {
                                    const rtcpKey = `${equipmentId}:${this.dashlet.commandTypeIdRtcpNew}`;
                                    this.dashlet.processTime = Date.now();
                                    this.dashlet.processRtcpData(rtcpKey, data);
                                }
                            })
                    );
                });
            });
        } else {
            this.dashlet.getPhoneNameList();
            this.dashlet.equipmentIds.forEach(equipmentId => {
                this.store$.dispatch(
                    Actions.SubscribeToRealTimeService({
                        equipmentId: equipmentId,
                        command: this.dashlet.commandTypeIdRtcpNew
                    })
                );
                this.store$.dispatch(
                    Actions.GetNotifyCommonEntitys({
                        equipmentId: equipmentId,
                        commandTypeId: this.dashlet.commandTypeIdRtcpNew
                    })
                );
                this.subscription.add(
                    this.store$
                        .pipe(select(selectDataFromCommonEntity(equipmentId + this.dashlet.commandTypeIdRtcpNew)))
                        .subscribe(data => {
                            if (data && data[0]) {
                                const rtcpKey = `${equipmentId}:${this.dashlet.commandTypeIdRtcpNew}`;
                                this.dashlet.processTime = Date.now();
                                this.dashlet.processRtcpData(rtcpKey, data);
                            }
                        })
                );
            });
        }

        this.settingsService.getEquipment(this.dashlet.locationId, 'ACM').subscribe(receivers => {
            receivers.forEach(acmId => {
                this.store$.dispatch(
                    Actions.SubscribeToRealTimeService({
                        equipmentId: acmId['value'],
                        command: this.dashlet.commandTypeIdIpnrLookup
                    })
                );
                this.store$.dispatch(
                    Actions.GetNotifyCommonEntitys({
                        equipmentId: acmId['value'],
                        commandTypeId: this.dashlet.commandTypeIdIpnrLookup
                    })
                );
                this.subscription.add(
                    this.store$
                        .pipe(
                            select(
                                selectDataFromCommonEntity(`${acmId['value']}` + this.dashlet.commandTypeIdIpnrLookup)
                            )
                        )
                        .subscribe(data => {
                            if (data) {
                                const ipnrList = data[0].data;
                                this.dashlet.ipnrMap.updateMap(ipnrList);
                            }
                            this.dashlet.processData();
                        })
                );
            });
        });

        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.locationId,
                command: this.dashlet.commandTypeIdLync,
                idType: 'locationId'
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.locationId,
                commandTypeId: this.dashlet.commandTypeIdLync
            })
        );
        this.subscription.add(
            this.store$
                .pipe(select(selectDataFromCommonEntity(this.dashlet.locationId + this.dashlet.commandTypeIdLync)))
                .subscribe(data => {
                    if (data) {
                        const lyncKey = `${this.dashlet.locationId}:${this.dashlet.commandTypeIdLync}`;
                        this.dashlet.processTime = Date.now();
                        this.dashlet.processLyncData(lyncKey, data);
                    }
                })
        );
        this.subscription.add(
            (this.filterCountsubscription = this.filteredCountSubject.subscribe(count => {
                this.filterCount = count;
            }))
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
    public getSeverityColor(qos: number): number {
        if (!qos) {
            return -1;
        } else if (qos < 0) {
            return -1;
        } else if (qos < 3.6) {
            return 2;
        } else if (qos < 4) {
            return 1;
        } else {
            return 0;
        }
    }

    private isExpandedView(): boolean {
        const s = this.dashlet.getSize();
        if (s.id === 0) {
            return false;
        } else if (s.id === 1) {
            return false;
        }
        if (s.id === 2) {
            return true;
        }
        if (s.id === 3) {
            return true;
        }
        return false;
    }

    public openDetails(cipData: CipData) {
        this.openSubcontainer = 'details';
        this.dashlet.displayHeader = false;
        this.selectedCip = cipData;
        this.detailsIndex = this.dashlet.cipData.indexOf(this.selectedCip) + 1;
        this.rowClick(cipData, this.detailsIndex);
        this.setDetailsCip();
    }

    public detailsPrev() {
        if (this.detailsHasPrev) {
            this.detailsIndex--;
            this.setDetailsCip();
        }
    }

    detailsNext() {
        if (this.detailsHasNext) {
            this.detailsIndex++;
            this.setDetailsCip();
        }
    }

    private setDetailsCip(): any {
        this.detailsHasPrev = this.detailsIndex > 0;
        this.detailsHasNext = this.detailsIndex < this.filterCount - 1;
        const selectedCipData = this.selectedCip;

        this.cipStreamDetail = this.dashletDataProcessingService
            .getCipStreamDetail(
                this.dashlet.customer.customerId,
                selectedCipData.sourceIp,
                selectedCipData.sourceEndPoint,
                selectedCipData.sourceName,
                selectedCipData.sourcePhoneName,
                selectedCipData.remoteIp
            )
            .pipe(
                retry(3),
                map((result: any[]) => {
                    let cipResult: CipStreamDetail = {
                        codec: selectedCipData.cipAdditional.codec,
                        dscp: selectedCipData.cipAdditional.dscp,
                        endPoint: selectedCipData.sourceEndPoint,
                        ipnr: selectedCipData.sourceIpnr,
                        port: selectedCipData.cipAdditional.port,
                        reveiverName: selectedCipData.cipAdditional.rtcpReceiver,
                        startTime: selectedCipData.callStart,
                        streamLength: selectedCipData.cipAdditional.length,

                        nearEndVqmData: this.generateVqmData(result[0]),
                        farEndVqmData: this.generateVqmData(result[1])
                    };

                    return cipResult;
                })
            );
    }

    private generateVqmData(data: any): VqmData {
        let vqmData: VqmData = {
            dscpAudio: data.dscpAudio,
            dscpSignal: data.dscpSignalling,
            dscpVideo: data.dscpVideo,
            dsp: data.dspVersion,
            ethernetAutoNegotiation: this.FormatRelatedDataValue(data.ethernetNegotiation),
            ethernetDuplex: data.ethernetDuplex,
            ethernetSpeed: data.ethernetSpeed,
            framingCode: this.FormatRelatedDataValue(data.framingCode8021Q),
            hardware: data.hardwareVersion,
            ip: data.ipAddress,
            layerAudioPriority: data.layer28021QAudioPriority,
            layerSignalPriority: data.layer28021QSignallingPriority,
            site: data.site,
            siteId: data.siteId,
            software: data.softwareVersion,
            taggingStatus: this.FormatRelatedDataValue(data.taggingStatus8021Q),
            type: data.itemType,
            vlan: data.layer28021QVLAN
        };
        return vqmData;
    }

    public GetEthernetSpeed(speed: number): string {
        if (!speed) return '';
        if (speed >= 1000) return (speed / 1000).toString() + ' GB/s';
        return speed + ' MB/s';
    }

    private FormatRelatedDataValue(value: string): string {
        if (value === '-1') return 'Unknown';
        if (value === '0') return 'Off';
        if (value === '1') return 'On';
        return value;
    }

    closeDetails() {
        this.openSubcontainer = null;
        this.dashlet.displayHeader = true;
    }

    rowClick(cipData: CipData, index: number): void {
        this.selectedRowSourceIp = cipData.sourceIp;
        this.selectedRowRemoteIp = cipData.remoteIp;
        this.rowIndex = index;
    }

    getMosDataTooltip(data: number) {
        if (data === -1) {
            //skype call mos value
            return 'This is a Skype call, which does not produce a MOS rating.';
        } else {
            return '';
        }
    }
}
