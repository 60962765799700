import * as Actions from '@actions/index';
import { AppState, selectDataFromCommonEntity, selectEntity } from '@reducers/index';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CustomerLogo, DashletVoiceQualityActiveStreams } from '@models/index';
import { DashletLineGraphComponent } from '../dashlet-line-graph/dashlet-line-graph.component';
import { DashletSettingsService, DashletService } from '@services/index';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-dashlet-voice-quality-active-streams',
    templateUrl: './dashlet-voice-quality-active-streams.component.html',
    styleUrls: ['./dashlet-voice-quality-active-streams.component.scss']
})
export class DashletVoiceQualityActiveStreamsComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletVoiceQualityActiveStreams;
    private hasPreviousSubscriptions: boolean = false;

    interval: number = 20;

    private pChart: DashletLineGraphComponent;
    get chart() {
        return this.pChart;
    }

    @ViewChild('chart') set chart(value: DashletLineGraphComponent) {
        this.pChart = value;
        this.updateChart();
    }

    private pLargeChart: DashletLineGraphComponent;
    get largeChart() {
        return this.pLargeChart;
    }
    @ViewChild('largeChart') set largeChart(value: DashletLineGraphComponent) {
        this.pLargeChart = value;
        this.updateChart();
    }

    private highQoS = {
        label: 'Good',
        pointRadius: 0,
        pointHitRadius: 10,
        borderColor: 'white',
        backgroundColor: this.dashletService.getSeverityColor(0),
        pointBorderColor: this.dashletService.getSeverityColor(0),
        pointBackgroundColor: this.dashletService.getSeverityColor(0),
        pointHighlightStroke: 'white',
        fill: true,
        data: []
    };

    private averageQoS = {
        label: 'Satisfactory',
        pointRadius: 0,
        pointHitRadius: 10,
        borderColor: 'white',
        backgroundColor: this.dashletService.getSeverityColor(1),
        pointBorderColor: this.dashletService.getSeverityColor(1),
        pointBackgroundColor: this.dashletService.getSeverityColor(1),
        pointHighlightStroke: 'white',
        fill: true,
        data: []
    };

    private poorQoS = {
        label: 'Poor',
        pointRadius: 0,
        pointHitRadius: 10,
        borderColor: 'white',
        backgroundColor: this.dashletService.getSeverityColor(2),
        pointBorderColor: this.dashletService.getSeverityColor(2),
        pointBackgroundColor: this.dashletService.getSeverityColor(2),
        pointHighlightStroke: 'white',
        fill: true,
        data: []
    };

    private unkownQoS = {
        label: 'Unknown',
        pointRadius: 0,
        pointHitRadius: 10,
        borderColor: 'white',
        backgroundColor: this.dashletService.getSeverityColor(-1),
        pointBorderColor: this.dashletService.getSeverityColor(-1),
        pointBackgroundColor: this.dashletService.getSeverityColor(-1),
        pointHighlightStroke: 'white',
        fill: true,
        data: []
    };

    lineData: any = {};
    chartOptions: any = {
        scales: {
            x: {
                type: 'time',
                grid: {
                    display: false
                },
                time: {
                    unit: 'minute',
                    displayFormats: {
                        minute: 'HH:mm'
                    },
                    stepSize: 6,
                    max: null,
                    min: null
                },
                ticks: {
                    fontSize: 10.2,
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0
                }
            },
            y: {
                stacked: true,
                grid: {
                    display: false
                },
                ticks: {
                    fontSize: 10.2,
                    beginAtZero: true,
                    suggestedMin: 1,
                    callback: function (value) {
                        if (value % 1 === 0) {
                            return value;
                        }
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'bottom',
                reverse: true
            },
            tooltip: {
                callback: {
                    title: function () {},
                    footer: tipItem => {
                        let string = [];
                        string.push(tipItem[0].xLabel.toString().split(' ').slice(4).join(' ').split('(')[0]);
                        string.push('(' + tipItem[0].xLabel.toString().split(' ').slice(4).join(' ').split('(')[1]);
                        return string;
                    }
                }
            }
        },

        animation: {
            duration: 0
        }
    };

    largeChartOptions: any = {
        scales: {
            x: {
                type: 'time',
                grid: {
                    display: false
                },
                time: {
                    unit: 'minute',
                    displayFormats: {
                        minute: 'HH:mm'
                    },
                    stepSize: 6,
                    max: null,
                    min: null
                },
                ticks: {
                    fontSize: 10.2,
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0
                }
            },
            y: {
                stacked: true,
                grid: {
                    display: false
                },
                ticks: {
                    fontSize: 10.2,
                    beginAtZero: true,
                    suggestedMin: 1,
                    callback: function (value) {
                        if (value % 1 === 0) {
                            return value;
                        }
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'bottom',
                reverse: true
            },
            tooltip: {
                callback: {
                    title: function () {},
                    footer: tipItem => {
                        let string = [];
                        string.push(tipItem[0].xLabel.toString().split(' ').slice(4).join(' ').split('(')[0]);
                        string.push('(' + tipItem[0].xLabel.toString().split(' ').slice(4).join(' ').split('(')[1]);
                        return string;
                    }
                }
            }
        },

        animation: {
            duration: 0
        }
    };

    private subscription: Subscription = new Subscription();
    private subscriptionDocument: Subscription = new Subscription();

    constructor(
        public dashletService: DashletService,
        private settingsService: DashletSettingsService,
        private store$: Store<AppState>
    ) {
        this.lineData = {
            datasets: [this.unkownQoS, this.poorQoS, this.averageQoS, this.highQoS]
        };
    }

    ngOnInit() {
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );

        this.subscription.add(
            this.dashlet.onDataUpdated.subscribe(() => {
                this.updateChart();
            })
        );
        this.updateChart();
        this.subscription.add(
            this.dashlet.equipmentLoaded.subscribe(() => {
                if (this.hasPreviousSubscriptions) {
                    this.subscriptionDocument.unsubscribe();
                    this.subscriptionDocument = new Subscription();
                }
                this.subscribeToDocument();
            })
        );
        this.subscribeToDocument();
    }

    private subscribeToDocument(): void {
        if (this.dashlet.equipmentIds && this.dashlet.locationId && this.dashlet.customer.customerId) {
            this.dashlet.resetData();
            this.subscriptionDocument.add(
                this.settingsService.getEquipment(this.dashlet.locationId, 'ACM').subscribe(receivers => {
                    receivers.forEach(acmId => {
                        this.store$.dispatch(
                            Actions.SubscribeToRealTimeService({
                                equipmentId: acmId['value'],
                                command: this.dashlet.commandTypeIdIpnrLookup
                            })
                        );
                        this.store$.dispatch(
                            Actions.GetNotifyCommonEntitys({
                                equipmentId: acmId['value'],
                                commandTypeId: this.dashlet.commandTypeIdIpnrLookup
                            })
                        );

                        this.subscriptionDocument.add(
                            this.store$
                                .pipe(
                                    select(
                                        selectDataFromCommonEntity(
                                            acmId['value'] + this.dashlet.commandTypeIdIpnrLookup
                                        )
                                    )
                                )
                                .subscribe(data => {
                                    if (data) this.dashlet.ipnrMap.updateMap(data);
                                })
                        );
                    });
                })
            );

            const key = this.dashlet.locationId + ':' + this.dashlet.commandTypeIdLync;
            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: this.dashlet.locationId,
                    command: this.dashlet.commandTypeIdLync
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: this.dashlet.locationId,
                    commandTypeId: this.dashlet.commandTypeIdLync
                })
            );
            this.subscriptionDocument.add(
                this.store$
                    .pipe(select(selectDataFromCommonEntity(this.dashlet.locationId + this.dashlet.commandTypeIdLync)))
                    .subscribe(data => {
                        if (data) {
                            this.dashlet.processLync(key, data);
                        }
                    })
            );
            this.dashlet.equipmentIds.forEach(equipmentId => {
                const key = equipmentId + ':' + this.dashlet.commandTypeIdRtcpNew;
                this.store$.dispatch(
                    Actions.SubscribeToRealTimeService({
                        equipmentId: equipmentId,
                        command: this.dashlet.commandTypeIdRtcpNew
                    })
                );
                this.store$.dispatch(
                    Actions.GetNotifyCommonEntitys({
                        equipmentId: equipmentId,
                        commandTypeId: this.dashlet.commandTypeIdRtcpNew
                    })
                );
                this.subscriptionDocument.add(
                    this.store$
                        .pipe(select(selectDataFromCommonEntity(equipmentId + this.dashlet.commandTypeIdRtcpNew)))
                        .subscribe(data => {
                            if (data) {
                                this.dashlet.processRtcpNew(key, data);
                            }
                        })
                );
            });
            this.hasPreviousSubscriptions = true;
        }
    }

    public ngOnDestroy(): void {
        this.subscriptionDocument.unsubscribe();
        this.subscription.unsubscribe();
    }

    private updateChart() {
        this.highQoS.data = this.dashlet.mosDataGood;
        this.averageQoS.data = this.dashlet.mosDataSatisfactory;
        this.poorQoS.data = this.dashlet.mosDataPoor;
        this.unkownQoS.data = this.dashlet.mosDataUnknown;
        this.lineData = {
            datasets: [this.unkownQoS, this.poorQoS, this.averageQoS, this.highQoS]
        };
        const max = new Date();
        max.setSeconds(Math.floor(max.getSeconds() / this.interval) * this.interval, 0);
        const min = new Date(max);

        if (this.dashlet) {
            if (this.dashlet.getSize().id === 0) {
                min.setMinutes(min.getMinutes() - 30);
            } else if (this.dashlet.getSize().id === 1) {
                min.setMinutes(min.getMinutes() - 60);
            }
        }
        this.chartOptions.scales.x.min = min;
        this.chartOptions.scales.x.max = max;

        if (this.chart) this.chart.updateChart();
        if (this.largeChart) this.largeChart.updateChart();
    }
}
