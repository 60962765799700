import * as Actions from '@actions/index';
import { AccountService, DashletService } from '@services/index';
import { AppState, selectCustomers, selectDataFromCommonEntity } from '@reducers/index';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DashletDatabaseHealth } from '@models/index';
import { select, Store } from '@ngrx/store';
import { Subject, combineLatest, takeUntil } from 'rxjs';

@Component({
    selector: 'app-dashlet-database-health',
    templateUrl: './dashlet-database-health.component.html',
    styleUrls: ['./dashlet-database-health.component.scss']
})
export class DashletDatabaseHealthComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletDatabaseHealth;

    private destroy$ = new Subject<void>();

    sortType: string = 'number';
    openSubcontainer: string;
    nameSearch: any;

    entityGroup: number;
    businessPartnerId: string;
    sortColumn: string = 'severity';
    sortDesc: boolean = true;

    currentDataIndex: number = 0;

    dtuHistoricOptions: any;

    constructor(
        private accountService: AccountService,
        private dashletService: DashletService,
        private store$: Store<AppState>
    ) {
        this.sortType = 'number';

        this.dtuHistoricOptions = {
            scales: {
                x: {
                    type: 'time',
                    time: {
                        unit: 'hour'
                    },
                    ticks: {
                        display: false
                    },
                    gridLines: {
                        display: false
                    }
                },
                y: {
                    ticks: {
                        display: false,
                        suggestedMin: 0,
                        suggestedMax: 105
                    },
                    gridLines: {
                        drawBorder: false,
                        display: false
                    }
                }
            },
            animation: {
                duration: 0
            },
            plugins: {
                tooltip: {
                    enabled: false
                },
                legend: {
                    display: false,
                    position: 'bottom'
                }
            }
        };
    }

    ngOnInit() {
        if (this.dashlet.configured && this.dashlet.businessPartners.length !== 0) {
            this.dashlet.businessPartners.forEach(item => {
                this.store$.dispatch(Actions.GetBPAssociatedCustomers({ businessPartnerId: item.Id }));
                this.store$.pipe(select(selectCustomers(item.Id))).subscribe(customers => {
                    if (customers) {
                        this.dashlet.customers = [...this.dashlet.customers, ...customers];
                    }
                });
            });

            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: this.dashlet.equipmentId,
                    command: this.dashlet.commandTypeId,
                    idType: 'customerId'
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: this.dashlet.equipmentId,
                    commandTypeId: this.dashlet.commandTypeId
                })
            );

            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: this.dashlet.equipmentId,
                    command: this.dashlet.commandTypeIdRecommendations,
                    idType: 'customerId'
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: this.dashlet.equipmentId,
                    commandTypeId: this.dashlet.commandTypeIdRecommendations
                })
            );

            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: this.dashlet.equipmentId,
                    command: this.dashlet.commandTypeIdHistoricDTU,
                    idType: 'customerId'
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: this.dashlet.equipmentId,
                    commandTypeId: this.dashlet.commandTypeIdHistoricDTU
                })
            );

            this.store$.dispatch(
                Actions.GetCommonHistoric({
                    equipmentId: this.dashlet.equipmentId,
                    commandTypeId: this.dashlet.commandTypeIdRecommendations,
                    from: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                    to: new Date().toISOString(),
                    max: 100000
                })
            );

            const sub1$ = this.store$.pipe(
                select(selectDataFromCommonEntity(this.dashlet.equipmentId + this.dashlet.commandTypeId)),
                takeUntil(this.destroy$)
            );
            const sub2$ = this.store$.pipe(
                select(
                    selectDataFromCommonEntity(this.dashlet.equipmentId + this.dashlet.commandTypeIdRecommendations)
                ),
                takeUntil(this.destroy$)
            );
            const sub3$ = this.store$.pipe(
                select(selectDataFromCommonEntity(this.dashlet.equipmentId + this.dashlet.commandTypeIdHistoricDTU)),
                takeUntil(this.destroy$)
            );

            combineLatest([sub1$, sub2$, sub3$])
                .pipe(takeUntil(this.destroy$))
                .subscribe(res => {
                    if (res[0] && res[1] && res[2]) {
                        const databaseInformationData =
                            res[0][0]['data'] !== null || res[0][0]['data'] !== undefined ? res[0][0]['data'] : null;
                        const recommendationsData =
                            res[1][0]['data'] !== null || res[1][0]['data'] !== undefined ? res[1][0]['data'] : null;
                        const historicDtuData =
                            res[2][0]['data'] !== null || res[2][0]['data'] !== undefined ? res[2][0]['data'] : null;
                        this.dashlet.processDatabaseHealthData(
                            databaseInformationData,
                            recommendationsData,
                            historicDtuData
                        );
                    }
                });
        }

        var userDetail = this.accountService.getUserDetails();
        this.entityGroup = userDetail.EntityGroup;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.dashlet.dispose();
    }
    get smallLayout() {
        return this.dashlet.getSize().id === 0;
    }

    get mediumLayout() {
        return this.dashlet.getSize().id === 1;
    }

    get largeLayout() {
        return this.dashlet.getSize().id === 2;
    }

    get hugeLayout() {
        return this.dashlet.getSize().id === 3;
    }

    getSeverity(data: any) {
        return this.dashletService.getSeverityColor(data.severity);
    }

    sort(property: string) {
        const prevCol = this.sortColumn;
        this.sortColumn = property;

        switch (property) {
            case 'customerName':
                this.sortType = 'string';
                break;
            case 'so':
                this.sortType = 'string';
                break;
            default:
                this.sortType = 'number';
        }

        if (prevCol === property) this.sortDesc = !this.sortDesc; //change the direction
    }

    isSortingDesc(column: string) {
        return this.isSorting(column) && this.sortDesc;
    }

    isSortingAsc(column: string) {
        return this.isSorting(column) && !this.sortDesc;
    }

    isSorting(column: string) {
        return column === this.sortColumn;
    }

    getRecommendationColor(autoScaleAction: number) {
        switch (autoScaleAction) {
            case 3:
                return this.dashletService.getSeverityColor(2);
            case 2:
                return this.dashletService.getSeverityColor(0);
            case 1:
                return this.dashletService.getSeverityColor(-1);
            default:
                return this.dashletService.getSeverityColor(-1);
        }
    }
}
