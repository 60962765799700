/* eslint-disable no-undef */
/* eslint-disable @angular-eslint/component-selector */
import * as Actions from '@actions/index';
import { DashletService } from '@services/index';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CallInfo, ColumnTypes, CustomerLogo, DashletSystemHealthCiscoUCM, DataBaseSummary } from '@models/index';
import { select, Store } from '@ngrx/store';
import { AppState, selectEntity, selectDataFromCommonEntity } from '@reducers/index';
import { Subscription } from 'rxjs';

@Component({
    selector: 'dashlet-system-health-cisco-ucm',
    templateUrl: 'dashlet-system-health-cisco-ucm.component.html',
    styleUrls: ['dashlet-system-health-cisco-ucm.component.scss']
})
export class DashletSystemHealthCiscoUCMComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletSystemHealthCiscoUCM;

    public callRateColumns: ColumnTypes[] = [
        {
            columnDef: 'callActive',
            header: 'Active',
            type: 'numeric'
        },
        {
            columnDef: 'callAttempt',
            header: 'Attempts',
            type: 'numeric'
        },
        {
            columnDef: 'callCompl',
            header: 'Complete',
            type: 'numeric'
        },
        {
            columnDef: 'videoCallActive',
            header: 'Active',
            type: 'numeric'
        },
        {
            columnDef: 'videoCallCompl',
            header: 'Complete',
            type: 'numeric'
        }
    ];

    public sipColumns: ColumnTypes[] = [
        {
            columnDef: 'trunkGroup',
            header: 'Trunk',
            dataTooltip: (row: CallInfo) => row.trunkGroup
        },
        {
            columnDef: 'callAttempt',
            header: 'Attempts',
            type: 'numeric'
        },
        {
            columnDef: 'callCompl',
            header: 'Complete',
            type: 'numeric'
        },
        {
            columnDef: 'videoCallActive',
            header: 'Active',
            type: 'numeric'
        },
        {
            columnDef: 'videoCallCompl',
            header: 'Complete',
            type: 'numeric'
        }
    ];

    openSubcontainer = null;
    private subscription: Subscription = new Subscription();

    constructor(private dashletService: DashletService, private store$: Store<AppState>) {}

    ngOnInit() {
        // Logo
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );

        // RTM SIP Data
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdRtmSip
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdRtmSip
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdRtmSip
                        )
                    )
                )
                .subscribe(data => {
                    if (data) {
                        this.dashlet.processRtmSipData(data);
                    }
                })
        );

        this.store$.dispatch(
            Actions.GetCommonHistoric({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdRtmSip,
                from: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                to: new Date().toISOString(),
                max: 3
            })
        );
        // Database Summary Data
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdDatabaseReplicateInfo
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdDatabaseReplicateInfo
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdDatabaseReplicateInfo
                        )
                    )
                )
                .subscribe(data => {
                    if (data) {
                        this.dashlet.processDatabaseInfo(data);
                    }
                })
        );

        // Call Rate Summary Data
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdCallRateInfo
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdCallRateInfo
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdCallRateInfo
                        )
                    )
                )
                .subscribe(data => {
                    if (data) {
                        this.dashlet.processCallRateInfo(data);
                    }
                })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    getSeverityColor(sev: number) {
        return this.dashletService.getSeverityColor(sev);
    }

    openDetails(container: String) {
        this.openSubcontainer = container;
        this.dashlet.displayHeader = false;
    }

    closeDetails() {
        this.openSubcontainer = null;
        this.dashlet.displayHeader = true;
    }

    getFileSystemColor(data: string) {
        let threshold = parseInt(data);
        if (threshold < 80) {
            return '#0A9B02';
        } else if (threshold >= 80 && threshold < 90) {
            return '#FFA500';
        } else if (threshold >= 90) {
            return '#DC3704';
        }
    }

    public getDatabaseColor(data: DataBaseSummary): string {
        if (data) {
            const info = data.Replicate_State;
            switch (info) {
                case 'Initializing':
                    return this.dashletService.getSeverityColor(1);
                case 'Count Displayed Incorrect':
                    return this.dashletService.getSeverityColor(2);
                case 'Good':
                    return this.dashletService.getSeverityColor(0);
                case 'Bad Replication':
                    return this.dashletService.getSeverityColor(2);
                case 'Setup Failed':
                    return this.dashletService.getSeverityColor(2);
                default:
                    return this.dashletService.getSeverityColor(2);
            }
        } else {
            return this.dashletService.getSeverityColor(2);
        }
    }
}
