import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as Actions from '@actions/index';
import {
    ColumnTypes,
    CustomerLogo,
    DashletSystemHealthMSTeamsRoomsSummary,
    HealthStatusFriendlyName,
    TeamsRoomDataRow
} from '@models/index';
import { Store, select } from '@ngrx/store';
import { AppState, selectDataFromCommonEntity, selectEntity, selectEntityTimer } from '@reducers/index';
import { filter, skip, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DashletService } from '@services/index';

interface TeamsRoomsSummary {
    key: string;
    value: number;
    color?: string;
}
interface PieChartColors {
    color: string;
    colorHexValue: string;
}

interface HealthRatioBarDetails {
    healthTooltip: string;
    healthCount: number;
    color: string;
    healthStatus: string;
}

interface HealthBarSummary {
    critical?: HealthRatioBarDetails;
    nonUrgent?: HealthRatioBarDetails;
    helathy?: HealthRatioBarDetails;
    offline?: HealthRatioBarDetails;
    unknown?: HealthRatioBarDetails;
}

@Component({
    selector: 'dashlet-system-health-ms-teams-rooms-summary',
    templateUrl: 'dashlet-system-health-ms-teams-rooms-summary.component.html',
    styleUrls: ['dashlet-system-health-ms-teams-rooms-summary.component.scss']
})
export class DashletSystemHealthMSTeamsRoomsSummaryComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletSystemHealthMSTeamsRoomsSummary;

    public isTeamsRoomsDataLoading: boolean = true;
    public sortField = 'ragIndicator';
    public isPagination: boolean = true;
    private unsubscribe$ = new Subject<void>();

    // Health bar properties
    public isMouseOverEnabled: boolean = false;
    public criticalHealthRatioBarDetails: HealthRatioBarDetails[] = [];
    public healthBarSummary: HealthBarSummary;

    // Pie charts properties
    public headings = ['Rooms', 'Device Type', 'Make | Model', 'Health', 'Activity'];
    public roomsCount: number;
    public deviceTypeDataForPieChart: object = {};
    public makeAndModelDataForPieChart: object = {};
    public healthDataForPieChart: object = {};
    public activityDataForPieChart: object = {};
    public options = {
        cutout: '80%',
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        },
        layout: {
            padding: {
                left: 10,
                right: 10
            }
        }
    };
    public roomDisplayNameSummary: TeamsRoomsSummary[] = [];
    public deviceTypeSummary: TeamsRoomsSummary[] = [];
    public makeAndModelSummary: TeamsRoomsSummary[] = [];
    public healthSummary: TeamsRoomsSummary[] = [];
    public activitySummary: TeamsRoomsSummary[] = [];
    private colorsArray: PieChartColors[] = [];

    constructor(private store$: Store<AppState>, private dashletService: DashletService) {
        this.colorsArray = this.getChartColors();
    }

    public columns: ColumnTypes[] = [
        {
            columnDef: 'ragIndicator',
            header: '',
            cell: (element: TeamsRoomDataRow) => element.ragIndicator,
            type: 'msTeamsRoomSeverity'
        },
        {
            columnDef: 'roomDisplayName',
            header: 'Room Display Name',
            filterType: 'text'
        },
        {
            columnDef: 'deviceType',
            header: 'Device Type',
            filterType: 'text'
        },
        {
            columnDef: 'makeAndModel',
            header: 'Make | Model',
            filterType: 'text'
        },
        {
            columnDef: 'health',
            header: 'Health',
            filterType: 'text'
        },
        {
            columnDef: 'activity',
            header: 'Activity',
            filterType: 'text'
        }
    ];

    public ngOnInit(): void {
        //#region get customer logo
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));

        this.store$
            .pipe(
                select(selectEntity(this.dashlet.customer.customerId)),
                filter(logo => !!logo), // Filter out undefined values,  convert a value to its corresponding boolean representation.
                take(1)
            )
            .subscribe(logo => {
                this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
            });
        //#endregion

        //#region subscribe to realtime service
        if (this.dashlet.equipment) {
            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    // [ProcessUpTime] Subscribe To Real Time Service
                    equipmentId: this.dashlet.equipment.equipmentId,
                    command: this.dashlet.commandTypeIdTeamsRoomsSummary
                })
            );

            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    commandTypeId: this.dashlet.commandTypeIdTeamsRoomsSummary
                })
            );

            this.store$.dispatch(
                Actions.GetEntityTimer({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    commandTypeId: this.dashlet.commandTypeIdTeamsRoomsSummary,
                    uniqueId: this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdTeamsRoomsSummary
                })
            );

            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdTeamsRoomsSummary
                        )
                    ),
                    filter(data => data !== undefined && data !== null && data.length > 0),
                    takeUntil(this.unsubscribe$)
                )
                .subscribe(data => {
                    this.dashlet.processMSTeamsRoomData(data[0]);

                    this.isTeamsRoomsDataLoading = false;

                    if (this.dashlet.msTeamsRoomsRowData.length <= 0) {
                        // length < 0 means empty array, no data to show
                        this.isTeamsRoomsDataLoading = false;
                        this.isPagination = false;
                    }
                });

            this.store$
                .pipe(
                    select(
                        selectEntityTimer(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdTeamsRoomsSummary
                        )
                    ),
                    skip(1),
                    takeUntil(this.unsubscribe$)
                )
                .subscribe((dataTimeoutExpired: boolean) => {
                    this.dashlet.isDataOld = dataTimeoutExpired;
                });
        }
        //#endregion
    }

    // This method will automatically called when initial page loads and everytime something type in filter text boxes
    public receiveFilterdTableData(recevedTableData: []) {
        this.processDataForChartSummary(recevedTableData);
        this.processDataForHealthRatioBar(recevedTableData);
    }

    //#region donut chart data process
    private processDataForChartSummary(filteredArray: []) {
        this.roomsCount = filteredArray.length;

        let allTableDataRows = this.dashlet.msTeamsRoomsRowData;
        if (filteredArray) {
            allTableDataRows = filteredArray;
        }
        const propertiesToCount = ['roomDisplayName', 'deviceType', 'makeAndModel', 'health', 'activity'];
        const counts = this.getCounts(allTableDataRows, propertiesToCount);

        this.roomDisplayNameSummary = Object.keys(counts.roomDisplayName).map((key: string) => ({
            key,
            value: counts.roomDisplayName[key]
        }));

        this.deviceTypeSummary = Object.keys(counts.deviceType).map((key: string, index: number) => ({
            key,
            value: counts.deviceType[key],
            color: this.setVirsaeColorsPieChart(index)
        }));

        this.makeAndModelSummary = Object.keys(counts.makeAndModel).map((key: string, index: number) => ({
            key,
            value: counts.makeAndModel[key],
            color: this.setVirsaeColorsPieChart(index)
        }));

        this.healthSummary = Object.keys(counts.health).map((key: string) => ({
            key,
            value: counts.health[key],
            color: this.setHealthColorsPieChart(key)
        }));

        this.activitySummary = Object.keys(counts.activity).map((key: string, index: number) => ({
            key,
            value: counts.activity[key],
            color: this.setVirsaeColorsPieChart(index)
        }));

        // Sorting
        this.roomDisplayNameSummary.sort((a, b) => b.value - a.value);
        this.deviceTypeSummary.sort((a, b) => b.value - a.value);
        this.makeAndModelSummary.sort((a, b) => b.value - a.value);
        this.healthSummary.sort((a, b) => b.value - a.value);
        this.activitySummary.sort((a, b) => b.value - a.value);

        // Assigning data to Device Type Pie chart object
        const deviceTypePieChartLabels = this.deviceTypeSummary.map(room => room.key);
        const deviceTypePieChartDataset = this.deviceTypeSummary.map(room => room.value);
        const deviceTypePieChartColors = this.deviceTypeSummary.map(room => room.color);
        this.deviceTypeDataForPieChart = {
            labels: deviceTypePieChartLabels,
            datasets: [
                {
                    data: deviceTypePieChartDataset,
                    backgroundColor: deviceTypePieChartColors,
                    hoverOffset: 4
                }
            ]
        };

        // Assigning data to Make|Model Pie chart object
        const makeAndModelTypePieChartLabels = this.makeAndModelSummary.map(room => room.key);
        const makeAndModelTypePieChartDataset = this.makeAndModelSummary.map(room => room.value);
        const makeAndModelTypePieChartColors = this.makeAndModelSummary.map(room => room.color);
        this.makeAndModelDataForPieChart = {
            labels: makeAndModelTypePieChartLabels,
            datasets: [
                {
                    data: makeAndModelTypePieChartDataset,
                    backgroundColor: makeAndModelTypePieChartColors,
                    hoverOffset: 4
                }
            ]
        };

        // Assigning data to Health Pie chart object
        const healthPieChartLabels = this.healthSummary.map(room => room.key);
        const healthPieChartDataset = this.healthSummary.map(room => room.value);
        const healthPieChartColors = this.healthSummary.map(room => room.color);
        this.healthDataForPieChart = {
            labels: healthPieChartLabels,
            datasets: [
                {
                    data: healthPieChartDataset,
                    backgroundColor: healthPieChartColors,
                    hoverOffset: 4
                }
            ]
        };

        // Assigning data to Activity Pie chart object
        const activityPieChartLabels = this.activitySummary.map(activity => activity.key);
        const activityPieChartDataset = this.activitySummary.map(activity => activity.value);
        const activityPieChartColors = this.activitySummary.map(activity => activity.color);
        this.activityDataForPieChart = {
            labels: activityPieChartLabels,
            datasets: [
                {
                    data: activityPieChartDataset,
                    backgroundColor: activityPieChartColors,
                    hoverOffset: 4
                }
            ]
        };
    }
    //#endregion

    //#region health bar
    public processDataForHealthRatioBar(tableData) {
        this.healthBarSummary = {};

        this.healthSummary.forEach(item => {
            //debugger;
            switch (item.key) {
                case HealthStatusFriendlyName.CRITICAL:
                    let criticalTooltip = this.getCountsForHealthBarTooltip(
                        tableData,
                        HealthStatusFriendlyName.CRITICAL
                    );

                    this.healthBarSummary.critical = {
                        healthStatus: item.key,
                        healthCount: item.value,
                        color: item.color,
                        healthTooltip: criticalTooltip
                    };
                    break;

                case HealthStatusFriendlyName.NON_URGENT:
                    let nonUrgentTooltip = this.getCountsForHealthBarTooltip(
                        tableData,
                        HealthStatusFriendlyName.NON_URGENT
                    );

                    this.healthBarSummary.nonUrgent = {
                        healthStatus: item.key,
                        healthCount: item.value,
                        color: item.color,
                        healthTooltip: nonUrgentTooltip
                    };
                    break;

                case HealthStatusFriendlyName.HEALTHY:
                    let healthyTooltip = this.getCountsForHealthBarTooltip(tableData, HealthStatusFriendlyName.HEALTHY);

                    this.healthBarSummary.helathy = {
                        healthStatus: item.key,
                        healthCount: item.value,
                        color: item.color,
                        healthTooltip: healthyTooltip
                    };
                    break;

                case HealthStatusFriendlyName.OFFLINE:
                    let offlineTooltip = this.getCountsForHealthBarTooltip(tableData, HealthStatusFriendlyName.OFFLINE);

                    this.healthBarSummary.offline = {
                        healthStatus: item.key,
                        healthCount: item.value,
                        color: item.color,
                        healthTooltip: offlineTooltip
                    };
                    break;

                case HealthStatusFriendlyName.UNKNOWN:
                    let unknownTooltip = this.getCountsForHealthBarTooltip(tableData, HealthStatusFriendlyName.UNKNOWN);

                    this.healthBarSummary.unknown = {
                        healthStatus: item.key,
                        healthCount: item.value,
                        color: item.color,
                        healthTooltip: unknownTooltip
                    };
                    break;

                default:
                    break;
            }
        });
    }
    //#endregion

    private getChartColors(): PieChartColors[] {
        const colorsObject = this.dashletService.getChartColors();
        this.colorsArray = Object.entries(colorsObject).map(([color, colorHexValue]) => ({
            color,
            colorHexValue
        }));
        return this.colorsArray;
    }

    private setVirsaeColorsPieChart(index: number): string {
        let colorValue;
        if (index < this.colorsArray.length) {
            colorValue = this.colorsArray[index].colorHexValue;
        } else {
            // if this method called more than colorsArray length, we will recycle agin colorsArray
            let overTheIndex = index - this.colorsArray.length;
            colorValue = this.colorsArray[overTheIndex].colorHexValue;
        }
        return colorValue;
    }

    private setHealthColorsPieChart(health: string): string {
        switch (health) {
            case HealthStatusFriendlyName.CRITICAL:
                return this.dashletService.getMsTeamsRoomSeverityColor(1);
            case HealthStatusFriendlyName.NON_URGENT:
                return this.dashletService.getMsTeamsRoomSeverityColor(2);
            case HealthStatusFriendlyName.HEALTHY:
                return this.dashletService.getMsTeamsRoomSeverityColor(3);
            case HealthStatusFriendlyName.OFFLINE:
                return this.dashletService.getMsTeamsRoomSeverityColor(4);
            default:
                HealthStatusFriendlyName.UNKNOWN;
                return this.dashletService.getMsTeamsRoomSeverityColor(4);
        }
    }

    private getCounts(dataTableRowObjectList, properties) {
        const counts = properties.reduce((acc, prop) => {
            acc[prop] = {};
            return acc;
        }, {});
        dataTableRowObjectList.forEach(dataTableRowObject => {
            properties.forEach(prop => {
                let value = dataTableRowObject[prop];
                if (value) {
                    counts[prop][value] = (counts[prop][value] || 0) + 1;
                }
            });
        });
        return counts;
    }

    private getCountsForHealthBarTooltip(dataTableRowObjectList, healthStatus) {
        let roomNamesArray: string[] = [];

        dataTableRowObjectList
            .filter(item => item.health === healthStatus)
            .forEach(dataTableRowObject => {
                let roomName = dataTableRowObject['roomDisplayName'];

                roomName = roomName === '' ? 'NO ROOM NAME' : roomName;
                roomNamesArray.push(roomName);
            });

        // Capitalize first letter
        const healthStatusType = healthStatus.charAt(0).toUpperCase() + healthStatus.slice(1);

        let roomsTooltip = `${roomNamesArray.length} ${healthStatusType} health status: `;

        roomNamesArray.forEach(roomName => {
            roomsTooltip += `${roomName}, `;
        });
        roomsTooltip = roomsTooltip.slice(0, -2);
        return roomsTooltip;
    }

    public deviceTypesSummaryToolTip(): string {
        let deviceTypesTooltip = 'Device types: ';

        if (this.deviceTypeSummary.length > 0) {
            this.deviceTypeSummary.forEach(item => {
                deviceTypesTooltip += `${item.key} (${item.value}), `;
            });

            // Remove the last ", " from the string
            deviceTypesTooltip = deviceTypesTooltip.slice(0, -2);
        } else {
            deviceTypesTooltip = '';
        }

        return deviceTypesTooltip;
    }

    public healthRatioBarHeight(): string {
        return '0.75rem';
    }

    ngOnDestroy(): void {
        this.dashlet.logo = null;
        this.store$.dispatch(
            Actions.UnsubscribeFromRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdTeamsRoomsSummary
            })
        );
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public tableSize(): number {
        const s = this.dashlet.getSize(); // s.id => 0 = Small, 1= Medium, 2 = Large

        switch (s.id) {
            case 0: // Small size
                return 10; // number of table recodes

            case 1: // Medium size
                return 14;

            case 2: // Large size
                return 18;

            default:
                return 13;
        }
    }
}
