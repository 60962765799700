import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

//  Get Notify
export const GetNotifyNetworkConnectivity = createAction(
  '[NetworkConnectivity] Get NetworkConnectivity',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetNotifyNetworkConnectivitySuccess = createAction(
  '[NetworkConnectivity] Get NetworkConnectivity Success',
  props<{ data: RealTimeNotify }>()
);

export const GetNotifyNetworkConnectivityFailure = createAction(
  '[NetworkConnectivity] Get NetworkConnectivity Failure',
  props<{ error: any }>()
);


// Get document
export const GetNetworkConnectivity = createAction(
  '[NetworkConnectivity] Get NetworkConnectivity',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetNetworkConnectivitySuccess = createAction(
  '[NetworkConnectivity] Get NetworkConnectivity Success',
  props<{ data: any}>()
);

export const GetNetworkConnectivityFailure = createAction(
  '[NetworkConnectivity] Get NetworkConnectivity Failure',
  props<{ error: any }>()
);

//  Get timer

export const GetNetworkConnectivityTimer = createAction(
  '[NetworkConnectivity] Get NetworkConnectivity Timer',
  props<{ equipmentId: string, command: string }>()
);

export const GetNetworkConnectivityTimerSuccess = createAction(
  '[NetworkConnectivity] Get NetworkConnectivity Timer Success',
  props<{ data: any}>()
);

export const GetNetworkConnectivityTimerFailure = createAction(
  '[NetworkConnectivity] Get NetworkConnectivity Timer Failure',
  props<{ error: any }>()
);
