import {
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingList,
    DashletSettingCheckbox,
    SelectItem
} from '@models/index';
import { AccountService, DashletSettingsService } from '@services/index';
import { Observable, forkJoin } from 'rxjs';

export class DashletSettingsFormSystemHealthCiscoPresence extends DashletSettingsForm {
    private customer!: DashletSettingDropdown;
    private location!: DashletSettingDropdown;
    private equipment!: DashletSettingDropdown;

    private isCust!: boolean;
    private customerId!: string;

    constructor(
        private readonly settingsService: DashletSettingsService,
        private readonly accountService: AccountService
    ) {
        super();
        this.init();
        this.loadCustomers();
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);
        if (this.customer.value) this.loadLocations(this.customer.value);
        if (this.location.value) this.loadequipments(this.location.value);
    }

    private init(): void {
        const equipmentGroup = new DashletSettingsGroup();
        const s: any = (equipmentGroup.settings = []);

        const customer = (this.customer = new DashletSettingDropdown());
        customer.label = 'Customer';
        customer.stateKey = 'customer';
        customer.disabled = true;
        customer.change.subscribe(() => {
            if (!this.location.value) this.clearAndDisable(this.location);
            if (!this.equipment.value) this.clearAndDisable(this.equipment);
            this.loadLocations(customer.value!);
        });
        s.push(customer);

        const location = (this.location = new DashletSettingDropdown());
        location.label = 'Location';
        location.stateKey = 'location';
        location.disabled = true;
        location.change.subscribe(() => {
            if (!this.equipment.value) this.clearAndDisable(this.equipment);
            this.loadequipments(location.value!);
        });
        s.push(location);

        const equipment = (this.equipment = new DashletSettingDropdown());
        equipment.label = 'Equipment';
        equipment.stateKey = 'equipment';
        equipment.disabled = true;
        s.push(equipment);

        // Layout section
        const layoutGroup = new DashletSettingsGroup();
        layoutGroup.title = 'Layout';
        const layoutSettings: any = (layoutGroup.settings = []);

        const showSystemChart = new DashletSettingCheckbox();
        showSystemChart.label = 'Show Occupancy Graph';
        showSystemChart.stateKey = 'showSystemChart';
        showSystemChart.disabled = false;
        layoutSettings.push(showSystemChart);

        const showFileSystem = new DashletSettingCheckbox();
        showFileSystem.label = 'Show Disk Partitions';
        showFileSystem.stateKey = 'showFileSystem';
        showFileSystem.disabled = false;
        layoutSettings.push(showFileSystem);

        const showNetworkGraph = new DashletSettingCheckbox();
        showNetworkGraph.label = 'Show Network Connectivity Graph';
        showNetworkGraph.stateKey = 'showNetworkGraph';
        showNetworkGraph.disabled = false;
        layoutSettings.push(showNetworkGraph);

        const showNetworkInterface = new DashletSettingCheckbox();
        showNetworkInterface.label = 'Show Network Interface';
        showNetworkInterface.stateKey = 'showNetworkInterface';
        showNetworkInterface.disabled = false;
        layoutSettings.push(showNetworkInterface);

        const showDatabaseSummary = new DashletSettingCheckbox();
        showDatabaseSummary.label = 'Show Database Summary';
        showDatabaseSummary.stateKey = 'showDatabaseSummary';
        showDatabaseSummary.disabled = false;
        layoutSettings.push(showDatabaseSummary);

        const group = new DashletSettingsGroup();
        group.groups = [equipmentGroup, layoutGroup];
        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);
        this.settingsService.getUserAssociatedCustomers().subscribe(
            customers => {
                this.customer.items = customers;
                this.customer.loadingComplete();
            },
            () => {
                this.customer.loadingComplete();
                this.customer.disabled = true;
                //show error notification here
            }
        );
    }

    private loadLocations(customer: string): void {
        this.enableAndLoading(this.location);
        this.settingsService.getLocations(customer).subscribe(
            locations => {
                this.location.items = locations;
                this.location.loadingComplete();
            },
            () => {
                this.location.loadingComplete();
                this.location.disabled = true;
                //show error notification here
            }
        );
    }

    private loadequipments(locationId: string): void {
        this.enableAndLoading(this.equipment);
        this.settingsService.getEquipment(locationId, 'CiscoPresence').subscribe(
            receivers => {
                this.equipment.items = receivers;
                this.equipment.loadingComplete();
            },
            () => {
                this.equipment.loadingComplete();
                this.equipment.disabled = true;
                //show error notification here
            }
        );
    }

    private clearAndDisable(widget: DashletSettingDropdown | DashletSettingList<string>) {
        widget.disabled = true;
        widget.items = [];
        if (widget instanceof DashletSettingList) widget.values = [];
        else widget.value = null;
    }

    private enableAndLoading(widget: DashletSettingDropdown | DashletSettingList<string>) {
        widget.disabled = false;
        widget.loadingBegin();
    }
}
