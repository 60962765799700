import {
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { DashletSettingsService, RealtimeGatewayService } from '@services/index';
import { DashletTrunkGroupUtilization } from '@models/index';

@Component({
  selector: 'app-dashlet-trunk-group-utilization',
  templateUrl: './dashlet-trunk-group-utilization.component.html',
  styleUrls: ['./dashlet-trunk-group-utilization.component.scss']
})
export class DashletTrunkGroupUtilizationComponent implements OnInit {

  @Input() dashlet: DashletTrunkGroupUtilization;

  smallUtilizationChartData;
  largeUtilizationChartData;
  channelsChartData;

  channels: number = 0;
  activeChannels: number = 0;

  get freeChannels(): number {
    return this.channels - this.activeChannels;
  }

  channelUtilization: number = 0;

  utilizationColour: string = 'green';

  smallUtilizationChartOptions: any = {
    scales: {
      xAxes: [{
        ticks: {
          fontSize: 10.2,
          callback: function (value, index, values) {
            return null;
          }
        }
      }],
      yAxes: [{
        ticks: {
          fontSize: 10.2,
          callback: function (value, index, values) {
            return null;
          }
        }
      }],
    },
    legend: {
      display: false
    }
  };

  largeUtilizationChartOptions: any = {
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          fontSize: 10.2,
          callback: function (value, index, values) {

            if (index % 4 !== 0)
              return null;

            const ms = Date.parse(value);
            const d = new Date(ms);
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const v = `${d.getDate()}/${monthNames[d.getMonth()]}`;
            return v;
          }
        }
      }],
      yAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          fontSize: 10.2,
          callback: function (value, index, values) {

            if (index % 2 !== 0)
              return null;

            return value;
          }
        }
      }],
    },
    legend: {
      position: "bottom"
    }
  };

  channelsChartOptions: any = {
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          fontSize: 10.2,
          callback: function (value, index, values) {

            if (index % 4 !== 0)
              return null;

            const ms = Date.parse(value);
            const d = new Date(ms);
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const v = `${d.getDate()}/${monthNames[d.getMonth()]}`;
            return v;
          }
        }
      }],
      yAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          fontSize: 10.2,
          callback: function (value, index, values) {

            if (index % 2 !== 0)
              return null;

            return value;
          }
        }
      }],
    },
    legend: {
      position: "bottom"
    }
  };

  constructor(
    private signalRService: RealtimeGatewayService,
    private dashletSettingsService: DashletSettingsService
  ) {

    this.smallUtilizationChartData = {
      labels: this.dummyUtilization.slice(-8).map(p => p.datetime),
      datasets: [
        {
          label: "Utilization",
          data: this.dummyUtilization.slice(-8).map(p => p.percentage)
        }
      ]
    };
    this.largeUtilizationChartData = {
      labels: this.dummyUtilization.map(p => p.datetime),
      datasets: [
        {
          label: "Utilization",
          data: this.dummyUtilization.map(p => p.percentage)
        }
      ]
    };
    this.channelsChartData = {
      labels: this.dummyChannels.map(p => p.datetime),
      datasets: [
        {
          label: "Active",
          lineTension: 0,
          data: this.dummyChannels.map(p => p.active)
        },
        {
          label: "Channels",
          lineTension: 0,
          steppedLine: true,
          data: this.dummyChannels.map(p => p.channels)
        },
      ]
    };
  }

  ngOnDestroy() {

    if (this.dashlet.equipmentId)
      this.signalRService.unsubscribe(this.dashlet.equipmentId, this.dashlet.commandTypeId);
  }

  ngOnInit() {

    this.dashletSettingsService.initSettings(this.dashlet.id);
    this.dashletSettingsService.observe(this.dashlet.id).subscribe(setting => {

      //unsub old
      if (this.dashlet.equipmentId)
        this.signalRService.unsubscribe(this.dashlet.equipmentId, this.dashlet.commandTypeId);

      //sub new
      this.dashlet.equipmentId = setting.value;
      if (this.dashlet.equipmentId) {
        this.signalRService.subscribe(this.dashlet.equipmentId, this.dashlet.commandTypeId);

        this.signalRService.observe(this.dashlet.equipmentId, this.dashlet.commandTypeId).subscribe(data => {
          if (data.length > 0) {
            this.channels = 0;
            for (let row of data) {

              if (row.GroupNumber == this.dashlet.trunkGroup) {
                this.channels = row.GroupSize;
                this.activeChannels = row.ActiveMembers;
                this.channelUtilization = this.channels == 0 ? 0 : parseFloat((this.activeChannels / this.channels).toFixed(2));
                if (this.channelUtilization < 0.5) {
                  this.utilizationColour = 'green';
                }
                else if (this.channelUtilization < 0.75) {
                  this.utilizationColour = 'orange';
                }
                else {
                  this.utilizationColour = 'red';
                }
              }
            }
          }
        });
      }
    });
  }

  getLayout(): number {

    const size = this.dashlet.getSize();
    for (let i = 0; i < this.dashlet.sizes.length; i++) {
      if (size.rows == this.dashlet.sizes[i].rows
        && size.cols == this.dashlet.sizes[i].cols) {
        return i;
      }
    }
    return -1;
  }

  private dummyChannels = [
    {
      active: 10,
      channels: 120,
      datetime: "2018-02-16 00:00:00.0"
    },
    {
      active: 30,
      channels: 120,
      datetime: "2018-02-16 06:00:00.0"
    },
    {
      active: 60,
      channels: 120,
      datetime: "2018-02-16 12:00:00.0"
    },
    {
      active: 50,
      channels: 120,
      datetime: "2018-02-16 18:00:00.0"
    },
    {
      active: 5,
      channels: 120,
      datetime: "2018-02-17 00:00:00.0"
    },
    {
      active: 10,
      channels: 120,
      datetime: "2018-02-17 06:00:00.0"
    },
    {
      active: 50,
      channels: 120,
      datetime: "2018-02-17 12:00:00.0"
    },
    {
      active: 30,
      channels: 160,
      datetime: "2018-02-17 18:00:00.0"
    },
    {
      active: 3,
      channels: 160,
      datetime: "2018-02-18 00:00:00.0"
    },
    {
      active: 15,
      channels: 160,
      datetime: "2018-02-18 06:00:00.0"
    },
    {
      active: 90,
      channels: 160,
      datetime: "2018-02-18 12:00:00.0"
    },
    {
      active: 75,
      channels: 160,
      datetime: "2018-02-18 18:00:00.0"
    },
    {
      active: 15,
      channels: 160,
      datetime: "2018-02-19 00:00:00.0"
    },
    {
      active: 7,
      channels: 160,
      datetime: "2018-02-19 06:00:00.0"
    },
    {
      active: 100,
      channels: 160,
      datetime: "2018-02-19 12:00:00.0"
    },
    {
      active: 110,
      channels: 160,
      datetime: "2018-02-19 18:00:00.0"
    }
  ];

  private dummyUtilization = [
    {
      percentage: 8,
      datetime: "2018-02-16 00:00:00.0"
    },
    {
      percentage: 25,
      datetime: "2018-02-16 06:00:00.0"
    },
    {
      percentage: 50,
      datetime: "2018-02-16 12:00:00.0"
    },
    {
      percentage: 45,
      datetime: "2018-02-16 18:00:00.0"
    },
    {
      percentage: 4,
      datetime: "2018-02-17 00:00:00.0"
    },
    {
      percentage: 8,
      datetime: "2018-02-17 06:00:00.0"
    },
    {
      percentage: 45,
      datetime: "2018-02-17 12:00:00.0"
    },
    {
      percentage: 18,
      datetime: "2018-02-17 18:00:00.0"
    },
    {
      percentage: 2,
      datetime: "2018-02-18 00:00:00.0"
    },
    {
      percentage: 10,
      datetime: "2018-02-18 06:00:00.0"
    },
    {
      percentage: 60,
      datetime: "2018-02-18 12:00:00.0"
    },
    {
      percentage: 45,
      datetime: "2018-02-18 18:00:00.0"
    },
    {
      percentage: 9,
      datetime: "2018-02-19 00:00:00.0"
    },
    {
      percentage: 4,
      datetime: "2018-02-19 06:00:00.0"
    },
    {
      percentage: 70,
      datetime: "2018-02-19 12:00:00.0"
    },
    {
      percentage: 75,
      datetime: "2018-02-19 18:00:00.0"
    }
  ];

}
