import {
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingList,
    DashletSettingCheckbox
} from '@models/index';
import { AccountService, DashletSettingsService } from '@services/index';

export class DashletSettingsFormSystemHealthCiscoCUBE extends DashletSettingsForm {
    private customer: DashletSettingDropdown;
    private location: DashletSettingDropdown;
    private period: DashletSettingDropdown;
    private equipment: DashletSettingDropdown;

    private isCust: boolean;
    private customerId: string;

    constructor(
        private readonly settingsService: DashletSettingsService,
        private readonly accountService: AccountService
    ) {
        super();

        this.init();
        this.loadCustomers();
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);

        if (this.customer.value) this.loadLocations(this.customer.value);
        if (this.location.value) this.loadequipments(this.location.value);
    }

    private init(): void {
        const equipmentGroup = new DashletSettingsGroup();
        const s = (equipmentGroup.settings = []);

        const user = this.accountService.getUserDetails();
        this.isCust = user.EntityGroup === 2;
        this.customerId = user.EntityId;

        const customer = (this.customer = new DashletSettingDropdown());
        customer.label = 'Customer';
        customer.stateKey = 'customer';
        customer.disabled = true;
        customer.change.subscribe(() => {
            if (!this.location.value) this.clearAndDisable(this.location);
            this.clearAndDisable(this.equipment);
            this.loadLocations(customer.value);
        });
        s.push(customer);

        const location = (this.location = new DashletSettingDropdown());
        location.label = 'Location';
        location.stateKey = 'location';
        location.disabled = true;
        location.change.subscribe(() => {
            this.clearAndDisable(this.equipment);
            this.loadequipments(location.value);
        });
        s.push(location);

        const equipment = (this.equipment = new DashletSettingDropdown());
        equipment.label = 'Source Equipment';
        equipment.stateKey = 'equipment';
        equipment.disabled = true;
        s.push(equipment);

        //layout section
        const layoutGroup = new DashletSettingsGroup();
        layoutGroup.title = 'Layout';
        const layoutSettings = (layoutGroup.settings = []);

        const showFileSystem = new DashletSettingCheckbox();
        showFileSystem.label = 'Show File System';
        showFileSystem.stateKey = 'showFileSystem';
        showFileSystem.disabled = false;
        layoutSettings.push(showFileSystem);

        const showNetworkGraph = new DashletSettingCheckbox();
        showNetworkGraph.label = 'Show Network Connectivity Graph';
        showNetworkGraph.stateKey = 'showNetworkGraph';
        showNetworkGraph.disabled = false;
        layoutSettings.push(showNetworkGraph);

        const showNetworkInterface = new DashletSettingCheckbox();
        showNetworkInterface.label = 'Show Network Interface';
        showNetworkInterface.stateKey = 'showNetworkInterface';
        showNetworkInterface.disabled = false;
        layoutSettings.push(showNetworkInterface);

        const showEnvironment = new DashletSettingCheckbox();
        showEnvironment.label = 'Show Environment';
        showEnvironment.stateKey = 'showEnvironment';
        showEnvironment.disabled = false;
        layoutSettings.push(showEnvironment);

        const showDialPeerSummary = new DashletSettingCheckbox();
        showDialPeerSummary.label = 'Show Dial Peer Summary';
        showDialPeerSummary.stateKey = 'showDialPeerSummary';
        showDialPeerSummary.disabled = false;
        layoutSettings.push(showDialPeerSummary);

        const showDSP = new DashletSettingCheckbox();
        showDSP.label = 'Show DSP';
        showDSP.stateKey = 'showDSP';
        showDSP.disabled = false;
        layoutSettings.push(showDSP);

        const showSipResponse = new DashletSettingCheckbox();
        showSipResponse.label = 'Show SIP Response Messages';
        showSipResponse.stateKey = 'showSipResponse';
        showSipResponse.disabled = false;
        layoutSettings.push(showSipResponse);

        const showCallLegsSummary = new DashletSettingCheckbox();
        showCallLegsSummary.label = 'Show Call Legs';
        showCallLegsSummary.stateKey = 'showCallLegs';
        showCallLegsSummary.disabled = false;
        layoutSettings.push(showCallLegsSummary);

        const group = new DashletSettingsGroup();
        group.groups = [equipmentGroup, layoutGroup];

        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);
        this.settingsService.getUserAssociatedCustomers().subscribe(
            customers => {
                this.customer.items = customers;
                this.customer.loadingComplete();
            },
            () => {
                this.customer.loadingComplete();
                this.customer.disabled = true;
                //show error notification here
            }
        );
    }

    private loadLocations(customer: string): void {
        this.enableAndLoading(this.location);
        this.settingsService.getLocations(customer).subscribe(
            locations => {
                this.location.items = locations;
                this.location.loadingComplete();
            },
            () => {
                this.location.loadingComplete();
                this.location.disabled = true;
                //show error notification here
            }
        );
    }

    private loadequipments(locationId: string): void {
        this.enableAndLoading(this.equipment);
        this.settingsService.getEquipment(locationId, 'CiscoCUBE').subscribe(
            receivers => {
                this.equipment.items = receivers;
                this.equipment.loadingComplete();
            },
            () => {
                this.equipment.loadingComplete();
                this.equipment.disabled = true;
                //show error notification here
            }
        );
    }

    private clearAndDisable(widget: DashletSettingDropdown) {
        widget.disabled = true;
        widget.items = [];
        widget.value = null;
    }

    private enableAndLoading(widget: DashletSettingDropdown | DashletSettingList<string>) {
        widget.disabled = false;
        widget.loadingBegin();
    }
}
