<vsm-dashlet-loading *ngIf="loading"></vsm-dashlet-loading>
<div class="justify-space-around" *ngIf="!loading">
    <div class="flex gap-m">
        <div class="flex-column equipment-detail-labels">
            <div class="mat-body-2 dashlet-link-underline" [matTooltip]="'Navigate to Genesys Cloud Conversation Data grid'" (click)="openCloudConversationGrid()">
                Conversation Records
                <mat-icon  class="medium-icon clickable">open_in_new</mat-icon>
            </div>
            <div class="mat-body-2">Average Duration</div>
        </div>
        <div class="flex-column text-no-wrap">
            <div class="mat-body-1">{{ callRecordsCount }}</div>
            <div class="mat-body-1">{{ callDurationCount }}</div>
        </div>
    </div>
    <div class="flex gap-m">
        <div class="flex-column equipment-detail-labels">
            <div class="mat-body-2">Unique Participants</div>
            <div class="mat-body-2">Total Participants</div>
        </div>
        <div class="flex-column text-no-wrap">
            <div class="mat-body-1">{{ uniqueUsersCount }}</div>
            <div class="mat-body-1">{{ participantCount }}</div>
        </div>
    </div>
</div>

<div class="flex  gap-l justify-space-between align-center" *ngIf="!loading">
    <div class="flex-column grow">
        <div class="mat-body-2 dashlet-link-underline" (click)="openMOSDistributionReport()"
        matTooltip="Open Mean Opinion Score Distribution report">Stream Quality&nbsp;<mat-icon  class="medium-icon clickable link-icon">open_in_new</mat-icon></div>
        <div class="metric align-baseline success-border justify-space-between">
            <div class="mat-body-2">Good</div>
            <div>{{ streamSummary.good }}</div>
        </div>
        <div class="metric align-baseline warning-border justify-space-between">
            <div class="mat-body-2">Satisfactory</div>
            <div>{{ streamSummary.satisfactory }}</div>
        </div>
        <div class="metric align-baseline failure-border justify-space-between">
            <div class="mat-body-2">Poor</div>
            <div>{{ streamSummary.poor }}</div>
        </div>
    </div>
    <dashlet-line-graph #networkChart style="height: 200px" [data]="streamData" [options]="networkChartOptions"
        [aspect]="3"></dashlet-line-graph>
</div>

<div class="grid grid-custom-4-columns grid-custom-3-rows" *ngIf="!loading">

    <!--Row 1-->
    <div class="mat-caption text-center" *ngFor="let heading of headings">
        <ng-container [ngSwitch]="heading">
            <ng-container *ngSwitchCase="'Agents'">
                <div class="mat-body-2 dashlet-link-underline" (click)="openLicenseUsageTrendReport()"
                matTooltip="Open License Usage Trend report">{{ heading }}&nbsp;<mat-icon  class="medium-icon clickable link-icon">open_in_new</mat-icon></div>
            </ng-container>
            <ng-container *ngSwitchDefault>
                {{ heading }}
            </ng-container>
        </ng-container>
    </div>

    <!--Row  2-->
    <p-chart #systemChart [type]="'doughnut'" [data]="mediaTypeChartData" [options]="options"></p-chart>
    <p-chart #systemChart [type]="'doughnut'" [data]="originatingDirectionChartData" [options]="options"></p-chart>
    <p-chart #systemChart [type]="'doughnut'" [data]="purposeChartData" [options]="options"></p-chart>

    <p-chart *ngIf="showAgentsChart; else missingEventBridgeMessage" #systemChart [type]="'doughnut'" [data]="agentsChartData" [options]="options"></p-chart>

    <!--Row 3-->
    <div class="doughnut-legends">
        <div *ngFor="let entry of mediaTypeChartData?.labels; let i = index" class="metric align-baseline justify-space-between" [ngStyle]="getStyleForEntry(mediaTypeChartData.datasets[0].backgroundColor[i])">
          <div class="doughnut-legend">
            <div class="legend-text mat-body-2" >{{ entry | titlecase }}</div>
            <div class="doughnut-count">{{ mediaTypeChartData?.datasets[0].data[i] }}</div>
          </div>
        </div>
    </div>

    <div class="doughnut-legends">
      <div *ngFor="let entry of originatingDirectionChartData?.labels; let i = index" class="metric align-baseline justify-space-between" [ngStyle]="getStyleForEntry(originatingDirectionChartData.datasets[0].backgroundColor[i])">
        <div class="doughnut-legend">
          <div class="legend-text mat-body-2" >{{ entry | titlecase }}</div>
          <div class="doughnut-count">{{ originatingDirectionChartData?.datasets[0].data[i] }}</div>
        </div>
      </div>
    </div>

    <div class="doughnut-legends">
      <div *ngFor="let entry of purposeChartData?.labels; let i = index" class="metric align-baseline justify-space-between" [ngStyle]="getStyleForEntry(purposeChartData.datasets[0].backgroundColor[i])">
        <div class="doughnut-legend">
          <div class="legend-text mat-body-2" >{{ entry }}</div>
          <div class="doughnut-count">{{ purposeChartData?.datasets[0].data[i] }}</div>
        </div>
      </div>
    </div>

    <div class="doughnut-legends">
      <div *ngFor="let entry of agentsChartData?.labels; let i = index" class="metric align-baseline justify-space-between" [ngStyle]="getStyleForEntry(agentsChartData.datasets[0].backgroundColor[i])">
        <div class="doughnut-legend">
            <div class="legend-text mat-body-2" >
                <ng-container [ngSwitch]="entry">
                    <ng-container *ngSwitchCase="'Configured'">
                        <div class="mat-body-2 dashlet-link-underline" (click)="openLicenseCapacityReport()"
                        matTooltip="Open License Capacity report">{{ entry| titlecase }}&nbsp;<mat-icon  class="medium-icon clickable link-icon">open_in_new</mat-icon></div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Logged In'">
                        <div class="mat-body-2 dashlet-link-underline" (click)="openVSMDevicesGrid()"
                        matTooltip="Navigate to VSM Everywhere Devices grid">{{ entry| titlecase }}&nbsp;<mat-icon  class="medium-icon clickable link-icon">open_in_new</mat-icon></div>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        {{ entry| titlecase }}
                    </ng-container>
                </ng-container>
            </div>
            <div class="doughnut-count">
                {{ agentsChartData?.datasets[0].data[i] }}
            </div>
        </div>
      </div>
    </div>
</div>

<div *ngIf="!loading">
    <div *ngIf="services.length > 0; else serviceStatusNotAvailable">
        <div class="marquee">
            <div class="marquee-group">
                <div *ngFor="let service of services">
                    <div>
                        <span>
                            {{ service.Service }}:
                        </span>
                        <span [ngStyle]="getServiceStatusColour(service.Colour)">
                            {{service.Status}}
                        </span>
                    </div>
                </div>
            </div>

            <!--
                Infinite loop happens by completely animating the above marquee group into the overflow.
                In the meantime, the duplicate marquee-group below fills up the empty space until the first
                marquee group restarts its animation.
            -->
            <div class="marquee-group">
                <div *ngFor="let service of services">
                    <div>
                        <span>
                            {{ service.Service }}:
                        </span>
                        <span [ngStyle]="getServiceStatusColour(service.Colour)">
                            {{service.Status}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #missingEventBridgeMessage>
    <div class="flex-column justify-space-evenly">
        <img src="/assets/images/attention.png" height="30%" width="30%" style="align-self: center;">
        <span class="text-center">Amazon event bridge is not configured.</span>
        <span class="text-center"><a href="https://documentation.virsae.com/display/VSMDOC/Genesys+Cloud+CX#GenesysCloudCX-AmazonEventBridgeConfiguration" target="_blank">Click here</a> to learn how to enable agent data collection.</span>
    </div>
</ng-template>

<ng-template #serviceStatusNotAvailable>
    <div class="flex-row justify-space-evenly">
        <span><img src="/assets/images/attention.png" style="height: 1em;"> The latest Genesys Cloud services health status are not available at this time.</span>
    </div>
</ng-template>

