import { createAction, props } from '@ngrx/store';

export const GetMosCapableReceivers = createAction(
    '[GetMosCapableReceivers] Get GetMosCapableReceivers',
    props<{ locationId: string }>()
);

export const GetMosCapableReceiversSuccess = createAction(
    '[GetMosCapableReceivers] Get GetMosCapableReceivers Success',
    props<{ data: any; locationId: string }>()
);

export const GetMosCapableReceiversFailure = createAction(
    '[GetMosCapableReceivers] Get GetMosCapableReceivers Failure',
    props<{ error: any }>()
);
