/* eslint-disable no-undef */
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    DashletTrunkGroupSummary,
    TrunkGroupDetail,
    CustomerLogo,
    ColumnTypes,
    TrunkUtilSummary,
    ChartCell
} from '@models/index';
import { UIChart } from 'primeng/chart';
import * as Actions from '@actions/index';
import { select, Store } from '@ngrx/store';

import { AppState, selectDataFromCommonEntity, selectEntity } from '@reducers/index';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-dashlet-trunk-group-summary',
    templateUrl: './dashlet-trunk-group-summary.component.html',
    styleUrls: ['./dashlet-trunk-group-summary.component.scss']
})
export class DashletTrunkGroupSummaryComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletTrunkGroupSummary;
    @ViewChild('trunkGroupChart') trunkGroupChart: UIChart;
    private timeoutId: ReturnType<typeof setTimeout>;
    private destroy$ = new Subject<void>();

    private trunkGroupChartOptions = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'hour'
                },
                ticks: {
                    display: false
                },
                grid: {
                    display: false
                }
            },
            y: {
                ticks: {
                    display: false
                },
                grid: {
                    drawBorder: false,
                    display: false
                }
            }
        },
        plugins: {
            legend: {
                display: false
            }
        },
        animation: {
            duration: 0
        },
        tooltips: {
            enabled: false
        },
        hover: {
            mode: null
        }
    };

    public loading: boolean;

    public columns: ColumnTypes[] = [
        {
            columnDef: 'severity',
            header: '',
            cell: (row: TrunkUtilSummary) => row.severity,
            type: 'severity'
        },
        {
            columnDef: 'expand',
            header: ''
        },
        {
            columnDef: 'occupancy',
            header: 'Maximum Trunk Group Occupancy'
        },
        {
            columnDef: 'total',
            header: 'Total',
            type: 'numeric',
            cell: (row: TrunkUtilSummary) => (row.total ? row.total : 0)
        },
        {
            columnDef: 'proportion',
            header: 'Proportion',
            type: 'numeric'
        }
    ];

    public rowExpansionColumns: ColumnTypes[] = [
        {
            columnDef: 'severity',
            header: '',
            cell: (row: TrunkGroupDetail) => row.severity,
            type: 'severity'
        },
        {
            columnDef: 'trunkName',
            header: 'Trunk Name'
        },
        {
            columnDef: 'trunkNumber',
            header: 'Number',
            type: 'numeric'
        },
        {
            columnDef: 'latestActive',
            header: 'Utilization',
            cell: (row: TrunkGroupDetail) => row.latestActive + '/' + row.latestSize
        },
        {
            columnDef: 'graphData',
            header: ``,
            cell: (row: TrunkGroupDetail): ChartCell => {
                return {
                    options: this.trunkGroupChartOptions,
                    data: row.chartData
                };
            },
            type: 'chart'
        }
    ];

    constructor(private store$: Store<AppState>) {}

    ngOnInit(): void {
        this.loading = true;

        this.dashlet.onChartDataUpdated.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.timeoutId = setTimeout(() => {
                this.updateCharts();
                this.loading = false;
            }, 1000);
        });

        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));

        this.store$
            .pipe(select(selectEntity(this.dashlet.customer.customerId)), takeUntil(this.destroy$))
            .subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            });

        if (this.dashlet.equipmentId) {
            // sub ipo trunks
            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: this.dashlet.equipmentId,
                    command: this.dashlet.commandTypeId
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: this.dashlet.equipmentId,
                    commandTypeId: this.dashlet.commandTypeId
                })
            );

            this.store$
                .pipe(
                    select(selectDataFromCommonEntity(this.dashlet.equipmentId + this.dashlet.commandTypeId)),
                    takeUntil(this.destroy$)
                )
                .subscribe(data => {
                    if (data) {
                        this.dashlet.processData(data);
                        this.dashlet.chartDataUpdated.next(null);
                        this.dashlet.lastUpdated =
                            new Date(data[0].timestamp) > this.dashlet.lastUpdated
                                ? new Date(data[0].timestamp)
                                : this.dashlet.lastUpdated;
                        this.dashlet.refreshNameTag();
                    }
                });

            this.store$.dispatch(
                Actions.GetCommonHistoric({
                    equipmentId: this.dashlet.equipmentId,
                    commandTypeId: this.dashlet.commandTypeId,
                    from: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                    to: new Date().toISOString(),
                    max: this.dashlet.summaryPeriod
                })
            );
        }
    }

    ngOnDestroy(): void {
        this.dashlet.dispose();
        this.destroy$.next();
        this.destroy$.complete();
        clearTimeout(this.timeoutId);
    }

    updateCharts() {
        if (this.trunkGroupChart) {
            if (this.trunkGroupChart.chart) {
                this.trunkGroupChart.chart.update();
            }
        }
    }

    getServerityToolTip() {
        return 'Current Trunk Status - last hour';
    }
}
