import { Action, createReducer, on } from '@ngrx/store';
import * as EntityLogoActions from '@actions/entity-logo/entity-logo.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { LogoRespObj } from '@models/index';

export const entityLogoFeatureKey = 'entityLogo';

export interface State extends EntityState<LogoRespObj> {
    logoData: LogoRespObj[] | [];
    ids: any[];
    entities: any;
}

export function selectEntityId(a: LogoRespObj): string {
    //In this case this would be optional since primary key is id
    return a.entityId;
}

export const adapter: EntityAdapter<LogoRespObj> = createEntityAdapter<LogoRespObj>({
    selectId: selectEntityId
});

export const initialState: State = adapter.getInitialState({
    // additional entity state properties
    logoData: [],
    ids: [],
    entities: {}
});

export const reducer = createReducer(
    initialState,
    on(EntityLogoActions.GetEntityLogoSuccess, (state, { data }) => {
        return adapter.addOne(data, state);
    })
);

const { selectEntities } = adapter.getSelectors();

// select the dictionary of Logo entities
export const selectLogoEntities = selectEntities;
