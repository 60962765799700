<div class="flex-column gap-l">
    <button class="text-left settings-button" mat-raised-button [ngClass]="{'selected': !activeDashlet}" (click)="selectDashboard()">
        <p class="mat-body-2">Dashboard</p>
    </button>

    <ng-container *ngIf="(dashlets | async) as dashlets; else noneInfo">
        <button
        color="accent"
            mat-raised-button
            *ngFor="let item of dashlets  | dashletsSort"
            class="text-left settings-button"
            [ngClass]="{'selected': item.id === activeDashlet?.id}"
            (click)="selectDashlet(item)"
        >
            <p class="mat-body-2 dashlet-name">{{ item.title }}</p>
            <p class="mat-caption dashlet-configuration-text">{{ item.customNameTag || item.generatedNameTag }}</p>
        </button>
    </ng-container>
    <ng-template #noneInfo>
        <p><em>None</em></p>
    </ng-template>
</div>
