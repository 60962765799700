import { BehaviorSubject, Observable, of, map } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InviteDetails, SelectItem, UserDetails } from '@models/index';
import { Router } from '@angular/router';
import { AppState } from '@reducers/index';
import { CustomerModel } from '@models/index';
import * as CustomersActions from '@actions/customers/customers.actions';

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    private readonly VO_ENTITY_ID = '815d9248-a488-4f06-be81-4a12cdaf5fac';
    router!: Router;

    private currentUserDetails!: UserDetails | any;
    private authToken: any;

    constructor(private http: HttpClient, private store$: Store<AppState>) {
        this.extractUserDetails();
    }

    public userDetailsSubject = new BehaviorSubject<any>(null);

    get userDetails(): Observable<UserDetails> {
        return this.userDetailsSubject;
    }

    public hasRole(roleName: string): boolean {
        let hasAccess = false;
        this.userDetailsSubject.subscribe(res => {
            for (const role of res.AccessRoles) {
                if (role === roleName) {
                    hasAccess = true;
                }
            }
        });
        return hasAccess;
    }

    public hasServiceFeature(customerId: string): Observable<boolean> {
        if (this.isVirsaeOwner()) {
            return of(true);
        }
        return this.hasFeatureEnabled(customerId, 'ftService');
    }

    public hasAvailabilityFeature(customerId: string): Observable<boolean> {
        if (this.isVirsaeOwner()) {
            return of(true);
        }
        return this.hasFeatureEnabled(customerId, 'ftAvailability');
    }

    public hasCapacityFeature(customerId: string): Observable<boolean> {
        if (this.isVirsaeOwner()) {
            return of(true);
        }
        return this.hasFeatureEnabled(customerId, 'ftCapacity');
    }

    public hasConfigurationFeature(customerId: string): Observable<boolean> {
        if (this.isVirsaeOwner()) {
            return of(true);
        }
        return this.hasFeatureEnabled(customerId, 'ftConfiguration');
    }

    public hasContinuityFeature(customerId: string): Observable<boolean> {
        if (this.isVirsaeOwner()) {
            return of(true);
        }
        return this.hasFeatureEnabled(customerId, 'ftContinuity');
    }

    public hasReleaseFeature(customerId: string): Observable<boolean> {
        if (this.isVirsaeOwner()) {
            return of(true);
        }
        return this.hasFeatureEnabled(customerId, 'ftRelease');
    }

    public hasChangeFeature(customerId: string): Observable<boolean> {
        if (this.isVirsaeOwner()) {
            return of(true);
        }
        return this.hasFeatureEnabled(customerId, 'ftChange');
    }

    public hasSecurityFeature(customerId: string): Observable<boolean> {
        if (this.isVirsaeOwner()) {
            return of(true);
        }
        return this.hasFeatureEnabled(customerId, 'ftSecurity');
    }

    public isAdmin(): boolean {
        const userDetail = this.getUserDetails();
        if (userDetail!.RoleDescription === 'Administrator') {
            return true;
        }
        return false;
    }

    public isVirsaeOwner(): boolean {
        if (this.currentUserDetails && this.currentUserDetails!.EntityId === this.VO_ENTITY_ID) {
            return true;
        }
        return false;
    }

    public getInviteTokenDetails(token: string) {
        return this.http.get<InviteDetails>('api/registerng/invitedetails', {
            params: new HttpParams().append('token', token)
        });
    }

    public getSecurityQuestions() {
        return this.http.get<any[]>('api/formng/securityquestions').subscribe(zones =>
            zones.map(tz => {
                const i = new SelectItem();
                i.label = tz.question;
                i.value = tz.id;
                return i;
            })
        );
    }

    public getUserDetails() {
        this.extractUserDetails();
        return this.currentUserDetails;
    }

    private extractUserDetails(): void {
        this.authToken = localStorage.getItem('id_token');
        this.userDetailsSubject.next(this.parseJwt(this.authToken));
        this.currentUserDetails = this.parseJwt(this.authToken);
    }

    private parseJwt(token: string) {
        if (token) {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(
                window
                    .atob(base64)
                    .split('')
                    .map(c => {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join('')
            );

            return JSON.parse(jsonPayload);
        }
        return;
    }

    private hasFeatureEnabled(customerId: string, feature: string): Observable<boolean> {
        return this.store$
            .select(state => state.customers.userAssociatedCustomers)
            .pipe(
                filter((customers: CustomerModel[]) => {
                    if (customers && customers.length === 0) {
                        this.store$.dispatch(CustomersActions.GetUserAssociatedCustomers());
                    }
                    return customers && customers.length > 0;
                }),
                map((customers: CustomerModel[]) => customers.find(customer => customer.customerId === customerId)),
                filter((customer: CustomerModel | undefined) => !!customer),
                map((customer: CustomerModel) => customer[feature]),
                take(1) // Unsubscribe after the first emission
            );
    }
}
