export class SelectItem {
    label!: string;
    value!: string;
    styleClass?: string;
}

export class SelectItemGeneric<T> {
    label!: string;
    value!: T;
}

export class SelectItemGroup {
    label!: string;
    items!: SelectItem[];
}
