import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MessageType } from '@services/notification/notification.service';

@Component({
    selector: 'vsm-snack-bar',
    template: ` <div class="flex-column gap-m">
        <div class="gap-s justify-space-between align-center">
            <h2 [ngClass]="data.messageType">{{ data?.title }}</h2>
            <mat-icon class="snackbar-icon" *ngIf="icon" [ngClass]="data.messageType">{{ icon }}</mat-icon>
        </div>
        <span class="message" *ngIf="data?.message">{{ data?.message }}</span>
    </div>`,
    styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent {
    public icon: string;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: { message?: string; title: string; messageType: MessageType }
    ) {
        if (data.messageType === 'success') {
            this.icon = 'thumb_up';
        } else if (data.messageType === 'info') {
            this.icon = 'notifications';
        } else {
            this.icon = data.messageType;
        }
    }
}
