import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DashletMeta } from '@models/index';
@Component({
    selector: 'app-dashlet-tile',
    templateUrl: './dashlet-tile.component.html',
    styleUrls: ['./dashlet-tile.component.scss']
})
export class DashletTileComponent implements OnInit {
    @Output() addDashlet = new EventEmitter();
    @Input() dashlet: DashletMeta;
    public backGroundImage: string = '';

    constructor() {}

    ngOnInit(): void {
        this.backGroundImage = 'url("/assets/images/' + this.dashlet.type.replace(new RegExp(' ', 'g'), '_') + '.png")';
    }

    createDashlet() {
        this.addDashlet.emit(this.dashlet);
    }
}
