import * as ProcessWorkflowDataActions from '@actions/process-workflow-data/process-workflow-data.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService } from '@services/index';
import { RealTimeNotify } from '@models/index';



@Injectable()
export class ProcessWorkflowDataEffects {

  constructor(private actions$: Actions, private realtimeGatewayService: RealtimeGatewayService) { }

  getNotifyProcessWorkflowData$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessWorkflowDataActions.GetNotifyProcessWorkflowData),
    switchMap((action) => this.realtimeGatewayService.observe(action.equipmentId, action.commandTypeId).pipe(
      catchError((err) => of(
        ProcessWorkflowDataActions.GetNotifyProcessWorkflowDataFailure({ error: err })
        )
      ),
      mergeMap((data: RealTimeNotify) => [
        ProcessWorkflowDataActions.GetNotifyProcessWorkflowDataSuccess({ data }),
        ProcessWorkflowDataActions.GetProcessWorkflowData({ ...data })
      ],
      ),
    ),
    )
  ));

  getProcessWorkflowData$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessWorkflowDataActions.GetProcessWorkflowData),
    mergeMap((action) => this.realtimeGatewayService.getDocument(action.equipmentId, action.commandTypeId).pipe(
      map((data) => ProcessWorkflowDataActions.GetProcessWorkflowDataSuccess({ data:{ data, equipmentId: action.equipmentId} }),
      ),
      catchError((err) => of(ProcessWorkflowDataActions.GetProcessWorkflowDataFailure({ error: err })))
    ))
  ));

}
