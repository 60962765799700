/* eslint-disable no-undef */
/* eslint-disable @angular-eslint/component-selector */
import * as Actions from '@actions/index';
import {
    AccountService,
    DashboardService,
    DashletService,
    ThresholdService,
    TileGridService,
    TimeoutService
} from '@services/index';
import { AppState, selectDataFromCommonEntity, selectEntity, selectEntityTimer } from '@reducers/index';
import { Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {
    ChartCell,
    ColumnTypes,
    CustomerLogo,
    DashletSystemHealthWindowsServer,
    Service,
    SQLData,
    Threshold
} from '@models/index';
import { MenuItem } from 'primeng/api';
import { select, Store } from '@ngrx/store';
import { ThresholdItemComponent } from '../threshold-item/threshold-item.component';
import { DashletSectionServicesComponent } from '../dashlet-section-services/dashlet-section-services.component';
import { DashletSectionProcessorMemoryComponent } from '../dashlet-section-processor-memory/dashlet-section-processor-memory.component';

@Component({
    selector: 'dashlet-system-health-windows-server',
    templateUrl: 'dashlet-system-health-windows-server.component.html',
    styleUrls: ['./dashlet-system-health-windows-server.component.scss']
})
export class DashletSystemHealthWindowsServerComponent implements OnInit, OnDestroy {
    @Input() dashlet!: DashletSystemHealthWindowsServer;
    @ViewChild('thresholdItem') thresholdItem!: ThresholdItemComponent;
    @ViewChildren('sqlChart') sqlChart!: QueryList<any>;
    @ViewChild('watchedServices') watchedServices: DashletSectionServicesComponent;

    @ViewChild('processorMemory') processorMemory: DashletSectionProcessorMemoryComponent;
    openSubcontainer: string | null = '';
    openSubcontainerSeverity = 0;
    occupancyContextMenuItems!: MenuItem[];

    selectedField!: string;
    selectedThreshold!: Threshold;
    public windowsServices: { [severity: number]: Service[] } = {};
    public iisTimedOut: boolean = false;

    titleMsg = '';

    public iisColumns: ColumnTypes[] = [
        {
            columnDef: 'name',
            header: 'Host'
        },
        {
            columnDef: 'connections',
            header: 'Connections',
            type: 'numeric'
        },
        {
            columnDef: 'getPerSec',
            header: 'GET/sec',
            type: 'numeric'
        },
        {
            columnDef: 'postPerSec',
            header: 'POST/sec',
            type: 'numeric'
        }
    ];
    public sqlColumns: ColumnTypes[] = [
        {
            columnDef: 'expand',
            header: '',
            width: '5%'
        },
        {
            columnDef: 'label',
            header: 'Performance Counter',
            dataTooltip: (row: SQLData) => this.getSQLTooltip(row.expansion[0].label),
            width: '40%'
        },
        {
            columnDef: 'currentString',
            header: 'Current',
            width: '25%'
        },
        {
            columnDef: 'peakString',
            header: 'Peak Last 30 Mins',
            width: '30%'
        }
    ];

    public sqlExpansionColumns: ColumnTypes[] = [
        {
            columnDef: 'expansion',
            header: ``,
            cell: (element: any): ChartCell => {
                return {
                    options: this.sqlDataLineOptions,
                    data: element,
                    chartHeight: '50'
                };
            },
            type: 'chart'
        }
    ];

    sqlDataLineOptions: any = {
        scales: {
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: false
                },
                beginAtZero: true,
                type: 'linear',
                ticks: {
                    fontSize: 10.2,
                    beginAtZero: true
                },
                scaleLabel: {
                    display: false
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'left',
                labels: {
                    boxWidth: 15
                }
            },
            tooltipCaretSize: 0,
            tooltip: {
                mode: 'index'
            }
        },

        maintainAspectRatio: false,

        layout: {
            padding: {
                bottom: 0,
                top: 5,
                left: 5,
                right: 10
            }
        }
    };

    get smallLayout() {
        return this.dashlet.getSize().id === 0;
    }

    constructor(
        private dashletService: DashletService,
        private dashboardService: DashboardService,
        private accountService: AccountService,
        private thresholdService: ThresholdService,
        private tileGridService: TileGridService,
        public timeoutService: TimeoutService,
        private store$: Store<AppState>
    ) {}

    ngOnInit() {
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));

        this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
            if (logo) {
                this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
            }
        });

        // Process Watched Service Data

        this.dashlet.sqlDataUpdated.subscribe(() => {
            if (this.sqlChart) {
                this.sqlChart.toArray().forEach(s => {
                    s.refresh();
                    s.reinit();
                });
            }
        });

        this.titleMsg = this.timeoutService.getSystemDataExpiredmessage(
            this.timeoutService.formatSystemTimeoutString(this.dashlet?.diskDataLost),
            [this.dashlet?.commandTypeIdCpuMem, this.dashlet?.commandTypeIdDisk],
            this.dashlet?.serverEquipment.equipmentId
        );
        this.sub();
        this.dashlet.unSub.subscribe(() => {
            this.unsubData();
        });
        this.dashlet.settingsUpdated.subscribe(() => {
            this.dashlet.resetData();
            this.sub();
        });

        // Get the commands ran on the equipment. lorem
    }

    private sub(): void {
        // NicName
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.serverEquipment.equipmentId,
                command: this.dashlet.commandTypeIdNicName
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.serverEquipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdNicName
            })
        );

        this.store$
            .pipe(
                select(
                    selectDataFromCommonEntity(
                        this.dashlet.serverEquipment.equipmentId + this.dashlet.commandTypeIdNicName
                    )
                )
            )
            .subscribe(data => {
                if (data) {
                    this.dashlet.processNicNames(data);
                    this.dashlet.produceNetworkInterfaceChartData(new Date(data[0].timestamp));
                    this.dashlet.sortNICSByDate();
                }
            });

        // Sql Monitoring

        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.serverEquipment.equipmentId,
                command: this.dashlet.commandTypeIdSqlMonitoring
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.serverEquipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdSqlMonitoring
            })
        );
        this.store$.dispatch(
            Actions.GetEntityTimer({
                equipmentId: this.dashlet.serverEquipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdSqlMonitoring,
                uniqueId: this.dashlet.serverEquipment.equipmentId + this.dashlet.commandTypeIdSqlMonitoring
            })
        );
        this.store$.dispatch(
            Actions.GetCommonHistoric({
                equipmentId: this.dashlet.serverEquipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdSqlMonitoring,
                from: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                to: new Date().toISOString(),
                max: 60
            })
        );

        this.store$
            .pipe(
                select(
                    selectDataFromCommonEntity(
                        this.dashlet.serverEquipment.equipmentId + this.dashlet.commandTypeIdSqlMonitoring
                    )
                )
            )
            .subscribe(data => {
                if (data) {
                    this.dashlet.processSqlData(data);
                }
            });

        this.store$
            .pipe(
                select(
                    selectEntityTimer(
                        this.dashlet.serverEquipment.equipmentId + this.dashlet.commandTypeIdSqlMonitoring
                    )
                )
            )
            .subscribe(dataTimeoutExpired => {
                this.dashlet.sqlData = dataTimeoutExpired ? null : this.dashlet.sqlData;
            });

        // Process IIS Data

        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.serverEquipment.equipmentId,
                command: this.dashlet.commandTypeIdIIS
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.serverEquipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdIIS
            })
        );

        this.store$.dispatch(
            Actions.GetEntityTimer({
                equipmentId: this.dashlet.serverEquipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdIIS,
                uniqueId: this.dashlet.serverEquipment.equipmentId + this.dashlet.commandTypeIdIIS
            })
        );

        this.store$
            .pipe(
                select(
                    selectDataFromCommonEntity(this.dashlet.serverEquipment.equipmentId + this.dashlet.commandTypeIdIIS)
                )
            )
            .subscribe(data => {
                if (data) {
                    this.dashlet.processIISData(data);
                }
            });

        this.store$
            .pipe(select(selectEntityTimer(this.dashlet.serverEquipment.equipmentId + this.dashlet.commandTypeIdIIS)))
            .subscribe(dataTimeoutExpired => {
                if (dataTimeoutExpired !== undefined) this.iisTimedOut = dataTimeoutExpired;
            });

        // Process HyperV Data

        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.serverEquipment.equipmentId,
                command: this.dashlet.commandTypeIdHyperV
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.serverEquipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdHyperV
            })
        );

        this.store$
            .pipe(
                select(
                    selectDataFromCommonEntity(
                        this.dashlet.serverEquipment.equipmentId + this.dashlet.commandTypeIdHyperV
                    )
                )
            )
            .subscribe(data => {
                if (data) {
                    this.dashlet.processHyperVData(data);
                }
            });

        // Process LyncMed ServerData

        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.serverEquipment.equipmentId,
                command: this.dashlet.commandTypeIdLyncMedServer
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.serverEquipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdLyncMedServer
            })
        );

        this.store$
            .pipe(
                select(
                    selectDataFromCommonEntity(
                        this.dashlet.serverEquipment.equipmentId + this.dashlet.commandTypeIdLyncMedServer
                    )
                )
            )
            .subscribe(data => {
                if (data) {
                    this.dashlet.processLyncMedServerData(data);
                }
            });

        // Process LyncMed FrontEnd ServerData

        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.serverEquipment.equipmentId,
                command: this.dashlet.commandTypeIdLyncFrontEndServer
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.serverEquipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdLyncFrontEndServer
            })
        );

        this.store$
            .pipe(
                select(
                    selectDataFromCommonEntity(
                        this.dashlet.serverEquipment.equipmentId + this.dashlet.commandTypeIdLyncFrontEndServer
                    )
                )
            )
            .subscribe(data => {
                if (data) {
                    this.dashlet.processLyncFrontEndServerData(data);
                }
            });

        // Process Flow Controller Data

        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.serverEquipment.equipmentId,
                command: this.dashlet.commandTypeIdFlowController
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.serverEquipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdFlowController
            })
        );

        this.store$
            .pipe(
                select(
                    selectDataFromCommonEntity(
                        this.dashlet.serverEquipment.equipmentId + this.dashlet.commandTypeIdFlowController
                    )
                )
            )
            .subscribe(data => {
                if (data) {
                    this.dashlet.processFlowControllerData(data);
                }
            });

        // Process Work flow Data

        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.serverEquipment.equipmentId,
                command: this.dashlet.commandTypeIdWorkflow
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.serverEquipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdWorkflow
            })
        );

        this.store$
            .pipe(
                select(
                    selectDataFromCommonEntity(
                        this.dashlet.serverEquipment.equipmentId + this.dashlet.commandTypeIdWorkflow
                    )
                )
            )
            .subscribe(data => {
                if (data) {
                    this.dashlet.processWorkflowData(data);
                }
            });
    }

    private unsubData(): void {
        if (this.dashlet.serverEquipment) {
            this.timeoutService.deleteTimer(this.dashlet.serverEquipment.equipmentId, this.dashlet.commandTypeIdUpTime);
            this.timeoutService.deleteTimer(this.dashlet.serverEquipment.equipmentId, this.dashlet.commandTypeIdCpuMem);
            this.timeoutService.deleteTimer(this.dashlet.serverEquipment.equipmentId, this.dashlet.commandTypeIdDisk);
            this.timeoutService.deleteTimer(
                this.dashlet.serverEquipment.equipmentId,
                this.dashlet.commandTypeIdNetwork
            );
            //unsub data
            this.store$.dispatch(
                Actions.UnsubscribeFromRealTimeService({
                    equipmentId: this.dashlet.serverEquipment.equipmentId,
                    command: this.dashlet.commandTypeIdUpTime
                })
            );

            this.store$.dispatch(
                Actions.UnsubscribeFromRealTimeService({
                    equipmentId: this.dashlet.serverEquipment.equipmentId,
                    command: this.dashlet.commandTypeIdCpuMem
                })
            );

            this.store$.dispatch(
                Actions.UnsubscribeFromRealTimeService({
                    equipmentId: this.dashlet.serverEquipment.equipmentId,
                    command: this.dashlet.commandTypeIdService
                })
            );

            this.store$.dispatch(
                Actions.UnsubscribeFromRealTimeService({
                    equipmentId: this.dashlet.serverEquipment.equipmentId,
                    command: this.dashlet.commandTypeIdDisk
                })
            );

            this.store$.dispatch(
                Actions.UnsubscribeFromRealTimeService({
                    equipmentId: this.dashlet.serverEquipment.equipmentId,
                    command: this.dashlet.commandTypeIdIIS
                })
            );

            this.store$.dispatch(
                Actions.UnsubscribeFromRealTimeService({
                    equipmentId: this.dashlet.serverEquipment.equipmentId,
                    command: this.dashlet.commandTypeIdHyperV
                })
            );

            this.store$.dispatch(
                Actions.UnsubscribeFromRealTimeService({
                    equipmentId: this.dashlet.serverEquipment.equipmentId,
                    command: this.dashlet.commandTypeIdLyncMedServer
                })
            );

            this.store$.dispatch(
                Actions.UnsubscribeFromRealTimeService({
                    equipmentId: this.dashlet.serverEquipment.equipmentId,
                    command: this.dashlet.commandTypeIdLyncFrontEndServer
                })
            );

            this.store$.dispatch(
                Actions.UnsubscribeFromRealTimeService({
                    equipmentId: this.dashlet.serverEquipment.equipmentId,
                    command: this.dashlet.commandTypeIdFlowController
                })
            );

            this.store$.dispatch(
                Actions.UnsubscribeFromRealTimeService({
                    equipmentId: this.dashlet.serverEquipment.equipmentId,
                    command: this.dashlet.commandTypeIdWorkflow
                })
            );

            this.store$.dispatch(
                Actions.UnsubscribeFromRealTimeService({
                    equipmentId: this.dashlet.serverEquipment.equipmentId,
                    command: this.dashlet.commandTypeIdWatchedService
                })
            );

            this.store$.dispatch(
                Actions.UnsubscribeFromRealTimeService({
                    equipmentId: this.dashlet.serverEquipment.equipmentId,
                    command: this.dashlet.commandTypeIdNicName
                })
            );

            this.store$.dispatch(
                Actions.UnsubscribeFromRealTimeService({
                    equipmentId: this.dashlet.serverEquipment.equipmentId,
                    command: this.dashlet.commandTypeIdHostName
                })
            );

            this.store$.dispatch(
                Actions.UnsubscribeFromRealTimeService({
                    equipmentId: this.dashlet.serverEquipment.equipmentId,
                    command: this.dashlet.commandTypeIdTotalMem
                })
            );

            this.store$.dispatch(
                Actions.UnsubscribeFromRealTimeService({
                    equipmentId: this.dashlet.serverEquipment.equipmentId,
                    command: this.dashlet.commandTypeIdNetwork
                })
            );

            this.store$.dispatch(
                Actions.UnsubscribeFromRealTimeService({
                    equipmentId: this.dashlet.serverEquipment.equipmentId,
                    command: this.dashlet.commandTypeIdSqlMonitoring
                })
            );
        }
    }

    ngOnDestroy() {
        if (this.dashlet) {
            this.dashlet.dispose();
        }
        this.unsubData();
    }

    getSeverityColor(severity: number): string {
        return this.dashletService.getSeverityColor(severity);
    }

    openAccessConcentrator() {
        const userDetail = this.accountService.getUserDetails();
        this.dashboardService.openAccessConcentrator(
            this.dashlet.customer.customerId,
            this.dashlet.serverEquipment.equipmentId,
            userDetail
        );
    }

    openDetails(container: string, severity: number) {
        this.openSubcontainer = container;
        this.dashlet.displayHeader = false;
        this.openSubcontainerSeverity = severity;
    }

    closeDetails() {
        this.openSubcontainer = null;
        this.dashlet.displayHeader = true;
    }

    public getTableHeight(): number {
        let height = 9;

        // if (this.dashlet.showNetworkConnectivitySection()){
        //     height += 100;
        // }
        if (this.dashlet.showModuleNetwork) {
            height += 3;
        }
        if (this.dashlet.showModuleServices) {
            height += 3;
        }
        if (this.dashlet.showSystemChart) {
            height += 3;
        }
        return height;
    }

    openThresholdAlerts(elementId: string, commandTypeId: string, elementInstance?: string) {
        this.openSubcontainer = 'createThreshold';
        this.dashlet.displayHeader = false;
        this.selectedThreshold = new Threshold();
        this.selectedThreshold.commandTypeId = commandTypeId;
        this.selectedThreshold.field = elementId;
        this.selectedThreshold.fieldInstance = elementInstance!;
        this.selectedThreshold.whereCreated = 'Dashboard';
    }

    openAlarmIconThreshold() {
        this.thresholdService.openThresholdManagement(this.accountService.getUserDetails().EntityId);
    }

    getInstance(jSONRules: string): string {
        const jsonObj = JSON.parse(jSONRules.trim());
        let returnStr = '';
        jsonObj.forEach((element: any) => {
            if (element['field'] === 'InstanceName') {
                returnStr = element['value'];
            }
        });
        return returnStr;
    }

    getField(jSONRules: string): string {
        const jsonObj = JSON.parse(jSONRules.trim());
        let returnStr = '';
        jsonObj.forEach((element: any) => {
            if (element['field'] === 'CategoryName') {
                returnStr = element['value'].toLowerCase();
            }
        });
        return returnStr;
    }

    onSubmit() {
        this.thresholdItem.onSubmit().subscribe((result: any) => {
            if (result) {
                this.selectedThreshold.dapThresholdId = result['DapThresholdId'];
                this.dashlet.thresholds.push(this.selectedThreshold);
                switch (this.selectedThreshold.field) {
                    case 'processor':
                        ++this.processorMemory.cpuUsageData.thresholdsNumber;
                        break;
                    case 'memory':
                        ++this.processorMemory.memoryUsageData.thresholdsNumber;
                        break;
                    case 'disk':
                        ++this.processorMemory.diskUsageData.find(
                            x => x.diskName === this.getInstance(result['JSONRules'])
                        )!.thresholdsNumber;
                        break;
                    default:
                        break;
                }
                this.tileGridService.thresholdCreated(
                    this.selectedThreshold.name + ' threshold added via ' + this.dashlet.title
                );
            } else {
                this.tileGridService.thresholdCreated('Threshold add failed');
            }
        });
        this.closeDetails();
    }

    saveEdit() {
        this.thresholdItem.saveEdit().subscribe(result => {
            if (result) {
                this.tileGridService.thresholdCreated(
                    this.selectedThreshold.name + ' threshold edited via ' + this.dashlet.title
                );
            } else {
                this.tileGridService.thresholdCreated('Threshold edit failed');
            }
        });
        this.closeDetails();
    }

    public getSQLTooltip(counter: string) {
        switch (counter) {
            case 'Processes blocked':
                return 'The processes blocked counter identifies the number of blocked processes. When one process is blocking another process, the blocked process cannot move forward with its execution plan until the resource that is causing it to wait is freed up.  Ideally there should not be any blocked processes, and any blocked processes should be investigated.';

            case 'Buffer cache hit ratio':
                return 'The buffer cache hit ratio counter shows the ratio of data pages found and read from the SQL Server cache. If the page doesn’t exist in the buffer cache, it has to be read from disk which downgrades performance.  It is recommended the Buffer cache hit ratio is 90% or higher. A low buffer cache hit ratio could indicate a memory problem.';

            case 'Page life expectancy':
                return 'The page life expectancy counter measures how long pages stay in the buffer cache in seconds. The longer a page stays in memory, the more likely SQL Server will not need to read from disk to resolve a query. Generally, a page life expectancy below 5 minutes (300 s), can be indicative of memory problems.';

            case 'Forwarded Records/sec':
                return 'The number of rows per second fetched from a heap through forwarded record pointers. When this number is high, it means there were many updates to rows that no longer fit on the original page, and requires rebuilding the heap, or adding a clustered index to correct.   Ideally this number should be very close to 0.';

            case 'Batch Requests/sec':
                return 'Batch Requests/Sec measures the number of batches SQL Server is receiving per second and is an indicator of the amount of activity on the server. Sudden changes can reveal availability issues or unexpected changes in demand.';

            case 'SQL Compilations/sec':
                return 'The SQL Compilations/Sec measures the number of times SQL Server compiles an execution plan per second. Compiling an execution plan is a resource-intensive operation. Compilations/Sec should be compared with the number of Batch Requests/Sec to get an indication of whether or not complications might be hurting your performance. Ideally 90% of queries should be reused, so compilations/sec should be <10% of the batch requests/sec.';
        }
        return '';
    }
}
