import { Customer, Dashlet } from '@models/index';
import { Subject } from 'rxjs';
export interface RibbonSystemHealthServer {
    severity: number;
    name: string;
    equipmentId: string;
    type: string;
    location: string;
    cpuPercentage: number | null;
    memoryPercentage: number | null;
    averagePing: any;
    maxPing: any;
    sessionsCurrentlyUp: number;
    signalGroupsGreen: number;
    signalGroupsRed: number;
    dspStatus: any[];
    lastUpdateTimes: any;
    availability: any;
    longestOutageSeconds: number;
}

export interface Availability {
    totalServers: number;
    totalAvgAvailability: number;
    longestOutageText: string;
    averageResponse: number;
}

export interface AvailabilityData {
    EquipmentId: string;
    EquipmentName: string;
    AveragePacketLossPercentage: number;
    AverageResponseTime: number;
    LongestOutageSeconds: number;
    MaxLongestOutageSeconds: number;
}

export class DashletRibbonSystemHealthSummary extends Dashlet {
    public readonly commandTypeIdSystemSummaryData = '08252A85-953D-402C-A9FF-CC87B264F76D';
    public readonly commandTypeIdAvailabilty = 'AA6DB57A-04A4-4952-97B1-FB27B5B9BABE';
    public settingsChanged: Subject<boolean> = new Subject<boolean>();

    public customer!: Customer;

    private location!: string;
    public locationId!: string;

    constructor() {
        super();

        this.sizes = [
            {
                id: 0,
                label: 'Small',
                cols: 15,
                rows: 5
            },
            {
                id: 1,
                label: 'Medium',
                cols: 15,
                rows: 7
            },
            {
                id: 2,
                label: 'Large',
                cols: 15,
                rows: 10
            }
        ];
        this.applySize(0);
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        this.configured = v.customer && v.location;

        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }

        this.location = v.location && v.location.value ? v.location.label : null;
        this.locationId = v.location && v.location.value ? v.location.value : null;

        this.generatedNameTag = this.configured ? `${v.location.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;
        this.updateSize();
        if (this.configured) {
            this.settingsChanged.next(true);
        }
    }

    applySize(id: number) {
        super.applySize(id);
        this.updateSize();
    }

    private updateSize() {
        let h = 0;
        let w = 0;
        this.applySizeExpansion(w, h);
    }

    public resetData(): void {}

    public dispose(): void {
        this.settingsChanged.complete();
        this.settingsChanged.unsubscribe();
    }
}
