import { AuthConfig, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { environment } from '@environments/environment';
import { AuthenticationService } from '@services/index';

export const authConfig: AuthConfig = {
    issuer: environment.issuer,
    clientId: 'dashboard',
    responseType: 'code',
    redirectUri: environment.redirectUrl,
    logoutUrl: environment.redirectUrl,
    silentRefreshRedirectUri: environment.redirectUrl + 'silent-refresh.html',
    scope: 'openid profile customvud DashboardsApi CentralApi RtgApi',
    useSilentRefresh: true,
    sessionChecksEnabled: true,
    showDebugInformation: false,
    clearHashAfterLogin: true,
    timeoutFactor: 0.33,
    nonceStateSeparator: 'semicolon'
};
export const authModuleConfig: OAuthModuleConfig = {
    // Inject "Authorization: Bearer ..." header for these APIs:
    resourceServer: {
        allowedUrls: [environment.dashboardApi, environment.centralApi, environment.realtimeApi],
        sendAccessToken: true
    }
};
export function storageFactory(): OAuthStorage {
    // eslint-disable-next-line no-undef
    return localStorage;
}

export function authAppInitializerFactory(authService: AuthenticationService): () => Promise<void> {
    return () => authService.runInitialLoginSequence();
}
