<div class="flex-column gap-l">
    <!-- system -->
    <dashlet-section-processor-memory
        *ngIf="!openSubcontainer"
        #processorMemory
        [showSystemChart]="dashlet.showSystemChart"
        [equipment]="dashlet.equipment"
        [customerId]="dashlet.customer.customerId"
        [locationId]="dashlet.location.locationId"
    >
    </dashlet-section-processor-memory>
    <!-- network connectivity -->
    <dashlet-section-network-connectivity
        *ngIf="!openSubcontainer"
        [showNetworkGraph]="dashlet.showNetworkGraph"
        [equipment]="dashlet.equipment"
        [customerId]="dashlet.customer.customerId"
    >
    </dashlet-section-network-connectivity>

    <dashlet-section-services
        *ngIf="(dashlet.showServiceStatus && !openSubcontainer) || openSubcontainer === 'service'"
        #services
        [equipment]="dashlet.equipment"
        [location]="dashlet.location"
        [customer]="dashlet.customer"
        [commandTypeIdServices]="[dashlet.commandTypeIdServiceStatus]"
        (openDetails)="$event === null ? closeDetails() : openDetails('service', $event)"
    >
    </dashlet-section-services>

    <div *ngIf="dashlet.showSIPEntityStatus && !openSubcontainer" class="section section-services">
        <dashlet-section-header>SIP Entities</dashlet-section-header>

        <div class="flex-column gap-m">
            <dashlet-ratio-bar class="dashlet-cell" emptyTitle="No data available for SIP Entities">
                <dashlet-ratio-bar-item
                    [value]="getSIPGoodEntities().length"
                    [color]="getSeverityColor(0)"
                    [matTooltip]="getSIPGoodEntities().length + ' of ' + dashlet.sipEntityStatusList.length + ' Up'"
                    (click)="openDetails('sipEntity', 0)"
                ></dashlet-ratio-bar-item>
                <dashlet-ratio-bar-item
                    [value]="getSIPSatisfactoryEntities().length"
                    [color]="getSeverityColor(1)"
                    [matTooltip]="getSIPSatisfactoryEntities().length + ' of ' + dashlet.sipEntityStatusList.length + ' Up (Errors)'"
                    (click)="openDetails('sipEntity', 1)"
                ></dashlet-ratio-bar-item>
                <dashlet-ratio-bar-item
                    [value]="getSIPPoorEntities().length"
                    [color]="getSeverityColor(2)"
                    [matTooltip]="getSIPPoorEntities().length + ' of ' + dashlet.sipEntityStatusList.length + ' Down'"
                    (click)="openDetails('sipEntity', 2)"
                ></dashlet-ratio-bar-item>
            </dashlet-ratio-bar>
            <!-- Readouts -->
            <div class="justify-space-between">
                <h3 class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('sipEntity', 0)" *ngIf="getSIPGoodEntities().length > 0">
                    {{ getSIPGoodEntities().length }}
                    <span class="mat-h3">Up</span>
                </h3>
                <h3 class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('sipEntity', 1)" *ngIf="getSIPSatisfactoryEntities().length > 0">
                    {{ getSIPSatisfactoryEntities().length }}
                    <span class="mat-h3">Up (Errors)</span>
                </h3>
                <h3 class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('sipEntity', 2)" *ngIf="getSIPPoorEntities().length > 0">
                    {{ getSIPPoorEntities().length }}
                    <span class="mat-h3">Down</span>
                </h3>
            </div>
        </div>
    </div>

    <!-- SIP registration status  -->
    <div *ngIf="dashlet.showSIPRegistrationStatus && !openSubcontainer">
        <dashlet-section-header>SIP Registrations</dashlet-section-header>
        <div class="grid-2-columns gap-m grow">
            <div class="flex gap-s align-baseline">
                <span class="mat-h2">{{ dashlet.sipRegistrationStatus.primarySubscribed }}</span>
                <span class="mat-body-1">primary subscribed</span>
            </div>
            <div class="flex gap-s align-baseline">
                <span class="mat-h2">{{ dashlet.sipRegistrationStatus.secondarySubscribed }}</span>
                <span class="mat-body-1">secondary subscribed</span>
            </div>
            <div class="flex gap-s align-baseline">
                <span class="mat-h2">{{ dashlet.sipRegistrationStatus.primaryRegistered }}</span>
                <span class="mat-body-1">primary registered</span>
            </div>
            <div class="flex gap-s align-baseline">
                <span class="mat-h2">{{ dashlet.sipRegistrationStatus.secondaryRegistered }}</span>
                <span class="mat-body-1">secondary registered</span>
            </div>
        </div>
    </div>

    <!-- SIP session status  -->
    <div *ngIf="dashlet.showSIPSessionStatus && !openSubcontainer">
        <dashlet-section-header>SIP Sessions</dashlet-section-header>
        <div class="grid-2-columns gap-m">
            <div class="flex gap-s align-baseline">
                <span class="mat-h2">{{ dashlet.sipSessionStatus.audio }}</span> <span class="mat-body-1">Audio</span>
            </div>
            <div class="flex gap-s align-baseline">
                <span class="mat-h2">{{ dashlet.sipSessionStatus.multimedia }}</span>
                <span class="mat-body-1">MultiMedia</span>
            </div>
            <div class="flex gap-s align-baseline">
                <span class="mat-h2">{{ dashlet.sipSessionStatus.fax }}</span> <span class="mat-body-1">Fax</span>
            </div>
            <div class="flex gap-s align-baseline">
                <span class="mat-h2">{{ dashlet.sipSessionStatus.text }}</span>
                <span class="mat-body-1">Text</span>
            </div>
            <div class="flex gap-s align-baseline">
                <span class="mat-h2">{{ dashlet.sipSessionStatus.other }}</span>
                <span class="mat-body-1">Other</span>
            </div>
            <div class="flex gap-s align-baseline">
                <span class="mat-h2">{{ dashlet.sipSessionStatus.getTotal() }}</span>
                <span class="mat-body-1">Total</span>
            </div>
        </div>
    </div>

    <dashlet-section-custom-scripts *ngIf="dashlet.showCustomScripts && !openSubcontainer" [equipment]="dashlet.equipment" [customer]="dashlet.customer">
    </dashlet-section-custom-scripts>
</div>

<!-- SIP Entity Status Drilldown Content -->
<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'sipEntity'"
    [title]="dashlet.location.locationName + ' | ' + dashlet.equipment.equipmentName"
    [navPrevious]="'ASM System Health - ' + dashlet.customer.customerName"
    [navCurrent]="'ASM SIP Entity Status - ' + getSIPEntityStatus()"
    [color]="getSeverityColor(openSubcontainerSeverity)"
    (close)="closeDetails()"
>
    <ng-container subtitle>
        <span *ngIf="openSubcontainerSeverity === 0"> {{ getSIPGoodEntities().length }} of {{ dashlet.sipEntityStatusList.length }} SIP Entities Up </span>
        <span *ngIf="openSubcontainerSeverity === 1">
            {{ getSIPSatisfactoryEntities().length }} of {{ dashlet.sipEntityStatusList.length }} SIP Entities Up (Errors)
        </span>
        <span *ngIf="openSubcontainerSeverity === 2"> {{ getSIPPoorEntities().length }} of {{ dashlet.sipEntityStatusList.length }} SIP Entities Down </span>
    </ng-container>
    <ng-container body>
        <div *ngFor="let sipEntityStatus of getSIPEntitiesByStatus()" class="gateway dashlet-cell">
            <!-- title -->
            <dashlet-section-header>{{ sipEntityStatus.entityName }}</dashlet-section-header>
            <!-- properties -->
            <div class="flex gap-m">
                <div class="flex-column text-align-right equipment-detail-labels">
                    <div class="mat-body-2">FQDN or IP address</div>
                    <div class="mat-body-2">Status</div>
                    <div class="mat-body-2">Mode</div>
                    <div class="mat-body-2">Address Map</div>
                    <div class="mat-body-2">Last Response</div>
                    <div class="mat-body-2">Response ms</div>
                    <div class="mat-body-2">Last Reason</div>
                </div>
                <div class="flex-column equipment-detail-values">
                    <div class="mat-body-1">{{ sipEntityStatus.fqdNorIPaddr || '---' }}</div>
                    <div class="mat-body-1">{{ sipEntityStatus.status || '---' }}</div>
                    <div class="mat-body-1">{{ sipEntityStatus.mode || '---' }}</div>
                    <div class="mat-body-1">{{ sipEntityStatus.addressMap || '---' }}</div>
                    <div class="mat-body-1">{{ sipEntityStatus.lastResponse || '---' }}</div>
                    <div class="mat-body-1">{{ sipEntityStatus.responseMs || '---' }}</div>
                    <div class="mat-body-1">{{ sipEntityStatus.reason || '---' }}</div>
                </div>
            </div>
        </div>
    </ng-container>

    <div footer class="justify-end grow gap-m">
        <button mat-button [disabled]="openSubcontainerSeverity === 0" matTooltip="View up" (click)="openDetails('sipEntity', 0)">Up</button>
        <button mat-button [disabled]="openSubcontainerSeverity === 1" matTooltip="View satisfactory" (click)="openDetails('sipEntity', 1)">Up (Errors)</button>
        <button mat-button [disabled]="openSubcontainerSeverity === 2" matTooltip="View down" (click)="openDetails('sipEntity', 2)">Down</button>
    </div>
</vsm-dashlet-subcontainer>
