/* eslint-disable no-undef */
import { filter } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { BehaviorSubject, ReplaySubject, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {
    private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
    public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

    private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
    public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

    private subs = new Subscription();
    private storageEventListener: (event: StorageEvent) => void;

    private navigateToLoginPage() {
        this.saveCurrentLocation();
        this.router.navigateByUrl('/Login');
    }

    constructor(private oauthService: OAuthService, private router: Router) {
        this.storageEventListener = (event: StorageEvent) => {
            if (event.key !== 'access_token' && event.key !== null) {
                return;
            }

            this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

            if (!this.oauthService.hasValidAccessToken()) {
                this.saveCurrentLocation();
                this.navigateToLoginPage();
            }
        };
        window.addEventListener('storage', this.storageEventListener);

        this.subs.add(
            this.oauthService.events.subscribe(_ => {
                this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
            })
        );
        this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

        this.subs.add(
            this.oauthService.events.pipe(filter(e => ['token_received'].includes(e.type))).subscribe(e => {
                this.oauthService.loadUserProfile().then(res => {
                    const redirectUrl = sessionStorage.getItem('postLoginRedirectUrl');
                    if (redirectUrl && redirectUrl.toLowerCase() !== '/login') {
                        router.navigateByUrl(redirectUrl);
                    }
                });
            })
        );

        this.subs.add(
            this.oauthService.events
                .pipe(filter(e => ['session_error'].includes(e.type)))
                .subscribe(() => this.oauthService.restartSessionChecksIfStillLoggedIn())
        );
        this.subs.add(
            this.oauthService.events
                .pipe(filter(e => ['session_terminated'].includes(e.type)))
                .subscribe(e => this.navigateToLoginPage())
        );
        this.subs.add(
            this.oauthService.events.pipe(filter(e => e.type === 'session_changed')).subscribe(e =>
                this.oauthService.silentRefresh().catch(result => {
                    const errorResponsesRequiringUserInteraction = [
                        'interaction_required',
                        'login_required',
                        'account_selection_required',
                        'consent_required'
                    ];
                    if (
                        result &&
                        result.reason &&
                        errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0
                    ) {
                        this.navigateToLoginPage();
                    }
                })
            )
        );
        this.saveCurrentLocation();
        this.oauthService.setupAutomaticSilentRefresh();
    }

    public ngOnDestroy(): void {
        this.subs.unsubscribe();
        window.removeEventListener('storage', this.storageEventListener);
    }

    public runInitialLoginSequence(): Promise<void> {
        return this.oauthService
            .loadDiscoveryDocument()
            .then(() => this.oauthService.tryLogin())
            .then(() => {
                if (this.oauthService.hasValidAccessToken()) {
                    return Promise.resolve();
                }
                return this.oauthService
                    .silentRefresh()
                    .then(() => {
                        return Promise.resolve();
                    })
                    .catch(result => {
                        const errorResponsesRequiringUserInteraction = [
                            'interaction_required',
                            'login_required',
                            'account_selection_required',
                            'consent_required'
                        ];
                        if (
                            result &&
                            result.reason &&
                            errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0
                        ) {
                            this.login();
                            return Promise.resolve();
                        }
                        return Promise.reject(result);
                    });
            })
            .then(() => {
                this.isDoneLoadingSubject$.next(true);
                if (
                    this.oauthService.state &&
                    this.oauthService.state !== 'undefined' &&
                    this.oauthService.state !== 'null'
                ) {
                    let stateUrl = this.oauthService.state;
                    if (stateUrl.startsWith('/') === false) {
                        stateUrl = decodeURIComponent(stateUrl);
                    }
                    this.router.navigateByUrl(stateUrl);
                }
            })
            .catch(() => {
                this.isDoneLoadingSubject$.next(true);
            });
    }

    public setupAutomaticSilentRefresh() {
        this.oauthService.setupAutomaticSilentRefresh();
    }
    public login(targetUrl?: string) {
        this.oauthService.initLoginFlow(targetUrl || this.router.url);
    }

    public logout() {
        this.oauthService.logOut(false, '/Login');
    }

    private saveCurrentLocation(): void {
        const redirectUri: string = location.pathname;
        if (redirectUri && redirectUri.toLowerCase() !== '/login' && redirectUri.toLowerCase() !== '/') {
            sessionStorage.setItem('postLoginRedirectUrl', redirectUri);
        }
    }

    public isAuthenticated(): boolean {
        return this.oauthService.hasValidAccessToken();
    }
}
