import {
    Directive,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewContainerRef,
    ComponentFactoryResolver,
    ReflectiveInjector,
    OnDestroy,
    ComponentRef
} from '@angular/core';

import { DashletSetting, DashletSettingType } from '@models/index';
import { DashletSettingsService } from '@services/index';

@Directive({
    selector: 'dashlet-setting'
})
export class DashletSettingDirective implements OnInit, OnDestroy {
    @Input() setting!: DashletSetting;
    @Output() settingChange: EventEmitter<void> = new EventEmitter<void>();

    private dynamic: ComponentRef<any> | null = null;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private settingsService: DashletSettingsService
    ) {}

    ngOnInit(): void {
        this.insertSettingComponent();
    }

    ngOnDestroy(): void {
        this.destroySettingComponent();
    }

    private insertSettingComponent(): void {
        if (this.setting) {
            // subscribe to event
            this.setting.change.subscribe(() => this.changed());

            // prepare injector for injecting data into component
            const resolvedInputs = ReflectiveInjector.resolve([
                {
                    provide: 'data',
                    useValue: this.setting
                }
            ]);
            const inputInjector = ReflectiveInjector.fromResolvedProviders(
                resolvedInputs,
                this.viewContainerRef.parentInjector
            );

            // create component
            const cmpType = this.settingsService.resolveComponent(this.setting);
            const cmpFactory = this.componentFactoryResolver.resolveComponentFactory(cmpType);
            const cmp = cmpFactory.create(inputInjector);

            // insert into view
            this.viewContainerRef.insert(cmp.hostView);

            // clean old component
            this.destroySettingComponent();
            this.dynamic = cmp;
        }
    }

    private destroySettingComponent(): void {
        if (this.dynamic) {
            if (this.setting) {
                // unsub event
                this.setting.change.unsubscribe();
            }

            // destroy component
            this.dynamic.destroy();
            this.dynamic = null;
        }
    }

    private changed(): void {
        this.settingChange.emit();
    }
}
