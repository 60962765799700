import * as RibbonSystemHealthEquipmentActions from '@actions/get-ribbon-system-health-equipment/get-ribbon-system-health-equipment.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { DashletSettingsService } from '@services/index';

@Injectable()
export class GetRibbonSystemHealthEquipmentEffects {
    constructor(private actions$: Actions, private settingsService: DashletSettingsService) {}
    getRibbonSystemHealthEquipment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RibbonSystemHealthEquipmentActions.GetRibbonSystemHealthEquipments),
            mergeMap(action =>
                this.settingsService.getRibbonSystemHealthEquipment(action.entityId).pipe(
                    catchError(err =>
                        of(RibbonSystemHealthEquipmentActions.GetRibbonSystemHealthEquipmentsFailure({ error: err }))
                    ),
                    mergeMap((data: any) => [
                        RibbonSystemHealthEquipmentActions.GetRibbonSystemHealthEquipmentsSuccess({
                            data: data,
                            entityId: action.entityId
                        })
                    ])
                )
            )
        )
    );
}
