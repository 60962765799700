<ng-container *ngIf="!openSubcontainer">
    <dashlet-table
        [columns]="columns"
        [dataSource]="tableData"
        [tableHeightRows]="getTableSize()"
       
        [sortDirection]="'desc'"
        [menuItems]="menuItems"
        defaultSort="severity"
    ></dashlet-table>
</ng-container>

<!-- details drilldown -->
<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'networkConnectivity'"
    navPrevious="Network Connectivity Summary"
    navCurrent="Details"
    (close)="closeDetails()"
    [showNavigation]="true"
>
    <ng-container title> Network Connectivity Summary </ng-container>
    <ng-container subtitle>
        {{ dashlet.customer.customerName }}/ {{ dashlet.networkConnectivitySummaryData[currentDataIndex].equipment.equipmentName }}
    </ng-container>
    <ng-container body>
        <div class="flex-column gap m" *ngIf="openSubcontainer === 'networkConnectivity'">
            <!-- nav header -->
            <dashlet-section-network-connectivity
                [equipment]="dashlet.networkConnectivitySummaryData[currentDataIndex].equipment"
                [customerId]="dashlet.customer.customerId"
                [showNetworkGraph]="true"
                [showHeader]="!smallLayout"
            >
            </dashlet-section-network-connectivity>
        </div>
        
        <dashlet-section-header *ngIf="!smallLayout"> Configuration Details</dashlet-section-header>
        <div class="section section-system" *ngIf="!smallLayout">
            <div class="dashlet-property">
                <div class="dashlet-property-key half-width dashlet-label dashlet-label-n">
                    Friendly Name
                </div>
                <div class="dashlet-property-value dashlet-value dashlet-value-xs">
                    {{dashlet.networkConnectivitySummaryData[currentDataIndex].equipment.networkConnectivityConfiguration.friendlyName}}
                </div>
            </div>
            <div class="dashlet-property">
                <div class="dashlet-property-key half-width dashlet-label dashlet-label-n">
                    FQDN
                </div>
                <div class="dashlet-property-value dashlet-value dashlet-value-xs">
                    {{dashlet.networkConnectivitySummaryData[currentDataIndex].equipment.networkConnectivityConfiguration.destinationIPAddress}}
                </div>
            </div>
            <div class="dashlet-property">
                <div class="dashlet-property-key half-width dashlet-label dashlet-label-n">
                    Repeat Interval (mins)
                </div>
                <div class="dashlet-property-value dashlet-value dashlet-value-xs">
                    {{dashlet.networkConnectivitySummaryData[currentDataIndex].equipment.networkConnectivityConfiguration.repeatIntervalMinutes}}
                </div>
            </div>
            <div class="dashlet-property">
                <div class="dashlet-property-key half-width dashlet-label dashlet-label-n">
                    Max Individual Response (ms)
                </div>
                <div class="dashlet-property-value dashlet-value dashlet-value-xs">
                    {{dashlet.networkConnectivitySummaryData[currentDataIndex].equipment.networkConnectivityConfiguration.maximumAcceptableIndividualResponseMs}}
                </div>
            </div>
            <div class="dashlet-property">
                <div class="dashlet-property-key half-width dashlet-label dashlet-label-n">
                    Max Average Response (ms)
                </div>
                <div class="dashlet-property-value dashlet-value dashlet-value-xs">
                    {{dashlet.networkConnectivitySummaryData[currentDataIndex].equipment.networkConnectivityConfiguration.maximumAcceptableAverageResponseMs}}
                </div>
            </div>
            <div class="dashlet-property">
                <div class="dashlet-property-key half-width dashlet-label dashlet-label-n">
                    TTL Value
                </div>
                <div class="dashlet-property-value dashlet-value dashlet-value-xs">
                    {{dashlet.networkConnectivitySummaryData[currentDataIndex].equipment.networkConnectivityConfiguration.ttlValue}}
                </div>
            </div>
            <div class="dashlet-property">
                <div class="dashlet-property-key half-width dashlet-label dashlet-label-n">
                    Build TTL Profile
                </div>
                <div class="dashlet-property-value dashlet-value dashlet-value-xs">
                    {{dashlet.networkConnectivitySummaryData[currentDataIndex].equipment.networkConnectivityConfiguration.buildTTLProfile}}
                </div>
            </div>
            <div class="dashlet-property">
                <div class="dashlet-property-key half-width dashlet-label dashlet-label-n">
                    Acceptable Down Times
                </div>
                <div class="dashlet-property-value dashlet-value dashlet-value-xs">
                    {{dashlet.networkConnectivitySummaryData[currentDataIndex].equipment.networkConnectivityConfiguration.pingAttempts}}
                </div>
            </div>
            <div class="dashlet-property">
                <div class="dashlet-property-key half-width dashlet-label dashlet-label-n">
                    Reporting Type
                </div>
                <div class="dashlet-property-value dashlet-value dashlet-value-xs">
                    {{dashlet.networkConnectivitySummaryData[currentDataIndex].equipment.networkConnectivityConfiguration.successReportingType}}
                </div>
            </div>
            <div class="dashlet-property">
                <div class="dashlet-property-key half-width dashlet-label dashlet-label-n">
                    Send Alarm on Failure
                </div>
                <div class="dashlet-property-value dashlet-value dashlet-value-xs">
                    {{dashlet.networkConnectivitySummaryData[currentDataIndex].equipment.networkConnectivityConfiguration.sendAlarmOnFailure}}
                </div>
            </div>
            <div class="dashlet-property">
                <div class="dashlet-property-key half-width dashlet-label dashlet-label-n">
                    Default Site
                </div>
                <div class="dashlet-property-value dashlet-value dashlet-value-xs">
                    ---
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container footer>
        <div class="grow justify-space-between align-center">
            <button [disabled]="!detailsHasPrev()" (click)="detailsPrev()" mat-icon-button><mat-icon>chevron_left</mat-icon></button>
            <div class="flex gap-s">
                <button [disabled]="!detailsHasNext()" (click)="detailsNext()" mat-icon-button><mat-icon>chevron_right</mat-icon></button>
            </div>
        </div>
    </ng-container>
</vsm-dashlet-subcontainer>

