import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as BrandingActions from '@actions/branding/branding.actions';
import { BrandingService } from '@services/index';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { LogoImage, LogoRespObj } from '@models/index';

@Injectable()
export class BrandingEffects {
    constructor(private actions$: Actions, private brandingService: BrandingService) {}

    getProcessWorkflowData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BrandingActions.GetBrandingColors),
            mergeMap(action =>
                this.brandingService.getBrandColors(action.entityId).pipe(
                    map(data =>
                        BrandingActions.GetBrandingColorsSuccess({
                            data: data
                        })
                    ),
                    catchError(err => of(BrandingActions.GetBrandingColorsFailure({ error: err })))
                )
            )
        )
    );

    getBusinessPartnerLogo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BrandingActions.GetBusinessPartnerLogo),
            mergeMap(action =>
                this.brandingService.getBusinessPartnerLogo(action.entityId).pipe(
                    map((logo: LogoImage) => {
                        return BrandingActions.GetBusinessPartnerLogoSuccess({
                            data: logo
                        });
                    }),
                    catchError(err => of(BrandingActions.GetBusinessPartnerLogoFailure({ error: err })))
                )
            )
        )
    );
}
