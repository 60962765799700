import { createAction, props } from '@ngrx/store';

export const GetHistoricMemoryData = createAction(
  '[HistoricMemoryData] Get HistoricMemoryDatas',
  props<{ equipmentId: string, commandTypeId: string, from?: Date | string, to?: Date | string, max?: number | null }>()
);

export const GetHistoricMemoryDataSuccess = createAction(
  '[HistoricMemoryData] Get HistoricMemoryDatas Success',
  props<{ data:any }>()
);

export const GetHistoricMemoryDataFailure = createAction(
  '[HistoricMemoryData] Get HistoricMemoryDatas Failure',
  props<{ error: any }>()
);
