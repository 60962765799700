import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

export const GetNotifyProcessAdminBackupData = createAction(
  '[ProcessAdminBackupData] Get Notify ProcessAdminBackupData',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetNotifyProcessAdminBackupDataSuccess = createAction(
  '[ProcessAdminBackupData] Get Notify ProcessAdminBackupData Success',
  props<{ data: RealTimeNotify }>()
);

export const GetNotifyProcessAdminBackupDataFailure = createAction(
  '[ProcessAdminBackupData] Get Notify ProcessAdminBackupData Failure',
  props<{ error: any }>()
);

export const GetProcessAdminBackupData = createAction(
  '[ProcessAdminBackupData] Get ProcessAdminBackupData',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetProcessAdminBackupDataSuccess = createAction(
  '[ProcessAdminBackupData] Get ProcessAdminBackupData Success',
  props<{ data: any}>()
);

export const GetProcessAdminBackupDataFailure = createAction(
  '[ProcessAdminBackupData] Get ProcessAdminBackupData Failure',
  props<{ error: any }>()
);

export const GetHistoricProcessAdminBackupData = createAction(
  '[ProcessAdminBackupData] Get HistoricProcessAdminBackupData',
  props<{ equipmentId: string, commandTypeId: string, from?: Date | string, to?: Date | string, max?: number | null }>()
);

export const GetHistoricProcessAdminBackupDataSuccess = createAction(
  '[ProcessAdminBackupData] Get HistoricProcessAdminBackupData Success',
  props<{ data:any }>()
);

export const GetHistoricProcessAdminBackupDataFailure = createAction(
  '[ProcessAdminBackupData] Get HistoricProcessAdminBackupData Failure',
  props<{ error: any }>()
);

 
