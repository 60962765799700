export class SupervisorData {
	private _total: number;
	private _webUser: number;
	private _windowsUser: number;

	constructor(total: number, webUser: number, windowsUser: number) {
		this._total = total;
		this._webUser = webUser;
		this._windowsUser = windowsUser;
	}

	public update(total: number, webUser: number, windowsUser: number) {
		this._total = total;
		this._webUser = webUser;
		this._windowsUser = windowsUser;
	}

	/**
	 * Getter total
	 * @return {number}
	 */
	public get total(): number {
		return this._total;
	}

	/**
	 * Getter webUser
	 * @return {number}
	 */
	public get webUser(): number {
		return this._webUser;
	}

	/**
	 * Getter windowUser
	 * @return {number}
	 */
	public get windowsUser(): number {
		return this._windowsUser;
	}

	/**
	 * Setter total
	 * @param {number} value
	 */
	public set total(value: number) {
		this._total = value;
	}

	/**
	 * Setter webUser
	 * @param {number} value
	 */
	public set webUser(value: number) {
		this._webUser = value;
	}

	/**
	 * Setter windowUser
	 * @param {number} value
	 */
	public set windowsUser(value: number) {
		this._windowsUser = value;
	}
}
