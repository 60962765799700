<div class="flex-column gap-l">
    <div class="justify-space-around">
        <div class="flex gap-m">
            <div class="flex-column equipment-detail-labels">
                <div class="mat-body-2 dashlet-link-underline">Streams</div>
                <div class="mat-body-2">Customers</div>
            </div>
            <div class="flex-column text-no-wrap">
                <div class="mat-body-1">{{ streams || 'Unknown' }}</div>
                <div class="mat-body-1">{{ customerData.length || 'Unknown' }}</div>
            </div>
        </div>
        <div class="flex gap-m">
            <div class="flex-column equipment-detail-labels">
                <div class="mat-body-2">Traffic</div>
                <div class="mat-body-2">Call Quality</div>
            </div>
            <div class="flex-column text-no-wrap">
                <div class="mat-body-1">{{ traffic | number: '1.0-3' || 'Unknown' }} GB data monitored</div>
                <div class="mat-body-1">{{ quality || 'Unknown' }} calls > 4.0 MOS</div>
            </div>
        </div>
    </div>

<div class="grow justify-space-around">
    <mat-form-field appearance="outline">
        <mat-label>Calls In Progress</mat-label>
        <mat-select #input [(ngModel)]="cipSelectedMOS" (ngModelChange)="onCipSelectChange($event)">
            <mat-option *ngFor="let item of cipOptions" [value]="item.value">{{ item.label }} </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Last 24 Hours</mat-label>
        <mat-select #input [(ngModel)]="selectedMOS" (ngModelChange)="onMosSelectChange($event)">
            <mat-option *ngFor="let item of mosOptions" [value]="item.value">{{ item.label }} </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<dashlet-table [columns]="columns" [dataSource]="customerData"
[pagination]="true"
[pageLength]="15"
[tableHeightRows]="15"
[loading]="loading"> </dashlet-table>
</div>
