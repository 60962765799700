/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable no-undef */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { GridsterConfig, GridsterItem, GridsterPush, GridsterItemComponentInterface } from 'angular-gridster2';

import { Dashlet } from '@models/index';
import { NotificationService, TileGridService } from '@services/index';

interface GridPoint {
    x: number;
    y: number;
}

@Component({
    selector: 'tile-grid',
    templateUrl: './tile-grid.component.html',
    styleUrls: ['./tile-grid.component.scss']
})
export class TileGridComponent implements OnInit {
    @Input() dashlets: Dashlet[] = [];
    @Input() interactive: boolean = false;
    @Input() fullscreen: boolean;
    @Output() gridChange: EventEmitter<null> = new EventEmitter();
    @Output() dashletRemove: EventEmitter<Dashlet> = new EventEmitter();
    @Output() threshCreated: EventEmitter<string> = new EventEmitter();
    @Output() showDashletSettings: EventEmitter<Dashlet> = new EventEmitter();

    public options: GridsterConfig = {};

    private itemComponents: GridsterItemComponentInterface[] = [];
    private previouslyPushedItemComponents: GridsterItemComponentInterface[] = [];
    private readonly gridColsMax: number = 30;
    private readonly gridRowsMax: number = 99;

    constructor(private tileGridService: TileGridService, private notificationService: NotificationService) {}

    public ngOnInit(): void {
        this.tileGridService.registerTileGrid(this);
        this.options = {
            gridType: 'fixed',
            compactType: 'none',
            margin: 10,
            outerMargin: true,
            minRows: 9,
            maxRows: this.gridRowsMax,
            minCols: 12,
            maxCols: this.gridColsMax,
            defaultItemCols: 2,
            defaultItemRows: 2,
            fixedColWidth: 65,
            fixedRowHeight: 65,
            scrollToNewItems: true,

            draggable: {
                enabled: true,
                ignoreContent: true,
                dragHandleClass: 'drag-handle'
            },
            resizable: {
                enabled: true,
                handles: {
                    s: false,
                    e: false,
                    n: false,
                    w: false,
                    se: false,
                    ne: false,
                    sw: false,
                    nw: false
                }
            },
            displayGrid: 'onDrag&Resize',
            swap: true,
            disablePushOnDrag: true,
            disablePushOnResize: false, // disable push on resize
            pushDirections: { north: true, east: true, south: true, west: true },
            pushResizeItems: false,
            disableWindowResize: false,
            pushItems: true
        };

        this.options.itemChangeCallback = this.itemChanged.bind(this);
        this.options.itemInitCallback = this.itemInitiated.bind(this);
        this.options.itemRemovedCallback = this.deleteItem.bind(this);
    }

    public canFit(size: { cols: number; rows: number }): boolean {
        return !!this.options.api!.getNextPossiblePosition!({
            rows: size.rows,
            cols: size.cols,
            x: 0,
            y: 0
        });
    }

    public remove(dashlet: Dashlet): void {
        this.dashletRemove.emit(dashlet);
    }

    public thresholdCreated(message: string): void {
        this.threshCreated.emit(message);
    }

    public showSettings(dashlet: Dashlet): void {
        this.showDashletSettings.emit(dashlet);
    }

    public resize(dashlet: Dashlet, size: { rows: number; cols: number }): void {
        let rightThenDownOK = false;
        let downThenRightOK = false;

        let colsOK = false;
        let rowsOK = false;

        const itemConponent = this.itemComponents.find(i => i.item.id === dashlet.id);
        if (itemConponent) {
            const diffCols = size.cols - itemConponent!.$item.cols;
            const diffRows = size.rows - itemConponent!.$item.rows;

            // try resize right then down
            let useOriginalSize = true;
            this.previouslyPushedItemComponents = [];
            colsOK = this.checkResizeCols(diffCols, diffRows, itemConponent!, useOriginalSize);

            if (colsOK) {
                useOriginalSize = false;
                rowsOK = this.checkResizeRows(diffCols, diffRows, itemConponent!, useOriginalSize);
            }

            rightThenDownOK = colsOK && rowsOK;

            // try resize down then right
            useOriginalSize = true;
            this.previouslyPushedItemComponents = [];
            if (!rightThenDownOK) {
                rowsOK = this.checkResizeRows(diffCols, diffRows, itemConponent!, useOriginalSize);

                if (rowsOK) {
                    useOriginalSize = false;
                    colsOK = this.checkResizeCols(diffCols, diffRows, itemConponent!, useOriginalSize);
                }

                downThenRightOK = colsOK && rowsOK;
            }

            // resize the item if possible
            if (rightThenDownOK) {
                const pushResize = new GridsterPush(itemConponent!);
                itemConponent!.$item.cols += diffCols;
                if (pushResize.pushItems(pushResize.fromWest)) {
                    this.moveItem(pushResize, itemConponent!);
                    itemConponent!.$item.rows += diffRows;
                    if (pushResize.pushItems(pushResize.fromNorth)) {
                        this.moveItem(pushResize, itemConponent!);
                        this.tileGridService.removeResizeFailedDashlet(dashlet);
                    } else {
                        itemConponent!.$item.cols -= diffCols;
                        itemConponent!.$item.rows -= diffRows;
                        pushResize.restoreItems(); // restore to initial state the pushed items
                        this.tileGridService.addResizeFailedDashlet(dashlet);
                    }
                } else {
                    itemConponent!.$item.cols -= diffCols;
                    pushResize.restoreItems(); // restore to initial state the pushed items
                    this.tileGridService.addResizeFailedDashlet(dashlet);
                }
                this.options.api!.resize!();
            } else if (downThenRightOK) {
                const pushResize = new GridsterPush(itemConponent!);
                itemConponent!.$item.rows += diffRows;
                if (pushResize.pushItems(pushResize.fromNorth)) {
                    this.moveItem(pushResize, itemConponent!);
                    itemConponent!.$item.cols += diffCols;
                    if (pushResize.pushItems(pushResize.fromWest)) {
                        this.moveItem(pushResize, itemConponent!);
                        this.tileGridService.removeResizeFailedDashlet(dashlet);
                    } else {
                        itemConponent!.$item.cols -= diffCols;
                        itemConponent!.$item.rows -= diffRows;
                        pushResize.restoreItems(); // restore to initial state the pushed items
                        this.tileGridService.addResizeFailedDashlet(dashlet);
                    }
                } else {
                    itemConponent!.$item.rows -= diffRows;
                    pushResize.restoreItems(); // restore to initial state the pushed items
                    this.tileGridService.addResizeFailedDashlet(dashlet);
                }

                this.options.api!.resize!();
            } else {
                this.notificationService.notify('Not Enough Space To Resize', 'error');
            }
        }
    }

    private moveItem(pushResize: GridsterPush, itemConponent: GridsterItemComponentInterface): void {
        pushResize.checkPushBack(); // check for items can restore to original position
        pushResize.setPushedItems(); // save the items pushed
        itemConponent.setSize();
        itemConponent.checkItemChanges(itemConponent.$item, itemConponent.item);
    }

    private checkResizeCols(
        diffCols: number,
        diffRows: number,
        itemConponent: GridsterItemComponentInterface,
        useOriginalSize: boolean
    ): boolean {
        let totalNewMaxCols = 0;

        const newItemCols = itemConponent.$item.cols + diffCols;
        const newItemRows = itemConponent.$item.rows + diffRows;

        const itemTop = itemConponent.$item.y;
        const itemBottom = itemConponent.$item.y + newItemRows;

        const horizontallyOverlapItems = this.itemComponents.filter(i => {
            if (i.item.id !== itemConponent.item.id) {
                let currentItemTop = i.item.y;
                let currentItemBottom = i.item.y + i.item.rows;

                // if this item was previously pushed, use the pushed position
                if (
                    this.previouslyPushedItemComponents.length > 0 &&
                    this.previouslyPushedItemComponents.find(h => h.item.id === i.item.id)
                ) {
                    currentItemTop += diffRows;
                    currentItemBottom += diffRows;
                }

                return (
                    (useOriginalSize ? itemConponent.$item.y : itemTop) < currentItemBottom &&
                    currentItemTop < (useOriginalSize ? itemConponent.$item.y + itemConponent.$item.rows : itemBottom)
                );
            } else {
                return false;
            }
        });

        this.previouslyPushedItemComponents = [...horizontallyOverlapItems];

        // find the max columns of the horizontally overlapped items
        for (const item of horizontallyOverlapItems) {
            if (item.$item.cols > totalNewMaxCols) {
                totalNewMaxCols = item.$item.cols;
            }
        }

        totalNewMaxCols += newItemCols;

        return totalNewMaxCols <= this.gridColsMax;
    }

    private checkResizeRows(
        diffCols: number,
        diffRows: number,
        itemConponent: GridsterItemComponentInterface,
        useOriginalSize: boolean
    ) {
        const totalNewMaxCols = 0;
        let totalNewMaxRows = 0;

        const newItemCols = itemConponent.$item.cols + diffCols;
        const newItemRows = itemConponent.$item.rows + diffRows;

        const itemLeft = itemConponent.$item.x;
        const itemRight = itemConponent.$item.x + newItemCols;

        const verticallyOverlapItems = this.itemComponents.filter(i => {
            if (i.item.id !== itemConponent.item.id) {
                // assume item position after the columns push
                let currentItemLeft = i.item.x;
                let currentItemRight = i.item.x + i.item.cols;

                // if this item was previously pushed, use the pushed position
                if (
                    this.previouslyPushedItemComponents.length > 0 &&
                    this.previouslyPushedItemComponents.find(h => h.item.id === i.item.id)
                ) {
                    currentItemLeft += diffCols;
                    currentItemRight += diffCols;
                }
                return (
                    (useOriginalSize ? itemConponent.$item.x : itemLeft) < currentItemRight &&
                    currentItemLeft < (useOriginalSize ? itemConponent.$item.x + itemConponent.$item.cols : itemRight)
                );
            } else {
                return false;
            }
        });

        this.previouslyPushedItemComponents = [...verticallyOverlapItems];

        // find the max rows of the vertically overlapped items
        for (const item of verticallyOverlapItems) {
            if (item.$item.rows > totalNewMaxRows) {
                totalNewMaxRows = item.$item.rows;
            }
        }

        totalNewMaxRows += newItemRows;

        return totalNewMaxRows <= this.gridRowsMax;
    }

    private itemChanged(item: GridsterItem, itemComponent: GridsterItemComponentInterface): void {
        this.gridChange.emit();
    }

    private itemInitiated(item: GridsterItem, itemComponent: GridsterItemComponentInterface): void {
        this.itemComponents.push(itemComponent);
    }

    private deleteItem(item: GridsterItem, itemComponent: GridsterItemComponentInterface): void {
        const index = this.itemComponents.indexOf(itemComponent);
        if (index !== -1) {
            this.itemComponents.splice(index, 1);
        }
    }

    public updateOptions(): void {
        this.options.api!.optionsChanged!();
    }

    public canResize(newRows: number, newCols: number, id: string): boolean {
        const currentDashlet = this.dashlets.find(i => i.id === id);
        const currentGridsterComponent = this.itemComponents.find(i => i.item.id === currentDashlet!.tile.id);
        const currentGridPoints: GridPoint[] = [];
        let canResize = true;
        if (currentGridsterComponent) {
            // Build an array of grid positions that the selected dashlet will need to take up when resized
            for (
                let row = currentGridsterComponent!.$item.y;
                row < currentGridsterComponent!.$item.y + newRows;
                row++
            ) {
                for (
                    let col = currentGridsterComponent!.$item.x;
                    col < currentGridsterComponent!.$item.x + newCols;
                    col++
                ) {
                    currentGridPoints.push({ x: col, y: row });
                }
            }

            /* --- CHECK IF DASHLET WILL EXCEED MAXIMUM BOUNDS --- */

            let colsOK = false;
            let rowsOK = false;
            const diffCols = newCols - currentDashlet!.tile.cols;
            const diffRows = newRows - currentDashlet!.tile.rows;

            // try resize right then down
            let useOriginalSize = true;
            this.previouslyPushedItemComponents = [];
            colsOK = this.checkResizeCols(diffCols, diffRows, currentGridsterComponent!, useOriginalSize);

            if (colsOK) {
                useOriginalSize = false;
                rowsOK = this.checkResizeRows(diffCols, diffRows, currentGridsterComponent!, useOriginalSize);
            }

            const rightThenDownOK = colsOK && rowsOK;

            // try resize down then right
            useOriginalSize = true;
            this.previouslyPushedItemComponents = [];
            rowsOK = this.checkResizeRows(diffCols, diffRows, currentGridsterComponent!, useOriginalSize);

            if (rowsOK) {
                useOriginalSize = false;
                colsOK = this.checkResizeCols(diffCols, diffRows, currentGridsterComponent!, useOriginalSize);
            }

            const downThenRightOK = colsOK && rowsOK;

            if (
                (!rightThenDownOK && !downThenRightOK) ||
                currentDashlet!.tile.y + newRows >= this.gridRowsMax ||
                currentDashlet!.tile.x + newCols >= this.gridColsMax
            ) {
                return false;
            }

            /* --- END CHECK MAXIMUM BOUNDS SECTION --- */

            // check that no other dashlet already occupies space
            this.itemComponents.forEach(i => {
                if (i !== currentGridsterComponent && !i.item.dragEnabled) {
                    const temp: GridPoint[] = [];
                    for (let row = i.$item.y; row < i.$item.y + i.$item.rows; row++) {
                        for (let col = i.$item.x; col < i.$item.x + i.$item.cols; col++) {
                            temp.push({ x: col, y: row });
                            if (
                                currentGridPoints.find(gridPoint => {
                                    return gridPoint.x === col && gridPoint.y === row;
                                })
                            ) {
                                canResize = false;
                                return;
                            }
                        }
                    }
                }
            });
        }
        return canResize;
    }
}
