<div class="flex-column gap-l" *ngIf="!openSubcontainer">
    <!-- system -->
    <dashlet-section-processor-memory
        #processorMemory
        [showSystemChart]="dashlet.showSystemChart"
        [equipment]="dashlet.equipment"
        [customerId]="dashlet.customer.customerId"
        [locationId]="dashlet.location.locationId"
    >
    </dashlet-section-processor-memory>
    <!-- network connectivity -->
    <dashlet-section-network-connectivity
        [showNetworkGraph]="dashlet.showNetworkConnectChart"
        [equipment]="dashlet.equipment"
        [customerId]="dashlet.customer.customerId"
    >
    </dashlet-section-network-connectivity>
    <dashlet-section-custom-scripts *ngIf="dashlet.showCustomScripts" [equipment]="dashlet.equipment" [customer]="dashlet.customer">
    </dashlet-section-custom-scripts>

    <!-- create thresholds alerts drilldown -->
    <vsm-dashlet-subcontainer
        *ngIf="openSubcontainer === 'createThreshold' || openSubcontainer === 'editThreshold'"
        [navPrevious]="'Linux System Health - ' + dashlet.customer.customerName"
        [navCurrent]="(openSubcontainer === 'createThreshold' ? 'Create' : 'Edit') + ' Threshold'"
        (close)="closeDetails()"
    >
        <ng-container title>{{ openSubcontainer === 'createThreshold' ? 'New' : 'Edit' }} {{ selectedField }} Threshold </ng-container>
        <ng-container body>
            <threshold-item #thresholdItem [settings]="dashlet.getSettings()" [type]="'Linux'" [threshold]="selectedThreshold"> </threshold-item>
        </ng-container>
        <div footer class="row row-buttons">
            <div>
                <button type="button" pButton class="btn button-details" (click)="closeDetails()" label="Cancel"></button>
            </div>
            <div *ngIf="openSubcontainer === 'editThreshold'; else addThreshold">
                <button type="submit" pButton class="btn button-details" (click)="saveEdit()" [disabled]="!thresholdItem.formValid()" label="Save"></button>
            </div>
            <ng-template #addThreshold>
                <button type="submit" pButton class="btn button-details" (click)="onSubmit()" [disabled]="!thresholdItem.formValid()" label="Save"></button>
            </ng-template>
        </div> </vsm-dashlet-subcontainer
></div>
