import { Customer, Dashlet, SelectItem } from '@models/index';
import { Subject } from 'rxjs';

export interface SystemHealthServer {
    severity: number | null;
    name: string | null;
    equipmentId: string | null;
    type: string;
    location: string | null;
    cpuPercentage: number | string | null;
    memoryPercentage: number | string | null;
    diskPercentage: number | string | null;
    uptime: number | null;
    averagePing: any;
    maxPing: string | number | null;
    servicesUp: number | null;
    servicesDown: number | null;
    servicesPartial: number | null;
    servicesOffline: number | null;
    availability: number | null;
    longestOutageSeconds: number | null;
    averagePingOrginal: number | null;
}

export interface SystemHealthService {
    Up: number;
    Partial: number;
    Down: number;
    Offline: number;
}

export interface ServerEquipment {
    equipmentGroup: string;
    equipmentName: string;
    equipmentId: string;
    locationId?: string;
}

export interface SummaryField {
    FieldCommandUpdateTimeInfoList: {
        CommandTypeId: string;
        LastUpdateTime: string;
    }[];
    FieldName: string;
    FieldValue: string;
}

export interface SummaryData {
    averageAvailability: SummaryField;
    averagePing: SummaryField;
    averageResponseTime: SummaryField;
    cpu: SummaryField;
    disk: SummaryField;
    longestOutageSeconds: SummaryField;
    maxPing: SummaryField;
    memory: SummaryField;
    servicesDown: SummaryField;
    servicesUp: SummaryField;
}

export interface DiskData {
    DiskName: string;
    DiskUsedPercentage: string;
}

//There are typos and Pascal casing errors in the data received from RealTime API so this function corrects it
export const fixObjectKeys = (obj: SummaryData): SummaryData => {
    const keyCorrections: { [key: string]: string } = {
        CPU: 'cpu',
        ServciesUp: 'servicesUp',
        ServciesDown: 'servicesDown'
    };
    const fixedObj = Object.keys(obj).reduce((acc, key) => {
        const correctedKey = keyCorrections[key] || key.charAt(0).toLowerCase() + key.slice(1);
        acc[correctedKey] = obj[key];
        return acc;
    }, {} as SummaryData);

    return fixedObj;
};

export class DashletSystemHealthSummary extends Dashlet {
    public readonly commandTypeIdSystemHealthSummary = '4EF0FF09-F8B5-4C33-83E2-C4D6F510D14C';

    public customer!: Customer;

    public location!: string;
    public locationId!: string;
    public locations: SelectItem[] = [];
    public equipment: SelectItem[] | undefined | null = [];
    public settingsUpdated: Subject<void> = new Subject<void>();

    constructor() {
        super();
        this.sizes = [
            {
                id: 0,
                label: 'Large',
                cols: 12,
                rows: 8
            },
            {
                id: 1,
                label: 'Huge',
                cols: 16,
                rows: 12
            }
        ];
        this.applySize(0);

        this.resetData();
    }

    public applySettings(v: { [key: string]: any }): void {
        super.applySettings(v);

        this.configured = v.customer && v.location && v.equipment?.length !== 0;

        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }

        this.generatedNameTag = this.configured ? `${v.location.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;

        if (this.configured) {
            if (
                (v.location && v.location.value && this.locationId !== v.location.value) ||
                (v.equipment && v.equipment.length !== this.equipment.length)
            ) {
                this.locationId = v.location.value ? v.location.value : null;
                this.location = v.location && v.location.value ? v.location.label : null;
                this.equipment = [];
                if (v.equipment.length) {
                    v.equipment.forEach((element: any) => {
                        this.equipment.push(element);
                    });
                } else if (v.equipment === undefined) {
                    this.equipment = undefined;
                }
                this.settingsUpdated.next(null);
            }
        }

        this.updateSize();
    }

    public applySize(id: number): void {
        super.applySize(id);
        this.updateSize();
    }

    private updateSize() {
        const h = 0;
        const w = 0;
        this.applySizeExpansion(w, h);
    }

    public resetData(): void {
        this.location = '';
        this.locationId = '';
        this.locations = [];
    }

    public dispose(): void {}
}
