import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

export const GetNotifyProcessHostNames = createAction(
  '[ProcessHostName] Get Notify ProcessHostNames',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetNotifyProcessHostNamesSuccess = createAction(
  '[ProcessHostName] Get Notify ProcessHostNames Success',
  props<{ data: RealTimeNotify }>()
);

export const GetNotifyProcessHostNamesFailure = createAction(
  '[ProcessHostName] Get Notify ProcessHostNames Failure',
  props<{ error: any }>()
);


export const GetProcessHostNames = createAction(
  '[ProcessHostName] Get ProcessHostNames',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetProcessHostNamesSuccess = createAction(
  '[ProcessHostName] Get ProcessHostNames Success',
  props<{ data: any}>()
);

export const GetProcessHostNamesFailure = createAction(
  '[ProcessHostName] Get ProcessHostNames Failure',
  props<{ error: any }>()
);