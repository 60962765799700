import * as ProcessMaintenanceBackupDataActions from '@actions/process-maintenance-backup-data/process-maintenance-backup-data.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService, TimeoutService } from '@services/index';
import { RealTimeNotify } from '@models/index';

@Injectable()
export class ProcessMaintenanceBackupDataEffects {
    constructor(private actions$: Actions, private realtimeGatewayService: RealtimeGatewayService) {}

    getNotifyProcessMaintenanceBackupData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessMaintenanceBackupDataActions.GetNotifyProcessMaintenanceBackupData),
            switchMap(action =>
                this.realtimeGatewayService.observe(action.equipmentId, action.commandTypeId).pipe(
                    catchError(err =>
                        of(
                            ProcessMaintenanceBackupDataActions.GetNotifyProcessMaintenanceBackupDataFailure({
                                error: err
                            })
                        )
                    ),
                    mergeMap((data: RealTimeNotify) => [
                        ProcessMaintenanceBackupDataActions.GetNotifyProcessMaintenanceBackupDataSuccess({ data }),
                        ProcessMaintenanceBackupDataActions.GetProcessMaintenanceBackupData({ ...data })
                    ])
                )
            )
        )
    );

    getProcessAdminBackupData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessMaintenanceBackupDataActions.GetProcessMaintenanceBackupData),
            mergeMap(action =>
                this.realtimeGatewayService.observe(action.equipmentId, action.commandTypeId).pipe(
                    map(data =>
                        ProcessMaintenanceBackupDataActions.GetProcessMaintenanceBackupDataSuccess({
                            data: { data, equipmentId: action.equipmentId }
                        })
                    ),
                    catchError(err =>
                        of(ProcessMaintenanceBackupDataActions.GetProcessMaintenanceBackupDataFailure({ error: err }))
                    )
                )
            )
        )
    );

    getHistoricProcessAdminBackupData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessMaintenanceBackupDataActions.GetHistoricProcesssMaintenanceBackupData),
            switchMap(action =>
                this.realtimeGatewayService
                    .requestHistoricDocument(
                        action.equipmentId,
                        action.commandTypeId,
                        new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                        new Date().toISOString(),
                        20
                    )
                    .pipe(
                        map(data =>
                            ProcessMaintenanceBackupDataActions.GetHistoricProcesssMaintenanceBackupDataSuccess({
                                data: { data, equipmentId: action.equipmentId }
                            })
                        ),
                        catchError(err =>
                            of(
                                ProcessMaintenanceBackupDataActions.GetHistoricProcesssMaintenanceBackupDataFailure({
                                    error: err
                                })
                            )
                        )
                    )
            )
        )
    );
}
