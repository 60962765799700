export class SBCApplicationStatus {
	private _applicationName: string;
	private _status: string;
	private _stateStart: string;

	constructor(applicationName: string, status: string, stateStart: string) {
		this._applicationName = applicationName;
		this._status = status;
		this._stateStart = stateStart;
	}

	public update(applicationName: string, status: string, stateStart: string) {
		this._applicationName = applicationName;
		this._status = status;
		this._stateStart = stateStart;
	}

	public updateFromObject(applicationStatus: SBCApplicationStatus) {
		this._applicationName = applicationStatus.applicationName;
		this._status = applicationStatus.status;
		this._stateStart = applicationStatus.stateStart;
	}

	/**
	 * Getter applicationName
	 * @return {string}
	 */
	public get applicationName(): string {
		return this._applicationName;
	}

	/**
	 * Getter status
	 * @return {string}
	 */
	public get status(): string {
		return this._status;
	}

	/**
	 * Getter stateStart
	 * @return {string}
	 */
	public get stateStart(): string {
		return this._stateStart;
	}

	/**
	 * Setter applicationName
	 * @param {string} value
	 */
	public set applicationName(value: string) {
		this._applicationName = value;
	}

	/**
	 * Setter status
	 * @param {string} value
	 */
	public set status(value: string) {
		this._status = value;
	}

	/**
	 * Setter stateStart
	 * @param {string} value
	 */
	public set stateStart(value: string) {
		this._stateStart = value;
	}
}
