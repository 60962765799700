import {
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingDropdownGrouped,
    DashletSettingCheckbox
} from '@models/index';
import { DashletSettingsService } from '@services/index';

export class DashletSettingsFormTrunkGroupUtilization extends DashletSettingsForm {
    private customer!: DashletSettingDropdown;
    private location!: DashletSettingDropdown;
    private equipment!: DashletSettingDropdownGrouped;
    private trunkGroup!: DashletSettingDropdown;
    private showAsPercentage!: DashletSettingCheckbox;

    constructor(private readonly settingsService: DashletSettingsService) {
        super();

        this.init();
        this.loadCustomers();
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);

        if (this.customer.value) {
            this.loadLocations(this.customer.value);
        }
        if (this.location.value) {
            this.loadEquipment(this.location.value);
            if (this.equipment.value) {
                this.loadTrunkGroups(this.equipment.value);
            }
        }
    }

    private init(): void {
        const group = new DashletSettingsGroup();
        const s: any = (group.settings = []);

        const customer = (this.customer = new DashletSettingDropdown());
        customer.label = 'Customer';
        customer.stateKey = 'customer';
        customer.disabled = true;
        customer.change.subscribe(() => {
            if (!this.location.value) this.clearAndDisable(this.location);
            if (!this.equipment.value) this.clearAndDisable(this.equipment);
            if (!this.trunkGroup.value) this.clearAndDisable(this.trunkGroup);

            this.loadLocations(customer.value!);
        });
        s.push(customer);

        const location = (this.location = new DashletSettingDropdown());
        location.label = 'Location';
        location.stateKey = 'location';
        location.disabled = true;
        location.change.subscribe(() => {
            if (!this.equipment.value) this.clearAndDisable(this.equipment);
            if (!this.trunkGroup.value) this.clearAndDisable(this.trunkGroup);

            this.loadEquipment(location.value!);
        });
        s.push(location);

        const equipment = (this.equipment = new DashletSettingDropdownGrouped());
        equipment.label = 'Equipment';
        equipment.stateKey = 'equipment';
        equipment.disabled = true;
        equipment.change.subscribe(() => {
            if (!this.trunkGroup.value) this.clearAndDisable(this.trunkGroup);

            this.loadTrunkGroups(equipment.value!);
        });
        s.push(equipment);

        const trunk = (this.trunkGroup = new DashletSettingDropdown());
        trunk.label = 'Trunk Group';
        trunk.stateKey = 'trunkGroup';
        trunk.disabled = true;
        s.push(trunk);

        const showAsPercentage = (this.showAsPercentage = new DashletSettingCheckbox());
        showAsPercentage.label = 'Show as Percentage';
        showAsPercentage.stateKey = 'showUtilizationPercentage';
        showAsPercentage.disabled = false;
        s.push(showAsPercentage);

        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);
        this.settingsService.getUserAssociatedCustomers().subscribe(
            customers => {
                this.customer.items = customers;
                this.customer.loadingComplete();
            },
            () => {
                this.customer.loadingComplete();
                this.customer.disabled = true;
                // show error notification here
            }
        );
    }

    private loadLocations(customer: string): void {
        this.enableAndLoading(this.location);
        this.settingsService.getLocations(customer).subscribe(
            locations => {
                this.location.items = locations;
                this.location.loadingComplete();
            },
            () => {
                this.location.loadingComplete();
                this.location.disabled = true;
                // show error notification here
            }
        );
    }

    private loadEquipment(location: string): void {
        this.enableAndLoading(this.equipment);
        Promise.all([
            this.settingsService.getEquipment(location, 'ACM').toPromise(),
            this.settingsService.getEquipment(location, 'IPOffice').toPromise(),
            this.settingsService.getEquipment(location, 'AEP').toPromise()
        ]).then(
            ([acm, ipo, aep]) => {
                const groups = [
                    {
                        label: 'ACM',
                        items: acm
                    },
                    {
                        label: 'IP Office',
                        items: ipo
                    },
                    {
                        label: 'AEP',
                        items: aep
                    }
                ];
                this.equipment.items = groups;
                this.equipment.loadingComplete();
            },
            () => {
                this.equipment.loadingComplete();
                this.equipment.disabled = true;
                // show error notification here
            }
        );
    }

    private loadTrunkGroups(equipment: string): void {
        this.enableAndLoading(this.trunkGroup);
        this.settingsService.getTrunkGroups(equipment).subscribe(
            trunks => {
                this.trunkGroup.items = trunks;
                this.trunkGroup.loadingComplete();
            },
            () => {
                this.trunkGroup.loadingComplete();
                this.trunkGroup.disabled = true;
                // show error notification here
            }
        );
    }

    private clearAndDisable(dropdown: DashletSettingDropdown | DashletSettingDropdownGrouped) {
        dropdown.disabled = true;
        dropdown.items = [];
        dropdown.value = null;
    }

    private enableAndLoading(dropdown: DashletSettingDropdown | DashletSettingDropdownGrouped) {
        dropdown.disabled = false;
        dropdown.loadingBegin();
    }
}
