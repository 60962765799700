export class AlarmExtended {
    vendor!: string;
    site!: string;
    siteId!: number;
    shortAlarmDescription!: string;
    administeredId!: string;
    locationName!: string;
    severity!: number;
    activatedTime: string;
}

export class AlarmSuppressionExtended {
    locationName: string;
    locationId: string;
    equipmentId: string;
    equipmentName: string;
}
