<div class="grid full-height" [ngClass]="{ 'grid-custom-2-columns': expandedView && !dashlet.loadingDocuments }">
    <div class="center grow" *ngIf="dashlet.loadingDocuments; else showTable">
        <ng-container *ngTemplateOutlet="vsmLoadingTemplate"></ng-container>
    </div>
    <ng-template #showTable>
        <div class="flex-column non-expanded-width">
            <ng-container *ngTemplateOutlet="summaryCallStats"></ng-container>
            <ng-container *ngTemplateOutlet="streamQualityGraph"></ng-container>
            <ng-container *ngTemplateOutlet="doughnutCharts"></ng-container>
            <ng-container *ngTemplateOutlet="endpoints"></ng-container>
            <ng-container *ngTemplateOutlet="feedbackRatings"></ng-container>
            <ng-container *ngTemplateOutlet="services"></ng-container>
        </div>
        <div *ngIf="expandedView">
            <ng-container *ngTemplateOutlet="siteStreamQualityGraph"></ng-container>
        </div>
    </ng-template>
</div>

<!-- loading template -->
<ng-template #vsmLoadingTemplate>
    <vsm-dashlet-loading></vsm-dashlet-loading>
</ng-template>

<ng-template #summaryCallStats>
    <div class="justify-space-around">
        <div class="flex gap-m">
            <div class="flex-column equipment-detail-labels">
                <div class="mat-body-2">
                    Stream Records
                </div>
                <div class="mat-body-2">Duration</div>
            </div>
            <div class="flex-column text-no-wrap">
                <div class="mat-body-1">{{ sessionRecordsCount || 'Unknown' }}</div>
                <div class="mat-body-1">{{ sessionDurationCount || 'Unknown' }}</div>
            </div>
        </div>
        <div class="flex gap-m">
            <div class="flex-column equipment-detail-labels">
                <div class="mat-body-2">Unique Participants</div>
                <div class="mat-body-2">Total Participants</div>
            </div>
            <div class="flex-column text-no-wrap">
                <div class="mat-body-1">{{ uniqueParticipants.length || 'Unknown' }}</div>
                <div class="mat-body-1">{{ totalParticipantsCount || 'Unknown' }}</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #endpoints>
    <div class="horizontal-line-graph-section">
        <dashlet-section-header>
            Endpoints
        </dashlet-section-header>
        <div class="flex-column gap-m">
            <dashlet-ratio-bar>
                <ng-container *ngIf="getEndPointKeys(endPointsUsed).length > 0; else noDataTemplate">
                    <ng-container *ngFor="let key of getEndPointKeys(endPointsUsed); let i = index">
                        <dashlet-ratio-bar-item [clickable]="false" [value]="endPointsUsed[key]"
                            [color]="chartColorsArray[i]" [title]="endPointsUsed[key] + ' ' + key">
                        </dashlet-ratio-bar-item>
                    </ng-container>
                </ng-container>
                <ng-template #noDataTemplate>
                    <dashlet-ratio-bar-item [clickable]="false" [value]="0" [color]="'#D3D3D3'"
                        [title]="'No Data Available'">
                    </dashlet-ratio-bar-item>
                </ng-template>
            </dashlet-ratio-bar>
            <div class="justify-space-around">
                <ng-container *ngIf="getEndPointKeys(endPointsUsed).length > 0; else noDataMetricsTemplate">
                    <ng-container *ngFor="let key of getEndPointKeys(endPointsUsed); let i = index">
                        <div class="metric flex-column align-baseline"
                            [ngStyle]="{ 'border-left-color': chartColorsArray[i] }">
                            <div class="mat-caption">{{ formatEndPointKey(key) }}</div>
                            <div class="mat-h2">{{ endPointsUsed[key] }}</div>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-template #noDataMetricsTemplate>
                    <div class="metric flex-column align-baseline">
                        <div class="mat-caption">No Data Available</div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #feedbackRatings>
    <div class="horizontal-line-graph-section">
        <dashlet-section-header>Feedback Ratings</dashlet-section-header>
        <div class="flex-column gap-m">
            <dashlet-ratio-bar class="dashlet-cell">
                <dashlet-ratio-bar-item [clickable]="false" [value]="feedbackRatingCount.Best"
                    [color]="getSeverityColor(0)"
                    [title]="feedbackRatingCount.Best + ' user(s) have given a rating of Excellent or Good'"></dashlet-ratio-bar-item>
                <dashlet-ratio-bar-item [clickable]="false" [value]="feedbackRatingCount.Middle"
                    [color]="getSeverityColor(1)"
                    [title]="feedbackRatingCount.Middle + ' user(s) have given a rating of Fair'"></dashlet-ratio-bar-item>
                <dashlet-ratio-bar-item [clickable]="false" [value]="feedbackRatingCount.Worst"
                    [color]="getSeverityColor(2)"
                    [title]="feedbackRatingCount.Worst + ' user(s) have given a rating of Poor or Bad'"></dashlet-ratio-bar-item>
                <dashlet-ratio-bar-item [clickable]="false" [value]="feedbackRatingCount.NoRating"
                    [color]="getSeverityColor(-1)"
                    [title]="feedbackRatingCount.NoRating + ' user(s) have given Not Rated'"></dashlet-ratio-bar-item>
            </dashlet-ratio-bar>
            <div class="justify-space-between">
                <h3>
                    {{ feedbackRatingCount.Best }}
                    <span class="mat-h3">Excellent/Good</span>
                </h3>
                <h3>
                    {{ feedbackRatingCount.Middle }}
                    <span class="mat-h3">Fair</span>
                </h3>
                <h3>
                    {{ feedbackRatingCount.Worst }}
                    <span class="mat-h3">Poor/Bad</span>
                </h3>
                <h3>
                    {{ feedbackRatingCount.NoRating }}
                    <span class="mat-h3">Not Rated</span>
                </h3>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #doughnutChartTemplate let-chartData="data" let-name="name">
    <!-- can't use *ngIf on the p-charts because they need to exist on page to be initialized properly, and *ngIf makes them not exist at first -->
    <p-chart #systemChart [type]="'doughnut'" [data]="chartData" [options]="options" [plugins]="plugins"></p-chart>
</ng-template>

<ng-template #legendTemplate let-chartData="data" let-name="name">
    <div class="doughnut-legends">
        <div *ngFor="let entry of chartData?.labels; let i = index" class="metric align-baseline justify-space-between"
            [ngStyle]="getStyleForEntry(chartData.datasets[0].backgroundColor[i])">

            <div class="doughnut-legend" *ngIf="['AudioDevices', 'SessionMedia'].includes(name)">
                <div class="mat-body-2 doughnut-legend-label" *ngIf="chartData.datasets[0].data[i]">
                    {{ entry }}
                </div>
                <div class="doughnut-count" *ngIf="chartData.datasets[0].data[i]">
                    {{ chartData.datasets[0].data[i] }}
                </div>
            </div>

            <div class="doughnut-legend" *ngIf="!['AudioDevices', 'SessionMedia'].includes(name)">
                <div class="mat-body-2 doughnut-legend-label">{{ entry }}</div>
                <div class="doughnut-count">
                    {{ chartData.datasets[0].data[i] }}
                </div>
            </div>

        </div>
    </div>
</ng-template>

<ng-template #doughnutCharts>
    <div class=" grid grid-custom-4-columns grid-custom-3-rows">
        <!-- Doughnut chart titles -->
        <div class="mat-caption text-center">Direction</div>
        <div class="mat-caption text-center">Network</div>
        <div class="mat-caption text-center">Audio Device</div>
        <div class="mat-caption text-center ">
            Session Media
        </div>
        <!-- Doughnut chart charts -->
        <ng-container
            *ngTemplateOutlet="doughnutChartTemplate; context: { data: directionChartData, name: 'Direction' }"></ng-container>
        <ng-container
            *ngTemplateOutlet="doughnutChartTemplate; context: { data: networksChartData, name: 'Networks'}"></ng-container>
        <ng-container
            *ngTemplateOutlet="doughnutChartTemplate; context: { data: audioDevicesChartData, name: 'AudioDevices' }"></ng-container>
        <ng-container
            *ngTemplateOutlet="doughnutChartTemplate; context: { data: sessionMediaChartData, name: 'SessionMedia' }"></ng-container>
        <!-- Doughnut chart legends -->
        <ng-container
            *ngTemplateOutlet="legendTemplate; context: { data: directionChartData, name: 'Direction' }"></ng-container>
        <ng-container
            *ngTemplateOutlet="legendTemplate; context: { data: networksChartData, name: 'Networks' }"></ng-container>
        <ng-container
            *ngTemplateOutlet="legendTemplate; context: { data: audioDevicesChartData, name: 'AudioDevices' }"></ng-container>
        <ng-container
            *ngTemplateOutlet="legendTemplate; context: { data: sessionMediaChartData, name: 'SessionMedia' }"></ng-container>
    </div>
</ng-template>

<ng-template #streamQualityGraph>
    <div class="flex gap-l justify-space-between align-center">
        <div class="flex-column grow">
            <div class="mat-body-2">
                Stream Quality
            </div>
            <div class="metric align-baseline success-border justify-space-between">
                <div class="mat-body-2">Good</div>
                <div>{{ streamSummary[selectedCustomSiteName].good }}</div>
            </div>
            <div class="metric align-baseline warning-border justify-space-between">
                <div class="mat-body-2">Satisfactory</div>
                <div>{{ streamSummary[selectedCustomSiteName].satisfactory }}</div>
            </div>
            <div class="metric align-baseline failure-border justify-space-between">
                <div class="mat-body-2">Poor</div>
                <div>{{ streamSummary[selectedCustomSiteName].poor }}</div>
            </div>
        </div>
        <dashlet-line-graph #mainGraph style="height: 200px" [data]="siteStreamData[selectedCustomSiteName]"
            [options]="mainStreamQualityChartOptions" [aspect]="3"></dashlet-line-graph>
    </div>
</ng-template>

<ng-template #siteStreamQualityGraph>
    <div class="site-graphs-container">
        <div tabindex="0" (click)="changeSite(label)" (keydown)="handleKeyDown($event, label)"
            class="align-center site-graph-container"
            [ngClass]="{ 'site-graph-container-selected': label === selectedCustomSiteName }"
            *ngFor="let label of graphLabels" [ngStyle]="!siteStreamData[label] && { pointerEvents: 'none' }">
            <ng-container *ngIf="siteStreamData[label]; else showPending">
                <div class="mat-body-1 text-center">
                    {{ startCase(label) }}
                </div>
                <dashlet-line-graph #subGraph [data]="siteStreamData[label]" width="200" height="75"
                    [options]="smallStreamQualityGraphOptions" [aspect]="1.5"></dashlet-line-graph>
            </ng-container>
            <ng-template #showPending>
                <div class="mat-body-1 text-center">
                    {{ startCase(label) }}
                </div>
                <div class="mat-body-1 text-center pending-data-container">Pending</div>
            </ng-template>
        </div>
        <div *ngIf="!graphLabels.length">
            <div class="align-center pending-data-container">No Custom Site Names Selected</div>
        </div>
    </div>
</ng-template>

<ng-template #services>
    <div *ngIf="!dashlet.loadingDocuments" >
        <div *ngIf="axpServicesStatus.length > 0; else serviceStatusNotAvailable">
            <div *ngIf="axpServicesStatus.length > 3 else stationary">
                <div class="marquee">
                    <div class="marquee-group">
                        <div *ngFor="let service of axpServicesStatus">
                            <div>
                                <span>
                                    {{ startCase(service.Service) }}:
                                </span>
                                <span [ngStyle]="getServiceStatusColour(service.Colour)">
                                    {{service.Status}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="marquee-group">
                        <div *ngFor="let service of axpServicesStatus">
                            <div>
                                <span>
                                    {{ startCase(service.Service) }}:
                                </span>
                                <span [ngStyle]="getServiceStatusColour(service.Colour)">
                                    {{service.Status}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #stationary>
                <div class="flex-row justify-space-evenly">
                    <div *ngFor="let service of axpServicesStatus">
                        <span>
                            {{ startCase(service.Service) }}:
                        </span>
                        <span [ngStyle]="getServiceStatusColour(service.Colour)">
                            {{service.Status}}
                        </span>
                    </div>
                </div>

            </ng-template>

        </div>
    </div>
    <ng-template #serviceStatusNotAvailable>
        <div class="flex-row justify-space-evenly">
            <span><img src="/assets/images/attention.png" style="height: 1em;"> Please check if Avaya AXP Cloud Subscriptions is configured in dashlet settings.</span>
        </div>
    </ng-template>
</ng-template>
