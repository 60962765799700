import { DashletSettingsForm, DashletSettingsGroup, DashletSettingDropdown, DashletSettingDropdownGrouped } from '@models/index';
import { DashletSettingsService } from '@services/index';

export class DashletSettingsFormServiceGatewayInstancesInformation extends DashletSettingsForm {
	constructor(private readonly settingsService: DashletSettingsService) {
		super();

		this.init();
	}

	loadState(obj: { [key: string]: object }): void {
		super.loadState(obj);
	}

	private init(): void {}
}
