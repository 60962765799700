import * as ProcessIISActions from '@actions/process-iisdata/process-iisdata.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService } from '@services/index';
import { RealTimeNotify } from '@models/index';



@Injectable()
export class ProcessIISDataEffects {

  constructor(private actions$: Actions, private realtimeGatewayService: RealtimeGatewayService) { }

  getNotifyProcessIISData$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessIISActions.GetNotifyProcessIISData),
    switchMap((action) => this.realtimeGatewayService.observe(action.equipmentId, action.commandTypeId).pipe(
      catchError((err) => of(
        ProcessIISActions.GetNotifyProcessIISDataFailure({ error: err })
        )
      ),
      mergeMap((data: RealTimeNotify) => [
        ProcessIISActions.GetNotifyProcessIISDataSuccess({ data }),
        ProcessIISActions.GetProcessIISData({ ...data })
      ],
      ),
    ),
    )
  ));

  getProcessIISData$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessIISActions.GetProcessIISData),
    mergeMap((action) => this.realtimeGatewayService.getDocument(action.equipmentId, action.commandTypeId).pipe(
      map((data) => ProcessIISActions.GetProcessIISDataSuccess({ data:{ data, equipmentId: action.equipmentId} }),
      ),
      catchError((err) => of(ProcessIISActions.GetProcessIISDataFailure({ error: err })))
    ))
  ));

}
