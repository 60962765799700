import {
    Customer,
    Location,
    Dashlet,
    Equipment,
    HealthStatus,
    HyperV,
    IisHost,
    LyncMediationServer,
    Nic,
    Service,
    Threshold,
    WorkflowControllerCounter,
    WorkflowEngineCounter
} from '@models/index';
import { DashboardService, DashletService } from '@services/index';
import { Subject, Subscription } from 'rxjs';

export interface SQLData {
    current: number;
    currentString: string;
    peak: number;
    peakString: string;
    label: string;
    expansion: {
        data: { y: number; x: Date }[];
        backgroundColor: string;
        borderColor: string;
        borderWidth: 2;
        fill: false;
        label: string;
        lineTension: 0;
        pointHitRadius: 10;
        pointRadius: 0;
        labels: string[];
    }[];
}

export class DashletSystemHealthWindowsServer extends Dashlet {
    public readonly commandTypeIdUpTime = '0A006835-F704-46F5-B7EE-C046E108985B'; // Uptime
    public readonly commandTypeIdCpuMem = '3608D081-F3D9-46E8-9EA7-199DB319AA86'; // CPU, Memory, Read/Write rates, etc.
    public readonly commandTypeIdService = '984F2784-2E62-41B6-8A2D-26622027C55A'; // Services
    public readonly commandTypeIdDisk = '284E78B0-5831-4F67-966B-CB6EA2315460'; // Disk space
    public readonly commandTypeIdIIS = '277D384F-B042-4E8B-B44E-5677161791BF'; // IIS
    public readonly commandTypeIdHyperV = 'DC44FF54-BE06-452F-B89A-3965AC7EC38D'; // Hyper-V,
    public readonly commandTypeIdLyncMedServer = '601F6CF3-18C7-4033-B813-0481103E4CBA'; // Lync Mediation Server
    public readonly commandTypeIdLyncFrontEndServer = '34E1E7B4-6D23-49B9-A5D6-660BCB36E122'; // Lync Front End Server
    public readonly commandTypeIdFlowController = '1C59361C-0E61-4BA3-89BD-7AF77DD22513'; // Virsae Workflow Flow Controller
    public readonly commandTypeIdWorkflow = 'D136B879-9639-4733-A94A-613C64208DAD'; // Virsae Workflow Activity Engine
    public readonly commandTypeIdWatchedService = '5CDAA2C3-9BD1-464F-B643-695F6BC38156'; // Watched Service State
    public readonly commandTypeIdNicName = '0D8DF852-36CF-4F4B-BE18-E8A8846B0AD7'; // NIC name mapping
    public readonly commandTypeIdHostName = 'DDBE8A1C-56BF-4348-948F-B205E8EC691C'; // host name
    public readonly commandTypeIdTotalMem = 'DFCFEED2-FAAB-4810-9797-71A8245453A7'; // Memory
    public readonly commandTypeIdNetwork = '7BF15776-F312-40C7-92A1-F95DDEAD52EA'; // Network Connectivity
    public readonly commandTypeIdSqlMonitoring = '3235EFEF-199A-4310-8D8F-E5A9944E37D8'; // SQL Monitoring

    readonly interval = 30;
    customer!: Customer;
    serverEquipment!: Equipment;
    locationName!: string;
    location: Location;

    thresholds: Threshold[] = [];

    name!: string | null;
    nics: { [severity: number]: Nic[] } = {};
    nicNamesMapping!: Map<string, string>;
    iisHosts: IisHost[] = [];
    hyperV: HyperV[] = [];
    hyperVHealth!: number;
    hyperVCritical!: number;
    lyncMediationServers: LyncMediationServer[] = [];
    workflowControllerCounters: WorkflowControllerCounter[] = [];
    workflowEngineCounters: WorkflowEngineCounter[] = [];
    showSystemChart = false;
    showModuleDisk = false;
    showModuleNetwork = false;
    showNetworkChart = false;
    showModuleServices = false;
    showModuleIis = false;
    showModuleSql = false;
    showCustomScripts = false;
    networkChartSubscription!: Subscription;
    totalMemory!: number;
    diskDataLost = false;
    equipmentIpAddress = '';
    locationId = '';
    commandsRunData: any = [];
    commandsRunDataLoading = true;

    sqlData: SQLData[] = [];
    public sqlDataUpdated: Subject<any> = new Subject();
    public settingsUpdated: Subject<any> = new Subject();
    public unSub: Subject<any> = new Subject();

    public chartDataUpdated: Subject<null> = new Subject();
    get onChartDataUpdated() {
        return this.chartDataUpdated;
    }

    constructor(private dashboardService: DashboardService, private dashletService: DashletService) {
        super();

        // sizing
        this.sizes = [
            {
                id: 0,
                label: 'Small',
                cols: 5,
                rows: 4
            },
            {
                id: 1,
                label: 'Medium',
                cols: 5,
                rows: 6
            }
        ];
        this.applySize(1);

        // init data
        this.resetData();

        // produce data every interval
        this.dashboardService.getUpdateInterval(this.interval).subscribe(time => {
            this.produceNetworkInterfaceChartData(time);
            this.chartDataUpdated.next(null);
        });
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        // read settings object
        this.configured = v.customer && v.location && v.equipment;
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
            // this.loadCustomerLogo();
        } else {
            this.customer = new Customer('', '');
        }
        this.unSub.next(null);
        if (v.equipment) {
            this.serverEquipment = new Equipment(v.equipment.value, v.equipment.label);
        } else {
            this.serverEquipment = new Equipment('', '');
        }
        if (v.location) {
            this.locationName = v.location.label;
            this.locationId = v.location.value;
            this.location = v.location;
        }
        if (v.equipment) {
            if (v.equipment.host) {
                this.equipmentIpAddress = v.equipment.host;
            }
        }

        this.generatedNameTag = this.configured ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;

        this.showSystemChart = v.showSystemChart || false;
        this.showModuleDisk = true;
        this.showModuleNetwork = v.showModuleNetwork || false;
        this.showNetworkChart = v.showNetworkChart || false;
        this.showModuleServices = v.showModuleServices || false;
        this.showModuleIis = v.showModuleIis || false;
        this.showModuleSql = v.showSql || false;
        this.showCustomScripts = v.showCustomScripts || false;
        // apply size
        this.updateSize();
        this.settingsUpdated.next(null);
        this.sqlDataUpdated.next(null);

        // sub realtime feed
        // this.subData();
    }

    applySize(id: number) {
        super.applySize(id);
        this.updateSize();
    }

    resetData() {
        this.name = null;
        this.nics = { 0: [], 2: [] };
        this.nicNamesMapping = new Map();
        this.iisHosts = [];
        this.hyperV = [];
        this.hyperVHealth = 0;
        this.hyperVCritical = 0;
        this.totalMemory = 0;
        this.lyncMediationServers = [];
        this.workflowControllerCounters = [];
        this.workflowEngineCounters = this.workflowControllerCounters; // [];
        this.sqlData = [];
    }

    dispose() {
        this.settingsUpdated.next(null);
        this.settingsUpdated.complete();
        this.sqlDataUpdated.next(null);
        this.sqlDataUpdated.complete();
        this.unSub.next(null);
        this.unSub.complete();
    }

    private updateSize() {
        let h = 0;
        if (this.getSize().id === 1) {
            // system
            if (this.showSystemChart) {
                h += 0.9;
            }

            // network interface
            if (this.showModuleNetwork) {
                h += 1;
            }
            // network connectivity data
            // if(this.showNetworkConnectivitySection()) {
            // network chart
            if (this.showNetworkChart) {
                h += 2;
            }
            // else
            //     h += 1;
            // } else {
            //     if(this.showNetworkChart)
            //         h += 1;

            // }

            // services
            if (this.showModuleServices) {
                h += 1.1;
            }
            // iis
            if (this.showModuleIis) {
                h += 1.1;
            }

            if (this.showModuleSql) {
                h += 4;
            }
            if (this.showCustomScripts) {
                h += 4;
            }
        }
        this.applySizeExpansion(0, h);
    }

    processSqlData(data: any) {
        const row = data[0];
        const timeStamp = new Date(row.timestamp);
        const newData = this.sqlData && this.sqlData.length ? [...this.sqlData] : [];

        if (row && row.data) {
            row.data.forEach((d: any) => {
                const record: SQLData = {
                    current: +d.value,
                    currentString: +d.value + (d.counterName === 'Buffer cache hit ratio' ? '%' : ''),
                    peak: 0,
                    peakString: '',
                    label: d.counterName,
                    expansion: [
                        {
                            data: [{ y: +d.value, x: timeStamp }],
                            label: d.counterName,
                            backgroundColor: this.dashletService.getChartColors().blue,
                            borderColor: this.dashletService.getChartColors().blue,
                            borderWidth: 2,
                            fill: false,
                            lineTension: 0,
                            pointHitRadius: 10,
                            pointRadius: 0,
                            labels: ['']
                        }
                    ]
                };

                const parentRecord = newData.filter(s => s.label === record.label);
                if (parentRecord[0]) {
                    parentRecord[0].expansion[0].data.push({ y: +d.value, x: timeStamp });
                    parentRecord[0].expansion[0].labels.push('');

                    // Keep only latest 60 data points
                    if (parentRecord[0].expansion[0].data.length > 60) {
                        parentRecord[0].expansion[0].data.shift();
                        parentRecord[0].expansion[0].labels.shift();
                    }

                    // Determine peak value
                    let peak = parentRecord[0].expansion[0].data.reduce(
                        (max, e) => (e.y > max ? e.y : max),
                        parentRecord[0].expansion[0].data[0].y
                    );
                    parentRecord[0].peak = peak;
                    let peakString = (Math.round((peak + Number.EPSILON) * 100) / 100).toString(); // Rounds to 2dp
                    if (d.counterName === 'Buffer cache hit ratio') {
                        peakString += '%';
                    } else if (d.counterName === 'Page life expectancy') {
                        peakString = this.formatPageLifeExpectancy(+peak);
                    }
                    parentRecord[0].peakString = peakString;

                    // Set current values to the latest data point
                    const currentDataPoint =
                        parentRecord[0].expansion[0].data[parentRecord[0].expansion[0].data.length - 1];
                    parentRecord[0].current = currentDataPoint.y;
                    let currentString = (Math.round((currentDataPoint.y + Number.EPSILON) * 100) / 100).toString();
                    if (d.counterName === 'Buffer cache hit ratio') {
                        currentString += '%';
                    } else if (d.counterName === 'Page life expectancy') {
                        currentString = this.formatPageLifeExpectancy(+currentDataPoint.y);
                    }
                    parentRecord[0].currentString = currentString;
                } else {
                    newData.push(record);
                }
            });
        }
        this.sqlData = newData;
        this.sqlDataUpdated.next(null);
    }

    private formatPageLifeExpectancy(value: number) {
        if (value === 0) {
            return '0s';
        }
        if (value <= 300) {
            return value + 's';
        } else if (value > 300 && value <= 7200) {
            return Math.round((value / 60 + Number.EPSILON) * 10) / 10 + ' mins'; // rounds to 1dp
        } else {
            return Math.round((value / 3600 + Number.EPSILON) * 10) / 10 + ' hours'; // rounds to 1dp
        }
    }

    public processNicNames(data: any) {
        const row = data[0].data;
        if (row) {
            const nicNamesMapping = new Map();
            const reg = /[!@#$%^&*\-()[\]{}+=?/]/g;
            for (const rowData of row) {
                nicNamesMapping.set(rowData.Description.replace(reg, '_'), rowData.Interface);
            }
            this.nicNamesMapping = nicNamesMapping;
        }
    }

    public processWorkflowData(data: any) {
        const row = data[0];
        if (row !== null) {
            const rowData: any[] = row.data;
            for (const counter of rowData) {
                if (counter.categoryName !== 'Virsae Workflow Activity Engine') {
                    return;
                }
                const v = counter.value;
                let color: string;
                switch (counter.counterName.Trim().ToLower()) {
                    case 'activity processing threads':
                        if (v >= 40) {
                            color = 'Red';
                        } else if (v >= 25 && v < 40) {
                            color = 'Yellow';
                        } else {
                            color = 'Green';
                        }
                        break;

                    case 'activities completed':
                        if (v >= 40) {
                            color = 'Red';
                        } else if (v >= 20 && v < 40) {
                            color = 'Yellow';
                        } else {
                            color = 'Green';
                        }
                        break;

                    case 'activities started':
                    case 'azure blob access':
                    case 'azure queue access':
                    case 'workflow database access':
                        if (v >= 50) {
                            color = 'Red';
                        } else if (v >= 20 && v < 50) {
                            color = 'Yellow';
                        } else {
                            color = 'Green';
                        }
                        break;
                    case 'customer database access':
                        if (v >= 150) {
                            color = 'Red';
                        } else if (v >= 50 && v < 150) {
                            color = 'Yellow';
                        } else {
                            color = 'Green';
                        }
                        break;
                    case 'root database access':
                        if (v >= 30) {
                            color = 'Red';
                        } else if (v >= 10 && v < 30) {
                            color = 'Yellow';
                        } else {
                            color = 'Green';
                        }
                        break;
                }
                this.workflowEngineCounters.push({
                    name: counter.instanceName,
                    value: v
                });
            }
        }
    }

    public processFlowControllerData(data: any) {
        const row = data[0];
        if (row !== null) {
            const rowData: any[] = row.data;
            for (const counter of rowData) {
                if (counter.categoryName !== 'Virsae Workflow Flow Controller') {
                    return;
                }
                const v = counter.value;
                let color: string;
                switch (counter.counterName.Trim().ToLower()) {
                    case 'active workflows':
                        if (v >= 600) {
                            color = 'Red';
                        } else if (v >= 300 && v < 600) {
                            color = 'Yellow';
                        } else {
                            color = 'Green';
                        }
                        break;
                    case 'workflows currently processing':
                    case 'azure blob access':
                    case 'azure queue access':
                    case 'workflows completed':
                    case 'workflow database access':
                        if (v >= 50) {
                            color = 'Red';
                        } else if (v >= 20 && v < 50) {
                            color = 'Yellow';
                        } else {
                            color = 'Green';
                        }
                        break;

                    case 'workflows started':
                        if (v >= 30) {
                            color = 'Red';
                        } else if (v >= 10 && v < 30) {
                            color = 'Yellow';
                        } else {
                            color = 'Green';
                        }
                        break;
                    case 'azure high priority results queue length':
                    case 'azure high priority requests queue length':
                        if (v >= 20) {
                            color = 'Red';
                        } else if (v >= 10 && v < 20) {
                            color = 'Yellow';
                        } else {
                            color = 'Green';
                        }
                        break;
                    case 'azure medium priority results queue length':
                    case 'azure medium priority requests queue length':
                        if (v >= 300) {
                            color = 'Red';
                        } else if (v >= 100 && v < 300) {
                            color = 'Yellow';
                        } else {
                            color = 'Green';
                        }
                        break;
                    case 'azure low priority results queue length':
                    case 'azure low priority requests queue length':
                        if (v >= 500) {
                            color = 'Red';
                        } else if (v >= 200 && v < 500) {
                            color = 'Yellow';
                        } else {
                            color = 'Green';
                        }
                        break;

                    case 'azure requests queue length':
                    case 'azure results queue length':
                        if (v >= 800) {
                            color = 'Red';
                        } else if (v >= 300 && v < 800) {
                            color = 'Yellow';
                        } else {
                            color = 'Green';
                        }
                }
                this.workflowControllerCounters.push({
                    name: counter.instanceName,
                    value: v
                });
            }
        }
    }
    public processLyncFrontEndServerData(data: any) {
        const row = data[0];
        if (row !== null) {
            const supportedCategories = [];
            supportedCategories.push('LS:Usrv - DBStore');
            supportedCategories.push('LS:Usrv - REGDBStore');
            supportedCategories.push('LS:Usrv - SharedDBStore');
            supportedCategories.push('LS:SIP - Authentication');
            supportedCategories.push('LS:SIP - Load Management');
            supportedCategories.push('LS:SIP - Peers');
            supportedCategories.push('LS:SIP - Protocol');
            supportedCategories.push('LS:SIP - Responses');
            supportedCategories.push('LS:RoutingApps - Inter Cluster Routing');
            supportedCategories.push('LS:LYSS - Storage Service API');
            supportedCategories.push('LS:Usrv - Cluster Manager');
            supportedCategories.push('LS:XMPPFederation - SIP Instant Messaging');
            supportedCategories.push('LS:RoutingApps - Emergency Call Routing');
            const rowData: any[] = row.data;
            for (const counter of rowData) {
                if (supportedCategories.indexOf(counter.categoryName)) {
                    let status = HealthStatus.Undefined;
                    switch (counter.counterName) {
                        case 'Usrv - Queue Latency (msec)':
                            status = counter.Value < 100 ? HealthStatus.Normal : HealthStatus.Critical;
                            break;
                        case 'Usrv - Sproc Latency (msec)':
                            status = counter.Value < 100 ? HealthStatus.Normal : HealthStatus.Critical;
                            break;
                        case 'Usrv - Throttled requests/sec':
                            status = counter.Value === 0 ? HealthStatus.Normal : HealthStatus.Critical;
                            break;
                        case 'Authentication System Errors/sec':
                            status = counter.Value < 1 ? HealthStatus.Normal : HealthStatus.Critical;
                            break;
                        case 'SIP - Average Holding Time For Incoming Messages':
                            status = counter.Value < 1 ? HealthStatus.Normal : HealthStatus.Critical;
                            break;
                        case 'SIP - Incoming Messages Timed out':
                            status = counter.Value < 2 ? HealthStatus.Normal : HealthStatus.Critical;
                            break;
                        case 'SIP - Average outgoing Queue Delay':
                            status = counter.Value < 2 ? HealthStatus.Normal : HealthStatus.Critical;
                            break;
                        case 'SIP - Flow-controlled Connections':
                            status = counter.Value < 2 ? HealthStatus.Normal : HealthStatus.Critical;
                            break;
                        case 'SIP - Sends Timed-Out/sec':
                            status = counter.Value === 0 ? HealthStatus.Normal : HealthStatus.Critical;
                            break;
                        case 'SIP - Average Incoming Message Processing Time':
                            status = counter.Value < 1 ? HealthStatus.Normal : HealthStatus.Critical;
                            break;
                        case 'SIP - Incoming Requests Dropped/sec':
                            status = counter.Value < 1 ? HealthStatus.Normal : HealthStatus.Critical;
                            break;
                        case 'SIP - Incoming Responses Dropped/sec':
                            status = counter.Value < 1 ? HealthStatus.Normal : HealthStatus.Critical;
                            break;
                        case 'SIP - Local 503 Responses/sec':
                            status = counter.Value === 0 ? HealthStatus.Normal : HealthStatus.Critical;
                            break;
                        case 'RoutingApps - Number of primary registrar timeouts':
                            status = counter.Value === 0 ? HealthStatus.Normal : HealthStatus.Critical;
                            break;
                        case 'RoutingApps - Number of backup registrar timeouts':
                            status = counter.Value === 0 ? HealthStatus.Normal : HealthStatus.Critical;
                            break;
                        case 'LYSS - Current percentage of space used by Storage Service DB.':
                            status = counter.Value < 80 ? HealthStatus.Normal : HealthStatus.Critical;
                            break;
                        case 'LYSS - Current number of Storage Service stale queue items':
                            status = counter.Value === 0 ? HealthStatus.Normal : HealthStatus.Critical;
                            break;
                        case 'Usrv - Number of data loss events with state change':
                            status = counter.Value === 0 ? HealthStatus.Normal : HealthStatus.Critical;
                            break;
                        case 'Usrv - Number of data loss events without state change':
                            status = counter.Value === 0 ? HealthStatus.Normal : HealthStatus.Critical;
                            break;
                        case 'Usrv - Number of failures of replication operations sent to other Replicas per second':
                            status = counter.Value === 0 ? HealthStatus.Normal : HealthStatus.Critical;
                            break;
                        case 'Usrv - Wheather server is connected to fabric pool manager':
                            status = counter.Value === 0 ? HealthStatus.Normal : HealthStatus.Critical;
                            break;
                        case 'XMPPFederation - Failure IMDNs sent/sec':
                            status = counter.Value === 0 ? HealthStatus.Normal : HealthStatus.Critical;
                            break;
                        case 'RoutingApps - Number of incoming failure responses':
                            status = counter.Value === 0 ? HealthStatus.Normal : HealthStatus.Critical;
                            break;
                    }
                }
            }
        }
    }
    public processLyncMedServerData(data: any) {
        const row = data[0];
        if (row !== null) {
            const supportedCategories = [];
            supportedCategories.push('LS:MediationServer - Global Counters');
            supportedCategories.push('LS:MediationServer - Inbound Calls');
            supportedCategories.push('LS:MediationServer - Outbound Calls');
            supportedCategories.push('LS:MediationServer - Health Indices');
            supportedCategories.push('LS:MediationServer - Media Relay');
            supportedCategories.push('Global Per Gateway Counters');
            const rowData: any[] = row.data;
            const lyncMedServerMap = new Map();
            for (const counter of rowData) {
                if (supportedCategories.indexOf(counter.categoryName)) {
                    const inbound = null;
                    const outbound = null;
                    // var tgrp = new LyncTrunkGroupModel();
                    if (counter.instanceName !== null) {
                        if (!lyncMedServerMap.has(counter.instanceName)) {
                            lyncMedServerMap.set(counter.instanceName, {
                                name: counter.instanceName,
                                inbound: inbound,
                                outbound: outbound
                            });
                        }
                    }
                    const status = HealthStatus.Undefined;
                    switch (counter.counterName) {
                        case '- Current':
                            switch (counter.categoryName) {
                                case 'LS:MediationServer - Inbound Calls':
                                    // inbound = counter.value;
                                    lyncMedServerMap.get(counter.instanceName).inbound = counter.value;
                                    break;
                                case 'LS:MediationServer - Outbound Calls':
                                    // outbound = counter.value;
                                    lyncMedServerMap.get(counter.instanceName).outbound = counter.value;
                                    break;
                            }
                            break;
                    }
                }
            }
            lyncMedServerMap.forEach(element => {
                const result = this.lyncMediationServers.find(
                    lyncMediationServer => lyncMediationServer.name === element.name
                );
                if (result) {
                    result.inbound = element.inbound;
                    result.outbound = element.outbound;
                } else {
                    this.lyncMediationServers.push({
                        name: element.name,
                        inbound: element.inbound,
                        outbound: element.outbound
                    });
                }
            });
        }
    }
    public processHyperVData(data: any) {
        const row = data[1];
        if (row !== null) {
            const rowData: any[] = row.data;
            for (const counter of rowData) {
                let vmName: any = null;
                let hyperVInstance = {
                    name: '',
                    host: 0,
                    guest: 0,
                    hostSum: 0,
                    hostCount: 0,
                    guestSum: 0,
                    guestCount: 0
                };
                switch (counter.categoryName) {
                    case 'Virtual Machine Health Summary':
                        break;
                    case 'Hyper-V Hypervisor Virtual Processor':
                        if (counter.instanceName === '_Total') {
                            break;
                        }
                        vmName = counter.instanceName.split(':')[0];
                        break;
                    case 'Hyper-V Hypervisor Logical Processor':
                        if (counter.instanceName === 'Total') {
                            break;
                        }
                        vmName = counter.instanceName;
                        break;
                    default:
                        break;
                }

                if (vmName !== null) {
                    const result = this.hyperV.find(hyperVData => hyperVData.name === vmName);
                    if (result) {
                        hyperVInstance = result;
                    } else {
                        hyperVInstance.name = vmName;
                        this.hyperV.push(hyperVInstance);
                    }
                }

                switch (counter.counterName) {
                    case 'Health OK':
                        this.hyperVHealth = counter.value;
                        break;
                    case 'Health Critical':
                        this.hyperVCritical = counter.value;
                        break;
                    case '% Hypervisor Run Time':
                        hyperVInstance.hostSum += +counter.value;
                        hyperVInstance.host =
                            Math.round((hyperVInstance.hostSum / ++hyperVInstance.hostCount) * 100) / 100;
                        break;
                    case '% Guest Run Time':
                        hyperVInstance.guestSum += +counter.value;
                        hyperVInstance.guest =
                            Math.round((hyperVInstance.guestSum / ++hyperVInstance.guestCount) * 100) / 100;
                        break;
                    default:
                        break;
                }
            }
        }
    }
    public processIISData(data: any) {
        const row = data[1];
        if (row !== null) {
            const rowData: any[] = row.data;
            const iisHostsMap = new Map();
            for (const counter of rowData) {
                if (counter.categoryName === 'Web Service') {
                    const connections = null;
                    const getRequestRate = null;
                    const postRequestRate = null;
                    // var host = new IisHostModel();
                    if (counter.instanceName !== '_Total') {
                        if (!iisHostsMap.has(counter.instanceName)) {
                            iisHostsMap.set(counter.instanceName, {
                                name: counter.instanceName,
                                connections: connections,
                                getPerSec: getRequestRate,
                                postPerSec: postRequestRate
                            });
                        }
                        switch (counter.counterName) {
                            case 'Current Connections':
                                // connections = counter.value;
                                iisHostsMap.get(counter.instanceName).connections = counter.value;
                                break;
                            case 'Get Requests/sec':
                                // getRequestRate = counter.value;
                                iisHostsMap.get(counter.instanceName).getPerSec = counter.value;
                                break;
                            case 'Post Requests/sec':
                                // postRequestRate = counter.value;npm
                                iisHostsMap.get(counter.instanceName).postPerSec = counter.value;
                                break;
                        }
                    }
                }
            }
            iisHostsMap.forEach(element => {
                const result = this.iisHosts.find(iisHost => iisHost.name === element.name);
                if (result) {
                    result.connections = element.connections;
                    result.getPerSec = element.getPerSec;
                    result.postPerSec = element.postPerSec;
                } else {
                    this.iisHosts.push({
                        name: element.name,
                        connections: element.connections,
                        getPerSec: element.getPerSec,
                        postPerSec: element.postPerSec
                    });
                }
            });
        }
    }

    public sortNICSByDate() {
        const nics = this.nics[0].concat(this.nics[2]);
        for (const nic of nics) {
            nic.dataPoints.sort(function (a, b) {
                return +a.x - +b.x;
            });
        }
    }

    public produceNetworkInterfaceChartData(time: Date): void {
        const nics = this.nics[0].concat(this.nics[2]);
        for (const nic of nics) {
            if (nic.up === null || nic.down === null) {
                return;
            }

            const point = {
                down: nic.down,
                up: nic.up,
                x: time,
                y: nic.down + nic.up
            };

            // create points
            nic.dataPoints.push(point);

            // trim old data
            if (nic.dataPoints.length > 20) {
                nic.dataPoints.shift();
            }
        }
    }
}
