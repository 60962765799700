import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

export const GetNotifyProcessSqlData = createAction(
  '[ProcessSqlData] Get ProcessSqlDatas',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetNotifyProcessSqlDataSuccess = createAction(
  '[ProcessSqlData] Get ProcessSqlDatas Success',
  props<{ data: RealTimeNotify }>()
);

export const GetNotifyProcessSqlDataFailure = createAction(
  '[ProcessSqlData] Get ProcessSqlDatas Failure',
  props<{ error: any }>()
);

export const GetProcessSqlData = createAction(
  '[ProcessSqlData] Get ProcessSqlDatas',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetProcessSqlDataSuccess = createAction(
  '[ProcessSqlData] Get ProcessSqlDatas Success',
  props<{ data: any}>()
);

export const GetProcessSqlDataFailure = createAction(
  '[ProcessSqlData] Get ProcessSqlDatas Failure',
  props<{ error: any }>()
);

// timer
export const GetProcessSqlDataTimer = createAction(
  '[ProcessSqlData] Get ProcessSqlData Timer',
  props<{ equipmentId: string, command: string }>()
);

export const GetProcessSqlDataTimerSuccess = createAction(
  '[ProcessSqlData] Get ProcessSqlData Timer Success',
  props<{ data:any }>() 
);

export const GetProcessSqlDataTimerFailure = createAction(
  '[ProcessSqlData] Get ProcessSqlData Timer Failure',
  props<{ error: any }>()
);

// Historic data
export const GetHistoricSqlData = createAction(
  '[ProcessSqlData] Get HistoricSqlData',
  props<{ equipmentId: string, commandTypeId: string, from?: Date | string, to?: Date | string, max?: number | null }>()
);

export const GetHistoricSqlDataSuccess = createAction(
  '[ProcessSqlData] Get HistoricSqlData Success',
  props<{ data:any }>()
);

export const GetHistoricSqlDataFailure = createAction(
  '[ProcessSqlData] Get HistoricSqlData Failure',
  props<{ error: any }>()
);

