<div class="flex-column gap-l">
    <span class="mat-body-2">{{ dashlet.trunkGroupName }}</span>
    <div class="flex gap-m size">
        <div class="flex-column grow justify-space-around">
            <div [ngStyle]="{ 'border-left-color': channelsUsedColor }" class="flex-column gap-s mat-caption metric">
                <div>In Use</div>
                <div class="mat-h2">{{ dashlet.channelsUsedCurrent ?? '---' }}</div>
            </div>
            <div [ngStyle]="{ 'border-left-color': channelsCapacityColor }" class="flex-column gap-s mat-caption metric">
                <div>Capacity</div>
                <div class="mat-h2">{{ dashlet.channelsCapacityCurrent ?? '---' }}</div>
            </div>
        </div>
        <dashlet-line-graph
            #chart
            *ngIf="!dashlet.showUtilizationPercentage"
            [data]="lineData"
            [options]="chartOptions"
            [convertToUserTimezone]=false
            height="95px"
            width="240px"
        ></dashlet-line-graph>
        <dashlet-line-graph
            #utilizationChart
            *ngIf="dashlet.showUtilizationPercentage"
            [data]="utilizationLineData"
            [options]="utilizationChartOptions"
            [convertToUserTimezone]=false
            height="95px"
            width="240px"
        ></dashlet-line-graph>
    </div>
</div>
