import { Point } from '../interfaces/Interfaces';
import { Regex } from '../../shared/SharedMethods';

export interface Disk {
    name: string;
    mountedOn: string;
    totalSpace: number;
    totalUsed: number;
    percentageUsed: number;
    id?: string;
}

export class DiskUsageData {
    private _diskName: string;
    private _diskUsageHistory: Point[];
    id?: string;
    commandTypeId: string;
    thresholdsNumber: number;
    diskDisplayName: string;
    mountedOn!: string;
    dataExpired!: boolean;
    totalSpaceKB!: number;
    usedSpaceKB!: number;

    /**
     *
     * @param diskName
     * @param diskUsage
     */
    constructor(diskName: string, commandTypeId: string) {
        this._diskName = diskName;
        this._diskUsageHistory = [];
        this.commandTypeId = commandTypeId;
        this.thresholdsNumber = 0;
        this.dataExpired = false;

        if (this._diskName.length > 6) {
            this.diskDisplayName = this._diskName.slice(0, 3).concat('...');
        } else {
            this.diskDisplayName = this._diskName;
        }
    }

    public getCommandTypeId() {
        return this.commandTypeId;
    }

    public updateDiskUsageHistory(time: Date, diskData: Disk) {
        if (Regex.stringIsNumber(diskData.percentageUsed.toString())) {
            if (this._diskUsageHistory.length > 20) {
                this._diskUsageHistory.shift();
            }
            this._diskUsageHistory.push({
                x: time,
                y: +(Number.parseFloat(diskData.percentageUsed.toString()) * 100).toPrecision(2)
            });
            this.usedSpaceKB = +diskData.totalUsed;
            this.totalSpaceKB = +diskData.totalSpace;
        }
    }

    public refreshDiskUsageData() {
        const disk: Disk = {
            percentageUsed: Number(this.getCurrentDiskUsageProportion()),
            totalSpace: this.totalSpaceKB,
            totalUsed: this.usedSpaceKB,
            name: this.diskName,
            mountedOn: this.mountedOn
        };
        this.updateDiskUsageHistory(new Date(), disk);
    }

    public getCurrentDiskUsageProportion() {
        if (this._diskUsageHistory.length > 0) {
            return (this._diskUsageHistory[this._diskUsageHistory.length - 1].y / 100).toString();
        } else {
            return '---';
        }
    }

    public getCurrentDiskUsagePercentage() {
        if (this._diskUsageHistory.length > 0 && !this.dataExpired) {
            return this._diskUsageHistory[this._diskUsageHistory.length - 1].y.toString();
        } else {
            return '---';
        }
    }

    getDiskFree() {
        const free = this.totalSpaceKB - this.usedSpaceKB;
        return this.convertUnits(free);
    }

    getWindowsHoverSpaceFree() {
        const percentageProportion = this._diskUsageHistory[this._diskUsageHistory.length - 1].y / 100;
        const z = 1 / percentageProportion;
        const answer = z * this.usedSpaceKB;
        const total = this.convertUnits(answer);
        const fraction = this.convertUnits(this.usedSpaceKB);
        return `${fraction}/${total} free`;
    }

    getDiskTotal() {
        return this.convertUnits(this.totalSpaceKB);
    }

    convertUnits(size: number): string {
        if (size < 1) {
            return '<1 KB';
        }
        if (size / 1024 < 1) {
            // KB
            return Math.round(size) + ' KB';
        }
        if (size / 1048576 < 1) {
            // MB
            return Math.round(size / 1024) + ' MB';
        }
        if (size / 1073741824 < 1) {
            // GB
            return Math.round(size / 1048576) + ' GB';
        }
        if (size / 1073741824 > 1) {
            return Math.round(size / 1073741824) + ' TB';
        }
        return '';
    }

    /**
     *
     */
    public getDiskUsageSeverity() {
        const diskUsageProportion = this.getCurrentDiskUsageProportion();
        if (Regex.stringIsNumber(diskUsageProportion)) {
            if (this.dataExpired) {
                return -1;
            } else if (+diskUsageProportion < 0.8) {
                return 0;
            } else if (+diskUsageProportion < 0.9) {
                return 1;
            } else {
                return 2;
            }
        }
        return;
    }

    /**
     * Getter diskName
     * @return {string}
     */
    public get diskName(): string {
        return this._diskName;
    }
    /**
     * Getter diskUsageHistory
     * @return {[]}
     */
    public get diskUsageHistory(): Point[] {
        return this._diskUsageHistory;
    }

    /**
     * Setter diskName
     * @param {string} value
     */
    public set diskName(value: string) {
        this._diskName = value;
    }
}
