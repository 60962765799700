import * as GetThresholdsBySettingsActions from '@actions/get-thresholds-by-settings/get-thresholds-by-settings.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { ThresholdService } from '@services/index';
import { Threshold } from '@models/index';


@Injectable()
export class GetThresholdsBySettingsEffects {

  constructor(private actions$: Actions, private thresholdService: ThresholdService,) { }
  getTypeEquipmentLocations$ = createEffect(() => this.actions$.pipe(
    ofType(GetThresholdsBySettingsActions.GetThresholdsBySettings),
    switchMap((action) => this.thresholdService.getThresholdsBySettings(action.commandTypeId, action.customerId, action.equipmentId, action.locationId).pipe(
      catchError((err) => of(
        GetThresholdsBySettingsActions.GetThresholdsBySettingsFailure({ error: err })
      )
      ),
      mergeMap((data: Threshold[]) => [
        GetThresholdsBySettingsActions.GetThresholdsBySettingsSuccess({ data }),
      ],
      ),
    ),
    )
  ));

}
