import * as ProcessHyperVDataActions from '@actions/process-hyper-vdata/process-hyper-vdata.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService } from '@services/index';
import { RealTimeNotify } from '@models/index';



@Injectable()
export class ProcessHyperVDataEffects {

  constructor(private actions$: Actions, private realtimeGatewayService: RealtimeGatewayService) { }

  getNotifyProcessHyperVData$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessHyperVDataActions.GetNotifyProcessHyperVData),
    switchMap((action) => this.realtimeGatewayService.observe(action.equipmentId, action.commandTypeId).pipe(
      catchError((err) => of(
        ProcessHyperVDataActions.GetNotifyProcessHyperVDataFailure({ error: err })
        )
      ),
      mergeMap((data: RealTimeNotify) => [
        ProcessHyperVDataActions.GetNotifyProcessHyperVDataSuccess({ data }),
        ProcessHyperVDataActions.GetProcessHyperVData({ ...data })
      ],
      ),
    ),
    )
  ));

  getProcessHyperVData$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessHyperVDataActions.GetProcessHyperVData),
    mergeMap((action) => this.realtimeGatewayService.getDocument(action.equipmentId, action.commandTypeId).pipe(
      map((data) => ProcessHyperVDataActions.GetProcessHyperVDataSuccess({ data:{ data, equipmentId: action.equipmentId} }),
      ),
      catchError((err) => of(ProcessHyperVDataActions.GetProcessHyperVDataFailure({ error: err })))
    ))
  ));

}
