<div class="flex-column gap-l">
    <div class="flex gap-m">
        <div class="flex-column text-align-right equipment-detail-labels">
            <div class="mat-body-2">Total Servers</div>
            <div class="mat-body-2" matTooltip="Total availability over the past 30 days">Total Availability</div>
            <div class="mat-body-2">Longest Outage</div>
            <div class="mat-body-2">Average Response Time</div>
        </div>
        <div class="flex-column equipment-detail-values">
            <div class="mat-body-1">{{ totalServers|| '---' }}</div>
            <div class="mat-body-1">{{ totalAvgAvailability.toFixed(2) + '%' || '---' }}</div>
            <div
                class="mat-body-1"
                [matTooltip]="longestOutageText !== 'No outages' ? 'View detailed 30 connectivity report for this equipment' : ''"
                (click)="longestOutageText !== 'No outages' ? openNetworkConnectivityReport() : null"
            >
                {{ longestOutageText || '---' }}
            </div>
            <div class="mat-body-1">{{ totalAveragePing ? totalAveragePing + ' ms' : '---' }}</div>
        </div>
    </div>
</div>

<dashlet-table
    [columns]="columns"
    [dataSource]="servers$ | async"
    [tableHeightRows]="this.dashlet.getSize().id === 0 ? 16 : 28"
    [menuItems]="menuItems"
    [loading]="loading"
    sortDirection="desc"
    [doCheck]="true"
    defaultSort="severity"
>
</dashlet-table>
