import * as Actions from '@actions/index';
import { AccountService, DashletService, ThresholdService, TileGridService, TimeoutService } from '@services/index';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CustomerLogo, DashletSystemHealthASM, ServiceStatus, SIPEntityStatus, Threshold } from '@models/index';
import { MenuItem } from 'primeng/api';
import { ThresholdItemComponent } from '../threshold-item/threshold-item.component';
import { select, Store } from '@ngrx/store';
import { AppState, selectEntity, selectDataFromCommonEntity } from '@reducers/index';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { DashletSectionProcessorMemoryComponent } from '../dashlet-section-processor-memory/dashlet-section-processor-memory.component';
import { DashletSectionServicesComponent } from '../dashlet-section-services/dashlet-section-services.component';

@Component({
    selector: 'app-dashlet-system-health-asm',
    templateUrl: './dashlet-system-health-asm.component.html',
    styleUrls: ['./dashlet-system-health-asm.component.scss']
})
export class DashletSystemHealthASMComponent implements OnInit, OnDestroy {
    @Input() dashlet!: DashletSystemHealthASM;
    @ViewChild('thresholdItem') thresholdItem!: ThresholdItemComponent;
    @ViewChild('processorMemory') processorMemory: DashletSectionProcessorMemoryComponent;

    @ViewChild('services') services: DashletSectionServicesComponent;

    openSubcontainer: string | null = '';
    openSubcontainerSeverity = 0;
    occupancyContextMenuItems!: MenuItem[];

    selectedField!: string;
    selectedThreshold!: Threshold;

    upStatus: any = ['up', 'up simplex', 'up standby'];
    partialStatus: any = ['partially up', 'partially down'];
    downStatus: any = ['down'];

    titleMsg: string;

    public subcontainerSubtitle: string;

    public servicesArray!: ServiceStatus[];

    private subscription: Subscription = new Subscription();
    private onDestroy$ = new Subject<void>();

    constructor(
        private dashletService: DashletService,
        private accountService: AccountService,
        private thresholdService: ThresholdService,
        private tileGridService: TileGridService,
        public timeoutService: TimeoutService,
        private store$: Store<AppState>
    ) {}

    ngOnInit() {
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );

        // SIP Entity Status
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdSIPEntityStatus
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdSIPEntityStatus
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdSIPEntityStatus
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length) {
                        this.dashlet.processSIPEntityStatus(data);
                    }
                })
        );

        // SIP Registration Status
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdSIPRegistrationStatus
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdSIPRegistrationStatus
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdSIPRegistrationStatus
                        )
                    )
                )
                .subscribe(data => {
                    if (data) {
                        this.dashlet.processSIPRegistrationStatus(data);
                    }
                })
        );

        // SIP Session Status
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdSIPSessionStatus
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdSIPSessionStatus
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdSIPSessionStatus
                        )
                    )
                )
                .subscribe(data => {
                    if (data) {
                        this.dashlet.processSIPSessionStatus(data);
                    }
                })
        );

        this.titleMsg = this.timeoutService.getSystemDataExpiredmessage(
            this.timeoutService.formatSystemTimeoutString(
                this.processorMemory?.cpuUsageData?.dataExpired,
                this.processorMemory?.totalDiskUsageData?.dataExpired,
                this.processorMemory?.memoryUsageData?.dataExpired
            ),
            [
                this.processorMemory?.commandTypeIdCPUUsage,
                this.processorMemory?.commandTypeIdMemoryUsage,
                this.processorMemory?.commandTypeIdDiskUsage
            ],
            this.dashlet.equipment.equipmentId
        );
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
        this.subscription.unsubscribe();
    }

    getSeverityColor(severity: number): string {
        return this.dashletService.getSeverityColor(severity);
    }

    getSIPEntityStatus() {
        switch (this.openSubcontainerSeverity) {
            case 0:
                return 'Up';
            case 1:
                return 'Up (Errors)';
            case 2:
                return 'Down';
        }
        return;
    }

    getSIPEntitiesByStatus() {
        switch (this.openSubcontainerSeverity) {
            case 0:
                return this.getSIPGoodEntities();
            case 1:
                return this.getSIPSatisfactoryEntities();
            case 2:
                return this.getSIPPoorEntities();
        }
        return;
    }

    getSIPGoodEntities() {
        const sipUpEntities: SIPEntityStatus[] = [];
        this.dashlet.sipEntityStatusList.forEach(element => {
            if (this.upStatus.includes(element.status.toLowerCase())) {
                sipUpEntities.push(element);
            }
        });
        return sipUpEntities;
    }

    getSIPSatisfactoryEntities() {
        const sipSatisfactoryEntities: SIPEntityStatus[] = [];
        this.dashlet.sipEntityStatusList.forEach(element => {
            if (
                !this.upStatus.includes(element.status.toLowerCase()) &&
                !this.downStatus.includes(element.status.toLowerCase())
            ) {
                sipSatisfactoryEntities.push(element);
            }
        });
        return sipSatisfactoryEntities;
    }

    getSIPPoorEntities() {
        const sipDownEntities: SIPEntityStatus[] = [];
        this.dashlet.sipEntityStatusList.forEach(element => {
            if (this.downStatus.includes(element.status.toLowerCase())) {
                sipDownEntities.push(element);
            }
        });
        return sipDownEntities;
    }

    openDetails(container: string, severity: number) {
        this.openSubcontainer = container;
        this.dashlet.displayHeader = false;
        this.openSubcontainerSeverity = severity;
    }

    closeDetails() {
        this.subcontainerSubtitle = '';
        this.openSubcontainer = null;
        this.dashlet.displayHeader = true;
    }

    openThresholdAlerts(elementId: string, commandTypeId: string, diskName?: string) {
        this.openSubcontainer = 'createThreshold';
        this.dashlet.displayHeader = false;
        this.selectedThreshold = new Threshold();
        this.selectedThreshold.commandTypeId = commandTypeId;
        this.selectedThreshold.field = elementId;
        this.selectedThreshold.whereCreated = 'Dashboard';
        if (diskName) {
            this.selectedThreshold.fieldInstance = diskName;
        }
    }

    openAlarmIconThreshold() {
        this.thresholdService.openThresholdManagement(this.accountService.getUserDetails().EntityId);
    }

    onSubmit() {
        this.thresholdItem
            .onSubmit()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((result: any) => {
                if (result) {
                    this.selectedThreshold.dapThresholdId = result['DapThresholdId'];
                    this.dashlet.thresholds.push(this.selectedThreshold);
                    switch (this.selectedThreshold.field) {
                        case 'processor':
                            ++this.processorMemory.cpuUsageData.thresholdsNumber;
                            break;
                        case 'memory':
                            ++this.processorMemory.memoryUsageData.thresholdsNumber;
                            break;
                        case 'disk':
                            const diskUsage = this.processorMemory.diskUsageData.find(d => {
                                return d.diskName === this.selectedThreshold.fieldInstance;
                            });
                            if (diskUsage) {
                                ++diskUsage.thresholdsNumber;
                            }
                            break;
                        default:
                            break;
                    }
                    this.tileGridService.thresholdCreated(
                        this.selectedThreshold.name + ' threshold added via ' + this.dashlet.title
                    );
                } else {
                    this.tileGridService.thresholdCreated('Threshold add failed');
                }
            });
        this.closeDetails();
    }

    saveEdit() {
        this.thresholdItem
            .saveEdit()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(result => {
                if (result) {
                    this.tileGridService.thresholdCreated(
                        this.selectedThreshold.name + ' threshold edited via ' + this.dashlet.title
                    );
                } else {
                    this.tileGridService.thresholdCreated('Threshold edit failed');
                }
            });
        this.closeDetails();
    }
    returnCustomScriptOutputData() {}
}
