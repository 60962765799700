import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

export const GetNotifyProcessNicNames = createAction(
  '[ProcessNicNames] Get Notify ProcessNicNamess',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetNotifyProcessNicNamesSuccess = createAction(
  '[ProcessNicNames] Get Notify ProcessNicNamess Success',
  props<{ data: RealTimeNotify }>()
);

export const GetNotifyProcessNicNamesFailure = createAction(
  '[ProcessNicNames] Get Notify ProcessNicNamess Failure',
  props<{ error: any }>()
);


export const GetProcessNicNames = createAction(
  '[ProcessNicNames] Get ProcessNicNamess',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetProcessNicNamesSuccess = createAction(
  '[ProcessNicNames] Get ProcessNicNamess Success',
  props<{ data: any}>()
);

export const GetProcessNicNamesFailure = createAction(
  '[ProcessNicNames] Get ProcessNicNamess Failure',
  props<{ error: any }>()
);
