import * as Actions from '@actions/index';
import {
    AccountService,
    DashboardService,
    DashletService,
    ReportService,
    ThresholdService,
    TileGridService,
    TimeoutService
} from '@services/index';
import {
    AppState,
    selectEntity,
    selectEntityTimer,
    selectDataFromCommonEntity,
    selectReportSubscription
} from '@reducers/index';
import {
    CallRate,
    ColumnTypes,
    CustomerLogo,
    DashletSystemHealthAcm,
    Ds1,
    FeatureSubscriptions,
    MenuItem,
    Threshold
} from '@models/index';
import { select, Store } from '@ngrx/store';
import { ThresholdItemComponent } from '../threshold-item/threshold-item.component';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { DashletSectionProcessorMemoryComponent } from '../dashlet-section-processor-memory/dashlet-section-processor-memory.component';
import { DashletSectionServicesComponent } from '../dashlet-section-services/dashlet-section-services.component';

@Component({
    selector: 'app-dashlet-system-health-acm-component',
    templateUrl: 'dashlet-system-health-acm-component.html',
    styleUrls: ['./dashlet-system-health-acm-component.scss']
})
export class DashletSystemHealthAcmComponent implements OnInit, OnDestroy {
    @Input() dashlet!: DashletSystemHealthAcm;
    @ViewChild('thresholdItem') thresholdItem!: ThresholdItemComponent;
    @ViewChild('processorMemory') processorMemory: DashletSectionProcessorMemoryComponent;
    @ViewChild('services') services: DashletSectionServicesComponent;

    private reportSubscriptions: FeatureSubscriptions = {
        capacityEnabled: false,
        availabilityEnabled: false,
        releaseEnabled: false,
        apiEnabled: false,
        serviceDeskEnabled: false,
        configurationEnabled: false,
        continuityEnabled: false,
        changeEnabled: false,
        securityEnabled: false
    };

    openSubcontainer: string | null = '';
    openSubcontainerSeverity = 0;

    selectedField!: string;
    selectedThreshold!: Threshold;

    public callRateColumns: ColumnTypes[] = [
        {
            columnDef: 'period',
            header: 'Period',
            cell: (row: CallRate) => row.period
        },
        {
            columnDef: 'busiestHour',
            header: 'Busiest Hr',
            cell: (row: CallRate) => row.busiestHour,
            dataTooltip: (row: CallRate) => (row.peakAllTimeDate ? row.peakAllTimeDate : '')
        },
        {
            columnDef: 'totalCompleted',
            header: 'Completed',
            cell: (row: CallRate) => row.totalCompleted,
            headerTooltip: 'Completed calls for the hour'
        },
        {
            columnDef: 'busiestInterval',
            header: 'Busiest Interval',
            cell: (row: CallRate) => row.busiestInterval,
            headerTooltip: ' Busiest Interval (36 sec.).Time is shown in HH:MM:SS format',
            dataTooltip: (row: CallRate) => (row.peakAllTimeDate ? row.peakAllTimeDate : '')
        },
        {
            columnDef: 'busiestCompleted',
            header: 'Completed',
            cell: (row: CallRate) => row.busiestCompleted
        }
    ];

    public callRateMenu: MenuItem[] = [
        {
            label: 'Call Rate Report',
            command: (row: CallRate) => this.openCallRateReport(),
            disabled: !(this.reportSubscriptions.capacityEnabled || this.accountService.isVirsaeOwner()),
            title: !(this.reportSubscriptions.capacityEnabled || this.accountService.isVirsaeOwner())
                ? this.getCapacityUnsubscribedText()
                : ''
        }
    ];

    private subscription: Subscription = new Subscription();

    constructor(
        public dashletService: DashletService,
        public dashboardService: DashboardService,
        public accountService: AccountService,
        public reportService: ReportService,
        public thresholdService: ThresholdService,
        public tileGridService: TileGridService,
        public timeoutService: TimeoutService,
        private store$: Store<AppState>
    ) {}

    ngOnInit() {
        if (this.dashlet.configured) {
            this.store$.dispatch(Actions.GetReportSubscriptions({ customerId: this.dashlet.customer.customerId }));
        }
        this.subscription.add(
            this.store$
                .pipe(select(selectReportSubscription(this.dashlet.customer.customerId)))
                .subscribe(subscriptions => {
                    if (subscriptions?.reportSubscriptions) {
                        this.reportSubscriptions = subscriptions.reportSubscriptions;
                        this.callRateMenu = [];
                        this.callRateMenu.push({
                            label: 'Call Rate Report',
                            command: (row: CallRate) => this.openCallRateReport(),
                            disabled: !(
                                this.reportSubscriptions.capacityEnabled || this.accountService.isVirsaeOwner()
                            ),
                            title: !(this.reportSubscriptions.capacityEnabled || this.accountService.isVirsaeOwner())
                                ? this.getCapacityUnsubscribedText()
                                : ''
                        });
                    }
                })
        );

        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdCallRate,
                idType: 'equipmentId'
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdCallRate
            })
        );
        this.store$.dispatch(
            Actions.GetEntityTimer({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdCallRate,
                uniqueId: this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdCallRate
            })
        );

        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdCallRateAllTime,
                idType: 'equipmentId'
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdCallRateAllTime
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdCallRate
                        )
                    )
                )
                .subscribe(processCallRate => {
                    if (processCallRate) {
                        this.dashlet.processCallRate(processCallRate);
                        this.subscription.add(
                            this.store$
                                .pipe(
                                    select(
                                        selectDataFromCommonEntity(
                                            this.dashlet.equipment.equipmentId +
                                                this.dashlet.commandTypeIdCallRateAllTime
                                        )
                                    )
                                )
                                .subscribe(data => {
                                    if (data) {
                                        this.dashlet.processCallRateAllTime(data);
                                    }
                                })
                        );
                    }
                })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(selectEntityTimer(this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdCallRate))
                )
                .subscribe(dataTimeoutExpired => {
                    if (dataTimeoutExpired !== undefined) {
                        this.dashlet.callRateTimedOut = dataTimeoutExpired;
                    }
                })
        );

        // cdr link
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdCdrLink,
                idType: 'equipmentId'
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdCdrLink
            })
        );
        this.store$.dispatch(
            Actions.GetEntityTimer({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdCdrLink,
                uniqueId: this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdCdrLink
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdCdrLink
                        )
                    )
                )
                .subscribe(data => {
                    if (data) {
                        this.dashlet.processCdrlink(data);
                    }
                    this.subscription.add(
                        this.store$
                            .pipe(
                                select(
                                    selectEntityTimer(
                                        this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdCdrLink
                                    )
                                )
                            )
                            .subscribe(dataTimeoutExpired => {
                                if (dataTimeoutExpired !== undefined) {
                                    this.dashlet.cdrLinkTimedOut = dataTimeoutExpired;
                                }
                            })
                    );
                })
        );
        // disk usage

        // media gateway
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdMediaGateway,
                idType: 'equipmentId'
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdMediaGateway
            })
        );
        this.store$.dispatch(
            Actions.GetEntityTimer({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdMediaGateway,
                uniqueId: this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdMediaGateway
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdMediaGateway
                        )
                    )
                )
                .subscribe(data => {
                    if (data) {
                        this.dashlet.processMediaGateway(data);
                    }
                    this.subscription.add(
                        this.store$
                            .pipe(
                                select(
                                    selectEntityTimer(
                                        this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdMediaGateway
                                    )
                                )
                            )
                            .subscribe(dataTimeoutExpired => {
                                this.dashlet.mediaGatewayTimedOut = dataTimeoutExpired;
                            })
                    );
                })
        );

        // DS1 log Name
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdDs1LogName,
                idType: 'equipmentId'
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdDs1LogName
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdDs1LogName
                        )
                    )
                )
                .subscribe(data => {
                    if (data) {
                        this.dashlet.processDs1LogName(data);
                    }
                })
        );

        // DS1 log
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdDs1Log,
                idType: 'equipmentId'
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdDs1Log
            })
        );
        this.store$.dispatch(
            Actions.GetEntityTimer({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdDs1Log,
                uniqueId: this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdDs1Log
            })
        );

        this.subscription.add(
            this.store$
                .pipe(select(selectEntityTimer(this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdDs1Log)))
                .subscribe(dataTimeoutExpired => {
                    if (dataTimeoutExpired !== undefined) {
                        this.dashlet.ds1LogTimedOut = dataTimeoutExpired;
                    }
                })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdDs1Log
                        )
                    )
                )
                .subscribe(data => {
                    if (data) {
                        this.dashlet.processDs1Log(data);
                    }
                })
        );
        // MediaGateway Info
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdMediaGatewayInfo,
                idType: 'equipmentId'
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdMediaGatewayInfo
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdMediaGatewayInfo
                        )
                    )
                )
                .subscribe(data => {
                    if (data) {
                        this.dashlet.processMediaGatewayInfo(data);
                    }
                })
        );
    }

    ngOnDestroy() {
        if (this.dashlet) {
            this.dashlet.dispose();
        }
        this.subscription.unsubscribe();
    }

    openAlarmIconThreshold() {
        this.thresholdService.openThresholdManagement(this.accountService.getUserDetails().EntityId);
    }

    getSeverityColor(severity: number): string {
        return this.dashletService.getSeverityColor(severity);
    }

    openDetails(container: string, severity: number) {
        this.openSubcontainer = container;
        this.dashlet.displayHeader = false;
        this.openSubcontainerSeverity = severity;
    }

    public closeDetails(): void {
        this.openSubcontainer = null;
        this.dashlet.displayHeader = true;
    }

    private openCallRateReport(): void {
        this.reportService.openReport(this.reportService.acmCallRateReportId, this.dashlet.customer.customerId);
    }

    public openMediaGatewayReport(): void {
        this.reportService.openReport(this.reportService.mediaGatewayTimeoutReportId, this.dashlet.customer.customerId);
    }

    public openDs1Report(): void {
        this.reportService.openReport(this.reportService.ds1PerformanceReportId, this.dashlet.customer.customerId);
    }

    public openAlarms(): void {
        this.dashboardService.openAlarms(this.dashlet.customer.customerId);
    }

    public openThresholdAlerts(elementId: string, commandTypeId: string, diskName?: string): void {
        this.openSubcontainer = 'createThreshold';
        this.dashlet.displayHeader = false;
        this.selectedThreshold = new Threshold();
        this.selectedThreshold.commandTypeId = commandTypeId;
        this.selectedThreshold.field = elementId;
        this.selectedThreshold.whereCreated = 'Dashboard';
        if (diskName) {
            this.selectedThreshold.fieldInstance = diskName;
        }
    }

    public onSubmit(): void {
        this.subscription.add(
            this.thresholdItem.onSubmit().subscribe((result: any) => {
                if (result) {
                    this.selectedThreshold.dapThresholdId = result['DapThresholdId'];
                    this.processorMemory.thresholds.push(this.selectedThreshold);
                    switch (this.selectedThreshold.field) {
                        case 'processor':
                            ++this.processorMemory.cpuUsageData.thresholdsNumber;
                            break;
                        case 'memory':
                            ++this.processorMemory.memoryUsageData.thresholdsNumber;
                            break;
                        case 'disk':
                            // eslint-disable-next-line no-case-declarations
                            const diskUsage = this.processorMemory.diskUsageData.find(d => {
                                return d.diskName === this.selectedThreshold.fieldInstance;
                            });
                            if (diskUsage) {
                                ++diskUsage.thresholdsNumber;
                            }
                            break;
                        default:
                            break;
                    }
                    this.tileGridService.thresholdCreated(
                        this.selectedThreshold.name + ' threshold added via ' + this.dashlet.title
                    );
                } else {
                    this.tileGridService.thresholdCreated('Threshold add failed');
                }
            })
        );
        this.closeDetails();
    }

    public saveEdit(): void {
        this.subscription.add(
            this.thresholdItem.saveEdit().subscribe(result => {
                if (result) {
                    this.tileGridService.thresholdCreated(
                        this.selectedThreshold.name + ' threshold edited via ' + this.dashlet.title
                    );
                } else {
                    this.tileGridService.thresholdCreated('Threshold edit failed');
                }
            })
        );
        this.closeDetails();
    }

    public getCapacityUnsubscribedText(): string {
        return this.dashletService.getCapacityUnsubscribedText();
    }

    public showDs1Names(ds1Array: Ds1[]) {
        return ds1Array.map(ds1 => ds1.name).join('\n');
    }
}
