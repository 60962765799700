import * as ProcessWFMStatusRealTimeACtions from '@actions/process-wfmstatus-real-time/process-wfmstatus-real-time.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService } from '@services/index';
import { RealTimeNotify } from '@models/index';

@Injectable()
export class ProcessWFMStatusRealTimeEffects {

  constructor(private actions$: Actions, private realtimeGatewayService: RealtimeGatewayService) { }

  getNotifyProcessWFMStatusRealTime$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessWFMStatusRealTimeACtions.GetNotifyProcessWFMStatusRealTime),
    switchMap((action) => this.realtimeGatewayService.observe(action.equipmentId, action.commandTypeId).pipe(
      catchError((err) => of(
        ProcessWFMStatusRealTimeACtions.GetNotifyProcessWFMStatusRealTimeFailure({ error: err })
        )
      ),
      mergeMap((data: RealTimeNotify) => [
        ProcessWFMStatusRealTimeACtions.GetNotifyProcessWFMStatusRealTimeSuccess({ data }),
        ProcessWFMStatusRealTimeACtions.GetProcessWFMStatusRealTime({ ...data })
      ],
      ),
    ),
    )
  ));

  getProcessWFMStatusRealTime$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessWFMStatusRealTimeACtions.GetProcessWFMStatusRealTime),
    mergeMap((action) => this.realtimeGatewayService.getDocument(action.equipmentId, action.commandTypeId).pipe(
      map((data) => ProcessWFMStatusRealTimeACtions.GetProcessWFMStatusRealTimeSuccess({ data:{ data, equipmentId: action.equipmentId} }),
      ),
      catchError((err) => of(ProcessWFMStatusRealTimeACtions.GetProcessWFMStatusRealTimeFailure({ error: err })))
    ))
  ));

}
