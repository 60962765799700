import { BusinessPartner, Customer, Dashlet, Point } from '@models/index';
import { Observable } from 'rxjs';
import { DashletSettingsService, RealtimeGatewayService } from '@services/index';

interface DatabaseHealthData {
    customerName: string;
    so: string;
    dapBehind: number;
    spaceUsed: number;
    dtu: number;
    cpu: number;
    memory: number;
    dataIO: number;
    logWrite: number;
    dbName: string;
    serverName: string;
    updateTime: Date;
    actualSize: number;
    maxSize: number;
    updateOffset: number;
    titleString: string;
    severity: number;
    autoScaleAction: number | null;
    autoScaleTimestamp: Date | null;
    dtuHistoric: any;
}

export class DashletDatabaseHealth extends Dashlet {
    public commandTypeId = 'F9756DBC-0DEC-4D6F-BFEA-4A314AF88D0F';
    public commandTypeIdRecommendations = 'AB2B1AA8-8416-451D-BFFD-253B181A20DD';
    public commandTypeIdHistoricDTU = '6C0DC489-CB5B-4E30-B68B-47AC5B64D937';
    public equipmentId = '445bb159-5bae-42ce-b568-58d84617bdfc';
    businessPartners: BusinessPartner[] = [];
    databaseHealthDataArray: DatabaseHealthData[] = [];

    customers: Customer[] = [];
    private customerObservables: Observable<any>[] = [];

    constructor(private realtimeService: RealtimeGatewayService, private settingsService: DashletSettingsService) {
        super();

        // sizing
        this.sizes = [
            {
                id: 0,
                label: 'Small',
                cols: 13,
                rows: 3
            },
            {
                id: 1,
                label: 'Medium',
                cols: 13,
                rows: 6
            },
            {
                id: 2,
                label: 'Large',
                cols: 13,
                rows: 8
            },
            {
                id: 3,
                label: 'Huge',
                cols: 15,
                rows: 8
            }
        ];

        this.applySize(1);

        // init data
        this.resetData();
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);
        this.generatedNameTag = '';

        // unsub realtime feed
        if (this.configured) {
            this.unsubData();
        }

        // read settings object
        this.configured = v.bp;
        // clear equipment array
        this.businessPartners = [];
        this.customerObservables = [];
        this.customers = [];

        if (v.bp) {
            v.bp.forEach((element: any) => {
                const businessPartner = new BusinessPartner();
                businessPartner.Id = element.value;
                businessPartner.Name = element.label;
                this.businessPartners.push(businessPartner);
                this.customerObservables.push(this.settingsService.getBPAssociatedCustomers(businessPartner.Id));

                if (this.configured) {
                    if (this.generatedNameTag !== '') {
                        this.generatedNameTag += ` | ${element.label}`;
                    } else {
                        this.generatedNameTag += `${element.label}`;
                    }
                } else {
                    this.generatedNameTag = 'Unconfigured';
                }
            });
        }
    }

    applySize(id: number) {
        super.applySize(id);
    }

    public processDatabaseHealthData(databaseInformationDate: any, recommendationsData: any, historicDtuData: any) {
        this.resetData();

        databaseInformationDate.forEach((row: any) => {
            if (this.customers.find(x => x['entityId'] === row.CustomerID)) {
                const databaseHealthData: DatabaseHealthData = {
                    so: row.ServiceObjective,
                    dapBehind: row.DAPMinutesBehind,
                    spaceUsed: Math.round((row.ActualSizeGb / row.MaxSizeGb) * 100),
                    dtu: row['DTU%'],
                    cpu: row['CPU%'],
                    memory: row['Memory%'],
                    dataIO: row['DataIO%'],
                    logWrite: row['LogWrite%'],
                    actualSize: row.ActualSizeGb,
                    maxSize: row.MaxSizeGb,
                    updateTime: this.calculateUpdateTime(row.UpdateTime),
                    customerName: this.customers.find(x => x['entityId'] === row.CustomerID)!['name'],
                    dbName: row.Database.substr(
                        row.Database.indexOf('Catalog=') + 8,
                        row.Database.indexOf(';Data') - row.Database.indexOf('Catalog=') - 8
                    ),
                    serverName: row.Database.substr(
                        row.Database.lastIndexOf('Source=') + 7,
                        row.Database.length - 1 - row.Database.lastIndexOf('Source=') - 7
                    ),
                    updateOffset: Math.round(
                        (new Date().getTime() - this.calculateUpdateTime(row.UpdateTime).getTime()) / 60000
                    ),
                    titleString: '',
                    severity: -1,
                    autoScaleAction: null,
                    autoScaleTimestamp: null,
                    dtuHistoric: null
                };
                databaseHealthData.titleString =
                    'Database Name = ' +
                    databaseHealthData.dbName +
                    '\n' +
                    'Server Name = ' +
                    databaseHealthData.serverName +
                    '\n' +
                    'Update Time = ' +
                    databaseHealthData.updateTime +
                    '\n' +
                    'Actual Size (Gb) = ' +
                    databaseHealthData.actualSize +
                    '\n' +
                    'Max Size (Gb) = ' +
                    databaseHealthData.maxSize +
                    '\n' +
                    'Update Offset Minutes = ' +
                    databaseHealthData.updateOffset +
                    '\n' +
                    'Log Write Percentage = ' +
                    databaseHealthData.logWrite;
                databaseHealthData.severity = this.getSeverityState(databaseHealthData);

                // Add recommendations
                let indexOfRecommendation;
                if (recommendationsData.length !== 0) {
                    indexOfRecommendation = recommendationsData.findIndex(
                        (x: any) => x.DatabaseName.toLowerCase() === databaseHealthData.dbName.toLowerCase()
                    );

                    if (indexOfRecommendation !== -1) {
                        databaseHealthData.autoScaleAction = parseInt(
                            recommendationsData[indexOfRecommendation].AutoScaleAction
                        );
                        const newTime = new Date(recommendationsData[indexOfRecommendation].AsOfTimeStampUTC);
                        if (databaseHealthData.autoScaleTimestamp) {
                            if (newTime.getTime() > databaseHealthData.autoScaleTimestamp.getTime()) {
                                databaseHealthData.autoScaleTimestamp = newTime;
                            }
                        } else {
                            databaseHealthData.autoScaleTimestamp = newTime;
                        }
                    }
                }

                // Add Historic DTU
                const indexOfHistoricDTU = historicDtuData.findIndex((x: any) => x.CustomerId === row.CustomerID);

                if (indexOfHistoricDTU !== -1) {
                    const chartData: Point[] = [];

                    // convert comma separated string into array of int
                    const DTUData = historicDtuData[indexOfHistoricDTU].MaxDtu.split(',').map(
                        (e: any) => (e = parseInt(e))
                    );
                    if (DTUData) {
                        for (let i = 0; i < DTUData.length; i++) {
                            chartData.push({
                                x: new Date(i),
                                y: DTUData[i]
                            });
                        }

                        databaseHealthData.dtuHistoric = {
                            datasets: [
                                {
                                    label: '',
                                    borderColor: '#336699',
                                    backgroundColor: '#336699',
                                    borderWidth: 1.5,
                                    pointRadius: 0,
                                    pointHitRadius: 10,
                                    fill: false,
                                    lineTension: 0,
                                    data: chartData
                                }
                            ]
                        };
                    }
                }

                this.databaseHealthDataArray.push(databaseHealthData);
            }
        });
    }

    calculateUpdateTime(updateTime: any): Date {
        const date = updateTime.substr(0, updateTime.indexOf(' ')).split('/').reverse().join('-') + 'T';

        const timeWithoutSeconds = updateTime.substr(
            updateTime.indexOf(' ') + 1,
            updateTime.lastIndexOf(':') - updateTime.indexOf(' ')
        );

        const seconds = Math.floor(updateTime.substr(updateTime.lastIndexOf(':') + 1, updateTime.length));

        const secondsString: string = seconds < 10 ? '0' + seconds : '' + seconds;

        return new Date(date + timeWithoutSeconds + secondsString + 'Z');
    }

    resetData() {
        this.databaseHealthDataArray = [];
    }

    dispose() {
        this.unsubData();
    }
    private unsubData() {
        if (this.businessPartners.length !== 0) {
            // unsub data
            this.realtimeService.unsubscribe(this.equipmentId, this.commandTypeId);
            this.realtimeService.unsubscribe(this.equipmentId, this.commandTypeIdRecommendations);
            this.realtimeService.unsubscribe(this.equipmentId, this.commandTypeIdHistoricDTU);
        }
    }
    getSeverityState(data: any) {
        let highestSeverity = -1;

        highestSeverity =
            this.getSeverityColor(data.cpu, 80, 60) > highestSeverity
                ? this.getSeverityColor(data.cpu, 80, 60)
                : highestSeverity;

        highestSeverity =
            this.getSeverityColor(data.spaceUsed, 90, 85) > highestSeverity
                ? this.getSeverityColor(data.spaceUsed, 90, 85)
                : highestSeverity;

        highestSeverity =
            this.getSeverityColor(data.dapBehind, 60, 10) > highestSeverity
                ? this.getSeverityColor(data.dapBehind, 60, 10)
                : highestSeverity;

        highestSeverity =
            this.getSeverityColor(data.dataIO, 80, 60) > highestSeverity
                ? this.getSeverityColor(data.dataIO, 80, 60)
                : highestSeverity;

        highestSeverity =
            this.getSeverityColor(data.dtu, 80, 60) > highestSeverity
                ? this.getSeverityColor(data.dtu, 80, 60)
                : highestSeverity;

        highestSeverity =
            this.getSeverityColor(data.logWrite, 80, 60) > highestSeverity
                ? this.getSeverityColor(data.logWrite, 80, 60)
                : highestSeverity;

        highestSeverity =
            this.getSeverityColor(data.memory, 80, 60) > highestSeverity
                ? this.getSeverityColor(data.memory, 80, 60)
                : highestSeverity;

        highestSeverity =
            this.getSeverityColor(data.updateOffset, 10, 10) > highestSeverity
                ? this.getSeverityColor(data.updateOffset, 10, 10)
                : highestSeverity;

        data.severity = highestSeverity;
        return highestSeverity;
    }

    private getSeverityColor(value: number, red: number, orange: number) {
        if (value >= red) {
            return 2;
        } else if (value >= orange) {
            return 1;
        } else if (value >= 0) {
            return 0;
        } else {
            return -1;
        }
    }
}
