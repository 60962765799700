import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

export const GetNotifyProcessIISData = createAction(
  '[ProcessIISData] Get Notify ProcessIISDatas',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetNotifyProcessIISDataSuccess = createAction(
  '[ProcessIISData] Get Notify ProcessIISDatas Success',
  props<{ data: RealTimeNotify }>()
);

export const GetNotifyProcessIISDataFailure = createAction(
  '[ProcessIISData] Get Notify ProcessIISDatas Failure',
  props<{ error: any }>()
);

export const GetProcessIISData = createAction(
  '[ProcessIISData] Get ProcessIISDatas',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetProcessIISDataSuccess = createAction(
  '[ProcessIISData] Get ProcessIISDatas Success',
  props<{ data: any}>()
);

export const GetProcessIISDataFailure = createAction(
  '[ProcessIISData] Get ProcessIISDatas Failure',
  props<{ error: any }>()
);
