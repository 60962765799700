import * as ProcessUpCpuMemActions from '@actions/process-up-cpu-mem-data/process-up-cpu-mem-data.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService, TimeoutService } from '@services/index';
import { RealTimeNotify } from '@models/index';

@Injectable()
export class ProcessUpCpuMemDataEffects {
    constructor(
        private actions$: Actions,
        private realtimeGatewayService: RealtimeGatewayService,
        private timeoutService: TimeoutService
    ) {}

    getNotifyProcessUpCpuMem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessUpCpuMemActions.GetNotifyProcessUpCpuMemData),
            switchMap(action =>
                this.realtimeGatewayService.observe(action.equipmentId, action.commandTypeId).pipe(
                    catchError(err => of(ProcessUpCpuMemActions.GetNotifyProcessUpCpuMemDataFailure({ error: err }))),
                    mergeMap((data: RealTimeNotify) => [
                        ProcessUpCpuMemActions.GetNotifyProcessUpCpuMemDataSuccess({ data }),
                        ProcessUpCpuMemActions.GetProcessUpCpuMemData({ ...data })
                    ])
                )
            )
        )
    );

    getProcessUpCpuMem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessUpCpuMemActions.GetProcessUpCpuMemData),
            mergeMap(action =>
                this.realtimeGatewayService.getDocument(action.equipmentId, action.commandTypeId).pipe(
                    map(data =>
                        ProcessUpCpuMemActions.GetProcessUpCpuMemDataSuccess({
                            data: { data, equipmentId: action.equipmentId }
                        })
                    ),
                    catchError(err => of(ProcessUpCpuMemActions.GetProcessUpCpuMemDataFailure({ error: err })))
                )
            )
        )
    );

    getProcessUpCpuMemTimer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessUpCpuMemActions.GetProcessUpCpuMemDataTimer),
            switchMap(action =>
                this.timeoutService.observeTimerNew(action.equipmentId, action.command).pipe(
                    catchError(err => of(ProcessUpCpuMemActions.GetProcessUpCpuMemDataTimerFailure({ error: err }))),
                    map((data: any) =>
                        ProcessUpCpuMemActions.GetProcessUpCpuMemDataTimerSuccess({
                            data: { data, equipmentId: action.equipmentId }
                        })
                    )
                )
            )
        )
    );

    getHistoricCpuMemData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessUpCpuMemActions.GetHistoricCpuMemData),
            switchMap(action =>
                this.realtimeGatewayService
                    .requestHistoricDocument(
                        action.equipmentId,
                        action.commandTypeId,
                        new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                        new Date().toISOString(),
                        20
                    )
                    .pipe(
                        map(data =>
                            ProcessUpCpuMemActions.GetHistoricCpuMemDataSuccess({
                                data: { data, equipmentId: action.equipmentId }
                            })
                        ),
                        catchError(err => of(ProcessUpCpuMemActions.GetHistoricCpuMemDataFailure({ error: err })))
                    )
            )
        )
    );
}
