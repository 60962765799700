import * as NetworkConnectivityActions from '@actions/index';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService, TimeoutService } from '@services/index';
import { RealTimeNotify } from '@models/index';


@Injectable()
export class NetworkConnectivityEffects {

  constructor(private actions$: Actions, private realtimeGatewayService: RealtimeGatewayService,
    private timeoutService: TimeoutService) { }

  getNotifyNetworkConnectivity$ = createEffect(() => this.actions$.pipe(
    ofType(NetworkConnectivityActions.GetNotifyNetworkConnectivity),
    switchMap((action) => this.realtimeGatewayService.observe(action.equipmentId, action.commandTypeId).pipe(
      catchError((err) => of(
        NetworkConnectivityActions.GetNotifyNetworkConnectivityFailure({ error: err })
      )
      ),
      mergeMap((data: RealTimeNotify) => [
        NetworkConnectivityActions.GetNotifyNetworkConnectivitySuccess({ data }),
        NetworkConnectivityActions.GetNetworkConnectivity({ ...data })
      ],
      ),
    ),
    )
  ));

  getNetworkConnectivity$ = createEffect(() => this.actions$.pipe(
    ofType(NetworkConnectivityActions.GetNetworkConnectivity),
    mergeMap((action) => this.realtimeGatewayService.getDocument(action.equipmentId, action.commandTypeId).pipe(
      map((data) => NetworkConnectivityActions.GetNetworkConnectivitySuccess({ data:{ data, equipmentId: action.equipmentId} }),
      ),
      catchError((err) => of(NetworkConnectivityActions.GetNetworkConnectivityFailure({ error: err })))
    ))
  ));

  getNetworkConnectivityTimer$ = createEffect(() => this.actions$.pipe(
    ofType(NetworkConnectivityActions.GetNetworkConnectivityTimer),
    mergeMap((action) => this.timeoutService.observeTimer(action.equipmentId, action.command).pipe(
      map((data) => NetworkConnectivityActions.GetNetworkConnectivityTimerSuccess({ data:{ data, equipmentId: action.equipmentId} }),
      ),
      catchError((err) => of(NetworkConnectivityActions.GetNetworkConnectivityTimerFailure({ error: err })))
    ))
  ));

}
