import * as Actions from '@actions/index';
import { AccountService, DashletDataProcessService, DashletService } from '@services/index';
import {
    AlarmAdditionalData,
    AlarmExtended,
    AlarmResponseLogData,
    AlarmStatistic,
    ColumnTypes,
    DashletAlarmsSummary,
    DashletBusinessPartnerAlarmsSummary,
    MenuItem,
    UserDetails
} from '@models/index';
import { AppState, selectSystemHealthEquipment } from '@reducers/index';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { DashletAlarmsSummaryComponent } from '@dashboardComponents/index';
import { map, retry, tap } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { interval } from 'rxjs/internal/observable/interval';
import { startWith, switchMap, share } from 'rxjs/operators';

enum detailAlarmStatus {
    resolving = -1,
    resolveFailed,
    resolveSucceed
}
@Component({
    selector: 'app-dashlet-business-partner-alarms-summary',
    templateUrl: './dashlet-business-partner-alarms-summary.component.html',
    styleUrls: ['./dashlet-business-partner-alarms-summary.component.scss']
})
export class DashletBusinessPartnerAlarmsSummaryComponent implements OnInit {
    @Input() dashlet: DashletBusinessPartnerAlarmsSummary;
    @ViewChild('dashletAlarmSummary') dashletAlarmSummary: DashletAlarmsSummaryComponent;

    private userDetails: UserDetails;
    public selectedAlarmId: string;
    public detailAlarmStatus: detailAlarmStatus;

    public openSubcontainer: string;
    public openDetailsDrilldown: boolean;
    public detailsAlarm: any;
    public detailsAlarmExtended: Observable<AlarmExtended> = new Subject();
    public detailsAlarmRepeats: Observable<Date[]> = new Subject();
    public detailsAlarmAdditional: Observable<AlarmAdditionalData[]> = new Subject();
    public detailsAlarmResponseLog: Observable<AlarmResponseLogData[]> = new Subject();
    public detailsIndex: number;
    public detailsHasPrev: boolean;
    public detailsHasNext: boolean;
    public showAlarmsTable = false;
    public showNavigation = true;

    public currentCustomer: string = '';
    public currentAlarmSummaryDashlet: DashletAlarmsSummary;

    private filterAlarms: any;
    private timeInterval: Observable<any>;

    private subscription: Subscription = new Subscription();

    get tableSize(): number {
        const s = this.dashlet.getSize();
        if (s.id === 0) return 4;
        else if (s.id === 1) return 14;
        else if (s.id === 2) return 14;
        else if (s.id === 3) return 25;
        return 8;
    }

    get compactTable(): boolean {
        const s = this.dashlet.getSize();
        return s.id === 0 || s.id === 1;
    }

    public columns: ColumnTypes[] = [
        {
            columnDef: 'customer',
            header: 'Customer',
            dataTooltip: (row: AlarmStatistic) => row.customer
        },
        {
            columnDef: 'highSeverity',
            header: 'High',
            headerTooltip: 'Severity 0-2',
            type: 'numeric'
        },
        {
            columnDef: 'mediumSeverity',
            header: 'Medium',
            headerTooltip: 'Severity 3-5',
            type: 'numeric'
        },
        {
            columnDef: 'lowSeverity',
            header: 'Low',
            headerTooltip: 'Severity 6-10',
            type: 'numeric'
        },
        {
            columnDef: 'percentage',
            header: '%',
            type: 'ratio',
            cell: (row: AlarmStatistic) => {
                return [
                    {
                        value: row.highSeverity,
                        colour: this.dashletService.getAlarmSeverityColor(0)
                    },
                    {
                        value: row.mediumSeverity,
                        colour: this.dashletService.getAlarmSeverityColor(3)
                    },
                    {
                        value: row.lowSeverity,
                        colour: this.dashletService.getAlarmSeverityColor(6)
                    }
                ];
            }
        }
    ];

    public menuItems: MenuItem[] = [
        {
            label: 'Details',
            command: (row: AlarmStatistic) => this.openAlarmsTable(row),
            title: '',
            disabled: false
        }
    ];

    constructor(
        private dashletService: DashletService,
        private accountService: AccountService,
        private dashletDataProcessingService: DashletDataProcessService,
        private store$: Store<AppState>,
        private cdRef: ChangeDetectorRef
    ) {
        this.userDetails = this.accountService.getUserDetails();
    }

    public ngOnInit(): void {
        this.subscription.add(
            this.dashlet.settingsChange$.subscribe(() => {
                this.requestData();
            })
        );
        if (this.dashlet.configured) this.requestData();
    }

    private requestData(): void {
        if (this.dashlet.location && this.dashlet.customerIds.length === 1 && this.dashlet.locationId !== '=all=') {
            this.store$.dispatch(Actions.GetSystemHealthEquipments({ entityId: this.dashlet.locationId }));
            this.subscription.add(
                this.store$.pipe(select(selectSystemHealthEquipment(this.dashlet.locationId))).subscribe(groups => {
                    if (groups) {
                        groups.forEach(equipment => {
                            equipment.items.forEach(equip => {
                                this.dashlet.equipmentIds.push(equip.value);
                                this.subData();
                            });
                        });
                    }
                })
            );
        } else {
            this.subData();
        }
    }

    private subData(): void {
        if (this.dashlet.configured && this.dashlet.customerIds) {
            for (const id of this.dashlet.customerIds) {
                this.timeInterval = interval(30 * 1000).pipe(
                    startWith(0),
                    switchMap(() =>
                        this.dashletDataProcessingService.getAlarmsData(id, this.dashlet.commandTypeIdAlarms)
                    ),
                    share()
                );
                this.subscription.add(
                    this.timeInterval.subscribe(res => {
                        this.dashlet.processAlarmData(id, res);
                        this.dashlet.processStatistics(id, res);
                        this.cdRef.detectChanges();
                    })
                );
            }
        }
    }

    get expandedView() {
        const s = this.dashlet.getSize();
        if (s !== null) return s.id === 2 || s.id === 3;
        return false;
    }

    get showCustomer() {
        const d = this.userDetails;
        return d.EntityGroup !== 2;
    }

    public openDetails(index: number): void {
        this.openSubcontainer = 'details';
        this.dashlet.displayHeader = false;
        this.detailsIndex = index;
        this.setDetailsAlarm();
    }

    public closeDetails(): void {
        this.openSubcontainer = 'alarmsTable';
        this.openDetailsDrilldown = false;
        this.dashletAlarmSummary.closeDetails();
    }

    private openAlarmsTable(data: AlarmStatistic) {
        this.openSubcontainer = 'alarmsTable';
        this.currentCustomer = data.customer;
        this.currentAlarmSummaryDashlet = new DashletAlarmsSummary(this.accountService);
        const g = [];
        for (let i = 0; i < 3; i++) {
            g.push({ label: `${i} - High`, value: i });
        }

        for (let i = 3; i < 6; i++) {
            g.push({ label: `${i} - Medium`, value: i });
        }

        for (let i = 6; i < 11; i++) {
            g.push({ label: `${i} - Low`, value: i });
        }
        const customerSetting = { label: data.customer, value: data.customerId };
        const locationSetting = { label: data.location, value: data.locationId };
        this.currentAlarmSummaryDashlet.applySettings({
            customer: [customerSetting],
            location: [locationSetting],
            severity: g,
            isFromDrillDownBP: true
        });
        this.dashlet.displayHeader = false;
    }

    public closeAlarmsTable(): void {
        this.openSubcontainer = null;
        this.currentCustomer = '';
        this.dashlet.displayHeader = true;
    }

    public detailsPrev(): void {
        if (this.detailsHasPrev) {
            this.detailsIndex--;
            this.setDetailsAlarm();
        }
    }

    public detailsNext(): void {
        if (this.detailsHasNext) {
            this.detailsIndex++;
            this.setDetailsAlarm();
        }
    }

    public showDetailDrilldown(): void {
        this.openDetailsDrilldown = true;
    }

    private setDetailsAlarm() {
        this.detailAlarmStatus = null;
        const alarm = (this.detailsAlarm = this.filterAlarms[this.detailsIndex]);
        this.detailsHasPrev = this.detailsIndex > 0;
        this.detailsHasNext = this.detailsIndex < this.filterAlarms.length - 1;
        //query extra data
        this.detailsAlarmExtended = this.dashletDataProcessingService
            .getAlarmExtended(alarm.id, alarm.firstOccurred, alarm.customerId)
            .pipe(retry(3))
            .pipe(map(a => a || null));
        this.detailsAlarmRepeats = this.dashletDataProcessingService
            .getAlarmRepeats(alarm.id, alarm.firstOccurred, alarm.customerId)
            .pipe(retry(3));
        this.detailsAlarmAdditional = this.dashletDataProcessingService
            .getAlarmAdditionalData(alarm.id, alarm.firstOccurred, alarm.customerId)
            .pipe(retry(3))
            .pipe(
                tap((data: any) => {
                    data.splice(
                        data.findIndex(d => {
                            return d.name === 'AdministeredID';
                        }),
                        1
                    );
                    data.splice(
                        data.findIndex(d => {
                            return d.name === 'AlarmDescriptionText';
                        }),
                        1
                    );
                    data.splice(
                        data.findIndex(d => {
                            return d.name === 'AlarmEventName';
                        }),
                        1
                    );
                    data.splice(
                        data.findIndex(d => {
                            return d.name === 'AlarmResolutionText';
                        }),
                        1
                    );
                })
            );
        this.detailsAlarmResponseLog = this.dashletDataProcessingService
            .getAlarmResponseLog(alarm.id, alarm.customerId)
            .pipe(retry(3));
    }

    public changeShowNavigation($event): void {
        this.showNavigation = $event;
    }
}
