import { Action, createReducer, on } from '@ngrx/store';
import * as CustomersActions from '@actions/customers/customers.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CustomerModel } from '@models/index';

interface CustomerState extends EntityState<any> {}

export interface State {
    customers: CustomerState;
    userAssociatedCustomers: CustomerModel[] | [];
}

export function selectEntityId(a: any): string {
    //In this case this would be optional since primary key is id
    return a.id;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
    selectId: selectEntityId
});

const CustomersInitState: CustomerState = adapter.getInitialState();

export const initialState: State = {
    customers: CustomersInitState,
    userAssociatedCustomers: []
};

export const reducer = createReducer(
    initialState,

    on(CustomersActions.GetBPAssociatedCustomersSuccess, (state, action) => {
        return {
            ...state,
            customers: adapter.upsertOne(action.data, state.customers)
        };
    }),
    on(CustomersActions.GetUserAssociatedCustomersSuccess, (state, action) => {
        return {
            ...state,
            userAssociatedCustomers: action.data
        };
    })
);

const { selectEntities } = adapter.getSelectors();

// select the dictionary of Logo entities
export const selectBpEntities = selectEntities;
