import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

export const GetNotifyProcessCallRate = createAction(
  '[ProcessCallRate] Get ProcessCallRates',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetNotifyProcessCallRateSuccess = createAction(
  '[ProcessCallRate] Get ProcessCallRates Success',
  props<{ data: RealTimeNotify }>()
);

export const GetNotifyProcessCallRateFailure = createAction(
  '[ProcessCallRate] Get ProcessCallRates Failure',
  props<{ error: any }>()
);


export const GetProcessCallRate = createAction(
  '[ProcessCallRate] Get ProcessCallRates',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetProcessCallRateSuccess = createAction(
  '[ProcessCallRate] Get ProcessCallRates Success',
  props<{ data: any }>()
);

export const GetProcessCallRateFailure = createAction(
  '[ProcessCallRate] Get ProcessCallRates Failure',
  props<{ error: any }>()
);

// timer
export const GetProcessCallRateTimer = createAction(
  '[ProcessCallRate] Get ProcessCallRatesTimer',
  props<{ equipmentId: string, commandTypeId: string, uniqueId: string }>()
);

export const GetProcessCallRateTimerSuccess = createAction(
  '[ProcessCallRate] Get ProcessCallRatesTimer Success',
  props<{ data: any }>()
);

export const GetProcessCallRateTimerFailure = createAction(
  '[ProcessCallRate] Get ProcessCallRatesTimer Failure',
  props<{ error: any }>()
);