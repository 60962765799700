import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { authAppInitializerFactory, authConfig, authModuleConfig, storageFactory } from '@config/index';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardEffects } from '@effects/index';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '@environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { reducers } from '@reducers/index';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EquipmentInfoEffects } from './store/effects/equipment-info/equipment-info.effects';
import {
    ReportService,
    DashletService,
    RealtimeGatewayService,
    TimeoutService,
    TileGridService,
    AuthenticationService
} from '@services/index';
import { CookieModule } from 'ngx-cookie';
import { TableModule } from 'primeng/table';
import { ContextMenuModule } from 'primeng/contextmenu';
import { CustomerLogoEffects } from './store/effects/entity-logo/entity-logo.effects';
import { ProcessUpTimeEffects } from './store/effects/process-up-time/process-up-time.effects';
import { ProcessUsageEffects } from './store/effects/process-usage/process-usage.effects';
import { HistoricCpuDataEffects } from './store/effects/historic-cpu-data/historic-cpu-data.effects';
import { MemoryUsageEffects } from './store/effects/memory-usage/memory-usage.effects';
import { HistoricMemoryDataEffects } from './store/effects/historic-memory-data/historic-memory-data.effects';
import { DiskUsageEffects } from './store/effects/disk-usage/disk-usage.effects';
import { HistoricDiskUsageDataEffects } from './store/effects/historic-disk-usage-data/historic-disk-usage-data.effects';
import { NetworkConnectivityEffects } from './store/effects/network-connectivity/network-connectivity.effects';
import { HistoricNetworkConnectivityEffects } from './store/effects/historic-network-connectivity/historic-network-connectivity.effects';
import { ProcessAdminBackupDataEffects } from './store/effects/process-admin-backup-data/process-admin-backup-data.effects';
import { ProcessMaintenanceBackupDataEffects } from './store/effects/process-maintenance-backup-data/process-maintenance-backup-data.effects';
import { ProcessAcdEffects } from './store/effects/process-acd/process-acd.effects';
import { ProcessAcdCallsEffects } from './store/effects/process-acd-calls/process-acd-calls.effects';
import { ProcessSupervisorLicensesEffects } from './store/effects/process-supervisor-licenses/process-supervisor-licenses.effects';
import { ProcessWFMStatusRealTimeEffects } from './store/effects/process-wfmstatus-real-time/process-wfmstatus-real-time.effects';
import { ProcessWFMStatusHistoricEffects } from './store/effects/process-wfmstatus-historic/process-wfmstatus-historic.effects';
import { ProcessThresholdDataEffects } from './store/effects/process-threshold-data/process-threshold-data.effects';
import { ProcessNicNamesEffects } from './store/effects/process-nic-names/process-nic-names.effects';
import { ProcessHostNameEffects } from './store/effects/process-host-name/process-host-name.effects';
import { ProcessTotalMemEffects } from './store/effects/process-total-mem/process-total-mem.effects';
import { ProcessSqlDataEffects } from './store/effects/process-sql-data/process-sql-data.effects';
import { ProcessUpCpuMemDataEffects } from './store/effects/process-up-cpu-mem-data/process-up-cpu-mem-data.effects';
import { ProcessVmwareDataEffect } from '@effects/process-vmware-data/process-vmware-data.effects';
import { ProcessDiskDataEffects } from './store/effects/process-disk-data/process-disk-data.effects';
import { ProcessIISDataEffects } from './store/effects/process-iisdata/process-iisdata.effects';
import { ProcessHyperVDataEffects } from './store/effects/process-hyper-vdata/process-hyper-vdata.effects';
import { ProcessLyncMedServerDataEffects } from './store/effects/process-lync-med-server-data/process-lync-med-server-data.effects';
import { ProcessLyncFrontEndServerDataEffects } from './store/effects/process-lync-front-end-server-data/process-lync-front-end-server-data.effects';
import { ProcessFlowControllerDataEffects } from './store/effects/process-flow-controller-data/process-flow-controller-data.effects';
import { ProcessWorkflowDataEffects } from './store/effects/process-workflow-data/process-workflow-data.effects';
import { ProcessWatchedServiceDataEffects } from './store/effects/process-watched-service-data/process-watched-service-data.effects';
import { ProcessCallRateEffects } from './store/effects/process-call-rate/process-call-rate.effects';
import { EntityTimerEffects } from './store/effects/entity-timer/entity-timer.effects';
import { CommonEntityEffects } from './store/effects/common-entity/common-entity.effects';
import { GetLocationsEffects } from './store/effects/get-locations/get-locations.effects';
import { GetRtcpReceiversEffects } from './store/effects/get-rtcp-receivers/get-rtcp-receivers.effects';
import { GetMosCapableReceiversEffects } from './store/effects/get-mos-capable-receivers/get-mos-capable-receivers.effects';
import { GetTypeEquipmentsAtLocationEffects } from './store/effects/get-type-equipments-at-location/get-type-equipments-at-location.effects';
import { GetThresholdsBySettingsEffects } from './store/effects/get-thresholds-by-settings/get-thresholds-by-settings.effects';
import { GetSystemHealthEquipmentEffects } from './store/effects/get-system-health-equipment/get-system-health-equipment.effects';
import { CustomersEffects } from './store/effects/customers/customers.effects';

import { GetUserContextEffects } from './store/effects/get-user-context/get-user-context.effects';

import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DatePipe, DecimalPipe } from '@angular/common';
import { DashletBandwidthPipe } from './pipes/dashlet-bandwidth.pipe';
import { BrandingEffects } from '@effects/branding/branding.effects';
import { LoginComponent } from './components/login/login.component';
import { ReportEffects } from '@effects/report/report.effects';
import { SummaryDocumentEffects } from '@effects/summary-document/summary-document.effects';
import { GetRibbonSystemHealthEquipmentEffects } from '@effects/get-ribbon-system-health-equipment/get-ribbon-system-health-equipment.effects';
import { DashboardInterceptor } from '@interceptors/dashboard.interceptor';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
@NgModule({
    declarations: [AppComponent, LoginComponent, SnackBarComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        RouterModule,
        OAuthModule.forRoot(authModuleConfig),
        EffectsModule.forRoot([DashboardEffects]),
        StoreModule.forRoot(reducers),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        EffectsModule.forFeature([
            BrandingEffects,
            EquipmentInfoEffects,
            CustomerLogoEffects,
            ProcessUpTimeEffects,
            ProcessUsageEffects,
            HistoricCpuDataEffects,
            MemoryUsageEffects,
            HistoricMemoryDataEffects,
            DiskUsageEffects,
            HistoricDiskUsageDataEffects,
            NetworkConnectivityEffects,
            HistoricNetworkConnectivityEffects,
            ProcessAdminBackupDataEffects,
            ProcessMaintenanceBackupDataEffects,
            ProcessAcdEffects,
            ProcessAcdCallsEffects,
            ProcessSupervisorLicensesEffects,
            ProcessWFMStatusRealTimeEffects,
            ProcessWFMStatusHistoricEffects,
            ProcessThresholdDataEffects,
            ProcessNicNamesEffects,
            ProcessHostNameEffects,
            ProcessTotalMemEffects,
            ProcessSqlDataEffects,
            ProcessUpCpuMemDataEffects,
            ProcessVmwareDataEffect,
            ProcessDiskDataEffects,
            ProcessIISDataEffects,
            ProcessHyperVDataEffects,
            ProcessLyncMedServerDataEffects,
            ProcessLyncFrontEndServerDataEffects,
            ProcessFlowControllerDataEffects,
            ProcessWorkflowDataEffects,
            ProcessWatchedServiceDataEffects,
            ProcessCallRateEffects,
            EntityTimerEffects,
            CommonEntityEffects,
            GetLocationsEffects,
            GetRtcpReceiversEffects,
            GetMosCapableReceiversEffects,
            GetTypeEquipmentsAtLocationEffects,
            GetThresholdsBySettingsEffects,
            GetSystemHealthEquipmentEffects,
            GetRibbonSystemHealthEquipmentEffects,
            CustomersEffects,
            GetUserContextEffects,
            ReportEffects,
            SummaryDocumentEffects,
            DashboardInterceptor
        ]),
        CookieModule.forRoot(),
        ContextMenuModule,
        TableModule,
        MatMenuModule,
        MatTooltipModule,
        MatSelectModule,
        MatDialogModule,
        MatSnackBarModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatToolbarModule,
        NgxMatNativeDateModule
    ],
    exports: [TableModule],
    providers: [
        { provide: APP_INITIALIZER, useFactory: authAppInitializerFactory, deps: [AuthenticationService], multi: true },
        { provide: AuthConfig, useValue: authConfig },
        { provide: OAuthModuleConfig, useValue: authModuleConfig },
        { provide: OAuthStorage, useFactory: storageFactory },
        { provide: HTTP_INTERCEPTORS, useClass: DashboardInterceptor, multi: true },
        RealtimeGatewayService,
        TimeoutService,
        TileGridService,
        DashletService,
        ReportService,
        DatePipe,
        DashletBandwidthPipe,
        DecimalPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
