export class SBCSIPInfo {
	private _name: string;
	private _total: number;
	private _last3Min: any;
	private _toolTips: string;

	constructor(name: string, total: number) {
		this._name = name;
		this._total = total;
		this._last3Min = null;
		this._toolTips = '';
	}

	public update(name: string, total: number, last3Min: number, toolTips: string) {
		this.name = name;
		this.total = total;
		this.last3Min = last3Min;
		this.toolTips = toolTips;
	}

	/**
	 * Getter name
	 * @return {string}
	 */
	public get name(): string {
		return this._name;
	}

	/**
	 * Getter total
	 * @return {number}
	 */
	public get total(): number {
		return this._total;
	}

	/**
	 * Getter last3Min
	 * @return {number}
	 */
	public get last3Min(): number {
		return this._last3Min;
	}

	/**
	 * Getter toolTips
	 * @return {string}
	 */
	public get toolTips(): string {
		return this._toolTips;
	}

	/**
	 * Setter name
	 * @param {string} value
	 */
	public set name(value: string) {
		this._name = value;
	}

	/**
	 * Setter total
	 * @param {number} value
	 */
	public set total(value: number) {
		this._total = value;
	}

	/**
	 * Setter last3Min
	 * @param {number} value
	 */
	public set last3Min(value: number) {
		this._last3Min = value;
	}

	/**
	 * Setter toolTips
	 * @param {string} value
	 */
	public set toolTips(value: string) {
		this._toolTips = value;
	}
}
