import { Dashlet } from '@models/index';
import { RealtimeGatewayService } from '@services/index';

interface ServiceGateway {
    id: string;
    name: string;
    alarmMessageCount: number;
    configurationMessageCount: number;
    notificationMessageCount: number;
    performanceMessageCount: number;
    createUTCTime: Date;
    messagesPerSecond: number | null;
    severity: number;
    alarmRate: number | null;
    configurationRate: number | null;
    notificationRate: number | null;
    performanceRate: number | null;
}

export class DashletServiceGatewayInstancesInformation extends Dashlet {
    public readonly commandTypeIdInstances = '70750153-6FCF-495A-B914-539A01EC9F00';

    serviceGateways: ServiceGateway[];
    rates: any[];

    constructor(private realtimeService: RealtimeGatewayService) {
        super();

        // sizing
        this.sizes = [
            {
                id: 0,
                label: 'Small',
                cols: 10,
                rows: 8
            }
        ];
        this.applySize(0);

        this.rates = [{}];
        this.serviceGateways = [];

        // init data
        this.resetData();
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        // unsub realtime feed
        this.unsubData();

        // read settings object
        this.configured = true;

        // update size
        this.updateSize();
    }

    applySize(id: number) {
        super.applySize(id);
        this.updateSize();
    }

    private updateSize() {
        const h = 0;
        const w = 0;
        this.applySizeExpansion(w, h);
    }

    processData(data: any[], id: string, name: string) {
        const record = {
            id: id,
            name: name,
            alarmMessageCount: parseInt(data[0].data[0].AlarmMessageCount),
            configurationMessageCount: parseInt(data[0].data[0].ConfigurationMessageCount),
            notificationMessageCount: parseInt(data[0].data[0].NotificationMessageCount),
            performanceMessageCount: parseInt(data[0].data[0].PerformanceMessagecount),
            createUTCTime: new Date(data[0].data[0].CreateUTCTime),
            messagesPerSecond: null,
            severity: -1,
            alarmRate: null,
            configurationRate: null,
            notificationRate: null,
            performanceRate: null
        };

        const recordTotalCount =
            record.alarmMessageCount +
            record.configurationMessageCount +
            record.notificationMessageCount +
            record.performanceMessageCount;

        const index = this.serviceGateways.findIndex(e => e.name === name);

        if (index === -1) {
            this.serviceGateways.push(record);
        } else {
            let existingRecord = this.serviceGateways[index];
            const existingRecordTotalCount =
                existingRecord.alarmMessageCount +
                existingRecord.configurationMessageCount +
                existingRecord.notificationMessageCount +
                existingRecord.performanceMessageCount;

            const seconds = Math.abs((existingRecord.createUTCTime.getTime() - record.createUTCTime.getTime()) / 1000);

            const messagesPerSecond =
                Math.round((Math.abs(existingRecordTotalCount - recordTotalCount) / seconds) * 10) / 10;

            // calculate rate based on difference between current and last record
            const alarmRate =
                Math.round((Math.abs(existingRecord.alarmMessageCount - record.alarmMessageCount) / seconds) * 10) / 10;
            const configurationRate =
                Math.round(
                    (Math.abs(existingRecord.configurationMessageCount - record.configurationMessageCount) / seconds) *
                        10
                ) / 10;
            const notificationRate =
                Math.round(
                    (Math.abs(existingRecord.notificationMessageCount - record.notificationMessageCount) / seconds) * 10
                ) / 10;
            const performanceRate =
                Math.round(
                    (Math.abs(existingRecord.performanceMessageCount - record.performanceMessageCount) / seconds) * 10
                ) / 10;

            /// severity depends on number of seconds since last record
            let severity = -1;
            if (seconds > 600) {
                severity = 2;
            } else if (seconds > 300) {
                severity = 1;
            } else {
                severity = 0;
            }

            // keep newest record
            if (existingRecord.createUTCTime.getTime() < record.createUTCTime.getTime()) {
                existingRecord = record;
            }

            existingRecord.messagesPerSecond = messagesPerSecond;
            existingRecord.severity = severity;
            existingRecord.alarmRate = alarmRate;
            existingRecord.configurationRate = configurationRate;
            existingRecord.notificationRate = notificationRate;
            existingRecord.performanceRate = performanceRate;
        }
        // required for updating primeng table
        this.serviceGateways = this.serviceGateways.splice(0);

        // sum rates of each gateway to get total rate
        this.rates[0] = {
            alarmRate: 0,
            configurationRate: 0,
            notificationRate: 0,
            performanceRate: 0
        };

        for (const gateway of this.serviceGateways) {
            this.rates[0].alarmRate += gateway.alarmRate;
            this.rates[0].configurationRate += gateway.configurationRate;
            this.rates[0].notificationRate += gateway.notificationRate;
            this.rates[0].performanceRate += gateway.performanceRate;
        }

        this.rates[0].alarmRate = Math.round(this.rates[0].alarmRate * 10) / 10;
        this.rates[0].configurationRate = Math.round(this.rates[0].configurationRate * 10) / 10;
        this.rates[0].notificationRate = Math.round(this.rates[0].notificationRate * 10) / 10;
        this.rates[0].performanceRate = Math.round(this.rates[0].performanceRate * 10) / 10;

        this.rates = this.rates.splice(0);
    }

    resetData() {
        this.serviceGateways = [];
    }

    dispose() {
        this.unsubData();
    }
    private unsubData() {
        if (this.serviceGateways) {
            // unsub data
            for (const gateway of this.serviceGateways) {
                this.realtimeService.unsubscribe(gateway.id, this.commandTypeIdInstances);
            }
        }
    }
}
