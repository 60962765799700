<div class="flex-column gap-l">
    <div class="flex gap-m">
        <div class="flex-column text-align-right equipment-detail-labels">
            <div class="mat-body-2">Total Servers</div>
            <div class="mat-body-2" matTooltip="Total availability over the past 30 days">Total availability last 30 days</div>
            <div class="mat-body-2">Longest Outage</div>
            <div class="mat-body-2">Average Response Time</div>
        </div>
        <div class="flex-column equipment-detail-values" *ngIf="availibilitySummary$ | async as availability">
            <div class="mat-body-1">{{ availability.totalServers || '---' }}</div>
            <div class="mat-body-1">{{ availability.totalAvgAvailability ? (availability.totalAvgAvailability).toFixed(1) + '%' : '---' }}</div>
            <div class="mat-body-1"
                [matTooltip]="availability.longestOutageText !== 'No outages' ? 'View detailed 30 connectivity report for this equipment' : ''"
                (click)="availability.longestOutageText !== 'No outages' ? openNetworkConnectivityReport() : null">
                {{ availability.longestOutageText || '---' }}
            </div>
            <div class="mat-body-1">{{ availability.averageResponse + ' ms' || '---' }}</div>
        </div>
    </div>



    <ng-container *ngIf="this.dashlet.getSize().id === 0">
        <ng-container *ngTemplateOutlet="table; context: { height: 1 }"></ng-container>
    </ng-container>
    <ng-container *ngIf="this.dashlet.getSize().id === 1">
        <ng-container *ngTemplateOutlet="table; context: { height: 6 }"></ng-container>
    </ng-container>
    <ng-container *ngIf="this.dashlet.getSize().id === 2">
        <ng-container *ngTemplateOutlet="table; context: { height: 13 }"></ng-container>
    </ng-container>

</div>
<ng-template #table let-height="height">
    <dashlet-table [columns]="columns" [dataSource]="servers$ | async" [pagination]="true" [pageLength]="tableSize()"
        [tableHeightRows]="tableSize()" [loading]="loading" [menuItems]="menuItems" sortDirection="desc" defaultSort="severity"></dashlet-table>

</ng-template>