import { Component, Injector } from '@angular/core';

import { DashletSettingCheckbox } from '@models/index';

@Component({
    selector: 'dashlet-setting-checkbox',
    templateUrl: 'dashlet-setting-checkbox.component.html',
    styleUrls: ['./settings.component.scss']
})
export class DashletSettingCheckboxComponent {
    data: DashletSettingCheckbox | null = null;

    constructor(private injector: Injector) {
        this.data = this.injector.get('data');
    }

    onModelChange(val: boolean): void {
        this.data!.value = val;
        this.data!.change.next(null);
    }
}
