import { Dashlet } from '@models/index';

export class DashletTrunkGroupUtilization extends Dashlet {
    readonly commandTypeId = 'f91b4082-1e63-43d4-820f-21b316afd1f2';
    equipmentId!: string;
    trunkGroup!: string;

    constructor() {
        super();

        this.sizes = [
            {
                id: 0,
                label: '3 X 3',
                cols: 3,
                rows: 3
            },
            {
                id: 1,
                label: '6 X 6',
                cols: 6,
                rows: 6
            }
        ];
        this.applySize(0);
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        this.configured = v.equipment && v.trunkGroup;

        this.equipmentId = v.equipment && v.equipment.value ? v.equipment.value : null;
        this.trunkGroup = v.trunkGroup && v.trunkGroup.value ? v.trunkGroup.value : null;

        this.generatedNameTag =
            v.customer && v.location && v.trunkGroup ? `${v.location.label} | ${v.trunkGroup.label}` : 'Unconfigured';

        this.customNameTag = v.nameTag;
    }

    dispose() {}

    resetData() {}
}
