import {
    DashletSettingList,
    DashletSettingDropdownGrouped,
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingText
} from '@models/index';
import { DashletSettingsService } from '@services/index';
import { forkJoin } from 'rxjs';

export class DashletSettingsFormSystemHealthSummary extends DashletSettingsForm {
    private customer!: DashletSettingDropdown;
    private location!: DashletSettingDropdown;
    private equipment!: DashletSettingList<any>;

    constructor(private readonly settingsService: DashletSettingsService) {
        super();
        this.init();
        this.loadCustomers();
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);

        if (this.customer.value) {
            this.loadLocations(this.customer.value);
        }

        if (this.location.value) {
            this.loadEquipment(this.location.value);
        }
    }

    private init(): void {
        const equipmentGroup = new DashletSettingsGroup();
        const s: any = (equipmentGroup.settings = []);

        const customer = (this.customer = new DashletSettingDropdown());
        customer.label = 'Customer';
        customer.stateKey = 'customer';
        customer.disabled = true;
        customer.change.subscribe(() => {
            this.clearAndDisable(this.location);
            this.clearAndDisable(this.equipment);
            this.loadLocations(customer.value!);
        });
        s.push(customer);

        const location = (this.location = new DashletSettingDropdown());
        location.label = 'Location';
        location.stateKey = 'location';
        location.disabled = true;
        location.change.subscribe(() => {
            this.clearAndDisable(this.equipment);
            this.loadEquipment(location.value!);
        });
        s.push(location);

        const equipment = (this.equipment = new DashletSettingList<any>());
        equipment.label = 'Equipment';
        equipment.stateKey = 'equipment';
        equipment.disabled = true;
        equipment.multiple = true;
        s.push(equipment);

        const customName = new DashletSettingText(40);
        customName.label = 'Custom Dashlet Name';
        customName.stateKey = 'customName';
        s.push(customName);

        const group = new DashletSettingsGroup();
        group.groups = [equipmentGroup];
        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);
        this.settingsService.getUserAssociatedCustomers().subscribe(
            customers => {
                this.customer.items = customers;
                this.customer.loadingComplete();
            },
            () => {
                this.customer.loadingComplete();
                this.customer.disabled = true;
                // show error notification here
            }
        );
    }

    private loadLocations(customer: string): void {
        this.enableAndLoading(this.location);
        this.settingsService.getLocations(customer).subscribe(
            locations => {
                locations.unshift({ label: 'All', value: '=all=' });
                this.location.items = locations;
                this.location.loadingComplete();
            },
            () => {
                this.location.loadingComplete();
                this.location.disabled = true;
                // show error notification here
            }
        );
    }

    private loadEquipment(location: string) {
        this.enableAndLoading(this.equipment);
        this.equipment.items = [];
        if (this.location.value !== '=all=') {
            this.settingsService.getSystemHealthEquipment(location).subscribe(
                equipmentGroup => {
                    equipmentGroup.forEach(group => {
                        group.items.forEach(e => {
                            this.equipment.items.push(e);
                        });
                    });
                    this.equipment.loadingComplete();
                },
                () => {
                    this.equipment.loadingComplete();
                    this.equipment.disabled = true;
                }
            );
        } else {
            this.settingsService.getLocations(this.customer.value!).subscribe(locations => {
                // this.locations = locations;
                locations.forEach(location => {
                    forkJoin(this.settingsService.getSystemHealthEquipment(location.value)).subscribe(
                        equipmentGroups => {
                            equipmentGroups.forEach(equipmentGroup => {
                                equipmentGroup.forEach(group => {
                                    group.items.forEach(e => {
                                        this.equipment.items.push(e);
                                    });
                                });
                            });
                            this.equipment.loadingComplete();
                        },
                        () => {
                            this.equipment.loadingComplete();
                            this.equipment.disabled = true;
                        }
                    );
                });
            });
        }
    }

    private clearAndDisable(
        dropdown: DashletSettingDropdown | DashletSettingDropdownGrouped | DashletSettingList<string>
    ) {
        dropdown.disabled = true;
        dropdown.items = [];
        if (dropdown instanceof DashletSettingList) {
            dropdown.values = [];
        } else {
            dropdown.value = null;
        }
    }

    private enableAndLoading(
        dropdown: DashletSettingDropdown | DashletSettingDropdownGrouped | DashletSettingList<string>
    ) {
        dropdown.disabled = false;
        dropdown.loadingBegin();
    }
}
