import { NgModule, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, ResolveFn, Router, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './modules/dashboard/components/dashboard/dashboard.component';
import { AuthenticationService, DashboardService } from '@services/index';
import { catchError, map } from 'rxjs';

const dashboardTitle: ResolveFn<string> = (route: ActivatedRouteSnapshot) => {
    const dashboardService = inject(DashboardService);
    return dashboardService.getDashboardName(route.params.id).pipe(
        map(dashboard => {
            return dashboard.Name || 'Dashboards';
        }),
        catchError(() => {
            return 'Dashboards';
        })
    );
};

const authGuard: () => boolean = () => {
    const authService = inject(AuthenticationService);
    const router = inject(Router);
    if (!authService.isAuthenticated()) {
        router.navigate(['Login']);
        return false;
    }
    return true;
};

const routes: Routes = [
    { path: 'Login', component: LoginComponent },
    {
        path: 'dashboard/:id',
        component: DashboardComponent,
        loadChildren: () => import('@modules/dashboard/dashboard.module').then(mod => mod.DashboardModule),
        title: dashboardTitle,
        canActivate: [authGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
