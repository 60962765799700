<div class="flex gap-xl">
	<div class="dashlet-nav">
		<app-dashlet-nav [selected]="dashletNavSelected" (change)="onNavChange($event)"></app-dashlet-nav>
	</div>
	<div class="form">
		<dashlet-settings-group  *ngIf="form?.getSettings()"  [group]="form.getSettings()"
			(settingChange)="onSettingChange($event)">
		</dashlet-settings-group>
		<p  *ngIf="tileGridService.getResizeFailedDashlets().indexOf(dashletNavSelected) > -1">Resize
			failed. Please re-organize dashboard so pinned dashlets are not in the way.</p>
	</div>
</div>