import * as ProcessCallRatesActions from '@actions/process-call-rate/process-call-rate.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService, TimeoutService } from '@services/index';
import { RealTimeNotify } from '@models/index';



@Injectable()
export class ProcessCallRateEffects {

  constructor(private actions$: Actions, private realtimeGatewayService: RealtimeGatewayService, private timeoutService: TimeoutService) { }

  getNotifyProcessCallRates$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessCallRatesActions.GetNotifyProcessCallRate),
    switchMap((action) => this.realtimeGatewayService.observe(action.equipmentId, action.commandTypeId).pipe(
      catchError((err) => of(
        ProcessCallRatesActions.GetNotifyProcessCallRateFailure({ error: err })
      )
      ),
      mergeMap((data: RealTimeNotify) => [
        ProcessCallRatesActions.GetNotifyProcessCallRateSuccess({ data }),
        ProcessCallRatesActions.GetProcessCallRate({ ...data })
      ],
      ),
    ),
    )
  ));

  getProcessCallRates$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessCallRatesActions.GetProcessCallRate),
    mergeMap((action) => this.realtimeGatewayService.getDocument(action.equipmentId, action.commandTypeId).pipe(
      map((data) => ProcessCallRatesActions.GetProcessCallRateSuccess({ data: { data, equipmentId: action.equipmentId } }),
      ),
      catchError((err) => of(ProcessCallRatesActions.GetProcessCallRateFailure({ error: err })))
    ))
  ));

  getProcessCallRatesTimer$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessCallRatesActions.GetProcessCallRateTimer),
    switchMap((action) => this.timeoutService.observeTimerNew(action.equipmentId, action.commandTypeId).pipe(
      catchError((err) => of(
        ProcessCallRatesActions.GetProcessCallRateTimerFailure({ error: err })
      )
      ),
      map((data: any) => ProcessCallRatesActions.GetProcessCallRateTimerSuccess({ data: { data, equipmentId: action.equipmentId } })
      ),
    ),
    )
  ));

}
