<ng-container *ngIf="!openSubcontainer">
    <dashlet-section-processor-memory
        #processorMemory
        [showSystemChart]="dashlet.showSystemChart"
        [showDiskUsage]="false"
        [equipment]="dashlet.equipment"
        [customerId]="dashlet.customer.customerId"
        [locationId]="dashlet.location.locationId"
        [commandTypeIdCPUUsage]="dashlet.commandTypeIdSystem"
        [commandTypeIdMemoryUsage]="dashlet.commandTypeIdSystem"
        [commandTypeIdUpTime]="dashlet.commandTypeIdSwitch"
        [commandTypeIdHostName]="dashlet.commandTypeIdSwitch"
    > </dashlet-section-processor-memory>
    <dashlet-section-network-connectivity
            [showNetworkGraph]="dashlet.showNetworkGraph"
            [equipment]="dashlet.equipment"
            [customerId]="dashlet.customer.customerId"
        >
        </dashlet-section-network-connectivity>
</ng-container>

<!-- create thresholds alerts drilldown -->
<vsm-dashlet-subcontainer *ngIf="openSubcontainer==='createThreshold' || openSubcontainer==='editThreshold'" [navPrevious]="'Ip Office System Health - ' + dashlet.customer.customerName" 
    [navCurrent]="(openSubcontainer==='createThreshold' ? 'Create' : 'Edit') + ' Threshold'" (close)="closeDetails()">
    <ng-container title>{{openSubcontainer==='createThreshold' ? 'New' : 'Edit'}} {{selectedField}} Threshold</ng-container>
    <ng-container body>
        <threshold-item #thresholdItem [settings]="dashlet.getSettings()" [type]="'ipOffice'" [threshold]="selectedThreshold"></threshold-item>
    </ng-container>
    <div footer class="row row-buttons">
        <div>               
            <button type="button" pButton class="btn button-details" (click)="closeDetails()" label="Cancel"></button>
        </div>
        <div *ngIf="openSubcontainer==='editThreshold'; else addThreshold">
            <button type="submit" pButton class="btn button-details" (click)="saveEdit()" [disabled]="!thresholdItem.formValid()" label="Save"></button>
        </div>
        <ng-template #addThreshold>
            <button type="submit" pButton class="btn button-details" (click)="onSubmit()" [disabled]="!thresholdItem.formValid()" label="Save"></button>
        </ng-template>
    </div>
</vsm-dashlet-subcontainer>