import * as ProcessTotalMemActions from '@actions/process-total-mem/process-total-mem.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap
  } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService } from '@services/index';
import { RealTimeNotify } from '@models/index';



@Injectable()
export class ProcessTotalMemEffects {

  constructor(private actions$: Actions, private realtimeGatewayService: RealtimeGatewayService) { }

  getNotifyProcessTotalMem$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessTotalMemActions.GetNotifyProcessTotalMems),
    switchMap((action) => this.realtimeGatewayService.observe(action.equipmentId, action.commandTypeId).pipe(
      catchError((err) => of(
        ProcessTotalMemActions.GetNotifyProcessTotalMemsFailure({ error: err })
      )
      ),
      mergeMap((data: RealTimeNotify) => [
        ProcessTotalMemActions.GetNotifyProcessTotalMemsSuccess({ data }),
        ProcessTotalMemActions.GetProcessTotalMems({ ...data })
      ],
      ),
    ),
    )
  ));

  getProcessTotalMem$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessTotalMemActions.GetProcessTotalMems),
    mergeMap((action) => this.realtimeGatewayService.getDocument(action.equipmentId, action.commandTypeId).pipe(
      map((data) => ProcessTotalMemActions.GetProcessTotalMemsSuccess({ data:{ data, equipmentId: action.equipmentId} }),
      ),
      catchError((err) => of(ProcessTotalMemActions.GetProcessTotalMemsFailure({ error: err })))
    ))
  ));

}
