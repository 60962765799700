/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-undef */
import { TileGridService } from '@services/index';
import { Dashboard, DashboardState, Dashlet, DashletMeta, UserDetails } from '@models/index';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, timer, BehaviorSubject, of, take } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { CompactType } from 'angular-gridster2';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    private dashboardCurrent!: Dashboard;
    private ticker!: Observable<number>;
    private dashletsCurrent: Dashlet[] = [];

    public writableSubject = new BehaviorSubject<boolean>(false);
    readonly canWrite = this.writableSubject.asObservable();
    private dashletsSubject: Subject<Dashlet[]> = new BehaviorSubject(this.dashletsCurrent);
    readonly dashlets = this.dashletsSubject.asObservable();
    constructor(protected http: HttpClient, private title: Title, private tileGridService: TileGridService) {}

    public getDashletsMeta(): Observable<DashletMeta[]> {
        return this.http.get<DashletMeta[]>(environment.dashboardApi + `Dashlet`);
    }

    public updateDashboardState(state: DashboardState): Observable<any> {
        return this.http.put(`${environment.dashboardApi}Dashboard/${this.dashboardCurrent.id}`, state);
    }

    public updateDashboard(dashboard?: Dashboard) {
        return this.http.post(environment.dashboardApi + 'API/DashboardNg/Edit', dashboard || this.dashboardCurrent);
    }

    public getDashboards(entityId: string, userId: string): Observable<Dashboard[]> {
        const params = {
            entityId,
            userId
        };
        return this.http.get<Dashboard[]>(environment.dashboardApi + 'API/DashboardNg/GetDashboardListAndOwner', {
            params
        });
    }

    public deleteDashboard(dashboardId: string): Observable<any> {
        return this.http.post<any>(
            environment.dashboardApi + 'API/DashboardNg/DeleteDashboard',
            JSON.stringify(dashboardId)
        );
    }

    public addDashboard(dashboard: Dashboard): Observable<any> {
        return this.http.post<any>(environment.dashboardApi + 'API/DashboardNg/AddDashboard', dashboard);
    }

    public copyDashboard(dashboardId: string, newDashboard: Dashboard): Observable<any> {
        const params = {
            dashboardId,
            name: newDashboard.name,
            description: newDashboard.description,
            autoStart: newDashboard.autoStart
        };
        return this.http.get<any>(environment.dashboardApi + 'API/DashboardNg/CopyDashboard', {
            params: params
        });
    }

    public loadDashboard(id: string): Observable<Dashboard> {
        return this.http.get<any>(environment.dashboardApi + `Dashboard/${id}`);
    }

    public getDashboardName(id: string): Observable<{ Name: string }> {
        return this.http.get<any>(environment.dashboardApi + `Dashboard/${id}?$select=Name`);
    }

    public getEquipmentInfo(equipmentId: string): Observable<any> {
        return this.http.get<any>(environment.centralApi + `Equipment/${equipmentId}`);
    }

    /**
     * [TODO] : Need to move this from here
     * Gets an observable that emits a date every specified interval.
     * Intervals are relative to the beginning of the minute, not to the time the method is called.
     * @param seconds length of interval in seconds
     */

    private initTick(): void {
        let i = 0;
        const tick = (this.ticker = new Subject<number>());
        let before = new Date().getTime();
        const doTick = () => {
            tick.next(++i);
            const after = new Date().getTime();
            const wait = 1000 - (after - before);
            before = after;
            sub.unsubscribe();
            sub = timer(0, wait).subscribe(doTick);
        };
        let sub = timer(0, 1000).subscribe(doTick);
    }

    private getUpdateTick(): Observable<number> {
        if (!this.ticker) {
            this.initTick();
        }
        return this.ticker;
    }

    public getUpdateInterval(seconds: number): Observable<Date> {
        const sub = new Subject<Date>();
        let nextUpdate = new Date();
        nextUpdate.setSeconds(Math.ceil(nextUpdate.getSeconds() / seconds) * seconds, 0);
        let nextUpdateMs = nextUpdate.getTime();

        this.getUpdateTick().subscribe(() => {
            if (nextUpdateMs <= new Date().getTime()) {
                sub.next(nextUpdate);

                nextUpdate = new Date(nextUpdate);
                nextUpdate.setSeconds(nextUpdate.getSeconds() + seconds);
                nextUpdateMs = nextUpdate.getTime();
            }
        });
        return sub;
    }

    public persistDashboardState(): Observable<DashboardState> {
        const dashletStates: any = [];
        this.dashletsCurrent.forEach((d: any) => {
            const dState: any = {};
            d.saveState(dState);
            dashletStates.push(dState);
        });
        const state: DashboardState = {
            state: JSON.stringify({ dashlets: dashletStates, autoReposition: this.dashboardCurrent.autoReposition }),
            id: this.dashboardCurrent.id,
            name: this.dashboardCurrent.name,
            title: this.dashboardCurrent.description,
            entityId: this.dashboardCurrent.entityId,
            ownerId: this.dashboardCurrent.ownerId,
            active: this.dashboardCurrent.active,
            isShared: this.dashboardCurrent.isShared,
            isDefault: true,
            autoStart: this.dashboardCurrent.autoStart,
            description: this.dashboardCurrent.description,
            ownerName: this.dashboardCurrent.ownerName
        };
        this.updateDashboardState(state).pipe(take(1)).subscribe();
        return of(state);
    }

    public removeDashlet(dashlet: Dashlet) {
        dashlet.dispose();
        this.setDashlets(this.dashletsCurrent.filter(d => d.id !== dashlet.id));
    }

    public setDashlets(dashlets: Dashlet[]) {
        this.dashletsCurrent = dashlets;
        this.dashletsSubject.next(dashlets);
    }

    public getDashboardSettings(): { [key: string]: object } {
        if (this.dashboardCurrent) {
            return this.dashboardCurrent.getSettings();
        }
    }

    public openAccessConcentrator(customerId: string, equipmentId: string, userDetails: UserDetails): void {
        if (userDetails.EntityGroup === 2) {
            window.open(environment.webPortalUrl + 'AccessConcentrator/AccessConcentrator/List?entityId=' + customerId);
        } else {
            window.open(
                environment.webPortalUrl +
                    'AccessConcentrator/AccessConcentrator/EquipmentList?entityId=' +
                    customerId +
                    '&equipmentId=' +
                    equipmentId
            );
        }
    }

    public setDashboard(dashboard: Dashboard): void {
        this.dashboardCurrent = dashboard;
    }

    public applyDashboardSettings(settings: { [key: string]: object }): void {
        this.dashboardCurrent.applySettings(settings);
        this.setDashboard(this.dashboardCurrent);
        this.applyAutoReposition();
    }

    public openAlarms(customerId: string): void {
        window.open(environment.webPortalUrl + '/EquipAlarms/EquipAlarm/SPIndex?entityId=' + customerId);
    }

    public addDashlet(dashlet: Dashlet): void {
        this.setDashlets([...this.dashletsCurrent, dashlet]);
    }

    public applyAutoReposition(): void {
        this.tileGridService.setCompact(
            this.dashboardCurrent.autoReposition ? CompactType.CompactUp : CompactType.None
        );
        this.tileGridService.setSwap(!this.dashboardCurrent.autoReposition);
        this.tileGridService.setDisablePushOnDrag(!this.dashboardCurrent.autoReposition);
        this.tileGridService.optionsUpdated();
        this.persistDashboardState().subscribe();
    }
}
