import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as SummaryDocumentActions from '@actions/summary-document/summary-document.actions';
import { RealtimeGatewayService, TimeoutService } from '@services/index';
import { interval, map, mergeMap, takeUntil, switchMap, startWith, merge } from 'rxjs';
import * as CommonEntityActions from '@actions/common-entity/common-entity.actions';
import { Router } from '@angular/router';

@Injectable()
export class SummaryDocumentEffects {
    constructor(
        private actions$: Actions,
        private realtimeGatewayService: RealtimeGatewayService,
        private router: Router,
        private timeoutService: TimeoutService
    ) {}

    private saveCurrentLocation(): void {
        let redirectUrl: string = location.pathname;
        if (redirectUrl && redirectUrl.toLowerCase() !== '/Login' && redirectUrl.toLowerCase() !== '/') {
            sessionStorage.setItem('postLoginRedirectUrl', redirectUrl);
        }
    }

    getSummaryDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SummaryDocumentActions.getSummaryDocument),
            mergeMap(action => {
                this.realtimeGatewayService.getSummaryDocument(action.equipmentId, action.commandTypeId);
                return this.realtimeGatewayService.getWorkerMessageObservable().pipe(
                    map(data => {
                        if (data && data.data.status && data.data.status === 401) {
                            this.saveCurrentLocation();
                            this.router.navigate(['/Login']);
                            return CommonEntityActions.GetCommonEntitysFailure({ error: 'Error occured' });
                        } else if (data && data.data.status && data.data.status === 404) {
                            return SummaryDocumentActions.getSummaryDocumentFailure({ error: 'Error occured' });
                        } else {
                            const timeStamp =
                                data && data.data[0] && data.data[0].timestamp ? data.data[0].timestamp : new Date();
                            const idKey = data.equipmentId + ':' + data.commandTypeId;

                            if (this.timeoutService.timers.get(idKey.toLowerCase())) {
                                this.timeoutService.documentRecieved(
                                    data.equipmentId + ':' + data.commandTypeId,
                                    data.commandTypeId,
                                    timeStamp,
                                    data.equipmentId
                                );
                            }
                            if (data.url.includes('summarydocument')) {
                                return SummaryDocumentActions.getSummaryDocumentSuccess({
                                    data: {
                                        data: data.data,
                                        uniqueId: (data.equipmentId + data.commandTypeId).toLowerCase()
                                    }
                                });
                            } else {
                                return CommonEntityActions.GetCommonEntitysSuccess({
                                    data: {
                                        data: data.data,
                                        uniqueId: (data.equipmentId + data.commandTypeId).toLowerCase()
                                    }
                                });
                            }
                        }
                    })
                );
            })
        )
    );

    getSummaryDocumentWithRepeat$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SummaryDocumentActions.getSummaryDocumentWithRepeat),
            mergeMap(action => {
                const equipIds = action.equipmentId;
                return interval(action.interval * 1000).pipe(
                    startWith(0),
                    switchMap(() => {
                        const observables = equipIds.map(equipId => {
                            this.realtimeGatewayService.getSummaryDocument(equipId, action.commandTypeId);
                            return this.realtimeGatewayService.getWorkerMessageObservable().pipe(
                                map(data => {
                                    if (data && data.data.status && data.data.status === 401) {
                                        this.saveCurrentLocation();
                                        this.router.navigate(['/Login']);
                                        return CommonEntityActions.GetCommonEntitysFailure({ error: 'Error occured' });
                                    } else if (data && data.data.status && data.data.status === 404) {
                                        return SummaryDocumentActions.getSummaryDocumentFailure({
                                            error: 'Error occured'
                                        });
                                    } else {
                                        if (data.url.includes('summarydocument')) {
                                            return SummaryDocumentActions.getSummaryDocumentSuccess({
                                                data: {
                                                    data: data.data,
                                                    uniqueId: (data.equipmentId + data.commandTypeId).toLowerCase()
                                                }
                                            });
                                        } else {
                                            return CommonEntityActions.GetCommonEntitysSuccess({
                                                data: {
                                                    data: data.data,
                                                    uniqueId: (data.equipmentId + data.commandTypeId).toLowerCase()
                                                }
                                            });
                                        }
                                    }
                                })
                            );
                        });
                        return merge(...observables);
                    }),
                    takeUntil(this.actions$.pipe(ofType(SummaryDocumentActions.stopSummaryDocumentRepeat)))
                );
            })
        )
    );
}
