/* eslint-disable no-undef */
import { Customer, Dashlet, Equipment, Location } from '@models/index';

export interface CallRate {
    period: string;
    totalCompleted: number;
    busiestHour: string;
    busiestInterval: string;
    busiestCompleted: number;
    peakAllTimeDate?: string;
}

interface MediaGateway {
    name: string;
    number: number;
    ipAddress: string;
    ipnr: number;
    control: string;
    type: string;
    linkStatus: string;
    alarmsMinor: number;
    alarmsMajor: number;
    alarmsWarning: number;
}

export interface Ds1 {
    name: string | undefined;
    ds1Number: string;
    erroredSeconds: number;
    burstyErroredSeconds: number;
    severelyErroredSeconds: number;
    unavailableFailedSeconds: number;
    controlledSlipSeconds: number;
    lossOfFrameCount: number;
    ds1Severity: number;
    alarms: number;
    ipNetworkRegion: number;
}

interface CdrLink {
    name: string;
    status: string;
    severity: number;
}

export class DashletSystemHealthAcm extends Dashlet {
    public readonly commandTypeIdCallRate = '7C2B8BEC-273B-413E-983E-6E5B243F996F';
    public readonly commandTypeIdCallRateAllTime = 'B9E996C5-B11E-4DF7-829E-B43C3F2F7B18';
    public readonly commandTypeIdCdrLink = '9CC77E8D-1458-49CD-B090-1ED21A546EED';
    public readonly commandTypeIdMediaGateway = 'A2060295-4CC8-4EA2-A404-2EE4BB001A09';
    public readonly commandTypeIdDs1Log = 'b7018135-5a9c-4375-8587-1d9ab3fbbb71';
    public readonly commandTypeIdMediaGatewayInfo = 'A2722BD0-B5E5-42AF-A782-3CC1BFF370D2';
    public readonly commandTypeIdDs1LogName = '31A0CDC5-F116-459E-9C70-8E96AF977653';

    readonly interval = 30;
    customer!: Customer;
    location!: Location;
    equipment!: Equipment;

    callRates!: CallRate[];
    cdrLink!: CdrLink[];
    mediaGateways: { [severity: number]: MediaGateway[] } = {};
    ds1s: { [severity: number]: Ds1[] } = {};
    ds1NameMap!: Map<string, string>;

    showSystemChart = false;
    showNetworkGraph = false;
    showCallRate = false;
    showServiceStatus = false;
    showCdrLinkStatus = false;
    showMediaGateway = false;
    showDs1Status = false;
    showCustomScripts = false;

    commandsRunData: any = [];
    commandsRunDataLoading = true;

    callRateTimedOut = true;
    cdrLinkTimedOut = true;
    mediaGatewayTimedOut = true;
    ds1LogTimedOut = true;
    mediaGatewayInfoTimedOut = true;
    ds1LogNameTimedOut = true;

    public callRateLoading = true;

    constructor() {
        super();

        // sizing
        this.sizes = [
            {
                id: 0,
                label: 'Standard',
                cols: 5,
                rows: 6
            }
        ];

        this.applySize(0);

        // init data
        this.resetData();
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        // read settings object
        this.configured = v.customer && v.location && v.equipment;
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
            // this.loadCustomerLogo();
        } else {
            this.customer = new Customer('', '');
        }
        if (v.location) {
            this.location = new Location(v.location.value, v.location.label);
        } else {
            this.location = new Location('', '');
        }
        if (v.equipment) {
            this.equipment = new Equipment(v.equipment.value, v.equipment.label);
        } else {
            this.equipment = new Equipment('', '');
        }
        this.generatedNameTag = this.configured ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;

        this.showSystemChart = v.showSystemChart || false;
        this.showNetworkGraph = v.showNetworkGraph || false;
        this.showCallRate = v.showCallRate || false;
        this.showServiceStatus = v.showServiceStatus || false;
        this.showCdrLinkStatus = v.showCdrLinkStatus || false;
        this.showMediaGateway = v.showMediaGateway || false;
        this.showDs1Status = v.showDs1Status || false;
        this.showCustomScripts = v.showCustomScripts || false;

        // apply size
        let h = 0;
        if (this.showSystemChart) {
            h += 1.9;
        }
        if (this.showNetworkGraph) {
            h += 2.1;
        }
        if (this.showCallRate) {
            h += 1.9;
        }
        if (this.showServiceStatus) {
            h += 1.15;
        }
        if (this.showCdrLinkStatus) {
            h += 1.15;
        }
        if (this.showMediaGateway) {
            h += 1.15;
        }
        if (this.showDs1Status) {
            h += 1.15;
        }
        if (this.showCustomScripts) h += 4;
        this.applySizeExpansion(0, h);

        // sub realtime feed
        // this.subData();
    }
    resetData() {
        this.callRates = [];
        this.cdrLink = [];
        this.mediaGateways = { 0: [], 2: [] };
        this.ds1s = { 0: [], 1: [], 2: [] };
    }

    dispose() {}

    processCallRateAllTime(data: any) {
        const row = { ...data[0].data[0] };
        if (row !== null) {
            if (row.BusiestHour.length < 4) {
                row.BusiestHour = '0' + row.BusiestHour;
            }

            const peakAllTime = {
                period: 'Peak All Time',
                totalCompleted: row.BusiestHourCompleted,
                busiestHour: row.BusiestHour.substr(0, 2) + ':' + row.BusiestHour.substr(2),
                busiestInterval: row.BusiestInterval,
                busiestCompleted: row.BusiestIntervalCompleted,
                peakAllTimeDate: row.BusiestIntervalDate
            };
            const index = this.callRates.findIndex(v => v.period === 'Peak All Time');
            if (index !== -1) {
                this.callRates[index] = peakAllTime;
            } else {
                this.callRates = [...this.callRates, peakAllTime];
            }
            this.callRateLoading = false;
        }
    }

    processDs1LogName(data: any) {
        const row = data[0].data;
        if (row !== null) {
            this.ds1NameMap = new Map<string, string>();
            for (const rowData of row) {
                this.ds1NameMap.set(rowData.Port, rowData.Name);
            }
        }
    }

    processMediaGatewayInfo(data: any) {
        const row = data && data.length ? data[0].data : null;
        if (row !== null) {
            const mediaGateways = this.mediaGateways[0].concat(this.mediaGateways[2]);
            for (const rowData of row) {
                const result = mediaGateways.find(element => element.number === rowData.Port);
                if (result) {
                    result.name = rowData.Server;
                    result.ipAddress = rowData.IPAddress;
                    result.ipnr = rowData.IPNR;
                    result.control = rowData.ControlIPAddress;
                    result.type = rowData.Type;
                }
            }
            this.mediaGateways[0] = mediaGateways.filter(element => element.linkStatus === 'up');
            this.mediaGateways[2] = mediaGateways.filter(element => element.linkStatus !== 'up');
        }
    }

    processDs1Log(data: any[]) {
        if (data !== null) {
            let ds1All = this.ds1s[0].concat(this.ds1s[1]).concat(this.ds1s[2]);
            for (let rowData of data) {
                for (let dataObject of rowData.data) {
                    let ds1Number = dataObject.Port;
                    let erroredSeconds = dataObject.ErroredSeconds || null;
                    let burstyErroredSeconds = dataObject.BurstyErroredSeconds || null;
                    let severelyErroredSeconds = dataObject.SeverelyErroredSeconds || null;
                    let unavailableFailedSeconds = dataObject.UnavailableFailedSeconds || null;
                    let controlledSlipSeconds = dataObject.ControlledSlipSeconds || null;
                    let lossOfFrameCount = dataObject.LossOfFrameCount || null;
                    let ds1Severity = -1;

                    const hasUnavailableFailedSeconds = +unavailableFailedSeconds > 0;
                    const allErrorValuesNull = [
                        erroredSeconds,
                        burstyErroredSeconds,
                        severelyErroredSeconds,
                        unavailableFailedSeconds,
                        controlledSlipSeconds,
                        lossOfFrameCount
                    ].every(val => val === null);

                    const allErrorsZero = [+erroredSeconds, +burstyErroredSeconds, +severelyErroredSeconds].every(
                        val => val === 0
                    );

                    const controlledSlipZeroOrNull = controlledSlipSeconds === null || +controlledSlipSeconds === 0;
                    const lossOfFrameZeroOrNull = lossOfFrameCount === null || +lossOfFrameCount === 0;

                    if (hasUnavailableFailedSeconds || allErrorValuesNull) {
                        ds1Severity = 2;
                    } else if (allErrorsZero && controlledSlipZeroOrNull && lossOfFrameZeroOrNull) {
                        ds1Severity = 0;
                    } else {
                        ds1Severity = 1;
                    }

                    let result = ds1All.find(element => element.ds1Number === ds1Number);
                    if (!result) {
                        ds1All.push({
                            name: this.ds1NameMap ? this.ds1NameMap.get(ds1Number) : '',
                            ds1Number: ds1Number,
                            erroredSeconds: erroredSeconds,
                            burstyErroredSeconds: burstyErroredSeconds,
                            severelyErroredSeconds: severelyErroredSeconds,
                            unavailableFailedSeconds: unavailableFailedSeconds,
                            controlledSlipSeconds: controlledSlipSeconds,
                            lossOfFrameCount: lossOfFrameCount,
                            ds1Severity: ds1Severity,
                            alarms: -1,
                            ipNetworkRegion: -1
                        });
                    } else {
                        result.erroredSeconds = erroredSeconds;
                        result.burstyErroredSeconds = burstyErroredSeconds;
                        result.severelyErroredSeconds = severelyErroredSeconds;
                        result.unavailableFailedSeconds = unavailableFailedSeconds;
                        result.controlledSlipSeconds = controlledSlipSeconds;
                        result.lossOfFrameCount = lossOfFrameCount;
                        result.ds1Severity = ds1Severity;
                        result.alarms = -1;
                        result.ipNetworkRegion = -1;
                    }
                }
            }
            this.ds1s[0] = ds1All.filter(element => element.ds1Severity === 0);
            this.ds1s[1] = ds1All.filter(element => element.ds1Severity === 1);
            this.ds1s[2] = ds1All.filter(element => element.ds1Severity === 2);
        }
    }
    processMediaGateway(data: any[]) {
        if (data[1] && data.length) {
            const rows = data[1].data;

            const mediaGateways = this.mediaGateways[0].concat(this.mediaGateways[2]);
            rows.forEach(row => {
                const result = mediaGateways.find(element => element.number === row.GatewayNumber);
                if (!result) {
                    mediaGateways.push({
                        name: 'Placeholder',
                        number: row.GatewayNumber,
                        ipAddress: 'X',
                        ipnr: -1,
                        control: 'X',
                        type: 'x',
                        alarmsMajor: row.MajorAlarms,
                        alarmsMinor: row.MinorAlarms,
                        alarmsWarning: row.WarningAlarms,
                        linkStatus: row.LinkStatus
                    });
                } else {
                    result.number = row.GatewayNumber;
                    result.alarmsMajor = row.MajorAlarms;
                    result.alarmsMinor = row.MinorAlarms;
                    result.alarmsWarning = row.WarningAlarms;
                    result.linkStatus = row.LinkStatus;
                }
            });

            let sortedArray = mediaGateways.slice(0);
            sortedArray.sort((leftSide, rightSide): number => {
                if (Number(leftSide.number) < Number(rightSide.number)) return -1;
                if (Number(leftSide.number) > Number(rightSide.number)) return 1;
                return 0;
            });
            this.mediaGateways[0] = sortedArray.filter(element => element.linkStatus === 'up');
            this.mediaGateways[2] = sortedArray.filter(element => element.linkStatus !== 'up');
        }
    }

    processCdrlink(data: any[]) {
        if (data !== null) {
            const cdrs = [];
            for (const cdrData of data) {
                let cdrStatus = cdrData.data[0].Status.replace('CDR not', 'not');
                if (cdrStatus.toString().toLowerCase() === 'up') {
                    cdrStatus += ' - ' + Math.round(+cdrData.data[0].CDRPercentageBufferFull) + '% buffer utilization';
                }
                cdrs.push({
                    name: cdrData.data[0].Link,
                    status: cdrStatus,
                    severity:
                        cdrData.data[0].Status.toLowerCase() === 'up'
                            ? 0
                            : cdrData.data[0].Status.toLowerCase() === 'cdr not administered'
                            ? -1
                            : 2
                });
            }
            this.cdrLink = cdrs;
        }
    }

    processCallRate(data: any[]) {
        const row = { ...data[0].data[0] };
        if (row !== null) {
            const callRates = [];
            if (row.MeasuredHour.length < 4) row.MeasuredHour = '0' + row.MeasuredHour;
            if (row.TodayPeakBusiestHourMeasuredHour.length < 4)
                row.TodayPeakBusiestHourMeasuredHour = '0' + row.TodayPeakBusiestHourMeasuredHour;
            if (row.YestPeakBusiestHourMeasuredHour.length < 4)
                row.YestPeakBusiestHourMeasuredHour = '0' + row.YestPeakBusiestHourMeasuredHour;
            let lasthour = {
                period: 'Last Hour',
                totalCompleted: row.LastHourCallsCompleted,
                busiestHour: row.MeasuredHour.substr(0, 2) + ':' + row.MeasuredHour.substr(2),
                busiestInterval: row.LastHourBusyInterval,
                busiestCompleted: row.LastHourBusyIntervalCallsCompleted
            };
            let todayPeak = {
                period: 'Today Peak',
                totalCompleted: row.TodayPeakBusiestHourCallsCompleted,
                busiestHour:
                    row.TodayPeakBusiestHourMeasuredHour.substr(0, 2) +
                    ':' +
                    row.TodayPeakBusiestHourMeasuredHour.substr(2),
                busiestInterval: row.TodayPeakBusiestHourBusiestInterval,
                busiestCompleted: row.TodayPeakBusiestHourBusiestIntervalCallsCompleted
            };
            let yesterdayPeak = {
                period: 'Yesterday Peak',
                totalCompleted: row.YestPeakBusiestHourCallsCompleted,
                busiestHour:
                    row.YestPeakBusiestHourMeasuredHour.substr(0, 2) +
                    ':' +
                    row.YestPeakBusiestHourMeasuredHour.substr(2),
                busiestInterval: row.YestPeakBusiestHourBusiestInterval,
                busiestCompleted: row.YestPeakBusiestHourBusiestIntervalCallsCompleted
            };

            //The following checks if the entries exist first and then overwrites - else add to front
            let index = this.callRates.findIndex(v => v.period === 'Yesterday Peak');
            if (index !== -1) this.callRates[index] = yesterdayPeak;
            else this.callRates.unshift(yesterdayPeak);

            index = this.callRates.findIndex(v => v.period === 'Today Peak');
            if (index !== -1) this.callRates[index] = todayPeak;
            else this.callRates.unshift(todayPeak);

            index = this.callRates.findIndex(v => v.period === 'Last Hour');
            if (index !== -1) this.callRates[index] = lasthour;
            else this.callRates.unshift(lasthour);
        }
    }
}
