import { SelectItemGroup, SelectItem, DashletSetting, DashletSettingLoadable } from '@models/index';

export class DashletSettingDropdownGrouped extends DashletSetting implements DashletSettingLoadable {
    items!: SelectItemGroup[];

    private pValue!: string | null;
    get value(): string | null {
        return this.pValue;
    }
    set value(value: string | null) {
        this.pValue = value;
        // clear loading items if selection changes
        if (this.pLoadingItem && value !== this.pLoadingItem.value) {
            this.pLoadingItem = null;
        }
    }

    private loadingSem = 0;

    private pLoadingItem!: SelectItem | null;
    get loadingItem(): SelectItem | null {
        return this.pLoadingItem;
    }

    getLoading() {
        return this.loadingSem > 0;
    }

    loadingBegin() {
        this.loadingSem++;
    }

    loadingComplete() {
        if (this.loadingSem > 0) {
            this.loadingSem--;
        }
    }

    loadState(obj: { [key: string]: any }): void {
        this.pLoadingItem = obj[this.stateKey];
        this.pValue = this.pLoadingItem ? this.loadingItem!.value : null;
    }

    saveState(obj: { [key: string]: any }): void {
        obj[this.stateKey] = this.getSelectedItem();
    }

    private getSelectedItem(): SelectItem | null {
        // check for null selections
        if (this.value === null) {
            return null;
        }
        if (typeof this.value === 'string' && this.value.trim() === '') {
            return null;
        }

        // find item from value
        let item: SelectItem | null = null;
        if (this.items !== null) {
            this.items.forEach(group => {
                group.items.forEach(i => {
                    if (i.value === this.value) {
                        item = i;
                    }
                });
            });
        }
        if (item === null && this.loadingItem!.value === this.value) {
            item = this.loadingItem;
        }

        // create default item if cannot find item
        if (item === null) {
            item = { label: 'Unknown', value: this.value };
        }
        return item;
    }
}
