/* eslint-disable no-undef */
import { Customer, Dashlet, Equipment, Location } from '@models/index';

interface EngelbartSoftwareComp {
    name: string;
    status: string;
    details: any[];
}

export class DashletSystemHealthEngelbartSoftwareEsuites extends Dashlet {
    public readonly commandTypeIdComponentInfo = 'CA3BDA36-70B4-4207-87EC-20EB7CAF8766';

    readonly interval = 30;

    public equipment: Equipment;
    public location: Location;
    public customer: Customer;
    public lshName: string;
    public uptimeMs: number;
    public loggedInUsers: number;

    public showSystemChart: boolean = false;
    public showNetworkConnectChart: boolean = false;
    public showComponents: boolean = false;
    showCustomScripts = false;

    commandsRunData: any = [];
    commandsRunDataLoading = true;

    public components: EngelbartSoftwareComp[] = [];

    constructor() {
        super();

        //sizing
        this.sizes = [
            {
                id: 0,
                label: 'Standard',
                cols: 5,
                rows: 6
            }
        ];
        this.applySize(0);

        //init data
        this.resetData();
    }

    public applySettings(v: { [key: string]: any }): void {
        super.applySettings(v);

        //read settings object
        this.configured = v.customer && v.location && v.equipment;
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        if (v.location) {
            this.location = new Location(v.location.value, v.location.label);
        } else {
            this.location = new Location('', '');
        }
        if (v.equipment) {
            this.equipment = new Equipment(v.equipment.value, v.equipment.label);
        } else {
            this.equipment = new Equipment('', '');
        }
        this.generatedNameTag = this.configured ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;
        this.lshName = this.configured ? v.equipment.label : null;

        this.showSystemChart = v.showSystemChart || false;
        this.showNetworkConnectChart = v.showNetworkConnectChart || false;
        this.showComponents = v.showComponents || false;
        this.showCustomScripts = v.showCustomScripts || false;

        //apply size
        let h = 0;
        if (this.showSystemChart) h += 2;
        if (this.showNetworkConnectChart) h += 2;
        if (this.showComponents) h += 2;
        if (this.showCustomScripts) h += 4;
        this.applySizeExpansion(0, h);
    }

    public processComponentData(data: any[]): void {
        this.components = [];
        if (data.length > 0) {
            data.forEach(component => {
                if (component.id !== 'CustomCommandOutput') {
                    this.components.push({
                        name: component.data[0].Name,
                        status: component.data[0].Status,
                        details: component.data[0].Details
                    });
                }
            });
        }
    }

    public resetData(): void {
        this.components = [];
    }

    public dispose(): void {}
}
