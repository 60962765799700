<ng-container *ngIf="!openSubcontainer">
    <div class="dashlet-cell database-health-container" [ngClass]="{ small: smallLayout, large: largeLayout }">
        <!-- table titles -->
        <div class="database-health-header">
            <div class="database-health-data title severity-cell selectable" (click)="sort('severity')">
                <span
                    class="fa sort-arrow-severity"
                    [ngClass]="{ 'fa-sort': isSorting('severity'), 'fa-sort-asc': isSortingAsc('severity'), 'fa-sort-desc': isSortingDesc('severity') }"
                >
                </span>
            </div>
            <div class="database-healths">
                <div class="database-health">
                    <div class="database-health" [ngClass]="!hugeLayout ? 'regular' : 'large'">
                        <div class="database-health-data title name-cell selectable" (click)="sort('customerName')">
                            <span class="dashlet-value dashlet-value-xs title" title="Customer">Customer</span>
                            <span
                                class="fa"
                                [ngClass]="{
                                    'fa-sort': isSorting('customerName'),
                                    'fa-sort-asc': isSortingAsc('customerName'),
                                    'fa-sort-desc': isSortingDesc('customerName')
                                }"
                            >
                            </span>
                        </div>
                        <div class="database-health-data title large-number-cell selectable" (click)="sort('dapBehind')">
                            <span class="dashlet-value dashlet-value-xs title" title="Dap Behind">Dap Behind (Mins) </span>
                            <span
                                class="fa"
                                [ngClass]="{
                                    'fa-sort': isSorting('dapBehind'),
                                    'fa-sort-asc': isSortingAsc('dapBehind'),
                                    'fa-sort-desc': isSortingDesc('dapBehind')
                                }"
                            >
                            </span>
                        </div>
                        <div class="database-health-data title large-number-cell selectable" (click)="sort('spaceUsed')">
                            <span class="dashlet-value dashlet-value-xs title" title="Spaced Used">Space Used %</span>
                            <span
                                class="fa"
                                [ngClass]="{
                                    'fa-sort': isSorting('spaceUsed'),
                                    'fa-sort-asc': isSortingAsc('spaceUsed'),
                                    'fa-sort-desc': isSortingDesc('spaceUsed')
                                }"
                            >
                            </span>
                        </div>
                        <div class="database-health-data title large-number-cell selectable" (click)="sort('updateOffset')">
                            <span class="dashlet-value dashlet-value-xs title" title="Update Offset" title="Update Offset">Update Offset (Mins) </span>
                            <span
                                class="fa"
                                [ngClass]="{
                                    'fa-sort': isSorting('updateOffset'),
                                    'fa-sort-asc': isSortingAsc('updateOffset'),
                                    'fa-sort-desc': isSortingDesc('updateOffset')
                                }"
                            >
                            </span>
                        </div>
                        <div class="database-health-data title number-cell selectable" (click)="sort('dtu')">
                            <span class="dashlet-value dashlet-value-xs title" title="DTU %">DTU %</span>
                            <span
                                class="fa"
                                [ngClass]="{ 'fa-sort': isSorting('dtu'), 'fa-sort-asc': isSortingAsc('dtu'), 'fa-sort-desc': isSortingDesc('dtu') }"
                            >
                            </span>
                        </div>
                        <div class="database-health-data title number-cell selectable" (click)="sort('cpu')">
                            <span class="dashlet-value dashlet-value-xs title" title="CPU %">CPU %</span>
                            <span
                                class="fa"
                                [ngClass]="{ 'fa-sort': isSorting('cpu'), 'fa-sort-asc': isSortingAsc('cpu'), 'fa-sort-desc': isSortingDesc('cpu') }"
                            >
                            </span>
                        </div>
                        <div class="database-health-data title number-cell selectable" (click)="sort('memory')">
                            <span class="dashlet-value dashlet-value-xs title" title="Memory %">Memory %</span>
                            <span
                                class="fa"
                                [ngClass]="{ 'fa-sort': isSorting('memory'), 'fa-sort-asc': isSortingAsc('memory'), 'fa-sort-desc': isSortingDesc('memory') }"
                            >
                            </span>
                        </div>
                        <div class="database-health-data title number-cell selectable" (click)="sort('dataIO')">
                            <span class="dashlet-value dashlet-value-xs title" title="Data IO %">Data IO %</span>
                            <span
                                class="fa"
                                [ngClass]="{ 'fa-sort': isSorting('dataIO'), 'fa-sort-asc': isSortingAsc('dataIO'), 'fa-sort-desc': isSortingDesc('dataIO') }"
                            >
                            </span>
                        </div>
                        <div class="database-health-data title number-cell selectable" (click)="sort('logWrite')">
                            <span class="dashlet-value dashlet-value-xs title" title="Log Write %">Log Write %</span>
                            <span
                                class="fa"
                                [ngClass]="{
                                    'fa-sort': isSorting('logWrite'),
                                    'fa-sort-asc': isSortingAsc('logWrite'),
                                    'fa-sort-desc': isSortingDesc('logWrite')
                                }"
                            >
                            </span>
                        </div>
                        <div class="database-health-data title so-cell selectable" (click)="sort('so')">
                            <span class="dashlet-value dashlet-value-xs title" title="Service Objective">S.O. </span>
                            <span class="fa" [ngClass]="{ 'fa-sort': isSorting('so'), 'fa-sort-asc': isSortingAsc('so'), 'fa-sort-desc': isSortingDesc('so') }">
                            </span>
                        </div>
                        <div class="database-health-data title graph-cell">
                            <span class="dashlet-value dashlet-value-xs title" title="DTU - last 7 days">DTU - last 7 days</span>
                        </div>
                        <div class="database-health-data title recommendations-cell selectable" (click)="sort('autoScaleAction')">
                            <span class="dashlet-value dashlet-value-xs title" title="Recommendations">Recommendations</span>
                            <span
                                class="fa"
                                [ngClass]="{
                                    'fa-sort': isSorting('autoScaleAction'),
                                    'fa-sort-asc': isSortingAsc('autoScaleAction'),
                                    'fa-sort-desc': isSortingDesc('autoScaleAction')
                                }"
                            >
                            </span>
                        </div>
                    </div>
                </div>

                <div class="database-health" [ngClass]="!hugeLayout ? 'regular' : 'large'">
                    <div class="database-health-data title name-cell">
                        <input class="col-filter" [(ngModel)]="nameSearch" placeholder="" />
                    </div>
                </div>
            </div>
        </div>
        <!--network connectivity table -->
        <div class="scrollpanel" [ngClass]="{ small: smallLayout, large: largeLayout, huge: hugeLayout }">
            <div class="database-healths database-health-body">
                <div
                    class="database-health"
                    [title]="data.titleString"
                    *ngFor="
                        let data of dashlet.databaseHealthDataArray | filter: { customerName: nameSearch } | sort: sortColumn:sortDesc:sortType;
                        index as i;
                        odd as isOdd;
                        even as isEven
                    "
                    [class.content-row]="isEven"
                    [class.content-row-alt]="isOdd"
                >
                    <div class="database-health-data severity-cell has-prompt-overlay" [style.background-color]="getSeverity(data)"></div>
                    <div class="database-health">
                        <div class="database-health-data name-cell has-prompt-overlay">
                            <span class="text-overflow dashlet-value dashlet-value-xs dashlet-value-name">{{ data.customerName }}</span>
                        </div>
                        <div class="database-health-data dashlet-value dashlet-value-xs dashlet-value-number large-number-cell">
                            <span class="dashlet-value dashlet-value-xs dashlet-value-number">{{ data.dapBehind }}</span>
                        </div>
                        <div class="database-health-data dashlet-value dashlet-value-xs dashlet-value-number large-number-cell">
                            <span class="dashlet-value dashlet-value-xs dashlet-value-number">{{ data.spaceUsed }}</span>
                        </div>
                        <div class="database-health-data dashlet-value dashlet-value-xs dashlet-value-number large-number-cell">
                            <span class="dashlet-value dashlet-value-xs dashlet-value-number">{{ data.updateOffset }}</span>
                        </div>
                        <div class="database-health-data dashlet-value dashlet-value-xs dashlet-value-number number-cell">
                            <span class="dashlet-value dashlet-value-xs dashlet-value-number">{{ data.dtu }}</span>
                        </div>
                        <div class="database-health-data dashlet-value dashlet-value-xs dashlet-value-number number-cell">
                            <span class="dashlet-value dashlet-value-xs dashlet-value-number">{{ data.cpu }}</span>
                        </div>
                        <div class="database-health-data dashlet-value dashlet-value-xs dashlet-value-number number-cell">
                            <span class="dashlet-value dashlet-value-xs dashlet-value-number">{{ data.memory }}</span>
                        </div>
                        <div class="database-health-data dashlet-value dashlet-value-xs dashlet-value-number number-cell">
                            <span class="dashlet-value dashlet-value-xs dashlet-value-number">{{ data.dataIO }}</span>
                        </div>
                        <div class="database-health-data dashlet-value dashlet-value-xs dashlet-value-number number-cell">
                            <span class="dashlet-value dashlet-value-xs dashlet-value-number">{{ data.logWrite }}</span>
                        </div>
                        <div class="database-health-data dashlet-value dashlet-value-xs dashlet-value-number so-cell">
                            <span class="dashlet-value dashlet-value-xs dashlet-value-number">{{ data.so }}</span>
                        </div>
                        <div class="database-health-data dashlet-value dashlet-value-xs dashlet-value-number graph-cell">
                            <p-chart
                                #dtuHistoricChart
                                *ngIf="data.dtuHistoric; else noChart"
                                type="line"
                                [data]="data.dtuHistoric"
                                [options]="dtuHistoricOptions"
                                [height]="'40px'"
                                [width]="'130px'"
                            >
                            </p-chart>
                            <ng-template #noChart>
                                <span class="dashlet-value dashlet-value-xs dashlet-value-number">---</span>
                            </ng-template>
                        </div>
                        <div class="database-health-data dashlet-value dashlet-value-xs dashlet-value-number recommendations-cell">
                            <span class="dashlet-value dashlet-value-xs dashlet-value-number">
                                <span
                                    class="fa"
                                    [ngClass]="{
                                        'fa-long-arrow-up': data.autoScaleAction === 3,
                                        'fa-long-arrow-down': data.autoScaleAction === 2 || data.autoScaleAction === 1,
                                        'fa-circle': !data.autoScaleAction
                                    }"
                                    [ngStyle]="{ color: getRecommendationColor(data.autoScaleAction) }"
                                ></span>
                                <span *ngIf="data.autoScaleAction === 3"> Up Tier </span>
                                <span *ngIf="data.autoScaleAction === 2"> Down Tier </span>
                                <span *ngIf="data.autoScaleAction === 1"> Archive to S0 </span>
                                <span *ngIf="!data.autoScaleAction"> No Change </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
