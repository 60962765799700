<div>
    <ng-container *ngIf="dashlet.getSize().id === 0">
        <div class="align-center" style="margin-top: 11px; margin-bottom: 19px">
            <div class="text-center tooltipprasad" style="margin-left: 17px; margin-right: 63px">
                <div [matTooltip]="deviceTypesSummaryToolTip()">Total Devices</div>
                <span>{{ roomsCount }}</span>
            </div>

            <div style="margin-right: 8px">Health</div>

            <div class="grow">
                <div>
                    <dashlet-ratio-bar emptyTitle="No data available for health">
                        <!-- Critical -->
                        <dashlet-ratio-bar-item
                            [title]="healthBarSummary?.critical?.healthTooltip"
                            [height]="healthRatioBarHeight()"
                            [value]="healthBarSummary?.critical?.healthCount"
                            [color]="healthBarSummary?.critical?.color"
                        >
                        </dashlet-ratio-bar-item>

                        <!-- Non Urgent -->
                        <dashlet-ratio-bar-item
                            [title]="healthBarSummary?.nonUrgent?.healthTooltip"
                            [height]="healthRatioBarHeight()"
                            [value]="healthBarSummary?.nonUrgent?.healthCount"
                            [color]="healthBarSummary?.nonUrgent?.color"
                        >
                        </dashlet-ratio-bar-item>

                        <!-- Healthy -->
                        <dashlet-ratio-bar-item
                            [title]="healthBarSummary?.helathy?.healthTooltip"
                            [height]="healthRatioBarHeight()"
                            [value]="healthBarSummary?.helathy?.healthCount"
                            [color]="healthBarSummary?.helathy?.color"
                        >
                        </dashlet-ratio-bar-item>

                        <!-- Offline -->
                        <dashlet-ratio-bar-item
                            [title]="healthBarSummary?.offline?.healthTooltip"
                            [height]="healthRatioBarHeight()"
                            [value]="healthBarSummary?.offline?.healthCount"
                            [color]="healthBarSummary?.offline?.color"
                        >
                        </dashlet-ratio-bar-item>

                        <!-- Unknown -->
                        <dashlet-ratio-bar-item
                            [title]="healthBarSummary?.unknown?.healthTooltip"
                            [height]="healthRatioBarHeight()"
                            [value]="healthBarSummary?.unknown?.healthCount"
                            [color]="healthBarSummary?.unknown?.color"
                        >
                        </dashlet-ratio-bar-item>
                    </dashlet-ratio-bar>
                </div>
            </div>
        </div>
    </ng-container>

    <div>
        <hr />
        <br />
        <dashlet-table
            [columns]="columns"
            [dataSource]="dashlet.msTeamsRoomsRowData"
            [loading]="isTeamsRoomsDataLoading"
            [tableHeightRows]="tableSize()"
            [pageLength]="tableSize()"
            [pagination]="isPagination"
            [(sortField)]="sortField"
            defaultSort="ragIndicator"
            (filteredData)="receiveFilterdTableData($event)"
        ></dashlet-table>
    </div>
</div>
