import { DashletSettingsForm, DashletSettingsGroup, DashletSettingDropdown, DashletSettingText } from '@models/index';
import { DashletSettingsService } from '@services/index';

export class DashletSettingsFormVoiceQuality extends DashletSettingsForm {
    private customer!: DashletSettingDropdown;
    private location!: DashletSettingDropdown;
    private rtcpReceiver!: DashletSettingDropdown;
    private group!: DashletSettingsGroup;

    constructor(private readonly settingsService: DashletSettingsService) {
        super();

        this.init();
        this.loadCustomers();
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);

        if (this.customer.value) {
            this.loadLocations(this.customer.value);
        }
        if (this.location.value) {
            this.loadRtcpReceivers(this.location.value);
        }
    }

    private init(): void {
        this.group = new DashletSettingsGroup();
        const s: any = (this.group.settings = []);

        const customer = (this.customer = new DashletSettingDropdown());
        customer.label = 'Customer';
        customer.stateKey = 'customer';
        customer.disabled = true;
        customer.change.subscribe(() => {
            if (!this.location.value) this.clearAndDisable(this.location);
            if (!this.rtcpReceiver.value) this.clearAndDisable(this.rtcpReceiver);
            this.loadLocations(customer.value!);
        });
        s.push(customer);

        const location = (this.location = new DashletSettingDropdown());
        location.label = 'Location';
        location.stateKey = 'location';
        location.disabled = true;
        location.change.subscribe(() => {
            if (!this.rtcpReceiver.value) this.clearAndDisable(this.rtcpReceiver);
            this.loadRtcpReceivers(location.value!);
        });
        s.push(location);

        const rtcpReceiver = (this.rtcpReceiver = new DashletSettingDropdown());
        rtcpReceiver.label = 'Source Equipment';
        rtcpReceiver.stateKey = 'equipment';
        rtcpReceiver.disabled = true;
        s.push(rtcpReceiver);

        const customName = new DashletSettingText(40);
        customName.label = 'Custom Dashlet Name';
        customName.stateKey = 'customName';
        s.push(customName);

        this.updateSettings(this.group);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);
        this.settingsService.getUserAssociatedCustomers().subscribe(
            customers => {
                this.customer.items = customers;
                this.customer.loadingComplete();
            },
            () => {
                this.customer.loadingComplete();
                this.customer.disabled = true;
                // show error notification here
            }
        );
    }

    private loadLocations(customer: string): void {
        this.enableAndLoading(this.location);
        this.settingsService.getLocations(customer).subscribe(
            locations => {
                this.location.items = locations;
                this.location.loadingComplete();
            },
            () => {
                this.location.loadingComplete();
                this.location.disabled = true;
                // show error notification here
            }
        );
    }

    private loadRtcpReceivers(location: string): void {
        this.enableAndLoading(this.rtcpReceiver);
        this.settingsService.getRtcpReceivers(location).subscribe(
            receivers => {
                if (receivers !== null && receivers.length > 1) {
                    receivers.unshift({ label: 'All', value: '=all=' });
                }
                this.rtcpReceiver.items = receivers;
                this.rtcpReceiver.loadingComplete();
            },
            () => {
                this.rtcpReceiver.loadingComplete();
                this.rtcpReceiver.disabled = true;
                // show error notification here
            }
        );
    }

    private clearAndDisable(dropdown: DashletSettingDropdown) {
        dropdown.disabled = true;
        dropdown.items = [];
        dropdown.value = null;
    }

    private enableAndLoading(dropdown: DashletSettingDropdown) {
        dropdown.disabled = false;
        dropdown.loadingBegin();
    }
}
