/* eslint-disable no-undef */
import {
    Customer,
    Dashlet,
    Equipment,
    Location,
    SIPEntityStatus,
    SIPRegistrationStatus,
    SIPSessionStatus,
    Threshold
} from '@models/index';

export class DashletSystemHealthASM extends Dashlet {
    public readonly commandTypeIdServiceStatus = 'A232D7BB-BEB3-4BC0-98E5-7F0D939667BF';
    public readonly commandTypeIdSIPEntityStatus = '2DA6D4D8-6B2D-4EED-91D7-6E9C751BC0E6';
    public readonly commandTypeIdSIPRegistrationStatus = '3CE158BB-4E4B-4C03-B46F-A07634D3431F';
    public readonly commandTypeIdSIPSessionStatus = '8BD7848B-7A4E-4C76-81A6-B766C467BFFA';

    customer!: Customer;
    location!: Location;
    equipment!: Equipment;

    thresholds: Threshold[] = [];
    sipEntityStatusList!: SIPEntityStatus[];
    sipRegistrationStatus!: SIPRegistrationStatus;
    sipSessionStatus!: SIPSessionStatus;

    showSystemChart = false;
    showNetworkGraph = false;
    showCallRate = false;
    showServiceStatus = false;
    showSIPEntityStatus = false;
    showSIPRegistrationStatus = false;
    showSIPSessionStatus = false;
    showCustomScripts = false;

    commandsRunData: any = [];
    commandsRunDataLoading = true;

    constructor() {
        super();

        // sizing
        this.sizes = [
            {
                id: 0,
                label: 'Standard',
                cols: 5,
                rows: 6
            }
        ];
        this.applySize(0);

        // init data
        this.resetData();
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        //read settings object
        this.configured = v.customer && v.location && v.equipment;
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        if (v.location) {
            this.location = new Location(v.location.value, v.location.label);
        } else {
            this.location = new Location('', '');
        }
        if (v.equipment) {
            this.equipment = new Equipment(v.equipment.value, v.equipment.label);
        } else {
            this.equipment = new Equipment('', '');
        }
        this.generatedNameTag = this.configured ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;

        this.showSystemChart = v.showSystemChart || false;
        this.showNetworkGraph = v.showNetworkGraph || false;
        this.showServiceStatus = v.showServiceStatus || false;
        this.showSIPEntityStatus = v.showSIPEntityStatus;
        this.showSIPRegistrationStatus = v.showSIPRegistrationStatus;
        this.showSIPSessionStatus = v.showSIPSessionStatus;
        this.showCustomScripts = v.showCustomScripts || false;

        //apply size
        let h = 0;
        if (this.showSystemChart) h += 1.9;
        if (this.showNetworkGraph) h += 2.1;
        if (this.showServiceStatus) h += 1.2;
        if (this.showSIPEntityStatus) h += 1.2;
        if (this.showSIPRegistrationStatus) h += 1.5;
        if (this.showSIPSessionStatus) h += 1.5;
        if (this.showCustomScripts) h += 4;
        this.applySizeExpansion(0, h);
    }

    resetData() {
        this.sipEntityStatusList = [];
        this.sipRegistrationStatus = new SIPRegistrationStatus();
        this.sipSessionStatus = new SIPSessionStatus();
    }

    dispose() {}

    processSIPSessionStatus(data: any[]) {
        const row = data[0].data[0];
        if (row !== null) {
            this.sipSessionStatus.update(row.Audio, row.FAX, row.MultiMedia, row.Text, row.Other);
        }
    }

    processSIPRegistrationStatus(data: any[]) {
        const row = data[0].data[0];
        if (row !== null) {
            this.sipRegistrationStatus.update(
                row.PrimaryRegistered,
                row.PrimarySubscribed,
                row.SecondaryRegistered,
                row.SecondarySubscribed
            );
        }
    }

    processSIPEntityStatus(data: any[]) {
        const row = data[0].data;
        if (row !== null) {
            for (const rowData of row) {
                const data: SIPEntityStatus = new SIPEntityStatus(
                    rowData.EntityName,
                    rowData.FQDNorIPaddr,
                    rowData.Status,
                    rowData.LastResponse,
                    rowData.Mode,
                    rowData.AddressMap,
                    rowData.Reason,
                    rowData.ResponseMs
                );
                const index = this.sipEntityStatusList.findIndex(e => e.entityName === rowData.EntityName);
                if (index === -1) {
                    this.sipEntityStatusList.push(data);
                } else {
                    this.sipEntityStatusList[index].updateFromObject(data);
                }
            }
            this.sipEntityStatusList.sort((e1, e2) => {
                if (e1.entityName.toLowerCase() > e2.entityName.toLowerCase()) {
                    return 1;
                }
                if (e1.entityName.toLowerCase() < e2.entityName.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
    }
}
