export class AEPApplicationStatus {
	private _applicationName: string;
	private _inbound: number;
	private _outbound: number;

	constructor(applicationName: string, inbound: number, outbound: number) {
		this._applicationName = applicationName;
		this._inbound = inbound;
		this._outbound = outbound;
	}

	public update(applicationName: string, inbound: number, outbound: number) {
		this._applicationName = applicationName;
		this._inbound = inbound;
		this._outbound = outbound;
	}

	public updateFromObject(applicationStatus: AEPApplicationStatus) {
		this._applicationName = applicationStatus.applicationName;
		this._inbound = applicationStatus.inbound;
		this._outbound = applicationStatus.outbound;
	}

	/**
	 * Getter applicationName
	 * @return {string}
	 */
	public get applicationName(): string {
		return this._applicationName;
	}

	/**
	 * Getter inbound
	 * @return {number}
	 */
	public get inbound(): number {
		return this._inbound;
	}

	/**
	 * Getter outbound
	 * @return {number}
	 */
	public get outbound(): number {
		return this._outbound;
	}

	/**
	 * Setter applicationName
	 * @param {string} value
	 */
	public set applicationName(value: string) {
		this._applicationName = value;
	}

	/**
	 * Setter inbound
	 * @param {number} value
	 */
	public set inbound(value: number) {
		this._inbound = value;
	}

	/**
	 * Setter outbound
	 * @param {number} value
	 */
	public set outbound(value: number) {
		this._outbound = value;
	}
}
