import { Component, Input, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { DashletIPNRStatus } from '@models/index';
import { DashletLineGraphComponent } from '../dashlet-line-graph/dashlet-line-graph.component';
import { DashletService } from '@services/index';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-dashlet-ipnrstatus',
    templateUrl: './dashlet-ipnrstatus.component.html',
    styleUrls: ['./dashlet-ipnrstatus.component.scss']
})
export class DashletIPNRStatusComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletIPNRStatus;
    entityGroup: number;

    private pChart: DashletLineGraphComponent;
    get chart() {
        return this.pChart;
    }
    @ViewChild('chart') set chart(value: DashletLineGraphComponent) {
        this.pChart = value;
    }

    private pUtilizationChart: DashletLineGraphComponent;
    get utilizationChart() {
        return this.pUtilizationChart;
    }
    @ViewChild('utilizationChart') set utilizationChart(value: DashletLineGraphComponent) {
        this.pUtilizationChart = value;
    }

    channelsUsedColor: string = 'grey';
    private channelsUsedDataset = {
        label: 'In Use',
        borderColor: this.channelsUsedColor,
        backgroundColor: this.channelsUsedColor,
        pointRadius: 0,
        pointHitRadius: 10,
        data: []
    };

    channelsCapacityColor: string = '#814e7c';
    private channelsCapacityDataset = {
        label: 'Capacity',
        borderColor: this.channelsCapacityColor,
        backgroundColor: this.channelsCapacityColor,
        pointRadius: 0,
        pointHitRadius: 10,
        data: []
    };
    lineData = {};
    chartOptions = {
        scales: {
            xAxes: [
                {
                    type: 'time',
                    gridLines: {
                        display: false
                    },
                    time: {
                        unit: 'minute',
                        displayFormats: {
                            minute: 'HH:mm'
                        },
                        stepSize: 1,
                        max: null,
                        min: null
                    },
                    ticks: {
                        fontSize: 10.2,
                        autoSkip: false,
                        maxRotation: 0,
                        minRotation: 0
                    }
                }
            ],
            yAxes: [
                {
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        fontSize: 10.2,
                        beginAtZero: true,
                        callback: value => {
                            //show only integer ticks
                            const truncated = parseInt(value);
                            if (truncated != value) return null;
                            return truncated;
                        }
                    }
                }
            ]
        },
        legend: {
            display: false,
            position: 'bottom'
        },
        animation: {
            duration: 0
        },
        tooltips: {
            callbacks: {
                title: tipItem => {
                    return null;
                }
            }
        }
    };

    private channelsUtilizationDataset = {
        label: 'In Use %',
        borderColor: this.channelsUsedColor,
        backgroundColor: this.channelsUsedColor,
        pointRadius: 0,
        pointHitRadius: 10,
        data: []
    };
    utilizationLineData = {};
    utilizationChartOptions = {
        scales: {
            xAxes: [
                {
                    type: 'time',
                    gridLines: {
                        display: false
                    },
                    time: {
                        unit: 'minute',
                        displayFormats: {
                            minute: 'HH:mm'
                        },
                        stepSize: 1,
                        max: null,
                        min: null
                    },
                    ticks: {
                        fontSize: 10.2,
                        autoSkip: false,
                        maxRotation: 0,
                        minRotation: 0
                    }
                }
            ],
            yAxes: [
                {
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        fontSize: 10.2,
                        max: 100,
                        beginAtZero: true
                    }
                }
            ]
        },
        legend: {
            display: false,
            position: 'bottom'
        },
        animation: {
            duration: 0
        },
        tooltips: {
            callbacks: {
                title: tipItem => {
                    return null;
                },
                label: (tipItem, data) => {
                    let label = data.datasets[tipItem.datasetIndex].label || '';
                    if (label) label += ': ';
                    return label + (+tipItem.yLabel).toFixed(1);
                }
            }
        }
    };

    constructor(private dashletService: DashletService) {
        this.lineData = {
            datasets: [this.channelsUsedDataset, this.channelsCapacityDataset]
        };
        this.utilizationLineData = {
            datasets: [this.channelsUtilizationDataset]
        };
    }

    ngOnInit() {
        this.dashlet.IPNRData.chartDataUpdated.subscribe(() => {
            this.updateChart();
        });
    }
    ngOnDestroy() {
        if (this.dashlet) this.dashlet.dispose();
    }
    private updateChart() {
        this.channelsUsedDataset.borderColor = 'grey';
        this.channelsUsedDataset.backgroundColor = 'grey';
        this.channelsUtilizationDataset.borderColor = 'grey';
        this.channelsUtilizationDataset.backgroundColor = 'grey';

        //update color
        for (let data of this.channelsCapacityDataset.data) {
            let index = this.channelsCapacityDataset.data.indexOf(data);
            let currentColor = this.processColor(data.y, this.channelsUsedDataset.data[index]);
            this.channelsUsedDataset.borderColor = currentColor;
            this.channelsUsedDataset.backgroundColor = currentColor;
            this.channelsUtilizationDataset.borderColor = currentColor;
            this.channelsUtilizationDataset.backgroundColor = currentColor;
            this.channelsUsedColor = currentColor;
        }

        //update axis range
        const max = new Date();
        max.setSeconds(Math.floor(max.getSeconds() / this.dashlet.interval) * this.dashlet.interval, 0);
        const min = new Date(max);
        min.setMinutes(min.getMinutes() - 4); //then show up to a few mins before max
        this.chartOptions.scales.xAxes[0].time.min = min;
        this.chartOptions.scales.xAxes[0].time.max = max;
        this.utilizationChartOptions.scales.xAxes[0].time.min = min;
        this.utilizationChartOptions.scales.xAxes[0].time.max = max;

        if (this.chart) this.chart.updateChart();
        if (this.utilizationChart) this.utilizationChart.updateChart();
    }

    private processColor(size: number, active: number) {
        let color = 'grey';

        //colorise
        const delta = size - active;
        if (size <= 0) color = this.dashletService.getSeverityColor(-1);
        else if (delta < size * 0.1) color = this.dashletService.getSeverityColor(2);
        else if (delta < size * 0.25) color = this.dashletService.getSeverityColor(1);
        else color = this.dashletService.getSeverityColor(0);

        return color;
    }

    accessConcentrator() {
        const settings = this.dashlet.getSettings();
        const customerId = settings.customer.value;
        const equipmentId = settings.equipment.value;
        // as a customer, go to access history
        if (this.entityGroup === 2) {
            window.open(
                environment.webPortalUrl + '/AccessConcentrator/AccessConcentrator/List?entityId=' + customerId
            );
        } else {
            window.open(
                environment.webPortalUrl +
                    '/AccessConcentrator/AccessConcentrator/EquipmentList?entityId=' +
                    customerId +
                    '&equipmentId=' +
                    equipmentId
            );
        }
    }

    get smallLayout() {
        return this.dashlet.getSize().id === 0;
    }

    get mediumLayout() {
        return this.dashlet.getSize().id === 1;
    }

    get wideLayout() {
        return this.dashlet.getSize().id === 2;
    }
}
