import * as Actions from '@actions/index';
import { AccountService, DashletService, DashletSettingsService, ReportService, TimeoutService } from '@services/index';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    ColumnTypes,
    CustomerLogo,
    DashletSystemHealthSummary,
    MenuItem,
    SystemHealthService,
    SummaryField,
    SystemHealthServer,
    UserDetails,
    FeatureSubscriptions,
    CustomerModel,
    SummaryData,
    DiskData,
    fixObjectKeys
} from '@models/index';
import { select, Store } from '@ngrx/store';
import { AppState, selectEntity } from '@reducers/index';
import {
    map,
    mergeMap,
    Observable,
    of,
    Subscription,
    switchMap,
    forkJoin,
    interval,
    Subject,
    startWith,
    from,
    BehaviorSubject,
    takeUntil
} from 'rxjs';
import { environment } from '@environments/environment';
import { catchError, shareReplay } from 'rxjs/operators';
import * as CustomersActions from '@actions/customers/customers.actions';

@Component({
    selector: 'app-dashlet-system-health-summary',
    templateUrl: './dashlet-system-health-summary.component.html',
    styleUrls: ['./dashlet-system-health-summary.component.scss']
})
export class DashletSystemHealthSummaryComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletSystemHealthSummary;

    private reportSubscriptions: FeatureSubscriptions = {
        capacityEnabled: false,
        availabilityEnabled: false,
        releaseEnabled: false,
        apiEnabled: false,
        serviceDeskEnabled: false,
        configurationEnabled: false,
        continuityEnabled: false,
        changeEnabled: false,
        securityEnabled: false
    };

    private dataServersSubject: BehaviorSubject<SystemHealthServer[]> = new BehaviorSubject<SystemHealthServer[]>([]);
    public servers$: Observable<SystemHealthServer[]> = this.dataServersSubject.asObservable();
    private userDetails: UserDetails;
    private entityGroup: number;
    private customerId: string;

    public longestOutageServer!: string | null;
    public longestOutageSeconds = 0;
    public longestOutageText!: string;
    public totalServers: number;

    private pingCount = 0;
    private totalPing = 0;
    public totalAveragePing: string;

    private availabilityCount = 0;
    private totalAvailability = 0;
    public totalAvgAvailability = 0;

    public loading: boolean;
    private destroy$: Subject<void>;
    private selectedCustomer: CustomerModel;

    public menuItems: MenuItem[] = [];
    selectedServer: SystemHealthServer;

    public columns: ColumnTypes[] = [
        {
            columnDef: 'severity',
            header: '',
            cell: (row: SystemHealthServer) => row.severity,
            type: 'severity',
            dataTooltip: (row: SystemHealthServer) => this.getSeverityReasons(row)
        },
        {
            columnDef: 'name',
            header: 'Server',
            cell: (row: SystemHealthServer) => row.name,
            filterType: 'text',
            dataTooltip: (row: SystemHealthServer) => this.getSeverityReasons(row),
            width: '18%'
        },
        {
            columnDef: 'type',
            header: 'Server Type',
            cell: (row: SystemHealthServer) => row.type,
            filterType: 'multiselect',
            width: '16%'
        },
        {
            columnDef: 'cpuPercentage',
            header: 'CPU',
            cell: (row: SystemHealthServer) =>
                row.cpuPercentage === 'null' || row.cpuPercentage === null || row.cpuPercentage === undefined
                    ? '---'
                    : `${row.cpuPercentage}%`,
            filterType: 'slider',
            sliderRange: [0, 100],
            type: 'numeric',
            width: '8.2%'
        },
        {
            columnDef: 'memoryPercentage',
            header: 'Memory',
            cell: (row: SystemHealthServer) =>
                row.memoryPercentage === 'null' || row.memoryPercentage === null || row.memoryPercentage === undefined
                    ? '---'
                    : `${row.memoryPercentage}%`,
            filterType: 'slider',
            sliderRange: [0, 100],
            type: 'numeric',
            width: '8.2%'
        },
        {
            columnDef: 'diskPercentage',
            header: 'Disk',
            cell: (row: SystemHealthServer) =>
                row.diskPercentage === 'null' || row.diskPercentage === null || row.diskPercentage === undefined
                    ? '---'
                    : `${row.diskPercentage}%`,
            filterType: 'slider',
            sliderRange: [0, 100],
            type: 'numeric',
            width: '8.2%'
        },
        {
            columnDef: 'maxPing',
            header: 'Ping',
            subHeadingInFilter: 'Max',
            cell: (row: SystemHealthServer) => (row.maxPing ? `${row.maxPing} ms` : '---'),
            headerTooltip: 'Maximum ping time from three tests at configured interval',
            type: 'numeric',
            width: '6%'
        },
        {
            columnDef: 'averagePing',
            header: 'Ping',
            subHeadingInFilter: 'Avg',
            cell: (row: SystemHealthServer) =>
                row.averagePing ? `${this.pingResponseCheck(row.averagePing)} ms` : '---',
            headerTooltip: 'Average ping time from three tests at configured interval',
            type: 'numeric',
            width: '6%'
        },
        {
            columnDef: 'spacerElement',
            header: ' ',
            width: '1%'
        },
        {
            columnDef: 'servicesUp',
            header: 'Services',
            subHeadingInFilter: 'Up',
            cell: (row: SystemHealthServer) =>
                row.servicesUp > 0 ? row.servicesUp.toString() : row.servicesUp === 0 ? '---' : 'N/A',
            width: '7.5%',
            type: 'services',
            dataTooltip: (row: SystemHealthServer) => {
                return row.servicesUp > 0
                    ? this.getServicesUpTitle(
                          row.servicesUp,
                          row.servicesDown + row.servicesOffline + row.servicesPartial,
                          row.type
                      )
                    : row.servicesUp === 0
                    ? this.getNoUpdateTitle('---')
                    : 'Service monitoring for this equipment is currently not supported.';
            }
        },
        {
            columnDef: 'servicesDown',
            header: 'Services',
            subHeadingInFilter: 'Down',
            cell: (row: SystemHealthServer) =>
                row.servicesDown > 0 ? row.servicesDown.toString() : row.servicesDown === 0 ? '---' : 'N/A',
            width: '7.5%',
            type: 'services',
            dataTooltip: (row: SystemHealthServer) => {
                return row.servicesDown > 0
                    ? this.getServicesDownTitle(
                          row.servicesUp,
                          row.servicesDown,
                          row.servicesPartial,
                          row.servicesOffline
                      )
                    : row.servicesDown === 0
                    ? this.getNoUpdateTitle('---')
                    : 'Service monitoring for this equipment is currently not supported.';
            }
        },
        {
            columnDef: 'availability',
            header: 'Availability',
            cell: (row: SystemHealthServer) => (row.availability ? `${row.availability}%` : '---'),
            filterType: 'slider',
            sliderRange: [0, 100],
            headerTooltip:
                'Availability is calculated as: Avg ping packets received/Ping packets sent * 100 over last 30 days',
            type: 'numeric',
            width: '9.5%'
        }
    ];

    private subscription: Subscription = new Subscription();
    private returnData: SystemHealthServer[] = [];

    constructor(
        private accountService: AccountService,
        private reportService: ReportService,
        public timeoutService: TimeoutService,
        public dashletService: DashletService,
        private store$: Store<AppState>,
        private settingsService: DashletSettingsService
    ) {
        this.userDetails = this.accountService.getUserDetails();
    }

    ngOnInit() {
        this.loading = true;
        const userDetail = this.accountService.getUserDetails();
        this.entityGroup = userDetail.EntityGroup;

        const isBP = this.userDetails.EntityGroup === 0 || this.userDetails.EntityGroup === 1; //allow for BPs & VOs
        let customersData$ = this.store$.select(state => state.customers.userAssociatedCustomers);

        customersData$.subscribe((res: CustomerModel[]) => {
            if (res && res.length) {
                this.selectedCustomer = res.find(item => item.customerId === this.dashlet.customer.customerId);
                this.reportSubscriptions.capacityEnabled = this.selectedCustomer.ftCapacity;
                this.reportSubscriptions.availabilityEnabled = this.selectedCustomer.ftAvailability;
                this.menuItems = [
                    {
                        label: 'Open Processor Report',
                        command: () => {
                            this.openCpuReport();
                        },
                        disabled: !(this.reportSubscriptions.capacityEnabled || this.accountService.isVirsaeOwner()),
                        title: !(this.reportSubscriptions.capacityEnabled || this.accountService.isVirsaeOwner())
                            ? this.getCapacityUnsubscribedText()
                            : ''
                    },
                    {
                        label: 'Open Disk Report',
                        command: () => {
                            this.openDiskReport();
                        },
                        disabled: !(this.reportSubscriptions.capacityEnabled || this.accountService.isVirsaeOwner()),
                        title: !(this.reportSubscriptions.capacityEnabled || this.accountService.isVirsaeOwner())
                            ? this.getCapacityUnsubscribedText()
                            : ''
                    },
                    {
                        label: 'Open Memory Report',
                        command: () => {
                            this.openMemoryReport();
                        },
                        disabled: !(this.reportSubscriptions.capacityEnabled || this.accountService.isVirsaeOwner()),
                        title: !(this.reportSubscriptions.capacityEnabled || this.accountService.isVirsaeOwner())
                            ? this.getCapacityUnsubscribedText()
                            : ''
                    },
                    {
                        label: 'Open Network Connectivity Report',
                        command: () => {
                            this.openNetworkConnectivityReport();
                        },
                        disabled: !(
                            this.reportSubscriptions.availabilityEnabled || this.accountService.isVirsaeOwner()
                        ),
                        title: !(this.reportSubscriptions.availabilityEnabled || this.accountService.isVirsaeOwner())
                            ? this.getAvailabilityUnsubscribedText()
                            : ''
                    }
                ];
                if (isBP) {
                    const newItem = {
                        label: 'Open Access Concentrator',
                        command: data => {
                            this.accessConcentrator(data);
                        },
                        disabled: this.userDetails.EntityGroup === 1 && this.selectedCustomer.disableAccessConcentrator,
                        title: 'Open Access Concentrator'
                    };
                    const isItemAlreadyExists = this.menuItems.some(item => item.label === newItem.label);
                    if (!isItemAlreadyExists) {
                        this.menuItems.push(newItem);
                    }
                }
            }

            // Check data length and dispatch action if needed
            if (!res || res.length === 0) {
                this.store$.dispatch(CustomersActions.GetUserAssociatedCustomers());
            }
        });

        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );
        this.load();
        this.subscription.add(
            this.dashlet.settingsUpdated.subscribe(() => {
                this.destroy$.complete();
                this.store$.dispatch(Actions.stopSummaryDocumentRepeat());
                this.load();
            })
        );
    }

    private entityFullValues = [];
    private load(): void {
        this.destroy$ = new Subject<void>();
        this.returnData = [];
        this.entityFullValues = [];
        if (this.dashlet.customer.customerId && this.dashlet.locationId) {
            if (this.dashlet.locationId !== '=all=') {
                this.settingsService
                    .getSystemHealthEquipment(this.dashlet.locationId)
                    .pipe(shareReplay(1))
                    .subscribe(groups => {
                        this.entityFullValues = groups;
                        this.subscribeEquipmentData();
                    });
            } else {
                // Create an array to hold the observables for system health equipment requests
                const systemHealthEquipmentObservables = [];

                this.settingsService.getLocations(this.dashlet.customer.customerId).subscribe(locations => {
                    locations.forEach(location => {
                        // Create an observable for each system health equipment request
                        const systemHealthEquipmentObservable = this.settingsService.getSystemHealthEquipment(
                            location.value
                        );
                        systemHealthEquipmentObservables.push(systemHealthEquipmentObservable);
                    });

                    // Use forkJoin to wait for all system health equipment requests to complete
                    forkJoin(systemHealthEquipmentObservables).subscribe(responses => {
                        // At this point, 'responses' is an array containing arrays from all requests.
                        // We'll flatten it into a single array using reduce.

                        this.entityFullValues = responses.flat();

                        this.subscribeEquipmentData();
                    });
                });
            }
        }
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
        this.store$.dispatch(Actions.stopSummaryDocumentRepeat());
    }

    private subscribeEquipmentData() {
        // Group the equipment IDs into arrays of up to 5
        const equipmentIdGroups = this.chunkArray(
            this.dashlet.equipment.map(e => e.value),
            10
        );

        // Set up an observable to poll for data periodically
        interval(60000)
            .pipe(
                startWith(0),
                takeUntil(this.destroy$),
                switchMap(() => from(equipmentIdGroups)),
                mergeMap(
                    group =>
                        this.settingsService.makeHealthSummaryApiCallWithIds(
                            group,
                            this.dashlet.commandTypeIdSystemHealthSummary
                        ),
                    20
                ),
                catchError(error => {
                    console.error('Error in health summary API call:', error);
                    return of([]); // Return an empty array on error
                })
            )
            .subscribe(
                (batchResponses: any) => {
                    this.loading = false;
                    Object.entries(batchResponses).forEach(([equipmentId, dataString]) => {
                        if (typeof dataString === 'string') {
                            // Parse the JSON string to get the actual data object
                            const data: SummaryData = fixObjectKeys(JSON.parse(dataString));

                            const equipDetails = this.findLabelsByValue(this.entityFullValues, equipmentId);
                            if (equipDetails) {
                                const server = this.processSystemHealthSummaryRow(
                                    data,
                                    equipDetails.itemLabel,
                                    equipDetails.groupLabel,
                                    equipmentId
                                );

                                // Now 'server' contains processed data for this piece of equipment
                                // Example: Updating total availability and ping
                                if (server.availability) {
                                    this.totalAvailability += server.availability;
                                    this.availabilityCount++;
                                }
                                if (server.averagePing) {
                                    this.totalPing += server.averagePingOrginal;
                                    this.pingCount++;
                                }

                                // Update or add the processed server data
                                this.updateReturnData(server);
                            }
                        }
                    });
                    // Process additional metrics and statistics
                    this.getLongestOutage(this.returnData);
                    this.totalAvgAvailability =
                        this.availabilityCount > 0 ? this.totalAvailability / this.availabilityCount : 0;
                    const averagePing = this.pingCount > 0 ? this.totalPing / this.pingCount : null;
                    if (this.pingCount) this.totalAveragePing = this.pingResponseCheck(+(+averagePing).toFixed(1));
                    else this.totalAveragePing = '';
                    // Notify subscribers that new server data is available
                    this.dataServersSubject.next(this.returnData);
                    // Get and log the total number of servers
                    this.servers$.pipe(map(arr => arr.length)).subscribe(length => {
                        this.totalServers = length;
                    });
                },
                error => {
                    console.error('Error in data subscription:', error);
                    this.loading = false;
                }
            );
    }

    private updateReturnData(server) {
        // Find the index of the server with the same newKey in this.returnData
        const existingIndex = this.returnData.findIndex(item => item.equipmentId === server.equipmentId);
        if (existingIndex > -1) {
            // Update the existing server data
            this.returnData[existingIndex] = server;
        } else {
            // Add the new server data
            this.returnData.push(server);
        }
    }

    private findLabelsByValue(data: any[], valueToFind: string): { groupLabel?: string; itemLabel?: string } | null {
        for (const group of data) {
            for (const item of group.items) {
                if (item.value === valueToFind) {
                    return {
                        groupLabel: group.label,
                        itemLabel: item.label
                    };
                }
            }
        }
        return null;
    }

    private chunkArray<T>(array: T[], size: number): T[][] {
        const results = [];
        const arrayCopy = [...array]; // This ensures we don't mutate the original array
        while (arrayCopy.length) {
            results.push(arrayCopy.splice(0, size));
        }
        return results;
    }

    private processSystemHealthSummaryRow(
        data: SummaryData,
        equipmentName: string,
        equipmentGroup: string,
        equipmentId: string
    ): SystemHealthServer {
        //A lot of this logic is to avoid Dashlet interpretting null as a 0.
        //Null means data is expired, or it didn't receive anything, which is very different to receiving a 0.
        const cpuPercentage = this.processToFixedPointOrNull(this.processSummaryField(data.cpu));

        const memoryPercentage = this.processToFixedPointOrNull(this.processSummaryField(data.memory));

        const diskPercentage = this.processSummaryField(data.disk)
            ? Number(this.processDiskPercentage(data.disk)).toFixed(1)
            : null;

        const servicesUp =
            this.processSummaryField(data.servicesUp) &&
            data.servicesUp.FieldCommandUpdateTimeInfoList &&
            data.servicesUp.FieldCommandUpdateTimeInfoList.length
                ? JSON.parse(data.servicesUp.FieldValue)
                : [];

        const serviceStatus = this.getServiceStatusByType(servicesUp, equipmentGroup);
        const averagePing = this.processPing(data.averagePing);
        const maxPing = this.processPing(data.maxPing);

        const longestOutageSeconds = this.processToNumberOrNull(this.processSummaryField(data.longestOutageSeconds));
        const availability = this.processToNumberOrNull(this.processSummaryField(data.averageAvailability));
        const averagePingOrginal = this.processToNumberOrNull(this.processSummaryField(data.averagePing));

        const severity = this.getSeverity(
            this.processToNumberOrNull(cpuPercentage),
            this.processToNumberOrNull(memoryPercentage),
            this.processToNumberOrNull(diskPercentage),
            this.processSummaryField(data.servicesUp) && serviceStatus,
            maxPing,
            averagePing,
            availability
        );

        return {
            severity,
            name: equipmentName,
            equipmentId,
            type: equipmentGroup,
            location: null,
            cpuPercentage,
            memoryPercentage,
            diskPercentage,
            uptime: null,
            averagePing,
            maxPing,
            servicesUp: this.processToNumberOrNull(serviceStatus.Up),
            servicesDown: this.processToNumberOrNull(serviceStatus.Down),
            servicesPartial: this.processToNumberOrNull(serviceStatus.Down),
            servicesOffline: this.processToNumberOrNull(serviceStatus.Offline),
            availability,
            longestOutageSeconds,
            averagePingOrginal
        };
    }

    //among other things, this function makes sure data is not expired
    private processSummaryField(data: SummaryField | null): string | null {
        if (data?.FieldCommandUpdateTimeInfoList) {
            const dataExpired = this.timeoutService.getIsDataExpired(
                data.FieldCommandUpdateTimeInfoList[0]?.CommandTypeId,
                data.FieldCommandUpdateTimeInfoList[0]?.LastUpdateTime
            );
            if (!dataExpired) {
                return data.FieldValue === null || data.FieldValue === undefined || data.FieldValue === ''
                    ? null
                    : data.FieldValue;
            }
        }
        return null;
    }
    //takes in a string number or a null value and returns either the null or the string number to 1 decimal place
    private processToFixedPointOrNull(value: string | null): string | null {
        return value === null ? null : Number(value).toFixed(1);
    }

    private processToNumberOrNull(value: string | number | null): number | null {
        if (value === null) return null;
        else return Number(Number(value).toFixed(1));
    }

    private processDiskPercentage(data: SummaryField): string | null {
        if (!data.FieldValue) return null;
        const parsedDiskData: DiskData[] = JSON.parse(data.FieldValue);
        const cDriveValue =
            parsedDiskData.find(x => x.DiskName === 'C:' || x.DiskName === 'Total').DiskUsedPercentage || null;
        return Number(cDriveValue).toFixed(1);
    }

    private processPing(data: SummaryField): string | null {
        if (data?.FieldCommandUpdateTimeInfoList) {
            const dataExpired = this.timeoutService.getIsDataExpired(
                data.FieldCommandUpdateTimeInfoList[0]?.CommandTypeId,
                data.FieldCommandUpdateTimeInfoList[0]?.LastUpdateTime
            );
            if (!dataExpired) {
                if (!data || !data.FieldValue) return null;
                if (!Number(data.FieldValue)) return '<1';
                return data.FieldValue;
            }
            return null;
        }
    }

    private getServiceStatusByType(arrayParam: any[], equipmentType: string): SystemHealthService {
        let upStatus: string[] = [];
        let partialStatus: string[] = [];
        let downStatus: string[] = [];
        let offlineStatus: string[] = [];
        switch (equipmentType) {
            case 'ACM': {
                upStatus = ['up', 'up simplex', 'up standby'];
                partialStatus = ['partially up', 'partially down'];
                downStatus = ['down'];
                return this.getServicesStatus(arrayParam, upStatus, partialStatus, downStatus);
            }
            case 'AES': {
                upStatus = ['online', 'running'];
                partialStatus = ['partial'];
                downStatus = ['down'];
                offlineStatus = ['offline'];
                return this.getServicesStatus(arrayParam, upStatus, partialStatus, downStatus, offlineStatus);
            }
            case 'Experience Portal': {
                const ccxml = new RegExp('^CCXML[0-9]');
                const vxmlmgr = new RegExp('^VXMLMGR[0-9]');

                arrayParam = arrayParam.filter(
                    (item: any) => !ccxml.test(item.ServiceName) && !vxmlmgr.test(item.ServiceName)
                );

                upStatus = ['running'];
                partialStatus = ['partial'];
                downStatus = ['stopped', 'stopping'];
                return this.getServicesStatus(arrayParam, upStatus, partialStatus, downStatus);
            }
            case 'SBC': {
                upStatus = ['ok'];
                partialStatus = ['partial'];
                downStatus = ['not ok'];
                return this.getServicesStatus(arrayParam, upStatus, partialStatus, downStatus);
            }
            case 'Session Manager': {
                upStatus = ['up'];
                partialStatus = ['partial'];
                downStatus = ['down'];
                return this.getServicesStatus(arrayParam, upStatus, partialStatus, downStatus);
            }
            case 'Windows Server': {
                // workaround for now, need to clearify logic later
                upStatus = ['running'];
                partialStatus = [];
                downStatus = ['stopped'];
                return this.getServicesStatus(arrayParam, upStatus, partialStatus, downStatus);
            }
            default:
                const serviceStatus: SystemHealthService = {
                    Up: -1,
                    Partial: -1,
                    Down: -1,
                    Offline: -1
                } as SystemHealthService;
                return serviceStatus;
        }
    }

    private getServicesStatus(
        arrayParam: any[],
        upStatusArr: any,
        partialStatusArr: any,
        downStatusArr: any,
        offlineStatusArr?: any
    ): SystemHealthService {
        const serviceStatus: SystemHealthService = { Up: 0, Partial: 0, Down: 0, Offline: 0 } as SystemHealthService;
        serviceStatus.Up = arrayParam.reduce(function (sum: any, rec: any) {
            if (upStatusArr.includes(rec.State.toLowerCase()) && rec.ServiceName !== '') {
                sum = sum * 1 + 1;
            }
            return sum;
        }, 0);

        serviceStatus.Partial = arrayParam.reduce(function (sum: any, rec: any) {
            if (partialStatusArr.includes(rec.State.toLowerCase()) && rec.ServiceName !== '') {
                sum = sum * 1 + 1;
            }
            return sum;
        }, 0);

        serviceStatus.Down = arrayParam.reduce(function (sum: any, rec: any) {
            if (downStatusArr.includes(rec.State.toLowerCase())) {
                sum = sum * 1 + 1;
            }
            return sum;
        }, 0);

        if (offlineStatusArr) {
            serviceStatus.Offline = arrayParam.reduce(function (sum: any, rec: any) {
                if (offlineStatusArr.includes(rec.State.toLowerCase())) {
                    sum = sum * 1 + 1;
                }
                return sum;
            }, 0);
        }

        return serviceStatus;
    }

    get smallLayout() {
        return this.dashlet.getSize().id === 0;
    }

    get mediumLayout() {
        return this.dashlet.getSize().id === 1;
    }

    get wideLayout() {
        return this.dashlet.getSize().id === 2;
    }

    private accessConcentrator(data: SystemHealthServer): void {
        const settings = this.dashlet.getSettings();
        const customerId = settings.customer.value;
        const equipmentId = data.equipmentId;
        //as a customer, go to access history
        if (this.entityGroup === 2) {
            // eslint-disable-next-line no-undef
            window.open(environment.webPortalUrl + 'AccessConcentrator/AccessConcentrator/List?entityId=' + customerId);
        } else {
            // eslint-disable-next-line no-undef
            window.open(
                environment.webPortalUrl +
                    'AccessConcentrator/AccessConcentrator/EquipmentList?entityId=' +
                    customerId +
                    '&equipmentId=' +
                    equipmentId
            );
        }
    }

    public openNetworkConnectivityReport(): void {
        var settings = this.dashlet.getSettings();
        this.customerId = settings.customer.value;

        this.reportService.openReport(this.reportService.networkConnectivityReportId, this.customerId);
    }

    private openCpuReport(): void {
        var settings = this.dashlet.getSettings();
        this.customerId = settings.customer.value;
        this.reportService.openReport(this.reportService.osCpuReportId, this.customerId);
    }

    get cpuReportName() {
        return this.reportService.getReportName(this.reportService.osCpuReportId);
    }

    private pingResponseCheck(data: number): string {
        if (data < 1) {
            //response time less than one for number or string
            return '<1';
        } else {
            return `${data}`;
        }
    }

    private openMemoryReport(): void {
        var settings = this.dashlet.getSettings();
        this.customerId = settings.customer.value;
        this.reportService.openReport(this.reportService.osMemoryReportId, this.customerId);
    }

    private openDiskReport(): void {
        var settings = this.dashlet.getSettings();
        this.customerId = settings.customer.value;
        this.reportService.openReport(this.reportService.osDiskReportId, this.customerId);
    }

    private getServicesUpTitle(servicesUp: number, servicesDownOfflinePartial: number, type: string): string {
        let total = servicesUp + servicesDownOfflinePartial;
        if ('Windows Server' === type) {
            return servicesUp + ' / ' + total + ' Services running normally';
        }
        return servicesUp + ' / ' + total + ' Services Up';
    }

    private getServicesDownTitle(
        servicesUp: number,
        servicesDown: number,
        servicesPartial: number,
        servicesOffline: number
    ): string {
        let total = servicesUp + servicesDown + servicesPartial + servicesOffline;
        return (
            servicesDown +
            ' / ' +
            total +
            ' Services Down' +
            (servicesOffline > 0 ? '\n' + servicesOffline + ' / ' + total + ' Services Offline' : '')
        );
    }

    private getNoUpdateTitle(value: string): string {
        return value === '---' ? 'No updates received.  Check network, configuration, and system settings.' : '';
    }

    public getCapacityUnsubscribedText(): string {
        return this.dashletService.getCapacityUnsubscribedText();
    }
    public getAvailabilityUnsubscribedText(): string {
        return this.dashletService.getAvailabilityUnsubscribedText();
    }

    private getSeverityReasons(item: SystemHealthServer): string {
        if (item.severity === 0) {
            return 'All systems ok';
        } else if (item.severity === 1 || item.severity === 2) {
            const reasonsArray: string[] = [];
            const totalServices: number =
                item.servicesDown + item.servicesUp + item.servicesPartial + item.servicesOffline;

            if (+item.cpuPercentage > 90) {
                reasonsArray.push('CPU > 90%');
            } else if (+item.cpuPercentage > 80) {
                reasonsArray.push('CPU > 80%');
            }

            if (+item.memoryPercentage > 90) {
                reasonsArray.push('Memory > 90%');
            } else if (+item.memoryPercentage > 80) {
                reasonsArray.push('Memory > 80%');
            }

            if (+item.diskPercentage > 90) {
                reasonsArray.push('Disk space used > 90%');
            } else if (+item.diskPercentage > 80) {
                reasonsArray.push('Disk space used > 80%');
            }

            if (item.servicesDown > 0) {
                reasonsArray.push(`${item.servicesDown}/${totalServices} services are down`);
            }

            if (item.servicesPartial > 0) {
                reasonsArray.push(`${item.servicesPartial}/${totalServices} services are partially down`);
            }

            if (!reasonsArray.length) reasonsArray.push('Check server availability, network and system settings.');

            const reasonsBox: string = reasonsArray.join(' \n');
            return reasonsBox;
        } else if (item.severity === -1) {
            return 'No data received.';
        }
    }

    private getSeverity(
        cpuPercentage: number | null,
        memoryPercentage: number | null,
        diskPercentage: number | null,
        serviceStatus: SystemHealthService | null,
        maxPing: string | null,
        averagePing: string | null,
        availability: number | null
    ): number {
        if (
            cpuPercentage === null &&
            memoryPercentage === null &&
            diskPercentage === null &&
            serviceStatus === null &&
            !maxPing &&
            !averagePing &&
            !availability
        )
            return -1;
        if (
            cpuPercentage === null &&
            memoryPercentage === null &&
            diskPercentage === null &&
            serviceStatus === null &&
            (maxPing || averagePing || availability)
        )
            return 2;
        let response = 0;
        const cpu80_90 = cpuPercentage! >= 80 && cpuPercentage! < 90;
        const cpu_90 = cpuPercentage! > 89;

        const memory80_90 = memoryPercentage! >= 80 && memoryPercentage! < 90;
        const memory_90 = memoryPercentage! > 89;

        const disk80_90 = diskPercentage! >= 80 && diskPercentage! < 90;
        const disk_90 = diskPercentage! > 89;

        if (cpu_90 || memory_90 || disk_90) {
            response = 2;
        } else if (cpu80_90 || memory80_90 || disk80_90) {
            response = 1;
        } else {
            response = 0;
        }

        if (serviceStatus) response = serviceStatus!.Down > 0 ? 2 : serviceStatus!.Partial > 0 ? 1 : response;

        return response;
    }

    private getLongestOutage(servers: SystemHealthServer[]): void {
        const longest = servers.reduce(
            (max: any, server: any) => (max && max.longestOutageSeconds > server.longestOutageSeconds ? max : server),
            null
        );

        if (longest) {
            this.longestOutageServer = longest.name;

            if (longest.longestOutageSeconds !== 0 && longest.longestOutageSeconds! > 5) {
                this.longestOutageText = longest.longestOutageSeconds + ' min(s) ' + longest.name;
            } else if (longest.longestOutageSeconds! <= 5) {
                //if it's null, then don't show anything, but if it's 0 or anything lower, it needs to display < 5 min
                if (longest.longestOutageSeconds === null) this.longestOutageText = null;
                else this.longestOutageText = '< 5 mins';
            }
        }
    }
}
