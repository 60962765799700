import { Customer, Dashlet, Equipment, Location, Threshold } from '@models/index';

export interface HealthData {
    witscsFreeCalls: number;
    witscsFreeMem: number;
    witscsOldestCall: number;
}

export interface LinkData {
    witscscmLinkState: number;
    witscscmLinkType: string;
    witscscmLinkTypeNumber: number;
    witscscmLinkIndex: number;
    witscscmLinkPath: string;
}

export interface ChannelData {
    witscscmChannelOwner: string;
    witscscmChannelPort: string;
    witscscmChannelRecording: boolean;
    witscscmChannelRecordingDetails: string;
    witscscmChannelState: string;
}

export interface TableChannelData {
    total: number;
    status: string;
    stateOriginal: string;
}

export interface ArchiveDestinationData {
    id: string;
    path: string;
    state: string;
    oldestWaiting: string;
    expansion: ArchiveDestinationExpansionData[];
}

export interface ArchiveDestinationExpansionData {
    mbPending: number;
    free: number;
    callsPending: number;
}

export interface TableStatisticsData {
    title: string;
    today: number;
    reboot: number;
}

export class DashletSystemHealthAvayaContact extends Dashlet {
    public readonly commandTypeIdServer = '5A2171BF-3916-4AF7-A33E-3E713DB3E65C';
    public readonly commandTypeIdMemoryUsage = 'D4769B5C-B83B-45FE-A6C7-647462D63DAF';
    public readonly commandTypeIdHealth = '0A17DEB1-1281-4D10-ABA3-6A6841AC7CD9';
    public readonly commandTypeIdLinks = '64C647D0-4114-483C-A824-17908C62464E';
    public readonly commandTypeIdChannel = '84471120-D975-4EF0-8F22-D07107326577';
    public readonly commandTypeIdArchiveDestination = '58EEA4FD-9EAF-4C64-B939-F1EC02DC8A02';
    public readonly commandTypeIdStatistics = '0668E744-91B9-43AB-A536-4CCBC29FDF2B';

    readonly interval = 30;

    equipment!: Equipment;
    location!: Location;
    customer!: Customer;
    lshName!: string | null;
    uptimeMs!: number | null;
    showCustomScripts: any;
    commandsRunDataLoading: any;
    loggedInUsers: any;
    commandsRunData: any;

    showSystemChart = false;
    showNetworkGraph = false;
    showHealth = false;
    showLinkDetails = false;
    showChannelDetails = false;
    showArchiveDestinationDetails = false;
    showStatistics = false;

    healthData: HealthData;
    linksData: LinkData[];
    linksStatusBar: number[];
    channelData: ChannelData[];
    channelTableData: TableChannelData[];
    channelDrilldownData: ChannelData[];
    archiveDestinationData: ArchiveDestinationData[];
    statisticsTableData: TableStatisticsData[];

    thresholds: Threshold[] = [];

    constructor() {
        super();

        // sizing
        this.sizes = [
            {
                id: 0,
                label: 'Standard',
                cols: 5,
                rows: 6
            }
        ];
        this.applySize(0);

        // init data
        this.resetData();

        this.healthData = { witscsFreeCalls: 0, witscsFreeMem: 0, witscsOldestCall: 0 };
        this.statisticsTableData = [];
        this.linksData = [];
        this.linksStatusBar = [0, 0];
        this.channelData = [];
        this.channelTableData = [];
        this.channelDrilldownData = [];
        this.archiveDestinationData = [];
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        // read settings object
        this.configured = v.customer && v.location && v.equipment;
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
            // this.loadCustomerLogo();
        } else {
            this.customer = new Customer('', '');
        }
        if (v.location) {
            this.location = new Location(v.location.value, v.location.label);
        } else {
            this.location = new Location('', '');
        }
        if (v.equipment) {
            this.equipment = new Equipment(v.equipment.value, v.equipment.label);
        } else {
            this.equipment = new Equipment('', '');
        }
        this.generatedNameTag = this.configured ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;
        this.lshName = this.configured ? v.equipment.label : null;

        this.showSystemChart = v.showSystemChart || false;
        this.showNetworkGraph = v.showNetworkGraph || false;
        this.showHealth = v.showHealth || false;
        this.showLinkDetails = v.showLinkDetails || false;
        this.showChannelDetails = v.showChannelDetails || false;
        this.showArchiveDestinationDetails = v.showArchiveDestinationDetails || false;
        this.showStatistics = v.showStatistics || false;
        this.showCustomScripts = v.showCustomScripts || false;
        // apply size
        let h = 0;
        if (this.showSystemChart) {
            h += 1.9;
        }
        if (this.showNetworkGraph) {
            h += 2.1;
        }
        if (this.showHealth) {
            h += 1.1;
        }
        if (this.showLinkDetails) {
            h += 1;
        }
        if (this.showChannelDetails) {
            h += 3.8;
        }
        if (this.showArchiveDestinationDetails) {
            h += 3.1;
        }
        if (this.showStatistics) {
            h += 2;
        }

        if (this.showCustomScripts) {
            h += 4;
        }

        this.applySizeExpansion(0, h);
    }

    public processProcessMemory(data: any[]) {
        const perc: number = parseFloat(data[0][0].data[0].witscsFreeMem) / parseFloat(data[1][0].data[0].MemoryTotal);
        this.healthData.witscsFreeMem = perc;
    }

    public processHostName(data: any) {
        const row = data[0].data[0];
        if (row !== null) {
            this.lshName = row.NodeName.split('.', 1);
        }
    }

    public processServerData(data: any[]) {
        const row = data[0].data[0];
        if (row !== null) {
            // calculate uptime
            // OSServerUpTime
            let seconds = 0;
            const days = row.UptimeDays || 0;
            const hours = row.UptimeHours || 0;
            const minutes = row.UptimeMinutes || 0;
            seconds = minutes * 60 + hours * 3600 + days * 86400;
            this.uptimeMs = seconds * 1000;
        }
    }

    public processHealthData(data: any[]) {
        const row = data[0].data[0];
        this.healthData.witscsFreeCalls = parseInt(row.witscsFreeCalls);
        this.healthData.witscsOldestCall = parseInt(row.witscsOldestCall);
    }

    public processLinksData(data: any[]) {
        const types = ['dmcc', 'jtapi', 'unify', 'qcm', 'upload', 'toprimary', 'tostandby', 'toslave'];
        const array = data[0].data;
        this.linksData = [];
        this.linksStatusBar = [0, 0];
        array.forEach((row: any) => {
            const entry: LinkData = {
                witscscmLinkIndex: row.witscscmLinkIndex,
                witscscmLinkPath: row.witscscmLinkPath,
                witscscmLinkState: row.witscscmLinkState,
                witscscmLinkType: types[parseInt(row.witscscmLinkType)],
                witscscmLinkTypeNumber: parseInt(row.witscscmLinkType)
            };
            this.linksData.push(entry);
            this.linksStatusBar[parseInt(row.witscscmLinkState)]++;
        });
    }

    public processChannelData(data: any[]) {
        let statusError = 0,
            statusActive = 0,
            statusSetup = 0,
            statusConnected = 0,
            statusIdle = 0,
            statusReg = 0;
        const rows = data && data.length ? data[0].data : null;
        this.channelData = [];
        this.channelTableData = [];
        if (rows) {
            rows.forEach((row: any) => {
                const entry: ChannelData = {
                    witscscmChannelOwner: row.witscscmChannelOwner,
                    witscscmChannelPort: row.witscscmChannelPort,
                    witscscmChannelRecording: parseInt(row.witscscmChannelRecording) === 1,
                    witscscmChannelRecordingDetails: row.witscscmChannelRecordingDetails,
                    witscscmChannelState: row.witscscmChannelState
                };
                this.channelData.push(entry);
                switch (row.witscscmChannelState) {
                    case 'softphone.state.error':
                        statusError++;
                        break;
                    case 'softphone.state.registering':
                        statusReg++;
                        break;
                    case 'softphone.state.idle':
                        statusIdle++;
                        break;
                    case 'softphone.state.setup':
                        statusSetup++;
                        break;
                    case 'softphone.state.connected':
                        statusConnected++;
                        break;
                    case 'softphone.state.active':
                        statusActive++;
                        break;
                }
            });
        }

        let tableObject: TableChannelData = {
            status: 'Error',
            total: statusError,
            stateOriginal: 'softphone.state.error'
        };
        this.channelTableData.push(tableObject);
        tableObject = { status: 'Registering', total: statusReg, stateOriginal: 'softphone.state.registering' };
        this.channelTableData.push(tableObject);
        tableObject = { status: 'Idle', total: statusIdle, stateOriginal: 'softphone.state.idle' };
        this.channelTableData.push(tableObject);
        tableObject = { status: 'Setup', total: statusSetup, stateOriginal: 'softphone.state.setup' };
        this.channelTableData.push(tableObject);
        tableObject = { status: 'Connected', total: statusConnected, stateOriginal: 'softphone.state.connected' };
        this.channelTableData.push(tableObject);
        tableObject = { status: 'Active', total: statusActive, stateOriginal: 'softphone.state.active' };
        this.channelTableData.push(tableObject);
    }

    processChannelDrilldownData(status: string) {
        this.channelDrilldownData = [
            ...this.channelData.filter(i => {
                return i.witscscmChannelState === status;
            })
        ];
    }

    processArchiveDestinationData(data: any[]) {
        const rows = data[0].data;
        this.archiveDestinationData = [];
        rows.forEach((row: any) => {
            const entry: ArchiveDestinationData = {
                id: row.witscscmArchiveDestId,
                path: row.witscscmArchiveDestPath,
                state: row.witscscmArchiveDestState,
                oldestWaiting: row.witscscmArchiveDestOldestWaiting,
                expansion: [
                    {
                        callsPending: parseInt(row.witscscmArchiveDestCallsPending),
                        free: parseInt(row.witscscmArchiveDestFree),
                        mbPending: parseInt(row.witscscmArchiveDestMBPending)
                    }
                ]
            };

            this.archiveDestinationData.push(entry);
        });
    }

    processStatisticsData(data: any[]) {
        const dataStats = data[0].data[0];
        this.statisticsTableData = [];

        this.statisticsTableData.push({
            title: 'Calls',
            today: +dataStats.witscsCallsToday,
            reboot: +dataStats.witscsCallsThisRun
        });

        this.statisticsTableData.push({
            title: 'Recordings',
            today: +dataStats.witscsRecsToday,
            reboot: +dataStats.witscsRecsThisRun
        });
    }

    resetData() {
        this.lshName = null;
        this.uptimeMs = null;

        this.healthData = { witscsFreeCalls: 0, witscsFreeMem: 0, witscsOldestCall: 0 };
        this.statisticsTableData = [];
        this.linksData = [];
        this.linksStatusBar = [0, 0];
        this.channelData = [];
        this.channelTableData = [];
        this.channelDrilldownData = [];
        this.archiveDestinationData = [];
    }

    dispose() {}
}
