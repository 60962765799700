<span class="spaced-inline-flex full-width">
    <h1 mat-dialog-title>Quick Alarm Suppression</h1>
    <button mat-icon-button aria-label="close dialog" (click)="OnCancel()">
        <mat-icon>close</mat-icon>
    </button>
</span>
<ng-container *ngIf="loading; else content">
    <div class="grow flex-column center gap-l spinner">
        <mat-spinner [diameter]="40"></mat-spinner>
    </div>
</ng-container>

<ng-template #content>
    <div mat-dialog-content>
        <span class="spaced-inline-flex full-width">
            <h3 class="autoMarginHeight">{{GetFormHeading()}}<span class="red-warning">*</span></h3>
            <mat-radio-group class="example-radio-group" [(ngModel)]="dispCustomTime" [disabled]="false">
                <mat-radio-button [color]="color" [value]="false">Quick Time Selection</mat-radio-button>
                <mat-radio-button [color]="color" [value]="true">Custom Time Selection</mat-radio-button>
            </mat-radio-group>
        </span>
        <mat-form-field *ngIf="!dispCustomTime" class="full-width">
            <mat-select [(ngModel)]="selectedTimeToSuppress" name="time" [formControl]="timeSelectFormControl">
                <mat-option *ngFor="let time of timesAvailable" [value]="time.value">
                    {{time.viewValue}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="timeSelectFormControl.hasError('required')">
                Period is <strong>required</strong>.
            </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="dispCustomTime" class="full-width">
            <input matInput readonly [ngxMatDatetimePicker]="picker" [defaultValue]="customTime"
                [(ngModel)]="customTime" [min]="minDate" [max]="maxDate" [contentEditable]="false" [disabled]="disabled">
            <mat-datepicker-toggle
                matSuffix
                [for]="$any(picker)"
                [matTooltip]="customTimeTooltip"
            ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
                [color]="color" [enableMeridian]="enableMeridian">
            </ngx-mat-datetime-picker>
        </mat-form-field>
        <h3>Reason<span class="red-warning">*</span></h3>
        <mat-form-field class="full-width">
            <textarea
                matInput
                [(ngModel)]="suppressionDescription"
                (ngModelChange)="onValueChange()"
                [formControl]="descriptionFormControl"
                [maxlength]="maxLength"
                rows="5"
                class="noResize">
            </textarea>
            <span *ngIf="!descriptionFormControl.hasError('required')">{{counter}}</span>
            <mat-error *ngIf="descriptionFormControl.hasError('required')">
                Reason is <strong>required</strong>.
            </mat-error>
        </mat-form-field>
        <br><br>
        <h3>Suppression Level</h3>
        <div class="spaced-inline-flex full-width">
            <mat-checkbox [color]="color" [(ngModel)]="excludeLocationId">Apply to All Equipment Locations</mat-checkbox>
            <mat-checkbox [color]="color" [(ngModel)]="excludeEquipmentId">Apply to All Equipment</mat-checkbox>
            <mat-checkbox [color]="color" [(ngModel)]="excludeAlarmName">Apply to All Alarms</mat-checkbox>
            <mat-checkbox [color]="color" [(ngModel)]="excludeAdminId">Apply to All Administered IDs</mat-checkbox>
        </div>
        <br><br>
        <table class="full-width">
            <tr>
                <td class="td-small"><b>Field</b></td>
                <td class="td-large"><b>Combination to be Suppressed</b></td>
            </tr>
            <tr>
                <td class="td-small"><b>Customer</b></td>
                <td class="td-large">
                    {{ quickSuppressionArguments.Customer.name }}</td>
            </tr>
            <tr>
                <td class="td-small"><b>Equipment Location</b></td>
                <td class="td-large">
                    {{
                        !excludeLocationId
                            ? !quickSuppressionArguments.Location.name
                                ? quickSuppressionArguments.Location.id !== emptyGuidVal
                                    ? "Unknown Name (ID: " + quickSuppressionArguments.Location.id + ")"
                                    : "N/A (All)"
                                : quickSuppressionArguments.Location.name
                            : "All"
                    }}</td>
            </tr>
            <tr>
                <td class="td-small"><b>Equipment</b></td>
                <td class="td-large">
                    {{
                        !excludeEquipmentId
                            ? !quickSuppressionArguments.Equipment.name
                                ? quickSuppressionArguments.Equipment.id !== emptyGuidVal
                                    ? "Unknown Device Name (ID: " + quickSuppressionArguments.Equipment.id + ")"
                                    : "N/A (All)"
                                : quickSuppressionArguments.Equipment.name
                            : "All"
                    }}</td>
            </tr>
            <tr>
                <td class="td-small"><b>Alarm</b></td>
                <td class="td-large">
                    {{
                        !excludeAlarmName
                            ? quickSuppressionArguments.AlarmName
                            : "All"
                    }}</td>
            </tr>
            <tr>
                <td class="td-small"><b>Admin ID</b></td>
                <td class="td-large">
                    {{
                        !excludeAdminId
                            ? quickSuppressionArguments.AdminId
                            : "All"
                    }}</td>
            </tr>
            <tr>
                <td class="td-small"><b>Suppression Period</b></td>
                <td class="td-large">{{getPeriodStatement()}}</td>
            </tr>
        </table>
        <br>
        <div *ngIf="cautionMessage.length > 0" class="failure-block full-width">
            <div class="container full-width">
                <div class="warning-left">
                    <span *ngFor="let text of cautionMessage">
                        <span [innerHTML]="text"></span>
                    </span>
                </div>
                <div class="warning-right">
                    <mat-checkbox [color]="color" [(ngModel)]="acknowledgedDanger">Acknowledge<span class="red-warning">*</span></mat-checkbox>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions align="end">
        <button
            [color]="color"
            mat-raised-button
            (click)="CreateRuleObject()"
            [disabled]="!acknowledgedDanger || timeSelectFormControl.hasError('required') || descriptionFormControl.hasError('required')"
            cdkFocusInitial>
                Suppress
        </button>
    </div>
</ng-template>
