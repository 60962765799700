import { Injectable } from '@angular/core';
import { CompactType } from 'angular-gridster2';
import { Dashlet } from '@models/index';
import { TileGridComponent } from '@modules/dashboard/components/tile-grid/tile-grid.component';

@Injectable()
export class TileGridService {
    tileGrid: TileGridComponent;
    private resizeFailedDashlets: Dashlet[] = [];
    constructor() {}

    canResize(newRows: number, newCols: number, id: string): boolean {
        return this.tileGrid.canResize(newRows, newCols, id);
    }

    public registerTileGrid(tileGrid: TileGridComponent): void {
        this.tileGrid = tileGrid;
    }

    public optionsUpdated(): void {
        this.tileGrid.updateOptions();
    }

    public remove(dashlet: Dashlet): void {
        this.tileGrid.remove(dashlet);
    }

    public thresholdCreated(message: string): void {
        this.tileGrid.thresholdCreated(message);
    }

    public showSettings(dashlet: Dashlet): void {
        this.tileGrid.showSettings(dashlet);
    }

    public setCompact(value: CompactType): void {
        this.tileGrid.options.compactType = value;
    }

    public setSwap(value: boolean): void {
        this.tileGrid.options.swap = value;
    }

    public setDisablePushOnDrag(value: boolean): void {
        this.tileGrid.options.disablePushOnDrag = value;
    }

    public addResizeFailedDashlet(dashlet: Dashlet): void {
        if (this.resizeFailedDashlets.indexOf(dashlet) === -1) {
            // If dashlet isn't already in the array
            this.resizeFailedDashlets.push(dashlet);
        }
    }

    public removeResizeFailedDashlet(dashlet: Dashlet): void {
        const index = this.resizeFailedDashlets.indexOf(dashlet);
        if (index > -1) {
            this.resizeFailedDashlets.splice(index, 1); // Remove dashlet from array
        }
    }

    public getResizeFailedDashlets(): Dashlet[] {
        return this.resizeFailedDashlets;
    }
}
