import { Customer, Dashlet, Point, Sort } from '@models/index';
import { DashletService, RealtimeGatewayService } from '@services/index';
import { Subject } from 'rxjs';

export class TrunkGroupDetail {
    trunkName!: string;
    trunkNumber!: number;
    latestSize!: number;
    latestActive!: number;
    latestUtil!: number;
    sizePoints!: Point[];
    activePoints!: Point[];
    trunkGroupKey!: TrunkGroupKey;
    severity!: number;
    chartData: any;
}

export interface TrunkSummary {
    averageUtil: string;
    totalTrunks: number;
}

export class TrunkUtilSummary {
    occupancy = '';
    total = 0;
    proportion = '';
    severity!: number;
    expansion: TrunkGroupDetail[];
}

export enum TrunkGroupKey {
    Above90 = 1,
    From80To90 = 2,
    Below80 = 3
}

export class DashletTrunkGroupSummary extends Dashlet {
    public commandTypeId = 'F1C00562-2617-4358-A80E-92864D31C4D7';
    public customer!: Customer;
    public equipmentId!: string;
    trunks: TrunkGroupDetail[] = [];
    private trunkGroups!: number[];
    summaryPeriod!: number | null;
    totalUtil = 0;
    totalTrunks = 0;
    lastUpdated: string = '';
    readonly interval = 30;
    originalNameTag!: string;

    trunkSummary: TrunkSummary = {
        averageUtil: '',
        totalTrunks: 0
    };
    trunkUtilSummary: TrunkUtilSummary[] = [];

    public chartDataUpdated: Subject<null> = new Subject();
    get onChartDataUpdated() {
        return this.chartDataUpdated;
    }

    constructor(private realtimeService: RealtimeGatewayService, private dashletService: DashletService) {
        super();

        this.sizes = [
            {
                id: 0,
                label: 'Medium',
                cols: 9,
                rows: 11
            }
        ];
        this.applySize(0);
        this.resetData();
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        // unsub realtime feed
        this.unsubData();

        // read settings
        const trunkGroups = v.trunkGroups || [];
        this.configured = v.customer && v.location && v.equipment && trunkGroups.length > 0 && v.summaryPeriod;
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        this.equipmentId = v.equipment && v.equipment.value ? v.equipment.value : null;
        this.summaryPeriod = v.summaryPeriod ? v.summaryPeriod.value : null;
        this.originalNameTag = this.configured
            ? `${v.location.label} | ${v.equipment.label} | ${v.summaryPeriod.value}`
            : 'Unconfigured';
        this.customNameTag = v.nameTag;

        // parse trunks array
        this.trunkGroups = trunkGroups.map((i: any) => +i.value);
    }

    refreshNameTag() {
        const newDate = new Date(this.lastUpdated);
        const _month = newDate.toLocaleDateString(undefined, { month: 'short' });
        const _date = newDate.toLocaleDateString(undefined, { day: 'numeric' });
        const _year = newDate.toLocaleDateString(undefined, { year: 'numeric' });

        const _hour = newDate.toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' }).toUpperCase();
        this.generatedNameTag =
            this.originalNameTag + ` hour period | Last updated at ${_date}-${_month}-${_year} ${_hour}`;
    }

    public processData(trunkGroupData: any[]) {
        trunkGroupData.forEach(trunkRow => {
            if (trunkRow.data && trunkRow.data.length !== 0) {
                trunkRow.data.forEach((rowData: any) => {
                    this.totalUtil += rowData['Utilisation'] === 'NaN' ? 0 : +rowData['Utilisation'];
                    ++this.totalTrunks;
                    const timestamp = new Date(rowData['PeriodEnd']);

                    const trunkName = rowData['GroupName'];
                    const trunkNumber = rowData['GroupNumber'];
                    const occupancy = rowData['Occupancy'];
                    const groupSize = rowData['GroupSize'];
                    const sizePoints = { x: timestamp, y: groupSize };
                    const activePoints = { x: timestamp, y: occupancy };
                    const util = rowData['Utilisation'];

                    let key = 0;
                    let severity = -1;
                    if (util > 90) {
                        key = TrunkGroupKey.Above90;
                        severity = 2;
                    } else if (util >= 80 && util <= 90) {
                        key = TrunkGroupKey.From80To90;
                        severity = 1;
                    } else if (util < 80) {
                        key = TrunkGroupKey.Below80;
                        severity = 0;
                    }

                    const trunk = this.trunks.find(
                        trunk => trunk.trunkName === trunkName && trunk.trunkNumber === trunkNumber
                    );

                    if (trunkName && trunkNumber) {
                        if (trunk) {
                            // doesn't need to change the latest value, cause the latest document is processed first by default, if not, need to compare the timestamp value
                            if (!trunk.sizePoints.some(point => point.x.getTime() === sizePoints.x.getTime())) {
                                trunk.sizePoints.push(sizePoints);
                                trunk.activePoints.push(activePoints);
                                Sort.sortDates(trunk.sizePoints);
                                Sort.sortDates(trunk.activePoints);
                                trunk.trunkGroupKey > key ? (trunk.trunkGroupKey = key) : null;
                                trunk.latestActive = occupancy;
                                trunk.severity = severity;
                                trunk.chartData = {
                                    datasets: [
                                        {
                                            label: 'Occupancy',
                                            borderColor: this.dashletService.getChartColors().blue,
                                            backgroundColor: this.dashletService.getChartColors().blue,
                                            borderWidth: 2,
                                            pointRadius: 0,
                                            pointHitRadius: 10,
                                            fill: false,
                                            lineTension: 0,
                                            data: trunk.activePoints
                                        },
                                        {
                                            label: 'Group Size',
                                            borderColor: this.dashletService.getChartColors().pink,
                                            backgroundColor: this.dashletService.getChartColors().pink,
                                            borderWidth: 2,
                                            pointRadius: 0,
                                            pointHitRadius: 10,
                                            fill: false,
                                            lineTension: 0,
                                            data: trunk.sizePoints
                                        }
                                    ]
                                };
                            }
                        } else {
                            const newTrunk = {
                                trunkName: trunkName,
                                trunkNumber: trunkNumber,
                                latestSize: groupSize,
                                latestActive: occupancy,
                                latestUtil: util,
                                sizePoints: [sizePoints],
                                activePoints: [activePoints],
                                trunkGroupKey: key,
                                severity: severity,
                                chartData: {
                                    datasets: [
                                        {
                                            label: 'Occupancy',
                                            borderColor: this.dashletService.getChartColors().blue,
                                            backgroundColor: this.dashletService.getChartColors().blue,
                                            borderWidth: 2,
                                            pointRadius: 0,
                                            pointHitRadius: 10,
                                            fill: false,
                                            lineTension: 0,
                                            data: [activePoints]
                                        },
                                        {
                                            label: 'Group Size',
                                            borderColor: this.dashletService.getChartColors().pink,
                                            backgroundColor: this.dashletService.getChartColors().pink,
                                            borderWidth: 2,
                                            pointRadius: 0,
                                            pointHitRadius: 10,
                                            fill: false,
                                            lineTension: 0,
                                            data: [sizePoints]
                                        }
                                    ]
                                }
                            };
                            if (this.trunkGroups.some(num => +trunkNumber === num)) {
                                this.trunks.push(newTrunk);
                            }
                        }
                    }
                });
            }
        });
        this.caculateSummary();
    }

    private caculateSummary() {
        this.trunkSummary.totalTrunks = this.trunks.length;
        this.trunkSummary.averageUtil = Number(this.totalUtil / this.totalTrunks).toFixed(2);

        this.calculateTrunkUtilSummary();
    }

    private calculateTrunkUtilSummary() {
        this.trunkUtilSummary = [];
        const util_90 = this.trunks.filter(x => x.trunkGroupKey === TrunkGroupKey.Above90);
        const util_80_90 = this.trunks.filter(x => x.trunkGroupKey === TrunkGroupKey.From80To90);
        const util_80 = this.trunks.filter(x => x.trunkGroupKey === TrunkGroupKey.Below80);
        const totalLength = util_90.length + util_80_90.length + util_80.length;

        this.trunkUtilSummary.push({
            occupancy: '>90%',
            total: util_90.length,
            proportion: Number((util_90.length / totalLength) * 100).toFixed(0) + '%',
            severity: 2,
            expansion: util_90
        });
        this.trunkUtilSummary.push({
            occupancy: '80% - 90%',
            total: util_80_90.length,
            proportion: Number((util_80_90.length / totalLength) * 100).toFixed(0) + '%',
            severity: 1,
            expansion: util_80_90
        });
        this.trunkUtilSummary.push({
            occupancy: '<80%',
            total: util_80.length,
            proportion: Number((util_80.length / totalLength) * 100).toFixed(0) + '%',
            severity: 0,
            expansion: util_80
        });
    }

    dispose(): void {
        this.unsubData();
    }

    resetData(): void {
        this.trunks = [];
        this.summaryPeriod = null;
        this.chartDataUpdated.next(null);
    }

    private unsubData() {
        if (this.equipmentId) {
            // unsub data
            this.realtimeService.unsubscribe(this.equipmentId, this.commandTypeId);
        }
    }
}
