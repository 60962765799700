import { createAction, props } from '@ngrx/store';

export const GetRtcpReceivers = createAction(
    '[GetRtcpReceivers] Get GetRtcpReceivers',
    props<{ locationId: string }>()
);

export const GetRtcpReceiversSuccess = createAction(
    '[GetRtcpReceivers] Get GetRtcpReceivers Success',
    props<{ data: any; locationId: string }>()
);

export const GetRtcpReceiversFailure = createAction(
    '[GetRtcpReceivers] Get GetRtcpReceivers Failure',
    props<{ error: any }>()
);
