import { Subject, takeUntil } from 'rxjs';
import { DashboardService, RealtimeGatewayService } from '@services/index';
import { Customer, Dashlet } from '@models/index';

interface Point {
    x: Date;
    y: number;
}

export class DashletTrunkGroupTraffic extends Dashlet {
    public readonly commandTypeIdIpo = '78ab641f-fbdd-497e-bd93-e2ecabb51831';
    public readonly commandTypeIdAcm = 'f91b4082-1e63-43d4-820f-21b316afd1f2';
    public equipmentId!: string;
    public customer!: Customer;
    private trunkGroup!: number | null;
    private destroy$ = new Subject<void>();

    readonly interval = 30;

    channelsUsedCurrent: any;
    channelsCapacityCurrent: any;
    channelsUsedData: Point[] = [];
    channelsCapacityData: Point[] = [];
    channelsUtilizationData: Point[] = [];
    trunkGroupName!: string;
    showUtilizationPercentage!: boolean;

    private dataUpdated: Subject<null> = new Subject();
    get onDataUpdated() {
        return this.dataUpdated;
    }

    constructor(private dashboardService: DashboardService, private realtimeService: RealtimeGatewayService) {
        super();

        this.sizes = [
            {
                id: 0,
                label: '5 X 3',
                cols: 5,
                rows: 3
            }
        ];
        this.applySize(0);

        // produce data every interval
        this.dashboardService
            .getUpdateInterval(this.interval)
            .pipe(takeUntil(this.destroy$))
            .subscribe((time: any) => {
                this.produceData(time);
            });
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        // unsub realtime feed
        this.unsubData();

        // read settings
        this.configured = v.customer && v.location && v.equipment && v.trunkGroup;
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
            // this.loadCustomerLogo();
        } else {
            this.customer = new Customer('', '');
        }
        this.equipmentId = v.equipment && v.equipment.value ? v.equipment.value : null;
        this.trunkGroup = v.trunkGroup && v.trunkGroup.value ? +v.trunkGroup.value : null;
        this.trunkGroupName = v.trunkGroup ? v.trunkGroup.label : null;
        this.generatedNameTag = this.configured
            ? `${v.location.label} | ${v.equipment.label}` + (v.trunkGroup ? ` | ${v.trunkGroup.value}` : '')
            : 'Unconfigured';
        this.customNameTag = v.nameTag;
        this.showUtilizationPercentage = !!v.showUtilizationPercentage;
    }

    dispose() {
        this.unsubData();
        this.destroy$.next();
        this.destroy$.complete();
    }
    private unsubData() {
        if (this.equipmentId) {
            // unsub data
            this.realtimeService.unsubscribe(this.equipmentId, this.commandTypeIdIpo);
            this.realtimeService.unsubscribe(this.equipmentId, this.commandTypeIdAcm);
        }
    }
    resetData() {
        this.channelsUsedCurrent = 0;
        this.channelsCapacityCurrent = 0;
        this.channelsUsedData = [];
        this.channelsCapacityData = [];
        this.channelsUtilizationData = [];

        this.dataUpdated.next(null);
    }

    public processDataIpo(data: any) {
        if (data !== null) {
            // find trunk row
            let row = null;
            for (const table of data) {
                row = table.data.find((r: any) => parseInt(r.TrunkNumber) === this.trunkGroup);
                if (row !== null) {
                    break;
                }
            }
            // extract info
            if (row) {
                this.channelsCapacityCurrent = +row.NumberOfChannels;
                this.channelsUsedCurrent = +row.ChannelsInUse;
            } else {
                this.channelsCapacityCurrent = 0;
                this.channelsUsedCurrent = 0;
            }

            this.onDataUpdated.next(null);
        }
    }

    public processDataAcm(data: any) {
        if (data !== null) {
            // find trunk row
            let row = null;
            for (const table of data) {
                row = table.data.find((r: any) => parseInt(r.GroupNumber) === this.trunkGroup);
                if (row !== null) {
                    break;
                }
            }
            // extract info
            if (row) {
                this.channelsCapacityCurrent = +row.GroupSize;
                this.channelsUsedCurrent = +row.ActiveMembers;
            } else {
                this.channelsCapacityCurrent = 0;
                this.channelsUsedCurrent = 0;
            }

            this.onDataUpdated.next(null);
        }
    }

    private produceData(time: Date): void {
        if (
            this.channelsCapacityData === null ||
            this.channelsUsedData === null ||
            this.channelsUtilizationData === null
        ) {
            return;
        }

        // create points
        this.channelsUsedData.push({
            x: time,
            y: this.channelsUsedCurrent
        });
        this.channelsCapacityData.push({
            x: time,
            y: this.channelsCapacityCurrent
        });
        this.channelsUtilizationData.push({
            x: time,
            y: (this.channelsUsedCurrent / this.channelsCapacityCurrent) * 100
        });

        // trim old data
        if (this.channelsCapacityData.length > 20) {
            this.channelsCapacityData.shift();
        }
        if (this.channelsUsedData.length > 20) {
            this.channelsUsedData.shift();
        }
        if (this.channelsUtilizationData.length > 20) {
            this.channelsUtilizationData.shift();
        }

        this.dataUpdated.next(null);
    }
}
