import { LicenceData } from '@models/index';

export class SwitchLink {
    private _name!: string;
    private _status!: string;
    private _state!: string;
    private _uptime!: string;

    constructor(name: string, status: string, uptime: string) {
        this.name = name;
        this.setStatus(status);
        this.uptime = uptime;
    }

    public updateFromObject(switchLink: SwitchLink) {
        this._name = switchLink.name;
        this._status = switchLink.status;
        this._uptime = switchLink.uptime;
    }

    public setStatus(value: string) {
        switch (value) {
            case '1':
                this._status = 'Too Slow';
                this._state = 'Red';
                break;
            case '2':
                this._status = 'Out of Service';
                this._state = 'Red';
                break;
            case '3':
                this._status = 'Invalid Type';
                this._state = 'Red';
                break;
            case '4':
                this._status = 'Invalid Cause';
                this._state = 'Red';
                break;
            case '5':
                this._status = 'No reply Hb';
                this._state = 'Red';
                break;
            case '6':
                this._status = 'Data length';
                this._state = 'Red';
                break;
            case '7':
                this._status = 'Invalid client';
                this._state = 'Red';
                break;
            case '8':
                this._status = 'New connection';
                this._state = 'Green';
                break;
            case '9':
                this._status = 'Context';
                this._state = 'Green';
                break;
            case '10':
                this._status = 'Link up';
                this._state = 'Green';
                break;
            case '11':
                this._status = 'Link down';
                this._state = 'Red';
                break;
            case '12':
                this._status = 'Invalid password';
                this._state = 'Red';
                break;
            case '13':
                this._status = 'Bad version';
                this._state = 'Red';
                break;
            case '14':
                this._status = 'Too many connections';
                this._state = 'Red';
                break;
            case '101':
                this._status = 'Switch down';
                this._state = 'Red';
                break;
            case '102':
                this._status = 'No link';
                this._state = 'Red';
                break;
            case '103':
                this._status = 'Switch reset';
                this._state = 'Red';
                break;
            case '104':
                this._status = 'Busy link';
                this._state = 'Red';
                break;
            case '105':
                this._status = 'Link in use';
                this._state = 'Green';
                break;
            case '200':
                this._status = 'Un-administered';
                this._state = 'Red';
                break;
            case '202':
                this._status = 'Talking';
                this._state = 'Green';
                break;
            case '203':
                this._status = 'No response';
                this._state = 'Red';
                break;
            case '206':
                this._status = 'Restart';
                this._state = 'Green';
                break;
            case '207':
                this._status = 'Tcp down';
                this._state = 'Red';
                break;
            case '208':
                this._status = 'Offline';
                this._state = 'Red';
                break;
            case '214':
                this._status = 'Linker';
                this._state = 'Red';
                break;
            case '215':
                this._status = 'No licence';
                this._state = 'Red';
                break;
            case '216':
                this._status = 'Preserved';
                this._state = 'Red';
                break;
            case '217':
                this._status = 'Standby';
                this._state = 'Green';
                break;
            case '255':
                this._status = 'Server error';
                this._state = 'Red';
                break;
            case '256':
                this._status = 'Resource unavailable';
                this._state = 'Red';
                break;
            case '300':
                this._status = 'Idle';
                this._state = 'Red';
                break;
            default:
                this._status = '---';
                this._state = 'Red';
                break;
        }
    }

    public severity(): number {
        return this.state == 'Green' ? 0 : 2;
    }

    /**
     * Getter name
     * @return {string}
     */
    public get name(): string {
        return this._name;
    }

    /**
     * Getter status
     * @return {string}
     */
    public get status(): string {
        return this._status;
    }

    /**
     * Getter uptime
     * @return {string}
     */
    public get uptime(): string {
        return this._uptime;
    }

    public get state(): string {
        return this._state;
    }

    /**
     * Setter name
     * @param {string} value
     */
    public set name(value: string) {
        if (value == null || value == '') {
            value = '---';
        }
        this._name = value;
    }

    /**
     * Setter uptime
     * @param {string} value
     */
    public set uptime(value: string) {
        this._uptime = value;
    }
}

export class TSAPITSDIBuffer {
    private _name!: string;
    private _allocatedBits!: number;
    private _allocated!: number;
    private _allocatedSizeUnit!: string;
    private _bufferSize!: number;
    private _bufferSizeBits!: number;
    private _bufferSizeUnit!: string;

    constructor(name: string, allocatedBits: number, buffersizeBits: number) {
        this.name = name;
        this.allocatedBits = allocatedBits;
        this.bufferSizeBits = buffersizeBits;
    }

    private setAllocatedSizeMagnitude() {
        if (this._allocatedBits < 1000) {
            this._allocatedSizeUnit = 'b';
            this._allocated = this._allocatedBits;
        } else if (this._allocatedBits < 1000000) {
            this._allocatedSizeUnit = 'Kb';
            this._allocated = this._allocatedBits / 1000;
        } else if (this._allocatedBits < 1000000000) {
            this._allocatedSizeUnit = 'Mb';
            this._allocated = this._allocatedBits / 1000000;
        } else {
            this._allocatedSizeUnit = 'Gb';
            this._allocated = this._allocatedBits / 1000000000;
        }
        this._allocated = Math.floor(this._allocated);
    }

    private setBufferSizeMagnitude() {
        if (this._bufferSizeBits < 1000) {
            this._bufferSizeUnit = 'b';
            this._bufferSize = this._bufferSizeBits;
        } else if (this._bufferSizeBits < 1000000) {
            this._bufferSizeUnit = 'Kb';
            this._bufferSize = this._bufferSizeBits / 1000;
        } else if (this._bufferSizeBits < 1000000000) {
            this._bufferSizeUnit = 'Mb';
            this._bufferSize = this._bufferSizeBits / 1000000;
        } else {
            this._bufferSizeUnit = 'Gb';
            this._bufferSize = this._bufferSizeBits / 1000000000;
        }
        this._bufferSize = Math.floor(this._bufferSize);
    }

    public updateFromObject(value: TSAPITSDIBuffer) {
        this._name = value.name;
        this._allocatedBits = value.allocatedBits;
        this._bufferSizeBits = value.bufferSizeBits;
        this._allocated = value.allocated;
        this._bufferSize = value.bufferSize;
        this._allocatedSizeUnit = value.allocatedSizeUnit;
        this._bufferSizeUnit = value.bufferSizeUnit;
    }

    /**
     * Getter name
     * @return {string}
     */
    public get name(): string {
        return this._name;
    }

    public getSeverity(): number {
        const usageProportion: number = this._allocatedBits / this._bufferSizeBits;
        if (usageProportion < 0.8) {
            return 0;
        } else if (usageProportion < 0.9) {
            return 1;
        } else {
            return 2;
        }
    }

    /**
     * Getter allocated
     * @return {number}
     */
    public get allocated(): number {
        return this._allocated;
    }

    /**
     * Getter allocatedBits
     * @return {number}
     */
    public get allocatedBits(): number {
        return this._allocatedBits;
    }

    /**
     * Getter allocatedSizeUnit
     * @return {number}
     */
    public get allocatedSizeUnit(): string {
        return this._allocatedSizeUnit;
    }

    /**
     * Getter bufferSize
     * @return {number}
     */
    public get bufferSize(): number {
        return this._bufferSize;
    }

    /**
     * Getter bufferSizeBits
     * @return {number}
     */
    public get bufferSizeBits(): number {
        return this._bufferSizeBits;
    }

    /**
     * Getter bufferSizeUnit
     * @return {string}
     */
    public get bufferSizeUnit(): string {
        return this._bufferSizeUnit;
    }

    /**
     * Setter name
     * @param {string} value
     */
    public set name(value: string) {
        if (value == null || value == '') {
            value = '---';
        }
        this._name = value;
    }

    /**
     * Setter allocated Bits
     * @param {number} value
     */
    public set allocatedBits(value: number) {
        if (value == null || value.toString() == '') {
            value = 0;
        }
        this._allocatedBits = value;
        this.setAllocatedSizeMagnitude();
    }

    /**
     * Setter bufferSize Bits
     * @param {number} value
     */
    public set bufferSizeBits(value: number) {
        if (value == null || value.toString() == '') {
            value = 0;
        }
        this._bufferSizeBits = value;
        this.setBufferSizeMagnitude();
    }
}

export class TSAPIData {
	switchLinksDataExpired!: boolean;
	buffersDataExpired!: boolean;
	licensesDataExpired!: boolean;
	private _licences: LicenceData[];
	private _tsapiTSDIBuffers: TSAPITSDIBuffer[];
	private _switchLinks: SwitchLink[];

	constructor() {
		this._licences = [];
		this._tsapiTSDIBuffers = [];
		this._switchLinks = [];
	}

	public checkSwitchLinksState() {
		let state = 0;
		this.switchLinks.forEach(e => {
			if (e.state === 'Red') {
				state = 2;
			}
		});
		return state;
	}

	public checkBufferState() {
		let state = 0;
		this.tsapiTSDIBuffers.forEach(e => {
			if (e.getSeverity() === 1 && state != 2) {
				state = 1;
			} else if (e.getSeverity() === 2) {
				state = 2;
			}
		});
		return state;
	}

	public checkLicenses() {
		const acquiredTotal = 0;
		const totalSum = 0;
		let severity = 0;
		for (const licence of this.licences) {
			if (licence.severity() > severity) {
				severity = licence.severity();
			}
		}

		return severity;
	}

	public severity() {
		const switchLinkState = this.checkSwitchLinksState();
		const checkLicenceState = this.checkLicenses();
		const checkBufferState = this.checkBufferState();

		if (switchLinkState === 0 && checkLicenceState === 0 && checkBufferState === 0) {
			return 0;
		} else if (switchLinkState === 0 && checkLicenceState === 1 && checkBufferState === 0) {
			return 1;
		} else if (switchLinkState === 0 && checkLicenceState === 0 && checkBufferState === 1) {
			return 1;
		} else if (switchLinkState === 0 && checkLicenceState === 1 && checkBufferState === 1) {
			return 1;
		} else {
			return 2;
		}
	}

	public getAcquiredLicences() {
		let acquiredTotal = 0;
		this.licences.forEach(e => {
			acquiredTotal += e.acquired;
		});
		return acquiredTotal;
	}

	public getTotalLicences() {
		let totalSum = 0;
		this.licences.forEach(e => {
			totalSum += e.total;
		});
		return totalSum;
	}

	public updateSwitchLinks(value: SwitchLink) {
		const index = this.switchLinks.findIndex(e => e.name == value.name && e.status == value.status);
		if (index != -1) {
			this.switchLinks[index].updateFromObject(value);
		} else {
			this.switchLinks.push(value);
		}
	}

	public updateTSAPITSDIBuffers(value: TSAPITSDIBuffer) {
		const index = this.tsapiTSDIBuffers.findIndex(e => e.name == value.name);
		if (index != -1) {
			this.tsapiTSDIBuffers[index].updateFromObject(value);
		} else {
			this.tsapiTSDIBuffers.push(value);
		}
	}

	public updateLicenses(licence: LicenceData) {
		const index = this.licences.findIndex(e => e.licenceName == licence.licenceName);
		if (index != -1) {
			this.licences[index].updateFromObject(licence);
		} else {
			this.licences.push(licence);
		}
	}

	public getStatus() {
		if (this.severity() == 0) {
			return 'No Issues detected';
		} else {
			return 'Issues detected';
		}
	}

	public get switchLinks(): SwitchLink[] {
		return this._switchLinks;
	}

	/**
	 * Getter tsapiTSDIBuffers
	 * @return {TSAPITSDIBuffer[]}
	 */
	public get tsapiTSDIBuffers(): TSAPITSDIBuffer[] {
		return this._tsapiTSDIBuffers;
	}

	/**
	 * Getter licence
	 * @return {LicenceData[]}
	 */
	public get licences(): LicenceData[] {
		return this._licences;
	}
}
