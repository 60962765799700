import { createAction, props } from '@ngrx/store';
import { Threshold } from '@models/index';

export const GetThresholdsBySettings = createAction(
  '[GetThresholdsBySettings] Get GetThresholdsBySettings',
  props<{ customerId: string, commandTypeId: string, locationId: string, equipmentId: string }>()
);

export const GetThresholdsBySettingsSuccess = createAction(
  '[GetThresholdsBySettings] Get GetThresholdsBySettings Success',
  props<{ data: Threshold[] }>()
);

export const GetThresholdsBySettingsFailure = createAction(
  '[GetThresholdsBySettings] Get GetThresholdsBySettings Failure',
  props<{ error: any }>()
);
