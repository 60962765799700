import { Equipment, NetworkConnectivityConfiguration } from '@models/index';

export class NetworkConnectivityEquipment extends Equipment {
	private _networkConnectivityConfiguration: NetworkConnectivityConfiguration;

	constructor(
		equipmentId: string,
		equipmentName: string,
		networkConnectivityConfiguration: NetworkConnectivityConfiguration
	) {
		super(equipmentId, equipmentName);
		this._networkConnectivityConfiguration = networkConnectivityConfiguration;
	}

	/**
	 * Getter networkConnectivityConfiguration
	 * @return {NetworkConnectivityConfiguration}
	 */
	public get networkConnectivityConfiguration(): NetworkConnectivityConfiguration {
		return this._networkConnectivityConfiguration;
	}

	/**
	 * Setter networkConnectivityConfiguration
	 * @param {NetworkConnectivityConfiguration} value
	 */
	public set networkConnectivityConfiguration(value: NetworkConnectivityConfiguration) {
		this._networkConnectivityConfiguration = value;
	}
}
