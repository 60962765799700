import {
    Customer,
    Dashlet,
    Location,
    NetworkConnectivityConfiguration,
    NetworkConnectivityData,
    NetworkConnectivityEquipment
} from '@models/index';
import { DashboardService, DashletDataProcessService, RealtimeGatewayService } from '@services/index';
import { Subject } from 'rxjs';

export class DashletNetworkConnectivitySummary extends Dashlet {
    public commandTypeIdNetwork = '7BF15776-F312-40C7-92A1-F95DDEAD52EA';

    public customer!: Customer;
    public equipment: NetworkConnectivityEquipment[] = [];
    sortColumn = 'loss';
    sortDesc = true;

    readonly interval = 30;

    location!: Location;
    lshName!: string | null;
    showNetworkConnectChart = true;
    public networkConnectivitySummaryData!: NetworkConnectivityData[];

    private chartDataUpdated: Subject<null> = new Subject();
    public settingsUpdated: Subject<null> = new Subject();

    get onChartDataUpdated() {
        return this.chartDataUpdated;
    }

    constructor(
        private dashboardService: DashboardService,
        private realtimeService: RealtimeGatewayService,
        private dashletDataService: DashletDataProcessService
    ) {
        super();

        // sizing
        this.sizes = [
            {
                id: 0,
                label: 'Small',
                cols: 5,
                rows: 3
            },
            {
                id: 1,
                label: 'Medium',
                cols: 5,
                rows: 6
            },
            {
                id: 2,
                label: 'Large',
                cols: 5,
                rows: 9
            }
        ];

        this.applySize(1);

        // init data
        this.resetData();
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        // unsub realtime feed
        this.unsubData();

        // read settings object
        this.configured = v.customer && v.location && v.equipment.length > 0;
        // clear equipment array
        this.equipment = [];

        if (v.customer) {
            this.customer = { customerId: v.customer.value, customerName: v.customer.label } as Customer;
            // this.loadCustomerLogo();
        } else {
            this.customer = new Customer('', '');
        }
        if (v.location) {
            this.location = { locationId: v.location.value, locationName: v.location.label } as Location;
        } else {
            this.location = new Location('', '');
        }
        this.generatedNameTag = this.configured ? `${v.location.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;
        if (v.equipment) {
            v.equipment.forEach((element: any) => {
                this.equipment.push(
                    new NetworkConnectivityEquipment(
                        element.value,
                        element.label,
                        NetworkConnectivityConfiguration.CreateFromConfigJSON(element.config)
                    )
                );
            });
        }
        if (this.configured) this.settingsUpdated.next(null);
        this.lshName = this.configured ? v.location.label : null;
        if (this.configured) this.chartDataUpdated.next(null);
        this.resetData();
    }

    applySize(id: number) {
        super.applySize(id);
    }

    public getUpdateInterval() {
        return this.dashboardService.getUpdateInterval(this.interval);
    }

    public processNetworkData(data: any[], equipment: NetworkConnectivityEquipment) {
        const row = data[0]?.data[0];

        if (row !== null) {
            const networkData = this.dashletDataService.processNetworkData(row);
            const networkConnectivityData = new NetworkConnectivityData(equipment);
            networkConnectivityData.dataExpired = false;
            networkConnectivityData.updateNetworkChartData(
                networkData.averagePing,
                networkData.packetLoss,
                networkData.maxPing,
                new Date(data[0]?.timestamp)
            );
            networkConnectivityData.eventName = row?.EventName ? row.EventName : '';
            this.addNetworkData(networkConnectivityData);
        }
        this.chartDataUpdated.next(null);
    }

    private addNetworkData(networkConnectivityData: NetworkConnectivityData) {
        const index = this.checkEquipmentNotInNetworkDataArray(networkConnectivityData);
        if (index === -1) {
            this.networkConnectivitySummaryData.push(networkConnectivityData);
        } else {
            this.networkConnectivitySummaryData[index].updateNetworkChartData(
                networkConnectivityData.getCurrentAvgPing(),
                networkConnectivityData.getCurrentPacketLossPercentage(),
                networkConnectivityData.getCurrentMaxPing(),
                networkConnectivityData.getLatestDate()
            );
        }
    }

    private checkEquipmentNotInNetworkDataArray(networkConnectivityData: NetworkConnectivityData) {
        return this.networkConnectivitySummaryData.findIndex(
            e => e.equipment.equipmentId === networkConnectivityData.equipment.equipmentId
        );
    }

    resetData() {
        this.lshName = null;
        this.networkConnectivitySummaryData = [];
        this.chartDataUpdated.next(null);
    }

    dispose() {
        this.unsubData();
    }

    private unsubData() {
        if (this.location) {
            // unsub data
            this.equipment.forEach(element => {
                this.realtimeService.unsubscribe(element.equipmentId, this.commandTypeIdNetwork);
            });
        }
    }
}
