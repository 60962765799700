import * as Actions from '@actions/index';
import {
    AccountService,
    DashletService,
    RealtimeGatewayService,
    ThresholdService,
    TileGridService,
    TimeoutService
} from '@services/index';
import {
    ACDConnectionData,
    BackupData,
    ColumnTypes,
    CustomerLogo,
    DashletSystemHealthCMS,
    Threshold,
    WFMHistoricData,
    WFMRealTimeData
} from '@models/index';
import { AppState, selectEntity, selectDataFromCommonEntity } from '@reducers/index';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ThresholdItemComponent } from '../threshold-item/threshold-item.component';

import { DashletSectionProcessorMemoryComponent } from '../dashlet-section-processor-memory/dashlet-section-processor-memory.component';
import { Subscription } from 'rxjs';
@Component({
    selector: 'app-dashlet-system-health-cms',
    templateUrl: './dashlet-system-health-cms.component.html',
    styleUrls: ['./dashlet-system-health-cms.component.scss']
})
export class DashletSystemHealthCMSComponent implements OnInit, OnDestroy {
    @Input() dashlet!: DashletSystemHealthCMS;
    @ViewChild('thresholdItem') thresholdItem!: ThresholdItemComponent;
    @ViewChild('processorMemory') processorMemory: DashletSectionProcessorMemoryComponent;

    openSubcontainer = '';
    openSubcontainerSeverity = 0;

    selectedField!: string;
    selectedThreshold!: Threshold;
    titleMsg: string;

    private subscription: Subscription = new Subscription();

    public wfmRealtimeColumns: ColumnTypes[] = [
        {
            columnDef: 'severity',
            header: '',
            cell: (row: WFMRealTimeData | WFMHistoricData) => row.severity,
            type: 'severity'
        },
        {
            columnDef: 'wfoVendor',
            header: 'WFM',
            cell: (row: WFMRealTimeData | WFMHistoricData) => row.wfoVendor
        },
        {
            columnDef: 'logEntryDateTime',
            header: 'Local Time',
            cell: (row: WFMRealTimeData | WFMHistoricData) => row.logEntryDateTime,
            type: 'date'
        },
        {
            columnDef: 'status',
            header: 'Status',
            cell: (row: WFMRealTimeData | WFMHistoricData) => row.status
        }
    ];

    public wfmHistoricColumns: ColumnTypes[] = [
        ...this.wfmRealtimeColumns,
        {
            columnDef: 'sessionId',
            header: 'Session',
            cell: (row: WFMHistoricData) => row.sessionId
        },
        {
            columnDef: 'filesize',
            header: 'File Size',
            cell: (row: WFMHistoricData) => row.fileSize
        }
    ];

    constructor(
        public dashletService: DashletService,
        private accountService: AccountService,
        private thresholdService: ThresholdService,
        private tileGridService: TileGridService,
        public timeoutService: TimeoutService,
        public realtimeService: RealtimeGatewayService,
        private store$: Store<AppState>
    ) {}

    ngOnInit(): void {
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));

        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdAdminBackup
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdAdminBackup
            })
        );
        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdAdminBackup
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length) {
                        this.dashlet.processAdminBackupData(data);
                    }
                })
        );
        this.store$.dispatch(
            Actions.GetCommonHistoric({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdAdminBackup,
                from: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                to: new Date().toISOString(),
                max: 3
            })
        );

        // commandTypeIdMaintenanceBackup

        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdMaintenanceBackup
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdMaintenanceBackup
            })
        );
        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdMaintenanceBackup
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length) {
                        this.dashlet.processMaintenanceBackupData(data);
                    }
                })
        );
        this.store$.dispatch(
            Actions.GetCommonHistoric({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdMaintenanceBackup,
                from: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                to: new Date().toISOString(),
                max: 3
            })
        );

        // commandTypeIdACD

        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdACD
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdACD
            })
        );
        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdACD)
                    )
                )
                .subscribe(data => {
                    if (data && data.length) {
                        this.dashlet.processACD(data);
                    }
                })
        );

        // processACDCalls
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdACDCalls
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdACDCalls
            })
        );
        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdACDCalls
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length) {
                        this.dashlet.processACDCalls(data);
                    }
                })
        );

        // commandTypeIdSupervisorLicense
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdSupervisorLicense
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdSupervisorLicense
            })
        );
        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdSupervisorLicense
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length) {
                        this.dashlet.processSupervisorLicenses(data);
                    }
                })
        );

        // commandTypeIdWFMRealTime
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdWFMRealTime
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdWFMRealTime
            })
        );
        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdWFMRealTime
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length) {
                        this.dashlet.processWFMStatusRealTime(data);
                    }
                })
        );

        // commandTypeIdWFMHistoric
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdWFMHistoric
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdWFMHistoric
            })
        );
        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdWFMHistoric
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length) {
                        this.dashlet.processWFMStatusHistoric(data);
                    }
                })
        );

        this.store$.dispatch(Actions.GetProcessThresholdData({ equipmentId: this.dashlet.equipment.equipmentId }));

        // subscribe to state
        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );

        this.titleMsg = this.timeoutService.getSystemDataExpiredmessage(
            this.timeoutService.formatSystemTimeoutString(
                this.processorMemory?.cpuUsageData?.dataExpired,
                this.processorMemory?.totalDiskUsageData?.dataExpired,
                this.processorMemory?.memoryUsageData?.dataExpired
            ),
            [
                this.processorMemory?.commandTypeIdCPUUsage,
                this.processorMemory?.commandTypeIdMemoryUsage,
                this.processorMemory?.commandTypeIdDiskUsage
            ],
            this.dashlet.equipment.equipmentId
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    getACDUpConnections() {
        const acdUpConnections: ACDConnectionData[] = [];
        this.dashlet.acdConnectionDataList.forEach(element => {
            if (element.connection === 'operational') {
                acdUpConnections.push(element);
            }
        });
        return acdUpConnections;
    }

    getACDDownConnections() {
        const acdDownConnections: ACDConnectionData[] = [];
        this.dashlet.acdConnectionDataList.forEach(element => {
            if (element.connection === 'down' || element.connection === 'out of order') {
                acdDownConnections.push(element);
            }
        });
        return acdDownConnections;
    }

    getGreenWFMRealTimeConnections() {
        return this.dashlet.wfmRealTimeDataList.filter(e => e.severity === 0);
    }

    getGreenWFMRealTimeConnectionsTooltip() {
        let tooltip = '';
        for (const connection of this.getGreenWFMRealTimeConnections()) {
            tooltip += `${connection.wfoVendor} ${connection.status}, ${connection.logEntryDateTime} `;
        }
        return tooltip;
    }

    getOrangeWFMRealTimeConnections() {
        return this.dashlet.wfmRealTimeDataList.filter(e => e.severity === 1);
    }

    getOrangeWFMRealTimeConnectionsTooltip() {
        let tooltip = '';
        for (const connection of this.getOrangeWFMRealTimeConnections()) {
            tooltip += `${connection.wfoVendor} ${connection.status}, ${connection.logEntryDateTime} `;
        }
        return tooltip;
    }

    getRedWFMRealTimeConnections() {
        return this.dashlet.wfmRealTimeDataList.filter(e => e.severity === 2);
    }

    getRedWFMRealTimeConnectionsTooltip() {
        let tooltip = '';
        for (const connection of this.getRedWFMRealTimeConnections()) {
            tooltip += `${connection.wfoVendor} ${connection.status}, ${connection.logEntryDateTime} `;
        }
        return tooltip;
    }

    getGreenWFMHistoricConnections() {
        return this.dashlet.wfmHistoricDataList.filter(e => e.severity === 0);
    }

    getGreenWFMHistoricConnectionsTooltip() {
        let tooltip = '';
        for (const connection of this.getGreenWFMHistoricConnections()) {
            tooltip += `${connection.wfoVendor} ${connection.status}, ${connection.logEntryDateTime} `;
        }
        return tooltip;
    }

    getRedWFMHistoricConnections() {
        return this.dashlet.wfmHistoricDataList.filter(e => e.severity === 2);
    }

    getRedWFMHistoricConnectionsTooltip() {
        let tooltip = '';
        for (const connection of this.getRedWFMHistoricConnections()) {
            tooltip += `${connection.wfoVendor} ${connection.status}, ${connection.logEntryDateTime} `;
        }
        return tooltip;
    }

    getSeverityColor(severity: number | undefined): string {
        severity = +severity!;
        return this.dashletService.getSeverityColor(severity!);
    }

    getMaintenanceSeverityColor(data: BackupData): string {
        const date = this.parseUnknownAbbreviations(data.displayDateTime);
        const severity = +data.state;

        if (date) {
            const daysBetween = Math.abs(Date.now() - date) / (1000 * 60 * 60 * 24);
            if (daysBetween >= 2) {
                return this.dashletService.getSeverityColor(2);
            }
        }

        return this.dashletService.getSeverityColor(severity);
    }

    getAdminSeverityColor(data: BackupData): string {
        const date = this.parseUnknownAbbreviations(data.displayDateTime);
        const severity = +data.state;

        if (date) {
            const daysBetween = Math.abs(Date.now() - date) / (1000 * 60 * 60 * 24);
            if (daysBetween >= 31) {
                return this.dashletService.getSeverityColor(2);
            }
        }

        return this.dashletService.getSeverityColor(severity);
    }

    private parseUnknownAbbreviations(dateString: string): number {
        // time zone abbreviations fail to be parsed correctly so must be replaced
        if (dateString.match(/NZST|NZDT|BST|BDT|EDT|GMT|CST|PST|PDT|CDT/)) {
            return Date.parse(
                dateString
                    .replace('NZST', 'GMT+1200')
                    .replace('NZDT', 'GMT+1300')
                    .replace('BST', 'GMT+0100')
                    .replace('BDT', 'GMT')
            );
        } else {
            // if not recognised just remove timezone (if it exists)
            return dateString.split(' ').length > 4
                ? Date.parse(dateString.replace(dateString.split(' ')[4], ''))
                : Date.parse(dateString);
        }
    }

    severityGreen() {
        if (this.openSubcontainerSeverity === 0) {
            return true;
        } else {
            return false;
        }
    }

    openDetails(container: string, severity: number) {
        this.openSubcontainer = container;
        this.dashlet.displayHeader = false;
        this.openSubcontainerSeverity = severity;
    }

    closeDetails() {
        this.openSubcontainer = '';
        this.dashlet.displayHeader = true;
    }

    openThresholdAlerts(elementId: string, commandTypeId: string, diskName?: string) {
        this.openSubcontainer = 'createThreshold';
        this.dashlet.displayHeader = false;
        this.selectedThreshold = new Threshold();
        this.selectedThreshold.commandTypeId = commandTypeId;
        this.selectedThreshold.field = elementId;
        this.selectedThreshold.whereCreated = 'Dashboard';
        if (diskName) {
            this.selectedThreshold.fieldInstance = diskName;
        }
    }

    openAlarmIconThreshold() {
        this.thresholdService.openThresholdManagement(this.accountService.getUserDetails().EntityId);
    }

    onSubmit() {
        this.subscription.add(
            this.thresholdItem.onSubmit().subscribe((result: any) => {
                if (result) {
                    this.selectedThreshold.dapThresholdId = result['DapThresholdId'];
                    this.dashlet.thresholds.push(this.selectedThreshold);
                    switch (this.selectedThreshold.field) {
                        case 'processor':
                            ++this.processorMemory.cpuUsageData.thresholdsNumber;
                            break;
                        case 'memory':
                            ++this.processorMemory.memoryUsageData.thresholdsNumber;
                            break;
                        case 'disk':
                            // eslint-disable-next-line no-case-declarations
                            const diskUsage = this.processorMemory.diskUsageData.find(d => {
                                return d.diskName === this.selectedThreshold.fieldInstance;
                            });
                            if (diskUsage) {
                                ++diskUsage.thresholdsNumber;
                            }
                            break;
                        default:
                            break;
                    }
                    this.tileGridService.thresholdCreated(
                        this.selectedThreshold.name + ' threshold added via ' + this.dashlet.title
                    );
                } else {
                    this.tileGridService.thresholdCreated('Threshold add failed');
                }
            })
        );
        this.closeDetails();
    }

    saveEdit() {
        this.subscription.add(
            this.thresholdItem.saveEdit().subscribe(result => {
                if (result) {
                    this.tileGridService.thresholdCreated(
                        this.selectedThreshold.name + ' threshold edited via ' + this.dashlet.title
                    );
                } else {
                    this.tileGridService.thresholdCreated('Threshold edit failed');
                }
            })
        );
        this.closeDetails();
    }
}
