import {
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingDropdownGrouped,
    DashletSettingList
} from '@models/index';
import { DashletSettingsService, NotificationService } from '@services/index';
import { EMPTY, catchError, forkJoin, take, tap } from 'rxjs';

export class DashletSettingsFormTrunkGroupList extends DashletSettingsForm {
    private customer!: DashletSettingDropdown;
    private location!: DashletSettingDropdown;
    private equipment!: DashletSettingDropdownGrouped;
    private trunkGroups!: DashletSettingList<string>;

    constructor(
        private readonly settingsService: DashletSettingsService,
        private readonly notificationService: NotificationService
    ) {
        super();

        this.init();
        this.loadCustomers();
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);

        if (this.customer.value) {
            this.loadLocations(this.customer.value);
        }
        if (this.location.value) {
            this.loadEquipment(this.location.value);
            if (this.equipment.value) {
                this.loadTrunkGroups(this.equipment.value);
            }
        }
    }

    private init(): void {
        const group = new DashletSettingsGroup();
        const s: any = (group.settings = []);

        const customer = (this.customer = new DashletSettingDropdown());
        customer.label = 'Customer';
        customer.stateKey = 'customer';
        customer.disabled = true;
        customer.change.subscribe(() => {
            if (!this.location.value) this.clearAndDisable(this.location);
            if (!this.equipment.value) this.clearAndDisable(this.equipment);
            if (!this.trunkGroups.values) this.clearAndDisable(this.trunkGroups);

            this.loadLocations(customer.value!);
        });
        s.push(customer);

        const location = (this.location = new DashletSettingDropdown());
        location.label = 'Location';
        location.stateKey = 'location';
        location.disabled = true;
        location.change.subscribe(() => {
            if (!this.equipment.value) this.clearAndDisable(this.equipment);
            if (!this.trunkGroups.values) this.clearAndDisable(this.trunkGroups);

            this.loadEquipment(location.value!);
        });
        s.push(location);

        const equipment = (this.equipment = new DashletSettingDropdownGrouped());
        equipment.label = 'Equipment';
        equipment.stateKey = 'equipment';
        equipment.disabled = true;
        equipment.change.subscribe(() => {
            if (!this.trunkGroups.values) this.clearAndDisable(this.trunkGroups);

            this.loadTrunkGroups(equipment.value);
        });
        s.push(equipment);

        const trunk = (this.trunkGroups = new DashletSettingList<string>());
        trunk.label = 'Trunk Groups';
        trunk.stateKey = 'trunkGroups';
        trunk.disabled = true;
        trunk.multiple = true;
        trunk.metaKeySelection = false;
        s.push(trunk);

        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);
        this.settingsService.getUserAssociatedCustomers().subscribe(
            customers => {
                this.customer.items = customers;
                this.customer.loadingComplete();
            },
            () => {
                this.customer.loadingComplete();
                this.customer.disabled = true;
                // show error notification here
            }
        );
    }

    private loadLocations(customer: string): void {
        this.enableAndLoading(this.location);
        this.settingsService.getLocations(customer).subscribe(
            locations => {
                this.location.items = locations;
                this.location.loadingComplete();
            },
            () => {
                this.location.loadingComplete();
                this.location.disabled = true;
                // show error notification here
            }
        );
    }

    private loadEquipment(location: string): void {
        this.enableAndLoading(this.equipment);
        forkJoin([
            this.settingsService.getEquipment(location, 'ACM'),
            this.settingsService.getEquipment(location, 'IPOffice'),
            this.settingsService.getEquipment(location, 'AEP')
        ])
            .pipe(
                take(1),
                tap(([acm, ipo, aep]) => {
                    const groups = [
                        {
                            label: 'ACM',
                            items: acm
                        },
                        {
                            label: 'IP Office',
                            items: ipo
                        },
                        {
                            label: 'AEP',
                            items: aep
                        }
                    ];
                    this.equipment.items = groups;
                    this.equipment.loadingComplete();
                }),
                catchError(() => {
                    this.equipment.loadingComplete();
                    this.equipment.disabled = true;
                    this.notificationService.notify('Could not load equipment', 'error');
                    return EMPTY;
                })
            )
            .subscribe();
    }

    private loadTrunkGroups(equipment: string): void {
        this.enableAndLoading(this.trunkGroups);
        this.settingsService
            .getTrunkGroups(equipment)
            .pipe(
                take(1),
                tap(trunks => {
                    this.trunkGroups.items = trunks;
                    this.trunkGroups.loadingComplete();
                }),
                catchError(() => {
                    this.trunkGroups.loadingComplete();
                    this.trunkGroups.disabled = true;
                    this.notificationService.notify('Could not load trunk groups', 'error');
                    return EMPTY;
                })
            )
            .subscribe();
    }

    private clearAndDisable(
        widget: DashletSettingDropdown | DashletSettingDropdownGrouped | DashletSettingList<string>
    ) {
        widget.disabled = true;
        widget.items = [];
        if (widget instanceof DashletSettingList) {
            widget.values = [];
        } else {
            widget.value = null;
        }
    }

    private enableAndLoading(
        widget: DashletSettingDropdown | DashletSettingDropdownGrouped | DashletSettingList<string>
    ) {
        widget.disabled = false;
        widget.loadingBegin();
    }
}
