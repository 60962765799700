import {createReducer, on } from '@ngrx/store';

import * as BrandingActions from '@actions/branding/branding.actions';
import { BrandingColors, LogoImage,  } from '@models/index';

export const brandingFeatureKey = 'branding';

export interface State {
    colors: BrandingColors;
    businessPartnerLogo: LogoImage;
}

export const initialState: State = {
    colors: {
        primaryColour: '#041c2c',
        onPrimaryColour: '#FFFFFF',
        secondaryColour: '#97D700',
        onSecondaryColour: '#FFFFFF'
    },
    businessPartnerLogo: undefined
};

export const colorReducer = createReducer(
    initialState,
    on(BrandingActions.GetBrandingColorsSuccess, (state, { data }) => ({
        ...state,
        colors: data
    })),
    on(BrandingActions.GetBusinessPartnerLogoSuccess, (state, { data }) => ({
        ...state,
        businessPartnerLogo: data
    }))
);
