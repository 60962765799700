export class NetworkConnectivityConfiguration {
    private _buildTTLProfile: string;
    private _destinationIPAddress: string;
    private _failureReportingType: string;
    private _friendlyName: string;
    private _hiddenFromView: string;
    private _ipAddress: string;
    private _maximumAcceptableAverageResponseMs: string;
    private _maximumAcceptableIndividualResponseMs: string;
    private _pingAttempts: string;
    private _repeatIntervalMinutes: string;
    private _sectionId: string;
    private _sendAlarmOnFailure: string;
    private _successReportingType: string;
    private _ttlValue: string;

    private constructor(
        buildTTLProfile: string,
        destinationIPAddress: string,
        failureReportingType: string,
        friendlyName: string,
        hiddenFromView: string,
        ipAddress: string,
        maximumAcceptableAverageResponseMs: string,
        maximumAcceptableIndividualResponseMs: string,
        pingAttempts: string,
        repeatIntervalMinutes: string,
        sectionId: string,
        sendAlarmOnFailure: string,
        successReportingType: string,
        ttlValue: string
    ) {
        this._buildTTLProfile = buildTTLProfile;
        this._destinationIPAddress = destinationIPAddress;
        this._failureReportingType = failureReportingType;
        this._friendlyName = friendlyName;
        this._hiddenFromView = hiddenFromView;
        this._ipAddress = ipAddress;
        this._maximumAcceptableAverageResponseMs = maximumAcceptableAverageResponseMs;
        this._maximumAcceptableIndividualResponseMs = maximumAcceptableIndividualResponseMs;
        this._pingAttempts = pingAttempts;
        this._repeatIntervalMinutes = repeatIntervalMinutes;
        this._sectionId = sectionId;
        this._sendAlarmOnFailure = sendAlarmOnFailure;
        this._successReportingType = successReportingType;
        this._ttlValue = ttlValue;
    }

    public static CreateEmpty() {
        return new NetworkConnectivityConfiguration(
            '---',
            '---',
            '---',
            '---',
            '---',
            '---',
            '---',
            '---',
            '---',
            '---',
            '---',
            '---',
            '---',
            '---'
        );
    }

    public static Create(
        buildTTLProfile: string,
        destinationIPAddress: string,
        failureReportingType: string,
        friendlyName: string,
        hiddenFromView: string,
        ipAddress: string,
        maximumAcceptableAverageResponseMs: string,
        maximumAcceptableIndividualResponseMs: string,
        pingAttempts: string,
        repeatIntervalMinutes: string,
        sectionId: string,
        sendAlarmOnFailure: string,
        successReportingType: string,
        ttlValue: string
    ) {
        return new NetworkConnectivityConfiguration(
            buildTTLProfile,
            destinationIPAddress,
            failureReportingType,
            friendlyName,
            hiddenFromView,
            ipAddress,
            maximumAcceptableAverageResponseMs,
            maximumAcceptableIndividualResponseMs,
            pingAttempts,
            repeatIntervalMinutes,
            sectionId,
            sendAlarmOnFailure,
            successReportingType,
            ttlValue
        );
    }

    public static CreateFromConfigJSON(config: any) {
        if (config && config !== 'null') {
            return new NetworkConnectivityConfiguration(
                config.BuildTTLProfile,
                config.DestinationIPAddress,
                config.FailureReportingType,
                config.FriendlyName,
                config.HiddenFromView,
                config.IPAddress,
                config.MaximumAcceptableAverageResponseMs,
                config.MaximumAcceptableIndividualResponseMs,
                config.PingAttempts,
                config.RepeatIntervalMinutes,
                config.SectionId,
                config.SendAlarmOnFailure,
                config.SuccessReportingType,
                config.TTLValue
            );
        } else {
            return NetworkConnectivityConfiguration.CreateEmpty();
        }
    }

    /**
     * Getter buildTTLProfile
     * @return {string}
     */
    public get buildTTLProfile(): string {
        return this._buildTTLProfile;
    }

    /**
     * Getter destinationIPAddress
     * @return {string}
     */
    public get destinationIPAddress(): string {
        return this._destinationIPAddress;
    }

    /**
     * Getter failureReportingType
     * @return {string}
     */
    public get failureReportingType(): string {
        return this._failureReportingType;
    }

    /**
     * Getter friendlyName
     * @return {string}
     */
    public get friendlyName(): string {
        return this._friendlyName;
    }

    /**
     * Getter hiddenFromView
     * @return {string}
     */
    public get hiddenFromView(): string {
        return this._hiddenFromView;
    }

    /**
     * Getter ipAddress
     * @return {string}
     */
    public get ipAddress(): string {
        return this._ipAddress;
    }

    /**
     * Getter maximumAcceptableAverageResponseMs
     * @return {string}
     */
    public get maximumAcceptableAverageResponseMs(): string {
        return this._maximumAcceptableAverageResponseMs;
    }

    /**
     * Getter maximumAcceptableIndividualResponseMs
     * @return {string}
     */
    public get maximumAcceptableIndividualResponseMs(): string {
        return this._maximumAcceptableIndividualResponseMs;
    }

    /**
     * Getter pingAttempts
     * @return {string}
     */
    public get pingAttempts(): string {
        return this._pingAttempts;
    }

    /**
     * Getter repeatIntervalMinutes
     * @return {string}
     */
    public get repeatIntervalMinutes(): string {
        return this._repeatIntervalMinutes;
    }

    /**
     * Getter sectionId
     * @return {string}
     */
    public get sectionId(): string {
        return this._sectionId;
    }

    /**
     * Getter sendAlarmOnFailure
     * @return {string}
     */
    public get sendAlarmOnFailure(): string {
        return this._sendAlarmOnFailure;
    }

    /**
     * Getter successReportingType
     * @return {string}
     */
    public get successReportingType(): string {
        return this._successReportingType;
    }

    /**
     * Getter ttlValue
     * @return {string}
     */
    public get ttlValue(): string {
        return this._ttlValue;
    }

    /**
     * Setter buildTTLProfile
     * @param {string} value
     */
    public set buildTTLProfile(value: string) {
        this._buildTTLProfile = value;
    }

    /**
     * Setter destinationIPAddress
     * @param {string} value
     */
    public set destinationIPAddress(value: string) {
        this._destinationIPAddress = value;
    }

    /**
     * Setter failureReportingType
     * @param {string} value
     */
    public set failureReportingType(value: string) {
        this._failureReportingType = value;
    }

    /**
     * Setter friendlyName
     * @param {string} value
     */
    public set friendlyName(value: string) {
        this._friendlyName = value;
    }

    /**
     * Setter hiddenFromView
     * @param {string} value
     */
    public set hiddenFromView(value: string) {
        this._hiddenFromView = value;
    }

    /**
     * Setter ipAddress
     * @param {string} value
     */
    public set ipAddress(value: string) {
        this._ipAddress = value;
    }

    /**
     * Setter maximumAcceptableAverageResponseMs
     * @param {string} value
     */
    public set maximumAcceptableAverageResponseMs(value: string) {
        this._maximumAcceptableAverageResponseMs = value;
    }

    /**
     * Setter maximumAcceptableIndividualResponseMs
     * @param {string} value
     */
    public set maximumAcceptableIndividualResponseMs(value: string) {
        this._maximumAcceptableIndividualResponseMs = value;
    }

    /**
     * Setter pingAttempts
     * @param {string} value
     */
    public set pingAttempts(value: string) {
        this._pingAttempts = value;
    }

    /**
     * Setter repeatIntervalMinutes
     * @param {string} value
     */
    public set repeatIntervalMinutes(value: string) {
        this._repeatIntervalMinutes = value;
    }

    /**
     * Setter sectionId
     * @param {string} value
     */
    public set sectionId(value: string) {
        this._sectionId = value;
    }

    /**
     * Setter sendAlarmOnFailure
     * @param {string} value
     */
    public set sendAlarmOnFailure(value: string) {
        this._sendAlarmOnFailure = value;
    }

    /**
     * Setter successReportingType
     * @param {string} value
     */
    public set successReportingType(value: string) {
        this._successReportingType = value;
    }

    /**
     * Setter ttlValue
     * @param {string} value
     */
    public set ttlValue(value: string) {
        this._ttlValue = value;
    }
}
