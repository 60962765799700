import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

export const GetNotifyCommonEntitys = createAction(
    '[CommonEntity] Get Notify CommonEntitys',
    props<{ equipmentId: string; commandTypeId: string }>()
);

export const GetNotifyCommonEntitysSuccess = createAction(
    '[CommonEntity] Get Notify CommonEntitys Success',
    props<{ data: RealTimeNotify }>()
);

export const GetNotifyCommonEntitysFailure = createAction(
    '[CommonEntity] Get Notify CommonEntitys Failure',
    props<{ error: any }>()
);

export const GetCommonEntitys = createAction(
    '[CommonEntity] Get CommonEntitys',
    props<{ equipmentId: string; commandTypeId: string; idKey: string }>()
);

export const GetCommonEntitysSuccess = createAction('[CommonEntity] Get CommonEntitys Success', props<{ data: any }>());

export const GetCommonEntitysFailure = createAction(
    '[CommonEntity] Get CommonEntitys Failure',
    props<{ error: any }>()
);

// Historic data
export const GetCommonHistoric = createAction(
    '[CommonEntity] Get CommonHistoric Eqipment Data',
    props<{
        equipmentId: string;
        commandTypeId: string;
        from?: Date | string;
        to?: Date | string;
        max?: number | null;
    }>()
);

export const GetCommonHistoricSuccess = createAction(
    '[CommonEntity] Get CommonHistoric Eqipment Data Success',
    props<{ data: any }>()
);

export const GetCommonHistoricFailure = createAction(
    '[CommonEntity] Get CommonHistoric Eqipment Data Failure',
    props<{ error: any }>()
);
