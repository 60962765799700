import { IpnrMap } from '@models/index';
import { Subject } from 'rxjs';
import { DashletSettingsService } from '@services/index';
import { Customer } from '@models/dashboard/Customer';
import { HttpClient } from '@angular/common/http';
import { Dashlet } from '@models/index';
import { environment } from '@environments/environment';

export interface CipData {
    callStart: any;
    sourceIp: number;
    remoteIp: number | null;
    sourceName: string | undefined;
    remoteName: string | undefined | null;
    sourceEndPoint: string;
    remoteEndPoint: string | null;
    sourceMos: number;
    remoteMos: number | null;
    sourceIpnr: number | null;
    remoteIpnr: number | null;
    sourcePhoneName: string | null;
    remotePhoneName: string | null;
    rtcpReceiver: string | null;
    qosWeakest: number;
    cipAdditional: CipAdditional;
    sourceSeverity: number;
    remoteSeverity: number;
}

export interface CipAdditional {
    mosMax: string;
    mosMin: string;
    length: number;
    dscp: string;
    rtcpReceiver: string;
    port: string;
    codec: string;
}

export interface LyncPhoneName {
    name?: string;
    number?: string;
}

export class DashletVoiceQualityStreamsInProgress extends Dashlet {
    loading = true;
    loadingChecker = true;

    public readonly commandTypeIdRtcpNew = '3A176A87-F14C-447E-AD1F-CD72E35EBC28';
    public readonly commandTypeIdLync = '73181898-00e7-41ce-8182-ae8d10a9f3a8';
    public readonly commandTypeIdIpnrLookup = '142e7a84-1b04-4c59-a645-9b9b1e5b5312';
    public locationId!: string;
    private ipnrs!: string[] | null;
    public customer!: Customer;
    public equipmentIds!: string[];
    public ipnrMap: IpnrMap = new IpnrMap();

    public cipReceiverMap!: Map<string, CipData[]>;
    public cipData: CipData[] = [];
    public processTime!: number | null;
    public totalData = 0;
    public cipTotalDataMap!: Map<string, number>;
    public lyncPhoneList: LyncPhoneName[] = [];

    public tableHeight: number = 5;

    private dataUpdated: Subject<null> = new Subject();
    public settingsUpdated: Subject<null> = new Subject();
    public sizeChange = new Subject<number>();
    public rtcpDataTimedOut = true;

    get onDataUpdated() {
        return this.dataUpdated;
    }
    public equipmentId: string;

    constructor(private settingsService: DashletSettingsService, private http: HttpClient) {
        super();

        this.sizes = [
            {
                id: 0,
                label: 'Small',
                cols: 5,
                rows: 4
            },
            {
                id: 1,
                label: 'Medium',
                cols: 5,
                rows: 8
            },
            {
                id: 2,
                label: 'Large',
                cols: 10,
                rows: 8
            },
            {
                id: 3,
                label: 'Huge',
                cols: 12,
                rows: 12
            }
        ];
        this.applySize(2);
    }

    public applySize(id: number): void {
        if (id === 0) {
            this.tableHeight = 6;
        } else if (id === 1) {
            this.tableHeight = 12;
        }
        if (id === 2) {
            this.tableHeight = 12;
        }
        if (id === 3) {
            this.tableHeight = 24;
        }
        super.applySize(id);
        this.sizeChange.next(id);
    }

    public applySettings(v: { [key: string]: any }) {
        super.applySettings(v);
        this.resetData();

        this.configured = v.customer && v.location && v.equipment && v.ipNetworkRegion;

        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        const equipValue: string = v.equipment && v.equipment.value ? v.equipment.value : null;
        if (equipValue && v.customer && v.location) {
            this.ipnrs = null;
            this.configured = true;
        } else {
            this.ipnrs =
                v.ipNetworkRegion && v.ipNetworkRegion.value
                    ? v.ipNetworkRegion.value === '=all='
                        ? null
                        : [v.ipNetworkRegion.value]
                    : null;
        }
        this.generatedNameTag =
            v.location && v.equipment ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;
        this.equipmentIds = [equipValue];
        if (this.configured) {
            this.settingsUpdated.next(null);
            if (
                (v.equipment && v.equipment.value && this.equipmentId !== v.equipment.value) ||
                (v.location && v.location.value && this.locationId !== v.location.value)
            ) {
                this.equipmentId = v.equipment.value;
                this.locationId = v.location.value;
            }

            this.customNameTag = v.nameTag;
        }
    }

    public getPhoneNameList() {
        this.http.get<any>(environment.centralApi + 'PhoneList/' + this.customer.customerId).subscribe(result => {
            this.lyncPhoneList = [];
            result.forEach((element: any) => {
                this.lyncPhoneList.push({
                    name: element.Name,
                    number: element.Number
                });
            });
        });
    }

    public dispose() {}

    public processData(): void {
        for (const cip of this.cipData) {
            if (cip.rtcpReceiver) {
                const sourceIpNameMap = this.ipnrMap ? this.ipnrMap.getIpnrMap(cip.sourcePhoneName) : null;
                cip.sourceIp = sourceIpNameMap ? sourceIpNameMap.get('ip') : null;
                cip.sourceEndPoint = sourceIpNameMap ? sourceIpNameMap.get('endPonit') : null;
                cip.sourceIpnr = sourceIpNameMap ? sourceIpNameMap.get('ipnr') : null;
                cip.sourceName = sourceIpNameMap ? sourceIpNameMap.get('description') : null;

                const reomoteIpNameMap = this.ipnrMap ? this.ipnrMap.getIpnrMap(cip.remotePhoneName) : null;
                cip.remoteEndPoint = reomoteIpNameMap ? reomoteIpNameMap.get('endPonit') : null;
                cip.remoteIp = reomoteIpNameMap ? reomoteIpNameMap.get('ip') : null;
                cip.remoteIpnr = reomoteIpNameMap ? reomoteIpNameMap.get('ipnr') : null;
                cip.remoteName = reomoteIpNameMap ? reomoteIpNameMap.get('description') : null;
            }
        }
        if (this.ipnrs && this.ipnrs.length > 0) {
            this.cipData = this.cipData.filter(e =>
                this.ipnrs!.some(ipnr => parseInt(ipnr) === e.remoteIpnr || parseInt(ipnr) === e.sourceIpnr)
            );
        }

        if (this.loadingChecker) {
            this.loading = false;
        }
        this.loadingChecker = false;
    }

    public processLyncData(lyncKey: string, data: any): any {
        const row = data[0].data;
        if (row !== null) {
            const cips: CipData[] = [];
            for (const rowData of row) {
                const phoneFrom: string = rowData.FromURI;
                const phoneTo: string = rowData.ToURI;

                const indexFrom1 = phoneFrom.indexOf(';');
                const indexTo1 = phoneTo.indexOf(';');

                let phoneFromSmall =
                    indexFrom1 >= 0
                        ? phoneFrom.substring(0, indexFrom1).replace('sip:', '')
                        : phoneFrom.replace('sip:', '');

                let phoneToSmall =
                    indexTo1 >= 0 ? phoneTo.substring(0, indexTo1).replace('sip:', '') : phoneTo.replace('sip:', '');

                const indexFrom2 = phoneFromSmall.indexOf('@');
                const indexTo2 = phoneToSmall.indexOf('@');

                phoneFromSmall = indexFrom2 >= 0 ? phoneFromSmall.substring(0, indexFrom2) : phoneFromSmall;

                phoneToSmall = indexTo2 >= 0 ? phoneToSmall.substring(0, indexTo2) : phoneToSmall;

                const cip: CipData = {
                    callStart: rowData.StartTimeStamp,
                    sourceIp: rowData.FromIP,
                    remoteIp: rowData.ToIP,
                    sourceName: this.getLyncPhoneName(rowData.FromPort), // phoneFromSmall,
                    remoteName: this.getLyncPhoneName(rowData.ToPort), // phoneToSmall,
                    sourceEndPoint: rowData.FromPort,
                    remoteEndPoint: rowData.ToPort,
                    sourceMos: rowData.FromQualityScore,
                    remoteMos: rowData.ToQualityScore,
                    sourceSeverity: rowData.FromQualityScore,
                    remoteSeverity: rowData.ToQualityScore,
                    sourceIpnr: null,
                    remoteIpnr: null,
                    sourcePhoneName: rowData.PhoneName,
                    remotePhoneName: null,
                    rtcpReceiver: null,
                    qosWeakest:
                        rowData.FromQualityScore < rowData.ToQualityScore
                            ? rowData.FromQualityScore
                            : rowData.ToQualityScore,
                    cipAdditional: this.processCipAdditional(rowData)
                };

                if (
                    !cips.find(element => element.sourceName === phoneToSmall && element.remoteName === phoneFromSmall)
                ) {
                    cips.push(cip);
                }
            }
            this.cipReceiverMap.set(lyncKey, cips);
        }
    }

    private getLyncPhoneName(portNumber: any): string | undefined {
        const record = this.lyncPhoneList.find(x => x.number === portNumber);
        return record ? record.number : undefined;
    }

    public processRtcpData(rtcpKey: string, data: any): void {
        const row = data[0].data.slice(0, 200);
        if (row !== null) {
            this.cipData = [];
            const callsToRemove: string[] = [];
            const cips: CipData[] = [];
            const unknown = data[0].data[0].MosUnknown ? +data[0].data[0].MosUnknown : 0;
            const low = data[0].data[0].MosLow ? +data[0].data[0].MosLow : 0;
            const med = data[0].data[0].MosMedium ? +data[0].data[0].MosMedium : 0;
            const high = data[0].data[0].MosHigh ? +data[0].data[0].MosHigh : 0;
            const totalData = unknown + low + med + high;
            this.cipTotalDataMap.set(rtcpKey, totalData);

            for (const rowData of row) {
                if (rowData.QoS) {
                    if (!callsToRemove.find(element => element === rowData.LocalSourceID + rowData.RemoteSourceID)) {
                        const sourceIpNameMap = this.ipnrMap ? this.ipnrMap.getIpnrMap(rowData.PhoneName) : null;

                        const cip: CipData = {
                            callStart: rowData.StartTime || null,
                            sourceIp: sourceIpNameMap ? sourceIpNameMap.get('ip') : null,
                            remoteIp: null,
                            sourceName: sourceIpNameMap ? sourceIpNameMap.get('description') : null,
                            remoteName: null,
                            sourceEndPoint: sourceIpNameMap ? sourceIpNameMap.get('endPonit') : null,
                            remoteEndPoint: null,
                            sourceMos: rowData.QoS,
                            remoteMos: null,
                            sourceSeverity: rowData.QoS,
                            remoteSeverity: null,
                            sourceIpnr: sourceIpNameMap ? sourceIpNameMap.get('ipnr') : null,
                            remoteIpnr: null,
                            sourcePhoneName: rowData.PhoneName,
                            remotePhoneName: null,
                            rtcpReceiver: rtcpKey,
                            qosWeakest: rowData.QoS,
                            cipAdditional: this.processCipAdditional(rowData)
                        };

                        const localSourceId = rowData.LocalSourceID;
                        if (localSourceId) {
                            const remoteRecord = row.find(
                                (rowObject: any) => rowObject.RemoteSourceID === localSourceId
                            );
                            if (remoteRecord) {
                                const remoteIpNameMap = this.ipnrMap
                                    ? this.ipnrMap.getIpnrMap(remoteRecord.PhoneName)
                                    : null;
                                cip.remotePhoneName = remoteRecord.PhoneName;
                                cip.remoteName = remoteIpNameMap ? remoteIpNameMap.get('description') : null;
                                cip.remoteMos = remoteRecord.QoS;
                                cip.remoteSeverity = remoteRecord.QoS;
                                cip.remoteIp = remoteIpNameMap ? remoteIpNameMap.get('ip') : null;
                                cip.remoteEndPoint = remoteIpNameMap ? remoteIpNameMap.get('endPonit') : null;
                                cip.remoteIpnr = remoteIpNameMap ? remoteIpNameMap.get('ipnr') : null;
                                cip.qosWeakest = remoteRecord.QoS < rowData.QoS ? remoteRecord.QoS : rowData.QoS;
                                callsToRemove.push(remoteRecord.LocalSourceID + remoteRecord.RemoteSourceID);
                            }
                        }
                        cips.push(cip);
                    }
                }
            }
            this.cipReceiverMap.set(rtcpKey, cips);
            this.updateCips();
        }
    }

    private processCipAdditional(rowData: any): CipAdditional {
        return {
            mosMax: '',
            mosMin: '',
            length: rowData.StreamLength,
            dscp: rowData.DSCP,
            rtcpReceiver: '',
            port: rowData.RtcpProbePort,
            codec: rowData.Codec
        };
    }

    private updateCips() {
        this.cipData = [];
        this.cipReceiverMap.forEach(cip => {
            this.cipData.push.apply(this.cipData, cip);
        });
        this.totalData = 0;
        this.cipTotalDataMap.forEach(number => {
            this.totalData += number;
        });
        if (this.ipnrMap) {
            this.processData();
        }
    }

    resetData() {
        this.cipReceiverMap = new Map<string, CipData[]>();
        this.cipTotalDataMap = new Map<string, number>();
        this.cipData = [];
        this.processTime = null;
        this.dataUpdated.next(null);
        this.totalData = 0;
    }
}
