import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as HistoricNetworkConnectivityDataActions from '@actions/index';
import { of } from 'rxjs';
import { RealtimeGatewayService, TimeoutService } from '@services/index';
import { RealTimeNotify } from '@models/index';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class HistoricNetworkConnectivityEffects {
    constructor(private actions$: Actions, private realtimeGatewayService: RealtimeGatewayService) {}

    getHistoricNetworkConnectivityData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HistoricNetworkConnectivityDataActions.GetHistoricNetworkConnectivity),
            switchMap(action =>
                this.realtimeGatewayService
                    .requestHistoricDocument(
                        action.equipmentId,
                        action.commandTypeId,
                        new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                        new Date().toISOString(),
                        20
                    )
                    .pipe(
                        map(data =>
                            HistoricNetworkConnectivityDataActions.GetHistoricNetworkConnectivitySuccess({
                                data: { data, equipmentId: action.equipmentId }
                            })
                        ),
                        catchError(err =>
                            of(
                                HistoricNetworkConnectivityDataActions.GetHistoricNetworkConnectivityFailure({
                                    error: err
                                })
                            )
                        )
                    )
            )
        )
    );
}
