/* eslint-disable no-undef */
import * as Actions from '@actions/index';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AEPApplicationStatus, ColumnTypes, CustomerLogo, DashletSystemHealthAEP, ServiceStatus, Threshold } from '@models/index';
import { Store, select } from '@ngrx/store';
import {
    AppState,
    selectDataFromCommonEntity,
    selectEntity
} from '@reducers/index';
import {
    AccountService,
    DashletService,
    ThresholdService,
    TileGridService,
    TimeoutService
} from '@services/index';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ThresholdItemComponent } from '../threshold-item/threshold-item.component';

import { DashletSectionProcessorMemoryComponent } from '../dashlet-section-processor-memory/dashlet-section-processor-memory.component';
import { DashletSectionServicesComponent } from '../dashlet-section-services/dashlet-section-services.component';

@Component({
    selector: 'app-dashlet-system-health-aep',
    templateUrl: './dashlet-system-health-aep.component.html',
    styleUrls: ['./dashlet-system-health-aep.component.scss']
})
export class DashletSystemHealthAEPComponent implements OnInit {
    @Input() dashlet!: DashletSystemHealthAEP;
    @ViewChild('thresholdItem') thresholdItem!: ThresholdItemComponent;
    @ViewChild('processorMemory') processorMemory: DashletSectionProcessorMemoryComponent;
    @ViewChild('services') services: DashletSectionServicesComponent;

    openSubcontainer: string | null = '';
    openSubcontainerSeverity = 0;
    occupancyContextMenuItems!: MenuItem[];

    selectedField!: string;
    selectedThreshold!: Threshold;

    public subcontainerSubtitle: string;

    public servicesArray!: ServiceStatus[];

    upStatus: any = ['running', 'run state'];
    partialStatus: any = ['partially up', 'partially down'];
    downStatus: any = ['stopped', 'stopping'];

    public trunkColumns: ColumnTypes[] = [
        {
            columnDef: 'trunkName',
            header: 'Name'
        },
        {
            columnDef: 'portCount',
            header: 'PortCount',
            type: 'numeric'
        },
        {
            columnDef: 'inService',
            header: 'In Service',
            type: 'numeric'
        },
        {
            columnDef: 'connected',
            header: 'Connected',
            type: 'numeric'
        }
    ];
    public appColumns: ColumnTypes[] = [
        {
            columnDef: 'applicationName',
            header: 'Name',
            width: '60%',
            dataTooltip: (item: AEPApplicationStatus) => item.applicationName
        },
        {
            columnDef: 'inbound',
            header: 'Inbound',
            type: 'numeric'
        },
        {
            columnDef: 'outbound',
            header: 'Outbound',
            type: 'numeric'
        }
    ];

    private subscription: Subscription = new Subscription();

    constructor(
        private dashletService: DashletService,
        private accountService: AccountService,
        private thresholdService: ThresholdService,
        private tileGridService: TileGridService,
        public timeoutService: TimeoutService,
        private store$: Store<AppState>,
    ) {}

    ngOnInit() {
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );
        // Trunk Status
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdTrunkStatus
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdTrunkStatus
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdTrunkStatus
                        )
                    )
                )
                .subscribe(data => {
                    if (data) {
                        this.dashlet.processTrunkStatus(data);
                    }
                })
        );

        // Application Status
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdApplicationStatus
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdApplicationStatus
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdApplicationStatus
                        )
                    )
                )
                .subscribe(data => {
                    if (data) {
                        this.dashlet.processApplicationStatus(data);
                    }
                })
        );
    }

    ngOnDestroy() {
        if (this.dashlet) {
            this.dashlet.dispose();
        }
        this.subscription.unsubscribe();
    }

    returnCustomScriptOutputData(e: any) {}

    getSeverityColor(severity: number): string {
        return this.dashletService.getSeverityColor(severity);
    }

    openDetails(container: string, severity: number) {
        this.openSubcontainer = container;
        this.dashlet.displayHeader = false;
        this.openSubcontainerSeverity = severity;
    }

    closeDetails() {
        this.subcontainerSubtitle = '';
        this.openSubcontainer = null;
        this.dashlet.displayHeader = true;
    }

    openThresholdAlerts(elementId: string, commandTypeId: string, diskName?: string) {
        this.openSubcontainer = 'createThreshold';
        this.dashlet.displayHeader = false;
        this.selectedThreshold = new Threshold();
        this.selectedThreshold.commandTypeId = commandTypeId;
        this.selectedThreshold.field = elementId;
        this.selectedThreshold.whereCreated = 'Dashboard';
        if (diskName) {
            this.selectedThreshold.fieldInstance = diskName;
        }
    }

    openAlarmIconThreshold() {
        this.thresholdService.openThresholdManagement(this.accountService.getUserDetails().EntityId);
    }

    onSubmit() {
        this.subscription.add(
            this.thresholdItem.onSubmit().subscribe((result: any) => {
                if (result) {
                    this.selectedThreshold.dapThresholdId = result['DapThresholdId'];
                    this.dashlet.thresholds.push(this.selectedThreshold);
                    switch (this.selectedThreshold.field) {
                        case 'processor':
                            ++this.processorMemory.cpuUsageData.thresholdsNumber;
                            break;
                        case 'memory':
                            ++this.processorMemory.memoryUsageData.thresholdsNumber;
                            break;
                        case 'disk':
                            const diskUsage = this.processorMemory.diskUsageData.find(d => {
                                return d.diskName === this.selectedThreshold.fieldInstance;
                            });
                            if (diskUsage) {
                                ++diskUsage.thresholdsNumber;
                            }
                            break;
                        default:
                            break;
                    }
                    this.tileGridService.thresholdCreated(
                        this.selectedThreshold.name + ' threshold added via ' + this.dashlet.title
                    );
                } else {
                    this.tileGridService.thresholdCreated('Threshold add failed');
                }
            })
        );
        this.closeDetails();
    }

    saveEdit() {
        this.thresholdItem.saveEdit().subscribe(result => {
            if (result) {
                this.tileGridService.thresholdCreated(
                    this.selectedThreshold.name + ' threshold edited via ' + this.dashlet.title
                );
            } else {
                this.tileGridService.thresholdCreated('Threshold edit failed');
            }
        });
        this.closeDetails();
    }
    public getSubcontainerSubtitle(): string {
        let subtitle: string = '';
        if (this.services) {
            switch (this.openSubcontainerSeverity) {
                case 0:
                    subtitle = `${this.services.getServiceUpNumber()} of ${
                        this.services.serviceStatusList.length
                    } Service(s) running`;
                    break;
                case 2:
                    subtitle = `${this.services.getServiceDownNumber()} of ${
                        this.services.serviceStatusList.length
                    } Service(s) stopped`;
                    break;
            }
        }
        return subtitle;
    }
}
