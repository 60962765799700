import * as Actions from '@actions/index';
import { DashletService, DashletSettingsService, ReportService } from '@services/index';
import { AppState, selectDataFromCommonEntity, selectEntity, selectLocations, selectReportSubscription } from '@reducers/index';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    ColumnTypes,
    DashletEndpointDataSummary,
    DhcpVlanModel,
    FeatureSubscriptions,
    IPNRNetworkModel,
    IPNRProtocolModel,
    IpnrVlanModel,
    pTableDataHelper,
    SeverityRatioBar,
    SubnetNetworkModel,
    SubnetProtocolModel,
    SubnetVlanModel
} from '@models/index';
import { select, Store } from '@ngrx/store';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { CustomerLogo } from '@models/index';

@Component({
    selector: 'app-dashlet-endpoint-data-summary',
    templateUrl: './dashlet-endpoint-data-summary.component.html',
    styleUrls: ['./dashlet-endpoint-data-summary.component.scss']
})
export class DashletEndpointDataSummaryComponent implements OnInit, OnDestroy {
    //#region Class Members

    @Input() dashlet: DashletEndpointDataSummary;

    private destroy$ = new Subject<void>();
    private subs: Subscription = new Subscription();

    private reportSubscriptions: FeatureSubscriptions = {
        capacityEnabled: false,
        availabilityEnabled: false,
        releaseEnabled: false,
        apiEnabled: false,
        serviceDeskEnabled: false,
        configurationEnabled: false,
        continuityEnabled: false,
        changeEnabled: false,
        securityEnabled: false
    };

    get configurationEnabled(): boolean{
        return this.reportSubscriptions.configurationEnabled;
    }

    SubnetVlan: any;
    IpnrVlan: any;
    DhcpVlan: any;

    SubnetNetwork: any;
    IpnrNetwork: any;
    DhcpNetwork: any;

    SubnetProtocol: any;
    IpnrProtocol: any;
    DhcpProtocol: any;

    VlanSelectedTab: number;
    NetworkSelectedTab: number;
    ProtocolSelectedTab: number;

    SubnetVlanDataNoResult = false;
    IpnrVlanDataNoResult = false;
    DhcpVlanDataNoResult = false;

    SubnetNetworkDataNoResult = false;
    IpnrNetworkDataNoResult = false;
    DhcpNetworkDataNoResult = false;

    SubnetProtocolDataNoResult = false;
    IpnrProtocolDataNoResult = false;
    DhcpProtocolDataNoResult = false;

    vlanSubnetSearchText: string = '';
    protocolSubnetSearchText: string = '';
    networkSubnetSearchText: string = '';

    networkDisplayDataSize: number = 9999;
    protocolDisplayDataSize: number = 9999;
    vlanDisplayDataSize: number = 9999;

    customerId: string;

    public vlanExpansionColumns: ColumnTypes[] = [
        {
            columnDef: 'Vlan',
            header: 'Vlan'
        },
        {
            columnDef: 'TotalStreams',
            header: 'Streams',
            type: 'numeric',
            cell: (row: SubnetVlanModel | IpnrVlanModel | DhcpVlanModel) => (row.TotalStreams ? row.TotalStreams : 0)
        },
        {
            columnDef: 'spacerElement',
            header: '',
            width: '5%'
        },
        {
            columnDef: 'MosLow',
            header: 'MOS Distribution',
            cell: (row: SubnetVlanModel | IpnrVlanModel | DhcpVlanModel): SeverityRatioBar[] => {
                return [
                    {
                        value: row.MosHigh,
                        colour: this.dashletService.getSeverityColor(0),
                        tooltip:
                            this.getPercentage(row.MosHigh, row.MosHigh + row.MosMedium + row.MosLow) + ' - MOS >= 4.0'
                    },
                    {
                        value: row.MosMedium,
                        colour: this.dashletService.getSeverityColor(1),
                        tooltip:
                            this.getPercentage(row.MosHigh, row.MosHigh + row.MosMedium + row.MosLow) +
                            ' - MOS 3.6 - 4.0'
                    },
                    {
                        value: row.MosLow,
                        colour: this.dashletService.getSeverityColor(2),
                        tooltip:
                            this.getPercentage(row.MosHigh, row.MosHigh + row.MosMedium + row.MosLow) +
                            ' - MOS &lt; 3.6'
                    }
                ];
            },
            type: 'ratio'
        }
    ];

    public subnetColumns: ColumnTypes[] = [
        {
            columnDef: 'expand',
            header: ''
        },
        {
            columnDef: 'Subnet',
            header: 'Subnet',
            filterType: 'text'
        },
        {
            columnDef: 'NumVLans',
            header: 'Unique VLANs',
            type: 'numeric',
            cell: (row: SubnetVlanModel) => (row.NumVLans ? row.NumVLans : 0)
        },
        {
            columnDef: 'TotalPhones',
            header: 'Endpoints',
            type: 'numeric',
            cell: (row: SubnetVlanModel) => (row.TotalPhones ? row.TotalPhones : 0)
        },
        {
            columnDef: 'TotalStreams',
            header: 'Streams',
            type: 'numeric',
            cell: (row: SubnetVlanModel) => (row.TotalStreams ? row.TotalStreams : 0)
        },
        {
            columnDef: 'spacerElement',
            header: '',
            width: '5%'
        },
        {
            columnDef: 'MosLow',
            header: 'MOS Distribution',
            cell: (row: SubnetVlanModel): SeverityRatioBar[] => {
                return [
                    {
                        value: row.MosHigh,
                        colour: this.dashletService.getSeverityColor(0),
                        tooltip:
                            this.getPercentage(row.MosHigh, row.MosHigh + row.MosMedium + row.MosLow) + ' - MOS >= 4.0'
                    },
                    {
                        value: row.MosMedium,
                        colour: this.dashletService.getSeverityColor(1),
                        tooltip:
                            this.getPercentage(row.MosHigh, row.MosHigh + row.MosMedium + row.MosLow) +
                            ' - MOS 3.6 - 4.0'
                    },
                    {
                        value: row.MosLow,
                        colour: this.dashletService.getSeverityColor(2),
                        tooltip:
                            this.getPercentage(row.MosHigh, row.MosHigh + row.MosMedium + row.MosLow) +
                            ' - MOS &lt; 3.6'
                    }
                ];
            },
            type: 'ratio'
        }
    ];

    public ipnrColumns: ColumnTypes[] = [
        {
            columnDef: 'expand',
            header: ''
        },
        {
            columnDef: 'IPNR',
            header: 'IPNR',
            type: 'numeric',
            filterType: 'text',
            isIdColumn: true
        },
        {
            columnDef: 'NumVLans',
            header: 'Unique VLANs',
            type: 'numeric',
            cell: (row: IpnrVlanModel) => (row.NumVLans ? row.NumVLans : 0)
        },
        {
            columnDef: 'TotalPhones',
            header: 'Endpoints',
            type: 'numeric',
            cell: (row: IpnrVlanModel) => (row.TotalStreams ? row.TotalStreams : 0)
        },
        {
            columnDef: 'TotalStreams',
            header: 'Streams',
            type: 'numeric',
            cell: (row: IpnrVlanModel) => (row.TotalStreams ? row.TotalStreams : 0)
        },
        {
            columnDef: 'spacerElement',
            header: '',
            width: '5%'
        },
        {
            columnDef: 'MosLow',
            header: 'MOS Distribution',
            cell: (row: IpnrVlanModel): SeverityRatioBar[] => {
                return [
                    {
                        value: row.MosHigh,
                        colour: this.dashletService.getSeverityColor(0),
                        tooltip:
                            this.getPercentage(row.MosHigh, row.MosHigh + row.MosMedium + row.MosLow) + ' - MOS >= 4.0'
                    },
                    {
                        value: row.MosMedium,
                        colour: this.dashletService.getSeverityColor(1),
                        tooltip:
                            this.getPercentage(row.MosHigh, row.MosHigh + row.MosMedium + row.MosLow) +
                            ' - MOS 3.6 - 4.0'
                    },
                    {
                        value: row.MosLow,
                        colour: this.dashletService.getSeverityColor(2),
                        tooltip:
                            this.getPercentage(row.MosHigh, row.MosHigh + row.MosMedium + row.MosLow) +
                            ' - MOS &lt; 3.6'
                    }
                ];
            },
            type: 'ratio'
        }
    ];

    public dhcpColumns: ColumnTypes[] = [
        {
            columnDef: 'expand',
            header: ''
        },
        {
            columnDef: 'DHCP',
            header: 'DHCP',
            filterType: 'text'
        },
        {
            columnDef: 'NumVLans',
            header: 'Unique VLANs',
            type: 'numeric',
            cell: (row: DhcpVlanModel) => (row.NumVLans ? row.NumVLans : 0)
        },
        {
            columnDef: 'TotalPhones',
            header: 'Endpoints',
            type: 'numeric',
            cell: (row: DhcpVlanModel) => (row.TotalPhones ? row.TotalPhones : 0)
        },
        {
            columnDef: 'TotalStreams',
            header: 'Streams',
            type: 'numeric',
            cell: (row: DhcpVlanModel) => (row.TotalPhones ? row.TotalPhones : 0)
        },
        {
            columnDef: 'spacerElement',
            header: '',
            width: '5%'
        },
        {
            columnDef: 'MosLow',
            header: 'MOS Distribution',
            cell: (row: IpnrVlanModel): SeverityRatioBar[] => {
                return [
                    {
                        value: row.MosHigh,
                        colour: this.dashletService.getSeverityColor(0),
                        tooltip:
                            this.getPercentage(row.MosHigh, row.MosHigh + row.MosMedium + row.MosLow) + ' - MOS >= 4.0'
                    },
                    {
                        value: row.MosMedium,
                        colour: this.dashletService.getSeverityColor(1),
                        tooltip:
                            this.getPercentage(row.MosHigh, row.MosHigh + row.MosMedium + row.MosLow) +
                            ' - MOS 3.6 - 4.0'
                    },
                    {
                        value: row.MosLow,
                        colour: this.dashletService.getSeverityColor(2),
                        tooltip:
                            this.getPercentage(row.MosHigh, row.MosHigh + row.MosMedium + row.MosLow) +
                            ' - MOS &lt; 3.6'
                    }
                ];
            },
            type: 'ratio'
        }
    ];

    public subnetNetworkColumns: ColumnTypes[] = [
        {
            columnDef: 'expand',
            header: ''
        },
        {
            columnDef: 'Subnet',
            header: 'Subnet',
            filterType: 'text'
        },
        {
            columnDef: 'spacerElement',
            header: '',
            width: '5%'
        },
        {
            columnDef: 'TotalDevices',
            header: 'Endpoints',
            type: 'numeric',
            cell: (row: SubnetNetworkModel) => (row.TotalDevices ? row.TotalDevices : 0)
        },
        {
            columnDef: 'MisconfiguredEthernet',
            header: 'Misconfigured Endpoints',
            type: 'numeric',
            cell: (row: SubnetNetworkModel) => (row.MisconfiguredEthernet ? row.MisconfiguredEthernet : 0),
            width: '30%'
        },
        {
            columnDef: 'TotalStreams',
            header: 'Streams',
            type: 'numeric',
            cell: (row: SubnetNetworkModel) => (row.TotalStreams ? row.TotalStreams : 0)
        },
        {
            columnDef: 'spacerElement2',
            header: '',
            width: '5%'
        },
        {
            columnDef: 'MosLow',
            header: 'MOS Distribution',
            width: '15%',
            cell: (row: SubnetNetworkModel): SeverityRatioBar[] => {
                return [
                    {
                        value: row.MosHigh,
                        colour: this.dashletService.getSeverityColor(0),
                        tooltip:
                            this.getPercentage(row.MosHigh, row.MosHigh + row.MosMedium + row.MosLow) + ' - MOS >= 4.0'
                    },
                    {
                        value: row.MosMedium,
                        colour: this.dashletService.getSeverityColor(1),
                        tooltip:
                            this.getPercentage(row.MosHigh, row.MosHigh + row.MosMedium + row.MosLow) +
                            ' - MOS 3.6 - 4.0'
                    },
                    {
                        value: row.MosLow,
                        colour: this.dashletService.getSeverityColor(2),
                        tooltip:
                            this.getPercentage(row.MosHigh, row.MosHigh + row.MosMedium + row.MosLow) +
                            ' - MOS &lt; 3.6'
                    }
                ];
            },
            type: 'ratio'
        }
    ];

    public ipnrNetworkColumns: ColumnTypes[] = [
        {
            columnDef: 'expand',
            header: ''
        },
        {
            columnDef: 'IPNR',
            header: 'IPNR',
            type: 'numeric',
            filterType: 'text',
            isIdColumn: true
        },

        {
            columnDef: 'TotalDevices',
            header: 'Endpoints',
            type: 'numeric',
            cell: (row: IPNRNetworkModel) => (row.TotalDevices ? row.TotalDevices : 0)
        },
        {
            columnDef: 'spacerElement',
            header: '',
            width: '5%'
        },
        {
            columnDef: 'MisconfiguredEthernet',
            header: 'Misconfigured Endpoints',
            type: 'numeric',
            cell: (row: IPNRNetworkModel) => (row.MisconfiguredEthernet ? row.MisconfiguredEthernet : 0),
            width: '30%'
        },
        {
            columnDef: 'TotalStreams',
            header: 'Streams',
            type: 'numeric',
            cell: (row: IPNRNetworkModel) => (row.TotalStreams ? row.TotalStreams : 0)
        },
        {
            columnDef: 'spacerElement2',
            header: '',
            width: '5%'
        },
        {
            columnDef: 'MosLow',
            header: 'MOS Distribution',
            width: '15%',
            cell: (row: IPNRNetworkModel): SeverityRatioBar[] => {
                return [
                    {
                        value: row.MosHigh,
                        colour: this.dashletService.getSeverityColor(0),
                        tooltip:
                            this.getPercentage(row.MosHigh, row.MosHigh + row.MosMedium + row.MosLow) + ' - MOS >= 4.0'
                    },
                    {
                        value: row.MosMedium,
                        colour: this.dashletService.getSeverityColor(1),
                        tooltip:
                            this.getPercentage(row.MosHigh, row.MosHigh + row.MosMedium + row.MosLow) +
                            ' - MOS 3.6 - 4.0'
                    },
                    {
                        value: row.MosLow,
                        colour: this.dashletService.getSeverityColor(2),
                        tooltip:
                            this.getPercentage(row.MosHigh, row.MosHigh + row.MosMedium + row.MosLow) +
                            ' - MOS &lt; 3.6'
                    }
                ];
            },
            type: 'ratio'
        }
    ];

    public networkExpansionColumns: ColumnTypes[] = [
        {
            columnDef: 'Title',
            header: 'Title'
        },

        {
            columnDef: 'Value',
            header: 'Endpoints',
            type: 'numeric',
            cell: row => (row.Value ? row.Value : 0)
        }
    ];

    public subnetProtocolColumns: ColumnTypes[] = [
        {
            columnDef: 'expand',
            header: ''
        },
        {
            columnDef: 'Subnet',
            header: 'Subnet',
            filterType: 'text'
        },

        {
            columnDef: 'TotalDevices',
            header: 'Total Devices',
            type: 'numeric',
            cell: (row: SubnetProtocolModel) => (row.TotalDevices ? row.TotalDevices : 0)
        },
        {
            columnDef: 'spacerElement',
            header: '',
            width: '5%'
        },
        {
            columnDef: 'MisconfiguredProtocols',
            header: 'Devices with unusual',
            subHeadingInFilter: 'protocol settings',
            type: 'numeric',
            cell: (row: SubnetProtocolModel) => (row.MisconfiguredProtocols ? row.MisconfiguredProtocols : 0),
            width: '20%'
        },
        {
            columnDef: 'TotalStreams',
            header: 'Streams',
            type: 'numeric',
            cell: (row: SubnetProtocolModel) => (row.TotalStreams ? row.TotalStreams : 0)
        },
        {
            columnDef: 'spacerElement2',
            header: '',
            width: '5%'
        },
        {
            columnDef: 'MosLow',
            header: 'MOS Distribution',
            width: '15%',
            cell: (row: SubnetProtocolModel): SeverityRatioBar[] => {
                return [
                    {
                        value: row.MosHigh,
                        colour: this.dashletService.getSeverityColor(0),
                        tooltip:
                            this.getPercentage(row.MosHigh, row.MosHigh + row.MosMedium + row.MosLow) + ' - MOS >= 4.0'
                    },
                    {
                        value: row.MosMedium,
                        colour: this.dashletService.getSeverityColor(1),
                        tooltip:
                            this.getPercentage(row.MosHigh, row.MosHigh + row.MosMedium + row.MosLow) +
                            ' - MOS 3.6 - 4.0'
                    },
                    {
                        value: row.MosLow,
                        colour: this.dashletService.getSeverityColor(2),
                        tooltip:
                            this.getPercentage(row.MosHigh, row.MosHigh + row.MosMedium + row.MosLow) +
                            ' - MOS &lt; 3.6'
                    }
                ];
            },
            type: 'ratio'
        }
    ];

    public ipnrProtocolColumns: ColumnTypes[] = [
        {
            columnDef: 'expand',
            header: ''
        },
        {
            columnDef: 'IPNR',
            header: 'IPNR',
            type: 'numeric',
            filterType: 'text',
            isIdColumn: true
        },

        {
            columnDef: 'TotalDevices',
            header: 'Total Devices',
            type: 'numeric',
            cell: (row: IPNRProtocolModel) => (row.TotalDevices ? row.TotalDevices : 0)
        },
        {
            columnDef: 'spacerElement',
            header: '',
            width: '5%'
        },
        {
            columnDef: 'MisconfiguredProtocols',
            header: 'Devices with unusual',
            subHeadingInFilter: 'protocol settings',
            type: 'numeric',
            cell: (row: IPNRProtocolModel) => (row.MisconfiguredProtocols ? row.MisconfiguredProtocols : 0),
            width: '20%'
        },
        {
            columnDef: 'TotalStreams',
            header: 'Streams',
            type: 'numeric',
            cell: (row: IPNRProtocolModel) => (row.TotalStreams ? row.TotalStreams : 0)
        },
        {
            columnDef: 'spacerElement2',
            header: '',
            width: '5%'
        },
        {
            columnDef: 'MosLow',
            header: 'MOS Distribution',
            width: '15%',
            cell: (row: IPNRProtocolModel): SeverityRatioBar[] => {
                return [
                    {
                        value: row.MosHigh,
                        colour: this.dashletService.getSeverityColor(0),
                        tooltip:
                            this.getPercentage(row.MosHigh, row.MosHigh + row.MosMedium + row.MosLow) + ' - MOS >= 4.0'
                    },
                    {
                        value: row.MosMedium,
                        colour: this.dashletService.getSeverityColor(1),
                        tooltip:
                            this.getPercentage(row.MosHigh, row.MosHigh + row.MosMedium + row.MosLow) +
                            ' - MOS 3.6 - 4.0'
                    },
                    {
                        value: row.MosLow,
                        colour: this.dashletService.getSeverityColor(2),
                        tooltip:
                            this.getPercentage(row.MosHigh, row.MosHigh + row.MosMedium + row.MosLow) +
                            ' - MOS &lt; 3.6'
                    }
                ];
            },
            type: 'ratio'
        }
    ];

    public subnetVlanLoading: boolean = true;
    public ipnrVlanLoading: boolean = true;
    public dhcpVlanLoading: boolean = true;

    constructor(
        private reportService: ReportService,
        private settingsService: DashletSettingsService,
        private dashletService: DashletService,
        private store$: Store<AppState>
    ) {}

    //#endregion

    //#region Angular Interface Methods

    ngOnInit() {
        this.customerId = null;

        this.SubnetVlan = this.IpnrVlan = this.DhcpVlan = [];
        this.SubnetNetwork = this.IpnrNetwork = this.DhcpNetwork = [];
        this.SubnetProtocol = this.IpnrProtocol = this.DhcpProtocol = [];

        this.VlanSelectedTab = 0;
        this.NetworkSelectedTab = 0;
        this.ProtocolSelectedTab = 0;

        this.subs.add(
            this.dashlet.settingsChanged.subscribe(() => {
                this.dashlet.dispose();
                this.loadData();
            })
        );
        this.loadData();
        
    }

    private loadData(): void{
        this.subs.add(
            this.store$.pipe(select(selectReportSubscription(this.dashlet.customer.customerId))).subscribe(subscriptions => {
                if (subscriptions?.reportSubscriptions) {
                    this.reportSubscriptions = subscriptions.reportSubscriptions;
                }
            })
        );


        // listens to all changes happening on subject "SubnetVlanDataUpdated" on dashletPhoneDataSummary.model.ts
        this.subs.add(
            this.dashlet.SubnetVlanDataUpdated.subscribe(() => {
                this.SubnetVlanDataNoResult = this.dashlet.SubnetVlanDataNoResult;
                let values = Array.from(this.dashlet.SubnetVlanMapped.values());

                values.forEach(elem => {
                    elem['VlanSubRowData'] = Array.from(elem.VlanSubRow.values());
                });

                let record = new pTableDataHelper(
                    values,
                    values.slice(0, this.vlanDisplayDataSize),
                    this.vlanDisplayDataSize
                );
                this.SubnetVlan = record;
            })
        );

        // listens to all changes happening on subject "IpnrVlanDataUpdated" on dashletPhoneDataSummary.model.ts
        this.subs.add(
            this.dashlet.IpnrVlanDataUpdated.subscribe(() => {
                this.IpnrVlanDataNoResult = this.dashlet.IpnrVlanDataNoResult;
                let values = Array.from(this.dashlet.IPNRVlanMapped.values());

                values.forEach(elem => {
                    elem['VlanSubRowData'] = Array.from(elem.VlanSubRow.values());
                });

                let record = new pTableDataHelper(
                    values,
                    values.slice(0, this.vlanDisplayDataSize),
                    this.vlanDisplayDataSize
                );
                this.IpnrVlan = record;
            })
        );

        // listens to all changes happening on subject "DhcpVlanDataUpdated" on dashletPhoneDataSummary.model.ts
        this.subs.add(
            this.dashlet.DhcpVlanDataUpdated.subscribe(() => {
                this.DhcpVlanDataNoResult = this.dashlet.IpnrVlanDataNoResult;
                let values = Array.from(this.dashlet.DHCPVlanMapped.values());

                values.forEach(elem => {
                    elem['VlanSubRowData'] = Array.from(elem.VlanSubRow.values());
                });

                let record = new pTableDataHelper(
                    values,
                    values.slice(0, this.vlanDisplayDataSize),
                    this.vlanDisplayDataSize
                );
                this.DhcpVlan = record;
            })
        );

        // listens to all changes happening on subject "SubnetNetworkDataUpdated" on dashletPhoneDataSummary.model.ts
        this.subs.add(
            this.dashlet.SubnetNetworkDataUpdated.subscribe(() => {
                this.SubnetNetworkDataNoResult = this.dashlet.SubnetNetworkDataNoResult;
                let values = Array.from(this.dashlet.SubnetNetworkMapped.values());

                let record = new pTableDataHelper(
                    values,
                    values.slice(0, this.networkDisplayDataSize),
                    this.networkDisplayDataSize
                );
                this.SubnetNetwork = record;
                this.dashlet.getSubnetNetworkChartData();
            })
        );

        // listens to all changes happening on subject "IPNRNetworkDataUpdated" on dashletPhoneDataSummary.model.ts
        this.subs.add(
            this.dashlet.IpnrNetworkDataUpdated.subscribe(() => {
                this.IpnrNetworkDataNoResult = this.dashlet.IpnrNetworkDataNoResult;
                let values = Array.from(this.dashlet.IPNRNetworkMapped.values());

                let record = new pTableDataHelper(
                    values,
                    values.slice(0, this.networkDisplayDataSize),
                    this.networkDisplayDataSize
                );
                this.IpnrNetwork = record;
            })
        );

        // listens to all changes happening on subject "SubnetProtocolDataUpdated" on dashletPhoneDataSummary.model.ts
        this.subs.add(
            this.dashlet.SubnetProtocolDataUpdated.subscribe(() => {
                this.SubnetProtocolDataNoResult = this.dashlet.SubnetProtocolDataNoResult;
                let values = Array.from(this.dashlet.SubnetProtocolMapped.values());

                let record = new pTableDataHelper(
                    values,
                    values.slice(0, this.protocolDisplayDataSize),
                    this.protocolDisplayDataSize
                );
                this.SubnetProtocol = record;

                this.dashlet.getSubnetProtocolChartData();
            })
        );

        // listens to all changes happening on subject "IPNRProtocolDataUpdated" on dashletPhoneDataSummary.model.ts
        this.subs.add(
            this.dashlet.IpnrProtocolDataUpdated.subscribe(() => {
                this.IpnrProtocolDataNoResult = this.dashlet.IpnrProtocolDataNoResult;
                let values = Array.from(this.dashlet.IPNRProtocolMapped.values());

                let record = new pTableDataHelper(
                    values,
                    values.slice(0, this.protocolDisplayDataSize),
                    this.protocolDisplayDataSize
                );
                this.IpnrProtocol = record;
            })
        );

        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.store$
            .pipe(select(selectEntity(this.dashlet.customer.customerId)), takeUntil(this.destroy$))
            .subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            });

        if (
            this.dashlet.configured &&
            this.dashlet.customer.customerId &&
            this.dashlet.locationId &&
            this.dashlet.equipmentId
        ) {
            this.store$.dispatch(Actions.GetReportSubscriptions({ customerId: this.dashlet.customer.customerId }));
            this.store$.dispatch(Actions.GetLocations({ customerId: this.dashlet.customer.customerId }));
            this.store$
                .pipe(select(selectLocations(this.dashlet.customer.customerId)), takeUntil(this.destroy$))
                .subscribe(locations => {
                    if (locations) {
                        // If not all locations, then set locations to be only the one specified in settings
                        if (this.dashlet.locationId !== '=all=') {
                            locations = [
                                {
                                    label: this.dashlet.location,
                                    value: this.dashlet.locationId
                                }
                            ];
                        }
                        locations.forEach(location => {
                            this.settingsService.getEquipment(location['value'], 'ACM').subscribe(receivers => {
                                if (this.dashlet.equipmentId !== '=all=') {
                                    receivers = [
                                        {
                                            label: this.dashlet.equipment,
                                            value: this.dashlet.equipmentId
                                        }
                                    ];
                                }
                                for (const receiver of receivers) {
                                    if (this.dashlet.receivers.findIndex(e => e.value === receiver.value) === -1) {
                                        this.dashlet.receivers.push(receiver);
                                    }
                                    // Subscribe Phone Data Summary By Subnet
                                    this.store$.dispatch(
                                        Actions.SubscribeToRealTimeService({
                                            equipmentId: receiver.value,
                                            command: this.dashlet.commandTypeIdSummaryBySubnet
                                        })
                                    );
                                    this.store$.dispatch(
                                        Actions.GetNotifyCommonEntitys({
                                            equipmentId: receiver.value,
                                            commandTypeId: this.dashlet.commandTypeIdSummaryBySubnet
                                        })
                                    );
                                    this.store$
                                        .pipe(
                                            select(
                                                selectDataFromCommonEntity(
                                                    receiver.value + this.dashlet.commandTypeIdSummaryBySubnet
                                                )
                                            )
                                        )
                                        .subscribe(
                                            data => {
                                                if (data) {
                                                    this.dashlet.processData(data, 'Subnet');
                                                    this.subnetVlanLoading = false;
                                                    this.dashlet.SubnetVlanDataNoResult = true;
                                                    this.dashlet.SubnetVlanDataUpdated.next(null);
                                                    this.dashlet.processSummaryDataSubnet();

                                                    this.dashlet.SubnetNetworkDataNoResult = true;
                                                    this.dashlet.SubnetNetworkDataUpdated.next(null);
                                                    this.dashlet.processSummaryDataNetwork();

                                                    this.dashlet.SubnetProtocolDataNoResult = true;
                                                    this.dashlet.SubnetProtocolDataUpdated.next(null);
                                                    this.dashlet.processSummaryDataProtocol();
                                                }
                                            },
                                            err => {
                                                // no data produced after time out
                                                if (this.dashlet.SubnetVlanMapped.size === 0) {
                                                    this.dashlet.SubnetVlanDataNoResult = true;
                                                    this.dashlet.SubnetVlanDataUpdated.next(null);
                                                }

                                                if (this.dashlet.SubnetNetworkMapped.size === 0) {
                                                    this.dashlet.SubnetNetworkDataNoResult = true;
                                                    this.dashlet.SubnetNetworkDataUpdated.next(null);
                                                }

                                                if (this.dashlet.SubnetProtocolMapped.size === 0) {
                                                    this.dashlet.SubnetProtocolDataNoResult = true;
                                                    this.dashlet.SubnetProtocolDataUpdated.next(null);
                                                }
                                            }
                                        );
                                    this.store$.dispatch(
                                        Actions.GetCommonHistoric({
                                            equipmentId: receiver.value,
                                            commandTypeId: this.dashlet.commandTypeIdSummaryBySubnet,
                                            from: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                                            to: new Date().toISOString(),
                                            max: parseInt(this.dashlet.summaryPeriod.value)
                                        })
                                    );

                                    // Subscribe Phone Data Summary By Ipnr
                                    this.store$.dispatch(
                                        Actions.SubscribeToRealTimeService({
                                            equipmentId: receiver.value,
                                            command: this.dashlet.commandTypeIdSummaryByIpnr
                                        })
                                    );
                                    this.store$.dispatch(
                                        Actions.GetNotifyCommonEntitys({
                                            equipmentId: receiver.value,
                                            commandTypeId: this.dashlet.commandTypeIdSummaryByIpnr
                                        })
                                    );
                                    this.store$
                                        .pipe(
                                            select(
                                                selectDataFromCommonEntity(
                                                    receiver.value + this.dashlet.commandTypeIdSummaryByIpnr
                                                )
                                            )
                                        )
                                        .subscribe(
                                            data => {
                                                if (data) {
                                                    this.dashlet.processData(data, 'IPNR');
                                                    this.ipnrVlanLoading = false;
                                                    this.dashlet.IpnrVlanDataNoResult = true;
                                                    this.dashlet.IpnrVlanDataUpdated.next(null);

                                                    this.dashlet.IpnrNetworkDataNoResult = true;
                                                    this.dashlet.IpnrNetworkDataUpdated.next(null);

                                                    this.dashlet.IpnrProtocolDataNoResult = true;
                                                    this.dashlet.IpnrProtocolDataUpdated.next(null);
                                                }
                                            },
                                            err => {
                                                // no data produced after time out
                                                if (this.dashlet.IPNRVlanMapped.size === 0) {
                                                    this.dashlet.IpnrVlanDataNoResult = true;
                                                    this.dashlet.IpnrVlanDataUpdated.next(null);
                                                }

                                                if (this.dashlet.IPNRNetworkMapped.size === 0) {
                                                    this.dashlet.IpnrNetworkDataNoResult = true;
                                                    this.dashlet.IpnrNetworkDataUpdated.next(null);
                                                }

                                                if (this.dashlet.IPNRProtocolMapped.size === 0) {
                                                    this.dashlet.IpnrProtocolDataNoResult = true;
                                                    this.dashlet.IpnrProtocolDataUpdated.next(null);
                                                }
                                            }
                                        );

                                    this.store$.dispatch(
                                        Actions.GetCommonHistoric({
                                            equipmentId: receiver.value,
                                            commandTypeId: this.dashlet.commandTypeIdSummaryByIpnr,
                                            from: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                                            to: new Date().toISOString(),
                                            max: parseInt(this.dashlet.summaryPeriod.value)
                                        })
                                    );

                                    // Subscribe Phone Data Summary By Dscp
                                    this.store$.dispatch(
                                        Actions.SubscribeToRealTimeService({
                                            equipmentId: receiver.value,
                                            command: this.dashlet.commandTypeIdSummaryByDhcp
                                        })
                                    );
                                    this.store$.dispatch(
                                        Actions.GetNotifyCommonEntitys({
                                            equipmentId: receiver.value,
                                            commandTypeId: this.dashlet.commandTypeIdSummaryByDhcp
                                        })
                                    );
                                    this.store$
                                        .pipe(
                                            select(
                                                selectDataFromCommonEntity(
                                                    receiver.value + this.dashlet.commandTypeIdSummaryByDhcp
                                                )
                                            )
                                        )
                                        .subscribe(
                                            data => {
                                                if (data) {
                                                    this.dashlet.processData(data, 'DHCP');
                                                    this.dhcpVlanLoading = false;
                                                    this.dashlet.DhcpVlanDataNoResult = true;
                                                    this.dashlet.DhcpVlanDataUpdated.next(null);
                                                }
                                            },
                                            err => {
                                                // no data produced after time out
                                                if (this.dashlet.DHCPVlanMapped.size === 0) {
                                                    this.dashlet.DhcpVlanDataNoResult = true;
                                                    this.dashlet.DhcpVlanDataUpdated.next(null);
                                                }
                                            }
                                        );

                                    this.store$.dispatch(
                                        Actions.GetCommonHistoric({
                                            equipmentId: receiver.value,
                                            commandTypeId: this.dashlet.commandTypeIdSummaryByDhcp,
                                            from: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                                            to: new Date().toISOString(),
                                            max: parseInt(this.dashlet.summaryPeriod.value)
                                        })
                                    );
                                }
                            });
                        });
                    }
                });
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        this.dashlet.dispose();
        this.subs.unsubscribe();
    }
    /* Gets open/closed state of specific accordion tab*/
    public getTabStateByIndex(index: number): boolean {
        return this.dashlet.tabsOpen.indexOf(index) !== -1;
    }

    getPercentage(severity: number, total: number) {
        let percentage = (severity / total) * 100;
        return isNaN(percentage) ? '0%' : percentage.toFixed(1) + '%';
    }

    /* Applies size expansion whenever an accordion tab is opened */
    handleTabOpen(index: number) {
        if (this.dashlet.tabsOpen.indexOf(index) === -1) {
            this.dashlet.tabsOpen.push(index);
        }
        if (this.largeLayout) {
            let expandSize = this.dashlet.phoneDataSummaryDashletSize.get(this.dashlet.tabsOpen.length);
            this.dashlet.applySizeExpansion(0, expandSize);
        }
    }

    /* Applies size expansion whenever an accordion tab is closed */
    handleTabClose(index: number) {
        this.dashlet.tabsOpen.splice(this.dashlet.tabsOpen.indexOf(index), 1);
        if (this.largeLayout) {
            let expandSize = this.dashlet.phoneDataSummaryDashletSize.get(this.dashlet.tabsOpen.length);

            this.dashlet.applySizeExpansion(0, expandSize);
        }
    }

    get smallLayout() {
        return this.dashlet.getSize().id === 0;
    }

    get largeLayout() {
        return this.dashlet.getSize().id === 2;
    }

    get hugeLayout() {
        return this.dashlet.getSize().id === 3;
    }

    genericSort(dataSource: string, event) {
        let data: any[];
        let column: string[] = [event.field];
        let order = event.order;
        let dataDisplay;
        //Set correct data for this table
        switch (dataSource) {
            case 'sv': //Subnet - VLAN
                data = Array.from(this.dashlet.SubnetVlanMapped.values());
                dataDisplay = this.SubnetVlan;
                break;
            case 'iv': //IPNR - VLAN
                data = Array.from(this.dashlet.IPNRVlanMapped.values());
                dataDisplay = this.IpnrVlan;
                break;
            case 'dv': //DHCP - VLAN
                data = Array.from(this.dashlet.DHCPVlanMapped.values());
                dataDisplay = this.DhcpVlan;
                break;
            case 'sn': //Subnet - Network
                data = Array.from(this.dashlet.SubnetNetworkMapped.values());
                dataDisplay = this.SubnetNetwork;
                break;
            case 'in': //IPNR - Network
                data = Array.from(this.dashlet.IPNRNetworkMapped.values());
                dataDisplay = this.IpnrNetwork;
                break;
            case 'sp': //Subnet - Protocol
                data = Array.from(this.dashlet.SubnetProtocolMapped.values());
                dataDisplay = this.SubnetProtocol;
                break;
            case 'ip': //IPNR - Protocol
                data = Array.from(this.dashlet.IPNRProtocolMapped.values());
                dataDisplay = this.IpnrProtocol;
                break;
        }
        // 1 asc, -1 desc;
        // Check criterias if not null
        if (!data || !column || !order) return data;

        data = data.sort((a: any, b: any) => {
            // We go for each property followed by path
            column.forEach(property => {
                a = a[property];
                b = b[property];
            });
            //Following is to force empty mos to the bottom on either sort
            if (column[0] === 'MosPercentage') {
                if (a === -1) {
                    if (b === -1) return 0;
                    else return 1;
                }
                if (b === -1) {
                    return -1;
                }
            }
            // Order * (-1): We change our order
            if (a === b) return 0;
            return a > b || (a !== 'N/A' && b === 'N/A') ? order : order * -1;
        });

        dataDisplay.updateSourceData(data);
        switch (dataSource) {
            case 'sv':
                this.dashlet.SubnetVlanMapped = new Map<string, any>(data.map(x => [x.Subnet, ...x] as [string, any]));
                this.customSubnetFilter('vlan');
                break;
            case 'iv':
                this.dashlet.IPNRVlanMapped = new Map<string, any>(data.map(x => [x.IPNR, ...x] as [string, any]));
                break;
            case 'dv':
                this.dashlet.DHCPVlanMapped = new Map<string, any>(data.map(x => [x.DHCP, ...x] as [string, any]));
                break;
            case 'sn':
                this.dashlet.SubnetNetworkMapped = new Map<string, any>(
                    data.map(x => [x.Subnet, ...x] as [string, any])
                );
                this.customSubnetFilter('network');
                break;
            case 'in':
                this.dashlet.IPNRNetworkMapped = new Map<string, any>(data.map(x => [x.IPNR, ...x] as [string, any]));
                break;
            case 'sp':
                this.dashlet.SubnetProtocolMapped = new Map<string, any>(
                    data.map(x => [x.Subnet, ...x] as [string, any])
                );
                this.customSubnetFilter('protocol');
                break;
            case 'ip':
                this.dashlet.IPNRProtocolMapped = new Map<string, any>(data.map(x => [x.IPNR, ...x] as [string, any]));
                break;
        }
    }

    customSubnetFilter(section: string) {
        let searchText, subnetMap: Map<string, any>, dataDisplay: pTableDataHelper, subnetListFiltered;

        switch (section) {
            case 'vlan':
                searchText = this.vlanSubnetSearchText;
                subnetMap = this.dashlet.SubnetVlanMapped;
                dataDisplay = this.SubnetVlan;
                break;
            case 'network':
                searchText = this.networkSubnetSearchText;
                subnetMap = this.dashlet.SubnetNetworkMapped;
                dataDisplay = this.SubnetNetwork;
                break;
            case 'protocol':
                searchText = this.protocolSubnetSearchText;
                subnetMap = this.dashlet.SubnetProtocolMapped;
                dataDisplay = this.SubnetProtocol;
                break;
        }
        subnetListFiltered = Array.from(subnetMap.values());
        //Filter the correct table
        if (searchText !== '') {
            subnetListFiltered = Array.from(subnetMap.values()).filter(rec => {
                return rec.Subnet.includes(searchText);
            });
        }
        dataDisplay.updateSourceData(subnetListFiltered);
    }
    public openPhoneDataReport(): void {
        if (this.configurationEnabled) {
            this.customerId = this.dashlet.getCustomerId();
            this.reportService.openReport(this.reportService.phoneDataReportId, this.customerId);
        }
    }
}
