
<!-- no chart -->
<dashlet-section-network-connectivity
            [showNetworkGraph]="!smallLayout"
            [equipment]="dashlet.equipment"
            [customerId]="dashlet.customer.customerId"
            [showHeader]="false"
            [chartAspect]="getChartAspect()"
        >
        </dashlet-section-network-connectivity>
