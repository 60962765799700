import { DashboardState } from './DashboardState';

export class Dashboard {
    static readonly SETTING_NAME_KEY = 'name';
    static readonly SETTING_COMPACT_KEY = 'compactUp';

    id!: string;
    entityId!: string;
    name!: string;
    autoReposition!: boolean;
    active = false;

    isShared!: boolean;
    ownerName!: string;
    ownerId!: string;
    description!: string;
    isEditable = true;
    autoStart = false;
    state: string;

    applySettings(obj: { [key: string]: any }): void {
        this.name = obj[Dashboard.SETTING_NAME_KEY];
        this.autoReposition = obj[Dashboard.SETTING_COMPACT_KEY];
    }

    getSettings(): { [key: string]: any } {
        const obj: any = {};
        obj[Dashboard.SETTING_NAME_KEY] = this.name;
        obj[Dashboard.SETTING_COMPACT_KEY] = this.autoReposition;
        return obj;
    }
}
