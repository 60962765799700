<div class="flex-column gap-l">
    <!-- resources template -->
    <dashlet-section-processor-memory
        *ngIf="!openSubcontainer"
        #processorMemory
        [showSystemChart]="dashlet.showSystemChart"
        [equipment]="dashlet.equipment"
        [customerId]="dashlet.customer.customerId"
        [locationId]="dashlet.location.locationId"
    >
    </dashlet-section-processor-memory>

    <dashlet-section-network-connectivity
        *ngIf="!openSubcontainer"
        [showNetworkGraph]="dashlet.showNetworkGraph"
        [equipment]="dashlet.equipment"
        [customerId]="dashlet.customer.customerId"
    >
    </dashlet-section-network-connectivity>

    <ng-container *ngIf="(dashlet.showServiceStatus && !openSubcontainer) || openSubcontainer === 'service'">
        <dashlet-section-services
            #services
            [equipment]="dashlet.equipment"
            [location]="dashlet.location"
            [customer]="dashlet.customer"
            (openDetails)="$event === null ? closeDetails() : openDetails('service', $event)"
        >
        </dashlet-section-services>
    </ng-container>

    <div *ngIf="dashlet.showCallRate && !openSubcontainer">
        <dashlet-section-header
            >Call Rate
            <sup
                matTooltip="'The Call Rate Measurement provides traffic data for all calls (incoming, outgoing, and intercom) completed on the system during defined time intervals.&#10;' +
                'The peak hours are the hours with the greatest number of calls and the hours with the busiest 36-second intervals.&#10;' +
                'A 36-second interval (1 one-hundredth of an hour) is used so the product of number of busy intervals and 100 gives the peak call rate for the listed hour.'
                "
                ><mat-icon>question_mark</mat-icon></sup
            ><i
                *ngIf="dashlet.callRateTimedOut"
                style="color: red"
                class="fa fa-exclamation-triangle"
                aria-hidden="true"
                [matTooltip]="timeoutService.getDataExpiredmessage('Call Rate', dashlet.commandTypeIdCallRate, dashlet.equipment.equipmentId)"
            ></i
        ></dashlet-section-header>
        <dashlet-table
            [menuItems]="callRateMenu"
            [sortable]="false"
            [columns]="callRateColumns"
            [dataSource]="dashlet.callRates"
            [loading]="dashlet.callRateLoading"
            [tableHeightRows]="5"
        >
        </dashlet-table>
    </div>
    <!-- CDR Link Status -->
    <div *ngIf="dashlet.showCdrLinkStatus  && !openSubcontainer">
        <dashlet-section-header>
            CDR Links
            <i
                *ngIf="dashlet.cdrLinkTimedOut"
                style="color: red"
                class="fa fa-exclamation-triangle"
                aria-hidden="true"
                [matTooltip]="timeoutService.getDataExpiredmessage('CDR Link', dashlet.commandTypeIdCdrLink, dashlet.equipment.equipmentId)"
            ></i>
        </dashlet-section-header>
        <div class="justify-space-between">
            <div
                *ngFor="let cdr of dashlet.cdrLink"
                class="metric gap-s"
                [ngStyle]="{ 'border-left-color': getSeverityColor(dashlet.cdrLinkTimedOut ? -1 : cdr.severity) }"
            >
                <div class="mat-h2">{{ cdr.name }}</div>
                <div class="mat-caption">{{ dashlet.cdrLinkTimedOut ? '---' : cdr.status }}</div>
            </div>
        </div>
    </div>
    <!-- Media Gateway Status -->
    <div *ngIf="dashlet.showMediaGateway  && !openSubcontainer">
        <dashlet-section-header>
            Media Gateways
            <i
                *ngIf="dashlet.mediaGatewayTimedOut"
                style="color: red"
                class="fa fa-exclamation-triangle"
                aria-hidden="true"
                [matTooltip]="timeoutService.getDataExpiredmessage('Media Gateway', dashlet.commandTypeIdMediaGateway, dashlet.equipment.equipmentId)"
            ></i>
        </dashlet-section-header>

        <!-- Bar -->
        <div class="flex-column gap-m">
            <dashlet-ratio-bar class="dashlet-cell" emptyTitle="No data available for Media Gateway Status">
                <dashlet-ratio-bar-item
                    [value]="dashlet.mediaGatewayTimedOut ? 0 : dashlet.mediaGateways[0].length"
                    [color]="getSeverityColor(0)"
                    [matTooltip]="dashlet.mediaGateways[0].length + ' of ' + (dashlet.mediaGateways[0].length + dashlet.mediaGateways[2].length) + ' up'"
                    (click)="openDetails('mediaGateway', 0)"
                ></dashlet-ratio-bar-item>
                <dashlet-ratio-bar-item
                    [value]="dashlet.mediaGatewayTimedOut ? 0 : dashlet.mediaGateways[2].length"
                    [color]="getSeverityColor(2)"
                    [matTooltip]="dashlet.mediaGateways[2].length + ' of ' + (dashlet.mediaGateways[0].length + dashlet.mediaGateways[2].length) + ' down'"
                    (click)="openDetails('mediaGateway', 2)"
                ></dashlet-ratio-bar-item>
            </dashlet-ratio-bar>
            <!-- Readouts -->
            <div class="justify-space-between">
                <h3
                    *ngIf="!dashlet.mediaGatewayTimedOut && dashlet.mediaGateways[0].length > 0"
                    class="dashlet-link-underline"
                    dashlet-link="true"
                    (click)="openDetails('mediaGateway', 0)"
                >
                    {{ dashlet.mediaGateways[0].length }}
                    <span class="mat-h3">Up</span>
                </h3>
                <h3
                    *ngIf="!dashlet.mediaGatewayTimedOut && dashlet.mediaGateways[2].length > 0"
                    class="dashlet-link-underline"
                    dashlet-link="true"
                    (click)="openDetails('mediaGateway', 2)"
                >
                    {{ dashlet.mediaGateways[2].length }}
                    <span class="mat-h3">Down</span>
                </h3>
            </div>
        </div>
    </div>
    <!-- DS1 -->
    <div *ngIf="dashlet.showDs1Status  && !openSubcontainer">
        <dashlet-section-header>
            DS1s
            <i
                *ngIf="dashlet.ds1LogTimedOut"
                style="color: red"
                class="fa fa-exclamation-triangle"
                aria-hidden="true"
                [matTooltip]="timeoutService.getDataExpiredmessage('DS1', dashlet.commandTypeIdDs1Log, dashlet.equipment.equipmentId)"
            ></i>
        </dashlet-section-header>
        <!-- Bar -->
        <div class="flex-column gap-m">
            <dashlet-ratio-bar class="dashlet-cell" emptyTitle="No data available for DS1 Status">
                <dashlet-ratio-bar-item
                    [value]="dashlet.ds1LogTimedOut ? 0 : dashlet.ds1s[0].length"
                    [color]="getSeverityColor(0)"
                    [title]="showDs1Names(dashlet.ds1s[0])"
                    (click)="openDetails('ds1', 0)"
                ></dashlet-ratio-bar-item>
                <dashlet-ratio-bar-item
                    [value]="dashlet.ds1LogTimedOut ? 0 : dashlet.ds1s[1].length"
                    [color]="getSeverityColor(1)"
                    [title]="showDs1Names(dashlet.ds1s[1])"
                    (click)="openDetails('ds1', 1)"
                ></dashlet-ratio-bar-item>
                <dashlet-ratio-bar-item
                    [value]="dashlet.ds1LogTimedOut ? 0 : dashlet.ds1s[2].length"
                    [color]="getSeverityColor(2)"
                    [title]="showDs1Names(dashlet.ds1s[2])"
                    (click)="openDetails('ds1', 2)"
                ></dashlet-ratio-bar-item>
            </dashlet-ratio-bar>
            <!-- Readouts -->
            <div class="justify-space-between">
                <h3
                    *ngIf="!dashlet.ds1LogTimedOut && dashlet.ds1s[0].length > 0"
                    class="dashlet-link-underline"
                    dashlet-link="true"
                    (click)="openDetails('ds1', 0)"
                >
                    {{ dashlet.ds1s[0].length }}
                    <span class="mat-h3">Up</span>
                </h3>
                <h3
                    *ngIf="!dashlet.ds1LogTimedOut && dashlet.ds1s[1].length > 0"
                    class="dashlet-link-underline"
                    dashlet-link="true"
                    (click)="openDetails('ds1', 1)"
                >
                    {{ dashlet.ds1s[1].length }}
                    <span class="mat-h3">Up (Errors)</span>
                </h3>
                <h3
                    *ngIf="!dashlet.ds1LogTimedOut && dashlet.ds1s[2].length > 0"
                    class="dashlet-link-underline"
                    dashlet-link="true"
                    (click)="openDetails('ds1', 2)"
                >
                    {{ dashlet.ds1s[2].length }}
                    <span class="mat-h3">Down</span>
                </h3>
            </div>
        </div>
    </div>

    <dashlet-section-custom-scripts *ngIf="dashlet.showCustomScripts && !openSubcontainer" [equipment]="dashlet.equipment" [customer]="dashlet.customer">
    </dashlet-section-custom-scripts>
</div>
<!-- Media Gateway Drilldown Content -->
<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'mediaGateway'"
    [title]="dashlet.location.locationName + ' | ' + dashlet.equipment.equipmentName"
    [navPrevious]="'ACM System Health - ' + dashlet.customer.customerName"
    [navCurrent]="'Media Gateway Status'"
    [color]="getSeverityColor(openSubcontainerSeverity)"
    (close)="closeDetails()"
>
    <ng-container subtitle>
        {{ dashlet.mediaGateways[openSubcontainerSeverity].length }} of {{ dashlet.mediaGateways[0].length + dashlet.mediaGateways[2].length }} Media gateway(s)
        {{ this.openSubcontainerSeverity === 0 ? 'up' : 'down' }}
    </ng-container>
    <div class="flex-column gap-l" body>
        <div *ngFor="let g of dashlet.mediaGateways[openSubcontainerSeverity]" class="gateway dashlet-cell">
            <!-- title -->
            <dashlet-section-header (click)="openMediaGatewayReport()"> {{ g.name }} ({{ g.number }}) </dashlet-section-header>
            <!-- properties -->
            <div class="flex gap-m">
                <div class="flex-column text-align-right equipment-detail-labels">
                    <div class="mat-body-2">IP Address</div>
                    <div class="mat-body-2">IPNR</div>
                    <div class="mat-body-2">Control</div>
                    <div class="mat-body-2">Type</div>
                    <div class="mat-body-2">Alarms</div>
                </div>
                <div class="flex-column equipment-detail-values">
                    <div class="mat-body-1">{{ g.ipAddress || '---' }}</div>
                    <div class="mat-body-1">{{ g.ipnr || '---' }}</div>
                    <div class="mat-body-1">{{ g.control || '---' }}</div>
                    <div class="mat-body-1">{{ g.type || '---' }}</div>
                    <div class="mat-body-1 dashlet-link-underline" (click)="openAlarms()">
                        Major: {{ g.alarmsMajor }}, Minor: {{ g.alarmsMinor }}, Warning: {{ g.alarmsWarning }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div footer class="justify-end grow gap-m">
        <button mat-button [disabled]="openSubcontainerSeverity === 0" matTooltip="View Active IP Interfaces" (click)="openDetails('mediaGateway', 0)">
            Up
        </button>

        <button mat-button [disabled]="openSubcontainerSeverity === 2" matTooltip="View Not Ready IP Interfaces" (click)="openDetails('mediaGateway', 2)">
            Down
        </button>
    </div>
</vsm-dashlet-subcontainer>

<!-- DS1 Drilldown Content -->
<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'ds1'"
    [title]="dashlet.location.locationName + ' | ' + dashlet.equipment.equipmentName"
    [navPrevious]="'ACM System Health - ' + dashlet.customer.customerName"
    [navCurrent]="'DS1'"
    [color]="getSeverityColor(openSubcontainerSeverity)"
    (close)="closeDetails()"
>
    <ng-container subtitle>
        {{ dashlet.ds1s[openSubcontainerSeverity].length }} of {{ dashlet.ds1s[0].length + dashlet.ds1s[1].length + dashlet.ds1s[2].length }} DS1(s) {{
        openSubcontainerSeverity === 0 ? 'Up' : openSubcontainerSeverity === 1 ? 'Up (Errors)' : 'Down' }}
    </ng-container>
    <ng-container body>
        <div *ngFor="let i of dashlet.ds1s[openSubcontainerSeverity]">
            <dashlet-section-header (click)="openDs1Report()"> {{ i.name }} ({{ i.ds1Number }}) </dashlet-section-header>
            <!-- properties -->
            <div class="flex gap-m">
                <div class="flex-column text-align-right equipment-detail-labels">
                    <div class="mat-body-2">Errored Seconds</div>
                    <div class="mat-body-2">Bursty Errored Seconds</div>
                    <div class="mat-body-2">Severely Errored Seconds</div>
                    <div class="mat-body-2">Unavailable Failed Seconds</div>
                    <div class="mat-body-2">Controlled Slip Seconds</div>
                    <div class="mat-body-2">Loss of frame count</div>
                </div>
                <div class="flex-column equipment-detail-values">
                    <div class="mat-body-1">{{ i.erroredSeconds || '---' }}</div>
                    <div class="mat-body-1">{{ i.burstyErroredSeconds || '---' }}</div>
                    <div class="mat-body-1">{{ i.severelyErroredSeconds || '---' }}</div>
                    <div class="mat-body-1">{{ i.unavailableFailedSeconds || '---' }}</div>
                    <div class="mat-body-1">{{ i.controlledSlipSeconds || '---' }}</div>

                    <div class="mat-body-1">{{ i.lossOfFrameCount || '---' }}</div>
                </div>
            </div>
        </div>
    </ng-container>

    <div footer class="justify-end grow gap-m">
        <button mat-button [disabled]="openSubcontainerSeverity === 0" matTooltip="View Active IP Interfaces" (click)="openDetails('ds1', 0)">Up</button>
        <button mat-button [disabled]="openSubcontainerSeverity === 1" matTooltip="View Active IP Interfaces" (click)="openDetails('ds1', 1)">
            Up (Errors)
        </button>
        <button mat-button [disabled]="openSubcontainerSeverity === 2" matTooltip="View Not Ready IP Interfaces" (click)="openDetails('ds1', 2)">Down</button>
    </div>
</vsm-dashlet-subcontainer>

<!-- create thresholds alerts drilldown -->
<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'createThreshold' || openSubcontainer === 'editThreshold'"
    [navPrevious]="'ACM System Health - ' + dashlet.customer.customerName"
    [navCurrent]="(openSubcontainer === 'createThreshold' ? 'Create' : 'Edit') + ' Threshold'"
    (close)="closeDetails()"
>
    <ng-container title>{{ openSubcontainer === 'createThreshold' ? 'New' : 'Edit' }} {{ selectedField }} Threshold </ng-container>
    <ng-container body>
        <threshold-item #thresholdItem [settings]="dashlet.getSettings()" [type]="'ACM'" [threshold]="selectedThreshold"> </threshold-item>
    </ng-container>
    <div footer class="row row-buttons">
        <div>
            <button type="button" pButton class="btn button-details" (click)="closeDetails()" label="Cancel"></button>
        </div>
        <div *ngIf="openSubcontainer === 'editThreshold'; else addThreshold">
            <button type="submit" pButton class="btn button-details" (click)="saveEdit()" [disabled]="!thresholdItem.formValid()" label="Save"></button>
        </div>
        <ng-template #addThreshold>
            <button type="submit" pButton class="btn button-details" (click)="onSubmit()" [disabled]="!thresholdItem.formValid()" label="Save"></button>
        </ng-template>
    </div>
</vsm-dashlet-subcontainer>

<!--TODO [model]="items" -->
