import { Component, Input, Output, EventEmitter } from '@angular/core';

import { DashletNavChangeEvent, DashletNavType, Dashlet } from '@models/index';
import { DashboardService } from '@services/index';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-dashlet-nav',
    templateUrl: './dashlet-nav.component.html',
    styleUrls: ['./dashlet-nav.component.scss']
})
export class DashletNavComponent {
    dashboardSelected: boolean = false;
    @Output() change: EventEmitter<DashletNavChangeEvent> = new EventEmitter();
    dashlets: Observable<Dashlet[]>;

    private pActiveDashlet: Dashlet = null;
    get activeDashlet(): Dashlet {
        return this.pActiveDashlet;
    }
    @Input('selected') set activeDashlet(value: Dashlet) {
        this.pActiveDashlet = value;
        if (value === null) this.dashboardSelected = true;
        else this.dashboardSelected = false;
    }

    constructor(private dashboardService: DashboardService) {
        this.dashlets = this.dashboardService.dashlets;
    }

    selectDashboard(): void {
        if (!this.dashboardSelected) {
            this.activeDashlet = null;
            this.dashboardSelected = true;
            this.fireChange();
        }
    }

    selectDashlet(item: Dashlet): void {
        if (this.activeDashlet !== item) {
            this.activeDashlet = item;
            this.dashboardSelected = false;
            this.fireChange();
        }
    }

    private fireChange(): void {
        const event = new DashletNavChangeEvent();
        if (this.activeDashlet === null) {
            event.type = this.dashboardSelected ? DashletNavType.Dashboard : DashletNavType.Nothing;
            event.dashlet = null;
        } else {
            (event.type = DashletNavType.Dashlet), (event.dashlet = this.activeDashlet);
        }
        this.change.emit(event);
    }
}
