<ng-container *ngIf="!openSubcontainer">
    <div class="flex-column gap-l">
        <!-- system -->
        <dashlet-section-processor-memory
            #processorMemory
            [showSystemChart]="dashlet.showSystemChart"
            [equipment]="dashlet.equipment"
            [customerId]="dashlet.customer.customerId"
            [locationId]="dashlet.location.locationId"
        >
        </dashlet-section-processor-memory>
        <!-- network connectivity -->
        <dashlet-section-network-connectivity
            [showNetworkGraph]="dashlet.showNetworkGraph"
            [equipment]="dashlet.equipment"
            [customerId]="dashlet.customer.customerId"
        >
        </dashlet-section-network-connectivity>
    </div>
</ng-container>

<ng-container *ngIf="dashlet.showServiceStatus">
    <dashlet-section-services
        #services
        [equipment]="dashlet.equipment"
        [location]="dashlet.location"
        [customer]="dashlet.customer"
        [commandTypeIdServices]="[dashlet.commandTypeIdServiceStatus]"
        (openDetails)="$event === null ? closeDetails() : openDetails('service', $event)"
    >
    </dashlet-section-services>
</ng-container>

<ng-container *ngIf="!openSubcontainer">
    <div class="flex-column gap-l">
        <div *ngIf="dashlet.showLicences">
            <dashlet-section-header
                >API
                <mat-icon
                    class="warning-triangle"
                    color="warn"
                    *ngIf="
                        dashlet.dmccData.dataExpired ||
                        dashlet.tsapiData.buffersDataExpired ||
                        dashlet.tsapiData.switchLinksDataExpired ||
                        dashlet.tsapiData.licensesDataExpired
                    "
                    [matTooltip]="dashlet.apiWarningMessage()"
                    >error</mat-icon
                ></dashlet-section-header
            >
            <div class="justify-space-between grow">
                <div
                    [ngClass]="{ 'dashlet-link-underline': hasDataDMCC() }"
                    (click)="openDetails('dmcc', -1)"
                    class="flex-column gap-s mat-h2 metric"
                    [ngStyle]="{ 'border-left-color': hasDataDMCC() ? getSeverityColor(dashlet.dmccData.getSeverity()) : getSeverityColor(-1) }"
                >
                    <div>DMCC</div>
                    <div class="mat-h3">{{ hasDataDMCC() ? dashlet.dmccData.getStatus() : '---' }}</div>
                </div>
                <div
                    [ngClass]="{ 'dashlet-link-underline': hasDataTSAPI() }"
                    (click)="openDetails('tsapi', -1)"
                    class="flex-column gap-s mat-h2 metric"
                    [ngStyle]="{ 'border-left-color': hasDataTSAPI() ? getSeverityColor(dashlet.tsapiData.severity()) : getSeverityColor(-1) }"
                >
                    <div class="dashlet-link-underline">TSAPI</div>
                    <div class="mat-h3" *ngIf="dashlet.tsapiData">{{ hasDataTSAPI() ? dashlet.tsapiData.getStatus() : '---' }}</div>
                </div>
            </div>
        </div>

        <!-- AES Connections -->
        <div *ngIf="dashlet.showAESConnections">
            <dashlet-section-header>ACM Connections </dashlet-section-header>
            <!-- Bar -->
            <div class="flex-column gap-m">
                <dashlet-ratio-bar emptyTitle="No data available for ACM Connections">
                    <ng-container *ngIf="dashlet.connectionStatus">
                        <dashlet-ratio-bar-item
                            [value]="dashlet.connectionStatus.status === 'in use' ? 1 : 0"
                            [color]="getSeverityColor(0)"
                            [matTooltip]=""
                            (click)="openDetails('aesconnections', 0)"
                        >
                        </dashlet-ratio-bar-item>
                        <dashlet-ratio-bar-item
                            [value]="dashlet.connectionStatus.status !== 'in use' ? 1 : 0"
                            [color]="getSeverityColor(2)"
                            [matTooltip]=""
                            (click)="openDetails('aesconnections', 0)"
                        >
                        </dashlet-ratio-bar-item>
                    </ng-container>
                </dashlet-ratio-bar>
                <div class="justify-space-between">
                    <h3 class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('aesconnections', 0)" *ngIf="dashlet.connectionStatus">
                        <span class="mat-h3">Server {{ dashlet.connectionStatus.serverId }} {{ dashlet.connectionStatus.status }}</span>
                    </h3>
                </div>
            </div>
        </div>
        <dashlet-section-custom-scripts *ngIf="dashlet.showCustomScripts" [equipment]="dashlet.equipment" [customer]="dashlet.customer">
        </dashlet-section-custom-scripts>
    </div>
</ng-container>

<!--DMCC Licences-->
<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'dmcc'"
    [title]="dashlet.location.locationName + ' | ' + dashlet.equipment.equipmentName"
    [navPrevious]="'AES - ' + dashlet.customer.customerName"
    [navCurrent]="'DMCC API'"
    (close)="closeDetails()"
>
    <div class="flex-column gap-l" body>
        <div>
            <dashlet-section-header>Equipment</dashlet-section-header>

            <ng-container *ngIf="dashlet.dmccData.activeDevices || dashlet.dmccData.activeSessions || dashlet.dmccData.totalMonitors; else noData">
                <div class="flex gap-m">
                    <div class="flex-column text-align-right equipment-detail-labels">
                        <div class="mat-body-2">Used Monitors</div>
                        <div class="mat-body-2">Active Devices</div>
                        <div class="mat-body-2">Active Sessions</div>
                    </div>
                    <div class="flex-column equipment-detail-values">
                        <div class="mat-body-1">{{ dashlet.dmccData.usedMonitors }} of {{ dashlet.dmccData.totalMonitors }}</div>
                        <div class="mat-body-1">{{ dashlet.dmccData.activeDevices }}</div>
                        <div class="mat-body-1">{{ dashlet.dmccData.activeSessions }}</div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div>
            <dashlet-section-header>Licenses</dashlet-section-header>

            <dashlet-table [columns]="licenseColumns" [dataSource]="dashlet.dmccData.licences" [tableHeightRows]="5"></dashlet-table>
        </div>
    </div>
</vsm-dashlet-subcontainer>

<!--TSAPI Licences-->
<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'tsapi'"
    [title]="dashlet.location.locationName + ' | ' + dashlet.equipment.equipmentName"
    [navPrevious]="'AES - ' + dashlet.customer.customerName"
    [navCurrent]="'TSAPI'"
    (close)="closeDetails()"
>
    <div class="flex-column gap-l" body>
        <div>
            <dashlet-section-header>Switch Links</dashlet-section-header>
            <dashlet-table
                [columns]="switchLinkColumns"
                [dataSource]="dashlet.tsapiData.switchLinks"
                [tableHeightRows]="getTableHeight(dashlet.tsapiData.switchLinks.length)"
            ></dashlet-table>
        </div>

        <div>
            <dashlet-section-header>TSDI Buffers</dashlet-section-header>
            <dashlet-table
                [columns]="tdsiBufferColumns"
                [dataSource]="dashlet.tsapiData.tsapiTSDIBuffers"
                [tableHeightRows]="getTableHeight(dashlet.tsapiData.tsapiTSDIBuffers.length)"
            ></dashlet-table>
        </div>

        <div>
            <dashlet-section-header>Licenses</dashlet-section-header>

            <dashlet-table
                [columns]="licenseColumns"
                [dataSource]="dashlet.tsapiData.licences"
                [tableHeightRows]="getTableHeight(dashlet.tsapiData.licences.length)"
            ></dashlet-table>
        </div>
    </div>
</vsm-dashlet-subcontainer>

<ng-template #noData>
    <span>No records found</span>
</ng-template>

<!--AES Connections-->
<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'aesconnections'"
    [title]="dashlet.location.locationName + ' | ' + dashlet.equipment.equipmentName"
    [navPrevious]="'AES - ' + dashlet.customer.customerName"
    [navCurrent]="'ACM Connection Status'"
    [color]="getSeverityColor(openSubcontainerSeverity)"
    (close)="closeDetails()"
>
    <div class="flex-column gap-l" body>
        <dashlet-section-header>Server {{ dashlet.connectionStatus.serverId }}</dashlet-section-header>
        <dashlet-table [sortable]="false" [columns]="connectionsColumns" [dataSource]="dashlet.connectionStatus.links" [tableHeightRows]="5"></dashlet-table>
        <dashlet-table [sortable]="false" [columns]="ctiColumns" [dataSource]="dashlet.connectionStatus.getCTILinks()" [tableHeightRows]="5"></dashlet-table>
        <div class="dashlet-cell"></div>
    </div>
</vsm-dashlet-subcontainer>

<!-- create thresholds alerts drilldown -->
<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'createThreshold' || openSubcontainer === 'editThreshold'"
    [navPrevious]="'AES System Health - ' + dashlet.customer.customerName"
    [navCurrent]="(openSubcontainer === 'createThreshold' ? 'Create' : 'Edit') + ' Threshold'"
    (close)="closeDetails()"
>
    <ng-container title>{{ openSubcontainer === 'createThreshold' ? 'New' : 'Edit' }} {{ selectedField }} Threshold </ng-container>
    <ng-container body>
        <threshold-item #thresholdItem [settings]="dashlet.getSettings()" [type]="'AES'" [threshold]="selectedThreshold"> </threshold-item>
    </ng-container>
    <div footer class="row row-buttons">
        <div>
            <button type="button" pButton class="btn button-details" (click)="closeDetails()" label="Cancel"></button>
        </div>
        <div *ngIf="openSubcontainer === 'editThreshold'; else addThreshold">
            <button type="submit" pButton class="btn button-details" (click)="saveEdit()" [disabled]="!thresholdItem.formValid()" label="Save"></button>
        </div>
        <ng-template #addThreshold>
            <button type="submit" pButton class="btn button-details" (click)="onSubmit()" [disabled]="!thresholdItem.formValid()" label="Save"></button>
        </ng-template>
    </div>
</vsm-dashlet-subcontainer>
