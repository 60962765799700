import * as MosCapableReceiversActions from '@actions/get-mos-capable-receivers/get-mos-capable-receivers.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { DashletSettingsService } from '@services/index';

@Injectable()
export class GetMosCapableReceiversEffects {
    constructor(private actions$: Actions, private settingsService: DashletSettingsService) {}

    getMosCapableReceivers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MosCapableReceiversActions.GetMosCapableReceivers),
            mergeMap(action =>
                this.settingsService.getMosCapableReceivers(action.locationId).pipe(
                    catchError(err => of(MosCapableReceiversActions.GetMosCapableReceiversFailure({ error: err }))),
                    mergeMap((data: any) => [
                        MosCapableReceiversActions.GetMosCapableReceiversSuccess({
                            data: data,
                            locationId: action.locationId
                        })
                    ])
                )
            )
        )
    );
}
