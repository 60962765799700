import { DashletSettingDropdownGrouped, DashletSettingsForm, DashletSettingsGroup, DashletSettingDropdown, DashletSettingCheckbox, SelectItemGroup, SelectItem } from '@models/index';
import { DashletSettingsService } from '@services/index';

export class DashletSettingsFormPortsByRegion extends DashletSettingsForm {
	constructor(private readonly settingsService: DashletSettingsService) {
		super();
	}

	loadState(obj: { [key: string]: object }): void {
		// super.loadState(obj);
	}
}
