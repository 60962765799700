import {
    Customer,
    Dashlet,
    Equipment,
    Location,
    NetworkConnectivityData,
    RealtimeGatewayDocument,
    SummaryData,
    SummaryField
} from '@models/index';
import { DashletDataProcessService, TimeoutService } from '@services/index';
import { Subject } from 'rxjs';

interface VMwareEquipment {
    equipmentId: string;
    equipmentName: string;
    equipmentHost: string;
}

interface Hypervisor {
    displayName: string;
    displayVersion: string;
    esxUpdate: string;
    version: string;
}

interface DataCenter {
    name: string;
    dataCenter: string;
}

export interface DataCenterAPIData {
    MyEquipmentID: string;
    Name: string;
    DataCenter: string;
}
export interface HostDataAPIData {
    MyEquipmentID: string;
    Host: string;
    Name: string;
    Connection_State: 'POWERED_OFF' | 'POWERED_ON' | 'SUSPENDED' | 'CONNECTED';
    Power_State: string;
    DataCenter: string;
}

export interface HealthLinkAPIData {
    VirtualMachineName: string;
    MACaddress?: string;
    EquipmentId: string;
}

export interface GuestOSDataAPIData {
    Family: string;
    Full_Name_Args: string;
    Full_Name_Default_Message: string;
    Full_Name_Id: string;
    Host_Name: string;
    IP_Address: string;
    Name: string;
    VM: string;
}

export interface HypervisorDataAPIData {
    Display_Name: string;
    Display_Version: string;
    ESx_Update: string;
    Host: string;
    Version: string;
}

export interface SystemHealth {
    vm: string;
    equipmentId: string;
    availability: number;
    avgPing: number;
    systemHealthCPU: number;
    systemHealthDisk: number;
    maxPing: number;
    systemHealthMemory: number;
    servicesDown: string;
    servicesUp: string;
    severity?: number;
    server: string;
}

export interface Vm {
    vmName: string;
    cpuCount: string;
    host: string;
    memorySize: string;
    name: string;
    expandedName: string;
    powerState: 'POWERED_OFF' | 'POWERED_ON' | 'SUSPENDED';
    vm: string;
    dataCenter: DataCenter | null;
    guestOs: GuestOSDataAPIData;
    server: string;
    equipmentId: string;
    servicesUp: string;
    servicesDown: string;
    systemHealthCPU: number;
    systemHealthMemory: number;
    systemHealthDisk: number;
    maxPing: number;
    avgPing: number;
    availability: number;
    severity: number;
}

export interface VMDataAPIData {
    MyEquipmentID: string;
    CPU_Count: string;
    Host: string;
    Memory_Size_MiB: string;
    Name: string;
    Power_State: 'POWERED_OFF' | 'POWERED_ON' | 'SUSPENDED';
    VM: string;
    ValidationValueNotInRecord: string;
}

interface NetworkData {
    averagePing: string;
    maxPing: string;
    packetLoss: string;
}

export interface Host {
    hypervisor: Hypervisor | null;
    connectionState: string;
    dataCenter: DataCenter | null;
    host: string;
    name: string;
    powerState: string;
    virtualMachines: Vm[];
    vmPowerStates: {
        vmPoweredOn: number;
        vmPoweredOff: number;
        vmPoweredSuspended: number;
    };
}

interface ServiceAPIData {
    ServiceName: string;
    State: string;
    AdditionalInfo: string;
}

export interface NetworkConnectivityAPIData {
    DestinationIPAddress: string;
    EventName: string;
    PacketLossPercentage: string;
    ReplyRoundTripAverageMs: string;
    ReplyRoundTripMaximumMs: string;
    ReplyRoundTripMinimumMs: string;
}

export class DashletSystemHealthVmware extends Dashlet {
    public readonly COMMAND_TYPE_ID_DATA_CENTRE = 'A77B9BC0-6053-422F-BAFD-F3D3F0D20215';
    public readonly COMMAND_TYPE_ID_HOST = '68381436-343F-41D6-8BC1-AA21452FA556';
    public readonly COMMAND_TYPE_ID_HYPERVISOR = '5E97FE45-4E6D-4949-A48F-C9C69A46AC3B';
    public readonly COMMAND_TYPE_ID_VM = 'CCA507A5-BF0D-49BD-9D5E-924AEAB076E0';
    public readonly COMMAND_TYPE_ID_GUEST_OS = '13D199D5-C31C-4467-865E-86A6405DA758';
    public readonly COMMAND_TYPE_ID_SYSTEM_HEALTH_LINK = '19766FBA-9836-4CFB-AC48-B9C77A3ADD79';
    public readonly COMMAND_TYPE_ID_SYSTEM_HEALTH = '4EF0FF09-F8B5-4C33-83E2-C4D6F510D14C';
    public readonly COMMAND_TYPE_ID_NETWORK_CONNECTIVITY = '7BF15776-F312-40C7-92A1-F95DDEAD52EA';

    public customer!: Customer;
    public equipment!: VMwareEquipment;
    private equipmentConnectivity!: Equipment;
    public location!: Location;

    private systemHealthData: SystemHealth[] = [];
    public systemHealthLinks: HealthLinkAPIData[] = [];
    private DataCenter!: DataCenter | null;

    public hosts!: Host[];
    public virtualMachines!: Vm[];

    public networkConnectivity: NetworkConnectivityData;

    public networkData!: NetworkData;

    public hostData: HostDataAPIData[] = [];

    public dataCenterData: DataCenterAPIData[] = [];

    public hypervisorData: HypervisorDataAPIData[] = [];

    public guestOsData: GuestOSDataAPIData[] = [];

    public fromDate: Date;

    public servers: { id: string; name: string }[] = [];

    public sizeChange: Subject<number> = new Subject<number>();
    public settingsUpdated: Subject<null> = new Subject();
    public unSub: Subject<null> = new Subject();

    constructor(private timeoutService: TimeoutService, private dashletDataService: DashletDataProcessService) {
        super();

        this.networkConnectivity = new NetworkConnectivityData(this.equipmentConnectivity);

        //sizing
        this.sizes = [
            {
                id: 0,
                label: 'Small',
                cols: 5,
                rows: 8
            },
            {
                id: 2,
                label: 'Large',
                cols: 15,
                rows: 8
            }
        ];

        this.fromDate = new Date(new Date().setDate(new Date().getDate() - 1));
        this.applySize(0);
        this.resetData();
    }

    public applySettings(v: { [key: string]: any }): void {
        super.applySettings(v);

        this.configured = v.customer && v.location && v.equipment;

        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        this.unSub.next(null);
        if (v.location) {
            this.location = new Location(v.location.value, v.location.label);
        } else {
            this.location = new Location('', '');
        }
        if (v.equipment) {
            this.equipmentConnectivity = new Equipment(v.equipment.value, v.equipment.label);
            this.equipment = {
                equipmentName: v.equipment.label,
                equipmentId: v.equipment.value,
                equipmentHost: v.equipment.host
            };
        } else {
            this.equipmentConnectivity = new Equipment('', '');
            this.equipment = {
                equipmentName: '',
                equipmentId: '',
                equipmentHost: ''
            };
        }
        this.generatedNameTag = this.configured ? `${v.location.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;
        this.settingsUpdated.next(null);
    }

    public applySize(id: number): void {
        super.applySize(id);
        this.sizeChange.next(id);
        this.updateSize();
    }

    private updateSize(): void {
        const h = 0;
        const w = 0;
        this.applySizeExpansion(w, h);
    }

    public resetData(): void {
        this.servers = [];
        this.hosts = [];
        this.hostData = [];
        this.hypervisorData = [];
        this.dataCenterData = [];
        this.virtualMachines = [];
        this.systemHealthData = [];
    }
    public resetHostData(): void {
        this.hosts = [];
    }

    public resetVmData(host: string): void {
        //removes old data for host before processing upt to date data
        this.virtualMachines = this.virtualMachines.filter(v => v.host !== host);
    }

    public dispose(): void {
        this.settingsUpdated.next(null);
        this.settingsUpdated.complete();
        this.unSub.next(null);
        this.unSub.complete();
    }

    private processSummaryField(data: SummaryField): string | null {
        if (data?.FieldCommandUpdateTimeInfoList) {
            const dataExpired = this.timeoutService.getIsDataExpired(
                data.FieldCommandUpdateTimeInfoList[0]?.CommandTypeId,
                data.FieldCommandUpdateTimeInfoList[0]?.LastUpdateTime
            );
            if (!dataExpired) {
                return data.FieldValue ? data.FieldValue : null;
            }
        }
        return null;
    }

    public processSystemHealthData(equipmentId: string, data: SummaryData): void {
        const currentSystemHealthLinks = this.systemHealthLinks.find(x => x.EquipmentId === equipmentId);
        if (!currentSystemHealthLinks) return;
        const server = this.servers.find(server => server.id === equipmentId);
        const services =
            data.servicesUp && JSON.parse(this.processSummaryField(data.servicesUp))
                ? JSON.parse(this.processSummaryField(data.servicesUp))
                : null;
        const servicesUp = services?.filter(
            (service: ServiceAPIData) => service.State === 'Running' || service.State === 'UP' || service.State === 'OK'
        );
        const servicesDown = services?.filter(
            (service: ServiceAPIData) => service.State === 'Stopped' || service.State === 'DOWN'
        );
        const disk = this.processDiskPercentage(data.disk);

        const newSystemHealth: SystemHealth = {
            vm: currentSystemHealthLinks.VirtualMachineName,
            equipmentId: equipmentId,
            availability: +this.processSummaryField(data.averageAvailability),
            avgPing: +this.processSummaryField(data.averagePing),
            systemHealthCPU: +this.processSummaryField(data.cpu) || null,
            systemHealthDisk: +this.processSummaryField(disk) || null,
            maxPing: +this.processSummaryField(data.maxPing),
            systemHealthMemory: +this.processSummaryField(data.memory) || null,
            servicesDown: servicesDown?.length,
            servicesUp: servicesUp?.length,
            server: server?.name || null
        };
        newSystemHealth.severity = this.getSystemHealthSeverity(newSystemHealth);
        const currentSystemHealth = this.systemHealthData.find(
            systemHealth => systemHealth.equipmentId === equipmentId
        );
        if (currentSystemHealth) {
            Object.assign(currentSystemHealth, newSystemHealth);
        } else this.systemHealthData.push(newSystemHealth);

        for (let host of this.hosts) {
            const currentVm = host.virtualMachines.find(vm => vm.vm === newSystemHealth.vm);
            if (currentVm) {
                Object.assign(currentVm, newSystemHealth);
                break;
            }
        }
    }

    private processDiskPercentage(data: SummaryField): SummaryField {
        const diskObject = data?.FieldValue
            ? JSON.parse(data.FieldValue).filter(disk => (disk.DiskName = 'Total'))[0]
            : null;
        return { ...data, FieldValue: diskObject?.DiskUsedPercentage };
    }

    public processGuestOsData(guestOsData: GuestOSDataAPIData[]): void {
        this.guestOsData.push(guestOsData[0]);
        this.hosts.forEach(host => {
            host.virtualMachines.forEach(vm => {
                if (guestOsData[0].VM === vm.vm) vm.guestOs = guestOsData[0];
            });
        });
    }

    public processVmData(vmData: VMDataAPIData[]): void {
        this.resetVmData(vmData[0].Host);
        vmData.forEach((vm: VMDataAPIData) => {
            this.DataCenter = null;
            const newVm: Vm = {
                vmName: vm.VM,
                cpuCount: vm.CPU_Count,
                host: vm.Host,
                memorySize: vm.Memory_Size_MiB,
                name: vm.Name,
                expandedName: vm.Name,
                powerState: vm.Power_State,
                vm: vm.VM,
                dataCenter: this.DataCenter,
                guestOs: null,
                server: '---',
                equipmentId: null,
                servicesDown: null,
                servicesUp: null,
                systemHealthCPU: null,
                systemHealthMemory: null,
                systemHealthDisk: null,
                maxPing: null,
                avgPing: null,
                availability: null,
                severity: null
            };
            this.virtualMachines.push(newVm);
        });
        //process host data once vms are loaded
        this.updateData();
    }

    public processData(
        hostData: HostDataAPIData[],
        hypervisorData: HypervisorDataAPIData[],
        dataCenterData: DataCenterAPIData[]
    ): void {
        this.resetHostData();
        hostData.forEach(host => {
            const matchingHyperVisor = hypervisorData.find(hypervisor => hypervisor.Host === host.Host);
            const hypervisor = matchingHyperVisor
                ? {
                      displayName: matchingHyperVisor.Display_Name,
                      displayVersion: matchingHyperVisor.Display_Version,
                      esxUpdate: matchingHyperVisor['ESx-Update'],
                      version: matchingHyperVisor.Version
                  }
                : null;

            const matchingDataCenter = dataCenterData.find(dataCenter => dataCenter.DataCenter === host.DataCenter);
            const dataCenter = matchingDataCenter
                ? {
                      name: matchingDataCenter.Name,
                      dataCenter: matchingDataCenter.DataCenter
                  }
                : null;

            const newHost: Host = {
                connectionState: host.Connection_State ? host.Connection_State : '',
                dataCenter: dataCenter,
                host: host.Host ? host.Host : '',
                name: host.Name ? host.Name : '',
                powerState: host.Power_State ? host.Power_State : '',
                virtualMachines: [],
                hypervisor: hypervisor,
                vmPowerStates: {
                    vmPoweredOn: 0,
                    vmPoweredOff: 0,
                    vmPoweredSuspended: 0
                }
            };
            //don't load if host has no name to show
            if (newHost.name) this.hosts.push(newHost);
        });
        //match vms from vm list with their matching host
        this.virtualMachines.forEach(currentVm => {
            const hostIndex = this.hosts.map(h => h.host).indexOf(currentVm.host);
            if (hostIndex !== -1) {
                const currentSystemHealth = this.systemHealthData.find(
                    systemHealthOfVm => systemHealthOfVm.vm === currentVm.vmName
                );
                const server = this.servers.find(server => server.id === currentVm.equipmentId);
                const dataCenter = this.hosts[hostIndex].dataCenter;
                const currentGuestOs = this.guestOsData.find(guestOS => guestOS.VM === currentVm.vmName);
                const newVm: Vm = {
                    vmName: currentVm.vmName,
                    cpuCount: currentVm.cpuCount,
                    host: currentVm.host,
                    memorySize: currentVm.memorySize,
                    name: currentVm.name,
                    expandedName: currentVm.name,
                    powerState: currentVm.powerState,
                    vm: currentVm.vm,
                    dataCenter: dataCenter,
                    guestOs: currentGuestOs,
                    server: server ? server.name : currentVm.server,
                    equipmentId: currentSystemHealth?.equipmentId,
                    servicesDown: currentSystemHealth?.servicesDown,
                    servicesUp: currentSystemHealth?.servicesUp,
                    systemHealthCPU: currentSystemHealth?.systemHealthCPU,
                    systemHealthMemory: currentSystemHealth?.systemHealthMemory,
                    systemHealthDisk: currentSystemHealth?.systemHealthDisk,
                    maxPing: currentSystemHealth?.maxPing,
                    avgPing: currentSystemHealth?.avgPing,
                    availability: currentSystemHealth?.availability,
                    severity: this.getSystemHealthSeverity(currentSystemHealth)
                };
                if (newVm.powerState === 'POWERED_ON') {
                    this.hosts[hostIndex].vmPowerStates.vmPoweredOn++;
                } else if (newVm.powerState === 'POWERED_OFF') {
                    this.hosts[hostIndex].vmPowerStates.vmPoweredOff++;
                } else if (newVm.powerState === 'SUSPENDED') {
                    this.hosts[hostIndex].vmPowerStates.vmPoweredSuspended++;
                }
                this.hosts[hostIndex].virtualMachines.push(newVm);
            }
            this.hosts.sort((a, b) => {
                return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
            });
        });
    }

    public processNetworkConnectivity(data: RealtimeGatewayDocument[]): void {
        const rows = data[0].data[0];
        if (rows !== null) {
            this.networkData = this.dashletDataService.processNetworkData(rows);
            this.networkConnectivity.updateNetworkChartData(
                this.networkData.averagePing,
                this.networkData.packetLoss,
                this.networkData.maxPing,
                new Date(data[0].timestamp)
            );
        }
    }

    public processHyperVisorData(hypervisorData: HypervisorDataAPIData) {
        this.hypervisorData.push(hypervisorData);
        const selectedHost = this.hosts.find(host => host.host === hypervisorData.Host);
        if (selectedHost)
            selectedHost.hypervisor = {
                displayName: hypervisorData.Display_Name,
                displayVersion: hypervisorData.Display_Version,
                esxUpdate: hypervisorData.ESx_Update,
                version: hypervisorData.Version
            };
    }

    private getSystemHealthSeverity(server: SystemHealth): number {
        if (!server?.systemHealthCPU && !server?.systemHealthMemory && !server?.systemHealthDisk) {
            return -1;
        } else if (
            (server.systemHealthCPU <= 100 && server.systemHealthCPU >= 90) ||
            (server.systemHealthMemory <= 100 && server.systemHealthMemory >= 90) ||
            (server.systemHealthDisk <= 100 && server.systemHealthDisk >= 90)
        ) {
            return 2;
        } else if (
            (server.systemHealthCPU < 90 && server.systemHealthCPU >= 80) ||
            (server.systemHealthMemory < 90 && server.systemHealthMemory >= 80) ||
            (server.systemHealthDisk < 90 && server.systemHealthDisk >= 80)
        ) {
            return 1;
        } else if (
            (server.systemHealthCPU < 80 && server.systemHealthCPU >= 0) ||
            (server.systemHealthMemory < 80 && server.systemHealthMemory >= 0) ||
            (server.systemHealthDisk < 80 && server.systemHealthDisk >= 0)
        ) {
            return 0;
        }
        return -1;
    }

    public updateData(): void {
        if (this.hostData.length) this.processData(this.hostData, this.hypervisorData, this.dataCenterData);
    }
}
