import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DashletSettingsService } from '@services/index';
import * as CustomersActions from '@actions/customers/customers.actions';
import { catchError, mergeMap, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class CustomersEffects {
    constructor(private actions$: Actions, private dashletSettingsService: DashletSettingsService) {}

    getBPAssociatedCustomers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomersActions.GetBPAssociatedCustomers),
            mergeMap(action =>
                this.dashletSettingsService.getBPAssociatedCustomers(action.businessPartnerId).pipe(
                    map(data =>
                        CustomersActions.GetBPAssociatedCustomersSuccess({
                            data: { data, id: action.businessPartnerId.toLowerCase() }
                        })
                    ),
                    catchError(err => of(CustomersActions.GetBPAssociatedCustomersFailure({ error: err })))
                )
            )
        )
    );

    getUserAssociatedCustomers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomersActions.GetUserAssociatedCustomers),
            mergeMap(action =>
                this.dashletSettingsService.getAllCustomers().pipe(
                    map(data =>
                        CustomersActions.GetUserAssociatedCustomersSuccess({
                            data
                        })
                    ),
                    catchError(err => of(CustomersActions.GetUserAssociatedCustomersFailure({ error: err })))
                )
            )
        )
    );
}
