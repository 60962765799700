import * as RibbonSystemHealthEquipmentActions from '@actions/get-ribbon-system-health-equipment/get-ribbon-system-health-equipment.actions';
import { createReducer, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const ribbonSystemHealthEquipmentFeatureKey = 'ribbonSystemHealthEquipment';
interface RibbonSystemHealthEquipmentEntityState extends EntityState<any> {}

export interface State {
    ribbonSystemHealthEquipment: RibbonSystemHealthEquipmentEntityState;
}

function selectEntityId(a: any): string {
    return a.entityId;
}

const adapterRibbonSystemHealthEquipment = createEntityAdapter<any>({
    selectId: selectEntityId
});

export const initialState: State = {
    ribbonSystemHealthEquipment: adapterRibbonSystemHealthEquipment.getInitialState()
};

export const reducer = createReducer(
    initialState,

    on(RibbonSystemHealthEquipmentActions.GetRibbonSystemHealthEquipmentsSuccess, (state, action) => ({
        ...state,
        ribbonSystemHealthEquipment: adapterRibbonSystemHealthEquipment.addOne(
            { ...action.data, entityId: action.entityId },
            state.ribbonSystemHealthEquipment
        )
    }))
);
