<div class="flex-column gap-m" *ngIf="!openSubcontainer">
    <!-- system -->

    <!-- properties -->
    <dashlet-section-processor-memory
        #processorMemory
        [showSystemChart]="dashlet.showSystemChart"
        [equipment]="dashlet.equipment"
        [customerId]="dashlet.customer.customerId"
        [locationId]="dashlet.location.locationId"
    >
    </dashlet-section-processor-memory>

    <!-- network connectivity -->
    <dashlet-section-network-connectivity
        [showNetworkGraph]="dashlet.showNetworkGraph"
        [equipment]="dashlet.equipment"
        [customerId]="dashlet.customer.customerId"
    >
    </dashlet-section-network-connectivity>
    <div>
        <dashlet-section-header>Backups</dashlet-section-header>
        <div class="justify-space-between grow">
            <div
                class="flex-column gap-s mat-h2 metric"
                [ngStyle]="{
                    'border-left-color':
                        dashlet.backupDataMaintenance.lastBackupStatus !== '' ? getAdminSeverityColor(dashlet.backupDataAdmin) : getSeverityColor(-1)
                }"
                matTooltip="Admin backups run once a month&#013;Last backup {{ dashlet.backupDataAdmin.lastBackupStatus.toLowerCase() }} on {{
                    dashlet.backupDataAdmin.displayDateTime
                }}"
            >
                <div>Admin</div>
                <div class="mat-h3">{{ dashlet.backupDataAdmin.lastBackupStatus !== '' ? dashlet.backupDataAdmin.lastBackupStatus : 'Unknown' }}</div>
            </div>
            <div
                class="flex-column gap-s mat-h2 metric"
                [ngStyle]="{
                    'border-left-color':
                        dashlet.backupDataMaintenance.lastBackupStatus !== ''
                            ? getMaintenanceSeverityColor(dashlet.backupDataMaintenance)
                            : getSeverityColor(-1)
                }"
                matTooltip="Maintenance backups run every 24 hours&#013;Last backup {{ dashlet.backupDataMaintenance.lastBackupStatus.toLowerCase() }} on {{
                    dashlet.backupDataMaintenance.displayDateTime
                }}"
            >
                <div>Maintenance</div>
                <div class="mat-h3">
                    {{ dashlet.backupDataMaintenance.lastBackupStatus !== '' ? dashlet.backupDataMaintenance.lastBackupStatus : 'Unknown' }}
                </div>
            </div>
        </div>
    </div>

    <!-- ACD connection status-->
    <div class="flex-column gap-m">
        <dashlet-section-header>Connections</dashlet-section-header>
        <!-- Bar -->
        <dashlet-ratio-bar emptyTitle="No data available for ACD Connection Status">
            <dashlet-ratio-bar-item
                [value]="getACDUpConnections().length"
                [color]="getSeverityColor(0)"
                [matTooltip]="getACDUpConnections().length + ' of ' + dashlet.acdConnectionDataList.length + ' up'"
                (click)="openDetails('ACD', 0)"
            ></dashlet-ratio-bar-item>
            <dashlet-ratio-bar-item
                [value]="getACDDownConnections().length"
                [color]="getSeverityColor(2)"
                [matTooltip]="getACDDownConnections().length + ' of ' + dashlet.acdConnectionDataList.length + ' down'"
                (click)="openDetails('ACD', 2)"
            ></dashlet-ratio-bar-item>
        </dashlet-ratio-bar>
        <!-- Readouts -->
        <div class="justify-space-between">
            <h3 *ngIf="getACDUpConnections().length > 0" class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('ACD', 0)">
                <span class="mat-h2">{{ getACDUpConnections().length + ' ' }}</span>
                <span class="mat-h3">ACDs up</span>
            </h3>
            <h3 *ngIf="getACDDownConnections().length > 0" class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('ACD', 2)">
                <span class="mat-h2">{{ getACDDownConnections().length + ' ' }}</span>
                <span class="mat-h3">ACDs Down</span>
            </h3>
        </div>
    </div>

    <!-- Supervisor Licenses -->
    <div>
        <dashlet-section-header>Supervisor Licenses</dashlet-section-header>
        <div class="justify-space-between">
            <h3>{{ dashlet.supervisorData.total + ' ' }} <span class="mat-h3">Total</span></h3>
            <h3>{{ dashlet.supervisorData.windowsUser + ' ' }} <span class="mat-h3">Windows User</span></h3>
            <h3>{{ dashlet.supervisorData.webUser + ' ' }} <span class="mat-h3">Web User</span></h3>
        </div>
    </div>

    <!-- Work Force Management Connection Status -->
    <div class="flex-column gap-m" *ngIf="dashlet.showWFMStatus">
        <dashlet-section-header>Work Force Management Connection Status</dashlet-section-header>
		<div class="mat-caption">Real Time Adherence</div>
        <!-- Real time WFM -->
        <dashlet-ratio-bar class="dashlet-cell" emptyTitle="No data available" (click)="openDetails('wfm', 0)">
            <dashlet-ratio-bar-item
                [value]="getGreenWFMRealTimeConnections().length"
                [color]="getSeverityColor(0)"
                [matTooltip]="getGreenWFMRealTimeConnectionsTooltip()"
                (click)="openDetails('wfm', 0)"
            >
            </dashlet-ratio-bar-item>
            <dashlet-ratio-bar-item
                [value]="getOrangeWFMRealTimeConnections().length"
                [color]="getSeverityColor(1)"
                [matTooltip]="getOrangeWFMRealTimeConnectionsTooltip()"
                (click)="openDetails('wfm', 0)"
            >
            </dashlet-ratio-bar-item>
            <dashlet-ratio-bar-item
                [value]="getRedWFMRealTimeConnections().length"
                [color]="getSeverityColor(2)"
                [matTooltip]="getRedWFMRealTimeConnectionsTooltip()"
                (click)="openDetails('wfm', 0)"
            >
            </dashlet-ratio-bar-item>
        </dashlet-ratio-bar>
        <div class="justify-space-between">
            <h3
                *ngIf="getGreenWFMRealTimeConnections().length > 0"
                class="dashlet-link-underline"
                dashlet-link="true"
                (click)="openDetails('wfm', 0)"
                [matTooltip]="getGreenWFMRealTimeConnectionsTooltip()"
            >
                <span class="mat-h2">{{ getGreenWFMRealTimeConnections().length + ' ' }}</span>
                <span class="mat-h3">Ran</span>
            </h3>
            <h3
                *ngIf="getOrangeWFMRealTimeConnections().length > 0"
                class="dashlet-link-underline"
                dashlet-link="true"
                (click)="openDetails('wfm', 1)"
                [matTooltip]="getOrangeWFMRealTimeConnectionsTooltip()"
            >
                <span class="mat-h2">{{ getOrangeWFMRealTimeConnections().length + ' ' }}</span>
                <span class="mat-h3">Warning</span>
            </h3>
            <h3
                *ngIf="getRedWFMRealTimeConnections().length > 0"
                class="dashlet-link-underline"
                dashlet-link="true"
                (click)="openDetails('wfm', 2)"
                [matTooltip]="getRedWFMRealTimeConnectionsTooltip()"
            >
                <span class="mat-h2">{{ getRedWFMRealTimeConnections().length + ' ' }}</span>
                <span class="mat-h3">Error</span>
            </h3>
        </div>

        <div class="mat-caption">Historic</div>
        <!-- Historic WFM -->
        <dashlet-ratio-bar class="dashlet-cell" emptyTitle="No data available">
            <dashlet-ratio-bar-item
                [value]="getGreenWFMHistoricConnections().length"
                [color]="getSeverityColor(0)"
                [matTooltip]="getGreenWFMHistoricConnectionsTooltip()"
                (click)="openDetails('wfm', 0)"
            >
            </dashlet-ratio-bar-item>
            <dashlet-ratio-bar-item
                [value]="getRedWFMHistoricConnections().length"
                [color]="getSeverityColor(2)"
                [matTooltip]="getRedWFMHistoricConnectionsTooltip()"
                (click)="openDetails('wfm', 0)"
            >
            </dashlet-ratio-bar-item>
        </dashlet-ratio-bar>
        <!-- Readouts -->
		<div class="justify-space-between">
            <h3
                *ngIf="getGreenWFMHistoricConnections().length > 0"
                class="dashlet-link-underline"
                dashlet-link="true"
                (click)="openDetails('wfm', 0)"
                [matTooltip]="getGreenWFMHistoricConnectionsTooltip()"
            >
                <span class="mat-h2">{{ getGreenWFMHistoricConnections().length + ' ' }}</span>
                <span class="mat-h3">Ran</span>
            </h3>
            <h3
                *ngIf="getRedWFMHistoricConnections().length > 0"
                class="dashlet-link-underline"
                dashlet-link="true"
                (click)="openDetails('wfm', 2)"
                [matTooltip]="getRedWFMHistoricConnectionsTooltip()"
            >
                <span class="mat-h2">{{ getRedWFMHistoricConnections().length + ' ' }}</span>
                <span class="mat-h3">Error</span>
            </h3>
        </div>

    </div>
    <dashlet-section-custom-scripts *ngIf="dashlet.showCustomScripts" [equipment]="dashlet.equipment" [customer]="dashlet.customer">
    </dashlet-section-custom-scripts>
</div>

<!-- WFM Drilldown -->
<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'wfm'"
    [title]="dashlet.location.locationName + ' | ' + dashlet.equipment.equipmentName"
    [navPrevious]="'CMS System Health - ' + dashlet.customer.customerName"
    [navCurrent]="'Work Force Management Connection Status'"
    (close)="closeDetails()"
>
    <ng-container subtitle>
        <span
            >{{ getGreenWFMRealTimeConnections().length + getGreenWFMHistoricConnections().length }} of
            {{ dashlet.wfmRealTimeDataList.length + dashlet.wfmHistoricDataList.length }}
            connections running</span
        >
    </ng-container>
    <div class="flex-column gap-xl" body>
		<div *ngIf="dashlet.wfmRealTimeDataList.length > 0">
			<dashlet-section-header>
				WFM Real Time Adherence Connection Status
			</dashlet-section-header>
			<dashlet-table
			[columns]="wfmRealtimeColumns"
			[dataSource]="dashlet.wfmRealTimeDataList" [sortable]="false">

			</dashlet-table>
		</div>
		<div *ngIf="dashlet.wfmHistoricDataList.length > 0">
			<dashlet-section-header>
				WFM Historic Adherence Connection Status
			</dashlet-section-header>
			<dashlet-table
			[columns]="wfmHistoricColumns"
			[dataSource]="dashlet.wfmHistoricDataList" [sortable]="false">

			</dashlet-table>
		</div>

    </div>
</vsm-dashlet-subcontainer>
<!-- ACD Drilldown -->
<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'ACD'"
    [title]="dashlet.location.locationName + ' | ' + dashlet.equipment.equipmentName"
    [navPrevious]="'CMS System Health - ' + dashlet.customer.customerName"
    [navCurrent]="'ACD Connection Status'"
    [color]="getSeverityColor(openSubcontainerSeverity)"
    (close)="closeDetails()"
>
    <ng-container subtitle>
        <span *ngIf="severityGreen()">{{ getACDUpConnections().length }} of {{ dashlet.acdConnectionDataList.length }} ACD(s) up</span>
        <span *ngIf="!severityGreen()">{{ getACDDownConnections().length }} of {{ dashlet.acdConnectionDataList.length }} ACD(s) down</span>
    </ng-container>
    <div class="felx-column gap-m" body>
        <div *ngFor="let acdConnection of severityGreen() ? getACDUpConnections() : getACDDownConnections()">
            <!-- title -->
            <dashlet-section-header>{{ acdConnection.acdName }}</dashlet-section-header>
            <div class="flex gap-m">
                <div class="flex-column text-align-right equipment-detail-labels">
                    <div class="mat-body-2">Application</div>
                    <div class="mat-body-2">Session</div>
                    <div class="mat-body-2">Connection</div>
                    <div class="mat-body-2">Calls</div>
                </div>
                <div class="flex-column equipment-detail-values">
                    <div class="mat-body-1">{{ acdConnection.applicationState || '---' }}</div>
                    <div class="mat-body-1">{{ acdConnection.sessionState || '---' }}</div>
                    <div class="mat-body-1">{{ acdConnection.connection || '---' }}</div>
                    <div class="mat-body-1">{{ acdConnection.calls || '---' }}</div>
                </div>
            </div>
        </div>
    </div>

    <div footer class="justify-end grow gap-m">
        <button mat-button [disabled]="openSubcontainerSeverity === 0" matTooltip="View up" (click)="openDetails('ACD', 0)">Up</button>
        <button mat-button [disabled]="openSubcontainerSeverity === 2" matTooltip="View down" (click)="openDetails('ACD', 2)">Down</button>
    </div>
</vsm-dashlet-subcontainer>

<!-- create thresholds alerts drilldown -->
<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'createThreshold' || openSubcontainer === 'editThreshold'"
    [navPrevious]="'CMS System Health - ' + dashlet.customer.customerName"
    [navCurrent]="(openSubcontainer === 'createThreshold' ? 'Create' : 'Edit') + ' Threshold'"
    (close)="closeDetails()"
>
    <ng-container title>{{ openSubcontainer === 'createThreshold' ? 'New' : 'Edit' }} {{ selectedField }} Threshold </ng-container>
    <ng-container body>
        <threshold-item #thresholdItem [settings]="dashlet.getSettings()" [type]="'CMS'" [threshold]="selectedThreshold"> </threshold-item>
    </ng-container>
    <div footer class="row row-buttons">
        <div>
            <button type="button" pButton class="btn button-details" (click)="closeDetails()" label="Cancel"></button>
        </div>
        <div *ngIf="openSubcontainer === 'editThreshold'; else addThreshold">
            <button type="submit" pButton class="btn button-details" (click)="saveEdit()" [disabled]="!thresholdItem.formValid()" label="Save"></button>
        </div>
        <ng-template #addThreshold>
            <button type="submit" pButton class="btn button-details" (click)="onSubmit()" [disabled]="!thresholdItem.formValid()" label="Save"></button>
        </ng-template>
    </div>
</vsm-dashlet-subcontainer>
