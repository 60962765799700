import { Customer, CustomSiteName, Dashlet, Equipment, GraphData, Location, Point, StreamGraph } from '@models/index';
import { Subject } from 'rxjs';
import {
    TeamsSummarybySite,
    TeamsSummaryBySiteDTO,
    TeamsSummaryDTO
} from '../dataObjects/interfaces/MSTeamsData.model';

export class DashletSystemHealthMSTeamsCallsSummary extends Dashlet {
    public customer: Customer;
    public location: Location;
    public equipment: Equipment;
    public pointArray: Point[] = null;
    public chartDataUpdated: Subject<TeamsSummarybySite> = new Subject();
    public settingsChanges: Subject<null> = new Subject();
    public hourSetting: number;
    public loadingDocuments: boolean = true;
    private msTeamsCallRecordsData: TeamsSummarybySite = { All: [] };
    public msTeamsCallQualityGraphData: { [key: string]: GraphData[] } = { All: [] };
    public sizeChange: Subject<number> = new Subject<number>();
    public customSiteNames: CustomSiteName[] = [];
    summaryPeriod = { value: 0 };

    public readonly commandTypeIdTeamsCallSummary = 'EBBB0BBB-94A8-49CF-9796-42C27C2EFED2';
    public readonly commandTypeIdTeamsPSTNCallSummary = '78178CB6-DF14-411B-A8A1-1E5ACD712F78';
    public lastUpdatedDataAt: Date = new Date(0);
    public nameTag: string;
    public showPSTNCallsOnly: boolean = false;

    constructor() {
        super();

        this.sizes = [
            {
                id: 0,
                label: 'Small',
                cols: 10,
                rows: 11
            },
            {
                id: 1,
                label: 'Medium',
                cols: 13,
                rows: 11
            }
        ];

        this.applySize(0);

        this.resetData();
        this.summaryPeriod = { value: 0 };
    }

    applySettings(v: { [key: string]: any }) {
        const previousSettings = {
            customer: this.customer,
            location: this.location,
            equipment: this.equipment,
            summaryPeriod: this.summaryPeriod,
            showPSTNCallsOnly: this.showPSTNCallsOnly,
            customSiteNames: this.customSiteNames
        };

        super.applySettings(v);

        this.configured = v.customer && v.location && v.equipment && v.summaryPeriod;

        this.hourSetting = v.summaryPeriod ? Number(v.summaryPeriod.value) : null;
        this.summaryPeriod = v.summaryPeriod;
        this.showPSTNCallsOnly = v.showPSTNCallsOnly;
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        if (this.configured) {
            this.equipment = new Equipment(v.equipment.value, v.equipment.label);
            this.location = new Location(v.location.value, v.location.label);
        }

        this.location = v.location ? new Location(v.location.value, v.location.label) : new Location('', '');

        this.equipment = v.equipment ? new Equipment(v.equipment.value, v.equipment.label) : new Equipment('', '');

        this.nameTag = this.configured ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';

        this.customNameTag = v.nameTag;

        if (v.customSiteNames) {
            this.customSiteNames = v.customSiteNames.filter(names => names.label !== 'Unknown');
        }

        this.updateSize();

        const currentSettings = {
            customer: this.customer,
            location: this.location,
            equipment: this.equipment,
            summaryPeriod: this.summaryPeriod,
            showPSTNCallsOnly: this.showPSTNCallsOnly,
            customSiteNames: this.customSiteNames
        };

        if (this.areSettingsDifferent(previousSettings, currentSettings) && this.areSettingsValid(currentSettings)) {
            this.settingsChanges.next(null);
        }
    }

    private areSettingsDifferent(prev: any, curr: any): boolean {
        return JSON.stringify(prev) !== JSON.stringify(curr);
    }

    private areSettingsValid(settings: any): boolean {
        return settings.customer && settings.location && settings.equipment && settings.summaryPeriod;
    }

    applySize(id: number) {
        super.applySize(id);
        this.sizeChange.next(id);
        this.updateSize();
        this.refreshNameTag();
    }

    refreshNameTag() {
        if (!this.lastUpdatedDataAt && this.lastUpdatedDataAt.getTime() === 0) {
            // We have no data for last updated yet
            return;
        }
        const originalDate = new Date(this.lastUpdatedDataAt),
            originalDateNoMinutes = new Date(this.lastUpdatedDataAt);
        originalDateNoMinutes.setMinutes(0, 0, 0);

        const time = originalDateNoMinutes
            .toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' })
            .toUpperCase();

        const date = new Date(this.lastUpdatedDataAt);
        const month = originalDate.toLocaleDateString(undefined, { month: 'short' });
        const day = date.getDate().toString().padStart(2, '0');
        const timeString = this.extractTime(date);

        const firstDateVal: Date = new Date(originalDateNoMinutes);
        firstDateVal.setHours(firstDateVal.getHours() - +this.summaryPeriod?.value);
        const firstTime = firstDateVal
            .toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' })
            .toUpperCase();

        let period = '';
        period = firstTime + ' - ' + time;

        if (this.location !== undefined && this.equipment !== undefined && this.summaryPeriod !== undefined) {
            this.generatedNameTag = `${this.nameTag} | ${period} | Last updated at ${day}-${month} ${timeString}`;
        }
    }

    private extractTime(date: Date): string {
        const hours24 = date.getHours(); // Use local time
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const isPM = hours24 >= 12;
        const hours12 = hours24 % 12 || 12; // Convert 24-hour format to 12-hour format
        const period = isPM ? 'PM' : 'AM';
        const timeString = `${hours12}:${minutes} ${period}`;
        return timeString;
    }

    private updateSize() {
        let h = 0;
        let w = 0;
        this.applySizeExpansion(w, h);
    }

    public GetEmptyTimeWindowArray(startingDate: Date, dateMinutes: number): Point[] {
        let emptyArray: Point[] = [];
        let dateItem: Date;

        //Data seems to update on the 45th minute of each our, so if the current time is past 45 minutes it means the latest data is for the next hour
        if (dateMinutes > 45) {
            dateItem = new Date(startingDate.setHours(startingDate.getHours()));
        } else {
            dateItem = new Date(startingDate.setHours(startingDate.getHours() - 1));
        }

        for (let i = 0; i < this.hourSetting; i++) {
            emptyArray.push({
                x: dateItem,
                y: 0
            });
            dateItem = new Date(dateItem.setHours(dateItem.getHours() + 1));
        }

        emptyArray.sort((a: Point, b: Point) =>
            a.x.getTime() < b.x.getTime() ? 1 : b.x.getTime() < a.x.getTime() ? -1 : 0
        );
        return emptyArray;
    }

    public processMSTeamsCallData(teamsCallsHourlySummary: string, documentTimestamp: Date): void {
        if (teamsCallsHourlySummary) this.loadingDocuments = false;
        const adjustedTimestamp = new Date(documentTimestamp.setMinutes(0, 0, 0));
        const parsedTeamsSiteData: TeamsSummaryBySiteDTO = JSON.parse(teamsCallsHourlySummary);
        if (!parsedTeamsSiteData['All']) return;

        const pointExists = this.pointArray.some(point => point.x.toISOString() === adjustedTimestamp.toISOString());
        if (!pointExists) {
            let oldestPointIndex = 0;
            let oldestPointTime = this.pointArray[0]?.x.getTime();
            for (let i = 1; i < this.pointArray.length; i++) {
                if (this.pointArray[i].x.getTime() < oldestPointTime) {
                    oldestPointTime = this.pointArray[i].x.getTime();
                    oldestPointIndex = i;
                }
            }
            if (this.pointArray.length >= this.hourSetting) {
                this.pointArray.splice(oldestPointIndex, 1);
            }
            this.pointArray.push({ x: adjustedTimestamp, y: 0 });
        }

        Object.keys(parsedTeamsSiteData).forEach(siteName => {
            const siteRecords = this.msTeamsCallRecordsData[siteName] || [];
            const previousRecord = siteRecords[siteRecords.length - 1];

            if (previousRecord?.DocumentCreation.getTime() === adjustedTimestamp.getTime()) return;

            const siteData = { ...parsedTeamsSiteData[siteName], DocumentCreation: adjustedTimestamp };
            siteRecords.push(siteData);

            if (this.hourSetting && siteRecords.length > this.hourSetting) {
                siteRecords.shift();
            }

            this.msTeamsCallRecordsData[siteName] = siteRecords;
            this.buildGraphData(siteRecords, siteName);
        });
        this.pointArray.sort((a: Point, b: Point) =>
            a.x.getTime() < b.x.getTime() ? 1 : b.x.getTime() < a.x.getTime() ? -1 : 0
        );

        this.refreshChartData();
    }

    public refreshChartData() {
        this.chartDataUpdated.next(this.msTeamsCallRecordsData);
        this.refreshNameTag();
    }

    private buildGraphData(msTeamsCallRecordsData: TeamsSummaryDTO[], siteName: string): void {
        this.msTeamsCallQualityGraphData[siteName] = this.pointArray.map(point => {
            const index = msTeamsCallRecordsData.findIndex(
                (item: TeamsSummaryDTO) => new Date(item.DocumentCreation).toISOString() === point.x.toISOString()
            );
            const goodStream = this.initNodeData();
            const satisfactoryStream = this.initNodeData();
            const poorStream = this.initNodeData();

            if (index !== -1) {
                const record = msTeamsCallRecordsData[index];
                goodStream.data.streams = record.StreamQuality.Good;
                satisfactoryStream.data.streams = record.StreamQuality.Satisfactory;
                poorStream.data.streams = record.StreamQuality.Bad;

                const timeStamp = record.DocumentCreation;
                goodStream.data.timeStamp = timeStamp;
                satisfactoryStream.data.timeStamp = timeStamp;
                poorStream.data.timeStamp = timeStamp;
            } else {
                goodStream.data.streams = 0;
                satisfactoryStream.data.streams = 0;
                poorStream.data.streams = 0;

                const timeStamp = point.x;
                goodStream.data.timeStamp = timeStamp;
                satisfactoryStream.data.timeStamp = timeStamp;
                poorStream.data.timeStamp = timeStamp;
            }

            return {
                Good: goodStream,
                Satisfactory: satisfactoryStream,
                Poor: poorStream
            };
        });
    }

    private initNodeData(): StreamGraph {
        return {
            data: {
                streams: 0,
                timeStamp: null
            }
        } as StreamGraph;
    }

    public resetData() {
        this.msTeamsCallRecordsData = { All: [] };
        this.msTeamsCallQualityGraphData = { All: [] };
        this.pointArray = [];
    }

    public dispose() {}
}
