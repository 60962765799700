import {
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingDropdownGrouped,
    DashletSettingList,
    SelectItem
} from '@models/index';
import { DashletSettingsService } from '@services/index';

export class DashletSettingsFormTrunkGroupSummary extends DashletSettingsForm {
    private customer!: DashletSettingDropdown;
    private location!: DashletSettingDropdown;
    private equipment!: DashletSettingDropdownGrouped;
    private trunkGroups!: DashletSettingList<string>;
    private period!: DashletSettingDropdown;

    constructor(private readonly settingsService: DashletSettingsService) {
        super();

        this.init();
        this.loadCustomers();
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);

        if (this.customer.value) {
            this.loadLocations(this.customer.value);
        }
        if (this.location.value) {
            this.loadEquipment(this.location.value);
            if (this.equipment.value) {
                this.loadTrunkGroups(this.equipment.value);
            }
        }
    }

    private init(): void {
        const group = new DashletSettingsGroup();
        const s: any = (group.settings = []);

        const period = (this.period = new DashletSettingDropdown());
        period.label = 'Summary Period';
        period.stateKey = 'summaryPeriod';
        period.items = [];

        const daily = new SelectItem();
        daily.label = '24 hours';
        daily.value = '24';
        period.items.push(daily);

        const twelveHourly = new SelectItem();
        twelveHourly.label = '12 hours';
        twelveHourly.value = '12';
        period.items.push(twelveHourly);

        const sixHourly = new SelectItem();
        sixHourly.label = '6 hours';
        sixHourly.value = '6';
        period.items.push(sixHourly);

        const hourly = new SelectItem();
        hourly.label = '1 hour';
        hourly.value = '1';
        period.items.push(hourly);

        s.push(period);

        const customer = (this.customer = new DashletSettingDropdown());
        customer.label = 'Customer';
        customer.stateKey = 'customer';
        customer.disabled = true;
        customer.change.subscribe(() => {
            if (!this.location.value) this.clearAndDisable(this.location);
            if (!this.equipment.value) this.clearAndDisable(this.equipment);
            if (!this.location.value) this.clearAndDisable(this.trunkGroups);

            this.loadLocations(customer.value!);
        });
        s.push(customer);

        const location = (this.location = new DashletSettingDropdown());
        location.label = 'Location';
        location.stateKey = 'location';
        location.disabled = true;
        location.change.subscribe(() => {
            if (!this.equipment.value) this.clearAndDisable(this.equipment);
            if (!this.trunkGroups.values) this.clearAndDisable(this.trunkGroups);

            this.loadEquipment(location.value!);
        });
        s.push(location);

        const equipment = (this.equipment = new DashletSettingDropdownGrouped());
        equipment.label = 'Equipment';
        equipment.stateKey = 'equipment';
        equipment.disabled = true;
        equipment.change.subscribe(() => {
            if (!this.trunkGroups.values) this.clearAndDisable(this.trunkGroups);

            this.loadTrunkGroups(equipment.value!);
        });
        s.push(equipment);

        const trunk = (this.trunkGroups = new DashletSettingList<string>());
        trunk.label = 'Trunk Groups';
        trunk.stateKey = 'trunkGroups';
        trunk.disabled = true;
        trunk.multiple = true;
        trunk.metaKeySelection = false;
        s.push(trunk);

        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);
        this.settingsService.getUserAssociatedCustomers().subscribe(
            customers => {
                this.customer.items = customers;
                this.customer.loadingComplete();
            },
            () => {
                this.customer.loadingComplete();
                this.customer.disabled = true;
                // show error notification here
            }
        );
    }

    private loadLocations(customer: string): void {
        this.enableAndLoading(this.location);
        this.settingsService.getLocations(customer).subscribe(
            locations => {
                this.location.items = locations;
                this.location.loadingComplete();
            },
            () => {
                this.location.loadingComplete();
                this.location.disabled = true;
                // show error notification here
            }
        );
    }

    private loadEquipment(location: string): void {
        this.enableAndLoading(this.equipment);
        Promise.all([
            this.settingsService.getEquipment(location, 'ACM').toPromise(),
            this.settingsService.getEquipment(location, 'IPOffice').toPromise()
        ]).then(
            ([acm, ipo]) => {
                const groups = [
                    {
                        label: 'ACM',
                        items: acm
                    },
                    {
                        label: 'IP Office',
                        items: ipo
                    }
                ];
                this.equipment.items = groups;
                this.equipment.loadingComplete();
            },
            () => {
                this.equipment.loadingComplete();
                this.equipment.disabled = true;
                // show error notification here
            }
        );
    }

    private loadTrunkGroups(equipment: string): void {
        this.enableAndLoading(this.trunkGroups);
        this.settingsService.getTrunkGroups(equipment).subscribe(
            trunks => {
                this.trunkGroups.items = trunks;
                this.trunkGroups.loadingComplete();
            },
            () => {
                this.trunkGroups.loadingComplete();
                this.trunkGroups.disabled = true;
                // show error notification here
            }
        );
    }

    private clearAndDisable(
        widget: DashletSettingDropdown | DashletSettingDropdownGrouped | DashletSettingList<string>
    ) {
        widget.disabled = true;
        widget.items = [];
        if (widget instanceof DashletSettingList) {
            widget.values = [];
        } else {
            widget.value = null;
        }
    }

    private enableAndLoading(
        widget: DashletSettingDropdown | DashletSettingDropdownGrouped | DashletSettingList<string>
    ) {
        widget.disabled = false;
        widget.loadingBegin();
    }
}
