import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { alarmStatus } from '@models/dashlets/DashletAlarmsSummary';
import { AccountService, DashletDataProcessService } from '@services/index';
import { take } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TextFieldModule } from '@angular/cdk/text-field';
import { UserDetails } from '@models/index';
import { ThemePalette } from '@angular/material/core';
import {
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';

interface Alarm {
    id: string;
    displayName: string;
    administeredId: string;
    firstOccurred: Date;
    lastOccurred: Date | null;
    equipmentId: string;
    equipment: string;
    customerId: string;
    customer: string;
    severity: number;
    resolveStatus: alarmStatus | null;
}

export interface QuickSuppressArguments {
    Description: string;
    EntityId: string;
    User: KeyValueItem;
    Customer: KeyValueItem;
    Location: KeyValueItem;
    Equipment: KeyValueItem;
    AlarmName: string;
    AdminId: string;
    SuppressionPeriod: DateRange;
    Granularity: string;
}

export interface SuppressAlarmDto {
    name: string;
    entityId: string;
    jsonRules: string;
    createdUTCDateTime: string;
    modifiedUTCDateTime: string;
    calloutId: string;
    calloutIdType: string;
    stopProcessingOnMatch: boolean;
    ruleOrder: number;
    virsaeWorkflows_WorkflowDefinitionId: string;
    isDeleted: boolean;
    customerId: string;
    locationId: string;
    equipmentId: string;
    alarmName: string;
    adminId: string;
}

interface KeyValueItem {
    name: string;
    id: string;
}

interface DateRange {
    startDateTime: string;
    endDateTime: string;
}

interface Select {
    value: string;
    viewValue: string;
}

@Component({
    selector: 'alarm-suppression-dialog',
    templateUrl: './alarm-suppression-dialog.component.html',
    styleUrls: ['./alarm-suppression-dialog.component.scss'],
    standalone: true,
    imports: [
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatSelectModule,
        FormsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatGridListModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        TextFieldModule,
        MatDatepickerModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule,
        MatRadioModule,
        MatTooltipModule
    ]
})
export class AlarmSuppressionDialog {
    public alarmInput: Alarm;
    public quickSuppressionArguments: QuickSuppressArguments;
    public suppressionDescription: string;
    public responseObject: SuppressAlarmDto;
    public JSONRuleObject: any[] = [];
    public cautionMessage: string[] = [];
    public emptyGuidVal: string = '00000000-0000-0000-0000-000000000000';
    public color: ThemePalette = 'primary';

    public dispCustomTime: boolean = false;
    public acknowledgedDanger: boolean = false;
    public excludeLocationId: boolean = false;
    public excludeEquipmentId: boolean = false;
    public excludeAlarmName: boolean = false;
    public excludeAdminId: boolean = false;
    public loading: boolean = false;
    public showDateTime: boolean = false;

    private user: UserDetails;

    public timesAvailable: Select[] = [
        { value: '1', viewValue: '1 Hour' },
        { value: '2', viewValue: '2 Hours' },
        { value: '4', viewValue: '4 Hours' },
        { value: '6', viewValue: '6 Hours' },
        { value: '8', viewValue: '8 Hours' },
        { value: '12', viewValue: '12 Hours' },
        { value: '18', viewValue: '18 Hours' },
        { value: '24', viewValue: '24 Hours' }
    ];

    public selectedTimeToSuppress: string;
    public customTime: Date;

    public disabled = false;
    public showSpinners = true;
    public showSeconds = true;
    public touchUi = false;
    public enableMeridian = false;
    public initialDate: Date;
    public minDate: Date;
    public maxDate: Date;
    public stepHour = 1;
    public stepMinute = 1;
    public stepSecond = 1;
    public counter: string = '';
    public charCount: number = 0;
    public maxLength: number = 60;
    public customTimeTooltip: string = 'A maximum of 7 days can be suppressed.';

    public timeSelectFormControl = new FormControl('', Validators.required);
    public descriptionFormControl = new FormControl('', Validators.required);

    constructor(
        public dialogRef: MatDialogRef<AlarmSuppressionDialog>,
        public dataService: DashletDataProcessService,
        private accountService: AccountService,
        private cdref: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: Alarm
    ) {
        this.alarmInput = data;
    }

    public ngOnInit(): void {
        this.SetUpInitialState();

        this.dataService
            .getAlarmById(this.alarmInput.id, this.alarmInput.customerId)
            .pipe(take(1))
            .subscribe(result => {
                this.quickSuppressionArguments = {
                    AdminId: this.alarmInput.administeredId,
                    AlarmName: this.alarmInput.displayName,
                    EntityId: '',
                    Description: '',
                    User: { name: '', id: '' },
                    Customer: { name: this.alarmInput.customer, id: this.alarmInput.customerId },
                    Location: { name: result.locationName, id: result.locationId },
                    Equipment: {
                        name:
                            this.alarmInput.equipment.toLowerCase() !== 'unknown'
                                ? this.alarmInput.equipment
                                : result.equipmentName,
                        id: result.equipmentId
                    },
                    SuppressionPeriod: {
                        startDateTime: '',
                        endDateTime: ''
                    },
                    Granularity: '0'
                };
                this.loading = false;
            });
    }

    public ngAfterContentChecked() {
        if (this.minDate.getTime() >= this.customTime.getTime()) {
            this.customTime = new Date(
                new Date().setHours(this.minDate.getHours(), this.minDate.getMinutes(), this.minDate.getSeconds())
            );
        }
        if (this.maxDate.getTime() <= this.customTime.getTime()) {
            this.customTime = new Date(
                new Date().setHours(this.maxDate.getHours(), this.maxDate.getMinutes(), this.maxDate.getSeconds())
            );
        }
        this.cdref.detectChanges();
    }

    public getPeriodStatement(): string {
        if (!this.dispCustomTime) {
            return (
                this.initialDate.toLocaleString() +
                ' until ' +
                new Date(
                    new Date().setHours(
                        this.initialDate.getHours() + parseInt(this.selectedTimeToSuppress),
                        this.initialDate.getMinutes(),
                        this.initialDate.getSeconds()
                    )
                ).toLocaleString()
            );
        } else {
            return this.initialDate.toLocaleString() + ' until ' + this.customTime.toLocaleString();
        }
    }

    public CreateRuleObject(): void {
        this.BuildArguments();
        this.dialogRef.close({ result: true, data: this.responseObject });
    }

    public GetFormHeading(): string {
        if (this.dispCustomTime) return 'Suppression Expiry';
        return 'Suppression Period';
    }

    public onValueChange(): void {
        if (this.suppressionDescription) {
            this.charCount = this.suppressionDescription.length;
            this.counter = `${this.charCount} of ${this.maxLength} characters.`;
        }
    }

    public OnCancel(): void {
        this.dialogRef.close({ result: false, data: null });
    }

    private SetUpInitialState(): void {
        this.loading = true;
        this.acknowledgedDanger = false;
        this.selectedTimeToSuppress = '1';
        this.ToggleAllExclusionsToFalse();
        this.cautionMessage = this.BuildDialogCautionMessage();
        this.initialDate = new Date();
        this.minDate = new Date(new Date().setHours(new Date().getHours() + 1));
        this.customTime = new Date(new Date().setHours(new Date().getHours() + 1));
        this.maxDate = new Date(new Date().setDate(new Date().getDate() + 7));
        this.user = this.accountService.getUserDetails() as UserDetails;
    }

    private ToggleAllExclusionsToFalse(): void {
        this.excludeLocationId = false;
        this.excludeEquipmentId = false;
        this.excludeAlarmName = false;
        this.excludeAdminId = false;
    }

    private CreateEquipmentExpression(): string {
        var result = 'Equipment equal ';
        if (this.quickSuppressionArguments.Equipment.name !== null) {
            return result + this.quickSuppressionArguments.Equipment.name;
        } else {
            return result + this.quickSuppressionArguments.Equipment.id;
        }
    }

    private CreateLocationExpression(): string {
        var result = 'Location equal ';
        if (this.quickSuppressionArguments.Location.name !== null) {
            return result + this.quickSuppressionArguments.Location.name;
        } else {
            return result + this.quickSuppressionArguments.Equipment.id;
        }
    }

    private BuildDialogCautionMessage(): string[] {
        const message: string[] = [];
        message.push(
            '<b>CAUTION<br> This action will resolve ALL unresolved alarm that match these Criteria.</b><br>Resolved alarms <b>CAN NOT be un-resolved.</b> Future alarms matching criteria will also be suppressed.'
        );
        return message;
    }

    private BuildArguments(): void {
        const suppressionStart = new Date(this.initialDate.toUTCString()).toISOString();

        const suppressionEnd = this.dispCustomTime
            ? new Date(this.customTime.toUTCString()).toISOString()
            : new Date(
                  new Date(
                      new Date().setHours(
                          new Date(this.initialDate.toUTCString()).getHours() + parseInt(this.selectedTimeToSuppress)
                      )
                  ).toUTCString()
              ).toISOString();

        this.quickSuppressionArguments.SuppressionPeriod.startDateTime = suppressionStart;
        this.quickSuppressionArguments.SuppressionPeriod.endDateTime = suppressionEnd;
        this.quickSuppressionArguments.User.id = this.user.UserId;
        this.quickSuppressionArguments.User.name = this.user.FirstName + ' ' + this.user.LastName;
        this.quickSuppressionArguments.EntityId = this.user.EntityId;
        this.quickSuppressionArguments.Description = this.suppressionDescription;
        this.quickSuppressionArguments.Granularity = '';

        this.JSONRuleObject = [
            {
                id: 1,
                parentid: null,
                isanyall: true,
                anyall: 'ALL',
                field: null,
                operatr: null,
                value: null,
                maskedvalue: null,
                expression: 'ALL',
                inputtype: null,
                validrule: true,
                mandatory: false,
                filterbyfield: null,
                visible: true,
                sqldatatype: null,
                sqlalias: null,
                sqlcolumnmap: null,
                sqlfromvalue: false,
                utcvalue: null
            },
            {
                id: 2,
                parentid: 1,
                isanyall: false,
                anyall: null,
                field: 'CustomerId',
                operatr: '==',
                value: this.quickSuppressionArguments.Customer.id,
                maskedvalue: null,
                expression: 'Customer equal ' + this.quickSuppressionArguments.Customer.name,
                inputtype: 'DropDownList',
                validrule: true,
                mandatory: false,
                filterbyfield: null,
                visible: true,
                sqldatatype: 'None',
                sqlalias: '',
                sqlcolumnmap: '',
                sqlfromvalue: false,
                utcvalue: null
            }
        ];

        if (!this.excludeLocationId && this.quickSuppressionArguments.Location.id !== this.emptyGuidVal) {
            this.JSONRuleObject.push({
                id: 3,
                parentid: 1,
                isanyall: false,
                anyall: null,
                field: 'LocationId',
                operatr: '==',
                value: this.quickSuppressionArguments.Location.id,
                maskedvalue: null,
                expression: this.CreateLocationExpression(),
                inputtype: 'DropDownList',
                validrule: true,
                mandatory: false,
                filterbyfield: null,
                visible: true,
                sqldatatype: 'None',
                sqlalias: '',
                sqlcolumnmap: '',
                sqlfromvalue: false,
                utcvalue: null
            });
        }

        if (!this.excludeEquipmentId && this.quickSuppressionArguments.Equipment.id !== this.emptyGuidVal) {
            this.JSONRuleObject.push({
                id: 4,
                parentid: 1,
                isanyall: false,
                anyall: null,
                field: 'EquipmentId',
                operatr: '==',
                value: this.quickSuppressionArguments.Equipment.id,
                maskedvalue: null,
                expression: this.CreateEquipmentExpression(),
                inputtype: 'DropDownList',
                validrule: true,
                mandatory: false,
                filterbyfield: null,
                visible: true,
                sqldatatype: 'None',
                sqlalias: '',
                sqlcolumnmap: '',
                sqlfromvalue: false,
                utcvalue: null
            });
        }

        if (!this.excludeAlarmName) {
            this.JSONRuleObject.push({
                id: 5,
                parentid: 1,
                isanyall: false,
                anyall: null,
                field: 'AlarmName',
                operatr: '==',
                value: this.quickSuppressionArguments.AlarmName,
                maskedvalue: null,
                expression: 'Alarm Name equal ' + this.quickSuppressionArguments.AlarmName,
                inputtype: 'TextBox',
                validrule: true,
                mandatory: false,
                filterbyfield: null,
                visible: true,
                sqldatatype: 'None',
                sqlalias: '',
                sqlcolumnmap: '',
                sqlfromvalue: false,
                utcvalue: null
            });
        }

        if (!this.excludeAdminId) {
            this.JSONRuleObject.push({
                id: 6,
                parentid: 1,
                isanyall: false,
                anyall: null,
                field: 'AdministeredId',
                operatr: '==',
                value: this.quickSuppressionArguments.AdminId,
                maskedvalue: null,
                expression: 'Administered ID equal ' + this.quickSuppressionArguments.AdminId,
                inputtype: 'TextBox',
                validrule: true,
                mandatory: false,
                filterbyfield: null,
                visible: true,
                sqldatatype: 'None',
                sqlalias: '',
                sqlcolumnmap: '',
                sqlfromvalue: false,
                utcvalue: null
            });
        }

        this.JSONRuleObject.push({
            id: 7,
            parentid: 1,
            isanyall: false,
            anyall: null,
            field: 'TimeOfDay',
            operatr: '>=',
            value: this.quickSuppressionArguments.SuppressionPeriod.startDateTime,
            maskedvalue: null,
            expression:
                'TimeOfDay greater than or equal ' + this.quickSuppressionArguments.SuppressionPeriod.startDateTime,
            inputtype: 'DateTime',
            validrule: true,
            mandatory: false,
            filterbyfield: null,
            visible: true,
            sqldatatype: 'None',
            sqlalias: '',
            sqlcolumnmap: '',
            sqlfromvalue: false,
            utcvalue: null
        });

        this.JSONRuleObject.push({
            id: 8,
            parentid: 1,
            isanyall: false,
            anyall: null,
            field: 'TimeOfDay',
            operatr: '<=',
            value: this.quickSuppressionArguments.SuppressionPeriod.endDateTime,
            maskedvalue: null,
            expression: 'TimeOfDay less than or equal ' + this.quickSuppressionArguments.SuppressionPeriod.endDateTime,
            inputtype: 'DateTime',
            validrule: true,
            mandatory: false,
            filterbyfield: null,
            visible: true,
            sqldatatype: 'None',
            sqlalias: '',
            sqlcolumnmap: '',
            sqlfromvalue: false,
            utcvalue: null
        });

        this.responseObject = {
            name: this.quickSuppressionArguments.User.name + '|&|&|&|' + this.quickSuppressionArguments.Description,
            entityId: this.quickSuppressionArguments.EntityId,
            jsonRules: JSON.stringify(this.JSONRuleObject),
            createdUTCDateTime: new Date(new Date().toUTCString()).toISOString(),
            modifiedUTCDateTime: new Date(new Date().toUTCString()).toISOString(),
            calloutId: this.emptyGuidVal,
            calloutIdType: 'NONE',
            stopProcessingOnMatch: true,
            ruleOrder: -1,
            virsaeWorkflows_WorkflowDefinitionId: this.emptyGuidVal,
            isDeleted: false,
            customerId: this.quickSuppressionArguments.Customer.id,
            locationId: !this.excludeLocationId ? this.quickSuppressionArguments.Location.id : this.emptyGuidVal,
            equipmentId: !this.excludeEquipmentId ? this.quickSuppressionArguments.Equipment.id : this.emptyGuidVal,
            alarmName: !this.excludeAlarmName ? this.quickSuppressionArguments.AlarmName : '',
            adminId: !this.excludeAdminId ? this.quickSuppressionArguments.AdminId : ''
        };
    }
}
