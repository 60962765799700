import * as Actions from '@actions/index';
import { AppState, selectDataFromCommonEntity, selectEntity } from '@reducers/index';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ContextMenu } from 'primeng/contextmenu';
import { MenuItem } from 'primeng/api';
import { select, Store } from '@ngrx/store';
import { AccountService, DashboardService, DashletService, ReportService } from '@services/index';
import { ColumnTypes, CustomerLogo, DashletSystemHealthCiscoCUBE } from '@models/index';
import { Subscription } from 'rxjs';
import {
    CiscoDialPeer,
    CiscoEnvFan,
    CiscoEnvSupply,
    CiscoEnvTemp,
    CiscoEnvVoltage,
    CiscoInterfaceStatus,
    CiscoMTP,
    CiscoTranscoding
} from '@models/dataObjects/Cisco';
import { ChartColors } from '@models/index';

@Component({
    selector: 'dashlet-system-health-cisco-cube',
    templateUrl: 'dashlet-system-health-cisco-cube.component.html',
    styleUrls: ['dashlet-system-health-cisco-cube.component.scss']
})
export class DashletSystemHealthCiscoCUBEComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletSystemHealthCiscoCUBE;
    @ViewChild('occupancyMenu') occupancyMenu: ContextMenu;

    public networkInterfaceDrilldownData: any[];

    constructor(
        private dashletService: DashletService,
        private accountService: AccountService,
        private reportService: ReportService,
        private dashboardService: DashboardService,
        private store$: Store<AppState>
    ) {}

    openSubcontainer = '';
    openSubcontainerSeverity = 0;
    occupancyContextMenuItems: MenuItem[];

    public networkInterfaceTableData: CiscoInterfaceStatus[] = [];

    public voltageTableData: CiscoEnvVoltage[] = [];
    public tempTableData: CiscoEnvSupply[] = [];
    public supplyTableData: CiscoEnvTemp[] = [];
    public fanTableData: CiscoEnvFan[] = [];

    public chartColors: ChartColors = this.getChartColors();

    public transcodingTableData: CiscoInterfaceStatus[] = [];

    networkInterfaceColumns: ColumnTypes[] = [
        {
            columnDef: 'expand',
            header: ''
        },
        {
            columnDef: 'Description',
            header: 'Interface'
        },
        {
            columnDef: 'AdminStatus',
            header: 'Admin',
            cell: (row: CiscoInterfaceStatus) =>
                row.AdminStatus === 1 ? 'Up' : row.AdminStatus === 2 ? 'Down' : row.AdminStatus,
            type: 'numeric'
        },
        {
            columnDef: 'OperationalStatus',
            header: 'Oper',
            cell: (row: CiscoInterfaceStatus) =>
                row.OperationalStatus === 1 ? 'Up' : row.OperationalStatus === 2 ? 'Down' : row.OperationalStatus,
            type: 'numeric'
        },
        {
            columnDef: 'Speed',
            header: 'Speed (MB/s)',
            cell: (row: CiscoInterfaceStatus) => this.convertKBtoGB(row.Speed),
            type: 'numeric'
        },
        {
            columnDef: 'InUcastPkts',
            header: 'IUcast Pkts',
            cell: (row: CiscoInterfaceStatus) =>
                row.InUcastPkts.length > 7 ? row.InUcastPkts.substring(0, 6) + '..' : row.InUcastPkts,
            type: 'numeric'
        },
        {
            columnDef: 'OutUcastPkts',
            header: 'OUcast Pkts',
            cell: (row: CiscoInterfaceStatus) =>
                row.OutUcastPkts.toString().length > 7
                    ? row.OutUcastPkts.toString().substring(0, 6) + '..'
                    : row.OutUcastPkts,
            type: 'numeric'
        },
        {
            columnDef: 'LastChange',
            header: 'Last Event',
            cell: (row: CiscoInterfaceStatus) => this.convertTime(row.LastChange)
        }
    ];

    public networkInterfaceExpansionColumns: ColumnTypes[] = [
        {
            columnDef: 'PhysicalAddress',
            header: 'Address'
        },
        {
            columnDef: 'MTU',
            header: 'MTU'
        },
        {
            columnDef: 'InOctets',
            header: 'In Ocets'
        },
        {
            columnDef: 'OutOctets',
            header: 'Out Ocets'
        },
        {
            columnDef: 'InUnknownProtos',
            header: 'In Unknown'
        },
        {
            columnDef: 'OutDiscards',
            header: 'Out Discards'
        },
        {
            columnDef: 'OutErrors',
            header: 'Out Errors'
        }
    ];

    public voltageColumns: ColumnTypes[] = [
        {
            columnDef: 'State',
            header: 'State',
            cell: (row: CiscoEnvVoltage | CiscoEnvSupply | CiscoEnvTemp | CiscoEnvFan) =>
                this.getStateDescription(row.State)
        },
        {
            columnDef: 'StatusValue',
            header: 'Value'
        },
        {
            columnDef: 'StatusDescr',
            header: 'Description'
        },
        {
            columnDef: 'Threshold',
            header: 'Threshold'
        },
        {
            columnDef: 'LastShutdown',
            header: 'Last Shutdown',
            dataTooltip: (row: CiscoEnvVoltage | CiscoEnvSupply | CiscoEnvTemp | CiscoEnvFan) =>
                'Temperature when an emergency shutdown was last initiated'
        }
    ];

    public dialPeerColumns: ColumnTypes[] = [
        {
            columnDef: 'severity',
            header: '',
            cell: (row: CiscoDialPeer) => this.determineSeverity(row),
            type: 'severity'
        },
        {
            columnDef: 'expand',
            header: ''
        },
        {
            columnDef: 'Tag',
            header: 'Tag'
        },
        {
            columnDef: 'AdministrativeState',
            header: 'Admin'
        },
        {
            columnDef: 'OperationalState',
            header: 'Oper'
        },
        {
            columnDef: 'DestinationPattern',
            header: 'DEst-Pattern'
        },
        {
            columnDef: 'KeepAlive',
            header: 'Keep Alive'
        },
        {
            columnDef: 'SessionServerGroupTarget',
            header: `Sess-SER-GRP\\Sess-Target`
        }
    ];

    public dialPeerExpansionColumns: ColumnTypes[] = [
        {
            columnDef: 'Type',
            header: 'Type'
        },
        {
            columnDef: 'Prefix',
            header: 'Prefix'
        },
        {
            columnDef: 'Preference',
            header: 'Preference'
        },
        {
            columnDef: 'ModemPassThrough',
            header: 'Pass Through'
        },
        {
            columnDef: 'OutStat',
            header: 'Out Stat'
        },
        {
            columnDef: 'Port',
            header: 'Port'
        },
        {
            columnDef: 'VRF',
            header: 'VRF'
        }
    ];

    public transcodingColumns: ColumnTypes[] = [
        { columnDef: 'expand', header: '' },
        {
            columnDef: 'severity',
            header: '',
            cell: (row: CiscoTranscoding) => this.getTransDrilldownSev(row, row.SessionsUsed, row.SessionsTotal)
        },
        {
            columnDef: 'Id',
            header: 'Id'
        },
        {
            columnDef: 'Description',
            header: 'Description'
        },
        {
            columnDef: 'Admin',
            header: 'Admin'
        },
        {
            columnDef: 'Oper',
            header: 'Oper'
        },
        {
            columnDef: 'Application',
            header: 'Application'
        },
        {
            columnDef: 'SessionsUsed',
            header: 'Used'
        },
        {
            columnDef: 'SessionsTotal',
            header: 'Total'
        }
    ];

    public mtpColumns: ColumnTypes[] = [
        {
            columnDef: 'severity',
            header: '',
            cell: (row: CiscoMTP) => this.getMTPDrilldownSev(row, row.HWUsed, row.HWTotal, row.SWUsed, row.SWTotal)
        },
        {
            columnDef: 'Id',
            header: 'Id'
        },
        {
            columnDef: 'Description',
            header: 'Description'
        },
        {
            columnDef: 'Admin',
            header: 'Admin'
        },
        {
            columnDef: 'Oper',
            header: 'Oper'
        },
        {
            columnDef: 'Application',
            header: 'Application'
        },
        {
            columnDef: 'Service',
            header: 'Service'
        },
        {
            columnDef: 'HWUsed',
            header: 'HW Used'
        },
        {
            columnDef: 'HWTotal',
            header: 'HW Total'
        },
        {
            columnDef: 'SWUsed',
            header: 'SW Used'
        },
        {
            columnDef: 'SWTotal',
            header: 'SW Total'
        }
    ];

    private subscription: Subscription = new Subscription();

    public ngOnDestroy(): void {
        if (this.dashlet) this.dashlet.dispose();
        this.subscription.unsubscribe();
    }
    public ngOnInit(): void {
        // Logo
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
            if (logo) {
                this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
            }
        });

        // File System
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdFilesystem
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdFilesystem
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdFilesystem
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length && data[0].data) {
                        this.dashlet.processFileSystem(data);
                    }
                })
        );

        // Services
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdServicesUpDown
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdServicesUpDown
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdServicesUpDown
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length && data[0].data) {
                        this.dashlet.processServices(data);
                    }
                })
        );

        // Dail Peer
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdDailPeer
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdDailPeer
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdDailPeer
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length && data[0].data) {
                        this.dashlet.processDialPeer(data);
                    }
                })
        );

        // Call Legs
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdCallLegs
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdCallLegs
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdCallLegs
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length && data[0].data) {
                        this.dashlet.processCallLegs(data);
                    }
                })
        );

        // Logged In Users

        // Env Voltage
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdEnvVoltage
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdEnvVoltage
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdEnvVoltage
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length && data[0].data) {
                        this.dashlet.processEnvironmentVoltage(data);
                    }
                })
        );

        // Env Temp
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdEnvTemp
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdEnvTemp
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdEnvTemp
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length && data[0].data) {
                        this.dashlet.processEnvironmentTemp(data);
                    }
                })
        );

        // Env Fan
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdEnvFan
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdEnvFan
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdEnvFan
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length && data[0].data) {
                        this.dashlet.processEnvironmentFan(data);
                    }
                })
        );

        // Env Supply
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdEnvSupply
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdEnvSupply
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdEnvSupply
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length && data[0].data) {
                        this.dashlet.processEnvironmentSupply(data);
                    }
                })
        );

        // SIP 1XX
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdSipResp1xx
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdSipResp1xx
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdSipResp1xx
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length && data[0].data) {
                        this.dashlet.processSipData(data, 1);
                    }
                })
        );

        // SIP 2XX
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdSipResp2xx
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdSipResp2xx
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdSipResp2xx
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length && data[0].data) {
                        this.dashlet.processSipData(data, 2);
                    }
                })
        );

        // SIP 3XX
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdSipResp3xx
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdSipResp3xx
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdSipResp3xx
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length && data[0].data) {
                        this.dashlet.processSipData(data, 3);
                    }
                })
        );

        // SIP 4XX
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdSipResp4xx
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdSipResp4xx
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdSipResp4xx
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length && data[0].data) {
                        this.dashlet.processSipData(data, 4);
                    }
                })
        );

        // SIP 5XX
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdSipResp5xx
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdSipResp5xx
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdSipResp5xx
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length && data[0].data) {
                        this.dashlet.processSipData(data, 5);
                    }
                })
        );

        // SIP 6XX
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdSipResp6xx
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdSipResp6xx
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdSipResp6xx
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length && data[0].data) {
                        this.dashlet.processSipData(data, 6);
                    }
                })
        );

        // Trans Coding
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdDSPTranscoder
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdDSPTranscoder
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdDSPTranscoder
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length && data[0].data) {
                        this.dashlet.processTransCodingData(data);
                    }
                })
        );

        // MTP Data
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdDSPMTP
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdDSPMTP
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdDSPMTP
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length && data[0].data) {
                        this.dashlet.processMTPData(data);
                    }
                })
        );
    }

    public openAccessConcentrator(): void {
        const userDetail = this.accountService.getUserDetails();
        this.dashboardService.openAccessConcentrator(
            this.dashlet.customer.customerId,
            this.dashlet.equipment.equipmentId,
            userDetail
        );
    }

    public openProcessorReport(): void {
        this.reportService.openReport(this.reportService.osCpuReportId, this.dashlet.customer.customerId);
    }

    public openDiskReport(): void {
        this.reportService.openReport(this.reportService.osDiskReportId, this.dashlet.customer.customerId);
    }

    public openMemoryReport(): void {
        this.reportService.openReport(this.reportService.osMemoryReportId, this.dashlet.customer.customerId);
    }

    public getCapacityUnsubscribedText(): string {
        return this.dashletService.getCapacityUnsubscribedText();
    }

    public getAvailabilityUnsubscribedText(): string {
        return this.dashletService.getAvailabilityUnsubscribedText();
    }

    public openDetails(container: string, severity: number): void {
        this.openSubcontainer = container;
        this.dashlet.displayHeader = false;
        this.openSubcontainerSeverity = severity;
        if (container === 'network-interface-drilldown') {
            this.networkInterfaceTableData = this.getNetworkInterfaceData(this.openSubcontainerSeverity);
        } else if (container === 'Environment') {
            this.voltageTableData = this.getVoltageList(this.openSubcontainerSeverity);
            this.tempTableData = this.getTempList(this.openSubcontainerSeverity);
            this.supplyTableData = this.getSupplyList(this.openSubcontainerSeverity);
            this.fanTableData = this.getFanList(this.openSubcontainerSeverity);
        } else if (container === 'dsp-drilldown') {
            this.transcodingTableData = this.getNetworkInterfaceData(this.openSubcontainerSeverity);
        }
    }

    public closeDetails(): void {
        this.openSubcontainer = null;
        this.dashlet.displayHeader = true;
    }

    public getServicesUp(data: any[]): number {
        let servicesUp = 0;
        data.forEach(item => {
            if (item.OperationalStatus === '1') {
                servicesUp++;
            }
        });
        return servicesUp;
    }

    public getServicesAmber(data: any[]): number {
        let amber = 0;
        data.forEach(item => {
            if (item.AdminStatus === '2' && item.OperationalStatus === '2') {
                amber++;
            }
        });
        return amber;
    }

    public getServicesDown(data: any[]): number {
        let servicesDown = 0;
        data.forEach(item => {
            if (item.AdminStatus === '1' && item.OperationalStatus === '2') {
                servicesDown++;
            }
        });
        return servicesDown;
    }

    public determineSeverityNetworkInterface(data: any): string {
        let dataP = this.dashlet.getRowInformationNetworkInterface(data);
        if (this.getServicesUp(dataP) > 0) {
            return this.getSeverityColor(0); // green
        } else if (this.getServicesDown(dataP) > 0) {
            return this.getSeverityColor(2); // red
        }
    }

    public getSeverityColor(sev: number): string {
        return this.dashletService.getSeverityColor(sev);
    }

    public getEnvNormal(): number {
        return this.checkStatus('1');
    }

    public getEnvWarning(): number {
        return this.checkStatus('2');
    }

    public getEnvCritical(): number {
        return this.checkStatus('3');
    }

    public getEnvShutdown(): number {
        return this.checkStatus('4');
    }

    public getEnvNotFunc(): number {
        return this.checkStatus('5');
    }

    public getVoltageList(mode: number): CiscoEnvVoltage[] {
        let result = [];
        this.dashlet.envVoltage.forEach(item => {
            if (item.State === (mode + 1).toString()) {
                result.push(item);
            }
        });
        return result;
    }

    public getTempList(mode: number): CiscoEnvSupply[] {
        let result = [];
        this.dashlet.envSupply.forEach(item => {
            if (item.State === (mode + 1).toString()) {
                result.push(item);
            }
        });
        return result;
    }

    public getSupplyList(mode: number): CiscoEnvTemp[] {
        let result = [];
        this.dashlet.envTemp.forEach(item => {
            if (item.State === (mode + 1).toString()) {
                result.push(item);
            }
        });
        return result;
    }

    public getFanList(mode: number): CiscoEnvFan[] {
        let result = [];
        this.dashlet.envFan.forEach(item => {
            if (item.State === (mode + 1).toString()) {
                result.push(item);
            }
        });
        return result;
    }

    public checkStatus(mode: string): number {
        let count = 0;
        this.dashlet.envVoltage.forEach(item => {
            if (item.State === mode) {
                count++;
            }
        });

        this.dashlet.envSupply.forEach(item => {
            if (item.State === mode) {
                count++;
            }
        });

        this.dashlet.envTemp.forEach(item => {
            if (item.State === mode) {
                count++;
            }
        });

        this.dashlet.envFan.forEach(item => {
            if (item.State === mode) {
                count++;
            }
        });
        return count;
    }

    public checkIfEnvAvailable(): boolean {
        if (
            this.dashlet.envVoltage.length > 0 ||
            this.dashlet.envTemp.length > 0 ||
            this.dashlet.envSupply.length > 0 ||
            this.dashlet.envFan.length > 0
        ) {
            return false;
        }
        return true;
    }

    public getDPConfigured(data: any[]): number {
        let configured = 0;
        data.forEach(item => {
            if (item.Tag !== '') {
                configured++;
            }
        });
        return configured;
    }

    public getDPAdminState(data: any[]): number {
        let adminState = 0;
        data.forEach(item => {
            if (item.AdministrativeState === 'up') {
                adminState++;
            }
        });
        return adminState;
    }

    public getDPOperatingState(data: any[]): number {
        let operatingState = 0;
        data.forEach(item => {
            if (item.OperationalState === 'up') {
                operatingState++;
            }
        });
        return operatingState;
    }

    public getDPActive(data: any[]): number {
        let alive = 0;
        data.forEach(item => {
            if (item.KeepAlive === 'active') {
                alive++;
            }
        });
        return alive;
    }

    public getDPBusyout(data: any[]): number {
        let busy = 0;
        data.forEach(item => {
            if (item.KeepAlive === 'busyout') {
                busy++;
            }
        });
        return busy;
    }

    public getDpGreenCount(data: any[]): number {
        let green = 0;
        data.forEach(item => {
            if (
                item.KeepAlive === 'active' ||
                (item.OperationalState === 'up' && item.AdministrativeState === 'up' && item.KeepAlive !== 'busyout')
            ) {
                green++;
            }
        });
        return green;
    }

    public getDpAmberCount(data: any[]): number {
        let amber = 0;
        data.forEach(item => {
            if (
                item.OperationalState === 'down' &&
                item.AdministrativeState === 'down' &&
                item.KeepAlive !== 'active' &&
                item.KeepAlive !== 'busyout'
            ) {
                amber++;
            }
        });
        return amber;
    }

    public getDpRedCount(data: any[]): number {
        let red = 0;
        data.forEach(item => {
            if (
                item.KeepAlive === 'busyout' ||
                (item.OperationalState === 'down' && item.AdministrativeState === 'up' && item.KeepAlive !== 'active')
            ) {
                red++;
            }
        });
        return red;
    }

    public getTransDSPTotal(): string {
        return this.dashlet.dspInfo.TotalTrans;
    }

    public getMtpDSPTotal(): string {
        return this.dashlet.dspInfo.TotalMtp;
    }

    public getTransDSP(): string {
        return this.dashlet.dspInfo.UsedTrans;
    }

    public getMtpDSP(): string {
        return this.dashlet.dspInfo.UsedMtp;
    }

    public getTransDrilldownSev(data: any, usedSess: string, totalSess: string): string {
        if (data.Admin === 'down') {
            return this.dspDrillDownHelper(0.85, 1);
        }

        if (data.Admin === 'up' && (data.Oper !== 'active' || data.Oper !== 'done')) {
            return this.dspDrillDownHelper(0.95, 1);
        }

        const used = parseInt(usedSess);
        const total = parseInt(totalSess);
        return this.dspDrillDownHelper(used, total);
    }

    public getMTPDrilldownSev(
        data: any,
        usedSessHw: string,
        totalSessHw: string,
        usedSessSw: string,
        totalSessSw: string
    ): string {
        if (data.Admin === 'down') {
            return this.dspDrillDownHelper(0.85, 1);
        }

        if (data.Admin === 'up' && (data.Oper !== 'active' || data.Oper !== 'done')) {
            return this.dspDrillDownHelper(0.95, 1);
        }

        const used = parseInt(usedSessHw) + parseInt(usedSessSw);
        const total = parseInt(totalSessHw) + parseInt(totalSessSw);
        return this.dspDrillDownHelper(used, total);
    }

    private dspDrillDownHelper(used: number, total: number): string {
        if (total === 0 || used / total < 0.8) {
            return '5px solid #0A9B02';
        } else if (used / total >= 0.8 && used / total < 0.9) {
            return '5px solid #FFA500';
        } else if (used / total >= 0.9) {
            return '5px solid #DC3704';
        }
    }

    public getDSPColour(mode: number): string {
        // 1 Trans -- 2 Mtp

        let top = 0;
        let bottom = 0;
        let x = this.dashlet.dspInfo;

        switch (mode) {
            case 1:
                top = parseInt(x.UsedTrans);
                bottom = parseInt(x.TotalTrans);
                break;
            case 2:
                top = parseInt(x.UsedMtp);
                bottom = parseInt(x.TotalMtp);
                break;
        }

        if (bottom === 0 || top / bottom < 0.8) {
            return this.dashletService.getSeverityColor(0);
        } else if (top / bottom >= 0.8 && top / bottom < 0.9) {
            return this.dashletService.getSeverityColor(1);
        } else if (top / bottom >= 0.9) {
            return this.dashletService.getSeverityColor(3);
        }
    }

    public getRowInformation(data: any): any[] {
        let obj = [];
        obj.push({
            DialPeerHunt: data.DialPeerHunt,
            Tag: data.Tag,
            Type: data.Type,
            AdministrativeState: data.AdministrativeState,
            OperationalState: data.OperationalState,
            Prefix: data.Prefix,
            DestinationPattern: data.DestinationPattern,
            Preference: data.Preference,
            ModemPassThrough: data.ModemPassThrough,
            SessionServerGroupTarget: data.SessionServerGroupTarget,
            OutStat: data.OutStat,
            Port: data.Port,
            KeepAlive: data.KeepAlive,
            VRF: data.VRF
        });
        return obj;
    }

    public getNetworkInterfaceData(state: number): CiscoInterfaceStatus[] {
        let result = [];
        this.dashlet.interfaceStatus.forEach(item => {
            switch (state) {
                case 0:
                    if (parseInt(item.OperationalStatus.toString()) === 1) {
                        result.push({ ...item, Expansion: this.dashlet.getRowInformationNetworkInterface(item) });
                    }
                    break;
                case 1:
                    if (
                        parseInt(item.OperationalStatus.toString()) === 2 &&
                        parseInt(item.AdminStatus.toString()) === 2
                    ) {
                        result.push({ ...item, Expansion: this.dashlet.getRowInformationNetworkInterface(item) });
                    }
                    break;
                case 2:
                    if (
                        parseInt(item.OperationalStatus.toString()) === 2 &&
                        parseInt(item.AdminStatus.toString()) === 1
                    ) {
                        result.push({ ...item, Expansion: this.dashlet.getRowInformationNetworkInterface(item) });
                    }
                    break;
            }
        });
        return result;
    }

    public determineSeverity(data: any): number {
        let obj = [];
        obj = this.getRowInformation(data);
        if (this.getDpGreenCount(obj) > 0) {
            return 0;
        } else if (this.getDpAmberCount(obj) > 0) {
            return 1;
        } else if (this.getDpRedCount(obj) > 0) {
            return 2;
        } else {
            return null;
        }
    }

    public getEnvDrillDownTheme(state: number): string {
        switch (state) {
            case 0:
                return this.getSeverityColor(0);
            case 1:
                return this.getSeverityColor(1);
            case 2:
                return 'rgb(255,77,77)';
            case 3:
                return this.getSeverityColor(2);
            case 4:
                return 'rgb(122,122,122)';
            default:
                return 'rgb(122,122,122)';
        }
    }

    public getStateDescription(state: string): string {
        switch (state) {
            case '1':
                return 'Normal';
            case '2':
                return 'Warning';
            case '3':
                return 'Critical';
            case '4':
                return 'Shutdown';
            case '5':
                return 'Not Func';
            default:
                return 'Not Func';
        }
    }

    public getSupplyDescription(state: string): string {
        switch (state) {
            case '1':
                return 'Unknown';
            case '2':
                return 'AC';
            case '3':
                return 'DC';
            case '4':
                return 'ExternalPowerSupply';
            case '5':
                return 'InternalRedundant';
            default:
                return 'Unknown';
        }
    }
    private getChartColors(): ChartColors {
        return this.dashletService.getChartColors();
    }
    public getRoundedSipDisc(data: number): string {
        let result = '';
        if (data < 1000) {
            result = data.toString();
        } else if (data >= 1000 && data <= 1000000) {
            result = (data / 1000).toFixed(1) + 'K';
        } else {
            result = (data / 1000000).toFixed(2) + 'M';
        }
        return result;
    }

    public convertKBtoGB(data): string {
        let truncated = null;
        truncated = Number(data) / 1000000; // b to MB
        return truncated.toFixed(0).toString();
    }

    public convertTime(row): string {
        let result: string = '';
        let dataIn = Number(row);
        let ms: number = dataIn; // 100th to ms
        var day, hour, minute, seconds;
        seconds = Math.floor(ms / 100);
        minute = Math.floor(seconds / 60);
        seconds = seconds % 60;
        hour = Math.floor(minute / 60);
        minute = minute % 60;
        day = Math.floor(hour / 24);
        hour = hour % 24;
        if (seconds > 0) result = seconds + ' s';
        if (minute > 0) result = minute + ' mins';
        if (hour > 0) result = hour + ' hrs, ';
        if (day > 0) result = day + ' days, ';
        if (result.length === 0 && ms > 0) {
            result = '<1 s';
        } else if (result.length === 0) {
            result = '---';
        }
        return result;
    }
}
