import { Component, Injector, ElementRef } from '@angular/core';

import { DashletSettingText } from '@models/index';

@Component({
    selector: 'dashlet-setting-text',
    templateUrl: 'dashlet-setting-text.component.html',
    styleUrls: ['./settings.component.scss']
})
export class DashletSettingTextComponent {
    data: DashletSettingText | null = null;

    constructor(private injector: Injector) {
        this.data = this.injector.get('data');
    }

    onModelChange(): void {
        if (this.data) {
            const isValid = this.validateInput();
            if (isValid) {
                this.data.change.next();
            }
        }
    }

    validateInput(): boolean {
        if (this.data) {
            if (this.data.maxLength !== undefined && this.data.value.length > this.data.maxLength) {
                return false;
            } else {
                return true;
            }
        }
        return false;
    }
}
