import { Subject } from 'rxjs';

export class SubscriptionSubject {
	key: string;
	subject: Subject<any>;
	counter: number;

	constructor(key: any) {
		this.key = key;
		this.subject = new Subject<any>();
		this.counter = 0;
	}
}
