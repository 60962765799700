import {
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingList,
    DashletSettingDropdownGroupedList,
    DashletSettingText
} from '@models/index';
import { AccountService, DashletSettingsService } from '@services/index';
import { take } from 'rxjs';

export class DashletSettingsFormSIPResponseSummary extends DashletSettingsForm {
    private customer!: DashletSettingDropdown;
    private location!: DashletSettingDropdown;
    private rtcpReceiver!: DashletSettingDropdownGroupedList;

    private isCust!: boolean;
    private customerId!: string;

    constructor(
        private readonly settingsService: DashletSettingsService,
        private readonly accountService: AccountService
    ) {
        super();

        this.init();
        this.loadCustomers();
    }

    public loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);

        if (this.customer.value) {
            this.loadLocations(this.customer.value);
        }
        if (this.location.value) {
            this.loadRtcpReceivers(this.location.value);
        }
    }

    private init(): void {
        const group = new DashletSettingsGroup();
        const s: any = (group.settings = []);

        const user = this.accountService.getUserDetails();
        this.isCust = user.EntityGroup === 2;
        this.customerId = user.EntityId;

        const customer = (this.customer = new DashletSettingDropdown());
        customer.label = 'Customer';
        customer.stateKey = 'customer';
        customer.disabled = true;
        customer.change.subscribe(() => {
            if (!this.location.value) this.clearAndDisable(this.location);
            if (!this.rtcpReceiver.values) this.clearAndDisable(this.rtcpReceiver);
            this.location.items = [];
            this.rtcpReceiver.items = [];
            this.loadLocations(customer.value!);
        });
        s.push(customer);

        const location = (this.location = new DashletSettingDropdown());
        location.label = 'Location';
        location.stateKey = 'location';
        location.disabled = true;
        location.change.subscribe(() => {
            this.rtcpReceiver.items = [];
            this.clearAndDisable(this.rtcpReceiver);

            this.loadRtcpReceivers(location.value!);
        });
        s.push(location);

        const rtcpReceiver = (this.rtcpReceiver = new DashletSettingDropdownGroupedList());
        rtcpReceiver.label = 'Source Equipment';
        rtcpReceiver.stateKey = 'equipment';
        rtcpReceiver.disabled = true;
        rtcpReceiver.limitSelection = 5;
        s.push(rtcpReceiver);

        const customName = new DashletSettingText(40);
        customName.label = 'Custom Dashlet Name';
        customName.stateKey = 'customName';
        s.push(customName);

        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);
        this.settingsService.getUserAssociatedCustomers().subscribe(
            customers => {
                this.customer.items = customers;
                this.customer.loadingComplete();
            },
            () => {
                this.customer.loadingComplete();
                this.customer.disabled = true;
                // show error notification here
            }
        );
    }

    private loadLocations(customer: string): void {
        this.enableAndLoading(this.location);
        this.settingsService.getLocations(customer).subscribe(
            locations => {
                this.location.items = locations;
                this.location.loadingComplete();
            },
            () => {
                this.location.loadingComplete();
                this.location.disabled = true;
                // show error notification here
            }
        );
    }

    private loadRtcpReceivers(locationId: string): void {
        this.enableAndLoading(this.rtcpReceiver);

        this.settingsService
            .getSipEquipment(locationId)
            .pipe(take(1))
            .subscribe(
                receivers => {
                    this.rtcpReceiver.items = [...receivers];
                    this.rtcpReceiver.loadingComplete();
                },
                () => {
                    this.rtcpReceiver.loadingComplete();
                    this.rtcpReceiver.disabled = true;
                }
            );
    }

    private clearAndDisable(
        widget: DashletSettingDropdownGroupedList | DashletSettingDropdown | DashletSettingList<string>
    ) {
        widget.disabled = true;
        widget.items = [];
        if (widget instanceof DashletSettingList) {
            widget.values = [];
        } else if (widget instanceof DashletSettingDropdownGroupedList) {
            widget.values = [];
        } else {
            widget.value = null;
        }
    }

    private enableAndLoading(
        widget: DashletSettingDropdownGroupedList | DashletSettingDropdown | DashletSettingList<string>
    ) {
        widget.disabled = false;
        widget.loadingBegin();
    }
}
