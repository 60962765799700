/* eslint-disable no-empty */
/* eslint-disable no-undef */
import {
    ACMAESServer,
    Customer,
    Dashlet,
    DMCCData,
    Equipment,
    LicenceData,
    Link,
    Location,
    SwitchLink,
    Threshold,
    TSAPIData,
    TSAPITSDIBuffer
} from '@models/index';
import { TimeoutService } from '@services/index';
import { Subject } from 'rxjs';

export class DashletSystemHealthAES extends Dashlet {
    public readonly commandTypeIdServiceStatus = '1A0CF50D-6AE8-4C78-9DF4-E0D1C7434528';
    public readonly commandTypeIdLicensingDMCC = '28ADD0A8-05CF-4116-9490-32E0ACA490B0';
    public readonly commandTypeIdLicensingTSAPI = '8358892C-2368-44C1-B2C5-0E267E2D0614';
    public readonly commandTypeIdDMCCStatus = 'EF4B5B10-5B2B-4E19-8974-C1C4059DBD8E';
    public readonly commandTypeIdTSAPITSDI = 'D4FADE87-38CB-45EC-9AAD-A08B37D36788';
    public readonly commandTypeIdTSAPISwitchLink = '3531254B-70D4-4EB9-B6B1-BCD95B332386';
    public readonly commandTypeIdACMDisplayIPServices = '15E08A7C-E4A1-4F36-B8AE-B77C70FBAF00';
    public readonly commandTypeIdACMStatusAESvcsCTI = '43529D03-78AE-408F-B1F1-C4A1B637AE40';
    public readonly commandTypeIdACMStatusAESvcsLink = 'D84C94FE-0111-4EDB-9E19-20184BA5AAFA';

    readonly interval = 30;
    customer!: Customer;
    location!: Location;
    equipment!: Equipment;
    serverName!: string;
    allACMs: string[] = [];
    acmEquipId!: string;

    connectionStatus!: ACMAESServer | null;
    dmccData!: DMCCData;
    tsapiData!: TSAPIData;
    aesServers!: ACMAESServer[];
    hostname!: string | null;
    uptimeMs!: number | null;
    loggedInUsers!: number;

    showSystemChart = false;
    showNetworkGraph = false;
    showCallRate = false;
    showServiceStatus = false;
    showLicences = false;
    showAESConnections = false;
    groupName!: string;
    serverEquipmentName!: string;

    showCustomScripts = false;

    commandsRunData: any = [];
    commandsRunDataLoading = true;

    thresholds: Threshold[] = [];

    public chartDataUpdated: Subject<null> = new Subject();
    get onChartDataUpdated() {
        return this.chartDataUpdated;
    }

    constructor(private timeoutService: TimeoutService) {
        super();
        // sizing
        this.sizes = [
            {
                id: 0,
                label: 'Standard',
                cols: 5,
                rows: 6
            }
        ];
        this.applySize(0);

        // init data
        this.resetData();
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        // read settings object
        this.configured = v.customer && v.location && v.equipment;
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        if (v.location) {
            this.location = new Location(v.location.value, v.location.label);
        } else {
            this.location = new Location('', '');
        }
        if (v.equipment) {
            this.equipment = new Equipment(v.equipment.value, v.equipment.label);
            this.serverEquipmentName = this.equipment.equipmentName;
        } else {
            this.equipment = new Equipment('', '');
        }
        this.generatedNameTag = this.configured ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;

        this.showSystemChart = v.showSystemChart || false;
        this.showNetworkGraph = v.showNetworkGraph || false;
        this.showServiceStatus = v.showServiceStatus || false;
        this.showLicences = v.showLicences || false;
        this.showAESConnections = v.showAESConnections || false;
        this.showCustomScripts = v.showCustomScripts || false;

        // apply size
        let h = 0;
        if (this.showSystemChart) {
            h += 1.9;
        }
        if (this.showNetworkGraph) {
            h += 2.1;
        }
        if (this.showServiceStatus) {
            h += 1.2;
        }
        if (this.showLicences) {
            h += 1.2;
        }
        if (this.showAESConnections) {
            h += 1.2;
        }
        if (this.showCustomScripts) h += 4;
        this.applySizeExpansion(0, h);
    }

    resetData() {
        this.dmccData = new DMCCData();
        this.tsapiData = new TSAPIData();
        this.aesServers = [];
        this.connectionStatus = null;
        this.chartDataUpdated.next(null);
    }

    dispose() {}

    processCTILinkStatus(data: any[]) {
        if (data && data.length) {
            for (const row of data[0].data) {
                if (row.AEServicesServer) {
                    if (this.serverName.toLowerCase() === row.AEServicesServer.toLowerCase()) {
                        this.connectionStatus!.addCTI(
                            'Link ' + row.CTILink,
                            row.ServiceState.charAt(0).toUpperCase() + row.ServiceState.slice(1)
                        );
                    }
                }
            }
        }
    }

    processServiceLinks(data: any[]) {
        if (data && data.length) {
            for (const row of data[0].data) {
                if (row.AEServicesServer) {
                    if (this.serverName.toLowerCase() === row.AEServicesServer.toLowerCase()) {
                        this.connectionStatus!.addLink(
                            new Link(row.LinkNumber, row.MessagesSent, row.MessagesReceived)
                        );
                    }
                }
            }
        }
    }

    processTSAPITSDI(data: any[]) {
        if (data && data.length) {
            const row = data[0].data[0];
            if (row !== null) {
                const tsapiTSDI = new TSAPITSDIBuffer(
                    row.TlinkName,
                    parseInt(row.BufferSpaceAllocated),
                    parseInt(row.BufferSize)
                );
                this.tsapiData.updateTSAPITSDIBuffers(tsapiTSDI);
            }
        }
    }

    processTSAPISwitchLink(data: any[]) {
        for (const row of data[0].data) {
            if (row !== null) {
                const switchLink = new SwitchLink(row.SessionSwitchName, row.Status, row.Since);
                this.tsapiData.updateSwitchLinks(switchLink);
            }
        }
    }

    processDMCCStatus(data: any[]) {
        const row = data[0].data[0];
        if (row !== null) {
            this.dmccData.update(row.MaxMonitors, row.UsedMonitors, row.ActiveSessions, row.ActiveDevices);
        }
    }

    processDMCCLicensingStatus(data: any[]) {
        const row = data[0].data;
        if (row !== null) {
            row.forEach((e: any) => {
                if (parseInt(e.Total) > 0) {
                    this.dmccData.updateLicenses(new LicenceData(e.Name, parseInt(e.Acquired), parseInt(e.Total)));
                }
            });
        }
    }

    processTSAPILicensingStatus(data: any[]) {
        const row = data[0].data;
        if (row !== null) {
            row.forEach((e: any) => {
                if (parseInt(e.Total) > 0) {
                    this.tsapiData.updateLicenses(new LicenceData(e.Name, parseInt(e.Acquired), parseInt(e.Total)));
                }
            });
        }
    }

    apiWarningMessage() {
        let commandId: string = '';
        if (this.dmccData.dataExpired) {
            commandId = '28add0a8-05cf-4116-9490-32e0aca490b0';
        } else if (this.tsapiData.buffersDataExpired) {
            commandId = 'd4fade87-38cb-45ec-9aad-a08b37d36788';
        } else if (this.tsapiData.switchLinksDataExpired) {
            commandId = '3531254b-70d4-4eb9-b6b1-bcd95b332386';
        } else if (this.tsapiData.licensesDataExpired) {
            commandId = '8358892c-2368-44c1-b2c5-0e267e2d0614';
        }
        const lastTime = this.timeoutService.getDataExpiredTime(commandId, this.equipment.equipmentId);
        return 'No updates received since ' + lastTime + '. Showing last known license state.';
    }
}
