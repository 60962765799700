/* eslint-disable no-undef */
import * as Actions from '@actions/dashboard/dashboard.actions';
import { ActivatedRoute } from '@angular/router';
import { AppState, selectDashletMeta } from '@reducers/index';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { Component, OnDestroy, OnInit, HostListener, ViewChild, TemplateRef } from '@angular/core';
import { Dashboard, DashletMeta, Dashlet, DashboardLocalStorage } from '@models/index';
import { DashletFactoryService, DashboardService, AuthenticationService, NotificationService } from '@services/index';
import { distinctUntilChanged, skipWhile, takeUntil, take } from 'rxjs/operators';
import { RealtimeGatewayService } from '@services/index';
import { select, Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { TileGridComponent } from '../tile-grid/tile-grid.component';
import { map } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    animations: [
        trigger('hideToolbar', [
            state('hidden', style({ marginTop: '-3rem', height: 'calc(100vh + 3rem' })),
            state('shown', style({ marginTop: '0', height: '100vh' })),
            transition('hidden <=> shown', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ]),
        trigger('rotateButton', [
            state('up', style({ transform: 'rotate(0)' })),
            state('down', style({ transform: 'rotate(180deg)' })),
            transition('up <=> down', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ]),
        trigger('slideRight', [
            state('right', style({ transform: 'translateX(5rem)' })),
            state('default', style({ transform: 'translateX(0)' })),
            transition('right <=> default', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ]),
        trigger('slideUp', [
            state('up', style({ height: '0' })),
            state('default', style({ height: '*' })),
            transition('up <=> default', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class DashboardComponent implements OnInit, OnDestroy {
    @ViewChild(TileGridComponent) grid: TileGridComponent;
    private idlerTicks = 0;
    private onDestroy$: Subject<void> = new Subject<void>();
    private isConnectedSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
    public lastConnected: BehaviorSubject<Date> = new BehaviorSubject(new Date());
    public loading: boolean = false;
    public removalAlertOpen: boolean = false;
    public dashlets: Dashlet[] = [];
    private showCreateDashletDialog = false;
    private showSettingsDialog = false;
    public dashletToBeRemoved!: Dashlet;
    public selectedDashlet!: Dashlet;
    public isUnloading = false;
    public canWrite!: Observable<boolean>;
    private entityId: string;
    public bpLogo$: Observable<SafeResourceUrl>;

    public fullscreen: boolean;

    @HostListener('window:beforeunload')
    unload() {
        this.isUnloading = true;
    }
    private userId: string;

    public dashletsMeta: DashletMeta[] = [];
    private dashboardId: string;
    private localStorage: DashboardLocalStorage;

    constructor(
        private sanitizer: DomSanitizer,
        private realtimeService: RealtimeGatewayService,
        private route: ActivatedRoute,
        private store$: Store<AppState>,
        private dashletFactoryService: DashletFactoryService,
        private dashboardService: DashboardService,
        public settingsDialog: MatDialog,
        public addDashletDialog: MatDialog,
        public deleteDashletDialog: MatDialog,
        private authenticationService: AuthenticationService,
        private notificationService: NotificationService
    ) {
        this.loading = true;
        this.removalAlertOpen = false;
        this.canWrite = this.dashboardService.canWrite;

        const lastConnected = (this.lastConnected = new BehaviorSubject(new Date()));

        realtimeService.isConnectedSubject
            .pipe(
                skipWhile(() => this.loading),
                takeUntil(this.onDestroy$),
                distinctUntilChanged()
            )
            .subscribe((status: boolean) => {
                this.isConnectedSubject.next(status);
                if (!status) {
                    lastConnected.next(new Date());
                }
            });
    }

    public async ngOnInit(): Promise<void> {
        this.localStorage = JSON.parse(localStorage.getItem('dashboard')) || {
            fullscreen: {}
        };
        
        this.store$.dispatch(Actions.GetDashletsMeta());

        this.route.paramMap.subscribe(params => {
            this.dashboardId = params.get('id') || '';

            this.store$
                .pipe(
                    select(state => state.dashboard.userContext),
                    takeUntil(this.onDestroy$)
                )
                .subscribe((data: any) => {
                    if (data) {
                        this.userId = data.sub;
                        this.entityId = data.EntityId;

                        this.fullscreen = this.localStorage?.fullscreen[`${this.userId}`] || true;
                        this.store$
                            .pipe(select(selectDashletMeta), takeUntil(this.onDestroy$))
                            .subscribe((data: any) => {
                                if (data.length) {
                                    this.dashletsMeta = [...data];
                                    this.store$.dispatch(
                                        Actions.LoadDashboard({ userId: data.sub, id: this.dashboardId })
                                    );
                                }
                            });

                        this.bpLogo$ = this.store$.pipe(
                            select(state => state.branding.businessPartnerLogo),
                            map(logo => {
                                if (logo) {
                                    return this.sanitizer.bypassSecurityTrustResourceUrl(
                                        'data:' + logo.mimeType + ';base64,' + logo.image
                                    );
                                }
                            })
                        );
                    }
                });

            this.store$
                .pipe(
                    select(state => state.dashboard.dashlet),
                    takeUntil(this.onDestroy$)
                )
                .subscribe((data: Dashlet[]) => {
                    this.dashlets = data.map((dashlet: Dashlet) => {
                        let dash = this.dashletFactoryService.createNewDashlet(
                            dashlet.id,
                            dashlet.type,
                            this.dashletsMeta
                        );
                        dash.title = dashlet.title;
                        dash.selected = dashlet.selected;
                        dash.applySettings({});
                        const size = dash.getTotalSize();
                        dash.tile = {
                            id: dashlet.id,
                            cols: size.w,
                            rows: size.h,
                            y: -1,
                            x: -1,
                            dragEnabled: true
                        };

                        if (dashlet.serialized) {
                            dash.loadState(dashlet.serialized);
                        }
                        return dash;
                    });
                    this.dashboardService.setDashlets(this.dashlets || []);
                });

            this.store$
                .pipe(
                    select(state => state.dashboard.dashboard),
                    takeUntil(this.onDestroy$)
                )
                .subscribe((data: any) => {
                    if (data) {
                        this.loading = false;
                        const dash = this.mapDashboard(data);
                        dash.isEditable = data.ownerId === this.userId;
                        this.dashboardService.setDashboard(dash);
                        this.dashboardService.writableSubject.next(data.ownerId === this.userId);
                    }
                });
        });

        timer(0, 1000)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(t => {
                this.idlerTicks += 1;
            });
    }

    public ngOnDestroy(): void {
        this.onDestroy$?.next();
        this.onDestroy$?.complete();
        this.realtimeService?.cleanup();
    }

    @HostListener('wheel')
    @HostListener('mousemove')
    public onActivity() {
        this.idlerTicks = 0;
    }

    public isIdle(): boolean {
        return this.idlerTicks > 4 && !this.showCreateDashletDialog && !this.showSettingsDialog;
    }

    public persist(): void {
        this.store$.dispatch(Actions.UpdateDashlets());
    }

    public updateDashletState() {
        this.store$.dispatch(Actions.UpdateDashboardState());
    }

    public removeDashlet(dashlet: Dashlet, deleteDialogTemplate: TemplateRef<any>) {
        this.dashletToBeRemoved = dashlet;
        this.deleteDashletDialog.open(deleteDialogTemplate);
    }

    public showDashletSettings(dashlet: Dashlet, settingsDialogTemplate: TemplateRef<any>): void {
        this.selectedDashlet = dashlet;
        this.toggleSettingsDialog(settingsDialogTemplate);
    }

    public toggleCreateDashletDialog(addDialogTemplate: TemplateRef<any>): void {
        this.addDashletDialog.open(addDialogTemplate);
    }

    public toggleSettingsDialog(settingsDialogTemplate: TemplateRef<any>): void {
        this.addDashletDialog.open(settingsDialogTemplate);
    }

    public deleteDashlet(): void {
        if (this.selectedDashlet && this.dashletToBeRemoved && this.dashletToBeRemoved.id === this.selectedDashlet.id) {
            this.selectedDashlet = null;
        }
        this.dashboardService.removeDashlet(this.dashletToBeRemoved);
        this.dashlets = this.dashlets.filter(d => d.id !== this.dashletToBeRemoved.id);
        this.dashboardService.setDashlets(this.dashlets || []);
        this.updateDashletState();
    }

    public cancelRemove(): void {
        this.removalAlertOpen = false;
    }

    private mapDashboard(d: { [key: string]: any }): Dashboard {
        const dashlet = new Dashboard();
        dashlet.id = d.id;
        dashlet.active = d.active;
        dashlet.name = d.name;
        dashlet.ownerId = d.ownerId;
        dashlet.isShared = d.isShared;
        dashlet.description = d.description;
        dashlet.ownerName = d.ownerName;
        dashlet.autoReposition = d?.state?.autoReposition || false;
        dashlet.autoStart = d.autoStart;
        dashlet.entityId = d.entityId;
        return dashlet;
    }

    public addItem(dashletMeta: DashletMeta): void {
        const dashlet = this.dashletFactoryService.createDashlet(dashletMeta);
        const spaceAvailable = this.grid.canFit(dashlet.getSize());
        if (spaceAvailable) {
            this.dashlets = [...this.dashlets, dashlet];
            this.dashboardService.setDashlets(this.dashlets || []);
        } else {
            this.notificationService.notify('Dashlet Not Added', 'error', 'Not enough space');
        }
    }

    public logout(): void {
        this.authenticationService.logout();
    }

    public manageUser(): void {
        window.open(
            `${environment.issuer}/account/manage/editsingleuser?userid=${this.userId}&groupId=${this.entityId}`,
            '_blank'
        );
    }

    public toggleFullscreen(): void {
        this.fullscreen = !this.fullscreen;
        this.localStorage.fullscreen[`${this.userId}`] = this.fullscreen;
        localStorage.setItem(`dashboard`, JSON.stringify(this.localStorage));
    }

    public navigateToWebPortal(): void {
        window.open(`${environment.webPortalUrl}`, '_blank');
    }

    get isConnected(): Observable<boolean> {
        return this.isConnectedSubject.asObservable();
    }
}
