<div class="flex-column gap-xl">
    <div class="flex-column" *ngIf="group!.settings">
        <ng-container *ngFor="let s of group!.settings">
			
            <dashlet-setting [setting]="s" (settingChange)="onSettingChange(s)"></dashlet-setting>
        </ng-container>
    </div>
    <div *ngIf="group!.groups">
        <ng-container *ngFor="let g of group!.groups">
            <dashlet-section-header *ngIf="g.title">{{ g.title }}</dashlet-section-header>
            <dashlet-settings-group [group]="g" (settingChange)="onSettingChange($event)"></dashlet-settings-group>
        </ng-container>
    </div>
</div>
