import { DashletService } from '@services/index';

export class IPNRBandwidthData {
    private _IPNRName!: string;
    private _IPNRNumber!: number;
    private _branches!: number;
    private _streams!: number;
    private _capacity!: number;
    private _mosgood!: number;
    private _moswarning!: number;
    private _mosdanger!: number;
    private _transmitted: number[] = [];
    private _received: number[] = [];
    private _peakUtil!: number;
    private _childnodes: IPNRBandwidthData[] = [];

    graphData: any;

    constructor(private dashletService: DashletService) {}

    public add(newIPNR: IPNRBandwidthData) {
        this.streams += newIPNR.streams;
        this.mosgood += newIPNR.mosgood;
        this.moswarning += newIPNR.moswarning;
        this.mosdanger += newIPNR.mosdanger;
        this.transmitted.push(...newIPNR.transmitted);
        this.received.push(...newIPNR.received);
        this._peakUtil = this.getPeakUtil();
    }

    public update(
        IPNRName: string,
        IPNRNumber: number,
        branches: number,
        streams: number,
        capacity: number,
        mosgood: number,
        moswarning: number,
        mosdanger: number,
        transmitted: number[],
        received: number[],
        peakUtil: number,
        childnodes: IPNRBandwidthData[]
    ) {
        this._IPNRName = IPNRName;
        this._IPNRNumber = IPNRNumber;
        this._branches = branches;
        this._streams = streams;
        this._capacity = capacity;
        this._mosgood = mosgood;
        this._moswarning = moswarning;
        this._mosdanger = mosdanger;
        this._transmitted = transmitted;
        this._received = received;
        this._peakUtil = peakUtil;
        this._childnodes = childnodes;
    }

    public get IPNRName(): string {
        return this._IPNRName;
    }

    public get IPNRNumber(): number {
        return this._IPNRNumber;
    }

    public get branches(): number {
        return this._branches;
    }

    public get streams(): number {
        return this._streams;
    }

    public get capacity(): number {
        return this._capacity;
    }

    public get mosgood(): number {
        return this._mosgood;
    }

    public get moswarning(): number {
        return this._moswarning;
    }

    public get mosdanger(): number {
        return this._mosdanger;
    }

    public get transmitted(): number[] {
        return this._transmitted;
    }

    public get received(): number[] {
        return this._received;
    }

    public get childnodes(): IPNRBandwidthData[] {
        return this._childnodes;
    }

    public get peakUtil(): number {
        return this._peakUtil;
    }

    public set IPNRName(value: string) {
        this._IPNRName = value;
    }

    public set IPNRNumber(value: number) {
        this._IPNRNumber = value;
    }

    public set branches(value: number) {
        this._branches = value;
    }

    public set streams(value: number) {
        this._streams = value;
    }

    public set capacity(value: number) {
        this._capacity = value;
    }

    public set mosgood(value: number) {
        this._mosgood = value;
    }

    public set moswarning(value: number) {
        this._moswarning = value;
    }

    public set mosdanger(value: number) {
        this._mosdanger = value;
    }

    public set transmitted(value: number[]) {
        this._transmitted = value;
        this._peakUtil = this.getPeakUtil();
    }

    public set received(value: number[]) {
        this._received = value;
        this._peakUtil = this.getPeakUtil();
    }

    public set childnodes(value: IPNRBandwidthData[]) {
        this._childnodes = value;
    }

    public getPeakUtil(): number {
        const maxTransmitted = Math.max.apply(Math, this.transmitted);
        const maxReceived = Math.max.apply(Math, this.received);
        return Math.max(maxTransmitted, maxReceived);
    }

    getTRGraphData(): any {
        return {
            labels: new Array(this.transmitted.length).fill(''),
            datasets: [
                {
                    borderColor: '#000FB2',
                    backgroundColor: '#000FB2',
                    borderWidth: 2,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    fill: false,
                    lineTension: 0,
                    label: 'Transmitted',
                    data: this.transmitted
                },
                {
                    borderColor: this.dashletService.getChartColors().blue,
                    backgroundColor: this.dashletService.getChartColors().blue,
                    borderWidth: 2,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    fill: false,
                    lineTension: 0,
                    label: 'Received',
                    data: this.received
                }
            ]
        };
    }
}
