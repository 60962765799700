/* eslint-disable @angular-eslint/component-selector */
import { Component, Input, Output, EventEmitter } from '@angular/core';

import { DashletSettingsGroup, DashletSetting } from '@models/index';

@Component({
    selector: 'dashlet-settings-group',
    templateUrl: 'dashlet-settings-group.component.html',
    styleUrls: ['dashlet-settings-group.component.scss']
})
export class DashletSettingsGroupComponent {
    @Input() group: DashletSettingsGroup | null = null;
    @Output() settingChange: EventEmitter<DashletSetting> = new EventEmitter();

    onSettingChange(setting: DashletSetting): void {
        this.settingChange.emit(setting);
    }
}
