export class BusinessPartner {
	Id!: string;
	PartnerId!: string;
	Name!: string;
	VirsaeAccountNumber!: string;
	VirsaeContractNumber!: string;
	TimeZoneName!: string;
	PrimaryContactName!: string;
	IsServicePartner!: boolean;
	ServicePartnerName!: string;
}
