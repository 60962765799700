import { createAction, props } from '@ngrx/store';

export const GetHistoricDiskUsageData = createAction(
  '[HistoricDiskUsageData] Get HistoricDiskUsageDatas',
  props<{ equipmentId: string, commandTypeId: string, from?: Date | string, to?: Date | string, max?: number | null }>()
);

export const GetHistoricDiskUsageDataSuccess = createAction(
  '[HistoricDiskUsageData] Get HistoricDiskUsageDatas Success',
  props<{ data:any }>()
);

export const GetHistoricDiskUsageDataFailure = createAction(
  '[HistoricDiskUsageData] Get HistoricDiskUsageDatas Failure',
  props<{ error: any }>()
);
