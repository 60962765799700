import { Component, Injector, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { DashletSettingDropdownGroupedList } from '@models/settings/dashletSettingDropdownGroupedList.model';

@Component({
    selector: 'app-dashlet-setting-dropdown-grouped-list',
    templateUrl: './dashlet-setting-dropdown-grouped-list.component.html',
    styleUrls: ['./settings.component.scss']
})
export class DashletSettingDropdownGroupedListComponent {
    @ViewChild('select') select: MatSelect;
    public data: DashletSettingDropdownGroupedList | null = null;

    constructor(private injector: Injector) {
        this.data = this.injector.get('data');
    }

    public togglePerOne(): void {
        let newStatus = true;
        this.select.options.filter((item: MatOption) => {
            if (!item.selected) {
                newStatus = false;
            } else {
                return true;
            }
        });
    }

    public selectionChanged({ value }: any): void {
        if (this.data.limitSelection && value.length >= this.data.limitSelection) {
            this.data.values = value.slice(0, this.data.limitSelection);
        } else {
            this.data.values = value;
        }
    }

    public openedChange(opened: boolean): void {
        if (!opened) {
            this.data.values = this.select.options.filter((item: MatOption) => item.selected).map(item => item.value);
            this.data.change.next(null);
        }
    }
}
