import {
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingList,
    DashletSettingCheckbox
} from '@models/index';
import { AccountService, DashletSettingsService } from '@services/index';
import { take } from 'rxjs/operators';

export class DashletSettingsFormSystemHealthAudioCodes extends DashletSettingsForm {
    private customer!: DashletSettingDropdown;
    private location!: DashletSettingDropdown;
    private equipment!: DashletSettingDropdown;

    constructor(
        private readonly settingsService: DashletSettingsService,
        private readonly accountService: AccountService
    ) {
        super();
        this.init();
        this.loadCustomers();
    }

    public loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);
        if (this.customer.value) this.loadLocations(this.customer.value);
        if (this.location.value) this.loadequipments(this.location.value);
    }

    private init(): void {
        const equipmentGroup = new DashletSettingsGroup();
        const s: any = (equipmentGroup.settings = []);

        const customer = (this.customer = new DashletSettingDropdown());
        customer.label = 'Customer';
        customer.stateKey = 'customer';
        customer.disabled = true;
        customer.change.subscribe(() => {
            if (!this.location.value) this.clearAndDisable(this.location);
            if (!this.equipment.value) this.clearAndDisable(this.equipment);
            this.loadLocations(customer.value!);
        });
        s.push(customer);

        const location = (this.location = new DashletSettingDropdown());
        location.label = 'Location';
        location.stateKey = 'location';
        location.disabled = true;
        location.change.subscribe(() => {
            if (!this.equipment.value) this.clearAndDisable(this.equipment);
            this.loadequipments(location.value!);
        });
        s.push(location);

        const equipment = (this.equipment = new DashletSettingDropdown());
        equipment.label = 'Equipment';
        equipment.stateKey = 'equipment';
        equipment.disabled = true;
        s.push(equipment);

        // Layout section
        const layoutGroup = new DashletSettingsGroup();
        layoutGroup.title = 'Layout';
        const layoutSettings: any = (layoutGroup.settings = []);

        const showSystemChart = new DashletSettingCheckbox();
        showSystemChart.label = 'Show Occupancy Graph';
        showSystemChart.stateKey = 'showSystemChart';
        showSystemChart.disabled = false;
        layoutSettings.push(showSystemChart);

        const showNetworkGraph = new DashletSettingCheckbox();
        showNetworkGraph.label = 'Show Network Connectivity Graph';
        showNetworkGraph.stateKey = 'showNetworkGraph';
        showNetworkGraph.disabled = false;
        layoutSettings.push(showNetworkGraph);

        const showEthernet = new DashletSettingCheckbox();
        showEthernet.label = 'Show Ethernet';
        showEthernet.stateKey = 'showEthernet';
        showEthernet.disabled = false;
        layoutSettings.push(showEthernet);

        const showIPInterface = new DashletSettingCheckbox();
        showIPInterface.label = 'Show IP Interface';
        showIPInterface.stateKey = 'showIPInterface';
        showIPInterface.disabled = false;
        layoutSettings.push(showIPInterface);

        const group = new DashletSettingsGroup();
        group.groups = [equipmentGroup, layoutGroup];
        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);
        this.settingsService
            .getUserAssociatedCustomers()
            .pipe(take<any[]>(1))
            .subscribe(
                customers => {
                    this.customer.items = customers;
                    this.customer.loadingComplete();
                },
                () => {
                    this.customer.loadingComplete();
                    this.customer.disabled = true;
                    //show error notification here
                }
            );
    }

    private loadLocations(customer: string): void {
        this.enableAndLoading(this.location);
        this.settingsService
            .getLocations(customer)
            .pipe(take<any[]>(1))
            .subscribe(
                locations => {
                    this.location.items = locations;
                    this.location.loadingComplete();
                },
                () => {
                    this.location.loadingComplete();
                    this.location.disabled = true;
                    //show error notification here
                }
            );
    }

    private loadequipments(locationId: string): void {
        this.enableAndLoading(this.equipment);
        this.settingsService
            .getEquipment(locationId, 'AudioCodes')
            .pipe(take<any[]>(1))
            .subscribe(
                receivers => {
                    this.equipment.items = receivers;
                    this.equipment.loadingComplete();
                },
                () => {
                    this.equipment.loadingComplete();
                    this.equipment.disabled = true;
                    //show error notification here
                }
            );
    }

    private clearAndDisable(widget: DashletSettingDropdown | DashletSettingList<string>): void {
        widget.disabled = true;
        widget.items = [];
        if (widget instanceof DashletSettingList) widget.values = [];
        else widget.value = null;
    }

    private enableAndLoading(widget: DashletSettingDropdown | DashletSettingList<string>): void {
        widget.disabled = false;
        widget.loadingBegin();
    }
}
