/* eslint-disable no-undef */
import * as Actions from '@actions/index';
import {
    AccountService,
    DashletService,
    RealtimeGatewayService,
    ThresholdService,
    TileGridService,
    TimeoutService
} from '@services/index';
import { AppState, selectDataFromCommonEntity, selectEntity } from '@reducers/index';
import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core';
import {
    ColumnTypes,
    CustomerLogo,
    DashletSystemHealthSBC,
    SBCApplicationStatus,
    SBCNICInfo,
    SecurityTableData,
    ServiceStatus,
    Threshold
} from '@models/index';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ThresholdItemComponent } from '../threshold-item/threshold-item.component';
import { DashletSectionProcessorMemoryComponent } from '../dashlet-section-processor-memory/dashlet-section-processor-memory.component';
import { DashletSectionServicesComponent } from '../dashlet-section-services/dashlet-section-services.component';

@Component({
    selector: 'app-dashlet-system-health-sbc',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './dashlet-system-health-sbc.component.html',
    styleUrls: ['./dashlet-system-health-sbc.component.scss']
})
export class DashletSystemHealthSBCComponent implements OnInit, OnDestroy {
    private networkInterfaceSubscriptions: Subscription[];

    @Input() dashlet: DashletSystemHealthSBC;
    @ViewChild('thresholdItem') thresholdItem: ThresholdItemComponent;
    @ViewChild('processorMemory') processorMemory: DashletSectionProcessorMemoryComponent;

    @ViewChild('services') services: DashletSectionServicesComponent;
    openSubcontainer = '';
    openSubcontainerSeverity = 0;

    public subcontainerSubtitle: string;

    public servicesArray!: ServiceStatus[];

    selectedField: string;
    selectedThreshold: Threshold;
    titleMsg: string;

    public sipColumns: ColumnTypes[] = [
        {
            columnDef: 'TotalINVITES',
            header: 'Invites',
            width: '21%'
        },
        {
            columnDef: 'TotalINVITE1XXResponses',
            header: '1xx Response',
            type: 'numeric',
            width: '25%'
        },
        {
            columnDef: 'TotalINVITE200Responses',
            header: '2xx Response',
            type: 'numeric',
            width: '27%'
        },
        {
            columnDef: 'TotalINVITE4XX6XXResponses',
            header: '4/6xx Response',
            type: 'numeric',
            width: '27%'
        }
    ];

    public securityColumns: ColumnTypes[] = [
        {
            columnDef: 'severity',
            header: '',
            cell: (row: SecurityTableData) => (row.last3Min > 0 ? 2 : 0),
            type: 'severity'
        },
        {
            columnDef: 'status',
            header: ''
        },
        {
            columnDef: 'total',
            header: 'Total',
            type: 'numeric',
            cell: (row: SecurityTableData) => row.total || '---'
        },
        {
            columnDef: 'last3Min',
            header: 'Last 3 Minutes',
            type: 'numeric',
            cell: (row: SecurityTableData) => row.last3Min || '---'
        }
    ];

    private subscription: Subscription = new Subscription();

    constructor(
        private dashletService: DashletService,
        private accountService: AccountService,
        private thresholdService: ThresholdService,
        private tileGridService: TileGridService,
        public timeoutService: TimeoutService,
        private store$: Store<AppState>,
        private cdr: ChangeDetectorRef,
        private realtimeService: RealtimeGatewayService
    ) {
        this.networkInterfaceSubscriptions = [];
        this.subscription.add(
            realtimeService.isReConnectedSubject.subscribe(status => {
                if (status) {
                    this.sub();
                }
            })
        );
    }

    ngOnInit() {
        // Logo
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );
        this.sub();
        // Network Interface
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdNetworkInterface
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdNetworkInterface
                        )
                    )
                )
                .subscribe(data => {
                    if (data) {
                        this.dashlet.processNetworkInterface(data);
                        this.cdr.detectChanges();
                    }
                })
        );

        // SIP Protocol
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdSIPProtocolStatus
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdSIPProtocolStatus
                        )
                    )
                )
                .subscribe(data => {
                    if (data) {
                        this.dashlet.processSIPProtocolStatus(data);
                        this.cdr.detectChanges();
                    }
                })
        );

        // SIP Call
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdSIPCallStatus
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdSIPCallStatus
                        )
                    )
                )
                .subscribe(data => {
                    if (data) {
                        this.dashlet.processSIPCallStatus(data);
                        this.cdr.detectChanges();
                    }
                })
        );

        // Application Status

        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdApplicationStatus
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdApplicationStatus
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length) {
                        this.dashlet.processApplicationStatus(data);
                        this.cdr.detectChanges();
                    }
                })
        );
        this.subscription.add(
            this.dashlet.settingsUpdated.subscribe(() => {
                this.cdr.detectChanges();
            })
        );
    }

    private sub(): void {
        if (!this.dashlet) return;
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdNetworkInterface
            })
        );
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdSIPProtocolStatus
            })
        );
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdSIPCallStatus
            })
        );
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdApplicationStatus
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    getSeverityColor(severity: number): string {
        return this.dashletService.getSeverityColor(severity);
    }

    getApplicationStatusList() {
        const list: SBCApplicationStatus[] = [];
        for (const status of this.dashlet.applicationStatusList) {
            list.push(status);
        }
        if (this.dashlet.applicationStatusList.findIndex(e => e.applicationName === 'sems') === -1) {
            list.push(new SBCApplicationStatus('sems', 'Not Available', ''));
        }
        if (this.dashlet.applicationStatusList.findIndex(e => e.applicationName === 'ss') === -1) {
            list.push(new SBCApplicationStatus('ss', 'Not Available', ''));
        }

        return list;
    }

    getApplicationStatusColorIndex(status: string) {
        if (status.toLowerCase() === 'running') {
            return 0;
        }

        if (status.toLowerCase() === 'not running') {
            return 2;
        }
        return -1;
    }

    getNetworkInterfaceByStatus(status: string | number): any {
        const sbcList: SBCNICInfo[] = [];
        this.dashlet.sbcNicInfoList.forEach(element => {
            if (+element.status === +status) {
                sbcList.push(element);
            }
        });
        return sbcList;
    }

    getSIPProtocolData() {
        return {
            TotalINVITE1XXResponses: this.dashlet.sbcSipInfoList.find(e => e.name === 'TotalINVITE1XXResponses'),
            TotalINVITE200Responses: this.dashlet.sbcSipInfoList.find(e => e.name === 'TotalINVITE200Responses'),
            TotalINVITE4XX6XXResponses: this.dashlet.sbcSipInfoList.find(e => e.name === 'TotalINVITE4XX6XXResponses'),
            TotalINVITES: this.dashlet.sbcSipInfoList.find(e => e.name === 'TotalINVITES')
        };
    }

    getSIPCallData() {
        return {
            TotalActiveCalls: this.dashlet.sbcSipInfoList.find(e => e.name === 'TotalActiveCalls'),
            TotalActiveSRTPCalls: this.dashlet.sbcSipInfoList.find(e => e.name === 'TotalActiveSRTPCalls'),
            // eslint-disable-next-line eqeqeq
            TotalActiveTCPRegistrations: this.dashlet.sbcSipInfoList.find(e => e.name == 'TotalActiveTCPRegistrations'),
            // eslint-disable-next-line eqeqeq
            TotalActiveUDPRegistrations: this.dashlet.sbcSipInfoList.find(e => e.name == 'TotalActiveUDPRegistrations'),
            // eslint-disable-next-line eqeqeq
            TotalActiveTLSRegistrations: this.dashlet.sbcSipInfoList.find(e => e.name == 'TotalActiveTLSRegistrations'),
            // eslint-disable-next-line eqeqeq
            TotalCalls: this.dashlet.sbcSipInfoList.find(e => e.name == 'TotalCalls'),
            // eslint-disable-next-line eqeqeq
            TotalCallsReceived: this.dashlet.sbcSipInfoList.find(e => e.name == 'TotalCallsReceived'),
            // eslint-disable-next-line eqeqeq
            TotalCallsAllowed: this.dashlet.sbcSipInfoList.find(e => e.name == 'TotalCallsAllowed')
        };
    }

    getSecurityViolationsData() {
        return {
            // eslint-disable-next-line eqeqeq
            CallsBlocked: this.dashlet.sbcSipInfoList.find(e => e.name == 'TotalCallsBlocked'),
            // eslint-disable-next-line eqeqeq
            CallsFailed: this.dashlet.sbcSipInfoList.find(e => e.name == 'TotalCallsFailed'),
            // eslint-disable-next-line eqeqeq
            CallsDenied: this.dashlet.sbcSipInfoList.find(e => e.name == 'TtlCallsDeniedDueToPolicy'),
            RegistrationsDropped: this.dashlet.sbcSipInfoList.find(
                // eslint-disable-next-line eqeqeq
                e => e.name == 'TotalRegistrationsDroppedByMissingPolicy'
            ),
            // eslint-disable-next-line eqeqeq
            InvitesDropped: this.dashlet.sbcSipInfoList.find(e => e.name == 'TotalInvitesDroppedByMissingPolicy'),
            // eslint-disable-next-line eqeqeq
            SessionsDropped: this.dashlet.sbcSipInfoList.find(e => e.name == 'TtlSessDroppedDueToMaxNumofConcSessExc')
        };
    }

    getSecurityViolationsUp() {
        let count = 0;

        for (const property of Object.keys(this.getSecurityViolationsData())) {
            if (this.getSecurityViolationsData()[property]) {
                if (
                    this.getSecurityViolationsData()[property].last3Min === 0 ||
                    !this.getSecurityViolationsData()[property].last3Min
                ) {
                    count++;
                }
            }
        }

        return count;
    }

    getSecurityViolationsDown() {
        let count = 0;

        for (const property of Object.keys(this.getSecurityViolationsData())) {
            if (this.getSecurityViolationsData()[property]) {
                if (Math.abs(this.getSecurityViolationsData()[property].last3Min) > 0) {
                    count++;
                }
            }
        }

        return count;
    }

    getSecurityBorderColor(last3Min: number) {
        if (last3Min > 0) {
            return this.dashletService.getSeverityColor(2);
        }
        return this.dashletService.getSeverityColor(0);
    }

    openDetails(container: string, severity: number) {
        this.openSubcontainer = container;
        this.dashlet.displayHeader = false;
        this.openSubcontainerSeverity = severity;
        if (container === 'network-interface') {
            this.dispatchHistoric();
        }
    }

    private dispatchHistoric(): void {
        this.store$.dispatch(
            Actions.GetCommonHistoric({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdNetworkInterface,
                from: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                to: new Date().toISOString(),
                max: 3
            })
        );
    }

    closeDetails() {
        if (this.openSubcontainer === 'network-interface') {
            for (const subscription of this.networkInterfaceSubscriptions) {
                subscription.unsubscribe();
            }
        }
        this.subcontainerSubtitle = '';
        this.openSubcontainer = null;
        this.dashlet.displayHeader = true;
    }

    openThresholdAlerts(elementId: string, commandTypeId: string, diskName?: string) {
        this.openSubcontainer = 'createThreshold';
        this.dashlet.displayHeader = false;
        this.selectedThreshold = new Threshold();
        this.selectedThreshold.commandTypeId = commandTypeId;
        this.selectedThreshold.field = elementId;
        this.selectedThreshold.whereCreated = 'Dashboard';
        if (diskName) {
            this.selectedThreshold.fieldInstance = diskName;
        }
    }

    openAlarmIconThreshold() {
        this.thresholdService.openThresholdManagement(this.accountService.getUserDetails().EntityId);
    }

    onSubmit() {
        this.subscription.add(
            this.thresholdItem.onSubmit().subscribe(result => {
                if (result) {
                    this.selectedThreshold.dapThresholdId = result['DapThresholdId'];
                    this.dashlet.thresholds.push(this.selectedThreshold);
                    switch (this.selectedThreshold.field) {
                        case 'processor':
                            ++this.processorMemory.cpuUsageData.thresholdsNumber;
                            break;
                        case 'memory':
                            ++this.processorMemory.memoryUsageData.thresholdsNumber;
                            break;
                        case 'disk':
                            const diskUsage = this.processorMemory.diskUsageData.find(d => {
                                return d.diskName === this.selectedThreshold.fieldInstance;
                            });
                            if (diskUsage) {
                                ++diskUsage.thresholdsNumber;
                            }
                            break;
                        default:
                            break;
                    }
                    this.tileGridService.thresholdCreated(
                        this.selectedThreshold.name + ' threshold added via ' + this.dashlet.title
                    );
                } else {
                    this.tileGridService.thresholdCreated('Threshold add failed');
                }
            })
        );
        this.closeDetails();
    }

    saveEdit() {
        this.subscription.add(
            this.thresholdItem.saveEdit().subscribe(result => {
                if (result) {
                    this.tileGridService.thresholdCreated(
                        this.selectedThreshold.name + ' threshold edited via ' + this.dashlet.title
                    );
                } else {
                    this.tileGridService.thresholdCreated('Threshold edit failed');
                }
            })
        );
        this.closeDetails();
    }

    public getSubcontainerSubtitle(): string {
        let subtitle: string = '';
        if (this.services) {
            switch (this.openSubcontainerSeverity) {
                case 0:
                    subtitle = `${this.services.getServiceUpNumber()} of ${
                        this.services.serviceStatusList.length
                    } Service(s) up`;
                    break;
                case 2:
                    subtitle = `${this.services.getServiceDownNumber()} of ${
                        this.services.serviceStatusList.length
                    } Service(s) down`;
                    break;
            }
        }
        return subtitle;
    }
}
