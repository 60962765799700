/* eslint-disable no-undef */
import { environment } from '@environments/environment';
import { Customer, Dashlet } from '@models/index';
import { DashletService, RealtimeGatewayService } from '@services/index';
import { Subject, Subscription } from 'rxjs';

export interface SubnetVlanModel {
    Subnet: string;
    NumVLans: number;
    NumOfUniqueVLans: number;
    TotalPhones: number;
    TotalStreams: number;
    MosPercentage: number;
    MosHigh: number;
    MosMedium: number;
    MosLow: number;
    TimeStamp: Date;
    VlanSubRow: Map<number, any>;
    VlansEqualToZero: number;
    DSCPsNotEqualTo46: number;
}

export interface IpnrVlanModel {
    IPNR: string;
    NumVLans: number;
    NumOfUniqueVLans: number;
    TotalPhones: number;
    TotalStreams: number;
    MosPercentage: number;
    MosHigh: number;
    MosMedium: number;
    MosLow: number;
    TimeStamp: Date;
    VlanSubRow: Map<number, any>;
    VlansEqualToZero: number;
    DSCPsNotEqualTo46: number;
}

export interface DhcpVlanModel {
    DHCP: string;
    NumVLans: number;
    NumOfUniqueVLans: number;
    TotalPhones: number;
    TotalStreams: number;
    MosPercentage: number;
    MosHigh: number;
    MosMedium: number;
    MosLow: number;
    TimeStamp: Date;
    VlanSubRow: Map<number, any>;
    VlansEqualToZero: number;
    DSCPsNotEqualTo46: number;
}

interface NetworkConfigBaseModel {
    TotalDevices: number;
    TotalStreams: number;
    MisconfiguredEthernet: number;
    MosPercentage: number;
    MosHigh: number;
    MosMedium: number;
    MosLow: number;
    TimeStamp: Date;
    NetworkSubRow: any;
}

interface ProtocolConfigBaseModel {
    TotalDevices: number;
    TotalStreams: number;
    MisconfiguredProtocols: number;
    MosPercentage: number;
    MosHigh: number;
    MosMedium: number;
    MosLow: number;
    TimeStamp: Date;
    ProtocolSubRow: any;
}

export interface SubnetNetworkModel extends NetworkConfigBaseModel {
    Subnet: string;
}

export interface IPNRNetworkModel extends NetworkConfigBaseModel {
    IPNR: string;
}

export interface DHCPNetworkModel extends NetworkConfigBaseModel {
    DHCP: string;
}

export interface SubnetProtocolModel extends ProtocolConfigBaseModel {
    Subnet: string;
}

export interface IPNRProtocolModel extends ProtocolConfigBaseModel {
    IPNR: string;
}

export interface DHCPProtocolModel extends ProtocolConfigBaseModel {
    DHCP: string;
}

export class DashletEndpointDataSummary extends Dashlet {
    //#region Class Members
    static readonly PERSIST_TABS_KEY: string = 'tabsOpen';
    public readonly commandTypeIdSummaryBySubnet = '53AC935C-3D81-4040-B0E7-8B8CC5FEB5C4';
    public readonly commandTypeIdSummaryByIpnr = '92AA8B0A-5BAD-4E3D-8B88-E9952799F502';
    public readonly commandTypeIdSummaryByDhcp = 'E52EF9C1-B3ED-4713-AC08-398B344F6B9F';

    public customer!: Customer;
    phoneDataSummaryDashletSize: Map<number, number>;

    logoSub!: Subscription;
    location!: string;
    locationId!: string;
    equipment!: string;
    equipmentId!: string;
    summaryPeriod!: any;

    sizeChanged: Subject<null> = new Subject();
    SubnetVlanDataUpdated: Subject<null> = new Subject();
    IpnrVlanDataUpdated: Subject<null> = new Subject();
    DhcpVlanDataUpdated: Subject<null> = new Subject();

    SubnetNetworkDataUpdated: Subject<null> = new Subject();
    IpnrNetworkDataUpdated: Subject<null> = new Subject();
    DhcpNetworkDataUpdated: Subject<null> = new Subject();

    SubnetProtocolDataUpdated: Subject<null> = new Subject();
    IpnrProtocolDataUpdated: Subject<null> = new Subject();
    DhcpProtocolDataUpdated: Subject<null> = new Subject();

    receivers: any[] = [];
    tabsOpen: number[] = [0];

    PhoneDataSummaryData: any = [];

    SubnetVlanMapped: Map<string, any>;
    IPNRVlanMapped: Map<string, any>;
    DHCPVlanMapped: Map<string, any>;

    SubnetNetworkMapped: Map<string, any>;
    IPNRNetworkMapped: Map<string, any>;
    DHCPNetworkMapped: Map<string, any>;

    SubnetProtocolMapped: Map<string, any>;
    IPNRProtocolMapped: Map<string, any>;
    DHCPProtocolMapped: Map<string, any>;

    subnetNetworkStatusChart: any;
    subnetNetworkDuplexChart: any;
    subnetNetworkSpeedChart: any;

    networkSubnetTotalDevices: number;
    networkSubnetTotalEthernetStatus: number;
    networkSubnetTotalEthernetDuplex: number;
    networkSubnetTotalEthernetSpeed: number;

    networkIPNRTotalDevices: number;
    networkIPNRTotalEthernetStatus: number;
    networkIPNRTotalEthernetDuplex: number;
    networkIPNRTotalEthernetSpeed: number;
    public settingsChanged: Subject<null> = new Subject();

    chartEthernetStatus: any = {
        labels: ['Auto Recognition', 'Unknown'],
        datasets: {
            label: 'Ethernet Status',
            backgroundColor: [this.dashletService.getChartColors().green, this.dashletService.getChartColors().red],
            data: []
        }
    };
    chartEthernetDuplex: any = {
        labels: ['Full Duplex', 'Unknown'],
        datasets: {
            label: 'Ethernet Duplex',
            backgroundColor: [this.dashletService.getChartColors().green, this.dashletService.getChartColors().red],
            data: []
        }
    };
    chartEthernetSpeed: any = {
        labels: ['> 100 MB', '< 100 MB'],
        datasets: {
            label: 'Ethernet Speed',
            backgroundColor: [this.dashletService.getChartColors().green, this.dashletService.getChartColors().red],
            data: []
        }
    };

    chartAudioPriority: any = {
        labels: ['= 6', '\u2260 6'],
        datasets: {
            label: 'Audio Priority',
            backgroundColor: [this.dashletService.getChartColors().green, this.dashletService.getChartColors().amber],
            data: []
        }
    };
    chartSignallingPriority: any = {
        labels: ['= 6', '\u2260 6'],
        datasets: {
            label: 'Signalling Priority',
            backgroundColor: [this.dashletService.getChartColors().green, this.dashletService.getChartColors().amber],
            data: []
        }
    };

    SubnetVlanDataNoResult = false;
    IpnrVlanDataNoResult = false;
    DhcpVlanDataNoResult = false;

    SubnetNetworkDataNoResult = false;
    IpnrNetworkDataNoResult = false;
    DhcpNetworkDataNoResult = false;

    SubnetProtocolDataNoResult = false;
    IpnrProtocolDataNoResult = false;
    DhcpProtocolDataNoResult = false;

    public lastUpdated: string;

    private documentTimeStamps: any[] = [];
    //#endregion

    //#region Constructor

    constructor(private realtimeService: RealtimeGatewayService, private dashletService: DashletService) {
        super();

        this.PhoneDataSummaryData = [
            {
                TotalDevices: 0,
                TotalStreams: 0,
                NumberOfSubnets: 0,
                SignallingPriority: 0,
                NumberOfVlan: 0,
                EthernetSpeedLess100Mb: 0,
                AudioPriority: 0,
                EthernetDuplex: 0,
                VlansEqualToZero: 0,
                DSCPsNotEqualTo46: 0
            }
        ];

        this.SubnetVlanMapped = new Map<string, any>();
        this.IPNRVlanMapped = new Map<string, any>();
        this.DHCPVlanMapped = new Map<string, any>();

        this.SubnetNetworkMapped = new Map<string, any>();
        this.IPNRNetworkMapped = new Map<string, any>();
        this.DHCPNetworkMapped = new Map<string, any>();

        this.SubnetProtocolMapped = new Map<string, any>();
        this.IPNRProtocolMapped = new Map<string, any>();
        this.DHCPProtocolMapped = new Map<string, any>();

        // static size expansions
        this.phoneDataSummaryDashletSize = new Map<number, number>().set(0, -23).set(1, -16).set(2, -8).set(3, -1);

        this.networkSubnetTotalDevices =
            this.networkSubnetTotalEthernetStatus =
            this.networkSubnetTotalEthernetDuplex =
            this.networkSubnetTotalEthernetSpeed =
                0;
        this.networkIPNRTotalDevices =
            this.networkIPNRTotalEthernetStatus =
            this.networkIPNRTotalEthernetDuplex =
            this.networkIPNRTotalEthernetSpeed =
                0;

        // sizing
        this.sizes = [
            {
                id: 2,
                label: 'Large',
                cols: 9,
                rows: 27
            }
        ];

        this.lastUpdated = new Date().toISOString();

        this.applySize(2);

        // init data
        this.resetData();
    }

    //#endregion

    //#region Methods

    public processData(dataArray: any[], type?: string) {
        if (dataArray) {
            dataArray.forEach(dataRow => {
                if (dataRow) {
                    if (!this.checkDataIfProcessed(dataRow.id, dataRow.timestamp)) {
                        this.processVlanDataRows(dataRow, type);
                        if (type !== 'DHCP') {
                            this.processNetworkDataRows(dataRow, type);
                            this.processProtocolDataRows(dataRow, type);
                        }
                    }
                }
            });
        }
    }

    private checkDataIfProcessed(id: string, timestamp: string) {
        const index = this.documentTimeStamps.findIndex(document => {
            return document.id === id && document.timestamp === timestamp;
        });

        if (index === -1) {
            this.documentTimeStamps.push({ id: id, timestamp: timestamp });
            return false;
        }

        return true;
    }

    /**
     * Process Vlan response data from realtime gateway
     * @param data
     * @param type Subnet, Ipnr, Dhcp
     */
    processVlanDataRows(completeDataParam: any, typeParam: string) {
        try {
            let newMap: Map<any, any> = new Map(this[`${typeParam}VlanMapped`]);
            let dataParam = completeDataParam.data;
            let timeStamp = new Date(completeDataParam.timestamp);

            dataParam.map(item => {
                let rowData = this.getVlanObjectType(typeParam);

                rowData[`${typeParam}`] = isNaN(item[`${typeParam}`] * 1)
                    ? item[`${typeParam}`]
                    : item[`${typeParam}`] * 1;
                rowData.NumVLans = item.NumVLans * 1; // used multiply by one(1) for performance. reference: https://flaviocopes.com/how-to-convert-string-to-number-javascript/
                rowData.TotalPhones = item.TotalDevices * 1;
                rowData.TotalStreams = item.TotalStreams * 1;
                rowData.MosHigh = item.MosHigh * 1;
                rowData.MosMedium = item.MosMedium * 1;
                rowData.MosLow = item.MosLow * 1;
                rowData.MosPercentage = rowData.MosHigh / (rowData.MosMedium + rowData.MosLow + rowData.MosHigh);
                rowData.TimeStamp = timeStamp;
                rowData.VlansEqualToZero = item.NoVlanData * 1;
                rowData.DSCPsNotEqualTo46 = item.DscpNot46 * 1;

                this.refreshNameTag();
                if (isNaN(rowData.MosPercentage)) rowData.MosPercentage = -1; //For empty Mos data - needed for sorting in tables

                let result = this.parseVlanString(item.VLanData);
                let ipnrRow: any = rowData; //Allows checking of IPNR member
                if (ipnrRow.IPNR !== undefined) {
                    //Check if this is an IPNR containing object
                    if (rowData[`${typeParam}`] === -1) rowData[`${typeParam}`] = 'N/A';
                }
                if (newMap.has(item[`${typeParam}`])) {
                    // update record on the mapped list

                    let currentRecord = newMap.get(item[`${typeParam}`]);
                    currentRecord.TotalStreams += rowData.TotalStreams;
                    currentRecord.MosHigh += rowData.MosHigh;
                    currentRecord.MosMedium += rowData.MosMedium;
                    currentRecord.MosLow += rowData.MosLow;
                    currentRecord.MosPercentage =
                        currentRecord.MosHigh /
                        (currentRecord.MosMedium + currentRecord.MosLow + currentRecord.MosHigh);
                    if (isNaN(currentRecord.MosPercentage)) currentRecord.MosPercentage = -1; //For empty Mos data - needed for sorting in tables
                    result.forEach(elem => {
                        if (currentRecord.VlanSubRow.has(elem.Vlan)) {
                            let currentSubRow = currentRecord.VlanSubRow.get(elem.Vlan);
                            currentSubRow.TotalStreams += elem.TotalStreams;
                            currentSubRow.MosHigh += elem.MosHigh;
                            currentSubRow.MosLow += elem.MosLow;
                            currentSubRow.MosMedium += elem.MosMedium;
                        } else {
                            currentRecord.VlanSubRow.set(elem.Vlan, elem);
                        }
                    });
                    currentRecord.NumVLans = currentRecord.VlanSubRow.size;
                } else {
                    // Insert new record to the mapped list
                    let resultMap = new Map<number, any>();

                    result.forEach(elem => {
                        resultMap.set(elem.Vlan, elem);
                    });
                    rowData.VlanSubRow = resultMap;
                    rowData.NumVLans = resultMap.size;
                    newMap.set(item[`${typeParam}`], rowData);
                }
            });
            this[`${typeParam}VlanMapped`] = newMap;
        } catch (error) {
            if (!environment.production) console.error(error);
        }
    }

    getVlanObjectType(typeParam: string) {
        switch (typeParam) {
            case 'Subnet': {
                const data: SubnetVlanModel = { VlanSubRow: new Map<number, any>() } as SubnetVlanModel;
                return data;
            }
            case 'IPNR': {
                const data: IpnrVlanModel = { VlanSubRow: new Map<number, any>() } as IpnrVlanModel;
                return data;
            }
            case 'DHCP': {
                const data: DhcpVlanModel = { VlanSubRow: new Map<number, any>() } as DhcpVlanModel;
                return data;
            }
            default:
                return;
        }
    }

    /**
     * Process Network response data from realtime gateway
     * @param data
     * @param type Subnet, Ipnr, Dhcp
     */
    processNetworkDataRows(completeDataParam: any, typeParam: string) {
        try {
            let newMap: Map<any, any> = new Map(this[`${typeParam}NetworkMapped`]);
            let dataParam = completeDataParam.data;
            let timeStamp = new Date(completeDataParam.timestamp);

            dataParam.map(item => {
                let rowData = this.getNetworkObjectType(typeParam);

                rowData[`${typeParam}`] = isNaN(item[`${typeParam}`] * 1)
                    ? item[`${typeParam}`]
                    : item[`${typeParam}`] * 1;
                rowData.TotalDevices = item.TotalDevices * 1;
                rowData.TotalStreams = item.TotalStreams * 1;
                rowData.MisconfiguredEthernet = item.MisconfiguredEthernet * 1;
                rowData.MosHigh = item.MosHigh * 1;
                rowData.MosMedium = item.MosMedium * 1;
                rowData.MosLow = item.MosLow * 1;
                rowData.MosPercentage = rowData.MosHigh / (rowData.MosMedium + rowData.MosLow + rowData.MosHigh);
                rowData.TimeStamp = timeStamp;

                this.refreshNameTag();
                if (isNaN(rowData.MosPercentage)) rowData.MosPercentage = -1; //For empty Mos data - needed for sorting in tables

                let result = this.generateNetworkSubrow(item);
                let ipnrRow: any = rowData; //Allows checking of IPNR member
                if (ipnrRow.IPNR !== undefined) {
                    //Check if this is an IPNR containing object
                    if (rowData[`${typeParam}`] === -1) rowData[`${typeParam}`] = 'N/A';
                }
                if (newMap.has(item[`${typeParam}`])) {
                    // update record on the mapped list
                    let currentRecord = newMap.get(item[`${typeParam}`]);
                    currentRecord.TotalStreams += rowData.TotalStreams;
                    currentRecord.MosHigh += rowData.MosHigh;
                    currentRecord.MosMedium += rowData.MosMedium;
                    currentRecord.MosLow += rowData.MosLow;
                    currentRecord.MosPercentage =
                        currentRecord.MosHigh /
                        (currentRecord.MosMedium + currentRecord.MosLow + currentRecord.MosHigh);
                    if (isNaN(currentRecord.MosPercentage)) currentRecord.MosPercentage = -1; //For empty Mos data - needed for sorting in tables
                } else {
                    // Insert new record to the mapped list
                    rowData.NetworkSubRow = result;
                    newMap.set(item[`${typeParam}`], rowData);
                }
            });
            this[`${typeParam}NetworkMapped`] = newMap;
        } catch (error) {
            if (!environment.production) console.error(error);
        }
    }

    getNetworkObjectType(typeParam: string) {
        switch (typeParam) {
            case 'Subnet': {
                const data: SubnetNetworkModel = {} as SubnetNetworkModel;
                return data;
            }
            case 'IPNR': {
                const data: IPNRNetworkModel = {} as IPNRNetworkModel;
                return data;
            }
            case 'DHCP': {
                const data: DHCPNetworkModel = {} as DHCPNetworkModel;
                return data;
            }
            default:
                return;
        }
    }

    private parseVlanString(vlanParam: string) {
        const primarySplit = vlanParam.split('>');
        const arrayObject: any = [];

        primarySplit.forEach(elem => {
            if (elem.length !== 0) {
                elem = elem.substr(1);
                const splitString = elem.split('|');
                const result = {
                    Vlan: isNaN(Number(splitString[0])) ? 0 : Number(splitString[0]),
                    TotalStreams: isNaN(Number(splitString[1])) ? 0 : Number(splitString[1]),
                    MosLow: isNaN(Number(splitString[2])) ? 0 : Number(splitString[2]),
                    MosMedium: isNaN(Number(splitString[3])) ? 0 : Number(splitString[3]),
                    MosHigh: isNaN(Number(splitString[4])) ? 0 : Number(splitString[4])
                };
                arrayObject.push(result);
            }
        });
        return arrayObject;
    }

    /**
     * Process Network response data from realtime gateway
     * @param data
     * @param type Subnet, Ipnr, Dhcp
     */
    processProtocolDataRows(completeDataParam: any, typeParam: string) {
        try {
            let newMap: Map<any, any> = new Map(this[`${typeParam}ProtocolMapped`]);
            let dataParam = completeDataParam.data;
            let timeStamp = new Date(completeDataParam.timestamp);

            dataParam.map(item => {
                let rowData = this.getProtocolObjectType(typeParam);
                rowData[`${typeParam}`] = isNaN(item[`${typeParam}`] * 1)
                    ? item[`${typeParam}`]
                    : item[`${typeParam}`] * 1;
                rowData.TotalDevices = item.TotalDevices * 1;
                rowData.TotalStreams = item.TotalStreams * 1;
                rowData.MisconfiguredProtocols = item.MisconfiguredProtocols * 1;
                rowData.MosHigh = item.MosHigh * 1;
                rowData.MosMedium = item.MosMedium * 1;
                rowData.MosLow = item.MosLow * 1;
                rowData.MosPercentage = rowData.MosHigh / (rowData.MosMedium + rowData.MosLow + rowData.MosHigh);
                rowData.TimeStamp = timeStamp;

                this.refreshNameTag();
                if (isNaN(rowData.MosPercentage)) rowData.MosPercentage = -1; //For empty Mos data - needed for sorting in tables

                let result = this.generateProtocolSubrow(item);
                let ipnrRow: any = rowData; //Allows checking of IPNR member
                if (ipnrRow.IPNR !== undefined) {
                    //Check if this is an IPNR containing object
                    if (rowData[`${typeParam}`] === -1) rowData[`${typeParam}`] = 'N/A';
                }
                if (newMap.has(item[`${typeParam}`])) {
                    // update record on the mapped list
                    let currentRecord = newMap.get(item[`${typeParam}`]);
                    currentRecord.TotalStreams += rowData.TotalStreams;
                    currentRecord.MosHigh += rowData.MosHigh;
                    currentRecord.MosMedium += rowData.MosMedium;
                    currentRecord.MosLow += rowData.MosLow;
                    currentRecord.MosPercentage =
                        currentRecord.MosHigh /
                        (currentRecord.MosMedium + currentRecord.MosLow + currentRecord.MosHigh);
                    if (isNaN(currentRecord.MosPercentage)) currentRecord.MosPercentage = -1; //For empty Mos data - needed for sorting in tables
                } else {
                    // Insert new record to the mapped list
                    rowData.ProtocolSubRow = result;
                    newMap.set(item[`${typeParam}`], rowData);
                }
            });
            this[`${typeParam}ProtocolMapped`] = newMap;
        } catch (error) {
            if (!environment.production) console.error(error);
        }
    }

    getProtocolObjectType(typeParam: string) {
        switch (typeParam) {
            case 'Subnet': {
                const data: SubnetProtocolModel = {} as SubnetProtocolModel;
                return data;
            }
            case 'IPNR': {
                const data: IPNRProtocolModel = {} as IPNRProtocolModel;
                return data;
            }
            case 'DHCP': {
                const data: DHCPProtocolModel = {} as DHCPProtocolModel;
                return data;
            }
            default:
                return;
        }
    }

    getSubnetNetworkChartData() {
        this.generateNetworkEthernetStatusChartData();
        this.generateNetworkEthernetDuplexChartData();
        this.generateNetworkEthernetSpeedChartData();
    }

    generateNetworkEthernetStatusChartData() {
        try {
            const dataSet = this.SubnetNetworkMapped;
            const dataTable: any = [];
            let totalDevices = 0;
            let totalEthernetStatus = 0;

            dataSet.forEach(values => {
                const row = values;
                dataTable.push(row);
            });

            totalDevices = dataTable.reduce((sum: any, rec: any) => {
                return sum * 1 + rec.TotalDevices * 1;
            }, 0);

            totalEthernetStatus = dataTable.reduce((sum: any, rec: any) => {
                return sum * 1 + rec.NetworkSubRow[0].Value * 1;
            }, 0);

            const valueOne = totalDevices - totalEthernetStatus;

            this.chartEthernetStatus = {
                labels: ['Auto Recognition', 'Unknown'],
                datasets: {
                    label: 'Ethernet Status',
                    backgroundColor: [
                        this.dashletService.getChartColors().green,
                        this.dashletService.getChartColors().red
                    ],
                    data: [valueOne, totalEthernetStatus]
                }
            };
        } catch (error) {
            if (!environment.production) console.error(error);
        }
    }

    generateNetworkEthernetDuplexChartData() {
        try {
            const dataSet = this.SubnetNetworkMapped;
            const dataTable: any = [];
            let totalDevices = 0;
            let totalEthernetDuplex = 0;

            dataSet.forEach(values => {
                const row = values;
                dataTable.push(row);
            });

            totalDevices = dataTable.reduce((sum: any, rec: any) => {
                return sum * 1 + rec.TotalDevices * 1;
            }, 0);

            totalEthernetDuplex = dataTable.reduce((sum: any, rec: any) => {
                return sum * 1 + rec.NetworkSubRow[1].Value * 1;
            }, 0);

            const valueOne = totalDevices - totalEthernetDuplex;

            this.chartEthernetDuplex = {
                labels: ['Full Duplex', 'Unknown'],
                datasets: {
                    label: 'Ethernet Duplex',
                    backgroundColor: [
                        this.dashletService.getChartColors().green,
                        this.dashletService.getChartColors().red
                    ],
                    data: [valueOne, totalEthernetDuplex]
                }
            };
        } catch (error) {
            if (!environment.production) console.error(error);
        }
    }

    generateNetworkEthernetSpeedChartData() {
        try {
            const dataSet = this.SubnetNetworkMapped;
            let dataTable = [];
            let totalDevices = 0;
            let totalEthernetSpeed = 0;

            dataTable = Array.from(dataSet.values());

            totalDevices = dataTable.reduce((sum, rec) => {
                return sum * 1 + rec.TotalDevices * 1;
            }, 0);

            totalEthernetSpeed = dataTable.reduce((sum, rec) => {
                return sum * 1 + rec.NetworkSubRow[2].Value * 1;
            }, 0);

            const valueOne = totalDevices - totalEthernetSpeed;
            this.chartEthernetSpeed = {
                labels: ['> 100 MB', '< 100 MB'],
                datasets: {
                    label: 'Ethernet Speed',
                    backgroundColor: [
                        this.dashletService.getChartColors().green,
                        this.dashletService.getChartColors().red
                    ],
                    data: [valueOne, totalEthernetSpeed]
                }
            };
        } catch (error) {
            if (!environment.production) console.error(error);
        }
    }

    getSubnetProtocolChartData() {
        this.generateProtocolAudioPriorityChartData();
        this.generateProtocolSignallingPriorityChartData();
    }

    generateProtocolAudioPriorityChartData() {
        try {
            const dataSet = this.SubnetProtocolMapped;
            const dataTable: any = [];
            let totalDevices = 0;
            let totalAudioPriority = 0;

            dataSet.forEach(values => {
                const row = values;
                dataTable.push(row);
            });

            totalDevices = dataTable.reduce((sum: any, rec: any) => {
                return sum * 1 + rec.TotalDevices * 1;
            }, 0);

            totalAudioPriority = dataTable.reduce((sum: any, rec: any) => {
                return sum * 1 + rec.ProtocolSubRow[1].Value * 1;
            }, 0);

            const valueOne = totalDevices - totalAudioPriority;

            this.chartAudioPriority = {
                labels: ['= 6', '\u2260 6'],
                datasets: {
                    label: 'Audio Priority',
                    backgroundColor: [
                        this.dashletService.getChartColors().green,
                        this.dashletService.getChartColors().amber
                    ],
                    data: [valueOne, totalAudioPriority]
                }
            };
        } catch (error) {
            if (!environment.production) console.error(error);
        }
    }

    generateProtocolSignallingPriorityChartData() {
        try {
            const dataSet = this.SubnetProtocolMapped;
            const dataTable: any = [];
            let totalDevices = 0;
            let totalSignallingPriority = 0;

            dataSet.forEach(values => {
                const row = values;
                dataTable.push(row);
            });

            totalDevices = dataTable.reduce((sum: any, rec: any) => {
                return sum * 1 + rec.TotalDevices * 1;
            }, 0);

            totalSignallingPriority = dataTable.reduce((sum: any, rec: any) => {
                return sum * 1 + rec.ProtocolSubRow[0].Value * 1;
            }, 0);

            const valueOne = totalDevices - totalSignallingPriority;

            this.chartSignallingPriority = {
                labels: ['= 6', '\u2260 6'],
                datasets: {
                    label: 'Signalling Priority',
                    backgroundColor: [
                        this.dashletService.getChartColors().green,
                        this.dashletService.getChartColors().amber
                    ],
                    data: [valueOne, totalSignallingPriority]
                }
            };
        } catch (error) {
            if (!environment.production) console.error(error);
        }
    }

    generateNetworkSubrow(rowParam: any) {
        const subrow = [];

        const unknownEthernet = {
            Code: 'EthernetStatus',
            Title: 'Unknown Ethernet Status',
            Value: rowParam.EthernetStatus * 1
        };

        const duplex = {
            Code: 'EthernetDuplex',
            Title: 'Ethernet Duplex Unknown',
            Value: rowParam.EthernetDuplex * 1
        };

        const ethernetSpeed = {
            Code: 'EthernetSpeed',
            Title: 'Ethernet Speed < 100MB',
            Value: rowParam.EthernetSpeed * 1
        };

        subrow.push(unknownEthernet);
        subrow.push(duplex);
        subrow.push(ethernetSpeed);

        return subrow;
    }

    generateProtocolSubrow(rowParam: any) {
        const subrow = [];

        const signalPriority = {
            Code: 'Layer28021QSignallingPriority',
            Title: 'Layer28021Q Signal Priority \u2260 6',
            Value: rowParam.Layer28021QSignallingPriority * 1
        };

        const audioPriority = {
            Code: 'Layer28021QAudioPriority',
            Title: 'Audio Priority \u2260 6',
            Value: rowParam.Layer28021QAudioPriority * 1
        };

        subrow.push(signalPriority);
        subrow.push(audioPriority);

        return subrow;
    }

    processSummaryDataSubnet() {
        // use vlan subnet mapped - SubnetVlanMapped

        const dataSetSubnet = this.SubnetVlanMapped;
        const dataTableSubnet: any = [];

        dataSetSubnet.forEach(values => {
            const row = values;
            dataTableSubnet.push(row);
        });

        const totalPhones = dataTableSubnet.reduce((sum: any, rec: any) => {
            return sum * 1 + rec.TotalPhones;
        }, 0);
        const totalStreams = dataTableSubnet.reduce((sum: any, rec: any) => {
            return sum * 1 + rec.TotalStreams;
        }, 0);
        const totalDSCPsNotEqualTo46 = dataTableSubnet.reduce((sum: any, rec: any) => {
            return sum * 1 + rec.DSCPsNotEqualTo46;
        }, 0);
        const totalVlansEqualToZero = dataTableSubnet.reduce((sum: any, rec: any) => {
            return sum * 1 + rec.VlansEqualToZero;
        }, 0);
        const numberofphones = this.SubnetVlanMapped.size;

        const uniqueVlans: any = [];
        dataTableSubnet.forEach((rec: any) => {
            if (rec.VlanSubRowData) {
                rec.VlanSubRowData.forEach((row: any) => {
                    if (uniqueVlans.findIndex((e: any) => e === row.Vlan) === -1) {
                        uniqueVlans.push(row.Vlan);
                    }
                });
            }
        });
        this.PhoneDataSummaryData[0].TotalDevices = totalPhones;
        this.PhoneDataSummaryData[0].TotalStreams = totalStreams;
        this.PhoneDataSummaryData[0].NumberOfSubnets = numberofphones;
        this.PhoneDataSummaryData[0].NumberOfVlan = uniqueVlans.length;
        this.PhoneDataSummaryData[0].DSCPsNotEqualTo46 = totalDSCPsNotEqualTo46;
        this.PhoneDataSummaryData[0].VlansEqualToZero = totalVlansEqualToZero;
    }

    processSummaryDataNetwork() {
        // use vlan subnet mapped - SubnetVlanMapped

        const dataSetNetwork = this.SubnetNetworkMapped;
        const dataTableNetwork: any = [];

        dataSetNetwork.forEach(values => {
            const row = values;
            dataTableNetwork.push(row);
        });

        // let needingattention = dataTableNetwork.reduce((sum, rec) => { return (sum * 1) + rec.NetworkSubRow[0].Value }, 0);
        const lessThan100Mb = dataTableNetwork.reduce((sum: any, rec: any) => {
            return sum * 1 + rec.NetworkSubRow[2].Value;
        }, 0);
        const ethernetDuplex = dataTableNetwork.reduce((sum: any, rec: any) => {
            return sum * 1 + rec.NetworkSubRow[1].Value;
        }, 0);

        // this.PhoneDataSummaryData[0].EquipmentAttention = needingattention;
        this.PhoneDataSummaryData[0].EthernetSpeedLess100Mb = lessThan100Mb;
        this.PhoneDataSummaryData[0].EthernetDuplex = ethernetDuplex;
    }

    processSummaryDataProtocol() {
        // use vlan subnet mapped - SubnetVlanMapped

        const dataSetProtocol = this.SubnetProtocolMapped;
        const dataTableProtocol: any = [];

        dataSetProtocol.forEach(values => {
            const row = values;
            dataTableProtocol.push(row);
        });

        const audioPriority = dataTableProtocol.reduce((sum: any, rec: any) => {
            return sum * 1 + rec.ProtocolSubRow[1].Value;
        }, 0);
        const signallingPriority = dataTableProtocol.reduce((sum: any, rec: any) => {
            return sum * 1 + rec.ProtocolSubRow[0].Value;
        }, 0);
        this.PhoneDataSummaryData[0].AudioPriority = audioPriority;
        this.PhoneDataSummaryData[0].SignallingPriority = signallingPriority;
    }

    //#endregion

    //#region Helpers

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        // unsub realtime feed
        this.unsubData();

        // read settings object
        this.configured = v.customer && v.location;
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }

        this.location = v.location && v.location.value ? v.location.label : null;
        this.locationId = v.location && v.location.value ? v.location.value : null;

        this.equipment = v.equipment && v.equipment.value ? v.equipment.label : null;
        this.equipmentId = v.equipment && v.equipment.value ? v.equipment.value : null;

        this.summaryPeriod = v.summaryPeriod;

        this.generatedNameTag = this.configured ? 'Loading' : 'Unconfigured';
        if (this.configured) {
            this.settingsChanged.next(null);
        }
    }

    applySize(id: number) {
        super.applySize(id);
        const expandSize = this.phoneDataSummaryDashletSize.get(this.tabsOpen.length);
        this.applySizeExpansion(0, expandSize!);
    }

    saveState(obj: { [key: string]: any }) {
        super.saveState(obj);
        obj[DashletEndpointDataSummary.PERSIST_TABS_KEY] = this.tabsOpen;
    }

    loadState(obj: { [key: string]: any }) {
        super.loadState(obj);
        if (obj[DashletEndpointDataSummary.PERSIST_TABS_KEY]) {
            this.tabsOpen = [...obj[DashletEndpointDataSummary.PERSIST_TABS_KEY]];
            this.applySize(obj[Dashlet.PERSIST_SIZE_ID_KEY]);
            const size = this.getTotalSize();
            this.tile = {
                id: this.id,
                cols: size.w,
                rows: size.h,
                x: obj[Dashlet.PERSIST_X_KEY] || 0,
                y: obj[Dashlet.PERSIST_Y_KEY] || 0,
                dragEnabled: obj[Dashlet.PERSIST_DRAG_ENABLED_KEY]
            };
        }
    }

    resetData() {
        this.PhoneDataSummaryData = [
            {
                TotalDevices: 0,
                TotalStreams: 0,
                NumberOfSubnets: 0,
                SignallingPriority: 0,
                NumberOfVlan: 0,
                EthernetSpeedLess100Mb: 0,
                AudioPriority: 0,
                EthernetDuplex: 0,
                VlansEqualToZero: 0,
                DSCPsNotEqualTo46: 0
            }
        ];

        this.SubnetVlanMapped = new Map<string, any>();
        this.IPNRVlanMapped = new Map<string, any>();
        this.DHCPVlanMapped = new Map<string, any>();

        this.SubnetNetworkMapped = new Map<string, any>();
        this.IPNRNetworkMapped = new Map<string, any>();
        this.DHCPNetworkMapped = new Map<string, any>();

        this.SubnetProtocolMapped = new Map<string, any>();
        this.IPNRProtocolMapped = new Map<string, any>();
        this.DHCPProtocolMapped = new Map<string, any>();

        this.chartEthernetStatus =
            this.chartEthernetDuplex =
            this.chartEthernetSpeed =
                {
                    labels: ['', ''],
                    datasets: {
                        label: '',
                        backgroundColor: [
                            this.dashletService.getChartColors().green,
                            this.dashletService.getChartColors().amber
                        ],
                        data: []
                    }
                };

        this.SubnetVlanDataNoResult = false;
        this.IpnrVlanDataNoResult = false;
        this.DhcpVlanDataNoResult = false;

        this.SubnetNetworkDataNoResult = false;
        this.IpnrNetworkDataNoResult = false;
        this.DhcpNetworkDataNoResult = false;

        this.SubnetProtocolDataNoResult = false;
        this.IpnrProtocolDataNoResult = false;
        this.DhcpProtocolDataNoResult = false;

        // Reset all subjects
        this.sizeChanged.next(null);
        this.SubnetVlanDataUpdated.next(null);
        this.IpnrVlanDataUpdated.next(null);
        this.DhcpVlanDataUpdated.next(null);

        this.SubnetNetworkDataUpdated.next(null);
        this.IpnrNetworkDataUpdated.next(null);
        this.DhcpNetworkDataUpdated.next(null);

        this.SubnetProtocolDataUpdated.next(null);
        this.IpnrProtocolDataUpdated.next(null);
        this.DhcpProtocolDataUpdated.next(null);
        this.documentTimeStamps = [];
    }

    dispose() {
        this.resetData();
        this.unsubData();
    }
    private unsubData() {
        // unsub data
        if (this.receivers) {
            for (const receiver of this.receivers) {
                this.realtimeService.unsubscribe(receiver.value, this.commandTypeIdSummaryByDhcp);
                this.realtimeService.unsubscribe(receiver.value, this.commandTypeIdSummaryByIpnr);
                this.realtimeService.unsubscribe(receiver.value, this.commandTypeIdSummaryBySubnet);
            }
        }
    }

    getCustomerId() {
        return this.customer.customerId;
    }

    refreshNameTag() {
        // Make new dates, one without minutes for period
        const originalDate = new Date(this.lastUpdated),
            originalDateNoMinutes = new Date(this.lastUpdated);
        originalDateNoMinutes.setMinutes(0, 0, 0);

        const _month = originalDate.toLocaleDateString('en-nz', { month: 'short' });
        const _date = originalDate.toLocaleDateString('en-nz', { day: 'numeric' });
        const _time = originalDateNoMinutes
            .toLocaleTimeString('en-nz', { hour: 'numeric', minute: 'numeric' })
            .toUpperCase();

        // Date for first value in period
        const firstDate: Date = new Date(originalDateNoMinutes);
        firstDate.setHours(firstDate.getHours() - parseInt(this.summaryPeriod.value));

        const _firstDate = firstDate.toLocaleDateString(undefined, { day: 'numeric' });
        const _firstMonth = firstDate.toLocaleDateString(undefined, { month: 'short' });
        const _firstTime = firstDate
            .toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' })
            .toUpperCase();

        let period = '';

        if (this.summaryPeriod.value === 24) {
            period = _firstDate + '-' + _firstMonth + ' ' + _firstTime + ' - ' + _date + '-' + _month + ' ' + _time;
        } else {
            period = _firstTime + ' - ' + _time;
        }

        if (this.location !== undefined && this.summaryPeriod !== undefined) {
            const _hour = originalDate
                .toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' })
                .toUpperCase();
            this.generatedNameTag = `${this.location} | ${period} | Last updated at ${_date}-${_month} ${_hour}`;
        }
    }

    //#endregion
}
