/* eslint-disable no-undef */
import { reducer as RealTimeDataReducer, State as RealTimeDataState } from './real-time-data/real-time-data.reducer';
import { reducer as DashboardReducer, State as DashboardState } from './dashboard/dashboard.reducer';
import { reducer as EntityLogoReducer, State as EntityLogoState } from './entity-logo/entity-logo.reducer';
import { reducer as ReportSubscriptionReducer, State as ReportSubscriptionState } from './report/report.reducer';
import { reducer as LocationDataReducer, State as LocationState } from './locations/locations.reducer';
import { reducer as RtcpReceiversReducer, State as RtcpReceiversState } from './rtcp-receivers/rtcp-receivers.reducer';
import {
    reducer as MosCapableReceiversReducer,
    State as MosCapableReceiversState
} from './mos-capable-receivers/mos-capable-receivers.reducer';
import {
    reducer as TypeEquipmentsAtLocationDataReducer,
    State as TypeEquipmentsAtLocationState
} from './type-eqipments-at-location/type-eqipments-at-location.reducer';
import {
    reducer as EquipmentInfoDataReducer,
    State as EquipmentInfoState
} from './equipment-info/equipment-info.reducer';
import {
    reducer as ThresholdsBySettingsDataReducer,
    State as ThresholdsBySettingsState
} from './threesholds-by-settings/threesholds-by-settings.reducer';
import {
    reducer as SystemHealthEquipmentDataReducer,
    State as SystemHealthEquipmentState
} from './system-health-equipment/system-health-equipment.reducer';
import {
    reducer as RibbonSystemHealthEquipmentDataReducer,
    State as RibbonSystemHealthEquipmentState
} from './ribbon-system-health-equipment/ribbon-system-health-equipment.reducer';
import { ActionReducerMap, createSelector } from '@ngrx/store';

import { reducer as CustomersReducer, State as CustomersState } from './customers/customers.reducer';
import { colorReducer as BrandingColorReducer, State as BrandingState } from './branding/branding.reducer';

export interface AppState {
    dashboard: DashboardState;
    customerLogos: EntityLogoState;
    reportSubscriptions: ReportSubscriptionState;
    realTimeData: RealTimeDataState;
    locations: LocationState;
    rtcpReceivers: RtcpReceiversState;
    mosCapableReceivers: MosCapableReceiversState;
    typeEquipmentsAtLocation: TypeEquipmentsAtLocationState;
    equipmentInfo: EquipmentInfoState;
    thresholdsBySettings: ThresholdsBySettingsState;
    systemHealthEquipment: SystemHealthEquipmentState;
    ribbonSystemHealthEquipment: RibbonSystemHealthEquipmentState;
    customers: CustomersState;
    branding: BrandingState;
}

export const reducers: ActionReducerMap<AppState> = {
    dashboard: DashboardReducer,
    customerLogos: EntityLogoReducer,
    reportSubscriptions: ReportSubscriptionReducer,
    realTimeData: RealTimeDataReducer,
    locations: LocationDataReducer,
    rtcpReceivers: RtcpReceiversReducer,
    mosCapableReceivers: MosCapableReceiversReducer,
    typeEquipmentsAtLocation: TypeEquipmentsAtLocationDataReducer,
    equipmentInfo: EquipmentInfoDataReducer,
    thresholdsBySettings: ThresholdsBySettingsDataReducer,
    systemHealthEquipment: SystemHealthEquipmentDataReducer,
    ribbonSystemHealthEquipment: RibbonSystemHealthEquipmentDataReducer,
    customers: CustomersReducer,
    branding: BrandingColorReducer
};

export const dashboard = (state: AppState) => state.dashboard;

export const selectDashboardState = createSelector(dashboard, (state: DashboardState) => state.dashboardState);

export const selectDashletMeta = createSelector(dashboard, (state: DashboardState) => state.dashletMetaData);

const selectLogoState = (state: AppState) => state.customerLogos;
const selectReportSubscriptionState = (state: AppState) => state.reportSubscriptions;
const selectRealTimeDataState = (state: AppState) => state.realTimeData;
const selectLocationState = (state: AppState) => state.locations;
const selectRtcpReceiversState = (state: AppState) => state.rtcpReceivers;
const selectMosCapableReceiversState = (state: AppState) => state.mosCapableReceivers;
const selectTypeEquipmentsAtLocationState = (state: AppState) => state.typeEquipmentsAtLocation;
const selectEquipmentInfoState = (state: AppState) => state.equipmentInfo;
const selectSystemHealthEquipmentState = (state: AppState) => state.systemHealthEquipment;
const selectRibbonSystemHealthEquipmentState = (state: AppState) => state.ribbonSystemHealthEquipment;
const selectCustomersState = (state: AppState) => state.customers;

export const selectEntity = (id: string) =>
    createSelector(selectLogoState, (state: EntityLogoState) => state.entities[id]);

export const selectReportSubscription = (id: string) =>
    createSelector(selectReportSubscriptionState, (state: ReportSubscriptionState) => {
        return state.entities[id];
    });

export const selectProcessUpTime = (id: string) =>
    createSelector(selectRealTimeDataState, (state: RealTimeDataState) => state.processUpTime.entities[`${id}`]?.data);

export const selectProcessUpTimeMs = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.processUpTimeMs.entities[`${id}`]?.data
    );

export const selectProcessUsage = (id: string) =>
    createSelector(selectRealTimeDataState, (state: RealTimeDataState) => state.processUsage.entities[`${id}`]?.data);

export const selectProcessUsageTimer = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.processUsageTimer.entities[`${id}`]?.data
    );

export const selectHistoricCpuData = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.historicCpuData.entities[`${id}`]?.data
    );

export const selectMemoryUsage = (id: string) =>
    createSelector(selectRealTimeDataState, (state: RealTimeDataState) => state.memoryUsage.entities[`${id}`]?.data);

export const selectMemoryUsageDataExpired = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.memoryUsageDataExpired.entities[`${id}`]?.data
    );

export const selecDiskUsage = (id: string) =>
    createSelector(selectRealTimeDataState, (state: RealTimeDataState) => state.diskUsage.entities[`${id}`]?.data);

export const selectDiskUsageDataExpired = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.diskUsageDataExpired.entities[`${id}`]?.data
    );

export const selectNetworkConnectivityData = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.networkConnectivity.entities[`${id}`]?.data
    );

export const selectNetworkConnectivityDataExpired = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.networkConnectivityDataExpired.entities[`${id}`]?.data
    );

export const selectProcessAdminBackupData = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.processAdminBackupData.entities[`${id}`]?.data
    );

export const selectProcessMaintenanceBackupData = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.processMaintenanceBackupData.entities[`${id}`]?.data
    );

export const selectProcessAcd = (id: string) =>
    createSelector(selectRealTimeDataState, (state: RealTimeDataState) => state.processAcd.entities[`${id}`]?.data);

export const selectProcessAcdCalls = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.processAcdCalls.entities[`${id}`]?.data
    );

export const selectProcessSupervisorLicense = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.processSupervisorLicense.entities[`${id}`]?.data
    );

export const selectProcessWFMStatusRealTime = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.processWFMStatusRealTime.entities[`${id}`]?.data
    );

export const selectProcessWFMStatusHistoric = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.processWFMStatusHistoric.entities[`${id}`]?.data
    );

export const selectProcessThresholdData = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.processThresholdData.entities[`${id}`]?.data
    );

export const selectProcessNicNamesData = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.processNicNames.entities[`${id}`]?.data
    );

export const selectProcessHostNameData = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.processHostName.entities[`${id}`]?.data
    );

export const selectProcessTotalMemData = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.processTotalMem.entities[`${id}`]?.data
    );

export const selectProcessSqlData = (id: string) =>
    createSelector(selectRealTimeDataState, (state: RealTimeDataState) => state.processSqlData.entities[`${id}`]?.data);

export const selectProcessSqlDataTimer = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.processSqlDataTimer.entities[`${id}`]?.data
    );

export const selectProcessUpCpuMemData = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.processUpCpuMemData.entities[`${id}`]?.data
    );

export const selectProcessUpCpuMemDataTimer = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.processUpCpuMemDataTimer.entities[`${id}`]?.data
    );

export const selectProcessDiskData = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.processDiskData.entities[`${id}`]?.data
    );

export const selectProcessDiskDataTimer = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.processDiskDataTimer.entities[`${id}`]?.data
    );

export const selectProcessIISData = (id: string) =>
    createSelector(selectRealTimeDataState, (state: RealTimeDataState) => state.processIISData.entities[`${id}`]?.data);

export const selectProcessHyperVData = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.processHyperVData.entities[`${id}`]?.data
    );

export const selectProcessLyncMedServerData = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.processLyncMedServerData.entities[`${id}`]?.data
    );

export const selectProcessLyncFrontEndServerData = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.processLyncFrontEndServerData.entities[`${id}`]?.data
    );

export const selectProcessFlowControllerData = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.processFlowControllerData.entities[`${id}`]?.data
    );

export const selectProcessWorkflowData = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.processWorkflowData.entities[`${id}`]?.data
    );

export const selectProcessWatchedServiceData = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.processWatchedServiceData.entities[`${id}`]?.data
    );

export const selectProcessCallRateData = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.processCallRate.entities[`${id}`]?.data
    );

export const selectEntityTimer = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.entityTimer.entities[`${id.toLowerCase()}`]?.data
    );

export const selectDataFromCommonEntity = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.commonEntityData.entities[`${id.toLowerCase()}`]?.data
    );

export const selectSummaryDocument = (id: string) =>
    createSelector(
        selectRealTimeDataState,
        (state: RealTimeDataState) => state.summaryDocumentData.entities[`${id.toLowerCase()}`]?.data
    );

export const selectLocations = (id: string) =>
    createSelector(selectLocationState, (state: LocationState) => state.locations.entities[id]?.data);

export const selectRtcpReceivers = (id: string) =>
    createSelector(selectRtcpReceiversState, (state: RtcpReceiversState) => state.rtcpReceivers.entities[id]);

export const selectMosCapableReceivers = (id: string) =>
    createSelector(
        selectMosCapableReceiversState,
        (state: MosCapableReceiversState) => state.mosCapableReceivers.entities[id]
    );

export const selectTypeEquipmentsAtLocation = (id: string) =>
    createSelector(
        selectTypeEquipmentsAtLocationState,
        (state: TypeEquipmentsAtLocationState) => state.typeEquipmentsAtLocationData.entities[id]
    );

export const selectEquipmentInfo = (id: string) =>
    createSelector(selectEquipmentInfoState, (state: EquipmentInfoState) => state.equipmentInfo[id]);

export const selectSystemHealthEquipment = (id: string) =>
    createSelector(
        selectSystemHealthEquipmentState,
        (state: SystemHealthEquipmentState) => state.systemHealthEquipment.entities[id]
    );
export const selectRibbonSystemHealthEquipment = (id: string) =>
    createSelector(
        selectRibbonSystemHealthEquipmentState,
        (state: RibbonSystemHealthEquipmentState) => state.ribbonSystemHealthEquipment.entities[id]
    );

export const selectCustomers = (id: string) =>
    createSelector(selectCustomersState, (state: CustomersState) => state.customers.entities[id.toLowerCase()]?.data);
