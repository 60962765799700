import { Component } from '@angular/core';

@Component({
    selector: 'dashlet-section-header',
    template: `
        <div class="header">
            <div class="mat-h3 align-baseline gap-s">
                <ng-content></ng-content>
            </div>
        </div>
    `,
    styleUrls: ['./dashlet-section-header.component.scss']
})
export class DashletSectionHeaderComponent {
    constructor() {}
}
