import { Customer, Dashlet, Equipment, Location } from '@models/index';

export interface CallInfo {
    trunkGroup?: string;
    callActive: number;
    callAttempt: number;
    callCompl: number;
    videoCallActive: number;
    videoCallCompl: number;
}

interface DataBaseSummary {
    Instance: string;
    NumberofReplicatesCreated: string;
    Replicate_State: string;
}

export class DashletSystemHealthCiscoUCM extends Dashlet {
    public readonly commandTypeIdServer = '0CD94CC2-42F3-4C6C-9F8B-E9CAF2995261';
    public readonly commandTypeIdCpu = '37EF261A-5321-4F74-B8BD-0734D834C3C8';
    public readonly commandTypeIdMemory = 'D2E8814E-A42D-4922-81C8-87F4139E65BD';
    public readonly commandTypeIdRtmSip = '5701F3D9-7F82-4603-A789-84724FEC50E4';
    public readonly commandTypeIdRtmLbm = '442363F2-1E41-418E-9F36-8BA47715DE6B'; // no data for this document
    public readonly commandTypeIdConnectivity = '7BF15776-F312-40C7-92A1-F95DDEAD52EA';
    public readonly commandTypeIdFileSystem = '2FD0886C-3CE8-41A6-AF70-E1BE7AE1A4D4';
    public readonly commandTypeIdDatabaseReplicateInfo = '0D7600A6-1D0F-4AF6-B6B0-3BADBD210F72';
    public readonly commandTypeIdCallRateInfo = '1DF31494-BC77-4CA6-B92E-F2008A43B98B';

    readonly interval = 30;
    customer: Customer;
    location: Location;
    equipment: Equipment;

    databaseSummaryTally: DataBaseSummary;

    sipCalls: CallInfo[] = [];
    callRate: CallInfo[] = [];

    showFileSystem = false;
    showSystemChart = false;
    showNetworkGraph = false;
    showNetworkInterface = false;
    showSipCalls = false;
    showDatabaseSummary = false;
    showCallRate = false;

    constructor() {
        super();

        //sizing
        this.sizes = [
            {
                id: 0,
                label: 'Standard',
                cols: 5,
                rows: 4
            }
        ];
        this.applySize(0);

        //init data
        this.resetData();
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        //read settings object
        this.configured = v.customer && v.location && v.equipment;
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        if (v.location) {
            this.location = new Location(v.location.value, v.location.label);
        } else {
            this.location = new Location('', '');
        }
        if (v.equipment) {
            this.equipment = new Equipment(v.equipment.value, v.equipment.label);
        } else {
            this.equipment = new Equipment('', '');
        }
        this.generatedNameTag = this.configured ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;

        this.showFileSystem = v.showFileSystem || false;
        this.showSystemChart = v.showSystemChart || false;
        this.showNetworkGraph = v.showNetworkGraph || false;
        this.showNetworkInterface = v.showNetworkInterface || false;
        this.showSipCalls = v.showSipCalls;
        this.showDatabaseSummary = v.showDatabaseSummary || false;
        this.showCallRate = v.showCallRate || false;

        //apply size
        let h = 0;
        if (this.showFileSystem) h += 2;
        if (this.showSystemChart) h += 1.9;
        if (this.showNetworkGraph) h += 2.1;
        if (this.showNetworkInterface) h += 1.2;
        if (this.showSipCalls) h += 2;
        if (this.showCallRate) h += 2;
        if (this.showDatabaseSummary) h += 1.2;

        this.applySizeExpansion(0, h);
    }

    public processCallRateInfo(data: any) {
        const rows = data[0].data[0];
        this.callRate = []; // only keep the one record
        if (rows !== null) {
            this.callRate.push({
                callActive: rows.CallsActive,
                callAttempt: rows.CallsAttempted,
                callCompl: rows.CallsCompleted,
                videoCallActive: rows.VideoCallsActive,
                videoCallCompl: rows.VideoCallsCompleted
            });
        }
    }

    public processRtmSipData(data: any) {
        let rows;
        try {
            rows = data[0].data;
        } catch (error) {
            rows = data.Document[0].data; // http request
        }
        if (rows !== null) {
            for (let rowData of rows) {
                let index = this.sipCalls.findIndex(e => e.trunkGroup === rowData.Instance);
                if (index === -1) {
                    // instance does not yet exist
                    this.sipCalls.push({
                        trunkGroup: rowData.Instance,
                        callActive: rowData.CallsActive,
                        callAttempt: rowData.CallsAttempted,
                        callCompl: rowData.CallsCompleted,
                        videoCallActive: rowData.VideoCallsActive,
                        videoCallCompl: rowData.VideoCallsCompleted
                    });
                } else {
                    // update existing record
                    this.sipCalls[index] = {
                        trunkGroup: rowData.Instance,
                        callActive: rowData.CallsActive,
                        callAttempt: rowData.CallsAttempted,
                        callCompl: rowData.CallsCompleted,
                        videoCallActive: rowData.VideoCallsActive,
                        videoCallCompl: rowData.VideoCallsCompleted
                    };
                }
            }
        }
    }

    public processDatabaseInfo(data: any): void {
        const rows = data[0].data[0];
        if (rows !== null) {
            this.databaseSummaryTally = {
                Instance: rows.Instance,
                NumberofReplicatesCreated: rows.NumberofReplicatesCreated,
                Replicate_State: this.getDataBaseEnum(rows.Replicate_State)
            };
        }
    }

    private getDataBaseEnum(info: string): string {
        switch (info) {
            case '0':
                return 'Initializing';
            case '1':
                return 'Count Displayed Incorrect';
            case '2':
                return 'Good';
            case '3':
                return 'Bad Replication';
            case '4':
                return 'Setup Failed';
            default:
                return 'Setup Failed';
        }
    }

    dispose(): void {}

    resetData(): void {
        this.sipCalls = [];
    }
}
