import { Point, Sort } from '@models/index';

// "cip" stands for "calls in progress", which has now been changed to "streams in progress" (November 2019)

export class CustomerMOSSummaryData {
    private _name: string;
    private _id: string;
    private _mosData: Point[][]; // 3 different mos bands
    private _totalCalls: Point[];
    private _traffic: Point[];
    private _mosPercentage: number;
    private _cipTotal: number;
    private _cipPoor: number;
    private _cipSatisfactory: number;
    private cipLastUpdated!: Date;

    constructor(name: string, id: string) {
        this._name = name;
        this._id = id;
        this._mosData = [[], [], []];
        this._totalCalls = [];
        this._traffic = [];
        this._mosPercentage = 0;
        this._cipTotal = 0;
        this._cipPoor = 0;
        this._cipSatisfactory = 0;
    }

    public updateCIP(cipTotal: number, cipPoor: number, cipSatisfactory: number) {
        this._cipTotal = cipTotal;
        this._cipPoor = cipPoor;
        this._cipSatisfactory = cipSatisfactory;
    }

    public updateMOSData(mosData: number[], totalCalls: number, traffic: number, summaryPeriod: number, date: Date) {
        // Updating MOS Data
        let mosTotal = 0;
        let mosGood = 0;
        for (let i = 0; i < this.mosData.length; i++) {
            Sort.sortDates(this.mosData[i]);
            if (this.mosData[i].length >= summaryPeriod) {
                this.mosData[i].shift();
            }

            if (this.mosData[i].findIndex(e => e.x.getTime() === date.getTime()) === -1) {
                this.mosData[i].push({
                    x: date,
                    y: mosData[i]
                });
            }

            let subTotal = 0; // Use subtotal to avoid using more loops
            this.mosData[i].forEach(point => {
                subTotal += point.y;
            });
            mosTotal += subTotal;
            if (i === 2) {
                // Mos > 4
                mosGood = subTotal;
            }
        }

        if (!isNaN(mosTotal) && mosTotal !== 0) {
            this._mosPercentage = mosGood / mosTotal;
        } else {
            this._mosPercentage = -1;
        }

        Sort.sortDates(this.totalCalls);

        // Updating total calls data
        if (this.totalCalls.length >= summaryPeriod) {
            this.totalCalls.shift();
        }
        if (this.totalCalls.findIndex(e => e.x.getTime() === date.getTime()) === -1) {
            this.totalCalls.push({
                x: date,
                y: totalCalls
            });
        }

        Sort.sortDates(this.traffic);

        if (this.traffic.length >= summaryPeriod) {
            this.traffic.shift();
        }
        if (this.traffic.findIndex(e => e.x.getTime() === date.getTime()) === -1) {
            this.traffic.push({
                x: date,
                y: traffic
            });
        }
    }

    public get name(): string {
        return this._name;
    }

    public get id(): string {
        return this._id;
    }

    public get cipTotal(): number {
        return this._cipTotal;
    }

    public get cipPoor(): number {
        return this._cipPoor;
    }

    public get cipSatisfactory(): number {
        return this._cipSatisfactory;
    }

    /**
     * Getter mosData
     * @return {Point[][]}
     */
    public get mosData(): Point[][] {
        return this._mosData;
    }

    /**
     * Getter totalCalls
     * @return {Point[]}
     */
    public get totalCalls(): Point[] {
        return this._totalCalls;
    }

    /**
     * Getter totalCalls
     * @return {Point[]}
     */
    public get traffic(): Point[] {
        return this._traffic;
    }

    /**
     * Getter mosPercentage
     * @return {number}
     */
    public get mosPercentage(): number {
        return this._mosPercentage;
    }

    getPoorTotalByHours(hours: number) {
        const data = this.getMOSDataByHours(hours);

        return data.mosData[0];
    }

    getSatisfactoryTotalByHours(hours: number) {
        const data = this.getMOSDataByHours(hours);

        return data.mosData[1];
    }

    getGoodTotalByHours(hours: number) {
        const data = this.getMOSDataByHours(hours);

        return data.mosData[2];
    }

    public getMOSDataByHours(hours: number) {
        const mosData = new Array(3).fill(0);
        let traffic = 0;
        let totalCalls = 0;

        // sum last 'x' hours for each mos band
        for (let i = 0; i < this.mosData.length; i++) {
            const startIndex = this.mosData[i].length >= hours ? this.mosData[i].length - hours : 0;
            for (let j = startIndex; j < this.mosData[i].length; j++) {
                mosData[i] += this.mosData[i][j].y;
            }
        }

        // sum last 'x' hours for equipment, traffic, and total calls
        const startIndex = this.traffic.length >= hours ? this.traffic.length - hours : 0;
        for (let i = startIndex; i < this.traffic.length; i++) {
            traffic += this.traffic[i].y;
            totalCalls += this.totalCalls[i].y;
        }

        return {
            mosData: mosData,
            totalCalls: totalCalls,
            traffic: traffic
        };
    }
}
