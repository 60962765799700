/* eslint-disable no-undef */
import * as Actions from '@actions/index';
import { DashletService, TimeoutService } from '@services/index';
import { AppState, selectDataFromCommonEntity, selectEntity } from '@reducers/index';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ColumnTypes, CustomerLogo, DashletSystemHealthOracle } from '@models/index';
import { select, Store } from '@ngrx/store';
import { DashletSectionProcessorMemoryComponent } from '../dashlet-section-processor-memory/dashlet-section-processor-memory.component';

@Component({
    selector: 'app-dashlet-system-health-oracle',
    templateUrl: './dashlet-system-health-oracle.component.html',
    styleUrls: ['./dashlet-system-health-oracle.component.scss']
})
export class DashletSystemHealthOracleComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletSystemHealthOracle;

    @ViewChild('processorMemory') processorMemory: DashletSectionProcessorMemoryComponent;

    public titleMsg: string;

    public wanColumns: ColumnTypes[] = [
        {
            columnDef: 'name',
            header: 'Name'
        },
        {
            columnDef: 'status',
            header: 'Status'
        },
        {
            columnDef: 'duplex',
            header: 'Duplex'
        },
        {
            columnDef: 'speed',
            header: 'Speed'
        }
    ];

    public mediaColumns: ColumnTypes[] = [
        {
            columnDef: 'slotAndPort',
            header: 'Slot/Port'
        },
        {
            columnDef: 'rxPerc',
            header: 'Rx %'
        },
        {
            columnDef: 'txPerc',
            header: 'Tx %'
        }
    ];

    public realmsColumns: ColumnTypes[] = [
        {
            columnDef: 'name',
            header: 'Realm'
        },
        {
            columnDef: 'in',
            header: 'In'
        },
        {
            columnDef: 'out',
            header: 'Out'
        },
        {
            columnDef: 'totalBw',
            header: 'Total BW'
        },
        {
            columnDef: 'noBw',
            header: 'No BW'
        }
    ];

    constructor(
        private dashletService: DashletService,
        public timeoutService: TimeoutService,
        private store$: Store<AppState>
    ) {}

    ngOnInit() {
        this.titleMsg = this.timeoutService.getSystemDataExpiredmessage(
            this.timeoutService.formatSystemTimeoutString(
                this.processorMemory?.cpuUsageData?.dataExpired,
                false,
                this.processorMemory?.memoryUsageData?.dataExpired
            ),
            [this.dashlet.commandTypeIdCpu, this.processorMemory?.commandTypeIdMemoryUsage],
            this.dashlet.equipment.equipmentId
        );
        if (this.dashlet.configured) {
            this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            });
        }

        if (this.dashlet.equipment.equipmentId) {
            // wan data
            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    command: this.dashlet.commandTypeIdWan
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    commandTypeId: this.dashlet.commandTypeIdWan
                })
            );
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdWan)
                    )
                )
                .subscribe(data => {
                    if (data && data.length) {
                        this.dashlet.processWanData(data);
                    }
                });

            // media util data
            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    command: this.dashlet.commandTypeIdMediaUtilization
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    commandTypeId: this.dashlet.commandTypeIdMediaUtilization
                })
            );
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdMediaUtilization
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length) {
                        this.dashlet.processMediaUtilData(data);
                    }
                });

            // sessions data
            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    command: this.dashlet.commandTypeIdSessions
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    commandTypeId: this.dashlet.commandTypeIdSessions
                })
            );
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdSessions
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length) {
                        this.dashlet.processSessionsData(data);
                    }
                });

            // co-process sipd interface & mbcd realms data

            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    command: this.dashlet.commandTypeIdSipdInterface
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    commandTypeId: this.dashlet.commandTypeIdSipdInterface
                })
            );

            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    command: this.dashlet.commandTypeIdMbcdRealms
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    commandTypeId: this.dashlet.commandTypeIdMbcdRealms
                })
            );

            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdSipdInterface
                        )
                    )
                )
                .subscribe(res => {
                    const sipdInterfaceData = res;
                    this.store$
                        .pipe(
                            select(
                                selectDataFromCommonEntity(
                                    this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdMbcdRealms
                                )
                            )
                        )
                        .subscribe(data => {
                            if (sipdInterfaceData && sipdInterfaceData.length && data && data.length) {
                                this.dashlet.processRealmsData(sipdInterfaceData, data);
                            }
                        });
                });
        }
    }

    ngOnDestroy() {
        if (this.dashlet) this.dashlet.dispose();
    }

    getSeverityColor(severity: number): string {
        return this.dashletService.getSeverityColor(severity);
    }
}
