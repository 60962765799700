import { Action, createReducer, on } from '@ngrx/store';
import {
  Threshold
} from '@models/index';
import * as GetThresholdsBySettingsActions from '@actions/get-thresholds-by-settings/get-thresholds-by-settings.actions';

export const threesholdsBySettingsFeatureKey = 'threesholdsBySettings';

export interface State {
  thresholdsBySettings: Threshold[]
}

export const initialState: State = {
  thresholdsBySettings: []
};


export const reducer = createReducer(
  initialState,
  on(GetThresholdsBySettingsActions.GetThresholdsBySettingsSuccess, (state, { data }) => ({ ...state, thresholdsBySettings: data })),
);

