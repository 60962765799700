import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

//  Get Notify Memory usage 
export const GetNotifyMemoryUsage = createAction(
  '[MemoryUsage] Get Notify MemoryUsage',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetNotifyMemoryUsageSuccess = createAction(
  '[MemoryUsage] Get Notify MemoryUsage Success',
  props<{ data: RealTimeNotify }>()
);

export const GetNotifyMemoryUsageFailure = createAction(
  '[MemoryUsage] Get MemoryUsage Failure',
  props<{ error: any }>()
);

//  Get Memory usage 
export const GetMemoryUsage = createAction(
  '[MemoryUsage] Get Notify MemoryUsage',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetMemoryUsageSuccess = createAction(
  '[MemoryUsage] Get MemoryUsage Success',
  props<{ data: any}>()
);

export const GetMemoryUsageFailure = createAction(
  '[MemoryUsage] Get MemoryUsage Failure',
  props<{ error: any }>()
);


// Get Memory usage timer
export const GetMemoryUsageTimer = createAction(
  '[MemoryUsage] Get MemoryUsage Timer',
  props<{ equipmentId: string, command: string }>()
);

export const GetMemoryUsageTimerSuccess = createAction(
  '[MemoryUsage] Get MemoryUsage Timer Success',
  props<{ data: any}>()
);

export const GetMemoryUsageTimerFailure = createAction(
  '[MemoryUsage] Get MemoryUsage Timer Failure',
  props<{ error: any }>()
);
