import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as HistoricMemoryDataActions from '@actions/index';
import { of } from 'rxjs';
import { RealtimeGatewayService, TimeoutService } from '@services/index';
import { RealTimeNotify } from '@models/index';
import {
  catchError,
  map,
  switchMap,
} from 'rxjs/operators';


@Injectable()
export class HistoricMemoryDataEffects {

  constructor(private actions$: Actions, private realtimeGatewayService: RealtimeGatewayService) { }

  getHistoricMemoryData$ = createEffect(() => this.actions$.pipe(
    ofType(HistoricMemoryDataActions.GetHistoricMemoryData),
    switchMap((action) => this.realtimeGatewayService.requestHistoricDocument(action.equipmentId, action.commandTypeId, new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(), new Date().toISOString(), 20).pipe(
      map((data) => HistoricMemoryDataActions.GetHistoricMemoryDataSuccess({ data:{ data, equipmentId: action.equipmentId} })),
      catchError((err) => of(HistoricMemoryDataActions.GetHistoricMemoryDataFailure({ error: err })))
    ))
  ));

}
