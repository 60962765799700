<div class="flex-column gap-l" *ngIf="!openSubcontainer">
    <!-- system -->
    <dashlet-section-processor-memory
        #processorMemory
        [showSystemChart]="dashlet.showSystemChart"
        [equipment]="dashlet.equipment"
        [customerId]="dashlet.customer.customerId"
        [locationId]="dashlet.location.locationId"
    >
    </dashlet-section-processor-memory>
    <!-- network connectivity -->
    <dashlet-section-network-connectivity
        [showNetworkGraph]="dashlet.showNetworkConnectChart"
        [equipment]="dashlet.equipment"
        [customerId]="dashlet.customer.customerId"
    >
    </dashlet-section-network-connectivity>

    <div *ngIf="dashlet.showComponents" class="section">
        <dashlet-section-header>Components</dashlet-section-header>
        <!-- Network Interface -->
        <div class="flex-column gap-m">
            <dashlet-ratio-bar class="dashlet-cell">
                <dashlet-ratio-bar-item
                    [value]="getComponentsUp()"
                    [color]="getSeverityColor(0)"
                    [matTooltip]="getComponentsUp() + ' of ' + dashlet.components.length + ' up'"
                ></dashlet-ratio-bar-item>
                <dashlet-ratio-bar-item
                    [value]="getComponentsDown()"
                    [color]="getSeverityColor(2)"
                    [matTooltip]="getComponentsDown() + ' of ' + dashlet.components.length + ' down'"
                ></dashlet-ratio-bar-item>
            </dashlet-ratio-bar>
            <div class="justify-space-between">
                <h3 class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('component-drilldown', 0)">
                    <span class="mat-h2">{{ getComponentsUp() + ' ' }}</span>
                    <span class="mat-h3">Up</span>
                </h3>
                <h3 class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('component-drilldown', 2)">
                    <span class="mat-h2">{{ getComponentsDown() + ' ' }}</span>
                    <span class="mat-h3">Down</span>
                </h3>
            </div>
        </div>
    </div>

    <!-- Custom Scripts -->
    <dashlet-section-custom-scripts *ngIf="dashlet.showCustomScripts" [equipment]="dashlet.equipment" [customer]="dashlet.customer">
    </dashlet-section-custom-scripts>
</div>

<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'component-drilldown'"
    [title]="dashlet.location.locationName + ' | ' + dashlet.equipment.equipmentName"
    [color]="getSeverityColor(this.openSubcontainerSeverity)"
    [navPrevious]="'Engelbart Software ESuites System Health - ' + dashlet.customer.customerName"
    [navCurrent]="'Components Breakdown'"
    (close)="closeDetails()"
>
    <ng-container subtitle>
        Components Breakdown | &nbsp;
        <span *ngIf="this.openSubcontainerSeverity === 0">
            {{ getComponentsUp() + ' of ' + (getComponentsUp() + getComponentsDown()) + ' Up' }}
        </span>
        <span *ngIf="this.openSubcontainerSeverity === 2">
            {{ getComponentsDown() + ' of ' + (getComponentsUp() + getComponentsDown()) + ' Down' }}
        </span>
    </ng-container>
    <ng-container body>
        <mat-accordion multi>
            <mat-expansion-panel *ngFor="let component of getComponentList(this.openSubcontainerSeverity)">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{ component.name }} </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="flex gap-m">
                    <div class="flex-column text-align-right equipment-detail-labels">
                        <div *ngFor="let detail of getComponentDetails(component)" class="mat-body-2">{{ detail.name || '---'  }}</div>
                    </div>
                    <div class="flex-column equipment-detail-values">
                        <div *ngFor="let detail of getComponentDetails(component)" class="mat-body-1 ">{{ detail.data  || '---'  }}</div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        
    </ng-container>
    
    <div footer class="justify-end grow gap-m">
        <button mat-button [disabled]="openSubcontainerSeverity === 0" matTooltip="View Up" (click)="openDetails('component-drilldown', 0)">
            Up
        </button>

        <button mat-button [disabled]="openSubcontainerSeverity === 2" matTooltip="View Down" (click)="openDetails('component-drilldown', 2)">
            Down
        </button>
    </div>
</vsm-dashlet-subcontainer>
