import { Component, Injector, ViewChild, DoCheck, KeyValueDiffers, KeyValueDiffer } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

import { DashletSettingList } from '@models/index';

@Component({
    selector: 'dashlet-setting-list',
    templateUrl: 'dashlet-setting-list.component.html',
    styleUrls: ['./settings.component.scss']
})
export class DashletSettingListComponent implements DoCheck {
    @ViewChild('select') select: MatSelect;
    data: DashletSettingList<any> | null = null;

    public allSelected = false;
    private dataDiffer: KeyValueDiffer<string, any>;

    constructor(private injector: Injector, private differs: KeyValueDiffers) {
        this.data = this.injector.get('data');
        this.dataDiffer = this.differs.find({}).create();
    }

    ngDoCheck() {
        if (this.data) {
            const changes = this.dataDiffer.diff(this.data);
            if (changes) {
                this.allSelected = false;
            }
        }
    }

    public togglePerOne(): void {
        let newStatus = true;
        this.select.options.filter((item: MatOption) => {
            if (!item.selected) {
                newStatus = false;
            } else {
                return true;
            }
        });

        this.allSelected = newStatus;
    }
    public toggleAllSelection(): void {
        if (this.allSelected) {
            this.data.values = this.data.items.map(item => item.value);
        } else {
            this.data.values = [];
        }
        this.data!.change.next(null);
    }

    selectionChanged({ value }: any) {
        if (this.data.limitSelection && value.length >= this.data.limitSelection) {
            this.data.values = value.slice(0, this.data.limitSelection);
        } else {
            this.data.values = value;
        }
    }

    openedChange(opened: boolean) {
        if (!opened) {
            this.data.values = this.select.options.filter((item: MatOption) => item.selected).map(item => item.value);
            this.data.change.next(null);
        }
    }
}
