import * as Actions from '@actions/index';
import { Component, Input, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { DashletSIPBandwidth } from '@models/index';
import { DashletLineGraphComponent } from '../dashlet-line-graph/dashlet-line-graph.component';
import { DashletService, DashletDataProcessService, TimeService } from '@services/index';
import { select, Store } from '@ngrx/store';

import { AppState, selectEntity, selectDataFromCommonEntity } from '@reducers/index';

import { CustomerLogo } from '@models/index';
import { UIChart } from 'primeng/chart';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-dashlet-sipbandwidth',
    templateUrl: './dashlet-sipbandwidth.component.html',
    styleUrls: ['./dashlet-sipbandwidth.component.scss']
})
export class DashletSIPBandwidthComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletSIPBandwidth;

    @ViewChild('chart') chart!: UIChart;

    private pSIPBandwidthChart: DashletLineGraphComponent;
    get sipBandwidthChart() {
        return this.pSIPBandwidthChart;
    }
    @ViewChild('sipBandwidthChart') set sipBandwidthChart(value: DashletLineGraphComponent) {
        this.pSIPBandwidthChart = value;
        this.updateChart();
    }

    private pSIPBandwidthChartMedium: DashletLineGraphComponent;
    get sipBandwidthChartMedium() {
        return this.pSIPBandwidthChartMedium;
    }
    @ViewChild('sipBandwidthChartMedium') set sipBandwidthChartMedium(value: DashletLineGraphComponent) {
        this.pSIPBandwidthChartMedium = value;
        this.updateChart();
    }

    public totalUsageColor: string = this.dashletService.getChartColors().pink;
    private totalUsageDataset = {
        label: 'Total Usage (kbps)',
        borderColor: this.totalUsageColor,
        backgroundColor: this.totalUsageColor,
        pointRadius: 0,
        pointHitRadius: 10,
        data: []
    };

    public videoUsageColor: string = this.dashletService.getChartColors().blue;
    private videoUsageDataset = {
        label: 'Video Usage (kbps)',
        borderColor: this.videoUsageColor,
        backgroundColor: this.videoUsageColor,
        pointRadius: 0,
        pointHitRadius: 10,
        data: []
    };

    public audioUsageColor: string = '#000FB2';
    private audioUsageDataset = {
        label: 'Audio Usage (kbps)',
        borderColor: this.audioUsageColor,
        backgroundColor: this.audioUsageColor,
        pointRadius: 0,
        pointHitRadius: 10,
        data: []
    };

    public sipBandwidthChartData: any = {};
    public sipBandwidthChartOptions: any = {
        scales: {
            x: {
                type: 'time',
                grid: {
                    display: false
                },
                time: {
                    unit: 'minute',
                    displayFormats: {
                        minute: 'HH:mm'
                    },
                    stepSize: 1,
                    max: null,
                    min: null
                },
                ticks: {
                    fontSize: 10.2,
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0,
                    maxTicksLimit: 5
                }
            },
            y: {
                type: 'linear',
                min: 0,
                grid: {
                    display: false
                },
                ticks: {
                    fontSize: 10.2,
                    beginAtZero: true,
                    max: 1
                }
            }
        },
        animation: {
            duration: 0
        },
        plugins: {
            tooltip: {
                callback: {
                    title: (tipItem: any) => {
                        return null;
                    },
                    label: (tipItem: any, data: any) => {
                        let label = data.datasets[tipItem.datasetIndex].label || '';
                        if (label) {
                            label += ': ';
                        }
                        return label + (+tipItem.yLabel).toFixed(1);
                    }
                }
            },
            legend: {
                display: false,
                position: 'bottom'
            }
        }
    };

    private subscription: Subscription = new Subscription();

    constructor(
        private store$: Store<AppState>,
        private dashletService: DashletService,
        private dashletDataService: DashletDataProcessService,
        private timeService: TimeService
    ) {
        this.sipBandwidthChartData = {
            datasets: [this.totalUsageDataset, this.audioUsageDataset, this.videoUsageDataset]
        };
    }

    public ngOnInit(): void {
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );

        // SIP Bandwidth

        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdSIPBandwidth
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdSIPBandwidth
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdSIPBandwidth
                        )
                    )
                )
                .subscribe(data => {
                    if (data) {
                        this.dashlet.processSIPBandwidth2(data);
                        this.dashlet.onChartDataUpdated.next(null);
                    }
                })
        );

        this.store$.dispatch(
            Actions.GetCommonHistoric({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdSIPBandwidth,
                from: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                to: new Date().toISOString(),
                max: 50
            })
        );

        this.subscription.add(
            this.dashlet.onChartDataUpdated.subscribe(() => {
                this.updateChart();
            })
        );
    }

    public ngOnDestroy(): void {
        if (this.dashlet) this.dashlet.dispose();
        this.subscription.unsubscribe();
    }

    private updateChart(): void {
        this.totalUsageDataset.data = this.dashletDataService
            .dedupe(this.dashlet.sipBandwidth.totalUsageHistory)
            .map(point => {
                return { ...point, x: new Date(this.timeService.convertToUserTimeZone(point.x)) };
            });
        this.videoUsageDataset.data = this.dashletDataService
            .dedupe(this.dashlet.sipBandwidth.videoUsageHistory)
            .map(point => {
                return { ...point, x: new Date(this.timeService.convertToUserTimeZone(point.x)) };
            });
        this.audioUsageDataset.data = this.dashletDataService
            .dedupe(this.dashlet.sipBandwidth.audioUsageHistory)
            .map(point => {
                return { ...point, x: new Date(this.timeService.convertToUserTimeZone(point.x)) };
            });

        const dateString = new Date();
        const max = new Date(this.timeService.convertToUserTimeZone(dateString));
        max.setSeconds(Math.floor(max.getSeconds() / this.dashlet.interval) * this.dashlet.interval, 0);
        const min = new Date(max);
        min.setMinutes(min.getMinutes() - 40);

        this.sipBandwidthChartOptions.scales.x.min = min;
        this.sipBandwidthChartOptions.scales.x.max = max;

        this.chart.refresh();
    }

    get smallLayout() {
        return this.dashlet.getSize().id === 0;
    }

    get mediumLayout() {
        return this.dashlet.getSize().id === 1;
    }
}
