import { Customer, Dashlet, Equipment, Location, Threshold } from '@models/index';

export class DashletSystemHealthIpOffice extends Dashlet {
    public readonly commandTypeIdSystem = '12bfc50d-a768-4762-ab51-42896543a0bc';
    public readonly commandTypeIdSwitch = '72dd9413-9c2b-4974-98a5-96a4b0567de6';

    readonly interval = 30;
    customer!: Customer;
    location!: Location;
    equipment!: Equipment;
    thresholds: Threshold[] = [];

    showSystemChart = false;
    showNetworkGraph = false;

    constructor() {
        super();

        // sizing
        this.sizes = [
            {
                id: 0,
                label: 'Standard',
                cols: 5,
                rows: 3
            }
        ];
        this.applySize(0);

        // init data
        this.resetData();
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        // read settings object
        this.configured = v.customer && v.location && v.equipment;
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        if (v.location) {
            this.location = new Location(v.location.value, v.location.label);
        } else {
            this.location = new Location('', '');
        }
        if (v.equipment) {
            this.equipment = new Equipment(v.equipment.value, v.equipment.label);
        } else {
            this.equipment = new Equipment('', '');
        }
        this.generatedNameTag = this.configured ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;

        this.showSystemChart = v.showSystemChart || false;
        this.showNetworkGraph = v.showNetworkGraph || false;

        // apply size
        let h = 0;
        if (this.showSystemChart) {
            h += 3;
        }
        if (this.showNetworkGraph) {
            h += 2;
        }
        this.applySizeExpansion(0, h);
    }
    resetData(): void {}
    dispose() {}
}
