/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable no-undef */
import * as Actions from '@actions/index';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CustomerLogo, DashletSystemHealthLinuxServer, Threshold } from '@models/index';
import { Store, select } from '@ngrx/store';
import {
    AppState,
    selectEntity
} from '@reducers/index';
import {
    AccountService,
    ThresholdService,
    TileGridService,
    TimeoutService
} from '@services/index';
import { MenuItem } from 'primeng/api';
import { DashletSectionProcessorMemoryComponent } from '../dashlet-section-processor-memory/dashlet-section-processor-memory.component';
import { ThresholdItemComponent } from '../threshold-item/threshold-item.component';

@Component({
    selector: 'dashlet-system-health-linux-server',
    templateUrl: 'dashlet-system-health-linux-server.component.html',
    styleUrls: ['dashlet-system-health-linux-server.component.scss']
})
export class DashletSystemHealthLinuxServerComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletSystemHealthLinuxServer;
    @ViewChild('thresholdItem') thresholdItem: ThresholdItemComponent;
    @ViewChild('processorMemory') processorMemory: DashletSectionProcessorMemoryComponent;

    entityGroup: number;
    customerId: string;
    occupancyContextMenuItems: MenuItem[];
    openSubcontainer = '';
    openSubcontainerSeverity = 0;

    selectedField: string;
    selectedThreshold: Threshold;
    public expiredMessageTitle: string;

    constructor(
        private accountService: AccountService,
        private thresholdService: ThresholdService,
        private tileGridService: TileGridService,
        public timeoutService: TimeoutService,
        private store$: Store<AppState>,
    ) {}

    ngOnInit() {
        var userDetail = this.accountService.getUserDetails();
        this.entityGroup = userDetail.EntityGroup;
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
            if (logo) {
                this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
            }
        });
    }

    ngOnDestroy() {
        if (this.dashlet) this.dashlet.dispose();
    }

    openThresholdAlerts(elementId: string, commandTypeId: string, diskName?: string) {
        this.openSubcontainer = 'createThreshold';
        this.dashlet.displayHeader = false;
        this.selectedThreshold = new Threshold();
        this.selectedThreshold.commandTypeId = commandTypeId;
        this.selectedThreshold.field = elementId;
        this.selectedThreshold.whereCreated = 'Dashboard';
        if (diskName) this.selectedThreshold.fieldInstance = diskName;
    }

    openAlarmIconThreshold() {
        this.thresholdService.openThresholdManagement(this.accountService.getUserDetails().EntityId);
    }

    onSubmit() {
        this.thresholdItem.onSubmit().subscribe(result => {
            if (result) {
                this.selectedThreshold.dapThresholdId = result['DapThresholdId'];
                this.dashlet.thresholds.push(this.selectedThreshold);
                switch (this.selectedThreshold.field) {
                    case 'processor':
                        ++this.processorMemory.cpuUsageData.thresholdsNumber;
                        break;
                    case 'memory':
                        ++this.processorMemory.memoryUsageData.thresholdsNumber;
                        break;
                    case 'disk':
                        let diskUsage = this.processorMemory.diskUsageData.find(d => {
                            return d.diskName === this.selectedThreshold.fieldInstance;
                        });
                        if (diskUsage) {
                            ++diskUsage.thresholdsNumber;
                        }
                        break;
                    default:
                        break;
                }
                this.tileGridService.thresholdCreated(
                    this.selectedThreshold.name + ' threshold added via ' + this.dashlet.title
                );
            } else {
                this.tileGridService.thresholdCreated('Threshold add failed');
            }
        });
        this.closeDetails();
    }

    saveEdit() {
        this.thresholdItem.saveEdit().subscribe(result => {
            if (result) {
                this.tileGridService.thresholdCreated(
                    this.selectedThreshold.name + ' threshold edited via ' + this.dashlet.title
                );
            } else {
                this.tileGridService.thresholdCreated('Threshold edit failed');
            }
        });
        this.closeDetails();
    }

    openDetails(container: string, severity: number) {
        this.openSubcontainer = container;
        this.dashlet.displayHeader = false;
        this.openSubcontainerSeverity = severity;
    }

    closeDetails() {
        this.openSubcontainer = null;
        this.dashlet.displayHeader = true;
    }
}
