import { IpnrMap } from '..';
import { Subject } from 'rxjs';
import { DashboardService, DashletSettingsService } from '@services/index';
import { Customer, Dashlet } from '@models/index';

export class MosDataPoint {
    y!: number;
    x!: Date;
    total!: number;
}

class MosScoreCount {
    roundedScore!: number;
    count!: number;
}

export class DashletVoiceQualityMeanOpinionScoreScatter extends Dashlet {
    public readonly commandTypeIdRtcpNew = '9343C956-3F20-4451-8ADF-D19656E7F0FD';
    public readonly commandTypeIdLync = '73181898-00e7-41ce-8182-ae8d10a9f3a8';
    public readonly commandTypeIdIpnrLookup = '142e7a84-1b04-4c59-a645-9b9b1e5b5312';
    public customer!: Customer;
    public locationId!: string;
    public equipmentIds!: string[];

    public ipnrMap: IpnrMap = new IpnrMap();

    readonly interval = 30;

    private recentScores: { [source: string]: number[] } = {};
    private recentScoresNew: { [source: string]: MosScoreCount[] } = {};

    public mosDataGood: MosDataPoint[] = [];
    public mosDataSatisfactory: MosDataPoint[] = [];
    public mosDataPoor: MosDataPoint[] = [];
    public mosDataUnknown: MosDataPoint[] = [];

    public currentDataGood = 0;
    public currentDataSatisfactory = 0;
    public currentDataPoor = 0;
    public currentDataUnknown = 0;
    public equipmentLoaded: Subject<null> = new Subject();
    private dataUpdated: Subject<null> = new Subject();
    get onDataUpdated() {
        return this.dataUpdated;
    }

    constructor(private dashboardService: DashboardService, private settingsService: DashletSettingsService) {
        super();

        this.sizes = [
            {
                id: 0,
                label: 'Small',
                cols: 5,
                rows: 5
            },
            {
                id: 1,
                label: 'Large',
                cols: 10,
                rows: 5
            }
        ];
        this.applySize(0);

        this.dashboardService.getUpdateInterval(this.interval).subscribe(time => {
            this.updateMosDataNew(time);
        });
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        this.configured = v.customer && v.location && v.equipment;

        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        this.locationId = v.location && v.location.value ? v.location.value : null;
        const equipValue: string = v.equipment && v.equipment.value ? v.equipment.value : null;

        this.generatedNameTag =
            v.location && v.equipment ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;

        if (equipValue && this.locationId) {
            this.configured = true;
            if (equipValue === '=all=') {
                this.settingsService.getRtcpReceivers(this.locationId).subscribe(res => {
                    if (res) {
                        this.equipmentIds = res.map(receiver => receiver.value);
                        this.equipmentLoaded.next(null);
                    }
                });
            } else {
                this.equipmentIds = [equipValue];
                this.equipmentLoaded.next(null);
            }
        }
    }

    dispose() {}

    resetData() {
        this.mosDataGood = [];
        this.mosDataSatisfactory = [];
        this.mosDataPoor = [];
        this.mosDataUnknown = [];

        this.recentScores = {};
        this.recentScoresNew = {};

        this.currentDataGood = 0;
        this.currentDataSatisfactory = 0;
        this.currentDataPoor = 0;
        this.currentDataUnknown = 0;

        this.dataUpdated.next(null);
    }

    public processLyncData(key: string, data: any[]) {
        delete this.recentScores[key];

        if (data) {
            const scores: number[] = [];
            for (const row of data[0].data) {
                const mosFrom = +row.FromQualityScore;
                if (!isNaN(mosFrom)) {
                    scores.push(mosFrom);
                }
                const mosTo = +row.ToQualityScore;
                if (!isNaN(mosTo)) {
                    scores.push(mosTo);
                }
            }
            this.recentScores[key] = scores;
        }
    }

    public processRtcpDataNew(key: string, data: any[]) {
        delete this.recentScoresNew[key];

        if (data && data.length !== 0) {
            const scores: MosScoreCount[] = [];

            var rowData = data[0].data;
            rowData.forEach(item => {
                if (+item.Count > 0) {
                    const mosRoundValue = item.MosInterval.split('_')[2];
                    scores.push({
                        roundedScore: mosRoundValue,
                        count: +item.Count
                    });
                }
            });

            this.recentScoresNew[key] = scores;
        }
    }

    private updateMosDataNew(time: Date) {
        const before = new Date(time);

        if (this.getSize().id === 0) {
            before.setMinutes(before.getMinutes() - 30);
        } else if (this.getSize().id === 1) {
            before.setMinutes(before.getMinutes() - 60);
        }

        const truncateExpr = (p: MosDataPoint) => p.x.valueOf() >= before.valueOf();
        const newUnknown = this.mosDataUnknown.filter(truncateExpr);
        const newGood = this.mosDataGood.filter(truncateExpr);
        const newSatisfactory = this.mosDataSatisfactory.filter(truncateExpr);
        const newPoor = this.mosDataPoor.filter(truncateExpr);

        const unknownExpr = (p: MosDataPoint) => p.y < 0;
        const goodExpr = (p: MosDataPoint) => p.y >= 4.0;
        const satisfactoryExpr = (p: MosDataPoint) => p.y >= 3.6 && p.y < 4.0;
        const poorExpr = (p: MosDataPoint) => p.y >= 0 && p.y < 3.6;

        this.currentDataGood = 0;
        this.currentDataSatisfactory = 0;
        this.currentDataPoor = 0;
        this.currentDataUnknown = 0;

        const totalRecords: MosScoreCount[] = [];
        Object.keys(this.recentScoresNew).forEach(key => {
            if (this.recentScoresNew[key]) {
                totalRecords.push(...this.recentScoresNew[key]);
            }
        });
        totalRecords.forEach(score => {
            const roundedScore = score.roundedScore / 10;
            const point = {
                x: time,
                y: roundedScore,
                total: score.count
            };
            if (goodExpr(point)) {
                newGood.push(point);
                this.currentDataGood += score.count;
            }
            if (satisfactoryExpr(point)) {
                newSatisfactory.push(point);
                this.currentDataSatisfactory += score.count;
            }
            if (poorExpr(point)) {
                newPoor.push(point);
                this.currentDataPoor += score.count;
            }
            if (unknownExpr(point)) {
                newUnknown.push(point);
                this.currentDataUnknown += score.count;
            }
        });

        this.mosDataGood = newGood;
        this.mosDataSatisfactory = newSatisfactory;
        this.mosDataPoor = newPoor;
        this.mosDataUnknown = newUnknown;

        this.dataUpdated.next(null);
    }
}
