import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

export const GetNotifyProcessTotalMems = createAction(
  '[ProcessTotalMem] Get Notify ProcessTotalMems',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetNotifyProcessTotalMemsSuccess = createAction(
  '[ProcessTotalMem] Get Notify ProcessTotalMems Success',
  props<{ data: RealTimeNotify }>()
);

export const GetNotifyProcessTotalMemsFailure = createAction(
  '[ProcessTotalMem] Get Notify ProcessTotalMems Failure',
  props<{ error: any }>()
);


export const GetProcessTotalMems = createAction(
  '[ProcessTotalMem] Get ProcessTotalMems',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetProcessTotalMemsSuccess = createAction(
  '[ProcessTotalMem] Get ProcessTotalMems Success',
  props<{ data: any}>()
);

export const GetProcessTotalMemsFailure = createAction(
  '[ProcessTotalMem] Get ProcessTotalMems Failure',
  props<{ error: any }>()
);

