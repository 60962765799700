import * as ProcessSqlActions from '@actions/process-sql-data/process-sql-data.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService, TimeoutService } from '@services/index';
import { RealTimeNotify } from '@models/index';

@Injectable()
export class ProcessSqlDataEffects {
    constructor(
        private actions$: Actions,
        private realtimeGatewayService: RealtimeGatewayService,
        private timeoutService: TimeoutService
    ) {}

    getNotifyProcessSql$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessSqlActions.GetNotifyProcessSqlData),
            switchMap(action =>
                this.realtimeGatewayService.observe(action.equipmentId, action.commandTypeId).pipe(
                    catchError(err => of(ProcessSqlActions.GetNotifyProcessSqlDataFailure({ error: err }))),
                    mergeMap((data: RealTimeNotify) => [
                        ProcessSqlActions.GetNotifyProcessSqlDataSuccess({ data }),
                        ProcessSqlActions.GetProcessSqlData({ ...data })
                    ])
                )
            )
        )
    );

    getProcessSql$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessSqlActions.GetProcessSqlData),
            mergeMap(action =>
                this.realtimeGatewayService.getDocument(action.equipmentId, action.commandTypeId).pipe(
                    map(data =>
                        ProcessSqlActions.GetProcessSqlDataSuccess({ data: { data, equipmentId: action.equipmentId } })
                    ),
                    catchError(err => of(ProcessSqlActions.GetProcessSqlDataFailure({ error: err })))
                )
            )
        )
    );

    getProcessSqlTimer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessSqlActions.GetProcessSqlDataTimer),
            switchMap(action =>
                this.timeoutService.observeTimerNew(action.equipmentId, action.command).pipe(
                    catchError(err => of(ProcessSqlActions.GetProcessSqlDataTimerFailure({ error: err }))),
                    map((data: any) =>
                        ProcessSqlActions.GetProcessSqlDataTimerSuccess({
                            data: { data, equipmentId: action.equipmentId }
                        })
                    )
                )
            )
        )
    );

    getHistoricSqlData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessSqlActions.GetHistoricSqlData),
            switchMap(action =>
                this.realtimeGatewayService
                    .requestHistoricDocument(
                        action.equipmentId,
                        action.commandTypeId,
                        new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                        new Date().toISOString(),
                        20
                    )
                    .pipe(
                        map(data =>
                            ProcessSqlActions.GetHistoricSqlDataSuccess({
                                data: { data, equipmentId: action.equipmentId }
                            })
                        ),
                        catchError(err => of(ProcessSqlActions.GetHistoricSqlDataFailure({ error: err })))
                    )
            )
        )
    );
}
