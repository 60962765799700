import { SelectItem } from './SelectItem';

export class ThresholdRule {
	fieldName!: string;
	ruleOperator!: string;
	ruleValue!: string;
}

export class ThresholdRuleGroup {
	groupId!: string; // not used for now
	rules!: ThresholdRule[];
}

export class Threshold {
	id!: string;
	dapThresholdId!: string;
	dapThresholdTemplateId!: string;
	entityId!: string;
	name!: string;
	active: Boolean = false;
	commandTypeId!: string;
	dAPMessageFullClassName!: string;

	alarmName!: string;
	customerId!: string;
	locationId!: string;
	equipmentId!: string;
	adminId!: string;
	lastUpdated!: Date;
	description: string = '';

	field!: string;
	fieldOptions!: SelectItem[];
	fieldNumber!: number; // for trunk list
	fieldTrunkType!: string; // for trunk
	fieldInstance:string = '';
	fieldAttributes: any;
	operator!: string;
	value!: string;
	jSONRules!: string;
	whereCreated!: string;
	validFields!: Map<any, any>;
	additionalRuleGroup!: ThresholdRuleGroup; // can extend to groups later
	configuredCorrectly = true;
}

export class ThresholdTemplate {
	thresholdTemplateId!: string;
	thresholdTemplateName!: string;
	commandTypeId!: string;

	className!: string;
	validField!: string[];
	inValidField!: string[];
	alarmName!: string;
	administratorId!: string;

	choosenField!: string;
	fieldInstance!: string;
	fieldAttributes: any;
	op!: string;
	value!: string;

	isActive!: false;
}

