<div class="flex-column gap-l">
    <ng-container *ngIf="!openSubcontainer">
        <!-- system -->
        <div class="justify-space-between">
            <div class="flex gap-m">
                <div class="flex-column text-align-right equipment-detail-labels">
                    <div class="mat-body-2">Streams</div>
                    <div class="mat-body-2">Equipment</div>
                    <div *ngIf="smallLayout" class="mat-body-2">Traffic</div>
                    <div *ngIf="smallLayout" class="mat-body-2">Call Quality</div>
                </div>
                <div class="flex-column equipment-detail-values">
                    <div *ngIf="dashlet.mosSummaryMap" class="mat-body-1" dashlet-link="true" (click)="openVQM('-2')" linkTitle="View in VQM">
                        {{ dashlet.combinedMosData?.totalCalls }}
                    </div>
                    <div class="mat-body-1">
                        <span *ngIf="dashlet.equipment === 'All'">{{ dashlet.receivers.length }}&nbsp;</span>
                        <span *ngIf="dashlet.equipment === 'All'">{{ dashlet.receivers.length > 1 ? 'Receivers' : 'Receiver' }}</span>
                        <span *ngIf="dashlet.receivers.length === 1">
                            {{ dashlet.receivers[0].equipmentName }}
                        </span>
                    </div>
                    <div *ngIf="smallLayout" class="mat-body-1">
                        <span *ngIf="dashlet.mosSummaryMap">{{ dashlet.combinedMosData?.traffic | number: '1.0-3' }}</span>
                        <span>GB data monitored</span>
                    </div>
                    <div *ngIf="smallLayout" class="mat-body-1">
                        <span *ngIf="dashlet.mosSummaryMap"
                            >{{ getPercentageTotalCalls(dashlet.combinedMosData?.mosData[5] + dashlet.combinedMosData?.mosData[4]) }}%</span
                        >
                        <span> streams &#62;&#61; 4.0 MOS</span>
                    </div>
                </div>
            </div>

            <div *ngIf="!smallLayout" class="flex gap-m">
                <div class="flex-column text-align-right equipment-detail-labels">
                    <div class="mat-body-2">Traffic</div>
                    <div class="mat-body-2">Call Quality</div>
                </div>
                <div class="flex-column equipment-detail-values">
                    <div class="mat-body-1">
                        <span *ngIf="dashlet.mosSummaryMap">{{ dashlet.combinedMosData?.traffic | number: '1.0-3' }}</span>
                        <span>GB data monitored</span>
                    </div>
                    <div class="mat-body-1">
                        <span *ngIf="dashlet.mosSummaryMap"
                            >{{ getPercentageTotalCalls(dashlet.combinedMosData?.mosData[5] + dashlet.combinedMosData?.mosData[4]) }}%</span
                        >
                        <span> streams &#62;&#61; 4.0 MOS</span>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Small Dashlet Markup -->
    <div *ngIf="smallLayout && !openSubcontainer">
        <ng-container *ngTemplateOutlet="mosTable"></ng-container>
    </div>

    <!-- Large Dashlet Markup -->
    <div *ngIf="!smallLayout" class="flex gap-l">
        <mat-accordion multi class="grow">
            <mat-expansion-panel (opened)="handleTabOpen(0)" (afterCollapse)="handleTabClose(0)" [expanded]="dashlet.tabsOpen.indexOf(0) !== -1 || hugeLayout">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span [matTooltip]="getMosSummaryToolTip()" class="mat-body-1"
                            >MOS Summary
                            <sup>?</sup>
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="flex-column gap-l">
                    <div *ngIf="manageThreshold === false">
                        <div *ngIf="mosSummaryLoading" class="justify-center align-center mos-panel">
                            <ng-container *ngTemplateOutlet="vsmLoadingTemplate"> </ng-container>
                        </div>
                        <ng-container *ngIf="MosSummaryTableDisplay.length !== 0 || mosSummaryLoading; else emptySummaryTable">
                            <ng-container *ngTemplateOutlet="mosSummaryPanel"> </ng-container>
                        </ng-container>
                        <ng-template #emptySummaryTable>
                            <div style="text-align: center">There was no traffic for the selected summary period.</div>
                        </ng-template>
                    </div>
                    <ng-container *ngIf="manageThreshold === true">
                        <vsm-dashlet-subcontainer
                            [navPrevious]="'VQM Summary - ' + dashlet.customer.customerName"
                            [navCurrent]="(openSubcontainer === 'createThreshold' ? 'Create' : 'Edit') + ' Threshold'"
                            (close)="closeDetails()"
                        >
                            <ng-container title
                                >{{ openSubcontainer === 'createThreshold' ? 'New' : 'Edit' }} {{ selectedField }}
                                Threshold
                            </ng-container>
                            <ng-container class="threshold-ui" body>
                                <threshold-item #thresholdItem [settings]="dashlet.getSettings()" [type]="'vqmSummary'" [threshold]="selectedThreshold">
                                </threshold-item>
                            </ng-container>
                            <div footer class="row row-buttons-threshold pull-right mr-3">
                                <div *ngIf="openSubcontainer === 'editThreshold'; else addThreshold">
                                    <button
                                        type="submit"
                                        pButton
                                        class="btn button-details"
                                        (click)="saveEdit()"
                                        [disabled]="!thresholdItem.formValid()"
                                        label="Save"
                                    ></button>
                                </div>
                                <ng-template #addThreshold>
                                    <button
                                        type="submit"
                                        pButton
                                        class="btn button-details"
                                        (click)="onSubmit()"
                                        [disabled]="!thresholdItem.formValid()"
                                        label="Save"
                                    ></button>
                                </ng-template>
                                <div>
                                    <button type="button" pButton class="btn button-details" (click)="closeDetails()" label="Cancel"></button>
                                </div>
                            </div>
                        </vsm-dashlet-subcontainer>
                    </ng-container>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel
                (opened)="handleTabOpen(1)"
                (afterCollapse)="handleTabClose(1)"
                [disabled]="isCiscoSystems"
                [expanded]="dashlet.tabsOpen.indexOf(1) !== -1 || hugeLayout"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span [matTooltip]="getDSCPToolTip()" class="mat-body-1">
                            Breakdown by DSCP, IPNR, Codec, Layer 3 and Call Center
                            <span *ngIf="isCiscoSystems"> currently not available for Cisco equipment</span>
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="flex-column gap-l">
                    <div class="flex-column gap-m">
                        <label class="mat-caption" id="radio-group-label">Group By</label>
                        <mat-radio-group aria-labelledby="radio-group-label" class="flex gap-l" color="primary" [(ngModel)]="selectedToggle">
                            <mat-radio-button [value]="'toggledDSCP'"> DSCP </mat-radio-button>
                            <mat-radio-button [value]="'toggledIPNR'"> IPNR </mat-radio-button>
                            <mat-radio-button [value]="'toggledCODEC'"> Codec </mat-radio-button>
                            <mat-radio-button [value]="'toggledLayer3'"> Layer 3 Device </mat-radio-button>
                            <mat-radio-button [value]="'toggledCMS'"> Call Center </mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div class="breakdown-panel grow flex-column justify-center" *ngIf="selectedToggle === 'toggledDSCP'">
                        <ng-container *ngIf="!this.DSCPLoading; else vsmLoadingTemplate"
                            ><ng-container *ngIf="DSCPDataRaw.length !== 0; else noTrafficforSummaryPeriodTemplate">
                                <ng-container *ngTemplateOutlet="breakdownByDSCPPanelv2"></ng-container>
                            </ng-container>
                        </ng-container>
                    </div>

                    <div class="breakdown-panel grow flex-column justify-center" *ngIf="selectedToggle === 'toggledIPNR'">
                        <ng-container *ngIf="!this.IPNRLoading; else vsmLoadingTemplate"
                            ><ng-container *ngIf="IPNRDataRaw.length !== 0; else noTrafficforSummaryPeriodTemplate">
                                <ng-container *ngTemplateOutlet="breakdownByIPNRPanelv2"></ng-container> </ng-container
                        ></ng-container>
                    </div>

                    <div class="breakdown-panel grow flex-column justify-center" *ngIf="selectedToggle === 'toggledCODEC'">
                        <ng-container *ngIf="!this.CodecLoading; else vsmLoadingTemplate"
                            ><ng-container *ngIf="CodecDataRaw.length !== 0; else noTrafficforSummaryPeriodTemplate">
                                <ng-container *ngTemplateOutlet="breakdownByCODECPanelv2"></ng-container> </ng-container
                        ></ng-container>
                    </div>
                    <div class="breakdown-panel grow flex-column justify-center" *ngIf="selectedToggle === 'toggledLayer3'">
                        <ng-container *ngIf="!this.Layer3Loading; else vsmLoadingTemplate"
                            ><ng-container *ngIf="Layer3DataRaw.length !== 0; else noTrafficforSummaryPeriodTemplate">
                                <ng-container *ngTemplateOutlet="breakdownByLayer3Panelv2"></ng-container> </ng-container
                        ></ng-container>
                    </div>
                    <div class="breakdown-panel grow flex-column justify-center" *ngIf="selectedToggle === 'toggledCMS'">
                        <ng-container *ngIf="!this.CMSLoading; else vsmLoadingTemplate"
                            ><ng-container *ngIf="callCenterData.length !== 0; else noTrafficforSummaryPeriodTemplate">
                                <ng-container *ngTemplateOutlet="breakdownByCMSPanelv2"></ng-container> </ng-container
                        ></ng-container>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel
                *ngIf="largeLayout"
                (opened)="handleTabOpen(2)"
                (afterCollapse)="handleTabClose(2)"
                [expanded]="dashlet.tabsOpen.indexOf(2) !== -1"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="mat-body-1"
                            >MOS Summary Over {{ dashlet.summaryPeriod.value }} Hours
                            <sup class="super-script">?</sup>
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="flex-column gap-l">
                    <div class="breakdown-panel grow flex-column justify-center">
                        <ng-container *ngIf="!this.MosOverTimeLoading; else vsmLoadingTemplate"
                            ><ng-container *ngIf="MosTimeDataRaw.length !== 0; else noTrafficforSummaryPeriodTemplate">
                                <ng-container *ngTemplateOutlet="mosSummaryHoursPanelv2"> </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion *ngIf="hugeLayout" style="min-width: 50%">
            <mat-expansion-panel
                (opened)="handleTabOpen(2)"
                (afterCollapse)="handleTabClose(2)"
                [expanded]="true"
                [ngStyle]="{ height: dashlet.tabsOpen.indexOf(2) !== -1 ? '100%' : 'auto' }"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="mat-body-1"
                            >MOS Summary Over {{ dashlet.summaryPeriod.value }} Hours
                            <sup class="super-script">?</sup>
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="flex-column gap-l">
                    <div class="breakdown-panel grow flex-column justify-center">
                        <ng-container *ngIf="!this.MosOverTimeLoading; else vsmLoadingTemplate"
                            ><ng-container *ngIf="!mosSummaryOverTimeNoResult; else noTrafficforSummaryPeriodTemplate">
                                <ng-container *ngTemplateOutlet="IPNRSummaryHoursPanelv2"> </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <!-- Empty summary period template -->
    <ng-template #noTrafficforSummaryPeriodTemplate>
        <div class="mt-3" style="text-align: center">There was no traffic for the selected summary period.</div>
    </ng-template>

    <ng-template #mosSummaryPanel>
        <div class="flex-column gap-m">
            <div *ngIf="MosSummaryTableDisplay.length !== 0">
                <vsm-smart-alex [type]="['MosSummary']" [typeData]="[MosSummaryTableDisplay]"> </vsm-smart-alex>
            </div>

            <div *ngIf="MosSummaryTableDisplay.length !== 0" class="justify-space-between">
                <p-chart #totalPie type="pie" [data]="totalMosSummaryData" [options]="totalPieOptions" width="140" height="150"></p-chart>

                <svg *ngIf="lowerLineStart && upperLineStart" height="150" width="200">
                    <line x1="0" [attr.y1]="upperLineStart" x2="200" y2="20" style="stroke: rgb(150, 150, 150); stroke-width: 1" />
                    <line x1="0" [attr.y1]="lowerLineStart" x2="200" y2="150" style="stroke: rgb(150, 150, 150); stroke-width: 1" />
                </svg>

                <p-chart #partialPie type="pie" [data]="partialMosSummaryData" [options]="partialPieOptions" width="140" height="150"></p-chart>
            </div>
            <ng-container *ngTemplateOutlet="mosTable"></ng-container>
        </div>
    </ng-template>

    <!-- Breakdown by DSCP Panel V2-->
    <ng-template #breakdownByDSCPPanelv2>
        <vsm-smart-alex *ngIf="DSCPDataRaw.length !== 0" [type]="['DSCP']" [typeData]="[DSCPDataRaw]"></vsm-smart-alex>

        <!-- dscp breakdown table -->
        <dashlet-table
            [tableHeightRows]="5"
            [rowExpansionField]="'expansion'"
            [rowExpansionColumns]="ipnrColumnExpansion"
            [dataSource]="DSCPDataRaw"
            [columns]="dscpColumns"
            [pagination]="true"
            [pageLength]="5"
        ></dashlet-table>
    </ng-template>

    <!-- Breakdown by IPNR Panel V2-->
    <ng-template #breakdownByIPNRPanelv2>
        <vsm-smart-alex *ngIf="IPNRDataRaw.length !== 0" [type]="['IPNR']" [typeData]="[IPNRDataRaw]"></vsm-smart-alex>
        <dashlet-table
            [tableHeightRows]="5"
            [rowExpansionField]="'expansion'"
            [rowExpansionColumns]="dscpColumnsExpansion"
            [dataSource]="IPNRDataRaw"
            [columns]="ipnrColumn"
            [pagination]="true"
            [pageLength]="5"
            [pageSizeOptions]="[5, 10, 20]"
        ></dashlet-table>
    </ng-template>

    <!-- Breakdown by CODEC Panel V2-->
    <ng-template #breakdownByCODECPanelv2>
        <vsm-smart-alex *ngIf="CodecDataRaw.length !== 0" [type]="['Codec']" [typeData]="[CodecDataRaw]"></vsm-smart-alex>
        <dashlet-table
            [tableHeightRows]="5"
            [rowExpansionField]="'expansion'"
            [rowExpansionColumns]="ipnrColumnExpansion"
            [dataSource]="CodecDataRaw"
            [columns]="codecColumn"
            [pagination]="true"
            [pageLength]="5"
        ></dashlet-table>
    </ng-template>

    <!-- Breakdown by Layer 3 Device Panel V2-->
    <ng-template #breakdownByLayer3Panelv2>
        <vsm-smart-alex *ngIf="Layer3DataRaw.length !== 0" [type]="['Layer3']" [typeData]="[Layer3DataRaw]"></vsm-smart-alex>
        <dashlet-table
            [tableHeightRows]="5"
            [rowExpansionField]="'expansion'"
            [rowExpansionColumns]="dscpColumnsExpansion"
            [dataSource]="Layer3DataRaw"
            [columns]="layer3Column"
            [pagination]="true"
            [pageLength]="5"
        ></dashlet-table>
    </ng-template>

    <!-- Breakdown by CMS Device Panel V2-->
    <ng-template #breakdownByCMSPanelv2>
        <div class="flex gap-s">
            <vsm-smart-alex [type]="['CMSData']" [typeData]="[CMSAgentDataRaw, dashlet.MasterCMSEquipment, dashlet.MasterCMSEquipmentWithData]">
            </vsm-smart-alex>
            <mat-form-field appearance="outline">
                <mat-label>Table Filter</mat-label>
                <mat-select (selectionChange)="cmsFilterOnChangeByType($event)" [(value)]="selectedCMSFilter">
                    <mat-option *ngFor="let option of cmsOptionItems" [value]="option.value">
                        {{ option.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <dashlet-table
            *ngIf="showCMSDataTable"
            [tableHeightRows]="5"
            [dataSource]="callCenterData"
            [columns]="callCenterColumns"
            [pagination]="true"
            [pageLength]="5"
        ></dashlet-table>
    </ng-template>
    <!-- Breakdown by CMS Device Panel V2-->

    <!-- MOS Summary Over x Hours Panel V2-->
    <ng-template #mosSummaryHoursPanelv2>
        <div class="flex-column gap-m">
            <ng-container *ngIf="MosTimeMappedData.length !== 0">
                <vsm-smart-alex [type]="['Time']" [typeData]="[MosTimeDataRaw]" [summaryPeriod]="dashlet.summaryPeriod.value"></vsm-smart-alex>
            </ng-container>

            <p-chart #mosOverTimeLine type="line" [data]="mosSummaryOverTimeData" [options]="mosSummaryOverTimeOptions">
            </p-chart>
        </div>
    </ng-template>

    <ng-template #IPNRSummaryHoursPanelv2>
        <div class="flex-column gap-l">
            <ng-container *ngIf="MosTimeMappedData.length !== 0">
                <vsm-smart-alex [type]="['TimeIpnr']" [typeData]="[IPNRMapList]"> </vsm-smart-alex>
            </ng-container>

            <p-chart #mosOverTimeLine type="line" [data]="mosSummaryOverTimeData" [options]="mosSummaryOverTimeOptions" [width]="'600'" [height]="'272'">
            </p-chart>
            <dashlet-table
                [tableHeightRows]="12"
                [dataSource]="mosSummaryOverTimeIPNRData"
                [columns]="mosSummaryColumns"
                [pagination]="true"
                [pageLength]="10"
            ></dashlet-table>
        </div>
    </ng-template>

    <!-- mos table -->
    <ng-template #mosTable>
        <dashlet-table
            [dataSource]="mosTableDataSource"
            [columns]="mosTableColumns"
            [tableHeightRows]="3"
            [menuItems]="occupancyContextMenuItems"
            [sortable]="false"
        ></dashlet-table>
        <div class="mos-table" [ngClass]="{ small: smallLayout, large: largeLayout }"></div>
    </ng-template>

    <!-- loading template -->
    <ng-template #vsmLoadingTemplate>
        <vsm-dashlet-loading></vsm-dashlet-loading>
    </ng-template>

    <!-- create thresholds alerts drilldown -->
    <vsm-dashlet-subcontainer
        *ngIf="openSubcontainer === 'createThreshold' || openSubcontainer === 'editThreshold'"
        [navPrevious]="'VQM Summary - ' + dashlet.customer.customerName"
        [navCurrent]="(openSubcontainer === 'createThreshold' ? 'Create' : 'Edit') + ' Threshold'"
        (close)="closeDetails()"
    >
        <ng-container title>{{ openSubcontainer === 'createThreshold' ? 'New' : 'Edit' }} {{ selectedField }} Threshold </ng-container>
        <ng-container body>
            <threshold-item #thresholdItem [settings]="dashlet.getSettings()" [type]="'vqmSummary'" [threshold]="selectedThreshold"> </threshold-item>
        </ng-container>
        <div footer class="row row-buttons-threshold pull-right mr-3">
            <div *ngIf="openSubcontainer === 'editThreshold'; else addThreshold">
                <button type="submit" pButton class="btn button-details" (click)="saveEdit()" [disabled]="!thresholdItem.formValid()" label="Save"></button>
            </div>
            <ng-template #addThreshold>
                <button type="submit" pButton class="btn button-details" (click)="onSubmit()" [disabled]="!thresholdItem.formValid()" label="Save"></button>
            </ng-template>
            <div>
                <button type="button" pButton class="btn button-details" (click)="closeDetails()" label="Cancel"></button>
            </div>
        </div>
    </vsm-dashlet-subcontainer>
</div>
