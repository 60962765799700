import * as ProcessFlowControllerDataActions from '@actions/process-flow-controller-data/process-flow-controller-data.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService } from '@services/index';
import { RealTimeNotify } from '@models/index';



@Injectable()
export class ProcessFlowControllerDataEffects {

  constructor(private actions$: Actions, private realtimeGatewayService: RealtimeGatewayService) { }

  getNotifyProcessFlowControllerData$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessFlowControllerDataActions.GetNotifyProcessFlowControllerData),
    switchMap((action) => this.realtimeGatewayService.observe(action.equipmentId, action.commandTypeId).pipe(
      catchError((err) => of(
        ProcessFlowControllerDataActions.GetNotifyProcessFlowControllerDataFailure({ error: err })
        )
      ),
      mergeMap((data: RealTimeNotify) => [
        ProcessFlowControllerDataActions.GetNotifyProcessFlowControllerDataSuccess({ data }),
        ProcessFlowControllerDataActions.GetProcessFlowControllerData({ ...data })
      ],
      ),
    ),
    )
  ));

  getProcessFlowControllerData$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessFlowControllerDataActions.GetProcessFlowControllerData),
    mergeMap((action) => this.realtimeGatewayService.getDocument(action.equipmentId, action.commandTypeId).pipe(
      map((data) => ProcessFlowControllerDataActions.GetProcessFlowControllerDataSuccess({ data:{ data, equipmentId: action.equipmentId} }),
      ),
      catchError((err) => of(ProcessFlowControllerDataActions.GetProcessFlowControllerDataFailure({ error: err })))
    ))
  ));

}
