import { Customer, Dashlet, Equipment, Location } from '@models/index';
import { Subject } from 'rxjs';

interface AudioCodesEthernet {
    Title: string;
    Active: string;
    Speed: string;
    Duplex: string;
    State: string;
    GroupMem: string;
}

interface AudioCodesIPInterface {
    Title: string;
    ActiveState: string;
    Application: string;
    IpAddress: string;
    InterfaceMode: string;
    PrefixLength: string;
    Gateway: string;
    PrimaryDNS: string;
    SecondaryDNS: string;
    UnderlyingDevice: string;
    UnderlyingInterface: string;
}

interface AudioCodesDeviceVersionDetails {
    serialNumber: string;
    ipAddress: string;
    productType: string;
    versionID: string;
}

interface AudioCodesUser {
    userName: string;
    ipAddress: string;
}

export class DashletSystemHealthAudioCodes extends Dashlet {
    // COMMANDS
    public readonly commandTypeIdOccupancy = '2ABD0F70-2306-448D-84F8-0D8B9702574E';
    public readonly commandTypeIdLoggedInUseres = 'BEB50E29-A87D-4523-ADE3-FE6CC4EA36D3';
    public readonly commandTypeIdEthernet = 'AB26E635-A335-400E-94D1-43B4098E7427';
    public readonly commandTypeIdIpInterface = '033B4E27-07AE-4A90-9C60-C89202EEA7E4';
    public readonly commandTypeIdConnectivity = '7BF15776-F312-40C7-92A1-F95DDEAD52EA';
    public readonly commandTypeIdDeviceVersion = 'F257EF59-5878-483A-B0F8-9F150D06A0EC';
    public readonly commandTypeIdSystemUptime = '0CD94CC2-42F3-4C6C-9F8B-E9CAF2995261';

    readonly interval = 30;
    uptime: String = '-';
    equipmentHostName = '-';

    customer: Customer;
    location: Location;
    equipment: Equipment;

    ethernetData: AudioCodesEthernet[] = [];
    ipInterfaceData: AudioCodesIPInterface[] = [];
    listOfFoundUsers: AudioCodesUser[] = [];

    deviceVersionInfo: AudioCodesDeviceVersionDetails = {
        serialNumber: '-',
        ipAddress: '-',
        productType: '-',
        versionID: '-'
    };

    showSystemChart = false;
    showNetworkGraph = false;
    showNetworkInterface = false;
    showEthernet = false;
    showIPInterface = false;

    public chartDataUpdated: Subject<null> = new Subject();
    get onChartDataUpdated() {
        return this.chartDataUpdated;
    }

    constructor() {
        super();

        //sizing
        this.sizes = [
            {
                id: 0,
                label: 'Standard',
                cols: 5,
                rows: 4.39
            }
        ];

        this.applySize(0);

        //init data
        this.resetData();
    }

    public applySettings(v: { [key: string]: any }): void {
        super.applySettings(v);

        //read settings object
        this.configured = v.customer && v.location && v.equipment;

        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        if (v.location) {
            this.location = new Location(v.location.value, v.location.label);
        } else {
            this.location = new Location('', '');
        }

        if (v.equipment) {
            this.equipment = new Equipment(v.equipment.value, v.equipment.label);
            this.equipmentHostName = v.equipment.host;
        } else {
            this.equipment = new Equipment('', '');
        }

        this.generatedNameTag = this.configured ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;

        this.showSystemChart = v.showSystemChart || false;
        this.showNetworkGraph = v.showNetworkGraph || false;
        this.showEthernet = v.showEthernet || false;
        this.showIPInterface = v.showIPInterface || false;

        let h = 0;
        if (this.showSystemChart) h += 1.7;
        if (this.showNetworkGraph) h += 1.7;
        if (this.showEthernet) h += 1.2;
        if (this.showIPInterface) h += 1.2;
        //update size
        this.applySizeExpansion(0, h);
    }

    public processLoggedInUsers(data: any): void {
        const rows = data[0].data;
        this.listOfFoundUsers = [];
        if (rows !== null) {
            rows.forEach(artifact => {
                const index = this.listOfFoundUsers.findIndex(
                    item => item.ipAddress === artifact.SourceAddress && item.userName === artifact.UserName
                );
                if (index === -1) {
                    this.listOfFoundUsers.push({
                        userName: artifact.UserName,
                        ipAddress: artifact.SourceAddress
                    });
                }
            });
        }
    }

    public processVersionData(data: any): void {
        const row = data;
        if (row !== null) {
            this.deviceVersionInfo = {
                serialNumber: row.serialNumber,
                ipAddress: row.ipAddress,
                productType: row.productType,
                versionID: row.versionID
            };
        }
    }

    private ipInterfaceActiveState(state: string): string {
        switch (state) {
            case '1':
                return 'active';
            case '2':
                return 'not in service';
            case '3':
                return 'no ready';
            default:
                return 'N/A';
        }
    }

    private ipInterfaceApplicationType(type: string): string {
        switch (type) {
            case '0':
                return 'Oam';
            case '1':
                return 'Media';
            case '2':
                return 'Control';
            case '3':
                return 'Oam & Media';
            case '4':
                return 'Oam & Control';
            case '5':
                return 'Media & Control';
            case '6':
                return 'O+M+C';
            default:
                return 'N/A';
        }
    }

    private ipInterfaceMode(mode: string): string {
        switch (mode) {
            case '3':
                return 'IPv6PrefixManual';
            case '4':
                return 'IPv6Prefix';
            case '10':
                return 'IPv4Manual';
            default:
                return 'N/A';
        }
    }

    public processIPInterfaceData(data: any[]): void {
        const rows = data;
        this.ipInterfaceData = [];
        if (rows !== null) {
            rows.forEach(item => {
                this.ipInterfaceData.push({
                    Title: item.acSysInterfaceName,
                    ActiveState: this.ipInterfaceActiveState(item.acSysInterfaceRowStatus),
                    Application: this.ipInterfaceApplicationType(item.acSysInterfaceApplicationTypes),
                    IpAddress: item.acSysInterfaceIPAddress,
                    InterfaceMode: this.ipInterfaceMode(item.acSysInterfaceMode),
                    PrefixLength: item.acSysInterfacePrefixLength,
                    Gateway: item.acSysInterfaceGateway,
                    PrimaryDNS: item.acSysInterfacePrimaryDNSServerIPAddress,
                    SecondaryDNS: item.acSysInterfaceSecondaryDNSServerIPAddress,
                    UnderlyingDevice: item.acSysInterfaceUnderlyingDevice,
                    UnderlyingInterface: item.acSysInterfaceUnderlyingInterface
                });
            });
        }
    }

    public resetData(): void {
        this.ethernetData = [];
        this.ipInterfaceData = [];
        this.listOfFoundUsers = [];
        this.equipmentHostName = '-';
    }

    private getEthernetTitle(name: string): string {
        switch (name) {
            case '2':
                return 'N/A_';
            case '0':
                return 'GE_';
            case '1':
                return 'FE_';
            default:
                return 'N/A_';
        }
    }

    private getEthernetSpeed(speed: string): string {
        switch (speed) {
            case '2':
                return 'Force Mode';
            case '3':
                return 'Not Available';
            case '10':
                return '10 Mbps';
            case '100':
                return '100 Mbps';
            case '1000':
                return '1 Gbps';
            case '10000':
                return '10 Gbps';
            default:
                return 'Not Available';
        }
    }

    private getEthernetDuplex(duplexEnum: string): string {
        switch (duplexEnum) {
            case '0':
                return 'Half Duplex';
            case '1':
                return 'Full Duplex';
            case '2':
                return 'Force Mode';
            case '3':
                return 'Not Available';
            default:
                return 'Not Available';
        }
    }

    private getEthernetState(state: string): string {
        switch (state) {
            case '0':
                return 'Disabled';
            case '1':
                return 'Blocking';
            case '2':
                return 'Learning';
            case '3':
                return 'Forwarding';
            case '10':
                return 'Not Applicable';
            default:
                return 'Not Available';
        }
    }

    public processEthernetData(data: any[]): void {
        const rows = data;
        this.ethernetData = [];
        if (rows !== null) {
            rows.forEach((item, index) => {
                this.ethernetData.push({
                    Title: this.getEthernetTitle(item.acSysEthernetStatusPortType) + index,
                    Active: item.acSysEthernetStatusActivePortNumber,
                    Speed: this.getEthernetSpeed(item.acSysEthernetStatusPortSpeed),
                    Duplex: this.getEthernetDuplex(item.acSysEthernetStatusPortDuplexMode),
                    State: this.getEthernetState(item.acSysEthernetStatusPortState),
                    GroupMem: item.acSysEthernetStatusGroup
                });
            });
        }
    }

    public processServerData(data: any[]): void {
        let row = data[0].data[0];
        if (row !== null) {
            let result: String = '';
            let ms: number = row.hrSystemUptime;
            var day, hour, minute, seconds;
            seconds = Math.floor(ms / 100);
            minute = Math.floor(seconds / 60);
            seconds = seconds % 60;
            hour = Math.floor(minute / 60);
            minute = minute % 60;
            day = Math.floor(hour / 24);
            hour = hour % 24;
            if (day > 0) result += day + ' days, ';
            if (hour > 0) result += hour + ' hrs, ';
            if (minute > 0) result += minute + ' mins';
            if (result.length === 0) result = '<1 minute';
            this.uptime = result;
        }
    }

    public dispose(): void {}
}
