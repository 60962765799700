import {
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingCheckbox,
    DashletSettingDropdownGrouped
} from '@models/index';
import { DashletSettingsService } from '@services/index';
import { take } from 'rxjs/operators';

export class DashletSettingsFormSystemHealthEngelbartSoftwareEsuites extends DashletSettingsForm {
    showSystemChart: DashletSettingCheckbox;
    showNetworkConnectChart: DashletSettingCheckbox;

    private customer: DashletSettingDropdown;
    private location: DashletSettingDropdown;
    private equipment: DashletSettingDropdownGrouped;

    constructor(private readonly settingsService: DashletSettingsService) {
        super();

        this.init();
        this.loadCustomers();
    }

    public loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);

        if (this.customer.value) {
            this.loadLocations(this.customer.value);
        }
        if (this.location.value) {
            this.loadEquipment(this.location.value);
        }
    }

    private init(): void {
        const equipmentGroup = new DashletSettingsGroup();
        const s = (equipmentGroup.settings = []);

        const customer = (this.customer = new DashletSettingDropdown());
        customer.label = 'Customer';
        customer.stateKey = 'customer';
        customer.disabled = true;
        customer.change.subscribe(() => {
            if (!this.location.value) this.clearAndDisable(this.location);
            if (!this.equipment.value) this.clearAndDisable(this.equipment);

            this.loadLocations(customer.value);
        });
        s.push(customer);

        const location = (this.location = new DashletSettingDropdown());
        location.label = 'Location';
        location.stateKey = 'location';
        location.disabled = true;
        location.change.subscribe(() => {
            if (!this.equipment.value) this.clearAndDisable(this.equipment);

            this.loadEquipment(location.value);
        });
        s.push(location);

        const equipment = (this.equipment = new DashletSettingDropdownGrouped());
        equipment.label = 'Equipment';
        equipment.stateKey = 'equipment';
        equipment.disabled = true;
        s.push(equipment);

        //layout section
        const layoutGroup = new DashletSettingsGroup();
        layoutGroup.title = 'Layout';
        const layoutSettings = (layoutGroup.settings = []);

        const showSystemChart = (this.showSystemChart = new DashletSettingCheckbox());
        showSystemChart.label = 'Show Occupancy Graph';
        showSystemChart.stateKey = 'showSystemChart';
        showSystemChart.disabled = false;
        layoutSettings.push(showSystemChart);

        const showNetworkConnectChart = (this.showNetworkConnectChart = new DashletSettingCheckbox());
        showNetworkConnectChart.label = 'Show Network Connectivity Graph';
        showNetworkConnectChart.stateKey = 'showNetworkConnectChart';
        showNetworkConnectChart.disabled = false;
        layoutSettings.push(showNetworkConnectChart);

        const showComponents = (this.showNetworkConnectChart = new DashletSettingCheckbox());
        showComponents.label = 'Show Components';
        showComponents.stateKey = 'showComponents';
        showComponents.disabled = false;
        layoutSettings.push(showComponents);

        const showCustomScripts = new DashletSettingCheckbox();
        showCustomScripts.label = 'Show Custom Scripts';
        showCustomScripts.stateKey = 'showCustomScripts';
        showCustomScripts.disabled = false;
        layoutSettings.push(showCustomScripts);

        const group = new DashletSettingsGroup();
        group.groups = [equipmentGroup, layoutGroup];
        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);
        this.settingsService
            .getUserAssociatedCustomers()
            .pipe(take<any[]>(1))
            .subscribe(
                customers => {
                    this.customer.items = customers;
                    this.customer.loadingComplete();
                },
                () => {
                    this.customer.loadingComplete();
                    this.customer.disabled = true;
                }
            );
    }

    private loadLocations(customer: string): void {
        this.enableAndLoading(this.location);
        this.settingsService
            .getLocations(customer)
            .pipe(take<any[]>(1))
            .subscribe(
                locations => {
                    this.location.items = locations;
                    this.location.loadingComplete();
                },
                () => {
                    this.location.loadingComplete();
                    this.location.disabled = true;
                }
            );
    }

    private loadEquipment(location: string): void {
        this.enableAndLoading(this.equipment);
        this.settingsService
            .getEngelbartSoftwareEsuitesEquipment(location)
            .pipe(take<any[]>(1))
            .subscribe(
                equipment => {
                    this.equipment.items = equipment;
                    this.equipment.loadingComplete();
                },
                () => {
                    this.equipment.loadingComplete();
                    this.equipment.disabled = true;
                }
            );
    }

    private clearAndDisable(dropdown: DashletSettingDropdown | DashletSettingDropdownGrouped): void {
        dropdown.disabled = true;
        dropdown.items = [];
        dropdown.value = null;
    }

    private enableAndLoading(dropdown: DashletSettingDropdown | DashletSettingDropdownGrouped): void {
        dropdown.disabled = false;
        dropdown.loadingBegin();
    }
}
