<div class="flex-column gap-l" *ngIf="!openSubcontainer">
    <!-- resources template -->

    <dashlet-section-processor-memory
        #processorMemory
        [showSystemChart]="dashlet.showSystemChart"
        [showDiskUsage]="dashlet.showFileSystem"
        [equipment]="dashlet.equipmentConnectivity"
        [customerId]="dashlet.customer.customerId"
        [locationId]="dashlet.location.locationId"
        [commandTypeIdCPUUsage]="dashlet.commandTypeIdProcessorCpu"
        [commandTypeIdMemoryUsage]="dashlet.commandTypeIdProcessorMemory"
        [commandTypeIdCiscoMemoryUsage]="dashlet.commandTypeIdPlatformMemory"
        [commandTypeIdCiscoCpuUsage]="dashlet.commandTypeIdPlatformCpu"
        [commandTypeIdDiskUsage]="dashlet.commandTypeIdFilesystem"
        [commandTypeIdUpTime]="dashlet.commandTypeIdOSUptime"
        [commandTypeIdLoggedInData]="dashlet.commandTypeIdLoggedInUsers"
    >
    </dashlet-section-processor-memory>

    <dashlet-section-network-connectivity
        [showNetworkGraph]="dashlet.showNetworkGraph"
        [equipment]="dashlet.equipmentConnectivity"
        [customerId]="dashlet.customer.customerId"
        [chartAspect]="2"
    >
    </dashlet-section-network-connectivity>
    <div *ngIf="dashlet.showNetworkInterface">
        <dashlet-section-header>Network Interface</dashlet-section-header>
        <ng-container *ngTemplateOutlet="networkInterfaceStatus"></ng-container>
    </div>
    <div *ngIf="dashlet.showEnvironment">
        <dashlet-section-header>Network Environment</dashlet-section-header>
        <ng-container *ngTemplateOutlet="networkEnvironment"></ng-container>
    </div>

    <div *ngIf="dashlet.showDialPeer">
        <dashlet-section-header>Dial Pair Summary</dashlet-section-header>
        <ng-container *ngTemplateOutlet="dialPeerSummary"></ng-container>
    </div>

    <div *ngIf="dashlet.showDSP">
        <dashlet-section-header>DSP</dashlet-section-header>
        <ng-container *ngTemplateOutlet="dspSection"></ng-container>
    </div>

    <div *ngIf="dashlet.showSipResponse">
        <dashlet-section-header>SIP Response</dashlet-section-header>
        <ng-container *ngTemplateOutlet="sipResponseMessages"></ng-container>
    </div>
    <div *ngIf="dashlet.showCallLegs">
        <dashlet-section-header>Call Legs</dashlet-section-header>
        <ng-container *ngTemplateOutlet="callLegs"></ng-container>
    </div>
    <!-- Network Interface -->
    <ng-template #networkInterfaceStatus>
        <div class="flex-column gap-m">
            <!-- Bar -->
            <dashlet-ratio-bar class="dashlet-cell clickable">
                <dashlet-ratio-bar-item
                    [value]="getServicesUp(dashlet.interfaceStatus)"
                    [color]="getSeverityColor(0)"
                    [matTooltip]="getServicesUp(dashlet.interfaceStatus) + ' of ' + dashlet.interfaceStatus.length + ' up'"
                ></dashlet-ratio-bar-item>
                <dashlet-ratio-bar-item
                    [value]="getServicesAmber(dashlet.interfaceStatus)"
                    [color]="getSeverityColor(1)"
                    [matTooltip]="getServicesAmber(dashlet.interfaceStatus) + ' of ' + dashlet.interfaceStatus.length + ' admin status down'"
                ></dashlet-ratio-bar-item>
                <dashlet-ratio-bar-item
                    [value]="getServicesDown(dashlet.interfaceStatus)"
                    [color]="getSeverityColor(2)"
                    [matTooltip]="getServicesDown(dashlet.interfaceStatus) + ' of ' + dashlet.interfaceStatus.length + ' down'"
                ></dashlet-ratio-bar-item>
            </dashlet-ratio-bar>
            <div class="justify-space-between">
                <h3 class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('network-interface-drilldown', 0)">
                    {{ getServicesUp(dashlet.interfaceStatus) }}
                    <span class="mat-h3">Up</span>
                </h3>
                <h3 class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('network-interface-drilldown', 1)">
                    {{ getServicesAmber(dashlet.interfaceStatus) }}
                    <span class="mat-h3">Admin Status Down</span>
                </h3>
                <h3 class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('network-interface-drilldown', 2)">
                    {{ getServicesDown(dashlet.interfaceStatus) }}
                    <span class="mat-h3">Down</span>
                </h3>
            </div>
        </div>
    </ng-template>

    <!-- Environment -->
    <ng-template #networkEnvironment>
        <div class="flex-column gap-m">
            <!-- Bar -->
            <dashlet-ratio-bar class="dashlet-cell clickable">
                <dashlet-ratio-bar-item
                    [value]="getEnvNormal()"
                    [color]="getSeverityColor(0)"
                    [matTooltip]="
                        getEnvNormal() +
                        ' of ' +
                        (dashlet.envVoltage.length + dashlet.envSupply.length + dashlet.envTemp.length + dashlet.envFan.length) +
                        ' normal'
                    "
                ></dashlet-ratio-bar-item>
                <dashlet-ratio-bar-item
                    [value]="getEnvWarning()"
                    [color]="getSeverityColor(1)"
                    [matTooltip]="
                        getEnvWarning() +
                        ' of ' +
                        (dashlet.envVoltage.length + dashlet.envSupply.length + dashlet.envTemp.length + dashlet.envFan.length) +
                        ' warning'
                    "
                ></dashlet-ratio-bar-item>
                <dashlet-ratio-bar-item
                    [value]="getEnvCritical()"
                    [color]="'rgb(255,77,77)'"
                    [matTooltip]="
                        getEnvCritical() +
                        ' of ' +
                        (dashlet.envVoltage.length + dashlet.envSupply.length + dashlet.envTemp.length + dashlet.envFan.length) +
                        ' critical'
                    "
                ></dashlet-ratio-bar-item>
                <dashlet-ratio-bar-item
                    [value]="getEnvShutdown()"
                    [color]="getSeverityColor(2)"
                    [matTooltip]="
                        getEnvShutdown() +
                        ' of ' +
                        (dashlet.envVoltage.length + dashlet.envSupply.length + dashlet.envTemp.length + dashlet.envFan.length) +
                        ' shutdown'
                    "
                ></dashlet-ratio-bar-item>
                <dashlet-ratio-bar-item
                    [value]="getEnvNotFunc()"
                    [color]="'rgb(122,122,122)'"
                    [matTooltip]="
                        getEnvNotFunc() +
                        ' of ' +
                        (dashlet.envVoltage.length + dashlet.envSupply.length + dashlet.envTemp.length + dashlet.envFan.length) +
                        ' not functional'
                    "
                ></dashlet-ratio-bar-item>
            </dashlet-ratio-bar>
            <div class="justify-space-between">
                <h3 class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('Environment', 0)">
                    {{ getEnvNormal() }}
                    <span class="mat-h3">Normal</span>
                </h3>
                <h3 class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('Environment', 1)">
                    {{ getEnvWarning() }}
                    <span class="mat-h3">Warning</span>
                </h3>
                <h3 class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('Environment', 2)">
                    {{ getEnvCritical() }}
                    <span class="mat-h3">Critical</span>
                </h3>
                <h3 class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('Environment', 3)">
                    {{ getEnvShutdown() }}
                    <span class="mat-h3">Shutdown</span>
                </h3>
                <h3 class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('Environment', 4)">
                    {{ getEnvNotFunc() }}
                    <span class="mat-h3">Not Func</span>
                </h3>
            </div>
        </div>
    </ng-template>

    <ng-template #dialPeerSummary>
        <div class="flex-column gap-m">
            <!-- Bar -->
            <dashlet-ratio-bar class="clickable">
                <dashlet-ratio-bar-item
                    [value]="getDpGreenCount(dashlet.dialPeerSummary)"
                    [color]="getSeverityColor(0)"
                    [matTooltip]="getDpGreenCount(dashlet.dialPeerSummary) + ' of ' + dashlet.dialPeerSummary.length"
                ></dashlet-ratio-bar-item>
                <dashlet-ratio-bar-item
                    [value]="getDpAmberCount(dashlet.dialPeerSummary)"
                    [color]="getSeverityColor(1)"
                    [matTooltip]="getDpAmberCount(dashlet.dialPeerSummary) + ' of ' + dashlet.dialPeerSummary.length"
                ></dashlet-ratio-bar-item>
                <dashlet-ratio-bar-item
                    [value]="getDpRedCount(dashlet.dialPeerSummary)"
                    [color]="getSeverityColor(2)"
                    [matTooltip]="getDpRedCount(dashlet.dialPeerSummary) + ' of ' + dashlet.dialPeerSummary.length"
                ></dashlet-ratio-bar-item>
            </dashlet-ratio-bar>
            <!-- Readouts -->
            <div class="justify-space-between">
                <h3 class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('dial-peer-drilldown', 0)">
                    {{ getDpGreenCount(dashlet.dialPeerSummary) }}
                    <span class="mat-h3">Up</span>
                </h3>
                <h3 class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('dial-peer-drilldown', 1)">
                    {{ getDpAmberCount(dashlet.dialPeerSummary) }}
                    <span class="mat-h3">Admin Status Down</span>
                </h3>
                <h3 class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('dial-peer-drilldown', 2)">
                    {{ getDpRedCount(dashlet.dialPeerSummary) }}
                    <span class="mat-h3">Down</span>
                </h3>
            </div>
        </div>
    </ng-template>

    <ng-template #dspSection>
        <!-- Readouts -->
        <div class="justify-space-around">
            <div class="metric" [ngStyle]="{ 'border-left-color': getDSPColour(1) }">
                <div class="mat-caption">Transcoding Session</div>
                <div class="mat-h2 dashlet-link-underline" (click)="openDetails('dsp-drilldown', 0)">{{ getTransDSP() }}/{{ getTransDSPTotal() }}</div>
            </div>
            <div class="metric" [ngStyle]="{ 'border-left-color': getDSPColour(2) }">
                <div class="mat-caption">MTP Sessions</div>
                <div class="mat-h2 dashlet-link-underline" (click)="openDetails('dsp-drilldown', 1)">{{ getMtpDSP() }}/{{ getMtpDSPTotal() }}</div>
            </div>
        </div>
    </ng-template>

    <ng-template #sipResponseMessages>
        <div class="flex-column gap-m">
            <div style="display: flex; justify-content: space-between;">
                <h3 class="sip-section" [ngStyle]="{ 'border-left-color': chartColors.blue }">
                    <div class="mat-h3">1xx</div>
                    {{ dashlet.sipResp1xx / 1000000 < 1 ? dashlet.sipResp1xx : (dashlet.sipResp1xx / 1000000 | number : '1.1-2') + 'M' }}
                </h3>
                <h3 class="sip-section" [ngStyle]="{ 'border-left-color': chartColors.green }">
                    <div class="mat-h3">2xx</div>
                    {{ dashlet.sipResp2xx / 1000000 < 1 ? dashlet.sipResp2xx : (dashlet.sipResp2xx / 1000000 | number : '1.1-2') + 'M'}}
                </h3>
                <h3 class="sip-section" [ngStyle]="{ 'border-left-color': chartColors.green }">
                    <div class="mat-h3">3xx</div>
                    {{ dashlet.sipResp3xx / 1000000 < 1 ? dashlet.sipResp3xx : (dashlet.sipResp3xx / 1000000 | number : '1.1-2') + 'M'}}
                </h3>
                <h3 class="sip-section" [ngStyle]="{ 'border-left-color': chartColors.amber }">
                    <div class="mat-h3">4xx</div>
                    {{ dashlet.sipResp4xx / 1000000 < 1 ? dashlet.sipResp4xx : (dashlet.sipResp4xx / 1000000 | number : '1.1-2') + 'M'}}
                </h3>
                <h3 class="sip-section" [ngStyle]="{ 'border-left-color': chartColors.red }">
                    <div class="mat-h3">5xx</div>
                    {{ dashlet.sipResp5xx / 1000000 < 1 ? dashlet.sipResp5xx : (dashlet.sipResp5xx / 1000000 | number : '1.1-2') + 'M'}}
                </h3>
                <h3 class="sip-section" [ngStyle]="{ 'border-left-color': chartColors.red }">
                    <div class="mat-h3">6xx</div>
                    {{ dashlet.sipResp6xx / 1000000 < 1 ? dashlet.sipResp6xx : (dashlet.sipResp6xx / 1000000 | number : '1.1-2') + 'M'}}
                </h3>
            </div>
        </div>
    </ng-template>

    <!-- Call Legs -->
    <ng-template #callLegs>
        <div class="grid-3-columns">
            <h3>
                {{ dashlet.callLegsInfo.h323 }}
                <span class="mat-h3">H323</span>
            </h3>
            <h3>
                {{ dashlet.callLegsInfo.sip }}
                <span class="mat-h3">SIP</span>
            </h3>
            <h3>
                {{ dashlet.callLegsInfo.mgcp }}
                <span class="mat-h3">MGCP</span>
            </h3>
            <h3>
                {{ dashlet.callLegsInfo.sccp }}
                <span class="mat-h3">SCCP</span>
            </h3>
            <h3>
                {{ dashlet.callLegsInfo.multicast }}
                <span class="mat-h3">Multicast</span>
            </h3>
            <h3>
                {{ dashlet.callLegsInfo.cacontrol }}
                <span class="mat-h3">CA Control</span>
            </h3>
            <h3>
                {{ dashlet.callLegsInfo.telephony }}
                <span class="mat-h3">Telephony</span>
            </h3>
        </div>
    </ng-template>
</div>

<dashlet-ratio-bar-item
    [value]="getEnvNormal()"
    [color]="getEnvDrillDownTheme(0)"
    [matTooltip]="getEnvNormal() + ' of ' + (dashlet.envVoltage.length + dashlet.envSupply.length + dashlet.envTemp.length + dashlet.envFan.length) + ' normal'"
></dashlet-ratio-bar-item>
<dashlet-ratio-bar-item
    [value]="getEnvWarning()"
    [color]="getEnvDrillDownTheme(1)"
    [matTooltip]="getEnvWarning() + ' of ' + (dashlet.envVoltage.length + dashlet.envSupply.length + dashlet.envTemp.length + dashlet.envFan.length) + ' warning'"
></dashlet-ratio-bar-item>
<dashlet-ratio-bar-item
    [value]="getEnvCritical()"
    [color]="getEnvDrillDownTheme(2)"
    [matTooltip]="getEnvCritical() + ' of ' + (dashlet.envVoltage.length + dashlet.envSupply.length + dashlet.envTemp.length + dashlet.envFan.length) + ' critical'"
></dashlet-ratio-bar-item>
<dashlet-ratio-bar-item
    [value]="getEnvShutdown()"
    [color]="getEnvDrillDownTheme(3)"
    [matTooltip]="getEnvShutdown() + ' of ' + (dashlet.envVoltage.length + dashlet.envSupply.length + dashlet.envTemp.length + dashlet.envFan.length) + ' shutdown'"
></dashlet-ratio-bar-item>
<dashlet-ratio-bar-item
    [value]="getEnvNotFunc()"
    [color]="getEnvDrillDownTheme(4)"
    [matTooltip]="
        getEnvNotFunc() + ' of ' + (dashlet.envVoltage.length + dashlet.envSupply.length + dashlet.envTemp.length + dashlet.envFan.length) + ' not functional'
    "
></dashlet-ratio-bar-item>

<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'Environment'"
    [title]="dashlet.location.locationName + ' | ' + dashlet.equipment.equipmentName"
    [navPrevious]="'Cisco CUBE System Health - ' + dashlet.customer.customerName"
    [navCurrent]="'Environment breakdown'"
    [color]="getEnvDrillDownTheme(this.openSubcontainerSeverity)"
    (close)="closeDetails()"
>
    <div class="flex-column gap-m" subtitle>
        Environment Breakdown

        <span *ngIf="this.openSubcontainerSeverity === 0">
            {{ getEnvNormal() + ' of ' + (dashlet.envVoltage.length + dashlet.envSupply.length + dashlet.envTemp.length + dashlet.envFan.length) + ' normal' }}
        </span>
        <span *ngIf="this.openSubcontainerSeverity === 1">
            {{
                getEnvWarning() + ' of ' + (dashlet.envVoltage.length + dashlet.envSupply.length + dashlet.envTemp.length + dashlet.envFan.length) + ' warning'
            }}
        </span>
        <span *ngIf="this.openSubcontainerSeverity === 2">
            {{
                getEnvCritical() +
                    ' of ' +
                    (dashlet.envVoltage.length + dashlet.envSupply.length + dashlet.envTemp.length + dashlet.envFan.length) +
                    ' critical'
            }}
        </span>
        <span *ngIf="this.openSubcontainerSeverity === 3">
            {{
                getEnvShutdown() +
                    ' of ' +
                    (dashlet.envVoltage.length + dashlet.envSupply.length + dashlet.envTemp.length + dashlet.envFan.length) +
                    ' shutdown'
            }}
        </span>
        <span *ngIf="this.openSubcontainerSeverity === 4">
            {{
                getEnvNotFunc() +
                    ' of ' +
                    (dashlet.envVoltage.length + dashlet.envSupply.length + dashlet.envTemp.length + dashlet.envFan.length) +
                    ' not functional'
            }}
        </span>
    </div>
    <ng-container body>
        <mat-accordion multi>
            <mat-expansion-panel *ngIf="voltageTableData.length > 0">
                <mat-expansion-panel-header>
                    <mat-panel-title>Environment Voltage State </mat-panel-title>
                </mat-expansion-panel-header>
                <dashlet-table [columns]="voltageColumns" [dataSource]="voltageTableData"></dashlet-table>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="tempTableData.length > 0">
                <mat-expansion-panel-header>
                    <mat-panel-title> Environment Temperature State </mat-panel-title>
                </mat-expansion-panel-header>
                <dashlet-table [columns]="voltageColumns" [dataSource]="tempTableData"></dashlet-table>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="supplyTableData.length > 0">
                <mat-expansion-panel-header>
                    <mat-panel-title> Environment Supply State </mat-panel-title>
                </mat-expansion-panel-header>
                <dashlet-table [columns]="voltageColumns" [dataSource]="supplyTableData"></dashlet-table>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="fanTableData.length > 0">
                <mat-expansion-panel-header>
                    <mat-panel-title> Environment Fan State </mat-panel-title>
                </mat-expansion-panel-header>
                <dashlet-table [columns]="voltageColumns" [dataSource]="fanTableData"></dashlet-table>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-container>

    <div footer class="justify-end grow gap-m">
        <button mat-button [disabled]="openSubcontainerSeverity === 0" matTooltip="View OK Interfaces" (click)="openDetails('Environment', 0)">Normal</button>
        <button mat-button [disabled]="openSubcontainerSeverity === 1" matTooltip="View OK Interfaces" (click)="openDetails('Environment', 1)">Warning</button>
        <button mat-button [disabled]="openSubcontainerSeverity === 2" matTooltip="View OK Interfaces" (click)="openDetails('Environment', 2)">Critical</button>
        <button mat-button [disabled]="openSubcontainerSeverity === 3" matTooltip="View OK Interfaces" (click)="openDetails('Environment', 3)">Shutdown</button>
        <button mat-button [disabled]="openSubcontainerSeverity === 4" matTooltip="View OK Interfaces" (click)="openDetails('Environment', 4)">Non Func</button>
    </div>
</vsm-dashlet-subcontainer>

<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'dial-peer-drilldown'"
    [title]="dashlet.location.locationName + ' | ' + dashlet.equipment.equipmentName"
    [navPrevious]="'Cisco CUBE System Health - ' + dashlet.customer.customerName"
    [navCurrent]="'Dial-Peer Breakdown'"
    (close)="closeDetails()"
>
    <ng-container subtitle> Dial-Peer Drilldown </ng-container>
    <ng-container body>
        <dashlet-table
            [columns]="dialPeerColumns"
            [dataSource]="dashlet.dialPeerSummary"
            rowExpansionField="Expansion"
            [rowExpansionColumns]="dialPeerExpansionColumns"
        ></dashlet-table>
    </ng-container>
</vsm-dashlet-subcontainer>

<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'network-interface-drilldown'"
    [title]="dashlet.location.locationName + ' | ' + dashlet.equipment.equipmentName"
    [navPrevious]="'Cisco CUBE System Health - ' + dashlet.customer.customerName"
    [navCurrent]="'Network Interface Breakdown'"
    [color]="getSeverityColor(this.openSubcontainerSeverity)"
    (close)="closeDetails()"
>
    <div class="flex-column gap-m" subtitle>
        Network Interface Drilldown

        <span *ngIf="this.openSubcontainerSeverity === 0">
            {{ getServicesUp(dashlet.interfaceStatus) + ' up' }}
        </span>
        <span *ngIf="this.openSubcontainerSeverity === 1">
            {{ getServicesAmber(dashlet.interfaceStatus) + ' Admin Status Down' }}
        </span>
        <span *ngIf="this.openSubcontainerSeverity === 2">
            {{ getServicesDown(dashlet.interfaceStatus) + ' down' }}
        </span>
    </div>
    <ng-container body>
        <dashlet-table
            [columns]="networkInterfaceColumns"
            [dataSource]="networkInterfaceTableData"
            [rowExpansionColumns]="networkInterfaceExpansionColumns"
            rowExpansionField="Expansion"
            [sortable]="false"
            [pagination]="true"
            [pageLength]="10"
        ></dashlet-table>
    </ng-container>
    <div footer class="justify-end grow gap-m">
        <button mat-button [disabled]="openSubcontainerSeverity === 0" matTooltip="View OK Interfaces" (click)="openDetails('network-interface-drilldown', 0)">
            Up
        </button>
        <button
            mat-button
            [disabled]="openSubcontainerSeverity === 1"
            matTooltip="View Admin-Down Interfaces"
            (click)="openDetails('network-interface-drilldown', 1)"
        >
            Admin Down
        </button>
        <button
            mat-button
            [disabled]="openSubcontainerSeverity === 2"
            matTooltip="View Down Interfaces"
            (click)="openDetails('network-interface-drilldown', 2)"
        >
            Down
        </button>
    </div>
</vsm-dashlet-subcontainer>

<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'dsp-drilldown'"
    [title]="dashlet.location.locationName + ' | ' + dashlet.equipment.equipmentName"
    [navPrevious]="'Cisco CUBE System Health - ' + dashlet.customer.customerName"
    [navCurrent]="'DSP Sessions Breakdown'"
    (close)="closeDetails()"
>
    <ng-container subtitle>
        {{ openSubcontainerSeverity === 0 ? 'Transcoding Sessions Drilldown' : 'MTP Sessions Drilldown' }}
    </ng-container>
    <ng-container body>
        <ng-container *ngIf="openSubcontainerSeverity === 0">
            <dashlet-table
                [columns]="transcodingColumns"
                [dataSource]="dashlet.transcodingDrillDownContent"
                [rowExpansionColumns]="networkInterfaceExpansionColumns"
                rowExpansionField="Expansion"
            ></dashlet-table>
        </ng-container>
        <ng-container *ngIf="openSubcontainerSeverity === 1">
            <dashlet-table [columns]="mtpColumns" [dataSource]="dashlet.mtpDrillDownContent"></dashlet-table>
        </ng-container>
    </ng-container>
    <div footer class="dashlet-subcontainer-switches">
        <span [attr.dashlet-link]="openSubcontainerSeverity !== 0" linkTitle="View Transcoding Sessions" (click)="openDetails('dsp-drilldown', 0)"
            >Transcoding Sessions</span
        >
        |
        <span [attr.dashlet-link]="openSubcontainerSeverity !== 1" linkTitle="View MTP Sessions" (click)="openDetails('dsp-drilldown', 1)">MTP Sessions</span>
    </div>
</vsm-dashlet-subcontainer>
