import { DashletSettingsForm, DashletSettingsGroup, DashletSettingList, DashletSettingText } from '@models/index';
import { AccountService, DashletSettingsService } from '@services/index';

export class DashletSettingsFormDatabaseHealth extends DashletSettingsForm {
    private bp!: DashletSettingList<string>;

    constructor(private readonly settingsService: DashletSettingsService) {
        super();

        this.init();
        // this.loadSeverirty();

        this.loadBPs();
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);
    }

    private init(): void {
        const group = new DashletSettingsGroup();
        const s: any = (group.settings = []);

        const bp = (this.bp = new DashletSettingList<string>());
        bp.label = 'Business Partner';
        bp.stateKey = 'bp';
        bp.disabled = true;
        bp.multiple = true;
        bp.metaKeySelection = false;
        bp.filter = true;
        bp.change.subscribe(() => {});
        s.push(bp);

        const customName = new DashletSettingText(40);
        customName.label = 'Custom Dashlet Name';
        customName.stateKey = 'customName';
        s.push(customName);

        this.updateSettings(group);
    }

    private loadBPs(): void {
        this.bp.enable();
        this.bp.loadingBegin();
        this.settingsService.getBusinessPartners().subscribe(
            bp => {
                this.bp.items = bp;
                this.bp.loadingComplete();
            },
            () => {
                this.bp.loadingComplete();
                this.bp.disabled = true;
                // show error notification here
            }
        );
    }
}
