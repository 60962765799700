<div>
    <div class="section section-table">
        <p-table [scrollable]="true" scrollHeight="450px" [value]="dashlet.serviceGateways"
            class="table borderless table-striped table-hover table-dashlet-header" #dt>
            <ng-template pTemplate="header">
                <tr>
                    <th field="severity" header="" [style]="{'width': '1%', 'font-weight': 'bold'}"
                    pSortableColumn="severity" styleClass="table-dashlet-header">
                </th>
                    <th header="" field="name"  pSortableColumn="name" styleClass="table-dashlet-header"
                        [style]="{'width': '16%'}">
                        Server Name
                    </th>
                    <th header="" field="messagesPerSecond" pSortableColumn="messagesPerSecond" styleClass="table-dashlet-header"
                        [style]="{'width': '14%'}">
                        <span class="table-header" title="Messages / Second">
                            Messages/s
                        </span>
                    </th>
                    <th header="" field="alarmMessageCount" pSortableColumn="alarmMessageCount" styleClass="table-dashlet-header"
                        [style]="{'width': '12%'}">
                        <span class="table-header" title="Total Alarm Message Count">
                            Alarm.
                        </span>
                    </th>
                    <th header="" field="configurationMessageCount" pSortableColumn="configurationMessageCount"
                        styleClass="table-dashlet-header" [style]="{'width': '12%'}">
                        <span class="table-header" title="Total Configuration Message Count">
                            Config.
                        </span>
                    </th>
                    <th header="" field="performanceMessageCount" pSortableColumn="performanceMessageCount"
                        styleClass="table-dashlet-header" [style]="{'width': '12%'}">
                        <span class="table-header" title="Total Performance Message Count">
                            Perform.
                        </span>
                    </th>
                    <th header="" field="notificationMessageCount" pSortableColumn="notificationMessageCount"
                        styleClass="table-dashlet-header" [style]="{'width': '12%'}">
                        <span class="table-header" title="Total Notification Message Count">
                            Notif.
                        </span>
                    </th>
                    <th header="" field="createUTCTime" [pSortableColumn]="createUTCTime" styleClass="table-dashlet-header"
                        [style]="{'width': '21%'}">
                        <span class="table-header">
                            Update Time
                        </span>
                    </th>
                </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
            <tr [ngStyle]="{'background-color': dashletService.getSeverityColor(data.severity)}">
                <td field="severity" [style]="{'width': '1%', 'padding': 0}">
                  {{data.severity}}
                </td>
                <td  field="name" [style]="{'width': '16%'}">
                    {{data.name}}
                </td>
                <td  field="messagesPerSecond" [style]="{'width': '14%'}">
                    {{data.messagesPerSecond}}
                </td>
                <td  field="alarmMessageCount" [style]="{'width': '12%'}">
                    {{data.alarmMessageCount}}
                </td>
                <td  field="configurationMessageCount" [style]="{'width': '12%'}">
                    {{data.configurationMessageCount}}
                </td>
                <td  field="performanceMessageCount" [style]="{'width': '12%'}">
                    {{data.performanceMessageCount}}
                </td>
                <td  field="notificationMessageCount" [style]="{'width': '12%'}">
                    {{data.notificationMessageCount}}
                </td>
                <td  field="createUTCTime" [style]="{'width': '21%'}">
                    <ng-template >
                        {{data.createUTCTime | date:'d-MMM-y h:mm a'}}
                    </ng-template>
                </td>
            </tr>
        </ng-template>
            

            
        </p-table>
    </div>
    <div>
        <p-table [value]="dashlet.rates" class="table borderless table-striped table-hover table-dashlet-header">
            <ng-template pTemplate="header">
                <tr>
                    <th header="" field="" styleClass="table-dashlet-header" [style]="{'width': '25%'}">
                        <span class="table-header" title="Messages / Second">
                            Alarms/s
                        </span>
                    </th>
                    <th header="" field="" styleClass="table-dashlet-header" [style]="{'width': '25%'}">
                        <span class="table-header" title="Messages / Second">
                            Configs/s
                        </span>
                    </th>
                    <th header="" field="" styleClass="table-dashlet-header" [style]="{'width': '25%'}">
                        <span class="table-header" title="Messages / Second">
                            Notifs/s
                        </span>
                    </th>
                    <th header="" field="" styleClass="table-dashlet-header" [style]="{'width': '25%'}">
                        <span class="table-header" title="Messages / Second">
                            Performs/s
                        </span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
                <tr>
                    <td field="alarmRate" [style]="{'width': '25%'}">
                        {{data.alarmRate}}
                    </td>
                    <td field="configurationRate" [style]="{'width': '25%'}">
                        {{data.configurationRate}}
                    </td>
                    <td field="notificationRate" [style]="{'width': '25%'}">
                        {{data.notificationRate}}
                    </td>
                    <td field="performanceRate" [style]="{'width': '25%'}">
                        {{data.performanceRate}}
                    </td>
                </tr>
            </ng-template>
            
        </p-table>
    </div>
</div>