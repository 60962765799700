import { Dashlet } from '../dashboard/Dashlet';
import { Subject } from 'rxjs';

export enum DashletNavType {
    Nothing,
    Dashboard,
    Dashlet
}

export class DashletNavChangeEvent {
    type!: DashletNavType;
    dashlet!: Dashlet;
}

export abstract class DashletSetting {
    label!: string;
    readonly change: Subject<void> = new Subject<void>();
    disabled!: boolean;
    hidden = false;
    /** Key used when loading state from or saving state to an object.  */
    stateKey!: string;
    abstract loadState(obj: { [key: string]: object }): void;
    abstract saveState(obj: { [key: string]: object }): void;

    disable() {
        this.disabled = true;
    }
    enable() {
        this.disabled = false;
    }
    hide() {
        this.change.unsubscribe();
        this.hidden = true;
    }
    show() {
        this.hidden = false;
    }
}

export class DashletSettingsGroup {
    settings!: DashletSetting[];
    groups!: DashletSettingsGroup[];
    title!: string;

    saveState(obj: { [key: string]: any }) {
        if (this.settings) {
            for (const s of this.settings) {
                s.saveState(obj);
            }
        }
        if (this.groups) {
            for (const g of this.groups) {
                g.saveState(obj);
            }
        }
    }

    loadState(obj: { [key: string]: any }) {
        if (this.settings) {
            for (const s of this.settings) {
                s.loadState(obj);
            }
        }
        if (this.groups) {
            for (const g of this.groups) {
                g.loadState(obj);
            }
        }
    }

    destroySubscripions(): void {
        if (this.settings) {
            for (const s of this.settings) {
                s.hide();
            }
        }
    }
}

export abstract class DashletSettingsForm {
    private settings!: DashletSettingsGroup;

    getSettings(): DashletSettingsGroup {
        return this.settings;
    }

    saveState(obj: { [key: string]: object }): void {
        if (this.settings) {
            this.settings.saveState(obj);
        }
    }

    loadState(obj: { [key: string]: object }): void {
        if (this.settings) {
            this.settings.loadState(obj);
        }
    }

    destroySubscripions(): void {
        if (this.settings) {
            this.settings.destroySubscripions();
        }
    }

    /** Must be called after building new form settings group, for Angular change detection. */
    protected updateSettings(settings: DashletSettingsGroup) {
        this.settings = settings;
    }
}

export enum DashletSettingType {
    None,
    Dropdown
}

export class SettingsSubject {
    dashletId: string;
    subject: Subject<any>;

    constructor(dashletId: any) {
        this.dashletId = dashletId;
        this.subject = new Subject<any>();
    }
}
