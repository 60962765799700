import {
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingCheckbox,
    SelectItem
} from '@models/index';
import { DashletSettingsService, AccountService } from '@services/index';
import { Observable, forkJoin } from 'rxjs';

export class DashletSettingsFormRibbonSBCEdge extends DashletSettingsForm {
    private customer: DashletSettingDropdown;

    private location: DashletSettingDropdown;

    private equipment: DashletSettingDropdown;

    private isCust: boolean;

    private customerId: string;

    constructor(private readonly settingsService: DashletSettingsService) {
        super();

        this.init();
        this.loadCustomers();
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);

        if (this.customer.value) this.loadLocations(this.customer.value);
        if (this.location.value) this.loadEquipment(this.location.value);
    }

    private init(): void {
        const equipmentGroup = new DashletSettingsGroup();
        const s = (equipmentGroup.settings = []);

        const customer = (this.customer = new DashletSettingDropdown());
        customer.label = 'Customer';
        customer.stateKey = 'customer';
        customer.disabled = true;
        customer.change.subscribe(() => {
            if (!this.location.value) this.clearAndDisable(this.location);
            if (!this.equipment.value) this.clearAndDisable(this.equipment);

            this.loadLocations(customer.value);
        });
        s.push(customer);

        const location = (this.location = new DashletSettingDropdown());
        location.label = 'Location';
        location.stateKey = 'location';
        location.disabled = true;
        location.change.subscribe(() => {
            if (!this.equipment.value) this.clearAndDisable(this.equipment);

            this.loadEquipment(location.value);
        });
        s.push(location);

        const equipment = (this.equipment = new DashletSettingDropdown());
        equipment.label = 'Equipment';
        equipment.stateKey = 'equipment';
        equipment.disabled = true;
        s.push(equipment);

        //layout section
        const layoutGroup = new DashletSettingsGroup();
        layoutGroup.title = 'Layout';
        const layoutSettings = (layoutGroup.settings = []);

        const showNetworkChart = new DashletSettingCheckbox();

        showNetworkChart.label = 'Show Network Connectivity Graph';
        showNetworkChart.stateKey = 'showNetworkChart';
        showNetworkChart.disabled = false;
        layoutSettings.push(showNetworkChart);

        const showNetworkInterface = new DashletSettingCheckbox();
        showNetworkInterface.label = 'Show Network Interface';
        showNetworkInterface.stateKey = 'showNetworkInterface';
        showNetworkInterface.disabled = false;
        layoutSettings.push(showNetworkInterface);

        const showSignallingGroups = new DashletSettingCheckbox();
        showSignallingGroups.label = 'Show Signalling Groups';
        showSignallingGroups.stateKey = 'showSignallingGroups';
        showSignallingGroups.disabled = false;
        layoutSettings.push(showSignallingGroups);

        const showLicenceOccupied = new DashletSettingCheckbox();
        showLicenceOccupied.label = 'Show Licenses';
        showLicenceOccupied.stateKey = 'showLicenceOccupied';
        showLicenceOccupied.disabled = false;
        layoutSettings.push(showLicenceOccupied);

        const showDSPSection = new DashletSettingCheckbox();
        showDSPSection.label = 'Show DSP';
        showDSPSection.stateKey = 'showDSPSection';
        showDSPSection.disabled = false;
        layoutSettings.push(showDSPSection);

        const showCallCounters = new DashletSettingCheckbox();
        showCallCounters.label = 'Show Global Call Counters';
        showCallCounters.stateKey = 'showCallCounters';
        showCallCounters.disabled = false;
        layoutSettings.push(showCallCounters);

        const group = new DashletSettingsGroup();
        group.groups = [equipmentGroup, layoutGroup];
        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);
        this.settingsService.getUserAssociatedCustomers().subscribe(
            customers => {
                this.customer.items = customers;
                this.customer.loadingComplete();
            },
            () => {
                this.customer.loadingComplete();
                this.customer.disabled = true;
                //show error notification here
            }
        );
    }

    private loadLocations(customer: string): void {
        this.enableAndLoading(this.location);
        this.settingsService.getLocations(customer).subscribe(
            locations => {
                this.location.items = locations;
                this.location.loadingComplete();
            },
            () => {
                this.location.loadingComplete();
                this.location.disabled = true;
                //show error notification here
            }
        );
    }

    private loadEquipment(location: string): void {
        this.enableAndLoading(this.equipment);
        this.settingsService.getEquipment(location, 'RibbonSCB').subscribe(
            receivers => {
                this.equipment.items = receivers;
                this.equipment.loadingComplete();
            },
            () => {
                this.equipment.loadingComplete();
                this.equipment.disabled = true;
                //show error notification here
            }
        );
    }

    private clearAndDisable(dropdown: DashletSettingDropdown) {
        dropdown.disabled = true;
        dropdown.items = [];
        dropdown.value = null;
    }

    private enableAndLoading(dropdown: DashletSettingDropdown) {
        dropdown.disabled = false;
        dropdown.loadingBegin();
    }
}
