<div class="flex-column grow gap-l">
    <ng-container *ngIf="this.dashlet.getSize().id === 0">
        <dashlet-line-graph
            #chart
            [data]="chartData"
            [options]="smallChartOptions"
            [aspect]="2"
            [type]="'scatter'"
            height="205px"
            width="100%"
        ></dashlet-line-graph>
    </ng-container>

    <ng-container *ngIf="this.dashlet.getSize().id === 1">
        <dashlet-line-graph
            #largeChart
            [data]="chartData"
            [options]="largeChartOptions"
            [aspect]="3.8"
            [type]="'scatter'"
            height="205px"
            width="100%"
        ></dashlet-line-graph>
    </ng-container>

    <div class="grid-4-columns gap-m">
        <div class="metric dashlet-link-underline" [ngStyle]="{ 'border-left-color': dashletService.getSeverityColor(0) }">
            <div class="mat-caption">Good</div>

            <div class="mat-h2">{{ dashlet.currentDataGood >= 0 ? dashlet.currentDataGood : '---' }}</div>
        </div>
        <div class="metric dashlet-link-underline" [ngStyle]="{ 'border-left-color': dashletService.getSeverityColor(1) }">
            <div class="mat-caption">Satisfactory</div>
            <div class="mat-h2">{{ dashlet.currentDataSatisfactory >= 0 ? dashlet.currentDataSatisfactory : '---' }}</div>
        </div>
        <div class="metric dashlet-link-underline" [ngStyle]="{ 'border-left-color': dashletService.getSeverityColor(2) }">
            <div class="mat-caption">Poor</div>
            <div class="mat-h2">{{ dashlet.currentDataPoor >= 0 ? dashlet.currentDataPoor : '---' }}</div>
        </div>

        <div class="metric dashlet-link-underline" [ngStyle]="{ 'border-left-color': dashletService.getSeverityColor(-1) }">
            <div class="mat-caption">Unknown</div>
            <div class="mat-h2">{{ dashlet.currentDataUnknown >= 0 ? dashlet.currentDataUnknown : '---' }}</div>
        </div>
    </div>
</div>
