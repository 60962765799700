import * as ProcessSupervisorLicenseActions from '@actions/process-supervisor-licenses/process-supervisor-licenses.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService } from '@services/index';
import { RealTimeNotify } from '@models/index';


@Injectable()
export class ProcessSupervisorLicensesEffects {

  constructor(private actions$: Actions, private realtimeGatewayService: RealtimeGatewayService) { }

  getNotifyProcessSupervisorLicenses$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessSupervisorLicenseActions.GetNotifyProcessSupervisorLicenses),
    switchMap((action) => this.realtimeGatewayService.observe(action.equipmentId, action.commandTypeId).pipe(
      catchError((err) => of(
        ProcessSupervisorLicenseActions.GetNotifyProcessSupervisorLicensesFailure({ error: err })
        )
      ),
      mergeMap((data: RealTimeNotify) => [
        ProcessSupervisorLicenseActions.GetNotifyProcessSupervisorLicensesSuccess({ data }),
        ProcessSupervisorLicenseActions.GetProcessSupervisorLicenses({ ...data })
      ],
      ),
    ),
    )
  ));

  getProcessSupervisorLicenses$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessSupervisorLicenseActions.GetProcessSupervisorLicenses),
    mergeMap((action) => this.realtimeGatewayService.getDocument(action.equipmentId, action.commandTypeId).pipe(
      map((data) => ProcessSupervisorLicenseActions.GetProcessSupervisorLicensesSuccess({ data:{ data, equipmentId: action.equipmentId} }),
      ),
      catchError((err) => of(ProcessSupervisorLicenseActions.GetProcessSupervisorLicensesFailure({ error: err })))
    ))
  ));

}
