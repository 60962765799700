import { createAction, props } from '@ngrx/store';

export const GetEntityTimer = createAction(
    '[EntityTimer] Get EntityTimers',
    props<{ equipmentId: string; commandTypeId: string; uniqueId: string }>()
);

export const GetEntityTimerSuccess = createAction('[EntityTimer] Get EntityTimers Success', props<{ data: any }>());

export const GetEntityTimerFailure = createAction('[EntityTimer] Get EntityTimers Failure', props<{ error: any }>());
