/* eslint-disable @angular-eslint/component-selector */
import * as Actions from '@actions/index';
import { DashletService } from '@services/index';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ContextMenu } from 'primeng/contextmenu';
import { ColumnTypes, CustomerLogo, DashletCiscoDialPeerSummary, DialPeer } from '@models/index';
import { ThresholdItemComponent } from '../threshold-item/threshold-item.component';
import { select, Store } from '@ngrx/store';
import { AppState, selectEntity, selectDataFromCommonEntity } from '@reducers/index';
import { Subscription } from 'rxjs';

@Component({
    selector: 'dashlet-system-health-cisco-dial-peer-summary',
    templateUrl: 'dashlet-system-health-cisco-dial-peer-summary.component.html',
    styleUrls: ['dashlet-system-health-cisco-dial-peer-summary.component.scss']
})
export class DashletCiscoDialPeerSummaryComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletCiscoDialPeerSummary;
    @ViewChild('thresholdMenu') thresholdMenu: ContextMenu;
    @ViewChild('thresholdItem') thresholdItem: ThresholdItemComponent;

    public sortType: string;
    public height: number;
    public columns: ColumnTypes[] = [
        {
            columnDef: 'severity',
            header: '',
            type: 'severity',
            cell: (row: DialPeer) => (row.severity === 0 ? 10 : row.severity - 1),
            footerRowDef: () => 'Total:'
        },
        {
            columnDef: 'tagId',
            header: 'Dial Peer',
            filterType: 'text',
            dataTooltip: (row: DialPeer) => this.getToolTipMessage(row),
            footerRowDef: () => `${this.tableData.length}`
        },
        {
            columnDef: 'callsIn',
            header: 'Incoming Calls',
            type: 'numeric',
            dataTooltip: (row: DialPeer) => this.getToolTipMessage(row),
            cell: (row: DialPeer) => (row.callsIn ? row.callsIn : 0),
            footerRowDef: () => `${this.totalIncoming}`
        },
        {
            columnDef: 'callsOut',
            header: 'Outgoing Calls',
            type: 'numeric',
            dataTooltip: (row: DialPeer) => this.getToolTipMessage(row),
            cell: (row: DialPeer) => (row.callsOut ? row.callsOut : 0),
            footerRowDef: () => `${this.totalOutgoing}`
        }
    ];
    public tableData: DialPeer[] = [];

    private totalIncoming: number = 0;
    private totalOutgoing: number = 0;
    public subscription: Subscription = new Subscription();

    constructor(private dashletService: DashletService, private store$: Store<AppState>) {}

    public ngOnInit(): void {
        const s = this.dashlet.getSize();
        this.tableData = [...this.dashlet.dialPeers];

        // Logo
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );

        // IPO Dial Peers
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipmentId,
                command: this.dashlet.commandTypeIdDialPeerCallCounts
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdDialPeerCallCounts
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipmentId + this.dashlet.commandTypeIdDialPeerCallCounts
                        )
                    )
                )
                .subscribe(data => {
                    if (data) {
                        this.dashlet.processCallCountsPerTag(data);
                        this.dashlet.refreshFlag = true;
                    }
                })
        );

        // RAG for Dial Peers
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipmentId,
                command: this.dashlet.commandTypeIdDailPeerRAGFields
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdDailPeerRAGFields
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipmentId + this.dashlet.commandTypeIdDailPeerRAGFields
                        )
                    )
                )
                .subscribe(data => {
                    if (data) {
                        this.dashlet.ragDataProcessed = false;
                        this.dashlet.processTagRag(data);
                        this.dashlet.refreshFlag = true;
                        this.dashlet.ragDataProcessed = true;
                        this.store$.dispatch(
                            Actions.GetCommonHistoric({
                                equipmentId: this.dashlet.equipmentId,
                                commandTypeId: this.dashlet.commandTypeIdDailPeerRAGFields,
                                from: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                                to: new Date().toISOString(),
                                max: 1
                            })
                        );
                    }
                })
        );

        if (s !== null) {
            this.checkAndUpdateTableSize(s.id);
        } else {
            this.height = 6;
        }
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getSeverityColor(severity: number): string {
        return this.dashletService.getSeverityColor(severity === 0 ? 10 : severity - 1); // 10 -> returns grey var-1 shift vars for correct color
    }

    public checkAndUpdateTableSize(id: number): boolean {
        let sizeId = 0;

        const s = this.dashlet.getSize();
        if (s !== null) {
            sizeId = s.id;
            switch (sizeId) {
                case 0:
                    this.height = 5;
                    break;
                case 1:
                    this.height = 10;
                    break;
                case 2:
                    this.height = 14;
                    break;
                default:
                    this.height = 5;
                    break;
            }
        } else {
            sizeId = -1;
        }
        return sizeId === id;
    }

    public getOutgoingCallsTally(): number {
        let count = 0;
        this.tableData.forEach(item => {
            if (item.callsOut !== null) {
                count += item.callsOut;
            }
        });
        return count;
    }

    public getIncomingCallsTally(): number {
        let count = 0;
        this.tableData.forEach(item => {
            if (item.callsIn !== null) {
                count += item.callsIn;
            }
        });
        return count;
    }

    public getTagIdDataAsInt(data: DialPeer): number {
        return data.tagId;
    }

    public refreshTableDataSource(): DialPeer[] {
        if (this.dashlet.refreshFlag) {
            this.tableData = [...this.dashlet.dialPeers];
            this.dashlet.refreshFlag = false;
        }
        this.totalIncoming = this.getIncomingCallsTally();
        this.totalOutgoing = this.getOutgoingCallsTally();
        return this.tableData;
    }

    public getToolTipMessage(item: DialPeer): string {
        if (item !== null) {
            let index = 0;
            let temp: string = ``;

            const codes = [item.tagId, item.admin, item.oper, item.busyOut];

            const titles = ['Tag:', 'Admin Status:', 'Operational Status:', 'Keep Alive:'];

            for (let code of codes) {
                temp += `${titles[index]} ${code}`;
                index++;
            }

            return temp;
        }
    }
}
