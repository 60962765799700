import { Point, Regex, Sort } from '@models/index';

export class MOSSummaryBreakdownData {
	private _breakdownMap: Map<string, Point[][]>; // Map between a group (DSCP/IPNR) and MOS data (6 bands)

	constructor() {
		this._breakdownMap = new Map<string, Point[][]>();
	}

	updateMOSBreakdownData(mosData: number[], group: string, date: Date) {
		if (!this._breakdownMap.has(group)) {
			this._breakdownMap.set(group, []);
			for (let i = 0; i < mosData.length; i++) {
				this._breakdownMap.get(group)?.push([]);
			}
		}

		for (let i = 0; i < mosData.length; i++) {
			if (this._breakdownMap.get(group)![i].findIndex((e: Point) => e.x.getTime() == date.getTime()) == -1) {
				if (this._breakdownMap.get(group)![i].length >= 24) {
					this._breakdownMap.get(group)![i].shift();
				}
				this._breakdownMap.get(group)![i].push({
					x: date,
					y: mosData[i]
				});
			}
			Sort.sortDates(this._breakdownMap.get(group)![i]);
		}
	}

	/*
    Get Breakdown by ipnr/dscp for the last hour
    */
	getMOSBreakdownDataByHours(hours: number): Map<string, number[]> {
		const map = new Map<string, number[]>(); // map between ipnr/dscp and mos data

		// for each ipnr/dscp group
		this._breakdownMap.forEach((mosData: Point[][], group: string) => {
			const currentGroupMosData = new Array(6).fill(0);
			// sum last 'x' hours
			for (let i = 0; i < mosData.length; i++) {
				const startIndex = mosData[i].length >= hours ? mosData[i].length - hours : 0;
				for (let j = startIndex; j < mosData[i].length; j++) {
					currentGroupMosData[i] += mosData[i][j].y;
				}
			}
			map.set(group, currentGroupMosData);
		});

		return map;
	}

	getGoodMOSBreakdownDataByHours(hours: number) {
		const mosGoodByHours: Map<String, Point[]> = new Map<String, Point[]>(); // Map between IPNRs and 'Good' data reduced by hours

		this._breakdownMap.forEach((mosData: Point[][], group: string) => {
			mosGoodByHours.set(group, []);
			if (mosData[4].length == mosData[5].length) {
				const endIndex = hours > mosData[4].length ? mosData[4].length : hours;
				// need to combine mosData[4] and mosData[5];
				for (let i = 0; i < endIndex; i++) {
					if (mosData[4][i].x.getTime() == mosData[5][i].x.getTime()) {
						mosGoodByHours.get(group)!.push({
							x: mosData[4][i].x,
							y: mosData[4][i].y + mosData[5][i].y
						});
					}
				}
			}
		});

		return mosGoodByHours;
	}

	getSatisfactoryBreakdownDataByHours(hours: number) {
		const mosSatisfactoryByHours: Map<String, Point[]> = new Map<String, Point[]>(); // Map between IPNRs and 'Satisfactory' data reduced by hours

		this._breakdownMap.forEach((mosData: Point[][], group: string) => {
			mosSatisfactoryByHours.set(group, []);
			const endIndex = hours > mosData[3].length ? mosData[3].length : hours;
			for (let i = 0; i < endIndex; i++) {
				mosSatisfactoryByHours.get(group)!.push({
					x: mosData[3][i].x,
					y: mosData[3][i].y
				});
			}
		});

		return mosSatisfactoryByHours;
	}

	getPoorMOSBreakdownDataByHours(hours: number) {
		const mosPoorByHours: Map<String, Point[]> = new Map<String, Point[]>(); // Map between IPNRs and 'Poor' data reduced by hours

		this._breakdownMap.forEach((mosData: Point[][], group: string) => {
			mosPoorByHours.set(group, []);
			if (mosData[0].length == mosData[1].length && mosData[0].length == mosData[2].length) {
				const endIndex = hours > mosData[0].length ? mosData[0].length : hours;
				// need to combine mosData[0] and mosData[1] and mosData[2];
				for (let i = 0; i < endIndex; i++) {
					if (
						mosData[0][i].x.getTime() == mosData[1][i].x.getTime() &&
						mosData[0][i].x.getTime() == mosData[2][i].x.getTime()
					) {
						mosPoorByHours.get(group)!.push({
							x: mosData[4][i].x,
							y: mosData[0][i].y + mosData[1][i].y + mosData[2][i].y
						});
					}
				}
			}
		});

		return mosPoorByHours;
	}
}
