import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

export const GetNotifyProcessWatchedServiceData = createAction(
  '[ProcessWatchedServiceData] Get Notify ProcessWatchedServiceDatas',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetNotifyProcessWatchedServiceDataSuccess = createAction(
  '[ProcessWatchedServiceData] Get Notify ProcessWatchedServiceDatas Success',
  props<{ data: RealTimeNotify }>()
);

export const GetNotifyProcessWatchedServiceDataFailure = createAction(
  '[ProcessWatchedServiceData] Get Notify ProcessWatchedServiceDatas Failure',
  props<{ error: any }>()
);

export const GetProcessWatchedServiceData = createAction(
  '[ProcessWatchedServiceData] Get ProcessWatchedServiceDatas',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetProcessWatchedServiceDataSuccess = createAction(
  '[ProcessWatchedServiceData] Get ProcessWatchedServiceDatas Success',
  props<{ data: any}>()
);

export const GetProcessWatchedServiceDataFailure = createAction(
  '[ProcessWatchedServiceData] Get ProcessWatchedServiceDatas Failure',
  props<{ error: any }>()
);
