export class ACDConnectionData {
    private _acdName: string;
    private _applicationState: string;
    private _connection: string;
    private _displayDate: string;
    private _displayTime: string;
    private _sessionState: string;
    private _calls: number;

    /**
     *
     * @param acdName
     * @param applicationState
     * @param connection
     * @param displayDate
     * @param displayTime
     * @param sessionState
     * @param calls
     */
    constructor(
        acdName: string,
        applicationState: string,
        connection: string,
        displayDate: string,
        displayTime: string,
        sessionState: string,
        calls: number
    ) {
        this._acdName = acdName;
        this._applicationState = applicationState;
        this._connection = connection;
        this._displayDate = displayDate;
        this._displayTime = displayTime;
        this._sessionState = sessionState;
        if (!calls) {
            this._calls = -1;
        } else {
            this._calls = calls;
        }
    }

    /**
     * Getter calls
     * @return {number}
     */
    public get calls(): number {
        return this._calls;
    }

    /**
     * Setter calls
     * @param {string} value
     */
    public set calls(value: number) {
        this._calls = value;
    }

    /**
     * Getter acdName
     * @return {string}
     */
    public get acdName(): string {
        return this._acdName;
    }

    /**
     * Getter applicationState
     * @return {string}
     */
    public get applicationState(): string {
        return this._applicationState;
    }

    /**
     * Getter connection
     * @return {string}
     */
    public get connection(): string {
        return this._connection;
    }

    /**
     * Getter displayDate
     * @return {string}
     */
    public get displayDate(): string {
        return this._displayDate;
    }

    /**
     * Getter displayTime
     * @return {string}
     */
    public get displayTime(): string {
        return this._displayTime;
    }

    /**
     * Getter sessionState
     * @return {string}
     */
    public get sessionState(): string {
        return this._sessionState;
    }

    /**
     * Setter acdName
     * @param {string} value
     */
    public set acdName(value: string) {
        this._acdName = value;
    }

    /**
     * Setter applicationState
     * @param {string} value
     */
    public set applicationState(value: string) {
        this._applicationState = value;
    }

    /**
     * Setter connection
     * @param {string} value
     */
    public set connection(value: string) {
        this._connection = value;
    }

    /**
     * Setter displayDate
     * @param {string} value
     */
    public set displayDate(value: string) {
        this._displayDate = value;
    }

    /**
     * Setter displayTime
     * @param {string} value
     */
    public set displayTime(value: string) {
        this._displayTime = value;
    }

    /**
     * Setter sessionState
     * @param {string} value
     */
    public set sessionState(value: string) {
        this._sessionState = value;
    }

    /**
     *
     * @param applicationState
     * @param connection
     * @param displayDate
     * @param displayTime
     * @param sessionState
     * @param calls
     */
    public update(
        applicationState: string,
        connection: string,
        displayDate: string,
        displayTime: string,
        sessionState: string,
        calls: number
    ) {
        this._applicationState = applicationState;
        this._connection = connection;
        this._displayDate = displayDate;
        this._displayTime = displayTime;
        this._sessionState = sessionState;
        if (!calls) {
            this._calls = -1;
        } else {
            this._calls = calls;
        }
    }

    /**
     *
     * @param acdConnectionData
     */
    public updateFromObject(acdConnectionData: ACDConnectionData) {
        this._applicationState = acdConnectionData.applicationState;
        this._connection = acdConnectionData.connection;
        this._displayDate = acdConnectionData.displayDate;
        this._displayTime = acdConnectionData.displayTime;
        this._sessionState = acdConnectionData.sessionState;
        this._calls = acdConnectionData.calls;
    }

    /**
     *
     * @param acdConnectionData
     */
    public updateFromObjectExcludingCalls(acdConnectionData: ACDConnectionData) {
        this._applicationState = acdConnectionData.applicationState;
        this._connection = acdConnectionData.connection;
        this._displayDate = acdConnectionData.displayDate;
        this._displayTime = acdConnectionData.displayTime;
        this._sessionState = acdConnectionData.sessionState;
    }
}
