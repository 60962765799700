/* eslint-disable no-undef */
import * as Actions from '@actions/index';
import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import {
    ColumnTypes,
    DashletNetworkConnectivitySummary,
    MenuItem,
    NetworkConnectivityEquipment,
    Regex
} from '@models/index';
import { DashletService, AccountService, EquipmentConfigurationService, TimeService } from '@services/index';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { AppState, selectEntity, selectDataFromCommonEntity } from '@reducers/index';

import { CustomerLogo } from '@models/index';
import { environment } from '@environments/environment';

interface AlarmState {
    severityColor: string;
    tooltip: string;
}

interface Data {
    id: string;
    name: string;
    ping: string;
    pingUnit: string;
    loss: number;
    lastResponse: string;
    severity: number;
}

@Component({
    selector: 'app-dashlet-network-connectivity-summary',
    templateUrl: './dashlet-network-connectivity-summary.component.html',
    styleUrls: ['./dashlet-network-connectivity-summary.component.scss']
})
export class DashletNetworkConnectivitySummaryComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletNetworkConnectivitySummary;

    private chartSubscription: Subscription = new Subscription();

    public tableData: Data[] = [];
    public entityGroup: number;
    public customerId: string;

    public pageSize = [5, 10, 20];

    public openSubcontainer = '';
    public currentDataIndex: number = 0;

    public columns: ColumnTypes[] = [
        {
            header: '',
            width: '3%',
            columnDef: 'severity',
            cell: (row: Data) => row.severity,
            type: 'severity'
        },
        {
            header: 'Name',
            columnDef: 'name',
            filterType: 'text',
            width: '42%',
            cell: (row: Data) => row.name
        },
        {
            header: 'Last Response',
            columnDef: 'lastResponse',
            filterType: 'text',
            width: '27.4%',
            cell: (row: Data) => row.lastResponse,
            type: 'date'
        },
        {
            header: 'Ping',
            columnDef: 'ping',
            width: '12.7%',
            cell: (row: Data) => `${row.ping}${row.pingUnit}`,
            type: 'numeric'
        },
        {
            header: 'Loss',
            columnDef: 'loss',
            width: '12.7%',
            cell: (row: Data) => row.loss + '%',
            type: 'numeric'
        }
    ];

    public menuItems: MenuItem[] = [
        {
            label: 'Details',
            command: (row: Data) => this.openDetails('networkConnectivity', row.id),
            disabled: false,
            title: 'Open Network Connectivity Details'
        }
    ];

    private subscription: Subscription = new Subscription();

    get smallLayout() {
        return this.dashlet.getSize().id === 0;
    }

    get mediumLayout() {
        return this.dashlet.getSize().id === 1;
    }

    get largeLayout() {
        return this.dashlet.getSize().id === 2;
    }

    constructor(
        private accountService: AccountService,
        private dashletService: DashletService,
        private equipmentConfigurationService: EquipmentConfigurationService,
        private cdr: ChangeDetectorRef,
        private store$: Store<AppState>,
        private timeService: TimeService
    ) {}

    public ngOnInit(): void {
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );

        this.load();
        this.subscription.add(
            this.dashlet.settingsUpdated.subscribe(() => {
                this.load();
            })
        );
    }

    private load(): void {
        this.dashlet.resetData();
        // network data
        this.dashlet.equipment.forEach(element => {
            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: element.equipmentId,
                    command: this.dashlet.commandTypeIdNetwork
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: element.equipmentId,
                    commandTypeId: this.dashlet.commandTypeIdNetwork
                })
            );

            this.subscription.add(
                this.store$
                    .pipe(select(selectDataFromCommonEntity(element.equipmentId + this.dashlet.commandTypeIdNetwork)))
                    .subscribe(data => {
                        if (data) {
                            this.dashlet.processNetworkData(data, element);
                        }
                    })
            );
        });

        const userDetail = this.accountService.getUserDetails();
        this.entityGroup = userDetail.EntityGroup;
        this.subscription.add(
            this.dashlet.onChartDataUpdated.subscribe(() => {
                this.tableData = [...this.getTableData()];
                this.cdr.detectChanges();
            })
        );
    }

    public ngOnDestroy(): void {
        if (this.dashlet) this.dashlet.dispose();
        this.subscription.unsubscribe();
    }

    private getTableData(): Data[] {
        let table: Data[] = [];
        let i = 0;
        if (this.dashlet.networkConnectivitySummaryData.length) {
            for (let row of this.dashlet.networkConnectivitySummaryData) {
                let ping: any = row.getCurrentMaxPing() === '---' ? '0' : +row.getCurrentMaxPing().toString();
                if (isNaN(ping)) {
                    ping = '<1';
                }

                table.push({
                    id: row.equipment.equipmentId,
                    name: row.equipment.equipmentName,
                    ping: ping,
                    pingUnit: row.pingUnit,
                    loss: +row.getCurrentPacketLossPercentage(),
                    lastResponse: this.latestDate(row.equipment.equipmentId),
                    severity: row.getCurrentPacketLossPercentage() === '100' ? 2 : 0
                });
                i++;
            }
        }

        return table;
    }

    public openDetails(container: string, id: string): void {
        this.currentDataIndex = this.dashlet.networkConnectivitySummaryData.findIndex(
            e => e.equipment.equipmentId === id
        );
        this.dashlet.networkConnectivitySummaryData[this.currentDataIndex].refreshNetworkChartData();
        this.dashlet.networkConnectivitySummaryData[this.currentDataIndex].subscribeToIntervalUpdate(
            this.dashlet.interval * 1000
        );
        this.openSubcontainer = container;
        this.dashlet.displayHeader = false;
    }

    public closeDetails(): void {
        this.chartSubscription.unsubscribe();
        this.dashlet.networkConnectivitySummaryData[this.currentDataIndex].unsubscribeToIntervalUpdate();
        this.openSubcontainer = null;
        this.dashlet.displayHeader = true;
    }

    public detailsHasPrev(): boolean {
        if (this.currentDataIndex > 0) {
            return true;
        } else {
            return false;
        }
    }

    public detailsPrev(): void {
        if (this.detailsHasPrev()) {
            this.dashlet.networkConnectivitySummaryData[this.currentDataIndex].unsubscribeToIntervalUpdate();
            this.currentDataIndex--;
            // Subscribe to the new chart
            this.dashlet.networkConnectivitySummaryData[this.currentDataIndex].subscribeToIntervalUpdate(
                this.dashlet.interval * 1000
            );
        }
    }

    public detailsHasNext(): boolean {
        if (this.currentDataIndex < this.dashlet.networkConnectivitySummaryData.length - 1) {
            return true;
        } else {
            return false;
        }
    }

    public latestDate(id: string): string {
        let index = this.dashlet.networkConnectivitySummaryData.findIndex(e => e.equipment.equipmentId === id);
        var date = this.dashlet.networkConnectivitySummaryData[index].getLatestDate();
        const dateString = new Date();
        var threshold = new Date(this.timeService.convertToUserTimeZoneForLastUpdated(dateString));
        threshold.setDate(threshold.getDate() - 1);
        if (date >= threshold) {
            return new Date(this.timeService.convertToUserTimeZoneForLastUpdated(date)).toLocaleTimeString();
        } else if (date < threshold) {
            return new Date(this.timeService.convertToUserTimeZoneForLastUpdated(date)).toLocaleString();
        } else {
            return '---';
        }
    }

    public detailsNext(): void {
        if (this.detailsHasNext()) {
            this.dashlet.networkConnectivitySummaryData[this.currentDataIndex].unsubscribeToIntervalUpdate();
            this.currentDataIndex++;
            // Subscribe to the new chart
            this.dashlet.networkConnectivitySummaryData[this.currentDataIndex].subscribeToIntervalUpdate(
                this.dashlet.interval * 1000
            );
        }
    }

    public accessConcentrator(): void {
        const settings = this.dashlet.getSettings();
        const customerId = settings.customer.value;
        const equipmentId = settings.equipment.value;
        //as a customer, go to access history
        if (this.entityGroup === 2) {
            window.open(
                environment.webPortalUrl + '/AccessConcentrator/AccessConcentrator/List?entityId=' + customerId
            );
        } else {
            window.open(
                environment.webPortalUrl +
                    '/AccessConcentrator/AccessConcentrator/EquipmentList?entityId=' +
                    customerId +
                    '&equipmentId=' +
                    equipmentId
            );
        }
    }

    public getAlarmState(id: string): AlarmState {
        let index = this.dashlet.networkConnectivitySummaryData.findIndex(e => e.equipment.equipmentId === id);
        let config = <NetworkConnectivityEquipment>this.dashlet.networkConnectivitySummaryData[index].equipment;
        const name = this.dashlet.networkConnectivitySummaryData[index].equipment.equipmentName;
        let state: AlarmState = {
            tooltip: `Connectivity to ${name} established.`,
            severityColor: this.dashletService.getSeverityColor(0)
        };

        if (config.networkConnectivityConfiguration.sendAlarmOnFailure.toString() === 'true') {
            if (
                Regex.stringIsNumber(config.networkConnectivityConfiguration.maximumAcceptableAverageResponseMs) &&
                Regex.stringIsNumber(this.dashlet.networkConnectivitySummaryData[index].getCurrentAvgPing())
            ) {
                if (
                    +this.dashlet.networkConnectivitySummaryData[index].getCurrentAvgPing() >=
                    +config.networkConnectivityConfiguration.maximumAcceptableAverageResponseMs
                ) {
                    state.tooltip =
                        this.dashlet.networkConnectivitySummaryData[index].getCurrentPacketLossPercentage() === '100'
                            ? `Connectivity to ${name} lost. An alarm has been raised.`
                            : `${name}: An alarm has been raised.`;
                    state.severityColor = this.dashletService.getSeverityColor(2);
                }
            }
        } else {
            if (this.dashlet.networkConnectivitySummaryData[index].getCurrentPacketLossPercentage() === '100') {
                state.tooltip = `Connectivity to ${name} lost. No alarm has been raised.`;
                state.severityColor = this.dashletService.getSeverityColor(2);
            }
        }

        return state;
    }

    public openEdit(): void {
        const config = <NetworkConnectivityEquipment>(
            this.dashlet.networkConnectivitySummaryData[this.currentDataIndex].equipment
        );
        this.equipmentConfigurationService.openEdit(
            this.dashlet.location.locationId,
            config.networkConnectivityConfiguration.sectionId
        );
    }

    public editDisabled(): boolean {
        const config = <NetworkConnectivityEquipment>(
            this.dashlet.networkConnectivitySummaryData[this.currentDataIndex].equipment
        );
        if (config.networkConnectivityConfiguration.sectionId === '---') {
            return true;
        } else {
            return false;
        }
    }

    public getTableSize(): number {
        switch (this.dashlet.getSize().id) {
            case 0:
                return 2;

            case 1:
                return 12;

            case 2:
                return 22;
        }
        return 4;
    }
}
