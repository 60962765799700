export class ServiceStatus {
	private _serviceName: string;
	private _runningComponents: string;
	private _expectedComponents: string;
	private _status: string;
	private _type: string;

	constructor(
		serviceName: string,
		runningComponents: string,
		expectedComponents: string,
		status: string,
		type: string
	) {
		this._serviceName = serviceName;
		this._runningComponents = runningComponents;
		this._expectedComponents = expectedComponents;
		this._status = status;
		this._type = type;
	}

	public update(runningComponents: string, expectedComponents: string, status: string, type: string) {
		this._runningComponents = runningComponents;
		this._expectedComponents = expectedComponents;
		this._status = status;
		this._type = type;
	}

	public updateFromObject(serviceStatus: ServiceStatus) {
		this._runningComponents = serviceStatus.runningComponents;
		this._expectedComponents = serviceStatus.expectedComponents;
		this._status = serviceStatus.status;
		this._type = serviceStatus.type;
	}

	/**
	 * Getter serviceName
	 * @return {string}
	 */
	public get serviceName(): string {
		return this._serviceName;
	}

	/**
	 * Getter runningComponents
	 * @return {string}
	 */
	public get runningComponents(): string {
		return this._runningComponents;
	}

	/**
	 * Getter expectedComponents
	 * @return {string}
	 */
	public get expectedComponents(): string {
		return this._expectedComponents;
	}

	/**
	 * Getter status
	 * @return {string}
	 */
	public get status(): string {
		return this._status;
	}

	/**
	 * Getter type
	 * @return {string}
	 */
	public get type(): string {
		return this._type;
	}

	/**
	 * Setter serviceName
	 * @param {string} value
	 */
	public set serviceName(value: string) {
		this._serviceName = value;
	}

	/**
	 * Setter runningComponents
	 * @param {string} value
	 */
	public set runningComponents(value: string) {
		this._runningComponents = value;
	}

	/**
	 * Setter expectedComponents
	 * @param {string} value
	 */
	public set expectedComponents(value: string) {
		this._expectedComponents = value;
	}

	/**
	 * Setter status
	 * @param {string} value
	 */
	public set status(value: string) {
		this._status = value;
	}

	/**
	 * Setter type
	 * @param {string} value
	 */
	public set type(value: string) {
		this._type = value;
	}
}
