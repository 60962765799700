import { Point } from '@models/index';

export class Conversions {
	/**
	 * Returns the base64 encoding of a hexadecimal value
	 * @param hexValue
	 */
	static hexToBase64(hexValue: String) {
		return btoa(
			String.fromCharCode.apply(
				null,
				hexValue.replace(/\r|\n/g, '').replace(/([\da-fA-F]{2}) ?/g, '0x$1 ').replace(/ +$/, '').split(' ').map((i) => +i)
			)
		);
	}

	/**
	 * Returns the hex encoding of a base64 string
	 * @param base64Value
	 */
	static base64ToHex(base64Value: String) {
		const hex: any = [];
		for (let i = 0, bin = atob(base64Value.replace(/[ \r\n]+$/, '')), hex = []; i < bin.length; ++i) {
			let tmp = bin.charCodeAt(i).toString(16);
			if (tmp.length === 1) {
				tmp = '0' + tmp;
			}
			hex[hex.length] = tmp;
		}
		return hex.join(' ');
	}
}

export class Sort {
	static sortDates(array: Point[]) {
		array.sort(function (a, b) {
			if (a.x < b.x) {
				return -1;
			}
			if (a.x > b.x) {
				return 1;
			}
			return 0;
		});
	}
}

export class Regex {
	static stringIsNumber(value: string) {
		return new RegExp('^ *\\d+\\.?\\d* *$').test(value);
	}
}
