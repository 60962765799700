
export class pTableDataHelper {
    private _firstSlice: number;

    public DataSource: any[];
    private CurrentSize: number; // Current max size of display data
    public DataDisplay: any[];
    public DataSlice: number; // The setting for small data size (default)
    public ShowMore: boolean;
    public ShowLess: boolean;

    /**
     *
     */
    constructor(_dataSource: any[], _dataDisplay: any[], _dataSlice: number) {
        this.DataSource = _dataSource;
        this.DataDisplay = _dataDisplay;
        this.DataSlice = _dataSlice;
        this.CurrentSize = _dataSlice;
        this._firstSlice = _dataSlice;

        this.calculateShowMore();
        this.calculateShowLess();
    }

    public calculateShowMore() {
        this.ShowMore = this.DataSource.length > this.DataDisplay.length ? true : false;
    }

    public calculateShowLess() {
        this.ShowLess = this.DataDisplay.length > this.DataSlice ? true : false;
    }

    public showMoreRecords(_count: number) {
        this.CurrentSize = _count + this.CurrentSize;
        this.DataDisplay = this.DataSource.slice(0, this.CurrentSize);
        this.calculateShowMore();
        this.calculateShowLess();
    }

    public showLessRecords() {
        this.DataDisplay = this.DataSource.slice(0, this.DataSlice);
        this.CurrentSize = this.DataSlice;
        this.calculateShowMore();
        this.calculateShowLess();
    }

    public updateSourceData(_source: any[]) {
        this.DataSource = _source;
        this.DataDisplay = this.DataSource.slice(0, this.CurrentSize);
        this.calculateShowMore();
        this.calculateShowLess();
    }
}
