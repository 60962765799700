/* eslint-disable no-dupe-class-members */
/* eslint-disable no-undef */
import {
    AccountService,
    DashboardService,
    DashletDataProcessService,
    DashletSettingsService,
    RealtimeGatewayService,
    TimeoutService,
    DashletService
} from '@services/index';
import { cloneDeep } from 'lodash';
import {
    DashboardState,
    Dashlet,
    DashletACMSystemHealthSummary,
    DashletAlarmOverview,
    DashletAlarmsSummary,
    DashletBusinessPartnerAlarmsSummary,
    DashletBusinessPartnerVQMSummary,
    DashletCiscoDialPeerSummary,
    DashletDatabaseHealth,
    DashletEndpointDataSummary,
    DashletIPNRBandwidth,
    DashletIPNREndpoints,
    DashletIPNRStatus,
    DashletMeta,
    DashletNetworkConnectivity,
    DashletNetworkConnectivitySummary,
    DashletPortsPerCustomer,
    DashletRibbonSBCEdge,
    DashletRibbonSystemHealthSummary,
    DashletServiceGatewayInstancesInformation,
    DashletSIPBandwidth,
    DashletSIPResponseSummary,
    DashletSystemHealthAcm,
    DashletSystemHealthAEP,
    DashletSystemHealthAES,
    DashletSystemHealthASM,
    DashletSystemHealthAudioCodes,
    DashletSystemHealthAvayaContact,
    DashletSystemHealthCiscoCUBE,
    DashletSystemHealthCiscoPresence,
    DashletSystemHealthCiscoUCM,
    DashletSystemHealthCiscoUnityConnection,
    DashletSystemHealthCMS,
    DashletSystemHealthIpOffice,
    DashletSystemHealthLinuxServer,
    DashletSystemHealthOracle,
    DashletSystemHealthSBC,
    DashletSystemHealthSummary,
    DashletSystemHealthVmware,
    DashletSystemHealthWindowsServer,
    DashletTotalCustomersByRegion,
    DashletTotalPortsByRegion,
    DashletTrunkGroupList,
    DashletTrunkGroupSummary,
    DashletTrunkGroupTraffic,
    DashletTrunkGroupUtilization,
    DashletType,
    DashletVoiceQualityActiveStreams,
    DashletVoiceQualityMeanOpinionScoreScatter,
    DashletVoiceQualityStreamsInProgress,
    DashletVQMDailySummary,
    DashletSystemHealthEngelbartSoftwareEsuites,
    DashletSystemHealthMSTeamsCallsSummary,
    DashletTotalPortsByType,
    DashletGenesysTrunksSummary,
    DashletGenesysDetailsSummary,
    DashletSystemHealthMSTeamsRoomsSummary
} from '@models/index';
import {
    DashletSystemHealthAcmComponent,
    DashletSystemHealthCMSComponent,
    DashletSystemHealthWindowsServerComponent,
    DashletSystemHealthASMComponent,
    DashletSystemHealthAEPComponent,
    DashletSystemHealthSBCComponent,
    DashletSystemHealthAESComponent,
    DashletSystemHealthAudioCodesComponent,
    DashletSystemHealthCiscoCUBEComponent,
    DashletIPNRStatusComponent,
    DashletIPNREndpointsComponent,
    DashletNetworkConnectivityComponent,
    DashletNetworkConnectivitySummaryComponent,
    DashletSystemHealthCiscoUCMComponent,
    DashletSystemHealthCiscoUnityConnectionComponent,
    DashletCiscoDialPeerSummaryComponent,
    DashletSystemHealthEngelbartSoftwareEsuitesComponent,
    DashletSIPBandwidthComponent,
    DashletAlarmsSummaryComponent,
    DashletPortsPerCustomerComponent,
    DashletTotalPortsByRegionComponent,
    DashletTotalPortsByTypeComponent,
    DashletTotalCustomersByRegionComponent,
    DashletDatabaseHealthComponent,
    DashletVQMDailySummaryComponent,
    DashletBusinessPartnerAlarmsSummaryComponent,
    DashletVoiceQualityStreamsInProgressComponent,
    DashletRibbonSBCEdgeComponent,
    DashletSipResponseSummaryComponent,
    DashletAlarmOverviewComponent,
    DashletSystemHealthCiscoPresenceComponent,
    DashletSystemVmwareComponent,
    DashletTrunkGroupSummaryComponent,
    DashletSystemHealthSummaryComponent,
    DashletServiceGatewayInstancesInformationComponent,
    DashletEndpointDataSummaryComponent,
    DashletSystemHealthMSTeamsCallsSummaryComponent,
    DashletSystemHealthLinuxServerComponent,
    DashletSystemHealthAvayaContactComponent,
    DashletSystemHealthRibbonSystemHealthSummaryComponent,
    DashletACMSystemHealthSummaryComponent,
    DashletBusinessPartnerVQMSummaryComponent,
    DashletSystemHealthOracleComponent,
    DashletSystemHealthIpOfficeComponent,
    DashletVoiceQualityMeanOpinionScoreScatterComponent,
    DashletVoiceQualityActiveStreamsComponent,
    DashletTrunkGroupListComponent,
    DashletTrunkGroupTrafficComponent,
    DashletTrunkGroupUtilizationComponent,
    DashletGenesysDetailsSummaryComponent,
    DashletSystemHealthMSTeamsRoomsSummaryComponent
} from '@dashboardComponents/index';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DashletIPNRBandwidthComponent } from '@modules/dashboard/components/dashlet-ipnrbandwidth/dashlet-ipnr-bandwidth.component';
import { DashletGenesysTrunksSummaryComponent } from '@modules/dashboard/components/dashlet-genesys-trunks-summary/dashlet-genesys-trunks-summary.component';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DashletFactoryService {
    constructor(
        private realtimeService: RealtimeGatewayService,
        private dashletSettingsService: DashletSettingsService,
        private http: HttpClient,
        private dashboardService: DashboardService,
        private dashletDataService: DashletDataProcessService,
        private timeoutService: TimeoutService,
        private accountService: AccountService,
        private dashletService: DashletService
    ) {}

    resolveDashletType(type: string): DashletType {
        switch (type) {
            case 'Sample1':
                return DashletType.Sample1;
            case 'Calls In Progress':
                return DashletType.VoiceQualityCallsInProgress;
            case 'Trunk Group Traffic':
                return DashletType.TrunkGroupTraffic;
            case 'Active Streams':
                return DashletType.VoiceQualityActiveStreams;
            case 'Mean Opinion Scores':
                return DashletType.VoiceQualityMeanOpinionScoreScatter;
            case 'IP Office':
                return DashletType.SystemHealthIpOffice;
            case 'Oracle SBC':
                return DashletType.SystemHealthOracle;
            case 'Multiple Trunk Groups Traffic':
                return DashletType.TrunkGroupList;
            case 'Linux Server':
                return DashletType.SystemHealthLinuxServer;
            case 'Windows Server':
                return DashletType.SystemHealthWindowsServer;
            case 'Avaya Communication Manager (ACM)':
                return DashletType.SystemHealthAcm;
            case 'Avaya Call Management System (CMS)':
                return DashletType.SystemHealthCMS;
            case 'Avaya Experience Portal (AEP)':
                return DashletType.SystemHealthAEP;
            case 'Avaya Session Manager (SM)':
                return DashletType.SystemHealthASM;
            case 'Avaya Application Enablement Services (AES)':
                return DashletType.SystemHealthAES;
            case 'Avaya Session Border Controller (ASBC)':
                return DashletType.SystemHealthSBC;
            case 'IP Network Region':
                return DashletType.IPNRStatus;
            case 'IP Network Region Endpoints':
                return DashletType.IPNREndpoints;
            case 'Network Connectivity':
                return DashletType.NetworkConnectivity;
            case 'Network Connectivity Summary':
                return DashletType.NetworkConnectivitySummary;
            case 'SIP Bandwidth':
                return DashletType.SIPBandwidth;
            case 'Alarms Summary':
                return DashletType.AlarmsSummary;
            case 'Average Ports per Customer':
                return DashletType.PortsPerCustomer;
            case 'Total Ports by Region':
                return DashletType.PortsByRegion;
            case 'Total Ports by Type':
                return DashletType.PortsByType;
            case 'Total Customers by Region':
                return DashletType.CustomersByRegion;
            case 'Database Health':
                return DashletType.DatabaseHealth;
            case 'VQM Summary':
                return DashletType.VQMDailySummary;
            case 'Business Partner Alarms Summary':
                return DashletType.BusinessPartnerAlarmsSummary;
            case 'Trunk Group Summary':
                return DashletType.TrunkGroupSummary;
            case 'System Health Summary':
                return DashletType.SystemHealthSummary;
            case 'Service Gateway Instances Information':
                return DashletType.ServiceGatewayInstancesInformation;
            case 'Endpoint Data Summary':
                return DashletType.EndpointDataSummary;
            case 'Avaya Contact Recorder (ACR)':
                return DashletType.SystemHealthAvayaContact;
            case 'Business Partner VQM Summary':
                return DashletType.BusinessPartnerVQMSummary;
            case 'IP Network Region Bandwidth':
                return DashletType.IPNRBandwidth;
            case 'ACM System Health Summary':
                return DashletType.ACMSystemHealthSummary;
            case 'Cisco UCM':
                return DashletType.SystemHealthCiscoUCM;
            case 'Cisco CUBE':
                return DashletType.SystemHealthCiscoCUBE;
            case 'Cisco CUC':
                return DashletType.SystemHealthCiscoUnityConnection;
            case 'Cisco IM and Presence':
                return DashletType.SystemHEalthCiscoPresence;
            case 'SIP Response Summary':
                return DashletType.SIPResponseSummary;
            case 'Alarms Overview':
                return DashletType.AlarmOverview;
            case 'Ribbon SBC Edge':
                return DashletType.RibbonSBCEdge;
            case 'VMWare Health Summary':
                return DashletType.SystemHealthVmware;
            case 'Ribbon System Health Summary':
                return DashletType.RibbonSystemHealthSummary;
            case 'AudioCodes Session Border Controller':
                return DashletType.SystemHealthAudioCodesSBC;
            case 'Cisco Dial Peer Call Summary':
                return DashletType.SystemHealthCiscoDialPeerSummary;
            case 'MS Teams Call Summary':
                return DashletType.MSTeamsCallSummary;
            case 'esuits by Engelbart Software':
                return DashletType.SystemHealthEngelbartSoftwareESuites;
            case 'Genesys Trunks Summary':
                return DashletType.GenesysTrunkGroupsSummary;
            case 'Genesys Conversation Summary':
                return DashletType.GenesysConversationSummary;
            case 'Microsoft Teams Room Health Summary':
                return DashletType.MicrosoftTeamsRoomHealthSummary;
            default:
                return DashletType.None;
        }
    }

    public createDashlet(meta: DashletMeta, serialized: { [key: string]: any }): Dashlet;
    public createDashlet(meta: DashletMeta): Dashlet;
    public createDashlet(meta: DashletMeta, serialized?: { [key: string]: any }): Dashlet {
        let dashlet: Dashlet;
        if (!serialized) {
            //resolve type
            const type = this.resolveDashletType(meta.type);
            if (type === DashletType.None && !environment.production)
                console.error(`No implementation for dashlet type '${meta.type}'`);

            //create dashlet
            const id = this.newUuid();
            dashlet = this.createNewDashlet(id, type, []);
        } else {
            //extract type

            const type = this.getSerializedDashletType(serialized);
            if (type === null) throw new Error('Serialised dashlet type not found.');

            //recreate dashlet
            const id = serialized[Dashlet.PERSIST_ID_KEY] || this.newUuid();
            dashlet = this.createNewDashlet(id, type, []);
        }

        //apply static properties
        dashlet.title = meta.type;
        dashlet.selected = false;

        //apply blank state
        dashlet.applySettings({});
        const size = dashlet.getTotalSize();
        dashlet.tile = {
            id: dashlet.id,
            cols: size.w,
            rows: size.h,
            y: -1,
            x: -1,
            dragEnabled: true
        };

        //apply serialised state
        if (serialized) {
            dashlet.loadState(serialized);
        }
        return dashlet;
    }

    createDashletsFromState(state: DashboardState, dashletsMeta: DashletMeta[]): Observable<Dashlet[]> {
        const created: Dashlet[] = [];
        const states = state.dashlets || [];
        states.forEach(d => {
            const type = this.getSerializedDashletType(d);
            if (type !== null) {
                const meta = dashletsMeta.filter(m => this.resolveDashletType(m.type) === type)[0];
                if (meta) {
                    try {
                        const dashlet = this.createDashlet(meta, d);
                        created.push(dashlet);
                    } catch (e) {
                        if (!environment.production) console.error('Failed to load a dashlet. ' + e);
                    }
                }
            }
        });
        return of(created);
    }

    getSerializedDashletType(serialized: { [key: string]: any }): DashletType | null {
        const typeName = serialized[Dashlet.PERSIST_TYPE_KEY] as string;
        const type: any = DashletType[typeName as any];
        if (type === undefined) return null;
        return type;
    }

    toArray(enumme: any) {
        return Object.keys(enumme).map(key => enumme[key]);
    }

    /* [To do: need to refactor this] */
    createDashletType(meta: DashletMeta, serialized: { [key: string]: any }): Dashlet;
    createDashletType(meta: DashletMeta): Dashlet;
    createDashletType(meta: DashletMeta, serialized?: { [key: string]: any }): Dashlet {
        let dashlet: Dashlet;
        if (!serialized) {
            //resolve type
            const type = this.resolveDashletType(meta.type);
            if (type === DashletType.None && !environment.production)
                console.error(`No implementation for dashlet type '${meta.type}'`);

            //create dashlet
            const id = this.newUuid();
            dashlet = this.createNewDashletIdType(id, type);
        } else {
            //extract type

            const type = this.getSerializedDashletType(serialized);
            if (type === null) throw new Error('Serialised dashlet type not found.');

            //recreate dashlet
            const id = serialized[Dashlet.PERSIST_ID_KEY] || this.newUuid();
            dashlet = this.createNewDashletIdType(id, type, serialized);
        }

        //apply static properties
        dashlet.title = meta.type;
        dashlet.selected = false;

        return dashlet;
    }

    public createDashletsFromStateNew(state: any, dashletsMeta: DashletMeta[]): Observable<Dashlet[]> {
        const created: Dashlet[] = [];
        const states = state?.dashlets || [];
        states.forEach(d => {
            //extract type
            const type = this.getSerializedDashletType(d);
            if (type !== null) {
                //find meta
                const meta = cloneDeep(dashletsMeta.filter(m => this.resolveDashletType(m.type) === type)[0]);
                if (meta) {
                    //create dashlet
                    try {
                        const dashlet = this.createDashletType(meta, d);
                        created.push(dashlet);
                    } catch (e) {
                        if (!environment.production) console.error('Failed to load a dashlet. ' + e);
                    }
                }
            }
        });
        return of(created);
    }

    private createNewDashletIdType(id: string, type: DashletType, serialized?: { [key: string]: any }): any {
        if (serialized) {
            return {
                id,
                type,
                serialized
            };
        } else {
            return {
                id,
                type
            };
        }
    }

    public createNewDashlet(id: string, type: DashletType | string, dashletsMeta: DashletMeta[]): Dashlet {
        const meta = cloneDeep(dashletsMeta.filter(m => this.resolveDashletType(m.type) === type)[0]);
        let d: Dashlet;
        switch (type) {
            case DashletType.VoiceQualityCallsInProgress:
                d = new DashletVoiceQualityStreamsInProgress(this.dashletSettingsService, this.http);
                break;
            case DashletType.TrunkGroupUtilization:
                d = new DashletTrunkGroupUtilization();
                break;
            case DashletType.TrunkGroupTraffic:
                d = new DashletTrunkGroupTraffic(this.dashboardService, this.realtimeService);
                break;
            case DashletType.TrunkGroupList:
                d = new DashletTrunkGroupList();
                break;
            case DashletType.SystemHealthCiscoDialPeerSummary:
                d = new DashletCiscoDialPeerSummary();
                break;
            case DashletType.VoiceQualityActiveStreams:
                d = new DashletVoiceQualityActiveStreams(this.dashboardService, this.dashletSettingsService);
                break;
            case DashletType.VoiceQualityMeanOpinionScoreScatter:
                d = new DashletVoiceQualityMeanOpinionScoreScatter(this.dashboardService, this.dashletSettingsService);
                break;
            case DashletType.SystemHealthIpOffice:
                d = new DashletSystemHealthIpOffice();
                break;
            case DashletType.SystemHealthOracle:
                d = new DashletSystemHealthOracle();
                break;
            case DashletType.SystemHealthLinuxServer:
                d = new DashletSystemHealthLinuxServer();
                break;
            case DashletType.SystemHealthWindowsServer:
                d = new DashletSystemHealthWindowsServer(this.dashboardService, this.dashletService);
                break;
            case DashletType.SystemHealthAcm:
                d = new DashletSystemHealthAcm();
                break;
            case DashletType.SystemHealthCMS:
                d = new DashletSystemHealthCMS();
                break;
            case DashletType.SystemHealthASM:
                d = new DashletSystemHealthASM();
                break;
            case DashletType.SystemHealthAEP:
                d = new DashletSystemHealthAEP();
                break;
            case DashletType.SystemHealthAES:
                d = new DashletSystemHealthAES(this.timeoutService);
                break;
            case DashletType.SystemHealthSBC:
                d = new DashletSystemHealthSBC();
                break;
            case DashletType.IPNRStatus:
                d = new DashletIPNRStatus(this.realtimeService, this.dashletDataService);
                break;
            case DashletType.IPNREndpoints:
                d = new DashletIPNREndpoints(this.realtimeService, this.dashletDataService);
                break;
            case DashletType.NetworkConnectivity:
                d = new DashletNetworkConnectivity(this.dashletDataService);
                break;
            case DashletType.NetworkConnectivitySummary:
                d = new DashletNetworkConnectivitySummary(
                    this.dashboardService,
                    this.realtimeService,
                    this.dashletDataService
                );
                break;
            case DashletType.SIPBandwidth:
                d = new DashletSIPBandwidth(this.dashboardService);
                break;
            case DashletType.AlarmsSummary:
                d = new DashletAlarmsSummary(this.accountService);
                break;
            case DashletType.PortsPerCustomer:
                d = new DashletPortsPerCustomer(this.realtimeService);
                break;
            case DashletType.PortsByRegion:
                d = new DashletTotalPortsByRegion(this.realtimeService);
                break;
            case DashletType.CustomersByRegion:
                d = new DashletTotalCustomersByRegion(this.realtimeService);
                break;
            case DashletType.DatabaseHealth:
                d = new DashletDatabaseHealth(this.realtimeService, this.dashletSettingsService);
                break;
            case DashletType.VQMDailySummary:
                d = new DashletVQMDailySummary(this.dashletSettingsService);
                break;
            case DashletType.BusinessPartnerAlarmsSummary:
                d = new DashletBusinessPartnerAlarmsSummary(this.accountService);
                break;
            case DashletType.TrunkGroupSummary:
                d = new DashletTrunkGroupSummary(this.realtimeService, this.dashletService);
                break;
            case DashletType.SystemHealthSummary:
                d = new DashletSystemHealthSummary();
                break;
            case DashletType.ServiceGatewayInstancesInformation:
                d = new DashletServiceGatewayInstancesInformation(this.realtimeService);
                break;
            case DashletType.EndpointDataSummary:
                d = new DashletEndpointDataSummary(this.realtimeService, this.dashletService);
                break;
            case DashletType.SystemHealthAvayaContact:
                d = new DashletSystemHealthAvayaContact();
                break;
            case DashletType.BusinessPartnerVQMSummary:
                d = new DashletBusinessPartnerVQMSummary(this.realtimeService);
                break;
            case DashletType.IPNRBandwidth:
                d = new DashletIPNRBandwidth(this.realtimeService, this.dashletService);
                break;
            case DashletType.ACMSystemHealthSummary:
                d = new DashletACMSystemHealthSummary();
                break;
            case DashletType.SystemHealthCiscoUCM:
                d = new DashletSystemHealthCiscoUCM();
                break;
            case DashletType.SIPResponseSummary:
                d = new DashletSIPResponseSummary(
                    this.realtimeService,
                    this.dashletSettingsService,
                    this.accountService,
                    this.dashletService
                );
                break;
            case DashletType.AlarmOverview:
                d = new DashletAlarmOverview();
                break;
            case DashletType.SystemHealthCiscoCUBE:
                d = new DashletSystemHealthCiscoCUBE(this.dashboardService);
                break;
            case DashletType.SystemHealthCiscoUnityConnection:
                d = new DashletSystemHealthCiscoUnityConnection();
                break;
            case DashletType.SystemHEalthCiscoPresence:
                d = new DashletSystemHealthCiscoPresence();
                break;
            case DashletType.SystemHealthEngelbartSoftwareESuites:
                d = new DashletSystemHealthEngelbartSoftwareEsuites();
                break;
            case DashletType.SystemHealthAudioCodesSBC:
                d = new DashletSystemHealthAudioCodes();
                break;
            case DashletType.RibbonSBCEdge:
                d = new DashletRibbonSBCEdge();
                break;
            case DashletType.SystemHealthVmware:
                d = new DashletSystemHealthVmware(this.timeoutService, this.dashletDataService);
                break;
            case DashletType.RibbonSystemHealthSummary:
                d = new DashletRibbonSystemHealthSummary();
                break;
            case DashletType.MSTeamsCallSummary:
                d = new DashletSystemHealthMSTeamsCallsSummary();
                break;
            case DashletType.PortsByType:
                d = new DashletTotalPortsByType(this.realtimeService);
                break;
            case DashletType.GenesysTrunkGroupsSummary:
                d = new DashletGenesysTrunksSummary();
                break;
            case DashletType.GenesysConversationSummary:
                d = new DashletGenesysDetailsSummary();
                break;
            case DashletType.MicrosoftTeamsRoomHealthSummary:
                d = new DashletSystemHealthMSTeamsRoomsSummary();
                break;
            default:
                throw new Error(`No implementation for type '${DashletType[type]}'.`);
        }
        d.id = id;
        d.type = type;
        d.title = meta ? meta.type : d.title;
        return d;
    }

    private newUuid() {
        let d = new Date().getTime();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
            d += performance.now(); //use high-precision timer if available
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
        });
    }

    getComponent(type: DashletType) {
        switch (type) {
            case DashletType.SystemHealthCMS:
                return DashletSystemHealthCMSComponent;
            case DashletType.SystemHealthWindowsServer:
                return DashletSystemHealthWindowsServerComponent;
            case DashletType.VoiceQualityCallsInProgress:
                return DashletVoiceQualityStreamsInProgressComponent;
            case DashletType.SystemHealthAcm:
                return DashletSystemHealthAcmComponent;
            case DashletType.SystemHealthASM:
                return DashletSystemHealthASMComponent;
            case DashletType.SystemHealthAEP:
                return DashletSystemHealthAEPComponent;
            case DashletType.SystemHealthAES:
                return DashletSystemHealthAESComponent;
            case DashletType.SystemHealthSBC:
                return DashletSystemHealthSBCComponent;
            case DashletType.SystemHealthAudioCodesSBC:
                return DashletSystemHealthAudioCodesComponent;
            case DashletType.SystemHealthCiscoCUBE:
                return DashletSystemHealthCiscoCUBEComponent;
            case DashletType.IPNRStatus:
                return DashletIPNRStatusComponent;
            case DashletType.IPNREndpoints:
                return DashletIPNREndpointsComponent;
            case DashletType.SystemHEalthCiscoPresence:
                return DashletSystemHealthCiscoPresenceComponent;
            case DashletType.NetworkConnectivity:
                return DashletNetworkConnectivityComponent;
            case DashletType.NetworkConnectivitySummary:
                return DashletNetworkConnectivitySummaryComponent;
            case DashletType.SystemHealthCiscoUCM:
                return DashletSystemHealthCiscoUCMComponent;
            case DashletType.SystemHealthCiscoUnityConnection:
                return DashletSystemHealthCiscoUnityConnectionComponent;
            case DashletType.SystemHealthCiscoDialPeerSummary:
                return DashletCiscoDialPeerSummaryComponent;
            case DashletType.SystemHealthEngelbartSoftwareESuites:
                return DashletSystemHealthEngelbartSoftwareEsuitesComponent;
            case DashletType.SIPBandwidth:
                return DashletSIPBandwidthComponent;
            case DashletType.AlarmsSummary:
                return DashletAlarmsSummaryComponent;
            case DashletType.PortsPerCustomer:
                return DashletPortsPerCustomerComponent;
            case DashletType.PortsByRegion:
                return DashletTotalPortsByRegionComponent;
            case DashletType.PortsByType:
                return DashletTotalPortsByTypeComponent;
            case DashletType.CustomersByRegion:
                return DashletTotalCustomersByRegionComponent;
            case DashletType.DatabaseHealth:
                return DashletDatabaseHealthComponent;
            case DashletType.VQMDailySummary:
                return DashletVQMDailySummaryComponent;
            case DashletType.AlarmOverview:
                return DashletAlarmOverviewComponent;
            case DashletType.BusinessPartnerAlarmsSummary:
                return DashletBusinessPartnerAlarmsSummaryComponent;
            case DashletType.SIPResponseSummary:
                return DashletSipResponseSummaryComponent;
            case DashletType.RibbonSBCEdge:
                return DashletRibbonSBCEdgeComponent;
            case DashletType.SystemHealthVmware:
                return DashletSystemVmwareComponent;
            case DashletType.TrunkGroupSummary:
                return DashletTrunkGroupSummaryComponent;
            case DashletType.SystemHealthSummary:
                return DashletSystemHealthSummaryComponent;
            case DashletType.ServiceGatewayInstancesInformation:
                return DashletServiceGatewayInstancesInformationComponent;
            case DashletType.EndpointDataSummary:
                return DashletEndpointDataSummaryComponent;
            case DashletType.MSTeamsCallSummary:
                return DashletSystemHealthMSTeamsCallsSummaryComponent;
            case DashletType.SystemHealthLinuxServer:
                return DashletSystemHealthLinuxServerComponent;
            case DashletType.SystemHealthAvayaContact:
                return DashletSystemHealthAvayaContactComponent;
            case DashletType.RibbonSystemHealthSummary:
                return DashletSystemHealthRibbonSystemHealthSummaryComponent;
            case DashletType.ACMSystemHealthSummary:
                return DashletACMSystemHealthSummaryComponent;
            case DashletType.BusinessPartnerVQMSummary:
                return DashletBusinessPartnerVQMSummaryComponent;
            case DashletType.IPNRBandwidth:
                return DashletIPNRBandwidthComponent;
            case DashletType.SystemHealthOracle:
                return DashletSystemHealthOracleComponent;
            case DashletType.SystemHealthIpOffice:
                return DashletSystemHealthIpOfficeComponent;
            case DashletType.VoiceQualityMeanOpinionScoreScatter:
                return DashletVoiceQualityMeanOpinionScoreScatterComponent;
            case DashletType.VoiceQualityActiveStreams:
                return DashletVoiceQualityActiveStreamsComponent;
            case DashletType.TrunkGroupList:
                return DashletTrunkGroupListComponent;
            case DashletType.TrunkGroupTraffic:
                return DashletTrunkGroupTrafficComponent;
            case DashletType.TrunkGroupUtilization:
                return DashletTrunkGroupUtilizationComponent;
            case DashletType.GenesysTrunkGroupsSummary:
                return DashletGenesysTrunksSummaryComponent;
            case DashletType.GenesysConversationSummary:
                return DashletGenesysDetailsSummaryComponent;
            case DashletType.MicrosoftTeamsRoomHealthSummary:
                return DashletSystemHealthMSTeamsRoomsSummaryComponent;
            default:
                console.warn(`No implementation for type '${DashletType[type]}'.`);
                return;
        }
    }
}
