export class TrunkStatus {
	private _trunkName: string;
	private _portCount: number;
	private _inService: number;
	private _connected: number;

	constructor(trunkName: string, portCount: number, inService: number, connected: number) {
		this._trunkName = trunkName;
		this._portCount = portCount;
		this._inService = inService;
		this._connected = connected;
	}

	public update(trunkName: string, portCount: number, inService: number, connected: number) {
		this._trunkName = trunkName;
		this._portCount = portCount;
		this._inService = inService;
		this._connected = connected;
	}

	public updateFromObject(trunkStatus: TrunkStatus) {
		this._trunkName = trunkStatus.trunkName;
		this._portCount = trunkStatus.portCount;
		this._inService = trunkStatus.inService;
		this._connected = trunkStatus.connected;
	}

	/**
	 * Getter trunkName
	 * @return {string}
	 */
	public get trunkName(): string {
		return this._trunkName;
	}

	/**
	 * Getter portCount
	 * @return {number}
	 */
	public get portCount(): number {
		return this._portCount;
	}

	/**
	 * Getter inService
	 * @return {number}
	 */
	public get inService(): number {
		return this._inService;
	}

	/**
	 * Getter connected
	 * @return {number}
	 */
	public get connected(): number {
		return this._connected;
	}

	/**
	 * Setter trunkName
	 * @param {string} value
	 */
	public set trunkName(value: string) {
		this._trunkName = value;
	}

	/**
	 * Setter portCount
	 * @param {number} value
	 */
	public set portCount(value: number) {
		this._portCount = value;
	}

	/**
	 * Setter inService
	 * @param {number} value
	 */
	public set inService(value: number) {
		this._inService = value;
	}

	/**
	 * Setter connected
	 * @param {number} value
	 */
	public set connected(value: number) {
		this._connected = value;
	}
}
