/* eslint-disable no-undef */
import * as TypeEquipmentLocationActions from '@actions/get-type-equipments-at-location/get-type-equipments-at-location.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const typeEqipmentsAtLocationFeatureKey = 'typeEqipmentsAtLocation';

interface TypeEquipmentsAtLocationsState extends EntityState<any> {}

export function selectEntityUniqueId(a: any): string {
    return a.locationId;
}
export interface State {
    typeEquipmentsAtLocationData: TypeEquipmentsAtLocationsState;
}

const adapterTypeEquipmentsAtLocation = createEntityAdapter<any>({
    selectId: selectEntityUniqueId
});

const TypeEquipmentsAtLocationInitState: TypeEquipmentsAtLocationsState =
    adapterTypeEquipmentsAtLocation.getInitialState();

export const initialState: State = {
    typeEquipmentsAtLocationData: TypeEquipmentsAtLocationInitState
};

export const reducer = createReducer(
    initialState,
    on(TypeEquipmentLocationActions.GetTypeEquipmentsAtLocationsSuccess, (state, action) => ({
        ...state,
        typeEquipmentsAtLocationData: adapterTypeEquipmentsAtLocation.upsertOne(
            action.data,
            state.typeEquipmentsAtLocationData
        )
    }))
);
