import {
    DashletSettingDropdownGrouped,
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingList,
    DashletSettingDropdown,
    SelectItem
} from '@models/index';
import { AccountService, DashletSettingsService } from '@services/index';

export class DashletSettingsFormBusinessPartnerVQMSummary extends DashletSettingsForm {
    private customer!: DashletSettingList<string>;
    private isCust!: boolean;
    private customerId!: string;

    constructor(
        private readonly settingsService: DashletSettingsService,
        private readonly accountService: AccountService
    ) {
        super();

        this.init();
        if (!this.isCust) {
            this.loadCustomers();
        }
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);
    }

    private init(): void {
        const group = new DashletSettingsGroup();
        const s: any = (group.settings = []);

        const period = new DashletSettingDropdown();
        period.label = 'Summary Period';
        period.stateKey = 'summaryPeriod';
        period.items = [];

        const daily = new SelectItem();
        daily.label = '24 hours';
        daily.value = '24';
        period.items.push(daily);

        const twelveHourly = new SelectItem();
        twelveHourly.label = '12 hours';
        twelveHourly.value = '12';
        period.items.push(twelveHourly);

        const sixHourly = new SelectItem();
        sixHourly.label = '6 hours';
        sixHourly.value = '6';
        period.items.push(sixHourly);

        const hourly = new SelectItem();
        hourly.label = '1 hour';
        hourly.value = '1';
        period.items.push(hourly);

        s.push(period);

        const user = this.accountService.getUserDetails();
        this.isCust = user.EntityGroup === 2;
        this.customerId = user.EntityId;

        const customer = (this.customer = new DashletSettingList<string>());
        customer.label = 'Customer';
        customer.stateKey = 'customer';
        customer.disabled = true;
        customer.multiple = true;
        customer.metaKeySelection = false;
        customer.filter = true;
        s.push(customer);

        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.customer.enable();
        this.customer.loadingBegin();
        this.settingsService.getUserAssociatedCustomers().subscribe(
            customers => {
                this.customer.items = customers;
                this.customer.loadingComplete();
            },
            () => {
                this.customer.loadingComplete();
                this.customer.disabled = true;
                // show error notification here
            }
        );
    }
}
