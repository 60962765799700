export class SIPRegistrationStatus {
	private _primaryRegistered: string;
	private _primarySubscribed: string;
	private _secondaryRegistered: string;
	private _secondarySubscribed: string;

	constructor() {
		this._primaryRegistered = '0';
		this._primarySubscribed = '0';
		this._secondaryRegistered = '0';
		this._secondarySubscribed = '0';
	}

	/**
	 *
	 * @param primaryRegistered
	 * @param primarySubscribed
	 * @param secondaryRegistered
	 * @param secondarySubscribed
	 */
	public update(
		primaryRegistered: string,
		primarySubscribed: string,
		secondaryRegistered: string,
		secondarySubscribed: string
	) {
		this.primaryRegistered = primaryRegistered;
		this.primarySubscribed = primarySubscribed;
		this.secondaryRegistered = secondaryRegistered;
		this.secondarySubscribed = secondarySubscribed;
	}

	/**
	 * Getter primaryRegistered
	 * @return {string}
	 */
	public get primaryRegistered(): string {
		return this._primaryRegistered;
	}

	/**
	 * Getter primarySubscribed
	 * @return {string}
	 */
	public get primarySubscribed(): string {
		return this._primarySubscribed;
	}

	/**
	 * Getter secondaryRegistered
	 * @return {string}
	 */
	public get secondaryRegistered(): string {
		return this._secondaryRegistered;
	}

	/**
	 * Getter secondarySubscribed
	 * @return {string}
	 */
	public get secondarySubscribed(): string {
		return this._secondarySubscribed;
	}

	/**
	 * Setter primaryRegistered
	 * @param {string} value
	 */
	public set primaryRegistered(value: string) {
		if (value == '' || !value) {
			this._primaryRegistered = '0';
		} else {
			this._primaryRegistered = value;
		}
	}

	/**
	 * Setter primarySubscribed
	 * @param {string} value
	 */
	public set primarySubscribed(value: string) {
		if (value == '' || !value) {
			this._primarySubscribed = '0';
		} else {
			this._primarySubscribed = value;
		}
	}

	/**
	 * Setter secondaryRegistered
	 * @param {string} value
	 */
	public set secondaryRegistered(value: string) {
		if (value == '' || !value) {
			this._secondaryRegistered = '0';
		} else {
			this._secondaryRegistered = value;
		}
	}

	/**
	 * Setter secondarySubscribed
	 * @param {string} value
	 */
	public set secondarySubscribed(value: string) {
		if (value == '' || !value) {
			this._secondarySubscribed = '0';
		} else {
			this._secondarySubscribed = value;
		}
	}
}
