<!-- Ports Chart-->
<div class="section section-network-connectivity"
    [ngClass]="{'no-padding-top' : smallLayout, 'graph-section-padding' : !smallLayout}">
    <!-- metrics template -->
    <ng-template #totalPortMetrics>
        <div class="dashlet-cell grow metrics-spacing"
            [ngClass]="{'emea-border' : !smallLayout, 'center': smallLayout}">
            <div class="dashlet-label dashlet-label-n">EMEA</div>
            <div class="dashlet-value dashlet-value-n">
                <span class="dashlet-value dashlet-value-n">{{dashlet.totalPortsLatestEMEA}}</span>
            </div>
        </div>
        <div class="dashlet-cell grow metrics-spacing"
            [ngClass]="{'apac-border' : !smallLayout, 'center': smallLayout}">
            <div class="dashlet-label dashlet-label-n">APAC</div>
            <div class="dashlet-value dashlet-value-n">
                <span class="dashlet-value dashlet-value-n">{{dashlet.totalPortsLatestAPAC}}</span>
            </div>
        </div>
        <div class="dashlet-cell grow metrics-spacing" [ngClass]="{'na-border' : !smallLayout, 'center': smallLayout}">
            <div class="dashlet-label dashlet-label-n">NA</div>
            <div class="dashlet-value dashlet-value-n">
                <span class="dashlet-value dashlet-value-n">{{dashlet.totalPortsLatestNA}}</span>
            </div>
        </div>
    </ng-template>
    <!-- no chart -->
    <div *ngIf="smallLayout">
        <div class="row justify-evenly">
            <ng-container *ngTemplateOutlet="totalPortMetrics">
            </ng-container>
        </div>
    </div>
    <!-- chart medium -->
    <div *ngIf="mediumLayout">
        <div class="row">
            <div class="col grow metrics-height">
                <ng-container *ngTemplateOutlet="totalPortMetrics"></ng-container>
            </div>
            <div class="dashlet-cell dashlet-cell-graph">
                <dashlet-line-graph #portsChart [data]="portsLineData" [options]="portsChartOptions" [aspect]="0.95">
                </dashlet-line-graph>
            </div>
        </div>
    </div>
    <!-- chart large -->
    <div *ngIf="wideLayout">
        <div class="row">
            <div class="col grow metrics-height">
                <ng-container *ngTemplateOutlet="totalPortMetrics"></ng-container>
            </div>
            <div class="dashlet-cell dashlet-cell-graph wideGraph">
                <dashlet-line-graph #portsChartWide [data]="portsLineData" [options]="portsChartOptionsWide"
                    [aspect]="2.3"></dashlet-line-graph>
            </div>
        </div>
    </div>
</div>