import {
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingList,
    DashletSettingText,
    SelectItem
} from '@models/index';
import { AccountService, DashletSettingsService } from '@services/index';
import { forkJoin, Observable } from 'rxjs';

export class DashletSettingsFormVQMDailySummary extends DashletSettingsForm {
    private customer!: DashletSettingDropdown;
    private location!: DashletSettingDropdown;
    private period!: DashletSettingDropdown;
    private mosCapableReceiver!: DashletSettingDropdown;

    private isCust!: boolean;
    private customerId!: string;

    constructor(
        private readonly settingsService: DashletSettingsService,
        private readonly accountService: AccountService
    ) {
        super();

        this.init();
        this.loadCustomers();
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);

        if (this.customer.value) {
            this.loadLocations(this.customer.value);
        }
        if (this.location.value) {
            this.loadMoscapableReceivers(this.location.value);
        }
    }

    private init(): void {
        const group = new DashletSettingsGroup();
        const s: any = (group.settings = []);

        const period = (this.period = new DashletSettingDropdown());
        period.label = 'Summary Period';
        period.stateKey = 'summaryPeriod';
        period.items = [];

        const daily = new SelectItem();
        daily.label = '24 hours';
        daily.value = '24';
        period.items.push(daily);

        const twelveHourly = new SelectItem();
        twelveHourly.label = '12 hours';
        twelveHourly.value = '12';
        period.items.push(twelveHourly);

        const sixHourly = new SelectItem();
        sixHourly.label = '6 hours';
        sixHourly.value = '6';
        period.items.push(sixHourly);

        const hourly = new SelectItem();
        hourly.label = '1 hour';
        hourly.value = '1';
        period.items.push(hourly);

        s.push(period);

        const user = this.accountService.getUserDetails();
        this.isCust = user.EntityGroup === 2;
        this.customerId = user.EntityId;

        const customer = (this.customer = new DashletSettingDropdown());
        customer.label = 'Customer';
        customer.stateKey = 'customer';
        customer.disabled = true;
        customer.change.subscribe(() => {
            this.clearAndDisable(this.location);
            this.clearAndDisable(this.mosCapableReceiver);
            this.loadLocations(customer.value!);
        });
        s.push(customer);
        const location = (this.location = new DashletSettingDropdown());
        location.label = 'Location';
        location.stateKey = 'location';
        location.disabled = true;
        location.change.subscribe(() => {
            this.clearAndDisable(this.mosCapableReceiver);

            this.loadMoscapableReceivers(location.value!);
        });
        s.push(location);

        const mosCapableReceiver = (this.mosCapableReceiver = new DashletSettingDropdown());
        mosCapableReceiver.label = 'Source Equipment';
        mosCapableReceiver.stateKey = 'equipment';
        mosCapableReceiver.disabled = true;
        s.push(mosCapableReceiver);

        const customName = new DashletSettingText(40);
        customName.label = 'Custom Dashlet Name';
        customName.stateKey = 'customName';
        s.push(customName);

        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);
        this.settingsService.getUserAssociatedCustomers().subscribe(
            customers => {
                this.customer.items = customers;
                this.customer.loadingComplete();
            },
            () => {
                this.customer.loadingComplete();
                this.customer.disabled = true;
                // show error notification here
            }
        );
    }

    private loadLocations(customer: string): void {
        this.enableAndLoading(this.location);
        this.settingsService.getLocations(customer).subscribe(
            locations => {
                locations.unshift({ label: 'All', value: '=all=' });
                this.location.items = locations;
                this.location.loadingComplete();
            },
            () => {
                this.location.loadingComplete();
                this.location.disabled = true;
                // show error notification here
            }
        );
    }

    private loadMoscapableReceivers(locationId: string): void {
        if (locationId === '=all=') {
            this.mosCapableReceiver.items = [];
            const receivers: Observable<SelectItem[]>[] = [];
            this.enableAndLoading(this.mosCapableReceiver);
            this.settingsService.getLocations(this.customer.value!).subscribe(locations => {
                for (const location of locations) {
                    receivers.push(this.settingsService.getMosCapableReceivers(location.value));
                }

                forkJoin(receivers).subscribe(
                    results => {
                        for (const result of results) {
                            for (const receiver of result) {
                                this.mosCapableReceiver.items.push(receiver);
                            }
                        }

                        if (this.mosCapableReceiver.items.length > 0) {
                            this.mosCapableReceiver.items.unshift({
                                label: 'All',
                                value: '=all='
                            });
                        } else {
                            this.mosCapableReceiver.disabled = true;
                        }

                        this.mosCapableReceiver.loadingComplete();
                    },
                    () => {
                        this.mosCapableReceiver.loadingComplete();
                        this.mosCapableReceiver.disabled = true;
                    }
                );
            });
        } else {
            this.enableAndLoading(this.mosCapableReceiver);

            this.settingsService.getMosCapableReceivers(locationId).subscribe(
                receivers => {
                    if (receivers.length > 0) {
                        receivers.unshift({ label: 'All', value: '=all=' });
                        this.mosCapableReceiver.items = receivers;
                    } else {
                        this.mosCapableReceiver.disabled = true;
                    }
                    this.mosCapableReceiver.loadingComplete();
                },
                () => {
                    this.mosCapableReceiver.loadingComplete();
                    this.mosCapableReceiver.disabled = true;
                    // show error notification here
                }
            );
        }
    }

    private clearAndDisable(widget: DashletSettingDropdown | DashletSettingList<string>) {
        widget.disabled = true;
        widget.items = [];
        if (widget instanceof DashletSettingList) {
            widget.values = [];
        } else {
            widget.value = null;
        }
    }

    private enableAndLoading(widget: DashletSettingDropdown | DashletSettingList<string>) {
        widget.disabled = false;
        widget.loadingBegin();
    }
}
