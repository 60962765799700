import {
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingList,
    SelectItem
} from '@models/index';
import { AccountService, DashletSettingsService } from '@services/index';

export class DashletSettingsFormIPNRBandwidth extends DashletSettingsForm {
    private customer!: DashletSettingDropdown;
    private location!: DashletSettingDropdown;
    private period!: DashletSettingDropdown;
    private equipment!: DashletSettingDropdown;
    // private rtcpReceiver: DashletSettingDropdown;

    private isCust!: boolean;
    private customerId!: string;

    constructor(
        private readonly settingsService: DashletSettingsService,
        private readonly accountService: AccountService
    ) {
        super();

        this.init();
        this.loadCustomers();
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);

        if (this.customer.value) {
            this.loadLocations(this.customer.value);
        }
        if (this.location.value) {
            this.loadEquipment(this.location.value);
        }
    }

    private init(): void {
        const group = new DashletSettingsGroup();
        const s: any = (group.settings = []);

        const period = (this.period = new DashletSettingDropdown());
        period.label = 'Summary Period';
        period.stateKey = 'summaryPeriod';
        period.items = [];

        const daily = new SelectItem();
        daily.label = '24 hours';
        daily.value = '24';
        period.items.push(daily);

        const twelveHourly = new SelectItem();
        twelveHourly.label = '12 hours';
        twelveHourly.value = '12';
        period.items.push(twelveHourly);

        const sixHourly = new SelectItem();
        sixHourly.label = '6 hours';
        sixHourly.value = '6';
        period.items.push(sixHourly);

        const hourly = new SelectItem();
        hourly.label = '1 hour';
        hourly.value = '1';
        period.items.push(hourly);

        s.push(period);

        const user = this.accountService.getUserDetails();
        this.isCust = user.EntityGroup === 2;
        this.customerId = user.EntityId;

        const customer = (this.customer = new DashletSettingDropdown());
        customer.label = 'Customer';
        customer.stateKey = 'customer';
        customer.disabled = true;
        customer.change.subscribe(() => {
            if (!this.location.value) this.clearAndDisable(this.location);
            if (!this.equipment.value) this.clearAndDisable(this.equipment);
            this.loadLocations(customer.value!);
            this.location.items = [];
            this.equipment.items = [];
        });
        s.push(customer);

        const location = (this.location = new DashletSettingDropdown());
        location.label = 'Location';
        location.stateKey = 'location';
        location.disabled = true;
        location.change.subscribe(() => {
            if (!this.equipment.value) this.clearAndDisable(this.equipment);

            this.loadEquipment(location.value!);
            this.equipment.items = [];
        });
        s.push(location);

        const equipment = (this.equipment = new DashletSettingDropdown());
        equipment.label = 'Source Equipment';
        equipment.stateKey = 'equipment';
        equipment.disabled = true;
        s.push(equipment);

        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);
        this.settingsService.getUserAssociatedCustomers().subscribe(
            customers => {
                this.customer.items = customers;
                this.customer.loadingComplete();
            },
            () => {
                this.customer.loadingComplete();
                this.customer.disabled = true;
                // show error notification here
            }
        );
    }

    private loadLocations(customer: string): void {
        this.enableAndLoading(this.location);
        this.settingsService.getLocations(customer).subscribe(
            locations => {
                // locations.unshift({ label: "All", value: "=all=" });
                this.location.items = locations;
                this.location.loadingComplete();
            },
            () => {
                this.location.loadingComplete();
                this.location.disabled = true;
                // show error notification here
            }
        );
    }

    // private loadRtcpReceivers(locationId: string): void {
    //    if (locationId == "=all=") { // if necessary to load all rtcp receivers from all locations
    //        let receivers: Observable<SelectItem[]>[] = [];
    //        this.rtcpReceiver.items = [
    //            {
    //                label: "All",
    //                value: "=all="
    //            }
    //        ]
    //        this.enableAndLoading(this.rtcpReceiver)
    //        this.settingsService
    //            .getLocations(this.customer.value)
    //            .subscribe((locations) => {
    //                for (let location of locations) {
    //                    receivers.push(this.settingsService.getRtcpReceivers(location.value));
    //                }
    //                forkJoin(receivers).subscribe((results) => {
    //                    for (let result of results) {
    //                        for (let receiver of result) {
    //                            this.rtcpReceiver.items.push(receiver);
    //                        }
    //                    }
    //                    this.rtcpReceiver.loadingComplete();
    //                }, () => {
    //                    this.rtcpReceiver.loadingComplete();
    //                    this.rtcpReceiver.disabled = true;
    //                });
    //            })
    //    } else {
    //        this.enableAndLoading(this.rtcpReceiver);
    //        this.settingsService
    //            .getRtcpReceivers(locationId)
    //            .subscribe(
    //                receivers => {
    //                    receivers.unshift({ label: "All", value: "=all=" });
    //                    this.rtcpReceiver.items = receivers;
    //                    this.rtcpReceiver.loadingComplete();
    //                },
    //                () => {
    //                    this.rtcpReceiver.loadingComplete();
    //                    this.rtcpReceiver.disabled = true;
    //                    //show error notification here
    //                }
    //            );
    //    }
    // }

    private clearAndDisable(widget: DashletSettingDropdown | DashletSettingList<string>) {
        widget.disabled = true;
        widget.items = [];
        if (widget instanceof DashletSettingList) {
            widget.values = [];
        } else {
            widget.value = null;
        }
    }

    private enableAndLoading(widget: DashletSettingDropdown | DashletSettingList<string>) {
        widget.disabled = false;
        widget.loadingBegin();
    }

    private loadEquipment(location: string): void {
        this.enableAndLoading(this.equipment);
        this.settingsService.getEquipment(location, 'ACM').subscribe(
            receivers => {
                this.equipment.items = receivers;
                this.equipment.loadingComplete();
            },
            () => {
                this.equipment.loadingComplete();
                this.equipment.disabled = true;
                // show error notification here
            }
        );
    }
}
