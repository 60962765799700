import * as ProcessThresholdDataACtions from '@actions/process-threshold-data/process-threshold-data.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { ThresholdService } from '@services/index';


@Injectable()
export class ProcessThresholdDataEffects {

  constructor(private actions$: Actions, private thresholdService: ThresholdService) { }

  getProcessThresholdData$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessThresholdDataACtions.GetProcessThresholdData),
    mergeMap((action) => this.thresholdService.getThresholdsByEquipmentId(action.equipmentId).pipe(
      map((data) => ProcessThresholdDataACtions.GetProcessThresholdDataSuccess({ data:{ data, equipmentId: action.equipmentId} }),
      ),
      catchError((err) => of(ProcessThresholdDataACtions.GetProcessThresholdDataFailure({ error: err })))
    ))
  ));

}
