import { Customer, Dashlet, Equipment, Location } from '@models/index';

export class DashletSystemHealthCiscoUnityConnection extends Dashlet {
    public readonly commandTypeIdServer = '0CD94CC2-42F3-4C6C-9F8B-E9CAF2995261';
    public readonly commandTypeIdCpu = '37EF261A-5321-4F74-B8BD-0734D834C3C8';
    public readonly commandTypeIdMemory = 'D2E8814E-A42D-4922-81C8-87F4139E65BD';

    customer: Customer;
    location: Location;
    equipment: Equipment;

    showSystemChart = false;
    showNetworkGraph = false;
    showNetworkInterface = false;

    constructor() {
        super();

        //sizing
        this.sizes = [
            {
                id: 0,
                label: 'Standard',
                cols: 5,
                rows: 4
            }
        ];

        this.applySize(0);

        //init data
        this.resetData();
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        //read settings object
        this.configured = v.customer && v.location && v.equipment;

        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        if (v.location) {
            this.location = new Location(v.location.value, v.location.label);
        } else {
            this.location = new Location('', '');
        }
        if (v.equipment) {
            this.equipment = new Equipment(v.equipment.value, v.equipment.label);
        } else {
            this.equipment = new Equipment('', '');
        }

        this.generatedNameTag = this.configured ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;

        this.showSystemChart = v.showSystemChart || false;
        this.showNetworkGraph = v.showNetworkGraph || false;
        this.showNetworkInterface = v.showNetworkInterface || false;

        let h = 0;
        if (this.showSystemChart) h += 1.9;
        if (this.showNetworkGraph) h += 2.1;
        if (this.showNetworkInterface) h += 1.2;

        //update size
        this.applySizeExpansion(0, h);
    }
    resetData() {}

    dispose() {}
}
