import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sort',
    pure: false
})
export class SortPipe implements PipeTransform {
    transform(array: any[], field: string, sortDesc: boolean, sortType: string): any[] {
        //define comparers
        const cmpNumber = (a: number, b: number): number => {
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
        };
        const cmpMultiple = (a: number, b: number, field: string): number => {
            let fields = field.split('|');
            return a[fields[0]] - b[fields[0]] || a[fields[1]] - b[fields[1]];
        };
        const cmpString = (a: string, b: string): number => {
            if (a === null) {
                return -1;
            }
            if (b === null) {
                return 1;
            }
            return a.localeCompare(b);
        };
        const cmpDate = (a: Date, b: Date): number => {
            if (a instanceof Date || b instanceof Date) {
                const aa = (a && a.getTime()) || 0;
                const bb = (b && b.getTime()) || 0;
                if (aa < bb) return -1;
                if (aa > bb) return 1;
            }
            return 0;
        };
        const cmpBoolean = (a: boolean, b: boolean): number => {
            if (a) {
                return -1;
            }
            if (b) {
                return 1;
            }
            return 0;
        };

        let direction = sortDesc ? -1 : 1;
        array.sort((a: any, b: any) => {
            switch (sortType) {
                case 'number':
                    return cmpNumber(Number(a[field]), Number(b[field])) * direction;
                case 'string':
                    return cmpString(a[field], b[field]) * direction;
                case 'date':
                    return cmpDate(a[field], b[field]) * direction;
                // for sorting on multiple columns (number), columns to be sorted should be separated by "|" character
                case 'multiple':
                    return cmpMultiple(a, b, field) * direction;
                // for sorting fractions e.g. string such as "10/20" will sort by 10 and then by 20
                case 'proportion':
                    let orderer = cmpNumber(a[field.split('/')[0]], b[field.split('/')[0]]);
                    if (orderer === 0)
                        //then by
                        orderer = cmpNumber(a[field.split('/')[1]], b[field.split('/')[1]]);
                    return orderer * direction;
                case 'boolean':
                    return cmpBoolean(a[field], b[field]) * direction;
            }
            // if (a[field] < b[field]) {
            //     return -1 * direction;
            // } else if (a[field] > b[field]) {
            //     return 1 * direction;
            // } else {
            //     return 0;
            // }
        });
        return array;
    }
}
