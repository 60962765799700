export class Equipment {
    private _equipmentId: string;
    private _equipmentName: string;
    constructor(equipmentId: string, equipmentName: string) {
        this._equipmentId = equipmentId;
        this._equipmentName = equipmentName;
    }

    /**
     * Getter equipmentId
     * @return {string}
     */
    public get equipmentId(): string {
        return this._equipmentId;
    }

    /**
     * Getter equipmentName
     * @return {string}
     */
    public get equipmentName(): string {
        return this._equipmentName;
    }

    /**
     * Setter equipmentId
     * @param {string} value
     */
    public set equipmentId(value: string) {
        this._equipmentId = value;
    }

    /**
     * Setter equipmentName
     * @param {string} value
     */
    public set equipmentName(value: string) {
        this._equipmentName = value;
    }
}
