import { Component, AfterViewInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { ActivatedRoute } from '@angular/router';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements AfterViewInit {
    constructor(private authService: OAuthService, private route: ActivatedRoute) {}

    public ngAfterViewInit(): void {
        this.replicateToggleClick();
    }

    public login($event: any) {
        if ($event !== null) {
            $event.preventDefault();
        }
        this.authService.initLoginFlow();
    }

    public replicateToggleClick(): void {
        // eslint-disable-next-line no-undef
        const element: HTMLElement = document.getElementById('autologin') as HTMLElement;
        element.click();
    }
}
