import { LogoRespObj } from '@models/index';
import { createAction, props } from '@ngrx/store';

export const GetEntityLogo = createAction('[CustomerLogo] Get CustomerLogos', props<{ entityId: string }>());

export const GetEntityLogoSuccess = createAction(
    '[CustomerLogo] Get CustomerLogos Success',
    props<{ data: LogoRespObj }>()
);

export const GetEntityLogoFailure = createAction('[CustomerLogo] Get CustomerLogos Failure', props<{ error: any }>());
