import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

export const GetNotifyProcessWFMStatusHistoric = createAction(
  '[ProcessWFMStatusHistoric] Get Notify ProcessWFMStatusHistoric',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetNotifyProcessWFMStatusHistoricSuccess = createAction(
  '[ProcessWFMStatusHistoric] Get Notify ProcessWFMStatusHistoric Success',
  props<{ data: RealTimeNotify }>()
);

export const GetNotifyProcessWFMStatusHistoricFailure = createAction(
  '[ProcessWFMStatusHistoric] Get Notify ProcessWFMStatusHistoric Failure',
  props<{ error: any }>()
);


export const GetProcessWFMStatusHistoric = createAction(
  '[ProcessWFMStatusHistoric] Get ProcessWFMStatusHistoric',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetProcessWFMStatusHistoricSuccess = createAction(
  '[ProcessWFMStatusHistoric] Get ProcessWFMStatusHistoric Success',
  props<{ data: any}>()
);

export const GetProcessWFMStatusHistoricFailure = createAction(
  '[ProcessWFMStatusHistoric] Get ProcessWFMStatusHistoric Failure',
  props<{ error: any }>()
);