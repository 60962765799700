import { Subject } from 'rxjs';
import { DashboardService, DashletSettingsService } from '@services/index';
import { Customer, Dashlet, IpnrMap } from '@models/index';

interface Point {
    x: Date;
    y: number;
}

export class DashletVoiceQualityActiveStreams extends Dashlet {
    public readonly commandTypeIdRtcpNew = '3A176A87-F14C-447E-AD1F-CD72E35EBC28';
    public readonly commandTypeIdLync = '73181898-00e7-41ce-8182-ae8d10a9f3a8';
    public readonly commandTypeIdIpnrLookup = '142e7a84-1b04-4c59-a645-9b9b1e5b5312';
    public customer!: Customer;
    public locationId!: string;
    public equipmentIds!: string[];
    public ipnrMap: IpnrMap = new IpnrMap();

    public readonly interval = 20;

    private mosData: { [source: string]: { unknown: number; low: number; med: number; high: number } } = {};

    mosDataGood: Point[] = [];
    mosGood!: number;
    mosDataSatisfactory: Point[] = [];
    mosSatisfactory!: number;
    mosDataPoor: Point[] = [];
    mosPoor!: number;
    mosDataUnknown: Point[] = [];
    mosUnknown!: number;
    groupName!: string;

    private dataUpdated: Subject<null> = new Subject();
    get onDataUpdated() {
        return this.dataUpdated;
    }

    public equipmentLoaded: Subject<null> = new Subject();

    constructor(private dashboardService: DashboardService, private settingsService: DashletSettingsService) {
        super();

        this.sizes = [
            {
                id: 0,
                label: 'Small',
                cols: 5,
                rows: 5
            },
            {
                id: 1,
                label: 'Large',
                cols: 10,
                rows: 5
            }
        ];
        this.applySize(0);

        this.dashboardService.getUpdateInterval(this.interval).subscribe(time => {
            this.produceData(time);
        });
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);
        this.configured = v.customer && v.location && v.equipment;

        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        this.locationId = v.location && v.location.value ? v.location.value : null;
        const equipValue: string = v.equipment && v.equipment.value ? v.equipment.value : null;
        this.generatedNameTag =
            v.location && v.equipment ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;

        if (equipValue && this.locationId) {
            this.configured = true;

            if (equipValue === '=all=') {
                this.settingsService.getRtcpReceivers(this.locationId).subscribe(res => {
                    if (res) {
                        this.equipmentIds = res.map(receiver => receiver.value);
                        this.equipmentLoaded.next(null);
                    }
                });
            } else {
                this.equipmentIds = [equipValue];
                this.equipmentLoaded.next(null);
            }
        }
    }

    dispose() {}

    resetData() {
        this.mosData = {};
        this.mosDataGood = [];
        this.mosDataSatisfactory = [];
        this.mosDataPoor = [];
        this.mosDataUnknown = [];

        this.mosGood = 0;
        this.mosSatisfactory = 0;
        this.mosPoor = 0;
        this.mosUnknown = 0;

        this.dataUpdated.next(null);
    }

    public processRtcpNew(sourceKey: string, data: any) {
        this.setBlankSource(sourceKey);
        if (data) {
            this.mosData[sourceKey] = {
                unknown: +data[0].data[0].MosUnknown,
                low: +data[0].data[0].MosLow,
                med: +data[0].data[0].MosMedium,
                high: +data[0].data[0].MosHigh
            };
        }
    }

    public processLync(sourceKey: string, data: any) {
        this.setBlankSource(sourceKey);
        if (data !== null) {
            for (const row of data[0].data) {
                const mosFrom = parseFloat(row.FromQualityScore);
                this.addSourceMos(sourceKey, mosFrom);
            }
        }
    }

    private addSourceMos(key: string, mos: number): void {
        if (isNaN(mos)) {
            mos = -1;
        }

        const scores = this.mosData[key] || this.setBlankSource(key);
        if (mos < 0) {
            scores.unknown++;
        } else if (mos < 3.6) {
            scores.low++;
        } else if (mos < 4) {
            scores.med++;
        } else {
            scores.high++;
        }
    }

    private setBlankSource(key: string) {
        return (this.mosData[key] = {
            unknown: 0,
            low: 0,
            med: 0,
            high: 0
        });
    }

    private produceData(time: Date): void {
        const earliest = new Date(time);

        if (this.getSize().id === 0) {
            earliest.setMinutes(earliest.getMinutes() - 30);
        } else if (this.getSize().id === 1) {
            earliest.setMinutes(earliest.getMinutes() - 60);
        }

        const thresFunc = (p: Point) => p.x > earliest;
        this.mosDataPoor = this.mosDataPoor.filter(thresFunc);
        this.mosDataSatisfactory = this.mosDataSatisfactory.filter(thresFunc);
        this.mosDataGood = this.mosDataGood.filter(thresFunc);
        this.mosDataUnknown = this.mosDataUnknown.filter(thresFunc);

        let unknown = 0,
            low = 0,
            med = 0,
            high = 0;
        for (const src in this.mosData) {
            unknown += this.mosData[src].unknown;
            low += this.mosData[src].low;
            med += this.mosData[src].med;
            high += this.mosData[src].high;
        }

        this.mosDataUnknown.push({
            x: time,
            y: unknown
        });
        this.mosUnknown = unknown;

        this.mosDataPoor.push({
            x: time,
            y: low
        });
        this.mosPoor = low;

        this.mosDataSatisfactory.push({
            x: time,
            y: med
        });
        this.mosSatisfactory = med;

        this.mosDataGood.push({
            x: time,
            y: high
        });
        this.mosGood = high;
        this.dataUpdated.next(null);
    }
}
