import { BrandingService } from '@services/index';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '@reducers/index';
import * as UserContextActions from '@actions/user-context/user-context.actions';
import * as BrandingActions from '@actions/branding/branding.actions';

import * as FullStory from '@fullstory/browser';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from '@environments/environment';
import { RealtimeGatewayService } from '@services/index';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    private onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private store$: Store<AppState>,
        private brandingService: BrandingService,
        private realtimeService: RealtimeGatewayService
    ) {
        this.realtimeService.initConnection();
    }

    public ngOnInit(): void {
        FullStory.init({ orgId: '196ZZF', devMode: !environment.production });

        this.store$.dispatch(UserContextActions.GetUserContexts());
        this.store$
            .pipe(
                select(state => state.dashboard.userContext),
                takeUntil(this.onDestroy$)
            )
            .subscribe((data: any) => {
                if (data) {
                    this.store$.dispatch(BrandingActions.GetBrandingColors({ entityId: data.EntityId }));
                    this.store$.dispatch(BrandingActions.GetBusinessPartnerLogo({ entityId: data.EntityId }));
                }
            });
        this.store$
            .pipe(
                select(state => state.branding.colors),
                takeUntil(this.onDestroy$)
            )
            .subscribe(data => {
                this.brandingService.applyBrandingColors(data.primaryColour, data.secondaryColour);
            });
    }

    public ngOnDestroy(): void {
        this.onDestroy$?.next();
        this.onDestroy$?.complete();
    }
}
