import { Customer, Dashlet, Equipment, IPNRStatusData } from '@models/index';
import { DashletDataProcessService, RealtimeGatewayService } from '@services/index';
import { Subject, Subscription } from 'rxjs';

interface Point {
    x: Date;
    y: number;
}

export class DashletIPNRStatus extends Dashlet {
    private readonly commandTypeIdIPNR = 'ECDB52F2-23F0-4A81-9631-C48EC449EE44';

    private equipment!: Equipment;
    private customer!: Customer;
    private subs: Subscription[] = [];

    readonly interval = 30;

    lshName!: string | null;
    uptimeMs!: number | null;
    loggedInUsers!: number;
    showNetworkConnectChart = true;
    IPNRData!: IPNRStatusData;
    IPNRSubscription!: Subscription;

    private chartDataUpdated: Subject<null> = new Subject();
    get onChartDataUpdated() {
        return this.chartDataUpdated;
    }

    constructor(
        private realtimeService: RealtimeGatewayService,
        private dashletDataService: DashletDataProcessService
    ) {
        super();

        // sizing
        this.sizes = [
            {
                id: 0,
                label: 'Small',
                cols: 5,
                rows: 2
            },
            {
                id: 1,
                label: 'Medium',
                cols: 5,
                rows: 4
            },
            {
                id: 2,
                label: 'Large',
                cols: 10,
                rows: 4
            }
        ];
        this.applySize(1);

        // init data
        this.resetData();

        // produce data every interval
        this.IPNRData.subscribeToIntervalUpdate(this.interval);
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        // unsub realtime feed
        this.unsubData();

        // read settings object
        this.configured = v.customer && v.location && v.equipment;
        if (v.equipment) {
            this.equipment = new Equipment(v.equipment.value, v.equipment.label);
        }
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        this.generatedNameTag = this.configured ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;
        this.lshName = this.configured ? v.equipment.label : null;

        // update size
        this.updateSize();

        // sub realtime feed
        this.subData();
    }

    applySize(id: number) {
        super.applySize(id);
        this.updateSize();
    }

    private updateSize() {
        const h = 0;
        const w = 0;
        this.applySizeExpansion(w, h);
    }

    private subData() {
        if (this.equipment) {
            // network data
            this.realtimeService.subscribe(this.equipment.equipmentId, this.commandTypeIdIPNR);
            const sub = this.realtimeService
                .observe(this.equipment.equipmentId, this.commandTypeIdIPNR)
                .subscribe(data => {
                    this.processNetworkData(data);
                    this.chartDataUpdated.next(null);
                });
            this.subs.push(sub);

            // request historic data
            this.realtimeService.requestHistoricDate(
                this.equipment.equipmentId,
                this.commandTypeIdIPNR,
                new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                new Date().toISOString(),
                20
            );
        }
    }

    private processNetworkData(data: any[]) {
        const row = data[0].data[0];
        if (row !== null) {
            const networkData = this.dashletDataService.processNetworkData(row);
            this.IPNRData.updateNetworkChartData(
                networkData.averagePing,
                networkData.packetLoss,
                networkData.maxPing,
                new Date(data[0].timestamp)
            );
        }
    }

    resetData() {
        this.lshName = null;
        this.uptimeMs = null;
        this.loggedInUsers = 0;
        this.IPNRData = new IPNRStatusData(this.equipment);
        this.chartDataUpdated.next(null);
    }

    dispose() {
        this.unsubData();
    }

    private unsubData() {
        if (this.equipment) {
            // unsub handlers
            if (this.subs && this.subs.length > 0) {
                this.subs.forEach(sub => sub.unsubscribe());
                this.subs = [];
            }
            // unsub data
            this.realtimeService.unsubscribe(this.equipment.equipmentId, this.commandTypeIdIPNR);
        }
    }
}
