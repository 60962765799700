import * as Actions from '@actions/index';
import { AccountService } from '@services/index';
import { AppState, selectDataFromCommonEntity } from '@reducers/index';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DashletLineGraphComponent } from '../dashlet-line-graph/dashlet-line-graph.component';
import { DashletTotalCustomersByRegion } from '@models/index';
import { select, Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-dashlet-total-customers-by-region',
    templateUrl: './dashlet-total-customers-by-region.component.html',
    styleUrls: ['./dashlet-total-customers-by-region.component.scss']
})
export class DashletTotalCustomersByRegionComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletTotalCustomersByRegion;
    private destroy$ = new Subject<void>();
    entityGroup: number;
    customerId: string;

    private pcustomersChart: DashletLineGraphComponent;
    get customersChart() {
        return this.pcustomersChart;
    }
    @ViewChild('customersChart') set customersChart(value: DashletLineGraphComponent) {
        this.pcustomersChart = value;
        this.updateChart();
    }

    private pcustomersChartWide: DashletLineGraphComponent;
    get customersChartWide() {
        return this.pcustomersChartWide;
    }
    @ViewChild('customersChartWide') set customersChartWide(value: DashletLineGraphComponent) {
        this.pcustomersChartWide = value;
        this.updateChart();
    }

    lineData = {};

    private totalCustomersEMEA = {
        label: 'Total Customers EMEA',
        backgroundColor: '#36d3bc',
        borderColor: 'white',
        pointBackgroundColor: 'white',
        pointHighlightStroke: 'white',
        pointRadius: 0,
        pointHitRadius: 10,
        fill: true,
        data: []
    };
    private totalCustomersNA = {
        label: 'Total Customers NA',
        backgroundColor: '#336699',
        borderColor: 'white',
        pointBackgroundColor: 'white',
        pointHighlightStroke: 'white',
        pointRadius: 0,
        pointHitRadius: 10,
        fill: true,
        data: []
    };
    private totalCustomersAPAC = {
        label: 'Total Customers APAC',
        backgroundColor: '#3399ff',
        borderColor: 'white',
        pointBackgroundColor: 'white',
        pointHighlightStroke: 'white',
        pointRadius: 0,
        pointHitRadius: 10,
        fill: true,
        data: []
    };

    customersLineData: any = {};
    customersChartOptions: any = {
        scales: {
            x: {
                type: 'time',
                grid: {
                    display: false
                },
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'd MMM'
                    },
                    stepSize: 3,
                    max: null,
                    min: null
                },
                ticks: {
                    fontSize: 10.2,
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0
                }
            },
            y: {
                stacked: true,
                grid: {
                    display: false
                },
                ticks: {
                    fontSize: 10.2,
                    beginAtZero: true
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'bottom',
                reverse: true
            },
            tooltip: {
                callback: {
                    title: tipItem => {
                        return null;
                    }
                }
            }
        },

        animation: {
            duration: 0
        }
    };
    customersChartOptionsWide: any = {
        scales: {
            x: {
                type: 'time',
                grid: {
                    display: false
                },
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'd MMM'
                    },
                    stepSize: 3,
                    max: null,
                    min: null
                },
                ticks: {
                    fontSize: 10.2,
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0
                }
            },
            y: {
                stacked: true,
                grid: {
                    display: false
                },
                ticks: {
                    fontSize: 10.2,
                    beginAtZero: true
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'bottom',
                reverse: true
            },
            tooltip: {
                callback: {
                    title: tipItem => {
                        return null;
                    }
                }
            }
        },

        animation: {
            duration: 0
        }
    };

    constructor(private accountService: AccountService, private store$: Store<AppState>) {
        this.customersLineData = {
            datasets: [this.totalCustomersNA, this.totalCustomersAPAC, this.totalCustomersEMEA]
        };
    }

    ngOnInit() {
        // network data

        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet._equipmentId,
                command: this.dashlet._commandTypeIdCustomer
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet._equipmentId,
                commandTypeId: this.dashlet._commandTypeIdCustomer
            })
        );

        this.store$
            .pipe(
                select(selectDataFromCommonEntity(this.dashlet._equipmentId + this.dashlet._commandTypeIdCustomer)),
                takeUntil(this.destroy$)
            )
            .subscribe(data => {
                if (data) {
                    this.dashlet.processCustomerData(data);
                }
            });

        this.dashlet.onChartDataUpdated.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.updateChart();
        });
        var userDetail = this.accountService.getUserDetails();
        this.entityGroup = userDetail.EntityGroup;
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        if (this.dashlet) this.dashlet.dispose();
    }

    private updateChart() {
        this.totalCustomersEMEA.data = this.dashlet.totalCustomersArrayEMEA;
        this.totalCustomersNA.data = this.dashlet.totalCustomersArrayNA;
        this.totalCustomersAPAC.data = this.dashlet.totalCustomersArrayAPAC;

        //update axis range
        let max = new Date();
        max.setDate(max.getDate() - 1);

        let min = new Date(max);
        min.setDate(min.getDate() - 15); //then show up to 15 days before max
        this.customersChartOptions.scales.x.min = min;
        this.customersChartOptions.scales.x.max = max;

        min = new Date(max);
        min.setDate(min.getDate() - 35); // show up to 35 days before max
        this.customersChartOptionsWide.scales.x.min = min;
        this.customersChartOptionsWide.scales.x.max = max;
        if (this.customersChart) {
            this.customersChart.updateChart();
        }
        if (this.customersChartWide) {
            this.customersChartWide.updateChart();
        }
    }

    get smallLayout() {
        return this.dashlet.getSize().id === 0;
    }

    get mediumLayout() {
        return this.dashlet.getSize().id === 1;
    }

    get wideLayout() {
        return this.dashlet.getSize().id === 2;
    }
}
