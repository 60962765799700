import { createAction, props } from '@ngrx/store';

export const GetRibbonSystemHealthEquipments = createAction(
    '[GetSystemHealthEquipment] Get RibbonSystemHealthEquipments',
    props<{ entityId: string }>()
);

export const GetRibbonSystemHealthEquipmentsSuccess = createAction(
    '[GetSystemHealthEquipment] Get RibbonSystemHealthEquipments Success',
    props<{ data: any; entityId: string }>()
);

export const GetRibbonSystemHealthEquipmentsFailure = createAction(
    '[GetSystemHealthEquipment] Get RibbonSystemHealthEquipments Failure',
    props<{ error: any }>()
);
