import { Customer, Dashlet, Equipment, Location } from '@models/index';
import { Subject } from 'rxjs';

interface Document {
    id: string;
    name: string;
    timestamp: string;
}

interface GenesysConversationDocument extends Document {
    data: {
        ConversationRecord: string;
        AverageDurationSeconds: string;
        StreamQualityGood: string;
        StreamQualitySatisfactory: string;
        StreamQualityPoor: string;
        TotalUniqueParticipants: string;
        TotalParticipants: string;
        TotalInbound: string;
        TotalOutbound: string;
        MediaTypes: string;
        Purposes: string;
    }[];
}

interface GenesysAgentDocument extends Document {
    data: GenesysAgentDataset[];
}

interface GenesysServiceStatusDocument extends Document {
    data: { [key: string]: string }[]; // Array of key-value pairs
}

interface GenesysAgentDataset {
    ConfigredAgentCount: string;
    LoggedInAgentCount: string;
}

interface AgentData {
    TotalAgents: number;
    LoggedInAgents: number;
}

export interface GenesysServiceStatusData {
    Service: string;
    Status: string;
    Colour: string;
}

export class DashletGenesysDetailsSummary extends Dashlet {
    public customer!: Customer;
    public location: Location;
    public equipmentId!: string;
    public hourSetting: number;
    public isDataOld: boolean = false;
    public equipment: Equipment;
    public showApiStatus: boolean = true; // To show API status in header

    public settingsChanges: Subject<null> = new Subject();
    public readonly commandTypeIdGenesysCallSummary = 'E68580F5-5E4B-48B7-BA18-A6B12E6757CA';
    public readonly commandTypeIdGenesysAgentSummary = '5B771E42-8F0E-48AC-8457-FA77C06F5786';
    public readonly commandTypeIdGenesysServiceStatus = 'F3BA09EE-49C7-4A93-9CFB-08D267354677';

    private readonly GenesysServiceStatus = {
        operational: { status: 'Normal', colour: 'green' },
        degraded_performance: { status: 'Degraded', colour: 'amber' },
        partial_outage: { status: 'Partial Outage', colour: 'amber' },
        major_outage: { status: 'Outage', colour: 'red' },
        under_maintenance: { status: 'Maintenance', colour: 'blueDark' }
    };

    public dataMap: Map<string, GenesysConversationDocument> = new Map();
    private maxDocuments: number = 96;
    public totalSummary = {
        callRecordsCount: 0,
        callDurationCount: 0,
        participantCount: 0,
        uniqueUsersCount: 0,
        streamSummary: {
            good: 0,
            poor: 0,
            satisfactory: 0
        },
        mediaType: {},
        purpose: {},
        originatingDirection: {
            inbound: 0,
            outbound: 0
        }
    };

    constructor() {
        super();

        this.sizes = [
            {
                id: 0,
                label: 'Huge',
                cols: 10,
                rows: 11
            }
        ];

        this.applySize(0);
    }

    public applySettings(v: { [key: string]: any }): void {
        super.applySettings(v);

        this.configured = v.customer && v.location && v.equipment && v.equipment?.length !== 0 && v.summaryPeriod;
        this.hourSetting = v.summaryPeriod ? Number(v.summaryPeriod.value) : null;

        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }

        if (v.equipment) {
            this.equipmentId = v.equipment.value ? v.equipment.value : null;
        }

        if (this.configured) {
            this.equipment = new Equipment(v.equipment.value, v.equipment.label);
            this.location = new Location(v.location.value, v.location.label);
            this.maxDocuments = this.hourSetting * 4;
            this.settingsChanges.next(null);
        }

        this.title = 'Genesys Cloud Conversation Summary';

        this.generatedNameTag = this.configured ? `${v.location.label} | ${v.equipment.label} ` : 'Unconfigured';
        this.customNameTag = v.nameTag;

        this.updateSize();
    }

    public applySize(id: number): void {
        super.applySize(id);
        this.updateSize();
    }

    private updateSize(): void {
        const h = 0;
        const w = 0;
        this.applySizeExpansion(w, h);
    }

    public resetData(): void {
        this.dataMap = new Map();
        this.totalSummary = {
            callRecordsCount: 0,
            callDurationCount: 0,
            participantCount: 0,
            uniqueUsersCount: 0,
            streamSummary: {
                good: 0,
                poor: 0,
                satisfactory: 0
            },
            mediaType: {},
            purpose: {},
            originatingDirection: {
                inbound: 0,
                outbound: 0
            }
        };
    }

    public dispose(): void {}

    public processAgentDocument(newDoc: GenesysAgentDocument): AgentData {
        const docData = newDoc.data[0];

        if (!docData) return null;

        return {
            TotalAgents: Number(docData.ConfigredAgentCount),
            LoggedInAgents: Number(docData.LoggedInAgentCount)
        };
    }

    public processIncomingConversationDocument(newDoc: GenesysConversationDocument) {
        // If a document with the same timestamp already exists, ignore the new one.
        if (this.dataMap.has(newDoc.timestamp)) {
            return;
        }
        // Store the document in the map using its timestamp as the key
        this.dataMap.set(newDoc.timestamp, newDoc);

        // Sum up the document's values
        const docData = newDoc.data[0];
        this.addToSummary(docData, newDoc.timestamp);

        // If there are more documents for selected period, remove the oldest one
        if (this.dataMap.size > this.maxDocuments) {
            const oldestTimestamp = this.dataMap.keys().next().value;
            const oldestDoc = this.dataMap.get(oldestTimestamp);
            this.dataMap.delete(oldestTimestamp);
            if (oldestDoc) {
                this.subtractFromSummary(oldestDoc.data[0], oldestTimestamp);
            }
        }
    }

    public processGenesysServiceStatusDocument(newDoc: GenesysServiceStatusDocument): GenesysServiceStatusData[] {
        if (!newDoc || !newDoc[0] || !newDoc[0].data || newDoc[0].data.length === 0) {
            return [];
        }
        const services: GenesysServiceStatusData[] = [];

        for (const [service, status] of Object.entries(newDoc[0].data[0])) {
            services.push({
                Service: service,
                Status: this.GenesysServiceStatus[status as string].status,
                Colour: this.GenesysServiceStatus[status as string].colour
            });
        }

        return services;
    }

    private addToSummary(docData: GenesysConversationDocument['data'][0], timeStamp: string) {
        this.totalSummary.callRecordsCount += Number(docData.ConversationRecord);
        this.totalSummary.callDurationCount += Number(docData.AverageDurationSeconds);
        this.totalSummary.participantCount += Number(docData.TotalParticipants);
        this.totalSummary.uniqueUsersCount += Number(docData.TotalUniqueParticipants);
        this.totalSummary.streamSummary.good += Number(docData.StreamQualityGood);
        this.totalSummary.streamSummary.poor += Number(docData.StreamQualityPoor);
        this.totalSummary.streamSummary.satisfactory += Number(docData.StreamQualitySatisfactory);
        this.totalSummary.originatingDirection.inbound += Number(docData.TotalInbound);
        this.totalSummary.originatingDirection.outbound += Number(docData.TotalOutbound);
        this.mergeRecord(this.totalSummary.mediaType, JSON.parse(docData.MediaTypes));
        this.mergeRecord(this.totalSummary.purpose, JSON.parse(docData.Purposes));
    }

    private subtractFromSummary(docData: GenesysConversationDocument['data'][0], timeStamp: string) {
        this.totalSummary.callRecordsCount -= Number(docData.ConversationRecord);
        this.totalSummary.callDurationCount -= Number(docData.AverageDurationSeconds);
        this.totalSummary.participantCount -= Number(docData.TotalParticipants);
        this.totalSummary.uniqueUsersCount -= Number(docData.TotalUniqueParticipants);
        this.totalSummary.streamSummary.good -= Number(docData.StreamQualityGood);
        this.totalSummary.streamSummary.poor -= Number(docData.StreamQualityPoor);
        this.totalSummary.streamSummary.satisfactory -= Number(docData.StreamQualitySatisfactory);
        this.totalSummary.originatingDirection.inbound -= Number(docData.TotalInbound);
        this.totalSummary.originatingDirection.outbound -= Number(docData.TotalOutbound);
        this.subtractRecord(this.totalSummary.mediaType, JSON.parse(docData.MediaTypes));
        this.subtractRecord(this.totalSummary.purpose, JSON.parse(docData.Purposes));
    }

    private mergeRecord(target: Record<string, number>, source: Record<string, number>) {
        for (const key in source) {
            if (!target[key]) {
                target[key] = 0;
            }
            target[key] += source[key];
        }
    }

    private subtractRecord(target: Record<string, number>, source: Record<string, number>) {
        for (const key in source) {
            if (target[key]) {
                target[key] -= source[key];
                if (target[key] === 0) {
                    delete target[key];
                }
            }
        }
    }

    public getTotalSummary() {
        return this.totalSummary;
    }
}
