import { DashletSettingsForm, DashletSettingsGroup, DashletSettingDropdown, DashletSettingType } from '@models/index';

export class DashletSettingsFormExample1 extends DashletSettingsForm {
    customer!: DashletSettingDropdown;
    equipment!: DashletSettingDropdown;

    constructor() /*private customerService: any*/ {
        super();
        this.init();
    }

    private init(): void {
        const group = new DashletSettingsGroup();
        const s: any = (group.settings = []);

        const customer = (this.customer = new DashletSettingDropdown());
        customer.label = 'Customer';
        customer.stateKey = 'customer';
        customer.items = [
            { label: 'Dummy 1', value: '1' },
            { label: 'Dummy 2', value: '2' }
        ];
        customer.value = '2';
        s.push(customer);

        const equipment = (this.equipment = new DashletSettingDropdown());
        equipment.label = 'Equipment';
        equipment.stateKey = 'equipment';
        equipment.items = [
            { label: 'Dummy A', value: 'A' },
            { label: 'Dummy B', value: 'B' }
        ];
        equipment.value = 'B';
        s.push(equipment);

        this.updateSettings(group);
    }
}
