import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Threshold, ThresholdTemplate } from '@models/index';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

class jsonRule {
    id!: number;
    parentid!: number | null;
    isanyall!: boolean;
    anyall!: string | null;
    field!: string | null;
    operatr!: string | null;
    value!: string | null;
    maskedvalue!: string | null;
    expression!: string | null;
    inputtype!: string | null;
    validrule!: boolean;
}

export enum BoardType {
    ANNC,
    IPSI
}

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class ThresholdService {
    constructor(protected http: HttpClient) {}

    getThresholdTemplateByCommandTypeId(commandTypeId: string, thresholdField: string) {
        const params = new HttpParams().append('commandTypeId', commandTypeId).append('thresholdField', thresholdField);

        return this.http.get<ThresholdTemplate>('API/ThresholdNg/GetThresholdTemplateByCommandTypeId', {
            params: params
        });
    }

    createNewThreshold(threshold: Threshold): Observable<Threshold> {
        return this.http.post<Threshold>('API/ThresholdNg/CreateNewDapThreshold', threshold, httpOptions);
    }

    editThreshold(threshold: Threshold) {
        return this.http.post<Threshold>('API/ThresholdNg/EditDapThreshold', threshold, httpOptions);
    }

    generateLinuxField(threshold: Threshold): string {
        switch (threshold.field.toLowerCase()) {
            case 'processor':
            case 'cpu':
                return 'CPUPercentage';
            case 'memory':
                return 'MemoryPercentage';
            case 'disk':
                if (threshold.fieldInstance) {
                    if (threshold.fieldInstance.toLowerCase() === 'any') {
                        return 'DiskPercentage';
                    }
                }
                return 'PercentUsed';
            default:
                return '';
        }
    }

    generateWindowsField(thresholdField: string): any {
        switch (thresholdField.toLowerCase()) {
            case 'processor':
            case 'cpu':
                return { CategoryName: 'Processor', CounterName: '% Processor Time', InstanceName: '_Total' };
            case 'memory':
                return { CategoryName: 'Memory', CounterName: 'Used Percentage' };
            case 'disk':
                return { CategoryName: 'LogicalDisk', CounterName: '% Free Space' };
            default:
                break;
        }
    }

    generateIpOfficeField(thresholdField: string): string {
        switch (thresholdField.toLowerCase()) {
            case 'processor':
            case 'cpu':
                return 'CPUUsage';
            case 'memory':
                return 'MemoryPercentage';
            default:
                return '';
        }
    }

    generateTrunkGroupListField(thresholdField: string): string {
        return 'TrunkPercentage';
    }

    generateLicenseCmsField(field: string): string {
        return field + 'Percentage';
    }

    generateVqmSummaryField(field: string): string {
        const regex = /[<>=.\s+]/g;
        const s = field.toLowerCase().charAt(0).toUpperCase() + field.toLowerCase().slice(1); // capitalise
        return s.replace(regex, '').replace('-', '_') + '_Percentage';
    }

    generateIpsiField(field: string): string {
        switch (field) {
            case 'IPSI Round Trip Delay':
            case 'NetworkDelayRoundTripMaxMsec':
                return 'NetworkDelayRoundTripMaxMsec';
            case 'IPSI Missed Heartbeats':
            case 'NetworkIndHeartBeatsMissed':
                return 'NetworkIndHeartBeatsMissed';
            default:
                return '';
        }
    }

    getThresholds(entityId: string) {
        const params = new HttpParams().append('entityId', entityId);
        return this.http.get<any[]>('API/ThresholdNg/GetThresholds', { params: params });
    }

    getThresholdsByEquipmentId(equipmentId: string) {
        const params = new HttpParams().append('equipmentId', equipmentId);
        return this.http.get<Threshold[]>(environment.centralApi + `Threshold/${equipmentId}`);
    }

    public getThresholdsBySettings(
        customerId: string,
        commandTypeId: string,
        locationId?: string,
        equipmentId?: string
    ): Observable<Threshold[]> {
        let baseParams = `Threshold/${customerId}/CommandType/${commandTypeId}`;

        if (locationId) {
            baseParams += `/Location/${locationId}`;
        }

        if (equipmentId) {
            baseParams += `/Equipment/${equipmentId}`;
        }

        return this.http.get<Threshold[]>(environment.centralApi + `` + `` + `` + ``);
    }

    deleteThreshold(threshold: Threshold) {
        return this.http.post<Threshold>('API/ThresholdNg/DeleteDapThreshold', threshold, httpOptions);
    }

    openThresholdManagement(entityId: string): any {
        window.open(environment.webPortalUrl + 'Threshold/Management?entityId=' + entityId);
    }

    createThreshold(entityId: string): any {
        window.open(environment.webPortalUrl + 'Threshold/Management?entityId=' + entityId + '&createThreshold=true');
    }

    buildJsonRulesByType(threshold: Threshold, type: string): string {
        switch (type) {
            case 'Linux':
            case 'SBC':
            case 'CMS':
            case 'ASM':
            case 'AES':
            case 'AEP':
            case 'ACM':
            case 'ACR':
                return this.buildLinuxJsonRules(threshold);
            case 'windows':
                return this.buildWindowsJsonRules(threshold);
            case 'ipOffice':
                return this.buildIpOfficeJsonRules(threshold);
            case 'trunkGroupList':
                return this.buildTrunkGroupJsonRules(threshold);
            case 'VqmMosSummary':
            case 'vqmSummary':
                return this.buildVqmSummaryJsonRules(threshold);
            case 'licenseAcm':
                return this.buildLicenseAcmJsonRules(threshold);
            case 'licenseAes':
                return this.buildLicenseAesJsonRules(threshold);
            case 'licenseCms':
                return this.buildLicenseCmsJsonRules(threshold);
            case 'AnnouncementBoard':
                return this.buildAnnouncementBoardJsonRules(threshold);
            case 'ACMIPSI':
                return this.buildIpsiJsonRules(threshold);
            case 'TrunkGroupSummary':
                return this.buildTrunkGroupOutOfServiceJsonRules(threshold);
            default:
                return '';
        }
    }

    private buildLicenseAesJsonRules(threshold: Threshold): string {
        const ruleSet = [];
        let id = 1;
        const parentRule = new jsonRule();
        parentRule.id = id;
        parentRule.parentid = null;
        parentRule.isanyall = true;
        parentRule.anyall = 'ALL';
        parentRule.field = null;
        parentRule.operatr = null;
        parentRule.value = null;
        parentRule.maskedvalue = null;
        parentRule.expression = null;
        parentRule.inputtype = null;
        parentRule.validrule = true;

        const operationRule = new jsonRule();
        operationRule.id = ++id;
        operationRule.parentid = parentRule.id;
        operationRule.isanyall = false;
        operationRule.anyall = null;
        operationRule.field = 'UsedPercentage';
        operationRule.operatr = threshold.operator;
        operationRule.value = threshold.value;
        operationRule.maskedvalue = null;
        operationRule.expression = null;
        operationRule.inputtype = null;
        operationRule.validrule = true;

        const aesNameRule = new jsonRule();
        aesNameRule.id = ++id;
        aesNameRule.parentid = parentRule.id;
        aesNameRule.isanyall = false;
        aesNameRule.anyall = null;
        aesNameRule.field = 'Name';
        aesNameRule.operatr = '==';
        aesNameRule.value = threshold.field;
        aesNameRule.maskedvalue = null;
        aesNameRule.expression = null;
        aesNameRule.inputtype = null;
        aesNameRule.validrule = true;

        ruleSet.push(parentRule, operationRule, aesNameRule);
        return JSON.stringify(ruleSet);
    }

    private buildLicenseCmsJsonRules(threshold: Threshold): string {
        const ruleSet = [];
        let id = 1;
        const parentRule = new jsonRule();
        parentRule.id = id;
        parentRule.parentid = null;
        parentRule.isanyall = true;
        parentRule.anyall = 'ALL';
        parentRule.field = null;
        parentRule.operatr = null;
        parentRule.value = null;
        parentRule.maskedvalue = null;
        parentRule.expression = null;
        parentRule.inputtype = null;
        parentRule.validrule = true;

        const operationRule = new jsonRule();
        operationRule.id = ++id;
        operationRule.parentid = parentRule.id;
        operationRule.isanyall = false;
        operationRule.anyall = null;
        operationRule.field = this.generateLicenseCmsField(threshold.field);
        operationRule.operatr = threshold.operator;
        operationRule.value = threshold.value;
        operationRule.maskedvalue = null;
        operationRule.expression = null;
        operationRule.inputtype = null;
        operationRule.validrule = true;

        ruleSet.push(parentRule, operationRule);
        return JSON.stringify(ruleSet);
    }

    private buildLicenseAcmJsonRules(threshold: Threshold): string {
        const ruleSet = [];
        let id = 1;
        const parentRule = new jsonRule();
        parentRule.id = id;
        parentRule.parentid = null;
        parentRule.isanyall = true;
        parentRule.anyall = 'ALL';
        parentRule.field = null;
        parentRule.operatr = null;
        parentRule.value = null;
        parentRule.maskedvalue = null;
        parentRule.expression = null;
        parentRule.inputtype = null;
        parentRule.validrule = true;

        const operationRule = new jsonRule();
        operationRule.id = ++id;
        operationRule.parentid = parentRule.id;
        operationRule.isanyall = false;
        operationRule.anyall = null;
        operationRule.field = 'UsedPercentage';
        operationRule.operatr = threshold.operator;
        operationRule.value = threshold.value;
        operationRule.maskedvalue = null;
        operationRule.expression = null;
        operationRule.inputtype = null;
        operationRule.validrule = true;

        const aesNameRule = new jsonRule();
        aesNameRule.id = ++id;
        aesNameRule.parentid = parentRule.id;
        aesNameRule.isanyall = false;
        aesNameRule.anyall = null;
        aesNameRule.field = 'Description';
        aesNameRule.operatr = '==';
        aesNameRule.value = threshold.field;
        aesNameRule.maskedvalue = null;
        aesNameRule.expression = null;
        aesNameRule.inputtype = null;
        aesNameRule.validrule = true;

        ruleSet.push(parentRule, operationRule, aesNameRule);
        return JSON.stringify(ruleSet);
    }

    private buildVqmSummaryJsonRules(threshold: Threshold): string {
        const ruleSet = [];
        let id = 1;
        const parentRule = new jsonRule();
        parentRule.id = id;
        parentRule.parentid = null;
        parentRule.isanyall = true;
        parentRule.anyall = 'ALL';
        parentRule.field = null;
        parentRule.operatr = null;
        parentRule.value = null;
        parentRule.maskedvalue = null;
        parentRule.expression = null;
        parentRule.inputtype = null;
        parentRule.validrule = true;

        const operationRule = new jsonRule();
        operationRule.id = ++id;
        operationRule.parentid = parentRule.id;
        operationRule.isanyall = false;
        operationRule.anyall = null;
        operationRule.field = this.generateVqmSummaryField(threshold.field);
        operationRule.operatr = threshold.operator;
        operationRule.value = threshold.value;
        operationRule.maskedvalue = null;
        operationRule.expression = null;
        operationRule.inputtype = null;
        operationRule.validrule = true;

        const group2 = new jsonRule();
        group2.id = ++id;
        group2.parentid = parentRule.id;
        group2.isanyall = true; // group indicator
        group2.anyall = 'ALL'; // and indicator
        group2.field = null;
        group2.operatr = null;
        group2.value = null;
        group2.maskedvalue = null;
        group2.expression = null;
        group2.inputtype = null;
        group2.validrule = true;

        const group2Rule = new jsonRule();
        group2Rule.id = ++id;
        group2Rule.parentid = group2.id;
        group2Rule.isanyall = false;
        group2Rule.anyall = null;
        group2Rule.field = threshold.additionalRuleGroup.rules[0].fieldName;
        group2Rule.operatr = threshold.additionalRuleGroup.rules[0].ruleOperator;
        group2Rule.value = threshold.additionalRuleGroup.rules[0].ruleValue.toLowerCase();
        group2Rule.maskedvalue = null;
        group2Rule.expression = null;
        group2Rule.inputtype = null;
        group2Rule.validrule = true;

        ruleSet.push(parentRule, operationRule, group2, group2Rule);
        return JSON.stringify(ruleSet);
    }

    private buildTrunkGroupJsonRules(threshold: Threshold): string {
        const ruleSet = [];
        let id = 1;
        const parentRule = new jsonRule();
        parentRule.id = id;
        parentRule.parentid = null;
        parentRule.isanyall = true;
        parentRule.anyall = 'ALL';
        parentRule.field = null;
        parentRule.operatr = null;
        parentRule.value = null;
        parentRule.maskedvalue = null;
        parentRule.expression = null;
        parentRule.inputtype = null;
        parentRule.validrule = true;

        const operationRule = new jsonRule();
        operationRule.id = ++id;
        operationRule.parentid = parentRule.id;
        operationRule.isanyall = false;
        operationRule.anyall = null;
        operationRule.field = this.generateTrunkGroupListField(threshold.field);
        operationRule.operatr = threshold.operator;
        operationRule.value = threshold.value;
        operationRule.maskedvalue = null;
        operationRule.expression = null;
        operationRule.inputtype = null;
        operationRule.validrule = true;

        const groupNumberRule = new jsonRule();
        groupNumberRule.id = ++id;
        groupNumberRule.parentid = parentRule.id;
        groupNumberRule.isanyall = false;
        groupNumberRule.anyall = null;
        groupNumberRule.field = threshold.fieldTrunkType === 'acm' ? 'GroupNumber' : 'TrunkNumber'; // difference between acm and ipo
        groupNumberRule.operatr = '==';
        groupNumberRule.value = threshold.fieldNumber.toString();
        groupNumberRule.maskedvalue = null;
        groupNumberRule.expression = null;
        groupNumberRule.inputtype = null;
        groupNumberRule.validrule = true;

        ruleSet.push(parentRule, operationRule, groupNumberRule);
        return JSON.stringify(ruleSet);
    }

    private buildLinuxJsonRules(threshold: Threshold): string {
        const ruleSet = [];
        let id = 1;
        const parentRule = new jsonRule();
        parentRule.id = id;
        parentRule.parentid = null;
        parentRule.isanyall = true;
        parentRule.anyall = 'ALL';
        parentRule.field = null;
        parentRule.operatr = null;
        parentRule.value = null;
        parentRule.maskedvalue = null;
        parentRule.expression = null;
        parentRule.inputtype = null;
        parentRule.validrule = true;

        const operationRule = new jsonRule();
        operationRule.id = ++id;
        operationRule.parentid = parentRule.id;
        operationRule.isanyall = false;
        operationRule.anyall = null;
        operationRule.field = this.generateLinuxField(threshold);
        operationRule.operatr = threshold.operator;
        operationRule.value = threshold.value;
        operationRule.maskedvalue = null;
        operationRule.expression = null;
        operationRule.inputtype = null;
        operationRule.validrule = true;

        const diskRule = new jsonRule();
        if (threshold.alarmName === 'Disk Threshold' && threshold.fieldInstance.toLowerCase() !== 'any') {
            // Check if we need to specify a disk
            diskRule.id = ++id;
            diskRule.parentid = parentRule.id;
            diskRule.isanyall = false;
            diskRule.anyall = null;
            diskRule.field = 'FileSystem';
            diskRule.operatr = '==';
            diskRule.value = threshold.fieldInstance;
            diskRule.maskedvalue = null;
            diskRule.expression = null;
            diskRule.inputtype = null;
            diskRule.validrule = true;
        }

        // const locationRule = new jsonRule;
        // if (threshold.locationId) {
        //     locationRule.id = ++id;
        //     locationRule.parentid = parentRule.id;
        //     locationRule.isanyall = false;
        //     locationRule.anyall = null;
        //     locationRule.field = "LocationId";
        //     locationRule.operatr = "==";
        //     locationRule.value = threshold.locationId;
        //     locationRule.maskedvalue = null;
        //     locationRule.expression = null;
        //     locationRule.inputtype = null;
        //     locationRule.validrule = true;
        // }

        // const equipmentRule = new jsonRule;
        // if (threshold.equipmentId) {
        //     equipmentRule.id = ++id;
        //     equipmentRule.parentid = parentRule.id;
        //     equipmentRule.isanyall = false;
        //     equipmentRule.anyall = null;
        //     equipmentRule.field = "EquipmentId";
        //     equipmentRule.operatr = "==";
        //     equipmentRule.value = threshold.equipmentId;
        //     equipmentRule.maskedvalue = null;
        //     equipmentRule.expression = null;
        //     equipmentRule.inputtype = null;
        //     equipmentRule.validrule = true;
        // }

        // this.ruleSet.push(parentRule, operationRule, locationRule, equipmentRule);
        ruleSet.push(parentRule, operationRule);
        if (diskRule.id) {
            ruleSet.push(diskRule);
        }

        return JSON.stringify(ruleSet);
    }

    private buildIpOfficeJsonRules(threshold: Threshold): string {
        const ruleSet = [];
        let id = 1;
        const parentRule = new jsonRule();
        parentRule.id = id;
        parentRule.parentid = null;
        parentRule.isanyall = true;
        parentRule.anyall = 'ALL';
        parentRule.field = null;
        parentRule.operatr = null;
        parentRule.value = null;
        parentRule.maskedvalue = null;
        parentRule.expression = null;
        parentRule.inputtype = null;
        parentRule.validrule = true;

        const operationRule = new jsonRule();
        operationRule.id = ++id;
        operationRule.parentid = parentRule.id;
        operationRule.isanyall = false;
        operationRule.anyall = null;
        operationRule.field = this.generateIpOfficeField(threshold.field);
        operationRule.operatr = threshold.operator;
        operationRule.value = threshold.value;
        operationRule.maskedvalue = null;
        operationRule.expression = null;
        operationRule.inputtype = null;
        operationRule.validrule = true;

        ruleSet.push(parentRule, operationRule);
        return JSON.stringify(ruleSet);
    }

    private buildWindowsJsonRules(threshold: Threshold): string {
        const ruleSet = [];
        const filedObject = this.generateWindowsField(threshold.field);
        let id = 1;
        const parentRule = new jsonRule();
        parentRule.id = id;
        parentRule.parentid = null;
        parentRule.isanyall = true;
        parentRule.anyall = 'ALL';
        parentRule.field = null;
        parentRule.operatr = null;
        parentRule.value = null;
        parentRule.maskedvalue = null;
        parentRule.expression = null;
        parentRule.inputtype = null;
        parentRule.validrule = true;

        const categoryRule = new jsonRule();
        categoryRule.id = ++id;
        categoryRule.parentid = parentRule.id;
        categoryRule.isanyall = false;
        categoryRule.anyall = null;
        categoryRule.field = 'CategoryName';
        categoryRule.operatr = '==';
        categoryRule.value = filedObject['CategoryName'];
        categoryRule.maskedvalue = null;
        categoryRule.expression = null;
        categoryRule.inputtype = null;
        categoryRule.validrule = true;

        const counterRule = new jsonRule();
        counterRule.id = ++id;
        counterRule.parentid = parentRule.id;
        counterRule.isanyall = false;
        counterRule.anyall = null;
        counterRule.field = 'CounterName';
        counterRule.operatr = '==';
        counterRule.value = filedObject['CounterName'];
        counterRule.maskedvalue = null;
        counterRule.expression = null;
        counterRule.inputtype = null;
        counterRule.validrule = true;

        const instanceRule = new jsonRule();
        if (threshold.fieldInstance) {
            instanceRule.id = ++id;
            instanceRule.parentid = parentRule.id;
            instanceRule.isanyall = false;
            instanceRule.anyall = null;
            instanceRule.field = 'InstanceName';
            instanceRule.operatr = '==';
            instanceRule.value = threshold.fieldInstance ? threshold.fieldInstance : filedObject['InstanceName'];
            instanceRule.maskedvalue = null;
            instanceRule.expression = null;
            instanceRule.inputtype = null;
            instanceRule.validrule = true;
        }

        const operationRule = new jsonRule();
        operationRule.id = ++id;
        operationRule.parentid = parentRule.id;
        operationRule.isanyall = false;
        operationRule.anyall = null;
        operationRule.field = 'Value';
        operationRule.operatr = threshold.operator;
        operationRule.value = threshold.value;
        operationRule.maskedvalue = null;
        operationRule.expression = null;
        operationRule.inputtype = null;
        operationRule.validrule = true;

        ruleSet.push(parentRule, categoryRule, counterRule, operationRule);
        Object.keys(instanceRule).length !== 0 ? ruleSet.push(instanceRule) : null;
        return JSON.stringify(ruleSet);
    }

    private buildAnnouncementBoardJsonRules(threshold: Threshold): string {
        const ruleSet = [];
        let id = 1;
        const parentRule = new jsonRule();
        parentRule.id = id;
        parentRule.parentid = null;
        parentRule.isanyall = true;
        parentRule.anyall = 'ALL';
        parentRule.field = null;
        parentRule.operatr = null;
        parentRule.value = null;
        parentRule.maskedvalue = null;
        parentRule.expression = null;
        parentRule.inputtype = null;
        parentRule.validrule = true;

        const operationRule = new jsonRule();
        operationRule.id = ++id;
        operationRule.parentid = parentRule.id;
        operationRule.isanyall = false;
        operationRule.anyall = null;
        operationRule.field = 'RemainingSeconds';
        operationRule.operatr = threshold.operator;
        operationRule.value = threshold.value;
        operationRule.maskedvalue = null;
        operationRule.expression = null;
        operationRule.inputtype = null;
        operationRule.validrule = true;

        const boardLocationRule = new jsonRule();
        boardLocationRule.id = ++id;
        boardLocationRule.parentid = parentRule.id;
        boardLocationRule.isanyall = false;
        boardLocationRule.anyall = null;
        boardLocationRule.field = 'BoardLocation';
        boardLocationRule.operatr = '==';
        boardLocationRule.value = threshold.field;
        boardLocationRule.maskedvalue = null;
        boardLocationRule.expression = null;
        boardLocationRule.inputtype = null;
        boardLocationRule.validrule = true;

        ruleSet.push(parentRule, operationRule, boardLocationRule);

        return JSON.stringify(ruleSet);
    }

    buildIpsiJsonRules(threshold: Threshold): string {
        const ruleSet = [];
        let id = 1;

        const parentRule = new jsonRule();
        parentRule.id = id;
        parentRule.parentid = null;
        parentRule.isanyall = true;
        parentRule.anyall = 'ALL';
        parentRule.field = null;
        parentRule.operatr = null;
        parentRule.value = null;
        parentRule.maskedvalue = null;
        parentRule.expression = null;
        parentRule.inputtype = null;
        parentRule.validrule = true;

        const operationRule = new jsonRule();
        operationRule.id = ++id;
        operationRule.parentid = parentRule.id;
        operationRule.isanyall = false;
        operationRule.anyall = null;
        operationRule.field = this.generateIpsiField(threshold.field);
        operationRule.operatr = threshold.operator;
        operationRule.value = threshold.value;
        operationRule.maskedvalue = null;
        operationRule.expression = null;
        operationRule.inputtype = null;
        operationRule.validrule = true;

        const boardLocationRule = new jsonRule();
        boardLocationRule.id = ++id;
        boardLocationRule.parentid = parentRule.id;
        boardLocationRule.isanyall = false;
        boardLocationRule.anyall = null;
        boardLocationRule.field = 'BoardLocation';
        boardLocationRule.operatr = '==';
        boardLocationRule.value = threshold.fieldInstance;
        boardLocationRule.maskedvalue = null;
        boardLocationRule.expression = null;
        boardLocationRule.inputtype = null;
        boardLocationRule.validrule = true;

        ruleSet.push(parentRule, operationRule, boardLocationRule);
        return JSON.stringify(ruleSet);
    }

    private buildTrunkGroupOutOfServiceJsonRules(threshold: Threshold): string {
        const ruleSet = [];
        let id = 1;
        const parentRule = new jsonRule();
        parentRule.id = id;
        parentRule.parentid = null;
        parentRule.isanyall = true;
        parentRule.anyall = 'ALL';
        parentRule.field = null;
        parentRule.operatr = null;
        parentRule.value = null;
        parentRule.maskedvalue = null;
        parentRule.expression = null;
        parentRule.inputtype = null;
        parentRule.validrule = true;

        const operationRule = new jsonRule();
        operationRule.id = ++id;
        operationRule.parentid = parentRule.id;
        operationRule.isanyall = false;
        operationRule.anyall = null;
        operationRule.field = 'OutOfService';
        operationRule.operatr = threshold.operator;
        operationRule.value = threshold.value;
        operationRule.maskedvalue = null;
        operationRule.expression = null;
        operationRule.inputtype = null;
        operationRule.validrule = true;

        const groupNumberRule = new jsonRule();
        groupNumberRule.id = ++id;
        groupNumberRule.parentid = parentRule.id;
        groupNumberRule.isanyall = false;
        groupNumberRule.anyall = null;
        groupNumberRule.field = 'GrpNumber';
        groupNumberRule.operatr = '==';
        groupNumberRule.value = threshold.fieldNumber.toString();
        groupNumberRule.maskedvalue = null;
        groupNumberRule.expression = null;
        groupNumberRule.inputtype = null;
        groupNumberRule.validrule = true;

        ruleSet.push(parentRule, operationRule, groupNumberRule);
        return JSON.stringify(ruleSet);
    }

    public getBoardLocations(customerId: string, equipmentId: string, type: BoardType) {
        const params = new HttpParams().append('customerId', customerId).append('equipmentId', equipmentId);
        if (type === BoardType.ANNC) {
            return this.http.get<any>('API/ThresholdNg/GetANNCBoardLocations', {
                params
            });
        } else if (type === BoardType.IPSI) {
            return this.http.get<any>('API/ThresholdNg/GetIPSIBoardLocations', {
                params
            });
        } else {
            return;
        }
    }
}
