import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

export const GetNotifyProcessLyncFrontEndServerData = createAction(
  '[ProcessLyncFrontEndServerData] Get Notify ProcessLyncFrontEndServerDatas',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetNotifyProcessLyncFrontEndServerDataSuccess = createAction(
  '[ProcessLyncFrontEndServerData] Get Notify ProcessLyncFrontEndServerDatas Success',
  props<{ data: RealTimeNotify }>()
);

export const GetNotifyProcessLyncFrontEndServerDataFailure = createAction(
  '[ProcessLyncFrontEndServerData] Get Notify ProcessLyncFrontEndServerDatas Failure',
  props<{ error: any }>()
);


export const GetProcessLyncFrontEndServerData = createAction(
  '[ProcessLyncFrontEndServerData] Get ProcessLyncFrontEndServerDatas',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetProcessLyncFrontEndServerDataSuccess = createAction(
  '[ProcessLyncFrontEndServerData] Get ProcessLyncFrontEndServerDatas Success',
  props<{ data: any}>()
);

export const GetProcessLyncFrontEndServerDataFailure = createAction(
  '[ProcessLyncFrontEndServerData] Get ProcessLyncFrontEndServerDatas Failure',
  props<{ error: any }>()
);