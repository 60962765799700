/* eslint-disable no-undef */
import { Customer, Dashlet, Equipment, Location, MemoryUsageData } from '@models/index';
import { Subscription } from 'rxjs';

interface WanLink {
    name: string;
    status: string;
    duplex: string;
    speed: number;
}
interface MediaUtilization {
    slotAndPort: string;
    rxPerc: number;
    txPerc: number;
}
export interface Realm {
    name: string;
    in: number;
    out: number;
    totalBw: number;
    noBw: number;
}

export class DashletSystemHealthOracle extends Dashlet {
    public readonly commandTypeIdCpu = 'b14df118-4161-439b-ad16-f490ec5f615e';
    public readonly commandTypeIdUptime = '28b8f190-49b7-461e-a5ce-d794954252d4';
    public readonly commandTypeIdWan = 'c3dbb5a5-b33b-467d-9c71-68b9c9053be3';
    public readonly commandTypeIdMediaUtilization = '8b45ebbf-d166-4573-8955-7c9f61b0ebad';
    public readonly commandTypeIdSessions = 'c867867f-c3da-4811-8f5b-299ceb1dc518';
    public readonly commandTypeIdSipdInterface = '42a315bf-d969-4a77-b0be-cc3d25ff1f6e';
    public readonly commandTypeIdMbcdRealms = '62924e63-e2e1-4ad6-8666-a9bb7ccfcdbb';
    private subs: Subscription[] = [];

    readonly interval = 30;
    customer!: Customer;
    location!: Location;
    equipment!: Equipment;

    showSystemChart = false;
    wanLinks!: WanLink[];
    media!: MediaUtilization[];
    sessionsSip!: number;
    sessionsH323!: number;
    sessionsSipToH323!: number;
    sessionsH323ToSip!: number;
    realms!: Realm[];

    constructor() {
        super();

        // sizing
        this.sizes = [
            {
                id: 0,
                label: 'Standard',
                cols: 5,
                rows: 9
            }
        ];
        this.applySize(0);

        // init data
        this.resetData();
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        // read settings object
        this.configured = v.customer && v.location && v.equipment;
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
            // this.loadCustomerLogo();
        } else {
            this.customer = new Customer('', '');
        }
        if (v.location) {
            this.location = new Location(v.location.value, v.location.label);
        } else {
            this.location = new Location('', '');
        }
        if (v.equipment) {
            this.equipment = new Equipment(v.equipment.value, v.equipment.label);
        } else {
            this.equipment = new Equipment('', '');
        }
        this.generatedNameTag = this.configured ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;
        this.showSystemChart = v.showSystemChart || false;

        // apply size
        let h = 0;
        if (this.showSystemChart) {
            h += 1;
        }
        this.applySizeExpansion(0, h);

        // sub realtime feed
        // this.subData();
    }

    resetData() {
        this.wanLinks = [];
        this.media = [];
        this.sessionsSip = 0;
        this.sessionsH323 = 0;
        this.sessionsSipToH323 = 0;
        this.sessionsH323ToSip = 0;
        this.realms = [];
    }

    dispose() {}
    public processWanData(data: any[]) {
        const links = data[0].data;
        this.wanLinks = links.map((l: any) => {
            return {
                duplex: l.DuplexMode,
                name: l.LinkName,
                speed: +l.Speed,
                status: l.Status
            };
        });
    }

    public processMediaUtilData(data: any[]) {
        const media = data[0].data;
        this.media = media.map((m: any) => {
            return {
                slotAndPort: `${+m.Slot}/${+m.Port}`,
                rxPerc: +m.ReceiveUtilization,
                txPerc: +m.TransmitUtilization
            };
        });
    }

    public processSessionsData(data: any[]) {
        const records = data[0].data;
        for (const r of records) {
            const active = +r.PeriodActive;
            switch (r.DataType) {
                case 'SIP Sessions':
                    this.sessionsSip = active;
                    break;
                case 'H.323 Calls':
                    this.sessionsH323 = active;
                    break;
                case 'H.323-to-SIP Calls':
                    this.sessionsH323ToSip = active;
                    break;
                case 'SIP-to-H.323 Calls':
                    this.sessionsSipToH323 = active;
                    break;
            }
        }
    }

    public processRealmsData(sipdData: any[], mbcdData: any[]) {
        // map mdcb name
        const mbcdNameMap: { [name: string]: any } = {};
        const mbcd = mbcdData[0].data;
        for (const m of mbcd) {
            mbcdNameMap[m.Realm] = m;
        }

        // process realms
        const sipd = sipdData[0].data;
        this.realms = sipd
            .filter((s: any) => !!mbcdNameMap[s.Realm])
            .map((s: any) => {
                const m = mbcdNameMap[s.Realm];
                return {
                    name: s.Realm,
                    in: +s.InboundActive,
                    out: +s.OutboundActive,
                    totalBw: +m.BandwidthUsageTotal,
                    noBw: +m.BandwidthUsageInsufficientBandWidth
                };
            });
    }
}
