import { Customer, Dashlet } from '@models/index';
import { Subject } from 'rxjs';

export class DashletGenesysTrunksSummary extends Dashlet {
    public tableHeightRows = 1;
    public customer!: Customer;
    public equipmentId!: string;
    public trunkBaseIds: string[] = [];
    public expanded!: boolean;
    public isDataOld: boolean = false;
    public showApiStatus: boolean = true;

    public settingsChanges: Subject<null> = new Subject();

    constructor() {
        super();

        this.sizes = [
            {
                id: 0,
                label: 'Medium',
                cols: 8,
                rows: 7
            }
        ];

        this.applySize(0);
    }

    public applySettings(v: { [key: string]: any }): void {
        super.applySettings(v);
        this.configured = v.customer && v.equipment && v.trunkBases?.length;
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }

        if (v.equipment) {
            this.equipmentId = v.equipment.value ? v.equipment.value : null;
        }

        if (v.trunkBases?.length) {
            this.trunkBaseIds = v.trunkBases.map(x => x.value.toLowerCase());
        }
        if (this.configured) {
            this.settingsChanges.next(null);
        }

        this.expanded = v.expanded || false;

        this.generatedNameTag = this.configured ? `${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;
        this.updateSize();
    }

    public applySize(id: number): void {
        super.applySize(id);
        this.updateSize();
    }

    private updateSize(): void {
        const h = 0;
        const w = 0;
        this.applySizeExpansion(w, h);
    }

    public resetData(): void {}

    public dispose(): void {}
}
