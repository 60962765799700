import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

export const GetNotifyProcessSupervisorLicenses = createAction(
  '[ProcessSupervisorLicenses] Get Notify ProcessSupervisorLicenses',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetNotifyProcessSupervisorLicensesSuccess = createAction(
  '[ProcessSupervisorLicenses] Get Notify ProcessSupervisorLicenses Success',
  props<{ data: RealTimeNotify }>()
);

export const GetNotifyProcessSupervisorLicensesFailure = createAction(
  '[ProcessSupervisorLicenses] Get Notify ProcessSupervisorLicenses Failure',
  props<{ error: any }>()
);

export const GetProcessSupervisorLicenses = createAction(
  '[ProcessSupervisorLicenses] Get ProcessSupervisorLicenses',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetProcessSupervisorLicensesSuccess = createAction(
  '[ProcessSupervisorLicenses] Get ProcessSupervisorLicenses Success',
  props<{ data: any}>()
);

export const GetProcessSupervisorLicensesFailure = createAction(
  '[ProcessSupervisorLicenses] Get ProcessSupervisorLicenses Failure',
  props<{ error: any }>()
);
