import * as LocationActions from '@actions/get-locations/get-locations.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { DashletSettingsService } from '@services/index';

@Injectable()
export class GetLocationsEffects {
    constructor(private actions$: Actions, private settingsService: DashletSettingsService) {}

    getLocations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LocationActions.GetLocations),
            mergeMap(action =>
                this.settingsService.getLocations(action.customerId).pipe(
                    catchError(err => of(LocationActions.GetLocationsFailure({ error: err }))),
                    map((data: any) =>
                        LocationActions.GetLocationsSuccess({ data: { data, customerId: action.customerId } })
                    )
                )
            )
        )
    );
}
