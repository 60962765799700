import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

export const GetNotifyProcessAcdCalls = createAction(
  '[ProcessAcdCalls] Get ProcessAcdCalls',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetNotifyProcessAcdCallsSuccess = createAction(
  '[ProcessAcdCalls] Get ProcessAcdCalls Success',
  props<{ data: RealTimeNotify }>()
);

export const GetNotifyProcessAcdCallsFailure = createAction(
  '[ProcessAcdCalls] Get ProcessAcdCalls Failure',
  props<{ error: any }>()
);


export const GetProcessAcdCalls = createAction(
  '[ProcessAcdCalls] Get ProcessAcdCalls',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetProcessAcdCallsSuccess = createAction(
  '[ProcessAcdCalls] Get ProcessAcdCalls Success',
  props<{ data: any}>()
);

export const GetProcessAcdCallsFailure = createAction(
  '[ProcessAcdCalls] Get ProcessAcdCalls Failure',
  props<{ error: any }>()
);
