<div class="flex-column gap-l">

        <!-- system -->
        <dashlet-section-processor-memory *ngIf="!openSubcontainer"
            #processorMemory
            [showSystemChart]="dashlet.showSystemChart"
            [equipment]="dashlet.equipment"
            [customerId]="dashlet.customer.customerId"
            [locationId]="dashlet.location.locationId"
        >
        </dashlet-section-processor-memory>
        <!-- network connectivity -->
        <dashlet-section-network-connectivity *ngIf="!openSubcontainer"
            [showNetworkGraph]="dashlet.showNetworkGraph"
            [equipment]="dashlet.equipment"
            [customerId]="dashlet.customer.customerId"
        >
        </dashlet-section-network-connectivity>

            <dashlet-section-services *ngIf="(dashlet.showServiceStatus && !openSubcontainer) || openSubcontainer === 'service'"
                #services
                [equipment]="dashlet.equipment"
                [location]="dashlet.location"
                [customer]="dashlet.customer"
                [commandTypeIdServices]="[dashlet.commandTypeIdServiceStatus, dashlet.commandTypeIdServiceStatusOperational]"
                (openDetails)="$event === null ? closeDetails() : openDetails('service', $event)"
            >
            </dashlet-section-services>

        <!-- Trunk status -->
        <div *ngIf="dashlet.showTrunkStatus && !openSubcontainer">
            <dashlet-section-header>Trunk Status</dashlet-section-header>
            <dashlet-table [sortable]="false" [columns]="trunkColumns" [dataSource]="dashlet.trunkStatusList" [tableHeightRows]="3"></dashlet-table>
        </div>

        <!-- Application status -->
        <div *ngIf="dashlet.showApplicationStatus && !openSubcontainer" >
            <dashlet-section-header>Application Status</dashlet-section-header>
            <dashlet-table [sortable]="false" [columns]="appColumns" [dataSource]="dashlet.applicationStatusList" [tableHeightRows]="3"></dashlet-table>

        </div>

        <dashlet-section-custom-scripts *ngIf="dashlet.showCustomScripts && !openSubcontainer" [equipment]="dashlet.equipment" [customer]="dashlet.customer">
        </dashlet-section-custom-scripts>


</div>
<!-- Service Status Drilldown Content -->

<!-- create thresholds alerts drilldown -->
<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'createThreshold' || openSubcontainer === 'editThreshold'"
    [navPrevious]="'AEP System Health - ' + dashlet.customer.customerName"
    [navCurrent]="(openSubcontainer === 'createThreshold' ? 'Create' : 'Edit') + ' Threshold'"
    (close)="closeDetails()"
>
    <ng-container title>{{ openSubcontainer === 'createThreshold' ? 'New' : 'Edit' }} {{ selectedField }} Threshold </ng-container>
    <ng-container body>
        <threshold-item #thresholdItem [settings]="dashlet.getSettings()" [type]="'AEP'" [threshold]="selectedThreshold"> </threshold-item>
    </ng-container>
    <div footer class="row row-buttons">
        <div>
            <button type="button" pButton class="btn button-details" (click)="closeDetails()" label="Cancel"></button>
        </div>
        <div *ngIf="openSubcontainer === 'editThreshold'; else addThreshold">
            <button type="submit" pButton class="btn button-details" (click)="saveEdit()" [disabled]="!thresholdItem.formValid()" label="Save"></button>
        </div>
        <ng-template #addThreshold>
            <button type="submit" pButton class="btn button-details" (click)="onSubmit()" [disabled]="!thresholdItem.formValid()" label="Save"></button>
        </ng-template>
    </div>
</vsm-dashlet-subcontainer>
