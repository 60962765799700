import { createAction, props } from '@ngrx/store';

export const GetTypeEquipmentsAtLocations = createAction(
    '[GetTypeEquipmentsAtLocation] Get TypeEquipmentsAtLocations',
    props<{ locationId: string; typeStr: string }>()
);

export const GetTypeEquipmentsAtLocationsSuccess = createAction(
    '[GetTypeEquipmentsAtLocation] Get TypeEquipmentsAtLocations Success',
    props<{ data: any }>()
);

export const GetTypeEquipmentsAtLocationsFailure = createAction(
    '[GetTypeEquipmentsAtLocation] Get TypeEquipmentsAtLocations Failure',
    props<{ error: any }>()
);
