import { Component, Input } from '@angular/core';

@Component({
    // moduleId: module.id.toString(),
    selector: 'vsm-dashlet-loading',
    templateUrl: 'dashlet-loading.component.html',
    styleUrls: ['./dashlet-loading.component.scss']
})
export class DashletLoadingComponent {
    @Input() message: string = 'Loading';
    @Input() size: 'large' | 'small' = 'small';
}