export class BackupData {
	private _type: string;
	private _displayDateTime: string;
	private _lastBackupStatus: string;
	private _state: number;
	private _message: string;

	constructor(type: string, displayDateTime: string, lastBackupStatus: string, state: number, message: string) {
		this._type = type;
		this._displayDateTime = displayDateTime;
		this._lastBackupStatus = lastBackupStatus;
		this._state = state;
		this._message = message;
	}

	/**
	 * Getter type
	 * @return {string}
	 */
	public get type(): string {
		return this._type;
	}

	/**
	 * Getter displayDateTime
	 * @return {string}
	 */
	public get displayDateTime(): string {
		return this._displayDateTime;
	}

	/**
	 * Getter lastBackupStatus
	 * @return {string}
	 */
	public get lastBackupStatus(): string {
		return this._lastBackupStatus;
	}

	/**
	 * Getter state
	 * @return {number}
	 */
	public get state(): number {
		return this._state;
	}

	/**
	 * Getter message
	 * @return {string}
	 */
	public get message(): string {
		return this._message;
	}

	/**
	 * Setter type
	 * @param {string} value
	 */
	public set type(value: string) {
		this._type = value;
	}

	/**
	 * Setter displayDateTime
	 * @param {string} value
	 */
	public set displayDateTime(value: string) {
		this._displayDateTime = value;
	}

	/**
	 * Setter lastBackupStatus
	 * @param {string} value
	 */
	public set lastBackupStatus(value: string) {
		this._lastBackupStatus = value;
	}

	/**
	 * Setter state
	 * @param {number} value
	 */
	public set state(value: number) {
		this._state = value;
	}

	/**
	 * Setter message
	 * @param {string} value
	 */
	public set message(value: string) {
		this._message = value;
	}

	/**
	 * Update the backup data object with new data
	 * @param type
	 * @param displayDateTime
	 * @param lastBackupStatus
	 * @param state
	 * @param message
	 */
	public update(type: string, displayDateTime: string, lastBackupStatus: string, state: number, message: string) {
		this._type = type;
		this._displayDateTime = displayDateTime;
		this._lastBackupStatus = lastBackupStatus;
		this._state = state;
		this._message = message;
	}
}
