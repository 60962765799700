import { LicenceData } from '@models/index';

export class DMCCData {
	dataExpired!: boolean;
	private _licences: LicenceData[];
	private _totalMonitors: string;
	private _usedMonitors: string;
	private _activeSessions: string;
	private _activeDevices: string;

	constructor() {
		this._licences = [];
		this._totalMonitors = '';
		this._usedMonitors = '';
		this._activeSessions = '';
		this._activeDevices = '';
	}

	public update(totalMonitors: string, usedMonitors: string, activeSessions: string, activeDevices: string) {
		this.totalMonitors = totalMonitors;
		this.usedMonitors = usedMonitors;
		this.activeSessions = activeSessions;
		this.activeDevices = activeDevices;
	}

	public updateLicenses(licence: LicenceData) {
		const index = this.licences.findIndex(e => e.licenceName == licence.licenceName);
		if (index != -1) {
			this.licences[index].updateFromObject(licence);
		} else {
			this.licences.push(licence);
		}
	}

	getAcquiredLicences() {
		let acquiredTotal = 0;
		this.licences.forEach(e => {
			acquiredTotal += e.acquired;
		});
		return acquiredTotal;
	}

	getTotalLicences() {
		let totalSum = 0;
		this.licences.forEach(e => {
			totalSum += e.total;
		});
		return totalSum;
	}

	public getStatus() {
		return `${this.getAcquiredLicences()} of ${this.getTotalLicences()} in use`;
	}

	public getSeverity() {
		const usageProportion: number = this.getAcquiredLicences() / this.getTotalLicences();
		if (usageProportion < 0.8 || this.getTotalLicences() == 0) {
			return 0;
		} else if (usageProportion < 0.9) {
			return 1;
		} else {
			return 2;
		}
	}

	/**
	 *
	 */
	public get activeSessions() {
		return this._activeSessions;
	}

	/**
	 *
	 */
	public get activeDevices() {
		return this._activeDevices;
	}

	/**
	 * Getter licence
	 * @return {LicenceData[]}
	 */
	public get licences(): LicenceData[] {
		return this._licences;
	}

	/**
	 * Getter usedMonitors
	 * @return {string}
	 */
	public get usedMonitors(): string {
		return this._usedMonitors;
	}

	/**
	 * Getter totalMonitors
	 * @return {string}
	 */
	public get totalMonitors(): string {
		return this._totalMonitors;
	}

	/**
	 * Setter usedMonitors
	 * @param {string} value
	 */
	public set usedMonitors(value: string) {
		if (value == null || value == '') {
			value = '---';
		}
		this._usedMonitors = value;
	}

	/**
	 * Setter activeSessions
	 * @param {string} value
	 */
	public set activeSessions(value: string) {
		if (value == null || value == '') {
			value = '---';
		}
		this._activeSessions = value;
	}

	/**
	 * Setter activeDevices
	 * @param {string} value
	 */
	public set activeDevices(value: string) {
		if (value == null || value == '') {
			value = '---';
		}
		this._activeDevices = value;
	}

	/**
	 * Setter totalMonitors
	 * @param {string} value
	 */
	public set totalMonitors(value: string) {
		if (value == null || value == '') {
			value = '---';
		}
		this._totalMonitors = value;
	}
}
