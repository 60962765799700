import { FeatureSubscriptionsRespObj } from '@models/index';
import { createAction, props } from '@ngrx/store';

export const GetReportSubscriptions = createAction(
    '[ReportSubscriptions] Get Report Subscriptions',
    props<{ customerId: string }>()
);

export const GetReportSubscriptionsSuccess = createAction(
    '[ReportSubscriptions] Get Report Subscriptions Success',
    props<{ data: FeatureSubscriptionsRespObj }>()
);

export const GetReportSubscriptionsFailure = createAction(
    '[ReportSubscriptions] Get Report Subscriptions Failure',
    props<{ error: any }>()
);
