import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

export const GetNotifyProcessDiskData = createAction(
  '[ProcessDiskData] Get Notify ProcessDiskDatas',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetNotifyProcessDiskDataSuccess = createAction(
  '[ProcessDiskData] Get Notify ProcessDiskDatas Success',
  props<{ data: RealTimeNotify }>()
);

export const GetNotifyProcessDiskDataFailure = createAction(
  '[ProcessDiskData] Get Notify ProcessDiskDatas Failure',
  props<{ error: any }>()
);

export const GetProcessDiskData = createAction(
  '[ProcessDiskData] Get ProcessDiskDatas',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetProcessDiskDataSuccess = createAction(
  '[ProcessDiskData] Get ProcessDiskDatas Success',
  props<{ data: any}>()
);

export const GetProcessDiskDataFailure = createAction(
  '[ProcessDiskData] Get ProcessDiskDatas Failure',
  props<{ error: any }>()
);

// timer
export const GetProcessDiskDataTimer = createAction(
  '[ProcessDiskData] Get ProcessDiskData Timer',
  props<{ equipmentId: string, command: string }>()
);

export const GetProcessDiskDataTimerSuccess = createAction(
  '[ProcessDiskData] Get ProcessDiskData Timer Success',
  props<{ data:any }>() 
);

export const GetProcessDiskDataTimerFailure = createAction(
  '[ProcessDiskData] Get ProcessDiskData Timer Failure',
  props<{ error: any }>()
);

