/* eslint-disable no-undef */
import {
    AEPApplicationStatus,
    Customer,
    Dashlet,
    Equipment,
    Location,
    ServiceStatus,
    Threshold,
    TrunkStatus
} from '@models/index';

export class DashletSystemHealthAEP extends Dashlet {
    public readonly commandTypeIdServiceStatus = 'FCBD605F-1781-47CC-8AB3-24AC97FEDB05';
    public readonly commandTypeIdServiceStatusOperational = 'F9F69B0E-6135-4645-BF39-2A2D867778CA';
    public readonly commandTypeIdTrunkStatus = 'C31BC130-BA99-4ECD-86F5-C93B8E255D5E';
    public readonly commandTypeIdApplicationStatus = '02B10ABA-EAD1-40FA-85D8-F3CDCD5FDCB2';

    readonly interval = 30;
    customer!: Customer;
    location!: Location;
    equipment!: Equipment;

    serviceStatusList!: ServiceStatus[];
    trunkStatusList!: TrunkStatus[];
    applicationStatusList!: AEPApplicationStatus[];

    showSystemChart = false;
    showNetworkGraph = false;
    showCallRate = false;
    showServiceStatus = false;
    showApplicationStatus = false;
    showTrunkStatus = false;
    showCustomScripts = false;

    commandsRunData: any = [];
    commandsRunDataLoading = true;

    thresholds: Threshold[] = [];

    constructor() {
        super();

        this.sizes = [
            {
                id: 0,
                label: 'Standard',
                cols: 5,
                rows: 6
            }
        ];
        this.applySize(0);

        this.resetData();
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);
        this.configured = v.customer && v.location && v.equipment;
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        if (v.location) {
            this.location = new Location(v.location.value, v.location.label);
        } else {
            this.location = new Location('', '');
        }
        if (v.equipment) {
            this.equipment = new Equipment(v.equipment.value, v.equipment.label);
        } else {
            this.equipment = new Equipment('', '');
        }
        this.generatedNameTag = this.configured
            ? `${v.customer.label}, ${v.location.label} | ${v.equipment.label}`
            : 'Unconfigured';
        this.customNameTag = v.nameTag;

        this.showSystemChart = v.showSystemChart || false;
        this.showNetworkGraph = v.showNetworkGraph || false;
        this.showServiceStatus = v.showServiceStatus || false;
        this.showApplicationStatus = v.showApplicationStatus;
        this.showTrunkStatus = v.showTrunkStatus;
        this.showCustomScripts = v.showCustomScripts || false;

        let h = 0;
        if (this.showSystemChart) h += 1.9;
        if (this.showNetworkGraph) h += 2.1;
        if (this.showServiceStatus) h += 1.2;
        if (this.showApplicationStatus) h += 1.9;
        if (this.showTrunkStatus) h += 1.9;

        if (this.showCustomScripts) h += 4;

        this.applySizeExpansion(0, h);
    }

    resetData() {
        this.serviceStatusList = [];
        this.trunkStatusList = [];
        this.applicationStatusList = [];
    }

    dispose() {} // Method must be kept as this class extends the "Dashlet" class

    processTrunkStatus(data: any[]) {
        const trunkNames = data[0].data
            .map((e: any) => e.Trunk)
            .filter((e: any, i: number, a: any) => i === a.indexOf(e));

        for (const trunkName of trunkNames) {
            let connected = 0;
            let inService = 0;
            let portCount = 0;

            const currentTrunkList = data[0].data.filter((e: any) => e.Trunk === trunkName);

            for (const item of currentTrunkList) {
                if (item.CallState.toLowerCase() === 'connected') {
                    connected++;
                }

                if (item.State.toLowerCase() === 'in-service') {
                    inService++;
                }
                portCount++;
            }

            const index = this.trunkStatusList.findIndex(e => e.trunkName === trunkName);
            if (index === -1) {
                this.trunkStatusList.push(new TrunkStatus(trunkName, portCount, inService, connected));
            } else {
                this.trunkStatusList[index].update(trunkName, portCount, inService, connected);
            }
        }
    }

    processApplicationStatus(data: any[]) {
        if (data.length > 0) {
            const applicationNames = data[0].data
                .map((e: any) => e.Application)
                .filter((e: any, i: number, a: any) => i === a.indexOf(e));

            for (const applicationName of applicationNames) {
                let inbound = 0;
                let outbound = 0;

                const currentApplicationList = data[0].data.filter((e: any) => e.Application === applicationName);

                for (const item of currentApplicationList) {
                    if (item.CallType.toLowerCase() === 'inbound') {
                        inbound++;
                    }
                    if (item.CallType.toLowerCase() === 'outbound') {
                        outbound++;
                    }
                }

                const index = this.applicationStatusList.findIndex(e => e.applicationName === applicationName);
                if (index === -1) {
                    this.applicationStatusList.push(new AEPApplicationStatus(applicationName, inbound, outbound));
                } else {
                    this.applicationStatusList[index].update(applicationName, inbound, outbound);
                }
            }
        }
    }

    processAEPServiceStatusOperational(data: any[]) {
        for (let i = 0; i < data.length; i++) {
            const row = data[i].data;
            if (row !== null) {
                for (const rowData of row) {
                    const data: ServiceStatus = new ServiceStatus(
                        rowData.Process,
                        rowData.RunningComponents,
                        rowData.ExpectedComponents,
                        rowData.State,
                        rowData.Type
                    );
                    const index = this.serviceStatusList.findIndex(e => e.serviceName === rowData.Process);
                    if (index === -1) {
                        this.serviceStatusList.push(data);
                    } else {
                        this.serviceStatusList[index].updateFromObject(data);
                    }
                }
            }
        }
        this.cleanServiceStatusRecords();
    }

    processAEPServiceStatus(data: any[]) {
        for (let i = 0; i < data.length; i++) {
            const row = data[i].data;
            if (row !== null) {
                for (const rowData of row) {
                    const data: ServiceStatus = new ServiceStatus(
                        rowData.ServiceName,
                        rowData.RunningComponents,
                        rowData.ExpectedComponents,
                        rowData.Status,
                        rowData.Type
                    );
                    const index = this.serviceStatusList.findIndex(e => e.serviceName === rowData.ServiceName);
                    if (index === -1) {
                        this.serviceStatusList.push(data);
                    } else {
                        this.serviceStatusList[index].updateFromObject(data);
                    }
                }
            }
        }
        this.cleanServiceStatusRecords();
    }

    cleanServiceStatusRecords() {
        const ccxml = new RegExp('^CCXML[0-9]');
        const vxmlmgr = new RegExp('^VXMLMGR[0-9]');

        this.serviceStatusList = this.serviceStatusList.filter(
            item => !ccxml.test(item.serviceName) && !vxmlmgr.test(item.serviceName)
        );
    }
}
