import * as EntityTimerActions from '@actions/entity-timer/entity-timer.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { TimeoutService } from '@services/index';

@Injectable()
export class EntityTimerEffects {
    constructor(private actions$: Actions, private timeoutService: TimeoutService) {}

    getEntityTimer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EntityTimerActions.GetEntityTimer),
            mergeMap(action =>
                this.timeoutService.observeTimerNew(action.equipmentId, action.commandTypeId).pipe(
                    catchError(err => {
                        return of(EntityTimerActions.GetEntityTimerFailure({ error: err }));
                    }),
                    mergeMap((data: any) => {
                        return [
                            EntityTimerActions.GetEntityTimerSuccess({
                                data: { data, uniqueId: action.uniqueId.toLowerCase() }
                            })
                        ];
                    })
                )
            )
        )
    );
}
