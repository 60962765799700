<div class="flex-column gap-l">
    <dashlet-line-graph
        #chart
        [data]="lineData"
        [options]="chartOptions"
        [aspect]="this.dashlet.getSize().id === 0 ? 1.65 : 3.8"
        [convertToUserTimezone]=false
        height="205px"
        width="100%"
    ></dashlet-line-graph>
    <div class="grid-4-columns gap-m">
        <div class="metric dashlet-link-underline" [ngStyle]="{ 'border-left-color': dashletService.getSeverityColor(0) }">
            <div class="mat-caption">Good</div>

            <div class="mat-h2">{{ dashlet.mosGood >= 0 ? dashlet.mosGood : '---'   }}</div>
        </div>
        <div class="metric dashlet-link-underline" [ngStyle]="{ 'border-left-color': dashletService.getSeverityColor(1) }">
            <div class="mat-caption">Satisfactory</div>
            <div class="mat-h2">{{ dashlet.mosSatisfactory>= 0 ? dashlet.mosSatisfactory : '---'   }}</div>
        </div>
        <div class="metric dashlet-link-underline" [ngStyle]="{ 'border-left-color': dashletService.getSeverityColor(2) }">
            <div class="mat-caption">Poor</div>
            <div class="mat-h2">{{ dashlet.mosPoor>= 0 ? dashlet.mosPoor : '---'   }}</div>
        </div>

        <div class="metric dashlet-link-underline" [ngStyle]="{ 'border-left-color': dashletService.getSeverityColor(-1) }">
            <div class="mat-caption">Unknown</div>
            <div class="mat-h2">{{ dashlet.mosUnknown>= 0 ? dashlet.mosUnknown : '---'   }}</div>
        </div>
    </div>
</div>
