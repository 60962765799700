import { Subject, takeUntil } from 'rxjs';
import { CPUUsageData, Customer, Dashlet, Equipment, Location, MemoryUsageData } from '@models/index';
import { DashboardService } from '@services/index';
import {
    CiscoEquipment,
    CiscoServerUptime,
    CiscoFileSystem,
    CiscoDialPeer,
    CiscoCallLegs,
    CiscoDSP,
    CiscoMTP,
    CiscoTranscoding,
    CiscoEnvVoltage,
    CiscoEnvSupply,
    CiscoEnvTemp,
    CiscoEnvFan,
    CiscoInterfaceStatus,
    CiscoInterfaceStatusExpansion
} from '@models/dataObjects/Cisco';

export class DashletSystemHealthCiscoCUBE extends Dashlet {
    // Data commands
    public readonly commandTypeIdOSUptime = '31CCB71F-4A29-49AE-A911-0327BE01F3FE';
    public readonly commandTypeIdProcessorCpu = '09CDEEBF-24DC-4FD4-854B-D547B4FC7520';
    public readonly commandTypeIdPlatformCpu = 'C85691E1-AFC6-4848-9698-F003046A988D';
    public readonly commandTypeIdProcessorMemory = '1D99DF00-3D5F-43A2-9875-16B6834BB6FE';
    public readonly commandTypeIdPlatformMemory = 'C85691E1-AFC6-4848-9698-F003046A988D';
    public readonly commandTypeIdFilesystem = 'BAA75EDB-E7A5-4874-896C-7C6D7AC4E04B';
    public readonly commandTypeIdConnectivity = '7BF15776-F312-40C7-92A1-F95DDEAD52EA';

    public readonly commandTypeIdLoggedInUsers = '851528A7-8791-42DC-B4F2-90681382B9E5';
    public readonly commandTypeIdServicesUpDown = '583C5D42-D54B-4FAA-968D-83AE87BF473B';
    public readonly commandTypeIdDailPeer = '4C217660-64A3-4D2F-B389-4BCE7C122FD4';
    public readonly commandTypeIdEnvironment = 'D60CDD0D-E723-49DA-8387-3C0271472F05';
    public readonly commandTypeIdCallLegs = 'B395362A-E972-46A6-A513-EC35D9AE329D';
    public readonly commandTypeIdDSPTranscoder = 'C1E8EC03-DE00-44C6-AAB4-466010C85027';
    public readonly commandTypeIdDSPMTP = 'A86A0285-E868-42E4-BE1C-881A21CEE9DD';

    public readonly commandTypeIdEnvVoltage = 'A022F4A3-D95C-417F-984B-A40D99FD199E';
    public readonly commandTypeIdEnvTemp = '59DD75B4-A093-4CF7-853C-038C5B4F538D';
    public readonly commandTypeIdEnvFan = '91D48E9E-7A09-4206-9896-C83B00ED24A6';
    public readonly commandTypeIdEnvSupply = '7FF8838B-3E4E-4A98-BD7F-B9D1F329BEB4';

    public readonly commandTypeIdSipResp1xx = 'EB61AFED-5824-4A1E-9A58-71BD79609718';
    public readonly commandTypeIdSipResp2xx = 'DCCE3010-2B23-4130-BB62-38245C10249A';
    public readonly commandTypeIdSipResp3xx = 'FA9FA956-CEE1-4781-A720-EE71897CDB55';
    public readonly commandTypeIdSipResp4xx = 'E5569711-B28B-4387-A91B-7A013AFDFAED';
    public readonly commandTypeIdSipResp5xx = '9C8FCF10-9D48-463A-A9AA-96A35878F97C';
    public readonly commandTypeIdSipResp6xx = 'A6D3CA92-1DB6-4936-8D90-EF4E4616A133';

    // Dashlet Configuration variables
    customer: Customer;
    location: Location;
    equipment: CiscoEquipment;
    equipmentConnectivity: Equipment;
    readonly interval = 30;

    // Dashlet display variable
    showFileSystem = false;
    showSystemChart = false;
    showNetworkGraph = false;
    showNetworkInterface = false;
    showEnvironment = false;
    showSipCalls = false;
    showDialPeer = false;
    showSipResponse = false;
    showCallLegs = false;
    showDSP = false;

    // Dashlet data containers
    upTime: CiscoServerUptime = { UpTime: '-' };
    platformCPU: CPUUsageData = new CPUUsageData(this.commandTypeIdPlatformCpu);
    processorCPU: CPUUsageData = new CPUUsageData(this.commandTypeIdProcessorCpu);
    platformMemory: MemoryUsageData = new MemoryUsageData(this.commandTypeIdPlatformMemory);
    processorMemory: MemoryUsageData = new MemoryUsageData(this.commandTypeIdProcessorMemory);
    fileSystem: CiscoFileSystem[] = [];
    interfaceStatus: CiscoInterfaceStatus[] = [];
    dialPeerSummary: CiscoDialPeer[] = [];
    callLegsInfo: CiscoCallLegs = {
        h323: '-',
        sip: '-',
        mgcp: '-',
        sccp: '-',
        multicast: '-',
        cacontrol: '-',
        telephony: '-'
    };
    dspInfo: CiscoDSP = {
        UsedTrans: '0',
        TotalTrans: '0',
        UsedMtp: '0',
        TotalMtp: '0'
    };
    mtpDrillDownContent: CiscoMTP[] = [];
    transcodingDrillDownContent: CiscoTranscoding[] = [];
    envVoltage: CiscoEnvVoltage[] = [];
    envSupply: CiscoEnvSupply[] = [];
    envTemp: CiscoEnvTemp[] = [];
    envFan: CiscoEnvFan[] = [];
    sipResp1xx = 0;
    sipResp2xx = 0;
    sipResp3xx = 0;
    sipResp4xx = 0;
    sipResp5xx = 0;
    sipResp6xx = 0;
    sipRespTotal = 0;
    loggedInInfo = [];

    private destroy$ = new Subject<void>();
    private chartDataUpdated: Subject<null> = new Subject();
    get onChartDataUpdated() {
        return this.chartDataUpdated;
    }

    constructor(private dashboardService: DashboardService) {
        super();

        //sizing
        this.sizes = [
            {
                id: 0,
                label: 'Standard',
                cols: 7,
                rows: 4
            }
        ];

        this.applySize(0);

        //init data
        this.resetData();

        //produce data every interval
        this.dashboardService
            .getUpdateInterval(this.interval)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.platformCPU.refreshCPUUsageData();
                this.processorCPU.refreshCPUUsageData();
                this.platformMemory.refreshMemoryUsageData();
                this.processorMemory.refreshMemoryUsageData();
                this.chartDataUpdated.next(null);
            });
    }

    public applySettings(v: { [key: string]: any }): void {
        super.applySettings(v);

        //read settings object
        this.configured = v.customer && v.location && v.equipment;
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        if (v.location) {
            this.location = new Location(v.location.value, v.location.label);
        } else {
            this.location = new Location('', '');
        }
        if (v.equipment) {
            this.equipment = {
                equipmentName: v.equipment.label,
                equipmentId: v.equipment.value,
                equipmentHost: v.equipment.host
            };
            this.equipmentConnectivity = new Equipment(this.equipment.equipmentId, this.equipment.equipmentName);
        } else {
            this.equipmentConnectivity = new Equipment('', '');
            this.equipment = {
                equipmentName: '',
                equipmentId: '',
                equipmentHost: ''
            };
        }
        this.generatedNameTag = this.configured ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;
        this.showFileSystem = v.showFileSystem || false;
        this.showNetworkGraph = v.showNetworkGraph || false;
        this.showNetworkInterface = v.showNetworkInterface || false;
        this.showEnvironment = v.showEnvironment || false;
        this.showDialPeer = v.showDialPeerSummary || false;
        this.showSipResponse = v.showSipResponse || false;
        this.showCallLegs = v.showCallLegs || false;
        this.showDSP = v.showDSP || false;

        //apply size
        let h = 0;
        if (this.showFileSystem) h += 2;
        if (this.showNetworkGraph) h += 2;
        if (this.showNetworkInterface) h += 1.2;
        if (this.showEnvironment) h += 1.1;
        if (this.showDialPeer) h += 1.25;
        if (this.showDSP) h += 1.25;
        if (this.showSipResponse) h += 1.25;
        if (this.showCallLegs) h += 2;

        //update size
        this.applySizeExpansion(0, h);
    }

    public applySize(id: number): void {
        super.applySize(id);
    }

    public processUpTimeData(data: any): void {
        let row;
        try {
            row = data[0].data;
        } catch (error) {
            row = data.Document[0].data; // http request
        }

        row.forEach(item => {
            if (item.UPTime !== null) {
                let weeks = 0;
                let days = 0;
                let hours = 0;
                let mins = 0;
                let index = 0; // if parameter is missing (i.e no days) index moves search for next name
                item.UPTime = item.UPTime.replace('hours', 'hrs').replace('minutes', 'mins');

                if (item.UPTime.includes('week')) {
                    weeks = Number(item.UPTime.split(',')[0].split(' ')[0]);

                    if (item.UPTime.includes('day')) {
                        days = Number(item.UPTime.split(',')[1].split(' ')[1]);
                    } else {
                        index++;
                    }

                    if (item.UPTime.includes('hrs')) {
                        hours = Number(item.UPTime.split(',')[2 - index].split(' ')[1]);
                    } else {
                        index++;
                    }

                    if (item.UPTime.includes('mins')) {
                        mins = Number(item.UPTime.split(',')[3 - index].split(' ')[1]);
                    } else {
                        index++;
                    }

                    // convert weeks given into days
                    days = weeks * 7 + days;

                    item.UPTime = '';
                    if (days > 0) item.UPTime += days + ' days, ';
                    if (hours > 0) item.UPTime += hours + ' hrs, ';
                    if (mins > 0) item.UPTime += mins + ' mins';
                }
            }

            this.upTime = {
                UpTime: item.UPTime
            };
        });
    }

    public processFileSystem(data: any): void {
        let row;

        try {
            row = data[0].data;
        } catch (error) {
            row = data.Document[0].data; // http request
        }
        this.fileSystem = [];
        row.forEach(element => {
            this.fileSystem.push({
                Available: element.Available,
                FileSystem: element.FileSystem,
                MountedOn: element.MountedOn,
                UsePercent: element.UsePercent,
                Used: element.Used
            });
        });
    }

    public processServices(data: any): void {
        let row;
        try {
            row = data[0].data;
        } catch (error) {
            row = data.Document[0].data; // http request
        }

        this.interfaceStatus = [];
        row.forEach(element => {
            // 10949 - Eyad said discard these
            var filter: string = element.Description;
            filter = filter.toLowerCase();
            if (!filter.includes('voice') && !filter.includes('null') && !filter.includes('voip')) {
                this.interfaceStatus.push({
                    AdminStatus: element.AdminStatus,
                    Description: element.Description,
                    InDiscards: element.InDiscards !== null ? element.InDiscards : '0',
                    InErrors: element.InErrors !== null ? element.InErrors : '0',
                    InOctets: element.InOctets !== null ? element.InOctets : '0',
                    InUcastPkts: element.InUcastPkts !== null ? element.InUcastPkts : '0',
                    InUnknownProtos: element.InUnknownProtos !== null ? element.InUnknownProtos : '0',
                    Index: element.Index,
                    LastChange: element.LastChange,
                    MTU: element.MTU !== null ? element.MTU : '0',
                    OperationalStatus: element.OperationalStatus,
                    OutDiscards: element.OutDiscards !== null ? element.OutDiscards : '0',
                    OutErrors: element.OutErrors !== null ? element.OutErrors : '0',
                    OutOctets: element.OutOctets !== null ? element.OutOctets : '0',
                    OutUcastPkts: element.OutUcastPkts !== null ? element.OutUcastPkts : '0',
                    PhysicalAddress: element.PhysicalAddress,
                    Speed: element.Speed,
                    Type: element.Type
                });
            }
        });
    }

    public processEnvironmentTemp(data: any[]): void {
        const row = data[0].data;
        this.envTemp = [];
        if (row !== null) {
            row.forEach(item => {
                this.envTemp.push({
                    StatusDescr: item.ciscoEnvMonTemperatureStatusDescr,
                    StatusValue: item.ciscoEnvMonTemperatureStatusValue,
                    Threshold: item.ciscoEnvMonTemperatureThreshold,
                    LastShutdown: item.ciscoEnvMonTemperatureLastShutdown,
                    State: item.ciscoEnvMonTemperatureState
                });
            });
        }
    }

    public processEnvironmentSupply(data: any[]): void {
        const row = data[0].data;
        this.envSupply = [];
        if (row !== null) {
            row.forEach(item => {
                this.envSupply.push({
                    StatusDescr: item.ciscoEnvMonSupplyStatusDescr,
                    State: item.ciscoEnvMonSupplyState,
                    Source: item.ciscoEnvMonSupplySource
                });
            });
        }
    }

    public processEnvironmentFan(data: any[]): void {
        const row = data[0].data;
        this.envFan = [];
        if (row !== null) {
            row.forEach(item => {
                this.envFan.push({
                    State: item.ciscoEnvMonFanState,
                    StatusDescr: item.ciscoEnvMonFanStatusDescr
                });
            });
        }
    }

    public processEnvironmentVoltage(data: any[]): void {
        const row = data[0].data;
        this.envVoltage = [];
        if (row !== null) {
            row.forEach(item => {
                this.envVoltage.push({
                    StatusDescr: item.ciscoEnvMonVoltageStatusDescr,
                    StatusValue: item.ciscoEnvMonVoltageStatusValue,
                    ThresholdLow: item.ciscoEnvMonVoltageThresholdLow,
                    ThresholdHigh: item.ciscoEnvMonVoltageThresholdHigh,
                    LastShutdown: item.ciscoEnvMonVoltageLastShutdown,
                    State: item.ciscoEnvMonVoltageState
                });
            });
        }
    }

    public processDialPeer(data: any[]): void {
        const row = data[0].data;
        if (row !== null) {
            this.dialPeerSummary = [];

            row.forEach(item => {
                const returnObject: CiscoDialPeer = {
                    DialPeerHunt: item.DialPeerHunt,
                    Tag: item.Tag,
                    AdministrativeState: item.AdministrativeState,
                    OperationalState: item.OperationalState,
                    DestinationPattern: item.DestinationPattern,
                    SessionServerGroupTarget: item.SessionServerGroupTarget,
                    KeepAlive: item.KeepAlive,
                    Expansion: [
                        {
                            Type: item.Type,
                            Prefix: item.Prefix,
                            VRF: item.VRF,
                            Preference: item.Preference,
                            ModemPassThrough: item.ModemPassThrough,
                            OutStat: item.OutStat,
                            Port: item.Port
                        }
                    ]
                };
                this.dialPeerSummary.push(returnObject);
            });
        }
    }

    public getRowInformationNetworkInterface(data: CiscoInterfaceStatus): CiscoInterfaceStatusExpansion[] {
        let obj: CiscoInterfaceStatusExpansion[] = [];
        obj.push({
            InOctets: data.InOctets,
            InUnknownProtos: data.InUnknownProtos,
            MTU: data.MTU,
            OutDiscards: data.OutDiscards,
            OutErrors: data.OutErrors,
            OutOctets: data.OutOctets,
            PhysicalAddress: data.PhysicalAddress
        });
        return obj;
    }

    public processCallLegs(data: any[]): void {
        let rows = data[0].data;
        if (rows !== null) {
            this.callLegsInfo = {
                h323: rows[0].cvCallVolConnActiveConnection,
                sip: rows[1].cvCallVolConnActiveConnection,
                mgcp: rows[2].cvCallVolConnActiveConnection,
                sccp: rows[3].cvCallVolConnActiveConnection,
                multicast: rows[4].cvCallVolConnActiveConnection,
                cacontrol: rows[5].cvCallVolConnActiveConnection,
                telephony: rows[6].cvCallVolConnActiveConnection
            };
        }
    }

    public processLoggedInData(data: any[]): void {
        let rows = data[0].data;
        if (rows !== null) {
            this.loggedInInfo = [];
            rows.forEach(item => {
                this.loggedInInfo.push({
                    userLineActive: item.tsLineActive
                });
            });
        }
    }

    public processSipData(data: any[], sipRange: number): void {
        let rows = data[0].data;
        let count = 0;

        if (rows !== null) {
            // looks at all keys from response and sums values
            Object.keys(rows[0]).forEach(key => {
                count += parseInt(rows[0][key]);
            });
        }

        switch (sipRange) {
            case 1:
                this.sipResp1xx = count;
                this.updateSipTotal();
                break;
            case 2:
                this.sipResp2xx = count;
                this.updateSipTotal();
                break;
            case 3:
                this.sipResp3xx = count;
                this.updateSipTotal();
                break;
            case 4:
                this.sipResp4xx = count;
                this.updateSipTotal();
                break;
            case 5:
                this.sipResp5xx = count;
                this.updateSipTotal();
                break;
            case 6:
                this.sipResp6xx = count;
                this.updateSipTotal();
                break;
            default:
                break;
        }
    }

    private getDSPOperType(type: string): string {
        switch (type) {
            case '1':
                return 'none';
            case '2':
                return 'done';
            case '3':
                return 'active';
            case '4':
                return 'down';
            case '5':
                return 'not done';
            case '6':
                return 'invalid';
            default:
                return 'none';
        }
    }

    private getDSPApplicationType(type: string): string {
        switch (type) {
            case '1':
                return 'none';
            case '2':
                return 'sccp';
            case '3':
                return 'sbc';
            case '4':
                return 'cube';
            case '5':
                return 'other';
            default:
                return 'none';
        }
    }

    private getDSPMtpService(state: string): string {
        switch (state) {
            case '1':
                return 'non secure';
            case '2':
                return 'secure';
            default:
                return 'non secure';
        }
    }

    public processMTPData(data: any[]): void {
        let rows = data[0].data;

        let mtpUsed = 0;
        let mtpTotal = 0;

        var hwUsed = 0;
        var swUsed = 0;

        this.mtpDrillDownContent = [];

        if (rows !== null) {
            rows.forEach(record => {
                hwUsed =
                    parseInt(record.cdspMtpProfileMaxConfHardSess) - parseInt(record.cdspMtpProfileMaxAvailHardSess);
                swUsed =
                    parseInt(record.cdspMtpProfileMaxConfSoftSess) - parseInt(record.cdspMtpProfileMaxAvailSoftSess);

                mtpUsed += hwUsed + swUsed;
                mtpTotal +=
                    parseInt(record.cdspMtpProfileMaxConfHardSess) + parseInt(record.cdspMtpProfileMaxConfSoftSess);

                this.mtpDrillDownContent.push({
                    Id: record.cdspMtpResourceId,
                    Description: record.cdspMtpDescription,
                    Admin: this.getDSPAdmin(record.cdspMtpAdminState),
                    Oper: this.getDSPOperType(record.cdspMtpApplicationStatus),
                    Application: this.getDSPApplicationType(record.cdspMtpApplication),
                    Service: this.getDSPMtpService(record.cdspMtpService),
                    HWUsed: hwUsed.toString(),
                    HWTotal: record.cdspMtpProfileMaxConfHardSess,
                    SWUsed: swUsed.toString(),
                    SWTotal: record.cdspMtpProfileMaxConfSoftSess
                });
            });
        }
        this.dspInfo.UsedMtp = mtpUsed.toString();
        this.dspInfo.TotalMtp = mtpTotal.toString();
    }

    private getDSPAdmin(state: string): string {
        switch (state) {
            case '1':
                return 'down';
            case '2':
                return 'up';
            default:
                return 'down';
        }
    }

    public processTransCodingData(data: any[]): void {
        let rows = data[0].data;

        let transUsed = 0;
        let transTotal = 0;

        this.transcodingDrillDownContent = [];

        if (rows !== null) {
            rows.forEach(record => {
                transUsed +=
                    parseInt(record.cdspTranscodeProfileMaxConfSess) -
                    parseInt(record.cdspTranscodeProfileMaxAvailSess);
                transTotal += parseInt(record.cdspTranscodeProfileMaxConfSess);

                this.transcodingDrillDownContent.push({
                    Id: record.cdspTranscodeResourceId,
                    Description: record.cdspTranscodeDescription,
                    Admin: this.getDSPAdmin(record.cdspTranscodeAdminState),
                    Oper: this.getDSPOperType(record.cdspTranscodeApplicationStatus),
                    Application: this.getDSPApplicationType(record.cdspTranscodeApplication),
                    SessionsUsed: transUsed.toString(),
                    SessionsTotal: record.cdspTranscodeProfileMaxConfSess,
                    Expansion: this.getRowInformationNetworkInterface(record)
                });
            });
        }
        this.dspInfo.UsedTrans = transUsed.toString();
        this.dspInfo.TotalTrans = transTotal.toString();
    }

    public resetData(): void {
        this.upTime = { UpTime: '-' };
        this.fileSystem = [];
        this.interfaceStatus = [];
        this.dialPeerSummary = [];
        this.platformCPU = new CPUUsageData(this.commandTypeIdPlatformCpu);
        this.processorCPU = new CPUUsageData(this.commandTypeIdProcessorCpu);
        this.platformMemory = new MemoryUsageData(this.commandTypeIdPlatformMemory);
        this.processorMemory = new MemoryUsageData(this.commandTypeIdProcessorMemory);
        this.chartDataUpdated.next(null);
    }

    public updateSipTotal(): void {
        this.sipRespTotal =
            this.sipResp1xx + this.sipResp2xx + this.sipResp3xx + this.sipResp4xx + this.sipResp5xx + this.sipResp6xx;
    }

    public dispose(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    //#endregion
}
