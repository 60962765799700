/* eslint-disable no-undef */
import { Subject, Subscription, Observable, interval } from 'rxjs';
import { Equipment } from './Equipment';
import { Regex, Sort, Point } from '@models/index';
import { environment } from '@environments/environment';

export class NetworkConnectivityData {
    readonly pingUnit = 'ms';
    dataExpired: boolean;
    completeLoss: boolean;
    private _eventName!: string;
    private _equipment!: Equipment;
    private _avgPingData: Point[] = [];
    private _packetLossData: Point[] = [];
    private _maxPingData: Point[] = [];
    private _chartDataUpdated: Subject<null> = new Subject(); // for dashlets with multiple network connectivity graphs
    private _intervalSubscription!: Subscription;

    /**
     *
     * @param equipment
     * @param maxPing
     * @param avgPing
     * @param lossUtil
     */
    constructor(equipment: Equipment | any) {
        this.equipment = equipment;
        this.resetChartData();
        this.dataExpired = true;
        this.completeLoss = false;
    }

    /**
     *
     */
    private resetChartData() {
        this._avgPingData = [];
        this._packetLossData = [];
        this._maxPingData = [];
    }

    public getCurrentMaxPing() {
        if (this._maxPingData.length > 0 && !this.dataExpired) {
            try {
                if (this._maxPingData[this._maxPingData.length - 1].y.toString() === '0') {
                    return '<1';
                }
            } catch (error) {
                return '---';
            }
            return this._maxPingData[this._maxPingData.length - 1].y.toString();
        } else {
            return '---';
        }
    }

    public getCurrentAvgPing() {
        if (this._avgPingData.length > 0 && !this.dataExpired) {
            try {
                if (this._avgPingData[this._avgPingData.length - 1].y.toString() === '0') {
                    return '<1';
                }
            } catch (error) {
                return '---';
            }
            return this._avgPingData[this._avgPingData.length - 1].y.toString();
        } else {
            return '---';
        }
    }

    public getCurrentPacketLossPercentage() {
        if (this._packetLossData.length > 0 && !this.dataExpired) {
            return this._packetLossData[this._packetLossData.length - 1].y.toString();
        } else {
            return '---';
        }
    }

    /**
     * Getter chartDataUpdated
     * @return {Subject<null> }
     */
    public get chartDataUpdated(): Subject<null> {
        return this._chartDataUpdated;
    }

    /**
     * Getter equipment
     * @return {string}
     */
    public get equipment(): Equipment | any {
        return this._equipment;
    }

    /**
     * Setter equipment
     * @param {string} value
     */
    public set equipment(value: Equipment) {
        this._equipment = value;
    }

    /**
     * Getter eventName
     * @return {string}
     */
    public get eventName(): string {
        return this._eventName;
    }

    /**
     * Getter avgTimeData
     * @return {Point[] }
     */
    public get avgPingData(): Point[] {
        return this._avgPingData;
    }

    /**
     * Getter packetLossData
     * @return {Point[] }
     */
    public get packetLossData(): Point[] {
        return this._packetLossData;
    }

    /**
     * Getter maxPingData
     * @return {Point[] }
     */
    public get maxPingData(): Point[] {
        return this._maxPingData;
    }

    /**
     *
     */
    public getLatestDate(): Date {
        if (this._maxPingData.length > 0) {
            return this.maxPingData[this.maxPingData.length - 1].x;
        } else if (this._packetLossData.length > 0) {
            return this._packetLossData[this.packetLossData.length - 1].x;
        } else if (this._avgPingData.length > 0) {
            return this._avgPingData[this.avgPingData.length - 1].x;
        } else {
            return new Date();
        }
    }

    /**
     * Setter eventName
     * @param {string} value
     */
    public set eventName(value: string) {
        this._eventName = value;
    }

    /**
     *
     * @param value
     */
    private addAvgPingData(value: string, date: Date) {
        if (Regex.stringIsNumber(value)) {
            if (this.avgPingData.length > 21) {
                this.avgPingData.shift();
            }
            this.avgPingData.push({
                x: date,
                y: +value
            });
            Sort.sortDates(this.avgPingData);
        }
    }

    /**
     *
     * @param value
     */
    private addPacketLossData(value: string, date: Date) {
        if (Regex.stringIsNumber(value)) {
            if (this.packetLossData.length > 21) {
                this.packetLossData.shift();
            }
            this.packetLossData.push({
                x: date,
                y: +value
            });
            Sort.sortDates(this.packetLossData);
        }
    }

    /**
     *
     * @param value
     */
    private addMaxPingData(value: string, date: Date) {
        if (Regex.stringIsNumber(value)) {
            if (this.maxPingData.length > 21) {
                this.maxPingData.shift();
            }
            this.maxPingData.push({
                x: date,
                y: +value
            });
            Sort.sortDates(this.maxPingData);
        }
    }

    /**
     *
     * @param avgPing
     * @param packetLoss
     * @param maxPing
     */
    public updateNetworkChartData(avgPing: string, packetLoss: string | null, maxPing: string, date: Date): void {
        this.addAvgPingData(avgPing, date);
        this.addPacketLossData(packetLoss!, date);
        this.addMaxPingData(maxPing, date);
        try {
            if (this._packetLossData && this._packetLossData.length > 0) {
                if (this._packetLossData[this._packetLossData.length - 1].y === 100) {
                    this.completeLoss = true;
                } else {
                    this.completeLoss = false;
                }
            }
        } catch (error) {
            if (!environment.production) console.error(error);
        }
        this._chartDataUpdated.next(null);
    }

    public refreshNetworkChartData() {
        this.updateNetworkChartData(
            this.getCurrentAvgPing(),
            this.getCurrentPacketLossPercentage(),
            this.getCurrentMaxPing(),
            new Date()
        );
    }

    public subscribeToIntervalUpdate(intervalNum: number) {
        this._intervalSubscription = interval(intervalNum).subscribe(() => {
            this.refreshNetworkChartData();
        });
    }

    public unsubscribeToIntervalUpdate() {
        this._intervalSubscription.unsubscribe();
    }
}
