<mat-form-field *ngIf="!data!.getLoading(); else loading" appearance="outline">
    <mat-label>{{ data!.label }}</mat-label>
    <span matPrefix>&nbsp;</span>
    <mat-select  #select [typeaheadDebounceInterval]="800" [ngModel]="data!.value" (ngModelChange)="onModelChange($event)" (selectionChange)="selectedValue()" [disabled]="data!.disabled">
        <mat-form-field appearance="outline" class="search-input">
            <mat-icon matPrefix>search</mat-icon>
            <input matInput placeholder="Search" (input)="onKey($event)" translate="placeholder" />
        </mat-form-field>
        <ng-container *ngIf="data!.items && data!.items.length > 0; else noOptions">
            <ng-container *ngIf="dataArray.length === 0; else noDataOptions">
                <mat-option *ngFor="let item of data!.items" [value]="item.value">
                    {{ item.label }}
                </mat-option>
            </ng-container>
            <ng-template #noDataOptions>
                <mat-option *ngFor="let item of dataArray" [value]="item.value">
                    {{ item.label }}
                </mat-option>
            </ng-template>
        </ng-container>
        <ng-template #noOptions>
            <mat-option disabled>None</mat-option>
        </ng-template>
    </mat-select>
</mat-form-field>

<ng-template #loading>
    <div class="flex align-center gap-m dropdown-loading">
        <mat-spinner [diameter]="28"></mat-spinner>
        <span class="mat-body-2">{{ 'Loading ' + data!.label }}</span>
    </div>
</ng-template>
