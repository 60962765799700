import { Regex, Sort, Point } from '@models/index';

interface MOSSummaryDataObject {
	mosData: number[];
	totalCalls: number;
	traffic: number;
}

export enum MosQuality {
	Poor = "POOR",
	Satisfactory = "SATISFACTORY",
	Good = "GOOD"
}

export class MOSSummaryData {
	private _mosData: Point[][]; // 6 different bands of Mos values e.g. 'Mos 1.0 - 2.6'
	private _totalCalls: Point[];
	private _traffic: Point[];

	constructor() {
		this._mosData = [[], [], [], [], [], []];
		this._totalCalls = [];
		this._traffic = [];
	}

	public updateMOSData(mosData: number[], equipment: number, totalCalls: number, traffic: number, date: Date) {
		// Updating MOS Data
		for (let i = 0; i < this.mosData.length; i++) {
			if (this.mosData[i].length >= 24) {
				this.mosData[i].shift();
			}

			if (this.mosData[i].findIndex(e => e.x.getTime() == date.getTime()) == -1) {
				this.mosData[i].push({
					x: date,
					y: mosData[i]
				});
			}
			Sort.sortDates(this.mosData[i]);
		}

		// Updating total calls data
		if (this.totalCalls.length >= 24) {
			this.totalCalls.shift();
		}
		if (this.totalCalls.findIndex(e => e.x.getTime() == date.getTime()) == -1) {
			this.totalCalls.push({
				x: date,
				y: totalCalls
			});
		}

		Sort.sortDates(this.totalCalls);

		// Updating traffic data
		if (this.traffic.length >= 24) {
			this.traffic.shift();
		}
		if (this.traffic.findIndex(e => e.x.getTime() == date.getTime()) == -1) {
			this.traffic.push({
				x: date,
				y: traffic
			});
		}
		Sort.sortDates(this.traffic);
	}

	/**
	 * Getter mosData
	 * @return {Point[][]}
	 */
	public get mosData(): Point[][] {
		return this._mosData;
	}

	/**
	 * Getter totalCalls
	 * @return {Point[]}
	 */
	public get totalCalls(): Point[] {
		return this._totalCalls;
	}

	/**
	 * Getter totalCalls
	 * @return {Point[]}
	 */
	public get traffic(): Point[] {
		return this._traffic;
	}

	public getMOSDataByHours(hours: number) {
		const mosData = new Array(6).fill(0);
		let traffic = 0;
		let totalCalls = 0;

		// sum last 'x' hours for each mos band
		for (let i = 0; i < this.mosData.length; i++) {
			const startIndex = this.mosData[i].length >= hours ? this.mosData[i].length - hours : 0;
			for (let j = startIndex; j < this.mosData[i].length; j++) {
				mosData[i] += this.mosData[i][j].y;
			}
		}

		// sum last 'x' hours for equipment, traffic, and total calls
		const startIndex = this.traffic.length >= hours ? this.traffic.length - hours : 0;
		for (let i = startIndex; i < this.traffic.length; i++) {
			traffic += this.traffic[i].y;
			totalCalls += this.totalCalls[i].y;
		}

		return {
			mosData: mosData,
			totalCalls: totalCalls,
			traffic: traffic
		};
	}

	public getGoodArrayByHours(hours: number) {
		const mosGoodByHours: Point[] = [];

		if (this.mosData[4].length == this.mosData[5].length) {
			const endIndex = hours > this.mosData[4].length ? this.mosData[4].length : hours;
			// need to combine this.mosData[4] and this.mosData[5];
			for (let i = 0; i < endIndex; i++) {
				if (this.mosData[4][i].x.getTime() == this.mosData[5][i].x.getTime()) {
					mosGoodByHours.push({
						x: this.mosData[4][i].x,
						y: this.mosData[4][i].y + this.mosData[5][i].y
					});
				}
			}
		}

		return mosGoodByHours;
	}

	public getSatisfactoryArrayByHours(hours: number) {
		const mosSatisfactoryByHours: Point[] = [];

		const endIndex = hours > this.mosData[3].length ? this.mosData[3].length : hours;
		// need to combine this.mosData[4] and this.mosData[5];
		for (let i = 0; i < endIndex; i++) {
			mosSatisfactoryByHours.push({
				x: this.mosData[3][i].x,
				y: this.mosData[3][i].y
			});
		}

		return mosSatisfactoryByHours;
	}

	public getPoorArrayByHours(hours: number) {
		const mosPoorByHours: Point[] = [];

		if (this.mosData[0].length == this.mosData[1].length && this.mosData[0].length == this.mosData[2].length) {
			const endIndex = hours > this.mosData[0].length ? this.mosData[0].length : hours;
			// need to combine this.mosData[0] and this.mosData[1] and this.mosData[2];
			for (let i = 0; i < endIndex; i++) {
				if (
					this.mosData[0][i].x.getTime() == this.mosData[1][i].x.getTime() &&
					this.mosData[0][i].x.getTime() == this.mosData[2][i].x.getTime()
				) {
					mosPoorByHours.push({
						x: this.mosData[4][i].x,
						y: this.mosData[0][i].y + this.mosData[1][i].y + this.mosData[2][i].y
					});
				}
			}
		}
		return mosPoorByHours;
	}
}
