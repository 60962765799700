import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

export const GetNotifyProcessAcd = createAction(
  '[ProcessAcd] Get ProcessAcd',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetNotifyProcessAcdSuccess = createAction(
  '[ProcessAcd] Get ProcessAcd Success',
  props<{ data: RealTimeNotify }>()
);

export const GetNotifyProcessAcdFailure = createAction(
  '[ProcessAcd] Get ProcessAcd Failure',
  props<{ error: any }>()
);

export const GetProcessAcd = createAction(
  '[ProcessAcd] Get ProcessAcd',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetProcessAcdSuccess = createAction(
  '[ProcessAcd] Get ProcessAcd Success',
  props<{ data: any}>()
);

export const GetProcessAcdFailure = createAction(
  '[ProcessAcd] Get ProcessAcd Failure',
  props<{ error: any }>()
);

