<mat-form-field *ngIf="!data!.getLoading(); else loading" appearance="outline">
    <mat-label>{{ data!.label }}</mat-label>

    <ng-container>
        <mat-select #select multiple [typeaheadDebounceInterval]="800" (selectionChange)="selectionChanged($event)" [ngModel]="data.values" [disabled]="data!.disabled"
        (openedChange)="openedChange($event)"><ng-container *ngIf="data!.items && data!.items.length > 0; else noOptions">
                <div class="select-all" *ngIf="!data.removeSelectAll">
                    <mat-checkbox color="primary" [(ngModel)]="allSelected" [ngModelOptions]="{ standalone: true }" (ngModelChange)="toggleAllSelection()"
                        ><span class="mat-body-2">Select All</span></mat-checkbox
                    >
                </div>
                <mat-option *ngFor="let item of data!.items" [value]="item.value" (click)="togglePerOne()" [disabled]="data.limitSelection && data.values.length >= data.limitSelection && !data.values.includes(item.value)">
                    {{ item.label }}
                </mat-option>
            </ng-container></mat-select
        ></ng-container
    ><ng-template #noOptions>
        <mat-option disabled>None</mat-option>
    </ng-template>
</mat-form-field>

<ng-template #loading>
    <div class="flex align-center gap-m dropdown-loading">
        <mat-spinner [diameter]="28"></mat-spinner>
        <span class="mat-body-2">{{ 'Loading ' + data!.label }}</span>
    </div>
</ng-template>
