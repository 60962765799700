import {
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingList,
    SelectItem
} from '@models/index';

import { AccountService, DashletSettingsService } from '@services/index';

export class DashletSettingsFormAlarmOverview extends DashletSettingsForm {
    private customer!: DashletSettingDropdown;

    private isCust!: boolean;

    private customerId!: string;

    constructor(
        private readonly settingsService: DashletSettingsService,

        private readonly accountService: AccountService
    ) {
        super();

        this.init();

        this.loadCustomers();
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);
    }

    private init(): void {
        const group = new DashletSettingsGroup();

        const s: any = (group.settings = []);

        const daily = new SelectItem();

        daily.label = '24 hours';

        daily.value = '24';

        // period.items.push(daily);

        const twelveHourly = new SelectItem();

        twelveHourly.label = '12 hours';

        twelveHourly.value = '12';

        // period.items.push(twelveHourly);

        const sixHourly = new SelectItem();

        sixHourly.label = '6 hours';

        sixHourly.value = '6';

        // period.items.push(sixHourly);

        const hourly = new SelectItem();

        hourly.label = '1 hour';

        hourly.value = '1';

        // period.items.push(hourly);

        // s.push(period);

        const user = this.accountService.getUserDetails();

        this.isCust = user.EntityGroup === 2;

        this.customerId = user.EntityId;

        const customer = (this.customer = new DashletSettingDropdown());

        customer.label = 'Customer';

        customer.stateKey = 'customer';

        customer.disabled = true;

        s.push(customer);

        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);

        this.settingsService.getUserAssociatedCustomers().subscribe(
            (customers: any) => {
                this.customer.items = customers;

                this.customer.loadingComplete();
            },

            () => {
                this.customer.loadingComplete();

                this.customer.disabled = true;

                // show error notification here
            }
        );
    }

    private enableAndLoading(widget: DashletSettingDropdown | DashletSettingList<string>) {
        widget.disabled = false;

        widget.loadingBegin();
    }
}
