import { Component, Input, OnDestroy, OnInit, HostListener } from '@angular/core';
import {
    ColumnTypes,
    CustomerLogo,
    DashletGenesysTrunksSummary,
    FeatureSubscriptions,
    MenuItem,
    GenesysTrunkBase,
    GenesysEdge,
    GenesysTrunkStatusData,
    GenesysTrunkStatusDocument,
    GenesysCallsPerTrunkData,
    GenesysCallsPerTrunkDocument,
    GenesysEdgeStatusData,
    GenesysEdgeStatusDocument,
    GenesysEdgeMetricsData,
    GenesysEdgeMetricsDocument
} from '@models/index';
import * as Actions from '@actions/index';
import {
    AppState,
    selectDataFromCommonEntity,
    selectEntity,
    selectEntityTimer,
    selectReportSubscription
} from '@reducers/index';
import { catchError, combineLatest, map, Observable, Subscription, Subject, of } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AccountService, NotificationService } from '@services/index';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-dashlet-genesys-trunks-summary',
    templateUrl: './dashlet-genesys-trunks-summary.component.html',
    styleUrls: ['./dashlet-genesys-trunks-summary.component.scss']
})
export class DashletGenesysTrunksSummaryComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletGenesysTrunksSummary;

    private readonly COMMAND_TYPE_ID_GENYSYS_TRUNK_STATUS = '83DBAFD5-117A-44D2-A15A-7D2636EC3608';
    private readonly COMMAND_TYPE_ID_GENESYS_CALLS_PER_TRUNK = 'BD9B057D-2A4E-4D85-AB68-17A1E1942C9E';
    private readonly COMMAND_TYPE_ID_GENYSYS_EDGE_STATUS = '3966F4D2-5A08-435E-9065-942735988AA9';
    private readonly COMMAND_TYPE_ID_GENESYS_EDGE_METRICS = '873BBF55-1159-4302-A510-47A18F2A86DB';

    private reportSubscriptions: FeatureSubscriptions = {
        capacityEnabled: false,
        availabilityEnabled: false,
        releaseEnabled: false,
        apiEnabled: false,
        serviceDeskEnabled: false,
        configurationEnabled: false,
        continuityEnabled: false,
        changeEnabled: false,
        securityEnabled: false
    };

    private subscription: Subscription = new Subscription();

    private genesysTrunkStatusData$: Observable<GenesysTrunkStatusData[]>;

    private genesysCallsPerTrunkData$: Observable<GenesysCallsPerTrunkData[]>;

    private genesysEdgeStatusData$: Observable<GenesysEdgeStatusData[]>;

    public genesysTrunkGroups$: Observable<GenesysTrunkBase[]>;

    private genesysEdgeMetricsData$: Observable<GenesysEdgeMetricsData[]>;

    private readonly destroy$ = new Subject<void>();

    public loading = true;

    public columns: ColumnTypes[] = [
        {
            columnDef: 'severity',
            header: '',
            type: 'severity',
            cell: (element: GenesysTrunkBase) => element.severity
        },
        {
            columnDef: 'expand',
            header: ''
        },
        {
            columnDef: 'trunkBaseName',
            header: ' Trunk Base Name',
            filterType: 'text',
            cell: (element: GenesysTrunkBase) => element.trunkBaseName,
            dataTooltip: (element: GenesysTrunkBase) => element.trunkBaseName,
            isLinkHeader: true,
            linkHeaderToolTip: 'Open Trunk Group Call report',
            linkCallbackNavigation: () => {
                window
                    .open(
                        `${environment.reportingUrl}viewer?entityId=${this.dashlet.customer.customerId}&reportId=e0cefb00-4ec6-48e0-842c-cfd0dcb10cf0&reportName=Trunk%20Group%20Call`,
                        '_blank'
                    )
                    .focus();
            }
        },
        {
            columnDef: 'total',
            header: 'In | Out | Total',
            cell: (element: GenesysTrunkBase) => `${element.in} | ${element.out} | ${element.total}`,
            type: 'numeric',
            width: '20%'
        }
    ];

    public expansionColumns: ColumnTypes[] = [
        {
            columnDef: 'severity',
            header: '',
            type: 'severity',
            cell: (element: GenesysEdge) => element.severity
        },
        {
            columnDef: 'trunkNameConcat',
            header: 'Trunk Name',
            filterType: 'text',
            cell: (element: GenesysEdge) => element.trunkNameConcat,
            dataTooltip: (element: GenesysEdge) => element.trunkName,
            width: '30%'
        },
        {
            columnDef: 'edgeSeverity',
            header: '',
            type: 'severity',
            cell: (element: GenesysEdge) => element.edgeSeverity
        },
        {
            columnDef: 'edgeName',
            header: 'Genesys Cloud Edge',
            filterType: 'text',
            cell: (element: GenesysEdge) => element.edgeName,
            dataTooltip: (element: GenesysEdge) => element.edgeName,
            width: '30%'
        },

        {
            columnDef: 'cpumemorydisk',
            header: 'CPU | Memory | Disk',
            type: 'numericCenter',
            subHeadingInFilter: '%',
            subHeadingClass: 'justify-center',
            cell: (element: GenesysEdge) => `${element.cpu || '-'} | ${element.memory || '-'} | ${element.disk || '-'}`,
            width: '20%',
            showSubheadWarning: false,
            warningToolTip:
                'No information has been received as the Amazon Event Bridge is not configured. Click here to learn how',
            warningClick: () => this.handleTooltipClick()
        },

        {
            columnDef: 'total',
            header: 'In | Out | Total',
            cell: (element: GenesysTrunkBase) => `${element.in} | ${element.out} | ${element.total}`,
            type: 'numeric',
            width: '15%'
        }
    ];

    public menuItems: MenuItem[] = [
        {
            label: 'Open Trunk Group Call Report',
            command: (element: GenesysTrunkBase) =>
                window.open(
                    `${environment.reportingUrl}viewer?entityId=${this.dashlet.customer.customerId}&reportId=e0cefb00-4ec6-48e0-842c-cfd0dcb10cf0&reportName=Trunk%20Group%20Call`
                ),
            disabled: !(this.reportSubscriptions.capacityEnabled || this.accountService.isVirsaeOwner()),
            title: ''
        }
    ];

    private static tooltipClickHandled: boolean = false;
    private timeoutId: ReturnType<typeof setTimeout>;

    constructor(
        private store$: Store<AppState>,
        private notificationService: NotificationService,
        private accountService: AccountService
    ) {}

    public ngOnInit(): void {
        this.subscription.add(
            this.dashlet.settingsChanges.subscribe(() => {
                this.requestData();
            })
        );
        if (this.dashlet.configured) {
            this.requestData();
        }

        this.store$
            .pipe(
                select(selectEntityTimer(this.dashlet.equipmentId + this.COMMAND_TYPE_ID_GENYSYS_TRUNK_STATUS)),
                skip(1),
                takeUntil(this.destroy$)
            )
            .subscribe((dataTimeoutExpired: boolean) => {
                this.dashlet.isDataOld = dataTimeoutExpired;
            });
    }
    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
        this.unSubFromRealtime();
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    private processGenesysTrunks(
        genesysTrunkStatusData: GenesysTrunkStatusData[],
        genesysCallsPerTrunkData: GenesysCallsPerTrunkData[],
        genesysEdgeStatusData: GenesysEdgeStatusData[],
        genesysEdgeMetricsData: GenesysEdgeMetricsData[]
    ): GenesysTrunkBase[] {
        const trunkBases: GenesysTrunkBase[] = [];
        this.expansionColumns.forEach(column => {
            if ('showSubheadWarning' in column) {
                column.showSubheadWarning = !(genesysEdgeMetricsData && genesysEdgeMetricsData.length > 0);
            }
        });
        genesysTrunkStatusData.forEach((trunk: GenesysTrunkStatusData) => {
            const callsForTrunk = genesysCallsPerTrunkData.find(element => element.TrunkID === trunk.TrunkID);
            const edgeStatus = genesysEdgeStatusData.find(element => element.EdgeID === trunk.EdgeID);
            const inBoundCalls = parseInt(callsForTrunk?.InboundCallCount) || 0;
            const outBoundCalls = parseInt(callsForTrunk?.OutboundCallCount) || 0;
            const totalCalls = inBoundCalls + outBoundCalls;
            const performanceMetrics = genesysEdgeMetricsData?.find(element => element.EdgeId === trunk.EdgeID);
            const edge: GenesysEdge = {
                trunkId: trunk.TrunkID,
                severity: this.getTrunkSeverity(trunk),
                trunkName: trunk.Name,
                trunkNameConcat: trunk.Name.replace(trunk.TrunkBaseName, ''),
                edgeSeverity: this.getEdgeSeverity(edgeStatus),
                edgeName: trunk.EdgeName,
                edgeId: trunk.EdgeID,
                in: inBoundCalls,
                out: outBoundCalls,
                total: totalCalls,
                disk: performanceMetrics?.DiskUsagePercentageMaximum,
                cpu: performanceMetrics?.ProcessorUsageTimePctMaximum,
                memory: performanceMetrics?.MemoryPhysicalUsagePercentageMaximum
            };
            const index = trunkBases.map(trunkBase => trunkBase.trunkBaseId).indexOf(trunk.TrunkBaseID);
            if (index === -1) {
                trunkBases.push({
                    trunkBaseId: trunk.TrunkBaseID,
                    severity: this.getTrunkBaseSeverity([edge]),
                    trunkBaseName: trunk.TrunkBaseName,
                    in: edge.in,
                    out: edge.out,
                    total: edge.total,
                    edges: [edge],
                    expanded: this.dashlet.expanded
                });
            } else {
                trunkBases[index].edges.push(edge);
                trunkBases[index].in += edge.in;
                trunkBases[index].out += edge.out;
                trunkBases[index].total += edge.total;
                trunkBases[index].severity = this.getTrunkBaseSeverity(trunkBases[index].edges);
            }
        });
        return trunkBases;
    }

    private getEdgeSeverity(edgeStatus: GenesysEdgeStatusData): number {
        if (edgeStatus) {
            if (
                edgeStatus.OnlineStatus === 'OFFLINE' ||
                edgeStatus.State === 'deleted' ||
                edgeStatus.State === 'inactive'
            ) {
                return 2;
            } else {
                return 0;
            }
        }
        return -1;
    }
    private getTrunkSeverity(trunkStatus: GenesysTrunkStatusData): number {
        if (trunkStatus.InService === 'False' || trunkStatus.State === 'inactive' || trunkStatus.State === 'deleted') {
            return 2;
        } else {
            return 0;
        }
    }

    private getTrunkBaseSeverity(trunks: GenesysEdge[]): number {
        const trunkSeverities: number[] = trunks.map(trunk => trunk.severity);
        const hasTrunksUp: boolean = trunkSeverities.includes(0);
        const hasTrunksDown: boolean = trunkSeverities.includes(2);
        if (hasTrunksUp && !hasTrunksDown) {
            return 0;
        } else if (hasTrunksUp && hasTrunksDown) {
            return 1;
        } else if (!hasTrunksUp && hasTrunksDown) {
            return 2;
        }
        return -1;
    }

    @HostListener('document:click', ['$event'])
    onClick(event: MouseEvent) {
        const clickedElement = event.target as HTMLElement;
        if (clickedElement.classList.contains('mat-tooltip-component') || clickedElement.closest('.mdc-tooltip')) {
            const tooltipContent = clickedElement.closest('.mdc-tooltip__surface');
            if (
                tooltipContent &&
                tooltipContent.textContent?.includes(
                    'No information has been received as the Amazon Event Bridge is not configured. Click here to learn how'
                )
            ) {
                if (!DashletGenesysTrunksSummaryComponent.tooltipClickHandled) {
                    DashletGenesysTrunksSummaryComponent.tooltipClickHandled = true;
                    this.handleTooltipClick();
                    this.timeoutId = setTimeout(() => {
                        DashletGenesysTrunksSummaryComponent.tooltipClickHandled = false;
                    }, 0);
                }
            }
        }
    }

    handleTooltipClick() {
        const url =
            'https://documentation.virsae.com/display/VSMDOC/Genesys+Cloud+CX#GenesysCloudCX-AmazonEventBridgeConfiguration';
        window.open(url, '_blank');
    }

    private requestData(): void {
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );
        this.store$.dispatch(Actions.GetReportSubscriptions({ customerId: this.dashlet.customer.customerId }));
        this.subscription.add(
            this.store$
                .pipe(select(selectReportSubscription(this.dashlet.customer.customerId)))
                .subscribe(subscriptions => {
                    if (subscriptions?.reportSubscriptions) {
                        this.reportSubscriptions = subscriptions.reportSubscriptions;
                        this.menuItems = [];
                        this.menuItems.push({
                            label: 'Open Trunk Group Call Report',
                            command: (element: GenesysTrunkBase) =>
                                window.open(
                                    `${environment.reportingUrl}viewer?entityId=${this.dashlet.customer.customerId}&reportId=e0cefb00-4ec6-48e0-842c-cfd0dcb10cf0&reportName=Trunk%20Group%20Call`
                                ),
                            disabled: !(
                                this.reportSubscriptions.capacityEnabled || this.accountService.isVirsaeOwner()
                            ),
                            title: ''
                        });
                    }
                })
        );
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipmentId,
                command: this.COMMAND_TYPE_ID_GENYSYS_TRUNK_STATUS
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipmentId,
                commandTypeId: this.COMMAND_TYPE_ID_GENYSYS_TRUNK_STATUS
            })
        );

        this.store$.dispatch(
            Actions.GetEntityTimer({
                equipmentId: this.dashlet.equipmentId,
                commandTypeId: this.COMMAND_TYPE_ID_GENYSYS_TRUNK_STATUS,
                uniqueId: this.dashlet.equipmentId + this.COMMAND_TYPE_ID_GENYSYS_TRUNK_STATUS
            })
        );

        this.genesysTrunkStatusData$ = this.store$.pipe(
            select(selectDataFromCommonEntity(this.dashlet.equipmentId + this.COMMAND_TYPE_ID_GENYSYS_TRUNK_STATUS)),
            map((data: GenesysTrunkStatusDocument[]) => {
                if (data) {
                    return data[0]?.data.filter(x => this.dashlet.trunkBaseIds.includes(x.TrunkBaseID?.toLowerCase()));
                } else {
                    return undefined;
                }
            }),
            catchError(err => {
                this.notificationService.notify('Failed To Load Genesys Trunk Status', 'error', err);
                return [];
            })
        );
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipmentId,
                command: this.COMMAND_TYPE_ID_GENESYS_CALLS_PER_TRUNK
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipmentId,
                commandTypeId: this.COMMAND_TYPE_ID_GENESYS_CALLS_PER_TRUNK
            })
        );

        this.genesysCallsPerTrunkData$ = this.store$.pipe(
            select(selectDataFromCommonEntity(this.dashlet.equipmentId + this.COMMAND_TYPE_ID_GENESYS_CALLS_PER_TRUNK)),
            map((data: GenesysCallsPerTrunkDocument[]) => {
                if (data) {
                    return data[0]?.data;
                } else {
                    return undefined;
                }
            }),
            catchError(err => {
                this.notificationService.notify('Failed To Load Genesys Calls per Trunk', 'error', err);
                return [];
            })
        );

        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipmentId,
                command: this.COMMAND_TYPE_ID_GENYSYS_EDGE_STATUS
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipmentId,
                commandTypeId: this.COMMAND_TYPE_ID_GENYSYS_EDGE_STATUS
            })
        );

        this.genesysEdgeStatusData$ = this.store$.pipe(
            select(selectDataFromCommonEntity(this.dashlet.equipmentId + this.COMMAND_TYPE_ID_GENYSYS_EDGE_STATUS)),
            map((data: GenesysEdgeStatusDocument[]) => {
                if (data) {
                    return data[0]?.data;
                } else {
                    return undefined;
                }
            }),
            catchError(err => {
                this.notificationService.notify('Failed To Load Genesys Edge Status', 'error', err);
                return [];
            })
        );

        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipmentId,
                command: this.COMMAND_TYPE_ID_GENESYS_EDGE_METRICS
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipmentId,
                commandTypeId: this.COMMAND_TYPE_ID_GENESYS_EDGE_METRICS
            })
        );

        this.genesysEdgeMetricsData$ = this.store$.pipe(
            select(selectDataFromCommonEntity(this.dashlet.equipmentId + this.COMMAND_TYPE_ID_GENESYS_EDGE_METRICS)),
            map((data: GenesysEdgeMetricsDocument[]) => data?.[0]?.data),
            catchError(err => {
                this.notificationService.notify('Failed To Load Genesys Edge Metrics', 'error', err);
                return of(undefined);
            })
        );

        this.genesysTrunkGroups$ = combineLatest([
            this.genesysTrunkStatusData$,
            this.genesysCallsPerTrunkData$,
            this.genesysEdgeStatusData$,
            this.genesysEdgeMetricsData$
        ]).pipe(
            takeUntil(this.destroy$),
            map(([genesysTrunkStatusData, genesysCallsPerTrunkData, genesysEdgeStatusData, genesysEdgeMetricsData]) => {
                const trunkGroups: GenesysTrunkBase[] = [];
                if (genesysTrunkStatusData && genesysCallsPerTrunkData && genesysEdgeStatusData) {
                    this.loading = false;
                    return this.processGenesysTrunks(
                        genesysTrunkStatusData,
                        genesysCallsPerTrunkData,
                        genesysEdgeStatusData,
                        genesysEdgeMetricsData
                    );
                }
                return trunkGroups;
            })
        );
    }

    private unSubFromRealtime(): void {
        this.store$.dispatch(
            Actions.UnsubscribeFromRealTimeService({
                equipmentId: this.dashlet.equipmentId,
                command: this.COMMAND_TYPE_ID_GENESYS_EDGE_METRICS
            })
        );
        this.store$.dispatch(
            Actions.UnsubscribeFromRealTimeService({
                equipmentId: this.dashlet.equipmentId,
                command: this.COMMAND_TYPE_ID_GENYSYS_EDGE_STATUS
            })
        );
        this.store$.dispatch(
            Actions.UnsubscribeFromRealTimeService({
                equipmentId: this.dashlet.equipmentId,
                command: this.COMMAND_TYPE_ID_GENESYS_CALLS_PER_TRUNK
            })
        );
        this.store$.dispatch(
            Actions.UnsubscribeFromRealTimeService({
                equipmentId: this.dashlet.equipmentId,
                command: this.COMMAND_TYPE_ID_GENYSYS_TRUNK_STATUS
            })
        );
    }
}
