export class SIPSessionStatus {
	private _audio!: number;
	private _multimedia!: number;
	private _fax!: number;
	private _text!: number;
	private _other!: number;

	constructor() {
		this.audio = 0;
		this.multimedia = 0;
		this.fax = 0;
		this.text = 0;
		this.other = 0;
	}

	public update(audio: number, multimedia: number, fax: number, text: number, other: number) {
		this.audio = Number(audio);
		this.multimedia = Number(multimedia);
		this.fax = Number(fax);
		this.text = Number(text);
		this.other = Number(other);
	}

	public getTotal() {
		return this.audio + this.multimedia + this.fax + this.text + this.other;
	}
	/**
	 * Getter audio
	 * @return {number}
	 */
	public get audio(): number {
		return this._audio;
	}

	/**
	 * Getter multimedia
	 * @return {number}
	 */
	public get multimedia(): number {
		return this._multimedia;
	}

	/**
	 * Getter fax
	 * @return {number}
	 */
	public get fax(): number {
		return this._fax;
	}

	/**
	 * Getter text
	 * @return {number}
	 */
	public get text(): number {
		return this._text;
	}

	/**
	 * Getter other
	 * @return {number}
	 */
	public get other(): number {
		return this._other;
	}

	/**
	 * Setter audio
	 * @param {number} value
	 */
	public set audio(value: number) {
		if (value.toString() == '' || !value) {
			this._audio = 0;
		}
		this._audio = Number(value);
	}

	/**
	 * Setter multimedia
	 * @param {number} value
	 */
	public set multimedia(value: number) {
		if (value.toString() == '' || !value) {
			this._multimedia = 0;
		}
		this._multimedia = Number(value);
	}

	/**
	 * Setter fax
	 * @param {number} value
	 */
	public set fax(value: number) {
		if (value.toString() == '' || !value) {
			this._fax = 0;
		}
		this._fax = Number(value);
	}

	/**
	 * Setter text
	 * @param {number} value
	 */
	public set text(value: number) {
		if (value.toString() == '' || !value) {
			this._text = 0;
		}
		this._text = Number(value);
	}

	/**
	 * Setter other
	 * @param {number} value
	 */
	public set other(value: number) {
		if (value.toString() == '' || !value) {
			this._other = 0;
		}
		this._other = Number(value);
	}
}
