import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DashletMeta } from '@models/index';
@Component({
    selector: 'app-create-dashlet',
    templateUrl: './create-dashlet.component.html',
    styleUrls: ['./create-dashlet.component.scss']
})
export class CreateDashletComponent {
    public groups: {
        dashlets: DashletMeta[];
        name: string;
    }[];

    @Output() createDashlet = new EventEmitter();
    @Input() set dashletsMeta(value: DashletMeta[]) {
        value.sort((n1, n2) => {
            if (n1.groupName > n2.groupName) {
                return 1;
            }

            if (n1.groupName < n2.groupName) {
                return -1;
            }

            return 0;
        });
        const groups = this.groupByGroupName(value);
        this.groups = Object.keys(groups).map(groupName => {
            return {
                name: groupName,
                dashlets: groups[groupName]
            };
        });

        for (let group of this.groups) {
            group.dashlets.sort((a, b) => {
                if (a.type === 'System Health Summary' && b.type === 'ACM System Health Summary') return -1;
                if (a.type === 'ACM System Health Summary' && b.type === 'System Health Summary') return 1;
                if (a.type === 'System Health Summary' || a.type === 'ACM System Health Summary') return -1;
                if (b.type === 'System Health Summary' || b.type === 'ACM System Health Summary') return 1;
                return a.type < b.type ? -1 : a.type > b.type ? 1 : 0;
            });
        }
    }

    constructor() {}

    addDashlet(dashlet) {
        this.createDashlet.emit(dashlet);
    }

    private groupByGroupName(dashlets: DashletMeta[]) {
        const groups: { [name: string]: DashletMeta[] } = {};
        for (let dashlet of dashlets) {
            if (dashlet.enabled) {
                let groupName = dashlet.groupName;

                if (!groups[groupName]) groups[groupName] = [];
                groups[groupName].push(dashlet);
            }
        }
        return groups;
    }
}
