import * as ProcessHostNameActions from '@actions/process-host-name/process-host-name.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService } from '@services/index';
import { RealTimeNotify } from '@models/index';



@Injectable()
export class ProcessHostNameEffects {

  constructor(private actions$: Actions, private realtimeGatewayService: RealtimeGatewayService) {}

  getNotifyProcessAcd$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessHostNameActions.GetNotifyProcessHostNames),
    switchMap((action) => this.realtimeGatewayService.observe(action.equipmentId, action.commandTypeId).pipe(
      catchError((err) => of(
        ProcessHostNameActions.GetNotifyProcessHostNamesFailure({ error: err })
        )
      ),
      mergeMap((data: RealTimeNotify) => [
        ProcessHostNameActions.GetNotifyProcessHostNamesSuccess({ data }),
        ProcessHostNameActions.GetProcessHostNames({ ...data })
      ],
      ),
    ),
    )
  ));

  getProcessAcd$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessHostNameActions.GetProcessHostNames),
    mergeMap((action) => this.realtimeGatewayService.getDocument(action.equipmentId, action.commandTypeId).pipe(
      map((data) => ProcessHostNameActions.GetProcessHostNamesSuccess({ data:{ data, equipmentId: action.equipmentId} }),
      ),
      catchError((err) => of(ProcessHostNameActions.GetProcessHostNamesFailure({ error: err })))
    ))
  ));

}
