export interface IpnrMapData {
    IPNR: string;
    IpAddress: string;
    ExtensionNumber: string;
    PhoneDescription: string;
}

export class IpnrMap {
    private ipnrMap: Map<string, IpnrMapData>;
    private ipnrMapIpAddress: Map<string, IpnrMapData>;

    constructor() {
        this.ipnrMap = new Map();
        this.ipnrMapIpAddress = new Map();
    }

    updateMap(ipnrMapData: IpnrMapData[]) {
        //region phoneNumber will be either 'gwp' or an extension number like 8751:
        ipnrMapData.forEach(data => {
            const key =
                data.ExtensionNumber === 'gwp'
                    ? data.ExtensionNumber + '@' + data.IpAddress
                    : data.ExtensionNumber + data.IpAddress;
            this.ipnrMap.set(key, data);

            if (data.IpAddress && data.IpAddress.length > 0) {
                this.ipnrMapIpAddress.set(data.IpAddress, data);
            }
        });
    }

    // return a Map of required field: ipnr, description, endpoint, ip
    getIpnrMap(rawPhoneName: string | null): Map<any, any> {
        if (rawPhoneName !== null && this.ipnrMap !== null) {
            let ipnr = '';
            let ipAddress = '';
            let description = '';
            const result = new Map();
            let key = '';

            //region phoneNumber will be either 'gwp' or an extension number like 8751:
            let phoneNumber =
                rawPhoneName.indexOf('@') > 0 ? rawPhoneName.substring(0, rawPhoneName.indexOf('@')) : rawPhoneName;

            if (phoneNumber === 'gwp') {
                // look using ip address
                ipAddress = rawPhoneName.substring(4);
                key = phoneNumber + '@' + ipAddress;
                const map = this.getIpnrData(key, ipAddress);
                if (map !== null) {
                    ipnr = map.IPNR;
                    description = map.PhoneDescription;
                } else {
                    description = rawPhoneName;
                }
                result.set('ipnr', ipnr);
                result.set('ip', ipAddress);
                result.set('endPonit', phoneNumber);
                result.set('description', description);
                return result;
            }

            if (
                phoneNumber.toLowerCase().startsWith('sip:') ||
                phoneNumber.toLowerCase().startsWith('sips:') ||
                phoneNumber.toLowerCase().startsWith('exs')
            ) {
                ipAddress = rawPhoneName.substring(rawPhoneName.indexOf('@') + 1);
            } else {
                ipAddress = rawPhoneName.substring(rawPhoneName.indexOf('@') + 1, rawPhoneName.indexOf(':'));
            }
            if (phoneNumber.toLowerCase().startsWith('sip:')) {
                phoneNumber = phoneNumber.substring(4);
            } else if (phoneNumber.toLowerCase().startsWith('sips:')) {
                phoneNumber = phoneNumber.substring(5);
            }

            if (!phoneNumber) {
                phoneNumber = rawPhoneName;
            }

            key = phoneNumber + ipAddress;
            const map = this.getIpnrData(key, ipAddress);
            if (map !== null) {
                ipnr = map.IPNR;
                description = map.PhoneDescription;
            }

            result.set('ipnr', ipnr);
            result.set('ip', ipAddress);
            result.set('endPonit', phoneNumber.replace('ext', '').replace('exs', ''));
            result.set('description', description);

            return result;
        }
        return null as any;
    }

    private getIpnrData(key: string, ipAddress: string): IpnrMapData {
        return this.ipnrMap.get(key) ?? this.ipnrMapIpAddress.get(ipAddress) ?? null;
    }
}
