import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

export const GetNotifyProcessFlowControllerData = createAction(
  '[ProcessFlowControllerData] Get Notify ProcessFlowControllerDatas',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetNotifyProcessFlowControllerDataSuccess = createAction(
  '[ProcessFlowControllerData] Get Notify ProcessFlowControllerDatas Success',
  props<{ data: RealTimeNotify }>()
);

export const GetNotifyProcessFlowControllerDataFailure = createAction(
  '[ProcessFlowControllerData] Get Notify ProcessFlowControllerDatas Failure',
  props<{ error: any }>()
);


export const GetProcessFlowControllerData = createAction(
  '[ProcessFlowControllerData] Get ProcessFlowControllerDatas',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetProcessFlowControllerDataSuccess = createAction(
  '[ProcessFlowControllerData] Get ProcessFlowControllerDatas Success',
  props<{ data: any}>()
);

export const GetProcessFlowControllerDataFailure = createAction(
  '[ProcessFlowControllerData] Get ProcessFlowControllerDatas Failure',
  props<{ error: any }>()
);