import * as Actions from '@actions/index';
import { Component, Input, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { DashletTotalPortsByRegion } from '@models/index';
import { AccountService } from '@services/index';
import { DashletLineGraphComponent } from '../dashlet-line-graph/dashlet-line-graph.component';
import { select, Store } from '@ngrx/store';
import { AppState, selectDataFromCommonEntity } from '@reducers/index';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-dashlet-total-ports-by-region',
    templateUrl: './dashlet-total-ports-by-region.component.html',
    styleUrls: ['./dashlet-total-ports-by-region.component.scss']
})
export class DashletTotalPortsByRegionComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletTotalPortsByRegion;
    private destroy$ = new Subject<void>();
    entityGroup: number;
    customerId: string;

    private pportsChart: DashletLineGraphComponent;
    get portsChart() {
        return this.pportsChart;
    }
    @ViewChild('portsChart') set portsChart(value: DashletLineGraphComponent) {
        this.pportsChart = value;
        this.updateChart();
    }

    private pportsChartWide: DashletLineGraphComponent;
    get portsChartWide() {
        return this.pportsChartWide;
    }
    @ViewChild('portsChartWide') set portsChartWide(value: DashletLineGraphComponent) {
        this.pportsChartWide = value;
        this.updateChart();
    }

    lineData = {};

    private totalPortsEMEA = {
        label: 'Total Ports EMEA',
        backgroundColor: '#36d3bc',
        borderColor: 'white',
        pointBackgroundColor: 'white',
        pointHighlightStroke: 'white',
        pointRadius: 0,
        pointHitRadius: 10,
        fill: true,
        data: []
    };
    private totalPortsNA = {
        label: 'Total Ports NA',
        backgroundColor: '#336699',
        borderColor: 'white',
        pointBackgroundColor: 'white',
        pointHighlightStroke: 'white',
        pointRadius: 0,
        pointHitRadius: 10,
        fill: true,
        data: []
    };
    private totalPortsAPAC = {
        label: 'Total Ports APAC',
        backgroundColor: '#3399ff',
        borderColor: 'white',
        pointBackgroundColor: 'white',
        pointHighlightStroke: 'white',
        pointRadius: 0,
        pointHitRadius: 10,
        fill: true,
        data: []
    };

    portsLineData: any = {};
    portsChartOptions: any = {
        scales: {
            x: {
                type: 'time',
                grid: {
                    display: false
                },
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'd MMM'
                    },
                    stepSize: 3,
                    max: null,
                    min: null
                },
                ticks: {
                    fontSize: 10.2,
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0
                }
            },
            y: {
                stacked: true,
                grid: {
                    display: false
                },
                ticks: {
                    fontSize: 10.2,
                    beginAtZero: true
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'bottom',
                reverse: true
            },
            tooltip: {
                callback: {
                    title: tipItem => {
                        return null;
                    }
                }
            }
        },

        animation: {
            duration: 0
        }
    };
    portsChartOptionsWide: any = {
        scales: {
            x: {
                type: 'time',
                grid: {
                    display: false
                },
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'd MMM'
                    },
                    stepSize: 3,
                    max: null,
                    min: null
                },
                ticks: {
                    fontSize: 10.2,
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0
                }
            },
            y: {
                stacked: true,
                grid: {
                    display: false
                },
                ticks: {
                    fontSize: 10.2,
                    beginAtZero: true
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'bottom',
                reverse: true
            },
            tooltip: {
                callback: {
                    title: tipItem => {
                        return null;
                    }
                }
            }
        },

        animation: {
            duration: 0
        }
    };

    constructor(private accountService: AccountService, private store$: Store<AppState>) {
        this.portsLineData = {
            datasets: [this.totalPortsNA, this.totalPortsAPAC, this.totalPortsEMEA]
        };
    }

    ngOnInit() {
        // network data
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet._equipmentId,
                command: this.dashlet._commandTypeIdPort
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet._equipmentId,
                commandTypeId: this.dashlet._commandTypeIdPort
            })
        );

        this.store$
            .pipe(
                select(selectDataFromCommonEntity(this.dashlet._equipmentId + this.dashlet._commandTypeIdPort)),
                takeUntil(this.destroy$)
            )
            .subscribe(data => {
                if (data) {
                    this.dashlet.processPortData(data);
                }
            });
        this.dashlet.onChartDataUpdated.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.updateChart();
        });
        var userDetail = this.accountService.getUserDetails();
        this.entityGroup = userDetail.EntityGroup;
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        this.dashlet.dispose();
    }

    private updateChart() {
        this.totalPortsEMEA.data = this.dashlet.totalPortsPerCustomerArrayEMEA;
        this.totalPortsNA.data = this.dashlet.totalPortsPerCustomerArrayNA;
        this.totalPortsAPAC.data = this.dashlet.totalPortsPerCustomerArrayAPAC;

        //update axis range
        let max = new Date();
        max.setDate(max.getDate() - 1);

        let min = new Date(max);
        min.setDate(min.getDate() - 15); //then show up to 15 days before max
        this.portsChartOptions.scales.x.min = min;
        this.portsChartOptions.scales.x.max = max;

        min = new Date(max);
        min.setDate(min.getDate() - 35); // show up to 35 days before max
        this.portsChartOptionsWide.scales.x.min = min;
        this.portsChartOptionsWide.scales.x.max = max;
        if (this.portsChart) {
            this.portsChart.updateChart();
        }
        if (this.portsChartWide) {
            this.portsChartWide.updateChart();
        }
    }

    get smallLayout() {
        return this.dashlet.getSize().id === 0;
    }

    get mediumLayout() {
        return this.dashlet.getSize().id === 1;
    }

    get wideLayout() {
        return this.dashlet.getSize().id === 2;
    }
}
