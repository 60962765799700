import { CustomerServicePartners } from '@models/index';

export class ServicePartnerSettingsUpdate {
	PartnerId!: string;
	ServicePartnerId!: string;
	ServicePartnerEnabled!: boolean;
	ServicePartnerName!: string;
	ServicePartnerContactEmail!: string;
	AssociatedOtherServicePartnerIds!: string[];
	CustomersWithServicePartners!: CustomerServicePartners[];
}
