import * as ProcessNicNamesActions from '@actions/process-nic-names/process-nic-names.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService } from '@services/index';
import { RealTimeNotify } from '@models/index';



@Injectable()
export class ProcessNicNamesEffects {

  constructor(private actions$: Actions,  private realtimeGatewayService: RealtimeGatewayService) {}

  getNotifyProcessNicNames$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessNicNamesActions.GetNotifyProcessNicNames),
    switchMap((action) => this.realtimeGatewayService.observe(action.equipmentId, action.commandTypeId).pipe(
      catchError((err) => of(
        ProcessNicNamesActions.GetNotifyProcessNicNamesFailure({ error: err })
        )
      ),
      mergeMap((data: RealTimeNotify) => [
        ProcessNicNamesActions.GetNotifyProcessNicNamesSuccess({ data }),
        ProcessNicNamesActions.GetProcessNicNames({ ...data })
      ],
      ),
    ),
    )
  ));

  getProcessNicNames$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessNicNamesActions.GetProcessNicNames),
    mergeMap((action) => this.realtimeGatewayService.getDocument(action.equipmentId, action.commandTypeId).pipe(
      map((data) => ProcessNicNamesActions.GetProcessNicNamesSuccess({ data:{ data, equipmentId: action.equipmentId} }),
      ),
      catchError((err) => of(ProcessNicNamesActions.GetProcessNicNamesFailure({ error: err })))
    ))
  ));

}
