import * as Actions from '@actions/index';
import { AppState, selectDataFromCommonEntity, selectEntity } from '@reducers/index';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CustomerLogo, DashletTrunkGroupTraffic } from '@models/index';
import { DashletLineGraphComponent } from '../dashlet-line-graph/dashlet-line-graph.component';
import { DashletService, TimeService, DashletDataProcessService } from '@services/index';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-dashlet-trunk-group-traffic',
    templateUrl: './dashlet-trunk-group-traffic.component.html',
    styleUrls: ['./dashlet-trunk-group-traffic.component.scss']
})
export class DashletTrunkGroupTrafficComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletTrunkGroupTraffic;

    private pChart: DashletLineGraphComponent;
    get chart() {
        return this.pChart;
    }
    @ViewChild('chart') set chart(value: DashletLineGraphComponent) {
        this.pChart = value;
        this.updateChart();
    }

    private pUtilizationChart: DashletLineGraphComponent;
    get utilizationChart() {
        return this.pUtilizationChart;
    }
    @ViewChild('utilizationChart') set utilizationChart(value: DashletLineGraphComponent) {
        this.pUtilizationChart = value;
        this.updateChart();
    }

    channelsUsedColor: string = this.dashletService.getChartColors().greyLight;
    private channelsUsedDataset = {
        label: 'In Use',
        borderColor: this.channelsUsedColor,
        backgroundColor: this.channelsUsedColor,
        pointRadius: 0,
        pointHitRadius: 10,
        data: []
    };

    channelsCapacityColor: string = this.dashletService.getChartColors().pink;
    private channelsCapacityDataset = {
        label: 'Capacity',
        borderColor: this.channelsCapacityColor,
        backgroundColor: this.channelsCapacityColor,
        pointRadius: 0,
        pointHitRadius: 10,
        data: []
    };
    lineData: any = {};
    chartOptions: any = {
        scales: {
            x: {
                type: 'time',
                grid: {
                    display: false
                },
                time: {
                    unit: 'minute',
                    displayFormats: {
                        minute: 'HH:mm'
                    },
                    stepSize: 1,
                    max: null,
                    min: null
                },
                ticks: {
                    fontSize: 10.2,
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0,
                    maxTicksLimit: 4
                }
            },
            y: {
                type: 'linear',
                min: 0,
                grid: {
                    display: false
                },
                ticks: {
                    fontSize: 10.2,
                    beginAtZero: true,
                    callback: value => {
                        //show only integer ticks
                        const truncated = parseInt(value);
                        if (truncated !== value) return null;
                        return truncated;
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'bottom'
            },
            tooltip: {
                callback: {
                    title: tipItem => {
                        return null;
                    }
                }
            }
        },
        maintainAspectRatio: false,
        animation: {
            duration: 0
        }
    };

    private channelsUtilizationDataset = {
        label: 'In Use %',
        borderColor: this.channelsUsedColor,
        backgroundColor: this.channelsUsedColor,
        pointRadius: 0,
        pointHitRadius: 10,
        data: []
    };
    utilizationLineData: any = {};
    utilizationChartOptions: any = {
        scales: {
            x: {
                type: 'time',
                grid: {
                    display: false
                },
                time: {
                    unit: 'minute',
                    displayFormats: {
                        minute: 'HH:mm'
                    },
                    stepSize: 1,
                    max: null,
                    min: null
                },
                ticks: {
                    fontSize: 10.2,
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0
                }
            },
            y: {
                type: 'linear',
                min: 0,
                max: 100,
                grid: {
                    display: false
                },
                ticks: {
                    fontSize: 10.2,
                    max: 100,
                    beginAtZero: true
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'bottom'
            },
            tooltip: {
                callback: {
                    title: tipItem => {
                        return null;
                    },
                    label: (tipItem, data) => {
                        let label = data.datasets[tipItem.datasetIndex].label || '';
                        if (label) label += ': ';
                        return label + (+tipItem.yLabel).toFixed(1);
                    }
                }
            }
        },
        maintainAspectRatio: false,
        animation: {
            duration: 0
        }
    };

    private subscription: Subscription = new Subscription();

    constructor(
        private dashletService: DashletService,
        private store$: Store<AppState>,
        private dashletDataService: DashletDataProcessService,
        private timeService: TimeService
    ) {
        this.lineData = {
            datasets: [this.channelsUsedDataset, this.channelsCapacityDataset]
        };
        this.utilizationLineData = {
            datasets: [this.channelsUtilizationDataset]
        };
    }

    ngOnInit() {
        if (this.dashlet.configured) {
            this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
            this.subscription.add(
                this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                    if (logo) {
                        this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                    }
                })
            );
        }

        if (this.dashlet.equipmentId) {
            // sub ipo trunks
            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: this.dashlet.equipmentId,
                    command: this.dashlet.commandTypeIdIpo
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: this.dashlet.equipmentId,
                    commandTypeId: this.dashlet.commandTypeIdIpo
                })
            );

            this.subscription.add(
                this.store$
                    .pipe(select(selectDataFromCommonEntity(this.dashlet.equipmentId + this.dashlet.commandTypeIdIpo)))
                    .subscribe(data => {
                        if (data) this.dashlet.processDataIpo(data);
                    })
            );

            // sub acm trunks

            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: this.dashlet.equipmentId,
                    command: this.dashlet.commandTypeIdAcm
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: this.dashlet.equipmentId,
                    commandTypeId: this.dashlet.commandTypeIdAcm
                })
            );

            this.subscription.add(
                this.store$
                    .pipe(select(selectDataFromCommonEntity(this.dashlet.equipmentId + this.dashlet.commandTypeIdAcm)))
                    .subscribe(data => {
                        if (data) this.dashlet.processDataAcm(data);
                    })
            );
        }

        this.subscription.add(
            this.dashlet.onDataUpdated.subscribe(() => {
                this.updateChart();
            })
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.dashlet.dispose();
    }

    private updateChart() {
        this.channelsUsedDataset.borderColor = 'grey';
        this.channelsUsedDataset.backgroundColor = 'grey';
        this.channelsUtilizationDataset.borderColor = 'grey';
        this.channelsUtilizationDataset.backgroundColor = 'grey';
        //update chart data sets
        this.channelsUsedDataset.data = this.dashletDataService.dedupe(this.dashlet.channelsUsedData).map(point => {
            return { ...point, x: new Date(this.timeService.convertToUserTimeZone(point.x)) };
        });
        this.channelsCapacityDataset.data = this.dashletDataService
            .dedupe(this.dashlet.channelsCapacityData)
            .map(point => {
                return { ...point, x: new Date(this.timeService.convertToUserTimeZone(point.x)) };
            });
        this.channelsUtilizationDataset.data = this.dashletDataService
            .dedupe(this.dashlet.channelsUtilizationData)
            .map(point => {
                return { ...point, x: new Date(this.timeService.convertToUserTimeZone(point.x)) };
            });

        //update color
        for (let data of this.channelsCapacityDataset.data) {
            let index = this.channelsCapacityDataset.data.indexOf(data);
            let currentColor = this.processColor(data.y, this.channelsUsedDataset.data[index]);
            this.channelsUsedDataset.borderColor = currentColor;
            this.channelsUsedDataset.backgroundColor = currentColor;
            this.channelsUtilizationDataset.borderColor = currentColor;
            this.channelsUtilizationDataset.backgroundColor = currentColor;
            this.channelsUsedColor = currentColor;
        }

        //update axis range
        const dateString = new Date();
        const max = new Date(this.timeService.convertToUserTimeZone(dateString));
        max.setSeconds(Math.floor(max.getSeconds() / this.dashlet.interval) * this.dashlet.interval, 0);
        const min = new Date(max);
        min.setMinutes(min.getMinutes() - 4); //then show up to a few mins before max
        this.chartOptions.scales.x.min = min;
        this.chartOptions.scales.x.max = max;
        this.utilizationChartOptions.scales.x.min = min;
        this.utilizationChartOptions.scales.x.max = max;

        if (this.chart) this.chart.updateChart();
        if (this.utilizationChart) this.utilizationChart.updateChart();
    }

    private processColor(size: number, active: number) {
        let color = this.dashletService.getChartColors().greyLight;

        //colorise
        const delta = size - active;
        if (size <= 0) color = this.dashletService.getSeverityColor(-1);
        else if (delta < size * 0.1) color = this.dashletService.getSeverityColor(2);
        else if (delta < size * 0.25) color = this.dashletService.getSeverityColor(1);
        else color = this.dashletService.getSeverityColor(0);

        return color;
    }
}
