export class AlarmResponseLogData {
    isRuleDeleted: boolean;
    isWorkflowDeleted: boolean;
    createUtcDateTime?: string;
    matchState?: string;
    ruleName?: string;
    ruleOrder?: number;
    workflowDefinitionName?: string;
    workflowTraceMessage?: string;
}
