import { createReducer, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import * as Actions from '@actions/index';
import { RealTimeNotify } from '@models/index';

export const realTimeDataFeatureKey = 'realTimeData';

interface ProcessUpTimeNotifyState extends EntityState<RealTimeNotify> {}
interface processUpTimeState extends EntityState<any> {}
interface ProcessUpTimeMsState extends EntityState<any> {}
interface ProcessUpTimeDocState extends EntityState<any> {}

interface ProcessUsageState extends EntityState<any> {}
interface ProcessUsageTimerState extends EntityState<any> {}
interface ProcessHistoricCpuDataState extends EntityState<any> {}

interface MemoryUsageState extends EntityState<any> {}
interface MemoryUsageDataExpiredState extends EntityState<any> {}

interface DiskUsageState extends EntityState<any> {}
interface DiskUsageDataExpiredState extends EntityState<any> {}

interface CpuUsageDataExpiredState extends EntityState<any> {}

interface NetworkConnectivityState extends EntityState<any> {}
interface NetworkConnectivityDataExpiredState extends EntityState<any> {}

interface ProcessAdminBackupDataState extends EntityState<any> {}

interface ProcessMaintenanceBackupDataState extends EntityState<any> {}

interface ProcessAcdState extends EntityState<any> {}

interface ProcessAcdCallsState extends EntityState<any> {}

interface ProcessSupervisorLicenseState extends EntityState<any> {}

interface ProcessWFMStatusRealTimeState extends EntityState<any> {}

interface ProcessWFMStatusHistoricState extends EntityState<any> {}

interface ProcessThresholdDataState extends EntityState<any> {}

interface ProcessNicNamesDataState extends EntityState<any> {}

interface ProcessHostNameDataState extends EntityState<any> {}

interface ProcessTotalMemDataState extends EntityState<any> {}

interface ProcessSqlDataState extends EntityState<any> {}
interface ProcessSqlDataTimerState extends EntityState<any> {}

interface ProcessUpCpuMemDataState extends EntityState<any> {}
interface ProcessUpCpuMemDataTimerState extends EntityState<any> {}

interface ProcessVmwareDataState extends EntityState<any> {}

interface ProcessDiskDataState extends EntityState<any> {}
interface ProcessDiskDataTimerState extends EntityState<any> {}

interface ProcessIISDataState extends EntityState<any> {}

interface ProcessHyperVDataState extends EntityState<any> {}

interface ProcessLyncMedServerDataState extends EntityState<any> {}

interface ProcessLyncFrontEndServerDataState extends EntityState<any> {}

interface ProcessFlowControllerDataState extends EntityState<any> {}

interface ProcessWorkflowDataState extends EntityState<any> {}

interface ProcessWatchedServiceDataState extends EntityState<any> {}

interface ProcessCallRateDataState extends EntityState<any> {}

interface EntityTimerState extends EntityState<any> {}

interface CommonEntityState extends EntityState<any> {}

interface SummaryDocumentState extends EntityState<any> {}

export interface State {
    msg: string;
    processUpTimeNotifyData: ProcessUpTimeNotifyState;
    processUpTimeMs: ProcessUpTimeMsState;
    processUpTimeDocument: ProcessUpTimeDocState;
    processUpTime: processUpTimeState;

    processUsage: ProcessUsageState;
    processUsageTimer: ProcessUsageTimerState;
    historicCpuData: ProcessHistoricCpuDataState;

    memoryUsage: MemoryUsageState;
    memoryUsageDataExpired: MemoryUsageDataExpiredState;

    diskUsage: DiskUsageState;
    diskUsageDataExpired: DiskUsageDataExpiredState;

    networkConnectivity: NetworkConnectivityState;
    networkConnectivityDataExpired: NetworkConnectivityDataExpiredState;

    processAdminBackupData: ProcessAdminBackupDataState;

    processMaintenanceBackupData: ProcessMaintenanceBackupDataState;

    processAcd: ProcessAcdState;

    processAcdCalls: ProcessAcdCallsState;

    cpuUsageDataExpired: CpuUsageDataExpiredState;

    processSupervisorLicense: ProcessSupervisorLicenseState;

    processWFMStatusRealTime: ProcessWFMStatusRealTimeState;

    processWFMStatusHistoric: ProcessWFMStatusHistoricState;

    processThresholdData: ProcessThresholdDataState;

    processNicNames: ProcessNicNamesDataState;

    processHostName: ProcessHostNameDataState;

    processTotalMem: ProcessTotalMemDataState;

    processSqlData: ProcessSqlDataState;
    processSqlDataTimer: ProcessSqlDataTimerState;

    processUpCpuMemData: ProcessUpCpuMemDataState;
    processUpCpuMemDataTimer: ProcessUpCpuMemDataTimerState;

    processVmwareData: ProcessVmwareDataState;

    processDiskData: ProcessDiskDataState;
    processDiskDataTimer: ProcessDiskDataTimerState;

    processIISData: ProcessIISDataState;

    processHyperVData: ProcessHyperVDataState;

    processLyncMedServerData: ProcessLyncMedServerDataState;

    processLyncFrontEndServerData: ProcessLyncFrontEndServerDataState;

    processFlowControllerData: ProcessFlowControllerDataState;

    processWorkflowData: ProcessFlowControllerDataState;

    processWatchedServiceData: ProcessFlowControllerDataState;

    processCallRate: ProcessCallRateDataState;

    entityTimer: EntityTimerState;

    commonEntityData: CommonEntityState;

    summaryDocumentData: SummaryDocumentState;

    storeEquipIdBasedOnType: any[];
}

export function selectEntityId(a: RealTimeNotify): string {
    return a.equipmentId;
}

export function selectEntityTimerId(a: any): string {
    return a.uniqueId;
}

export function selectEntityUniqueId(a: any): string {
    return a.uniqueId;
}

// Process up time
const adapterProcessUpTime = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessUpTimeNotify = createEntityAdapter<RealTimeNotify>({
    selectId: selectEntityId
});

const adapterProcessUpTimeMs = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessUpTimeDocument = createEntityAdapter<any>({
    selectId: selectEntityId
});

// Process Usage
const adapterProcessUsage = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessUsageTimer = createEntityAdapter<any>({
    selectId: selectEntityId
});
const adapterHistoricCpuData = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterCpuUsageDataExpired = createEntityAdapter<any>({
    selectId: selectEntityId
});
const adapterMemoryUsage = createEntityAdapter<any>({
    selectId: selectEntityId
});
const adapterMemoryUsageDataExpired = createEntityAdapter<any>({
    selectId: selectEntityId
});
const adapterDiskUsage = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterDiskUsageDataExpired = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterNetworkConnectivity = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterNetworkConnectivityDataExpired = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessAdminBackupData = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessMaintenanceBackupData = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessAcd = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessAcdCalls = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterSupervisorLicense = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessWFMStatusRealTime = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessWFMStatusHistoric = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessThresholdData = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessNicNamesData = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessHostNamesData = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessTotalMemData = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessSqlData = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessSqlDataTimer = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessUpCpuMemData = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessUpCpuMemDataTimer = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessVmwareData = createEntityAdapter<any>({
    selectId: selectEntityUniqueId
});

const adapterProcessDiskData = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessDiskDataTimer = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessIISData = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessHyperVData = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessLyncMedServerData = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessLyncFrontEndServerData = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessFlowControllerData = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessWorkflowData = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessWatchedServiceData = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterProcessCallRateData = createEntityAdapter<any>({
    selectId: selectEntityId
});

const adapterEntityTimer = createEntityAdapter<any>({
    selectId: selectEntityTimerId
});

const adapterCommonEntity = createEntityAdapter<any>({
    selectId: selectEntityUniqueId
});

const adapterSummaryDocument = createEntityAdapter<any>({
    selectId: selectEntityUniqueId
});

const processUpTimeInitState: ProcessUpTimeNotifyState = adapterProcessUpTime.getInitialState();
const processUpTimeNotifyInitState: ProcessUpTimeNotifyState = adapterProcessUpTimeNotify.getInitialState();
const processUpTimeMsInitState: ProcessUpTimeMsState = adapterProcessUpTimeMs.getInitialState();
const processUpTimeDocumentInitState: ProcessUpTimeDocState = adapterProcessUpTimeDocument.getInitialState();

const processUsageInitState: ProcessUsageState = adapterProcessUsage.getInitialState();
const processUsageTimerInitState: ProcessUsageTimerState = adapterProcessUsage.getInitialState();
const historicCpuDataInitState: ProcessUsageTimerState = adapterProcessUsage.getInitialState();

const memoryUsageInitState: CpuUsageDataExpiredState = adapterMemoryUsage.getInitialState();
const memoryUsageDataExpiredInitState: MemoryUsageDataExpiredState = adapterMemoryUsageDataExpired.getInitialState();

const diskUsageInitState: CpuUsageDataExpiredState = adapterDiskUsage.getInitialState();
const diskUsageDataExpiredInitState: DiskUsageDataExpiredState = adapterDiskUsageDataExpired.getInitialState();

const cpuUsageDataExpiredInitState: CpuUsageDataExpiredState = adapterCpuUsageDataExpired.getInitialState();

const networkConnectivityInitState: NetworkConnectivityState = adapterNetworkConnectivity.getInitialState();
const networkConnectivityDataExpiredInitState: NetworkConnectivityState =
    adapterNetworkConnectivityDataExpired.getInitialState();
const processAdminBackupDataInitState: NetworkConnectivityState = adapterProcessAdminBackupData.getInitialState();
const processMaintenanceBackupDataInitState: NetworkConnectivityState =
    adapterProcessMaintenanceBackupData.getInitialState();
const processAcdInitState: ProcessAcdState = adapterProcessAcd.getInitialState();
const processAcdCallsInitState: ProcessAcdCallsState = adapterProcessAcdCalls.getInitialState();

const processSupervisorLicenseInitState: ProcessSupervisorLicenseState = adapterSupervisorLicense.getInitialState();

const processWFMStatusRealTimeInitState: ProcessWFMStatusRealTimeState =
    adapterProcessWFMStatusRealTime.getInitialState();

const processWFMStatusHistoricInitState: ProcessWFMStatusHistoricState =
    adapterProcessWFMStatusHistoric.getInitialState();

const processThresholdDataInitState: ProcessThresholdDataState = adapterProcessThresholdData.getInitialState();

const processNicNamesDataInitState: ProcessNicNamesDataState = adapterProcessNicNamesData.getInitialState();

const processHostNameDataInitState: ProcessHostNameDataState = adapterProcessHostNamesData.getInitialState();

const processTotalMemDataInitState: ProcessTotalMemDataState = adapterProcessTotalMemData.getInitialState();

const processSqlDataInitState: ProcessSqlDataState = adapterProcessSqlData.getInitialState();

const processSqlDataTimerInitState: ProcessSqlDataTimerState = adapterProcessSqlDataTimer.getInitialState();

const processUpCpuMemDataInitState: ProcessUpCpuMemDataState = adapterProcessUpCpuMemData.getInitialState();

const processVmwareDataInitState: ProcessVmwareDataState = adapterProcessVmwareData.getInitialState();

const processDiskDataInitState: ProcessDiskDataState = adapterProcessDiskData.getInitialState();
const processDiskDataTimerInitState: ProcessDiskDataTimerState = adapterProcessDiskDataTimer.getInitialState();

const processIISDataInitState: ProcessIISDataState = adapterProcessIISData.getInitialState();

const processHyperVDataInitState: ProcessHyperVDataState = adapterProcessHyperVData.getInitialState();

const processLyncMedServerDataInitState: ProcessLyncMedServerDataState =
    adapterProcessLyncMedServerData.getInitialState();

const processLyncFrontEndServerDataInitState: ProcessLyncFrontEndServerDataState =
    adapterProcessLyncFrontEndServerData.getInitialState();

const processFlowControllerDataInitState: ProcessFlowControllerDataState =
    adapterProcessFlowControllerData.getInitialState();

const processWorkflowDataInitState: ProcessWorkflowDataState = adapterProcessWorkflowData.getInitialState();

const processWatchedServiceDataInitState: ProcessWatchedServiceDataState =
    adapterProcessWatchedServiceData.getInitialState();

const ProcessCallRateDataInitState: ProcessCallRateDataState = adapterProcessCallRateData.getInitialState();

const EntityTimerInitState: EntityTimerState = adapterEntityTimer.getInitialState();

const CommonEntityInitState: CommonEntityState = adapterCommonEntity.getInitialState();

const SummaryDocumentInitState: SummaryDocumentState = adapterCommonEntity.getInitialState();

export const initialState: State = {
    msg: 'Multiple entities in the same state',
    processUpTimeNotifyData: processUpTimeNotifyInitState,
    processUpTimeMs: processUpTimeMsInitState,
    processUpTimeDocument: processUpTimeDocumentInitState,
    processUpTime: processUpTimeInitState,

    processUsage: processUsageInitState,
    processUsageTimer: processUsageTimerInitState,
    historicCpuData: historicCpuDataInitState,

    memoryUsage: memoryUsageInitState,
    memoryUsageDataExpired: memoryUsageDataExpiredInitState,

    diskUsage: diskUsageInitState,
    diskUsageDataExpired: diskUsageDataExpiredInitState,

    networkConnectivity: networkConnectivityInitState,
    networkConnectivityDataExpired: networkConnectivityDataExpiredInitState,

    processAdminBackupData: processAdminBackupDataInitState,

    processMaintenanceBackupData: processMaintenanceBackupDataInitState,

    cpuUsageDataExpired: cpuUsageDataExpiredInitState,

    processAcd: processAcdInitState,

    processAcdCalls: processAcdCallsInitState,

    processSupervisorLicense: processSupervisorLicenseInitState,

    processWFMStatusRealTime: processWFMStatusRealTimeInitState,

    processWFMStatusHistoric: processWFMStatusHistoricInitState,

    processThresholdData: processThresholdDataInitState,

    processNicNames: processNicNamesDataInitState,

    processHostName: processHostNameDataInitState,

    processTotalMem: processTotalMemDataInitState,

    processSqlData: processSqlDataInitState,

    processSqlDataTimer: processSqlDataTimerInitState,

    processUpCpuMemData: processUpCpuMemDataInitState,
    processUpCpuMemDataTimer: processUpCpuMemDataInitState,

    processVmwareData: processVmwareDataInitState,

    processDiskData: processDiskDataInitState,
    processDiskDataTimer: processDiskDataTimerInitState,

    processIISData: processIISDataInitState,

    processHyperVData: processHyperVDataInitState,

    processLyncMedServerData: processLyncMedServerDataInitState,

    processLyncFrontEndServerData: processLyncFrontEndServerDataInitState,

    processFlowControllerData: processFlowControllerDataInitState,

    processWorkflowData: processWorkflowDataInitState,

    processWatchedServiceData: processWatchedServiceDataInitState,

    processCallRate: ProcessCallRateDataInitState,

    entityTimer: EntityTimerInitState,

    commonEntityData: CommonEntityInitState,

    summaryDocumentData: SummaryDocumentInitState,

    storeEquipIdBasedOnType: []
};

const storeEquipIdsandCustIds = (newId, previousStateList) => {
    const finalList = Object.assign([], previousStateList);
    const chekExistOrNot = finalList.find(c => c.id === newId.id && c.idType === newId.idType);
    if (!chekExistOrNot) {
        finalList.push(newId);
    }
    return finalList;
};

export const reducer = createReducer(
    initialState,

    on(Actions.GetProcessUpTimeDocumentSuccess, (state, action) => ({
        ...state,
        processUpTime: adapterProcessUpTime.addOne(action.data, state.processUpTime)
    })),
    on(Actions.GetNotifyProcessUpTimeSuccess, (state, action) => ({
        ...state,
        processUpTimeNotifyData: adapterProcessUpTimeNotify.addOne(action.data, state.processUpTimeNotifyData)
    })),
    on(Actions.GetProcessUpTimeTimerSuccess, (state, action) => ({
        ...state,
        processUpTimeMs: adapterProcessUpTimeMs.addOne(action.data, state.processUpTimeMs)
    })),

    // on(Actions.GetProcessUpTimeDocumentSuccess, (state, {data, equipmentId}) => ({
    //   ...state,
    //   processUpTimeDocument: adapterProcessUpTimeMs.addOne({...data, equipmentId}, state.processUpTimeDocument ),
    // })),

    on(Actions.GetProcessUsageSuccess, (state, action) => ({
        ...state,
        processUpTimeMs: adapterProcessUsage.addOne(action.data, state.processUsage)
    })),
    on(Actions.GetProcessUsageTimerSuccess, (state, action) => ({
        ...state,
        processUsageTimer: adapterProcessUsageTimer.addOne(action.data, state.processUsageTimer)
    })),

    on(Actions.GetHistoricCpuDataSuccess, (state, action) => ({
        ...state,
        historicCpuData: adapterCpuUsageDataExpired.addOne(action.data, state.historicCpuData)
    })),

    on(Actions.GetMemoryUsageSuccess, (state, action) => ({
        ...state,
        memoryUsage: adapterCpuUsageDataExpired.addOne(action.data, state.memoryUsage)
    })),

    on(Actions.GetMemoryUsageTimerSuccess, (state, action) => ({
        ...state,
        memoryUsageDataExpired: adapterMemoryUsageDataExpired.addOne(action.data, state.memoryUsageDataExpired)
    })),

    on(Actions.GetDiskUsageSuccess, (state, action) => ({
        ...state,
        diskUsage: adapterDiskUsage.addOne(action.data, state.diskUsage)
    })),

    on(Actions.GetDiskUsageTimerSuccess, (state, action) => ({
        ...state,
        diskUsageDataExpired: adapterDiskUsageDataExpired.addOne(action.data, state.diskUsageDataExpired)
    })),

    on(Actions.GetNetworkConnectivitySuccess, (state, action) => ({
        ...state,
        networkConnectivity: adapterNetworkConnectivity.addOne(action.data, state.networkConnectivity)
    })),

    on(Actions.GetNetworkConnectivityTimerSuccess, (state, action) => ({
        ...state,
        networkConnectivityDataExpired: adapterNetworkConnectivityDataExpired.addOne(
            action.data,
            state.networkConnectivityDataExpired
        )
    })),

    on(Actions.GetProcessAdminBackupDataSuccess, (state, action) => ({
        ...state,
        processAdminBackupData: adapterProcessAdminBackupData.addOne(action.data, state.processAdminBackupData)
    })),

    on(Actions.GetProcessMaintenanceBackupDataSuccess, (state, action) => ({
        ...state,
        processMaintenanceBackupData: adapterProcessMaintenanceBackupData.addOne(
            action.data,
            state.processMaintenanceBackupData
        )
    })),

    on(Actions.GetProcessAcdSuccess, (state, action) => ({
        ...state,
        processAcd: adapterProcessAcd.addOne(action.data, state.processAcd)
    })),

    on(Actions.GetProcessAcdCallsSuccess, (state, action) => ({
        ...state,
        processAcdCalls: adapterProcessAcdCalls.addOne(action.data, state.processAcdCalls)
    })),

    on(Actions.GetProcessSupervisorLicensesSuccess, (state, action) => ({
        ...state,
        processSupervisorLicense: adapterSupervisorLicense.addOne(action.data, state.processSupervisorLicense)
    })),

    on(Actions.GetProcessWFMStatusRealTimeSuccess, (state, action) => ({
        ...state,
        processWFMStatusRealTime: adapterProcessWFMStatusRealTime.addOne(action.data, state.processWFMStatusRealTime)
    })),

    on(Actions.GetProcessWFMStatusHistoricSuccess, (state, action) => ({
        ...state,
        processWFMStatusHistoric: adapterProcessWFMStatusHistoric.addOne(action.data, state.processWFMStatusHistoric)
    })),

    on(Actions.GetProcessThresholdDataSuccess, (state, action) => ({
        ...state,
        processThresholdData: adapterProcessThresholdData.addOne(action.data, state.processThresholdData)
    })),

    on(Actions.GetProcessNicNamesSuccess, (state, action) => ({
        ...state,
        processNicNames: adapterProcessNicNamesData.addOne(action.data, state.processNicNames)
    })),

    on(Actions.GetProcessHostNamesSuccess, (state, action) => ({
        ...state,
        processHostName: adapterProcessHostNamesData.addOne(action.data, state.processHostName)
    })),

    on(Actions.GetProcessTotalMemsSuccess, (state, action) => ({
        ...state,
        processTotalMem: adapterProcessTotalMemData.addOne(action.data, state.processTotalMem)
    })),

    on(Actions.GetProcessSqlDataSuccess, (state, action) => ({
        ...state,
        processSqlData: adapterProcessSqlData.addOne(action.data, state.processSqlData)
    })),

    on(Actions.GetProcessSqlDataTimerSuccess, (state, action) => ({
        ...state,
        processSqlDataTimer: adapterProcessSqlDataTimer.addOne(action.data, state.processSqlDataTimer)
    })),

    on(Actions.GetProcessUpCpuMemDataSuccess, (state, action) => ({
        ...state,
        processUpCpuMemData: adapterProcessUpCpuMemData.addOne(action.data, state.processUpCpuMemData)
    })),

    on(Actions.GetProcessUpCpuMemDataTimerSuccess, (state, action) => ({
        ...state,
        processUpCpuMemDataTimer: adapterProcessUpCpuMemDataTimer.addOne(action.data, state.processUpCpuMemDataTimer)
    })),

    on(Actions.GetChildEquipmentDataSuccess, (state, action) => ({
        ...state,
        commonEntityData: adapterProcessVmwareData.upsertOne(action.data, state.commonEntityData)
    })),

    on(Actions.GetProcessDiskDataSuccess, (state, action) => ({
        ...state,
        processDiskData: adapterProcessDiskData.addOne(action.data, state.processDiskData)
    })),

    on(Actions.GetProcessDiskDataTimerSuccess, (state, action) => ({
        ...state,
        processDiskDataTimer: adapterProcessDiskDataTimer.addOne(action.data, state.processDiskDataTimer)
    })),

    on(Actions.GetProcessIISDataSuccess, (state, action) => ({
        ...state,
        processIISData: adapterProcessIISData.addOne(action.data, state.processIISData)
    })),

    on(Actions.GetProcessHyperVDataSuccess, (state, action) => ({
        ...state,
        processHyperVData: adapterProcessHyperVData.addOne(action.data, state.processHyperVData)
    })),

    on(Actions.GetProcessLyncMedServerDataSuccess, (state, action) => ({
        ...state,
        processLyncMedServerData: adapterProcessLyncMedServerData.addOne(action.data, state.processLyncMedServerData)
    })),

    on(Actions.GetProcessHyperVDataSuccess, (state, action) => ({
        ...state,
        processHyperVData: adapterProcessIISData.addOne(action.data, state.processHyperVData)
    })),

    on(Actions.GetProcessHyperVDataSuccess, (state, action) => ({
        ...state,
        processHyperVData: adapterProcessIISData.addOne(action.data, state.processHyperVData)
    })),

    on(Actions.GetProcessLyncMedServerDataSuccess, (state, action) => ({
        ...state,
        processLyncMedServerData: adapterProcessLyncMedServerData.addOne(action.data, state.processLyncMedServerData)
    })),

    on(Actions.GetProcessLyncFrontEndServerDataSuccess, (state, action) => ({
        ...state,
        processLyncFrontEndServerData: adapterProcessLyncFrontEndServerData.addOne(
            action.data,
            state.processLyncFrontEndServerData
        )
    })),

    on(Actions.GetProcessFlowControllerDataSuccess, (state, action) => ({
        ...state,
        processFlowControllerData: adapterProcessFlowControllerData.addOne(action.data, state.processFlowControllerData)
    })),

    on(Actions.GetProcessWorkflowDataSuccess, (state, action) => ({
        ...state,
        processWorkflowData: adapterProcessWorkflowData.addOne(action.data, state.processWorkflowData)
    })),

    on(Actions.GetProcessWatchedServiceDataSuccess, (state, action) => ({
        ...state,
        processWatchedServiceData: adapterProcessWatchedServiceData.addOne(action.data, state.processWatchedServiceData)
    })),

    on(Actions.GetEntityTimer, (state, action) => ({
        ...state,
        entityTimer: adapterEntityTimer.upsertOne(
            { uniqueId: action.uniqueId.toLowerCase(), data: true },
            state.entityTimer
        )
    })),

    on(Actions.GetEntityTimerSuccess, (state, action) => ({
        ...state,
        entityTimer: adapterEntityTimer.upsertOne(action.data, state.entityTimer)
    })),

    on(Actions.GetCommonEntitysSuccess, (state, action) => ({
        ...state,
        commonEntityData: adapterCommonEntity.upsertOne(action.data, state.commonEntityData)
    })),

    on(Actions.GetCommonHistoricSuccess, (state, action) => {
        return {
            ...state,
            commonEntityData: adapterCommonEntity.upsertOne(action.data, state.commonEntityData)
        };
    }),

    on(Actions.getSummaryDocumentSuccess, (state, action) => {
        return {
            ...state,
            summaryDocumentData: adapterSummaryDocument.upsertOne(action.data, state.summaryDocumentData)
        };
    }),

    on(Actions.StoreEquipIdBasedOnType, (state, action) => ({
        ...state,
        storeEquipIdBasedOnType: storeEquipIdsandCustIds(
            { id: action.equipmentId, idType: action.idType },
            state.storeEquipIdBasedOnType
        )
    }))
);

export const adapterProcessUpTimeEntities = adapterProcessUpTimeNotify.getSelectors().selectEntities;
export const adapterProcessUpTimeMsEntities = adapterProcessUpTimeMs.getSelectors().selectEntities;
