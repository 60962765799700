import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';

@Pipe({
    name: 'filter',
    pure: false
})
export class FilterPipe implements PipeTransform {
    transform(...args: any[]): any {
        const searchString = args[1];
        let items: any = args[0];
        if (searchString && items) {
            // Arguments sent from HTML.
            const args = arguments;
            // copy all objects of original array into new array of objects
            // let returnobjects = [];
            // args are the compare oprators provided in the *ngFor directive
            Object.keys(searchString).forEach(filterkey => {
                let filtervalue = searchString[filterkey];
                if (filtervalue) {
                    // range
                    if (typeof filtervalue === 'object' && filtervalue.range === true) {
                        items = items.filter(
                            obj => filtervalue.min <= obj[filterkey] && filtervalue.max >= obj[filterkey]
                        );
                    } else if (Array.isArray(filtervalue)) {
                        items = items.filter(
                            obj => filtervalue.indexOf(obj[filterkey].toLowerCase()) !== -1 || !filtervalue.length
                        );
                    } else {
                        items = items.filter(obj => {
                            let item = obj[filterkey];
                            if (item instanceof Date) { // format date to be a searchable string
                                const options: Intl.DateTimeFormatOptions = { month: 'short' };
                                const hours = item.getHours() % 12 ? item.getHours() % 12 : 12;
                                const minutes = item.getMinutes() > 10 ? item.getMinutes() : `0${item.getMinutes()}`;
                                const ampm = item.getHours() > 11 ? 'PM' : 'AM';
                                const month = new Intl.DateTimeFormat('en-US', options).format(item);

                                item = `${item.getDate()}-${month}-${item.getFullYear()} ${hours}:${minutes} ${ampm}`
                            } else if (typeof item === 'number'){
                                item = item.toString()
                            }
                            return item ? item.toLowerCase().indexOf(filtervalue.toLowerCase()) !== -1 : false;
                        });
                    }
                }
            });

            const filterredCountSubj = args[2];
            if (filterredCountSubj) {
                filterredCountSubj.next(items.length);
            }
            const filterAlarmsubscription = args[3];
            if (filterAlarmsubscription) {
                filterAlarmsubscription.next(items);
            }
            // return new object to *ngFor directive
            return items;
        }
    }
}
