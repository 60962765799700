import { createAction, props } from '@ngrx/store';

import { Dashboard, DashletMeta, Dashlet, DashboardState } from '@models/index';

// Dashlets Meta
export const GetDashletsMeta = createAction('[Dashboard] Get Dashlets');

export const GetDashletsMetaSuccess = createAction(
    '[Dashboard] Get Dashlets Success',
    props<{ data: DashletMeta[] }>()
);

export const GetDashletsMetaFailure = createAction('[Dashboard] Get Dashlets Failure', props<{ error: any }>());

// Load Dashboard
export const LoadDashboard = createAction('[Dashboard] Load Dashboard', props<{ id: string; userId: string }>());

export const LoadDashboardSuccess = createAction(
    '[Dashboard] Load Dashboard Success',
    props<{ dashboard: any; dashboardState: DashboardState }>()
);

export const LoadDashboardFailure = createAction('[Dashboard] Load Dashboard Failure', props<{ error: any }>());

// Get Dashboards
export const GetDashboards = createAction('[Dashboard] Get Dashboards', props<{ entityId: string; userId: string }>());

export const GetDashboardsSuccess = createAction(
    '[Dashboard] Get Dashboards Success',
    props<{ dashboards: Dashboard[] }>()
);

export const GetDashboardsFailure = createAction('[Dashboard] Get Dashboards Failure', props<{ error: any }>());

// Update Dashboard
export const UpdateDashboard = createAction('[Dashboard] Update Dashboard', props<{ dashboard: Dashboard }>());

export const UpdateDashboardSuccess = createAction('[Dashboard] Update Dashboard Success', props<{ data: any }>());

export const UpdateDashboardFailure = createAction('[Dashboard] Update Dashboard Failure', props<{ error: any }>());

// Delete Dashboard
export const DeleteDashboard = createAction('[Dashboard] Delete Dashboard', props<{ dashletId: any }>());

export const DeleteDashboardSuccess = createAction('[Dashboard] Delete Dashboard Success', props<{ data: any }>());

export const DeleteDashboardFailure = createAction('[Dashboard] Delete Dashboard Failure', props<{ error: any }>());

// Add Dashboard
export const AddDashboard = createAction('[Dashboard] Add Dashboard', props<{ dashboard: Dashboard }>());

export const AddDashboardSuccess = createAction('[Dashboard] Add Dashboard Success', props<{ data: any }>());

export const AddDashboardFailure = createAction('[Dashboard] Add Dashboard Failure', props<{ error: any }>());

//Update Dashboard State
export const UpdateDashboardState = createAction('[Dashboard] Update Dashboard State');

export const UpdateDashboardStateSuccess = createAction(
    '[Dashboard] Update Dashboard State Success',
    props<{ data: any }>()
);

export const UpdateDashboardStateFailure = createAction(
    '[Dashboard] Update Dashboard State Failure',
    props<{ error: any }>()
);

// Copy  Dashboard
export const CopyDashboard = createAction(
    '[Dashboard] Copy Dashboard',
    props<{ dashboardId: string; newDashboard: Dashboard }>()
);

export const CopyDashboardSuccess = createAction('[Dashboard] Copy Dashboard Success', props<{ data: any }>());

export const CopyDashboardFailure = createAction('[Dashboard] Copy Dashboard Failure', props<{ error: any }>());

// create dashlets from state

export const CreateDashletsFromState = createAction(
    '[Dashboard] Create Dashlets From State',
    props<{ state: any; dashletsMeta: any[] }>()
);

export const CreateDashletsFromStateSuccess = createAction(
    '[Dashboard] Create Dashlets From State Success',
    props<{ data: any }>()
);

export const CreateDashletsFromStateFailure = createAction(
    '[Dashboard] Create Dashlets From State Failure',
    props<{ error: any }>()
);

export const AddDashlet = createAction('[Dashboard] Add Dashlet', props<{ dashlet: any; dashType: any }>());

export const UpdateDashlets = createAction('[Dashboard] Update Dashlets');

export const UpdateDashletsSuccess = createAction('[Dashboard] Update Dashlets Success', props<{ data: any }>());

export const UpdateDashletsFailure = createAction('[Dashboard] Update Dashlets Failure', props<{ error: any }>());
