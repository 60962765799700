import { Subject, Subscription } from 'rxjs';
import { Equipment, Regex, Sort, Point } from '@models/index';

export class SBCNICInfo {
    private _description!: string;
    private _status!: string;
    private _inUcastPkts: Point[] = [];
    private _inUcastPktsDeltas: Point[] = [];
    private _outUcastPkts: Point[] = [];
    private _outUcastPktsDeltas: Point[] = [];
    private _chartDataUpdated: Subject<null> = new Subject();
    // private _intervalSubscription: Subscription;

    constructor(description: string, status: string) {
        this.description = description;
        this.status = status;
        this.resetChartData();
    }

    /**
     *
     */
    private resetChartData() {
        this._inUcastPkts = [];
        this._outUcastPkts = [];
    }

    public update(description: string) {
        this._description = description;
    }

    public updateFromObject(data: SBCNICInfo) {
        this._description = data.description;
    }

    /**
     * Getter description
     * @return {string}
     */
    public get description(): string {
        return this._description;
    }

    /**
     * Getter description
     * @return {string}
     */
    public get status(): string {
        return this._status;
    }

    /**
     * Getter chartDataUpdated
     * @return {Subject<null> }
     */
    public get chartDataUpdated(): Subject<null> {
        return this._chartDataUpdated;
    }

    /**
     * Getter inUcastPkts
     * @return {Point[]}F
     */
    public get inUcastPkts(): Point[] {
        return this._inUcastPkts;
    }

    /**
     * Getter inUcastPktsDeltas
     * @return {Point[]}
     */
    public get inUcastPktsDeltas(): Point[] {
        return this._inUcastPktsDeltas;
    }

    /**
     * Getter outUcastPkts
     * @return {Point[]}
     */
    public get outUcastPkts(): Point[] {
        return this._outUcastPkts;
    }

    /**
     * Getter outUcastPktsDeltas
     * @return {Point[]}
     */
    public get outUcastPktsDeltas(): Point[] {
        return this._outUcastPktsDeltas;
    }

    /**
     * Setter description
     * @param {string} value
     */
    public set description(value: string) {
        this._description = value;
    }

    /**
     * Setter description
     * @param {string} value
     */
    public set status(value: string) {
        this._status = value;
    }

    private addInUcastPktsData(value: string, date: Date) {
        if (Regex.stringIsNumber(value)) {
            if (this.inUcastPkts.length > 21) {
                this.inUcastPkts.shift();
            }
            const index = this.inUcastPkts.findIndex(e => e.x.getTime() === date.getTime());
            if (index === -1) {
                this.inUcastPkts.push({
                    x: date,
                    y: +value
                });
            }
            Sort.sortDates(this.inUcastPkts);
        }

        // if enough values to calculate deltas
        if (this.inUcastPkts.length >= 2) {
            if (this.inUcastPktsDeltas.length > 21) {
                this.inUcastPktsDeltas.shift();
            }
            // calculate deltas only if previous value is 5 minutes behind
            for (let i = 1; i < this.inUcastPkts.length; i++) {
                if (this.inUcastPkts[i].x.getMinutes() - this.inUcastPkts[i - 1].x.getMinutes() === 5) {
                    // ensure no duplicates
                    const index = this.inUcastPktsDeltas.findIndex(
                        e => e.x.getTime() === this.inUcastPkts[i].x.getTime()
                    );
                    if (index === -1) {
                        // push calculated delta to array
                        this.inUcastPktsDeltas.push({
                            x: this.inUcastPkts[i].x,
                            y: this.inUcastPkts[i].y - this.inUcastPkts[i - 1].y
                        });
                    }
                    Sort.sortDates(this.inUcastPktsDeltas);
                }
            }
        }
    }

    private addOutUcastPktsData(value: string, date: Date) {
        if (Regex.stringIsNumber(value)) {
            if (this.outUcastPkts.length > 21) {
                this.outUcastPkts.shift();
            }
            const index = this.outUcastPkts.findIndex(e => e.x.getTime() === date.getTime());
            if (index === -1) {
                this.outUcastPkts.push({
                    x: date,
                    y: +value
                });
            }
            Sort.sortDates(this.outUcastPkts);

            // if enough values to calculate deltas
            if (this.outUcastPkts.length >= 2) {
                if (this.outUcastPktsDeltas.length > 21) {
                    this.outUcastPktsDeltas.shift();
                }
                // calculate deltas only if previous value is 5 minutes behind
                for (let i = 1; i < this.outUcastPkts.length; i++) {
                    if (this.outUcastPkts[i].x.getMinutes() - this.outUcastPkts[i - 1].x.getMinutes() === 5) {
                        // ensure no duplicates
                        const index = this.outUcastPktsDeltas.findIndex(
                            e => e.x.getTime() === this.outUcastPkts[i].x.getTime()
                        );
                        if (index === -1) {
                            // push calculated delta to array
                            this.outUcastPktsDeltas.push({
                                x: this.outUcastPkts[i].x,
                                y: this.outUcastPkts[i].y - this.outUcastPkts[i - 1].y
                            });
                        }
                        Sort.sortDates(this.outUcastPktsDeltas);
                    }
                }
            }
        }
    }

    public updateNICChartData(inUcastPkts: string, outUcastPkts: string, date: Date): void {
        this.addInUcastPktsData(inUcastPkts, date);
        this.addOutUcastPktsData(outUcastPkts, date);
        this._chartDataUpdated.next(null);
    }

    /*
    public refreshNICChartData() {
        this.updateNICChartData(this.getCurrentAvgPing(), this.getCurrentPacketLossPercentage(), this.getCurrentMaxPing(), new Date());
    }

    public subscribeToIntervalUpdate(interval: number) {
       this._intervalSubscription = Observable.interval(interval).subscribe(() => {
            this.refreshNicChartData();
        })
    }

    public unsubscribeToIntervalUpdate() {
        this._intervalSubscription.unsubscribe();
    }
    */
}
