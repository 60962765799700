import {
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingList,
    DashletSettingText
} from '@models/index';
import { AccountService, DashletSettingsService } from '@services/index';
import { Observable, forkJoin } from 'rxjs';

export class DashletSettingsFormSystemHealthVmware extends DashletSettingsForm {
    private customer!: DashletSettingDropdown;

    private location!: DashletSettingDropdown;

    private equipment!: DashletSettingDropdown;

    private isCust!: boolean;

    private customerId!: string;

    constructor(
        private readonly settingsService: DashletSettingsService,

        private readonly accountService: AccountService
    ) {
        super();

        this.init();

        this.loadCustomers();
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);

        if (this.customer.value) {
            this.loadLocations(this.customer.value);
        }

        if (this.location.value) {
            this.loadEquipment(this.location.value);
        }
    }

    private init(): void {
        const group = new DashletSettingsGroup();

        const s: any = (group.settings = []);

        const user = this.accountService.getUserDetails();

        this.isCust = user.EntityGroup === 2;

        this.customerId = user.EntityId;

        const customer = (this.customer = new DashletSettingDropdown());

        customer.label = 'Customer';

        customer.stateKey = 'customer';

        customer.disabled = true;

        customer.change.subscribe(() => {
            if (!this.location.value) this.clearAndDisable(this.location);

            if (!this.equipment.value) this.clearAndDisable(this.equipment);

            this.loadLocations(customer.value!);
        });

        s.push(customer);

        const location = (this.location = new DashletSettingDropdown());

        location.label = 'Location';

        location.stateKey = 'location';

        location.disabled = true;

        location.change.subscribe(() => {
            if (!this.equipment.value) this.clearAndDisable(this.equipment);

            this.loadEquipment(location.value!);
        });

        s.push(location);

        const equipment = (this.equipment = new DashletSettingDropdown());

        equipment.label = 'Source Equipment';

        equipment.stateKey = 'equipment';

        equipment.disabled = true;

        s.push(equipment);

        const customName = new DashletSettingText(40);
        customName.label = 'Custom Dashlet Name';
        customName.stateKey = 'customName';
        s.push(customName);

        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);

        this.settingsService

            .getUserAssociatedCustomers()

            .subscribe(
                customers => {
                    this.customer.items = customers;

                    this.customer.loadingComplete();
                },

                () => {
                    this.customer.loadingComplete();

                    this.customer.disabled = true;

                    // show error notification here
                }
            );
    }

    private loadLocations(customer: string): void {
        this.enableAndLoading(this.location);

        this.settingsService

            .getLocations(customer)

            .subscribe(
                locations => {
                    this.location.items = locations;

                    this.location.loadingComplete();
                },

                () => {
                    this.location.loadingComplete();

                    this.location.disabled = true;

                    // show error notification here
                }
            );
    }

    private loadEquipment(location: string): void {
        this.enableAndLoading(this.equipment);
        this.settingsService.getEquipment(location, 'vCenter').subscribe(
            receivers => {
                this.equipment.items = receivers;
                this.equipment.loadingComplete();
            },
            () => {
                this.equipment.loadingComplete();
                this.equipment.disabled = true;
                // show error notification here
            }
        );
    }

    private clearAndDisable(widget: DashletSettingDropdown | DashletSettingList<string>) {
        widget.disabled = true;

        widget.items = [];

        if (widget instanceof DashletSettingList) {
            widget.values = [];
        } else {
            widget.value = null;
        }
    }

    private enableAndLoading(widget: DashletSettingDropdown | DashletSettingList<string>) {
        widget.disabled = false;

        widget.loadingBegin();
    }
}
