import { BrandingColors, LogoImage } from '@models/index';
import { createAction, props } from '@ngrx/store';

export const GetBrandingColors = createAction('[Branding] Get BrandingColors', props<{ entityId: string }>());

export const GetBrandingColorsSuccess = createAction(
    '[Branding] Get BrandingColors Success',
    props<{ data: BrandingColors }>()
);

export const GetBrandingColorsFailure = createAction('[Branding] Get BrandingColors Failure', props<{ error: any }>());

export const GetBusinessPartnerLogo = createAction(
    '[BusinessPartnerLogo] Get BusinessPartnerLogos',
    props<{ entityId: string }>()
);

export const GetBusinessPartnerLogoSuccess = createAction(
    '[BusinessPartnerLogo] Get BusinessPartnerLogos Success',
    props<{ data: LogoImage }>()
);

export const GetBusinessPartnerLogoFailure = createAction(
    '[BusinessPartnerLogo] Get BusinessPartnerLogos Failure',
    props<{ error: any }>()
);
