import { DashletSettingsForm, DashletSettingDropdown, DashletSettingsGroup } from '@models/index';
import { DashletSettingsService } from '@services/index';

export class DashletSettingsFormMSTeamsRoomsHealthSummary extends DashletSettingsForm {
    private customer: DashletSettingDropdown = new DashletSettingDropdown();
    private location: DashletSettingDropdown = new DashletSettingDropdown();
    private equipment: DashletSettingDropdown = new DashletSettingDropdown();

    constructor(private readonly settingsService: DashletSettingsService) {
        super();

        this.init();
        this.loadCustomers();
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);

        if (this.customer.value) this.loadLocations(this.customer.value);
        if (this.location.value) this.loadequipments(this.location.value);
    }

    private init(): void {
        const group = new DashletSettingsGroup();
        group.settings = [];

        this.customer.label = 'Customer';
        this.customer.stateKey = 'customer';
        this.customer.disabled = true;
        this.customer.change.subscribe(() => {
            this.clearAndDisable(this.equipment);
            this.loadLocations(this.customer.value);
        });

        this.location.label = 'Location';
        this.location.stateKey = 'location';
        this.location.disabled = true;
        this.location.change.subscribe(() => {
            this.clearAndDisable(this.equipment);
            this.loadequipments(this.location.value);
        });

        this.equipment.label = 'Service';
        this.equipment.stateKey = 'equipment';
        this.equipment.disabled = true;

        group.settings.push(this.customer);
        group.settings.push(this.location);
        group.settings.push(this.equipment);

        this.updateSettings(group);
    }

    // 1st drop down, Customer
    private loadCustomers(): void {
        this.enableAndLoading(this.customer);
        this.settingsService.getUserAssociatedCustomers().subscribe({
            next: customers => {
                this.customer.items = customers;
                this.customer.loadingComplete();
            },
            error: () => {
                this.customer.loadingComplete();
                this.customer.disabled = true;
            }
        });
    }

    // 2nd drop down, Location
    private loadLocations(customer: string): void {
        this.enableAndLoading(this.location);

        this.settingsService.getLocations(customer).subscribe({
            next: locations => {
                this.location.items = locations;
                this.location.loadingComplete();
            },
            error: () => {
                this.location.loadingComplete();
                this.location.disabled = true;
            }
        });
    }

    // 3rd drop down, Service (equipment)
    private loadequipments(locationId: string): void {
        this.enableAndLoading(this.equipment);
        this.settingsService.getTeamsTenants(locationId).subscribe({
            next: receivers => {
                this.equipment.items = receivers;
                this.equipment.loadingComplete();
            },
            error: () => {
                this.equipment.loadingComplete();
                this.equipment.disabled = true;
            }
        });
    }

    private enableAndLoading(widget: DashletSettingDropdown) {
        widget.disabled = false;
        widget.loadingBegin();
    }

    private clearAndDisable(widget: DashletSettingDropdown) {
        widget.disabled = true;
        widget.items = [];
        widget.value = null;
    }
}
