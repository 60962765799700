import { Customer, Dashlet, Equipment, Location, RemoteLocation, SIPBandwidth } from '@models/index';
import { DashboardService } from '@services/index';
import { Subject } from 'rxjs';

export class DashletSIPBandwidth extends Dashlet {
    public commandTypeIdSIPBandwidth = '8BE80AC3-8AAE-46D4-8DE0-1AD25AC2A17F';
    public equipment!: Equipment;
    public customer!: Customer;
    public location!: Location;
    public remoteLocation!: RemoteLocation;
    public localLocation!: RemoteLocation;
    public sipBandwidth!: SIPBandwidth;
    public documentTimeStamps: any[] = [];

    public chartHeight = '';

    readonly interval = 60;

    lshName!: string | null;
    uptimeMs!: number | null;
    loggedInUsers!: number;

    private chartDataUpdated: Subject<null> = new Subject();
    get onChartDataUpdated() {
        return this.chartDataUpdated;
    }

    constructor(private dashboardService: DashboardService) {
        super();

        this.sizes = [
            {
                id: 0,
                label: 'Small',
                cols: 3,
                rows: 3
            },
            {
                id: 1,
                label: 'Medium',
                cols: 5,
                rows: 6
            }
        ];
        this.applySize(1);
        this.resetData();
        this.dashboardService.getUpdateInterval(this.interval).subscribe(time => {
            this.sipBandwidth.refreshUsageHistory();
            this.onChartDataUpdated.next(null);
        });
    }

    public applySettings(v: { [key: string]: any }): void {
        super.applySettings(v);
        this.configured = v.customer && v.location && v.equipment && v.remoteLocation;
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
            // this.loadCustomerLogo();
        } else {
            this.customer = new Customer('', '');
        }
        if (v.location) {
            this.location = new Location(v.location.value, v.location.label);
        } else {
            this.location = new Location('', '');
        }
        if (v.equipment) {
            this.equipment = new Equipment(v.equipment.value, v.equipment.label);
        } else {
            this.equipment = new Equipment('', '');
        }
        if (v.remoteLocation) {
            this.remoteLocation = new RemoteLocation(v.remoteLocation.value, v.remoteLocation.label, '');
            this.localLocation = new RemoteLocation('', v.remoteLocation.localLocationName, v.remoteLocation.timeZone);
        } else {
            this.remoteLocation = new RemoteLocation('', '', '');
            this.localLocation = new RemoteLocation('', '', '');
        }
        this.generatedNameTag = this.configured
            ? `${v.location.label} | ${v.equipment.label} | ${v.remoteLocation.label}`
            : 'Unconfigured';
        this.customNameTag = v.nameTag;
        this.lshName = this.configured ? v.equipment.label : null;
        this.updateSize();
    }

    public applySize(id: number): void {
        super.applySize(id);
        this.updateSize();
    }

    private updateSize(): void {
        const h = 0;
        const w = 0;
        this.applySizeExpansion(w, h);
        if (this.getSize().id === 0) {
            this.chartHeight = '8rem';
        } else {
            this.chartHeight = '14rem';
        }
    }

    public processSIPBandwidth2(dataArray: any[]): void {
        if (dataArray) {
            dataArray.forEach(dataRow => {
                if (dataRow) {
                    if (!this.checkDataIfProcessed(dataRow.id, dataRow.timestamp)) {
                        this.processSIPBandwidth(dataRow.data, dataRow.timestamp);
                    }
                }
            });
        }
    }

    private checkDataIfProcessed(id: string, timestamp: string): boolean {
        const index = this.documentTimeStamps.findIndex(document => {
            return document.id === id && document.timestamp === timestamp;
        });

        if (index === -1) {
            this.documentTimeStamps.push({ id: id, timestamp: timestamp });
            return false;
        }

        return true;
    }

    public processSIPBandwidth(data: any, timestamp: string): void {
        for (let i = 0; i < data.length; i++) {
            if (data[i].RemoteLocationId === this.remoteLocation.locationId) {
                this.sipBandwidth.updateUsageHistory(
                    new Date(timestamp),
                    data[i].AudioUsage,
                    data[i].VideoUsage,
                    data[i].TotalUsage
                );
            }
        }

        this.chartDataUpdated.next(null);
    }

    public resetData(): void {
        this.lshName = null;
        this.uptimeMs = null;
        this.loggedInUsers = 0;
        this.chartDataUpdated.next(null);
        this.sipBandwidth = new SIPBandwidth();
        this.localLocation = new RemoteLocation('', '', '');
    }

    public dispose(): void {}
}
