import { Customer, CustomerMOSSummaryData, Dashlet } from '@models/index';
import { RealtimeGatewayService } from '@services/index';
import { Subject } from 'rxjs';

export class DashletBusinessPartnerVQMSummary extends Dashlet {
    public readonly commandTypeIDBusinessPartnerVQMSummary = 'F0BB94FA-3BC2-4D5C-963C-976520F80C84';
    public readonly commandTypeIDCIP = '123458A9-CA47-4CEA-B798-64C69A077C88';

    customers!: Customer[];
    customerMOSData!: Map<string, CustomerMOSSummaryData>;
    summaryPeriod!: any;
    lastUpdated: string;
    timeRange: string;

    private documentTimeStamps: any[] = [];
    settingsUpdated: Subject<null> = new Subject();

    constructor(private realtimeService: RealtimeGatewayService) {
        super();

        // sizing
        this.sizes = [
            {
                id: 0,
                label: 'Large',
                cols: 9,
                rows: 9
            }
        ];
        this.applySize(0);

        this.timeRange = '';

        // init data
        this.resetData();
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        // unsub realtime feed
        this.unsubData();

        // read settings object
        this.customers = v.customer ? v.customer.map((element: any) => new Customer(element.value, element.label)) : [];
        this.summaryPeriod = v.summaryPeriod;
        this.configured = this.customers.length > 0 && this.summaryPeriod;
        // update size
        this.updateSize();
        this.settingsUpdated.next(null);
    }

    applySize(id: number) {
        super.applySize(id);
        this.updateSize();
    }

    private updateSize() {
        const h = 0;
        const w = 0;
        this.applySizeExpansion(w, h);
    }

    public updateNameTag(timestamp: string) {
        if (this.lastUpdated === undefined) {
            return;
        }
        const originalDate = new Date(this.lastUpdated),
            originalDateNoMinutes = new Date(this.lastUpdated);
        originalDateNoMinutes.setMinutes(0, 0, 0);

        const _month = originalDate.toLocaleDateString(undefined, { month: 'short' });
        const _date = originalDate.toLocaleDateString(undefined, { day: 'numeric' });

        const firstDate: Date = new Date(originalDateNoMinutes);
        firstDate.setHours(firstDate.getHours() - 24);

        if (this.configured) {
            const _hour = originalDate
                .toLocaleTimeString(undefined, {
                    hour: 'numeric',
                    minute: 'numeric'
                })
                .toUpperCase();
            this.generatedNameTag = `Last updated at ${_date}-${_month} ${_hour}`;
        } else {
            this.generatedNameTag = 'Unconfigured';
        }
    }

    public updateTimeRange(timestamp: string) {
        let newDate = new Date(timestamp);
        newDate = newDate > new Date(this.lastUpdated) ? newDate : new Date(this.lastUpdated);
        newDate.setMinutes(0, 0);
        const minDate = new Date(newDate);
        minDate.setHours(minDate.getHours() - parseInt(this.summaryPeriod.value));

        this.timeRange = `Last ${this.summaryPeriod.value === '1' ? 'hour' : this.summaryPeriod.value + ' hours'}  |
         ${minDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} -
          ${newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
    }

    public processData(dataArray: any[]) {
        if (dataArray) {
            dataArray.forEach(dataRow => {
                if (dataRow) {
                    if (
                        !this.checkDataIfProcessed(dataRow.id, dataRow.timestamp) ||
                        !this.customerMOSData.get(dataRow.id)
                    ) {
                        this.processArrayData(dataRow.timestamp, dataRow.data);
                    }
                }
            });
        }
    }

    private processArrayData(timestamp: string, data: any) {
        const row = data[0];
        const customerName = row.CustomerName;
        const customerId = row.CustomerId;
        let currentCustomerData = this.customerMOSData.get(customerId);

        if (!currentCustomerData) {
            this.customerMOSData.set(customerId, new CustomerMOSSummaryData(customerName, customerId));
            currentCustomerData = this.customerMOSData.get(customerId);
        }

        currentCustomerData!.updateMOSData(
            [parseInt(row.Mos10_36), parseInt(row.Mos36_40), parseInt(row.Mos40)],
            parseInt(row.StreamCount),
            parseInt(row.TafficVolume),
            this.summaryPeriod,
            new Date(timestamp)
        );
    }

    private checkDataIfProcessed(id: string, timestamp: string) {
        const index = this.documentTimeStamps.findIndex(document => {
            return document.id === id && document.timestamp === timestamp;
        });

        if (index === -1) {
            this.documentTimeStamps.push({ id: id, timestamp: timestamp });
            return false;
        }

        return true;
    }

    processCIP(data: any[], customerId: any, customerName: any) {
        let currentCustomerData = this.customerMOSData.get(customerId);

        if (!currentCustomerData) {
            this.customerMOSData.set(customerId, new CustomerMOSSummaryData(customerName, customerId));
            currentCustomerData = this.customerMOSData.get(customerId);
        }

        let cipTotal = 0;
        let cipPoor = 0;
        let cipSatisfactory = 0;

        for (const row of data) {
            cipTotal += parseInt(row.data[0].Total);
            cipPoor += parseInt(row.data[0].Less36);
            cipSatisfactory += parseInt(row.data[0].Between36And4);
        }

        currentCustomerData!.updateCIP(cipTotal, cipPoor, cipSatisfactory);
    }

    resetData() {
        this.customers = [];
        this.customerMOSData = new Map<string, CustomerMOSSummaryData>();
    }

    dispose() {
        this.unsubData();
    }
    private unsubData() {
        // unsub data
        for (const customer of this.customers) {
            this.realtimeService.unsubscribe(customer.customerId, this.commandTypeIDBusinessPartnerVQMSummary);
            this.realtimeService.unsubscribe(customer.customerId, this.commandTypeIDCIP);
        }
    }
}
