/* eslint-disable no-undef */
import { environment } from '@environments/environment';
import { Dashlet } from '@models/index';
import { RealtimeGatewayService } from '@services/index';
import { Subject } from 'rxjs';

interface Point {
    x: Date;
    y: number;
}

export class DashletTotalCustomersByRegion extends Dashlet {
    public _commandTypeIdCustomer = '7879A649-666E-480F-924E-92D447D4D821';
    public _equipmentId = '445bb159-5bae-42ce-b568-58d84617bdfc';

    public groupName!: string;

    private _totalCustomersArrayEMEA: Point[] = [];
    private _totalCustomersArrayNA: Point[] = [];
    private _totalCustomersArrayAPAC: Point[] = [];

    private _totalCustomersLatestAPAC = 0;
    private _totalCustomersLatestEMEA = 0;
    private _totalCustomersLatestNA = 0;

    private _chartDataUpdated: Subject<null> = new Subject();
    get onChartDataUpdated() {
        return this._chartDataUpdated;
    }

    constructor(private realtimeService: RealtimeGatewayService) {
        super();
        // sizing
        this.sizes = [
            {
                id: 0,
                label: 'Small',
                cols: 5,
                rows: 2
            },
            {
                id: 1,
                label: 'Medium',
                cols: 5,
                rows: 5
            },
            {
                id: 2,
                label: 'Large',
                cols: 10,
                rows: 5
            }
        ];
        this.applySize(1);

        // init data
        this.resetData();
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);
        this.configured = true;
    }

    applySize(id: number) {
        super.applySize(id);
        this.updateSize();
    }

    private updateSize() {
        const h = 0;
        const w = 0;
        this.applySizeExpansion(w, h);
    }

    public processCustomerData(data: any[]) {
        this.resetData();
        const results = data[0].data;
        if (results !== null) {
            const startDate = new Date(); // sets to current date
            startDate.setDate(startDate.getDate() - 40); // date we want to start at
            let currentPeriod: Date = new Date();
            currentPeriod.setFullYear(0); // Start with impossible year
            let totalCustomersEMEA: any = null;
            let totalCustomersNA: any = null;
            let totalCustomersAPAC: any = null;
            let i = 0;
            const periodStart: Date = new Date();
            results.forEach((row: any) => {
                // Convert date from RTG to Date object format. RTG date is in US format
                let dateSplit = row.PeriodStart.split('/', 3);
                periodStart.setDate(dateSplit[1]);
                periodStart.setMonth(dateSplit[0] - 1);
                dateSplit = dateSplit[2].split(' ', 1);
                periodStart.setFullYear(dateSplit[0]);

                if (startDate < periodStart) {
                    i++;
                    // New day and not the first day
                    if (currentPeriod < periodStart && currentPeriod.getFullYear() !== 0) {
                        this._totalCustomersArrayNA.push({ x: currentPeriod, y: totalCustomersNA });
                        this._totalCustomersArrayAPAC.push({ x: currentPeriod, y: totalCustomersAPAC });
                        this._totalCustomersArrayEMEA.push({ x: currentPeriod, y: totalCustomersEMEA });
                        // Get latest value for each region unless it's null
                        this._totalCustomersLatestEMEA = totalCustomersEMEA
                            ? totalCustomersEMEA
                            : this._totalCustomersLatestEMEA;
                        this._totalCustomersLatestNA = totalCustomersNA
                            ? totalCustomersNA
                            : this._totalCustomersLatestNA;
                        this._totalCustomersLatestAPAC = totalCustomersAPAC
                            ? totalCustomersAPAC
                            : this._totalCustomersLatestAPAC; // reset values for new day
                        totalCustomersEMEA = null;
                        totalCustomersNA = null;
                        totalCustomersAPAC = null;
                    }

                    switch (row.RegionShortName) {
                        case 'NA':
                            totalCustomersNA = row.CustomerCount;
                            break;
                        case 'APAC':
                            totalCustomersAPAC = row.CustomerCount;
                            break;
                        case 'EMEA':
                            totalCustomersEMEA = row.CustomerCount;
                            break;
                        case 'null':
                            break;
                    }
                    currentPeriod = new Date(periodStart);
                }
            });
            // Get the last days values if we have them all
            if (totalCustomersEMEA !== null && totalCustomersAPAC !== null && totalCustomersNA !== null) {
                this._totalCustomersArrayNA.push({ x: currentPeriod, y: totalCustomersNA });
                this._totalCustomersArrayAPAC.push({ x: currentPeriod, y: totalCustomersAPAC });
                this._totalCustomersArrayEMEA.push({ x: currentPeriod, y: totalCustomersEMEA });
                // Get latest value for each region unless it's null
                this._totalCustomersLatestEMEA = totalCustomersEMEA
                    ? totalCustomersEMEA
                    : this._totalCustomersLatestEMEA;
                this._totalCustomersLatestNA = totalCustomersNA ? totalCustomersNA : this._totalCustomersLatestNA;
                this._totalCustomersLatestAPAC = totalCustomersAPAC
                    ? totalCustomersAPAC
                    : this._totalCustomersLatestAPAC; // reset values for new day
            }
        } else {
            if (!environment.production) console.error('RTG CONNECTION ERROR');
        }
        this._chartDataUpdated.next(null);
    }

    resetData() {
        this._totalCustomersArrayEMEA = [];
        this._totalCustomersArrayNA = [];
        this._totalCustomersArrayAPAC = [];
        this._totalCustomersLatestEMEA = 0;
        this._totalCustomersLatestNA = 0;
        this._totalCustomersLatestAPAC = 0;
        this._chartDataUpdated.next(null);
    }

    dispose() {
        this.unsubData();
    }
    private unsubData() {
        if (this._equipmentId) {
            // unsub data
            this.realtimeService.unsubscribe(this._equipmentId, this._commandTypeIdCustomer);
        }
    }
    get totalCustomersLatestEMEA(): number {
        return this._totalCustomersLatestEMEA;
    }
    get totalCustomersLatestNA(): number {
        return this._totalCustomersLatestNA;
    }
    get totalCustomersLatestAPAC(): number {
        return this._totalCustomersLatestAPAC;
    }
    get totalCustomersArrayEMEA(): Point[] {
        return this._totalCustomersArrayEMEA;
    }
    get totalCustomersArrayNA(): Point[] {
        return this._totalCustomersArrayNA;
    }
    get totalCustomersArrayAPAC(): Point[] {
        return this._totalCustomersArrayAPAC;
    }
}
