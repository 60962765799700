import { createAction, props } from '@ngrx/store';

export const GetUserContexts = createAction('[UserContext] Get UserContexts');

export const GetUserContextsSuccess = createAction('[UserContext] Get UserContexts Success', props<{ data: any }>());

export const GetUserContextsFailure = createAction('[UserContext] Get UserContexts Failure', props<{ error: any }>());

export const GetUserContextsFailureHandledSuccess = createAction(
    '[UserContext] Get UserContextsFailureHandled Success'
);
