import * as Actions from '@actions/index';
import { AppState, selectDataFromCommonEntity, selectEntity } from '@reducers/index';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CustomerLogo, DashletVoiceQualityMeanOpinionScoreScatter, MosDataPoint } from '@models/index';
import { DashletLineGraphComponent } from '../dashlet-line-graph/dashlet-line-graph.component';
import { DashletService, DashletSettingsService } from '@services/index';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-dashlet-voice-quality-mean-opinion-score-scatter',
    templateUrl: './dashlet-voice-quality-mean-opinion-score-scatter.component.html',
    styleUrls: ['./dashlet-voice-quality-mean-opinion-score-scatter.component.scss']
})
export class DashletVoiceQualityMeanOpinionScoreScatterComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletVoiceQualityMeanOpinionScoreScatter;
    clearHigh: boolean = false;
    clearAverage: boolean = false;
    clearPoor: boolean = false;
    clearUnknown: boolean = false;

    private subscription: Subscription = new Subscription();
    private subscriptionDocument: Subscription = new Subscription();
    private hasPreviousSubscriptions: boolean = false;
    private toolTipDuplicateRecords: {} = {};

    private pChart: DashletLineGraphComponent;
    get chart() {
        return this.pChart;
    }
    @ViewChild('chart') set chart(value: DashletLineGraphComponent) {
        this.pChart = value;
        this.updateChartData();
    }

    private pLargeChart: DashletLineGraphComponent;
    get largeChart() {
        return this.pLargeChart;
    }
    @ViewChild('largeChart') set largeChart(value: DashletLineGraphComponent) {
        this.pLargeChart = value;
        this.updateChartData();
    }

    private poorDataset = {
        label: 'Poor',
        data: [],
        borderColor: 'white',
        backgroundColor: this.dashletService.getSeverityColor(2),
        pointBorderColor: this.dashletService.getSeverityColor(2),
        pointBackgroundColor: this.dashletService.getSeverityColor(2),
        pointStyle: 'rect',
        pointRadius: 3,
        pointHoverRadius: 3
    };

    private satisfactoryDataset = {
        label: 'Satisfactory',
        data: [],
        borderColor: 'white',
        backgroundColor: this.dashletService.getSeverityColor(1),
        pointBorderColor: this.dashletService.getSeverityColor(1),
        pointBackgroundColor: this.dashletService.getSeverityColor(1),
        pointStyle: 'rect',
        pointRadius: 3,
        pointHoverRadius: 3
    };

    private goodDataset = {
        label: 'Good',
        data: [],
        borderColor: 'white',
        backgroundColor: this.dashletService.getSeverityColor(0),
        pointBorderColor: this.dashletService.getSeverityColor(0),
        pointBackgroundColor: this.dashletService.getSeverityColor(0),
        pointStyle: 'rect',
        pointRadius: 3,
        pointHoverRadius: 3
    };

    private unknownDataset = {
        label: 'Good',
        data: [],
        borderColor: 'white',
        backgroundColor: this.dashletService.getSeverityColor(-1),
        pointBorderColor: this.dashletService.getSeverityColor(-1),
        pointBackgroundColor: this.dashletService.getSeverityColor(-1),
        pointStyle: 'rect',
        pointRadius: 3,
        pointHoverRadius: 3
    };
    chartData: any = {};
    smallChartOptions: any = {
        scales: {
            x: {
                type: 'time',
                max: null,
                min: null,
                grid: {
                    display: false
                },
                time: {
                    unit: 'minute',
                    displayFormats: {
                        minute: 'HH:mm'
                    },
                    stepSize: 6
                },
                ticks: {
                    fontSize: 10.2,
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0
                }
            },
            y: {
                type: 'linear',
                min: 0,
                max: 5,
                grid: {
                    display: false
                },
                ticks: {
                    fontSize: 10.2,
                    beginAtZero: true,
                    callback: function (value) {
                        if (value % 1 === 0) {
                            return value;
                        }
                    },
                    suggestedMin: 1,
                    max: 6,
                    stepSize: 0.5,
                    autoSkip: false
                }
            }
        },
        animation: {
            duration: 0
        },
        hover: {
            animationDuration: 0
        },
        interaction: {
            mode: 'nearest',
            axis: 'xy',
            intersect: true
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: context => {
                        const scoreCount = context.dataset.data.reduce((accumulator, currentValue: MosDataPoint) => {
                            //Make sure the info has the same time-stamp and score
                            if (
                                currentValue.y === context.raw.y &&
                                currentValue.x.toString() === context.raw.x.toString()
                            )
                                return accumulator + currentValue.total;
                            return accumulator;
                        }, 0);
                        //to avoid duplicate tooltips, check if tooltip has already been shown
                        if (context.raw.y.toString() in this.toolTipDuplicateRecords) {
                            return '';
                        }
                        this.toolTipDuplicateRecords[context.raw.y.toString()] = scoreCount.toString();
                        return context.raw.y.toString() + ', Stream count: ' + scoreCount.toString();
                    },
                    footer: tipItem => {
                        //Reset the duplicate checker. Done in the footer since it runs after all tooltips are processed.
                        this.toolTipDuplicateRecords = {};
                        let string = [];
                        string.push(
                            tipItem[tipItem.length - 1].raw.x.toString().split(' ').slice(4).join(' ').split('(')[0]
                        );
                        string.push('(' + tipItem[0].raw.x.toString().split(' ').slice(4).join(' ').split('(')[1]);
                        return string;
                    }
                }
            },
            legend: {
                display: false,
                position: 'bottom'
            }
        }
    };

    largeChartOptions: any = {
        scales: {
            x: {
                type: 'time',
                max: null,
                min: null,
                grid: {
                    display: false
                },
                time: {
                    unit: 'minute',
                    displayFormats: {
                        minute: 'HH:mm'
                    },
                    stepSize: 6
                },
                ticks: {
                    fontSize: 10.2,
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0
                }
            },
            y: {
                type: 'linear',
                min: 0,
                max: 5,
                grid: {
                    display: false
                },
                beginAtZero: true,
                ticks: {
                    fontSize: 10.2,
                    callback: function (value) {
                        if (value % 1 === 0) {
                            return value;
                        }
                    },
                    suggestedMin: 1,
                    max: 6,
                    stepSize: 0.5,
                    autoSkip: false
                }
            }
        },
        animation: {
            duration: 0
        },
        hover: {
            animationDuration: 0
        },
        interaction: {
            mode: 'nearest',
            axis: 'xy',
            intersect: true
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: context => {
                        const scoreCount = context.dataset.data.reduce((accumulator, currentValue: MosDataPoint) => {
                            //Make sure the info has the same time-stamp and score
                            if (
                                currentValue.y === context.raw.y &&
                                currentValue.x.toString() === context.raw.x.toString()
                            )
                                return accumulator + currentValue.total;
                            return accumulator;
                        }, 0);
                        //to avoid duplicate tooltips, check if tooltip has already been shown
                        if (context.raw.y.toString() in this.toolTipDuplicateRecords) {
                            return '';
                        }
                        this.toolTipDuplicateRecords[context.raw.y.toString()] = scoreCount.toString();
                        return context.raw.y.toString() + ', Stream count: ' + scoreCount.toString();
                    },
                    footer: tipItem => {
                        //Reset the duplicate checker. Done in the footer since it runs after all tooltips are processed.
                        this.toolTipDuplicateRecords = {};
                        let string = [];
                        string.push(
                            tipItem[tipItem.length - 1].raw.x.toString().split(' ').slice(4).join(' ').split('(')[0]
                        );
                        string.push('(' + tipItem[0].raw.x.toString().split(' ').slice(4).join(' ').split('(')[1]);
                        return string;
                    }
                }
            },
            legend: {
                display: false,
                position: 'bottom'
            }
        }
    };

    constructor(
        public dashletService: DashletService,
        private store$: Store<AppState>,
        private settingsService: DashletSettingsService
    ) {
        this.chartData = {
            datasets: [this.goodDataset, this.satisfactoryDataset, this.poorDataset, this.unknownDataset]
        };
    }

    ngOnInit() {
        if (this.dashlet.configured) {
            this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
            this.subscription.add(
                this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                    if (logo) {
                        this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                    }
                })
            );
        }

        this.subscription.add(
            this.dashlet.onDataUpdated.subscribe(() => {
                this.updateChartData(false, this.clearHigh, this.clearAverage, this.clearPoor, this.clearUnknown);
            })
        );

        this.dashlet.equipmentLoaded.subscribe(() => {
            if (this.hasPreviousSubscriptions) {
                this.subscriptionDocument.unsubscribe();
                this.subscriptionDocument = new Subscription();
            }
            this.subscribeToDocument();
        });

        this.subscribeToDocument();
        this.updateChartData(true);
    }

    private subscribeToDocument(): void {
        if (this.dashlet.equipmentIds && this.dashlet.locationId && this.dashlet.customer.customerId) {
            this.dashlet.resetData();
            this.subscriptionDocument.add(
                this.settingsService.getEquipment(this.dashlet.locationId, 'ACM').subscribe(receivers => {
                    this.settingsService.getEquipment(this.dashlet.locationId, 'ACM').subscribe(receivers => {
                        receivers.forEach(acmId => {
                            this.store$.dispatch(
                                Actions.SubscribeToRealTimeService({
                                    equipmentId: acmId['value'],
                                    command: this.dashlet.commandTypeIdIpnrLookup
                                })
                            );
                            this.store$.dispatch(
                                Actions.GetNotifyCommonEntitys({
                                    equipmentId: acmId['value'],
                                    commandTypeId: this.dashlet.commandTypeIdIpnrLookup
                                })
                            );

                            this.subscriptionDocument.add(
                                this.store$
                                    .pipe(
                                        select(
                                            selectDataFromCommonEntity(
                                                acmId['value'] + this.dashlet.commandTypeIdIpnrLookup
                                            )
                                        )
                                    )
                                    .subscribe(data => {
                                        if (data) this.dashlet.ipnrMap.updateMap(data);
                                    })
                            );
                        });
                    });
                })
            );
            const key = this.dashlet.locationId + ':' + this.dashlet.commandTypeIdLync;
            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: this.dashlet.locationId,
                    command: this.dashlet.commandTypeIdLync
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: this.dashlet.locationId,
                    commandTypeId: this.dashlet.commandTypeIdLync
                })
            );
            this.subscriptionDocument.add(
                this.store$
                    .pipe(select(selectDataFromCommonEntity(this.dashlet.locationId + this.dashlet.commandTypeIdLync)))
                    .subscribe(data => {
                        if (data) {
                            this.dashlet.processLyncData(key, data);
                        }
                    })
            );

            this.dashlet.equipmentIds.forEach(equipmentId => {
                const key = equipmentId + ':' + this.dashlet.commandTypeIdRtcpNew;
                this.store$.dispatch(
                    Actions.SubscribeToRealTimeService({
                        equipmentId: equipmentId,
                        command: this.dashlet.commandTypeIdRtcpNew
                    })
                );
                this.store$.dispatch(
                    Actions.GetNotifyCommonEntitys({
                        equipmentId: equipmentId,
                        commandTypeId: this.dashlet.commandTypeIdRtcpNew
                    })
                );
                this.subscriptionDocument.add(
                    this.store$
                        .pipe(select(selectDataFromCommonEntity(equipmentId + this.dashlet.commandTypeIdRtcpNew)))
                        .subscribe(data => {
                            if (data) {
                                this.dashlet.processRtcpDataNew(key, data);
                            }
                        })
                );
            });
            this.hasPreviousSubscriptions = true;
        }
    }
    ngOnDestroy() {
        this.dashlet.dispose();
        this.subscription.unsubscribe();
        this.subscriptionDocument.unsubscribe();
    }

    private updateChartData(
        clear?: boolean,
        clearHigh?: boolean,
        clearAverage?: boolean,
        clearPoor?: boolean,
        clearUnknown?: boolean
    ) {
        const clearVal = !this.dashlet || clear ? [] : null;
        const clearHighVal = !this.dashlet || clearHigh ? [] : null;
        const clearAverageVal = !this.dashlet || clearAverage ? [] : null;
        const clearPoorVal = !this.dashlet || clearPoor ? [] : null;
        const clearUnknownVal = !this.dashlet || clearUnknown ? [] : null;

        this.goodDataset.data = clearVal || clearHighVal || this.dashlet.mosDataGood;
        this.satisfactoryDataset.data = clearVal || clearAverageVal || this.dashlet.mosDataSatisfactory;
        this.poorDataset.data = clearVal || clearPoorVal || this.dashlet.mosDataPoor;
        this.unknownDataset.data = clearVal || clearUnknownVal || this.dashlet.mosDataUnknown;

        const max = new Date();
        max.setSeconds(Math.ceil(max.getSeconds() / 30) * 30, 0);

        let min = new Date(max);

        if (this.dashlet) {
            if (this.dashlet.getSize().id === 0) {
                min.setMinutes(min.getMinutes() - 30);

                this.smallChartOptions.scales.x.min = min;
                this.smallChartOptions.scales.x.max = max;
            } else if (this.dashlet.getSize().id === 1) {
                min.setMinutes(min.getMinutes() - 60);

                this.largeChartOptions.scales.x.min = min;
                this.largeChartOptions.scales.x.max = max;
            }
        }

        if (this.chart) this.chart.updateChart();
        if (this.largeChart) this.largeChart.updateChart();
    }

    toggleHigh() {
        this.clearHigh = !this.clearHigh;
        this.dashlet.onDataUpdated.next(null);
    }

    toggleAverage() {
        this.clearAverage = !this.clearAverage;
        this.dashlet.onDataUpdated.next(null);
    }

    togglePoor() {
        this.clearPoor = !this.clearPoor;
        this.dashlet.onDataUpdated.next(null);
    }

    toggleUnknown() {
        this.clearUnknown = !this.clearUnknown;
        this.dashlet.onDataUpdated.next(null);
    }
}
