import {
    DashletSettingDropdownGrouped,
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingText
} from '@models/index';
import { DashletSettingsService } from '@services/index';

export class DashletSettingsFormIPNRStatus extends DashletSettingsForm {
    private customer!: DashletSettingDropdown;
    private location!: DashletSettingDropdown;
    private equipment!: DashletSettingDropdown;
    private fromIPNR!: DashletSettingDropdown;
    private toIPNR!: DashletSettingDropdown;
    constructor(private readonly settingsService: DashletSettingsService) {
        super();

        this.init();
        this.loadCustomers();
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);

        if (this.customer.value) {
            this.loadLocations(this.customer.value);
        }
        if (this.location.value) {
            this.loadEquipment(this.location.value);
            if (this.equipment.value) {
                this.loadNetworkRegions(this.equipment.value);
            }
        }
    }
    private loadNetworkRegions(equipment: string): void {
        this.enableAndLoading(this.fromIPNR);
        this.enableAndLoading(this.toIPNR);
        this.settingsService.getIPNRsByEquipment(this.customer.value!, equipment).subscribe(
            trunks => {
                this.fromIPNR.items = trunks;
                this.toIPNR.items = trunks;
                this.fromIPNR.loadingComplete();
                this.toIPNR.loadingComplete();
            },
            () => {
                this.fromIPNR.loadingComplete();
                this.fromIPNR.disabled = true;
                this.toIPNR.loadingComplete();
                this.toIPNR.disabled = true;
                // show error notification here
            }
        );
    }
    private init(): void {
        const equipmentGroup = new DashletSettingsGroup();
        const s: any = (equipmentGroup.settings = []);

        const customer = (this.customer = new DashletSettingDropdown());
        customer.label = 'Customer';
        customer.stateKey = 'customer';
        customer.disabled = true;
        customer.change.subscribe(() => {
            if (!this.location.value) this.clearAndDisable(this.location);
            if (!this.equipment.value) this.clearAndDisable(this.equipment);
            if (!this.fromIPNR.value) this.clearAndDisable(this.fromIPNR);
            if (!this.toIPNR.value) this.clearAndDisable(this.toIPNR);
            this.loadLocations(customer.value!);
        });
        s.push(customer);

        const location = (this.location = new DashletSettingDropdown());
        location.label = 'Location';
        location.stateKey = 'location';
        location.disabled = true;
        location.change.subscribe(() => {
            if (!this.equipment.value) this.clearAndDisable(this.equipment);
            if (!this.fromIPNR.value) this.clearAndDisable(this.fromIPNR);
            if (!this.toIPNR.value) this.clearAndDisable(this.toIPNR);
            this.loadEquipment(location.value!);
        });
        s.push(location);

        const equipment = (this.equipment = new DashletSettingDropdown());
        equipment.label = 'Equipment';
        equipment.stateKey = 'equipment';
        equipment.disabled = true;
        equipment.change.subscribe(() => {
            if (!this.fromIPNR.value) this.clearAndDisable(this.fromIPNR);
            if (!this.toIPNR.value) this.clearAndDisable(this.toIPNR);
            this.loadNetworkRegions(equipment.value!);
        });
        s.push(equipment);

        const fromIPNR = (this.fromIPNR = new DashletSettingDropdown());
        fromIPNR.label = 'From Region';
        fromIPNR.stateKey = 'fromIPNR';
        fromIPNR.disabled = true;
        s.push(fromIPNR);

        const toIPNR = (this.toIPNR = new DashletSettingDropdown());
        toIPNR.label = 'To Region';
        toIPNR.stateKey = 'toRegion';
        toIPNR.disabled = true;
        s.push(toIPNR);

        const customName = new DashletSettingText(40);
        customName.label = 'Custom Dashlet Name';
        customName.stateKey = 'customName';
        s.push(customName);

        const group = new DashletSettingsGroup();
        group.groups = [equipmentGroup];
        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);
        this.settingsService.getUserAssociatedCustomers().subscribe(
            customers => {
                this.customer.items = customers;
                this.customer.loadingComplete();
            },
            () => {
                this.customer.loadingComplete();
                this.customer.disabled = true;
                // show error notification here
            }
        );
    }

    private loadLocations(customer: string): void {
        this.enableAndLoading(this.location);
        this.settingsService.getLocations(customer).subscribe(
            locations => {
                this.location.items = locations;
                this.location.loadingComplete();
            },
            () => {
                this.location.loadingComplete();
                this.location.disabled = true;
                // show error notification here
            }
        );
    }

    private loadEquipment(location: string): void {
        this.enableAndLoading(this.equipment);
        this.settingsService.getEquipment(location, 'ACM').subscribe(
            equipment => {
                this.equipment.items = equipment;
                this.equipment.loadingComplete();
            },
            () => {
                this.equipment.loadingComplete();
                this.equipment.disabled = true;
                // show error notification here
            }
        );
    }

    private clearAndDisable(dropdown: DashletSettingDropdown | DashletSettingDropdownGrouped) {
        dropdown.disabled = true;
        dropdown.items = [];
        dropdown.value = null;
    }

    private enableAndLoading(dropdown: DashletSettingDropdown | DashletSettingDropdownGrouped) {
        dropdown.disabled = false;
        dropdown.loadingBegin();
    }
}
