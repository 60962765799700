import * as ProcessDiskActions from '@actions/process-disk-data/process-disk-data.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService, TimeoutService } from '@services/index';
import { RealTimeNotify } from '@models/index';

@Injectable()
export class ProcessDiskDataEffects {
    constructor(
        private actions$: Actions,
        private realtimeGatewayService: RealtimeGatewayService,
        private timeoutService: TimeoutService
    ) {}

    getNotifyProcessDisk$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessDiskActions.GetNotifyProcessDiskData),
            switchMap(action =>
                this.realtimeGatewayService.observe(action.equipmentId, action.commandTypeId).pipe(
                    catchError(err => of(ProcessDiskActions.GetNotifyProcessDiskDataFailure({ error: err }))),
                    mergeMap((data: RealTimeNotify) => [
                        ProcessDiskActions.GetNotifyProcessDiskDataSuccess({ data }),
                        ProcessDiskActions.GetProcessDiskData({ ...data })
                    ])
                )
            )
        )
    );

    getProcessDisk$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessDiskActions.GetProcessDiskData),
            mergeMap(action =>
                this.realtimeGatewayService.getDocument(action.equipmentId, action.commandTypeId).pipe(
                    map(data =>
                        ProcessDiskActions.GetProcessDiskDataSuccess({
                            data: { data, equipmentId: action.equipmentId }
                        })
                    ),
                    catchError(err => of(ProcessDiskActions.GetProcessDiskDataFailure({ error: err })))
                )
            )
        )
    );

    getProcessDiskTimer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessDiskActions.GetProcessDiskDataTimer),
            switchMap(action =>
                this.timeoutService.observeTimerNew(action.equipmentId, action.command).pipe(
                    catchError(err => of(ProcessDiskActions.GetProcessDiskDataTimerFailure({ error: err }))),
                    map((data: any) =>
                        ProcessDiskActions.GetProcessDiskDataTimerSuccess({
                            data: { data, equipmentId: action.equipmentId }
                        })
                    )
                )
            )
        )
    );
}
