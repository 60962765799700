import { Customer, Dashlet } from '@models/index';
import { Subject } from 'rxjs';

export interface DialPeer {
    severity: number;
    tagId: number;
    callsIn: number;
    callsOut: number;
    admin: string;
    oper: string;
    busyOut: string;
}

export class DashletCiscoDialPeerSummary extends Dashlet {
    public readonly commandTypeIdDailPeerRAGFields = '4C217660-64A3-4D2F-B389-4BCE7C122FD4';
    public readonly commandTypeIdDialPeerCallCounts = 'E4CE2F15-CEB4-4021-854A-2628CD0A3DD8';
    public equipmentId: string;
    public dialPeerGroups: number[];
    public customer: Customer;
    public ragDataProcessed: boolean = false;
    public dialPeers: DialPeer[] = [];
    public dialPeerTagsLookup: string[] = [];
    public sortColumn: string = 'severity';
    public sortDesc: boolean = true;
    public refreshFlag: boolean = true;
    public height: string = '100px';

    public dialPeerReceived: Subject<null> = new Subject();

    constructor() {
        super();

        this.sizes = [
            {
                id: 0,
                label: 'Small',
                cols: 5,
                rows: 5
            },
            {
                id: 1,
                label: 'Medium',
                cols: 5,
                rows: 7
            },
            {
                id: 2,
                label: 'Large',
                cols: 5,
                rows: 9
            }
        ];
        this.applySize(0);
    }

    public applySettings(v: { [key: string]: any }): void {
        super.applySettings(v);

        //read settings
        const dialPeerGroups = v.dialPeerGroups || [];
        this.configured = v.customer && v.location && v.equipment && dialPeerGroups.length > 0;
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        this.equipmentId = v.equipment && v.equipment.value ? v.equipment.value : null;
        this.generatedNameTag = this.configured ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;

        //parse dialPeers array
        this.dialPeerGroups = dialPeerGroups.map(i => +i.value);

        this.createEmptyList(dialPeerGroups);

        this.refreshFlag = true;
        this.applySizeExpansion(0, 0);
        this.dialPeerReceived.next(null);
    }

    public dispose(): void {}

    public resetData(): void {
        // reset list
        this.dialPeers = [];
    }

    private createEmptyList(data: any): void {
        this.dialPeers = [];
        this.dialPeerTagsLookup = [];
        data.forEach(item => {
            this.dialPeers.push({
                tagId: item.value.toString(),
                callsIn: 0,
                callsOut: 0,
                severity: 0,
                admin: '-',
                oper: '-',
                busyOut: '-'
            });
        });
    }

    // <-- PROCESS DATA SECTION --> //
    public processCallCountsPerTag(data: any): void {
        this.dialPeerTagsLookup = [];
        if (this.ragDataProcessed) {
            const row = data[0].data;
            if (row != null) {
                row.forEach(element => {
                    let index = this.dialPeers.findIndex(item => element.cvCallIndex === item.tagId);
                    if (index != -1) {
                        // only if id has been selected
                        this.dialPeers[index].callsIn = parseInt(element.cvCallVolPeerIncomingCalls);
                        this.dialPeers[index].callsOut = parseInt(element.cvCallVolPeerOutgoingCalls);
                    }
                    this.dialPeerTagsLookup.push('countToken');
                    this.dialPeerReceived.next(null);
                });
            }
        }
    }

    public processTagRag(data: any): void {
        const row = data[0].data;
        if (row != null) {
            row.forEach(element => {
                let index = this.dialPeers.findIndex(item => element.Tag === item.tagId);
                if (index != -1) {
                    // only if id has been selected
                    this.dialPeers[index].severity = this.getDialPeerRagSev(element);
                    if (this.dialPeers[index].severity > 0) {
                        this.dialPeers[index].admin = element.AdministrativeState;
                        this.dialPeers[index].oper = element.OperationalState;
                        this.dialPeers[index].busyOut = element.KeepAlive;
                    } else {
                        this.dialPeers[index].admin = '-';
                        this.dialPeers[index].oper = '-';
                        this.dialPeers[index].busyOut = '-';
                    }
                }
            });
        }
        this.dialPeers.sort((a, b) => (a.severity < b.severity ? 1 : b.severity < a.severity ? -1 : 0));
        this.dialPeerReceived.next(null);
    }

    private getDialPeerRagSev(item: any): number {
        if (
            item.KeepAlive === 'active' ||
            (item.OperationalState === 'up' && item.AdministrativeState === 'up' && item.KeepAlive !== 'busyout')
        ) {
            return 1; // green
        }

        if (
            item.OperationalState === 'down' &&
            item.AdministrativeState === 'down' &&
            item.KeepAlive !== 'active' &&
            item.KeepAlive !== 'busyout'
        ) {
            return 2; // amber
        }

        if (
            item.KeepAlive === 'busyout' ||
            (item.OperationalState === 'down' && item.AdministrativeState === 'up' && item.KeepAlive !== 'active')
        ) {
            return 3; // red
        }

        return 0; // no record found for selected item - grey it out
    }
}
