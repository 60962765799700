import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

export const GetNotifyProcessMaintenanceBackupData = createAction(
  '[ProcessMaintenanceBackupData] Get ProcessMaintenanceBackupData',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetNotifyProcessMaintenanceBackupDataSuccess = createAction(
  '[ProcessMaintenanceBackupData] Get ProcessMaintenanceBackupData Success',
  props<{ data: RealTimeNotify }>()
);

export const GetNotifyProcessMaintenanceBackupDataFailure = createAction(
  '[ProcessMaintenanceBackupData] Get ProcessMaintenanceBackupData Failure',
  props<{ error: any }>()
);


export const GetProcessMaintenanceBackupData = createAction(
  '[ProcessMaintenanceBackupData] Get ProcessMaintenanceBackupData',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetProcessMaintenanceBackupDataSuccess = createAction(
  '[ProcessMaintenanceBackupData] Get ProcessMaintenanceBackupData Success',
  props<{ data: any}>()
);

export const GetProcessMaintenanceBackupDataFailure = createAction(
  '[ProcessMaintenanceBackupData] Get ProcessMaintenanceBackupData Failure',
  props<{ error: any }>()
);

export const GetHistoricProcesssMaintenanceBackupData = createAction(
  '[ProcessAdminBackupData] Get HistoricProcessAdminBackupData',
  props<{ equipmentId: string, commandTypeId: string, from?: Date | string, to?: Date | string, max?: number | null }>()
);

export const GetHistoricProcesssMaintenanceBackupDataSuccess = createAction(
  '[ProcessAdminBackupData] Get HistoricProcessAdminBackupData Success',
  props<{ data: any }>()
);

export const GetHistoricProcesssMaintenanceBackupDataFailure = createAction(
  '[ProcessAdminBackupData] Get HistoricProcessAdminBackupData Failure',
  props<{ error: any }>()
);