/* eslint-disable no-undef */
import {
    Customer,
    Dashlet,
    Equipment,
    Location,
    Threshold
} from '@models/index';
import { Subject } from 'rxjs';
import {
    CommandsRunDataModel
} from '../dataObjects/interfaces/customScripts.model';

export class DashletSystemHealthLinuxServer extends Dashlet {
    public readonly commandTypeIdServer = '5A2171BF-3916-4AF7-A33E-3E713DB3E65C'; //host name

    readonly interval = 30;

    equipment: Equipment;
    location: Location;
    customer: Customer;
    lshName: string;
    uptimeMs: number;
    loggedInUsers: number;
    showSystemChart = false;
    showNetworkConnectChart = false;
    showCustomScripts = false;

    commandsRunData: CommandsRunDataModel[] = [];
    commandsRunDataLoading = true;

    thresholds: Threshold[] = [];

    private chartDataUpdated: Subject<null> = new Subject();
    get onChartDataUpdated() {
        return this.chartDataUpdated;
    }

    constructor() {
        super();

        //sizing
        this.sizes = [
            {
                id: 0,
                label: 'Standard',
                cols: 5,
                rows: 6
            }
        ];
        this.applySize(0);

        //init data
        this.resetData();
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        //read settings object
        this.configured = v.customer && v.location && v.equipment;
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        if (v.location) {
            this.location = new Location(v.location.value, v.location.label);
        } else {
            this.location = new Location('', '');
        }
        if (v.equipment) {
            this.equipment = new Equipment(v.equipment.value, v.equipment.label);
        } else {
            this.equipment = new Equipment('', '');
        }
        this.generatedNameTag = this.configured ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;
        this.lshName = this.configured ? v.equipment.label : null;

        this.showSystemChart = v.showSystemChart || false;
        this.showNetworkConnectChart = v.showNetworkConnectChart || false;
        this.showCustomScripts = v.showCustomScripts || false;

        //apply size
        let h = 0;
        if (this.showSystemChart) h += 2;
        if (this.showNetworkConnectChart) h += 2;

        if (this.showCustomScripts) h += 4;
        this.applySizeExpansion(0, h);

        //sub realtime feed
        //   this.subData();
    }

    resetData() {
        this.lshName = null;
        this.uptimeMs = null;
    }

    dispose() {}
}
