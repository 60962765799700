export class SBCServiceStatus {
	private _application: string;
	private _name: string;
	private _status: string;
	private _stateStart: string;

	constructor(application: string, name: string, status: string, stateStart: string) {
		this._application = application;
		this._name = name;
		this._status = status;
		this._stateStart = stateStart;
	}

	public update(name: string, status: string, stateStart: string) {
		this._name = name;
		this._status = status;
		this._status = status;
		this._stateStart = stateStart;
	}

	public updateFromObject(serviceStatus: SBCServiceStatus) {
		this._name = serviceStatus.name;
		this._status = serviceStatus.status;
		this._status = serviceStatus.status;
		this._stateStart = serviceStatus.stateStart;
	}

	/**
	 * Getter application
	 * @return {string}
	 */
	public get application(): string {
		return this._application;
	}

	/**
	 * Getter name
	 * @return {string}
	 */
	public get name(): string {
		return this._name;
	}

	/**
	 * Getter status
	 * @return {string}
	 */
	public get status(): string {
		return this._status;
	}

	/**
	 * Getter stateStart
	 * @return {string}
	 */
	public get stateStart(): string {
		return this._stateStart;
	}

	/**
	 * Setter application
	 * @param {string} value
	 */
	public set application(value: string) {
		this._application = value;
	}

	/**
	 * Setter name
	 * @param {string} value
	 */
	public set name(value: string) {
		this._name = value;
	}

	/**
	 * Setter status
	 * @param {string} value
	 */
	public set status(value: string) {
		this._status = value;
	}

	/**
	 * Setter stateStart
	 * @param {string} value
	 */
	public set stateStart(value: string) {
		this._stateStart = value;
	}
}
