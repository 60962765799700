/* eslint-disable no-undef */
import {
    ACDConnectionData,
    BackupData,
    Customer,
    Dashlet,
    Equipment,
    Location,
    SupervisorData,
    Threshold,
    WFMHistoricData,
    WFMRealTimeData
} from '@models/index';
import { Subject } from 'rxjs';

export class DashletSystemHealthCMS extends Dashlet {
    public readonly commandTypeIdAdminBackup = 'B4C7EAE0-2A0C-4B07-A4FB-F3C57DC646F0';
    public readonly commandTypeIdMaintenanceBackup = '5ABD0D41-F3EB-4770-970B-54E40EE5B3AA';
    public readonly commandTypeIdACD = 'D11E5380-638E-4662-A2D4-1FC2123F2FAA';
    public readonly commandTypeIdACDCalls = '45E22620-6EB9-403E-8A70-96C2D94AFFD4';
    public readonly commandTypeIdSupervisorLicense = 'E8CC22ED-9DF6-4840-8D0D-9318A2A72A55';
    public readonly commandTypeIdWFMRealTime = '36CDF1BA-1D4A-494B-B65D-A0F79689260D';
    public readonly commandTypeIdWFMHistoric = 'CA575E17-5A18-4C21-ACF2-C21E99DF325A';

    readonly interval = 30;
    uptimeMs!: number;
    loggedInUsers!: number;

    acdConnectionDataList!: ACDConnectionData[];
    supervisorData!: SupervisorData;
    backupDataAdmin!: BackupData;
    backupDataMaintenance!: BackupData;
    wfmRealTimeDataList!: WFMRealTimeData[];
    wfmHistoricDataList!: WFMHistoricData[];
    equipment!: Equipment;
    customer!: Customer;
    location!: Location;

    thresholds: Threshold[] = [];

    showSystemChart = false;
    showNetworkGraph = false;
    showWFMStatus = false;
    showCustomScripts = false;

    commandsRunData: any = [];
    commandsRunDataLoading = true;

    public chartDataUpdated: Subject<null> = new Subject();
    get onChartDataUpdated() {
        return this.chartDataUpdated;
    }

    constructor() {
        super();

        // sizing
        this.sizes = [
            {
                id: 0,
                label: 'Standard',
                cols: 5,
                rows: 10
            }
        ];
        this.applySize(0);

        // init data
        this.resetData();
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        // read settings object
        this.configured = v.customer && v.location && v.equipment;

        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        if (v.location) {
            this.location = new Location(v.location.value, v.location.label);
        } else {
            this.location = new Location('', '');
        }
        if (v.equipment) {
            this.equipment = new Equipment(v.equipment.value, v.equipment.label);
        } else {
            this.equipment = new Equipment('', '');
        }

        this.generatedNameTag = this.configured ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;

        this.showSystemChart = v.showSystemChart || false;
        this.showNetworkGraph = v.showNetworkGraph || false;
        this.showWFMStatus = v.showWFMStatus || false;
        this.showCustomScripts = v.showCustomScripts || false;

        // apply size
        let h = 0;
        if (this.showSystemChart) {
            h += 1.9;
        }
        if (this.showNetworkGraph) {
            h += 2.1;
        }
        if (this.showWFMStatus) {
            h += 1.2;
        }
        if (this.showCustomScripts) h += 4;
        this.applySizeExpansion(0, h);
    }

    resetData() {
        this.uptimeMs = 0;
        this.loggedInUsers = 0;
        this.acdConnectionDataList = [];
        this.backupDataAdmin = new BackupData('', '', '', 0, '');
        this.backupDataMaintenance = new BackupData('', '', '', 0, '');
        this.supervisorData = new SupervisorData(0, 0, 0);
        this.wfmRealTimeDataList = [];
        this.wfmHistoricDataList = [];
        this.chartDataUpdated.next(null);
    }

    dispose() {}

    processWFMStatusHistoric(data: any[]) {
        for (const row of data[0].data) {
            if (
                row.Message.trim().toLowerCase().includes('ftp') &&
                row.Message.trim().toLowerCase().includes('session')
            ) {
                const index = this.wfmHistoricDataList.findIndex(e => row.WFOVendor === e.wfoVendor);
                if (index === -1) {
                    this.wfmHistoricDataList.push(
                        new WFMHistoricData(row.WFOVendor, row.Message, row.LogEntryDateTime)
                    );
                } else {
                    this.wfmHistoricDataList[index].update(row.WFOVendor, row.Message, row.LogEntryDateTime);
                }
            }
        }

        let errorMessage;
        const errorMessageArray: any[] = [];
        const index2: number = this.wfmHistoricDataList.length - 1;
        data[0].data.forEach((e: any) => {
            if (e.Message.includes('Session')) {
                errorMessageArray.push(e.Message.split('transport: ').pop());
            } else if (e.Message.trim().toLowerCase().includes('ftp')) {
                errorMessageArray.push(e.Message);
            }
        });
        errorMessage = errorMessageArray.join('\n');
        this.wfmHistoricDataList[index2].updateErrorMessage(errorMessage);
    }

    processWFMStatusRealTime(data: any[]) {
        for (const row of data[0].data) {
            const index = this.wfmRealTimeDataList.findIndex(e => row.WFOVendor === e.wfoVendor);
            if (index === -1) {
                this.wfmRealTimeDataList.push(new WFMRealTimeData(row.WFOVendor, row.LogMessage, row.LogEntryDateTime));
            } else {
                this.wfmRealTimeDataList[index].update(row.WFOVendor, row.LogMessage, row.LogEntryDateTime);
            }
        }
    }

    processSupervisorLicenses(data: any[]) {
        const row = data[0].data[0];
        this.supervisorData.update(row.Total, row.WebUser, row.WindowsUser);
    }

    processACD(data: any[]) {
        this.acdConnectionDataList = [];
        data[0].data.forEach((element: any) => {
            const acdConnectionData = new ACDConnectionData(
                element.ACDName,
                element.ApplicationState,
                element.Connection,
                element.DisplayDate,
                element.DisplayTime,
                element.SessionState,
                +element.Calls
            );
            this.acdConnectionDataList.push(acdConnectionData);
        });
    }

    processACDCalls(data: any[]) {
        if (this.acdConnectionDataList) {
            let i = 0;
            data.forEach(element => {
                this.acdConnectionDataList[i].calls = +element.data[element.data.length - 1].CallCount;
                i++;
            });
        }
    }

    processAdminBackupData(data: any[]) {
        const row = data[0].data[0];
        // fallback onto historic data if lastbackupstatus undefined
        if (
            (!this.backupDataAdmin.lastBackupStatus && row.LastBackupStatus) ||
            (Date.parse(row.DisplayDateTime) > Date.parse(this.backupDataAdmin.displayDateTime) && row.LastBackupStatus)
        ) {
            this.backupDataAdmin.update(row.Type, row.DisplayDateTime, row.LastBackupStatus, row.State, '');
        }
    }

    processMaintenanceBackupData(data: any[]) {
        const row = data[0].data[0];
        // fallback onto historic data if lastbackupstatus undefined
        if (
            (!this.backupDataMaintenance.lastBackupStatus && row.LastBackupStatus) ||
            (Date.parse(row.DisplayDateTime) > Date.parse(this.backupDataMaintenance.displayDateTime) &&
                row.LastBackupStatus)
        ) {
            this.backupDataMaintenance.update(row.Type, row.DisplayDateTime, row.LastBackupStatus, row.State, '');
        }
    }
}
