import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

export const GetNotifyProcessWorkflowData = createAction(
  '[ProcessWorkflowData] Get Notify ProcessWorkflowDatas',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetNotifyProcessWorkflowDataSuccess = createAction(
  '[ProcessWorkflowData] Get Notify ProcessWorkflowDatas Success',
  props<{ data: RealTimeNotify }>()
);

export const GetNotifyProcessWorkflowDataFailure = createAction(
  '[ProcessWorkflowData] Get Notify ProcessWorkflowDatas Failure',
  props<{ error: any }>()
);

export const GetProcessWorkflowData = createAction(
  '[ProcessWorkflowData] Get ProcessWorkflowDatas',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetProcessWorkflowDataSuccess = createAction(
  '[ProcessWorkflowData] Get ProcessWorkflowDatas Success',
  props<{ data: any}>()
);

export const GetProcessWorkflowDataFailure = createAction(
  '[ProcessWorkflowData] Get ProcessWorkflowDatas Failure',
  props<{ error: any }>()
);
