import * as ProcessUpTimeActions from '@actions/process-up-time/process-up-time.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService, TimeoutService } from '@services/index';
import { RealTimeNotify } from '@models/index';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class ProcessUpTimeEffects {
    constructor(
        private actions$: Actions,
        private realtimeGatewayService: RealtimeGatewayService,
        private timeoutService: TimeoutService
    ) {}

    getProcessUpTime$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessUpTimeActions.GetNotifyProcessUpTime),
            switchMap(action =>
                this.realtimeGatewayService.observe(action.equipmentId, action.command).pipe(
                    catchError(err => of(ProcessUpTimeActions.GetNotifyProcessUpTimeFailure({ error: err }))),
                    mergeMap((data: RealTimeNotify) => [
                        ProcessUpTimeActions.GetNotifyProcessUpTimeSuccess({ data }),
                        ProcessUpTimeActions.GetProcessUpTimeDocument({ ...data })
                    ])
                )
            )
        )
    );

    getProcessUpTimeTimer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessUpTimeActions.GetProcessUpTimeTimer),
            switchMap(action =>
                this.timeoutService.observeTimerNew(action.equipmentId, action.command).pipe(
                    catchError(err => of(ProcessUpTimeActions.GetProcessUpTimeTimerFailure({ error: err }))),
                    map((data: any) => {
                        return ProcessUpTimeActions.GetProcessUpTimeTimerSuccess({
                            data: { data, equipmentId: action.equipmentId }
                        });
                    })
                )
            )
        )
    );

    subscribeToRealTimeService$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProcessUpTimeActions.SubscribeToRealTimeService),
                tap(action => this.realtimeGatewayService.subscribe(action.equipmentId, action.command, action.idType))
            ),
        { dispatch: false }
    );

    unsubscribeToRealTimeService$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProcessUpTimeActions.UnsubscribeFromRealTimeService),
                tap(action => this.realtimeGatewayService.unsubscribe(action.equipmentId, action.command))
            ),
        { dispatch: false }
    );

    getProcessUpTimeDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessUpTimeActions.GetProcessUpTimeDocument),
            switchMap(action =>
                this.realtimeGatewayService.getDocument(action.equipmentId, action.commandTypeId).pipe(
                    map(data =>
                        ProcessUpTimeActions.GetProcessUpTimeDocumentSuccess({
                            data: { data, equipmentId: action.equipmentId }
                        })
                    ),
                    catchError(err => of(ProcessUpTimeActions.GetProcessUpTimeDocumentFailure({ error: err })))
                )
            )
        )
    );
}
