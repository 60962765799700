export class DataSet {
    label!: string;
    data!: any[];

    //line options https://www.chartjs.org/docs/latest/configuration/elements.html#line-configuration
    tension?: number;
    backgroundColor: string;
    borderWidth?: number;
    borderColor?: string;
    boderCapStyle?: string;
    borderDash?: number[];
    boderDashOffset?: number;
    borderJoinStyle?: string;
    capBezierPoints?: boolean;
    cubicInterpolationMode?: string;
    fill: boolean | string = false;
    stepped?: boolean;

    //point options https://www.chartjs.org/docs/latest/configuration/elements.html#point-configuration
    pointRadius?: number;
    pointStyle?: string;
    rotation?: number;
    hitRadius?: number;
    hoverRadius?: number;
    hoverBorderWidth?: number;
}
