import { createAction, props } from '@ngrx/store';

export const GetSystemHealthEquipments = createAction(
    '[GetSystemHealthEquipment] Get GetSystemHealthEquipments',
    props<{ entityId: string }>()
);

export const GetSystemHealthEquipmentsSuccess = createAction(
    '[GetSystemHealthEquipment] Get GetSystemHealthEquipments Success',
    props<{ data: any; entityId: string }>()
);

export const GetSystemHealthEquipmentsFailure = createAction(
    '[GetSystemHealthEquipment] Get GetSystemHealthEquipments Failure',
    props<{ error: any }>()
);
