import { Component, Injector, ElementRef } from '@angular/core';

import { DashletSettingDropdownGrouped } from '@models/index';

@Component({
    selector: 'dashlet-setting-dropdown-grouped',
    templateUrl: 'dashlet-setting-dropdown-grouped.component.html',
    styleUrls: ['./settings.component.scss']
})
export class DashletSettingDropdownGroupedComponent {
    data: DashletSettingDropdownGrouped | null = null;

    constructor(private injector: Injector) {
        this.data = this.injector.get('data');
    }

    public onModelChange(val: string): void {
        this.data!.value = val;
        this.data!.change.next(null);
    }
}
