import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { BrandingColors, HSL, LogoImage, MaterialColorPalette } from '@models/index';

@Injectable({
    providedIn: 'root'
})
export class BrandingService {
    constructor(protected http: HttpClient) {}

    public getCustomerLogo(entityId: string): Observable<LogoImage> {
        return this.http.get<LogoImage>(`${environment.centralApi}Branding/Customer/${entityId}/Icon`);
    }

    public getBusinessPartnerLogo(entityId: string): Observable<LogoImage> {
        return this.http.get<LogoImage>(`${environment.centralApi}Branding/BusinessPartner/${entityId}/Icon`);
    }

    public getBrandColors(entityId: string): Observable<BrandingColors> {
        return this.http.get<BrandingColors>(`${environment.centralApi}Branding/${entityId}/ColourScheme`);
    }

    public applyBrandingColors(primaryColor: string, secondaryColor: string) {
        let color = primaryColor;
        if (this.hexToHSL(primaryColor).saturation < 70) {
            color = secondaryColor;
        }

        const materialColors: MaterialColorPalette = this.generateMaterialColorPalette(color);
        this.assignValueToCSSVariable('--primary-50', materialColors.color50);
        this.assignValueToCSSVariable('--primary-100', materialColors.color100);
        this.assignValueToCSSVariable('--primary-200', materialColors.color200);
        this.assignValueToCSSVariable('--primary-300', materialColors.color300);
        this.assignValueToCSSVariable('--primary-400', materialColors.color400);
        this.assignValueToCSSVariable('--primary-500', materialColors.color500);
        this.assignValueToCSSVariable('--primary-600', materialColors.color600);
        this.assignValueToCSSVariable('--primary-700', materialColors.color700);
        this.assignValueToCSSVariable('--primary-800', materialColors.color800);
        this.assignValueToCSSVariable('--primary-900', materialColors.color900);
        this.assignValueToCSSVariable('--primary-A100', materialColors.A100);
        this.assignValueToCSSVariable('--primary-A200', materialColors.A200);
        this.assignValueToCSSVariable('--primary-A400', materialColors.A400);
        this.assignValueToCSSVariable('--primary-A700', materialColors.A700);
        this.assignValueToCSSVariable('--on-primary-color', this.generateContrastColor(materialColors.color500));
    }

    private generateMaterialColorPalette(color: string): MaterialColorPalette {
        const A500 = this.saturateColor(color);
        const palette: MaterialColorPalette = {
            color50: this.lightenColor(color, 0.88),
            color100: this.lightenColor(color, 0.69),
            color200: this.lightenColor(color, 0.5),
            color300: this.lightenColor(color, 0.3),
            color400: this.lightenColor(color, 0.154),
            color500: color,
            color600: this.darkenColor(color, 0.0625),
            color700: this.darkenColor(color, 0.146),
            color800: this.darkenColor(color, 0.23),
            color900: this.darkenColor(color, 0.375),
            A100: this.lightenColor(A500, 0.55),
            A200: this.lightenColor(A500, 0.34),
            A400: this.lightenColor(A500, 0.26),
            A700: this.lightenColor(A500, 0.21)
        };

        return palette;
    }

    private lightenColor(color: string, ammount: number): string {
        const colorHSL = this.hexToHSL(color);
        const lighter: HSL = {
            hue: colorHSL.hue,
            saturation: colorHSL.saturation - colorHSL.saturation / (ammount * 100),
            lightness: colorHSL.lightness + (100 - colorHSL.lightness) * ammount
        };

        return this.HSLToHex(lighter);
    }

    private darkenColor(color: string, ammount: number): string {
        const colorHSL = this.hexToHSL(color);
        const lighter: HSL = {
            hue: colorHSL.hue,
            saturation: colorHSL.saturation - colorHSL.saturation / (ammount * 100),
            lightness: colorHSL.lightness - colorHSL.lightness * ammount
        };

        return this.HSLToHex(lighter);
    }

    private saturateColor(color: string): string {
        const colorHSL = this.hexToHSL(color);
        const returnColor = { ...colorHSL, saturation: 100 };
        return this.HSLToHex(returnColor);
    }

    private hexToHSL(hex: string): HSL {
        // Convert hex to RGB first
        let r = 0,
            g = 0,
            b = 0;
        if (hex.length === 4) {
            r = Number('0x' + hex[1] + hex[1]);
            g = Number('0x' + hex[2] + hex[2]);
            b = Number('0x' + hex[3] + hex[3]);
        } else if (hex.length === 7) {
            r = Number('0x' + hex[1] + hex[2]);
            g = Number('0x' + hex[3] + hex[4]);
            b = Number('0x' + hex[5] + hex[6]);
        }
        // Then to HSL
        r /= 255;
        g /= 255;
        b /= 255;
        let cmin = Math.min(r, g, b),
            cmax = Math.max(r, g, b),
            delta = cmax - cmin,
            h = 0,
            s = 0,
            l = 0;

        if (delta === 0) h = 0;
        else if (cmax === r) h = ((g - b) / delta) % 6;
        else if (cmax === g) h = (b - r) / delta + 2;
        else h = (r - g) / delta + 4;

        h = Math.round(h * 60);

        if (h < 0) h += 360;

        l = (cmax + cmin) / 2;
        s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
        s = +(s * 100).toFixed(1);
        l = +(l * 100).toFixed(1);
        return {
            hue: h,
            saturation: s,
            lightness: l
        };
    }

    private HSLToHex(color: HSL): string {
        color.saturation /= 100;
        color.lightness /= 100;

        let c = (1 - Math.abs(2 * color.lightness - 1)) * color.saturation,
            x = c * (1 - Math.abs(((color.hue / 60) % 2) - 1)),
            m = color.lightness - c / 2,
            r = 0,
            g = 0,
            b = 0;

        if (0 <= color.hue && color.hue < 60) {
            r = c;
            g = x;
            b = 0;
        } else if (60 <= color.hue && color.hue < 120) {
            r = x;
            g = c;
            b = 0;
        } else if (120 <= color.hue && color.hue < 180) {
            r = 0;
            g = c;
            b = x;
        } else if (180 <= color.hue && color.hue < 240) {
            r = 0;
            g = x;
            b = c;
        } else if (240 <= color.hue && color.hue < 300) {
            r = x;
            g = 0;
            b = c;
        } else if (300 <= color.hue && color.hue < 360) {
            r = c;
            g = 0;
            b = x;
        }
        // Having obtained RGB, convert channels to hex
        let red = Math.round((r + m) * 255).toString(16);
        let green = Math.round((g + m) * 255).toString(16);
        let blue = Math.round((b + m) * 255).toString(16);

        // Prepend 0s, if necessary
        if (red.length === 1) red = '0' + red;
        if (green.length === 1) green = '0' + green;
        if (blue.length === 1) blue = '0' + blue;

        return '#' + red + green + blue;
    }

    private generateContrastColor(color: string): string {
        let r = 0,
            g = 0,
            b = 0;
        if (color.length === 4) {
            r = Number('0x' + color[1] + color[1]);
            g = Number('0x' + color[2] + color[2]);
            b = Number('0x' + color[3] + color[3]);
        } else if (color.length === 7) {
            r = Number('0x' + color[1] + color[2]);
            g = Number('0x' + color[3] + color[4]);
            b = Number('0x' + color[5] + color[6]);
        }
        if (r * 0.299 + g * 0.587 + b * 0.114 > 186) {
            // TODO remove when config page is complete
            // Makes logo dark grey
            this.assignValueToCSSVariable('--logo-filter', 'grayscale(2) brightness(0)');
            return '#222222';
        } else {
            // TODO remove when config page is complete
            //Makes logo white
            this.assignValueToCSSVariable('--logo-filter', 'grayscale(2) brightness(100)');
            return '#ffffff';
        }
    }

    private assignValueToCSSVariable(variableName: string, value: string) {
        // eslint-disable-next-line no-undef
        document.documentElement.style.setProperty(variableName, value);
    }
}
