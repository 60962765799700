import {
    CMSMosData_Agent,
    CMSMosData_Skill,
    CMSMosData_VDN,
    CodecDataMap,
    Customer,
    Dashlet,
    DSCPDataMap,
    IPNRDataMap,
    Layer3_DSCPCategorized,
    Layer3Categorized,
    MOSSummaryBreakdownData,
    MOSSummaryData,
    Threshold
} from '@models/index';
import { DashletSettingsService } from '@services/index';
import { Subject, Subscription } from 'rxjs';

export class VqmMosMap {
    'Mos36_Percentage': 'MOS < 3.6';
    'Mos36_40_Percentage': 'MOS 3.6 - 4.0';
    'Mos40_Percentage': 'MOS > 4.0';
}

export class DashletVQMDailySummary extends Dashlet {
    constructor(private settingsService: DashletSettingsService) {
        super();

        this.DSCPMappedData = [];
        this.IPNRMappedData = [];
        this.CodecMappedData = [];
        this.Layer3MappedData = [];
        this.MosTimeMappedData = [];

        this.IPNR_DSCPCombinedData = [];
        this.DSCP_IPNRCombinedData = [];
        this.Codec_IPNRCombinedData = [];
        this.Layer3_DSCPCombinedData = [];

        this.IPNRMapList = [];
        this.CodecMapList = [];
        this.DSCPMapList = [];
        this.Layer3MapList = [];

        this.CountAll = 0;

        this.DSCPCount = 0;
        this.IPNRCount = 0;
        this.CodecCount = 0;
        this.Layer3Count = 0;

        this.DSCP_IPNRCount = 0;
        this.IPNR_DSCPCount = 0;
        this.Codec_IPNRCount = 0;
        this.Layer3_DSCPCount = 0;

        this.CodecMappedDataNoResult = false;
        this.Layer3MappedDataNoResult = false;

        this.Layer3Mapped = new Map<string, Layer3Categorized>();

        this.CMSMosDataMapped_Agent = new Map<string, CMSMosData_Agent>();
        this.CMSMosDataMapped_VDN = new Map<string, CMSMosData_VDN>();
        this.CMSMosDataMapped_Skill = new Map<string, CMSMosData_Skill>();
        // sizing
        this.sizes = [
            {
                id: 0,
                label: 'Small',
                cols: 5,
                rows: 5
            },
            {
                id: 2,
                label: 'Large',
                cols: 9,
                rows: 27
            },
            {
                id: 3,
                label: 'Huge',
                cols: 19,
                rows: 15
            }
        ];
        this.summaryPeriod = { value: 0 };

        this.applySize(0);

        // init data
        this.resetData();
    }

    static readonly PERSIST_TABS_KEY: string = 'tabsOpen';
    static readonly GIGABYTE: number = Math.pow(2, 30);
    static readonly DSCP = 'DSCP';
    static readonly IPNR = 'IPNR';
    static readonly Codec = 'Codec';
    static readonly Layer3 = 'Layer3';
    static readonly IPNRbyDSCP = 'IPNR/DSCP';
    static readonly DSCPbyIPNR = 'DSCP/IPNR';

    public commandTypeIdMOSSummary = 'C9F3DC4F-8F40-48B7-873E-529BDA48F303';

    // <-----          Without spaces ----------------->
    public commandTypeIdMOSSummaryDSCPByIPNR = '47393BB5-0AAC-4D24-9595-F1B83DF4E20B';
    public commandTypeIdMOSSummaryByDSCP = 'D07359A2-8663-40F5-B7FE-A5CD154B3FEF';
    public commandTypeIdMOSSummaryByIPNR = 'C21468C2-8A75-4538-904B-9B25494E011F';
    public commandTypeIdMOSSummaryLayer3ByDSCP = '0E0CCAF5-EC3F-4576-B59E-8B82E5588D73';
    public commandTypeIdMOSSummaryIPNRByDSCP = '0DB57B5D-EA36-4987-BA70-8D8E8967004A';
    public commandTypeIdMOSSummaryCodecByIPNR = 'B38F1541-88E7-4B8E-96DD-10C3763E6E7C';
    public commandTypeIdMOSSummaryByCodec = 'BAF10AFB-B243-4E5C-BBB8-C961F3D7BFA4';
    public commandTypeIdMOSSummaryByLayer3 = '7BD8EB49-CBEE-4BB3-AD41-751131BFAAF9';
    public commandTypeIdMOSSummaryByCMS_Agent = '292D2078-3195-4172-87AF-4E5A954FDF07';
    public commandTypeIdMOSSummaryByCMS_VDN = '4E541982-55A2-43AD-8135-44CAD8CBE0FC';
    public commandTypeIdMOSSummaryByCMS_Skill = '9EAD5CE7-320F-4E44-9C9E-6CC5B20E667F';

    public settingsUpdated: Subject<null> = new Subject();
    chartDataUpdated: Subject<null> = new Subject();

    ipnrDataUpdated_v2: Subject<null> = new Subject();

    layer3ByDscpDataUpdated: Subject<null> = new Subject();
    DSCP_IPNRDataUpdated: Subject<null> = new Subject();
    IPNR_DSCPDataUpdated: Subject<null> = new Subject();
    Codec_IPNRDataUpdated: Subject<null> = new Subject();
    Layer3_DSCPDataUpdated: Subject<null> = new Subject();

    dscpDataUpdated: Subject<null> = new Subject();
    ipnrDataUpdated: Subject<null> = new Subject();
    codecDataUpdated: Subject<null> = new Subject();
    layer3DataUpdated: Subject<null> = new Subject();
    mosTimeDataUpdated: Subject<null> = new Subject();

    cmsDataUpdated_Agent: Subject<null> = new Subject();
    cmsDataUpdated_VDN: Subject<null> = new Subject();
    cmsDataUpdated_Skill: Subject<null> = new Subject();

    DSCPMappedDataNoResultSub: Subject<null> = new Subject();
    IPNRMappedDataNoResultSub: Subject<null> = new Subject();
    CodecMappedDataNoResultSub: Subject<null> = new Subject();
    Layer3MappedDataNoResultSub: Subject<null> = new Subject();
    checkIfEquipmentTypeSub: Subject<null> = new Subject();

    sizeChanged: Subject<null> = new Subject();

    tabsOpen: number[] = [0];
    leftTabsOpen: number[] = [0, 1];
    rightTabsOpen: number[] = [0, 1];

    logoSub!: Subscription;
    customer!: Customer | null;
    customerUnsub!: Customer | null;
    location!: string | null;
    locationId!: string | null;
    locationIdUnsub!: string | null;
    equipment!: string | null;
    equipmentId!: string | null;
    equipmentIdUnsub!: string | null;
    receivers: any[] = [];

    mosSummaryMap!: Map<string, MOSSummaryData>; // mos data per rtcp receiver
    mosSummaryIPNRBreakdownMap!: Map<string, MOSSummaryBreakdownData>; // mos data by ipnr per rtcp receiver
    mosSummaryDSCPBreakdownMap!: Map<string, MOSSummaryBreakdownData>; // mos data by dscp per rtcp receiver

    mosTimeMap!: Map<string, any[]>;

    Layer3Mapped: Map<string, Layer3Categorized>;

    CMSMosDataMapped_Agent: Map<string, CMSMosData_Agent>;
    CMSMosDataMapped_VDN: Map<string, CMSMosData_VDN>;
    CMSMosDataMapped_Skill: Map<string, CMSMosData_Skill>;

    summaryPeriod: any;
    lastUpdated: string;

    DSCPMappedData: any;
    IPNRMappedData: any;
    CodecMappedData: any;
    Layer3MappedData: any;
    MosTimeMappedData: any;

    IPNR_DSCPCombinedData: any;
    DSCP_IPNRCombinedData: any;
    Codec_IPNRCombinedData: any;
    Layer3_DSCPCombinedData: any;
    public combinedMosData: {
        mosData: number[];
        totalCalls: number;
        traffic: number;
    };

    DSCPMapList: any;
    IPNRMapList: any;
    CodecMapList: any;
    Layer3MapList: any;

    CountAll: number;

    DSCPCount: number;
    IPNRCount: number;
    CodecCount: number;
    Layer3Count: number;

    DSCP_IPNRCount: number;
    IPNR_DSCPCount: number;
    Codec_IPNRCount: number;
    Layer3_DSCPCount: number;

    ipnrSet = new Set();
    thresholds: Threshold[] = [];
    Mos40Thresholds: Threshold[] = [];
    Mos36_40Thresholds: Threshold[] = [];
    Mos36Thresholds: Threshold[] = [];

    MasterLocations: any;
    MasterReceivers: any;
    MasterCMSEquipment: any;
    MasterCMSEquipmentWithData: any;
    DSCPMappedDataNoResult!: boolean;
    IPNRMappedDataNoResult!: boolean;
    CodecMappedDataNoResult: boolean;
    Layer3MappedDataNoResult: boolean;

    isCiscoSystems = false;

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);
        this.configured = v.customer && v.summaryPeriod && v.location && v.equipment;

        if (this.configured) {
            if (v.customer) {
                if (this.customer?.customerId && this.customer?.customerId !== '') this.customerUnsub = this.customer;
                this.customer = new Customer(v.customer.value, v.customer.label);
            } else {
                this.customer = new Customer('', '');
            }

            if (this.locationId) this.locationIdUnsub = this.locationId;
            this.location = v.location && v.location.value ? v.location.label : null;
            this.locationId = v.location && v.location.value ? v.location.value : null;

            if (this.equipmentId) this.equipmentIdUnsub = this.equipmentId;
            this.equipment = v.equipment && v.equipment.value ? v.equipment.label : null;
            this.equipmentId = v.equipment && v.equipment.value ? v.equipment.value : null;

            this.summaryPeriod = v.summaryPeriod;
            this.generatedNameTag = this.configured ? 'Loading' : 'Unconfigured';

            if (+this.summaryPeriod.value === 1) {
                const indexOfSummaryOverTime = this.tabsOpen.indexOf(2);
                if (indexOfSummaryOverTime !== -1) {
                    this.tabsOpen.splice(indexOfSummaryOverTime, 1);
                }
            }
            this.applySize(this.getSize().id);
            this.resetData();
            this.settingsUpdated.next(null);
        }
    }

    saveState(obj: { [key: string]: any }) {
        obj[DashletVQMDailySummary.PERSIST_TABS_KEY] = [...this.tabsOpen];
        super.saveState(obj);
    }

    loadState(obj: { [key: string]: any }) {
        super.loadState(obj);
        if (obj[DashletVQMDailySummary.PERSIST_TABS_KEY]) {
            this.tabsOpen = [...obj[DashletVQMDailySummary.PERSIST_TABS_KEY]];
            this.applySize(obj[Dashlet.PERSIST_SIZE_ID_KEY]);
            const size = this.getTotalSize();
            this.tile = {
                id: this.id,
                cols: size.w,
                rows: size.h,
                x: obj[Dashlet.PERSIST_X_KEY] || 0,
                y: obj[Dashlet.PERSIST_Y_KEY] || 0,
                dragEnabled: obj[Dashlet.PERSIST_DRAG_ENABLED_KEY]
            };
        }
    }

    applySize(id: number) {
        super.applySize(id);
        if (id === 2) {
            // if changing to large
            this.refreshNameTag();
            this.applySizeExpansion(0, -6 * (4 - this.tabsOpen.length) + 2);
        } else {
            this.refreshNameTag();
            this.applySizeExpansion(0, 0);
        }
        this.sizeChanged.next(null);
    }

    refreshNameTag() {
        if (new Date(this.lastUpdated).getTime() === 0) {
            // We have no data for last updated yet
            return;
        }
        // Make new dates, one without minutes for period
        const originalDate = new Date(this.lastUpdated),
            originalDateNoMinutes = new Date(this.lastUpdated);
        originalDateNoMinutes.setMinutes(0, 0, 0);

        const _month = originalDate.toLocaleDateString('en-nz', { month: 'short' });
        const _date = originalDate.toLocaleDateString('en-nz', { day: 'numeric' });
        const _time = originalDateNoMinutes
            .toLocaleTimeString('en-nz', { hour: 'numeric', minute: 'numeric' })
            .toUpperCase();

        // Date for first value in period
        const firstDate: Date = new Date(originalDateNoMinutes);
        firstDate.setHours(firstDate.getHours() - +this.summaryPeriod.value);

        const _firstDate = firstDate.toLocaleDateString(undefined, { day: 'numeric' });
        const _firstMonth = firstDate.toLocaleDateString(undefined, { month: 'short' });
        const _firstTime = firstDate
            .toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' })
            .toUpperCase();

        let period = '';

        if (this.summaryPeriod.value === 24) {
            period = _firstDate + '-' + _firstMonth + ' ' + _firstTime + ' - ' + _date + '-' + _month + ' ' + _time;
        } else {
            period = _firstTime + ' - ' + _time;
        }

        if (this.location !== undefined && this.summaryPeriod !== undefined) {
            if (this.getSize().id === 0) {
                const _hour = originalDate
                    .toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' })
                    .toUpperCase();
                this.generatedNameTag = `${this.location} | ${period} | Last updated at ${_hour}`;
            } else {
                const _hour = originalDate
                    .toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' })
                    .toUpperCase();
                this.generatedNameTag = `${this.location} | ${period} | Last updated at ${_date}-${_month} ${_hour}`;
            }
        }
    }

    public processipnrSet(data: any[]) {
        data.forEach(dataObject => {
            this.ipnrSet.add(dataObject['IPNR']);
        });
    }

    async postProcessMosDataAsync(dataParam: any, typeParam: string, dateStamp: any) {
        // retrieving of the mapped data is by type
        const mappedTable: any = this[`${typeParam}MappedData` as keyof this];

        // loop the passed data array [eg. new document file]
        for (const item of dataParam) {
            const exists = false;
            if (!exists) {
                // [not exists] then insert into the array.
                const newObject: any[] = [];

                newObject[typeParam as any] = dateStamp;
                newObject['noOfStreams' as any] = parseInt(item['TotalCalls']);
                newObject['MOSDanger' as any] =
                    parseInt(item['Mos 1.0 - 2.6']) + parseInt(item['Mos 2.6 - 3.1']) + parseInt(item['Mos 3.1 - 3.6']);
                newObject['MOSWarning' as any] = parseInt(item['Mos 3.6 - 4.0']);
                newObject['MOSHealthy' as any] = parseInt(item['Mos 4.0 - 4.3']) + parseInt(item['Mos 4.3+']);

                mappedTable.push(newObject);
            }
        }

        this[`${typeParam}MappedData` as keyof this] = mappedTable;

        this.mosTimeDataUpdated.next(null);
    }

    /**
     * Breakdown and compile each basic documents to its own table array.
     * used currently in calculating for vsm alex IPNR/Time
     * @param completeDataParam
     * @param typeParam
     */
    processDataRows(completeDataParam: any, typeParam: string) {
        const dataParam = completeDataParam[0].data;
        const timeStamp = new Date(completeDataParam[0].timestamp);
        timeStamp.setMinutes(0);
        timeStamp.setSeconds(0);
        const mappedTable: any = this[`${typeParam}MapList` as keyof this];

        dataParam.map((item: any) => {
            let rowData: any = this.getObjectType(typeParam); // check if IPNR, Codec, DSCP, Layer3 Object Type.

            rowData = { ...item };

            rowData[`${typeParam}`] = isNaN(rowData[`${typeParam}`] * 1)
                ? rowData[`${typeParam}`]
                : rowData[`${typeParam}`] * 1;
            rowData.Mos10_26 = rowData.Mos10_26 * 1; // used multiply by one(1) for performance. reference: https://flaviocopes.com/how-to-convert-string-to-number-javascript/
            rowData.Mos26_31 = rowData.Mos26_31 * 1;
            rowData.Mos31_36 = rowData.Mos31_36 * 1;
            rowData.Mos36_40 = rowData.Mos36_40 * 1;
            rowData.Mos40_43 = rowData.Mos40_43 * 1;
            rowData.Mos43 = rowData.Mos43 * 1;
            rowData.TotalStreams =
                rowData.Mos10_26 +
                rowData.Mos26_31 +
                rowData.Mos31_36 +
                rowData.Mos36_40 +
                rowData.Mos40_43 +
                rowData.Mos43;
            rowData.TimeStamp = timeStamp;

            mappedTable.push(rowData);
        });

        this[`${typeParam}MapList` as keyof this] = mappedTable;
        // increment each pass by document type
        let typeParamCount: any = this[`${typeParam}Count` as keyof this];
        typeParamCount++;
        this[`${typeParam}Count` as keyof this] = typeParamCount;

        // check if === to the summary period trigger update on the dashlet-vqm-daily-summary.component.ts
        if (typeParamCount >= this.summaryPeriod.value - 1) {
            const type = typeParam.toLowerCase();
            this.dataUpdateNext(type);
        }
    }

    dataUpdateNext(type: string): void {
        switch (type) {
            case 'chart':
                this.chartDataUpdated.next(null);
                break;
            case 'layer3ByDscp':
                this.layer3ByDscpDataUpdated.next(null);
                break;
            case 'DSCP_IPNR':
                this.DSCP_IPNRDataUpdated.next(null);
                break;
            case 'IPNR_DSCP':
                this.IPNR_DSCPDataUpdated.next(null);
                break;
            case 'Codec_IPNR':
                this.Codec_IPNRDataUpdated.next(null);
                break;
            case 'Layer3_DSCP':
                this.Layer3_DSCPDataUpdated.next(null);
                break;
            case 'dscp':
                this.dscpDataUpdated.next(null);
                break;
            case 'ipnr':
                this.ipnrDataUpdated.next(null);
                break;
            case 'codec':
                this.codecDataUpdated.next(null);
                break;
            case 'layer3':
                this.layer3DataUpdated.next(null);
                break;
            case 'mosTime':
                this.mosTimeDataUpdated.next(null);
                break;
            default:
        }
    }

    /**
     * Breakdown and compile each basic documents to its own table array.
     * used currently in calculating for vsm alex IPNR/Time
     * @param completeDataParam
     * @param typeParam
     */
    processDataRowsAsync(completeDataParam: any, typeParam: string): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            const dataParam = completeDataParam[0].data;
            const timeStamp = new Date(completeDataParam[0].timestamp);
            timeStamp.setMinutes(0);
            timeStamp.setSeconds(0);
            timeStamp.setMilliseconds(0);

            const overTimeStamp = `${timeStamp.toLocaleDateString()} ${timeStamp.toLocaleTimeString()}`;
            const mappedTable: any = this[`${typeParam}MapList` as keyof this];

            dataParam.map((item: any) => {
                let rowData: any = this.getObjectType(typeParam); // check if IPNR, Codec, DSCP, Layer3 Object Type.

                rowData = { ...item };

                rowData[`${typeParam}`] = isNaN(rowData[`${typeParam}`] * 1)
                    ? rowData[`${typeParam}`]
                    : rowData[`${typeParam}`] * 1;
                rowData.Mos10_26 = rowData.Mos10_26 * 1; // used multiply by one(1) for performance. reference: https://flaviocopes.com/how-to-convert-string-to-number-javascript/
                rowData.Mos26_31 = rowData.Mos26_31 * 1;
                rowData.Mos31_36 = rowData.Mos31_36 * 1;
                rowData.Mos36_40 = rowData.Mos36_40 * 1;
                rowData.Mos40_43 = rowData.Mos40_43 * 1;
                rowData.Mos43 = rowData.Mos43 * 1;
                rowData.TotalStreams =
                    rowData.Mos10_26 +
                    rowData.Mos26_31 +
                    rowData.Mos31_36 +
                    rowData.Mos36_40 +
                    rowData.Mos40_43 +
                    rowData.Mos43;
                rowData.TimeStamp = timeStamp;
                rowData.OverTimeStamp = overTimeStamp;

                mappedTable.push(rowData);
            });

            this[`${typeParam}MapList` as keyof this] = mappedTable;
            let typeParamCount: any = this[`${typeParam}Count` as keyof this];
            typeParamCount++;

            resolve(true);
        });
    }

    /**
     * Breakdown and compile each basic documents to its own table array.
     * used currently in calculating for vsm alex IPNR/Time
     * @param completeDataParam
     * @param typeParam
     */
    processDataRowsForLayer3(completeDataParam: any, typeParam: string) {
        const dataParam = completeDataParam[0].data;
        const timeStamp = new Date(completeDataParam[0].timestamp);
        const mappedTable: any = this[`${typeParam}MapList` as keyof this];

        // loop the passed data array [eg. new document file]
        for (const item of dataParam) {
            const rowData = {} as Layer3Categorized; // check if IPNR, Codec, DSCP, Layer3 Object Type.

            rowData[`IpAddress`] = item[`IpAddress`];
            rowData.MosDanger = item.Mos10_36 * 1; // used multiply by one(1) for performance. reference: https://flaviocopes.com/how-to-convert-string-to-number-javascript/
            rowData.MosWarning = item.Mos36_40 * 1;
            rowData.MosGood = item.Mos40 * 1;
            rowData.TotalStreams = rowData.MosGood + rowData.MosWarning + rowData.MosDanger;
            rowData.TimeStamp = timeStamp;

            mappedTable.push(rowData);
        }

        this[`${typeParam}MapList` as keyof this] = mappedTable;

        const type = typeParam.toLowerCase();
        this.dataUpdateNext(type);

        //// increment each pass by document type
        let typeParamCount: any = this[`${typeParam}Count` as keyof this];
        typeParamCount++;
    }

    /**
     * Breakdown and compile each basic documents to its own table array.
     * used currently in calculating for vsm alex IPNR/Time
     * @param completeDataParam
     * @param typeParam
     */
    processDataRowsForLayer3V2(completeDataParam: any, typeParam: string) {
        const dataParam = completeDataParam[0].data;
        const timeStamp = new Date(completeDataParam[0].timestamp);
        const mappedTable: any = this[`${typeParam}MapList` as keyof this];

        dataParam.map((item: any) => {
            const rowData = {} as Layer3Categorized; // check if IPNR, Codec, DSCP, Layer3 Object Type.

            rowData[`IpAddress`] = item[`IpAddress`];
            rowData.MosDanger = item.Mos10_36 * 1; // used multiply by one(1) for performance. reference: https://flaviocopes.com/how-to-convert-string-to-number-javascript/
            rowData.MosWarning = item.Mos36_40 * 1;
            rowData.MosGood = item.Mos40 * 1;
            rowData.TotalStreams = rowData.MosGood + rowData.MosWarning + rowData.MosDanger;
            rowData.TimeStamp = timeStamp;

            mappedTable.push(rowData);
        });

        this[`${typeParam}MapList` as keyof this] = mappedTable;

        const type = typeParam.toLowerCase();
        this.dataUpdateNext(type);
        let typeParamCount: any = this[`${typeParam}Count` as keyof this];
        typeParamCount++;
    }

    /**
     * Breakdown and compile each basic documents to its own table array.
     * used currently in calculating for vsm alex IPNR/Time
     * @param completeDataParam  - the received data table from the realtime document
     * @param typeParam - the type of CMS data that is being passed (ex. Agent, Skill, VDN)
     */
    processDataRowsForCMS(completeDataParam: any, typeParam: string) {
        const dataParam = completeDataParam[0].data;
        const promise = new Promise(resolve => {
            dataParam.map((item: any) => {
                // check type of cms data to properly push it to the table
                if (typeParam === 'Agent') {
                    const data: CMSMosData_Agent = {
                        AgentId: item['AgentId'],
                        AgentName: item['AgentName'],
                        MosGood: item['Mos40'] * 1,
                        MosDanger: item['Mos10_36'] * 1,
                        MosWarning: item['Mos36_40'] * 1,
                        TotalStreams: item['TotalCalls'] * 1,
                        Percentage: 0,
                        RtcpReceiverId: item['RtcpReceiverId']
                    } as CMSMosData_Agent;

                    if (
                        this.MasterReceivers.filter(
                            (rec: any) =>
                                rec?.equipmentId?.toLowerCase() === data?.RtcpReceiverId?.toString().toLowerCase()
                        ).length < 1 &&
                        this.equipmentId !== '=all='
                    ) {
                        return;
                    }

                    if (!this.CMSMosDataMapped_Agent.has(item['AgentId'])) {
                        // IF AGENTID NOT EXISTING, INSERT INTO THE MAP
                        data.Percentage = this.getPercentageField(data.MosGood, data.MosWarning, data.MosDanger);
                        this.CMSMosDataMapped_Agent.set(item['AgentId'], data);
                    } else {
                        // IF EXISTING, UPDATE
                        const currentData: any = this.CMSMosDataMapped_Agent.get(item['AgentId']);
                        currentData.MosGood += data.MosGood * 1;
                        currentData.MosWarning += data.MosWarning * 1;
                        currentData.MosDanger += data.MosDanger * 1;
                        currentData.TotalStreams += data.TotalStreams * 1;
                        currentData.Percentage = this.getPercentageField(
                            currentData.MosGood,
                            currentData.MosWarning,
                            currentData.MosDanger
                        );

                        this.CMSMosDataMapped_Agent.set(item['AgentId'], currentData);
                    }
                } else if (typeParam === 'VDN') {
                    const data: CMSMosData_VDN = {
                        Vdn: item['Vdn'],
                        MosGood: item['Mos40'] * 1,
                        MosDanger: item['Mos10_36'] * 1,
                        MosWarning: item['Mos36_40'] * 1,
                        TotalStreams: item['TotalCalls'] * 1,
                        Percentage: 0,
                        RtcpReceiverId: item['RtcpReceiverId']
                    } as CMSMosData_VDN;

                    if (
                        this.MasterReceivers.filter(
                            (rec: any) =>
                                rec?.equipmentId?.toLowerCase() === data?.RtcpReceiverId?.toString().toLowerCase()
                        ).length < 1 &&
                        this.equipmentId !== '=all='
                    ) {
                        return;
                    }

                    if (!this.CMSMosDataMapped_VDN.has(item['Vdn'])) {
                        // IF AGENTID NOT EXISTING, INSERT INTO THE MAP
                        data.Percentage = this.getPercentageField(data.MosGood, data.MosWarning, data.MosDanger);
                        this.CMSMosDataMapped_VDN.set(item['Vdn'], data);
                    } else {
                        // IF EXISTING, UPDATE
                        const currentData: any = this.CMSMosDataMapped_VDN.get(item['Vdn']);
                        currentData.MosGood += data.MosGood * 1;
                        currentData.MosWarning += data.MosWarning * 1;
                        currentData.MosDanger += data.MosDanger * 1;
                        currentData.TotalStreams += data.TotalStreams * 1;
                        currentData.Percentage = this.getPercentageField(
                            currentData.MosGood,
                            currentData.MosWarning,
                            currentData.MosDanger
                        );

                        this.CMSMosDataMapped_VDN.set(item['Vdn'], currentData);
                    }
                } else {
                    const data: CMSMosData_Skill = {
                        Skill: item['Skill'],
                        MosGood: item['Mos40'] * 1,
                        MosDanger: item['Mos10_36'] * 1,
                        MosWarning: item['Mos36_40'] * 1,
                        TotalStreams: item['TotalCalls'] * 1,
                        Percentage: 0,
                        RtcpReceiverId: item['RtcpReceiverId']
                    } as CMSMosData_Skill;

                    if (
                        this.MasterReceivers.filter(
                            (rec: any) =>
                                rec?.equipmentId?.toLowerCase() === data?.RtcpReceiverId?.toString().toLowerCase()
                        ).length < 1 &&
                        this.equipmentId !== '=all='
                    ) {
                        return;
                    }

                    if (!this.CMSMosDataMapped_Skill.has(item['Skill'])) {
                        // IF AGENTID NOT EXISTING, INSERT INTO THE MAP
                        data.Percentage = this.getPercentageField(data.MosGood, data.MosWarning, data.MosDanger);
                        this.CMSMosDataMapped_Skill.set(item['Skill'], data);
                    } else {
                        // IF EXISTING, UPDATE
                        const currentData: any = this.CMSMosDataMapped_Skill.get(item['Skill']);
                        currentData.MosGood += data.MosGood * 1;
                        currentData.MosWarning += data.MosWarning * 1;
                        currentData.MosDanger += data.MosDanger * 1;
                        currentData.TotalStreams += data.TotalStreams * 1;
                        currentData.Percentage = this.getPercentageField(
                            currentData.MosGood,
                            currentData.MosWarning,
                            currentData.MosDanger
                        );

                        this.CMSMosDataMapped_Skill.set(item['Skill'], currentData);
                    }
                }
            });

            resolve(true);
        });

        promise.then(res => {
            if (res) {
                if (typeParam === 'Agent') {
                    this.cmsDataUpdated_Agent.next(null);
                } else if (typeParam === 'VDN') {
                    this.cmsDataUpdated_VDN.next(null);
                } else {
                    this.cmsDataUpdated_Skill.next(null);
                }
            }
        });
    }

    getPercentageField(mosGood: any, mosWarning: any, mosDanger: any) {
        if (mosGood !== 0) {
            return parseInt((((mosGood * 1) / (mosGood * 1 + mosWarning * 1 + mosDanger * 1)) * 100).toFixed(0)) + 100;
        } else if (mosWarning >= mosDanger) {
            return parseInt((((mosWarning * 1) / (mosWarning * 1 + mosDanger * 1)) * 100).toFixed(0));
        } else {
            return -1;
        }
    }

    /**
     * Breakdown and compile each basic documents to its own table array.
     * used currently in calculating for vsm alex IPNR/Time
     * @param completeDataParam
     * @param typeParam
     * currently used for layer 3
     */
    processDataRowsForLayer3AsyncV3(dataParam: any) {
        const promise = new Promise(resolve => {
            dataParam.map((item: any) => {
                const data: Layer3Categorized = {
                    IpAddress: item['IpAddress'],
                    MosGood: item['Mos40'] * 1,
                    MosDanger: item['Mos10_36'] * 1,
                    MosWarning: item['Mos36_40'] * 1,
                    TotalStreams: item['TotalStreams'] * 1,
                    Percentage: 0,
                    Priority: 0
                } as Layer3Categorized;

                if (!this.Layer3Mapped.has(item['IpAddress'])) {
                    // IF NOT EXISTING
                    // INSERT
                    data.Percentage = this.getPercentageField(data.MosGood, data.MosWarning, data.MosDanger);
                    this.Layer3Mapped.set(item['IpAddress'], data);
                } else {
                    // IF EXISTING
                    // UPDATE
                    const currentData: any = this.Layer3Mapped.get(item['IpAddress']);
                    currentData.MosGood += data.MosGood * 1;
                    currentData.MosWarning += data.MosWarning * 1;
                    currentData.MosDanger += data.MosDanger * 1;
                    currentData.TotalStreams += data.TotalStreams * 1;
                    currentData.Percentage = this.getPercentageField(
                        currentData.MosGood,
                        currentData.MosWarning,
                        currentData.MosDanger
                    );

                    this.Layer3Mapped.set(item['IpAddress'], currentData);
                }
            });

            resolve(true);
        });

        promise.then(res => {
            if (res) {
                this.layer3DataUpdated.next(null);
            }
        });
    }

    /**
     * Breakdown and compile each basic documents to its own table array.
     * used currently in calculating for vsm alex IPNR/Time
     * @param completeDataParam
     * @param typeParam
     */
    processSubDataRows(completeDataParam: any, typeParam: string) {
        const dataParam = completeDataParam[0].data;
        const parentRow = typeParam.split('_')[0];
        const childRow = typeParam.split('_')[1];
        const timeStamp = new Date(completeDataParam[0].timestamp);
        const mappedTable: any = this[`${typeParam}CombinedData` as keyof this];

        dataParam.map((item: any) => {
            const rowData = { ...item };
            rowData[`${parentRow}`] = isNaN(rowData[`${parentRow}`] * 1)
                ? rowData[`${parentRow}`]
                : rowData[`${parentRow}`] * 1;
            rowData[`${childRow}`] = isNaN(rowData[`${childRow}`] * 1)
                ? rowData[`${childRow}`]
                : rowData[`${childRow}`] * 1;
            rowData.Mos10_26 = rowData.Mos10_26 * 1; // used multiply by one(1) for performance. reference: https://flaviocopes.com/how-to-convert-string-to-number-javascript/
            rowData.Mos26_31 = rowData.Mos26_31 * 1;
            rowData.Mos31_36 = rowData.Mos31_36 * 1;
            rowData.Mos36_40 = rowData.Mos36_40 * 1;
            rowData.Mos40_43 = rowData.Mos40_43 * 1;
            rowData.Mos43 = rowData.Mos43 * 1;
            rowData.TotalStreams =
                rowData.Mos10_26 +
                rowData.Mos26_31 +
                rowData.Mos31_36 +
                rowData.Mos36_40 +
                rowData.Mos40_43 +
                rowData.Mos43;
            rowData.TimeStamp = timeStamp;

            mappedTable.push(rowData);
        });

        this[`${typeParam}CombinedData` as keyof this] = mappedTable;
        this.dataUpdateNext(typeParam);
    }

    /**
     * Breakdown and compile each basic documents to its own table array.
     * used currently in calculating for vsm alex IPNR/Time
     * @param completeDataParam
     * @param typeParam
     */
    processSubDataRowsForLayer3(completeDataParam: any, typeParam: string) {
        const dataParam = completeDataParam[0].data;
        let parentRow = typeParam.split('_')[0];

        parentRow = parentRow === 'Layer3' ? 'IpAddress' : typeParam;

        const childRow = typeParam.split('_')[1];
        const timeStamp = new Date(completeDataParam[0].timestamp);
        const mappedTable: any = this[`${typeParam}CombinedData` as keyof this];

        dataParam.map((item: any) => {
            const rowData: any = {} as Layer3_DSCPCategorized;
            rowData[`${parentRow}`] = item[`${parentRow}`];
            rowData[`${childRow}`] = item[`${childRow}`] * 1;
            rowData.MosDanger = item.Mos10_36 * 1; // used multiply by one(1) for performance. reference: https://flaviocopes.com/how-to-convert-string-to-number-javascript/
            rowData.MosWarning = item.Mos36_40 * 1;
            rowData.MosGood = item.Mos40 * 1;
            rowData.TotalStreams = rowData.MosGood + rowData.MosWarning + rowData.MosDanger;
            rowData.TimeStamp = timeStamp;

            mappedTable.push(rowData);
        });

        this[`${typeParam}CombinedData` as keyof this] = mappedTable;
        this.dataUpdateNext(typeParam);
    }

    getObjectType(typeParam: string) {
        switch (typeParam) {
            case 'Codec': {
                const data: CodecDataMap = {} as CodecDataMap;
                return data;
            }
            case 'IPNR': {
                const data: IPNRDataMap = {} as IPNRDataMap;
                return data;
            }
            case 'DSCP': {
                const data: DSCPDataMap = {} as DSCPDataMap;
                return data;
            }
            default:
                return;
        }
    }

    processMOSSummary(data: any, receiver: string) {
        const summary = data[0].data[0];

        this.mosSummaryMap
            .get(receiver)!
            .updateMOSData(
                [
                    parseInt(summary['Mos10_26']),
                    parseInt(summary['Mos26_31']),
                    parseInt(summary['Mos31_36']),
                    parseInt(summary['Mos36_40']),
                    parseInt(summary['Mos40_43']),
                    parseInt(summary['Mos43'])
                ],
                parseInt(summary.Equipment),
                parseInt(summary.TotalCalls),
                parseInt(summary.Traffic) / DashletVQMDailySummary.GIGABYTE,
                new Date(data[0].timestamp)
            );
        this.setCombinedMosData();
        this.chartDataUpdated.next(null);
    }

    /* type = "IPNR" or "DSCP" */
    processMOSSummaryBreakdown(data: any, receiver: string, type: string) {
        const breakdownMap =
            type === DashletVQMDailySummary.IPNR ? this.mosSummaryIPNRBreakdownMap : this.mosSummaryDSCPBreakdownMap;
        for (const row of data[0].data) {
            if (row) {
                breakdownMap
                    .get(receiver)!
                    .updateMOSBreakdownData(
                        [
                            parseInt(row['Mos10_26']),
                            parseInt(row['Mos26_31']),
                            parseInt(row['Mos31_36']),
                            parseInt(row['Mos36_40']),
                            parseInt(row['Mos40_43']),
                            parseInt(row['Mos43'])
                        ],
                        row[type],
                        new Date(data[0].timestamp)
                    );
            }
        }
        this.chartDataUpdated.next(null);
    }

    /* Get combined mos summary breakdown data from all Rtcp receivers */
    /* type = "IPNR" or "DSCP" */
    getCombinedMOSSummaryBreakdown(type: string): Map<string, number[]> {
        const breakdownMap =
            type === DashletVQMDailySummary.IPNR ? this.mosSummaryIPNRBreakdownMap : this.mosSummaryDSCPBreakdownMap;
        const combinedData = new Map<string, number[] | any>(); // map between dscp/ipnr and 6 mos categories

        // for each receiver
        breakdownMap.forEach((mosData: MOSSummaryBreakdownData) => {
            // get the breakdown for the summary period for the current receiver and add it to the combined data
            mosData.getMOSBreakdownDataByHours(this.summaryPeriod.value).forEach((bands: number[], group: string) => {
                // if first receiver in loop, initialise mos data for current group
                let currentGroupMosData;
                if (combinedData.has(group)) {
                    currentGroupMosData = combinedData.get(group);
                } else {
                    currentGroupMosData = new Array(6).fill(0);
                }
                // add each mos band e.g. 'Mos 4.3+' for each dscp/ipnr group
                for (let i = 0; i < bands.length; i++) {
                    currentGroupMosData![i] += bands[i];
                }
                combinedData.set(group, currentGroupMosData);
            });
        });

        return combinedData;
    }

    /*
    Get top 'top' groups (e.g. for cases when too many IPNRs to show on chart)
    */
    getTopGroupsByType(type: string, top: number) {
        const combinedBreakdown = this.getCombinedMOSSummaryBreakdown(type);

        const keys = Array.from(combinedBreakdown.keys());
        // sorts the groups by number of total streams
        keys.sort((a, b) => {
            let aTotalStreams = 0;
            let bTotalStreams = 0;

            for (let i = 0; i < combinedBreakdown.get(a)!.length; i++) {
                aTotalStreams += combinedBreakdown.get(a)![i];
                bTotalStreams += combinedBreakdown.get(b)![i];
            }

            if (aTotalStreams > bTotalStreams) {
                return -1;
            }

            if (bTotalStreams < aTotalStreams) {
                return 1;
            }

            return 0;
        });

        const topGroups = keys.map(name => {
            return {
                name: name,
                data: combinedBreakdown.get(name)
            };
        });
        return topGroups.slice(0, top);
    }

    /* Extension of getTopGroupsByType where bands is an array containing the indices of desired bands to reduce to.
    Flattens object for use in sort pipe.
    */
    getTopGroupsByTypeReducedByBands(type: string, top: number, bands: number[]) {
        const topGroups = this.getTopGroupsByType(type, top);
        const topGroupsByBand = [];

        for (const group of topGroups) {
            let sumOfBandsForGroup = 0;
            let sumOfBandsTotal = 0;
            for (const band of bands) {
                sumOfBandsForGroup += group.data![band];
                sumOfBandsTotal += this.combinedMosData.mosData[band];
            }

            const percentage = (sumOfBandsForGroup / sumOfBandsTotal) * 100;
            topGroupsByBand.push({
                name: group.name,
                streams: sumOfBandsForGroup,
                percentage: isNaN(percentage) ? '---' : percentage.toFixed(1) + '%'
            });
        }

        return topGroupsByBand;
    }

    /*
        Get number of groups
    */
    getNumberOfGroupsByType(type: string): number {
        return Array.from(this.getCombinedMOSSummaryBreakdown(type).keys()).length;
    }

    /* Get combined mos summary data from all Rtcp receivers */
    public setCombinedMosData(): void {
        this.combinedMosData = {
            mosData: new Array(6).fill(0),
            totalCalls: 0,
            traffic: 0
        };

        this.mosSummaryMap.forEach((data: MOSSummaryData) => {
            for (let i = 0; i < this.combinedMosData.mosData.length; i++) {
                this.combinedMosData.mosData[i] += data.getMOSDataByHours(this.summaryPeriod.value).mosData[i];
            }
            this.combinedMosData.totalCalls += data.getMOSDataByHours(this.summaryPeriod.value).totalCalls;
            this.combinedMosData.traffic += data.getMOSDataByHours(this.summaryPeriod.value).traffic;
        });
    }

    public processThresholdData(data: Threshold[]): any {
        if (data.length > 0) {
            data.forEach((data: any) => {
                const threshold = new Threshold();
                threshold.commandTypeId = data['CommandTypeId'];
                threshold.dapThresholdTemplateId = data['DapThresholdTemplateId'];
                threshold.alarmName = data['AlarmName'];
                threshold.name = data['Name'];
                threshold.customerId = data['CustomerId'];
                threshold.dAPMessageFullClassName = data['DAPMessageFullClassName'];
                threshold.dapThresholdId = data['DapThresholdId'];
                threshold.description = data['Description'];
                threshold.equipmentId = data['EquipmentId'];
                threshold.jSONRules = data['JSONRules'];
                threshold.locationId = data['LocationId'];

                const jsonObj = JSON.parse(data['JSONRules']); //Sravan and Yuri decided that this will not be returned as a string, it will be returned as an actial JSON object.
                jsonObj.forEach((element: any) => {
                    if (element['field'] !== null && element['field'] !== 'IPNR') {
                        threshold.field = element['field'];
                        threshold.value = element['value'];
                        threshold.operator = element['operatr'];
                    }
                });
                this.thresholds.push(threshold);
            });
            this.processThresholdsMapping();
        }
    }

    private processThresholdsMapping() {
        this.thresholds.forEach(threshold => {
            const category = threshold.field.replace('_Percentage', '');
            switch (category) {
                case 'Mos40':
                    this.Mos40Thresholds.push(threshold);
                    break;
                case 'Mos36_40':
                    this.Mos36_40Thresholds.push(threshold);
                    break;
                case 'Mos36':
                    this.Mos36Thresholds.push(threshold);
            }
        });
    }

    resetData() {
        this.mosSummaryMap = new Map<string, MOSSummaryData>();
        this.mosSummaryIPNRBreakdownMap = new Map<string, MOSSummaryBreakdownData>();
        this.mosSummaryDSCPBreakdownMap = new Map<string, MOSSummaryBreakdownData>();
        this.DSCPMappedData = [];
        this.CodecMappedData = [];
        this.IPNRMappedData = [];
        this.IPNR_DSCPCombinedData = [];
        this.Codec_IPNRCombinedData = [];
        this.DSCP_IPNRCombinedData = [];

        this.CMSMosDataMapped_Agent = new Map<string, CMSMosData_Agent>();
        this.CMSMosDataMapped_VDN = new Map<string, CMSMosData_VDN>();
        this.CMSMosDataMapped_Skill = new Map<string, CMSMosData_Skill>();

        this.IPNRMapList = [];
        this.DSCPMapList = [];
        this.CodecMapList = [];
        this.receivers = [];

        this.DSCPCount = 0;
        this.CodecCount = 0;
        this.IPNRCount = 0;
        this.IPNR_DSCPCount = 0;
        this.Layer3_DSCPCount = 0;
        this.DSCP_IPNRCount = 0;
        this.Codec_IPNRCount = 0;

        this.thresholds = [];
        this.Mos40Thresholds = [];
        this.Mos36_40Thresholds = [];
        this.Mos36Thresholds = [];
    }

    dispose() {}
}
