export class Link {
    private _linkNumber!: string;
    private _tx!: number;
    private _rx!: number;

    public constructor(linkNumber: string, tx: number, rx: number) {
        this.linkNumber = linkNumber;
        this.tx = tx;
        this.rx = rx;
    }

    public updateFromObject(link: Link) {
        this._linkNumber = link.linkNumber;
        this._rx = link.rx;
        this._tx = link.tx;
    }

    /**
     * Getter linkNumber
     * @return {string}
     */
    public get linkNumber(): string {
        return this._linkNumber;
    }

    /**
     * Getter tx
     * @return {number}
     */
    public get tx(): number {
        return this._tx / 1800.0;
    }

    /**
     * Getter rx
     * @return {number}
     */
    public get rx(): number {
        return this._rx / 1800.0;
    }

    /**
     * Setter linkNumber
     * @param {string} value
     */
    public set linkNumber(value: string) {
        if (value == null || value == '') {
            value = '---';
        }
        this._linkNumber = value;
    }

    /**
     * Setter tx
     * @param {number} value
     */
    public set tx(value: number) {
        this._tx = value;
    }

    /**
     * Setter rx
     * @param {number} value
     */
    public set rx(value: number) {
        this._rx = value;
    }
}

export class ACMAESServer {
    private _serverId: string;
    private _status: string;
    private _links: Link[];
    private _cti: {
        link: string;
        state: string;
    }[];

    constructor(serverId: string, status: string) {
        this._serverId = serverId;
        this._links = [];
        this._cti = [];
        this._status = status;
    }

    public addLink(link: Link) {
        const index = this._links.findIndex(e => e.linkNumber == link.linkNumber);
        if (index == -1) {
            this._links.push(link);
        } else {
            this._links[index] = link;
        }
    }

    public addCTI(link: string, state: string) {
        const cti = {
            link: link,
            state: state
        };
        if (!this._cti.some(e => e.link == cti.link)) {
            this._cti.push(cti);
        }
    }

    public getCTILinks(): any[] {
        return this._cti;
    }

    public getTotalRX(): number {
        let total = 0;

        for (const link of this._links) {
            total += link.rx;
        }

        return total;
    }

    public getTotalTX(): number {
        let total = 0;

        for (const link of this._links) {
            total += link.tx;
        }

        return total;
    }

    /**
     * Getter serverId
     * @return {string}
     */
    public get serverId(): string {
        return this._serverId;
    }

    /**
     * Getter status
     * @return {string}
     */
    public get status(): string {
        return this._status;
    }

    /**
     * Getter links
     * @return {Link[]}
     */
    public get links(): Link[] {
        return this._links;
    }

    /**
     * Getter cti
     * @return {string[]}
     */
    public get cti(): any[] {
        return this._cti;
    }

    /**
     * Setter serverId
     * @param {string} value
     */
    public set serverId(value: string) {
        if (value == null || value == '') {
            value = '---';
        }
        this._serverId = value;
    }

    /**
     * Setter status
     * @param {string} value
     */
    public set status(value: string) {
        if (value == null || value == '') {
            value = '---';
        }
        this._status = value;
    }
}
