<!-- Note: the "Streams in progress" dashlet was previously called "Calls in progress", and as such some "Sip" abbreviation labels have been left as "Cip"  -->

<div *ngIf="!openSubcontainer">
    <div class="flex-column gap-m">
        <div class="text-center">
            {{ dashlet.totalData }} stream(s) in progress at {{ dashlet.processTime | date: 'HH:mm:ss' }}
            <span *ngIf="filterCount > 200"> - showing 1 - {{ filterCount > 200 ? 200 : filterCount }} with lowest MOS</span>
        </div>
        <div class="justify-space-evenly">
            <div class="mat-body-2">Party A</div>
            <mat-icon>compare_arrows</mat-icon>
            <div class="mat-body-2">Party B</div>
        </div>
    </div>
    <dashlet-table
        *ngIf="displayTable"
        [columns]="columns"
        [dataSource]="dashlet.cipData"
        [pagination]="true"
        [tableHeightRows]="dashlet.tableHeight"
        [pageLength]="dashlet.tableHeight"
        [menuItems]="items"
        [loading]="dashlet.loading"
    >
    </dashlet-table>
</div>

<!-- loading template -->
<ng-template #vsmLoadingTemplate>
    <vsm-dashlet-loading></vsm-dashlet-loading>
</ng-template>

<!-- details drilldown -->
<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'details'"
    navPrevious="Streams In Progress"
    navCurrent="Details"
    [color]="dashletService.getSeverityColor(getSeverityColor(selectedCip.sourceMos))"
    (close)="closeDetails()"
>
    <ng-container title> Stream Details | {{ selectedCip.sourcePhoneName }} </ng-container>
    <ng-container subtitle> {{ dashlet.customer.customerName }} | {{ selectedCip.sourceName }} </ng-container>
    <div class="flex-column gap-l" body>
        <!-- properties -->
        <div class="flex gap-m">
            <div class="flex-column equipment-detail-labels">
                <div class="mat-body-2">DSCP</div>
                <div class="mat-body-2">Port</div>
                <div class="mat-body-2">Start Time</div>
                <div class="mat-body-2">Codec</div>
                <div class="mat-body-2">IPNR</div>
                <div class="mat-body-2">Stream Length</div>
                <div class="mat-body-2">End Point</div>
            </div>
            <div class="flex-column text-no-wrap">
                <div class="mat-body-1">{{ selectedCip.cipAdditional.dscp || 'Unknown' }}</div>
                <div class="mat-body-1">{{ selectedCip.cipAdditional.port || 'Unknown' }}</div>
                <div class="mat-body-1">{{ selectedCip.callStart || 'Unknown' }}</div>
                <div class="mat-body-1">{{ selectedCip.cipAdditional.codec || 'Unknown' }}</div>
                <div class="mat-body-1">{{ selectedCip.sourceIpnr || 'Unknown' }}</div>
                <div class="mat-body-1">{{ selectedCip.cipAdditional.length || 'Unknown' }}</div>
                <div class="mat-body-1" [matTooltip]="selectedCip.sourceEndPoint">{{ selectedCip.sourceEndPoint || 'Unknown' }}</div>
            </div>
        </div>
        <mat-accordion multi>
            <mat-expansion-panel expanded="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="mat-body-1">VQM Related Data </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="grid-3-columns gap-m" *ngIf="cipStreamDetail | async as details; else responseLoading">
                    <div></div>
                    <div class="mat-body-2 align-center justify-space-between">
                        <div class="justify-center grow">Party A</div>
                        <mat-icon>compare_arrows</mat-icon>
                    </div>

                    <div class="mat-body-2 align-center justify-center grow">Party B</div>
                    <div class="mat-body-2">Type/Location</div>
                    <div class="mat-body-2">
                        <div>
                            Type:<span class="mat-body-1"> {{ details.nearEndVqmData.type }}</span>
                        </div>
                        <div>
                            IP Address:<span class="mat-body-1"> {{ details.nearEndVqmData.ip }}</span>
                        </div>
                        <div>
                            Site ID:<span class="mat-body-1"> {{ details.nearEndVqmData.siteId }}</span>
                        </div>
                        <div>
                            Site:<span class="mat-body-1"> {{ details.nearEndVqmData.site }}</span>
                        </div>
                    </div>
                    <div class="mat-body-2">
                        <ng-container *ngIf="details.nearEndVqmData.type !== details.farEndVqmData.type; else noData">
                            <div>
                                Type: <span class="mat-body-1">{{ details.farEndVqmData.type }}</span>
                            </div>
                            <div>
                                IP Address: <span class="mat-body-1">{{ details.farEndVqmData.ip }}</span>
                            </div>
                            <div>
                                Site ID: <span class="mat-body-1"> {{ details.farEndVqmData.siteId }}</span>
                            </div>
                            <div>
                                Site:<span class="mat-body-1"> {{ details.farEndVqmData.site }}</span>
                            </div>
                        </ng-container>
                        <ng-template #noData>No data available</ng-template>
                    </div>
                    <div class="mat-body-2">Versions</div>
                    <div class="mat-body-2">
                        <div>
                            Hardware: <span class="mat-body-1">{{ details.nearEndVqmData.hardware }}</span>
                        </div>
                        <div>
                            Software: <span class="mat-body-1">{{ details.nearEndVqmData.software }}</span>
                        </div>
                        <div>
                            DSP: <span class="mat-body-1">{{ details.nearEndVqmData.dsp }}</span>
                        </div>
                    </div>
                    <div class="mat-body-2">
                        <ng-container *ngIf="details.nearEndVqmData.type !== details.farEndVqmData.type">
                            <div>
                                Hardware: <span class="mat-body-1">{{ details.farEndVqmData.hardware }}</span>
                            </div>
                            <div>
                                Software: <span class="mat-body-1">{{ details.farEndVqmData.software }}</span>
                            </div>
                            <div>
                                DSP: <span class="mat-body-1">{{ details.farEndVqmData.dsp }}</span>
                            </div>
                        </ng-container>
                    </div>
                    <div class="mat-body-2">Network</div>
                    <div class="mat-body-2">
                        <div>
                            Ethernet Speed:<span class="mat-body-1"> {{ GetEthernetSpeed(details.nearEndVqmData.ethernetSpeed) }}</span>
                        </div>
                        <div>
                            Ethernet Duplex:<span class="mat-body-1"> {{ details.nearEndVqmData.ethernetDuplex }}</span>
                        </div>
                        <div>
                            Ethernet Auto Negotiation:<span class="mat-body-1"> {{ details.nearEndVqmData.ethernetAutoNegotiation }}</span>
                        </div>
                        <div>
                            8021Q Framing Code:<span class="mat-body-1"> {{ details.nearEndVqmData.framingCode }}</span>
                        </div>
                    </div>
                    <div class="mat-body-2">
                        <ng-container *ngIf="details.nearEndVqmData.type !== details.farEndVqmData.type">
                            <div>
                                Ethernet Speed:<span class="mat-body-1"> {{ GetEthernetSpeed(details.farEndVqmData.ethernetSpeed) }}</span>
                            </div>
                            <div>
                                Ethernet Duplex:<span class="mat-body-1"> {{ details.farEndVqmData.ethernetDuplex }}</span>
                            </div>
                            <div>
                                Ethernet Auto Negotiation:<span class="mat-body-1"> {{ details.farEndVqmData.ethernetAutoNegotiation }}</span>
                            </div>
                            <div>
                                8021Q Framing Code:<span class="mat-body-1"> {{ details.farEndVqmData.framingCode }}</span>
                            </div>
                        </ng-container>
                    </div>
                    <div class="mat-body-2">Protocols</div>
                    <div class="mat-body-2">
                        <div>
                            Layer 28021Q Audio Priority: <span class="mat-body-1"> {{ details.nearEndVqmData.layerAudioPriority }}</span>
                        </div>
                        <div>
                            Layer 28021Q Signalling Priority: <span class="mat-body-1"> {{ details.nearEndVqmData.layerSignalPriority }}</span>
                        </div>
                        <div>
                            Layer 28021Q VLAN: <span class="mat-body-1"> {{ details.nearEndVqmData.vlan }}</span>
                        </div>
                        <div>
                            8021Q Tagging Status: <span class="mat-body-1"> {{ details.nearEndVqmData.taggingStatus }}</span>
                        </div>
                    </div>
                    <div class="mat-body-2">
                        <ng-container *ngIf="details.nearEndVqmData.type !== details.farEndVqmData.type">
                            <div>
                                Layer 28021Q Audio Priority: <span class="mat-body-1"> {{ details.farEndVqmData.layerAudioPriority }}</span>
                            </div>
                            <div>
                                Layer 28021Q Signalling Priority: <span class="mat-body-1"> {{ details.farEndVqmData.layerSignalPriority }}</span>
                            </div>
                            <div>
                                Layer 28021Q VLAN: <span class="mat-body-1"> {{ details.farEndVqmData.vlan }}</span>
                            </div>
                            <div>
                                8021Q Tagging Status: <span class="mat-body-1"> {{ details.farEndVqmData.taggingStatus }}</span>
                            </div>
                        </ng-container>
                    </div>
                    <div class="mat-body-2">QOS</div>
                    <div class="mat-body-2">
                        <div>
                            DSCP Video: <span class="mat-body-1"> {{ details.nearEndVqmData.dscpVideo }}</span>
                        </div>
                        <div>
                            DSCP Audio: <span class="mat-body-1"> {{ details.nearEndVqmData.dscpAudio }}</span>
                        </div>
                        <div>
                            DSCP Signalling: <span class="mat-body-1"> {{ details.nearEndVqmData.dscpSignal }}</span>
                        </div>
                    </div>
                    <div class="mat-body-2">
                        <ng-container *ngIf="details.nearEndVqmData.type !== details.farEndVqmData.type">
                            <div>
                                DSCP Video: <span class="mat-body-1"> {{ details.farEndVqmData.dscpVideo }}</span>
                            </div>
                            <div>
                                DSCP Audio: <span class="mat-body-1"> {{ details.farEndVqmData.dscpAudio }}</span>
                            </div>
                            <div>
                                DSCP Signalling: <span class="mat-body-1"> {{ details.farEndVqmData.dscpSignal }}</span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <ng-template #responseLoading><vsm-dashlet-loading></vsm-dashlet-loading></ng-template>
</vsm-dashlet-subcontainer>
