import { createAction, props } from '@ngrx/store';

export const GetHistoricCpuData = createAction(
  '[HistoricCpuData] Get HistoricCpuDatas',
  props<{ equipmentId: string, commandTypeId: string, from?: Date | string, to?: Date | string, max?: number | null }>()
);

export const GetHistoricCpuDataSuccess = createAction(
  '[HistoricCpuData] Get HistoricCpuDatas Success',
  props<{ data:any }>()
);

export const GetHistoricCpuDataFailure = createAction(
  '[HistoricCpuData] Get HistoricCpuDatas Failure',
  props<{ error: any }>()
);
