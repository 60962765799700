import {
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingList,
    DashletSettingText
} from '@models/index';
import { DashletSettingsService } from '@services/index';

export class DashletSettingsFormRibbonSystemHealth extends DashletSettingsForm {
    private customer!: DashletSettingDropdown;
    private location!: DashletSettingDropdown;

    constructor(private readonly settingsService: DashletSettingsService) {
        super();

        this.init();
        this.loadCustomers();
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);

        if (this.customer.value) this.loadLocations(this.customer.value);
    }

    private init(): void {
        const group = new DashletSettingsGroup();
        const s: any = (group.settings = []);

        const customer = (this.customer = new DashletSettingDropdown());
        customer.label = 'Customer';
        customer.stateKey = 'customer';
        customer.disabled = true;
        customer.change.subscribe(() => {
            if (!this.location.value) this.clearAndDisable(this.location);
            this.loadLocations(customer.value!);
        });
        s.push(customer);

        const location = (this.location = new DashletSettingDropdown());
        location.label = 'Location';
        location.stateKey = 'location';
        location.disabled = true;
        s.push(location);

        const customName = new DashletSettingText(40);
        customName.label = 'Custom Dashlet Name';
        customName.stateKey = 'customName';
        s.push(customName);

        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);
        this.settingsService.getUserAssociatedCustomers().subscribe(
            customers => {
                this.customer.items = customers;
                this.customer.loadingComplete();
            },
            () => {
                this.customer.loadingComplete();
                this.customer.disabled = true;
                //show error notification here
            }
        );
    }

    private loadLocations(customer: string): void {
        this.enableAndLoading(this.location);
        this.settingsService.getLocations(customer).subscribe(
            locations => {
                locations.unshift({ label: 'All', value: '=all=' });
                this.location.items = locations;
                this.location.loadingComplete();
            },
            () => {
                this.location.loadingComplete();
                this.location.disabled = true;
                //show error notification here
            }
        );
    }

    private clearAndDisable(widget: DashletSettingDropdown | DashletSettingList<string>) {
        widget.disabled = true;
        widget.items = [];
        if (widget instanceof DashletSettingList) widget.values = [];
        else widget.value = null;
    }

    private enableAndLoading(widget: DashletSettingDropdown | DashletSettingList<string>) {
        widget.disabled = false;
        widget.loadingBegin();
    }
}
