import { Customer, Dashlet } from '@models/index';
import { Subject } from 'rxjs';

interface Service {
    serviceName: string;
    state: string;
    type: string;
}

export interface CdrLink {
    Name: string;
    Status: string;
    CDRPercentageBufferFull: number;
}

export interface MediaGateway {
    Server: string;
    Port: number;
    IpAddress: string;
    Ipnr: number;
    ControlIPAddress: string;
    AlarmsMajor: number;
    AlarmsMinor: number;
    AlarmsWarning: number;
    LinkStatus: string;
    Type: string;
}

export interface Snmp {
    Name: string;
    Daemon: string;
    PeakToneReceivers: string;
}

export interface Duplication {
    ClusterId: string;
    Duplication: string;
    StandbyBusied: string;
    StandbyRefreshed: string;
    StandbyShadowing: string;
    DuplicationLink: string;
    ElapsedTimeSinceInterchange: string;
}

export interface StandBy {
    Server: string;
    ServerID: string;
    Mode: string;
    MajorAlarms: string;
    MinorAlarms: string;
    ControlNetwork: string;
    ProcessorEthernet: string;
    PEPriority: string;
    ServerHardware: string;
    Processes: string;
}

export interface Lsp {
    Active: string;
    Hardware: string;
    IPAddress: string;
    Ipnr: number | null;
    Registered: string;
    Server: string;
    TranslationsUpdated: string;
}

export interface Pn {
    Port: string;
    IPAddress: string;
    ServerState: string;
    ControlState: string;
}

export interface MediaServer {
    Number: number;
    SignallingGroup: number;
    NodeName: string;
    Limit: number;
    InUse: number;
    IPNR: number;
    Location: number;
    State: string;
}

export interface License {
    CommuncationManagerLicenseMode: string;
    WebLMServer: string;
}

export interface AcmSystemHealthSummary {
    severity: number;
    equipmentId: string;
    name: string;
    cpuPercentage: number;
    memoryPercentage: number;
    diskPercentage: number;
    averagePing: number;
    servicesUp: Service[];
    servicesDown: Service[];
    linkStatus: number;
    licenseStatus: number;
    snmpUp: Snmp[];
    snmpDown: Snmp[];
    snmpStatus: number;
    standByStatus: number;
    duplicationStatus: number;
    lspStatus: number;
    lsp: Lsp;
    mediaServer: MediaServer[];
    mediaGatewaysUp: MediaGateway[];
    mediaGatewaysDown: MediaGateway[];
    mediaGatewaysNoData: MediaGateway[];
    standBy: StandBy[];
    duplication: Duplication;
    mediaServerStatus: number;
    pn: Pn[];
    pnStatus: number;
    cdrLink: CdrLink[];
    license: License;
}

export class DashletACMSystemHealthSummary extends Dashlet {
    public readonly commandTypeIdSystemHealthSummary = '4EF0FF09-F8B5-4C33-83E2-C4D6F510D14C';
    public readonly commandTypeIdACMSystemHealth = 'DD0E7FA6-F8A6-4F91-8075-00347D45A7AA';

    public tableHeightRows = 1;
    public customer!: Customer;
    public locationId!: string;
    public settingsUpdated: Subject<null> = new Subject();

    constructor() {
        super();

        this.sizes = [
            {
                id: 2,
                label: 'Small',
                cols: 15,
                rows: 2
            },
            {
                id: 1,
                label: 'Medium',
                cols: 15,
                rows: 3
            },
            {
                id: 0,
                label: 'Large',
                cols: 15,
                rows: 7
            }
        ];

        this.applySize(0);
        this.customer = new Customer('', '');
    }

    public applySettings(v: { [key: string]: any }): void {
        super.applySettings(v);

        this.configured = v.customer && v.location;

        if (this.configured) {
            if (
                (v.location && v.location.value && this.locationId !== v.location.value) ||
                (v.customer && v.customer.value !== this.customer.customerId)
            ) {
                this.customer = new Customer(v.customer.value, v.customer.label);
                this.locationId = v.location.value ? v.location.value : null;
                this.generatedNameTag = this.configured ? `${v.location.label}` : 'Unconfigured';
                this.customNameTag = v.nameTag;
                this.settingsUpdated.next(null);
            }
        }

        this.updateSize();
    }

    public applySize(id: number): void {
        if (id === 0) {
            this.tableHeightRows = 16;
        } else if (id === 1) {
            this.tableHeightRows = 2;
        } else if (id === 2) {
            this.tableHeightRows = 1;
        }
        super.applySize(id);
        this.updateSize();
    }

    private updateSize(): void {
        const h = 0;
        const w = 0;
        this.applySizeExpansion(w, h);
    }

    public resetData(): void {}

    public dispose(): void {}
}
