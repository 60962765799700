import { Point } from '../interfaces/Interfaces';
import { Regex, Sort } from '../../shared/SharedMethods';

export class MemoryUsageData {
    private _memoryUsageHistory: Point[];
    commandTypeId: string;
    thresholdsNumber: number;
    dataExpired: boolean;
    /**
     *
     * @param memoryUsage
     * @param memoryUsageHistory
     * @param totalMemory
     */
    constructor(commandTypeId: string) {
        this._memoryUsageHistory = [];
        this.commandTypeId = commandTypeId;
        this.thresholdsNumber = 0;
        this.dataExpired = true;
    }

    public getCommandTypeId() {
        return this.commandTypeId;
    }

    public updateMemoryUsageHistory(time: Date, memoryUsagePercentage: string) {
        if (Regex.stringIsNumber(memoryUsagePercentage)) {
            if (this._memoryUsageHistory.length > 20) {
                this.memoryUsageHistory.shift();
            }
            this.memoryUsageHistory.push({ x: time, y: +Number.parseFloat(memoryUsagePercentage).toPrecision(2) });
        }
    }

    public refreshMemoryUsageData() {
        this.updateMemoryUsageHistory(new Date(), this.getCurrentMemoryUsagePercentage());
    }

    public getCurrentMemoryUsageProportion() {
        if (this._memoryUsageHistory.length > 0 && !this.dataExpired) {
            return (this._memoryUsageHistory[this._memoryUsageHistory.length - 1].y / 100).toString();
        } else {
            return '---';
        }
    }

    public getCurrentMemoryUsagePercentage() {
        if (this._memoryUsageHistory.length > 0 && !this.dataExpired) {
            return this._memoryUsageHistory[this._memoryUsageHistory.length - 1].y.toString();
        } else {
            return '---';
        }
    }

    /**
     *
     */
    public getMemoryUsageSeverity() {
        const memoryUsageProportion = this.getCurrentMemoryUsageProportion();
        if (Regex.stringIsNumber(memoryUsageProportion)) {
            if (+memoryUsageProportion < 0.8) {
                return 0;
            } else if (+memoryUsageProportion < 0.9) {
                return 1;
            } else {
                return 2;
            }
        }
        return;
    }

    /**
     * Getter memoryUsageHistory
     * @return
     */
    public get memoryUsageHistory(): Point[] {
        return this._memoryUsageHistory;
    }
}
