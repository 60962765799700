import { SelectItemGeneric, DashletSetting, DashletSettingLoadable } from '@models/index';

export class DashletSettingList<T> extends DashletSetting implements DashletSettingLoadable {
    items: SelectItemGeneric<T>[] = [];
    multiple = false;
    filter = false;
    metaKeySelection = true;
    disabled!: boolean;
    removeSelectAll: boolean = false;
    limitSelection: number | null = null;

    private loadingSem = 0;

    private pValues!: T[];
    get values(): T[] {
        return this.pValues;
    }
    set values(value: T[]) {
        this.pValues = value;
        // clear loading items if selection changes
        if (this.pLoadingItems) {
            if (value === null || value.length < 1 || !this.pLoadingItems.every(i => value.some(v => i.value === v))) {
                this.pLoadingItems = [];
            }
        }
    }

    private pLoadingItems!: SelectItemGeneric<T>[];
    get loadingItems(): SelectItemGeneric<T>[] {
        return this.pLoadingItems;
    }

    getLoading() {
        return this.loadingSem > 0;
    }

    loadingBegin() {
        this.loadingSem++;
    }

    loadingComplete() {
        if (this.loadingSem > 0) {
            this.loadingSem--;
        }
        this.change.next(null);
    }

    loadState(obj: { [key: string]: any }): void {
        const s = obj[this.stateKey];
        if (s?.length) {
            this.pLoadingItems = s;
            this.pValues = s.map(i => i.value);
        } else {
            this.pLoadingItems = [];
            this.pValues = [];
        }
    }

    saveState(obj: { [key: string]: any }): void {
        obj[this.stateKey] = this.getSelectedItems();
    }

    clear(): void {
        this.items = [];
        this.values = [];
    }
    getSelectedItems(): SelectItemGeneric<T>[] {
        // check for null selections
        if (!this.values) {
            return [];
        }

        return this.values.map(v => {
            // find item from value
            let item = null;
            if (this.items) {
                item = this.items.find(item => item.value === v);
            }
            if (!item && !this.loadingItems) {
                item = this.loadingItems.find(item => item.value === v);
            }

            // create default item if cannot find item
            if (!item) {
                item = { label: 'Unknown', value: v };
            }
            return item;
        });
    }
}
