import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

export const GetNotifyProcessLyncMedServerData = createAction(
  '[ProcessLyncMedServerData] Get Notify ProcessLyncMedServerDatas',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetNotifyProcessLyncMedServerDataSuccess = createAction(
  '[ProcessLyncMedServerData] Get Notify ProcessLyncMedServerDatas Success',
  props<{ data: RealTimeNotify }>()
);

export const GetNotifyProcessLyncMedServerDataFailure = createAction(
  '[ProcessLyncMedServerData] Get Notify ProcessLyncMedServerDatas Failure',
  props<{ error: any }>()
);

export const GetProcessLyncMedServerData = createAction(
  '[ProcessLyncMedServerData] Get ProcessLyncMedServerDatas',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetProcessLyncMedServerDataSuccess = createAction(
  '[ProcessLyncMedServerData] Get ProcessLyncMedServerDatas Success',
  props<{ data: any}>()
);

export const GetProcessLyncMedServerDataFailure = createAction(
  '[ProcessLyncMedServerData] Get ProcessLyncMedServerDatas Failure',
  props<{ error: any }>()
);
