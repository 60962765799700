import * as DashboardActions from '@actions/dashboard/dashboard.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { Dashboard, DashboardState, Dashlet, DashletMeta } from '@models/index';
import * as UserContextActions from '@actions/user-context/user-context.actions';

export const dashboardFeatureKey = 'dashboard';

export interface State {
    dashletMetaData: DashletMeta[] | [];
    dashboardState: DashboardState;
    dashboard: Dashboard | null;
    dashlet: Dashlet[] | [];
    userContext: any;
}

export const initialState: State = {
    dashletMetaData: [],
    dashboardState: {} as DashboardState,
    dashboard: null,
    dashlet: [],
    userContext: null
};

const convertToJsonObj = (obj: any) => {
    const temp = { ...obj };
    const state = JSON.parse(temp.state);
    temp.state = state;
    return temp;
};

const delDashlet = (dashlets: Dashlet[], dashlet: any) => {
    const temp = [...dashlets];
    temp.splice(
        temp.findIndex(function (i) {
            return i.id.toLowerCase() === dashlet.toLowerCase();
        }),
        1
    );
    return temp;
};

const updateDashlet = (dashlets: Dashlet[], dashlet: Dashlet) => {
    const temp = [...dashlets];
    temp.splice(
        temp.findIndex(function (i) {
            return i.id.toLowerCase() === dashlet.id.toLowerCase();
        }),
        1,
        dashlet
    );
    return temp;
};

const mergeUnique = (a, b, p) => a.filter(aa => !b.find(bb => aa[p] === bb[p])).concat(b);

export const reducer = createReducer(
    initialState,
    on(DashboardActions.GetDashletsMetaSuccess, (state, { data }) => ({ ...state, dashletMetaData: data })),
    on(DashboardActions.GetDashletsMetaFailure, (state, { error }) => ({ ...state, dashletMetaData: [] })),
    on(DashboardActions.LoadDashboardSuccess, (state, { dashboard, dashboardState }) => ({
        ...state,
        dashboard: convertToJsonObj(dashboard),
        dashboardState: dashboardState
    })),
    on(DashboardActions.CreateDashletsFromStateSuccess, (state, { data }) => ({
        ...state,
        dashlet: mergeUnique(state.dashlet, data, 'id')
    })),
    on(UserContextActions.GetUserContextsSuccess, (state, { data }) => ({ ...state, userContext: data.info })),
    on(DashboardActions.DeleteDashboardSuccess, (state, { data }) => ({
        ...state,
        dashlet: delDashlet(state.dashlet, data)
    })),
    on(DashboardActions.UpdateDashboardStateSuccess, (state, { data }) => ({
        ...state,
        dashboardState: data
    })),
    on(DashboardActions.UpdateDashletsSuccess, (state, { data }) => ({
        ...state,
        dashboardState: data
    }))
);
