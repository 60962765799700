import * as Actions from '@actions/index';
import { Component, Input, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ChartCell, ColumnTypes, DashletIPNRBandwidth, IPNRBandwidthData, SeverityRatioBar } from '@models/index';
import { DashletService } from '@services/index';
import { saveAs } from 'file-saver';
import { select, Store } from '@ngrx/store';

import { AppState, selectEntity, selectDataFromCommonEntity } from '@reducers/index';

import { CustomerLogo } from '@models/index';
import { HttpClient } from '@angular/common/http';
import { UIChart } from 'primeng/chart';
import { Table } from 'primeng/table';
import { DashletBandwidthPipe } from 'src/app/pipes/dashlet-bandwidth.pipe';
import { Subscription } from 'rxjs';

@Component({
    selector: 'dashlet-ipnr-bandwidth',
    templateUrl: 'dashlet-ipnr-bandwidth.component.html',
    styleUrls: ['dashlet-ipnr-bandwidth.component.scss']
})
export class DashletIPNRBandwidthComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletIPNRBandwidth;
    @ViewChild('dt', { static: false }) dt: Table;

    bandwidthOverTimeLine: UIChart;
    bandwidthOverTimeLineOptions: any;

    public columns: ColumnTypes[] = [
        {
            columnDef: 'expand',
            header: ''
        },
        {
            columnDef: 'IPNRNumber',
            header: 'Intv. IPNR',
            filterType: 'text',
            headerTooltip: this.getIPNRTooltip(),
            cell: (row: IPNRBandwidthData) => row.IPNRNumber
        },
        {
            columnDef: 'IPNRName',
            header: 'IPNR Name',
            filterType: 'text',
            cell: (row: IPNRBandwidthData) => row.IPNRName
        },
        {
            columnDef: 'branches',
            header: 'Branches',
            headerTooltip: this.getBranchTooltip(),
            cell: (row: IPNRBandwidthData) => row.branches,
            type: 'numeric'
        },
        {
            columnDef: 'streams',
            header: 'Streams',
            headerTooltip: this.getStreamsTooltip2(),
            cell: (row: IPNRBandwidthData) => row.streams,
            type: 'numeric'
        },
        {
            columnDef: 'mos',
            header: 'MOS',
            headerTooltip: this.getMOSTooltip(),
            cell: (row: IPNRBandwidthData): SeverityRatioBar[] => {
                return [
                    {
                        value: row.mosgood,
                        colour: this.dashletService.getSeverityColor(0),
                        tooltip: `${((row.mosgood / row.streams) * 100).toFixed(2)}%`
                    },
                    {
                        value: row.moswarning,
                        colour: this.dashletService.getSeverityColor(1),
                        tooltip: `${((row.moswarning / row.streams) * 100).toFixed(2)}%`
                    },
                    {
                        value: row.mosdanger,
                        colour: this.dashletService.getSeverityColor(2),
                        tooltip: `${((row.mosdanger / row.streams) * 100).toFixed(2)}%`
                    }
                ];
            },
            type: 'ratio'
        }
    ];

    public rowExpansionColumns: ColumnTypes[] = [
        {
            columnDef: 'branch',
            header: 'Branch',
            cell: (row: IPNRBandwidthData) => (row.IPNRName === 'Internal' ? 'Internal' : row.IPNRNumber)
        },
        {
            columnDef: 'streams',
            header: 'Streams',
            cell: (row: IPNRBandwidthData) => (row.streams === 0 ? 'N/A' : row.streams)
        },
        {
            columnDef: 'peakUtil',
            header: 'Peak Util',
            cell: (row: IPNRBandwidthData) =>
                row.IPNRName === 'Internal' ? 'N/A' : this.bandwidthPipe.transform(row.peakUtil)
        },
        {
            columnDef: 'MosGood',
            header: 'Mos >= 4.0',
            cell: (row: IPNRBandwidthData): string => `${row.streams === 0 ? 'N/A' : row.mosgood}`,
            type: 'numeric'
        },
        {
            columnDef: 'MosWarning',
            header: 'Mos 3.6 - 4.0',
            cell: (row: IPNRBandwidthData): string => `${row.streams === 0 ? 'N/A' : row.moswarning}`,
            type: 'numeric'
        },
        {
            columnDef: 'MosDanger',
            header: 'Mos <3.6',
            cell: (row: IPNRBandwidthData): string => `${row.streams === 0 ? 'N/A' : row.mosdanger}`,
            type: 'numeric'
        },
        {
            columnDef: 'percentage',
            header: 'Percentage',
            cell: (row: IPNRBandwidthData): SeverityRatioBar[] => {
                return [
                    {
                        value: row.mosgood,
                        colour: this.dashletService.getSeverityColor(0),
                        tooltip: `${((row.mosgood / row.streams) * 100).toFixed(2)}%`
                    },
                    {
                        value: row.moswarning,
                        colour: this.dashletService.getSeverityColor(1),
                        tooltip: `${((row.moswarning / row.streams) * 100).toFixed(2)}%`
                    },
                    {
                        value: row.mosdanger,
                        colour: this.dashletService.getSeverityColor(2),
                        tooltip: `${((row.mosdanger / row.streams) * 100).toFixed(2)}%`
                    }
                ];
            },
            type: 'ratio'
        },
        {
            columnDef: 'graphData',
            header: `Bandwidth`,
            cell: (row: any): ChartCell => {
                return {
                    options: this.bandwidthOverTimeLineOptions,
                    data: row.graphData
                };
            },
            type: 'chart'
        }
    ];
    private subscription: Subscription = new Subscription();
    constructor(
        private dashletService: DashletService,
        private http: HttpClient,
        private store$: Store<AppState>,
        private bandwidthPipe: DashletBandwidthPipe,
        private cdr: ChangeDetectorRef
    ) {}

    getLayout() {
        const s = this.dashlet.getSize();
        if (s !== null) {
            return s.id;
        }
        return -1;
    }

    ngOnInit() {
        this.bandwidthOverTimeLineOptions = {
            scales: {
                x: {
                    ticks: {
                        display: false
                    }
                },
                y: {
                    ticks: {
                        fontSize: 10.2,
                        beginAtZero: true
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'kb/s'
                    }
                }
            },
            plugins: {
                legend: {
                    display: false,
                    position: 'left',
                    labels: {
                        boxWidth: 15
                    }
                },
                tooltipCaretSize: 0,
                tooltip: {
                    mode: 'index'
                }
            },

            maintainAspectRatio: false,

            layout: {
                padding: {
                    bottom: 0,
                    top: 5,
                    left: 5,
                    right: 10
                }
            }
        };

        if (this.dashlet.customer.customerId && this.dashlet.locationId && this.dashlet.equipmentId) {
            this.dashlet.resetData();
            this.dashlet.documentTimeStamps = [];
            // Logo
            this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
            this.subscription.add(
                this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                    if (logo) {
                        this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                    }
                })
            );

            // set minimum date time to request documents from
            const minDate = new Date();
            minDate.setMinutes(0, 0, 0);
            minDate.setHours(minDate.getHours() - (parseInt(this.dashlet.summaryPeriod.value) - 1));

            // IPNR Bandwidth
            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: this.dashlet.equipmentId,
                    command: this.dashlet.commandTypeIdIPNRBandwidth
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: this.dashlet.equipmentId,
                    commandTypeId: this.dashlet.commandTypeIdIPNRBandwidth
                })
            );

            this.subscription.add(
                this.store$
                    .pipe(
                        select(
                            selectDataFromCommonEntity(
                                this.dashlet.equipmentId + this.dashlet.commandTypeIdIPNRBandwidth
                            )
                        )
                    )
                    .subscribe(data => {
                        if (data) {
                            this.cdr.detectChanges();
                            this.dashlet.processData(data);
                        }
                    })
            );

            this.store$.dispatch(
                Actions.GetCommonHistoric({
                    equipmentId: this.dashlet.equipmentId,
                    commandTypeId: this.dashlet.commandTypeIdIPNRBandwidth,
                    from: minDate.toISOString(),
                    to: new Date().toISOString(),
                    max: this.dashlet.summaryPeriod.value
                })
            );
        }
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.dashlet.dispose();
    }

    getSeverityBarPercentage(severity: number, total: number) {
        let percentage = (severity / total) * 100;
        return isNaN(percentage) ? '0%' : percentage.toFixed(1) + '%';
    }

    hasChildren(ipnr: IPNRBandwidthData): boolean {
        return ipnr.branches === 0 ? false : true;
    }

    downloadSchematic() {
        return this.http
            .get(
                '/VirsaeFileManager/VirsaeFileManager/DownloadSelectedFile?entityId=' +
                    this.dashlet.customerId +
                    '&selectedFileId=' +
                    this.dashlet.schematicBlobId,
                { responseType: 'blob' }
            )
            .subscribe((result: any) => {
                let blob = new Blob([result], { type: 'application/zip' });
                saveAs(blob, this.dashlet.schematicFileName + '.zip');
            });
    }

    getDashletTooltip(param: string) {
        switch (param) {
            case 'ipnrnumber':
                return this.getIPNRTooltip();
            case 'branches':
                return this.getBranchesTooltip();
            case 'ipnrbranches':
                return this.getBranchTooltip();
            case 'streams':
                return this.getStreamsTooltip();
            case 'ipnrstreams':
                return this.getStreamsTooltip2();
            case 'mos':
                return this.getMOSTooltip();
            case 'capacity':
                return this.getCapacityTooltip();
        }
    }

    getIPNRTooltip() {
        return (
            'An Intervening IPNR is defined as an IP Network Region indirectly connected to one or more other IP Network Regions, where the intervening IPNR has the most connections.\n' +
            'Visually, an intervening IPNR is depicted as a cloud in the Network Region schematic.'
        );
    }

    getBranchesTooltip() {
        return 'Branches are the IPNRs linked to the intervening IPNR. Endpoints within a single floor, site, or branch office are often members of a branch IPNR.';
    }

    getBranchTooltip() {
        return "A branch IPNR refers to all IPNRs associated with the intervening (parent) IPNR.  Any branch IPNRs labelled 'Internal' refers to the intervening IPNR, that is, traffic originating, terminating, or occurring entirely within the parent Intervening IPNR. This can be indicative of a configuration problem and should be reviewed.";
    }

    getStreamsTooltip() {
        return (
            'For Intervening IP Network Regions, the number of streams refers to the total number of streams passing through, or limited to within that IPNR.\n' +
            'In an ideal configuration, there should be no calls originating or terminating within the intervening Network Region.'
        );
    }

    getStreamsTooltip2() {
        return 'The number of streams passing through Branch IPNR and intervening IPNR.';
    }

    getMOSTooltip() {
        return 'MOS is calculated as the total MOS across the entire call path, and not just the hop between branch and intervening IPNR.';
    }

    getCapacityTooltip() {
        return 'The maximum bandwidth received, or transmitted during the selected summary period as specified in the dashlet settings.';
    }
}
