import * as Actions from '@actions/index';
import { DashletService } from '@services/index';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CustomerLogo, DashletSystemHealthAudioCodes } from '@models/index';
import { MenuItem } from 'primeng/api';
import { select, Store } from '@ngrx/store';
import { AppState, selectEntity, selectDataFromCommonEntity } from '@reducers/index';
import { Subscription } from 'rxjs';

@Component({
    selector: 'dashlet-system-health-audio-codes-sbc',
    templateUrl: 'dashlet-system-health-audio-codes-sbc.component.html',
    styleUrls: ['dashlet-system-health-audio-codes-sbc.component.scss']
})
export class DashletSystemHealthAudioCodesComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletSystemHealthAudioCodes;

    constructor(private dashletService: DashletService, private store$: Store<AppState>) {}

    openSubcontainer = '';
    occupancyContextMenuItems: MenuItem[];
    openSubcontainerSeverity = 0;

    private subscription: Subscription = new Subscription();

    public ngOnDestroy(): void {
        this.subscription.unsubscribe;
    }
    public ngOnInit(): void {
        // Logo
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );

        // Device Version Number
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdDeviceVersion
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdDeviceVersion
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdDeviceVersion
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length && data[0].data) {
                        this.dashlet.processVersionData(data[0].data[0]);
                    }
                })
        );

        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdIpInterface
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdIpInterface
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdIpInterface
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length && data[0].data) {
                        this.dashlet.processIPInterfaceData(data[0].data);
                    }
                })
        );

        //Ethernet
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdEthernet
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdEthernet
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdEthernet
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length && data[0].data) {
                        this.dashlet.processEthernetData(data[0].data);
                    }
                })
        );
    }

    public openDetails(container: string, severity: number): void {
        this.openSubcontainer = container;
        this.dashlet.displayHeader = false;
        this.openSubcontainerSeverity = severity;
    }

    public getSeverityColor(sev: number): string {
        return this.dashletService.getSeverityColor(sev);
    }

    public closeDetails(): void {
        this.openSubcontainer = null;
        this.dashlet.displayHeader = true;
    }

    public getEthernetActive(): number {
        let count = 0;
        if (this.dashlet.ethernetData.length === 0) return count;

        this.dashlet.ethernetData.forEach(item => {
            if (item.Active === '1') {
                count++;
            }
        });

        return count;
    }

    public getEthernetInActive(): number {
        let count = 0;
        if (this.dashlet.ethernetData.length === 0) return count;

        this.dashlet.ethernetData.forEach(item => {
            if (item.Active === '0') {
                count++;
            }
        });

        return count;
    }

    public getIpInterfaceActive(): number {
        let count = 0;
        if (this.dashlet.ipInterfaceData.length === 0) return count;

        this.dashlet.ipInterfaceData.forEach(item => {
            if (item.ActiveState === 'active') {
                count++;
            }
        });

        return count;
    }

    public getIpInterfaceNIS(): number {
        let count = 0;
        if (this.dashlet.ipInterfaceData.length === 0) return count;

        this.dashlet.ipInterfaceData.forEach(item => {
            if (item.ActiveState === 'not in service') {
                count++;
            }
        });

        return count;
    }

    public getIpInterfaceNotReady(): number {
        let count = 0;
        if (this.dashlet.ipInterfaceData.length === 0) return count;

        this.dashlet.ipInterfaceData.forEach(item => {
            if (item.ActiveState === 'no ready') {
                count++;
            }
        });

        return count;
    }

    public getEthernetDrillDownSelected(mode: number): any[] {
        // 0 - active ||| 1 - Inactive
        var result = [];
        const stringToFind = mode === 0 ? '1' : '0';

        this.dashlet.ethernetData.forEach(item => {
            if (item.Active === stringToFind) {
                result.push(item);
            }
        });

        return result;
    }

    public getIPInterfaceDrillDownSelected(mode: number): any[] {
        // 0-active 1-not in service 2-not ready
        var result = [];
        const stringToFind = mode === 0 ? 'active' : mode === 1 ? 'not in service' : mode === 2 ? 'no ready' : 'Error';

        this.dashlet.ipInterfaceData.forEach(item => {
            if (item.ActiveState === stringToFind) {
                result.push(item);
            }
        });

        return result;
    }

    public getVersionTooltip(): string {
        let result = '';

        if (this.dashlet.deviceVersionInfo.serialNumber === '-' && this.dashlet.deviceVersionInfo.productType === '-') {
            return 'No version data available';
        }

        let index = 0;
        const titles: any[] = ['Model:', 'IP Address:', 'Serial Number:'];
        const codes = [
            this.dashlet.deviceVersionInfo.productType,
            this.dashlet.deviceVersionInfo.ipAddress,
            this.dashlet.deviceVersionInfo.serialNumber
        ];

        for (let code of codes) {
            if (code !== '-') {
                result += `<span>${titles[index]} ${code}</span><br>`;
            }
            index++;
        }

        return result;
    }
}
