export class RemoteLocation {
	private _locationId: string;
	private _locationName: string;
	private _timeZone: string;

	constructor(remoteLocationId: string, remoteLocationName: string, timeZone: string) {
		this._locationId = remoteLocationId;
		this._locationName = remoteLocationName;
		this._timeZone = timeZone;
	}

	/**
	 * Getter locationId
	 * @return {string}
	 */
	public get locationId(): string {
		return this._locationId;
	}

	/**
	 * Getter locationName
	 * @return {string}
	 */
	public get locationName(): string {
		return this._locationName;
	}

	/**
	 *
	 */
	public get timeZone(): string {
		return this._timeZone;
	}
}
