export class LicenceData {
	private _licenceName!: string;
	private _acquired!: number;
	private _total!: number;

	constructor(licenceName: string, acquired: number, total: number) {
		this.licenceName = licenceName;
		this.acquired = acquired;
		this.total = total;
	}

	/**
	 *
	 * @param licence
	 */
	public updateFromObject(licence: LicenceData) {
		this.licenceName = licence.licenceName;
		this.acquired = licence.acquired;
		this.total = licence.total;
	}

	public severity(): number {
		const usagePropotion = this.acquired / this.total;
		if (usagePropotion < 0.8) {
			return 0;
		} else if (usagePropotion < 0.9) {
			return 1;
		} else {
			return 2;
		}
	}

	/**
	 * Getter licenceName
	 * @return {string}
	 */
	public get licenceName(): string {
		return this._licenceName;
	}

	/**
	 * Getter acquired
	 * @return {number}
	 */
	public get acquired(): number {
		return this._acquired;
	}

	/**
	 * Getter total
	 * @return {number}
	 */
	public get total(): number {
		return this._total;
	}

	/**
	 * Setter licenceName
	 * @param {string} value
	 */
	public set licenceName(value: string) {
		if (value == null || value == '') {
			value = '---';
		}
		this._licenceName = value;
	}

	/**
	 * Setter acquired
	 * @param {number} value
	 */
	public set acquired(value: number) {
		if (value == null || value.toString() == '') {
			value = 0;
		}
		this._acquired = value;
	}

	/**
	 * Setter total
	 * @param {number} value
	 */
	public set total(value: number) {
		if (value == null || value.toString() == '') {
			value = 0;
		}
		this._total = value;
	}
}
