import { environment } from '@environments/environment';
import { Dashlet } from '@models/index';
import { RealtimeGatewayService } from '@services/index';
import { Subject } from 'rxjs';

interface Point {
    x: Date;
    y: number;
}

export class DashletPortsPerCustomer extends Dashlet {
    public _commandTypeIdPort = '3496E39F-8637-4F04-8171-4FF590557961';
    public _equipmentId = '445bb159-5bae-42ce-b568-58d84617bdfc';

    public groupName!: string;

    private _avgPortsPerCustomerArrayEMEA: Point[] = [];
    private _avgPortsPerCustomerArrayNA: Point[] = [];
    private _avgPortsPerCustomerArrayAPAC: Point[] = [];
    private _avgPortsPerCustomerArrayAll: Point[] = [];

    private _avgPortsLatestAll = 0;
    private _avgPortsLatestAPAC = 0;
    private _avgPortsLatestEMEA = 0;
    private _avgPortsLatestNA = 0;

    private _chartDataUpdated: Subject<null> = new Subject();
    get onChartDataUpdated() {
        return this._chartDataUpdated;
    }

    constructor(private realtimeService: RealtimeGatewayService) {
        super();
        // sizing
        this.sizes = [
            {
                id: 0,
                label: 'Small',
                cols: 5,
                rows: 2
            },
            {
                id: 1,
                label: 'Medium',
                cols: 5,
                rows: 5
            },
            {
                id: 2,
                label: 'Large',
                cols: 10,
                rows: 5
            }
        ];
        this.applySize(1);

        // init data
        this.resetData();
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);
        this.configured = true;
    }

    applySize(id: number) {
        super.applySize(id);
        this.updateSize();
    }

    private updateSize() {
        const h = 0;
        const w = 0;
        this.applySizeExpansion(w, h);
    }

    public processPortData(data: any[]) {
        this.resetData();
        const results = data[0].data;
        if (results !== null) {
            const startDate = new Date(); // sets to current date

            startDate.setDate(startDate.getDate() - 40); // date we want to start at
            let currentPeriod: any = null;
            let avgPortsPerCustomerEMEA: any = null;
            let avgPortsPerCustomerNA: any = null;
            let avgPortsPerCustomerAPAC: any = null;
            let avgPortsPerCustomerAll: any = null;
            const periodStart = new Date();

            results.forEach((row: any) => {
                // Convert date from RTG to Date object format. RTG date is in US format
                let dateSplit = row.PeriodStart.split('/', 3);
                periodStart.setDate(dateSplit[1]);
                periodStart.setMonth(dateSplit[0] - 1);
                dateSplit = dateSplit[2].split(' ', 1);
                periodStart.setFullYear(dateSplit[0]);

                if (startDate < periodStart) {
                    // New day and not the first day
                    if (currentPeriod < periodStart && currentPeriod !== null) {
                        this._avgPortsPerCustomerArrayAll.push({ x: currentPeriod, y: avgPortsPerCustomerAll });
                        this._avgPortsPerCustomerArrayNA.push({ x: currentPeriod, y: avgPortsPerCustomerNA });
                        this._avgPortsPerCustomerArrayAPAC.push({ x: currentPeriod, y: avgPortsPerCustomerAPAC });
                        this._avgPortsPerCustomerArrayEMEA.push({ x: currentPeriod, y: avgPortsPerCustomerEMEA });
                        // Get latest value for each region unless it's null
                        this._avgPortsLatestEMEA = avgPortsPerCustomerEMEA
                            ? avgPortsPerCustomerEMEA
                            : this._avgPortsLatestEMEA;
                        this._avgPortsLatestNA = avgPortsPerCustomerNA ? avgPortsPerCustomerNA : this._avgPortsLatestNA;
                        this._avgPortsLatestAPAC = avgPortsPerCustomerAPAC
                            ? avgPortsPerCustomerAPAC
                            : this._avgPortsLatestAPAC;
                        this._avgPortsLatestAll = avgPortsPerCustomerAll
                            ? avgPortsPerCustomerAll
                            : this._avgPortsLatestAll;
                        // reset values for new day
                        avgPortsPerCustomerEMEA = null;
                        avgPortsPerCustomerNA = null;
                        avgPortsPerCustomerAPAC = null;
                        avgPortsPerCustomerAll = null;
                    }

                    switch (row.RegionShortName) {
                        case 'All':
                            avgPortsPerCustomerAll = row.AvgPortsPerCustomer;
                            break;
                        case 'NA':
                            avgPortsPerCustomerNA = row.AvgPortsPerCustomer;
                            break;
                        case 'APAC':
                            avgPortsPerCustomerAPAC = row.AvgPortsPerCustomer;
                            break;
                        case 'EMEA':
                            avgPortsPerCustomerEMEA = row.AvgPortsPerCustomer;
                            break;
                        case 'null':
                            break;
                    }
                    currentPeriod = new Date(periodStart);
                }
            });
            // Get the last days values if we have them all
            if (
                avgPortsPerCustomerAll !== null &&
                avgPortsPerCustomerAPAC !== null &&
                avgPortsPerCustomerEMEA !== null &&
                avgPortsPerCustomerNA !== null
            ) {
                this._avgPortsPerCustomerArrayAll.push({ x: currentPeriod, y: avgPortsPerCustomerAll });
                this._avgPortsPerCustomerArrayNA.push({ x: currentPeriod, y: avgPortsPerCustomerNA });
                this._avgPortsPerCustomerArrayAPAC.push({ x: currentPeriod, y: avgPortsPerCustomerAPAC });
                this._avgPortsPerCustomerArrayEMEA.push({ x: currentPeriod, y: avgPortsPerCustomerEMEA });
                // Get latest value for each region unless it's null
                this._avgPortsLatestEMEA = avgPortsPerCustomerEMEA ? avgPortsPerCustomerEMEA : this._avgPortsLatestEMEA;
                this._avgPortsLatestNA = avgPortsPerCustomerNA ? avgPortsPerCustomerNA : this._avgPortsLatestNA;
                this._avgPortsLatestAPAC = avgPortsPerCustomerAPAC ? avgPortsPerCustomerAPAC : this._avgPortsLatestAPAC;
                this._avgPortsLatestAll = avgPortsPerCustomerAll ? avgPortsPerCustomerAll : this._avgPortsLatestAll;
            }
        } else {
            if (!environment.production) console.error('RTG CONNECTION ERROR');
        }

        this._chartDataUpdated.next(null);
    }

    resetData() {
        this._avgPortsPerCustomerArrayEMEA = [];
        this._avgPortsPerCustomerArrayNA = [];
        this._avgPortsPerCustomerArrayAPAC = [];
        this._avgPortsPerCustomerArrayAll = [];
        this._avgPortsLatestEMEA = 0;
        this._avgPortsLatestNA = 0;
        this._avgPortsLatestAPAC = 0;
        this._avgPortsLatestAll = 0;
        this._chartDataUpdated.next(null);
    }

    dispose() {
        this.unsubData();
    }
    private unsubData() {
        if (this._equipmentId) {
            // unsub data
            this.realtimeService.unsubscribe(this._equipmentId, this._commandTypeIdPort);
        }
    }
    get avgPortsLatestEMEA(): number {
        return this._avgPortsLatestEMEA;
    }
    get avgPortsLatestNA(): number {
        return this._avgPortsLatestNA;
    }
    get avgPortsLatestAPAC(): number {
        return this._avgPortsLatestAPAC;
    }
    get avgPortsLatestAll(): number {
        return this._avgPortsLatestAll;
    }

    get avgPortsPerCustomerArrayEMEA(): Point[] {
        return this._avgPortsPerCustomerArrayEMEA;
    }
    get avgPortsPerCustomerArrayNA(): Point[] {
        return this._avgPortsPerCustomerArrayNA;
    }
    get avgPortsPerCustomerArrayAPAC(): Point[] {
        return this._avgPortsPerCustomerArrayAPAC;
    }
    get avgPortsPerCustomerArrayAll(): Point[] {
        return this._avgPortsPerCustomerArrayAll;
    }
}
