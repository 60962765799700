import { createAction, props } from '@ngrx/store';

export const getSummaryDocument = createAction(
    '[SummaryDocument] Get SummaryDocument',
    props<{ equipmentId: string; commandTypeId: string }>()
);

export const getSummaryDocumentSuccess = createAction(
    '[SummaryDocument] Get SummaryDocument Success',
    props<{ data: any }>()
);

export const getSummaryDocumentFailure = createAction(
    '[SummaryDocument] Get SummaryDocument Failure',
    props<{ error: any }>()
);

export const getSummaryDocumentWithRepeat = createAction(
    '[SummaryDocument] Get SummaryDocument Repeat',
    props<{ equipmentId: string[]; commandTypeId: string; interval: number }>()
);

export const stopSummaryDocumentRepeat = createAction('[SummaryDocument] Stop SummaryDocument Repeat');
