<mat-card class="card " [ngClass]="{ 'clickable': dashlet.enabled && dashlet.groupName !== 'Internal', 'dashlet-disabled': !dashlet.enabled || dashlet.groupName === 'Internal'}" (click)="createDashlet()">
    <div class="flex-column text-center gap-m card-content"  >
        <div class="dashlet-image mat-elevation-z2" [style.background-image]="backGroundImage"  ></div>

        <div class="tile-add center " [matTooltip]="'Add new ' + dashlet.tooltip">
            <mat-icon aria-hidden="true">add</mat-icon>
        </div>
        <div class="mat-body-1 flex-column justify-center">
            {{ dashlet.type === 'MS Teams Call Summary' ? 'Microsoft Teams Call Summary': dashlet.type}}
        </div>
        <div class="disabled-overlay"></div>
    </div>
</mat-card>
