import { SelectItem } from '@models/index';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TimeService {
    constructor(private http: HttpClient) {}

    getTimestamp(): string {
        return new Date().toISOString();
    }

    getTimeZones() {
        return this.http.get<any[]>('api/formng/timezones').subscribe(zones =>
            zones.map(tz => {
                const i = new SelectItem();
                i.label = tz.name;
                i.value = tz.id;
                return i;
            })
        );
    }

    private getUserTimezone(): string {
        const claims = JSON.parse(localStorage.getItem('id_token_claims_obj'));
        return claims?.TimeZoneId || 'UTC'; // Default to UTC if timezone is not found
    }

    public convertToUserTimezone(time: string | Date): string {
        const userTimezone = this.getUserTimezone();
        const date = new Date(time);
        return this.formatDateWithTimezone(date, userTimezone);
    }

    private formatDateWithTimezone(date: Date, timeZone: string): string {
        const options: Intl.DateTimeFormatOptions = {
            timeZone,
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        };
        const utcOffset = date.getTimezoneOffset() * 60000;
        const localTime = new Date(date.getTime() - utcOffset);
        const timeZoneOffset = new Date(localTime.toLocaleString('en-US', { timeZone })).getTimezoneOffset() * 60000;
        const adjustedTime = new Date(localTime.getTime() - timeZoneOffset + utcOffset);

        return new Intl.DateTimeFormat('en-GB', options).format(adjustedTime);
    }

    convertToUserTimeZoneForLastUpdated(time: string | Date): string {
        const timeZone = this.getUserTimezone();
        const date = new Date(time);
        const options: Intl.DateTimeFormatOptions = {
            timeZone,
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        };
        return new Intl.DateTimeFormat('en-GB', options).format(date);
    }
}
