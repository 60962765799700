import * as Actions from '@actions/index';
import { AccountService, DashletDataProcessService, DashletService } from '@services/index';
import { AppState, selectDataFromCommonEntity } from '@reducers/index';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DashletServiceGatewayInstancesInformation } from '@models/index';
import { retry, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-dashlet-service-gateway-instances-information',
    templateUrl: './dashlet-service-gateway-instances-information.component.html',
    styleUrls: ['./dashlet-service-gateway-instances-information.component.scss']
})
export class DashletServiceGatewayInstancesInformationComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletServiceGatewayInstancesInformation;
    private destroy$ = new Subject<void>();
    entityGroup: number;
    customerId: string;
    createUTCTime: any;

    constructor(
        private accountService: AccountService,
        public dashletService: DashletService,
        private store$: Store<AppState>,
        private dashletDataService: DashletDataProcessService
    ) {}

    public ngOnInit(): void {
        const userDetail = this.accountService.getUserDetails();
        this.entityGroup = userDetail.EntityGroup;
        if (this.dashlet.configured) {
            this.dashletDataService
                .getServiceGatewayIdsAndName()
                .pipe(retry(3), takeUntil(this.destroy$))
                .subscribe(data => {
                    for (const gateway of data) {
                        this.store$.dispatch(
                            Actions.SubscribeToRealTimeService({
                                equipmentId: gateway.value,
                                command: this.dashlet.commandTypeIdInstances
                            })
                        );
                        this.store$.dispatch(
                            Actions.GetNotifyCommonEntitys({
                                equipmentId: gateway.value,
                                commandTypeId: this.dashlet.commandTypeIdInstances
                            })
                        );
                        this.store$
                            .pipe(
                                select(selectDataFromCommonEntity(gateway.value + this.dashlet.commandTypeIdInstances))
                            )
                            .subscribe(data => {
                                if (data) {
                                    this.dashlet.processData(data, gateway.Value, gateway.Key);
                                }
                            });
                        this.store$.dispatch(
                            Actions.GetCommonHistoric({
                                equipmentId: gateway.Value,
                                commandTypeId: this.dashlet.commandTypeIdInstances,
                                from: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                                to: new Date().toISOString(),
                                max: 2
                            })
                        );
                    }
                });
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        if (this.dashlet) this.dashlet.dispose();
    }

    get smallLayout() {
        return this.dashlet.getSize().id === 0;
    }

    get mediumLayout() {
        return this.dashlet.getSize().id === 1;
    }

    get wideLayout() {
        return this.dashlet.getSize().id === 2;
    }
}
