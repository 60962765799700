import { DashletSetting } from '@models/index';

export class DashletSettingCheckbox extends DashletSetting {
    public value: boolean = false;

    public loadState(obj: { [key: string]: any }): void {
        this.value = obj[this.stateKey] || false;
    }

    public saveState(obj: { [key: string]: any }): void {
        obj[this.stateKey] = this.value;
    }

    public customClass?: string = '';
}
