import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class DashboardInterceptor implements HttpInterceptor {
    private readonly errorResponseCodes = [400, 403, 404, 500];
    constructor(private router: Router) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(catchError(err => this.handleError(err)));
    }

    private handleError(err: HttpErrorResponse) {
        if (err.status === 401) {
            this.saveCurrentLocation();
            this.router.navigate(['/Login']);
            return throwError(() => err);
        } else if (this.errorResponseCodes.includes(err.status)) {
            return throwError(() => this.handleProblemDetails(err));
        }
    }

    private saveCurrentLocation(): void {
        let redirectUrl: string = location.pathname;
        if (redirectUrl && redirectUrl.toLowerCase() !== '/Login' && redirectUrl.toLowerCase() !== '/') {
            sessionStorage.setItem('postLoginRedirectUrl', redirectUrl);
        }
    }

    private handleProblemDetails(error: HttpErrorResponse): string {
        return `${error.status}`;
    }
}
