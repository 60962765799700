<div class="flex-column gap-l">
    <div class="justify-space-between align-center grow">
        <button
            mat-button
            matTooltip="Click here to download Visio diagram of Network Region schematic"
            aria-label="download"
            (click)="downloadSchematic()"
        >
            <mat-icon>file_download</mat-icon> Download IPNR Schematic
        </button>
        <mat-checkbox color="primary" [ngModel]="dashlet.showAllIpnrs" (ngModelChange)="dashlet.filterEmptyIpnrs()">Show IPNRs with no traffic</mat-checkbox>
    </div>
<dashlet-table
[columns]="columns"
[dataSource]="dashlet.filteredIpnrs"
[pagination]="true"
[tableHeightRows]="5"
[pageLength]="5"
[rowExpansionColumns]="rowExpansionColumns"
[rowExpansionField]="'childnodes'"></dashlet-table>
    
