<ng-container *ngIf="!openSubcontainer">
    <div class="mat-h2">
        {{ dashlet.unresolved }}
        <span matTooltip="Displaying {{ dashlet.unresolved }} most recent alarms per customer" class="mat-body-1"
            >&nbsp;unresolved alarms&nbsp;<sup> <mat-icon>question_mark</mat-icon> </sup></span
        >
    </div>
    <dashlet-table
        *ngIf="displayTable"
        [columns]="columns"
        [dataSource]="dashlet.alarms"
        [tableHeightRows]="tableSize()"
        [pagination]="true"
        [pageLength]="tableSize()"
        [loading]="alarmsLoading"
        [menuItems]="items"
        [sortDirection]="sortDirection"
        [(filters)]="filters"
        [defaultSort]="sortField"
    ></dashlet-table>
</ng-container>

<!-- details drilldown -->
<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'details'"
    navPrevious="Alarm Summary"
    navCurrent="Details"
    [color]="getSeverityColor(detailsAlarm.severity)"
    (close)="closeDetails()"
    [showNavigation]="showDetailNavigation"
>
    <ng-container title>
        <span>
            <h3 class="drill-down-title">
                Alarm Details |
                <span [class.detail-alarm-resolved]="detailAlarmStatus === 1">{{
                    detailAlarmStatus && dashlet.getSize().id === 0 ? detailsAlarm.displayName.substring(0, 10) + '...' : detailsAlarm.displayName
                }}</span>
            </h3>
        </span>
        {{ detailAlarmStatus ? ' - ' : '' }}
        <span [ngStyle]="{ color: drilldownResolvedMessageStyle() }">
            <span>{{ drilldownResolvedMessage() }}</span>
        </span>
    </ng-container>
    <ng-container subtitle> {{ detailsAlarm.customer }}, {{ detailsAlarm.equipment }} </ng-container>
    <ng-container body>
        <div class="flex-column gap-m">
            <!-- properties -->
            <div class="flex gap-m" *ngIf="detailsAlarmExtended | async as details; else detailsLoading">
                <div class="flex-column equipment-detail-labels">
                    <div class="mat-body-2">Vendor</div>
                    <div class="mat-body-2">Site</div>
                    <div class="mat-body-2">Site ID</div>
                    <div class="mat-body-2">Detailed Description</div>
                    <div class="mat-body-2">Administered ID</div>
                    <div class="mat-body-2">Equipment Location</div>
                    <div class="mat-body-2">Severity</div>
                    <div class="mat-body-2">Activated Date</div>
                </div>
                <div class="flex-column text-no-wrap">
                    <div class="mat-body-1">{{ details.vendor || 'Unknown' }}</div>
                    <div class="mat-body-1">{{ details.site || 'Unknown' }}</div>
                    <div class="mat-body-1">{{ details.siteId || 'Unknown' }}</div>
                    <div class="mat-body-1">{{ details.shortAlarmDescription || 'Unknown' }}</div>
                    <div class="mat-body-1">{{ details.administeredId || 'Unknown' }}</div>
                    <div class="mat-body-1">{{ details.locationName || 'Unknown' }}</div>
                    <div class="mat-body-1">{{ details.severity || 'Unknown' }}</div>
                    <div class="mat-body-1">{{ details.activatedTime || 'Unknown' }}</div>
                </div>
            </div>
            <ng-template #detailsLoading><vsm-dashlet-loading></vsm-dashlet-loading></ng-template>
            <mat-accordion multi>
                <mat-expansion-panel expanded="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class="mat-body-1">Repeats </span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <dashlet-table
                        *ngIf="detailsAlarmRepeats | async as detailsAlarmRepeats; else vsmLoadingTemplate"
                        [columns]="repeatColumns"
                        [dataSource]="detailsAlarmRepeats"
                        [tableHeightRows]="5"
                        [sortable]="false"
                        [loading]="repeatsLoading"
                    ></dashlet-table> </mat-expansion-panel
                ><mat-expansion-panel expanded="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class="mat-body-1">Additional Information</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <dashlet-table
                        *ngIf="detailsAlarmAdditional | async as detailsAlarmAdditional; else vsmLoadingTemplate"
                        [columns]="additionalColumns"
                        [dataSource]="detailsAlarmAdditional"
                        [tableHeightRows]="5"
                        [sortable]="false"
                        [loading]="repeatsLoading"
                    ></dashlet-table>
                </mat-expansion-panel>
                <mat-expansion-panel expanded="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class="mat-body-1">Alarm Rules Matched</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <dashlet-table
                        *ngIf="detailsAlarmResponseLog | async as detailsAlarmResponseLog; else vsmLoadingTemplate"
                        [columns]="responseColumns"
                        [dataSource]="detailsAlarmResponseLog"
                        [tableHeightRows]="5"
                        [sortable]="false"
                        [loading]="responseLoading"
                        [menuItems]="alarmResponseMenu"
                    ></dashlet-table>
                </mat-expansion-panel>
            </mat-accordion>
            <!-- repeats -->
        </div>
    </ng-container>
    <ng-container footer>
        <div class="flex gap-m">
            <button mat-raised-button (click)="manageAlarm()">Manage Alarm</button>
            <button mat-raised-button [disabled]="detailAlarmStatus === -1 || detailAlarmStatus === 1" (click)="resolveAlarmInDetails(detailsAlarm.id)">
                Resolve
            </button>
            <button mat-raised-button (click)="configureAlarm()">Configure</button>
        </div>

        <div class="grow justify-end align-center">
            <span class="mat-caption">{{ detailsIndex + 1 }} of {{ dashlet.unresolved }}</span>

            <button [disabled]="!detailsHasPrev" (click)="detailsPrev()" mat-icon-button><mat-icon>chevron_left</mat-icon></button>
            <button [disabled]="!detailsHasNext" (click)="detailsNext()" mat-icon-button><mat-icon>chevron_right</mat-icon></button>
        </div>
    </ng-container>
</vsm-dashlet-subcontainer>
<ng-template #vsmLoadingTemplate>
    <vsm-dashlet-loading></vsm-dashlet-loading>
</ng-template>
