<div class="flex-column gap-l" *ngIf="!openSubcontainer">
    <!-- system -->
    <dashlet-section-processor-memory
        #processorMemory
        [showSystemChart]="dashlet.showSystemChart"
        [equipment]="dashlet.equipment"
        [customerId]="dashlet.customer.customerId"
        [locationId]="dashlet.location.locationId"
    >
    </dashlet-section-processor-memory>
    <!-- network connectivity -->
    <dashlet-section-network-connectivity
        [showNetworkGraph]="dashlet.showNetworkGraph"
        [equipment]="dashlet.equipment"
        [customerId]="dashlet.customer.customerId"
    >
    </dashlet-section-network-connectivity>
    <div *ngIf="dashlet.showStatistics">
        <dashlet-section-header>Call and Recording Statistics</dashlet-section-header>
        <dashlet-table [sortable]="false" [columns]="statisticsColumns" [dataSource]="dashlet.statisticsTableData" [tableHeightRows]="5">
        </dashlet-table>
    </div>
    <div *ngIf="dashlet.showHealth">
        <dashlet-section-header>Health</dashlet-section-header>
        <div class="justify-space-around">
            <div class="flex-column align-center gap-s">
                <div class="mat-caption">Process Memory</div>
                <div>
                    <ng-container *ngTemplateOutlet="processMemDial"></ng-container>
                </div>
            </div>
            <div class="flex-column gap-s align-center">
                <div class="mat-caption">Free Call Partition Disk Space</div>
                <div class="mat-h2 flex grow align-center">
                    {{ dashlet.healthData.witscsFreeCalls }}
                    <span class="mat-caption"> MB</span>
                </div>
            </div>
        </div>

        <ng-template #processMemDial>
            <dashlet-percentage
                [value]="dashlet.healthData.witscsFreeMem"
                [color]="getSeverityColor(processorMemory.memoryUsageData.getMemoryUsageSeverity())"
            ></dashlet-percentage>
        </ng-template>
    </div>
    <div *ngIf="dashlet.showLinkDetails" class="section">
        <dashlet-section-header>Links</dashlet-section-header>
        <div class="flex-column gap-m">
            <dashlet-ratio-bar class="dashlet-cell" emptyTitle="No data available for Links">
                <dashlet-ratio-bar-item
                    [value]="dashlet.linksStatusBar[1]"
                    [color]="getSeverityColor(0)"
                    [matTooltip]=""
                    (click)="openLinkDrilldown()"
                ></dashlet-ratio-bar-item>
                <dashlet-ratio-bar-item
                    [value]="dashlet.linksStatusBar[0]"
                    [color]="getSeverityColor(2)"
                    [matTooltip]=""
                    (click)="openLinkDrilldown()"
                ></dashlet-ratio-bar-item>
            </dashlet-ratio-bar>
            <div class="justify-space-between">
                <h3 *ngIf="dashlet.linksStatusBar[1] > 0" class="dashlet-link-underline" dashlet-link="true" (click)="openLinkDrilldown()">
                    {{ dashlet.linksStatusBar[1] }}
                    <span class="mat-h3">Up</span>
                </h3>
                <h3 *ngIf="dashlet.linksStatusBar[0] > 0" class="dashlet-link-underline" dashlet-link="true" (click)="openLinkDrilldown()">
                    {{ dashlet.linksStatusBar[0] }}
                    <span class="mat-h3">Down</span>
                </h3>
            </div>
        </div>
    </div>
    <div *ngIf="dashlet.showChannelDetails">
        <dashlet-section-header>Channel</dashlet-section-header>

        <dashlet-table
            [columns]="channelColumns"
            [dataSource]="dashlet.channelTableData"
            [tableHeightRows]="6"
            [menuItems]="linkMenu"
        ></dashlet-table>
    </div>
    <div *ngIf="dashlet.showArchiveDestinationDetails">
        <dashlet-section-header>Archive Destination</dashlet-section-header>
        <dashlet-table
            [columns]="archiveColumns"
            [dataSource]="dashlet.archiveDestinationData"
            [tableHeightRows]="6"
            [pagination]="true"
            [pageLength]="6"
            [rowExpansionColumns]="rowExpansionArchiveColumns"
            [rowExpansionField]="'expansion'"
        ></dashlet-table>


    </div>
    <dashlet-section-custom-scripts *ngIf="dashlet.showCustomScripts" [equipment]="dashlet.equipment" [customer]="dashlet.customer">
    </dashlet-section-custom-scripts>
</div>
<!--Links drilldown-->
<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'link'"
    [title]="dashlet.equipment.equipmentName + ' | Links'"
    [navPrevious]="'Avaya Contact Recorder - ' + dashlet.customer.customerName"
    [navCurrent]="'Link Status'"
    (close)="closeDetails()"
>
    <ng-container subtitle> {{ dashlet.linksStatusBar[1] }} up, {{ dashlet.linksStatusBar[0] }} down </ng-container>
    <div body class="section">
        <!-- Table -->
        <dashlet-table
            [columns]="linkColumns"
            [dataSource]="dashlet.linksData"
            [sortable]="false"
            [pagination]="dashlet.linksData.length > 10"
            [pageLength]="10"
            [tableHeightRows]="10"
        ></dashlet-table>
    </div>
</vsm-dashlet-subcontainer>
<!--Channels drilldown-->
<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'channel'"
    [title]="dashlet.equipment.equipmentName + ' | Channels'"
    [navPrevious]="'Avaya Contact Recorder - ' + dashlet.customer.customerName"
    [navCurrent]="'Channel Status'"
    (close)="closeDetails()"
>
    <ng-container subtitle>
        {{ dashlet.channelDrilldownData.length }} - {{ dashlet.channelDrilldownData[0] ? dashlet.channelDrilldownData[0].witscscmChannelState : '' }}
    </ng-container>
    <div body>
        <dashlet-table
            [columns]="channelDetailColumns"
            [dataSource]="dashlet.channelDrilldownData"
            [tableHeightRows]="20"
            [pagination]="true"
            [pageLength]="20"
        ></dashlet-table>
        <!-- Table -->
    </div>
</vsm-dashlet-subcontainer>

<!-- create thresholds alerts drilldown -->
<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'createThreshold' || openSubcontainer === 'editThreshold'"
    [navPrevious]="'Windows System Health - ' + dashlet.customer.customerName"
    [navCurrent]="(openSubcontainer === 'createThreshold' ? 'Create' : 'Edit') + ' Threshold'"
    (close)="closeDetails()"
>
    <ng-container title>{{ openSubcontainer === 'createThreshold' ? 'New' : 'Edit' }} {{ selectedField }} Threshold </ng-container>
    <ng-container body>
        <threshold-item #thresholdItem [settings]="dashlet.getSettings()" [type]="'ACR'" [threshold]="selectedThreshold"> </threshold-item>
    </ng-container>
    <div footer class="row row-buttons">
        <div>
            <button type="button" pButton class="btn button-details" (click)="closeDetails()" label="Cancel"></button>
        </div>
        <div *ngIf="openSubcontainer === 'editThreshold'; else addThreshold">
            <button type="submit" pButton class="btn button-details" (click)="saveEdit()" [disabled]="!thresholdItem.formValid()" label="Save"></button>
        </div>
        <ng-template #addThreshold>
            <button type="submit" pButton class="btn button-details" (click)="onSubmit()" [disabled]="!thresholdItem.formValid()" label="Save"></button>
        </ng-template>
    </div>
</vsm-dashlet-subcontainer>
