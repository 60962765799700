/* eslint-disable no-undef */
/* eslint-disable @angular-eslint/component-selector */
import * as Actions from '@actions/index';
import { AccountService, DashletService, TimeoutService } from '@services/index';
import { AppState, selectDataFromCommonEntity, selectEntity } from '@reducers/index';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CustomerLogo, DashletSystemHealthEngelbartSoftwareEsuites } from '@models/index';
import { Subscription } from 'rxjs';

@Component({
    selector: 'dashlet-system-health-engelbart-software-esuites',
    templateUrl: 'dashlet-system-health-engelbart-software-esuites.component.html',
    styleUrls: ['dashlet-system-health-engelbart-software-esuites.component.scss']
})
export class DashletSystemHealthEngelbartSoftwareEsuitesComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletSystemHealthEngelbartSoftwareEsuites;

    public entityGroup: number;
    public customerId: string;
    public openSubcontainer = '';
    public openSubcontainerSeverity = 0;

    public selectedField: string;
    public expiredMessageTitle: string;

    private subscription: Subscription = new Subscription();

    constructor(
        private dashletService: DashletService,
        private accountService: AccountService,
        public timeoutService: TimeoutService,
        private store$: Store<AppState>
    ) {}

    public ngOnInit(): void {
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );

        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdComponentInfo
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdComponentInfo
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdComponentInfo
                        )
                    )
                )
                .subscribe(data => {
                    if (data) {
                        this.dashlet.processComponentData(data);
                    }
                })
        );

        this.store$.dispatch(
            Actions.GetCommonHistoric({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdComponentInfo,
                from: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                to: new Date().toISOString(),
                max: 1
            })
        );

        /// CUSTOM SCRIPTS was half implemented on the old project it seems so was not ported across
        var userDetail = this.accountService.getUserDetails();
        this.entityGroup = userDetail.EntityGroup;
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getSeverityColor(sev: number): string {
        return this.dashletService.getSeverityColor(sev);
    }

    public getComponentsUp(): number {
        let count = 0;

        this.dashlet.components.forEach(item => {
            if (item.status.toString().toLowerCase() === 'up') {
                count++;
            }
        });

        return count;
    }

    public getComponentsDown(): number {
        let count = 0;

        this.dashlet.components.forEach(item => {
            if (item.status.toString().toLowerCase() !== 'up') {
                count++;
            }
        });

        return count;
    }

    public getComponentList(sev: number): any[] {
        if (sev === 0) {
            return this.getComponentListUp(sev);
        } else {
            return this.getComponentListDown(sev);
        }
    }

    // create helper method to get down components
    private getComponentListDown(sev: number): any[] {
        let list = [];
        if (this.dashlet.components !== null) {
            Object.keys(this.dashlet.components).forEach(key => {
                if (this.dashlet.components[key].status.toString().toLowerCase() !== 'up' && sev === 2) {
                    list.push(this.dashlet.components[key]);
                }
            });
        }
        return list;
    }

    private getComponentListUp(sev: number): any[] {
        let list = [];
        if (this.dashlet.components !== null) {
            Object.keys(this.dashlet.components).forEach(key => {
                if (this.dashlet.components[key].status.toString().toLowerCase() === 'up' && sev === 0) {
                    list.push(this.dashlet.components[key]);
                }
            });
        }
        return list;
    }

    public getComponentDetails(comp: any): any[] {
        let list = [];
        if (comp.details !== null && comp.details !== undefined) {
            list.push({
                name: 'status',
                data: comp.status.toString().toLowerCase()
            });

            if (comp.details !== '') {
                let object = '';
                try {
                    object = JSON.parse(comp.details);
                } catch (error) {
                    // This is to deal with broken JSON coming from raw RT - comment VSMP-12708
                    let patchJob: string = comp.details.toString();
                    let brokenEnd: string[] = patchJob.split(',');
                    object = JSON.parse(patchJob.replace(',' + brokenEnd[brokenEnd.length - 1], ' }'));
                }

                Object.keys(object).forEach(key => {
                    list.push({
                        name: key,
                        data:
                            comp.name === 'diskSpace'
                                ? this.convertUnits(object[key])
                                : key === 'timestamp'
                                ? new Date(object[key]).toString()
                                : object[key]
                    });
                });
            }
        } else {
            list.push({
                name: 'No Data Found',
                data: '---'
            });
        }
        return list;
    }

    private convertUnits(size: any): string {
        if (size < 1000) {
            return Math.round(size) + ' B';
        }
        if (size < 1000000) {
            return Math.round(size / 1000) + ' KB';
        }
        if (size < 1000000000) {
            return Math.round(size / 1000000) + ' MB';
        }

        return Math.round(size / 1000000000) + ' GB';
    }

    public openDetails(container: string, severity: number): void {
        this.openSubcontainer = container;
        this.dashlet.displayHeader = false;
        this.openSubcontainerSeverity = severity;
    }

    public closeDetails(): void {
        this.openSubcontainer = null;
        this.dashlet.displayHeader = true;
    }
}
