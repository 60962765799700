import { createAction, props } from '@ngrx/store';

export const GetEquipmentInfo = createAction(
  '[EquipmentInfo] Get EquipmentInfo',
  props<{ equipmentId: string }>()
);

export const GetEquipmentInfoSuccess = createAction(
  '[EquipmentInfo] Get EquipmentInfo Success',
  props<{ data: any, equipmentId: string }>()
);

export const GetEquipmentInfoFailure = createAction(
  '[EquipmentInfo] Get EquipmentInfo Failure',
  props<{ error: any }>()
);
