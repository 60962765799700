import {
    NetworkConnectivityConfiguration,
    DashletSettingList,
    DashletSettingDropdownGrouped,
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingCheckbox
} from '@models/index';
import { DashletSettingsService } from '@services/index';

export class DashletSettingsFormNetworkConnectivitySummary extends DashletSettingsForm {
    private customer!: DashletSettingDropdown;
    private location!: DashletSettingDropdown;
    private equipment!: DashletSettingList<any>;

    constructor(private readonly settingsService: DashletSettingsService) {
        super();

        this.init();
        this.loadCustomers();
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);

        if (this.customer.value) {
            this.loadLocations(this.customer.value);
        }

        if (this.location.value) {
            this.loadEquipment(this.location.value);
        }
    }

    private init(): void {
        const equipmentGroup = new DashletSettingsGroup();
        const s: any = (equipmentGroup.settings = []);

        const customer = (this.customer = new DashletSettingDropdown());
        customer.label = 'Customer';
        customer.stateKey = 'customer';
        customer.disabled = true;
        customer.change.subscribe(() => {
            this.location.items = [];
            this.equipment.items = [];
            this.equipment.values = [];
            this.loadLocations(customer.value!);
        });
        s.push(customer);

        const location = (this.location = new DashletSettingDropdown());
        location.label = 'Location';
        location.stateKey = 'location';
        location.disabled = true;
        location.change.subscribe(() => {
            this.equipment.items = [];
            this.equipment.values = [];
            this.loadEquipment(location.value!);
        });
        s.push(location);

        const equipment = (this.equipment = new DashletSettingList<any>());
        equipment.label = 'Equipment';
        equipment.stateKey = 'equipment';
        equipment.disabled = true;
        equipment.multiple = true;
        s.push(equipment);

        const group = new DashletSettingsGroup();
        group.groups = [equipmentGroup];
        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);
        this.settingsService.getUserAssociatedCustomers().subscribe(
            customers => {
                this.customer.items = customers;
                this.customer.loadingComplete();
            },
            () => {
                this.customer.loadingComplete();
                this.customer.disabled = true;
                // show error notification here
            }
        );
    }

    private loadLocations(customer: string): void {
        this.enableAndLoading(this.location);
        this.settingsService.getLocations(customer).subscribe(
            locations => {
                this.location.items = locations;
                this.location.loadingComplete();
            },
            () => {
                this.location.loadingComplete();
                this.location.disabled = true;
                // show error notification here
            }
        );
    }

    private loadEquipment(location: string) {
        this.enableAndLoading(this.equipment);
        this.settingsService.getAllNetworkConnectivityEquipmentAtLocation(location).subscribe(
            (equipment: any) => {
                this.equipment.items = equipment;
                this.equipment.loadingComplete();
            },
            () => {
                this.equipment.loadingComplete();
                this.equipment.disabled = true;
            }
        );
    }

    private clearAndDisable(
        dropdown: DashletSettingDropdown | DashletSettingDropdownGrouped | DashletSettingList<string>
    ) {
        dropdown.disabled = true;
        dropdown.items = [];
        if (dropdown instanceof DashletSettingList) {
            dropdown.values = [];
        } else {
            dropdown.value = null;
        }
    }

    private enableAndLoading(
        dropdown: DashletSettingDropdown | DashletSettingDropdownGrouped | DashletSettingList<string>
    ) {
        dropdown.disabled = false;
        dropdown.loadingBegin();
    }
}
