import { Action, createAction, props } from '@ngrx/store';
import { CustomerModel } from '@models/index';

export const GetBPAssociatedCustomers = createAction(
    '[Customers] Get BP Associated Customers',
    props<{ businessPartnerId: string }>()
);

export const GetBPAssociatedCustomersSuccess = createAction(
    '[Customers] Get BP Associated Customers Success',
    props<{ data: any }>()
);

export const GetBPAssociatedCustomersFailure = createAction(
    '[Customers] Get BP Associated Customers Failure',
    props<{ error: any }>()
);

export const GetUserAssociatedCustomers = createAction('[Customers] Get User Associated Customers');

export const GetUserAssociatedCustomersSuccess = createAction(
    '[Customers] Get User Associated Customers Success',
    props<{ data: CustomerModel[] }>()
);

export const GetUserAssociatedCustomersFailure = createAction(
    '[Customers] Get User Associated Customers Failure',
    props<{ error: any }>()
);
