/* eslint-disable no-undef */
import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { timer } from 'rxjs';
import * as Actions from '@actions/index';
import { AppState } from '@reducers/index';
import { Store } from '@ngrx/store';
import { environment } from '@environments/environment';

export enum TimeoutType {
    CPU = 540000,
    Memory = 540000,
    Disk = 9e7,
    Network = 450000,
    UpTime = 450000,
    WindowsDisk = 2700000,
    CallRate = 5.4e6,
    ACMService = 270000,
    AESService = 3900000,
    ASBService = 720000,
    CDRLink = 270000,
    MediaGateway = 900000,
    Ds1Log = 1.32e6,
    MediaGatewayInfo = 5.4e6,
    Ds1LogName = 9e7,
    LongestOutage = 9e7,
    Availability = 9e7,
    MaxPing = 450000,
    AveragePing = 450000,
    License = 5.4e6,
    SNMP = 5.4e6,
    Duplication = 1.35e6,
    StandBy = 1.35e6,
    LSP = 9e7,
    PN = 9e7,
    MediaServer = 270000,
    GeneralSystemHealthSummary = 600000,
    ACMSystemHealthSummary = 9e7,
    API = 270000,
    SQLData = 60000,
    GenesysConversation = 17 * 60 * 1000,
    MSTeamsRooms = 17 * 60 * 1000,
    GenesysTrunksSummary = 17 * 60 * 1000,
    AlarmsOverview = 9e7
}

interface Timer {
    timer: Observable<number>;
    count: number;
    sub: Subject<boolean>;
    timerSub: Subscription;
    timestamp: Date;
}

@Injectable()
export class TimeoutService {
    private timePeriods: Map<string, number> = new Map<string, number>();
    public timers: Map<string, Timer> = new Map<string, Timer>();

    constructor(private store$: Store<AppState>) {
        this.timePeriods.set('52c6550b-5321-4f7e-881c-82e4db60893b'.toLowerCase(), TimeoutType.CPU); // SysHealth CPU
        this.timePeriods.set('d4769b5c-b83b-45fe-a6c7-647462d63daf'.toLowerCase(), TimeoutType.Memory); // SysHealth Memory
        this.timePeriods.set('63396140-e759-4899-97ea-c64456197056'.toLowerCase(), TimeoutType.Disk); // SysHealth Disk
        this.timePeriods.set('5a2171bf-3916-4af7-a33e-3e713db3e65c'.toLowerCase(), TimeoutType.UpTime); // SysHealth UpTime
        this.timePeriods.set('31ccb71f-4a29-49ae-a911-0327be01f3fe'.toLowerCase(), TimeoutType.UpTime); // SysHealth UpTime Cisco Cube
        this.timePeriods.set('7bf15776-f312-40c7-92a1-f95ddead52ea'.toLowerCase(), TimeoutType.Network); // SysHealth Network
        this.timePeriods.set('AA6DB57A-04A4-4952-97B1-FB27B5B9BABE'.toLowerCase(), TimeoutType.LongestOutage); // SysHealth Longest Outage

        this.timePeriods.set('3608d081-f3d9-46e8-9ea7-199db319aa86'.toLowerCase(), TimeoutType.Memory); // Windows Memory/CPU
        this.timePeriods.set('284e78b0-5831-4f67-966b-cb6ea2315460'.toLowerCase(), TimeoutType.WindowsDisk); // Windows Disk
        this.timePeriods.set('0a006835-f704-46f5-b7ee-c046e108985b'.toLowerCase(), TimeoutType.UpTime); // Windows UpTime
        this.timePeriods.set('7bf15776-f312-40c7-92a1-f95ddead52ea'.toLowerCase(), TimeoutType.Network); // Windows Network
        this.timePeriods.set('5CDAA2C3-9BD1-464F-B643-695F6BC38156'.toLowerCase(), TimeoutType.ACMService); // Windows Watched Services
        this.timePeriods.set('277d384f-b042-4e8b-b44e-5677161791bf'.toLowerCase(), TimeoutType.MediaServer); // Windows IIS Services

        this.timePeriods.set('12bfc50d-a768-4762-ab51-42896543a0bc'.toLowerCase(), TimeoutType.CPU); // IP Office System
        this.timePeriods.set('72dd9413-9c2b-4974-98a5-96a4b0567de6'.toLowerCase(), TimeoutType.UpTime); // IP Office UpTime

        this.timePeriods.set('b14df118-4161-439b-ad16-f490ec5f615e'.toLowerCase(), TimeoutType.CPU); // Oracle CPU
        this.timePeriods.set('28b8f190-49b7-461e-a5ce-d794954252d4'.toLowerCase(), TimeoutType.UpTime); // Oracle UpTime

        this.timePeriods.set('37EF261A-5321-4F74-B8BD-0734D834C3C8'.toLowerCase(), TimeoutType.CPU); // Cisco CPU
        this.timePeriods.set('D2E8814E-A42D-4922-81C8-87F4139E65BD'.toLowerCase(), TimeoutType.CPU); // Cisco Memory
        this.timePeriods.set('66D10D7B-A010-490D-82C2-73A9D4DB8E32'.toLowerCase(), TimeoutType.Network); //cisco network interface

        this.timePeriods.set('7c2b8bec-273b-413e-983e-6e5b243f996f'.toLowerCase(), TimeoutType.CallRate); // ACM
        this.timePeriods.set('a3f041b0-2479-4e03-8edd-e93400f0e5ff'.toLowerCase(), TimeoutType.ACMService); // ACM
        this.timePeriods.set('1A0CF50D-6AE8-4C78-9DF4-E0D1C7434528'.toLowerCase(), TimeoutType.AESService); // AES Services
        this.timePeriods.set('FCBD605F-1781-47CC-8AB3-24AC97FEDB05'.toLowerCase(), TimeoutType.ACMService); // AEP Services
        this.timePeriods.set('F9F69B0E-6135-4645-BF39-2A2D867778CA'.toLowerCase(), TimeoutType.ACMService); // AES Services Operational
        this.timePeriods.set('A232D7BB-BEB3-4BC0-98E5-7F0D939667BF'.toLowerCase(), TimeoutType.ACMService); // ASM Services
        this.timePeriods.set('d1599cfe-3f5b-4b85-829a-94b074b08350'.toLowerCase(), TimeoutType.ASBService); // ASB Services
        this.timePeriods.set('9cc77e8d-1458-49cd-b090-1ed21a546eed'.toLowerCase(), TimeoutType.CDRLink); // ACM
        this.timePeriods.set('a2060295-4cc8-4ea2-a404-2ee4bb001a09'.toLowerCase(), TimeoutType.MediaGateway); // ACM
        this.timePeriods.set('b7018135-5a9c-4375-8587-1d9ab3fbbb71'.toLowerCase(), TimeoutType.Ds1Log); // ACM
        this.timePeriods.set('a2722bd0-b5e5-42af-a782-3cc1bff370d2'.toLowerCase(), TimeoutType.MediaGatewayInfo); // ACM
        this.timePeriods.set('31a0cdc5-f116-459e-9c70-8e96af977653'.toLowerCase(), TimeoutType.Ds1LogName); // ACM
        this.timePeriods.set('bc472814-17b3-4567-a563-26ae6efdc643'.toLowerCase(), TimeoutType.License); // ACM License
        this.timePeriods.set('db89b0d0-dabb-4b12-ae68-ac5e6daaacb5'.toLowerCase(), TimeoutType.SNMP); // ACM Snmp
        this.timePeriods.set('b31f937b-f698-4423-80e3-896f59bb48e7'.toLowerCase(), TimeoutType.Duplication); // Duplication and standby
        this.timePeriods.set('fa49d808-38ee-4618-a936-bca19456732b'.toLowerCase(), TimeoutType.LSP); // ACM ESS/LSP
        this.timePeriods.set('572bcf5e-87f2-484d-912d-755574b7a2fb'.toLowerCase(), TimeoutType.PN); // ACM PN
        this.timePeriods.set('51f8a2a6-2c6e-4dca-87c5-500e5dd0e74a'.toLowerCase(), TimeoutType.MediaServer); // ACM Media Server

        this.timePeriods.set(
            '4ef0ff09-f8b5-4c33-83e2-c4d6f510d14c'.toLowerCase(),
            TimeoutType.GeneralSystemHealthSummary
        ); // General System Health summary plugin
        this.timePeriods.set('dd0e7fa6-f8a6-4f91-8075-00347d45a7aa'.toLowerCase(), TimeoutType.ACMSystemHealthSummary); // ACM system health summary

        this.timePeriods.set('28add0a8-05cf-4116-9490-32e0aca490b0'.toLowerCase(), TimeoutType.API); // commandTypeIdLicensingDMCC - AES DMCC Licensing
        this.timePeriods.set('d4fade87-38cb-45ec-9aad-a08b37d36788'.toLowerCase(), TimeoutType.API); // commandTypeIdTSAPITSDI - AES TSAPI Switch Links
        this.timePeriods.set('3531254b-70d4-4eb9-b6b1-bcd95b332386'.toLowerCase(), TimeoutType.API); // commandTypeIdTSAPISwitchLink - AES TSAPI Buffers
        this.timePeriods.set('8358892c-2368-44c1-b2c5-0e267e2d0614'.toLowerCase(), TimeoutType.API); // commandTypeIdLicensingTSAPI - AES TSAPI Licensing

        this.timePeriods.set('0cd94cc2-42f3-4c6c-9f8b-e9caf2995261'.toLowerCase(), TimeoutType.API); //commandTypeIdSystemUptime - AudioCodes SBC

        this.timePeriods.set('3235efef-199a-4310-8d8f-e5a9944e37d8'.toLowerCase(), TimeoutType.SQLData); // SQLData for Windows dashlet

        this.timePeriods.set('9DD4FBF9-A1BF-45CD-B834-CA5EB551CAAC'.toLowerCase(), TimeoutType.CPU); //ribbon processor/memory

        this.timePeriods.set('09CDEEBF-24DC-4FD4-854B-D547B4FC7520'.toLowerCase(), TimeoutType.CPU); //cisco cube processor CPU

        this.timePeriods.set('C85691E1-AFC6-4848-9698-F003046A988D'.toLowerCase(), TimeoutType.Memory); //cisco cube platform cpu

        this.timePeriods.set('1D99DF00-3D5F-43A2-9875-16B6834BB6FE'.toLowerCase(), TimeoutType.CPU); //cisco cube processor memory

        this.timePeriods.set('C85691E1-AFC6-4848-9698-F003046A988D'.toLowerCase(), TimeoutType.Memory); //cisco cube platform memory
        this.timePeriods.set('BAA75EDB-E7A5-4874-896C-7C6D7AC4E04B'.toLowerCase(), TimeoutType.Disk); //cisco cube fileservice
        this.timePeriods.set('2FD0886C-3CE8-41A6-AF70-E1BE7AE1A4D4'.toLowerCase(), TimeoutType.Disk); //cisco pressence fileservice

        this.timePeriods.set('2ABD0F70-2306-448D-84F8-0D8B9702574E'.toLowerCase(), TimeoutType.CPU); //audiocodes processor memory
        this.timePeriods.set('9ED314F7-69AB-44E0-9E60-EFC3227727C9'.toLowerCase(), TimeoutType.CPU); // Linux SysHealth CPU
        this.timePeriods.set('E68580F5-5E4B-48B7-BA18-A6B12E6757CA'.toLowerCase(), TimeoutType.GenesysConversation); // Genesys COnversaton
        this.timePeriods.set('583C5D42-D54B-4FAA-968D-83AE87BF473B'.toLowerCase(), TimeoutType.Network); // avaya SBC network interface
        this.timePeriods.set('BF61A9FC-4430-415E-9368-E7907E1D6903'.toLowerCase(), TimeoutType.MSTeamsRooms); // Microsoft Teams Rooms
        this.timePeriods.set('83DBAFD5-117A-44D2-A15A-7D2636EC3608'.toLowerCase(), TimeoutType.GenesysTrunksSummary); // Microsoft Teams Rooms
        this.timePeriods.set('97BD0527-A111-40F1-AA04-EB6759EFCEE7'.toLowerCase(), TimeoutType.AlarmsOverview); // Alarms Overview
        this.timePeriods.set('62568F87-2184-4F4B-B938-147BEEDE6529'.toLowerCase(), TimeoutType.AlarmsOverview); // Alarms Severity Overview
    }

    public getTimer(commandTypeId: string): Observable<number> {
        let time;
        if (this.timePeriods.has(commandTypeId.toLowerCase())) {
            time = this.timePeriods.get(commandTypeId.toLowerCase());
            return timer(time);
        }

        return null as any;
    }

    public documentRecieved(key: string, commandTypeId: string, time: any, equipmentId: string) {
        const timer = this.timers.get(key.toLowerCase());
        const date: Date = new Date(time);
        if (timer && date.getTime() >= timer.timestamp.getTime()) {
            if (timer.timerSub) {
                timer.timerSub.unsubscribe();
            } // Stop old timers since we are creating a new one
            timer.timer = this.getTimer(commandTypeId.toLowerCase());
            timer.timestamp = date;
            let notWithinTime = false; // Is this document still valid or is it too old
            let newKey = this.timePeriods.get(commandTypeId.toLowerCase());
            let now = new Date();
            let utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

            if (Date.now() - newKey > Date.parse(time)) {
                notWithinTime = true;
            }
            timer.sub.next(notWithinTime); // false - we have recieved data

            this.store$.dispatch(
                Actions.GetEntityTimerSuccess({
                    data: { data: notWithinTime, uniqueId: equipmentId.toLowerCase() + commandTypeId.toLowerCase() }
                })
            );

            timer.timerSub = timer.timer.subscribe(() => timer.sub.next(true)); // true - timer has expired
        }
    }

    public observeTimer(equipmentId: string, commandTypeId: string): Subject<boolean> {
        if (equipmentId === null) {
            throw new Error('Parameter equipmentId required.');
        }
        if (commandTypeId === null) {
            throw new Error('Parameter commandTypeId required.');
        }
        equipmentId = equipmentId.toLowerCase();
        commandTypeId = commandTypeId.toLowerCase();
        const id = this.getDocumentIdentifier(equipmentId, commandTypeId);
        this.addTimer(id.key, commandTypeId);
        const timer = this.timers.get(id.key);
        if (timer) {
            return timer.sub;
        }
        return null as any;
    }

    public observeTimerNew(equipmentId: string, commandTypeId: string): Subject<boolean> {
        if (equipmentId === null) {
            throw new Error('Parameter equipmentId required.');
        }
        if (commandTypeId === null) {
            throw new Error('Parameter commandTypeId required.');
        }
        equipmentId = equipmentId.toLowerCase();
        commandTypeId = commandTypeId.toLowerCase();
        const id = this.getDocumentIdentifier(equipmentId, commandTypeId);
        this.addTimer(id.key, commandTypeId);
        const timer = this.timers.get(id.key);

        if (timer) {
            return timer.sub;
        }
        return;
    }

    public addTimer(key: any, commandTypeId: any) {
        let timer = this.timers.get(key);
        if (timer) {
            timer.count++;
        } else {
            timer = {
                timer: this.getTimer(commandTypeId.toLowerCase()),
                count: 1,
                sub: new Subject<any>(),
                timerSub: null as any,
                timestamp: new Date(0)
            };
            if (timer && timer.timer) {
                this.timers.set(key, timer);
            }
        }
    }

    public deleteTimer(equipmentId: string, commandTypeId: string) {
        const id = this.getDocumentIdentifier(equipmentId, commandTypeId);
        const timer = this.timers.get(id.key);
        if (timer) {
            timer.count--;
            if (timer.count === 0) {
                if (timer.timerSub) {
                    timer.timerSub.unsubscribe();
                }
                this.timers.delete(id.key);
            }
        } else {
            if (!environment.production) console.error('Attempt to delete non-existent timer.');
        }
    }

    private getDocumentIdentifier(equipmentId: string, commandTypeId: string) {
        if (equipmentId && commandTypeId) {
            equipmentId = equipmentId.toLowerCase();
            commandTypeId = commandTypeId.toLowerCase();
            return {
                equipmentId: equipmentId,
                commandTypeId: commandTypeId,
                key: equipmentId + ':' + commandTypeId
            };
        }
        return;
    }

    getDataExpiredTime(commandTypeId: string, equipmentId: string) {
        const id = this.getDocumentIdentifier(equipmentId, commandTypeId);
        const timer = this.timers.get(id.key);
        if (!timer) {
            return '';
        }
        return timer.timestamp.toDateString() + ' ' + timer.timestamp.toLocaleTimeString();
    }

    getDataExpiredmessage(dataName: string, commandTypeId: string, equipmentId: string) {
        if (commandTypeId === null || equipmentId === null) {
            return;
        }
        const id = this.getDocumentIdentifier(equipmentId, commandTypeId);
        const timer = this.timers.get(id.key);
        if (!timer) {
            return '';
        } else if (timer.timestamp.getTime() === new Date(0).getTime() || dataName === 'connectivity') {
            return 'No ' + dataName + ' data received. Check server availability, network and system settings.';
        }
        return (
            'No ' +
            dataName +
            ' updates received since ' +
            timer.timestamp.toDateString() +
            ' ' +
            timer.timestamp.toLocaleTimeString() +
            '. Check server availability, network and system settings.'
        );
    }

    public getSystemDataExpiredmessage(dataName: string, commandTypeId: string[], equipmentId: string) {
        if (commandTypeId === null || equipmentId === null) {
            if (!environment.production) console.error('Timer Message: Invalid Command or Equipment Id');
            return;
        }
        const cpuId = commandTypeId[0] ? this.getDocumentIdentifier(equipmentId, commandTypeId[0]) : null;
        const memId = commandTypeId[1] ? this.getDocumentIdentifier(equipmentId, commandTypeId[1]) : null;
        const diskId = commandTypeId[2] ? this.getDocumentIdentifier(equipmentId, commandTypeId[2]) : null;

        const cpuTimer =
            cpuId !== null
                ? this.timers.get(cpuId.key)
                : { timer: null, count: 0, sub: null, timerSub: null, timestamp: new Date(0) }; // Empty Timer Object
        const memTimer =
            memId !== null
                ? this.timers.get(memId.key)
                : { timer: null, count: 0, sub: null, timerSub: null, timestamp: new Date(0) };
        const diskTimer =
            diskId !== null
                ? this.timers.get(diskId.key)
                : { timer: null, count: 0, sub: null, timerSub: null, timestamp: new Date(0) };

        if (!cpuTimer && !memTimer && !diskTimer) {
            return '';
        } else if (
            cpuTimer &&
            cpuTimer.timestamp.getTime() === new Date(0).getTime() &&
            memTimer &&
            memTimer.timestamp.getTime() === new Date(0).getTime() &&
            diskTimer &&
            diskTimer.timestamp.getTime() === new Date(0).getTime()
        ) {
            return 'No ' + dataName + ' data received. Check server availability, network and system settings.';
        }
        let latestExpired: Date = new Date(); // Most recently expired timer
        let newCpuIdCommandTypeId;
        let newMemIdCommandTypeId;
        let newDiskIdCommandTypeId;

        if (cpuId) newCpuIdCommandTypeId = this.timePeriods.get(cpuId!.commandTypeId);
        if (memId) newMemIdCommandTypeId = this.timePeriods.get(memId!.commandTypeId);
        if (diskId) newDiskIdCommandTypeId = this.timePeriods.get(diskId!.commandTypeId);

        if (
            cpuTimer &&
            cpuTimer.timer !== null &&
            newCpuIdCommandTypeId &&
            latestExpired.getTime() - cpuTimer.timestamp.getTime() > newCpuIdCommandTypeId
        ) {
            latestExpired = new Date(cpuTimer.timestamp);
        }
        if (
            memTimer &&
            memTimer.timer !== null &&
            newMemIdCommandTypeId &&
            latestExpired.getTime() - memTimer.timestamp.getTime() > newMemIdCommandTypeId
        ) {
            latestExpired = new Date(memTimer.timestamp);
        }
        if (
            diskTimer &&
            diskTimer.timer !== null &&
            newDiskIdCommandTypeId &&
            latestExpired.getTime() - diskTimer.timestamp.getTime() > newDiskIdCommandTypeId
        ) {
            latestExpired = new Date(diskTimer.timestamp);
        }

        if (latestExpired.getTime() === new Date(0).getTime()) {
            // If the timed out data never arrived
            return 'No ' + dataName + ' data received. Check server availability, network and system settings.';
        }
        return (
            'No ' +
            dataName +
            ' updates received since ' +
            latestExpired.toDateString() +
            ' ' +
            latestExpired.toLocaleTimeString() +
            '. Check server availability, network and system settings.'
        );
    }

    formatSystemTimeoutString(
        processorExpired: boolean = false,
        diskExpired: boolean = false,
        memoryExpired: boolean = false
    ) {
        let message = '';
        const dataNames = [];
        if (processorExpired) {
            dataNames.push('processor');
        }
        if (memoryExpired) {
            dataNames.push('memory');
        }
        if (diskExpired) {
            dataNames.push('disk');
        }

        if (dataNames.length === 1) {
            return dataNames[0];
        }

        for (let i = 0; i < dataNames.length - 1; i++) {
            message += dataNames[i] + (i !== dataNames.length - 2 ? ', ' : ' '); // check if this is that last itteration of the loop
        }
        message += 'or ' + dataNames[dataNames.length - 1];

        return message;
    }

    public getIsDataExpired(commandTypeId: string, updateTime: string): boolean {
        return Date.now() - this.timePeriods.get(commandTypeId.toLowerCase()) > Date.parse(updateTime);
    }
}
