import * as MemoryUsageActions from '@actions/memory-usage/memory-usage.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService, TimeoutService } from '@services/index';
import { RealTimeNotify } from '@models/index';


@Injectable()
export class MemoryUsageEffects {

  constructor(private actions$: Actions, private realtimeGatewayService: RealtimeGatewayService, 
    private timeoutService: TimeoutService) {}

    getNotifyProcessUsage$ = createEffect(() => this.actions$.pipe(
      ofType(MemoryUsageActions.GetNotifyMemoryUsage),
      switchMap((action) => this.realtimeGatewayService.observe(action.equipmentId, action.commandTypeId).pipe(
        catchError((err) => of(
          MemoryUsageActions.GetNotifyMemoryUsageFailure({ error: err })
          )
        ),
        mergeMap((data: RealTimeNotify) => [
          MemoryUsageActions.GetNotifyMemoryUsageSuccess({ data }),
          MemoryUsageActions.GetMemoryUsage({ ...data })
        ],
        ),
      ),
      )
    ));
  

    getMemoryUsage$ = createEffect(() => this.actions$.pipe(
      ofType(MemoryUsageActions.GetMemoryUsage),
      mergeMap((action) => this.realtimeGatewayService.getDocument(action.equipmentId, action.commandTypeId).pipe(
        map((data) => MemoryUsageActions.GetMemoryUsageSuccess({ data:{ data, equipmentId: action.equipmentId} }),
        ),
        catchError((err) => of(MemoryUsageActions.GetMemoryUsageFailure({ error: err })))
      ))
    ));

    getMemoryUsageTimer$ = createEffect(() => this.actions$.pipe(
      ofType(MemoryUsageActions.GetMemoryUsageTimer),
      mergeMap((action) => this.timeoutService.observeTimer(action.equipmentId, action.command).pipe(
        map((data) => MemoryUsageActions.GetMemoryUsageSuccess({ data:{ data, equipmentId: action.equipmentId} }),
        ),
        catchError((err) => of(MemoryUsageActions.GetMemoryUsageFailure({ error: err })))
      ))
    ));

}
