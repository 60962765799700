import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackBarComponent } from 'src/app/components/snack-bar/snack-bar.component';

export type MessageType = 'info' | 'success' | 'warning' | 'error';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(private readonly snackBar: MatSnackBar) {}

    public notify(title: string, type: MessageType, message?: string, duration?: number) {
        this.showSnackBar({
            duration: duration ? duration : 3000,
            horizontalPosition: 'end',
            data: {
                message: message,
                messageType: type,
                title: title
            }
        });
    }

    private showSnackBar(configuration: MatSnackBarConfig) {
        this.snackBar.openFromComponent(SnackBarComponent, configuration);
    }
}
