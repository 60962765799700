import * as ProcessAcdCallsActions from '@actions/process-acd-calls/process-acd-calls.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RealtimeGatewayService } from '@services/index';
import { RealTimeNotify } from '@models/index';

@Injectable()
export class ProcessAcdCallsEffects {

  constructor(private actions$: Actions, private realtimeGatewayService: RealtimeGatewayService) { }

  getNotifyProcessAcdCalls$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessAcdCallsActions.GetNotifyProcessAcdCalls),
    switchMap((action) => this.realtimeGatewayService.observe(action.equipmentId, action.commandTypeId).pipe(
      catchError((err) => of(
        ProcessAcdCallsActions.GetNotifyProcessAcdCallsFailure({ error: err })
        )
      ),
      mergeMap((data: RealTimeNotify) => [
        ProcessAcdCallsActions.GetNotifyProcessAcdCallsSuccess({ data }),
        ProcessAcdCallsActions.GetProcessAcdCalls({ ...data })
      ],
      ),
    ),
    )
  ));

  getProcessAcdCalls$ = createEffect(() => this.actions$.pipe(
    ofType(ProcessAcdCallsActions.GetProcessAcdCalls),
    mergeMap((action) => this.realtimeGatewayService.getDocument(action.equipmentId, action.commandTypeId).pipe(
      map((data) => ProcessAcdCallsActions.GetProcessAcdCallsSuccess({ data:{ data, equipmentId: action.equipmentId} }),
      ),
      catchError((err) => of(ProcessAcdCallsActions.GetProcessAcdCallsFailure({ error: err })))
    ))
  ));

}
