import * as EquipmentInfoActions from '@actions/equipment-info/equipment-info.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { DashboardService } from '@services/index';



@Injectable()
export class EquipmentInfoEffects {

  constructor(private actions$: Actions, private dashboardService: DashboardService,) { }
  getEquipmentInfo$ = createEffect(() => this.actions$.pipe(
    ofType(EquipmentInfoActions.GetEquipmentInfo),
    switchMap((action) => this.dashboardService.getEquipmentInfo(action.equipmentId).pipe(
      catchError((err) => of(
        EquipmentInfoActions.GetEquipmentInfoFailure({ error: err })
      )
      ),
      mergeMap((data: any) => [
        EquipmentInfoActions.GetEquipmentInfoSuccess({ data: data, equipmentId: action.equipmentId }),
      ],
      ),
    ),
    )
  ));

}
