import {
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingDropdownGrouped,
    SelectItem,
    DashletSettingList,
    DashletSettingText,
    DashletSettingCheckbox
} from '@models/index';
import { AccountService, DashletSettingsService } from '@services/index';
import { forkJoin, Observable } from 'rxjs';

export class DashletSettingsFormAvayaAXPCloudSummary extends DashletSettingsForm {
    private customer: DashletSettingDropdown;
    private services: DashletSettingDropdown;
    public period: DashletSettingDropdown;
    private mosCapableReceiver!: DashletSettingList<string>;
    private customSiteNames: DashletSettingList<string>;

    public allEquipments = [];

    private isCust: boolean;
    private customerId: string;

    constructor(
        private readonly settingsService: DashletSettingsService,
        private readonly accountService: AccountService
    ) {
        super();
        this.init();
        this.loadCustomers();
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);

        if (this.customer.value) this.loadServices(this.customer.value);

        if (this.services.value) {
            this.loadMoscapableReceivers();
            this.loadCustomSiteNames(this.services.value);
        }
    }

    private init(): void {
        const group = new DashletSettingsGroup();
        const s = (group.settings = []);
        const subscriptionsGroup = new DashletSettingsGroup();
        subscriptionsGroup.title = 'Subscriptions';

        const subscriptionSettings = (subscriptionsGroup.settings = []);

        const period = (this.period = new DashletSettingDropdown());

        period.label = 'Summary Period';
        period.stateKey = 'summaryPeriod';
        period.items = [];

        const daily = new SelectItem();
        daily.label = '24 hours';
        daily.value = '24';
        period.items.push(daily);

        const twelveHourly = new SelectItem();
        twelveHourly.label = '12 hours';
        twelveHourly.value = '12';

        period.items.push(twelveHourly);

        const sixHourly = new SelectItem();
        sixHourly.label = '6 hours';
        sixHourly.value = '6';
        period.items.push(sixHourly);

        const hourly = new SelectItem();
        hourly.label = '1 hour';
        hourly.value = '1';
        period.items.push(hourly);
        s.push(period);

        const user = this.accountService.getUserDetails();
        this.isCust = user.EntityGroup === 2;
        this.customerId = user.EntityId;

        this.customer = new DashletSettingDropdown();
        this.customer.label = 'Customer';
        this.customer.stateKey = 'customer';
        this.customer.disabled = true;
        this.customer.change.subscribe(() => {
            this.clearAndDisable(this.services);
            this.clearAndDisable(this.mosCapableReceiver);
            this.clearAndDisable(this.customSiteNames);
            this.loadServices(this.customer.value);
        });
        s.push(this.customer);

        this.services = new DashletSettingDropdown();
        this.services.label = 'Service';
        this.services.stateKey = 'service';
        this.services.disabled = true;
        this.services.change.subscribe(() => {
            this.clearAndDisable(this.mosCapableReceiver);
            if (!this.customSiteNames.values) this.clearAndDisable(this.customSiteNames);
            this.loadMoscapableReceivers();
        });
        s.push(this.services);

        this.mosCapableReceiver = new DashletSettingList();
        this.mosCapableReceiver.label = 'Source Equipment';
        this.mosCapableReceiver.stateKey = 'equipment';
        this.mosCapableReceiver.disabled = true;
        this.mosCapableReceiver.change.subscribe(() => {
            if (!this.customSiteNames.values) this.clearAndDisable(this.customSiteNames);
            this.loadCustomSiteNames(this.services.value);
        });
        s.push(this.mosCapableReceiver);

        this.customSiteNames = new DashletSettingList();
        this.customSiteNames.label = 'Custom Site Name';
        this.customSiteNames.stateKey = 'customSiteNames';
        this.customSiteNames.disabled = true;
        s.push(this.customSiteNames);

        const showPSTNCallsOnly = new DashletSettingCheckbox();
        showPSTNCallsOnly.label = 'Show PSTN Calls Only';
        showPSTNCallsOnly.stateKey = 'showPSTNCallsOnly';
        showPSTNCallsOnly.disabled = true;
        showPSTNCallsOnly.customClass = 'pstn-calls-only';
        s.push(showPSTNCallsOnly);

        const customName = new DashletSettingText(40);
        customName.label = 'Custom Dashlet Name';
        customName.stateKey = 'customName';
        s.push(customName);

        const voice = new DashletSettingCheckbox();
        voice.label = 'Voice';
        voice.stateKey = 'voice';
        voice.disabled = false;
        voice.customClass = 'pstn-calls-only';
        subscriptionSettings.push(voice);

        const email = new DashletSettingCheckbox();
        email.label = 'Email';
        email.stateKey = 'email';
        email.disabled = false;
        email.customClass = 'pstn-calls-only';
        subscriptionSettings.push(email);

        const chat = new DashletSettingCheckbox();
        chat.label = 'Chat';
        chat.stateKey = 'chat';
        chat.disabled = false;
        chat.customClass = 'pstn-calls-only';
        subscriptionSettings.push(chat);

        const messaging = new DashletSettingCheckbox();
        messaging.label = 'Messaging';
        messaging.stateKey = 'messaging';
        messaging.disabled = false;
        messaging.customClass = 'pstn-calls-only';
        subscriptionSettings.push(messaging);

        const analytics = new DashletSettingCheckbox();
        analytics.label = 'Analytics';
        analytics.stateKey = 'analytics';
        analytics.disabled = false;
        analytics.customClass = 'pstn-calls-only';
        subscriptionSettings.push(analytics);

        const socialConnections = new DashletSettingCheckbox();
        socialConnections.label = 'Social Connections';
        socialConnections.stateKey = 'socialConnections';
        socialConnections.disabled = false;
        socialConnections.customClass = 'pstn-calls-only';
        subscriptionSettings.push(socialConnections);

        const apiAccess = new DashletSettingCheckbox();
        apiAccess.label = 'Api Access';
        apiAccess.stateKey = 'apiAccess';
        apiAccess.disabled = false;
        apiAccess.customClass = 'pstn-calls-only';
        subscriptionSettings.push(apiAccess);

        const previewDialing = new DashletSettingCheckbox();
        previewDialing.label = 'Preview Dialing';
        previewDialing.stateKey = 'previewDialing';
        previewDialing.disabled = false;
        previewDialing.customClass = 'pstn-calls-only';
        subscriptionSettings.push(previewDialing);

        const allSetgroup = new DashletSettingsGroup();
        allSetgroup.groups = [group, subscriptionsGroup];
        this.updateSettings(allSetgroup);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);

        this.settingsService.getUserAssociatedCustomers().subscribe(
            customers => {
                this.customer.items = customers;
                this.customer.loadingComplete();
            },
            () => {
                this.customer.loadingComplete();
                this.customer.disabled = true;
                //show error notification here
            }
        );
    }

    private loadServices(customer: string): void {
        this.enableAndLoading(this.services);

        this.settingsService.getAvayaAxpCloudTenants(customer).subscribe(
            locations => {
                this.services.items = locations;
                this.services.loadingComplete();
            },
            () => {
                this.services.loadingComplete();
                this.services.disabled = true;
            }
        );
    }

    private loadMoscapableReceivers(): void {
        this.enableAndLoading(this.mosCapableReceiver);
        this.settingsService.getAssociatedAvayaAxpLocations(this.services.value!).subscribe(locations => {
            const receivers: Observable<SelectItem[]>[] = [];
            for (const location of locations) {
                receivers.push(this.settingsService.getMosCapableReceivers(location.value));
            }
            forkJoin(receivers).subscribe({
                next: results => {
                    this.mosCapableReceiver.items = results.flat();
                    if (!results.flat().length) {
                        this.mosCapableReceiver.disabled = true;
                    }
                    this.mosCapableReceiver.loadingComplete();
                },
                error: () => {
                    this.mosCapableReceiver.loadingComplete();
                    this.mosCapableReceiver.disabled = true;
                }
            });
        });
    }

    private loadCustomSiteNames(equipmentId: string): void {
        this.enableAndLoading(this.customSiteNames);
        this.settingsService.getSites(equipmentId).subscribe({
            next: (customSiteNames: SelectItem[]) => {
                this.customSiteNames.items = customSiteNames;
                this.customSiteNames.loadingComplete();
            },
            error: () => {
                this.customSiteNames.loadingComplete();
                this.customSiteNames.disabled = true;
            }
        });
    }

    private clearAndDisable(
        widget: DashletSettingDropdownGrouped | DashletSettingDropdown | DashletSettingList<string>
    ) {
        widget.disabled = true;
        widget.items = [];
        if (widget instanceof DashletSettingList) widget.values = [];
        else widget.value = null;
    }

    private enableAndLoading(
        widget: DashletSettingDropdownGrouped | DashletSettingDropdown | DashletSettingList<string>
    ) {
        widget.disabled = false;
        widget.loadingBegin();
    }
}
