<div class="flex-column gap-l">
    <div class="flex gap-m">
        <div class="flex-column text-align-right equipment-detail-labels">
            <div class="mat-body-2">VMWare Management</div>
            <div class="mat-body-2">Total Servers</div>
            <div class="mat-body-2">Average Response</div>
        </div>
        <div class="flex-column equipment-detail-values">
            <div class="mat-body-1">{{ dashlet.equipment.equipmentName || '---' }}</div>
            <div class="mat-body-1">{{ dashlet.virtualMachines.length || '---' }}</div>
            <div class="mat-body-1">{{getAverageResponse()}} </div>
        </div>
    </div>
</div>
<dashlet-table
    [dataSource]="dashlet.hosts"
    [columns]="columns"
    [rowExpansionColumns]="expansionColumns"
    rowExpansionField="virtualMachines"
    [pagination]="true"
    [pageLength]="10"
    [tableHeightRows]="10"
    [loading]="loading"
    sortDirection="desc"
    defaultSort="severity"
></dashlet-table>

