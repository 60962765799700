import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

//  Get Notify
export const GetNotifyDiskUsage = createAction(
  '[DiskUsage] Get Notify DiskUsage',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetNotifyDiskUsageSuccess = createAction(
  '[DiskUsage] Get Notify DiskUsage Success',
  props<{ data: RealTimeNotify }>()
);

export const GetNotifyDiskUsageFailure = createAction(
  '[DiskUsage] Get Notify DiskUsage Failure',
  props<{ error: any }>()
);

// Get Disk usage 
export const GetDiskUsage = createAction(
  '[DiskUsage] Get DiskUsage',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetDiskUsageSuccess = createAction(
  '[DiskUsage] Get DiskUsage Success',
  props<{ data: any}>()
);

export const GetDiskUsageFailure = createAction(
  '[DiskUsage] Get DiskUsage Failure',
  props<{ error: any }>()
);


// Get Disk usage timer
export const GetDiskUsageTimer = createAction(
  '[DiskUsage] Get DiskUsage Timer',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetDiskUsageTimerSuccess = createAction(
  '[DiskUsage] Get DiskUsage Timer Success',
  props<{ data: any}>()
);

export const GetDiskUsageTimerFailure = createAction(
  '[DiskUsage] Get DiskUsage Timer Failure',
  props<{ error: any }>()
);