/* eslint-disable no-undef */
/* eslint-disable @angular-eslint/component-selector */
import * as Actions from '@actions/index';
import { DashletService } from '@services/index';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CustomerLogo, DashletSystemHealthCiscoUnityConnection } from '@models/index';
import { select, Store } from '@ngrx/store';
import { AppState, selectEntity } from '@reducers/index';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'dashlet-system-health-cisco-unity-connection',
    templateUrl: 'dashlet-system-health-cisco-unity-connection.component.html',
    styleUrls: ['dashlet-system-health-cisco-unity-connection.component.scss']
})
export class DashletSystemHealthCiscoUnityConnectionComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletSystemHealthCiscoUnityConnection;
    private destroy$ = new Subject<void>();
    constructor(private dashletService: DashletService, private store$: Store<AppState>) {}

    public openSubcontainer = '';

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    ngOnInit(): void {
        // Logo
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.store$
            .pipe(select(selectEntity(this.dashlet.customer.customerId)), takeUntil(this.destroy$))
            .subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            });
    }

    openDetails(container: string) {
        this.openSubcontainer = container;
        this.dashlet.displayHeader = false;
    }

    getSeverityColor(sev: number) {
        return this.dashletService.getSeverityColor(sev);
    }

    closeDetails() {
        this.openSubcontainer = null;
        this.dashlet.displayHeader = true;
    }
}
