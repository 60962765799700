/* eslint-disable no-undef */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { catchError, map, Observable, of } from 'rxjs';

import { FeatureSubscriptions, ReportNavMenu, UserDetails } from '@models/index';
import { environment } from '@environments/environment';

@Injectable()
export class ReportService {
    readonly networkConnectivityReportId = '786e2f9e-5f31-473c-9863-d11e9121653f';
    readonly mediaGatewayTimeoutReportId = 'b6e823e6-dde3-49e5-9063-759bb7abe876';
    readonly ds1PerformanceReportId = '4dd83fad-7546-48fe-b0b8-80d37cc06f3b';
    readonly osCpuReportId = 'a5f06f19-609d-446f-b287-0a1da1fa812c';
    readonly osMemoryReportId = '5ee44939-1d67-4462-b785-05dc4ab40a53';
    readonly osDiskReportId = '2d6ef69e-8635-43f2-8093-f2f2f85124d5';
    readonly acmCallRateReportId = '2da436cc-6537-42f6-b844-1a033028bf22';
    readonly phoneDataReportId = '4a7ad8c3-bb02-446d-838c-a33177c6e55f';

    private readonly CONFIGURATION_FEATURE_ID = '77ef9012-c756-4159-a444-9d0e8889e7ee';
    private readonly SERVICE_DESK_FEATURE_ID = '676b880a-73ec-41ac-95a4-4bfe939a3c42';
    private readonly API_FEATURE_ID = '6854adfd-9efc-4b9f-976f-776bbde5a744';
    private readonly CAPACITY_FEATURE_ID = '49f20cb3-d902-42f7-a44b-5a5b3f3372ad';
    private readonly CONTINUITY_FEATURE_ID = '22ccfb83-b598-46a3-a4dc-29fd76ad2795';
    private readonly AVAILABILITY_FEATURE_ID = '4601a881-fbd7-4f37-9d4f-4a4b7adfd8b5';
    private readonly CHANGE_FEATURE_ID = '4177f510-e141-4e4a-814e-b300ede48e6d';
    private readonly RELEASE_FEATURE_ID = 'c95e2b02-0757-424f-a5d3-4449345dabfb';
    private readonly SECURITY_FEATURE_ID = 'b52abb0c-839a-4d00-9dd1-5bf1044af052';

    constructor(protected http: HttpClient) {}

    public getEntityReportList(entityGroup: string) {
        return <Observable<ReportNavMenu[]>>(
            this.http.get<ReportNavMenu[]>('API/ReportNg/GetEntityReportList?entityGroup=' + entityGroup)
        );
    }

    public openReport(reportId: string, customerId: string, locationId?: string, equipmentId?: string): void {
        let url = `${environment.reportingUrl}viewer?reportId=${reportId}&entityId=${customerId}`;
        if (locationId) {
            url += `&locationId=${locationId}`;
        }
        if (equipmentId) {
            url += `&equipmentId=${equipmentId}`;
        }
        window.open(url);
    }

    public getReportName(reportId: string): string {
        switch (reportId) {
            case this.networkConnectivityReportId:
                return 'Network Connectivity';
            case this.osCpuReportId:
                return 'OS Processor Occupancy';
            case this.osMemoryReportId:
                return 'OS Memory Usage';
            case this.osDiskReportId:
                return 'OS Disk Free Disk Space';
            case this.acmCallRateReportId:
                return 'Call Rate';
            default:
                return '';
        }
    }

    public getSubscriptions(customerId: string): Observable<FeatureSubscriptions> {
        return this.http
            .get<{ name: string; featureId: string }[]>(`${environment.centralApi}Feature/${customerId}/Subscriptions`)
            .pipe(
                map(response => {
                    const featureIds = response.map(element => element.featureId.toLocaleLowerCase());
                    return {
                        capacityEnabled: featureIds.includes(this.CAPACITY_FEATURE_ID),
                        availabilityEnabled: featureIds.includes(this.AVAILABILITY_FEATURE_ID),
                        releaseEnabled: featureIds.includes(this.RELEASE_FEATURE_ID),
                        apiEnabled: featureIds.includes(this.API_FEATURE_ID),
                        serviceDeskEnabled: featureIds.includes(this.SERVICE_DESK_FEATURE_ID),
                        configurationEnabled: featureIds.includes(this.CONFIGURATION_FEATURE_ID),
                        continuityEnabled: featureIds.includes(this.CONTINUITY_FEATURE_ID),
                        changeEnabled: featureIds.includes(this.CHANGE_FEATURE_ID),
                        securityEnabled: featureIds.includes(this.SECURITY_FEATURE_ID)
                    };
                })
            );
    }
}
