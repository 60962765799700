<div class="flex-column gap-l">
    <!-- System -->
    <dashlet-section-processor-memory
        #processorMemory
        [showDiskUsage]="false"
        [showSystemChart]="dashlet.showSystemChart"
        [equipment]="dashlet.equipment"
        [customerId]="dashlet.customer.customerId"
        [locationId]="dashlet.location.locationId"
        [commandTypeIdCPUUsage]="dashlet.commandTypeIdCpu"
        [commandTypeIdUpTime]="dashlet.commandTypeIdUptime"
    >
    </dashlet-section-processor-memory>
    <!-- WAN Links -->
    <div>
        <dashlet-section-header> WAN Links </dashlet-section-header>
        <dashlet-table [columns]="wanColumns" [dataSource]="dashlet.wanLinks" [tableHeightRows]="1"></dashlet-table>
    </div>
    <!-- Media Utilization -->
    <div>
        <dashlet-section-header>Media Utilization</dashlet-section-header>
        <dashlet-table [columns]="mediaColumns" [dataSource]="dashlet.media" [tableHeightRows]="1"></dashlet-table>
    </div>
    <!-- Sessions -->
    <div>
        <dashlet-section-header>Sessions</dashlet-section-header>
        <div class="justify-space-between metrics gap-l grow">
            <div class="flex-column align-center gap-s">
                <div class="mat-caption">Sip</div>
                <div class="mat-h2">{{ dashlet.sessionsSip }}</div>
            </div>
            <div class="flex-column align-center gap-s">
                <div class="mat-caption">SIP to H.323</div>
                <div class="mat-h2">{{ dashlet.sessionsSipToH323 }}</div>
            </div>
            <div class="flex-column align-center gap-s">
                <div class="mat-caption">H.323</div>
                <div class="mat-h2">{{ dashlet.sessionsH323 }}</div>
            </div>
            <div class="flex-column align-center gap-s">
                <div class="mat-caption">H.323 to SIP</div>
                <div class="mat-h2">{{ dashlet.sessionsH323ToSip }}</div>
            </div>
        </div>
        
    </div>
    <div>
        <dashlet-section-header>Realms</dashlet-section-header>
        <dashlet-table [columns]="realmsColumns" [dataSource]="dashlet.realms" [tableHeightRows]="4"></dashlet-table>
        
    </div>
</div>
