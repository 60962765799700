import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as EntityLogoActions from '@actions/entity-logo/entity-logo.actions';
import { BrandingService } from '@services/index';
import { of } from 'rxjs';

import { catchError, map, mergeMap } from 'rxjs/operators';
import { LogoImage, LogoRespObj } from '@models/index';

@Injectable()
export class CustomerLogoEffects {
    constructor(private actions$: Actions, private brandingService: BrandingService) {}
    getEntityLogo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EntityLogoActions.GetEntityLogo),
            mergeMap(action =>
                this.brandingService.getCustomerLogo(action.entityId).pipe(
                    map((data: LogoImage) => {
                        return EntityLogoActions.GetEntityLogoSuccess({
                            data: {
                                image: data.image,
                                imageType: data.mimeType,
                                entityId: action.entityId
                            } as LogoRespObj
                        });
                    }),
                    catchError(err => of(EntityLogoActions.GetEntityLogoFailure({ error: err })))
                )
            )
        )
    );
}
