import {
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingList,
    DashletSettingText
} from '@models/index';
import { AccountService, DashletSettingsService } from '@services/index';

export class DashletSettingsFormAlarmsSummary extends DashletSettingsForm {
    private customer!: DashletSettingList<string>;
    private equipmentGroups!: DashletSettingList<string>;
    private severityGroups!: DashletSettingList<any>;

    private isCust!: boolean;
    private customerId!: string;

    constructor(
        private readonly settingsService: DashletSettingsService,
        private readonly accountService: AccountService
    ) {
        super();

        this.init();
        this.loadSeverity();
        if (!this.isCust) {
            this.loadCustomers();
        } else {
            this.loadEquipment(this.customerId);
        }
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);

        if (this.isCust) {
            this.loadEquipment(this.customerId);
        }
    }

    private init(): void {
        const group = new DashletSettingsGroup();
        const s: any = (group.settings = []);

        const user = this.accountService.getUserDetails();
        this.isCust = user.EntityGroup === 2;
        this.customerId = user.EntityId;

        if (!this.isCust) {
            const customer = (this.customer = new DashletSettingList<string>());
            customer.label = 'Customer';
            customer.stateKey = 'customer';
            customer.disabled = true;
            customer.multiple = true;
            customer.metaKeySelection = false;
            customer.filter = true;
            customer.change.subscribe(() => {
                if (this.isCust) {
                    this.clearAndDisable(this.equipmentGroups);
                    this.loadEquipment(this.customerId);
                }
            });
            s.push(customer);
        }

        if (this.isCust) {
            const equipment = (this.equipmentGroups = new DashletSettingList<string>());
            equipment.label = 'Equipment';
            equipment.stateKey = 'equipment';
            equipment.disabled = true;
            equipment.multiple = true;
            equipment.metaKeySelection = false;
            equipment.filter = true;
            s.push(equipment);
        }

        const severity = (this.severityGroups = new DashletSettingList<string>());
        severity.label = 'Severity';
        severity.stateKey = 'severity';
        severity.disabled = true;
        severity.multiple = true;
        severity.metaKeySelection = false;
        s.push(severity);

        const customName = new DashletSettingText(40);
        customName.label = 'Custom Dashlet Name';
        customName.stateKey = 'customName';
        s.push(customName);

        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.customer.enable();
        this.customer.loadingBegin();
        this.settingsService.getUserAssociatedCustomers().subscribe(
            customers => {
                this.customer.items = customers;
                this.customer.loadingComplete();
            },
            () => {
                this.customer.loadingComplete();
                this.customer.disabled = true;
                // show error notification here
            }
        );
    }

    private loadEquipment(customer: string): void {
        this.enableAndLoading(this.equipmentGroups);
        this.settingsService.getEquipmentForCustomerList(customer).subscribe(
            receivers => {
                this.equipmentGroups.items = receivers;
                this.equipmentGroups.loadingComplete();
            },
            () => {
                this.equipmentGroups.loadingComplete();
                this.equipmentGroups.disabled = true;
                // show error notification here
            }
        );
    }

    private loadSeverity(): void {
        this.enableAndLoading(this.severityGroups);
        const g = [];
        for (let i = 0; i < 3; i++) {
            g.push({ label: `${i} - High`, value: i });
        }

        for (let i = 3; i < 6; i++) {
            g.push({ label: `${i} - Medium`, value: i });
        }

        for (let i = 6; i < 11; i++) {
            g.push({ label: `${i} - Low`, value: i });
        }

        this.severityGroups.items = g;
        this.severityGroups.loadingComplete();
    }

    private clearAndDisable(widget: DashletSettingDropdown | DashletSettingList<string>) {
        widget.disabled = true;
        widget.items = [];
        if (widget instanceof DashletSettingList) {
            widget.values = [];
        } else {
            widget.value = null;
        }
    }

    private enableAndLoading(widget: DashletSettingDropdown | DashletSettingList<string>) {
        widget.disabled = false;
        widget.loadingBegin();
    }
}
