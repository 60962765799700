import {
    DashletSettingCheckbox,
    Dashboard,
    DashletSettingText,
    DashletSettingsForm,
    DashletSettingsGroup
} from '@models/index';

export class DashletSettingsFormDashboard extends DashletSettingsForm {
    constructor() {
        super();
        this.init();
    }

    private init(): void {
        const adminGroup = new DashletSettingsGroup();
        const s: any = (adminGroup.settings = []);

        const name: any = new DashletSettingText();
        name.label = 'Name';
        name.stateKey = Dashboard.SETTING_NAME_KEY;
        s.push(name);

        const behaviorGroup = new DashletSettingsGroup();
        behaviorGroup.title = 'Behavior';
        const behaviorSettings: any = (behaviorGroup.settings = []);

        const autoReposition = new DashletSettingCheckbox();
        autoReposition.label = 'Automatically Reposition Dashlets';
        autoReposition.stateKey = Dashboard.SETTING_COMPACT_KEY;
        autoReposition.disabled = false;
        behaviorSettings.push(autoReposition);

        const group = new DashletSettingsGroup();
        group.groups = [adminGroup, behaviorGroup];
        super.updateSettings(group);
    }
}
