<ng-template #loadingTemplate>
    <!-- loading state -->
    <div class="loading">
        <vsm-dashlet-loading [message]="''" [size]="'large'"><span class="mat-h2">Loading Dashboard</span></vsm-dashlet-loading>
    </div>
</ng-template>
<ng-container *ngIf="!loading; else loadingTemplate">
    <!-- grid -->
    <div class="dashboard-container" [@hideToolbar]="fullscreen ? 'hidden' : 'shown'">
        <mat-toolbar color="primary" class="justify-space-between mat-elevation-z6">
            <div>
                <img class="clickable bp-logo" (click)="navigateToWebPortal()" *ngIf="bpLogo$ | async as logo; else noLogo" [src]="logo" />
                <ng-template #noLogo>
                    <img class="clickable bp-logo" (click)="navigateToWebPortal()" src="src/assets/images/WhiteVirsaeLogo.png" />
                </ng-template>
            </div>
            <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon aria-hidden="false" aria-label="Account">account_circle</mat-icon></button>
        </mat-toolbar>

        <button [@slideUp]="!isIdle() ? 'default' : 'up'" *ngIf="isConnected | async" (click)="toggleFullscreen()" mat-icon-button class="fullscreen-button">
            <mat-icon [@rotateButton]="fullscreen ? 'up' : 'down'" aria-hidden="false" aria-label="fullscreen" class="expand-icon"> expand_more</mat-icon>
        </button>

        <tile-grid
            [fullscreen]="fullscreen"
            [dashlets]="dashlets"
            [interactive]="!isIdle()"
            (gridChange)="persist()"
            (dashletRemove)="removeDashlet($event, deleteDashletDialog)"
            (showDashletSettings)="showDashletSettings($event, settingsDialog)"
        ></tile-grid>
    </div>
    <!-- connection error -->

    <div class="alert-disconnect flex center" *ngIf="(isConnected | async) === false && !isUnloading">
        <div>
            <mat-card class="mat-elevation-z24">
                <mat-card-content>
                    <div class="flex gap-xl align-center">
                        <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABQCAYAAACZM2JkAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAABcQwAAXEMBOS4mtwAAABl0R
                Vh0U29mdHdhcmUAcGFpbnQubmV0IDQuMC4xOdTWsmQAAAdaSURBVHhe7VxpiBxFFB5jNBrFM7punO3qmhlXHUGCQQVFlsQLVDyIaCA/xANFvH54gZgokXigwSDiDzX6IxolaEAhRDySoGgMWRFD
                BJFEiebYzc61STYmu8mO35t9GWZ73s7O0d3VM9MffEyyXVXv1Tevq15XVU8k6MhHIsfltL48rdQycEvatkczSg3j89eMbS+layhzPBcPUQ+2JpMnQsyFEPgwPvMScW0YfCvf0zOVq4WoBRSlEPJ
                9p7ASC1Fu2x+EYteBrFK3Q8CjTlEnIomNOvdy9RDVYF8icQ6Gg72SoBWpVP9+rTu4mRCVQJMfovNNUcgqWBiv0QY3F2IipOPxSyD0QUnEagihRwZt+0puLoSE3tmzT4BQGyQBayG+qG/DibEC0p
                Z1cy0T4EREG0fweSs3G6IU/cnkqRBna6lgjRBi/01tcvMhjgHCPCMJ1iAXcfMhCKlo9HykZjlBqIZIKWIqkYiymRAQpaonwDq5ks20N/A0N4snL0mkhkmTa0rr69hceyJP6Zxt/yAJ5CqV2kS22
                Gz7ASLfDSEaTucmI+yMYryez2bbC3mkXhBguySMF6SJcX8sdi6bbw/QWkTKtt+QBPGS+GJfYRfaAznLinmRzk1KpQbxqdiN1saqsQX9leME8JdrcEdNYXdaF1nLmkuTkyCAL8RYPYyUcg6705oY
                TSSmobO/ODtvgJvJF3ar9ZDV+n6h02ZoWY+wW62Ffd3dMzBkZMROm6BSfQNdXTPZvdYBOvaq2GGDxBf/DrvXGtin1EXoWP3bU3QnKNU/ARtJE0dSlpVkN5sblEqhQ2scHayJEPquVCJxmsQBpR6
                W6lRLtL2uJdZBUlrPQ2caWs+gMx7cXBkQkQ1NsOQbUr6buLnmRD6ZPBGd+E3qYC30UmjmtvXNuplL6xmUQgmdqpk+CE02FnOTzQU4rzBR0dqC2LFa6IfQtLpHJ6S42eYBHF8mdage+iF0gUq911
                TrIBmtL4XQI2Jn6qBfQhcmRsu6mpsONv5MJKalbPtHqSP10reIBmFrQ1Osg8DZ20BXt6f8FBpRTSuL93DzwcSejo5T4OS2UsfdoJ9CM//NxGKns4ngAUPGIsHphmlAaIrsJZSispngYK/W3W6lc
                06aEBo8QCeo2EwwQN88IuAjwVlXaEhoiurVgYrqlNZXUGokOesGTQmNO3Q4F5RTTvnOzukQmd75k511gcaEHmMvHZBnc+aQsawFgnOuMqXUHWyuDGmtH5DquEnYv4/NmcHgzJlnI5p3S865Sq0f
                ZZNlQKazUKzjLncbWwcprM75dNoIX+Z3bHYc1kciU3H9J2d5T6jUa0YmxlxXVxzG94tOucw0HovZ7DiQ0Li2SarjOpU6lLWsy9i0P6A3nmD88zJnPCLE/F6KJvID1zZLdTwinXLy7wV/jIs3wKh
                nh8edhJh7kXmcweaLoLdkMaykpTpeEH6M5Gz7RjbvLWg9w+t0TiJuW80uFIFs4GKprJdE37f/E42ezC54B0TzY5IDXpOOLLALRSDKZ0llvSbEfpZd8AZ8qxo5bYTb9kl2owhMUM9JZT2nUn1DXp
                5ygshvi4b94VJ2owjD/nzIbriLAdu+EI27tj1VB5eXZh6cx3/iKOMnjwxY1mx2xx0U0ijbXicY842w/9doySREWQj+lpXK+kVKO11dB0lpfSc65fnbU5WITu3Y1dk5nV2K7LCsM/16YKrAo5UWv
                GpCXqmT0Mk/BCN+c4iyDHaL9iZ7KK91lDHBnbu6u2ewW/WBxkFE8tNC40aY0/p6do3OjcyXypggAuBFdqs+oBFlehwsJXx5kIYPIv79lFTGBOFLpu5tL0TzFDSwXGrYFOHPEI3VRPx/yHndJBHV
                X5BmLF/1yGh9DTpm7O2pZiO+/MM1n3Ki47aovNHZmEmiI314/H8CkTOHiEC4BX/z/oX9Ggh/fibtWMbJgU4sCFg0b+xX6jx2rwj6Sc2sbS8Jkq+0tcbuVQa9rgDHU1IjJghfsn2WFWP3ykCvRSC
                f/kaqa4g7cceVLemWAeH/klDZGCH0x6WP3hLwKHytVNcU4XPll/sH6bRRwGZzOD3pK2q0xSTVNUalDvXH4wl2bzxeGEvnVosVDbIphSYq9ZW4DoKLPeiUb9tT1RJD2ZcYOiru00HoeVJdkyQtS5
                9kC6DD47gQhJfhJR5EWjfhb43S5I0yrv0wocv8vRgkNNH4cKyqUe4isUsjm/zu07oD1z5zlA0any84TM/oiObgvAw/AeHjfxj3VmEoWUxErv86/r7HWS6A3J2LRs+izdbHhYshXSSC5CHKm4OU7
                Lcm8WhO2cbOsgshXSUiOk1b9v7/WlebEUIfpYgOamrUStxDZyNWCBdCukmlPqWfe78K/wnCJmdLEoF8BE+uc49tWQVujaOFuLb4CzeFX/BSaotQKGRj3EraFkQ+htzYgZR3cfGAo3DIGokR4iAC
                d0XhiVACLZUOxmIXoODLqLAW7A1ZHaFZLwL166xSi3PxeILWYlhWIBL5H6w3ZdHKCe9xAAAAAElFTkSuQmCC"
                        />
                        <div class="flex-column gap-m">
                            <div class="mat-h2">Connection Lost: {{ lastConnected | async | date: 'medium' }}</div>
                            <div class="mat-body">Check your network settings, or contact your system administrator for assistance</div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div [@slideRight]="!isIdle() ? 'default' : 'right'" *ngIf="(canWrite | async) && (isConnected | async)" class="fabs flex-column gap-l">
        <button
            (click)="toggleCreateDashletDialog(addDashletDialog)"
            color="primary"
            mat-fab
            aria-label="add dashlet"
            aria-hidden="true"
            mat-tooltip="Add Dashlet"
        >
            <mat-icon>add</mat-icon>
        </button>
        <button (click)="toggleSettingsDialog(settingsDialog)" color="primary" mat-fab aria-label="Settings" aria-hidden="true" mat-tooltip="Settings">
            <mat-icon>settings</mat-icon>
        </button>
    </div>
</ng-container>
<!-- dialogs -->

<ng-template #deleteDashletDialog>
    <h3 mat-dialog-title>Delete Dashlet - {{ dashletToBeRemoved ? dashletToBeRemoved.title : null }}</h3>
    <mat-dialog-content>
        <p class="mat-body-2">Are you sure you want to remove this Dashlet?</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end"
        ><button mat-raised-button color="warn" mat-dialog-close (click)="deleteDashlet()">Delete</button>
        <button mat-raised-button mat-dialog-close (click)="cancelRemove()">Cancel</button></mat-dialog-actions
    >
</ng-template>

<ng-template #settingsDialog>
    <h3 mat-dialog-title>Settings</h3>
    <mat-dialog-content>
        <settings [(dashletNavSelected)]="selectedDashlet" (dashletChange)="persist()"></settings>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button color="primary" mat-raised-button mat-dialog-close (click)="updateDashletState()">Done</button>
    </mat-dialog-actions>
</ng-template>
<ng-template #addDashletDialog>
    <h3 mat-dialog-title>Add Dashlet</h3>
    <mat-dialog-content>
        <app-create-dashlet [dashletsMeta]="dashletsMeta" (createDashlet)="addItem($event)"></app-create-dashlet>
    </mat-dialog-content>
    <mat-dialog-actions align="end"><button color="primary" mat-raised-button mat-dialog-close (click)="updateDashletState()">Done</button></mat-dialog-actions>
</ng-template>

<mat-menu #menu="matMenu">
    <ng-template matMenuContent>
        <button mat-menu-item (click)="manageUser()">Edit Profile</button>
        <button mat-menu-item (click)="logout()">Log Out</button>
    </ng-template>
</mat-menu>
