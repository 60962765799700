<div class="flex-column gap-l">
    <div class="flex gap-m">
        <div class="flex-column equipment-detail-labels">
            <div class="mat-body-2">Average utilization</div>
            <div class="mat-body-2">Total Trunk Groups</div>
        </div>
        <div class="flex-column text-no-wrap">
            <div class="mat-body-1">{{ dashlet.trunkSummary.averageUtil || '---' }}%</div>
            <div class="mat-body-1">{{ dashlet.trunkSummary.totalTrunks || '---' }}</div>
        </div>
    </div>

    <dashlet-table
        [dataSource]="dashlet.trunkUtilSummary"
        [columns]="columns"
        rowExpansionField="expansion"
        [rowExpansionColumns]="rowExpansionColumns"
        [loading]="loading"
        [tableHeightRows]="20"
        [rowExpansionHeight]="15"
    ></dashlet-table>
</div>
