export enum DashletType {
    None,
    Sample1,
    VoiceQualityCallsInProgress,
    TrunkGroupUtilization,
    TrunkGroupTraffic,
    TrunkGroupList,
    VoiceQualityActiveStreams,
    VoiceQualityMeanOpinionScoreScatter,
    SystemHealthIpOffice,
    SystemHealthOracle,
    SystemHealthLinuxServer,
    SystemHealthWindowsServer,
    SystemHealthAcm,
    SystemHealthCMS,
    SystemHealthASM,
    SystemHealthAEP,
    SystemHealthAES,
    SystemHealthSBC,
    SystemHealthAudioCodesSBC,
    SystemHealthAvayaContact,
    SystemHealthCiscoUCM,
    SystemHealthCiscoCUBE,
    SystemHealthCiscoUnityConnection,
    SystemHEalthCiscoPresence,
    SystemHealthCiscoDialPeerSummary,
    SystemHealthEngelbartSoftwareEsuites,
    NetworkConnectivity,
    NetworkConnectivitySummary,
    SIPBandwidth,
    AlarmsSummary,
    PortsPerCustomer,
    PortsByRegion,
    PortsByType,
    CustomersByRegion,
    DatabaseHealth,
    IPNRStatus,
    IPNREndpoints,
    VQMDailySummary,
    BusinessPartnerAlarmsSummary,
    TrunkGroupSummary,

    SystemHealthSummary,
    ServiceGatewayInstancesInformation,

    EndpointDataSummary,
    BusinessPartnerVQMSummary,
    IPNRBandwidth,
    ACMSystemHealthSummary,

    SIPResponseSummary,
    AlarmOverview,
    RibbonSBCEdge,
    SystemHealthVmware,
    RibbonSystemHealthSummary,
    MSTeamsCallSummary,
    SystemHealthEngelbartSoftwareESuites,
    GenesysTrunkGroupsSummary,
    GenesysConversationSummary,
    MicrosoftTeamsRoomHealthSummary,
    AvayaAXPCloudHealthSummary
}

export enum HealthStatus {
    Undefined = -1,
    Normal,
    Warning,
    Critical
}
