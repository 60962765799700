import * as Actions from '@actions/index';
import { DashletDataProcessService, DashletService, TimeService } from '@services/index';
import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DashletLineGraphComponent } from '../dashlet-line-graph/dashlet-line-graph.component';
import { CustomerLogo, DashletSystemHealthMSTeamsCallsSummary, Point } from '@models/index';
import { select, Store } from '@ngrx/store';
import { AppState, selectEntity, selectDataFromCommonEntity } from '@reducers/index';
import { UIChart } from 'primeng/chart';
import {
    StreamData,
    DonutChartData,
    GraphData,
    TeamsSummaryDTO,
    DataCategories,
    SumGenericDataCount,
    SumFeedbackRatings,
    SumPlatforms,
    TeamsSummarybySite
} from '@models/dataObjects/interfaces/MSTeamsData.model';
import { Subscription } from 'rxjs';
import { ChartColors } from '@models/index';
import { environment } from '@environments/environment';
import { startCase } from 'lodash';

@Component({
    selector: 'dashlet-system-health-ms-teams-call-summary',
    templateUrl: 'dashlet-system-health-ms-teams-call-summary.component.html',
    styleUrls: ['dashlet-system-health-ms-teams-call-summary.component.scss']
})
export class DashletSystemHealthMSTeamsCallsSummaryComponent implements OnInit, OnDestroy, AfterContentChecked {
    @Input() dashlet: DashletSystemHealthMSTeamsCallsSummary;

    public startCase = startCase;
    public graphLabels: string[] = [];
    public chartColors: ChartColors = this.getChartColors();
    public callTypeChartData: DonutChartData;
    public networksChartData: DonutChartData;
    public modalitiesChartData: DonutChartData;
    public audioDevicesChartData: DonutChartData;
    public doughnutHeadings = ['Call Type', 'Network', 'Audio Device', 'Modalities'];
    private subscription: Subscription;
    public expandedView: boolean = false;
    public selectedCustomSiteName: string = 'All';
    public uniqueUsersList: string[] = [];
    public callRecordsCount: number = 0;
    public callDurationCount: number = 0;
    public participantCount: number = 0;
    public callType: SumGenericDataCount = {};
    public networks: SumGenericDataCount = {};
    public audioDevicesUsed: SumGenericDataCount = {};
    public moduleType: SumGenericDataCount = {};
    public feedbackRatingCount: SumFeedbackRatings = {
        Best: 0,
        Middle: 0,
        Worst: 0,
        NoRating: 0
    };
    public platformUsed: SumPlatforms = {
        Windows: 0,
        Android: 0,
        MacOs: 0,
        Ios: 0,
        Other: 0
    };

    public streamSummary = {
        All: {
            good: 0,
            satisfactory: 0,
            poor: 0
        }
    };
    private pChart: UIChart;
    get chart() {
        return this.pChart;
    }
    @ViewChild('systemChart') set chart(value: UIChart) {
        this.pChart = value;
        this.updateChart(this.selectedCustomSiteName);
    }

    private pMainGraph: DashletLineGraphComponent;
    get mainGraph() {
        return this.pMainGraph;
    }
    @ViewChild('mainGraph') set mainGraph(value: DashletLineGraphComponent) {
        this.pMainGraph = value;
        this.updateChart(this.selectedCustomSiteName);
    }

    private pSubGraph: DashletLineGraphComponent;
    get subGraph() {
        return this.pSubGraph;
    }
    @ViewChild('subGraph') set subGraph(value: DashletLineGraphComponent) {
        this.pSubGraph = value;
        this.updateChart(this.selectedCustomSiteName);
    }

    private goodStreamData: { [key: string]: StreamData } = {
        All: {
            label: 'Good Stream',
            borderColor: this.chartColors.green,
            backgroundColor: this.chartColors.green,
            pointRadius: 0,
            pointHitRadius: 20,
            networkBreakdown: [],
            data: [],
            spanGap: false,
            tension: 0.1,
            fill: true
        }
    };
    private middleStreamData: { [key: string]: StreamData } = {
        All: {
            label: 'Satisfactory Stream',
            borderColor: this.chartColors.amber,
            backgroundColor: this.chartColors.amber,
            pointRadius: 0,
            pointHitRadius: 20,
            networkBreakdown: [],
            data: [],
            spanGap: false,
            tension: 0.1,
            fill: true
        }
    };

    private poorStreamData: { [key: string]: StreamData } = {
        All: {
            label: 'Poor Stream',
            borderColor: this.chartColors.red,
            backgroundColor: this.chartColors.red,
            pointRadius: 0,
            pointHitRadius: 20,
            networkBreakdown: [],
            data: [],
            spanGap: false,
            tension: 0.1,
            fill: true
        }
    };

    public options = {
        cutout: '80%',
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        },
        layout: {
            padding: {
                left: 10,
                right: 10
            }
        }
    };

    public mainStreamQualityChartOptions: any = {
        scales: {
            x: {
                type: 'time',
                max: null,
                min: null,
                grid: {
                    display: false
                },
                time: {
                    unit: 'hour',
                    displayFormats: {
                        minute: 'HH'
                    },
                    stepSize: 3
                },
                ticks: {
                    fontSize: 10.2,
                    autoSkip: true,
                    maxRotation: 0,
                    minRotation: 0
                }
            },
            y: {
                type: 'linear',
                min: 0,
                stacked: true,
                beginAtZero: true,
                grid: {
                    display: false
                },
                ticks: {
                    autoSkip: false,
                    fontSize: 10.2,
                    precision: 0
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'bottom'
            },
            tooltip: {
                displayColors: true
            },
            filler: {
                propagate: true
            },
            title: {
                display: true,
                text: '',
                font: {
                    size: 11.2
                },
                padding: {
                    top: 5,
                    bottom: 5
                }
            }
        },

        animation: {
            duration: 0
        },
        elements: {
            line: {
                borderJoinStyle: 'round'
            }
        }
    };
    public siteStreamData: { [key: string]: any } = {};
    public smallStreamQualityGraphOptions: any = {
        scales: {
            x: {
                type: 'time',
                max: null,
                min: null,
                grid: {
                    display: false
                },
                time: {
                    unit: 'hour'
                },
                ticks: {
                    display: false,
                    autoSkip: true,
                    maxRotation: 0,
                    minRotation: 0
                }
            },
            y: {
                type: 'linear',
                min: 0,
                stacked: true,
                beginAtZero: true,
                grid: {
                    display: false
                },
                ticks: {
                    display: false,
                    autoSkip: false
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'bottom'
            },
            tooltip: {
                enabled: false
            },
            filler: {
                propagate: true
            }
        },
        animation: {
            duration: 0
        },
        elements: {
            line: {
                borderJoinStyle: 'round'
            }
        },
        interaction: {
            mode: null,
            intersect: false
        }
    };

    public chartColorsArray = [
        this.chartColors.blue,
        this.chartColors.pink,
        this.chartColors.cyan,
        this.chartColors.brownLight,
        this.chartColors.purpleDark,
        this.chartColors.blueLight,
        this.chartColors.blueDarker,
        this.chartColors.yellow,
        this.chartColors.cyanDark,
        this.chartColors.greyDarker
    ];

    private getColorsArray(labelArray: string[], keyToMakeGrey?: string): string[] {
        const colorsArray: string[] = [];

        //Loops though the chartColorsArray, in case labelArray is bigger
        for (let i = 0; i < labelArray.length; i++) {
            colorsArray.push(this.chartColorsArray[i % this.chartColorsArray.length]);
        }

        //make 'Other' or 'Uncertified' always the same color
        if (keyToMakeGrey) {
            const indexOfOther = labelArray.indexOf(keyToMakeGrey);
            if (indexOfOther !== -1) {
                colorsArray[indexOfOther] = this.chartColors.grey;
            }
        }
        return colorsArray;
    }

    constructor(
        private dashletService: DashletService,
        private cdr: ChangeDetectorRef,
        private store$: Store<AppState>,
        private dashletDataService: DashletDataProcessService,
        private timeService: TimeService
    ) {
        this.siteStreamData = {
            All: {
                datasets: [this.goodStreamData, this.middleStreamData, this.poorStreamData]
            }
        };
    }

    public ngOnInit(): void {
        this.dashlet.settingsChanges.subscribe(() => {
            this.dashlet.resetData();
            if (this.mainGraph) {
                this.mainGraph.updateChart();
            }
            this.loadDocument(
                this.dashlet.showPSTNCallsOnly
                    ? this.dashlet.commandTypeIdTeamsPSTNCallSummary
                    : this.dashlet.commandTypeIdTeamsCallSummary
            );
        });

        this.loadDocument(
            this.dashlet.showPSTNCallsOnly
                ? this.dashlet.commandTypeIdTeamsPSTNCallSummary
                : this.dashlet.commandTypeIdTeamsCallSummary
        );
    }

    private loadDocument(documentId: string): void {
        this.mainStreamQualityChartOptions.plugins.title.text = this.dashlet.equipment.equipmentName;

        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.subscription = new Subscription();

        this.expandedView = this.dashlet.getSize().id === 1;
        this.subscription.add(
            this.dashlet.sizeChange.subscribe(sizeId => {
                this.expandedView = sizeId === 1;
            })
        );

        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));

        // clear the store for any previous data
        this.store$.dispatch(
            Actions.GetCommonEntitysSuccess({
                data: {
                    data: [],
                    uniqueId: (this.dashlet.equipment.equipmentId + documentId).toLowerCase()
                }
            })
        );

        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );

        this.subscription.add(
            this.dashlet.chartDataUpdated.subscribe(teamSummaryBySiteData => {
                this.updateDataVars(teamSummaryBySiteData[this.selectedCustomSiteName]);
                this.updateCharts(teamSummaryBySiteData);

                this.cdr.detectChanges();
            })
        );

        if (!this.dashlet.equipment) return;
        this.mainStreamQualityChartOptions.scales.x.time.stepSize = this.getStepSize(this.dashlet.hourSetting);
        this.mainStreamQualityChartOptions.scales.x.max = null;
        this.goodStreamData['All'].pointHitRadius = 20;
        this.middleStreamData['All'].pointHitRadius = 20;
        this.poorStreamData['All'].pointHitRadius = 20;

        let startingDate = new Date();
        let dateMinutes = new Date().getMinutes();
        startingDate = new Date(
            new Date(
                new Date(
                    new Date(startingDate.setHours(startingDate.getHours() - this.dashlet.hourSetting)).setMinutes(0)
                ).setSeconds(0)
            ).setMilliseconds(0)
        );

        this.dashlet.pointArray = this.dashlet.GetEmptyTimeWindowArray(startingDate, dateMinutes);
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: documentId
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: documentId
            })
        );
        this.store$.dispatch(
            Actions.GetCommonHistoric({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: documentId,
                from: startingDate,
                to: new Date(),
                max: this.dashlet.hourSetting
            })
        );

        this.subscription.add(
            this.store$
                .pipe(select(selectDataFromCommonEntity(this.dashlet.equipment.equipmentId + documentId)))
                .subscribe(data => {
                    if (data && data.length) {
                        const tempData = JSON.parse(JSON.stringify(data));
                        tempData.reverse().forEach(element => {
                            if (element && element.data) {
                                this.dashlet.lastUpdatedDataAt = new Date(
                                    this.timeService.convertToUserTimeZoneForLastUpdated(element.timestamp)
                                );
                                this.dashlet.processMSTeamsCallData(
                                    element.data[element.data.length - 1].SummaryStats,
                                    new Date(element.timestamp)
                                );
                            }
                        });
                    }
                })
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public ngAfterContentChecked(): void {
        this.cdr.detectChanges();
    }

    private updateDataVars(siteTeamsSummary: TeamsSummaryDTO[]): void {
        this.getCallRecords(siteTeamsSummary);
        this.getTotalDuration(siteTeamsSummary);
        this.getParticipants(siteTeamsSummary);
        this.getCallType(siteTeamsSummary);
        this.getModalities(siteTeamsSummary);
        this.getFeedbackRatings(siteTeamsSummary);
        this.getNetworksUsed(siteTeamsSummary);
        this.getPlatformsUsed(siteTeamsSummary);
        this.getAudioDevicesUsed(siteTeamsSummary);
        this.getUniqueUsersList(siteTeamsSummary);
    }

    private updateCharts(teamsSummarybySite: TeamsSummarybySite) {
        Object.keys(teamsSummarybySite).forEach(siteName => this.updateChart(siteName));
        this.graphLabels = this.dashlet.customSiteNames.map(subnets => subnets.label);
    }

    private updateChart(siteName: string) {
        if (this.chart) {
            this.chart.reinit();

            this.callTypeChartData = this.updateCallType();
            this.audioDevicesChartData = this.updateAudioDevices();
            this.networksChartData = this.updateNetworks();
            this.modalitiesChartData = this.updateModalities();

            this.chart.refresh();
        }

        if (this.mainGraph) {
            this.createGraphData(siteName);
            this.goodStreamData.data = this.dashletDataService.dedupe(this.goodStreamData[siteName].data);
            this.middleStreamData.data = this.dashletDataService.dedupe(this.middleStreamData[siteName].data);
            this.poorStreamData.data = this.dashletDataService.dedupe(this.poorStreamData[siteName].data);

            this.siteStreamData[siteName] = {
                datasets: [
                    this.goodStreamData[siteName],
                    this.middleStreamData[siteName],
                    this.poorStreamData[siteName]
                ]
            };

            this.mainGraph.chart.reinit();
            this.mainGraph.updateChart();
        }
    }

    public getSeverityColor(severity: number): string {
        return this.dashletService.getSeverityColor(severity);
    }

    private getCallRecords(siteTeamsSummary: TeamsSummaryDTO[]): void {
        if (!siteTeamsSummary?.length) return;
        this.callRecordsCount = 0;
        siteTeamsSummary.forEach((document: TeamsSummaryDTO) => {
            this.callRecordsCount += document.CallRecorded;
        });
    }

    private getTotalDuration(siteTeamsSummary: TeamsSummaryDTO[]): void {
        if (!siteTeamsSummary?.length) return;
        this.callDurationCount = 0;
        siteTeamsSummary.forEach((document: TeamsSummaryDTO) => {
            this.callDurationCount += document.CallsDuration;
        });
    }

    private getParticipants(siteTeamsSummary: TeamsSummaryDTO[]): void {
        if (!siteTeamsSummary?.length) return;
        this.participantCount = 0;
        siteTeamsSummary.forEach((document: TeamsSummaryDTO) => {
            this.participantCount += document.Participants;
        });
    }

    private getAccumulatedDataValues(
        categoryKey: DataCategories,
        siteTeamsSummary: TeamsSummaryDTO[]
    ): SumGenericDataCount {
        const arrayOfAllValues = siteTeamsSummary.map(record => record[categoryKey]);

        let accumulatedValues: SumGenericDataCount = arrayOfAllValues.reduce((accumulator, current) => {
            Object.keys(current).forEach(key => {
                if (!current[key]) return;
                accumulator[key] = (accumulator[key] || 0) + current[key];
            });
            return accumulator;
        }, {});

        accumulatedValues = this.sortObjectByValuesDescending(accumulatedValues);
        return accumulatedValues;
    }

    private getCallType(siteTeamsSummary: TeamsSummaryDTO[]): void {
        let callTypeAccumulated = this.getAccumulatedDataValues('CallType', siteTeamsSummary);
        if (this.dashlet.showPSTNCallsOnly) {
            callTypeAccumulated = {
                ...callTypeAccumulated,
                ...this.getAccumulatedDataValues('PstnAndDirectRouteCallTypes', siteTeamsSummary)
            };
        }
        this.callType = callTypeAccumulated;
    }

    private getNetworksUsed(siteTeamsSummary: TeamsSummaryDTO[]): void {
        const networksAccumulated = this.getAccumulatedDataValues('Networks', siteTeamsSummary);
        this.networks = this.sortKeyToBottom(networksAccumulated, 'Other');
    }

    private getAudioDevicesUsed(siteTeamsSummary: TeamsSummaryDTO[]): void {
        const audioDevicesAccumulated = this.getAccumulatedDataValues('AudioDevices', siteTeamsSummary);
        this.audioDevicesUsed = this.sortKeyToBottom(this.convertToCorrectKeys(audioDevicesAccumulated), 'Uncertified');
    }

    private getModalities(siteTeamsSummary: TeamsSummaryDTO[]): void {
        const modalitiesAccumulated = this.getAccumulatedDataValues('ModuleType', siteTeamsSummary);
        this.moduleType = modalitiesAccumulated;
    }

    private getPlatformsUsed(siteTeamsSummary: TeamsSummaryDTO[]): void {
        this.platformUsed.Windows = 0;
        this.platformUsed.Android = 0;
        this.platformUsed.MacOs = 0;
        this.platformUsed.Ios = 0;
        this.platformUsed.Other = 0;

        siteTeamsSummary.forEach((document: TeamsSummaryDTO) => {
            this.platformUsed = {
                Windows: this.platformUsed.Windows + document.Platforms.Windows,
                Android: this.platformUsed.Android + document.Platforms.Android,
                MacOs: this.platformUsed.MacOs + document.Platforms.MacOs,
                Ios: this.platformUsed.Ios + document.Platforms.Ios,
                Other: this.platformUsed.Other + document.Platforms.Other
            };
        });
    }

    private getFeedbackRatings(siteTeamsSummary: TeamsSummaryDTO[]): void {
        this.feedbackRatingCount.Best = 0;
        this.feedbackRatingCount.Middle = 0;
        this.feedbackRatingCount.Worst = 0;
        this.feedbackRatingCount.NoRating = 0;

        siteTeamsSummary.forEach((document: TeamsSummaryDTO) => {
            this.feedbackRatingCount = {
                Best: this.feedbackRatingCount.Best + document.FeedbackRatings.Best,
                Middle: this.feedbackRatingCount.Middle + document.FeedbackRatings.Middle,
                NoRating: this.feedbackRatingCount.NoRating + document.FeedbackRatings.NoRating,
                Worst: this.feedbackRatingCount.Worst + document.FeedbackRatings.Worst
            };
        });
    }

    private updateCallType(): DonutChartData {
        const labels = Object.keys(this.callType).map(key => {
            if (key === 'PeerToPeer' || key === 'GroupCall') {
                return startCase(key);
            }
            return key;
        });
        return {
            labels,
            datasets: [
                {
                    data: Object.values(this.callType),
                    backgroundColor: this.getColorsArray(labels)
                }
            ]
        };
    }

    private updateNetworks(): DonutChartData {
        const labels = Object.keys(this.networks).map(key => startCase(key));
        return {
            labels,
            datasets: [
                {
                    data: Object.values(this.networks),
                    backgroundColor: this.getColorsArray(labels, 'Other')
                }
            ]
        };
    }

    private updateAudioDevices(): DonutChartData {
        const labels = Object.keys(this.audioDevicesUsed).map(key => startCase(key));
        return {
            labels,
            datasets: [
                {
                    data: Object.values(this.audioDevicesUsed),
                    backgroundColor: this.getColorsArray(labels, 'Uncertified')
                }
            ]
        };
    }

    private updateModalities(): DonutChartData {
        const labels = Object.keys(this.moduleType).map(key => startCase(key));
        return {
            labels,
            datasets: [
                {
                    data: Object.values(this.moduleType),
                    backgroundColor: this.getColorsArray(labels)
                }
            ]
        };
    }

    private createGraphData(siteName: string) {
        this.resetStreamsForSite(siteName);

        //If there's only one data point, surround it with dummy data to make it appear correctly.
        if (this.dashlet.hourSetting === 1 && this.dashlet.msTeamsCallQualityGraphData[siteName].length === 1) {
            this.createOneHourDummyGraphData(this.dashlet.msTeamsCallQualityGraphData[siteName][0], siteName);
        }

        if (this.dashlet.msTeamsCallQualityGraphData?.[siteName].length)
            this.dashlet.msTeamsCallQualityGraphData[siteName].forEach(document => {
                this.assignGraphStreams(
                    document.Good.data.streams,
                    document.Satisfactory.data.streams,
                    document.Poor.data.streams,
                    document.Good.data.timeStamp,
                    siteName
                );
                this.streamSummary[siteName].good += Number(document.Good.data.streams);
                this.streamSummary[siteName].satisfactory += Number(document.Satisfactory.data.streams);
                this.streamSummary[siteName].poor += Number(document.Poor.data.streams);
            });
    }

    private assignGraphStreams(good: number, satisfactory: number, poor: number, timeStamp: Date, siteName: string) {
        let point: Point = {
            x: timeStamp,
            y: good
        };
        this.goodStreamData[siteName].data.push(point);
        this.goodStreamData[siteName].data.sort((a: Point, b: Point) =>
            a.x.getTime() < b.x.getTime() ? 1 : b.x.getTime() < a.x.getTime() ? -1 : 0
        );

        point = {
            x: timeStamp,
            y: satisfactory
        };
        this.middleStreamData[siteName].data.push(point);
        this.middleStreamData[siteName].data.sort((a: Point, b: Point) =>
            a.x.getTime() < b.x.getTime() ? 1 : b.x.getTime() < a.x.getTime() ? -1 : 0
        );

        point = {
            x: timeStamp,
            y: poor
        };
        this.poorStreamData[siteName].data.push(point);
        this.poorStreamData[siteName].data.sort((a: Point, b: Point) =>
            a.x.getTime() < b.x.getTime() ? 1 : b.x.getTime() < a.x.getTime() ? -1 : 0
        );
    }

    private createOneHourDummyGraphData(document: GraphData, siteName: string) {
        this.mainStreamQualityChartOptions.scales.x.time.stepSize = 1;
        this.mainStreamQualityChartOptions.scales.x.max = new Date(
            new Date(this.timeService.convertToUserTimeZoneForLastUpdated(document.Good.data.timeStamp)).getTime() +
                60 * 60 * 1000
        );

        this.smallStreamQualityGraphOptions.scales.x.time.stepSize = 1;
        this.smallStreamQualityGraphOptions.scales.x.max = new Date(
            new Date(this.timeService.convertToUserTimeZoneForLastUpdated(document.Good.data.timeStamp)).getTime() +
                60 * 60 * 1000
        );

        this.assignGraphStreams(
            document.Good.data.streams,
            document.Satisfactory.data.streams,
            document.Poor.data.streams,
            new Date(document.Good.data.timeStamp.getTime() - 60 * 60 * 1000),
            siteName
        );

        //Makes it so the dummy data doesn't have a visible tooltip
        this.goodStreamData[siteName].pointHitRadius = [0, 20];
        this.middleStreamData[siteName].pointHitRadius = [0, 20];
        this.poorStreamData[siteName].pointHitRadius = [0, 20];
    }

    public changeSite(siteName: string) {
        if (!this.siteStreamData[siteName]) return;
        if (this.selectedCustomSiteName === siteName) {
            this.selectedCustomSiteName = 'All';
            this.mainStreamQualityChartOptions.plugins.title.text = this.dashlet.equipment.equipmentName;
        } else {
            this.selectedCustomSiteName = siteName;
            this.mainStreamQualityChartOptions.plugins.title.text =
                siteName === 'All' ? this.dashlet.equipment.equipmentName : startCase(siteName);
        }

        this.dashlet.refreshChartData();
        const index = this.graphLabels.indexOf(siteName);
        if (index !== -1) {
            this.graphLabels[index] = 'All';
        }
        this.subGraph.updateChart();
    }

    public handleKeyDown(event: KeyboardEvent, label: string) {
        if (event.key === 'Enter') {
            this.changeSite(label);
        }
    }

    private resetStreamsForSite(siteName: string) {
        this.streamSummary[siteName] = {
            good: 0,
            satisfactory: 0,
            poor: 0
        };
        this.goodStreamData[siteName] = {
            label: 'Good Stream',
            borderColor: this.chartColors.green,
            backgroundColor: this.chartColors.green,
            pointRadius: 0,
            pointHitRadius: 20,
            networkBreakdown: [],
            data: [],
            spanGap: false,
            tension: 0.1,
            fill: true
        };

        this.middleStreamData[siteName] = {
            label: 'Satisfactory Stream',
            borderColor: this.chartColors.amber,
            backgroundColor: this.chartColors.amber,
            pointRadius: 0,
            pointHitRadius: 20,
            networkBreakdown: [],
            data: [],
            spanGap: false,
            tension: 0.1,
            fill: true
        };

        this.poorStreamData[siteName] = {
            label: 'Poor Stream',
            borderColor: this.chartColors.red,
            backgroundColor: this.chartColors.red,
            pointRadius: 0,
            pointHitRadius: 20,
            networkBreakdown: [],
            data: [],
            spanGap: false,
            tension: 0.1,
            fill: true
        };
    }

    public getStyleForEntry(color: string): object {
        return { 'border-left-color': color };
    }

    public getChartColors(): ChartColors {
        return this.dashletService.getChartColors();
    }

    public openDeviceUsageReport(): void {
        // eslint-disable-next-line no-undef
        window
            .open(
                `${environment.reportingUrl}viewer?entityId=${this.dashlet.customer.customerId}&locationId=${this.dashlet.location.locationId}&equipmentId=${this.dashlet.equipment.equipmentId}&reportId=218096fd-ddcc-41b4-b232-586ad07e67dd&reportName=Microsoft%20Teams%20Device%20Usage`,
                '_blank'
            )
            .focus();
    }

    public openUserActivityReport(): void {
        // eslint-disable-next-line no-undef
        window
            .open(
                `${environment.reportingUrl}viewer?entityId=${this.dashlet.customer.customerId}&locationId=${this.dashlet.location.locationId}&equipmentId=${this.dashlet.equipment.equipmentId}&reportId=2890a39b-7579-48f7-83b0-3804a3ea462f&reportName=Microsoft%20Teams%20User%20Activity`,
                '_blank'
            )
            .focus();
    }

    public openMOSDistributionReport(): void {
        // eslint-disable-next-line no-undef
        window
            .open(
                `${environment.reportingUrl}viewer?entityId=${this.dashlet.customer.customerId}&locationId=${this.dashlet.location.locationId}&equipmentId=${this.dashlet.equipment.equipmentId}&reportId=8e2b20e5-7ba9-42da-b40b-cf3bbe2da8db&reportName=Mean%20Opinion%20Score%20Distributions`,
                '_blank'
            )
            .focus();
    }

    public openUserFeedbackSummaryReport(): void {
        // eslint-disable-next-line no-undef
        window
            .open(
                `${environment.reportingUrl}viewer?entityId=${this.dashlet.customer.customerId}&locationId=${this.dashlet.location.locationId}&equipmentId=${this.dashlet.equipment.equipmentId}&reportId=e2e46996-6edd-4fe8-8a22-9963cd1f1b3e&reportName=Session%20Quality%20User%20Feedback%20Summary`,
                '_blank'
            )
            .focus();
    }

    public OpenMsTeamsCallRecords(): void {
        const endDate = new Date(this.dashlet.lastUpdatedDataAt);
        endDate.setMinutes(0, 0, 0);

        const startDate: Date = new Date(endDate);
        startDate.setHours(startDate.getHours() - +this.dashlet.summaryPeriod.value);

        // eslint-disable-next-line no-undef
        window
            .open(
                `${environment.webPortalUrl}Availability/TeamsCallRecords?customerId=${
                    this.dashlet.customer.customerId
                }&equipmentId=${
                    this.dashlet.equipment.equipmentId
                }&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
                '_blank'
            )
            .focus();
    }

    private sortObjectByValuesDescending(objectToSort: SumGenericDataCount): SumGenericDataCount {
        let entries = Object.entries(objectToSort);
        entries.sort((a, b) => b[1] - a[1]);
        return Object.fromEntries(entries);
    }

    private sortKeyToBottom(objectToSort: SumGenericDataCount, keyToSortToBottom: string): SumGenericDataCount {
        const entryToSortToBottom = Object.entries(objectToSort).find(entry => entry[0] === keyToSortToBottom);
        const entriesWithoutBottomKey = Object.entries(objectToSort).filter(entry => entry[0] !== keyToSortToBottom);
        entryToSortToBottom && entriesWithoutBottomKey.push(entryToSortToBottom);
        return Object.fromEntries(entriesWithoutBottomKey);
    }

    private convertToCorrectKeys(categoryData: SumGenericDataCount): SumGenericDataCount {
        const fixedCategoryData = {};

        for (let key in categoryData) {
            if (key === 'BangOlufsen') {
                fixedCategoryData['BangAndOlufsen'] = categoryData[key];
            } else {
                fixedCategoryData[key] = categoryData[key];
            }
        }
        return fixedCategoryData;
    }

    private getUniqueUsersList(siteTeamsSummary: TeamsSummaryDTO[]) {
        this.uniqueUsersList = siteTeamsSummary.reduce((accumulator, currentValue) => {
            if (!currentValue?.UniqueParticipantsList) return [];
            const combinedArray = [...accumulator, ...currentValue.UniqueParticipantsList];
            return Array.from(new Set(combinedArray));
        }, []);
    }

    private getStepSize(summaryPeriod: number): number {
        switch (summaryPeriod) {
            case 24:
                return 3;
            case 12:
                return 2;
            case 6:
                return 1;
            default:
                return 3;
        }
    }

    public displayPiChart(chartData: DonutChartData): boolean {
        return chartData?.labels.length > 0;
    }
}
