import { Customer, Dashlet, Location } from '@models/index';
import {
    AlarmByEquipment,
    AlarmByName,
    AlarmBySeverity,
    AlarmEquipmentDocument,
    AlarmModel,
    AlarmTimeDocument
} from '../dataObjects/interfaces/AlarmOverview.model';
import { Subject } from 'rxjs';

export class DashletAlarmOverview extends Dashlet {
    public ALARM_SUMMARY_BY_EQUIPMENT_COMMAND_TYPE_ID = '97BD0527-A111-40F1-AA04-EB6759EFCEE7';
    public ALARM_SUMMARY_BY_SEVERITY_COMMAND_TYPE_ID = '62568F87-2184-4F4B-B938-147BEEDE6529';

    public customer!: Customer;
    public location!: Location;
    public lastUpdated: Date;

    public settingsChanged$: Subject<boolean> = new Subject<boolean>();

    private processedAlarmDocumentsByTimestamp: Date[];
    private processedSeverityDocumentsByTimestamp: Date[];
    private processedAlarmData: AlarmModel[];
    private severityData: AlarmBySeverity[];
    public alarmDataTimedOut: boolean = true;
    public alarmSeverityDataTimedOut: boolean = true;

    constructor() {
        super();
        this.sizes = [
            {
                id: 1,
                label: 'Small',
                cols: 9,
                rows: 6
            },
            {
                id: 0,
                label: 'Large',
                cols: 9,
                rows: 13
            }
        ];

        this.applySize(0);
        this.lastUpdated = new Date();
        this.processedAlarmDocumentsByTimestamp = [];
        this.processedAlarmData = [];
        this.processedSeverityDocumentsByTimestamp = [];
        this.severityData = [];

        // Bind helper functions
        this.processAlarmDocument = this.processAlarmDocument.bind(this);
        this.processTimeDocument = this.processTimeDocument.bind(this);
        this.sortEquipmentByTimestampAsc = this.sortEquipmentByTimestampAsc.bind(this);
        this.sortEquipmentByTimestampDesc = this.sortEquipmentByTimestampDesc.bind(this);
    }

    public applySettings(v: { [key: string]: any }): void {
        super.applySettings(v);
        this.configured = v.customer ? true : false;
        if (this.configured) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        this.updateSize();
        if (this.configured) {
            this.customNameTag = v.nameTag;
            this.refreshNameTag();
            this.settingsChanged$.next(true);
        }
    }

    public refreshNameTag(): void {
        if (this.lastUpdated === undefined) {
            return;
        }
        const originalDate = new Date(this.lastUpdated),
            originalDateNoMinutes = new Date(this.lastUpdated);
        originalDateNoMinutes.setMinutes(0, 0, 0);

        const _month = originalDate.toLocaleDateString(undefined, { month: 'short' });
        const _date = originalDate.toLocaleDateString(undefined, { day: 'numeric' });

        const firstDate: Date = new Date(originalDateNoMinutes);
        firstDate.setHours(firstDate.getHours() - 24);

        if (this.configured) {
            const _hour = originalDate
                .toLocaleTimeString(undefined, {
                    hour: 'numeric',
                    minute: 'numeric',
                    timeZoneName: 'short'
                })
                .toUpperCase();
            this.generatedNameTag = `${this.customer.customerName} | Last updated at ${_date}-${_month} ${_hour}`;
        } else {
            this.generatedNameTag = 'Unconfigured';
        }
    }

    public applySize(id: number): void {
        super.applySize(id);
        this.updateSize();
        this.refreshNameTag();
    }

    private updateSize(): void {
        const h = 0;
        const w = 0;
        this.applySizeExpansion(w, h);
    }

    public resetData(): void {
        this.processedAlarmDocumentsByTimestamp = [];
        this.processedAlarmData = [];
        this.processedSeverityDocumentsByTimestamp = [];
        this.severityData = [];
    }

    public dispose(): void {}

    public sumariseSeverityData(documents: AlarmTimeDocument[]): AlarmBySeverity[] {
        if (this.alarmSeverityDataTimedOut) {
            return [];
        }
        const newData = documents
            .map(this.processTimeDocument)
            .filter(d => d.length > 0)
            .flatMap(d => d);
        if (newData.length === 0) return [];

        this.severityData = [...newData, ...this.severityData];

        const minRealtimeDocumentTimestamp = this.getMinimumTimestampForRealtimeDocuments();
        const relevantData = this.severityData.filter(e => e.Timestamp >= minRealtimeDocumentTimestamp);

        return relevantData;
    }

    public processTimeDocument(document: AlarmTimeDocument): AlarmBySeverity[] {
        const timestamp = new Date(document.timestamp);

        if (this.processedSeverityDocumentsByTimestamp.includes(timestamp)) {
            return [];
        }

        const processedData = document.data.map(d => {
            const severity: AlarmBySeverity = {
                Severity: parseInt(d.Severity),
                TotalActive: parseInt(d.UnresolvedAlarms),
                Timestamp: timestamp
            };

            return severity;
        });

        this.processedSeverityDocumentsByTimestamp.push(timestamp);
        return processedData;
    }

    public summariseAlarmsData(documents: AlarmEquipmentDocument[]): AlarmModel[] {
        if (this.alarmDataTimedOut) {
            return [];
        }
        const newData = documents
            .map(this.processAlarmDocument)
            .filter(d => d.length > 0)
            .flatMap(d => d);
        if (newData.length === 0) return [];

        this.processedAlarmData = [...newData, ...this.processedAlarmData];

        const minRealtimeDocumentTimestamp = this.getMinimumTimestampForRealtimeDocuments();
        const relevantEquipment = this.processedAlarmData.filter(e => e.Timestamp >= minRealtimeDocumentTimestamp);

        const equipmentAlarmMap: Map<string, AlarmModel[]> = this.groupEquipmentAndAlarm(relevantEquipment);

        const summaryData: AlarmModel[] = [];

        for (const value of equipmentAlarmMap.values()) {
            summaryData.push(this.findResolvedAlarms(value));
        }

        return summaryData;
    }

    public processAlarmDocument(document: AlarmEquipmentDocument): AlarmModel[] {
        const timestamp = new Date(document.timestamp);

        if (this.processedAlarmDocumentsByTimestamp.includes(timestamp)) {
            return [];
        }

        const processedData = document.data.map(d => {
            const equipment: AlarmModel = {
                EquipmentId: d.EquipmentId,
                EquipmentName: d.EquipmentName,
                Timestamp: timestamp,

                Alarm: {
                    AlarmName: d.AlarmName,
                    AlarmDescription: d.AlarmDescription,
                    Severity: parseInt(d.AlarmSeverity),
                    TotalAlarms: parseInt(d.TotalAlarms),

                    UnresolvedAlarms: d.AlarmIds.split(','),
                    ResolvedAlarms: []
                }
            };

            return equipment;
        });

        this.processedAlarmDocumentsByTimestamp.push(timestamp);
        return processedData;
    }

    private getMinimumTimestampForRealtimeDocuments(): Date {
        let minDate = new Date(0);
        let maxDate = new Date(0);

        for (const timestamp of this.processedAlarmDocumentsByTimestamp) {
            minDate = new Date(Math.min(minDate.getTime(), timestamp.getTime()));
            maxDate = new Date(Math.max(maxDate.getTime(), timestamp.getTime()));
        }

        const minRealtimeDocTimestamp =
            maxDate.getTime() - minDate.getTime() > 24 * 60 * 60 * 1000
                ? new Date(maxDate.getTime() - 24 * 60 * 60 * 1000)
                : minDate;
        return minRealtimeDocTimestamp;
    }

    // Group alarms based on their equipment ID and Alarm name
    private groupEquipmentAndAlarm(equipments: AlarmModel[]): Map<string, AlarmModel[]> {
        const equipmentAlarmMap = new Map<string, AlarmModel[]>();

        for (const equipment of equipments) {
            const id = `${equipment.EquipmentId}:${equipment.Alarm.AlarmName}`;

            if (equipmentAlarmMap.has(id)) {
                equipmentAlarmMap.get(id).push(equipment);
            } else {
                equipmentAlarmMap.set(id, [equipment]);
            }
        }

        return equipmentAlarmMap;
    }

    // > 0 will sort a before b, e.g. [b,a]
    // < 0 will sort b before a, e.g. [a,b]
    // === 0 will keep the original ordering
    private sortEquipmentByTimestampAsc(a: AlarmModel, b: AlarmModel): number {
        if (a.Timestamp > b.Timestamp) {
            return 1;
        } else if (a.Timestamp < b.Timestamp) {
            return -1;
        } else {
            return 0;
        }
    }

    private sortEquipmentByTimestampDesc(a: AlarmModel, b: AlarmModel): number {
        return this.sortEquipmentByTimestampAsc(a, b) * -1;
    }

    private findResolvedAlarms(equipmentAlarm: AlarmModel[]): AlarmModel {
        // Oldest document will have a list of all unresolved alarms
        // Assumption: each document is a list of unresolved alarms.
        const summarisedData = equipmentAlarm.sort(this.sortEquipmentByTimestampDesc).reduce((accumulator, current) => {
            const resolvedAlarms = current.Alarm.UnresolvedAlarms.filter(
                a => !accumulator.Alarm.UnresolvedAlarms.includes(a)
            );
            const unresolvedAlarms = current.Alarm.UnresolvedAlarms.filter(a =>
                accumulator.Alarm.UnresolvedAlarms.includes(a)
            );

            accumulator.Alarm.ResolvedAlarms = [...new Set([...resolvedAlarms, ...accumulator.Alarm.ResolvedAlarms])];
            accumulator.Alarm.UnresolvedAlarms = [
                ...new Set([...unresolvedAlarms, ...accumulator.Alarm.UnresolvedAlarms])
            ];

            return accumulator;
        });

        summarisedData.Alarm.TotalAlarms =
            summarisedData.Alarm.ResolvedAlarms.length + summarisedData.Alarm.UnresolvedAlarms.length;

        return summarisedData;
    }

    public groupSummaryDataByEquipment(summaryData: AlarmModel[]): AlarmByEquipment[] {
        const equipmentAlarms = summaryData.reduce((accumulator: AlarmByEquipment[], current: AlarmModel) => {
            const found = accumulator.find(a => a.EquipmentId === current.EquipmentId);

            const activeAlarms = current.Alarm.UnresolvedAlarms.length;
            const resolvedAlarms = current.Alarm.ResolvedAlarms.length;

            const highActive = [1, 2].includes(current.Alarm.Severity) ? activeAlarms : 0;
            const mediumActive = [3, 4].includes(current.Alarm.Severity) ? activeAlarms : 0;
            const lowActive = current.Alarm.Severity > 4 ? activeAlarms : 0;

            const highResolved = [1, 2].includes(current.Alarm.Severity) ? resolvedAlarms : 0;
            const mediumResolved = [3, 4].includes(current.Alarm.Severity) ? resolvedAlarms : 0;
            const lowResolved = current.Alarm.Severity > 4 ? resolvedAlarms : 0;

            if (!found) {
                accumulator.push({
                    HighActive: highActive,
                    MediumActive: mediumActive,
                    LowActive: lowActive,
                    HighResolved: highResolved,
                    MediumResolved: mediumResolved,
                    LowResolved: lowResolved,
                    TotalAlarms: activeAlarms + resolvedAlarms,
                    AlarmNames: [current.Alarm.AlarmName],
                    EquipmentName: current.EquipmentName,
                    EquipmentId: current.EquipmentId,
                    AlarmIds: current.Alarm.UnresolvedAlarms
                });

                return accumulator;
            }

            found.HighActive += highActive;
            found.MediumActive += mediumActive;
            found.LowActive += lowActive;
            found.HighResolved += highResolved;
            found.MediumResolved += mediumResolved;
            found.LowResolved += lowResolved;
            found.TotalAlarms += activeAlarms + resolvedAlarms;
            found.AlarmNames.push(current.Alarm.AlarmName);
            found.AlarmIds = [...found.AlarmIds, ...current.Alarm.UnresolvedAlarms];

            return accumulator;
        }, []);

        return equipmentAlarms;
    }

    public groupSummaryDataByName(summaryData: AlarmModel[]): AlarmByName[] {
        const nameAlarms = summaryData.reduce((accumulator: AlarmByName[], current: AlarmModel) => {
            const found = accumulator.find(a => a.AlarmName === current.Alarm.AlarmName);

            const resolvedAlarms = current.Alarm.ResolvedAlarms;
            const unresolvedAlarms = current.Alarm.UnresolvedAlarms;

            if (!found) {
                accumulator.push({
                    Resolved: resolvedAlarms.length,
                    Unresolved: unresolvedAlarms.length,
                    TotalAlarms: resolvedAlarms.length + unresolvedAlarms.length,
                    EquipmentList: [current.EquipmentName],
                    AlarmIds: [...resolvedAlarms, ...unresolvedAlarms],
                    AlarmResponseId: '',
                    AlarmName: current.Alarm.AlarmName,
                    Severity: current.Alarm.Severity
                });

                return accumulator;
            }

            found.Resolved += resolvedAlarms.length;
            found.Unresolved += unresolvedAlarms.length;
            found.TotalAlarms += resolvedAlarms.length + unresolvedAlarms.length;
            found.EquipmentList.push(current.EquipmentName);
            found.AlarmIds.push(...resolvedAlarms, ...unresolvedAlarms);

            return accumulator;
        }, []);

        return nameAlarms;
    }
}
