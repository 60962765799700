import { Regex, Sort } from '../shared/SharedMethods';

interface Point {
    x: Date;
    y: number;
}

export class SIPBandwidth {
    private _audioUsageHistory: Point[];
    private _videoUsageHistory: Point[];
    private _totalUsageHistory: Point[];

    constructor() {
        this._audioUsageHistory = [];
        this._videoUsageHistory = [];
        this._totalUsageHistory = [];
    }

    /**
     *
     */
    private updateAudioUsageHistory(date: Date, audioUsage: string) {
        if (this.audioUsageHistory.length > 100) {
            this._audioUsageHistory.shift();
        }
        if (Regex.stringIsNumber(audioUsage)) {
            const index = this._audioUsageHistory.findIndex(e => e.x.getTime() == date.getTime());
            if (index == -1) {
                this._audioUsageHistory.push({
                    x: date,
                    y: +audioUsage
                });
            }
        }
        Sort.sortDates(this._audioUsageHistory);
    }

    /**
     *
     */
    private updateVideoUsageHistory(date: Date, videoUsage: string) {
        if (this.videoUsageHistory.length > 100) {
            this._videoUsageHistory.shift();
        }
        if (Regex.stringIsNumber(videoUsage)) {
            const index = this._videoUsageHistory.findIndex(e => e.x.getTime() == date.getTime());
            if (index == -1) {
                this._videoUsageHistory.push({
                    x: date,
                    y: +videoUsage
                });
            }
        }
        Sort.sortDates(this._videoUsageHistory);
    }

    /**
     *
     */
    private updateTotalUsageHistory(date: Date, totalUsage: string) {
        if (this.totalUsageHistory.length > 100) {
            this._totalUsageHistory.shift();
        }
        if (Regex.stringIsNumber(totalUsage)) {
            const index = this._totalUsageHistory.findIndex(e => e.x.getTime() == date.getTime());
            if (index == -1) {
                this._totalUsageHistory.push({
                    x: date,
                    y: +totalUsage
                });
            }
        }
        Sort.sortDates(this._totalUsageHistory);
    }

    public updateUsageHistory(date: Date, audioUsage: string, videoUsage: string, totalUsage: string) {
        this.updateAudioUsageHistory(date, audioUsage);
        this.updateVideoUsageHistory(date, videoUsage);
        this.updateTotalUsageHistory(date, totalUsage);
    }

    public getCurrentAudioUsage() {
        if (this._audioUsageHistory.length > 0) {
            return this._audioUsageHistory[this.audioUsageHistory.length - 1].y.toString();
        } else {
            return '---';
        }
    }

    public getCurrentVideoUsage() {
        if (this._videoUsageHistory.length > 0) {
            return this._videoUsageHistory[this.videoUsageHistory.length - 1].y.toString();
        } else {
            return '---';
        }
    }

    public getCurrentTotalUsage() {
        if (this._totalUsageHistory.length > 0) {
            return this._totalUsageHistory[this.totalUsageHistory.length - 1].y.toString();
        } else {
            return '---';
        }
    }

    public refreshUsageHistory() {
        this.updateUsageHistory(
            new Date(),
            this.getCurrentAudioUsage(),
            this.getCurrentVideoUsage(),
            this.getCurrentTotalUsage()
        );
    }

    /**
     * Getter audioUsageHistory
     * @return {Point[]}
     */
    public get audioUsageHistory(): Point[] {
        return this._audioUsageHistory;
    }

    /**
     * Getter videoUsageHistory
     * @return {Point[]}
     */
    public get videoUsageHistory(): Point[] {
        return this._videoUsageHistory;
    }

    /**
     * Getter totalUsageHistory
     * @return {Point[]}
     */
    public get totalUsageHistory(): Point[] {
        return this._totalUsageHistory;
    }
}
