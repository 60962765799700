import { createAction, props } from '@ngrx/store';
import { RealTimeNotify } from '@models/index';

export const GetNotifyProcessWFMStatusRealTime = createAction(
  '[ProcessWFMStatusRealTime] Get Notify ProcessWFMStatusRealTime',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetNotifyProcessWFMStatusRealTimeSuccess = createAction(
  '[ProcessWFMStatusRealTime] Get Notify ProcessWFMStatusRealTime Success',
  props<{ data: RealTimeNotify }>()
);

export const GetNotifyProcessWFMStatusRealTimeFailure = createAction(
  '[ProcessWFMStatusRealTime] Get Notify ProcessWFMStatusRealTime Failure',
  props<{ error: any }>()
);

export const GetProcessWFMStatusRealTime = createAction(
  '[ProcessWFMStatusRealTime] Get ProcessWFMStatusRealTime',
  props<{ equipmentId: string, commandTypeId: string }>()
);

export const GetProcessWFMStatusRealTimeSuccess = createAction(
  '[ProcessWFMStatusRealTime] Get ProcessWFMStatusRealTime Success',
  props<{ data: any}>()
);

export const GetProcessWFMStatusRealTimeFailure = createAction(
  '[ProcessWFMStatusRealTime] Get ProcessWFMStatusRealTime Failure',
  props<{ error: any }>()
);
