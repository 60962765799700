/* eslint-disable no-undef */
import {
    Customer,
    Dashlet,
    Equipment,
    Location,
    SBCApplicationStatus,
    SBCNICInfo,
    SBCSIPInfo,
    Threshold
} from '@models/index';
import { Subject } from 'rxjs';

export interface SipTableData {
    TotalINVITE1XXResponses: string;
    TotalINVITE200Responses: string;
    TotalINVITE4XX6XXResponses: string;
    TotalINVITES: string;
}

export interface SecurityTableData {
    status: string;
    total: number;
    last3Min: number;
}

export class DashletSystemHealthSBC extends Dashlet {
    public readonly commandTypeIdNetworkInterface = '583C5D42-D54B-4FAA-968D-83AE87BF473B';
    public readonly commandTypeIdServiceStatus = 'D1599CFE-3F5B-4B85-829A-94B074B08350';
    public readonly commandTypeIdApplicationStatus = '53B1572F-10B1-4318-9232-714C3DB204DF';
    public readonly commandTypeIdSIPProtocolStatus = 'EB0BFBDA-4189-458E-A800-3F22509FED5F';
    public readonly commandTypeIdSIPCallStatus = '9D6E2CB7-029A-4526-810C-DA85E9C03655';
    public settingsUpdated: Subject<void> = new Subject<void>();

    readonly interval = 30;
    public customer!: Customer;
    public location!: Location;
    public equipment!: Equipment;

    public sbcSipInfoList!: SBCSIPInfo[];
    public sbcNicInfoList!: SBCNICInfo[];
    public applicationStatusList!: SBCApplicationStatus[];

    public sipTableData: SipTableData[] = [];

    public securityTableData: SecurityTableData[] = [];

    public thresholds: Threshold[] = [];

    public showCustomScripts = false;
    public showSystemChart = false;
    public showNetworkGraph = false;
    public showCallRate = false;
    public showServiceStatus = false;
    public showApplicationStatus = false;
    public showSIPCallStatus = false;
    public showSIPProtocolStatus = false;
    public showSecurityViolations = false;
    public showNetworkInterface = false;

    constructor() {
        super();

        this.sizes = [
            {
                id: 0,
                label: 'Standard',
                cols: 5,
                rows: 6
            }
        ];
        this.applySize(0);

        this.resetData();
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);
        this.configured = v.customer && v.location && v.equipment;
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        if (v.location) {
            this.location = new Location(v.location.value, v.location.label);
        } else {
            this.location = new Location('', '');
        }
        if (v.equipment) {
            this.equipment = new Equipment(v.equipment.value, v.equipment.label);
        } else {
            this.equipment = new Equipment('', '');
        }
        this.generatedNameTag = this.configured ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;

        this.showCustomScripts = v.showCustomScripts || false;
        this.showSystemChart = v.showSystemChart || false;
        this.showNetworkGraph = v.showNetworkGraph || false;
        this.showServiceStatus = v.showServiceStatus || false;
        this.showApplicationStatus = v.showApplicationStatus || false;
        this.showSIPProtocolStatus = v.showSIPProtocolStatus || false;
        this.showSIPCallStatus = v.showSIPCallStatus || false;
        this.showSecurityViolations = v.showSecurityViolations || false;
        this.showNetworkInterface = v.showNetworkInterface || false;

        let h = 0;
        if (this.showCustomScripts || this.showSystemChart) {
            h += 1.9;
        }
        if (this.showNetworkGraph) {
            h += 2.1;
        }
        if (this.showNetworkInterface) {
            h += 1.2;
        }
        if (this.showServiceStatus) {
            h += 1.2;
        }
        if (this.showApplicationStatus) {
            h += 1.2;
        }
        if (this.showSIPProtocolStatus) {
            h += 1.2;
        }
        if (this.showSIPCallStatus) {
            h += 1.4;
        }
        if (this.showSecurityViolations) {
            h += 1.2;
        }
        this.applySizeExpansion(0, h);
        this.settingsUpdated.next(null);
    }

    resetData() {
        this.sipTableData = [];
        this.sbcSipInfoList = [];
        this.sbcNicInfoList = [];
        this.applicationStatusList = [];
    }

    dispose() {}

    public processSIPProtocolStatus(data: any[]): void {
        const rows = data && data[0] ? data[0].data : null;
        if (rows) {
            for (let row of rows) {
                Object.keys(row).forEach(name => {
                    let index = this.sbcSipInfoList.findIndex(e => e.name === name);
                    if (index === -1) {
                        this.sbcSipInfoList.push(new SBCSIPInfo(name, row[name]));
                    } else {
                        this.sbcSipInfoList[index].update(
                            name,
                            row[name],
                            Math.abs(this.sbcSipInfoList[index].total - row[name]),
                            ''
                        );
                    }
                });
            }
        }
        this.sipTableData = [
            {
                TotalINVITE1XXResponses:
                    this.sbcSipInfoList.find(e => e.name === 'TotalINVITE1XXResponses')?.total.toString() || '---',
                TotalINVITE200Responses:
                    this.sbcSipInfoList.find(e => e.name === 'TotalINVITE200Responses')?.total.toString() || '---',
                TotalINVITE4XX6XXResponses:
                    this.sbcSipInfoList.find(e => e.name === 'TotalINVITE4XX6XXResponses')?.total.toString() || '---',
                TotalINVITES: this.sbcSipInfoList.find(e => e.name === 'TotalINVITES')?.total.toString() || '---'
            }
        ];
        const CallsBlocked = this.sbcSipInfoList.find(e => e.name === 'TotalCallsBlocked');
        const CallsFailed = this.sbcSipInfoList.find(e => e.name === 'TotalCallsFailed');
        const CallsDenied = this.sbcSipInfoList.find(e => e.name === 'TtlCallsDeniedDueToPolicy');
        const RegistrationsDropped = this.sbcSipInfoList.find(
            e => e.name === 'TotalRegistrationsDroppedByMissingPolicy'
        );
        const InvitesDropped = this.sbcSipInfoList.find(e => e.name === 'TotalInvitesDroppedByMissingPolicy');
        const SessionsDropped = this.sbcSipInfoList.find(e => e.name === 'TtlSessDroppedDueToMaxNumofConcSessExc');

        this.securityTableData = [
            {
                status: 'Calls Blocked',
                total: CallsBlocked ? CallsBlocked.total : null,
                last3Min: CallsBlocked ? CallsBlocked.last3Min : null
            },
            {
                status: 'Calls Failed',
                total: CallsFailed ? CallsFailed.total : null,
                last3Min: CallsFailed ? CallsFailed.last3Min : null
            },
            {
                status: 'Calls Denied',
                total: CallsDenied ? CallsDenied.total : null,
                last3Min: CallsDenied ? CallsDenied.last3Min : null
            },
            {
                status: 'Registrations Dropped',
                total: RegistrationsDropped ? RegistrationsDropped.total : null,
                last3Min: RegistrationsDropped ? RegistrationsDropped.last3Min : null
            },
            {
                status: 'Invites Dropped',
                total: InvitesDropped ? InvitesDropped.total : null,
                last3Min: InvitesDropped ? InvitesDropped.last3Min : null
            },
            {
                status: 'Sessions Dropped',
                total: SessionsDropped ? SessionsDropped.total : null,
                last3Min: SessionsDropped ? SessionsDropped.last3Min : null
            }
        ];
    }

    public processSIPCallStatus(data: any[]): void {
        const rows = data && data[0] ? data[0].data : null;
        if (rows) {
            for (let row of rows) {
                Object.keys(row).forEach(name => {
                    let index = this.sbcSipInfoList.findIndex(e => e.name === name);
                    if (index === -1) {
                        let item = new SBCSIPInfo(name, row[name]);
                        this.sbcSipInfoList.push(item);
                    } else {
                        this.sbcSipInfoList[index].update(
                            name,
                            row[name],
                            Math.abs(this.sbcSipInfoList[index].total - row[name]),
                            ''
                        );
                    }
                });
            }
        }
    }

    public processApplicationStatus(data: any[]): void {
        const row = data && data[0] ? data[0].data : null;
        if (row !== null) {
            for (let rowData of row) {
                let data: SBCApplicationStatus = new SBCApplicationStatus(
                    rowData.Name,
                    rowData.Status,
                    rowData.StateStart
                );
                let index = this.applicationStatusList.findIndex(e => e.applicationName === rowData.Name);
                if (index === -1) {
                    this.applicationStatusList.push(data);
                } else {
                    this.applicationStatusList[index].updateFromObject(data);
                }
            }
        }
    }

    public processNetworkInterface(data: any[]): void {
        const row = data[0].data;
        if (row !== null) {
            for (let rowData of row) {
                let index = this.sbcNicInfoList.findIndex(e => e.description === rowData.Description);
                if (index === -1) {
                    let newData = new SBCNICInfo(rowData.Description, rowData.OperationalStatus);
                    newData.updateNICChartData(rowData.InUcastPkts, rowData.OutUcastPkts, new Date(data[0].timestamp));
                    this.sbcNicInfoList.push(newData);
                } else {
                    this.sbcNicInfoList[index].updateNICChartData(
                        rowData.InUcastPkts,
                        rowData.OutUcastPkts,
                        new Date(data[0].timestamp)
                    );
                }
            }
        }
    }
}
