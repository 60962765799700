import {
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingDropdownGrouped,
    DashletSettingList
} from '@models/index';
import { DashletSettingsService, NotificationService } from '@services/index';
import { catchError, EMPTY, switchMap, take, tap } from 'rxjs';

export class DashletSettingsFormGenesysTrunksSummary extends DashletSettingsForm {
    private customer!: DashletSettingDropdown;
    private equipment!: DashletSettingDropdown;
    private trunkBaseIds!: DashletSettingList<string>;

    constructor(
        private readonly settingsService: DashletSettingsService,
        private readonly notificationService: NotificationService
    ) {
        super();
        this.init();

        this.loadCustomers();
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);

        if (this.customer.value) this.loadCloudServices(this.customer.value);
        if (this.equipment.value) this.loadGenesysTrunkBases(this.customer.value, this.equipment.value);
    }

    private init(): void {
        const group = new DashletSettingsGroup();
        const s: any = (group.settings = []);

        const customer = (this.customer = new DashletSettingDropdown());
        customer.label = 'Customer';
        customer.stateKey = 'customer';
        customer.disabled = true;
        customer.change.subscribe(() => {
            this.clearAndDisable(this.equipment);
            this.clearAndDisable(this.trunkBaseIds);
            this.loadCloudServices(customer.value);
        });
        s.push(customer);

        const equipment = (this.equipment = new DashletSettingDropdown());
        equipment.label = 'Cloud Service';
        equipment.stateKey = 'equipment';
        equipment.disabled = true;
        equipment.change.subscribe(() => {
            this.clearAndDisable(this.trunkBaseIds);
            if (equipment.value.length > 0) this.loadGenesysTrunkBases(customer.value, equipment.value);
        });
        s.push(equipment);

        const trunkBaseIds = (this.trunkBaseIds = new DashletSettingList());
        trunkBaseIds.label = 'Trunk Bases';
        trunkBaseIds.stateKey = 'trunkBases';
        trunkBaseIds.disabled = true;
        s.push(trunkBaseIds);

        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);

        this.settingsService
            .getUserAssociatedCustomers()
            .pipe(
                take(1),
                tap(customers => {
                    this.customer.items = customers;
                    this.customer.loadingComplete();
                }),
                catchError(err => {
                    this.notificationService.notify('Could not load Customers', 'error', err);
                    this.customer.loadingComplete();
                    this.customer.disabled = true;
                    return EMPTY;
                })
            )
            .subscribe();
    }

    private loadCloudServices(customer: string): void {
        this.enableAndLoading(this.equipment);
        this.settingsService
            .getVirtualLocations(customer)
            .pipe(
                take(1),
                switchMap(virtualLocations => {
                    if (virtualLocations.length) {
                        return this.settingsService.getGenesysCloudServices(virtualLocations[0].value).pipe(
                            take(1),
                            tap(cloudServices => {
                                this.equipment.items = cloudServices;
                                this.equipment.loadingComplete();
                            })
                        );
                    } else {
                        this.equipment.loadingComplete();
                        this.clearAndDisable(this.trunkBaseIds);
                        return EMPTY;
                    }
                }),
                catchError(err => {
                    this.notificationService.notify('Could not load Genesys Cloud Services', 'error', err);
                    this.equipment.loadingComplete();
                    this.clearAndDisable(this.trunkBaseIds);
                    this.equipment.disabled = true;
                    return EMPTY;
                })
            )
            .subscribe();
    }

    private loadGenesysTrunkBases(customerId: string, equipmentId: string): void {
        this.enableAndLoading(this.trunkBaseIds);
        this.settingsService
            .getGenesysTrunkBases(customerId, equipmentId)
            .pipe(
                take(1),
                tap(trunkBases => {
                    this.trunkBaseIds.items = trunkBases;
                    this.trunkBaseIds.loadingComplete();
                }),
                catchError(err => {
                    this.notificationService.notify('Could not load Genesys Trunk Bases', 'error', err);
                    this.trunkBaseIds.loadingComplete();
                    this.trunkBaseIds.disabled = true;
                    return EMPTY;
                })
            )
            .subscribe();
    }

    private enableAndLoading(
        widget: DashletSettingDropdownGrouped | DashletSettingDropdown | DashletSettingList<string>
    ) {
        widget.disabled = false;
        widget.loadingBegin();
    }

    private clearAndDisable(
        widget: DashletSettingDropdownGrouped | DashletSettingDropdown | DashletSettingList<string>
    ) {
        widget.disabled = true;
        widget.items = [];
        if (widget instanceof DashletSettingList) widget.values = [];
        else widget.value = null;
    }
}
