<ng-container *ngIf="!openSubcontainer">
    <dashlet-table
        [dataSource]="dashlet.alarmStatistics"
        [columns]="columns"
        [tableHeightRows]="tableSize"
        [pagination]="true"
        [pageLength]="tableSize"
        [menuItems]="menuItems"
    ></dashlet-table>
</ng-container>

<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'alarmsTable'"
    [navPrevious]="openDetailsDrilldown ? 'Alarm Summary / Customer Alarms Summary - ' + currentCustomer : 'Alarm Summary'"
    [showFooter]="false"
    [navCurrent]="openDetailsDrilldown ? 'Details' : 'Customer Alarms Summary - ' + currentCustomer"
    (close)="openDetailsDrilldown ? closeDetails() : closeAlarmsTable()"
    [showHeader]="false"
    [showNavigation]="showNavigation"
>
    <ng-container body>
        <app-dashlet-alarms-summary
            #dashletAlarmSummary
            [dashlet]="currentAlarmSummaryDashlet"
            (disableNavigation)="changeShowNavigation($event)"
            (showDetailDrilldown)="showDetailDrilldown()"
        >
        </app-dashlet-alarms-summary>
    </ng-container>
</vsm-dashlet-subcontainer>
