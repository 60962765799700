import { take } from 'rxjs/operators';
import {
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingDropdownGrouped,
    DashletSettingList
} from '@models/index';
import { DashletSettingsService } from '@services/index';

export class DashletSettingsFormCiscoDialPeerSummary extends DashletSettingsForm {
    private customer!: DashletSettingDropdown;
    private location!: DashletSettingDropdown;
    private equipment!: DashletSettingDropdown;
    private dialPeerGroups!: DashletSettingList<string>;

    constructor(private readonly settingsService: DashletSettingsService) {
        super();

        this.init();
        this.loadCustomers();
    }

    public loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);

        if (this.customer.value) {
            this.loadLocations(this.customer.value);
        }
        if (this.location.value) {
            this.loadEquipment(this.location.value);
            if (this.equipment.value) {
                this.loadTrunkGroups(this.equipment.value, this.location.value);
            }
        }
    }

    private init(): void {
        const group = new DashletSettingsGroup();
        const s: any = (group.settings = []);

        const customer = (this.customer = new DashletSettingDropdown());
        customer.label = 'Customer';
        customer.stateKey = 'customer';
        customer.disabled = true;
        customer.change.subscribe(() => {
            if (!this.location.value) this.clearAndDisable(this.location);
            if (!this.equipment.value) this.clearAndDisable(this.equipment);
            if (!this.dialPeerGroups.values) this.clearAndDisable(this.dialPeerGroups);

            this.loadLocations(customer.value!);
        });
        s.push(customer);

        const location = (this.location = new DashletSettingDropdown());
        location.label = 'Location';
        location.stateKey = 'location';
        location.disabled = true;
        location.change.subscribe(() => {
            if (!this.equipment.value) this.clearAndDisable(this.equipment);
            if (!this.dialPeerGroups.values) this.clearAndDisable(this.dialPeerGroups);

            this.loadEquipment(location.value!);
        });
        s.push(location);

        const equipment = (this.equipment = new DashletSettingDropdown());
        equipment.label = 'Source Equipment';
        equipment.stateKey = 'equipment';
        equipment.disabled = true;
        equipment.change.subscribe(() => {
            if (!this.dialPeerGroups.values) this.clearAndDisable(this.dialPeerGroups);

            this.loadTrunkGroups(equipment.value!, location.value!);
        });
        s.push(equipment);

        const dialPeer = (this.dialPeerGroups = new DashletSettingList<string>());
        dialPeer.label = 'Dial Peer';
        dialPeer.stateKey = 'dialPeerGroups';
        dialPeer.disabled = true;
        dialPeer.multiple = true;
        dialPeer.metaKeySelection = false;
        s.push(dialPeer);

        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);
        this.settingsService
            .getUserAssociatedCustomers()
            .pipe(take<any[]>(1))
            .subscribe(
                customers => {
                    this.customer.items = customers;
                    this.customer.loadingComplete();
                },
                () => {
                    this.customer.loadingComplete();
                    this.customer.disabled = true;
                    //show error notification here
                }
            );
    }

    private loadLocations(customer: string): void {
        this.enableAndLoading(this.location);
        this.settingsService
            .getLocations(customer)
            .pipe(take<any[]>(1))
            .subscribe(
                locations => {
                    this.location.items = locations;
                    this.location.loadingComplete();
                },
                () => {
                    this.location.loadingComplete();
                    this.location.disabled = true;
                    //show error notification here
                }
            );
    }

    private loadEquipment(location: string): void {
        this.enableAndLoading(this.equipment);
        this.settingsService
            .getEquipment(location, 'CiscoCUBE')
            .pipe(take<any[]>(1))
            .subscribe(
                receivers => {
                    this.equipment.items = receivers;
                    this.equipment.loadingComplete();
                },
                () => {
                    this.equipment.loadingComplete();
                    this.equipment.disabled = true;
                    //show error notification here
                }
            );
    }

    private loadTrunkGroups(equipment: string, location: string): void {
        this.enableAndLoading(this.dialPeerGroups);
        this.settingsService
            .getCiscoCubeDialPeerGroups(equipment, location)
            .pipe(take<any[]>(1))
            .subscribe(
                trunks => {
                    this.dialPeerGroups.items = trunks;
                    this.dialPeerGroups.loadingComplete();
                },
                () => {
                    this.dialPeerGroups.loadingComplete();
                    this.dialPeerGroups.disabled = true;
                    //show error notification here
                }
            );
    }

    private clearAndDisable(
        widget: DashletSettingDropdown | DashletSettingDropdownGrouped | DashletSettingList<string>
    ): void {
        widget.disabled = true;
        widget.items = [];
        if (widget instanceof DashletSettingList) widget.values = [];
        else widget.value = null;
    }

    private enableAndLoading(
        widget: DashletSettingDropdown | DashletSettingDropdownGrouped | DashletSettingList<string>
    ): void {
        widget.disabled = false;
        widget.loadingBegin();
    }
}
