<div class="flex-column gap-l">
    <!-- System -->
    <div class="flex-column gap-l">
        <dashlet-section-processor-memory
            *ngIf="!openSubcontainer"
            #processorMemory
            [showSystemChart]="dashlet.showSystemChart"
            [showDiskUsage]="dashlet.showModuleDisk"
            [equipment]="dashlet.serverEquipment"
            [customerId]="dashlet.customer.customerId"
            [locationId]="dashlet.location.locationId"
            [commandTypeIdCPUUsage]="dashlet.commandTypeIdCpuMem"
            [commandTypeIdMemoryUsage]="dashlet.commandTypeIdCpuMem"
            [commandTypeIdDiskUsage]="dashlet.commandTypeIdDisk"
            [commandTypeIdUpTime]="dashlet.commandTypeIdUpTime"
        >
        </dashlet-section-processor-memory>
        <ng-container *ngIf="!smallLayout">
            <!-- Network Connectivity Chart -->
            <dashlet-section-network-connectivity
                *ngIf="!openSubcontainer"
                [showNetworkGraph]="dashlet.showNetworkChart"
                [equipment]="dashlet.serverEquipment"
                [customerId]="dashlet.customer.customerId"
            >
            </dashlet-section-network-connectivity>
            <!--Windows Server Network interface data is sent via the CpuMem commandType Id-->
            <dashlet-section-network-interface
                *ngIf="(dashlet.showModuleNetwork && !openSubcontainer) || openSubcontainer === 'network-interface'"
                [equipment]="dashlet.serverEquipment"
                [locationName]="dashlet.locationName"
                [customerName]="dashlet.customer.customerName"
                [commandTypeIdNetworkInterface]="dashlet.commandTypeIdCpuMem"
                [commandTypeIdNic]="dashlet.commandTypeIdNicName"
                (openDetails)="$event ? openDetails($event, 0) : closeDetails()"
            >
            </dashlet-section-network-interface>
            <!-- Network Interface Popout -->

            <dashlet-section-services
                *ngIf="(dashlet.showModuleServices && !openSubcontainer) || openSubcontainer === 'service'"
                #services
                [equipment]="dashlet.serverEquipment"
                [location]="dashlet.location"
                [customer]="dashlet.customer"
                [commandTypeIdServices]="[dashlet.commandTypeIdWatchedService]"
                (openDetails)="$event === null ? closeDetails() : openDetails('service', $event)"
            >
            </dashlet-section-services>

            <div *ngIf="dashlet.showModuleIis && !openSubcontainer" class="section section-iis">
                <dashlet-section-header>
                  IIS
                  <mat-icon
                    class="warning-triangle"
                    color="warn"
                    *ngIf="iisTimedOut"
                    [matTooltip]="timeoutService.getDataExpiredmessage('IIS Monitoring', dashlet.commandTypeIdIIS.toLowerCase(), dashlet.serverEquipment.equipmentId)"
                    >
                    {{dashlet.iisHosts}}
                      error
                  </mat-icon>
                </dashlet-section-header>
                <div class="flex-column gap-m">
                    <dashlet-ratio-bar class="dashlet-cell" emptyTitle="No data available for IIS">
                        <dashlet-ratio-bar-item
                            [clickable]="dashlet.iisHosts.length > 0 ? true : false"
                            [value]="iisTimedOut? 0 : dashlet.iisHosts.length"
                            [color]="getSeverityColor(dashlet.iisHosts.length > 0 ? 0 : -1)"
                            [matTooltip]="'View details'"
                            (click)="openDetails('iis', 0)">
                        </dashlet-ratio-bar-item>
                    </dashlet-ratio-bar>
                    <div class="justify-space-between">
                        <h3 *ngIf="!iisTimedOut && dashlet.iisHosts.length > 0" class="dashlet-link-underline" dashlet-link="true"
                            (click)="openDetails('iis', 0)">
                            {{ dashlet.iisHosts.length }}
                            <span class="mat-h3"> Site(s) Running</span>
                        </h3>
                    </div>
                </div>
            </div>
            <div *ngIf="dashlet.showModuleSql && !openSubcontainer" class="section section-iis">
                <dashlet-section-header>
                    SQL Monitoring
                    <mat-icon
                        class="warning-triangle"
                        color="warn"
                        *ngIf="!dashlet.sqlData"
                        [matTooltip]="
                            timeoutService.getDataExpiredmessage('SQL Monitoring', dashlet.commandTypeIdSqlMonitoring, dashlet.serverEquipment.equipmentId)
                        "
                        >error</mat-icon
                    >
                </dashlet-section-header>
                <dashlet-table
                    [columns]="sqlColumns"
                    [dataSource]="dashlet.sqlData ? dashlet.sqlData : []"
                    [tableHeightRows]="5"
                    [rowExpansionColumns]="sqlExpansionColumns"
                    rowExpansionField="expansion"
                ></dashlet-table>
            </div>
        </ng-container>
    </div>

    <!-- Custom Scripts -->
    <dashlet-section-custom-scripts
        *ngIf="dashlet.showCustomScripts && !openSubcontainer"
        [equipment]="dashlet.serverEquipment"
        [customer]="dashlet.customer"
        [type]="'Windows'"
    >
    </dashlet-section-custom-scripts>
</div>
<!-- IIS Content -->
<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'iis'"
    [title]="dashlet.locationName + ' | ' + dashlet.serverEquipment.equipmentName"
    [navPrevious]="'Windows Server System Health - ' + dashlet.customer.customerName"
    [navCurrent]="'IIS'"
    [color]="getSeverityColor(openSubcontainerSeverity)"
    (close)="closeDetails()"
>
    <ng-container subtitle> {{ dashlet.iisHosts.length }} IIS Hosts running </ng-container>
    <div body>
        <dashlet-table [columns]="iisColumns" [dataSource]="dashlet.iisHosts" [tableHeightRows]="getTableHeight()" [sortable]="false"></dashlet-table>
    </div>
</vsm-dashlet-subcontainer>

<!-- create thresholds alerts drilldown -->
<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'createThreshold' || openSubcontainer === 'editThreshold'"
    [navPrevious]="'Windows System Health - ' + dashlet.customer.customerName"
    [navCurrent]="(openSubcontainer === 'createThreshold' ? 'Create' : 'Edit') + ' Threshold'"
    (close)="closeDetails()"
>
    <ng-container title>{{ openSubcontainer === 'createThreshold' ? 'New' : 'Edit' }} {{ selectedField }} Threshold </ng-container>
    <ng-container body>
        <threshold-item #thresholdItem [settings]="dashlet.getSettings()" [type]="'windows'" [threshold]="selectedThreshold"> </threshold-item>
    </ng-container>
    <div footer class="row row-buttons">
        <div>
            <button type="button" pButton class="btn button-details" (click)="closeDetails()" label="Cancel"></button>
        </div>
        <div *ngIf="openSubcontainer === 'editThreshold'; else addThreshold">
            <button type="submit" pButton class="btn button-details" (click)="saveEdit()" [disabled]="!thresholdItem.formValid()" label="Save"></button>
        </div>
        <ng-template #addThreshold>
            <button type="submit" pButton class="btn button-details" (click)="onSubmit()" [disabled]="!thresholdItem.formValid()" label="Save"></button>
        </ng-template>
    </div>
</vsm-dashlet-subcontainer>