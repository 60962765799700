import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as Actions from '@actions/index';
import { DashletService, DashletSettingsService } from '@services/index';
import {
    ColumnTypes,
    CustomerLogo,
    DashletSystemHealthVmware,
    DataCenterAPIData,
    fixObjectKeys,
    GuestOSDataAPIData,
    HealthLinkAPIData,
    Host,
    HostDataAPIData,
    HypervisorDataAPIData,
    RealtimeGatewayDocument,
    TableIcon,
    Vm,
    VMDataAPIData
} from '@models/index';
import { AppState, selectDataFromCommonEntity, selectEntity } from '@reducers/index';
import {
    Observable,
    Subscription,
    tap,
    mergeMap,
    takeUntil,
    Subject,
    interval,
    from,
    startWith,
    of,
    EMPTY
} from 'rxjs';
import { Store, select } from '@ngrx/store';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';

@Component({
    selector: 'app-dashlet-system-vmware',
    templateUrl: './dashlet-system-vmware.component.html',
    styleUrls: ['./dashlet-system-vmware.component.scss']
})
export class DashletSystemVmwareComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletSystemHealthVmware;
    private subscription: Subscription = new Subscription();
    private destroy$: Subject<void>;
    private expandedView: boolean = false;
    public loading = true;

    public columns: ColumnTypes[] = [
        {
            columnDef: 'powerState',
            header: '',
            cell: (row: Host) => {
                if (row.powerState === 'POWERED_ON') {
                    return 0;
                } else if (row.powerState === 'POWERED_OFF') {
                    return 2;
                } else if (row.powerState === 'SUSPENDED') {
                    return 1;
                }
                return -1;
            },
            type: 'severity'
        },
        {
            columnDef: 'expand',
            header: ''
        },
        {
            columnDef: 'name',
            header: 'Host',
            dataTooltip: (row: Host) => this.getHostTooltip(row),
            filterType: 'select',
            width: '140px'
        },
        {
            columnDef: 'vmPowerStates',
            header: 'Vm Power Status',
            cell: (row: Host) =>
                `${row.vmPowerStates.vmPoweredOn} / ${row.vmPowerStates.vmPoweredOff} / ${row.vmPowerStates.vmPoweredSuspended}`,
            dataTooltip: (row: Host) => this.getVmPowerStatusTooltip(row.vmPowerStates)
        }
    ];

    public expansionColumns: ColumnTypes[] = [
        {
            columnDef: 'severity',
            header: '',
            cell: (vm: Vm) => vm.severity,
            type: 'severity'
        },
        {
            columnDef: 'name',
            header: 'Virtual Machine',
            filterType: 'text',
            width: '50%',
            display: () => !this.expandedView,
            dataTooltip: (vm: Vm) => this.getVmTooltip(vm)
        },
        {
            columnDef: 'expandedName',
            header: 'Virtual Machine',
            filterType: 'text',
            width: '15%',
            display: () => this.expandedView,
            dataTooltip: (vm: Vm) => this.getVmTooltip(vm)
        },
        {
            columnDef: 'powerState',
            header: 'Power Status',
            type: 'icon',
            cell: (element: Vm): TableIcon => {
                switch (element.powerState) {
                    case 'POWERED_OFF':
                        return {
                            text: '',
                            iconName: 'power_off',
                            color: this.getSeverityColor(2)
                        };
                    case 'SUSPENDED':
                        return {
                            text: '',
                            iconName: 'suspended_mode',
                            color: this.getSeverityColor(1)
                        };
                    case 'POWERED_ON':
                        return {
                            text: '',
                            iconName: 'power',
                            color: this.getSeverityColor(0)
                        };

                    default:
                        return {
                            text: '---',
                            iconName: '',
                            color: this.getSeverityColor(-1)
                        };
                }
            }
        },

        {
            columnDef: 'server',
            header: 'Server',
            display: () => this.expandedView,
            filterType: 'text'
        },
        {
            columnDef: 'systemHealthCPU',
            header: 'CPU',
            display: () => this.expandedView,
            cell: (row: Vm) =>
                row.systemHealthCPU === null || row.systemHealthCPU === undefined
                    ? '---'
                    : `${row.systemHealthCPU.toFixed(1)}%`,
            filterType: 'slider',
            sliderRange: [0, 100],
            type: 'numeric'
        },
        {
            columnDef: 'systemHealthMemory',
            header: 'Memory',
            display: () => this.expandedView,
            cell: (row: Vm) =>
                row.systemHealthMemory === null || row.systemHealthMemory === undefined
                    ? '---'
                    : `${row.systemHealthMemory.toFixed(1)}%`,
            filterType: 'slider',
            sliderRange: [0, 100],
            type: 'numeric'
        },
        {
            columnDef: 'systemHealthDisk',
            header: 'Disk',
            display: () => this.expandedView,
            cell: (row: Vm) =>
                row.systemHealthDisk === null || row.systemHealthDisk === undefined
                    ? '---'
                    : `${row.systemHealthDisk.toFixed(1)}%`,
            filterType: 'slider',
            sliderRange: [0, 100],
            type: 'numeric'
        },
        {
            columnDef: 'maxPing',
            header: 'Max',
            subHeadingInFilter: 'Ping',
            display: () => this.expandedView,
            cell: (row: Vm) =>
                row.maxPing === null || row.maxPing === undefined ? '---' : `${+row.maxPing.toFixed(0) || '<1'} ms`,
            type: 'numeric',
            width: '6%'
        },
        {
            columnDef: 'avgPing',
            header: 'Avg',
            subHeadingInFilter: 'Ping',
            display: () => this.expandedView,
            cell: (row: Vm) =>
                row.avgPing === null || row.avgPing === undefined ? '---' : `${+row.avgPing.toFixed(0) || '<1'} ms`,
            type: 'numeric',
            width: '6%'
        },
        {
            columnDef: 'spacerElement',
            header: ' ',
            display: () => this.expandedView,
            width: '1%'
        },
        {
            columnDef: 'servicesUp',
            header: 'Services',
            subHeadingInFilter: 'Up',
            cell: (row: Vm) =>
                parseInt(row.servicesUp) > 0 ? row.servicesUp : parseInt(row.servicesUp) === 0 ? '---' : 'N/A',
            type: 'services',
            display: () => this.expandedView
        },
        {
            columnDef: 'servicesDown',
            subHeadingInFilter: 'Down',
            header: 'Services',
            cell: (row: Vm) =>
                parseInt(row.servicesDown) > 0 ? row.servicesDown : parseInt(row.servicesDown) === 0 ? '---' : 'N/A',
            type: 'services',
            display: () => this.expandedView
        },
        {
            columnDef: 'availability',
            header: 'Availability',
            display: () => this.expandedView,
            cell: (row: Vm) =>
                row.availability === null || row.availability === undefined
                    ? '---'
                    : `${+row.availability.toFixed(1)}%`,
            filterType: 'slider',
            sliderRange: [0, 100],
            type: 'numeric'
        }
    ];

    constructor(
        private dashletService: DashletService,
        private dashletSettingsService: DashletSettingsService,
        private store$: Store<AppState>,
        private settingsService: DashletSettingsService
    ) {}

    public ngOnInit(): void {
        this.dashletSettingsService.initSettings(this.dashlet.id);

        this.dashlet.unSub.subscribe(() => {
            this.unSub();
            this.dashlet.resetData();
        });

        this.dashlet.settingsUpdated.subscribe(() => {
            this.loading = true;
            this.subscription = new Subscription();
            this.sub();
        });
        this.sub();
    }

    private sub(): void {
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));

        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );

        this.destroy$ = new Subject<void>();

        if (!this.dashlet.configured) return;

        this.expandedView = this.dashlet.getSize().id === 2;
        this.subscription.add(
            this.dashlet.sizeChange.subscribe(sizeId => {
                this.expandedView = sizeId === 2;
            })
        );
        const locationId = this.dashlet.location.locationId;
        const equipmentId = this.dashlet.equipment.equipmentId;

        this.subscription.add(
            this.settingsService.getSystemHealthEquipment(locationId).subscribe(res => {
                if (!res) return;
                Object.keys(res).map(key => {
                    if (key !== 'entityId') {
                        res[key].items.forEach(item => {
                            this.dashlet.servers.push({
                                id: item.value,
                                name: item.label
                            });
                        });
                    }
                });
            })
        );

        this.subscription.add(
            this.requestSystemHealthLinkData(equipmentId)
                .pipe(
                    mergeMap(systemHealthLinkDocument => this.processHealthLinkDocument(systemHealthLinkDocument)),
                    tap(systemHealthLinkData => this.requestHealthSummaryPeriodically(systemHealthLinkData)),
                    mergeMap(systemHealthLinkData =>
                        this.requestNetworkConnectivityDocument(systemHealthLinkData.EquipmentId)
                    )
                )
                .subscribe(data => {
                    this.dashlet.networkConnectivity.dataExpired = false;
                    this.dashlet.processNetworkConnectivity(data);
                })
        );

        this.subscription.add(
            this.requestDataCenterDocument(equipmentId)
                .pipe(
                    mergeMap(dataCenterDocument => this.processDataCenterDocument(dataCenterDocument)),
                    mergeMap(dataCenter => this.requestHostsDocument(dataCenter?.MyEquipmentID, equipmentId)),
                    mergeMap(hostDocument => this.processHostsDocument(hostDocument)),
                    tap(host => this.requestHyperVisorData(host?.MyEquipmentID, equipmentId)),
                    mergeMap(host => this.requestVmsDocumentPeriodically(host?.MyEquipmentID, equipmentId)),
                    mergeMap(vmDocument => this.processVmsDocument(vmDocument)),
                    mergeMap(vm => this.requestGuestOsDocument(vm?.MyEquipmentID, equipmentId))
                )
                .subscribe(guestOsDocument => this.dashlet.processGuestOsData(guestOsDocument[0].data))
        );
    }
    private unSub(): void {
        this.subscription.unsubscribe();

        if (this.dashlet.systemHealthLinks.length) {
            this.dashlet.systemHealthLinks.forEach(systemHealthLink => {
                this.store$.dispatch(
                    Actions.UnsubscribeFromRealTimeService({
                        equipmentId: systemHealthLink.EquipmentId,
                        command: this.dashlet.COMMAND_TYPE_ID_SYSTEM_HEALTH_LINK
                    })
                );
                this.store$.dispatch(
                    Actions.UnsubscribeFromRealTimeService({
                        equipmentId: systemHealthLink.EquipmentId,
                        command: this.dashlet.COMMAND_TYPE_ID_NETWORK_CONNECTIVITY
                    })
                );
            });
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
    }

    private getSeverityColor(sev: number): string {
        return this.dashletService.getSeverityColor(sev);
    }

    private getVmTooltip(vm: Vm) {
        return `Host: ${vm.guestOs?.Host_Name || '--'}
Data Centre: ${vm.dataCenter?.dataCenter || '--'}
Guest OS: ${vm.guestOs?.Full_Name_Default_Message || '--'}
IP Address: ${vm.guestOs?.IP_Address || '--'}`;
    }

    private getHostTooltip(host): string {
        return `Data Center: ${host?.dataCenter?.dataCenter}
Esxi Version: ${host?.hypervisor ? host.hypervisor?.displayVersion : '--'}
Connection Status: ${host?.connectionState}
Total Servers: ${host?.virtualMachines?.length}`;
    }

    private getVmPowerStatusTooltip(powerStates): string {
        return `Powered on: ${powerStates?.vmPoweredOn}
Powered off: ${powerStates?.vmPoweredOff}
Suspended: ${powerStates?.vmPoweredSuspended}`;
    }

    private requestHealthSummaryPeriodically(systemHealthLinkData: HealthLinkAPIData) {
        const url = `summarydocument/equipment/${systemHealthLinkData.EquipmentId}/${this.dashlet.COMMAND_TYPE_ID_SYSTEM_HEALTH}`;

        interval(60000 * 3)
            .pipe(
                startWith(0),
                takeUntil(this.destroy$),
                switchMap(() =>
                    this.settingsService
                        .makeHealthSummaryApiCall(url, systemHealthLinkData.EquipmentId)
                        .pipe(catchError(() => of(null)))
                )
            )
            .subscribe(summary => this.dashlet.processSystemHealthData(summary.newKey, fixObjectKeys(summary)));
    }

    private requestSystemHealthLinkData(equipmentId: string): Observable<{ data: HealthLinkAPIData[] }[]> {
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: equipmentId,
                command: this.dashlet.COMMAND_TYPE_ID_SYSTEM_HEALTH_LINK
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: equipmentId,
                commandTypeId: this.dashlet.COMMAND_TYPE_ID_SYSTEM_HEALTH_LINK
            })
        );
        return this.store$.pipe(
            select(selectDataFromCommonEntity(equipmentId + this.dashlet.COMMAND_TYPE_ID_SYSTEM_HEALTH_LINK)),
            filter(data => data?.length)
        );
    }
    private processHealthLinkDocument(
        systemHealthLinkDocument: { data: HealthLinkAPIData[] }[]
    ): Observable<HealthLinkAPIData> {
        const cleanData = this.removeSortedDuplicates(systemHealthLinkDocument[0].data);
        this.dashlet.systemHealthLinks = cleanData;
        return from(cleanData);
    }

    private requestNetworkConnectivityDocument(equipmentId: string): Observable<RealtimeGatewayDocument[]> {
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: equipmentId,
                command: this.dashlet.COMMAND_TYPE_ID_NETWORK_CONNECTIVITY
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: equipmentId,
                commandTypeId: this.dashlet.COMMAND_TYPE_ID_NETWORK_CONNECTIVITY
            })
        );

        return this.store$.pipe(
            select(selectDataFromCommonEntity(equipmentId + this.dashlet.COMMAND_TYPE_ID_NETWORK_CONNECTIVITY)),
            filter(data => data?.length)
        );
    }

    private requestHyperVisorData(childEquipmentId: string, parentEquipmentId: string): void {
        if (!childEquipmentId) return;

        this.store$.dispatch(
            Actions.GetChildEquipmentData({
                childEquipmentId,
                parentEquipmentId,
                commandTypeId: this.dashlet.COMMAND_TYPE_ID_HYPERVISOR
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(selectDataFromCommonEntity(childEquipmentId + this.dashlet.COMMAND_TYPE_ID_HYPERVISOR)),
                    takeUntil(this.destroy$),
                    take(2), //Take 2 because it always sends 1 undefined before the actual data comes through
                    filter(data => data?.length),
                    map(newHyperVisorData => newHyperVisorData?.[0].data[0])
                )
                .subscribe((hypervisor: HypervisorDataAPIData) => {
                    this.dashlet.processHyperVisorData(hypervisor);
                })
        );
    }

    private requestDataCenterDocument(equipmentId: string): Observable<{ data: DataCenterAPIData[] }[]> {
        this.store$.dispatch(
            Actions.GetCommonHistoric({
                equipmentId: equipmentId,
                commandTypeId: this.dashlet.COMMAND_TYPE_ID_DATA_CENTRE,
                from: this.dashlet.fromDate,
                to: new Date().toISOString(),
                max: 1
            })
        );
        return this.store$.pipe(
            select(selectDataFromCommonEntity(equipmentId + this.dashlet.COMMAND_TYPE_ID_DATA_CENTRE)),
            takeUntil(this.destroy$),
            take(2), //Take 2 because it always sends 1 undefined before the actual data comes through
            filter(data => data?.length)
        );
    }

    private processDataCenterDocument(document: { data: DataCenterAPIData[] }[]): Observable<DataCenterAPIData> {
        this.dashlet.dataCenterData = document[0].data;
        return from(document[0].data);
    }

    private requestHostsDocument(
        childEquipmentId: string,
        parentEquipmentId: string
    ): Observable<{ data: HostDataAPIData[] }[]> {
        this.store$.dispatch(
            Actions.GetChildEquipmentData({
                childEquipmentId,
                parentEquipmentId,
                commandTypeId: this.dashlet.COMMAND_TYPE_ID_HOST
            })
        );

        return this.store$.pipe(
            select(selectDataFromCommonEntity(childEquipmentId + this.dashlet.COMMAND_TYPE_ID_HOST)),
            take(2), //Take 2 because it always sends 1 undefined before the actual data comes through
            filter(data => data?.length)
        );
    }

    private processHostsDocument(document: { data: HostDataAPIData[] }[]): Observable<HostDataAPIData> {
        this.dashlet.hostData = document[0].data;
        return from(document[0].data);
    }

    private requestVmsDocumentPeriodically(
        childEquipmentId: string,
        parentEquipmentId: string
    ): Observable<{ data: VMDataAPIData[] }[]> {
        interval(60000 * 15) //Update VM list and GuestOS every 15 minutes
            .pipe(
                startWith(0),
                takeUntil(this.destroy$),
                switchMap(() => {
                    this.store$.dispatch(
                        Actions.GetChildEquipmentData({
                            childEquipmentId,
                            parentEquipmentId,
                            commandTypeId: this.dashlet.COMMAND_TYPE_ID_VM
                        })
                    );
                    return EMPTY;
                })
            )
            .subscribe();

        return this.store$.pipe(
            select(selectDataFromCommonEntity(childEquipmentId + this.dashlet.COMMAND_TYPE_ID_VM)),
            filter(data => data?.length)
        );
    }

    private processVmsDocument(document: { data: VMDataAPIData[] }[]): Observable<VMDataAPIData> {
        this.dashlet.processVmData([...document[0].data].reverse());
        this.loading = false;
        return from(document[0].data);
    }

    private requestGuestOsDocument(
        childEquipmentId: string,
        parentEquipmentId: string
    ): Observable<{ data: GuestOSDataAPIData[] }[]> {
        this.store$.dispatch(
            Actions.GetChildEquipmentData({
                childEquipmentId,
                parentEquipmentId,
                commandTypeId: this.dashlet.COMMAND_TYPE_ID_GUEST_OS
            })
        );

        return this.store$.pipe(
            select(selectDataFromCommonEntity(childEquipmentId + this.dashlet.COMMAND_TYPE_ID_GUEST_OS)),
            take(2), //Take 2 because it always sends 1 undefined before the actual data comes through
            filter(data => data?.length)
        );
    }

    public getAverageResponse() {
        switch (this.dashlet?.networkData?.averagePing) {
            case undefined:
            case null:
                return '---';
            case '0':
                return '<1 ms';
            default:
                return this.dashlet.networkData.averagePing;
        }
    }

    private removeSortedDuplicates(data) {
        if (data.length === 0) return [];

        const uniqueData = [data[0]];

        for (let i = 1; i < data.length; i++) {
            if (data[i].VirtualMachineName !== data[i - 1].VirtualMachineName) {
                uniqueData.push(data[i]);
            }
        }

        return uniqueData;
    }
}
