export class WFMHistoricData {
	private _wfoVendor: string;
	private _message: string;
	private _logEntryDateTime!: string;
	private _status!: string;
	private _severity!: number;
	private _sessionId!: number;
	private _fileSize!: number;
	private _displayError!: string;

	constructor(wfoVendor: string, message: string, logEntryDateTime: string) {
		this._wfoVendor = wfoVendor;
		this._message = message;

		this.updateFields();
	}

	public update(wfoVendor: string, message: string, logEntryDateTime: string) {
		this._wfoVendor = wfoVendor;
		this._message = message;

		this.updateFields();
	}

	public updateErrorMessage(errorMessage: string) {
		this._displayError = errorMessage;
	}

	private updateFields() {
		const args = this._message.split(' ');
		for (let i = 0; i < args.length; i++) {
			if (args[i].trim().toLowerCase().includes('ftp')) {
				if (i + 1 <= args.length - 1) {
					this._status = args[i + 1].toLowerCase();
					this._status = this._status.charAt(0).toUpperCase() + this._status.slice(1);
					this._status = this._status.replace(',', '');
				}
			}
			if (args[i].trim().toLowerCase() == 'session') {
				if (i + 1 <= args.length - 1) {
					this._sessionId = parseInt(args[i + 1]);
				}
			}
			if (args[i].trim().toLowerCase() == 'bytes:') {
				if (i + 1 <= args.length - 1) {
					this._fileSize = parseInt(args[i + 1]);
				}
			}
			if (args[i].trim().split(':').length == 3) {
				const at = args[i].split('-');
				if (at.length == 2) {
					this._logEntryDateTime = at[1];
				} else {
					this._logEntryDateTime = at[0];
				}
			}
		}

		if (!this._fileSize) {
			this._severity = 2;
		} else {
			this._severity = 0;
		}
	}

	/**
	 * Getter status
	 * @return {string}
	 */
	public get status(): string {
		return this._status;
	}

	/**
	 * Getter severity
	 * @return {number}
	 */
	public get severity(): number {
		return this._severity;
	}

	/**
	 * Getter wfoVendor
	 * @return {string}
	 */
	public get wfoVendor(): string {
		return this._wfoVendor;
	}

	/**
	 * Getter message
	 * @return {string}
	 */
	public get message(): string {
		return this._message;
	}

	/**
	 * Getter logEntryDateTime
	 * @return {string}
	 */
	public get logEntryDateTime(): string {
		return this._logEntryDateTime;
	}

	public get fileSize(): number {
		return this._fileSize;
	}

	public get sessionId(): number {
		return this._sessionId;
	}

	public get displayError(): string {
		return this._displayError;
	}

	/**
	 * Setter wfoVendor
	 * @param {string} value
	 */
	public set wfoVendor(value: string) {
		this._wfoVendor = value;
	}

	/**
	 * Setter message
	 * @param {string} value
	 */
	public set message(value: string) {
		this._message = value;
	}

	/**
	 * Setter logEntryDateTime
	 * @param {string} value
	 */
	public set logEntryDateTime(value: string) {
		this._logEntryDateTime = value;
	}
}
