import { createAction, props } from '@ngrx/store';

export const GetChildEquipmentData = createAction(
    '[GetChildEquipmentData] Get GetChildEquipmentData',
    props<{ childEquipmentId: string; parentEquipmentId: string; commandTypeId: string }>()
);

export const GetChildEquipmentDataSuccess = createAction(
    '[GetChildEquipmentData] Get GetChildEquipmentData Success',
    props<{ data: any }>()
);

export const GetChildEquipmentDataFailure = createAction(
    '[GetChildEquipmentData] Get GetChildEquipmentData Failure',
    props<{ error: any }>()
);
