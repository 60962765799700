import * as RtcpReceiversActions from '@actions/get-rtcp-receivers/get-rtcp-receivers.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { DashletSettingsService } from '@services/index';

@Injectable()
export class GetRtcpReceiversEffects {
    constructor(private actions$: Actions, private settingsService: DashletSettingsService) {}

    getRtcpReceivers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RtcpReceiversActions.GetRtcpReceivers),
            mergeMap(action =>
                this.settingsService.getRtcpReceivers(action.locationId).pipe(
                    catchError(err => of(RtcpReceiversActions.GetRtcpReceiversFailure({ error: err }))),
                    mergeMap((data: any) => [
                        RtcpReceiversActions.GetRtcpReceiversSuccess({ data: data, locationId: action.locationId })
                    ])
                )
            )
        )
    );
}
