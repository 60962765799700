export class IPNREndpointsData {
	private _BGColor: number;
	private _IPNRName: string;
	private _IPEndpointsAverage: number;
	private _IPNRNumber: number;
	private _IPEndpointsNow: number;

	constructor(IPNRName: string, IPNRNumber: number, IPEndpointsAverage: number, IPEndpointsNow: number) {
		this._IPNRName = IPNRName;
		this._BGColor = -1;
		this._IPEndpointsAverage = IPEndpointsAverage;
		this._IPNRNumber = IPNRNumber;
		this._IPEndpointsNow = IPEndpointsNow;
	}

	public update(IPNRName: string, IPNRNumber: number, IPEndpointsAverage: number, IPEndpointsNow: number) {
		this._IPNRName = IPNRName;
		this._BGColor = -1;
		this._IPEndpointsAverage = IPEndpointsAverage;
		this._IPNRNumber = IPNRNumber;
		this._IPEndpointsNow = IPEndpointsNow;
	}

	public get IPNRName(): string {
		return this._IPNRName;
	}
	public get BGColor(): number {
		return this._BGColor;
	}
	public get IPNRNumber(): number {
		return this._IPNRNumber;
	}

	public get IPEndpointsAverage(): number {
		return this._IPEndpointsAverage;
	}

	public get IPEndpointsNow(): number {
		return this._IPEndpointsNow;
	}

	public set IPNRName(value: string) {
		this._IPNRName = value;
	}

	public set IPNRNumber(value: number) {
		this._IPNRNumber = value;
	}
	public set IPEndpointsAverage(value: number) {
		this._IPEndpointsAverage = value;
	}

	public set IPEndpointsNow(value: number) {
		this._IPEndpointsNow = value;
	}
	public set BGColor(value: number) {
		this._BGColor = value;
	}
}
