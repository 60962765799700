<div class="flex gap-m h-100">
    <div class="flex-column grow justify-space-around" [ngClass]="{ 'flex-row': smallLayout, 'flex-column': mediumLayout || wideLayout }">
        <div class="dashlet-cell grow metrics-spacing" [ngClass]="{ 'emea-border': !smallLayout, center: smallLayout }">
            <div class="dashlet-label dashlet-label-n">EMEA</div>
            <div class="dashlet-value dashlet-value-n">
                <span class="dashlet-value dashlet-value-n">{{ dashlet.avgPortsLatestEMEA }}</span>
            </div>
        </div>
        <div class="dashlet-cell grow metrics-spacing" [ngClass]="{ 'apac-border': !smallLayout, center: smallLayout }">
            <div class="dashlet-label dashlet-label-n">APAC</div>
            <div class="dashlet-value dashlet-value-n">
                <span class="dashlet-value dashlet-value-n">{{ dashlet.avgPortsLatestAPAC }}</span>
            </div>
        </div>
        <div class="dashlet-cell grow metrics-spacing" [ngClass]="{ 'na-border': !smallLayout, center: smallLayout }">
            <div class="dashlet-label dashlet-label-n">NA</div>
            <div class="dashlet-value dashlet-value-n">
                <span class="dashlet-value dashlet-value-n">{{ dashlet.avgPortsLatestNA }}</span>
            </div>
        </div>
        <div class="dashlet-cell grow metrics-spacing" [ngClass]="{ 'all-border': !smallLayout, center: smallLayout }">
            <div class="dashlet-label dashlet-label-n">All</div>
            <div class="dashlet-value dashlet-value-n">
                <span class="dashlet-value dashlet-value-n">{{ dashlet.avgPortsLatestAll }}</span>
            </div>
        </div>
    </div>
    <div class="flex-column grow justify-space-around h-100" *ngIf="mediumLayout || wideLayout">
        <!-- chart medium -->
        <div *ngIf="mediumLayout" class="h-100">
            <dashlet-line-graph #portsChart [data]="portsLineData" [options]="portsChartOptions" [height]="'100%'" [width]="'100%'">
            </dashlet-line-graph>
        </div>
        <!-- chart large -->
        <div *ngIf="wideLayout" class="h-100">
            <dashlet-line-graph
                #portsChartWide
                [data]="portsLineData"
                [options]="portsChartOptionsWide"
                [height]="'100%'"
                [width]="'100%'"
            ></dashlet-line-graph>
        </div>
    </div>
</div>
