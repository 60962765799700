import { Customer, Dashlet, Equipment, Location } from '@models/index';

interface NetworkInterface {
    interface: string;
    timestamp: any;
    txQueueLen: string;
    rxDropped: string;
    txDropped: string;
    rxPackets: string;
    txPackets: string;
    rxBytes: any;
    txBytes: any;
}

export interface DataBaseSummary {
    Instance: string;
    NumberofReplicatesCreated: string;
    Replicate_State: string;
}

export class DashletSystemHealthCiscoPresence extends Dashlet {
    public readonly commandTypeIdServer = '0CD94CC2-42F3-4C6C-9F8B-E9CAF2995261'; // yes
    public readonly commandTypeIdCpu = '37EF261A-5321-4F74-B8BD-0734D834C3C8'; // no
    public readonly commandTypeIdMemory = 'D2E8814E-A42D-4922-81C8-87F4139E65BD'; // no
    public readonly commandTypeIdConnectivity = '7BF15776-F312-40C7-92A1-F95DDEAD52EA'; // yes
    public readonly commandTypeIdFileSystem = '2FD0886C-3CE8-41A6-AF70-E1BE7AE1A4D4';
    public readonly commandTypeIdDatabaseReplicateInfo = '0D7600A6-1D0F-4AF6-B6B0-3BADBD210F72';

    readonly interval = 30;
    customer: Customer;
    location: Location;
    equipment: Equipment;

    databaseSummaryTally: DataBaseSummary;

    showSystemChart = false;
    showFileSystem = false;
    showNetworkGraph = false;
    showNetworkInterface = false;
    showDatabaseSummary = false;

    constructor() {
        super();
        //sizing
        this.sizes = [
            {
                id: 0,
                label: 'Standard',
                cols: 5,
                rows: 4
            }
        ];

        this.applySize(0);

        //init data
        this.resetData();

        //produce data every interval
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        //read settings object
        this.configured = v.customer && v.location && v.equipment;

        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        if (v.location) {
            this.location = new Location(v.location.value, v.location.label);
        } else {
            this.location = new Location('', '');
        }
        if (v.equipment) {
            this.equipment = new Equipment(v.equipment.value, v.equipment.label);
        } else {
            this.equipment = new Equipment('', '');
        }

        this.generatedNameTag = this.configured ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;

        this.showSystemChart = v.showSystemChart || false;
        this.showFileSystem = v.showFileSystem || false;
        this.showNetworkGraph = v.showNetworkGraph || false;
        this.showNetworkInterface = v.showNetworkInterface || false;
        this.showDatabaseSummary = v.showDatabaseSummary || false;

        let h = 0;
        if (this.showSystemChart) h += 1.9;
        if (this.showFileSystem) h += 2;
        if (this.showNetworkGraph) h += 2.1;
        if (this.showNetworkInterface) h += 1.2;
        if (this.showDatabaseSummary) h += 1.2;

        //update size
        this.applySizeExpansion(0, h);
    }

    public resetData(): void {}

    public dispose(): void {}

    public processDatabaseInfo(data: any): void {
        const rows = data[0].data[0];
        if (rows !== null) {
            this.databaseSummaryTally = {
                Instance: rows.Instance,
                NumberofReplicatesCreated: rows.NumberofReplicatesCreated,
                Replicate_State: this.getDataBaseEnum(rows.Replicate_State)
            };
        }
    }

    private getDataBaseEnum(info: string): string {
        switch (info) {
            case '0':
                return 'Initializing';
            case '1':
                return 'Count Displayed Incorrect';
            case '2':
                return 'Good';
            case '3':
                return 'Bad Replication';
            case '4':
                return 'Setup Failed';
            default:
                return 'Setup Failed';
        }
    }
}
