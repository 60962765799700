import * as TypeEquipmentLocationActions from '@actions/get-type-equipments-at-location/get-type-equipments-at-location.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { DashletSettingsService } from '@services/index';

@Injectable()
export class GetTypeEquipmentsAtLocationEffects {
    constructor(private actions$: Actions, private settingsService: DashletSettingsService) {}
    getTypeEquipmentLocations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TypeEquipmentLocationActions.GetTypeEquipmentsAtLocations),
            mergeMap(action =>
                this.settingsService.getTypeEquipmentsAtLocation(action.locationId, action.typeStr).pipe(
                    catchError(err =>
                        of(TypeEquipmentLocationActions.GetTypeEquipmentsAtLocationsFailure({ error: err }))
                    ),
                    mergeMap((data: any) => [
                        TypeEquipmentLocationActions.GetTypeEquipmentsAtLocationsSuccess({
                            data: { locationId: action.locationId.toLowerCase(), data }
                        })
                    ])
                )
            )
        )
    );
}
