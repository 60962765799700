import { createAction, props } from '@ngrx/store';

export const GetHistoricNetworkConnectivity = createAction(
  '[HistoricNetworkConnectivity] Get HistoricNetworkConnectivitys',
  props<{ equipmentId: string, commandTypeId: string, from?: Date | string, to?: Date | string, max?: number | null }>()
);

export const GetHistoricNetworkConnectivitySuccess = createAction(
  '[HistoricNetworkConnectivity] Get HistoricNetworkConnectivitys Success',
  props<{ data:any }>()
);

export const GetHistoricNetworkConnectivityFailure = createAction(
  '[HistoricNetworkConnectivity] Get HistoricNetworkConnectivitys Failure',
  props<{ error: any }>()
);
