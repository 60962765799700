import { Pipe, PipeTransform } from '@angular/core';
import { Dashlet } from '@models/index';

/**
 * Sort dashlets array.
 */
@Pipe({
    name: 'dashletsSort',
    pure: true
})
export class DashletsSortPipe implements PipeTransform {
    transform(dashlets: Dashlet[]) {
        //sort by title, then descriptor
        return dashlets.sort((a, b) => {
            const aTitle = a.title || '';
            const bTitle = b.title || '';
            let cmp = aTitle.localeCompare(bTitle);
            if (cmp === 0) {
                const aTag = a.customNameTag || a.generatedNameTag;
                const bTag = b.customNameTag || b.generatedNameTag;
                if (aTag && bTag) cmp = aTag.localeCompare(bTag);
            }
            return cmp;
        });
    }
}
