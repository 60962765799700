<div class="dashlet-cell">
    <ng-container *ngIf="getLayout() === 0">
        <div class="row l0-utilization-row">
            <div class="dashlet-cell value-box center l0-fix-width">
                <div class="dashlet-label dashlet-label-n">Utilization</div>
                <dashlet-percentage class="dashlet-value" [value]="channelUtilization" [color]="utilizationColour"></dashlet-percentage>
            </div>
            <div class="dashlet-cell l0-fix-width">
                <dashlet-line-graph [data]="smallUtilizationChartData" [options]="smallUtilizationChartOptions" [aspect]="1"></dashlet-line-graph>   
            </div>
        </div>
        <div class="row l0-channels-row">
            <div class="dashlet-cell value-box center l0-fix-width">
                <div class="dashlet-label dashlet-label-n">Active</div>
                <div class="dashlet-value dashlet-value-n">{{activeChannels}}</div>
            </div>
            <div class="dashlet-cell value-box center l0-fix-width">
                <div class="dashlet-label dashlet-label-n">Free</div>
                <div class="dashlet-value dashlet-value-n">{{freeChannels}}</div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="getLayout() === 1">
        <div class="row">
            <div class="col l1-left-col">
                <div class="dashlet-cell value-box center">
                    <div class="dashlet-label dashlet-label-n">Utilization</div>
                    <dashlet-percentage class="dashlet-value" [value]="channelUtilization" [color]="utilizationColour"></dashlet-percentage>
                </div>
                <div class="dashlet-cell value-box center">
                    <div class="dashlet-label dashlet-label-n">Channels</div>
                    <div class="dashlet-value dashlet-value-n">{{channels}}</div>
                </div>
            </div>
            <div class="dashlet-cell dashlet-cell-graph grow">
                <dashlet-line-graph [data]="largeUtilizationChartData" [options]="largeUtilizationChartOptions" [aspect]="1.05"></dashlet-line-graph>   
            </div>
            <div class="col l1-weekly-col">
                <div class="dashlet-cell value-box">
                    <div class="dashlet-label dashlet-label-n">Weekly overutilization</div>
                    <dashlet-percentage class="dashlet-value" [value]=".4" [color]="'red'"></dashlet-percentage>
                </div>
                <div class="dashlet-cell value-box">
                    <div class="dashlet-label dashlet-label-n">Weekly effective utilization</div>
                    <dashlet-percentage class="dashlet-value" [value]=".5" [color]="'green'"></dashlet-percentage>
                </div>
                <div class="dashlet-cell value-box">
                    <div class="dashlet-label dashlet-label-n">Weekly underutilization</div>
                    <dashlet-percentage class="dashlet-value" [value]=".1" [color]="'green'"></dashlet-percentage>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col l1-left-col">
                <div class="dashlet-cell value-box center">
                    <div class="dashlet-label dashlet-label-n">Active</div>
                    <div class="dashlet-value dashlet-value-n">{{activeChannels}}</div>
                </div>
                <div class="dashlet-cell value-box center">
                    <div class="dashlet-label dashlet-label-n">Free</div>
                    <div class="dashlet-value dashlet-value-n">{{freeChannels}}</div>
                </div>
            </div>
            <div class="dashlet-cell dashlet-cell-graph grow">
                <dashlet-line-graph [data]="channelsChartData" [options]="channelsChartOptions" [aspect]="3.1"></dashlet-line-graph>   
            </div>
        </div>
    </ng-container>
</div>