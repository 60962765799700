/* eslint-disable no-undef */
import * as Actions from '@actions/index';
import {
    AccountService,
    DashletService,
    DashletSettingsService,
    ThresholdService,
    TileGridService,
    TimeoutService
} from '@services/index';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    ACMAESServer,
    ColumnTypes,
    CustomerLogo,
    DashletSystemHealthAES,
    LicenceData,
    Link,
    SwitchLink,
    Threshold,
    TSAPITSDIBuffer
} from '@models/index';
import { ThresholdItemComponent } from '../threshold-item/threshold-item.component';
import { DashletSectionProcessorMemoryComponent } from '../dashlet-section-processor-memory/dashlet-section-processor-memory.component';

import { select, Store } from '@ngrx/store';
import {
    AppState,
    selectTypeEquipmentsAtLocation,
    selectEntity,
    selectEntityTimer,
    selectDataFromCommonEntity
} from '@reducers/index';
import { Subscription, from } from 'rxjs';
import { DashletSectionServicesComponent } from '../dashlet-section-services/dashlet-section-services.component';
import { DecimalPipe } from '@angular/common';
import { switchMap, mergeMap, filter, tap } from 'rxjs/operators';

@Component({
    selector: 'app-dashlet-system-health-aes',
    templateUrl: './dashlet-system-health-aes.component.html',
    styleUrls: ['./dashlet-system-health-aes.component.scss']
})
export class DashletSystemHealthAESComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletSystemHealthAES;
    @ViewChild('thresholdItem') thresholdItem: ThresholdItemComponent;

    @ViewChild('services') services: DashletSectionServicesComponent;
    @ViewChild('processorMemory') processorMemory: DashletSectionProcessorMemoryComponent;

    openSubcontainer = '';
    openSubcontainerSeverity = 0;

    selectedField: string;
    selectedThreshold: Threshold;

    titleMsg: string;

    public licenseColumns: ColumnTypes[] = [
        {
            columnDef: 'severity',
            header: '',
            cell: (row: LicenceData) => row.severity(),
            type: 'severity'
        },
        {
            columnDef: 'licenceName',
            header: 'Name',
            cell: (row: LicenceData) => row.licenceName,
            dataTooltip: (row: SwitchLink) => row.name
        },
        {
            columnDef: 'acquired',
            header: 'Acquired',
            cell: (row: LicenceData) => row.acquired,
            type: 'numeric'
        },
        {
            columnDef: 'percentage',
            header: '%',
            cell: (row: LicenceData) => this.getPercentage(row.acquired, row.total),
            type: 'numeric'
        },
        {
            columnDef: 'total',
            header: 'Total',
            cell: (row: LicenceData) => row.total,
            type: 'numeric'
        }
    ];

    public switchLinkColumns: ColumnTypes[] = [
        {
            columnDef: 'severity',
            header: '',
            cell: (row: SwitchLink) => row.severity(),
            type: 'severity'
        },
        {
            columnDef: 'name',
            header: 'Name',
            cell: (row: SwitchLink) => row.name
        },
        {
            columnDef: 'status',
            header: 'Status',
            cell: (row: SwitchLink) => row.status
        },
        {
            columnDef: 'uptime',
            header: 'Uptime',
            cell: (row: SwitchLink) => new Date(row.uptime),
            type: 'date',
            dataTooltip: (row: SwitchLink) => row.uptime
        }
    ];

    public tdsiBufferColumns: ColumnTypes[] = [
        {
            columnDef: 'severity',
            header: '',
            cell: (row: TSAPITSDIBuffer) => row.getSeverity(),
            type: 'severity'
        },
        {
            columnDef: 'name',
            header: 'Name',
            cell: (row: TSAPITSDIBuffer) => row.name,
            dataTooltip: (row: SwitchLink) => row.name
        },
        {
            columnDef: 'allocated',
            header: 'Allocated',
            cell: (row: TSAPITSDIBuffer) => `${row.allocated} ${row.allocatedSizeUnit}`,
            type: 'numeric'
        },
        {
            columnDef: 'percentage',
            header: '%',
            cell: (row: TSAPITSDIBuffer) => this.getPercentage(row.allocatedBits, row.bufferSizeBits),
            type: 'numeric'
        },
        {
            columnDef: 'total',
            header: 'Total',
            cell: (row: TSAPITSDIBuffer) => `${row.bufferSize} ${row.bufferSizeUnit}`,
            type: 'numeric'
        }
    ];

    public connectionsColumns: ColumnTypes[] = [
        {
            columnDef: 'linkNumber',
            header: 'Link',
            cell: (row: Link) => row.linkNumber,
            footerRowDef: () => 'Total'
        },
        {
            columnDef: 'tx',
            header: 'Tx',
            cell: (row: Link) => `${this.decimalPipe.transform(row.tx, '1.2-2')}`,
            headerTooltip: this.getTxTooltip(),
            footerRowDef: () => `${this.decimalPipe.transform(this.dashlet.connectionStatus.getTotalTX(), '1.2-2')}`,
            type: 'numeric'
        },
        {
            columnDef: 'rx',
            header: 'Rx',
            cell: (row: Link) => `${this.decimalPipe.transform(row.rx, '1.2-2')}`,
            headerTooltip: this.getRxTooltip(),
            footerRowDef: () => `${this.decimalPipe.transform(this.dashlet.connectionStatus.getTotalRX(), '1.2-2')}`,
            type: 'numeric'
        }
    ];

    public ctiColumns: ColumnTypes[] = [
        {
            columnDef: 'cti',
            header: 'CTI',
            headerTooltip: this.getCTITooltip()
        },
        {
            columnDef: 'link',
            header: 'Link',
            cell: (row: any) => row.link
        },
        {
            columnDef: 'state',
            header: 'Status',
            cell: (row: any) => row.state
        }
    ];

    private subscription: Subscription = new Subscription();

    constructor(
        private dashletService: DashletService,
        private accountService: AccountService,
        private thresholdService: ThresholdService,
        private tileGridService: TileGridService,
        public timeoutService: TimeoutService,
        private settingsService: DashletSettingsService,
        private store$: Store<AppState>,
        private decimalPipe: DecimalPipe
    ) {}

    ngOnInit() {
        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));

        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );
        this.subscription.add(
            this.settingsService
                .getServerName(
                    this.dashlet.customer.customerId,
                    this.dashlet.location.locationId,
                    this.dashlet.equipment.equipmentId
                )
                .subscribe((serverName: any) => {
                    this.dashlet.serverName = serverName.nodeName;
                })
        );

        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdLicensingTSAPI
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdLicensingTSAPI
            })
        );
        this.store$.dispatch(
            Actions.GetEntityTimer({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdLicensingTSAPI,
                uniqueId: this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdLicensingTSAPI
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdLicensingTSAPI
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length) {
                        this.dashlet.processTSAPILicensingStatus(data);
                    }
                })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectEntityTimer(this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdLicensingTSAPI)
                    )
                )
                .subscribe(dataTimeoutExpired => {
                    if (dataTimeoutExpired !== undefined) {
                        this.dashlet.tsapiData.licensesDataExpired = dataTimeoutExpired;
                    }
                })
        );

        // Licensing status DMCC

        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdLicensingDMCC
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdLicensingDMCC
            })
        );
        this.store$.dispatch(
            Actions.GetEntityTimer({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdLicensingDMCC,
                uniqueId: this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdLicensingDMCC
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdLicensingDMCC
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length) {
                        this.dashlet.processDMCCLicensingStatus(data);
                    }
                })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectEntityTimer(this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdLicensingDMCC)
                    )
                )
                .subscribe(dataTimeoutExpired => {
                    if (dataTimeoutExpired !== undefined) {
                        this.dashlet.dmccData.dataExpired = dataTimeoutExpired;
                    }
                })
        );

        // DMCC status
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdDMCCStatus
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdDMCCStatus
            })
        );
        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdDMCCStatus
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length) {
                        this.dashlet.processDMCCStatus(data);
                    }
                })
        );

        // TSAPI TSDI
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdTSAPITSDI
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdTSAPITSDI
            })
        );
        this.store$.dispatch(
            Actions.GetEntityTimer({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdTSAPITSDI,
                uniqueId: this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdTSAPITSDI
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdTSAPITSDI
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length) {
                        this.dashlet.processTSAPITSDI(data);
                    }
                })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(selectEntityTimer(this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdTSAPITSDI))
                )
                .subscribe(dataTimeoutExpired => {
                    if (dataTimeoutExpired !== undefined) {
                        this.dashlet.tsapiData.buffersDataExpired = dataTimeoutExpired;
                    }
                })
        );

        // TSAPI Switch Link
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipment.equipmentId,
                command: this.dashlet.commandTypeIdTSAPISwitchLink
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdTSAPISwitchLink
            })
        );
        this.store$.dispatch(
            Actions.GetEntityTimer({
                equipmentId: this.dashlet.equipment.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdTSAPISwitchLink,
                uniqueId: this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdTSAPISwitchLink
            })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdTSAPISwitchLink
                        )
                    )
                )
                .subscribe(data => {
                    if (data && data.length) {
                        this.dashlet.processTSAPISwitchLink(data);
                    }
                })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectEntityTimer(
                            this.dashlet.equipment.equipmentId + this.dashlet.commandTypeIdTSAPISwitchLink
                        )
                    )
                )
                .subscribe(dataTimeoutExpired => {
                    if (dataTimeoutExpired !== undefined) {
                        this.dashlet.tsapiData.switchLinksDataExpired = dataTimeoutExpired;
                    }
                })
        );

        this.store$.dispatch(
            Actions.GetTypeEquipmentsAtLocations({ locationId: this.dashlet.location.locationId, typeStr: 'ACM' })
        );

        this.subscription.add(
            this.store$
                .pipe(
                    select(selectTypeEquipmentsAtLocation(this.dashlet.location.locationId)),
                    filter(equips => !!equips), // Ensure equipment data exists
                    switchMap((equips: any) => {
                        return from(equips.data).pipe(
                            mergeMap((equipment: any) => {
                                this.dashlet.allACMs.push(equipment.equipmentId);

                                this.store$.dispatch(
                                    Actions.SubscribeToRealTimeService({
                                        equipmentId: equipment.equipmentId,
                                        command: this.dashlet.commandTypeIdACMDisplayIPServices
                                    })
                                );

                                this.store$.dispatch(
                                    Actions.GetNotifyCommonEntitys({
                                        equipmentId: equipment.equipmentId,
                                        commandTypeId: this.dashlet.commandTypeIdACMDisplayIPServices
                                    })
                                );

                                return this.store$.pipe(
                                    select(
                                        selectDataFromCommonEntity(
                                            equipment.equipmentId + this.dashlet.commandTypeIdACMDisplayIPServices
                                        )
                                    ),
                                    filter(data => !!data && data.length > 0), // Ensure data exists
                                    tap(data => {
                                        const server = data[0].data.find(server => {
                                            const sliceEnd = this.dashlet.serverName.includes('.')
                                                ? this.dashlet.serverName.indexOf('.')
                                                : this.dashlet.serverName.length;
                                            return (
                                                this.dashlet.serverName.slice(0, sliceEnd).toLowerCase() ===
                                                server.AEServicesServer.toLowerCase()
                                            );
                                        });

                                        if (server) {
                                            this.dashlet.acmEquipId = equipment.equipmentId;
                                            this.dashlet.connectionStatus = new ACMAESServer(
                                                server.ServerId,
                                                server.Status
                                            );

                                            this.store$.dispatch(
                                                Actions.SubscribeToRealTimeService({
                                                    equipmentId: this.dashlet.acmEquipId,
                                                    command: this.dashlet.commandTypeIdACMStatusAESvcsCTI
                                                })
                                            );

                                            this.store$.dispatch(
                                                Actions.GetNotifyCommonEntitys({
                                                    equipmentId: this.dashlet.acmEquipId,
                                                    commandTypeId: this.dashlet.commandTypeIdACMStatusAESvcsCTI
                                                })
                                            );
                                        }
                                    }),
                                    mergeMap(data => {
                                        return this.store$.pipe(
                                            select(
                                                selectDataFromCommonEntity(
                                                    this.dashlet.acmEquipId +
                                                        this.dashlet.commandTypeIdACMStatusAESvcsCTI
                                                )
                                            ),
                                            filter(data => !!data && data.length > 0), // Ensure data exists
                                            tap(data => {
                                                this.dashlet.processCTILinkStatus(data);

                                                this.store$.dispatch(
                                                    Actions.SubscribeToRealTimeService({
                                                        equipmentId: this.dashlet.acmEquipId,
                                                        command: this.dashlet.commandTypeIdACMStatusAESvcsLink
                                                    })
                                                );

                                                this.store$.dispatch(
                                                    Actions.GetNotifyCommonEntitys({
                                                        equipmentId: this.dashlet.acmEquipId,
                                                        commandTypeId: this.dashlet.commandTypeIdACMStatusAESvcsLink
                                                    })
                                                );
                                            }),
                                            switchMap(data => {
                                                return this.store$.pipe(
                                                    select(
                                                        selectDataFromCommonEntity(
                                                            this.dashlet.acmEquipId +
                                                                this.dashlet.commandTypeIdACMStatusAESvcsLink
                                                        )
                                                    ),
                                                    filter(data => !!data), // Ensure data exists
                                                    tap(data => {
                                                        this.dashlet.processServiceLinks(data);
                                                    })
                                                );
                                            })
                                        );
                                    })
                                );
                            })
                        );
                    })
                )
                .subscribe()
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getTableHeight(noOfRecordsInTable: number): number {
        const preferedTableHeight = 5;
        return noOfRecordsInTable <= preferedTableHeight ? noOfRecordsInTable : preferedTableHeight;
    }

    getSeverityColor(severity: number): string {
        return this.dashletService.getSeverityColor(severity);
    }

    openDetails(container: string, severity: number) {
        this.openSubcontainer = container;
        this.dashlet.displayHeader = false;
        this.openSubcontainerSeverity = severity;
    }

    closeDetails() {
        this.openSubcontainer = null;
        this.dashlet.displayHeader = true;
    }

    openThresholdAlerts(elementId: string, commandTypeId: string, diskName?: string) {
        this.openSubcontainer = 'createThreshold';
        this.dashlet.displayHeader = false;
        this.selectedThreshold = new Threshold();
        this.selectedThreshold.commandTypeId = commandTypeId;
        this.selectedThreshold.field = elementId;
        this.selectedThreshold.whereCreated = 'Dashboard';
        if (diskName) {
            this.selectedThreshold.fieldInstance = diskName;
        }
    }

    openAlarmIconThreshold() {
        this.thresholdService.openThresholdManagement(this.accountService.getUserDetails().EntityId);
    }

    onSubmit() {
        this.thresholdItem.onSubmit().subscribe(result => {
            if (result) {
                this.selectedThreshold.dapThresholdId = result['DapThresholdId'];
                this.dashlet.thresholds.push(this.selectedThreshold);
                switch (this.selectedThreshold.field) {
                    case 'processor':
                        ++this.processorMemory.cpuUsageData.thresholdsNumber;
                        break;
                    case 'memory':
                        ++this.processorMemory.memoryUsageData.thresholdsNumber;
                        break;
                    case 'disk':
                        const diskUsage = this.processorMemory.diskUsageData.find(d => {
                            return d.diskName === this.selectedThreshold.fieldInstance;
                        });
                        if (diskUsage) {
                            ++diskUsage.thresholdsNumber;
                        }
                        break;
                    default:
                        break;
                }
                this.tileGridService.thresholdCreated(
                    this.selectedThreshold.name + ' threshold added via ' + this.dashlet.title
                );
            } else {
                this.tileGridService.thresholdCreated('Threshold add failed');
            }
        });
        this.closeDetails();
    }

    saveEdit() {
        this.thresholdItem.saveEdit().subscribe(result => {
            if (result) {
                this.tileGridService.thresholdCreated(
                    this.selectedThreshold.name + ' threshold edited via ' + this.dashlet.title
                );
            } else {
                this.tileGridService.thresholdCreated('Threshold edit failed');
            }
        });
        this.closeDetails();
    }

    getCTITooltip() {
        return '(Computer Telephony Integration) - shows status of interactions.';
    }

    getTxTooltip() {
        return 'Shows the number of transmitted packets from AES on that link.';
    }

    getRxTooltip() {
        return 'Shows the number of received packets by AES on that link.';
    }

    getPercentage(count: number, total: number): string {
        return `${Math.round((count / total) * 1000) / 10}%`;
    }

    hasDataDMCC() {
        return (
            this.dashlet.dmccData.activeDevices ||
            this.dashlet.dmccData.activeSessions ||
            this.dashlet.dmccData.totalMonitors ||
            this.dashlet.dmccData.licences.length > 0
        );
    }

    hasDataTSAPI() {
        return (
            this.dashlet.tsapiData.licences.length > 0 ||
            this.dashlet.tsapiData.tsapiTSDIBuffers.length > 0 ||
            this.dashlet.tsapiData.switchLinks.length > 0
        );
    }
}
