<div class="flex-column gap-l">
    <ng-container *ngIf="!openSubcontainer">
        <!-- system -->
        <dashlet-section-processor-memory
            #processorMemory
            [showSystemChart]="dashlet.showSystemChart"
            [showDiskUsage]="dashlet.showFileSystem"
            [equipment]="dashlet.equipment"
            [customerId]="dashlet.customer.customerId"
            [locationId]="dashlet.location.locationId"
            [commandTypeIdCPUUsage]="dashlet.commandTypeIdCpu"
            [commandTypeIdMemoryUsage]="dashlet.commandTypeIdMemory"
            [commandTypeIdUpTime]="dashlet.commandTypeIdServer"
            [commandTypeIdDiskUsage]="dashlet.commandTypeIdFileSystem"
        >
        </dashlet-section-processor-memory>

        <!-- network connectivity -->
        <dashlet-section-network-connectivity
            [showNetworkGraph]="dashlet.showNetworkGraph"
            [equipment]="dashlet.equipment"
            [customerId]="dashlet.customer.customerId"
        >
        </dashlet-section-network-connectivity>

        <div *ngIf="dashlet.showDatabaseSummary">
            <dashlet-section-header>Database Summary</dashlet-section-header>
            <div class="justify-space-between grow">
                <div class="flex-column gap-s mat-h2 metric" [ngStyle]="{ 'border-left-color': getDatabaseColor(dashlet.databaseSummaryTally) }">
                    <div class="mat-caption">Replication Status</div>
                    <div>{{ dashlet.databaseSummaryTally ? dashlet.databaseSummaryTally.Replicate_State : '-' }}</div>
                </div>
                <div class="flex-column gap-s mat-h2 metric">
                    <div class="mat-caption">Replicates Created</div>
                    <div class="text-right">{{ dashlet.databaseSummaryTally ? dashlet.databaseSummaryTally.NumberofReplicatesCreated : '-' }}</div>
                </div>
            </div>
        </div>

        <!-- Call Rate -->
        <div *ngIf="dashlet.showCallRate">
            
            <dashlet-section-header>Call Rate</dashlet-section-header>
            <div class="grow justify-space-around">
                <div class="mat-body-2">Calls</div>
                <div class="mat-body-2">Video</div>
            </div>
            <dashlet-table [columns]="callRateColumns" [dataSource]="dashlet.callRate" [sortable]="false"></dashlet-table>
            
        </div>

        <!-- SIP Calls -->
        <div *ngIf="dashlet.showSipCalls" >
            <dashlet-section-header>SIP Calls</dashlet-section-header>
            <div class="grow justify-space-around">
                
                <div class="mat-body-2">Calls</div>
                <div class="mat-body-2">Video</div>
            </div>
            <dashlet-table [columns]="sipColumns" [dataSource]="dashlet.sipCalls"  [sortable]="false"></dashlet-table>
            
        </div>
    </ng-container>
    <dashlet-section-network-interface
        *ngIf="dashlet.showNetworkInterface"
        [equipment]="dashlet.equipment"
        [locationName]="dashlet.location.locationName"
        [customerName]="dashlet.customer.customerName"
        (openDetails)="$event ? openDetails($event) : closeDetails()"
    ></dashlet-section-network-interface>
</div>
